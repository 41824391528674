import React, { useEffect, useState } from 'react'
import styles from './dropdown.module.scss'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { notification, Input, Form, Button, Modal } from 'antd'
import { sortBy } from 'lodash'

import {CloseOutlined} from '@ant-design/icons'
//apis
import { onShift, outShift, getShift } from 'apis/roll-call'
export default function RollCallForm({ visibleRollCall, toggleRollCall }) {
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [formLogin] = Form.useForm()
  const [formLogout] = Form.useForm()

  const [visibleOut, setVisibleOut] = useState(false)
  const [visibleButton, setVisibleButton] = useState(true)
  const [shifts, setShifts] = useState([])
  const [visibleLogout, setVisibleLogout] = useState(false)
  const [rollCallId, setRollCallId] = useState(0)
  const toggleLogout = () => setVisibleLogout(!visibleLogout)
  const toggleButton = () => setVisibleButton(!visibleButton)
  const toggleOut = () => setVisibleOut(!visibleOut)
  const _getShift = async () => {
    try {
      let res = await getShift({ time: moment().format(), branch_id: branchIdApp })
      if (res.status === 200) {
        setShifts(res.data.data)
        if (res.data.data.length > 0) {
          let arr_new = res.data.data.reduce((arr, element) => {
            let index = arr.findIndex((e) => e.shift_id === element.shift_id)
            if (index === -1) {
              arr.push(element.shift)
            }
            return arr
          }, [])
          arr_new = sortBy(arr_new,'time_start')
          res.data.data.forEach((element) => {
            let user = {
              full_name: element.user.first_name + ' ' + element.user.last_name,
              role_id: element.role_id,
              phone: element.user.phone,
              roll_call_id: element.roll_call_id,
              shift_id: element.shift_id,
            }
            for (let item of arr_new) {
              if (item.shift_id === user.shift_id) {
                let index = item.role.findIndex((e) => e.role_id === user.role_id)
                if (index !== -1) item.role[index].users.push(user)
              }
            }
          })
          setShifts(arr_new)
        }
        toggleOut()
      } else {
        notification.warning({ message: res.data.message || 'Không có ca nào đang làm việc!' })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const CustomNotification = ({ linkUrl }) => {
    return (
      <div>
        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
          <Button style={{width: '100%', backgroundColor: '#335BD3', color: '#FFFFFF', borderColor: '#335BD3'}}>Tạo ca</Button>
        </a>
      </div>
    );
  };
  
  const showCustomNotification = (linkUrl, text) => {
    notification.warning({
      key:'none-shift',
      message: text,
      duration: 0,
      description: <CustomNotification linkUrl={linkUrl} />,
    });
  };

  const login = async () => {
    try {
      await formLogin.validateFields()
      const dataForm = formLogin.getFieldValue()
      const body = {
        phone: dataForm.phone,
        password: dataForm.password,
        branch_id: branchIdApp,
        start_time: moment().format(),
      }
      const res = await onShift(body)
      if (res.status === 200) {
        notification.success({ message: res.data.message || 'Vào ca thành công' })
        toggleButton()
        formLogin.resetFields()
        setShifts([])
      } else {
        if (res.data.type === 'shift') {
          showCustomNotification(`http://${dataUser._business.prefix}.${process.env.REACT_APP_HOST}/schedule-staff`, res.data.message)
          setTimeout(() => {
            notification.close('none-shift');
          }, 5000)
        } else {
          notification.error({ message: res.data.message || 'Vào ca thất bại' })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const logout = async () => {
    try {
      await formLogout.validateFields()
      const dataForm = formLogout.getFieldValue()
      const body = {
        roll_call_id: rollCallId,
        phone: dataForm.phone,
        password: dataForm.password,
        branch_id: branchIdApp,
        end_time: moment().format(),
      }
      const res = await outShift(body)
      if (res.status === 200) {
        notification.success({ message: res.data.message || 'Ra ca thành công' })
        toggleLogout()
        toggleOut()
        formLogout.resetFields()
        setShifts([])
      } else {
        notification.error({ message: res.data.message || 'Ra ca thất bại' })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const Item = ({ data }) => {
    return (
      <div className={styles['list-shift']}>
        {/* <div className={styles['list-shift__name']}>{data.title}</div> */}
        {data?.role.map((role) => (
          <div style={{ paddingLeft: '40px' }}>
            {role.users.length > 0 ? (
              <>
                <div className={styles['list-shift__name-role']}>{role.name}</div>
                {role.users.map((user) => (
                  <div className={styles['list-shift__out-shift']}>
                    <div style={{ width: '10em' }}>{user.full_name}</div>
                    <Button
                    className={styles['list-shift__btn-out']}
                    onClick={() => {
                      toggleLogout()
                        setRollCallId(user.roll_call_id)
                        formLogout.setFieldValue({
                          phone: user.phone,
                          password: '',
                        })
                      }}
                    >
                      Ra ca
                    </Button>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        ))}
      </div>
    )
  }
  return (
    <>
      <Modal visible={visibleRollCall} width={400} footer={false} closeIcon={<CloseOutlined onClick={toggleRollCall}/>}>
        <div className={styles['layout-form']}>
          <p className={styles['name']}>{visibleButton ? 'Điểm danh' : 'Vào ca'}</p>
          <div className={styles['layout-form-footer']}>
            {visibleButton ? (
              <>
                <Button className={styles['btn-shift']} onClick={toggleButton}>
                  Vào ca
                </Button>
                <Button className={styles['btn-shift']} onClick={_getShift}>
                  Ra ca
                </Button>
              </>
            ) : null}
          </div>
          {visibleButton ? null : (
            <div style={{ width: '100%' }}>
              <Form
                requiredMark={false}
                form={formLogin}
                layout="vertical"
                style={{ width: '100%' }}
              >
                <Form.Item
                  style={{ marginBottom: '30px' }}
                  label={<p className={styles['label-style']}>Số điện thoại</p>}
                  name="phone"
                  rules={[
                    { required: true, message: 'Vui lòng nhập số điện thoại', whitespace: true },
                    {
                      pattern: new RegExp(/([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/),
                      message: 'Vui lòng nhập số điện thoại đúng định dạng',
                    },
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input
                    //   prefix={<PrefixSelector />}
                    allowClear
                    className={styles['input-item']}
                    size="large"
                    placeholder="Nhập số điện thoại"
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: '24px' }}
                  label={<p className={styles['label-style']}>Mật khẩu</p>}
                  name="password"
                  rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                >
                  <Input.Password
                    className={styles['input-item']}
                    size="large"
                    type="password"
                    placeholder="Nhập mật khẩu"
                  />
                </Form.Item>
                <div style={{ display: 'flex' }}>
                  <Button className={styles['btn-cancel']} onClick={toggleButton}>
                    Hủy
                  </Button>

                  <Button className={styles['btn-shift']} onClick={login}>
                    Vào ca
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </Modal>
      <Modal visible={visibleOut} width={550} footer={false} closeIcon={<CloseOutlined onClick={toggleOut}/>}>
        <div>
          <div className={styles['title-staff']}>Danh sách nhân viên trong ca</div>
          <div style={{overflowY: "scroll", height:600}}>{shifts.length > 0 ? shifts.map((shift) => <Item data={shift} />) : null}</div>
        </div>
      </Modal>
      <Modal visible={visibleLogout} width={300} footer={false} closeIcon={<CloseOutlined onClick={toggleLogout}/>}> 
        <div className={styles['layout-form']}>
          <div className={styles['name']}>Ra ca</div>
          <Form requiredMark={false} form={formLogout} layout="vertical" style={{ width: '100%' }}>
            <Form.Item
              style={{ marginBottom: '30px' }}
              label={<p className={styles['label-style']}>Số điện thoại</p>}
              name="phone"
              rules={[
                { required: true, message: 'Vui lòng nhập số điện thoại', whitespace: true },
                {
                  pattern: new RegExp(/([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/),
                  message: 'Vui lòng nhập số điện thoại đúng định dạng',
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <Input
                //   prefix={<PrefixSelector />}
                allowClear
                className={styles['input-item']}
                size="large"
                placeholder="Nhập số điện thoại"
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: '24px' }}
              label={<p className={styles['label-style']}>Mật khẩu</p>}
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
            >
              <Input.Password
                className={styles['input-item']}
                size="large"
                type="password"
                placeholder="Nhập mật khẩu"
              />
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Button className={styles['btn-cancel']} onClick={toggleLogout}>
                Hủy
              </Button>

              <Button className={styles['btn-shift']} onClick={logout}>
                Ra ca
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}
