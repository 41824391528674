import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { ACTION, ROUTES } from 'consts'
import { validatePhone } from 'utils'
import { PERMISSIONS } from 'consts'
// antd
import {
  Select,
  Button,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  notification,
  Radio,
  Divider,
} from 'antd'

//apis
import { getDistricts, getProvinces, getWards } from 'apis/address'
import { addCustomer, updateCustomer, getCustomerTypes, addCustomerType } from 'apis/customer'
import Permission from 'components/permission'
import { Link } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons'

const { Option } = Select
export default function CustomerForm({ record, close, reload, text = 'Tạo' }) {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [districts, setDistricts] = useState([])
  const [provinces, setProvinces] = useState([])
  const [ward, setWard] = useState([])
  const [provinceName, setProvinceName] = useState('')
  const [districtName, setDistrictsName] = useState('')
  const [wardValue, setWardValue] = useState({})
  const [customerTypes, setCustomerTypes] = useState([])
  const [type, setType] = useState('')
  const [loadingBtn, setLoadingBtn] = useState(false)

  const _createType = async () => {
    try {
      if (!type) {
        notification.warning({ message: 'Vui lòng nhập loại khách hàng' })
        return
      }
      setLoadingBtn(true)
      const res = await addCustomerType({ name: type })
      if (res.status === 200) {
        if (res.data.success) {
          await _getCustomerTypes()
          notification.success({ message: 'Tạo loại khách hàng thành công' })
          setType('')
        } else
          notification.error({
            message: res.data.message || 'Tạo loại khách hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Tạo loại khách hàng thất bại, vui lòng thử lại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
    }
  }

  const onFinish = async (values) => {
    try {
      values.phone = values.phone.replace('+84', 0)

      if (!validatePhone(values.phone)) {
        notification.warning({ message: 'Vui lòng nhập số điện thoại đúng định dạng' })
        return
      }
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        ...values,
        branch_id: branchIdApp,
        point: 0,
        used_point: 0,
        order_quantity: 0,
        order_total_cost: 0,
        first_name: values.first_name || '',
        email: values.email || '',
        birthday: values.birthday || '',
        address: values.address || '',
        // province: values.province || '',
        // district: values.district || '',
        // ward: values.ward || '',
        balance: [],
        active: 1,
      }
      let res
      if (record) res = await updateCustomer(record.customer_id, body)
      else res = await addCustomer(body)
      if (res.status === 200) {
        if (res.data.success) {
          reload()
          notification.success({ message: `${record ? 'Cập nhật' : 'Thêm'} khách hàng thành công` })
          close()
          initForm()
        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? 'Cập nhật' : 'Thêm'} khách hàng thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${record ? 'Cập nhật' : 'Thêm'} khách hàng thất bại, vui lòng thử lại!`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const initForm = () => {
    form.setFieldsValue({
      // type: 'Vãng Lai',
      gender: 0,
    })
  }
  const _getCustomerTypes = async () => {
    try {
      const res = await getCustomerTypes()
      if (res.status === 200) setCustomerTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getCustomerTypes()
    if (!record) initForm()
    else {
      setProvinceName(record.province || '')
      setDistrictsName(record.district || '')
      form.setFieldsValue({
        ...record,
        birthday: record.birthday !== '' ? moment(new Date(record.birthday)) : '',
        type_id: record._type ? record._type.type_id : '',
      })
    }
  }, [])
  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row justify="space-between" align="middle">
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Họ"
            name="first_name"
            rules={[
              {
                pattern:
                  /^[a-zA-Z\sàáảạãặẳằắẵẩấầẫậâăÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪẽẻẹèéễếệểềêỉĩịìíọõỏôóốồổỗộơớờởỡợụùúũủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹđ]+$/,
                message: 'Chỉ cho phép nhập ký tự chữ',
              },
            ]}
          >
            <Input allowClear size="large" placeholder="Nhập họ khách hàng" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Tên khách hàng"
            name="last_name"
            rules={[
              { required: true, message: 'Vui lòng nhập tên khách hàng' },
              {
                pattern:
                  /^[a-zA-Z\sàáảạãặẳằắẵẩấầẫậâăÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪẽẻẹèéễếệểềêỉĩịìíọõỏôóốồổỗộơớờởỡợụùúũủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹđ]+$/,
                message: 'Chỉ cho phép nhập ký tự chữ',
              },
            ]}
          >
            <Input allowClear size="large" placeholder="Nhập tên khách hàng" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Số điện thọai"
            name="phone"
            rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
          >
            <Input
              allowClear
              style={{ width: '100%' }}
              size="large"
              placeholder="Nhập số điện thoại"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item name="type_id" label="Loại khách hàng">
            <Select
              allowClear
              placeholder="Chọn loại khách hàng"
              size="large"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Link target="_blank" to={{ pathname: ROUTES.CUSTOMER_TYPE }}>
                    <Button type="primary" icon={<PlusCircleOutlined />} primary block>
                      Tạo mới
                    </Button>
                  </Link>
                </div>
              )}
            >
              {customerTypes.map((type, index) => (
                <Option value={type.type_id} key={index}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item name="birthday" label="Ngày sinh">
            <DatePicker
              placeholder="Chọn ngày sinh"
              size="large"
              className="br-15__date-picker"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item label="Địa chỉ" name="address">
            <Input allowClear placeholder="Nhập địa chỉ" size="large" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item label="Email" name="email">
            <Input allowClear placeholder="Nhập email" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item name="gender" label="Giới tính">
          <Radio.Group>
            <Radio value={0}>Nam</Radio>
            <Radio value={1}>Nữ</Radio>
            <Radio value={2}>Khác</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      <Row justify="end">
        <Form.Item>
          {text == 'Lưu' && (
            <Permission permissions={[PERMISSIONS.cap_nhat_khach_hang]}>
              <Button style={{ width: 100 }} type="primary" htmlType="submit" size="large">
                {text}
              </Button>
            </Permission>
          )}

          {text == 'Tạo' && (
            <Permission permissions={[PERMISSIONS.them_khach_hang]}>
              <Button style={{ width: 100 }} type="primary" htmlType="submit" size="large">
                {text}
              </Button>
            </Permission>
          )}
        </Form.Item>
      </Row>
    </Form>
  )
}
