import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import styles from './update_business.module.scss'
import DefaultLogo from 'assets/img/default_logo_ekata.png'
import logo_default from 'assets/img/bg-profile.png'

import {
  Card,
  Avatar,
  Typography,
  Button,
  Form,
  Input,
  Upload,
  Tabs,
  List,
  notification,
  Modal,
  Row,
  message,
  Col,
  Badge,
} from 'antd'

//api
import { updateBusiness } from 'apis/business'
import { CloseCircleTwoTone, DeleteTwoTone } from '@ant-design/icons'
import { regexRemoveUnicode } from 'consts'

export default function Update_business({ children }) {
  const { Meta } = Card
  const { Title } = Typography
  const user = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [loading, setLoading] = useState(false)
  const [listImage, setList_image] = useState([])
  const [coverImage, setCoverImage] = useState('')
  const [logo, setLogo] = useState('')
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const dispatch = useDispatch()

  const props = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        setList_image(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} tải lên bị lỗi`)
      }
    },
  }
  const propsCover = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        setCoverImage(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} tải lên bị lỗi`)
      }
    },
  }
  const propsLogo = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/upload/single',
    async onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        setLogo(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} tải lên bị lỗi`)
      }
    },
  }
  const [form] = Form.useForm()
  const _editBusiness = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()

      setLoading(true)
      const body = {
        ...dataForm,
        business_name: dataForm.business_name || '',
        business_desiption: dataForm.bio || '',
        list_image: listImage || '',
        business_logo: logo,
        business_cover_image: coverImage || '',
        business_phone: dataForm.phone,
        business_address: dataForm.address,
        business_email: dataForm.email,
        business_website: dataForm.website,
        branch_id: branchIdApp
      }
      let res = await updateBusiness(user._business.business_id, body)

      if (res.status === 200) {
        if (res.data.success) {
          toggle()
          dispatch({
            type: 'UPDATE_BUSINESS',
            data: {
              business_id: user._business.business_id,
              ...body,
            },
          })
          notification.success({
            message: `Cập nhật cửa hàng thành công`,
          })
        } else
          notification.error({
            message: res.data.message || `Cập nhật chức năng thất bại, vui lòng thử lại`,
          })
      } else
        notification.error({
          message: res.data.message || `Cập nhật chức năng thất bại, vui lòng thử lại`,
        })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    setLogo(user._business.business_logo)
    setCoverImage(user._business.business_cover_image)
    form.setFieldsValue({
      business_name: user._business.business_name,
      phone: user._business.business_phone,
      address: user._business.business_address,
      email: user._business.business_email,
      website: user._business.business_website,
      bio: user._business.business_desiption,
    })
  }, [])

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal
        width="55%"
        style={{ height: '70%', marginTop: '-100px' }}
        title="Cập nhật cửa hàng của bạn"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        centered
        visible={visible}
        footer={
          <Row justify="end">
            <Button
              onClick={_editBusiness}
              loading={loading}
              size="large"
              type="primary"
              style={{ width: 230, background: '#1e4db7' }}
            >
              Cập nhật cửa hàng
            </Button>
          </Row>
        }
      >
        <Form
          form={form}
          className={styles['form']}
          layout="horizontal"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={[12, 0]}>
            <Col span={10} id="update">
              <div
                className={styles['card']}
                style={{ backgroundImage: `url(${coverImage || logo_default})` }}
              >
                <Meta
                  avatar={
                    <div
                      className={styles['avatar']}
                      style={{ backgroundImage: `url(${logo || DefaultLogo})` }}
                    >
                      <Badge
                        count={
                          logo && (
                            <CloseCircleTwoTone
                              twoToneColor={'red'}
                              style={{ fontSize: 20, cursor: 'pointer' }}
                              onClick={() => {
                                if (logo) {
                                  setLogo('')
                                  notification.success({
                                    key: 'delete avatar business',
                                    message: 'Cập nhật cửa hàng thành công',
                                  })
                                }
                              }}
                            />
                          )
                        }
                      >
                        <Upload showUploadList={false} {...propsLogo}>
                          <div className={styles['edit_avatar']}>Sửa</div>
                        </Upload>
                      </Badge>
                    </div>
                  }
                  title={
                    <Title level={2} style={{ color: 'white' }}>
                      {user._business.business_name}
                    </Title>
                  }
                  description={
                    <div style={{ display: 'flex', position: 'absolute', marginTop: '59px' }}>
                      <span style={{ color: 'white', fontSize: 15, float: 'left' }}>
                        Đã tham gia:
                      </span>
                      <span style={{ color: 'white', fontSize: 15, marginLeft: 3 }}>
                        {moment(user._business.created_at).format('DD-MM-YYYY HH:mm')}
                      </span>
                    </div>
                  }
                  style={{ marginTop: 20, marginLeft: 10, fontSize: 30 }}
                />
                <Badge
                  count={
                    coverImage && (
                      <CloseCircleTwoTone
                        twoToneColor={'red'}
                        style={{ fontSize: 20, cursor: 'pointer' }}
                        onClick={() => {
                          if (coverImage) {
                            setCoverImage('')
                            notification.success({
                              key: 'delete avatar business',
                              message: 'Cập nhật cửa hàng thành công',
                            })
                          }
                        }}
                      />
                    )
                  }
                >
                  <Upload
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    {...propsCover}
                  >
                    <div className={styles['edit_cover_image']}>Sửa ảnh bìa</div>
                  </Upload>
                </Badge>
              </div>
              <List
                //  className={styles['list']}
                dataSource={[
                  {
                    id: 1,
                    name: 'Giao diện Shop trên máy tính',
                    src: 'https://png.pngtree.com/png-vector/20191026/ourlarge/pngtree-lcd-vector-icon-white-background-png-image_1870176.jpg',
                    a: 'Xem >',
                  },
                  {
                    id: 2,
                    name: 'Sản phẩm',
                    src: 'https://cdn1.vectorstock.com/i/1000x1000/96/40/magic-open-box-line-icon-box-with-stars-vector-24019640.jpg',
                    a: '784 >',
                  },
                  {
                    id: 3,
                    name: 'Tỉ lệ phản hồi',
                    src: 'https://cdn5.vectorstock.com/i/1000x1000/78/34/chat-icon-isolated-on-white-background-from-vector-27697834.jpg',
                    a: '97%',
                  },
                  {
                    id: 4,
                    name: 'Thời gian phản hồi',
                    src: 'https://www.iconpacks.net/icons/1/free-time-icon-968-thumb.png',
                    a: 'Vài tiếng',
                  },
                  {
                    id: 5,
                    name: 'Đánh giá Shop',
                    src: 'https://cdn3.vectorstock.com/i/1000x1000/09/42/star-icon-vector-22390942.jpg',
                    a: '0,0(000)',
                  },
                  {
                    id: 6,
                    name: 'Tỉ lệ đơn không thành công',
                    src: 'https://media.istockphoto.com/vectors/bill-icon-vector-design-trendy-vector-id1284067597?k=20&m=1284067597&s=612x612&w=0&h=7rHw18-AHc771dObKJZY3s5_ifMjd4cfs6e93MkZ61s=',
                    a: '0,00%',
                  },
                ]}
                bordered
                renderItem={(item) => (
                  <List.Item key={item.id} actions={[<a>{item.a}</a>]}>
                    <List.Item.Meta avatar={<Avatar src={item.src} />} title={item.name} />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={14}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Không được để trống tên cửa hàng!',
                  },
                ]}
                label={<span className={styles.label}>Tên shop</span>}
                name="business_name"
              >
                <Input />
              </Form.Item>
              <Form.Item label={<span className={styles.label}>SDT</span>} name="phone">
                <Input disabled={true} />
              </Form.Item>
              <Form.Item label={<span className={styles.label}>Địa chỉ</span>} name="address">
                <Input placeholder="Nhập địa chỉ " />
              </Form.Item>
              <Form.Item
                name="email"
                className={styles['width-input']}
                rules={[
                  {
                    type: 'email',
                    message: 'Email không đúng định dạng',
                  },
                  {
                    pattern: regexRemoveUnicode,
                    message: 'Email không đúng định dạng',
                  },
                ]}
                label={<span className={styles.label}>Email</span>}
              >
                <Input placeholder="Nhập email" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    pattern: RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/),
                    message: 'Website không đúng định dạng',
                  },
                ]}
                label={<span className={styles.label}>Website</span>}
                name="website"
              >
                <Input
                  onInput={(e) => {
                    e.target.value = e.target.value.replaceAll(' ', '')
                  }}
                  placeholder="Nhập website"
                />
              </Form.Item>

              <Form.Item label={<span className={styles.label}>Bio</span>} name="bio">
                <Input.TextArea
                  size="large"
                  placeholder="Nhập mô tả hoặc thông tin về Shop của bạn"
                />
              </Form.Item>
              <Title level={4}>Mô tả hình ảnh và video</Title>
              {user._business.list_image ? (
                <>
                  {user._business.list_image.map((e) => (
                    <>
                      <img style={{ width: 100, height: 100, margin: 5 }} src={e}></img>
                    </>
                  ))}
                </>
              ) : null}
              <Upload
                name="image2"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={true}
                {...props}
              >
                Hình ảnh và video
              </Upload>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
