import React, { useState, useEffect, useRef, useMemo } from 'react'
import localStorage from '../../utils/localStorage'
import styles from './sell.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { formatCash, validatePhone } from 'utils'
import { ACTION, PERMISSIONS, ROUTES, SHIP_STATUS_ORDER } from 'consts'
import noData from 'assets/icons/no-data.png'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import moment from 'moment'
//components
import CustomerForm from './customer-form'
import { DetailProductModal } from './items/detailProductModal'
import ModalPromotion from './promotion-available'
import ModalPromotionWarning from './promotion-warning'
import Permission from 'components/permission'
import ListDeliveryAddress from './list-delivery-address/list-delivery-address'
import ConnectHistorySell from './connect-history-sell'
import PrintOldInvoices from './print-old-invoices'
import ProductsRelated from './left-layout/productsRelated'
import CustomerInfo from './right-layout/customer-info'
import { convertTemplate } from './items/convertTemplate'
import { ProductInfo } from './table-product-sell'
import { socketInvoiceBusiness } from 'redux/actions/update-invoice'
import { getLocationExpiry } from 'apis/location'
import { sortBy, orderBy, delay } from 'lodash'
import Logo from 'assets/img/logo-icon.png'
import OtpOrderPoint from './items/OtpOrderPoint'
import { socket } from '../../socket'
import { _getProductChange } from '../../components/Product/getProductChange'
import { PaymentMethodModal } from './items/paymentMethodModal'
import HeaderLayout from './header-layout'
import FaceBook from 'assets/icons/facebook-logo.png'
import Tiktok from 'assets/icons/tiktok-logo.png'
import Instagram from 'assets/icons/instagram-logo.png'
import Shopee from 'assets/icons/shopee-logo.png'

//antd
import {
  Row,
  Select,
  Popconfirm,
  Tooltip,
  Modal,
  Button,
  Divider,
  Switch,
  Radio,
  Input,
  InputNumber,
  Table,
  Popover,
  notification,
  Col,
  Badge,
  Typography,
  Space
} from 'antd'

//icons antd
import {
  CloseCircleTwoTone,
  UserOutlined,
  CloseOutlined,
  PlusSquareFilled,
  ExclamationCircleOutlined,
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  InfoCircleOutlined,
  DownCircleTwoTone,
  UpCircleTwoTone,
} from '@ant-design/icons'
//apis
import { getShippings } from 'apis/shipping'
import { getPromotions } from 'apis/promotion'
import { addCustomer, getCustomers, updateCustomer } from 'apis/customer'
import { getVariantsSell } from 'apis/product'
import { addOrder, updateStatusCheckOtp } from 'apis/order'
import { getAllBranch } from 'apis/branch'
import { getPayments } from 'apis/payment'
import { getPoint } from 'apis/point'
import { getTaxs } from 'apis/tax'
import { getConfigInvoiceSale } from 'apis/app'
import PrintCustomize from 'components/print/print-customize'

import { getAllUserSystem } from 'apis/manage-staff'
import { getProductAllBranch } from 'apis/report'
import { socketTransport, socketInventoryNote, socketProductUpdate } from '../../components/socket'


export default function Sell() {
  const { Paragraph, Text } = Typography
  const [ellipsis] = useState(true)
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const invoicesSelector = useSelector((state) => state.invoice.invoices)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { products, productsFil } = useSelector((state) => state.products)
  let { variants, variantFil } = useSelector((state) => state.variant)

  const [contentOrderPrinter, setContenrOrderPrinter] = useState('')
  const [orderSeletedPrint, setOrderSeletedPrint] = useState()
  const [timeoutBlinkVariant, setTimeoutBlink] = useState({})
  const printerRef = useRef()
  const [productQuantity, setProductQuantity] = useState([])
  const socketReducer = useSelector((state) => state.socket)
  const [productiMei, setProductiMei] = useState([])
  const [showProductRelated, setShowProductRelated] = useState(false)
  const loadingScreen = useSelector((state) => state.login.loading)
  const [checkSyncPoint, setCheckSyncPoint] = useState(false)
  const [shippings, setShippings] = useState([])
  const scrollSmoothHandler = (variant_id) => {
    const variantEl = document.getElementById(`variant_${variant_id}`)
    const timeOut = variantEl ? 0 : 300
    setTimeout(() => {
      const variantEl = document.getElementById(`variant_${variant_id}`)
      if (variantEl) {
        if (typeof timeoutBlinkVariant[variant_id] != 'undefined') {
          clearTimeout(timeoutBlinkVariant[variant_id])
        }

        variantEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

        variantEl.style.backgroundColor = '#dddddd'

        let intv = setTimeout(() => {
          variantEl.style.backgroundColor = ''
        }, 1000)
        timeoutBlinkVariant[variant_id] = intv
        setTimeoutBlink(timeoutBlinkVariant)
      }
    }, timeOut)
  }

  const isBranchClosed = () =>
    branches.some((branch) => branchIdApp === branch.branch_id && !branch.active)

  const PrintTemplate = () => (
    <div style={{ display: 'none' }}>
      <PrintCustomize
        ref={printerRef}
        content={parse(convertTemplate(orderSeletedPrint, contentOrderPrinter, dataUser), {
          trim: false,
        })}
      />
    </div>
  )

  const handlePrint = useReactToPrint({
    content: () => printerRef.current,
    copyStyles: false,
  })
  const [branches, setBranches] = useState([])
  const [visibleConfirmCreateOrder, setVisibleConfirmCreateOrder] = useState(false)

  const [taxList, setTaxList] = useState([])
  const [productsSearch, setProductsSearch] = useState([])
  const [productsRelated, setProductsRelated] = useState([])
  const [promotions, setPromotions] = useState([])
  const inputRef = useRef(null)
  const [countProducts, setCountProducts] = useState(0)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [loadingProductRelated, setLoadingProductRelated] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 15 })
  const [isAutoTrackingNumber, setIsAutoTrackingNumber] = useState(false)
  const [paymentMethodDefault, setPaymentMethodDefault] = useState({})
  const [visiblePayments, setVisiblePayments] = useState(false)
  const [isVisiblePromotion, setVisiblePromotion] = useState(false)
  const [visibleCreateCustomer, setVisibleCreateCustomer] = useState(false)
  const toggleCustomer = () => setVisibleCreateCustomer(!visibleCreateCustomer)
  const [visibleUpdateCustomer, setVisibleUpdateCustomer] = useState(false)
  const toggleUpdateCustomer = () => setVisibleUpdateCustomer(!visibleUpdateCustomer)
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [point, setPoint] = useState({})
  const [customers, setCustomers] = useState([])
  const [systemCustomers, setsystemCustomers] = useState([])
  const [anotherCustomers, setAnotherCustomers] = useState([])

  const [infoBranch, setInfoBranch] = useState({})
  const [availableOrder, setAvailableOrder] = useState(false)
  const [visiblePrinter, setVisiblePrinter] = useState(false)
  const [visibleProductDetail, setVisibleProductDetail] = useState(false)

  const [overlayPage, setOverlayPage] = useState(false)

  const [showInfoPayment, setShowInfoPayment] = useState(false)
  //object invoice
  const initInvoice = {
    id: uuidv4(),
    name: '000001',
    type: 'default',
    customer: null,
    order_details: [], //danh sách sản phẩm trong hóa đơn
    payments: [{ ...paymentMethodDefault }], //hình thức thanh toán
    sumCostPaid: 0, // tổng tiền của tất cả sản phẩm
    total_discount: 0, // tổng giảm giá bao gồm từ sản phẩm và khuyến mãi
    discount: {}, // giảm giá từ khuyến mãi (bao gồm voucher)
    list_tax: [], //ds thuế áp dụng
    noteInvoice: '',
    salesChannel: '', //kênh bán hàng
    isDelivery: false,
    payer_shipping: false, // người trả phí ship
    fee_shipping: 0, //phí giao hàng
    shipping_info: null, //địa chỉ nhận hàng
    shipping: null, //đơn vị vận chuyển
    tracking_number: '',
    discount_invoice: 0,
    moneyToBePaidByCustomer: 0, // tổng tiền khách hàng phải trả
    prepay: 0, //tiền khách thanh toán một phần
    moneyGivenByCustomer: 0, //tiền khách hàng đưa
    excessCash: 0, //tiền thừa
    create_date: new Date(), //ngày tạo đơn hàng
    code: '', //mã đơn hàng khi in hóa đơn
    imei_new: [], // imei sản phẩm
    locations_new: [], // lô của sản phẩm
  }
  const [invoices, setInvoices] = useState(!invoicesSelector.length ? [initInvoice] : invoicesSelector)
  const [indexInvoice, setIndexInvoice] = useState(0)
  const [indexInvoiceOld, setIndexInvoiceOld] = useState(0)
  const [activeKeyTab, setActiveKeyTab] = useState(initInvoice.id)

  
  const _getPromotions = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getPromotions()
      let today = moment().startOf('date').unix()
      if (res.status === 200) {
        let newData = res.data.data.reduce((arr, item) => {
          if (item.end_date) {
            let someday = moment(item.end_date).startOf('date').unix()
            if (today < someday) {
              arr.push(item)
            }
          } else {
            arr.push(item)
          }
          return arr
        }, [])
        let newData2 = newData.reduce((arr, item) => {
          if (item.limit_quantity > 0) {
            arr.push(item)
          } else {
            if (item.voucher_quantity > 0) {
              arr.push(item)
            }
          }
          return arr
        }, [])
        setPromotions(newData2.filter((e) => !e.has_voucher && e.is_active === true))
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _getPoint = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getPoint()
      if (res.data) {
        if (res.data.data && res.data.data.length) setPoint(res.data.data[0])
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }
  const _getShippings = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getShippings({active: true})
      if (res.status === 200) setShippings(res.data.data)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const onLoadTemplatePrinter = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const response = await getConfigInvoiceSale()
      if (response.status === 200) {
        setContenrOrderPrinter(response.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (err) {
      alert(err)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }
  const _deleteInvoiceAfterCreateOrder = () => {
    const invoicesNew = [...invoices]
    invoicesNew.splice(indexInvoice, 1)
    if (invoicesNew.length === 0) {
      initInvoice.name = `00000${invoicesNew.length + 1}`
      invoicesNew.push(initInvoice)
      setActiveKeyTab(initInvoice.id)
    } else setActiveKeyTab(invoicesNew[0].id)

    setIndexInvoice(0)
    setInvoices([...invoicesNew])
  }
  const _createInvoice = () => {
    if (invoices.length > 9) {
      notification.warning({ message: t('sell.you_can_only_create_10_orders_at_most') })
      return
    }
    
    const invoicesNew = [...invoices]
    initInvoice.name = `0000${invoicesNew.length + 1}`
    invoicesNew.push(initInvoice)
    setInvoices([...invoicesNew])
    setActiveKeyTab(initInvoice.id)
    const iVoice = invoicesNew.findIndex((e) => e.id === initInvoice.id)
    if (iVoice !== -1) {
      setIndexInvoice(iVoice)
    }
  }
  const _deleteInvoice = (invoice, index) => {
    let invoicesNew = [...invoices]
      if(invoicesNew[index].payments.findIndex((payment) => payment.payment_method_id === 2 || payment.payment_method_id === 6) >= 0){
      localStorage.removeItem(invoicesNew[index]?.customer?.phone)
    }
    invoicesNew.splice(index, 1)

    if (activeKeyTab === invoice.id) {
      setIndexInvoice(0)
      setActiveKeyTab(invoicesNew[0].id)
    } else {
      const indexInvoice = invoicesNew.findIndex((e) => e.id === activeKeyTab)
      if (indexInvoice !== -1) {
        setIndexInvoice(indexInvoice)
      }
    }
    setInvoices([...invoicesNew])
  }
  const _deleteAllInvoice = () => {
    let invoicesNew = []
    initInvoice.name = `000001`
    invoicesNew.push(initInvoice)
    setActiveKeyTab(initInvoice.id)
    setInvoices([...invoicesNew])
  }
  const _addProductToCartInvoice = async (product) => {

    const ButtonsBottom = () => (
      <Row>
        <Col span={6}>
          <ModalQuantityProductInStores
            btn={t('sell.see_products_in_other_branches')}
            product={product}
          />
        </Col>
      </Row>
    )
    const productInsufficientQuantity = () =>
      notification.warning({
        key: 'warning_create_a_pre_order',
        message: (
          <div>
            <div>{t('sell.warning_create_a_pre_order')}</div>
            <ButtonsBottom />
          </div>
        ),
        style: { width: 350 },
      })

    if (product.inventory === 'advanced' && product.total_quantity === 0) {
      return notification.warning({
        key: 'warning_create_a_pre_order',
        message: (
          <div>
            <div>{t('sell.warning_create_a_pre_order')}</div>
            <ButtonsBottom />
          </div>
        ),
        style: { width: 350 },
      })
    }
    if (product.inventory === 'normal' && product.is_open === false) {
      return notification.warning({
        key: 'warning_sell_normal_product',
        message: 'Sản phẩm tồn kho cơ bản hiện tại đang không được mở bán',
      })
    }
    if (product) {
      if (!product.active) {
        notification.warning({
          message: t('sell.product_discontinued_re_open_to_continue'),
        })
        return
      }
      //check product có đủ số lượng
      // kiểm tra nếu là tồn kho dạng cơ bản thì số lượng = 0 vẫn bán được hàng
      if (product.total_quantity !== 0 || product.inventory === 'normal' || product.is_pre_order) {

        const invoicesNew = [...invoices]
        const indexProduct = invoicesNew[indexInvoice].order_details.findIndex(
          (e) => e.variant_id === product.variant_id
        )
        //nếu đã có sẵn trong cart rồi thì tăng số lượng và tổng tiền của sản phẩm đó lên
        //nếu chưa có thì push vào giỏ hàng
        // kiểm tra nếu là tồn kho dạng cơ bản thì số lượng = 0 vẫn bán được hàng
        if (indexProduct !== -1) {
          if (
            invoicesNew[indexInvoice].order_details[indexProduct].quantity <
              product.total_quantity ||
            product.inventory === 'normal'
          ) {
            if (product.quantity > 0) {
              invoicesNew[indexInvoice].order_details[indexProduct].quantity += product.quantity
            } else {
              invoicesNew[indexInvoice].order_details[indexProduct].quantity++ // tăng số lượng sp lên
            }

            invoicesNew[indexInvoice].order_details[indexProduct].sumCost =
              +invoicesNew[indexInvoice].order_details[indexProduct].quantity *
              +invoicesNew[indexInvoice].order_details[indexProduct].price

            //thuế VAT của mỗi sản phẩm
            invoicesNew[indexInvoice].order_details[indexProduct].tax_product =
              invoicesNew[indexInvoice].order_details[indexProduct]._taxes &&
              invoicesNew[indexInvoice].order_details[indexProduct]._taxes.length
                ? (
                    (invoicesNew[indexInvoice].order_details[indexProduct]._taxes.reduce(
                      (total, current) => total + current.value,
                      0
                    ) /
                      100) *
                    invoicesNew[indexInvoice].order_details[indexProduct].sumCost
                  ).toFixed(0)
                : 0
          } else
            notification.warning({
              message: `Sản phẩm ${product.title} (Có thể bán: ${product.total_quantity}), vui lòng nhập thêm hàng để tiếp tục thao tác.`,
            })
        } else {
          if (product.inventory !== 'normal') {
            if (product.quantity > 1) {
              invoicesNew[indexInvoice].order_details.push({
                quantity: product.quantity, //số lượng sản phẩm
                ...product,
                unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                sumCost: product.price * product.quantity, // tổng giá tiền
                tax_product:
                  product._taxes && product._taxes.length
                    ? (
                        (product._taxes.reduce((total, current) => total + current.value, 0) /
                          100) *
                        product.price
                      ).toFixed(0)
                    : 0,
              })
            } else {
              if (product.type_advanced === 2) {
                // let today = moment().startOf('date').format('YYYY-MM-DD')

                // let new_arr = product?.location_advanced?.location.filter((item) => item.exp >= today)
                // let new_data = sortBy(new_arr, [
                //   (o) => {
                //     return o.exp
                //   },
                // ])

                let today = moment().startOf('date').format('YYYY-MM-DD')
                let new_arr = product?.location_advanced?.location.reduce(
                  ({ sum, arr }, item) => {
                    if (item.exp >= today) {
                      sum = sum + item.quantity
                      arr.push(item)
                    }
                    return { sum, arr }
                  },
                  { sum: 0, arr: [] }
                )
                let new_data = sortBy(new_arr?.arr, [
                  (o) => {
                    return o?.exp
                  },
                ])

                if (new_data.length === 0) {
                  invoicesNew[indexInvoice].order_details.push({
                    quantity: 0, //số lượng sản phẩm
                    ...product,
                    locations_new: [],
                    unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                    sumCost: product.price, // tổng giá tiền
                    tax_product:
                      product._taxes && product._taxes.length
                        ? (
                            (product._taxes.reduce((total, current) => total + current.value, 0) /
                              100) *
                            product.price
                          ).toFixed(0)
                        : 0,
                  })
                } else {
                  let new_data_quantity = new_data.filter((item) => item.quantity !== 0)

                  let new_data_location = [
                    {
                      location_id: new_data_quantity[0]?.location_id,
                      quantity: 1,
                      code: new_data_quantity[0]?.code,
                      exp: new_data_quantity[0]?.exp,
                      mfg: new_data_quantity[0]?.mfg,
                    },
                  ]
                  invoicesNew[indexInvoice].order_details.push({
                    quantity: 1, //số lượng sản phẩm
                    ...product,
                    locations_new: product.type_advanced === 2 ? new_data_location : [],
                    unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                    sumCost: product.price, // tổng giá tiền
                    tax_product:
                      product._taxes && product._taxes.length
                        ? (
                            (product._taxes.reduce((total, current) => total + current.value, 0) /
                              100) *
                            product.price
                          ).toFixed(0)
                        : 0,
                  })
                }
              }
              if (product.type_advanced === 3) {
                invoicesNew[indexInvoice].order_details.push({
                  quantity: 0, //số lượng sản phẩm
                  ...product,
                  imei_new: [],
                  unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                  sumCost: product.price, // tổng giá tiền
                  tax_product:
                    product._taxes && product._taxes.length
                      ? (
                          (product._taxes.reduce((total, current) => total + current.value, 0) /
                            100) *
                          product.price
                        ).toFixed(0)
                      : 0,
                })
              } else {
                if (product.type_advanced === 1) {
                  invoicesNew[indexInvoice].order_details.push({
                    quantity: 1, //số lượng sản phẩm
                    ...product,
                    unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                    sumCost: product.price, // tổng giá tiền
                    tax_product:
                      product._taxes && product._taxes.length
                        ? (
                            (product._taxes.reduce((total, current) => total + current.value, 0) /
                              100) *
                            product.price
                          ).toFixed(0)
                        : 0,
                  })
                }
              }
            }
          } else {
            invoicesNew[indexInvoice].order_details.push({
              ...product,
              quantity: 1, //số lượng sản phẩm
              unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
              sumCost: product.price, // tổng giá tiền
              tax_product:
                product._taxes && product._taxes.length
                  ? (
                      (product._taxes.reduce((total, current) => total + current.value, 0) / 100) *
                      product.price
                    ).toFixed(0)
                  : 0,
            })
          }
        }

        let discount_percent = Math.round((Math.round(invoicesNew[indexInvoice].discount_invoice * 100) / 100 / invoicesNew[indexInvoice].sumCostPaid) * 100 * 100) / 100 || 0
        
        // tổng tiền của tất cả sản phẩm
        invoicesNew[indexInvoice].sumCostPaid = invoicesNew[indexInvoice].order_details.reduce(
          (total, current) => total + current.sumCost,
          0
        )
        
        // tổng giảm giá của sản phẩm
        const discount_product = invoicesNew[indexInvoice].order_details.reduce(
          (total, current) => total + (current.discount || 0) * current.quantity,
          0
        )
        //nếu có giảm giá đơn hàng trước khi sản phẩm được thêm
        if(invoicesNew[indexInvoice].total_discount - invoicesNew[indexInvoice].discount_product > 0){
        let person = window.confirm("Bạn muốn áp dụng chiết khấu, khuyến mãi và thuế cho sản phẩm vừa thêm?")
        //vẫn sử dụng giảm giá
          if (person) {
            invoicesNew[indexInvoice].discount_invoice = (discount_percent * invoicesNew[indexInvoice].sumCostPaid) / 100
            if(invoicesNew[indexInvoice].discount.type && invoicesNew[indexInvoice].discount.type == 'PERCENT'){
              invoicesNew[indexInvoice].discount.discount = parseInt(parseInt(invoicesNew[indexInvoice].discount.value) *parseInt(invoicesNew[indexInvoice].sumCostPaid || 0)) / 100
            }
            // reset giảm giá đơn hàng
          }else{
            invoicesNew[indexInvoice].discount_invoice = 0
            invoicesNew[indexInvoice].discount = {}
            invoicesNew[indexInvoice].list_tax = []
          }
        }
        //tổng giá trị thuế
        invoicesNew[indexInvoice].total_tax = invoicesNew[indexInvoice].list_tax.reduce(
          (total, current) =>
            total +
            (current.value / 100) *
              (invoicesNew[indexInvoice].sumCostPaid - invoicesNew[indexInvoice].total_discount),
          0
        )
        invoicesNew[indexInvoice].total_discount = 0
        // invoicesNew[indexInvoice].discount_invoice = 0

        invoicesNew[indexInvoice].total_discount += invoicesNew[indexInvoice].discount_invoice

        if (invoicesNew[indexInvoice].discount.discount > 0)
          invoicesNew[indexInvoice].total_discount += invoicesNew[indexInvoice].discount.discount

        invoicesNew[indexInvoice].fee_shipping = invoicesNew[indexInvoice].fee_shipping || 0

        invoicesNew[indexInvoice].moneyToBePaidByCustomer =
          invoicesNew[indexInvoice].sumCostPaid +
          invoicesNew[indexInvoice].total_tax -
          invoicesNew[indexInvoice].total_discount

        if (invoicesNew[indexInvoice].moneyToBePaidByCustomer < 0)
          invoicesNew[indexInvoice].moneyToBePaidByCustomer = 0

        invoicesNew[indexInvoice].discount_product = discount_product
        invoicesNew[indexInvoice].total_discount += invoicesNew[indexInvoice].discount_product

        //mặc định cho số tiền cần thanh toán = số tiền phải trả
        //khi có 1 phương thức thanh toán
        invoicesNew[indexInvoice].moneyGivenByCustomer =
          invoicesNew[indexInvoice].moneyToBePaidByCustomer

        if (invoicesNew[indexInvoice].list_taxSum) {
          invoicesNew[indexInvoice].moneyToBePaidByCustomer += invoicesNew[indexInvoice].list_taxSum
        }

        //tiền thừa
        const excessCashNew =
          (invoicesNew[indexInvoice].isDelivery
            ? invoicesNew[indexInvoice].prepay
            : invoicesNew[indexInvoice].moneyGivenByCustomer) -
          invoicesNew[indexInvoice].moneyToBePaidByCustomer

        invoicesNew[indexInvoice].excessCash = excessCashNew >= 0 ? excessCashNew : 0
        setInvoices([...invoicesNew])
      } else productInsufficientQuantity()
    }
  }
  const _removeMultipleProduct = (value, invoice) => {
    if (value > 0) {
      for (let i = 0; i < value; i++) {
        _removeProductToCartInvoice(0)
      }
      notification.warning({ message: 'Thay đổi chi nhánh thành công vui lòng chọn lại sản phẩm' })
    }
    if (invoice > 1) {
      invoices.splice(1, invoice)
      notification.error({ message: 'Thay đổi chi nhánh thành công, hoá đơn đã trở về mặc định' })
    }
  }
  const _removeProductToCartInvoice = (indexProduct) => {

    notification.error({
      key: 'DeleteProductData',
      message: 'Bạn vừa xoá sản phẩm vui lòng kiểm tra lại giảm giá đơn hàng!',
    })
    if (indexProduct !== -1) {

      const invoicesNew = [...invoices]

      invoicesNew[indexInvoice].order_details.splice(indexProduct, 1)
      
      let discount_percent = Math.round((Math.round(invoicesNew[indexInvoice].discount_invoice * 100) / 100 / invoicesNew[indexInvoice].sumCostPaid) * 100 * 100) / 100 || 0

      var discount_product = invoicesNew[indexInvoice].order_details.reduce(
        (total, current) => total + (current.discount || 0) * current.quantity,
        0
      )
      // tổng tiền của tất cả sản phẩm
      invoicesNew[indexInvoice].sumCostPaid = invoicesNew[indexInvoice].order_details.reduce(
        (total, current) => total + current.quantity * (current.price - (current.discount || 0)),
        0
      )
      //nếu có giảm giá đơn hàng trước khi sản phẩm được thêm
      if(invoicesNew[indexInvoice].discount_invoice > 0){
        let person = window.confirm("Bạn vẫn muốn áp dụng chiết khấu, khuyến mãi và thuế khi xóa sản phẩm?")
        //vẫn sử dụng giảm giá
          if (person) {
            invoicesNew[indexInvoice].discount_invoice = (discount_percent * invoicesNew[indexInvoice].sumCostPaid) / 100
            if(invoicesNew[indexInvoice].discount.type && invoicesNew[indexInvoice].discount.type == 'PERCENT'){
              invoicesNew[indexInvoice].discount.discount =parseInt(parseInt(invoicesNew[indexInvoice].discount.value) *parseInt(invoicesNew[indexInvoice].sumCostPaid || 0)) / 100
            }
            // reset giảm giá đơn hàng
          }else{
            invoicesNew[indexInvoice].discount_invoice = 0
            invoicesNew[indexInvoice].discount = {}
            invoicesNew[indexInvoice].list_tax = []
          }
        }
      //tổng thuế VAT của tất cả các sản phẩm
      invoicesNew[indexInvoice].total_tax = invoicesNew[indexInvoice].order_details.reduce(
        (total, current) => total + +current.tax_product,
        0
      )
      //tổng giá trị ds thuế
      invoicesNew[indexInvoice].list_taxSum = invoicesNew[indexInvoice].list_tax.reduce(
        (total, current) => total + (current.value / 100) * invoicesNew[indexInvoice].sumCostPaid,
        0
      )

      invoicesNew[indexInvoice].fee_shipping = invoicesNew[indexInvoice].fee_shipping || 0
      invoicesNew[indexInvoice].total_discount = invoicesNew[indexInvoice].total_discount || 0

      // giảm giá từ khuyến mãi

      invoicesNew[indexInvoice].total_discount += invoicesNew[indexInvoice].discount.discount

      // giảm giá trên sản phẩm
      //tổng tiền khách hàng phải trả
      invoicesNew[indexInvoice].moneyToBePaidByCustomer =
        invoicesNew[indexInvoice].sumCostPaid + invoicesNew[indexInvoice].total_tax

      if (invoicesNew[indexInvoice].moneyToBePaidByCustomer < 0)
        invoicesNew[indexInvoice].moneyToBePaidByCustomer = 0

      invoicesNew[indexInvoice].discount_product = discount_product
      invoicesNew[indexInvoice].total_discount += discount_product

      //mặc định cho số tiền cần thanh toán = số tiền phải trả
      //khi có 1 phương thức thanh toán
      invoicesNew[indexInvoice].moneyGivenByCustomer =
        invoicesNew[indexInvoice].moneyToBePaidByCustomer

      //tiền thừa
      const excessCashNew =
        (invoicesNew[indexInvoice].isDelivery
          ? invoicesNew[indexInvoice].prepay
          : invoicesNew[indexInvoice].moneyGivenByCustomer) -
        invoicesNew[indexInvoice].moneyToBePaidByCustomer

      invoicesNew[indexInvoice].excessCash = excessCashNew >= 0 ? excessCashNew : 0
      setInvoices([...invoicesNew])
    }
  }
  const _editInvoice = (attribute, value) => {
    const invoicesNew = [...invoices]
    invoicesNew[indexInvoice][attribute] = value

    // tổng tiền của tất cả sản phẩm
    invoicesNew[indexInvoice].sumCostPaid = invoicesNew[indexInvoice].order_details.reduce(
      (total, current) => total + current.quantity * (current.price - (current.discount || 0)),
      0
    )

    // tổng giảm giá của tất cả sản phẩm
    var discount_product = invoicesNew[indexInvoice].order_details.reduce(
      (total, current) => total + (current.discount || 0) * current.quantity,
      0
    )

    //tổng thuế VAT của tất cả sản phẩm
    invoicesNew[indexInvoice].total_tax = invoicesNew[indexInvoice].order_details.reduce(
      (total, current) => total + +current.tax_product,
      0
    )

    invoicesNew[indexInvoice].fee_shipping = invoicesNew[indexInvoice].fee_shipping || 0

    //discount có 2 loại
    //nếu type = value thì cộng
    // nếu type = percent thì nhân

    invoicesNew[indexInvoice].total_discount = 0

    if (invoicesNew[indexInvoice].discount.discount > 0) {
      invoicesNew[indexInvoice].total_discount += invoicesNew[indexInvoice].discount.discount
    }
    invoicesNew[indexInvoice].total_discount += invoicesNew[indexInvoice].discount_invoice

    invoicesNew[indexInvoice].total_tax = invoicesNew[indexInvoice].list_tax.reduce(
      (total, current) =>
        total +
        (current.value / 100) *
          (invoicesNew[indexInvoice].sumCostPaid - invoicesNew[indexInvoice].total_discount),
      0
    )
    //tổng tiền khách hàng phải trả
    invoicesNew[indexInvoice].moneyToBePaidByCustomer =
      invoicesNew[indexInvoice].sumCostPaid +
      invoicesNew[indexInvoice].total_tax -
      invoicesNew[indexInvoice].total_discount
    if(invoicesNew[indexInvoice].isDelivery && !invoicesNew[indexInvoice].payer_shipping){
      invoicesNew[indexInvoice].moneyToBePaidByCustomer += invoicesNew[indexInvoice].fee_shipping
    }
    //tổng tiền khách trả
    invoicesNew[indexInvoice].total_discount += discount_product

    if (invoicesNew[indexInvoice].moneyToBePaidByCustomer < 0)
      invoicesNew[indexInvoice].moneyToBePaidByCustomer = 0

    invoicesNew[indexInvoice].discount_product = discount_product

    if (!invoicesNew[indexInvoice].isDelivery && attribute === 'payments') {
      const sumCostPaid = value.reduce((total, current) => total + current.value, 0)
      const excessCash = sumCostPaid - invoicesNew[indexInvoice].moneyToBePaidByCustomer
      invoicesNew[indexInvoice].excessCash = excessCash >= 0 ? excessCash : 0
    }

    if (attribute !== 'moneyGivenByCustomer') {
      invoicesNew[indexInvoice].moneyGivenByCustomer =
        invoicesNew[indexInvoice].sumCostPaid +
        invoicesNew[indexInvoice].total_tax -
        invoicesNew[indexInvoice].total_discount
    }

    //mặc định cho số tiền cần thanh toán = số tiền phải trả
    //khi có 1 phương thức thanh toán
    invoicesNew[indexInvoice].moneyGivenByCustomer =
      invoicesNew[indexInvoice].moneyToBePaidByCustomer
    setInvoices([...invoicesNew])
  }
  const _editProductInInvoices = (attribute, value, index, indexInvoice) => {
    if (index !== -1) {
      const invoicesNew = JSON.parse(JSON.stringify(invoices))
      const oldValue = invoicesNew[indexInvoice].order_details[index][attribute]
      if (oldValue == value) {
        return
      }
      invoicesNew[indexInvoice].order_details[index][attribute] = value
      if (attribute === 'imei_new')
        invoicesNew[indexInvoice].order_details[index].quantity = value.length
      if (attribute === 'locations_new')
        invoicesNew[indexInvoice].order_details[index].quantity = value.reduce(
          (total, item) => (total += item.quantity),
          0
        )
      if (invoicesNew[indexInvoice].order_details[index].quantity <= 0) {
        if (
          invoicesNew[indexInvoice].order_details[index].type_advanced === 1 ||
          invoicesNew[indexInvoice].order_details[index].type_advanced === 0
        ) {
          invoicesNew[indexInvoice].order_details[index].quantity = 1
        } else {
          invoicesNew[indexInvoice].order_details[index].quantity = 0
        }
      }

      //tổng tiền của 1 sản phẩm
      invoicesNew[indexInvoice].order_details[index].sumCost =
        +invoicesNew[indexInvoice].order_details[index].quantity *
          +invoicesNew[indexInvoice].order_details[index].price -
        (invoicesNew[indexInvoice].order_details[index].discount *
          invoicesNew[indexInvoice].order_details[index].quantity || 0)

      if (invoicesNew[indexInvoice].order_details[index].sumCost < 0)
        invoicesNew[indexInvoice].order_details[index].sumCost = 0
      //thuế VAT của mỗi sản phẩm
      invoicesNew[indexInvoice].order_details[index].tax_product =
        invoicesNew[indexInvoice].order_details[index]._taxes &&
        invoicesNew[indexInvoice].order_details[index]._taxes.length
          ? (
              (invoicesNew[indexInvoice].order_details[index]._taxes.reduce(
                (total, current) => total + current.value,
                0
              ) /
                100) *
              invoicesNew[indexInvoice].order_details[index].sumCost
            ).toFixed(0)
          : 0
      let discount_percent = Math.round((Math.round(invoicesNew[indexInvoice].discount_invoice * 100) / 100 / invoicesNew[indexInvoice].sumCostPaid) * 100 * 100) / 100 || 0

      // tổng tiền của tất cả sản phẩm
      invoicesNew[indexInvoice].sumCostPaid = invoicesNew[indexInvoice].order_details.reduce(
        (total, current) => total + (current.price - (current.discount || 0)) * current.quantity,
        0
      )
      var discount_product = 0
      invoicesNew[indexInvoice].order_details.map((product_detail) => {
        discount_product += (product_detail.discount || 0) * product_detail.quantity
      })

    //nếu có giảm giá đơn hàng trước khi sản phẩm được thêm
    if(invoicesNew[indexInvoice].total_discount - invoicesNew[indexInvoice].discount_product > 0){
      let person = window.confirm("Bạn muốn áp dụng chiết khấu, khuyến mãi và thuế cho sản phẩm vừa được chỉnh sửa?")
      //vẫn sử dụng giảm giá
        if (person) {
          invoicesNew[indexInvoice].discount_invoice = (discount_percent * invoicesNew[indexInvoice].sumCostPaid) / 100
          if(invoicesNew[indexInvoice].discount.type && invoicesNew[indexInvoice].discount.type == 'PERCENT'){
            invoicesNew[indexInvoice].discount.discount =parseInt(parseInt(invoicesNew[indexInvoice].discount.value) *parseInt(invoicesNew[indexInvoice].sumCostPaid || 0)) / 100
          }
          // reset giảm giá đơn hàng
        }else{
          invoicesNew[indexInvoice].discount_invoice = 0
          invoicesNew[indexInvoice].discount = {}
          invoicesNew[indexInvoice].list_tax = []
        }
      }
      invoicesNew[indexInvoice].fee_shipping = invoicesNew[indexInvoice].fee_shipping || 0

      // Cộng discount sản phẩm vào discount đơn hàng
      invoicesNew[indexInvoice].total_discount += discount_product

      if (invoicesNew[indexInvoice].discount.discount > 0)
        invoicesNew[indexInvoice].total_discount += invoicesNew[indexInvoice].discount.discount
      //tổng thuế VAT của tất cả các sản phẩm
      invoicesNew[indexInvoice].total_tax = invoicesNew[indexInvoice].list_tax.reduce(
        (total, current) =>
          total +
          (current.value / 100) *
            (invoicesNew[indexInvoice].sumCostPaid - invoicesNew[indexInvoice].total_discount),
        0
      )
      //tổng tiền khách hàng phải trả
      invoicesNew[indexInvoice].moneyToBePaidByCustomer =
        invoicesNew[indexInvoice].sumCostPaid + invoicesNew[indexInvoice].total_tax

      if (invoicesNew[indexInvoice].moneyToBePaidByCustomer < 0)
        invoicesNew[indexInvoice].moneyToBePaidByCustomer = 0

      invoicesNew[indexInvoice].discount_product = discount_product
      invoicesNew[indexInvoice].moneyToBePaidByCustomer +=
        invoicesNew[indexInvoice].list_taxSum || 0

      //mặc định cho số tiền cần thanh toán = số tiền phải trả
      invoicesNew[indexInvoice].moneyGivenByCustomer =
        invoicesNew[indexInvoice].moneyToBePaidByCustomer
      //tiền thừa
      const excessCashNew =
        (invoicesNew[indexInvoice].isDelivery
          ? invoicesNew[indexInvoice].prepay
          : invoicesNew[indexInvoice].moneyGivenByCustomer) -
        invoicesNew[indexInvoice].moneyToBePaidByCustomer

      invoicesNew[indexInvoice].excessCash = excessCashNew >= 0 ? excessCashNew : 0
      setInvoices([...invoicesNew])
    }
  }
  const ModalQuantityProductInStores = ({ product, btn }) => {
    const toggle = () => setVisible(!visible)
    const [visible, setVisible] = useState(false)
    const [productAnotherbranch, setProductAnotherBranch] = useState([])

    const _getProductAnotherBranch = async () => {
      try {
        dispatch({ type: ACTION.LOADING, data: true })
        const res = await getProductAllBranch({ variant_id: product.variant_id })
        if (res.status === 200) {
          setProductAnotherBranch(res.data.data)
        }
        dispatch({ type: ACTION.LOADING, data: false })
      } catch (e) {
        dispatch({ type: ACTION.LOADING, data: false })
        console.log(e)
      }
    }

    const column = [
      {
        title: t('sell.branch'),
        dataIndex: 'name',
        render: (text, record) => record.name,
      },
      {
        title: t('sell.quantity'),
        render: (text, record) => formatCash(record.quantity || 0),
      },
    ]

    const content = (
      <div>
        <Row justify="space-between">
          <span>{t('sell.price')}</span>
          <span>{formatCash(product ? product.price : 0)}</span>
        </Row>
        <Row justify="space-between">
          <span>{t('sell.can_be_sold')}</span>
          {product && product.inventory === 'normal' ? (
            <span>{product.is_open === false ? 'Hết hàng' : 'Còn hàng'}</span>
          ) : (
            <span>{formatCash(product ? product.total_quantity : 0)}</span>
          )}
        </Row>
      </div>
    )

    useEffect(() => {
      if (btn) _getProductAnotherBranch()
    }, [visible])

    return (
      <div onClick={(e) => e.stopPropagation()}>
        {btn ? (
          <Button
            type="primary"
            onClick={toggle}
            style={{
              cursor: 'pointer',
            }}
          >
            {btn}
          </Button>
        ) : (
          <Popover
            content={content}
            placement="bottom"
            title={
              <Row
                wrap={false}
                justify="space-between"
                align="middle"
                style={{ maxWidth: 450, minWidth: 250 }}
              >
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': '1',
                    '-webkit-box-orient': 'vertical',
                    display: '-webkit-box',
                    marginRight: '10px',
                  }}
                >
                  {product && product.title}
                </p>
              </Row>
            }
          >
            <ExclamationCircleOutlined
              style={{ color: '#1991FF', fontSize: 12, cursor: 'pointer', marginLeft: 6 }}
            />
          </Popover>
        )}

        <Modal
          width={700}
          footer={
            <Row justify="end">
              <Button onClick={toggle}>{t('common.close')}</Button>
            </Row>
          }
          visible={visible}
          onCancel={toggle}
          title={product && product.title}
        >
          <Table
            pagination={false}
            style={{ width: '100%' }}
            columns={column}
            size="small"
            dataSource={productAnotherbranch}
          />
        </Modal>
      </div>
    )
  }
  const ModalDiscountInvoice = ({ invoices, isBranchClosed }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [discount, setDiscount] = useState(invoices.discount_invoice || '')
    const [discount1, setDiscount1] = useState(
      ((invoices.discount_invoice / invoices.sumCostPaid) * 100).toFixed(2)
    )

    const setDataDiscount = (check, value) => {
      // const valueNew = value.replaceAll(',', '').replaceAll('%', '')
      if (check == true) {
        setDiscount1(
          Math.round((Math.round(value * 100) / 100 / invoices.sumCostPaid) * 100 * 100) / 100
        )
        setDiscount(Math.round(value * 100) / 100)
      } else {
        setDiscount1(Math.round(value * 100) / 100)
        setDiscount((value * invoices.sumCostPaid) / 100)
      }
    }

    return (
      <>
        <div
          onClick={toggle}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: 'gray',
            cursor: 'pointer',
            marginBottom: 'auto',
            marginTop: 'auto',
            // marginRight: '58%',
            position: 'absolute',
            marginLeft: 134,
          }}
        >
          <a>Thêm</a>
        </div>
        <Modal
          title="Chiết khấu đơn hàng"
          onCancel={() => {
            setDiscount(invoices.discount_invoice || '')
          }}
          visible={visible}
          closable={false}
          footer={[
            discount >= 0 && discount <= invoices.sumCostPaid - invoices.discount_product ? (
              <>
                <Button
                  onClick={() => {
                    toggle()
                    setDiscount(invoices.discount_invoice || '')
                  }}
                  style={{
                    width: 100,
                    borderRadius: 5,
                  }}
                >
                  Đóng
                </Button>
                <Button
                  disabled={isBranchClosed}
                  key="cancel"
                  onClick={() => {
                    _editInvoice('discount_invoice', discount)
                  }}
                  style={{
                    cursor: `${isBranchClosed} ? 'not-allowed' ? 'pointer'`,
                    width: 100,
                    borderColor: '#3579FE',
                    borderRadius: 5,
                  }}
                >
                  Áp dụng
                </Button>
              </>
            ) : (
              <span style={{ color: 'red' }}>
                Chiết khấu không được vượt quá giá trị đơn hàng và không nhỏ hơn 0
              </span>
            ),
          ]}
        >
          <Row>
            <Col span={8}>
              <Text>Giá chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                onChange={(value) => {
                  setDataDiscount(true, value)
                }}
                defaultValue={invoices.discount_invoice || 0}
                min={0}
                value={discount}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={8}>
              <Text>Phần trăm chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                onChange={(value) => {
                  setDataDiscount(false, value)
                }}
                defaultValue={
                  Math.round(
                    (invoices.discount_invoice /
                      (invoices.sumCostPaid - invoices.discount_product)) *
                      100 *
                      100
                  ) / 100
                }
                value={discount1}
                min={0}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
        </Modal>
      </>
    )
  }

  const ModalAddCustomer = () => {
    return (
      <>
        <Permission permissions={[PERMISSIONS.ban_hang]}>
          <Tooltip placement="bottom" title={t('sell.add_new_customer')}>
            <Button
              disabled={isBranchClosed()}
              onClick={toggleCustomer}
              icon={
                <PlusSquareFilled
                  disabled={isBranchClosed()}
                  style={{ color: '#0362BA', fontSize: 34 }}
                />
              }
              style={{
                padding: 0,
                border: 'none',
                cursor: `${isBranchClosed() ? 'not-allowed' : 'pointer'}`,
              }}
            />
          </Tooltip>
        </Permission>
        <Modal
          centered
          width={800}
          footer={null}
          closable={false}
          title={
            <Row justify="space-between">
              <Col>{t('sell.add_new_customers')}</Col>
              <Col>
                <CloseOutlined
                  onClick={() => {
                    toggleCustomer()
                  }}
                />
              </Col>
            </Row>
          }
          visible={visibleCreateCustomer}
        >
          <CustomerForm
            close={toggleCustomer}
            text={t('common.more')}
            reload={_getCustomers}
            editInvoice={_editInvoice}
            phone={inputRef.current}
          />
        </Modal>
      </>
    )
  }
  
  const HandlerKeyboard = () => {
    return (
      <KeyboardEventHandler
        handleFocusableElements={true}
        handleKeys={['f1', 'f2', 'f3', 'f4', 'f6', 'f8', 'f9', 'f10', 'space', 'esc']}
        onKeyEvent={(key) => {
          switch (key) {
            case 'f2': {
              _validatedCreateOrderOrPay()
              break
            }
            case 'f4': {
              if (visibleProductDetail === true) {
                setVisibleProductDetail(false)
                setVisibleProductDetail(true)
              } else {
                setVisibleProductDetail(true)
              }
              break
            }
            case 'f6': {
              toggleModalPromotion()
              break
            }
            case 'f8': {
              setVisiblePayments(true)
              break
            }
            case 'f9': {
              _createInvoice()
              break
            }
            case 'f10': {
              setVisibleCreateCustomer(true)
              break
            }
            case 'space': {
              break
            }
            case 'esc': {
              setVisiblePayments(false)
              setVisibleCreateCustomer(false)
              setVisibleConfirmCreateOrder(false)
              setVisibleUpdateCustomer(false)
              break
            }
            default:
              break
          }
        }}
      />
    )
  }

  const ModalConfirmCreateOrderOrPay = () => {
    return (
      <Modal
        onOk={(e) => {
          setVisibleConfirmCreateOrder(false)
          _createOrder(200)
        }}
        cancelText={t('common.exit')}
        okText={t('common.agree')}
        visible={visibleConfirmCreateOrder}
        onCancel={() => setVisibleConfirmCreateOrder(false)}
        title={t('sell.insufficient_payment_amount')}
      >
        {t('sell.warning_the_amount')}
      </Modal>
    )
  }

  // Kiểm tra số tiền khách hàng thanh toán đã bằng với số tiền đơn hàng chưa
  const onCheckPaymentValid = (payments) => {
    var value_payment = 0
    if (payments.map !== undefined) {
      payments.map((item) => {
        value_payment += item.value
      })
      if (value_payment >= invoices[indexInvoice].moneyToBePaidByCustomer) {
        setAvailableOrder(true)
      } else {
        setAvailableOrder(false)
      }
    }
  }
  const _validatedBeforeCreateOrderOrPay = () => {
    if (invoices[indexInvoice].isDelivery && !invoices[indexInvoice].customer) {
      notification.warning({ message: t('sell.please_add_customers_to_use_the_delivery_service') })
      return false
    }

    if (invoices[indexInvoice].order_details.length === 0) {
      notification.warning({ message: t('sell.please_select_a_product_in_your_order') })
      return false
    }

    if (invoices[indexInvoice].payments.length === 0) {
      notification.warning({ message: t('sell.please_choose_a_payment_method') })
      return false
    }
    let index = invoices[indexInvoice].payments.findIndex((e) => e.payment_method_id === 6)
    if (index !== -1) {
      if (invoices[indexInvoice].customer == undefined) {
        notification.warning({ message: 'Yêu cầu thông tin khách hàng đầy đủ' })
        return false
      } else {
        return true
      }
    }

    return true
  }

  const toggleModalPromotion = () => setVisiblePromotion(!isVisiblePromotion)

  const _validatedCreateOrderOrPay = () => {
    const isValidated = _validatedBeforeCreateOrderOrPay()
    if (isValidated === false) {
      return
    } else {
      for (let i in invoices[indexInvoice].payments) {
        if (!invoices[indexInvoice].payments[i].value) {
          invoices[indexInvoice].payments.splice(i, 1)
        }
      }
      for (let i in invoices[indexInvoice].paymentRefund) {
        if (!invoices[indexInvoice].paymentRefund[i].value) {
          invoices[indexInvoice].paymentRefund.splice(i, 1)
        }
      }
      let index = invoices[indexInvoice].payments.findIndex((e) => e.payment_method_id === 6)
      if(index === -1){
        _createOrder(200)
      }
    }
  }
  const _createOrder = async (int) => {
    try {
      let shipping = {}
      if (invoices[indexInvoice].isDelivery) {
        if(!invoices[indexInvoice].shipping_company_id){
          return notification.error({
            key:'shipping_company',
            message: 'Bạn vui lòng chọn đơn vị vận chuyển!'
          })
        }
        if (
          !invoices[indexInvoice].shipping_info.address
          // !invoices[indexInvoice].shipping_info.ward_code ||
          // !invoices[indexInvoice].shipping_info.district_id ||
          // !invoices[indexInvoice].shipping_info.province_id
        ) {
          return notification.error({
            key:'address',
            message: 'Bạn vui lòng thêm địa chỉ!'
              // 'Bạn vui lòng thêm địa chỉ, Tỉnh thành, Quận/huyện, Phường/xã của khách hàng để sử dụng dịch vụ giao hàng',
          })
        }
        if(invoices[indexInvoice].shipping_company_id !== 1){
          if(!invoices[indexInvoice].tracking_number || invoices[indexInvoice].tracking_number.trim() == ''){
            return notification.error({
              key: 'tracking_number',
              message: 'Bạn vui lòng nhập mã vận đơn!'
            })
          }
        }
      }
      const checkPoint = invoices[indexInvoice].payments.find(
        (item) => item.payment_method_id === 2
      )

      if (!invoices[indexInvoice].customer) {
        invoices[indexInvoice].customer = customers.find((item) => item.customer_id === 1)
      }
      if (invoices[indexInvoice].isDelivery)
        shipping.shipping_info = {
          tracking_number: invoices[indexInvoice].tracking_number ? invoices[indexInvoice].tracking_number.trim() : '',
          first_name: invoices[indexInvoice].shipping_info.first_name || '',
          last_name: invoices[indexInvoice].shipping_info.last_name || '',
          phone: invoices[indexInvoice].shipping_info.phone || '',
          address: invoices[indexInvoice].shipping_info.address || 1,
          cod: 0,
          fee_shipping: invoices[indexInvoice].fee_shipping || 0,
          fee_warehouse: invoices[indexInvoice].fee_warehouse || 0,
          fee_insurance: invoices[indexInvoice].fee_insurance || 0,
          delivery_time: moment().add(4, 'days').format(),
          complete_time: moment().add(7, 'days').format(),
        }

      var total_value_tax = 0
      invoices[indexInvoice].list_tax.map((tax) => {
        total_value_tax += tax.value
      })

      var total_cost = invoices[indexInvoice].order_details.reduce(
        (total, current) => total + current.sumCost,
        0
      )
      for (let product of invoices[indexInvoice].order_details) {
        if (product.type_advanced === 2 && product?.locations_new?.length > 0) {
          let quantity = product?.locations_new?.reduce(
            (total, item) => (total += item.quantity),
            0
          )
          // if (product.quantity > quantity) {
          //   return notification.error({
          //     message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          //   })
          // }
          if (product.quantity < quantity) {
            return notification.error({
              message: `Số lượng bán đang không bằng số lượng lô được nhập. Vui lòng sửa số lượng của sản phẩm ${product.title}`,
            })
          }
        }
        if (product.type_advanced === 2) {
          if (
            !product.locations_new ||
            product?.locations_new?.length === 0 ||
            product.quantity === 0
          ) {
            return notification.error({
              message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
            })
          }
        }
      }
      for (let product of invoices[indexInvoice].order_details) {
        if (product.type_advanced === 3 && product?.imei_new?.length > 0) {
          if (product.quantity > product?.imei_new?.length) {
            return notification.error({
              message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
            })
          }
          if (product.quantity < product?.imei_new?.length) {
            return notification.error({
              message: `Số lượng bán đang không bằng số lượng imei được chọn. Vui lòng sửa số lượng của sản phẩm ${product.title}`,
            })
          }
        }
        if (product.type_advanced === 3 && !product.imei_new) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 3 && product?.imei_new?.length === 0) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
      }
      dispatch({ type: ACTION.LOADING, data: true })
      let valueDebt = 0
      if(int === 100){
        valueDebt = Object(
          invoices[indexInvoice].payments.find((e) => e.payment_method_id === 6)
        ).value
      }
      
      const body = {
        ...shipping,
        branch_id: infoBranch.branch_id,
        sale_location: { branch_id: infoBranch.branch_id || '' },
        customer_id: invoices[indexInvoice].customer?.customer_id
          ? invoices[indexInvoice].customer.customer_id
          : invoices[indexInvoice].customer.phone,
        employee_id: dataUser ? dataUser.user_id || '' : '',
        order_details: invoices[indexInvoice].order_details.map((product, index) => ({
          product_id: product.product_id || '',
          variant_id: product.variant_id || '',
          quantity: product.quantity || 0,
          price: product.price || 0,
          total_cost: product.sumCost || invoices[indexInvoice].discount_product,
          discount: product.discount || 0,
          final_cost: invoices[indexInvoice].discount_product - product.discount || 0,
          note: product.note || '',
          type_advanced: product.type_advanced || 1,
          location: product.type_advanced === 2 ? product.locations_new : null,
          imei: product.type_advanced === 3 ? product.imei_new : null,
        })),
        order_type: invoices[indexInvoice].order_type ,
        payer_shipping: invoices[indexInvoice].payer_shipping,
        refund_from_order_id: invoices[indexInvoice]?.order_details[0]?.refund_from_order_id,
        is_refund: invoices[indexInvoice]?.order_details[0]?.status === 'refund',
        payments: invoices[indexInvoice].payments,
        paymentRefund: invoices[indexInvoice].paymentRefund,
        is_auto_create_shipping: isAutoTrackingNumber,
        voucher: invoices[indexInvoice].discount ? invoices[indexInvoice].discount.name || '' : '',
        promotion_id: invoices[indexInvoice].discount
          ? invoices[indexInvoice].discount.promotion_id || ''
          : '',
        // total_cost: invoices[indexInvoice].sumCostPaid || 0,
        total_cost:
          invoices[indexInvoice].sumCostPaid -
          (invoices[indexInvoice].discount_invoice || 0) -
          (invoices[indexInvoice]?.discount?.discount || 0),
        discount_product: invoices[indexInvoice].discount_product,
        discount_invoice: invoices[indexInvoice].discount_invoice,
        fee_shipping: invoices[indexInvoice].fee_shipping || 0,
        total_payment: invoices[indexInvoice].sumCostPaid || 0, // thành tiền tất cả sản phẩm
        total_promotion: invoices[indexInvoice].discount.discount || 0, // tổng khuyến mãi
        total_tax:
          (total_value_tax / 100) *
            (invoices[indexInvoice].sumCostPaid -
              invoices[indexInvoice].total_discount +
              invoices[indexInvoice].discount_product) || 0,
        total_discount: invoices[indexInvoice].total_discount || 0,
        final_cost: invoices[indexInvoice].moneyToBePaidByCustomer || 0,
        shipping_company_id: invoices[indexInvoice].shipping_company_id ,
        customer_paid: invoices[indexInvoice].isDelivery
          ? invoices[indexInvoice].prepay - valueDebt || 0
          : invoices[indexInvoice].moneyGivenByCustomer - valueDebt || 0,
        customer_debt: valueDebt|| 0,
        bill_status: invoices[indexInvoice].order_details.find(
          (product) => product.total_quantity === -1
        )
          ? 'PRE-ORDER'
          : 'COMPLETE', // nếu trong đơn hàng có sản phẩm bị hết sl -> đơn hàng bán trước
        note: invoices[indexInvoice].noteInvoice || '',
        tags: [],
        channel: invoices[indexInvoice].salesChannel || 'POS',
        is_delivery: invoices[indexInvoice].isDelivery,
        ship_status: invoices[indexInvoice].isDelivery === true ? 'COMPLETE' : 'DRAFT',

        list_taxSum: invoices[indexInvoice].list_taxSum,
        list_tax: invoices[indexInvoice].list_tax,
        excessCash: invoices[indexInvoice].excessCash,
        status: int,
      }
      //encrypt body create order
      // const bodyEncryption = encryptText(JSON.stringify(body))
      // Dùng điểm để thanh toán
      if (
        checkPoint?.payment_method_id === 2 &&
        checkPoint?.value / point.exchange_money_rate > invoices[indexInvoice].customer.point
      ) {
        notification.warning({ message: 'Số điểm của khách hàng không đủ thanh toán!' })
      } else {
        let res
        if(!location.state){
          if(!invoices[indexInvoice].order_id){
            res = await addOrder(body)
          }else{
            res = await updateStatusCheckOtp(body, invoices[indexInvoice].order_id)
          }
        }else res = await updateStatusCheckOtp(body, location.state.order_id)
        if (res.status === 200) {
          if (res.data.success) {
            dispatch({ type: 'SELL_CHANGE_VARIANT', data: body.order_details })
            // _getProductsRelated()
            _getCustomers()
            _getPromotions()
            _editInvoice('code', res.data.data.code || '')
            _editInvoice('order_id', res.data.data.order_id || '')
            setOrderSeletedPrint(res.data.data)
            // socketListener()
            if(int !== 100){
              _deleteInvoiceAfterCreateOrder()
              handlePrint()
            }
            return true
          } else{
             notification.error({
              message: res.data.message || t('sell.order_creation_failed_please_try_again'),
            })
            dispatch({ type: ACTION.LOADING, data: false })
            return false
          }
        } else
          notification.error({
            message: res.data.message || t('sell.order_creation_failed_please_try_again'),
          })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _getBranches = async () => {
    dispatch({ type: ACTION.LOADING, data: true })
    try {
      const res = await getAllBranch()
      if (res.status === 200) setBranches(res.data.data)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getCustomers = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      setLoadingCustomer(true)
      const res = await getCustomers()
      const res2 = await getAllUserSystem({ sell: 1 })
      if (res.status === 200) {
        setCustomers(res.data.data)
        setsystemCustomers(res.data.data)
        setAnotherCustomers(res2.data.data)
        //mặc định chọn khách lẻ
        const customer = res.data.data.find((e) => e.customer_id === 1)
        if (customer && !invoices[indexInvoice].customer && !location.state) {
          _editInvoice('shipping_info', customer)
          _editInvoice('customer', customer)
          _editInvoice('name', `${customer.first_name} ${customer.last_name}`)
        }
      }
      setLoadingCustomer(false)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      setLoadingCustomer(false)
      console.log(error)
    }
  }

  const _getPayments = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getPayments()
      if (res.status === 200) {
        let paymentMethodDefault = ''
        res.data.data.map((e) => {
          if (e.default && e.active) paymentMethodDefault = e
        })
        if(!location.state){
          if (paymentMethodDefault) {
            const pDefault = {
              name: paymentMethodDefault.name,
              value: invoices[indexInvoice].moneyToBePaidByCustomer,
              payment_method_id: paymentMethodDefault.payment_method_id,
            }
            const pDefault2 = {
              name: paymentMethodDefault.name,
              value: 0,
              payment_method_id: paymentMethodDefault.payment_method_id,
            }
            _editInvoice('payments', [pDefault])
            _editInvoice('paymentRefund', [pDefault2])
            setPaymentMethodDefault(pDefault)
          } else {
            const dataActive = res.data.data
              .filter((e) => e.active)
              .map((e) => {
                return e
              })
            _editInvoice('payments', [
              {
                name: dataActive[0].name,
                value: invoices[indexInvoice].moneyToBePaidByCustomer,
                payment_method_id: dataActive[0].payment_method_id,
              },
            ])
            _editInvoice('paymentRefund', [
              {
                name: dataActive[0].name,
                value: 0,
                payment_method_id: dataActive[0].payment_method_id,
              },
            ])
            setPaymentMethodDefault({
              name: dataActive[0].name,
              value: 0,
              payment_method_id: dataActive[0].payment_method_id,
            })
          }
        }
        
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })

      console.log(error)
    }
  }

  const _getTaxList = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getTaxs()
      if (res.status === 200) {
        setTaxList(res.data.data)
        if (invoices[indexInvoice].list_tax.length === 0 && !location.state) {
          const taxDefault = res.data.data.find((e) => e.default)
          _editInvoice('list_tax', taxDefault ? [taxDefault] : [])
        }
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const addUserToCustomer = async (is_exists, values) => {
    try {
      if (!validatePhone(values.phone)) {
        notification.warning({ message: 'Vui lòng nhập số điện thoại đúng định dạng' })
        return
      }
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        ...values,
        branch_id: branchIdApp,
        first_name: values.first_name || '',
        last_name: values.last_name || '',
        phone: values.phone,
        email: values.email || '',
        birthday: values.birthday ? new Date(values.birthday).toString() : null,
        address: values.address || '',
        balance: [],
      }
      let res
      if (is_exists == true) {
        let customer_id = systemCustomers
          .filter((item) => item.phone == values.phone)
          .map((item) => item.customer_id)
        res = await updateCustomer(customer_id, body)
      } else {
        res = await addCustomer({ ...body, active: 1 })
      }
      if (res.status === 200) {
        if (res.data.success) {
          _getCustomers()
          if (is_exists == true) {
            notification.success({ message: `Cập nhật khách hàng thành công` })
          } else {
            notification.success({ message: `Thêm khách hàng thành công` })
          }
        } else {
          if (is_exists == true) {
            notification.error({
              message: res.data.message || `cập nhật khách hàng thất bại, vui lòng thử lại!`,
            })
          } else {
            notification.error({
              message: res.data.message || `Thêm khách hàng thất bại, vui lòng thử lại!`,
            })
          }
        }
      } else
        notification.error({
          message: res.data.message || `Hệ thống khách hàng đang lỗi, vui lòng thử lại!`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  // const socketListener = async () => {
  //   await socketListener(dataUser, anotherCustomers, branchIdApp, variants, dispatch)
  // }
  const socketListenerPoint = () => {
    socket.on(`point:setting:${dataUser._business.business_id}`, _getPoint())
  }
  const socketListenerCustomer = () => {
    socket.on(`customer:update:${dataUser._business.business_id}`, _getCustomers())
  }
  const socketListenerTransport = async () => {
    await socketTransport(dataUser, anotherCustomers, branchIdApp, variants, dispatch)
  }
  const socketListenerInventoryNote = async () => {
    await socketInventoryNote(dataUser, anotherCustomers, branchIdApp, variants, dispatch)
  }
  //lưu invoice lên reducer mỗi khi có sự thay đổi
  useEffect(() => {
    if (invoices) {
      dispatch({ type: 'UPDATE_INVOICE', data: invoices })
      // dispatch(
      //   socketInvoiceBusiness({
      //     data: invoices,
      //     key_invoice: indexInvoice,
      //     auth: {
      //       business_id: dataUser._business.business_id,
      //       user_id: dataUser.user_id,
      //       branch_id: branchIdApp,
      //     },
      //     socket: socketReducer,
      //   })
      // )
    }
  }, [dispatch, invoices])

  useEffect(() => {
    if (branches.length) {
      const branch = branches.find((branch) => branch.branch_id === branchIdApp)
      if (branch) setInfoBranch(branch)
      else setInfoBranch(branches[0] && branches[0])
    }
  }, [branchIdApp, branches])

  const changeProductOfBranch = async () => {
    try {
      if (loadingProduct == false && variants.length == 0) {
        setLoadingProduct(true)
        const res = await getVariantsSell({ branch_id: branchIdApp })
        if (res.status === 200) {
          dispatch({
            type: 'SET_VARIANTSELL',
            data: {
              variants: res.data.data,
              counts: res.data.count,
            },
          })
        }
        setLoadingProduct(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  const socketListenerUpdateProduct = async () => {
    socketProductUpdate(dataUser, anotherCustomers, branchIdApp, dispatch)
  }
  useEffect(() => {
    if(location.state){
      setTimeout(() =>{
        let invoicesNew = []
        let discount_product = 0
        let list_product = location.state.order_details.reduce((arr, current) => {
          let obj = {
            active: current.variant_info.active || true,
            barcode: current.variant_info.barcode,
            bulk_prices: current.variant_info.bulk_prices,
            category_id: current.variant_info.category_id,
            default_price: current.variant_info.default_price,
            description: current.variant_info.description,
            enable_bulk_price: current.variant_info.enable_bulk_price || false,
            image: current.variant_info.image,
            import_price: current.variant_info.import_price,
            inventory: current.variant_info.inventory,
            is_delete: current.variant_info.is_delete,
            is_open: current.variant_info.is_open,
            is_pre_order: current.variant_info.is_pre_order,
            location_advanced: current.type_advanced === 2 ? {location : current.location} : [],
            location_ids: current.variant_info.location_ids,
            locations: current.variant_info.location || [],
            option: current.variant_info.option,
            options: current.variant_info.options,
            price: current.price,
            product: current.product_info,
            product_id: current.product_id,
            quantity: current.quantity,
            sku: current.sku,
            sumCost: current.total_cost,
            supplier_id: current.variant_info.supplier_id,
            tax_product: 0,
            title: current.title,
            total_quantity: current.product_info.sale_quantity,
            type_advanced: current.type_advanced,
            unit: current.variant_info.unit,
            variant_id: current.variant_id,
            whole_sale_price: current.variant_info.whole_sale_price,
            discount: current.total_discount,
          }
          discount_product += current.total_discount
          arr.push(obj)
          return arr
        },[])
        let discount_tmp = 0
        if(location.state.promotion){
          if(location.state.promotion.type == 'PERCENT'){
            discount_tmp = parseInt(parseInt(location.state.promotion.value) * parseInt(location.state.total_payment || 0)) / 100
          }else{
            discount_tmp = location.state.promotion.value
          }
        }
        let obj = {
          id: uuidv4(),
          name: location.state.customer_info.first_name + ' ' + location.state.customer_info.last_name,
          type: 'default',
          customer: location.state.customer_info,
          order_details: list_product, //danh sách sản phẩm trong hóa đơn
          payments: location.state.payments, //hình thức thanh toán
          paymentRefund: location.state.payment_refund,
          sumCostPaid: location.state.total_payment, // tổng tiền của tất cả sản phẩm
          total_discount: location.state.total_discount, // tổng giảm giá bao gồm từ sản phẩm và khuyến mãi
          discount: {...location.state.promotion, discount: discount_tmp}, // giảm giá từ khuyến mãi (bao gồm voucher)
          list_tax: location.state.list_tax, //ds thuế áp dụng
          noteInvoice: location.state.note,
          salesChannel: location.state.channel, //kênh bán hàng
          isDelivery: false,
          payer_shipping: false, // người trả phí ship
          fee_shipping: 0, //phí giao hàng
          shipping_info: location.state.customer_info, //địa chỉ nhận hàng
          shipping: null, //đơn vị vận chuyển
          tracking_number: '',
          discount_invoice: location.state.discount_invoice,
          discount_product: discount_product,
          moneyToBePaidByCustomer: location.state.final_cost, // tổng tiền khách hàng phải trả
          prepay: 0, //tiền khách thanh toán một phần
          moneyGivenByCustomer: location.state.customer_paid, //tiền khách hàng đưa
          excessCash: location.state.excessCash, //tiền thừa
          create_date: location.state.create_date, //ngày tạo đơn hàng
          code: location.state.code, //mã đơn hàng khi in hóa đơn
          imei_new: [], // imei sản phẩm
          locations_new: [], // lô của sản phẩm
        }
        invoicesNew = [obj]
        setInvoices([...invoicesNew])
      }, 2000)
      
    }
  },[location.state])
  useEffect(() => {
    if (!localStorage.getItem('accessToken')) history.push(ROUTES.LOGIN)
    dispatch({ type: 'UPDATE_INVOICE', data: [] })
    onLoadTemplatePrinter()
    _getBranches()
    _getShippings()
    _getPayments()
    _getTaxList()
  }, [branchIdApp])
  useEffect(() => {
    _getPromotions()
  }, [invoices[indexInvoice]?.discount?.promotion_code])
  useEffect(() => {
    // socketListener()
    socketListenerPoint()
    socketListenerCustomer()
    socketListenerUpdateProduct()
    socketListenerTransport()
    socketListenerInventoryNote()
  }, [socket])

  useEffect(() => {
    const checkNotifiPromotion = () => {
      try {
        let itemInvoices = invoices[0].sumCostPaid
        for (let promotion of promotions) {
          let today = moment().startOf('date').unix()
          let someday = moment(promotion.start_date).startOf('date').unix()

          if (someday > today) {
          } else {
            if (promotion.warning_value !== 0) {
              if (
                itemInvoices > promotion.warning_value &&
                promotion.order_value_require > itemInvoices
              ) {
                notification.info({
                  key: `notification ${promotion.name}`,
                  message: `Hoá đơn gần đạt khuyến mãi ${
                    promotion.name
                  } với giá trị cần là ${formatCash(promotion.order_value_require)} VNĐ`,
                })
              }
            }
          }
        }
      } catch (error) {
        notification.error({
          message: `Không tồn tại khuyến mãi nào`,
        })
      }
    }
    checkNotifiPromotion()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotions, invoices[indexInvoice]?.sumCostPaid])
  return (
    <div className={styles['sell-container']}>
      <HandlerKeyboard />
      <ModalConfirmCreateOrderOrPay />
      <PrintTemplate />
      <HeaderLayout
        loadingProduct={loadingProduct}
        setLoadingProduct={setLoadingProduct}
        products={orderBy(variants, ['create_date', 'desc'])}
        productsFil={variantFil}
        invoices={invoices}
        indexInvoice={indexInvoice}
        _editProductInInvoices={_editProductInInvoices}
        _addProductToCartInvoice={_addProductToCartInvoice}
        activeKeyTab={activeKeyTab}
        setActiveKeyTab={setActiveKeyTab}
        _createInvoice={_createInvoice}
        setIndexInvoice={setIndexInvoice}
        _deleteInvoice={_deleteInvoice}
        _removeMultipleProduct={_removeMultipleProduct}
        changeProductOfBranch={changeProductOfBranch}
        scrollSmoothHandler={scrollSmoothHandler}
        _deleteAllInvoice={_deleteAllInvoice}
      />

      <div className={styles['sell-content']}>
        <div className={styles['sell-left']}>
          <div
            style={{ height: showProductRelated ? '40%' : '90%', transition: 'height 1s' }}
            className={styles['sell-products-invoice']}
            id="order_detail"
          >
            {invoices[indexInvoice].order_details && invoices[indexInvoice].order_details.length ? (
              <>
                <ProductInfo
                  products={variants}
                  _removeProductToCartInvoice={_removeProductToCartInvoice}
                  invoices={invoices}
                  indexInvoice={indexInvoice}
                  _editInvoice={_editInvoice}
                  _editProductInInvoices={_editProductInInvoices}
                  setProductQuantity={setProductQuantity}
                _addProductToCartInvoice={_addProductToCartInvoice}
                changeProductOfBranch={changeProductOfBranch}
              />
              </>
              
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 400,
                }}
              >
                <img src={noData} alt="" style={{ width: 100, height: 100 }} />
                <h3>{t('sell.your_order_has_no_products')}</h3>
              </div>
            )}
          </div>
          {/* // )} */}

          {loadingScreen ? (
            <></>
          ) : (
            <div style={{ position: 'sticky', display: 'flex', justifyContent: 'center' }}>
              <Badge
                count={
                  showProductRelated === false ? (
                    <UpCircleTwoTone
                      twoToneColor="#5f73e2"
                      style={{
                        fontSize: 25,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        changeProductOfBranch()
                        setShowProductRelated(!showProductRelated)
                      }}
                    />
                  ) : (
                    <DownCircleTwoTone
                      twoToneColor="#5f73e2"
                      style={{
                        fontSize: 25,
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowProductRelated(!showProductRelated)}
                    />
                  )
                }
              ></Badge>
            </div>
          )}
          {/* {loadingScreen ? (
            <div
              className={styles['sell-products-invoice']}
              style={{
                width: '100%',
                height: showProductRelated ? '400px' : '90%',
                transition: 'height 1s',
                padding: '10px',
              }}
            >
              <Skeleton
                active
                paragraph={{
                  rows: 5,
                }}
                style={{ height: '100%' }}
              />
            </div>
          ) : ( */}
          <ProductsRelated
            loadingProductRelated={loadingProductRelated}
            productsRelated={variants}
            scrollSmoothHandler={scrollSmoothHandler}
            invoices={invoices}
            indexInvoice={indexInvoice}
            _editProductInInvoices={_editProductInInvoices}
            _addProductToCartInvoice={_addProductToCartInvoice}
            setParamsFilter={setParamsFilter}
            paramsFilter={paramsFilter}
            countProducts={variants.length}
            showProductRelated={showProductRelated}
            // _getProductsRelated={_getProductsRelated}
          />
          {/* // )} */}
        </div>

        <div className={styles['sell-right']}>
          {/* {loadingScreen ? (
            <div
              className={styles['sell-products-invoice']}
              style={{
                width: '100%',
                height: showProductRelated ? '400px' : '90%',
                transition: 'height 1s',
                padding: '10px',
              }}
            >
              <Skeleton
                active
                paragraph={{
                  rows: 5,
                }}
                style={{ height: '100%' }}
              />
            </div>
          ) : ( */}
          <>
            <CustomerInfo
              isBranchClosed={isBranchClosed}
              loadingCustomer={loadingCustomer}
              systemCustomers={systemCustomers}
              anotherCustomers={anotherCustomers}
              setCustomers={setCustomers}
              toggleCustomer={toggleCustomer}
              inputRef={inputRef}
              customers={customers}
              invoices={invoices}
              indexInvoice={indexInvoice}
              _editInvoice={_editInvoice}
              addUserToCustomer={addUserToCustomer}
              permissions={permissions}
              ModalAddCustomer={ModalAddCustomer}
              setCheckSyncPoint={setCheckSyncPoint}
            />
            <Row
              wrap={false}
              align="middle"
              style={{ display: !invoices[indexInvoice].customer && 'none', marginTop: 15 }}
            >
              {anotherCustomers.findIndex(
                (e) => e.phone === invoices[indexInvoice].customer?.phone
              ) >= 0 ? (
                <img width={40} style={{ marginRight: 10 }} src={Logo} alt="ekata-logo"></img>
              ) : (
                <UserOutlined style={{ fontSize: 28, marginRight: 15 }} />
              )}
              <div style={{ width: '100%' }}>
                <Row justify="space-between" wrap={false} align="middle">
                  <Row>
                    {invoices[indexInvoice].customer ? (
                      <Link style={{ fontWeight: 600, marginRight: 5, color: '#1890ff' }}>
                        {invoices[indexInvoice].customer &&
                          invoices[indexInvoice].customer?.first_name +
                            ' ' +
                            invoices[indexInvoice].customer?.last_name}
                      </Link>
                    ) : (
                      <div />
                    )}
                    <span style={{ fontWeight: 500 }}>
                      {' '}
                      - {invoices[indexInvoice].customer && invoices[indexInvoice].customer?.phone}
                    </span>
                  </Row>
                  {invoices[indexInvoice].customer?.customer_id !== 1 && !checkSyncPoint ? (
                    <ConnectHistorySell
                      customer={invoices[indexInvoice].customer}
                      isBranchClosed={isBranchClosed()}
                      setCheckSyncPoint={setCheckSyncPoint}
                      getCustomers={getCustomers}
                    />
                  ) : null}
                </Row>
                <div>
                  <span style={{ fontWeight: 600 }}>{t('sell.debts')} </span>
                  <span>
                    {invoices[indexInvoice].customer && invoices[indexInvoice].customer?.debt}
                  </span>
                </div>
                <Row wrap={false} justify="space-between" align="middle">
                  <div>
                    <span style={{ fontWeight: 600 }}>{t('sell.current_score')} </span>
                    <span>
                      {invoices[indexInvoice].customer?.point
                        ? invoices[indexInvoice].customer?.point?.toFixed()
                        : 0}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 600 }}>{t('sell.Exchange_value')} </span>
                    <span>
                      {invoices[indexInvoice].customer?.point
                        ? formatCash(
                            invoices[indexInvoice].customer?.point?.toFixed() *
                              point.exchange_money_rate
                          )
                        : 0}
                    </span>
                  </div>
                </Row>
              </div>
              <Popconfirm
                title={t('sell.do_you_want_to_delete_this_customer')}
                okText={t('common.agree')}
                cancelText={t('common.refuse')}
                onConfirm={async () => {
                  _editInvoice('shipping_info', null)
                  _editInvoice('customer', null)
                  _editInvoice('name', `Đơn ${invoices.length}`)
                }}
              >
                <CloseCircleTwoTone style={{ cursor: 'pointer', marginLeft: 20, fontSize: 23 }} />
              </Popconfirm>
            </Row>

            <Divider style={{ marginTop: 8, marginBottom: 0 }} />
            <Row
              style={{ alignItems: 'baseline' }}
              justify="space-between"
              align="middle"
              wrap={false}
            >
              <div>
                <Switch
                  disabled={isBranchClosed()}
                  checked={invoices[indexInvoice].isDelivery}
                  style={{ marginRight: 10, marginBottom: 8, marginTop: 8, fontSize: 15 }}
                  onChange={(checked) => {
                    _editInvoice('isDelivery', checked)
                    _editInvoice('fee_shipping', 0)
                    _editInvoice('billOfLadingCode', '')
                    _editInvoice('prepay', 0)
                    _editInvoice('salesChannel', checked ? 'Facebook' : 'POS')
                    _editInvoice('order_type', checked ? 'online' : 'offline')
                  }}
                />
                {t('common.delivery')}
              </div>

              <div>
                <Switch
                  disabled={isBranchClosed()}
                  checked={invoices[indexInvoice].pre_order_sales}
                  style={{ marginRight: 10, marginBottom: 8, marginTop: 8, fontSize: 15 }}
                  onChange={(checked) => {
                  }}
                />
                {t('sell.pre_order_sales')}
              </div>
            </Row>

            <div style={{ display: !invoices[indexInvoice].isDelivery && 'none' }}>
            <Row
                style={{ marginTop: 10 }}
                justify="space-between"
                align="middle"
              >
                <div>Kênh bán hàng:</div>
                <Radio
                  checked={invoices[indexInvoice].salesChannel === 'Facebook'}
                  onClick={() => _editInvoice('salesChannel', 'Facebook')}
                >
                  <img src={FaceBook} width={30} height={30} alt='facebook'></img>
                </Radio>
                <Radio
                  checked={invoices[indexInvoice].salesChannel === 'Instagram'}
                  onClick={() => _editInvoice('salesChannel', 'Instagram')}
                >
                  <img src={Instagram} width={30} height={30} alt='instagram'></img>
                </Radio>
                <Radio
                  checked={invoices[indexInvoice].salesChannel === 'TikTok'}
                  onClick={() => _editInvoice('salesChannel', 'TikTok')}
                >
                  <img src={Tiktok} width={30} height={30} alt='tiktok'></img>
                </Radio>
                <Radio
                  checked={invoices[indexInvoice].salesChannel === 'Shopee'}
                  onClick={() => _editInvoice('salesChannel', 'Shopee')}
                >
                  <img src={Shopee} width={30} height={30} alt='shopee'></img>
                </Radio>
              </Row>
              <Row
                style={{ marginTop: 10 }}
                justify="space-between"
                align="middle"
              >
                <div>Đơn vị vận chuyển:</div>
                <Select
                  disabled={isBranchClosed()}
                  allowClear
                      size="small"
                      placeholder={t('common.select_shiping')}
                      style={{ width: '70%', marginLeft: 8, height: '100%' }}
                      value={invoices[indexInvoice].shipping_company_id }
                      onChange={(value) => {
                        _editInvoice('shipping_company_id', value)
                      }}
                >
                  {shippings.map((item, index) => (
                    <Select.Option key={index} value={item.shipping_company_id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
              <Row
                style={{ marginTop: 10 }}
                justify="space-between"
                align="middle"
              >
                <div>Mã vận đơn:</div>
                <div style={{ borderBottom: '0.75px solid #C9C8C8', width: '70%' }}>
                  <Input
                    value={invoices[indexInvoice].tracking_number || ''}
                    onChange={(e) => _editInvoice('tracking_number', e.target.value)}
                    placeholder={t('common.enter_tracking_number_if_any')}
                    defaultValue={''}
                    bordered={false}
                    style={{ width: '100%' }}
                  />
                </div>
              </Row>
              <Row
                style={{ marginTop: 10 }}
                justify="space-between"
                wrap={false}
                align="middle"
              >
                <div style={{ marginTop: 10 }}>{t('common.delivery_charges')}</div>
                <div style={{ borderBottom: '0.75px solid #C9C8C8', width: '70%' }}>
                  <InputNumber
                    // disabled={isAutoTrackingNumber}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    value={invoices[indexInvoice].fee_shipping || ''} 
                    onChange={(value) =>{_editInvoice('fee_shipping', value)}}
                    placeholder={t('common.enter_delivery_fee')}
                    defaultValue={''}
                    min={0}
                    bordered={false}
                    style={{ width: '100%' }}
                  />
                </div>
              </Row>
              <Row
                style={{ marginTop: 10 }}
                justify="space-between"
                align="middle"
              >
                <div>Trả phí ship:</div>
                <Radio
                  checked={invoices[indexInvoice].payer_shipping === true}
                  onClick={() => _editInvoice('payer_shipping', true)}
                >
                  Cửa hàng trả
                </Radio>
                <Radio
                  checked={invoices[indexInvoice].payer_shipping === false}
                  onClick={() => _editInvoice('payer_shipping', false)}
                >
                  Khách hàng trả
                </Radio>
              </Row>
              <Row justify="space-between" align="middle">
                <div>{t('common.delivery_address')}</div>
                {invoices[indexInvoice].customer &&
                invoices[indexInvoice].customer.customer_id === 1 ? (
                  <></>
                ) : (
                  <ListDeliveryAddress
                    editInvoice={_editInvoice}
                    address={invoices[indexInvoice].shipping_info}
                    customer={invoices[indexInvoice].customer}
                  />
                )}
              </Row>
              {invoices[indexInvoice].shipping_info && (
                <div style={{ fontSize: 15 }}>
                  <div>
                    {`${invoices[indexInvoice].shipping_info.address || ''}
                  ${
                    (invoices[indexInvoice].shipping_info.ward &&
                      ', ' + invoices[indexInvoice].shipping_info.ward) ||
                    ''
                  }
                  ${
                    (invoices[indexInvoice].shipping_info.district &&
                      ', ' + invoices[indexInvoice].shipping_info.district) ||
                    ''
                  }
                  ${
                    (invoices[indexInvoice].shipping_info.province &&
                      ', ' + invoices[indexInvoice].shipping_info.province) ||
                    ''
                  }`}
                    </div>
                  </div>
                )}

            </div>
            <Divider style={{ marginTop: 8, marginBottom: 0 }} />

            <Row wrap={false} justify="space-between" align="middle"></Row>
            <div>
              <Row
                justify="space-between"
                wrap={false}
                align="middle"
                style={{ marginTop: 0, height: 30 }}
              >
                <Row wrap={false} align="middle">
                  <span style={{ width: 300 }}>
                    {t('common.total_payment')} (
                    <b>
                      {invoices[indexInvoice].order_details.reduce(
                        (total, current) => total + +current.quantity,
                        0
                      )}
                    </b>{' '}
                    {t('common.product')})
                  </span>
                </Row>
                <p style={{ marginBottom: 0 }}>{formatCash(invoices[indexInvoice].sumCostPaid)}</p>
              </Row>

              <Row
                justify="space-between"
                wrap={false}
                align="middle"
                style={{ marginTop: 0, height: 30 }}
              >
                <p style={{ marginTop: 'auto', marginBottom: 'auto', width: 142 }}>
                  Chiết khấu đơn hàng
                </p>
                <ModalDiscountInvoice
                  invoices={invoices[indexInvoice]}
                  isBranchClosed={isBranchClosed()}
                />
                <div
                  style={{
                    // width: '50%',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    {formatCash(invoices[indexInvoice].discount_invoice)}
                  </div>
                </div>
              </Row>
              <Row
                justify="space-between"
                wrap={false}
                align="middle"
                style={{ marginTop: 0, height: 30 }}
              >
                <span style={{ padding: 0 }}>Áp dụng khuyến mãi</span>
                {invoices[indexInvoice].sumCostPaid - invoices[indexInvoice].discount_product ===
                0 ? (
                  'Chưa có sản phẩm'
                ) : (
                  <div>
                    {!invoices[indexInvoice].customer ? (
                      'Chưa có khách hàng'
                    ) : (
                      <div>
                        {promotions.find(
                          (item) =>
                            invoices[indexInvoice].sumCostPaid -
                              invoices[indexInvoice].discount_product >=
                            item.order_value_require
                        ) ? (
                          <Row justify="space-between" wrap={false} align="middle">
                            <ModalPromotion
                              invoiceCurrent={invoices[indexInvoice]}
                              editInvoice={_editInvoice}
                              checkData={invoices[indexInvoice].discount.promotion_code}
                            />
                          </Row>
                        ) : (
                          <p className={styles['warning']}>
                            {promotions.find(
                              (item) =>
                                item.warning_value > 0 &&
                                invoices[indexInvoice].sumCostPaid >= item.warning_value
                            ) ? (
                              <ModalPromotionWarning
                                invoiceCurrent={invoices[indexInvoice]}
                                editInvoice={_editInvoice}
                                checkData={invoices[indexInvoice].discount.promotion_code}
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Row>
              {invoices[indexInvoice].discount.promotion_code !== undefined ? (
                <Row justify="space-between" wrap={false} align="middle">
                  <div>
                    <a style={{ padding: 0 }}>{invoices[indexInvoice].discount.promotion_code}</a>
                    <Popconfirm
                      title="Bạn muốn gỡ khuyến mãi này?"
                      onConfirm={() => {
                        _editInvoice('discount', {})
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        size="small"
                        style={{ marginLeft: '7px' }}
                        icon={<CloseOutlined />}
                      ></Button>
                    </Popconfirm>
                  </div>
                  <p>{formatCash(invoices[indexInvoice].discount.discount)}</p>
                </Row>
              ) : (
                <></>
              )}

              <Row
                justify="space-between"
                wrap={false}
                align="middle"
                style={{ marginTop: 0, height: 30 }}
              >
                <strong style={{ width: 300, display: 'flex' }}>
                  {'Tổng giảm giá đơn hàng'}{' '}
                  <Tooltip
                    title={
                      <div style={{ textAlign: 'center' }}>
                        Chiết khấu sản phẩm đã được trừ trực tiếp vào "Thành tiền"
                      </div>
                    }
                  >
                    <InfoCircleOutlined
                      style={{
                        fontSize: 12,
                        marginBottom: 'auto',
                        marginTop: 'auto',
                        marginLeft: 4,
                      }}
                    />
                  </Tooltip>
                </strong>

                <strong style={{ marginLeft: '50%' }}>
                  {formatCash(
                    (invoices[indexInvoice].discount_invoice || 0) +
                      (invoices[indexInvoice].discount.discount || 0) +
                      (invoices[indexInvoice].discount_product || 0)
                  ) || 0}{' '}
                </strong>
                <>
                  <Row gutter={[12, 12]} onClick={() => setShowInfoPayment(!showInfoPayment)}>
                    <Col>
                      {showInfoPayment === true ? (
                        <CaretUpOutlined style={{ color: '#8c8c8c' }} />
                      ) : (
                        <CaretDownOutlined style={{ color: '#8c8c8c' }} />
                      )}
                    </Col>
                  </Row>
                </>
              </Row>
              {showInfoPayment === true ? (
                <div style={{ marginLeft: '5%' }}>
                  <div>
                    - Chiết khấu đơn hàng: {formatCash(invoices[indexInvoice].discount_invoice)}
                  </div>
                  <div>
                    - Chiết khấu sản phẩm: {formatCash(invoices[indexInvoice].discount_product)}
                  </div>
                  <div>- Khuyến mãi: {formatCash(invoices[indexInvoice].discount.discount)}</div>
                </div>
              ) : (
                <></>
              )}
              <span style={{ whiteSpace: 'nowrap', marginTop: 0, height: 30 }}>
                <Row>
                  <p>Thuế đã áp dụng: </p>
                  <Row style={{ marginBottom: 10, width: '70%' }} wrap={false} align="middle">
                    <Select
                      disabled={isBranchClosed()}
                      allowClear
                      maxTagCount="responsive"
                      mode="multiple"
                      size="small"
                      placeholder={t('common.select_applicable_tax')}
                      style={{ width: '100%', marginLeft: 8, height: '100%' }}
                      value={invoices[indexInvoice].list_tax.map((tax) => tax.tax_id)}
                      onChange={(value) => {
                        const taxListNew = taxList.filter((tax) => value.includes(tax.tax_id))
                        _editInvoice('list_tax', taxListNew)
                      }}
                    >
                      {taxList.map((tax, index) => (
                        <Select.Option key={index} value={tax.tax_id}>
                          {tax.name} ({tax.value} %)
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Row>
              </span>
              {/* <Row wrap={false} align="middle">
                <div style={{ width: '100%', marginBottom: 10 }}>
                  <Row>
                    {invoices[indexInvoice].list_tax.map((tax) => (
                      <div justify="space-between" wrap={false} align="middle">
                        <span style={{ marginBottom: 0 }}>
                          <strong>{tax.name}</strong> ({tax.value} %)
                        </span>
                        <span style={{ marginBottom: 0 }}>
                          {formatCash(
                            (tax.value / 100) *
                              (invoices[indexInvoice].sumCostPaid -
                                invoices[indexInvoice].total_discount)
                          )}
                          ;
                        </span>
                      </div>
                    ))}
                  </Row>
                </div>
              </Row> */}

              <Row
                justify="space-between"
                wrap={false}
                align="middle"
                style={{ fontWeight: 700, color: '#0877de', fontSize: 15 }}
              >
                <div>{t('common.guest_must_pay') + ':'}</div>
                <div>{formatCash(invoices[indexInvoice].moneyToBePaidByCustomer)}</div>
              </Row>
              <Row justify="space-between" wrap={false} align="middle">
                <span
                  style={{ marginBottom: 0, fontWeight: '700', color: '#0877de', fontSize: 15 }}
                >
                  {t('common.payment_method') + '(F8):'}
                </span>
                <PaymentMethodModal
                  isBranchClosed={isBranchClosed()}
                  onCheckPaymentValid={onCheckPaymentValid}
                  setVisible={setVisiblePayments}
                  visible={visiblePayments}
                  moneyToBePaidByCustomer={invoices[indexInvoice].moneyToBePaidByCustomer}
                  editInvoice={_editInvoice}
                  paymentsOld={invoices[indexInvoice].payments}
                  paymentRefundOld={invoices[indexInvoice].paymentRefund}
                  excessCashOld={invoices[indexInvoice].excessCashOld}
                  indexInvoice={indexInvoice}
                  indexInvoiceOld={indexInvoiceOld}
                  setIndexInvoiceOld={setIndexInvoiceOld}
                  />
              </Row>

              {!availableOrder && (
                <div style={{ color: 'red' }}>{'Vui lòng điền số tiền thanh toán phù hợp'}</div>
              )}
            </div>

            {!invoices[indexInvoice].isDelivery && invoices[indexInvoice].excessCash > 0 && (
              <Row wrap={false} justify="space-between" align="middle">
                <span style={{ fontWeight: 600, color: 'red' }}>Tiền thừa</span>
                <span style={{ fontWeight: 600, color: 'red' }}>
                  {formatCash(invoices[indexInvoice].excessCash)}
                </span>
              </Row>
            )}

            <div style={{ marginBottom: 12, marginTop: 4 }}>
              {t('common.note')} <EditOutlined />
              <Input.TextArea
                onChange={(e) => _editInvoice('noteInvoice', e.target.value)}
                value={invoices[indexInvoice].noteInvoice || ''}
                rows={2}
                placeholder={t('sell.enter_order_notes')}
                style={{ width: '100%' }}
              />
            </div>
            <Row justify="center" style={{ bottom: '0px', right: '90px', marginTop: 16 }}>
              <PrintOldInvoices
                setVisible={setVisiblePrinter}
                visible={visiblePrinter}
                isBranchClosed={isBranchClosed()}
              />
              {invoices[indexInvoice].payments.findIndex(
                (payment) => payment.payment_method_id === 2 || payment.payment_method_id === 6
              ) >= 0 ? (
                <OtpOrderPoint
                  invoices={invoices}
                  availableOrder={availableOrder}
                  isBranchClosed={isBranchClosed}
                  createOrder={_createOrder}
                  anotherCustomers={anotherCustomers}
                  setOverlayPage={setOverlayPage}
                  point={point}
                  indexInvoice={indexInvoice}
                  handlePrint={handlePrint}
                  deleteInvoiceAfterCreateOrder={_deleteInvoiceAfterCreateOrder}
                />
              ) : (
                <Button
                  disabled={!availableOrder || isBranchClosed()}
                  onClick={_validatedCreateOrderOrPay}
                  size="large"
                  type="primary"
                  style={{
                    minWidth: 250,
                    backgroundColor: '#0877DE',
                    borderColor: '#0877DE',
                    marginLeft: 10,
                    cursor: `${!availableOrder || isBranchClosed() ? 'not-allowed' : 'pointer'}`,
                  }}
                >
                  {invoices[indexInvoice].isDelivery
                    ? t('sell.create_a_delivery_order')
                    : t('sell.payment')}{' '}
                  (F2)
                </Button>
              )}
            </Row>
          </>
          {/* // )} */}
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <DetailProductModal
          addProductToCartInvoice={_addProductToCartInvoice}
          check={visibleProductDetail}
        />
      </div>
    </div>
  )
}
