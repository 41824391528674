const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: 60,
    },
    {
      title: 'Mã nhân viên',
      dataIndex:'code',
      key: 'code',
    },
    {
      title: 'Tên nhân viên',
      key: 'name',
    },  
    {
      title: 'Số điện thoại',
      key: 'phone'
    },  
    {
      title: 'Email',
      key: 'email',
    },    
    {
      title: 'Địa chỉ',
      key: 'address',
    },
    {
        title: 'Chi nhánh',
        key: 'branch',
    },
    {
      title: 'Vai trò',
      key: 'role',
    },
    {
      title: 'Trạng thái',
      key: 'status',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'create_date',
      key: 'create_date',
    },
    {
      title: 'Hành động',
      key: 'action',
    },
  ]
  
  export default columns
  