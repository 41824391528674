import React, { useEffect, useState } from 'react'
// import styles from '../import-customer.module.css'

//antd
import { Col, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getRowsExcel } from 'apis/shipping-control'

export default function Step2({
  filePath,
  submit,
  gotoStep,
  current,
  setOpen,
  dataOrders,
  setDataOrders,
  setOrdersError,
  start_date,
  end_date
}) {
  const [excelRows, setExcelRows] = useState([])
  const [loading, setLoading] = useState(true)
  
  const [orderCheck, setOrderCheck] = useState([])

  const handleImport = async () => {
    try {
      let newData = [...dataOrders]
      let newDataCheck = [...orderCheck]
      if(newDataCheck.length > 0) {
        for(let data of newData) {
          let dataObject = newDataCheck.find(item => item.code == data.tracking_number)
          data.cod_partner = dataObject.cod
          data.cost_ship_partner = dataObject.fee_shipping
        }
      }
      setDataOrders(newData)
      setOpen(false)
      submit({ step: 0 })
    } catch (error) {
      console.log(error)
    }
  }

  async function _getRowsExcel(filePath, start_date, end_date) {
    try {
      setLoading(true)
      let res = await getRowsExcel(filePath, start_date, end_date)
      if (res.status == 200) {
        setOrderCheck(res.data.orders)
        setOrdersError(res.data.ordersError)
        setExcelRows(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getRowsExcel(filePath, start_date, end_date)
  }, [filePath])

  useEffect(() => {
    if (excelRows.length > 0) {
      handleImport()
    }
  }, [excelRows])

  return (
    <div>
      <div>
        <div style={{ marginTop: 20 }}>
          <Row justify="center">
            <Col style={{ marginRight: 10 }}></Col>
          </Row>
        </div>
      </div>
      <div>
        <p style={{ color: 'red' }}>
          Vui lòng đợi trong giây lát Mỗi lần nhập tối đa 1000 sản phẩm
        </p>

        {loading === true && (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            }
          />
        )}
      </div>
    </div>
  )
}
