import React, { useEffect, useState } from 'react'
import styles from './import-inventory.module.scss'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { ROUTES, PERMISSIONS, IMAGE_DEFAULT, ACTION } from 'consts'
import { filterOptionProduct, formatCash } from 'utils'
import moment from 'moment'
import noData from 'assets/icons/no-data.png'
import { useDispatch, useSelector } from 'react-redux'

//antd
import {
  Row,
  Col,
  Divider,
  Input,
  Button,
  Table,
  InputNumber,
  Form,
  Select,
  Radio,
  Spin,
  Tooltip,
  Space,
  DatePicker,
  Upload,
  Modal,
  notification,
  Drawer,
  Checkbox,
  Tag,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
  SearchOutlined,
  PlusSquareOutlined,
  CreditCardFilled,
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'

//apis
import { getProducts } from 'apis/product'
import { getAllBranch } from 'apis/branch'
import { uploadFile } from 'apis/upload'
import { getSuppliers } from 'apis/supplier'
import { getEmployees } from 'apis/employee'
import {
  createOrderImportInventory,
  updateOrderImportInventory,
  getOrdersImportInventory,
} from 'apis/inventory'
import { addTax, getTaxs, updateTax, deleteTax } from 'apis/tax'

//components
import Permission from 'components/permission'
import TitlePage from 'components/title-page'
import delay from 'delay'
import ItemProductImei from 'views/import-inventory-create/items/itemProductImei'
import { getProductsSystem } from 'redux/actions/products'
const { TextArea } = Input
export default function ImportInventory() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataUser = useSelector((state) => state.login.dataUser)
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const query = new URLSearchParams(location.search)
  const product_ids = query.get('product_ids')
  const order_id = query.get('order_id')
  let { code } = useParams()
  const [taxList, setTaxList] = useState([])
  const [users, setUsers] = useState([])
  const [branches, setBranches] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [file, setFile] = useState('')
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [productsSearch, setProductsSearch] = useState([])
  const [importLocation, setImportLocation] = useState({})

  const [orderCurrent, setOrderCurrent] = useState()

  const [supplierId, setSupplierId] = useState()
  const [productsSupplier, setProductsSupplier] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [visibleProductsToSupplier, setVisibleProductsToSupplier] = useState(false)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const toggleProductsToSupplier = () => {
    setVisibleProductsToSupplier(!visibleProductsToSupplier)
    setProductsSupplier([])
    setSelectedProducts([])
    setSupplierId()
  }

  //object order create
  const [orderCreate, setOrderCreate] = useState({
    order_details: [], //danh sách sản phẩm trong hóa đơn
    type_payment: 'PAID', //hình thức thanh toán
    sumCostPaid: 0, // tổng tiền của tất cả sản phẩm
    deliveryCharges: 0, //phí giao hàng
    moneyToBePaidByCustomer: 0, // tổng tiền khách hàng phải trả (Tổng tiền thanh toán)
    tax_list: [],
    sum_tax_list: 0,
    order_id: 0
  })

  const _editOrder = (attribute, value) => {
    const orderCreateNew = { ...orderCreate }
    orderCreateNew[attribute] = value

    //tổng giá trị ds thuế
    orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
      (total, current) => total + (current.value / 100) * orderCreateNew.sumCostPaid,
      0
    )

    //tổng tiền khách hàng phải trả (thành tiền)
    //tổng tiền sp + phí ship + VAT (mặc định 10%)
    orderCreateNew.moneyToBePaidByCustomer = +(
      orderCreateNew.sumCostPaid +
      orderCreateNew.deliveryCharges +
      orderCreateNew.sum_tax_list -
      orderCreateNew.discount
    ).toFixed(0)

    form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
    setOrderCreate({ ...orderCreateNew })
  }

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: '5%',
      align: 'center',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      render: (data, record) => (
        <Link
          to={{
            pathname: ROUTES.PRODUCT_UPDATE + '/' + record.sku,
          }}
          target="_blank"
        >
          {record.sku}
        </Link>
      ),
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_name',
    },
    {
      title: 'Phiên bản',
      dataIndex: 'title',
    },
    {
      title: (
        <div>
          {'Tồn kho  '}
          <Tooltip
            title={
              <div style={{ textAlign: 'center' }}>
                SL tồn kho hiện tại của địa điểm nhận hàng được chọn bên phải
              </div>
            }
          >
            <InfoCircleTwoTone style={{ fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
      render: (data, record, index) => {
        var inventory_current = 0
        if (record.locations != undefined) {
          var index = record.locations.findIndex(
            (item) => item.branch_id == importLocation.branch_id
          )
          if (index !== -1) inventory_current = record.locations[index].quantity
        }

        return (
          <div>
            {importLocation.branch_id == undefined ? (
              <div>Chọn địa điểm nhận hàng để xem</div>
            ) : (
              <div>{inventory_current}</div>
            )}
          </div>
        )
      },
    },
    {
      title: 'SL nhập thêm',
      width: 100,

      render: (data, record) => (
        <InputNumber
          style={{ width: 90, padding: 3 }}
          disabled
          defaultValue={record.quantity || 0}
          min={1}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder="Số lượng nhập"
        />
      ),
    },
    {
      width: 130,
      title: () => {
        return (
          <div>
            {'Đơn giá nhập  '}
            <Tooltip
              title={
                <div style={{ textAlign: 'center' }}>
                  Hệ thống lấy đơn giá nhập hàng lần gần nhất
                </div>
              }
            >
              <InfoCircleTwoTone style={{ fontSize: 12 }} />
            </Tooltip>
          </div>
        )
      },
      render: (data, record) => (
        <InputNumber
          disabled
          style={{ width: '100%', padding: 3 }}
          defaultValue={record.import_price || 0}
          min={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder="Nhập đơn giá nhập"
        />
      ),
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {'Loại hàng có tồn kho'}
            <Tooltip
              title={
                <div style={{ textAlign: 'center' }}>
                  - Tồn kho theo lô sẽ điền ngày tháng năm <br></br>- Tồn kho theo iMei sẽ nhập số
                  iMei <br></br>- Tồn kho thường sẽ không nhập
                </div>
              }
            >
              <InfoCircleTwoTone style={{ fontSize: 12 }} />
            </Tooltip>
          </div>
        )
      },
      width: 200,
      align: 'center',
      render: (text, record, index) => {
        return (
          (record.type_advanced === 3 && (
            <div>
              {record?.imei.map((item, index) => (
                <Tag key={index}>{item}</Tag>
              ))}
            </div>
          )) ||
          (record.type_advanced === 2 && (
            <span>
              {moment(record.mfg).format('DD/MM/YYYY')}-{moment(record.exp).format('DD/MM/YYYY')}
            </span>
          ))
        )
      },
    },
    {
      title: 'Tổng tiền',
      render: (text, record) =>
        record.sumCost && (
          <div style={{ whiteSpace: 'nowrap' }}>{formatCash(record.sumCost || 0)}</div>
        ),
    },
  ]

  const _getBranches = async () => {
    try {
      const res = await getAllBranch()
      if (res.status === 200) {
        setBranches(res.data.data)
        if (!code) {
          setImportLocation({ branch_id: dataUser && dataUser.data && dataUser.data.branch_id })
          form.setFieldsValue({
            import_location: dataUser && dataUser.data && dataUser.data.branch_id,
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getUsers = async () => {
    try {
      const res = await getEmployees()
      if (res.status === 200) {
        setUsers(res.data.data)

        if (!code)
          form.setFieldsValue({
            order_creator_id: dataUser && dataUser.data && dataUser.data.user_id,
            receiver_id: dataUser && dataUser.data && dataUser.data.user_id,
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _uploadFile = async (file) => {
    try {
      setLoadingUpload(true)
      const url = await uploadFile(file)
      if (url) setFile(url)
      setLoadingUpload(false)
    } catch (error) {
      setLoadingUpload(false)
    }
  }

  const _editImportInventoryOrder = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      await form.validateFields()
      const dataForm = form.getFieldsValue()

      let payment_info = {}

      if (dataForm.payment_status === 'PAID') {
        payment_info.paid_amount = dataForm.moneyToBePaidByCustomer
        payment_info.debt_amount = 0
      }

      if (dataForm.payment_status === 'PAYING') {
        const excessCash = dataForm.moneyToBePaidByCustomer - dataForm.paid
        payment_info.paid_amount = dataForm.paid
        payment_info.debt_amount = excessCash >= 0 ? excessCash : 0
      }

      if (dataForm.payment_status === 'UNPAID') {
        payment_info.paid_amount = 0
        payment_info.debt_amount = dataForm.moneyToBePaidByCustomer
      }
      // Check valid
      for (var i = 0; i < orderCreate.order_details.length; i++) {
        if (
          moment(orderCreate.order_details[i].mfg).unix() >
          moment(orderCreate.order_details[i].exp).unix()
        ) {
          notification.warning({
            message: 'Hạn sử dụng phải lớn hơn ngày sản xuất',
          })
          return
        }
      }

      const body = {
        ...dataForm,
        note: dataForm?.note?.trim() || '',
        code: dataForm.code || '',
        tags: dataForm.tags || [],
        import_location: { ...importLocation },
        products: orderCreate.order_details.map((e) => ({
          product_id: e.product_id,
          variant_id: e.variant_id,
          price: +e.price,
          import_price: +e.import_price,
          quantity: +e.quantity,
          locations: e.locations || [],
          mfg: e.type_advanced === 2 ? e.mfg : '',
          exp: e.type_advanced === 2 ? e.exp : '',
          imei: e.type_advanced === 3 ? e.imei : [],
          type_advanced: e.type_advanced,
          total_cost: e.total_cost,
        })),
        files: file ? [file] : [],
        complete_date: dataForm.complete_date ? new Date(dataForm.complete_date).toString() : null,
        total_cost: orderCreate.sumCostPaid || 0,
        total_tax: orderCreate.sum_tax_list || 0,
        list_tax: orderCreate.tax_list,
        total_discount: orderCreate.discount,
        fee_shipping: orderCreate.deliveryCharges || 0,
        final_cost: orderCreate.moneyToBePaidByCustomer || 0,
        payment_info: [payment_info],
      }

      delete body.moneyToBePaidByCustomer
      delete body.paid
      let res = await updateOrderImportInventory(body, orderCreate.order_id)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `${code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng thành công`,
          })
          history.push(ROUTES.IMPORT_INVENTORIES)
        } else
          notification.error({
            message:
              res.data.message ||
              `${code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng thất bại, vui lòng thử lại`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng thất bại, vui lòng thử lại`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }


  const _getTaxList = async () => {
    try {
      const res = await getTaxs({ branch_id: branchIdApp })
      if (res.status === 200) {
        setTaxList(res.data.data)

        if (!code) {
          if (orderCreate.tax_list.length === 0 && !product_ids) {
            const taxDefault = res.data.data.find((e) => e.default)
            _editOrder('tax_list', taxDefault ? [taxDefault] : [])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrderDetail = async (code) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getOrdersImportInventory({ code: code, branch_id: branchIdApp, detail:1 })
      if (res.status === 200 && res.data.data[0]) {
        initOrder(res.data.data[0])
        await delay(100)
        form.setFieldsValue({ code: res.data.data[0].code, complete_date: '' })
      } else {
        history.push(ROUTES.IMPORT_INVENTORIES)
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const initOrder = (orderDetail = { products: [] }) => {
    setOrderCreate({
      ...orderCreate,
      order_details: orderDetail.products.map((e) => ({
        ...e.variant_info,
        product_name: e.product_info ? e.product_info.name : '',
        sku: e.product_info ? e.product_info.sku : '',
        quantity: e.quantity || 0,
        import_price: e.import_price || 0,
        sumCost: +e.import_price * +e.quantity,
        mfg: e.mfg || '',
        exp: e.exp || '',
        imei: e.imei || [],
        product_id: e.product_id,
        variant_id: e.variant_id,
        total_cost: e.total_cost || 0,
        type_advanced: e.type_advanced || 1,
        price: e.price,
        locations: e.locations,
      })),
      order_id: orderDetail.order_id,
      type_payment: orderDetail.payment_status || '',
      sumCostPaid: orderDetail.total_cost || 0,
      deliveryCharges: orderDetail.fee_shipping || 0,
      moneyToBePaidByCustomer: orderDetail.final_cost || 0,
      tax_list: orderDetail.list_tax || [],
      sum_tax_list: orderDetail.total_tax,
      discount: orderDetail.total_discount,
    })
    setImportLocation(orderDetail.import_location ? { ...orderDetail.import_location } : {})
    form.setFieldsValue({
      ...orderDetail,
      import_location: orderDetail.import_location ? orderDetail.import_location.branch_id : '',
      complete_date: orderDetail.complete_date ? moment(orderDetail.complete_date) : null,
      moneyToBePaidByCustomer: orderDetail.final_cost || 0,
      paid: orderDetail.payment_amount || 0,
      order_creator_id: orderDetail.order_creator_id,
      receiver_id: orderDetail.receiver_id,
    })
  }

  useEffect(() => {
    _getBranches()
    _getUsers()
    _getTaxList()
  }, [])

  useEffect(() => {
      form.setFieldsValue({ payment_status: 'PAID', complete_date: moment(new Date()) })
      _getOrderDetail(code)
  }, [branchIdApp, code])
  useEffect(() => {
    if (!permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_nhap_hang))
      history.push(ROUTES.INVENTORY)
    if (branchIdApp) {
      dispatch(getProductsSystem({ branchIdApp: branchIdApp }))
    }
  }, [branchIdApp])
  return (
    <>
        <div className="card">
          <Form layout="vertical" form={form}>
            <TitlePage
              isAffix={true}
              title={
                <Row
                  onClick={() => history.push(ROUTES.IMPORT_INVENTORIES)}
                  align="middle"
                  style={{ color: 'black', cursor: 'pointer' }}
                >
                  <ArrowLeftOutlined style={{ marginRight: 5 }} />
                  {code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng
                </Row>
              }
            >
              <Space>
                <Button
                  size="large"
                  type="primary"
                  onClick={() =>
                    history.push(
                      `${ROUTES.PRODUCT_BARCODE}?product_ids=${orderCreate.order_details.length > 0 ? orderCreate.order_details
                        .reduce((arr, item) =>{
                          arr.push(item.product_id)
                          return arr
                        }, [])
                        .join('--') : ''}`
                    )
                  }
                >
                  in mã vạch
                </Button>
                <Permission permissions={[PERMISSIONS.cap_nhat_phieu_nhap_hang]}>
                  <Button
                    style={{ minWidth: 100 }}
                    size="large"
                    type="primary"
                    onClick={() => _editImportInventoryOrder('COMPLETE')}
                  >
                    Lưu
                  </Button>
                </Permission>
              </Space>
            </TitlePage>

            <Row gutter={30} style={{ marginTop: 25 }}>
              <Col span={18}>
                <div className={styles['block']}>
                  <Row justify="space-between" align="middle" wrap={false}>
                    <div className={styles['title']}>Sản phẩm</div>
                    <Button disabled type="link" onClick={toggleProductsToSupplier}>
                      Chọn nhanh từ nhà cung cấp
                    </Button>
                  </Row>
                  <div className="select-product-sell">
                    <Select
                      filterOption={filterOptionProduct}
                      notFoundContent={loadingProduct ? <Spin size="small" /> : null}
                      dropdownClassName="dropdown-select-search-product"
                      allowClear
                      showSearch
                      disabled
                      clearIcon={<CloseOutlined style={{ color: 'black' }} />}
                      suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
                      style={{ width: '100%', marginBottom: 15 }}
                      placeholder="Thêm sản phẩm vào hoá đơn"
                    >
                    </Select>
                  </div>

                  <Table
                    scroll={{ y: 400 }}
                    sticky
                    pagination={false}
                    columns={columns}
                    size="small"
                    dataSource={[...orderCreate.order_details]}
                    locale={{
                      emptyText: (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 200,
                          }}
                        >
                          <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                          <h4 style={{ fontSize: 15, color: '#555' }}>
                            Đơn hàng của bạn chưa có sản phẩm
                          </h4>
                        </div>
                      ),
                    }}
                    summary={() => (
                      <Table.Summary fixed>
                        <Table.Summary.Row
                          style={{ display: orderCreate.order_details.length === 0 && 'none' }}
                        >
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={3}>
                            <div style={{ fontSize: 14.4 }}>
                              <Row wrap={false} justify="space-between">
                                <div>Tổng tiền ({orderCreate.order_details.length} sản phẩm)</div>
                                <div>
                                  {orderCreate.sumCostPaid
                                    ? formatCash(+orderCreate.sumCostPaid)
                                    : 0}
                                </div>
                              </Row>
                              <Row
                                style={{
                                  height: 60,
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                wrap={false}
                                justify="space-between"
                              >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ paddingRight: 10 }}>Thuế</div>
                                  <Select
                                    maxTagCount="responsive"
                                    // size="small"
                                    showSearch
                                    style={{
                                      width: 220,
                                      height: 30,
                                      marginRight: 5,
                                    }}
                                    placeholder="Chọn thuế"
                                    optionFilterProp="children"
                                    allowClear
                                    disabled
                                    mode="multiple"
                                    value={orderCreate.tax_list.map((tax) => tax.tax_id)}
                                  >
                                    {taxList.map((tax, index) => (
                                      <Select.Option value={tax.tax_id} key={index}>
                                        {tax.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                  <Link to='/tax' target='_blank'>
                                    <Button
                                      style={{ marginBottom: 3 }}
                                      size="medium"
                                      type="primary"
                                      icon={<PlusCircleOutlined />}
                                    ></Button>
                                  </Link>
                                </div>
                              </Row>
                              <Row wrap={false} align="middle">
                                <div style={{ width: '100%', marginBottom: 10 }}>
                                  {orderCreate.tax_list.map((tax) => (
                                    <Row justify="space-between" wrap={false} align="middle">
                                      <p style={{ marginBottom: 0 }}>
                                        {tax.name} ({tax.value} %)
                                      </p>
                                      <p style={{ marginBottom: 0 }}>
                                        {formatCash(
                                          (tax.value / 100) *
                                            (orderCreate.sumCostPaid ? +orderCreate.sumCostPaid : 0)
                                        )}
                                      </p>
                                    </Row>
                                  ))}
                                </div>
                              </Row>
                              <Row
                                style={{ marginTop: 5, marginBottom: 5 }}
                                wrap={false}
                                justify="space-between"
                              >
                                <div>Chiết khấu</div>
                                <div>
                                  <InputNumber
                                    style={{ minWidth: 120, height: 30 }}
                                    min={0}
                                    formatter={(value) =>
                                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    }
                                    disabled
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    defaultValue={orderCreate.discount}
                                    initialValues={orderCreate.discount}
                                    value={orderCreate.discount}
                                  />
                                </div>
                              </Row>
                              <Row
                                style={{ marginTop: 5, marginBottom: 5 }}
                                wrap={false}
                                justify="space-between"
                                align="middle"
                              >
                                <div>Phí giao hàng</div>
                                <div>
                                  <InputNumber
                                    style={{ minWidth: 120, height: 30 }}
                                    disabled
                                    min={0}
                                    value={orderCreate.deliveryCharges}
                                    formatter={(value) =>
                                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    }
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    defaultValue={orderCreate.deliveryCharges}
                                  />
                                </div>
                              </Row>
                              <Row wrap={false} justify="space-between" style={{ fontWeight: 600 }}>
                                <div>Thành tiền thanh toán</div>
                                <div>{formatCash(+orderCreate.moneyToBePaidByCustomer || 0)}</div>
                              </Row>
                            </div>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                  />
                </div>
                <div className={styles['block']} style={{ marginTop: 30 }}>
                  <Row wrap={false} align="middle" style={{ fontWeight: 600 }}>
                    <CreditCardFilled style={{ fontSize: 17, marginRight: 5 }} />
                    <div>PHƯƠNG THỨC THANH TOÁN</div>
                  </Row>
                  <div style={{ marginTop: 10 }}>
                    <Form.Item name="payment_status">
                      <Radio.Group
                        disabled
                        onChange={(e) => _editOrder('type_payment', e.target.value)}
                      >
                        <Space size="small" direction="vertical">
                          <Radio value="PAID">Đã thanh toán toàn bộ</Radio>
                          <Radio value="PAYING">Thanh toán một phần</Radio>
                          <Radio value="UNPAID">Thanh toán sau</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <Divider />

                  {orderCreate.type_payment === 'UNPAID' && (
                    <div>
                      <div>Thành tiền cần thanh toán (bao gồm VAT)</div>
                      <Form.Item
                        name="moneyToBePaidByCustomer"
                        rules={[
                          { required: true, message: 'Vui lòng nhập thành tiền cần thanh toán' },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          disabled
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          style={{ width: '45%' }}
                          placeholder="Nhập thành tiền cần thanh toán"
                        />
                      </Form.Item>
                    </div>
                  )}
                  {orderCreate.type_payment === 'PAYING' && (
                    <Row justify="space-between">
                      <div style={{ width: '45%' }}>
                        <div>Tổng tiền cần thanh toán (bao gồm VAT)</div>
                        <Form.Item
                          name="moneyToBePaidByCustomer"
                          rules={[
                            { required: true, message: 'Vui lòng nhập tổng tiền cần thanh toán' },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            disabled
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder="Nhập tổng tiền cần thanh toán"
                          />
                        </Form.Item>
                      </div>

                      <div style={{ width: '45%' }}>
                        <div>Số tiền đã trả</div>
                        <Form.Item
                          name="paid"
                          rules={[{ required: true, message: 'Vui lòng nhập số tiền đã trả' }]}
                        >
                          <InputNumber
                            min={0}
                            disabled
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder="Nhập số tiền đã trả"
                          />
                        </Form.Item>
                      </div>
                    </Row>
                  )}

                  {orderCreate.type_payment === 'PAID' && (
                    <div>
                      <div>Thành tiền cần thanh toán (bao gồm VAT)</div>
                      <Form.Item
                        name="moneyToBePaidByCustomer"
                        rules={[
                          { required: true, message: 'Vui lòng nhập thành tiền cần thanh toán' },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          disabled
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          style={{ width: '45%' }}
                          placeholder="Nhập thành tiền cần thanh toán"
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
              </Col>

              <Col span={6}>
                <div className={styles['block']} style={{ marginBottom: 30 }}>
                  <div className={styles['title']}>Thông tin đơn nhập hàng</div>
                  <Form.Item
                    label="Mã đơn nhập hàng"
                    name="code"
                    rules={[{ required: true, message: 'Vui lòng điền mã đơn nhập hàng!' }]}
                  >
                    <Input disabled placeholder="Nhập mã đơn nhập hàng" />
                  </Form.Item>
                  <Form.Item
                    name="branch_id"
                    label="Địa điểm nhận hàng"
                    rules={[{ required: true, message: 'Vui lòng chọn địa điểm nhận hàng!' }]}
                  >
                    <Select
                      value=""
                      showSearch
                      disabled
                      optionFilterProp="children"
                      defaultValue={orderCreate?.branch_id}
                      placeholder="Chọn địa điểm nhận hàng"
                      style={{ width: '100%' }}
                      onChange={(value, option) => {
                        let p = {}
                        if (value) {
                          const branchFind = branches.find((e) => e.branch_id === value)
                          if (branchFind) p.branch_id = branchFind.branch_id
                        }
                        setImportLocation({ ...p })
                      }}
                    >
                      {branches.map((e, index) => (
                        <Select.Option value={e.branch_id} key={index}>
                          {e.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Ngày nhập hàng" name="complete_date">
                    <DatePicker
                      disabled
                      placeholder="Chọn ngày giao"
                      style={{ width: '100%' }}
                      size="large"
                      className="br-15__date-picker"
                      showTime={{ format: 'HH:mm' }}
                    />
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true, message: 'Vui lòng chọn người tạo đơn!' }]}
                    label="Người tạo đơn"
                    name="creator_id"
                  >
                    <Select
                      disabled
                      showSearch
                      optionFilterProp="children"
                      placeholder="Chọn nhân viên lên đơn"
                      defaultValue={dataUser && dataUser.data && dataUser.data.user_id}
                    >
                      {users.map((user, index) => (
                        <Select.Option value={user.user_id} key={index}>
                          {user.first_name + ' ' + user.last_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    // rules={[{ required: true, message: 'Vui lòng chọn Người xác nhận đơn!' }]}
                    label="Người xác nhận đơn"
                    name="verifier_id"
                    initialValue={dataUser && dataUser.data && dataUser.data.user_id}
                  >
                    <Select
                      disabled
                      allowClear
                      defaultValue={dataUser && dataUser.data && dataUser.data.user_id}
                      showSearch
                      optionFilterProp="children"
                      placeholder="Chọn người xác nhận đơn"
                    >
                      {users.map((user, index) => (
                        <Select.Option value={user.user_id} key={index}>
                          {user.first_name + ' ' + user.last_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className={styles['block']}>
                  <div className={styles['title']}>Hóa đơn </div>
                  <div>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      data={_uploadFile}
                    >
                      {file ? (
                        <img src={file} alt="avatar" style={{ width: '100%' }} />
                      ) : (
                        <div>
                          {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>Tải hóa đơn</div>
                        </div>
                      )}
                    </Upload>
                  </div>

                  <div className={styles['title']}>
                    Ghi chú{' '}
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Thêm thông tin ghi chú phục vụ cho việc xem thông tin và xử lý đơn hàng.
                          (VD: đơn giao trong ngày, giao trong giờ hành chính...)
                        </div>
                      }
                    >
                      <InfoCircleTwoTone style={{ fontSize: 12 }} />
                    </Tooltip>
                  </div>
                  <Form.Item name="note">
                    <Input.TextArea rows={2} placeholder="Nhập ghi chú" />
                  </Form.Item>

                  <div className={styles['title']}>
                    Tag{' '}
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Chọn hoặc thêm các thẻ cho đơn hàng, thẻ này phục vụ cho việc lọc các đơn
                          (VD: Đơn giao gấp, đơn nội thành...)
                        </div>
                      }
                    >
                      <InfoCircleTwoTone style={{ fontSize: 12 }} />
                    </Tooltip>
                  </div>
                  <Form.Item name="tags">
                    <Select mode="tags" placeholder="Nhập tags"></Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
    </>
  )
}
