import React, { useState, useEffect, useRef, memo } from 'react'
import localStorage from '../../utils/localStorage'
import styles from './product.module.scss'
import moment from 'moment'
import { ACTION, ROUTES, PERMISSIONS, regexRemoveUnicode, regexRemoveUnicodeNoNumber } from 'consts'
import { compare, formatCash, removeAccents, removeUnicode } from 'utils'

import { useHistory, useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CKEditor } from 'ckeditor4-react'
import parse from 'html-react-parser'
import delay from 'delay'
import { useTranslation } from 'react-i18next'
import jwt_decode from 'jwt-decode'
import { socket } from '../../socket'
import { ModalPrice } from './items/modal-price'
//components
import NotSupportMobile from 'components/not-support-mobile'
import TitlePage from 'components/title-page'
import SupplierForm from 'views/supplier/supplier-form'
import Permission from 'components/permission'
import CreateCategory from 'views/category-create'
import ModalPicture from './modal-picture'
//antd
import {
  Button,
  Table,
  Input,
  Form,
  Row,
  Col,
  InputNumber,
  Select,
  Upload,
  notification,
  Popconfirm,
  Tooltip,
  Space,
  Modal,
  TreeSelect,
  Badge,
  Drawer,
  Radio,
  Switch,
  Spin,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  InboxOutlined,
  CloseOutlined,
  PlusOutlined,
  EditOutlined,
  FileImageOutlined,
  DollarOutlined,
  ReloadOutlined,
  CloseCircleFilled,
  UploadOutlined,
} from '@ant-design/icons'

//apis
import { getCategories } from 'apis/category'
import { getSuppliers } from 'apis/supplier'
import { uploadFiles, uploadFile } from 'apis/upload'
import { getWarranties, addWarranty } from 'apis/warranty'
import { getSaleHistory, getRefundHistory, getChangePriceHistory, addProduct } from 'apis/product'

export default function ProductAdd() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [formGuarantee] = Form.useForm()
  const typingTimeoutRef = useRef(null)
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [isLoading, setIsLoading] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [imageBig, setImageBig] = useState([])
  const [modalDefaultImg, setModalDefaultImg] = useState(null)
  const toogleModalPicture = () => {
    setImageBig([])
    setIsModalVisible(!isModalVisible)
  }

  const [keyTab, setKeyTab] = useState('1')
  const [isRenderFirst, setIsRenderFirst] = useState(false)
  const [files, setFiles] = useState([])
  const [idsWarranty, setIdsWarranty] = useState([''])
  const [warranties, setWarranties] = useState([])
  const [attributes, setAttributes] = useState([{ option: '', values: [] }])
  const [variants, setVariants] = useState([])
  const [selectRowKeyVariant, setSelectRowKeyVariant] = useState([])
  const [isMobile, setIsMobile] = useState(false)
  const [description, setDescription] = useState('')
  const [suppliers, setSuppliers] = useState([])
  const [supplier, setSupplier] = useState('') // dung o variant
  const [imagesProduct, setImagesProduct] = useState(['', '', '', '', '', '', '', '', ''])

  const [coverimageProduct, setCoverImageProduct] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingImageCount, setLoadingImageCount] = useState(0)
  const [loadingImageVariant, setLoadingImageVariant] = useState(false)

  const [loadingSaleHistory, setLoadingSaleHistory] = useState(false)
  const [saleHistory, setSaleHistory] = useState([])
  const [loadingRefundHistory, setLoadingRefundHistory] = useState(false)
  const [refundHistory, setRefundHistory] = useState([])
  const [loadingChangePriceHistory, setLoadingChangePriceHistory] = useState(false)
  const [changePriceHistory, setChangePriceHistory] = useState([])
  const [inventory, setInventory] = useState('normal')
  const [typeAdvanced, setTypeAdvanced] = useState(1)
  const [loadInfoImage, setLoadInfoImage] = useState(false)
  const [loadInfoAnother, setLoadInfoAnother] = useState(false)
  const [bulkPrices, setBulkPrices] = useState([{ price: 0 }]) //giá sỉ
  const dataEnventory = [
    {
      title: 'Nâng cao',
      key: 'advanced',
      children: [
        { title: 'Thường', key: 'normal' },
        { title: 'Serial/Imei', key: 'serial' },
        { title: 'Lô - HSD', key: 'hsd' },
      ],
    },
  ]

  const addAttribute = () => {
    let attributesNew = [...attributes]
    attributesNew.push({ option: '', values: [] })
    setAttributes([...attributesNew])
  }

  const removeAttribute = (index) => {
    let attributesNew = [...attributes]
    attributesNew.splice(index, 1)
    setAttributes([...attributesNew])
  }

  const uploadVideoProduct = async (file) => {
    if (file.type !== 'video/mp4') {
      return notification.error({ message: 'Video phải có định dạng MP4' })
    }
    if (file.size > 1024 * 1024 * 25) {
      return notification.error({ message: 'Video không được quá 30MB' })
    }
    setLoadingFile(true)
    const url = await uploadFile(file)
    setLoadingFile(false)
    const filesNew = [...files]
    filesNew.push(url)
    setFiles([...filesNew])
  }

  const addValueVariant = () => {
    let variantsNew = []

    const dataForm = form.getFieldsValue()

    const initVariant = {
      image: imagesProduct || [],
      price: dataForm.price || 0,
      description: '',
      bulk_price: dataForm.price || 0,
      whole_sale_price: dataForm.whole_sale_price,
      import_price: dataForm.import_price || 0,
      enable_bulk_price: true,
      quantity: 0,
    }

    if (attributes.length !== 0) {
      //trường hợp chỉ có 1 attribute
      if (attributes.length === 1) {
        attributes[0].values.map((value, index) => {
          variantsNew.push({
            title: `${(dataForm.name || '').toUpperCase()} ${value.toUpperCase()}`,
            sku: `${dataForm.sku || ''}-${value.toUpperCase()}`,
            options: [{ name: attributes[0].option, value: value }],
            ...initVariant,
          })
        })
      } else if (attributes.length === 2) {
        //trường hợp có 2 attribute
        if (!attributes[1].values.length) {
          attributes[1].values.map((value) => {
            variantsNew.push({
              title: `${(dataForm.name || '').toUpperCase()} ${value.toUpperCase()}`,
              sku: `${dataForm.sku || ''}-${value.toUpperCase()}`,
              options: [{ name: attributes[1].option, value: value }],
              ...initVariant,
            })
          })
        }

        if (!attributes[0].values.length) {
          attributes[0].values.map((value) => {
            variantsNew.push({
              title: `${(dataForm.name || '').toUpperCase()} ${value.toUpperCase()}`,
              sku: `${dataForm.sku || ''}-${value.toUpperCase()}`,
              options: [{ name: attributes[0].option, value: value }],
            })
          })
        }

        if (attributes[0].values.length && attributes[1].values.length)
          attributes[0].values.map((v0) => {
            attributes[1].values.map((v1) => {
              variantsNew.push({
                title: `${(
                  dataForm.name || ''
                ).toUpperCase()} ${v0.toUpperCase()} ${v1.toUpperCase()}`,
                sku: `${dataForm.sku || ''}-${v0.toUpperCase()}-${v1.toUpperCase()}`,
                options: [
                  { name: attributes[0].option, value: v0 },
                  { name: attributes[1].option, value: v1 },
                ],
                ...initVariant,
              })
            })
          })
      } else if (attributes.length === 3) {
        attributes[0].values.map((v0) => {
          attributes[1].values.map((v1) => {
            attributes[2].values.map((v2) => {
              variantsNew.push({
                title: `${(
                  dataForm.name || ''
                ).toUpperCase()} ${v0.toUpperCase()} ${v1.toUpperCase()} ${v2.toUpperCase()}`,
                sku: `${
                  dataForm.sku || ''
                }-${v0.toUpperCase()}-${v1.toUpperCase()}-${v2.toUpperCase()}`,
                options: [
                  { name: attributes[0].option, value: v0 },
                  { name: attributes[1].option, value: v1 },
                  { name: attributes[2].option, value: v2 },
                ],
                ...initVariant,
              })
            })
          })
        })
      }
    }

    variantsNew = variantsNew.map((e) => {
      const variantCurrent = variants.find((v) => v.title === e.title)
      if (variantCurrent) return variantCurrent
      else return e
    })

    setVariants([...variantsNew])
  }

  const defaultValueInventory = () => {
    let variantsNew = []
    const dataForm = form.getFieldsValue()
    const initVariant = {
      image: imagesProduct || [],
      price: dataForm.price || 0,
      description:'',
      bulk_price: dataForm.price || 0,
      whole_sale_price: dataForm.whole_sale_price || 0,
      import_price: dataForm.import_price || 0,
      enable_bulk_price: true,
      quantity: 0,
    }
      variantsNew.push({
        title: dataForm.name,
        sku: dataForm.sku,
        options: [{ name: 'default', value: ['default'] }],
        ...initVariant,
      })

      let attributesNew = []
      attributesNew.push({ option: 'default', values: ['default'] })
      setAttributes([...attributesNew])
      setVariants([...variantsNew])
  }

  const _getSuppliers = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getSuppliers()
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) {
          form.setFieldsValue({ supplier_id: 1 })
          setSuppliers(res.data.data)
        }
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const newData = (value) => {
    if (!value) return
    value.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/g,
      ''
    )
    return value
  }

  const submitProduct = async () => {
    await form.validateFields()

    if (inventory === 'advanced') {
      if (variants.length === 0) {
        notification.error({
          message: 'Vui lòng nhập ít nhất một thuộc tính và ít nhất 1 giá trị của thuộc tinh đó',
        })
        return
      }

      if (variants.find((item) => item.title === '')) {
        notification.error({
          message: 'Vui lòng điền đầy đủ tên thuộc tính cho toàn bộ thuộc tính',
        })
        return
      }
      if (variants.find((item) => item.sku === '')) {
        notification.error({
          message: 'Vui lòng điền đầy đủ sku cho toàn bộ mã sku',
        })
        return
      }
      //validated, prices
      for (let i = 0; i < variants.length; ++i) {
        if (!variants[i].price) {
          variants[i].price = 0
        }
        if (!variants[i].options) {
          notification.error({ message: 'Vui lòng nhập tên thuộc tính' })
          return
        }

        if (!variants[i].title) {
          return notification.error({
            message: `Vui lòng nhập tên cho thuộc tính`,
          })
        }

        if (!variants[i].sku) {
          return notification.error({
            message: `Vui lòng nhập mã cho thuộc tính`,
          })
        }
      }
    }

    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const formProduct = form.getFieldsValue()

      let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
      if (regex.test(formProduct.name)) {
        dispatch({ type: ACTION.LOADING, data: false })
        return notification.error({ message: 'Không được nhập kí tự đặc biệt ở tên sản phẩm' })
      }
      if (regex.test(formProduct.sku)) {
        dispatch({ type: ACTION.LOADING, data: false })
        return notification.error({ message: 'Không được nhập kí tự đặc biệt ở mã sản phẩm' })
      }
      let arrWarrantines = warranties.map(e => e.warranty_id)
      let body = {
        sku: newData(formProduct.sku),
        branch_id: branchIdApp,
        price: formProduct.price || 0,
        whole_sale_price: formProduct.whole_sale_price || 0,
        import_price: formProduct.import_price || 0,
        barcode: formProduct.barcode || '',
        name: newData(formProduct.name),
        category_id: formProduct.category_id || [],
        supplier_id: formProduct.supplier_id,
        length: formProduct.length || '',
        width: formProduct.width || '',
        height: formProduct.height || '',
        weight: formProduct.weight || '',
        unit: formProduct?.unit?.trim() || '',
        files: files || [],
        warranties: arrWarrantines,
        inventory: formProduct.inventory || 'normal',
        description: description ? description.split('&nbsp;').join('') : '',
        images: imagesProduct || [],
        cover_image: coverimageProduct || [],
        price_recipe: formProduct.price_recipe || 'FIFO',
        type_advanced: Number(formProduct.type_advanced) || 0,
      }
      body.attributes = attributes
      const variantsNew = variants.map((v) => ({ ...v, supplier: supplier || '' }))
      body.variants = variantsNew || []
      let res = await addProduct(body)
      if (res.status === 200) {
        notification.success({
          message: `${`Tạo sản phẩm thành công!`}`,
        })
        history.push(ROUTES.PRODUCT)
      } else
        notification.error({
          message: res.data.message || `'Tạo sản phẩm thất bại!`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: `${`Có lỗi xảy ra, vui lòng thử lại!`}`,
      })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const [categories, setCategories] = useState([])
  const _getCategories = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getCategories()
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) {
          setCategories(res.data.data.filter((e) => e.active))
        }
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  /* list input variants */
  const uploadImage = async (file, indexVariant) => {
    try {
      setLoadingImageVariant({ index: indexVariant, loading: true })
      let variantsNew = [...variants]

      const url = await uploadFile(file)
      if (url) variantsNew[indexVariant].image = [url]

      setVariants([...variantsNew])
      setLoadingImageVariant({ index: indexVariant, loading: false })
    } catch (error) {
      console.log(error)
      setLoadingImageVariant({ index: indexVariant, loading: false })
    }
  }

  const UploadImageProduct = ({ variant }) => (
    <Upload
      name="avatar"
      listType="picture-card"
      className="upload-variant-image"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      data={(file) => {
        const isJpgOrPng =
          file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'

        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJpgOrPng) {
          notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
        }

        if (!isLt2M) {
          notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
        }

        if (isJpgOrPng && isLt2M) {
          const indexVariant = variants.findIndex((ob) => ob.title === variant.title)
          if (indexVariant !== -1) uploadImage(file, indexVariant)
        }
      }}
    >
      {variant.image && variant.image.length ? (
        <img
          src={variant.image[0] || ''}
          alt=""
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      ) : (
        <PlusOutlined />
      )}
    </Upload>
  )

  const _getChangePriceHistory = async () => {
    try {
      setLoadingChangePriceHistory(true)
      const res = await getChangePriceHistory({ product_id: location?.state?.product_id })
      if (res.status === 200) {
        setChangePriceHistory(res.data.data)
      }
      setLoadingChangePriceHistory(false)
    } catch (error) {
      setLoadingChangePriceHistory(false)
    }
  }

  const _getSaleHistory = async () => {
    try {
      setLoadingSaleHistory(true)
      const res = await getSaleHistory({ product_id: location?.state?.product_id })
      if (res.status === 200) {
        setSaleHistory(res.data.data)
      }
      setLoadingSaleHistory(false)
    } catch (error) {
      setLoadingSaleHistory(false)
    }
  }

  const _getRefundHistory = async () => {
    try {
      setLoadingRefundHistory(true)
      const res = await getRefundHistory({ product_id: location?.state?.product_id })
      if (res.status === 200) {
        setRefundHistory(res.data.data)
      }
      setLoadingRefundHistory(false)
    } catch (error) {
      setLoadingRefundHistory(false)
    }
  }

  const ModalAddGuarantee = () => {
    return (
      <>
        <Tooltip title="Thêm chính sách bảo hành">
          <Link target="_blank" to={'/guarantee'}>
            <Button
              style={{ marginLeft: 10 }}
              size="large"
              type="primary"
              icon={<PlusOutlined />}
            />
          </Link>
        </Tooltip>
      </>
    )
  }

  const InputSku = ({ value, variant }) => {
    const [valueSku, setValueSku] = useState(value)

    return (
      <Input
        disabled={location.state ? true : false}
        placeholder="Nhập mã phiên bản"
        size="large"
        defaultValue={value}
        onBlur={() => {
          let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
          if (regex.test(valueSku)) {
            return notification.error({ message: 'Không được nhập kí tự đặc biệt ở mã phiên bản' })
          } else {
            let variantsNew = [...variants]
            const index = variantsNew.findIndex((e) => e.title === variant.title)
            variantsNew[index].sku = newData(valueSku)
            setVariants([...variantsNew])
          }
        }}
        onChange={(e) => setValueSku(newData(e.target.value))}
        style={{ width: '100%' }}
      />
    )
  }
  const InputAttribute = ({ value, variant, variants, setVariants }) => {
    const [valueAttribute, setValueAttribute] = useState(value)
    const [visible, setVisible] = useState(false)
    const [description, setDescription] = useState('')
  
    const editDescription = () => {
      let newDataVariant = [...variants]
      let indexVariant = newDataVariant.findIndex((e) => e.sku === variant.sku)
      newDataVariant[indexVariant].description = description
      setVariants([...newDataVariant])
      setVisible(false)
    }
    
    const closeEditDescription = () => {
      setDescription(variant.description)
      setVisible(false)
    }

    return (
      <div>
        <Input
          placeholder="Nhập tên phiên bản"
          size="large"
          defaultValue={value}
          onBlur={() => {
            let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
            if (regex.test(valueAttribute)) {
              return notification.error({
                message: 'Không được nhập kí tự đặc biệt ở tên phiên bản ',
              })
            } else {
              let variantsNew = [...variants]
              const index = variantsNew.findIndex((e) => e.title === variant.title)
              variantsNew[index].title = newData(valueAttribute)
              setVariants([...variantsNew])
            }
          }}
          onChange={(e) => setValueAttribute(e.target.value)}
          style={{ width: '100%' }}
        />
        <div onClick={(e) =>
           {e.stopPropagation()
           setVisible(true)
           setDescription(variant.description)
          }} type="text" style={{textAlign:'center'}}>
          <a>Sửa mô tả</a>
        </div>
      <Modal
        visible={visible}
        closable={false}
        onCancel={closeEditDescription}
        footer={[
          <Row gutter={[16, 0]} justify="end">
            <Col>
              <Button onClick={() => closeEditDescription()}>Đóng</Button>
            </Col>
            <Col>
              <Button onClick={() => editDescription()} type="primary">Xác nhận</Button>
            </Col>
          </Row>,
        ]}
        title="Mô tả phiên bản"
        width={500}
      >
        <Row gutter={[32, 0]}>
          <Col span={24}>
          <Input.TextArea
            placeholder="Mô tả phiên bản sản phẩm"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={7}
            />
          </Col>
        </Row>
      </Modal>
      </div>
      
    )
  }
  const InputQuantity = ({ value, variant }) => {
    const [valueQuantity, setValueQuantity] = useState(value)

    return (
      <InputNumber
        style={{ width: '100%' }}
        placeholder="Nhập số lượng"
        className="br-15__input"
        size="large"
        defaultValue={formatCash(value)}
        min={0}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        onChange={(e) => {
          setValueQuantity(e)
        }}
        onBlur={() => {
          let variantsNew = [...variants]
          const index = variantsNew.findIndex((e) => e.sku === variant.sku)
          variantsNew[index].quantity = valueQuantity
          setVariants([...variantsNew])
        }}
      />
    )
  }

  const UploadAllVariant = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [image, setImage] = useState('')
    const [loading, setLoading] = useState(false)

    const upload = () => {
      if (image !== '') {
        const variantsNew = [...variants]

        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)
          variantsNew[indexVariant].image = [image]
        })

        setVariants([...variantsNew])

        toggle()
      } else {
        notification.warning({ message: 'Vui lòng chọn 1 ảnh!' })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) setImage('')
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<FileImageOutlined />}>
          Chỉnh sửa ảnh
        </Button>
        <Modal
          visible={visible}
          title="Chọn ảnh"
          onCancel={toggle}
          onOk={upload}
          footer={
            <Row justify="end">
              <Space>
                <Button onClick={() => setVisible(false)}>Đóng</Button>
                <Button loading={loading} type="primary" onClick={upload}>
                  Lưu
                </Button>
              </Space>
            </Row>
          }
        >
          <Upload
            name="avatar"
            fileList={[]}
            listType="picture-card"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            data={async (file) => {
              const isJpgOrPng =
                file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'

              const isLt2M = file.size / 1024 / 1024 < 2

              if (!isJpgOrPng) {
                notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
              }

              if (!isLt2M) {
                notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
              }

              if (isJpgOrPng && isLt2M && file) {
                setLoading(true)
                const url = await uploadFile(file)
                if (url) setImage(url || '')
                setLoading(false)
              }
            }}
          >
            {image ? <img src={image} alt="" style={{ width: '100%' }} /> : <PlusOutlined />}
          </Upload>
        </Modal>
      </>
    )
  }

  const EditSku = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [valueSku, setValueSku] = useState('')

    const edit = () => {
      if (valueSku) {
        let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
        if (regex.test(valueSku)) {
          return notification.error({
            message: 'Không được nhập kí tự đặc biệt ở mã phiên bản ',
          })
        }
        let variantsNew = [...variants]

        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)
          variantsNew[indexVariant].sku = valueSku
        })

        setVariants([...variantsNew])
        toggle()
      } else {
        return notification.error({
          key: 'error invalid variant',
          message: 'Vui lòng nhập mã phiên bản!',
        })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) setValueSku('')
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<EditOutlined />}>
          Chỉnh sửa sku
        </Button>
        <Modal visible={visible} onCancel={toggle} onOk={edit} title="Nhập sku">
          <Input
            placeholder="Nhập mã phiên bản"
            size="large"
            value={valueSku}
            onChange={(e) => setValueSku(e.target.value)}
            style={{ width: '100%' }}
          />
        </Modal>
      </>
    )
  }

  const EditAttribute = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [valueAttribute, setValueAttribute] = useState('')

    const edit = () => {
      if (valueAttribute) {
        let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
        if (regex.test(valueAttribute)) {
          return notification.error({
            message: 'Không được nhập kí tự đặc biệt ở tên thuộc tính',
          })
        }
        let variantsNew = [...variants]
        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)
          variantsNew[indexVariant].title = valueAttribute
        })

        setVariants([...variantsNew])
        toggle()
      } else {
        return notification.error({
          key: 'error invalid variant',
          message: 'Vui lòng nhập tên thuộc tính!',
        })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) setValueAttribute('')
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<EditOutlined />}>
          Chỉnh sửa tên thuộc tính
        </Button>
        <Modal visible={visible} onCancel={toggle} onOk={edit} title="Nhập tên thuộc tính">
          <Input
            placeholder="Nhập tên thuộc tính"
            size="large"
            value={valueAttribute}
            onChange={(e) => setValueAttribute(e.target.value)}
            style={{ width: '100%' }}
          />
        </Modal>
      </>
    )
  }

  const EditPrice = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [valueSalePrice, setValueSalePrice] = useState('')

    const edit = () => {
      if (valueSalePrice) {
        let variantsNew = [...variants]

        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)

          variantsNew[indexVariant].price = valueSalePrice
        })

        setVariants([...variantsNew])

        toggle()
      } else {
        return notification.error({
          key: 'error invalid variant',
          message: 'Vui lòng nhập giá bán!',
        })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) {
        setValueSalePrice('')
      }
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<DollarOutlined />}>
          Chỉnh sửa giá
        </Button>
        <Modal visible={visible} onCancel={toggle} onOk={edit} title="Nhập giá">
          <Space size="middle" direction="vertical">
            <div>
              <span style={{ marginBottom: 0 }}>Giá bán</span>
              <InputNumber
                placeholder="Nhập giá bán"
                className="br-15__input"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                size="large"
                min={0}
                onChange={(value) => setValueSalePrice(value)}
                style={{ width: '100%' }}
              />
            </div>
          </Space>
        </Modal>
      </>
    )
  }

  const columnsVariant = [
    {
      width: 90,
      title: 'Hình ảnh',
      render: (text, record, index) => (
        <Spin spinning={loadingImageVariant.index == index && loadingImageVariant.loading}>
          {record?.image[0]?.length > 0 ? (
            <Badge
              count={
                <CloseCircleFilled
                  onClick={() => {
                    let variantsNew = [...variants]
                    variantsNew[index].image[0] = ''
                    setVariants([...variantsNew])
                  }}
                  style={{ fontSize: 18, color: '#ff4d4f', cursor: 'pointer' }}
                />
              }
            >
              <UploadImageProduct variant={record}/>
            </Badge>
          ) : (
            <UploadImageProduct variant={record}/>
          )}
        </Spin>
      ),
    },
    {
      title: 'Tên phiên bản',
      render: (text, record) => <InputAttribute value={record.title} variant={record} variants={variants} setVariants={setVariants} />
      // <ModalVariant 
      //   data={record}
      //   setVariants={setVariants}
      //   variants={variants}
      //   newData={newData}
      // />,
    },
    {
      title: 'Mã phiên bản',
      render: (text, record) => <InputSku value={record.sku} variant={record} />,
    },
    {
      width: 180,
      title: 'Giá phiên bản',
      render: (text, record) => (
        <ModalPrice data={record} setVariants={setVariants} variants={variants} />
      ),
    },
    {
      width: 90,
      title: 'Số lượng',
      render: (text, record) =>
        typeAdvanced == 1 && <InputQuantity value={record.quantity} variant={record} />,
    },
  ]

  const _getWarranties = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getWarranties({ branch_id: branchIdApp })
      if (res.status === 200) {
        setWarranties(res.data.data)
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const initProductWithEditProduct = async () => {
    form.setFieldsValue({ unit: 'Cái' })

    await delay(100)
    setIsRenderFirst(true)
    // setKeyTab('3')
    await delay(100)
    setKeyTab('1')
  }

  const initBulkPriceDefaultByPrice = (price) => {
    var bulk_prices = [...bulkPrices]
    bulk_prices[0].price = price
    setBulkPrices(bulk_prices)
  }

  useEffect(() => {
    _getWarranties()
  }, [branchIdApp])

  useEffect(() => {
    _getSuppliers()
    _getCategories()
    _getSaleHistory()
    _getRefundHistory()
    _getChangePriceHistory()
  }, [])

  //get width device
  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true)
    else setIsMobile(false)
  }, [])

  useEffect(() => {
    if (isRenderFirst) addValueVariant()
  }, [attributes])

  //update product
  useEffect(() => {
    form.setFieldsValue({ price: 0 })
    initProductWithEditProduct()
  }, [])

  return !isMobile ? (
    <div className="card">
      <TitlePage
        isAffix={true}
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.PRODUCT)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            <div>{'Thêm mới sản phẩm'}</div>
          </Row>
        }
      >
        <Space>
          <Button
            icon={<ReloadOutlined />}
            style={{ display: !location.state && 'none' }}
            size="large"
            onClick={() => history.go(0)}
          >
            Tải lại
          </Button>
          <Button icon={<EditOutlined />} size="large" type="primary" onClick={submitProduct}>
            {'Thêm'}
          </Button>
        </Space>
      </TitlePage>

      <Form form={form} layout="vertical" style={{ width: '100%', marginTop: 15, display: 'flex'}}>
        <div className={styles['container-left']}>
          <div className={styles['container-ttcb']}>
            <div className={styles['title-container']}>Thông tin chung</div>
            <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
            <Row gutter={[32, 0]}>
              <Col>
                <Form.Item
                  label="Tên sản phẩm"
                  name="name"
                  rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }]}
                >
                  <Input
                    disabled={location.state ? true : false}
                    size="large"
                    placeholder="Nhập tên sản phẩm"
                    onBlur={(e) => {
                      const generatedItemsSku = e.target.value.split(' ')
                      const valueSku = generatedItemsSku
                        .map((items) => (items[0] ? removeAccents(items[0]).toUpperCase() : ''))
                        .join('')

                      form.setFieldsValue({ sku: newData(valueSku) })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Mã (SKU)"
                  name="sku"
                  rules={[{ message: 'Vui lòng nhập mã sản phẩm/SKU', required: true }]}
                >
                  <Input
                    disabled={location.state ? true : false}
                    size="large"
                    placeholder="Nhập mã sản phẩm/sku"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col>
                <Form.Item label="Barcode" name="barcode">
                  <Input
                    disabled={location.state ? true : false}
                    size="large"
                    placeholder="Nhập barcode"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Đơn vị"
                  name="unit"
                  initialValue={'Cái'}
                  rules={[
                    {
                      message: 'Vui lòng chỉ nhập ký tự chữ!',
                      pattern: regexRemoveUnicodeNoNumber,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Đơn vị" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={styles['container-ttcb']}>
            <div className={styles['title-container']}>Giá sản phẩm</div>
            <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
            <Row gutter={[32, 0]}>
              <Col>
                <Form.Item
                  className={styles['input']}
                  label="Giá bán lẻ"
                  name="price"
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]*$/,
                      message: 'Giá bán lẻ không hợp lệ',
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    size="large"
                    min={0}
                    placeholder="Nhập giá bán"
                    style={{ width: '100%' }}
                    className="br-15__input"
                    formatter={formatCash}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[32, 0]}>
              <Col>
                <Form.Item className={styles['input']} label="Giá bán sỉ" name="whole_sale_price">
                  <InputNumber
                    controls={false}
                    size="large"
                    min={0}
                    placeholder="Nhập giá bán sỉ"
                    style={{ width: '100%' }}
                    className="br-15__input"
                    formatter={formatCash}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item className={styles['input']} label="Giá nhập" name="import_price">
                  <InputNumber
                    controls={false}
                    size="large"
                    min={0}
                    placeholder="Nhập giá nhập"
                    style={{ width: '100%' }}
                    className="br-15__input"
                    formatter={formatCash}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col xs={12} sm={12} md={10} lg={10} xl={10}>
              <a>
                {dataUser &&
                  dataUser.data &&
                  dataUser.data.price_recipe &&
                  `* Giá vốn được tính theo công thức ${dataUser.data.price_recipe}`}
              </a>
            </Col>
            <Col></Col>
          </div>
          <div className={styles['container-ttcb']}>
            <Row gutter={[32, 0]}>
              <Col span={21}>
                <div className={styles['title-container']}>Hình ảnh sản phẩm</div>
              </Col>
              <Col span={3}>
                <Switch value={loadInfoImage} onChange={() => setLoadInfoImage(!loadInfoImage)} />
              </Col>
            </Row>
            <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
            {loadInfoImage === true ? (
              <>
                <Row style={{ marginBottom: 10 }}>
                  {imagesProduct.map((image, index) =>
                    image ? (
                      <div className={styles['create-product-image-upload']}>
                        <Badge
                          count={
                            <CloseCircleFilled
                              onClick={() => {
                                const imagesNew = [...imagesProduct]
                                imagesNew[index] = ''
                                setImagesProduct([...imagesNew])
                              }}
                              style={{ fontSize: 18, color: '#ff4d4f', cursor: 'pointer' }}
                            />
                          }
                        >
                          <img
                            src={image}
                            alt=""
                            style={{
                              width: 102,
                              height: 102,
                              margin: 2,
                              marginRight: 10,
                              objectFit: 'cover',
                            }}
                          />
                        </Badge>
                        <Row style={{ width: 75, marginTop: 9 }} justify="center">
                          {index === 0 ? (
                            <div style={{ color: 'black' }}>Ảnh bìa</div>
                          ) : (
                            <div style={{ color: 'gray' }}>Hình ảnh {index}</div>
                          )}
                        </Row>
                      </div>
                    ) : (
                      <Spin spinning={index < loadingImageCount ? loadingImage : false}>
                        <div>
                          <Upload
                            accept="image/*"
                            multiple
                            style={{ width: 'max-content', marginRight: 10, marginBottom: 10 }}
                            name="avatar"
                            listType="picture-card"
                            className={styles['create-product-upload-img']}
                            showUploadList={false}
                            onChange={async (info) => {
                              let newCount = (imagesProduct?.filter(
                                (item) => item?.length > 0
                              )).length
                              setLoadingImageCount(info?.fileList?.length + newCount)
                              if (
                                info.file.type !== 'image/png' &&
                                info.file.type !== 'image/jpg' &&
                                info.file.type !== 'image/jpeg'
                              ) {
                                return notification.error({
                                  key: 'check file img',
                                  message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!',
                                })
                              }
                              if (info.file.size > 1024 * 1024 * 2) {
                                return notification.error({
                                  key: 'check file img',
                                  message: 'Hình ảnh không được lớn hơn 2MB!',
                                })
                              }
                              if (typingTimeoutRef.current) {
                                clearTimeout(typingTimeoutRef.current)
                              }
                              typingTimeoutRef.current = setTimeout(async () => {
                                setLoadingImage(true)
                                const urls = await uploadFiles(
                                  info.fileList.map((file) => file.originFileObj)
                                )

                                if (urls) {
                                  const imagesProductNew = [...imagesProduct]
                                  for (let i = 0; i < urls.length; i++)
                                    for (let j = 0; j < imagesProductNew.length; j++)
                                      if (!imagesProductNew[j]) {
                                        imagesProductNew[j] = urls[i]
                                        break
                                      }
                                  setImagesProduct([...imagesProductNew])
                                }
                                setLoadingImage(false)
                                setLoadingImageCount(0)
                              }, 450)
                            }}
                          >
                            <div>
                              <PlusOutlined />
                              {/* <div style={{ marginTop: 8 }}>T?i l�n</div> */}
                            </div>
                          </Upload>
                          <Row style={{ width: 75, marginTop: 3 }} justify="center">
                            {index === 0 ? (
                              <div style={{ color: 'black' }}>Ảnh bìa</div>
                            ) : (
                              <div style={{ color: 'gray' }}>Hình ảnh {index}</div>
                            )}
                          </Row>
                        </div>
                      </Spin>
                    )
                  )}
                </Row>
                <div style={{ marginBottom: 10, marginTop: 25 }}>
                  <span style={{ fontWeight: 500 }}>Video sản phẩm</span>
                  <br />
                  <Upload
                    accept="video/*"
                    fileList={files?.map((file, index) => {
                      const fileSplit = file?.split('/') || []
                      const nameFile = fileSplit.length > 0 && fileSplit[fileSplit.length - 1]
                      return {
                        uid: index,
                        name: nameFile ? nameFile : 'file',
                        status: 'done',
                        url: file,
                      }
                    })}
                    onRemove={(file) => {
                      const indexRemove = files.findIndex((url) => url === file)
                      if (indexRemove) {
                        const filesNew = [...files]
                        filesNew.splice(indexRemove, 1)
                        setFiles([...filesNew])
                      }
                    }}
                    data={uploadVideoProduct}
                    onChange={(info) => {
                      if (info.file.status !== 'done') info.file.status = 'done'
                    }}
                  >
                    <Button loading={loadingFile} style={{ width: 120 }} icon={<UploadOutlined />}>
                      Chọn file
                    </Button>
                  </Upload>
                  <Row gutter={[30, 0]}>
                    <Col span={8}>
                      {files.map((video) => (
                        <Col span={24}>
                          <video width="100%" height="240" controls>
                            <source
                              style={{ width: 200, height: 150 }}
                              src={video}
                              type="video/mp4"
                            />
                          </video>
                        </Col>
                      ))}
                    </Col>
                    <Col span={16}>
                      <ul style={{ color: '#b7b7b7' }}>
                        <li>Kích thước: Tối đa 25Mb</li>
                        <li>Định dạng: MP4 </li>
                        <li>
                          Lưu ý: sản phẩm có thể hiển thị trong khi video đang được xử lý. Video sẽ
                          tự động hiển thị sau khi đã xử lý thành công..
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <span>Thông tin hình ảnh sản phẩm</span>
            )}
          </div>

          <div className={styles['container-ttcb']}>
            <Row gutter={[32, 0]}>
              <Col span={21}>
                <div className={styles['title-container']}>Thông tin bổ sung</div>
              </Col>
              <Col span={3}>
                <Switch
                  value={loadInfoAnother}
                  onChange={() => setLoadInfoAnother(!loadInfoAnother)}
                />
              </Col>
            </Row>
            <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
            {loadInfoAnother === true ? (
              <>
                <Col>
                  <Row wrap={false} align="middle">
                    <Form.Item
                      label="Nhà cung cấp"
                      name="supplier_id"
                      style={{ width: 300, marginRight: 16 }}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Chọn nhà cung cấp"
                        onChange={(value) => {
                          const supplier = suppliers.find((s) => s.supplier_id === value)
                          supplier && setSupplier(supplier.name)
                        }}
                      >
                        {suppliers.map((values, index) => {
                          return (
                            <Select.Option value={values.supplier_id} key={index}>
                              {values.name}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Permission permissions={[PERMISSIONS.them_nha_cung_cap]}>
                      <Tooltip title="Tạo nhà cung cấp">
                        <Link target="_blank" to="/supplier">
                          <Button size="large" type="primary" icon={<PlusOutlined />} />
                        </Link>
                      </Tooltip>
                    </Permission>
                  </Row>
                </Col>
                <Col>
                  <Row wrap={false} align="middle">
                    <Form.Item
                      label="Nhóm sản phẩm"
                      name="category_id"
                      style={{ width: 300, marginRight: 16 }}
                    >
                      <TreeSelect
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        size="large"
                        style={{ width: '100%' }}
                        treeNodeFilterProp="title"
                        maxTagCount="responsive"
                        placeholder="Chọn nhóm sản phẩm"
                        allowClear
                        multiple
                        treeDefaultExpandAll
                      >
                        {categories.map((category) => (
                          <TreeSelect.TreeNode value={category.category_id} title={category.name}>
                            {category.children_category.map((child) => (
                              <TreeSelect.TreeNode value={child.category_id} title={child.name}>
                                {child.children_category.map((e) => (
                                  <TreeSelect.TreeNode value={e.category_id} title={e.name}>
                                    {e.name}
                                  </TreeSelect.TreeNode>
                                ))}
                              </TreeSelect.TreeNode>
                            ))}
                          </TreeSelect.TreeNode>
                        ))}
                      </TreeSelect>
                    </Form.Item>
                    <Permission permissions={[PERMISSIONS.tao_nhom_san_pham]}>
                      <Tooltip title="Tạo nhóm sản phẩm">
                        <Link target="_blank" to={'/categories'}>
                          <Button size="large" type="primary" icon={<PlusOutlined />} />
                        </Link>
                      </Tooltip>
                    </Permission>
                  </Row>
                </Col>
                <Row>
                  <Col>
                    <Form.Item
                      style={{ width: 300, marginRight: 8 }}
                      rules={[
                        {
                          pattern: /^[0-9]*$/,
                          message: 'Cân nặng chỉ cho phép nhập số dương!',
                        },
                      ]}
                      label="Khối lượng (g)"
                      name="weight"
                    >
                      <InputNumber
                        allowClear
                        style={{ width: '100%' }}
                        className="br-15__input"
                        size="large"
                        placeholder="Khối lượng (g)"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ width: '100%', marginTop: 20 }}>
                  <Col>
                    <div>Thêm chính sách bảo hành (nếu có)</div>
                    <Row wrap={false}>
                      <Select
                        size="large"
                        mode="multiple"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Chọn chính sách bảo hành"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => setIdsWarranty(value)}
                        value={idsWarranty}
                      >
                        <Select.Option value={''}>Không áp dụng chính sách bảo hành</Select.Option>
                        {warranties.map((values, index) => (
                          <Select.Option value={values.warranty_id} key={index}>
                            {values.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <ModalAddGuarantee />
                    </Row>
                  </Col>
                </Row>
                <Col style={{ marginTop: 2, marginBottom: 15 }}>
                  <div>Mô tả sản phẩm</div>
                  <Input.TextArea
                    placeholder="Mô tả sản phẩm"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={7}
                  />
                </Col>
              </>
            ) : (
              <span>Thông tin bổ sung sản phẩm</span>
            )}
          </div>
        </div>

        <div className={styles['container-right']}>
          <div className={styles['container-ttcb']}>
            <div className={styles['title-container']}>Tuỳ chọn tồn kho</div>
            <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>

            <Row>
              <Form.Item name="inventory" label="Tuỳ chọn tồn kho">
                <Radio.Group
                  defaultValue="normal"
                  onChange={(e) => {
                    setInventory(e.target.value)
                  }}
                >
                  <Radio value="normal">Sản phẩm không tồn kho</Radio>
                  <Radio value="advanced">Sản phẩm có tồn kho</Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            {inventory === 'advanced' && (
              <Row>
                <Form.Item
                  name="type_advanced"
                  label="Tuỳ chọn loại của sản phẩm có tồn kho"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn loại tồn kho cho sản phẩm có tồn kho',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setTypeAdvanced(e.target.value)
                      defaultValueInventory()
                    }}
                  >
                    <Radio value={1}>Tồn thường</Radio>
                    <Radio value={2}>Tồn theo lô & HSD</Radio>
                    <Radio value={3}>Tồn theo iMei</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
            )}
            {typeAdvanced !== 1 && (
              <p>
                Đối với sản phẩm tồn kho theo lô & HSD Hoặc tồn kho theo iMei, hệ thống chưa hỗ trợ
                khởi tạo kho. Sau khi thêm mới sản phẩm, bạn tạo đơn nhập hàng để sẵn sàng quản lý.
              </p>
            )}
          </div>

          <div className={styles['container-ttcb']}>
            <div className={styles['title-container']}>Danh sách phiên bản</div>
            <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
            {/* <Row > */}
            {inventory === 'normal' ? (
              <span>Đối với sản phẩm tồn kho cơ bản, hệ thống không hỗ trợ thêm phiên bản</span>
            ) : (
              <div style={{ width: '100%', marginTop: 35 }}>
                <div
                  style={{
                    marginBottom: 16,
                    border: '1px solid #f0f0f0',
                    padding: 16,
                    width: '100%',
                  }}
                >
                  <div style={{ borderBottom: '1px solid #f0f0f0', width: '100%' }}>
                    <div style={{ width: '100%', paddingBottom: 8 }}>
                      <h3 style={{ marginBottom: 0, fontWeight: 700 }}>Thuộc tính</h3>
                    </div>
                  </div>
                  {attributes.map((e, index) => {
                    const RenderInput = () => (
                      <Input
                        disabled={location.state ? true : false}
                        size="large"
                        placeholder="Nhập tên thuộc tính"
                        defaultValue={e.option}
                        onBlur={(event) => {
                          let regex =
                            /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
                          if (regex.test(event.target.value)) {
                            return notification.error({
                              key: 'check value attribute',
                              message: 'Không được nhập kí tự đặc biệt ở tên thuộc tính',
                            })
                          } else {
                            const optionName = event.target.value
                            const option = attributes.find(
                              (attr) => attr.option === optionName && optionName
                            )
                            if (option) {
                              notification.warning({ message: 'Bạn đã thêm thuộc tính này rồi' })
                              attributes[index].option = ''
                              return
                            }
                            attributes[index].option = optionName
                          }
                        }}
                        style={{ width: '100%' }}
                      />
                    )
                    return (
                      <Row
                        style={{ width: '100%', marginBottom: 15 }}
                        justify="start"
                        gutter={10}
                        align="middle"
                      >
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                          <span style={{ marginBottom: 0 }}>Tên thuộc tính</span>
                          <RenderInput />
                        </Col>
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                          <span style={{ marginBottom: 0 }}>Giá trị</span>
                          <Select
                            // disabled={location.state ? true : false}
                            mode="tags"
                            size="large"
                            style={{ width: '100%' }}
                            placeholder="Nhập giá trị"
                            value={e.values.map((v) => v)}
                            onDeselect={(v) => {
                              let items = [...attributes]
                              // //check value add này đã tồn tại chưa
                              const indexRemove = e.values.findIndex((f) => f === v)
                              if (indexRemove !== -1) {
                                items[index].values.splice(indexRemove, 1)
                                setAttributes([...items])
                              }
                            }}
                            onSelect={(e) => {
                              //add tag
                              let items = [...attributes]
                              const splitValue = e.split(',')
                              let newValue = [...new Set(splitValue)]
                              for (let i = 0; i < items.length; i++) {
                                for (let j = 0; j < items[i].values.length; j++) {
                                  if (items[i].values[j] === e) {
                                    notification.error({ message: 'Giá trị đã có!' })
                                    return
                                  }
                                }
                              }
                              newValue.map((v) => {
                                const checkAttribute = /[^A-Za-z\d ]/gi.test(removeUnicode(v))
                                if (v && checkAttribute === false)
                                  items[index].values.push(v.trim())
                                else
                                  notification.warning({
                                    message: 'Vui lòng không sử dụng ký tự đặc biệt!',
                                  })
                              })
                              setAttributes([...items])
                            }}
                            optionLabelProp="label"
                          ></Select>
                        </Col>
                        <Popconfirm
                          title="Bạn có muốn xoá thuộc tính này?"
                          onConfirm={() => removeAttribute(index)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <CloseOutlined
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                              fontSize: 18,
                              marginTop: 22,
                              marginLeft: 5,
                              display: attributes.length === 1 && 'none',
                              visibility: location.state && 'hidden',
                            }}
                          />
                        </Popconfirm>
                      </Row>
                    )
                  })}{' '}
                  <Tooltip title="Tối đa tạo 3 thuộc tính">
                    <Button
                      size="large"
                      style={{
                        marginTop: 17,
                        display: (attributes.length === 3 || location.state) && 'none',
                      }}
                      onClick={addAttribute}
                      disabled={location.state ? true : false}
                    >
                      Thêm thuộc tính
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ marginBottom: 16, border: '1px solid #f0f0f0', width: '100%' }}>
                  <div
                    style={{
                      marginLeft: 10,
                      marginTop: 10,
                      marginBottom: 20,
                      display: !selectRowKeyVariant.length && 'none',
                    }}
                  >
                    <Space wrap>
                      <UploadAllVariant />
                      <EditAttribute />
                      {!location.state && <EditSku />}
                      <EditPrice />
                    </Space>
                  </div>
                  <Table
                    rowKey="title"
                    columns={columnsVariant}
                    dataSource={variants}
                    pagination={false}
                    rowSelection={{
                      selectedRowKeys: selectRowKeyVariant,
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectRowKeyVariant(selectedRowKeys)
                      },
                    }}
                    size="small"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Form>
      <Modal
        title="Danh sách hình ảnh"
        visible={isModalVisible}
        onCancel={toogleModalPicture}
        footer={false}
      >
        <ModalPicture
          imageBig={imageBig}
          setImageBig={setImageBig}
          modalDefaultImg={modalDefaultImg}
          imageData={imagesProduct}
          toogleModalPicture={toogleModalPicture}
        />
      </Modal>
    </div>
  ) : (
    <NotSupportMobile />
  )
}
