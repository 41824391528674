import React from 'react'
//antd
import moment from 'moment'
import { formatCash } from 'utils'

export default class PrintLockShift extends React.PureComponent {
  render() {
    const data = this.props.data || {}

    return (
      <div style={{ width: '100%', fontSize: 8, fontFamily: 'Arial,sans-serif' }}>
        <div>
          <div style={{ textAlign: 'center', fontSize: 15, fontWeight: 'bold', marginBottom: 5 }}>
            {data.business_name}
          </div>
          {data.branch !== '' ? (
            <>
              <div style={{ fontSize: 10 }}>{`Chi nhánh: ${data.branch.name}`}</div>
              <div>
                <span>Địa chỉ: </span>
                <span style={{ marginLeft: 3 }}>{data.branch.address}</span>
              </div>
            </>
          ) : (
            <div>
              <span>Địa chỉ: </span>
              <span style={{ marginLeft: 3 }}>{data.business_address}</span>
            </div>
          )}
          <div>
            <span>Điện thoại: </span>
            <span style={{ marginLeft: 3 }}>{data.business_phone}</span>
          </div>
          <div>
            <span>Thời điểm: </span>
            <span style={{ marginLeft: 3 }}>{moment().format('DD-MM-YYYY HH:mm')}</span>
          </div>
          <div>
            <span>Nhân viên: </span>
            {data.employee_name.map((item) => (
              <span style={{ marginLeft: 3 }}>{item} </span>
            ))}
          </div>
        </div>
        <hr />
        <div style={{ textAlign: 'center', marginBottom: 0, fontWeight: 'bold', fontSize: 10 }}>
          Đơn chốt ca
        </div>
        <table style={{ borderCollapse: 'collapse' }}>
          <tr>
            <th style={{ border: '0.5px dotted #c1c1c1' }}>Phương thức thanh toán</th>
            <th style={{ border: '0.5px dotted #c1c1c1' }}>Giá trị</th>
          </tr>
          {data.dataPayments.map((e) => (
            <tr>
              <td style={{ border: '0.5px dotted #c1c1c1' }}>{e.method}</td>
              <td style={{ border: '0.5px dotted #c1c1c1' }}>{formatCash(e.value)}</td>
            </tr>
          ))}
        </table>
        <div style={{ textAlign: 'center', marginBottom: 0, fontWeight: 'bold', fontSize: 10 }}>
          Thu chi
        </div>
        <table style={{ borderCollapse: 'collapse' }}>
          <tr>
            <th style={{ border: '0.5px dotted #c1c1c1' }}>Loại thu chi</th>
            <th style={{ border: '0.5px dotted #c1c1c1' }}>Giá trị</th>
          </tr>
          {data.dataReven.map((e) => (
            <tr>
              <td style={{ border: '0.5px dotted #c1c1c1' }}>
                <>
                  <p style={{marginBottom: 0 }}>{e._id}</p>
                  {e?.payments.map((e) => (
                    <span style={{ fontSize: 5 }}>{e + ' '}</span>
                  ))}
                </>
              </td>
              <td style={{ border: '0.5px dotted #c1c1c1' }}>{formatCash(e.value)}</td>
            </tr>
          ))}
        </table>
        <hr />
        <div>
          <div>
            <span>Tiền mặt đúng: </span>
            <span style={{ marginLeft: 3 }}>
              {formatCash(data.systemCash + data.sumReceipt - data.sumPayment)}
            </span>
          </div>
          <div>
            <span>Tiền mặt thực tế: </span>
            <span style={{ marginLeft: 3 }}>{formatCash(data.realCash)}</span>
          </div>
          <div>
            <span>Tổng doanh thu: </span>
            <span style={{ marginLeft: 3 }}>{formatCash(data.turnoverOrder)}</span>
          </div>
          <div>
            <span>Tình trạng: </span>
            <span style={{ marginLeft: 3 }}>{data.status}</span>
          </div>
          <div>
            <span>Nhân viên chốt ca: </span>
            <span style={{ marginLeft: 3 }}>{data.user}</span>
          </div>
          <div>
            <span>Ghi chú: </span>
            <span style={{ marginLeft: 3 }}>{data.note}</span>
          </div>
        </div>
      </div>
    )
  }
}
