import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

//antd
import {  Row, InputNumber, Space,  Select, Col, Button, Modal,notification } from 'antd'

//icons

import { formatCash } from 'utils'

//apis
import { getPayments } from 'apis/payment'

export default function PaymentMethods({
  sumCostPaidData,
  editInvoice,
  setVisible,
  visible,
  listPaymentsR
}) {
  const { t } = useTranslation()
  const toggle = () => setVisible(!visible)

  const [paymentsMethod, setPaymentsMethod] = useState([])
  const [payments, setPayments] = useState([])
  const [paymentMethodDefault, setPaymentMethodDefault] = useState({}) //hình thức thanh toán mặc định
  const [costPaid, setCostPaid] = useState(0)


  const _onChangeValue = (value, payment_id) => {
    let paymentsNew = [...payments]
    let indexPayment = paymentsNew.findIndex((item) => item.payment_method_id == payment_id)

    paymentsNew[indexPayment].value = value
    let total = paymentsNew.reduce((sum, e) => sum += e.value, 0)
    setPayments(paymentsNew)
    setCostPaid(total)
  }

  const _addPaymentMethod = (paymentIdListSeleted) => {
    var _payment_new = [...paymentsMethod]
    var _payment_current = [...payments]
    _payment_new = _payment_new
    .filter((item) => paymentIdListSeleted.includes(item.payment_method_id))
    .map((item) => {
      return {
        payment_method_id: item.payment_method_id,
        name: item.name,
        value:Object(_payment_current.find((e) => e.payment_method_id == item.payment_method_id)).value || 0,
      }
    })
    let totalPaymentSelect = 0

  if (paymentIdListSeleted.length > 0) {
    if (paymentIdListSeleted.length > _payment_current.length) {
      let newValue = paymentIdListSeleted.pop()
      let indexPaymentSelect = _payment_new.findIndex(
        (item) => item.payment_method_id === newValue
      )
      totalPaymentSelect = _payment_new.reduce((total, item) => (total += item.value), 0)
      if (totalPaymentSelect < sumCostPaidData) {
        _payment_new[indexPaymentSelect].value = sumCostPaidData - totalPaymentSelect

        totalPaymentSelect = _payment_new.reduce((total, item) => (total += item.value), 0)
      } else {
        _payment_new[indexPaymentSelect].value = 0
      }
    }
  }
    setPayments(_payment_new)
    setCostPaid(totalPaymentSelect)
  }
  const _removePaymentMethod = (payment_method_id) => {
    let paymentsNew = [...payments]
    paymentsNew = paymentsNew.filter(
      (item) => parseInt(item.payment_method_id) !== parseInt(payment_method_id)
    )
    let sumCostPaid = 0
    if(paymentsNew.length > 1) {
      sumCostPaid = paymentsNew.reduce((total, current) => total + current.value, 0)
    }else if(paymentsNew.length === 1){
      paymentsNew[0].value = sumCostPaidData
      sumCostPaid = sumCostPaidData
    }
  
    setCostPaid(sumCostPaid)
    setPayments([...paymentsNew])
  }
  const _exit = () => {
    let _payment_new = [...payments]
    for (let index in _payment_new) {
      if (Number(_payment_new[index].value) == 0) {
        _payment_new.splice(index, 1)
      }
    }

    editInvoice(costPaid, _payment_new)
    toggle()
  }
  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        let paymentMethodDefault = ''
        if (res.data.data.length == 0) return

        setPaymentsMethod(
          res.data.data
            .filter((e) => e.payment_method_id !==2 && e.active)
            .map((e) => {
              if (e.default) paymentMethodDefault = e
              return e
            })
        )
        if(listPaymentsR.length == 0){
          if (paymentMethodDefault) {
            setPaymentMethodDefault({
              name: paymentMethodDefault.name,
              value: sumCostPaidData,
              payment_method_id: paymentMethodDefault.payment_method_id,
            })
            setPayments([
              {
                name: paymentMethodDefault.name,
                value: sumCostPaidData,
                payment_method_id: paymentMethodDefault.payment_method_id,
              },
            ])
            setCostPaid(sumCostPaidData)
          } else {
            setPaymentMethodDefault({
              name: res.data.data[0].name,
              value: sumCostPaidData,
              payment_method_id: res.data.data[0].payment_method_id,
            })
            setPayments([
              {
                name: res.data.data[0].name,
                value: sumCostPaidData,
                payment_method_id: res.data.data[0].payment_method_id,
              },
            ])
            setCostPaid(sumCostPaidData)
          }
        }else{
          setPayments(listPaymentsR)
          let total = listPaymentsR.reduce((sum, e) => sum += e.value, 0)
          setPaymentMethodDefault(listPaymentsR)
          setCostPaid(total)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }  
  useEffect(() => {
    _getPayments()
  }, [sumCostPaidData, listPaymentsR])

  return (
    <>
    <Button onClick={_exit} type='primary'>Chọn Phương thức thanh toán</Button>
      <Modal
        width={540}
        footer={
          <Row justify="end">
            <Button type='primary' style={{ width: 100, borderRadius: 5 }} onClick={_exit}>
              Chọn
            </Button>
            
          </Row>
        }
        title={t('sell.payment_methods')}
        onOk={_exit}
        onCancel={_exit}
        visible={visible}
      >
        <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
          {t('sell.can_choose_multiple_payment_methods')}
        </p>
        <Space direction="vertical" size="middle" style={{ width: '100%', fontSize: 18 }}>
          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <p>Tiền phải trả</p>
            <p>{formatCash(sumCostPaidData)}</p>
          </Row>

          <Row wrap={false} justify="space-between" align="middle">
            <Select
              value={payments.map((item) => item.payment_method_id)}
              defaultValue={paymentMethodDefault.payment_method_id}
              placeholder={t('sell.choose_a_form_of_payment')}
              style={{ width: '100%' }}
              mode="multiple"
              allowClear
              onChange={(list) => {
                _addPaymentMethod(list)
              }}
              onDeselect={(a, b) => {
                _removePaymentMethod(b.key)
              }}
            >
              {paymentsMethod.map((paymentMethod, index) => (
                <Select.Option
                  value={paymentMethod.payment_method_id}
                  key={paymentMethod.payment_method_id}
                >
                  {paymentMethod.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Space direction="vertical" style={{ width: '100%' }}>
            {payments.length > 0 &&
              payments.map((payment, index) => {
                return(
                  <Row key={index} style={{ marginBottom: 10 }}>
                    <Col span={18}>
                    {payment.name}
                    </Col>

                  <Col span={6}>
                  <InputNumber
                      min={0}
                      // max={sumCostPaidData - costPaid}
                      onChange={(e) => _onChangeValue(e, payment.payment_method_id)}
                      value={payment.value}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: 130, position: 'relative',borderBottom: '0.75px solid #C9C8C8' }}
                      placeholder={t('sell.customer_pay')}
                      bordered={false}
                    />
                  </Col>
                   
                  </Row>
                )
              })}
          </Space>
          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <p>Tổng tiền trả khách</p>
            <p>{formatCash(costPaid)}</p>
          </Row>
        </Space>
        </Modal>
    </>
  )
}
