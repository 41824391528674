import React, { useState } from 'react'
import { IMAGE_DEFAULT, PERMISSIONS } from 'consts'
import { formatCash } from 'utils'
import { useSelector } from 'react-redux'
import styles from './stock-adjustments.module.scss'
import { removeUnicode } from 'utils'
//components
import ImportProduct from '../import-stock-adjustment'
import { TableProduct } from './item/table-product'
//antd
import { Row, Col, Input, Button, Form, Select, Spin, Checkbox } from 'antd'

//icons
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'

export default function ContainerRight({
  current,
  detailLocation,
  _updateInventoryListLocation,
  _cancel,
  addVOte,
  form,
  loadingProduct,
  addProduct,
  productArr,
  paramsFilterSearchHeader,
  _importProduct,
  users,
  listProduct,
  setListProduct,
  filteredResults,
  sample,
  setSample,
  sampleList,
  setSampleList,
  _getSampleList,
  id
}) {
  const { TextArea } = Input
  const dataUser = useSelector((state) => state.login.dataUser)

  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [inputText, setInputText] = useState('')
  let onSearch = (e) => {
    var lowerCase = e.target.value
    setInputText(lowerCase)
  }

  const filteredData =
    listProduct &&
    listProduct.filter((e) => {
      if (inputText === '') {
        return e
      } else {
        return (
          removeUnicode(e.sku.toLowerCase()).includes(inputText.toLowerCase().trim()) ||
          removeUnicode(e.title.toLowerCase()).includes(inputText.toLowerCase().trim())
        )
      }
    })
    const [timeoutBlinkVariant, setTimeoutBlink] = useState({})
  const scrollSmoothHandler = (variant_id) => {
    const variantEl = document.getElementById(`variant_${variant_id}`)
    const timeOut = variantEl ? 0 : 300
    setTimeout(() => {
      const variantEl = document.getElementById(`variant_${variant_id}`)
      if (variantEl) {
        if (typeof timeoutBlinkVariant[variant_id] != 'undefined') {
          clearTimeout(timeoutBlinkVariant[variant_id])
        }
        variantEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        variantEl.style.backgroundColor = '#dddddd'
        let intv = setTimeout(() => {
          variantEl.style.backgroundColor = ''
        }, 1000)
        timeoutBlinkVariant[variant_id] = intv
        setTimeoutBlink(timeoutBlinkVariant)
      }
    }, timeOut)
  }
  return (
    <div className={styles['containerCON']}>
      <Row
        style={{
          borderBottom: '1px solid rgb(197, 184, 184)',
          justifyContent: 'space-between',
        }}
      >
        {current >= 1 ? (
          detailLocation === 0 ? (
            <></>
          ) : (
            detailLocation === 1 && <h3>Chi tiết vị trí kiểm</h3>
          )
        ) : detailLocation === 0 ? (
          <>
            <h3>Thêm vị trí kiểm</h3>
            {permissions.includes(PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao) ? (
              <Button className={styles['luu']} type="primary" onClick={addVOte}>
                Lưu vị trí kiểm
              </Button>
            ) : (
              <Button className={styles['luu']} type="primary" disabled>
                Lưu vị trí kiểm
              </Button>
            )}
          </>
        ) : (
          detailLocation === 1 && (
            <>
              <h3>Chi tiết vị trí kiểm</h3>
              {permissions.includes(PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao) ? (
                <div style={{ width: '20em' }}>
                  <Button
                    className={styles['luu']}
                    style={{
                      width: '60%',
                    }}
                    type="primary"
                    onClick={_updateInventoryListLocation}
                  >
                    Cập nhật vị trí
                  </Button>
                  <Button className={styles['luu']} onClick={_cancel}>
                    Hủy
                  </Button>
                </div>
              ) : (
                <div style={{ width: '20em' }}>
                  <Button
                    className={styles['luu']}
                    style={{
                      width: '60%',
                    }}
                    type="primary"
                    disabled
                  >
                    Cập nhật vị trí
                  </Button>
                  <Button className={styles['luu']} disabled>
                    Hủy
                  </Button>
                </div>
              )}
            </>
          )
        )}
      </Row>
      <Form layout="vertical" form={form}>
        <Row gutter={16} style={{ marginTop: 15, height: 58 }}>
          <Col span={10}>
            <Form.Item
              label="Tên vị trí kiểm"
              name="locations"
              rules={[{ required: true, message: 'Vui lòng chọn vị trí kiểm!', whitespace: true }]}
            >
              <Input disabled={current >= 1 ? true : false} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Ghi chú"
              name="note"
              placeholder="Nhập ghi chú"
              style={{ maxWidth: '100%' }}
              rules={[
                {
                  pattern: /^[^<>\/]*$/,
                  message: 'Chỉ cho phép nhập ký tự chữ và số',
                },
              ]}
            >
              <TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={sample}
              onChange={(e) => {
                let sample_list = [...sampleList]
                if (listProduct.length > 0) {
                  listProduct.forEach((element) => {
                    let index = sample_list.findIndex((e) => e === element.variant_id)
                    if (e.target.checked === true) {
                      if (index === -1) sample_list.push(element.variant_id)
                    } else {
                      if (index !== -1) sample_list.splice(index, 1)
                    }
                  })
                }
                sample_list = [...new Set([...sample_list, ..._getSampleList()])]
                setSampleList(sample_list)
                setSample(e.target.checked)
              }}
            >
              Hàng trưng bày
            </Checkbox>
          </Col>
        </Row>

        <div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={20} xl={20}>
              <Select
                className={styles['location']}
                notFoundContent={loadingProduct ? <Spin size="small" /> : null}
                dropdownClassName="dropdown-select-search-product"
                allowClear
                showSearch
                clearIcon={<CloseOutlined style={{ color: 'black' }} />}
                style={{ width: '100%', marginBottom: 15 }}
                placeholder="Thêm sản phẩm vào phiếu kiểm"
                value={null}
                disabled={current >= 1 ? true : false}
                onChange={(value, option) => {
                  if (value !== null && value !== undefined && value !== '') {
                    let index = value.lastIndexOf('/')
                    let resultIndex = value.substring(index + 1)
                    let list_new = [...listProduct]
                    let index_variant = list_new.findIndex(e => e.variant_id === productArr[resultIndex].variant_id)
                      if(index_variant === -1){
                        addProduct(productArr[resultIndex])
                      }else{
                        list_new[index_variant].real_quantity += 1
                        setListProduct(list_new)
                      }
                      scrollSmoothHandler(productArr[resultIndex].variant_id)
                    delete paramsFilterSearchHeader.search
                  }
                }}
                filterOption={(input, option) =>
                  removeUnicode(option.key.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
              >
                {filteredResults &&
                  productArr &&
                  (filteredResults.length > 0 ? filteredResults : productArr).map((data, index) => (
                    <Select.Option key={data.title + data.sku + data.barcode + '/' + index + ''}>
                      <Row
                        align="middle"
                        wrap={false}
                        style={{
                          padding: '7px 13px',
                          backgroundColor: data.is_choose == true ? 'rgb(231, 233, 251)' : 'unset',
                        }}
                      >
                        <img
                          src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                          alt=""
                          style={{
                            minWidth: 40,
                            minHeight: 40,
                            maxWidth: 40,
                            maxHeight: 40,
                            objectFit: 'cover',
                          }}
                        />

                        <div style={{ width: '100%', marginLeft: 15 }}>
                          <Row wrap={false} justify="space-between">
                            <span
                              style={{
                                maxWidth: 450,
                                marginBottom: 0,
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                              }}
                            >
                              {data.title}
                            </span>
                            <p style={{ marginBottom: 0, fontWeight: 500 }}>
                              {formatCash(data.price)}
                            </p>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <p style={{ marginBottom: 0, color: 'gray' }}>{data.sku}</p>
                          </Row>
                        </div>
                      </Row>
                    </Select.Option>
                  ))}
              </Select>
            </Col>
            <Col
              style={{ width: 'auto', height: '34px', marginTop: '30px' }}
              xs={24}
              sm={12}
              md={12}
              lg={4}
              xl={4}
            >
              {current < 1 ? <ImportProduct importProduct={_importProduct} id={id}/> : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <h3>Danh sách sản phẩm đã kiểm</h3>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Input
                style={{ width: '100%' }}
                prefix={<SearchOutlined />}
                name="name"
                value={inputText}
                onChange={onSearch}
                placeholder="Tìm kiếm"
                bordered={true}
                allowClear
              />
            </Col>
          </Row>
          <TableProduct
            products={filteredData}
            users={users}
            current={current}
            listProduct={listProduct}
            setListProduct={setListProduct}
            sampleList={sampleList}
            setSampleList={setSampleList}
            _getSampleList={_getSampleList}
            productArr={productArr}
          />
        </div>
      </Form>
    </div>
  )
}
