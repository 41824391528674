import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { validatePhone } from 'utils'
// antd
import { Select, Button, Input, Form, Row, Col, DatePicker, notification, Radio } from 'antd'

//apis
import { addCustomer, updateCustomer, getCustomerTypes, addCustomerType } from 'apis/customer'
import { ACTION } from 'consts'

const { Option } = Select
export default function CustomerForm({ record, close, reload, text = 'Thêm', editInvoice, phone }) {
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [customerTypes, setCustomerTypes] = useState([])
  const [type, setType] = useState('')
  const [loadingBtn, setLoadingBtn] = useState(false)

  const focusNameCustomer = useRef(null)
  const dispatch = useDispatch()

  const _createType = async () => {
    try {
      if (!type) {
        notification.warning({ message: 'Vui lòng nhập loại khách hàng' })
        return
      }
      setLoadingBtn(true)
      const res = await addCustomerType({ name: type })
      if (res.status === 200) {
        if (res.data.success) {
          await _getCustomerTypes()
          notification.success({ message: 'Tạo loại khách hàng thành công' })
          setType('')
        } else
          notification.error({
            message: res.data.message || 'Tạo loại khách hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Tạo loại khách hàng thất bại, vui lòng thử lại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
    }
  }

  const onFinish = async (values) => {
    try {
      values.phone = values.phone.replace('+84', 0)
      if (!validatePhone(values.phone)) {
        notification.warning({ message: 'Vui lòng nhập số điện thoại đúng định dạng' })
        return
      }

      const body = {
        ...values,
        point: 0,
        used_point: 0,
        order_quantity: 0,
        order_total_cost: 0,
        order_debt: 0,
        branch_id: branchIdApp,
        first_name: values.first_name || '',
        email: values.email || '',
        birthday: values.birthday ? moment(new Date(values.birthday)) : null,
        address: values.address || '',
        balance: [],
        active: 1,
      }
      let res
      if (record) res = await updateCustomer(record.customer_id, body)
      else res = await addCustomer(body)
      if (res.status === 200) {
        if (res.data.success) {
          reload && reload()
          notification.success({ message: `${record ? 'Cập nhật' : 'Thêm'} khách hàng thành công` })
          initForm()
          close()
          editInvoice('customer', res.data.data)
          editInvoice('name', `${body.first_name} ${body.last_name}`)
          editInvoice('shipping_info', body)

        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? 'Cập nhật' : 'Thêm'} khách hàng thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${record ? 'Cập nhật' : 'Thêm'} khách hàng thất bại, vui lòng thử lại!`,
        })
    } catch (error) {
      console.log(error)
    }
  }

  const initForm = () => {
    form.setFieldsValue({
      // type: 'Vãng Lai',
      gender: 0,
    })
  }

  const _getCustomerTypes = async () => {
    try {
      const res = await getCustomerTypes()
      if (res.status === 200) setCustomerTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getCustomerTypes()
    if (!record) initForm()
    else {
      form.setFieldsValue({
        ...record,
        birthday: moment(new Date(record.birthday)),
        type_id: record._type ? record._type.type_id : '',
      })
    }
  }, [])

  useEffect(() => {
    if (phone) {
      form.setFieldsValue({ phone: phone })
    }
  }, [phone])

  useEffect(() => {
    if (focusNameCustomer?.current && focusNameCustomer?.current !== null) {
      setTimeout(() => {
        focusNameCustomer?.current?.focus()
      }, 500)
    }
  }, [close])
  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row justify="space-between" align="middle">
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Họ"
            name="first_name"
            rules={[
              {
                pattern:
                  /^[a-zA-Z\sàáảạãặẳằắẵẩấầẫậâăÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪẽẻẹèéễếệểềêỉĩịìíọõỏôóốồổỗộơớờởỡợụùúũủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹđ]+$/,
                message: 'Chỉ cho phép nhập ký tự chữ',
              },
            ]}
          >
            <Input allowClear size="large" placeholder="Nhập họ khách hàng" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Tên khách hàng"
            name="last_name"
            rules={[{ required: true, message: 'Vui lòng nhập tên khách hàng' },{
              pattern:
                  /^[a-zA-Z\sàáảạãặẳằắẵẩấầẫậâăÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪẽẻẹèéễếệểềêỉĩịìíọõỏôóốồổỗộơớờởỡợụùúũủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹđ]+$/,
                message: 'Chỉ cho phép nhập ký tự chữ',
            }]}
          >
            <Input
              ref={focusNameCustomer}
              allowClear
              size="large"
              placeholder="Nhập tên khách hàng"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Số điện thọai"
            name="phone"
            rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
          >
            <Input
              allowClear
              style={{ width: '100%' }}
              size="large"
              placeholder="Nhập số điện thoại"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item name="type_id" label="Loại khách hàng">
            <Select
              allowClear
              placeholder="Chọn loại khách hàng"
              size="large"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input
                      value={type}
                      onPressEnter={_createType}
                      placeholder="Nhập loại khách hàng mới"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <Button
                      loading={loadingBtn}
                      onClick={_createType}
                      type="primary"
                      style={{ marginLeft: 10 }}
                    >
                      Tạo mới
                    </Button>
                  </div>
                </div>
              )}
            >
              {customerTypes.map((type, index) => (
                <Option value={type.type_id} key={index}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item rules={[{ type: 'object' }]} name="birthday" label="Ngày sinh">
            <DatePicker
              placeholder="Chọn ngày sinh"
              size="large"
              className="br-15__date-picker"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item label="Địa chỉ" name="address">
            <Input allowClear placeholder="Nhập địa chỉ" size="large" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item label="Email" name="email">
            <Input allowClear placeholder="Nhập email" size="large" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item name="gender" label="Giới tính">
            <Radio.Group>
              <Radio value={0}>Nam</Radio>
              <Radio value={1}>Nữ</Radio>
              <Radio value={2}>Khác</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Form.Item>
          <Button style={{ width: 100 }} type="primary" htmlType="submit" size="large">
            {text}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  )
}
