// import styles from '../import-customer.module.css'
import localStorage from '../../../../../utils/localStorage'

//antd
import { message, Upload, notification, Button, Row } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import React from 'react'

const { Dragger } = Upload

export default function Step1({ submit, setOpen }) {
  let accessToken = localStorage.getItem('accessToken')

  if (!accessToken) {
    accessToken = Cookies.get('accessToken')
  }

  const props = {
    name: 'file',
    action:
      process.env.REACT_APP_API_ENDPOINT_DEV +
      '/fix-price/fix-price/upload-excel',
    headers: {
      'X-Ekata-Site': window.location.hostname,
      'X-Ekata-Token': accessToken,
    },
    async onChange(info) {
      var extension = info.file.name.split('.').pop().toLowerCase()
      const allowedExtensions = ['xlsx', 'xls', 'xlsm']
      if (allowedExtensions.includes(extension)) {
        const { status } = info.file
        if (status === 'done') {
          await submit({ step: 1, path: info.file.response.path })
        } else if (status === 'error') {
          message.error(`${info.file.name} tải lên bị lỗi`)
        }
      } else {
        if (info.file.status !== 'removed') {
          notification.warning({ key: 'fail file', message: 'Yêu cầu nhập file đúng định dạng' })
        }
      }
    },
  }

  return (
    <>
      <div>
        <a href="https://s3.ap-southeast-1.wasabisys.com/ekata-production/public/2022/12/12/1670810701860-254265544-mau_nhap_sp_dieu_chinh_gia (1).xlsx">
          Tải về file import mẫu
        </a>
        <br />
        <br />
      </div>
      <div
      // className={styles['step-1']}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Nội dung hint</p>
        </Dragger>
      </div>
      <Row style={{ marginTop: 20 }} justify="center" align="center">
        <Button style={{ width: '150px' }} onClick={() => setOpen(false)}>
          Đóng
        </Button>
      </Row>
    </>
  )
}
