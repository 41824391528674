import React, { useState, useEffect } from 'react'
import { compare, removeUnicode } from 'utils'
import { ACTION, FILTER_SIZE, PAGE_SIZE, PERMISSIONS } from 'consts'
import { useDispatch, useSelector } from 'react-redux'

//antd
import { Row, Table, Button, Col, DatePicker, Select, Space } from 'antd'
import { ToTopOutlined } from '@ant-design/icons'

//components
import TitlePage from 'components/title-page'
import { formatCash, tableSum } from 'utils'
import { useTranslation } from 'react-i18next'
import FilterDate from 'components/filter-date/filter'
import ConnectStatus from '../order-update/connect-status'
import { handleCell } from 'components/handleCell'
import { DefaultFilterData } from 'utils/DefaultFilterData'
import exportToCSV from 'components/ExportCSV/export'

//apis
import { getSaleHistory, getProducts } from 'apis/product'

import moment from 'moment'

export default function SaleHistory() {
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [loading, setLoading] = useState(false)
  const [saleHistory, setSaleHistory] = useState([])
  const [saleHistoryShow, setSaleHistoryShow] = useState([])

  const [total, setTotal] = useState({})
  const [product, setProduct] = useState([])
  const [paramsFilter, setParamsFilter] = useState({page: 1, page_size: PAGE_SIZE})
  const [valueTime, setValueTime] = useState(``)

  const [countAction, setCountAction] = useState(0)
  const { t } = useTranslation()
  const handleTotal = (data) => {
    let total = {
      totalQuantity: 0,
      totalFinalCost: 0,
    }
    data.forEach((element) => {
      total.totalQuantity += element.quantity
      total.totalFinalCost += element.quantity * element?.variant_info?.price || 0
    })
    return total
  }
  const _onFilter = (attribute = '', value = '') => {
    if (attribute == 'product_sku') {
      let resultIndex
      if (value !== undefined) {
        let index = value.lastIndexOf('+')
        resultIndex = value.substring(index + 1)
      }
      paramsFilter[attribute] = resultIndex
      setParamsFilter({...paramsFilter})
    }
  }
  useEffect(() => {
    let arr = [...saleHistory]
    const filtered = arr.filter((current) => {
      let matchType = true
      let matchTime = true
      let matchSearch = true
      if (paramsFilter.product_sku && paramsFilter.product_sku !== 'all')
        matchType = current?.product_info?.sku == paramsFilter.product_sku
      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date
        return matchType && matchSearch && matchTime
    })
    setSaleHistoryShow(filtered)
    setCountAction(filtered.length)
    setTotal(handleTotal(filtered))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter])
  const columns = [
    {
      key: 'STT',
      title: 'STT',
      render: (text, record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
      width: '4%',
      align: 'center',
    },
    {
      title: 'Mã đơn hàng',
      render: (text, record) => (
        <ConnectStatus
          permissions={[PERMISSIONS.chi_tiet_don_hang]}
          record={record?.order_info}
          location={1}
        />
      ),
      width: '16%',
      onCell: (record, index) => handleCell(record, (paramsFilter.page - 1) * paramsFilter.page_size + index, saleHistoryShow, 'sale_order_id',(paramsFilter.page - 1) * paramsFilter.page_size),
    },
    {
      title: 'SKU phiên bản',
      render: (text, record) => record?.variant_info?.sku,
      width: '12%',
    },
    {
      title: 'Tên phiên bản',
      render: (text, record) => record?.variant_info?.title,
    },
    {
      key: 'quantity',
      title: 'Số lượng',
      dataIndex: 'quantity',
      sorter: (a, b) => compare(a, b, 'quantity'),
      width: '8%',
      align: 'center',
    },
    {
      key: 'price',
      title: 'Đơn giá',
      render: (text, record) => formatCash(record?.variant_info?.price),
      sorter: (a, b) => compare(a, b, 'price'),
      align: 'center',
      width: '8%',
    },
    {
      key: 'total_price',
      title: 'Thành tiền',
      render: (text, record) => formatCash(record?.quantity * record?.variant_info?.price),
      align: 'center',
      width: '8%',
    },
    {
      title: 'Nhân viên',
      render: (text, record) =>
        record?.creator_info?.first_name + ' ' + record?.creator_info?.last_name || '',
    },
    {
      key: 'variant_inventory_quantity',
      title: 'Tồn kho',
      render: (text, record) => record?.variant_inventory_quantity || 0,
      align: 'center',
      width: '6%',
    },
    {
      title: 'Ngày ghi nhận',
      render: (text, record) => moment(record.create_date).format('DD-MM-YYYY, HH:mm'),
    },
  ]

  const _getSaleHistory = async () => {
    try {
      setLoading(true)
      const res = await getSaleHistory({ branch_id: branchIdApp })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id,permissions, res.data.data)
        setSaleHistory(result.data)
        setParamsFilter({
          page: 1,
          page_size: PAGE_SIZE,
          from_date: result.defaultTime.from_date,
          to_date: result.defaultTime.to_date
        })
        setValueTime(result.valueDate)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _getSaleHistoryExport = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
        let dataExport = []

        saleHistoryShow && saleHistoryShow.map((order, key_index) => {
            const obj = {
              STT: key_index + 1,
              'Mã đơn hàng': order?.order_info?.code || '',
              'SKU phiên bản': order?.variant_info?.sku || '',
              'Tên phiên bản': order?.variant_info?.title || '',
              'Số lượng': order.quantity || 0,
              'Đơn giá': formatCash(order?.variant_info?.price) || 0,
              'Thành tiền': formatCash(order?.quantity * order?.variant_info?.price) || 0,
              'Nhân viên': order?.creator_info?.first_name + ' ' + order?.creator_info?.last_name || '',
              'Tồn kho': order.variant_inventory_quantity || 0,
              'Ngày ghi nhận': moment(order.create_date).format('DD-MM-YYYY, HH:mm') || '',
            }
            dataExport.push(obj)
        })
        exportToCSV(dataExport, 'Lịch sử bán hàng', 'Xuất excel lịch sử bán hàng',dispatch)
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const onClickClear = async () => {
    setValueTime('')
    setParamsFilter({page: 1, page_size: PAGE_SIZE})
  }
  const _getProducts = async () => {
    try {
      const res = await getProducts()
      if (res.status === 200) setProduct(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getProducts()
    _getSaleHistory()
  }, [branchIdApp,dataUser])
  return (
    <div className="card">
      <TitlePage isAffix title={<Row align="middle">Lịch sử bán hàng</Row>}>
          <Space>{permissions.includes(PERMISSIONS.lich_su_ban_hang) ? (
          <Button
          id='exportFile'
            style={{ backgroundColor: 'green', borderColor: 'green' }}
            size="large"
            onClick={_getSaleHistoryExport}
            type="primary"
            icon={<ToTopOutlined />}
          >
            Xuất excel
          </Button>
        ) : null}

        <Button
          style={{ display:  Object.keys(paramsFilter).length <= 2 &&  'none' }}
          onClick={onClickClear}
          type="primary"
          size="large"
        >
          Xóa tất cả lọc
        </Button></Space>
        
      </TitlePage>
      <Row
        gutter={[16, 16]}
        style={{
          marginTop: 8
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={6}
        >
          <FilterDate
            size='default'
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            valueTime={valueTime}
            setValueTime={setValueTime}
            title="bán hàng"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={6}
        >
          <Select
            size={FILTER_SIZE}
            allowClear
            bordered={true}
            value={paramsFilter.product_sku || 'all'}
            defaultValue={'all'}
            showSearch
            filterOption={(input, option) =>
              removeUnicode(option.children.toString())
                .toLowerCase()
                .trim()
                .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
            }
            onChange={(value) => {
              _onFilter('product_sku', value)
            }}
            placeholder="Lọc theo sản phẩm"
            style={{ width: '100%' }}
          >
            <Select.Option value="all">Tất cả sản phẩm</Select.Option>
            {product.map((_product, index) => (
              <Select.Option value={`${_product.name}+${_product.sku}`} key={index}>
                {_product.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          marginLeft: '0px',
          marginRight: '0px',
          borderRadius: 5,
        }}
      >
        <Table
          loading={loading}
          size="small"
          columns={columns}
          dataSource={saleHistoryShow}
          style={{ width: '100%' }}
          scroll={{ y: 600 }}
          pagination={{
            position: ['bottomLeft'],
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
            },
            total: countAction,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {columns.map((e, index) => {
                  if (e.key === 'STT')
                    return (
                      <Table.Summary.Cell>
                        <b style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                          {t('common.total')}
                        </b>
                      </Table.Summary.Cell>
                    )
                  if (e.key === 'quantity')
                    return (
                      <Table.Summary.Cell>
                        <div style={{ textAlign: 'center' }}>{total.totalQuantity}</div>
                      </Table.Summary.Cell>
                    )

                  if (e.key === 'total_price')
                    return (
                      <Table.Summary.Cell>
                        <div style={{ textAlign: 'center' }}>
                          {formatCash(total.totalFinalCost)} VND
                        </div>
                      </Table.Summary.Cell>
                    )

                  return <Table.Summary.Cell></Table.Summary.Cell>
                })}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Row>
    </div>
  )
}
