import { remove } from 'lodash'
import { each } from 'underscore'

const initialState = {
  loading: false,
  products: [],
  count: 2,
  productsFil: [],
  countFil: 50,
  variant: [],
  productWarehouse: []
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS': {
      return {
        ...state,
        products: action.data.products,
        productsFil:
          action.data.products.length > 0 ? action.data.products.slice(0, 50) : state.productsFil,
        counts: action.data.counts,
        countFil: 50,
      }
    }
    case 'SET_NEW_PRODUCTS': {
      let newArrProduct = state.products
      let arrFilIds = [...new Set(action.data.ids)]
      each(arrFilIds, function (id) {
        remove(newArrProduct, function (item) {
          return item?.product_id == id
        })
      })
      newArrProduct = [...newArrProduct, ...action.data.products]

      return {
        ...state,
        products: newArrProduct,
        productsFil: newArrProduct.length > 0 ? newArrProduct.slice(0, 50) : state.productsFil,
      }
    }
    case 'SET_VARIANTSELL': {
      return {
        ...state,
        variant: action.data.variant,
        counts: action.data.counts
      }
    }
    case 'SET_PRODUCTS_WAREHOUSE': {
      return {
        ...state,
        productWarehouse: action.data.productWarehouse,
      }
    }
    default:
      return state
  }
}
export default products
