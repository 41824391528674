import styled from 'styled-components'
import { Table } from 'antd'
export const TableProducts = styled(Table)`
  .ant-table-expanded-row > .ant-table-cell {
    display: none;
  }
  .ant-table-expanded-row > .ant-table-cell:has(.table-child) {
    display: table-cell;
  }
`
