import React, { useEffect, useState, useCallBack } from 'react'
import { Row, Modal, Button, InputNumber, Table, Popover, Col, Typography,notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'


//component
import { orderBy, sortBy } from 'lodash'

//apis
import { getLocationExpiry } from 'apis/location'

const ModalProductLocation = ({ product, index, variant_id, _edit }) => {
  const { Text } = Typography
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const { t } = useTranslation()
  const [locationExpiry, setLocationExpiry] = useState([])
  const [listLocation, setListLocation] = useState([])
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const _getLocationExpiry = async () => {
    try {
      const res = await getLocationExpiry({ variant_id: variant_id, branch_id: branchIdApp, warehouse: true })
      if (res.status === 200) {
        // let new_data = orderBy(res.data.data, ['quantity', 'exp'],['desc','asc'])
        let new_data = orderBy(res.data.data.filter(e => e.quantity > 0), 'exp','asc');
        let new_data2 = orderBy(res.data.data.filter(e => e.quantity == 0), 'exp', 'asc');
        new_data = new_data.concat(new_data2)
        for (let i of new_data) {
          let index = product.location_check.findIndex((item) => item.location_id == i.location_id)
          if (index !== -1) {
            i.quantity_check = product.location_check[index].quantity_check || 0
          }
        }
        setLocationExpiry(new_data)
        setListLocation(product.location_check)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handeChangeQuantity = () => {
    _edit(
      'real_quantity',
      listLocation
        .filter((item) => item.quantity_check !== 0)
        .reduce((total, item) => (total += item.quantity_check || 0), 0),
      index
    )
    _edit(
      'location_check',
      listLocation
        // .filter((item) => item.quantity_check !== 0)
        .map((item) => ({
          ...item,
          quantity_check: item.quantity_check,
        })),
      index
    )
    toggle()
  }
  const columns = [
    {
      title: 'Mã lô',
      dataIndex: 'location_id',
      render: (text, record) => record.code,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'exp',
      render: (text, record) => record.exp,
    },
    {
      title: <div style={{textAlign:'center'}}>Tồn kho hệ thống</div>,
      render: (text, record) => <div style={{textAlign:'center'}}>{record.quantity}</div> ,
    },
    {
      title: 'Số lượng tồn',
      render: (text, record, index) => (
        <InputNumber
          onChange={(value) => {
            const listProductNew = [...locationExpiry]
            let indexNew = listProductNew.findIndex(e => e.location_id == record.location_id)
            listProductNew[indexNew].quantity_check = value
            let arrTmp = [...listLocation]
            let index_location = arrTmp.findIndex(item => item.location_id == record.location_id)
            if(value === null){
              arrTmp.splice(index_location,1)
              setListLocation(arrTmp)
            }else{
              if (index_location !== -1) {
                arrTmp[index_location].quantity_check = value
              } else {
                arrTmp.push(listProductNew[indexNew])
              }
              setListLocation(arrTmp)
            }
          }}
          className="show-handler-number"
          style={{ width: '100%' }}
          value={record.quantity_check || 0}
          min={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder={t('sell.quantity')}
        />
      ),
    },
  ]

  return (
    <>
      <div
        onClick={() => {
          toggle()
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          color: 'gray',
          cursor: 'pointer',
        }}
      >
        <Button
        onClick={async (e) => {
          e.preventDefault()
          await _getLocationExpiry()
        }}
        size="small"
        style={{
          width: 120,
          borderRadius: '10px',
          boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
          transition: 'all .3s ease-in',
        }}
      >
        CHỌN LÔ
      </Button>
      </div>
      <Modal
        title={`Tồn kho của sản phẩm ${product.title}`}
        width={800}
        closable={false}
        visible={visible}
        footer={[
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button
                onClick={() => {
                  toggle()
                }}
              >
                Đóng
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  handeChangeQuantity()
                }}
              >
                Xác nhận
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Table
          size="small"
          rowKey="location_id"
          scroll={{ y: 500 }}
          columns={columns}
          style={{ width: '100%' }}
          dataSource={locationExpiry}
        />
      </Modal>
    </>
  )
}

export default ModalProductLocation
