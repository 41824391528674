import { useState, useEffect, useRef, useCallback } from 'react'
import { PERMISSIONS, FILTER_COL_HEIGHT, FILTER_SIZE, ROUTES,ACTION, PAGE_SIZE } from 'consts'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { socket } from '../../socket'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'

//icons
import { SearchOutlined, PlusCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons'

//antd
import { Input, Row, Col, DatePicker, Select, Button, Space, Affix, Tooltip } from 'antd'

//components
import BranchForm from './branch-form'
import Permission from 'components/permission'
import TitlePage from 'components/title-page'
import columnsBranch from './columns'
import TableBranch from './table'
import jwtDecode from 'jwt-decode'
import localStorage from '../../utils/localStorage'
import FilterDate from 'components/filter-date'

//apis
import { getListBranch } from 'apis/branch'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

function Branch() {
  const typingTimeoutRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState([])
  const [countBranch, setCountBranch] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
  })
  const [valueSearch, setValueSearch] = useState('')
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [valueTime, setValueTime] = useState()
  const onSearch = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _getBranches = useCallback(async () => {
    try {
      setLoading(true)
      const res = await getListBranch({ ...paramsFilter, _creator: true, role_id: dataUser?.role_id })
      if (res.status === 200) {
        setBranches(res.data.data)
        setCountBranch(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [paramsFilter])

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE,check:1 })
    setValueSearch('')
    setValueTime()
  }
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueSearch()
    if(dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)){
      setParamsFilter({page: 1, page_size: PAGE_SIZE, check:1})
      setValueTime()
    }else {
      setParamsFilter({page: 1, page_size: PAGE_SIZE, from_date: result.from_date, to_date: result.to_date, check:1})
      setValueTime(result.valueDate)
    }
  },[dataUser])
  useEffect(() => {
    if(paramsFilter.check) _getBranches()
  }, [paramsFilter])
  return (
    <div className="card">
        <TitlePage
        isAffix
          title={
            <Row
              align="middle"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
            >
              <ArrowLeftOutlined style={{ marginRight: 8 }} />
              Quản lý chi nhánh
            </Row>
          }
        >
          <Space>
            <Button
              style={{ display: Object.keys(paramsFilter).length < 3 && 'none' }}
              onClick={onClickClear}
              type="danger"
              size="large"
            >
              Xóa tất cả lọc
            </Button>
            {permissions.includes(PERMISSIONS.them_chi_nhanh) ? (
              <BranchForm reloadData={_getBranches}>
                <Button size="large" icon={<PlusCircleOutlined />} type="primary">
                  Thêm chi nhánh
                </Button>
              </BranchForm>
            ) : (
              <Button disabled size="large" icon={<PlusCircleOutlined />} type="primary">
                Thêm chi nhánh
              </Button>
            )}
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_chi_nhanh)?(<>
        <Row gutter={[8,16]} style={{marginTop:10, marginBottom:10 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{ height: FILTER_COL_HEIGHT }}>
            <Input
              style={{ width: '100%' }}
              size={FILTER_SIZE}
              name="name"
              value={valueSearch}
              enterButton
              onChange={onSearch}
              placeholder="Tìm kiếm theo mã, theo tên, địa chỉ của chi nhánh..."
              allowClear
              prefix={<SearchOutlined />}
              bordered={true}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            style={{
              height: FILTER_COL_HEIGHT,
            }}
          >
            <FilterDate
            size='default'
              style={{ with: '100%' }}
              bordered={true}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
              width="100%"
              title='tạo chi nhánh'
              valueTime={valueTime}
              setValueTime={setValueTime}
              screen={1}
            />
          </Col>
        </Row>

      <TableBranch
        onGetBranches={_getBranches}
        branches={branches}
        countBranch={countBranch}
        loading={loading}
        columns={columnsBranch}
        paramsFilter={paramsFilter}
        onSetParamFilter={setParamsFilter}
      /></>):null}
      
    </div>
  )
}

export default Branch
