import { ArrowLeftOutlined, CloudUploadOutlined, ImportOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Col, Input, InputNumber, Row, Select, Spin } from 'antd'
import React, { useState } from 'react'
import { syncPrice } from '../function-items/sync-price'
import { SaveSystem } from '../function-items/save-system'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ModalImport from '../import-items/modal-import'
import { ROUTES } from 'consts'
const Header = (props) => {
  const { valuePrice, setValuePrice, productChange, setProductChange } = props
  const [valueTypePrice, setValueTypePrice] = useState(null)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState(false)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  return (
    <div>
      <div>
        <h1 onClick={() => history.push(ROUTES.PRICE_ADJUSTMENTS)} style={{ fontSize: 20, cursor:'pointer' }}><ArrowLeftOutlined /> Đổi giá hàng hoá</h1>
      </div>
      <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between'}}>
        <Row>
          <Input.TextArea onChange={(e) => setNote(e.target.value)} placeholder='Nhập ghi chú' style={{width: '400px', marginRight: '10px'}} />
        </Row>
        <Row gutter={[12, 12]}>
          <Col>
            <Select
              onChange={(e) => setValueTypePrice(e)}
              placeholder={<span><span style={{color: 'red'}}>* </span>---Chế độ đổi giá---</span>}
              style={{ width: '200px' }}
            >
              <Select.Option value={1}>Chế độ đồng giá</Select.Option>
              <Select.Option value={2}>Chế độ giảm phần trăm</Select.Option>
              <Select.Option value={3}>Chế độ giảm lượng giá</Select.Option>
              <Select.Option value={4}>Khôi phục giá gốc</Select.Option>
            </Select>
          </Col>
          <Col>
            <InputNumber
              prefix={<span style={{color: 'red'}}>*</span>}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              min={0}
              placeholder="Nhập giá trị..."
              style={{ width: '100%' }}
              onChange={(e) => setValuePrice(e)}
            />
          </Col>
          <Col>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                syncPrice(valuePrice, valueTypePrice, productChange, setProductChange)
              }}
            >
              <SyncOutlined /> Nạp giá
            </Button>
          </Col>
          <Col>
          <ModalImport
              setProductChange={setProductChange}
              productChange={productChange}
              loading={loading}
            />
          </Col>
          <Col>
            <Spin spinning={loading}>
              <Button
                onClick={async (e) => {
                  e.stopPropagation()
                  await SaveSystem(productChange, history, setLoading, note, branchIdApp)
                }}
                style={{ background: 'rgb(91, 107, 232)', color: 'white' }}
              >
                <CloudUploadOutlined /> Tạo phiếu
              </Button>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Header
