/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './style.module.scss'
import { AutoComplete, Button, Card, Col, Image, Input, Modal, notification, Row, Tabs } from 'antd'
import { formatCash } from 'utils'
import { IMAGE_DEFAULT } from 'consts'
import { useSelector } from 'react-redux'
import {
  ExclamationCircleFilled,
  ExclamationCircleTwoTone,
  SearchOutlined,
} from '@ant-design/icons'
const styleDiv = {
  marginBottom: '1px',
}

export const DetailProductModal = ({ product, addProductToCartInvoice, changeProductOfBranch,check }) => {
  const [visible, setVisible] = useState(false)
  const productsSystem = useSelector((state) => state.variant.variants)
  const [nameProductCheck, setNameProductCheck] = useState(null)
  const [variantChange, setVariantChange] = useState(true)
  const [idChange, setIdChange] = useState(product?.variant_id)
  const [listVariantChange, setListVariantChange] = useState([])
  const [options, setOptions] = useState([
    ...new Set(
      productsSystem.map((item) => {
        return { value: item.sku }
      })
    ),
  ])
  const [productSearch, setProductSearch] = useState(product)
  const [valueSearch, setValueSearch] = useState('')
  const focusInput = useRef()
  const onSearch = (searchText) => {
    setValueSearch(searchText)
    let newData = [...productsSystem]
    if (searchText == '') {
      newData = newData?.map((item) => {
        return { value: item.sku }
      })
    } else {
      if (searchText !== null) {
        newData = newData
          ?.filter((item) => String(item?.sku).toLowerCase().includes(searchText.toLowerCase()))
          .map((item) => {
            return { value: item.sku }
          })
      }
    }
    setOptions([...new Set(newData)])
  }
  const _changeVariant = (id) => {
    if (id === idChange) {
      setVariantChange(false)
      setIdChange(0)
    } else {
      let variant = productsSystem.find((e) => e.variant_id == id)
      setVariantChange(true)
      setProductSearch(variant)
      setIdChange(id)
    }
  }
  const _changeSku = (value) =>{
    if (value) {
      let dataProduct = productsSystem.find((item) => item.sku == value)
      if (dataProduct) {
        setProductSearch(dataProduct)
        let result = dataProduct?.product?.variants.reduce((arr, e) => {
          let variant = productsSystem.find(item => item.variant_id === e)
          if(variant){
            arr.push({
              variant_id: variant.variant_id,
              sku: variant.sku
            })
          }
          return arr
        }, [])
        setIdChange(dataProduct?.variant_id)
        setListVariantChange(result)
      }
    }
  }
  useEffect(() => {
    if (nameProductCheck) {
      setProductSearch(nameProductCheck)
        let result = nameProductCheck?.product?.variants.reduce((arr, e) => {
          let variant = productsSystem.find(item => item.variant_id === e)
          if(variant){
            arr.push({
              variant_id: variant.variant_id,
              sku: variant.sku
            })
          }
          return arr
        }, [])
        setIdChange(nameProductCheck?.variant_id)
        setListVariantChange(result)
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameProductCheck])
  useEffect(() => {
    setProductSearch(product)
    let result = product?.product?.variants.reduce((arr, e) => {
      let variant = productsSystem.find(item => item.variant_id === e)
      if(variant){
        arr.push({
          variant_id: variant.variant_id,
          sku: variant.sku
        })
      }
      return arr
    }, [])
    setIdChange(product?.variant_id)
    setListVariantChange(result)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])
  useEffect(() => {
    let result = product?.product?.variants.reduce((arr, e) => {
      let variant = productsSystem.find(item => item.variant_id === e)
      if(variant){
        arr.push({
          variant_id: variant.variant_id,
          sku: variant.sku
        })
      }
      return arr
    }, [])
    setIdChange(product?.variant_id)
    setListVariantChange(result)
    setOptions([
      ...new Set(
        productsSystem.map((item) => {
          return { value: item.sku }
        })
      ),
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsSystem])
  useEffect(() => {
    if (focusInput.current && check === true) {
      setTimeout((e) => {
        focusInput.current.focus()
      }, 500)
    }
  }, [visible])
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          setVisible(true)
          setNameProductCheck(product)
        }}
        icon={<ExclamationCircleTwoTone />}
        type="link"
        size="small"
      />
      <Modal
        visible={visible}
        closable={false}
        width={800}
        footer={[
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setVisible(false)
                  setNameProductCheck(null)
                  setValueSearch('')
                  setOptions([
                    ...new Set(
                      productsSystem.map((item) => {
                        return { value: item.sku }
                      })
                    ),
                  ])
                }}
              >
                Đóng
              </Button>
            </Col>
            <Col>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  if (productSearch) {
                    if(idChange === 0){
                      notification.warning({message: 'Bạn chưa chọn phiên bản sản phẩm!'})
                      return
                    }
                      addProductToCartInvoice(productSearch)
                  } else {
                    return notification.error({ message: 'Sản phẩm không tồn tại!' })
                  }
                  setVisible(false)
                  setNameProductCheck(null)
                  setValueSearch('')
                  setOptions([
                    ...new Set(
                      productsSystem.map((item) => {
                        return { value: item.sku }
                      })
                    ),
                  ])
                }}
                type="primary"
              >
                Thêm vào đơn hàng
              </Button>
            </Col>
          </Row>,
        ]}
        title={
          <Row>
            <Col span={12}>
              <span>Thông tin sản phẩm</span>
            </Col>
            <Col span={12}>
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                style={{
                  width: '100%',
                }}
                options={options}
                onSearch={onSearch}
                onSelect={(e) => {
                  _changeSku(e)
                }}
                value={valueSearch}
                ref={focusInput}
                autoFocus
              >
                <Input placeholder="Mã sản phẩm" suffix={<SearchOutlined />} onFocus={changeProductOfBranch}/>
              </AutoComplete>
            </Col>
          </Row>
        }
      >
        <Row justify="center">
          <Col span={8}>
            <img
              style={{ borderRadius: '8px', width: '150px' }}
              src={
                variantChange
                  ? productSearch?.image[0]
                    ? productSearch?.image[0]
                    : IMAGE_DEFAULT
                  : productSearch?.product?.images[0]
                  ? productSearch?.product?.images[0]
                  : IMAGE_DEFAULT
              }
            />
          </Col>
          <Col style={{ marginLeft: '30px' }} span={14}>
            <div style={styleDiv}>
              <strong>Tên sản phẩm: </strong>
              <span style={{ marginBottom: '5px' }}>
                {variantChange ? productSearch?.title : productSearch?.product?.name}
              </span>
            </div>
            <div style={styleDiv}>
              <strong>Đơn vị tính: </strong>
              <span style={{ marginBottom: '5px' }}>
                {variantChange
                  ? productSearch?.unit
                  : productSearch?.product?.unit || 'Cái (Mặc định)'}
              </span>
            </div>
            <div style={styleDiv}>
              <strong>Giá bán: </strong>
              <span style={{ marginBottom: '5px' }}>
                {variantChange
                  ? formatCash(productSearch?.price)
                  : formatCash(productSearch?.product?.price)}
              </span>
            </div>
            <div style={styleDiv}>
              <strong>Số lượng: </strong>
              <span style={{ marginBottom: '5px' }}>
                {variantChange
                  ? productSearch?.total_quantity
                  : productSearch?.product?.total_quantity}
              </span>
            </div>
            <div style={styleDiv}>
              <strong>Mô tả: </strong>
              <span style={{ marginBottom: '5px' }}>
                {variantChange ? productSearch?.description : productSearch?.product?.description}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}></Col>
          <Col span={16}>
            {listVariantChange && listVariantChange.map((variant) => (
              <Button
                key={variant.variant_id}
                className={styles['variant-btn']}
                style={{borderColor: idChange === variant.variant_id ? '#5f73e2' : '#c9c9c9', color: idChange === variant.variant_id ? '#5f73e2' : '#262626'}}
                onClick={(e) => {
                  _changeVariant(variant.variant_id)
                  e.stopPropagation()
                }}
              >
                {variant.sku}
              </Button>
            ))}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
