import React, { useCallback, useEffect, useState } from 'react'
import styles from '../schedule-staff.module.scss'
import moment from 'moment'
//antd
import { Col, Row, Card, notification, Spin } from 'antd'

//component
import CloseIcon from 'assets/icons/close.svg'
import Modal from 'components/Modal'
import ClockIcon from 'assets/icons/clock.svg'
import { TimePickerRangePicker } from 'components/Form'
import { Button } from 'components/Button/Button'
const ModalRegisterShift = ({
  isModalVisible,
  handleCancel,
  onFinish,
  addItemShift,
  onUpdate,
  shiftStaffDay,
  employee,
  shiftSchedule,
  background,
  setShiftStaffDay,
  predate
}) => {
  const [loading, setLoading] = useState(false)
  const [visibleShift, setVisibleShift] = useState(false)
  const [timeRegister, setTimeRegister] = useState([])
  const [itemNew, setItemNew] = useState()
  const toggleShift = (item) => {
    setItemNew(item)
    setVisibleShift(!visibleShift)
    setTimeRegister([])
  }
  const checkClick = (value) => {
    setLoading(true)
    if (value === 1) {
      onUpdate()
    }
    if (value === 2) {
      onFinish()
    }
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  const register = (value) => {
    value['time_register_start'] = moment(`${predate} ${moment(value.time_start).format('HH:00')}`)
    value['time_register_end'] = moment(`${predate} ${moment(value.time_end).format('HH:00')}`)
    addItemShift(value)
    let arr = [...shiftStaffDay]
    let index = arr.findIndex((e) => e.shift_id === value.shift_id)
    if (index !== -1) {
      arr[index] = value
    } else {
      arr.push(value)
    }
    setShiftStaffDay(arr)
  }
  

  const getTime = (shift, register) => {
      let index = register.findIndex((e) => Number(e?.shift_id) === shift)
      return (
        <>
          Giờ làm: {moment(register[index]?.time_register_start).format('HH:mm')} -{' '}
          {moment(register[index]?.time_register_end).format('HH:mm')}
        </>
      )
  }
  const verify = (value) => {
    if (
      Number(moment(timeRegister[0]).format('HH')) <
        Number(moment(itemNew.time_start).format('HH')) ||
      Number(moment(timeRegister[1]).format('HH')) > Number(moment(itemNew.time_end).format('HH'))
    ) {
      notification.warning({message: 'Nhập thời gian trong giờ làm việc của ca'})
      return
    } else {
      if (
        Number(moment(timeRegister[0]).format('HH')) ===
        Number(moment(timeRegister[1]).format('HH'))
      ) {
        notification.warning({ message: 'Thời gian làm việc không hợp lệ' })
        return
      } else {
        value['time_register_start'] = moment(`${predate} ${moment(timeRegister[0]).format('HH:00')}`)
        value['time_register_end'] = moment(`${predate} ${moment(timeRegister[1]).format('HH:00')}`)
        addItemShift(value)

        let arr = [...shiftStaffDay]

      let index = arr.findIndex((e) => e.shift_id === value.shift_id)
      if (index !== -1) {
        arr[index] = value
      } else {
        arr.push(value)
      }
      setShiftStaffDay(arr)
      setTimeRegister([])
      toggleShift(itemNew)
      }
    }
  }
  function rangeHours(start, end) {
    const result = []
    for (let i = 0; i < 24; i++) {
      if (i < start || i > end) result.push(i)
    }
    return result
  }
  function rangeMinutes(start, end) {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  function disabledDateTime(int) {
    let start = Number(moment(int.time_start).format('HH'))
    let end = Number(moment(int.time_end).format('HH'))
    return {
      disabledHours: () => rangeHours(start, end),
      disabledMinutes: () => rangeMinutes(0, 60),
      disabledSeconds: () => [0, 60],
    }
  }

  return (
    <>
      <Modal
        width="55%"
        title="Thông tin ca làm việc"
        visible={isModalVisible}
        closeIcon={<img src={CloseIcon} alt="" />}
        onCancel={handleCancel}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Col style={{ margin: 5 }}>
              <Button
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#ff4040',
                  border: '1px solid #ff4040',
                }}
                key="cancel"
                onClick={handleCancel}
                text="Hủy"
              />
            </Col>
            <Col style={{ margin: 5 }}>
              {shiftStaffDay.some((item) => item.staff_id == employee.user_id) == true
                ? loading === false && (
                    <Button
                      style={{
                        display: 'inline-block',
                        fontSize: '14px',
                        fontWeight: 500,
                        background: '#1e4db7',
                        color: 'white',
                        border: '#1e4db7',
                      }}
                      type="primary"
                      key="ok"
                      onClick={() => checkClick(1)}
                      text="Cập nhập"
                    />
                  )
                : loading === false && (
                    <Button
                      style={{
                        display: 'inline-block',
                        fontSize: '14px',
                        fontWeight: 500,
                        background: '#1e4db7',
                        color: 'white',
                        border: '#1e4db7',
                      }}
                      type="primary"
                      key="ok"
                      onClick={() => checkClick(2)}
                      text="Đăng ký"
                    />
                  )}
            </Col>
          </div>
        }
      >
        <Row>
          {shiftSchedule &&
            shiftSchedule.map((item, index) => (
          
          <Col key={index} style={{ overflow: 'auto', height: '15em' }}>
                {item.role &&
                  item.role.map(
                    (data, index2) =>
                      data.role_id == employee._role.role_id && (
                        <div
                          className={styles['card-register']}
                          style={{ margin: '20px' }}
                          key={index2}
                          // onClick={() => addItemShift(item)}
                        >
                          <Card
                            className={styles['itemCard']}
                            style={background(item)[0]}
                            size="default"
                            title={
                              `${item.title.toUpperCase()} [${moment(item.time_start).format(
                                'HH:mm'
                              )} - ${moment(item.time_end).format('HH:mm')}` + `]`
                            }
                          >
                            {background(item)[1] === 0 ? (
                              <Row style={{ marginTop: 21 }}>
                                <Col style={{ margin: 5 }}>
                                  <Button
                                    key="add"
                                    style={{
                                      display: 'inline-block',
                                      fontSize: '14px',
                                      fontWeight: 500,
                                      background: '#1e4db7',
                                      color: 'white',
                                      border: '#1e4db7',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      register(item)
                                    }}
                                    text="Đăng ký"
                                  />
                                </Col>
                                <Col style={{ margin: 5 }}>
                                  <Button
                                    key="broken"
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 500,
                                      background: '#ff4040',
                                      color: 'white',
                                      border: '#ff4040',
                                    }}
                                    onClick={() => toggleShift(item)}
                                    text="Ca gãy"
                                  />
                                </Col>
                              </Row>
                            ) : (
                              <>
                                <Row>{getTime(item.shift_id, shiftStaffDay)}</Row>
                                <Row>
                                  <Button
                                    key="add"
                                    style={{
                                      display: 'inline-block',
                                      fontSize: '14px',
                                      fontWeight: 500,
                                      background: '#ff4040',
                                      color: 'white',
                                      margin: 5,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      addItemShift(item)
                                    }}
                                    text="Hủy đăng ký"
                                  />
                                </Row>
                              </>
                            )}
                          </Card>
                        </div>
                      )
                  )}
              </Col>
            ))}
        </Row>
      </Modal>
      <Modal
        onCancel={toggleShift}
        footer={null}
        title="Chọn thời gian làm việc"
        visible={visibleShift}
        closeIcon={<img src={CloseIcon} alt="" />}
        width="20%"
      >
        <Row>
          <TimePickerRangePicker
            suffixIcon={<img src={ClockIcon} alt="" />}
            showTime={{ format: 'HH:mm' }}
            disabledTime={() => disabledDateTime(itemNew)}
            format="HH:mm"
            value={timeRegister}
            classes={styles.input__time}
            onChange={(e) => 
              setTimeRegister(e)
            }
            
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Button
            onClick={() => {
              verify(itemNew)
            }}
            classes="btn__blue-fill"
            style={{
              display: 'inline-block',
              padding: '6px 12px',
              fontSize: '14px',
              fontWeight: 500,
            }}
            text="Xác nhận"
          />
        </Row>
      </Modal>
    </>
  )
}
export default ModalRegisterShift
