import { getProducts } from 'apis/product'
export const _getProductChange = async (branchIdApp, ids) => {
  try {
    let res
    if (ids.length > 0) {
      res = await getProducts({
        branch_id: branchIdApp,
        merge: true,
        detach: true,
        product_arr_ids: ids,
        warehouse: true,
      })
      if (res.status === 200) {
        return {
          products: res.data.data,
          counts: res.data.data.length,
        }
      } else {
        return {
          products: [],
          counts: 0,
        }
      }
    }
  } catch (err) {
    console.log(err)
  }
}
