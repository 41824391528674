import React, { useEffect, useState } from 'react'
// import styles from '../import-customer.module.css'
import { useDispatch, useSelector } from 'react-redux'

//antd
import { Button, Col, notification, Radio, Row, Spin, Table } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getRowsExcel } from 'apis/price-adjustments'
import { ACTION } from 'consts'

export default function Step2({
  filePath,
  submit,
  gotoStep,
  current,
  setDataError,
  setProductChange,
  setOpen,
  productChange,
  setDisabledImport,
}) {
  const [excelRows, setExcelRows] = useState([])
  const [loading, setLoading] = useState(true)

  const [productAdd, setProductAdd] = useState([])
  const dispatch = useDispatch()
  const sharedOnCell = (_) => {
    if (_.error === '{ERROR}') {
      return {
        colSpan: 0,
      }
    }

    return {}
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      colSpan: 1,
      align: 'center',
      render: (text, record) => (record.error === '{ERROR}' ? '' : text),
    },
    {
      title: 'Mã sản phẩm (*)',
      dataIndex: 'sku',
      onCell: (_) => ({
        colSpan: _.error === '{ERROR}' ? 1 : 1,
      }),
      render: (text, record) =>
        record.error === '{ERROR}' ? (
          <span style={{ color: '#ff9800' }}>{record.error_msg}</span>
        ) : (
          <span>
            {text}
            <br></br>
            <span style={{ color: 'red' }}>
              {productChange?.find((item) => item.sku === record.sku || item.title === record.title)
                ? 'Sản phẩm đã được thêm vào kho nhập'
                : ''}
            </span>
          </span>
        ),
    },
    {
      title: 'Tên sản phẩm (*)',
      dataIndex: 'title',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá bán',
      dataIndex: 'new_price',
      onCell: sharedOnCell,
    },
  ]

  const handleImport = () => {
    dispatch({ type: ACTION.LOADING, data: true })
    let newData = [...productAdd]
    if (productChange.length > 0) {
      for (let item of productChange) {
        let index = newData.find((e) => e.sku === item.sku || e.title === item.title)
        if (index) {
          let dataFil = newData.filter(
            (item) => item.sku !== index.sku || item.title !== index.title
          )
          newData = dataFil
          setProductAdd(dataFil)
        }
      }
    }
    setProductChange([...productChange, ...newData])
    dispatch({ type: ACTION.LOADING, data: false })
    submit({ step: 0 })
    setOpen(false)
  }

  async function _getRowsExcel(filePath) {
    dispatch({ type: ACTION.LOADING, data: true })
    setTimeout(async () => {
      setLoading(true)
      let res = await getRowsExcel(filePath)
      if (res.status == 200) {
        setProductAdd(res.data.products)
        setExcelRows(res.data.data)
      }
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
    }, 5000)
  }

  useEffect(() => {
    _getRowsExcel(filePath)
  }, [filePath])

  return (
    <div>
      <div>
        <div style={{ marginTop: 20 }}>
          <Row justify="center">
            <Col style={{ marginRight: 10 }}>
              <Button type="default" onClick={() => submit({ step: 0 })}>
                Quay lại
              </Button>
            </Col>
            <Col style={{ marginRight: 10 }}>
              <Button type="primary" onClick={handleImport}>
                Thực hiện nhập hàng
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  submit({ step: 0 })
                  setOpen(false)
                }}
              >
                Đóng
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        {loading === true ? (
          <></>
        ) : (
          <>
            <p style={{ color: 'red' }}>
              Dưới đây là dữ liệu của 100 dòng được nhập trong file excel bạn vừa chọn.
            </p>
            <Table
              rowKey="sku"
              scroll={{ y: 400 }}
              columns={columns}
              dataSource={excelRows}
              size="small"
              pagination={false}
            />
          </>
        )}
      </div>
    </div>
  )
}
