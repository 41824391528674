import React, { useEffect, useState } from 'react'
import styles from 'components/Shipping/shipping.module.scss'
import { Form, Input, Radio, Checkbox, Row, Col } from 'antd'

//components
import accGHN from 'assets/shipping/ghn_1.png'
import tokenGHN from 'assets/shipping/ghn_2.png'
import { Button } from 'components/Button/Button'

export default function Ghtk({connectShipping}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const connect = async () => {
    try {
      const data = form.getFieldValue()
      const body = {
        token: data.token,
        shop_id: data.shop_id
      }
      await connectShipping(body)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Form
      form={form}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      // layout="horizontal"
      onFinish={connect}
    >
      <Form.Item label="Token API" name="token">
        <Input placeholder="Token API" />
      </Form.Item>
      <Form.Item label="Shop Id" name="shop_id">
        <Input placeholder="Shop Id" />
      </Form.Item>
      <Button
        style={{
          backgroundColor: '#6D24CF',
          border: 'none',
          color: 'white',
          borderRadius: '5px',
          width:"40%"
        }}
        htmlType="submit"
        text="Kết nối"
      />
      <div style={{ marginTop: 20, color: 'black', fontWeight: 'bold', fontSize: 22 }}>
        {'Hướng dẫn lấy Token'}
      </div>
      <div style={{ fontWeight: 'bold', fontSize: 16 }}>{'B1. Truy cập đường dẫn sau: '}</div>
      <a href="https://khachhang.ghn.vn/" target="_blank">
        {'https://khachhang.ghn.vn/'}
      </a>
      <div style={{ fontWeight: 'bold', fontSize: 16 }}>
        {'B2. Đăng nhập tài khoản (hoặc đăng ký nếu chưa có)'}
      </div>

      <div style={{ fontWeight: 'bold', fontSize: 16 }}>
        {'B3. Sau khi đăng nhập, bấm vào avatar'}
      </div>
      <img src={accGHN} alt="accGHN"></img>

      <div style={{ fontWeight: 'bold', fontSize: 16 }}>
        {'B4. Bấm Xem để hiện Token, copy ShopID và Token điền lên phần mềm'}
      </div>
      <img src={tokenGHN} alt="tokenGHN"></img>
    </Form>
  )
}
