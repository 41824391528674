import React, { useState } from 'react'
import { Button, Input, Modal, Select } from 'antd'
import styles from '../styles/modal-info-payment.module.scss'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const FormBanking = () => {
  return (
    <div>
      <span>Tên ngân hàng</span>
      <Input />
      <span>Số tài khoản ngân hàng</span>
      <Input />
      <span>Tên người nhận</span>
      <Input />
    </div>
  )
}

export const FormVNPay = () => {

    const [title, setTitle] = useState('Thanh toán trực tuyến qua VNPay')
    const [description, setDescription] = useState('Thanh toán trực tuyến qua VNPay')
    const [urlDefault, setUrlDefault] = useState('http://sandbox.vnpayment.vn/paymentv2/vpcpay.html')
    const [terminalID, setTerminalID] = useState('')
    const [secretKey, setSecretKey] = useState('')
    const [successPage, setSuccessPage] = useState('me')
    const [locale, setLocale] = useState('vn')


  return (
      <div className={styles['form-vnpay']}>
        <div className={styles['form-title']}>
          <span><QuestionCircleOutlined style={{fontSize: '12px'}}/> Tiêu đề</span>
          <span style={{marginTop: '19px'}}><QuestionCircleOutlined style={{fontSize: '12px'}}/> Mô tả</span>
          <span style={{marginTop: '40px'}}><QuestionCircleOutlined style={{fontSize: '12px'}}/> Url khởi tạo</span>
          <span style={{marginTop: '19px'}}><QuestionCircleOutlined style={{fontSize: '12px'}}/> Terminal ID</span>
          <span style={{marginTop: '19px'}}><QuestionCircleOutlined style={{fontSize: '12px'}}/> Secret Key</span>
          <span style={{marginTop: '19px'}}><QuestionCircleOutlined style={{fontSize: '12px'}}/> Success Page</span>
          <span style={{marginTop: '19px'}}><QuestionCircleOutlined style={{fontSize: '12px'}}/> Locale</span>
        </div>

        <div className={styles['form-input']}>
          <Input value={title} onChange={(e) => setTitle(e.target.value)}/>
          <Input.TextArea value={description} onChange={(e) => setDescription(e.target.value)} style={{marginTop: '10px', resize: 'none'}}/>
          <Input value={urlDefault} onChange={(e) => setUrlDefault(e.target.value)} style={{marginTop: '10px'}}/>
          <Input value={terminalID} onChange={(e) => setTerminalID(e.target.value)} style={{marginTop: '10px'}}/>
          <Input.Password value={secretKey} onChange={(e) => setSecretKey(e.target.value)} style={{marginTop: '10px'}}/>
          <Select value={successPage} onChange={(e) => setSuccessPage(e)} style={{width: '100%', marginTop: '10px'}}>
            <Select.Option value="me">Tài khoản của tôi</Select.Option>
          </Select>
          <Select value={locale} onChange={(e) => setLocale(e)} style={{width: '100%', marginTop: '10px'}}>
            <Select.Option value="vn">vn</Select.Option>
          </Select>
        </div>
      </div>
  )
}



const ModalInfoPayment = ({ item }) => {
  const [visible, setVisible] = useState(false)

  const handleCancelEdit = () => {
    setVisible(false)
  }

  return (
    <div>
      <Button onClick={() => setVisible(true)} style={{ marginTop: '10px' }}>
        Chỉnh sửa
      </Button>
      <Modal
        closable={false}
        title={[<h2>{item.name}</h2>]}
        visible={visible}
        footer={[
          <div>
            <Button onClick={handleCancelEdit}>Huỷ chỉnh sửa</Button>
            <Button type="primary">Liên kết</Button>
          </div>,
        ]}
      >
        {item.name == 'Chuyển khoản ngân hàng' && <FormBanking />}
        {item.name == 'VNPAY-QR' && <FormVNPay />}
      </Modal>
    </div>
  )
}

export default ModalInfoPayment
