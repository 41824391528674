import React, { useState, useEffect } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { PERMISSIONS } from 'consts'

//antd
import { Select, DatePicker, Tooltip, Button } from 'antd'

import locale from 'antd/es/date-picker/locale/de_DE'

import removeVietnameseTones from 'utils/removeUnicode'
import { useSelector } from 'react-redux'

const { Option } = Select
const { RangePicker } = DatePicker

export default function FilterDate({
  placeholder = t('common.filter_by_time'),
  paramsFilter = {},
  setParamsFilter,
  width = '100%',
  style,
  bordered = true,
  size = 'small',
  SetParamsFilterQuantity,
  title,
  valueTime = '',
  setValueTime,
  screen = 0
}) {
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [open, setOpen] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)

  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({})
  const [valueDateSearch, setValueDateSearch] = useState([
    moment(moment().subtract(29, 'days').format('YYYY-MM-DD')),
    moment(moment().format('YYYY-MM-DD')),
  ]) //dùng để hiện thị date trong filter by date

  const [datesNew, setDatesNew] = useState()
  const [dateSettings, setDateSettings] = useState([])
  const PARAMS = [
    'from_date',
    'to_date',
    'today',
    'yesterday',
    'this_week',
    'last_week',
    'last_month',
    'this_month',
    'this_year',
    'last_year',
  ]

  //check có đang filter date hay không
  //nếu không thì reset value select date và select time
  const resetFilterDate = () => {
    const keysParamsFilter = Object.keys(paramsFilter)
    for (let i = 0; i < keysParamsFilter.length; i++)
      if (PARAMS.includes(keysParamsFilter[i])) return
    if(screen === 1) setValueDateSearch()
    setValueDateTimeSearch({})
    setValueTime()
  }
  const setDataDate = (dates, dateStrings) => {
    setDatesNew(dates)
    setDateSettings(dateStrings)
  }
  //time disable
  const getDisabledMinutes = (selectedHour) => {
    var minutes = []
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i)
      }
    }
    return minutes
  }
  const setDatePicker = (dates, dateStrings) => {
    //khi search hoac filter thi reset page ve 1
    setOpen(!open)

    if (isOpenSelect) toggleOpenSelect()

    //nếu search date thì xoá các params date
    delete paramsFilter.today
    delete paramsFilter.yesterday
    delete paramsFilter.this_week
    delete paramsFilter.last_week
    delete paramsFilter.last_month
    delete paramsFilter.this_month
    delete paramsFilter.this_year
    delete paramsFilter.last_year

    //Kiểm tra xem date có được chọn ko
    //Nếu ko thì thoát khỏi hàm, tránh cash app
    //và get danh sách order
    if (!dateStrings[0] && !dateStrings[1]) {
      delete paramsFilter.from_date
      delete paramsFilter.to_date

      setValueDateSearch(null)
      setValueTime()
    } else {
      const dateFirst = dateStrings[0]
      const dateLast = dateStrings[1]
      setValueDateSearch(dates)
      setValueTime(`${dateFirst} -> ${dateLast}`)

      dateFirst.replace(/-/g, '/')
      dateLast.replace(/-/g, '/')

      paramsFilter.from_date = dateFirst
      paramsFilter.to_date = dateLast
    }
    if (SetParamsFilterQuantity) {
      SetParamsFilterQuantity({ ...paramsFilter })
    }
    paramsFilter.page = 1

    setParamsFilter({ ...paramsFilter })
  }

  useEffect(() => {
    resetFilterDate()
  }, [paramsFilter])

  return (
    <Tooltip
      title={
        <span>
          Lọc theo thời gian<br></br>
          {title}
        </span>
      }
    >
      {permissions.includes(PERMISSIONS.bo_loc_thoi_gian)  ? (
        <Select
          id='selectTime'
          size={size}
          style={{ width: width, ...style, height: '100%' }}
          bordered={bordered}
          open={isOpenSelect}
          onBlur={() => {
            if (isOpenSelect) toggleOpenSelect()
          }}
          onClick={() => {
            if (!isOpenSelect) toggleOpenSelect()
          }}
          onClear={() => {
            document.getElementById('selectTime').focus()
          }}
          allowClear
          showSearch
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(inputValue, option) =>
            removeVietnameseTones(option.props.children)
              .toLowerCase()
              .includes(removeVietnameseTones(inputValue).toLowerCase().trim())
          }
          value={valueTime || 'Lọc theo thời gian'}
          onChange={async (value) => {
            delete paramsFilter.today
            delete paramsFilter.yesterday
            delete paramsFilter.this_week
            delete paramsFilter.last_week
            delete paramsFilter.last_month
            delete paramsFilter.this_month
            delete paramsFilter.this_year
            delete paramsFilter.last_year
            setValueTime(value)

            //xoa params search date hien tai
            const p = Object.keys(valueDateTimeSearch)

            if (p.length) delete paramsFilter[p[0]]

            setValueDateSearch(null)
            delete paramsFilter.from_date
            delete paramsFilter.to_date

            if (isOpenSelect) toggleOpenSelect()

            if (value) {
              const searchDate = Object.fromEntries([[value, true]]) // them params search date moi
              paramsFilter.page = 1

              setParamsFilter({ ...paramsFilter, ...searchDate })
              setValueDateTimeSearch({ ...searchDate })
              if (SetParamsFilterQuantity) {
                delete paramsFilter.page
                delete paramsFilter.page_size
                SetParamsFilterQuantity({ ...paramsFilter, ...searchDate })
              }
            } else {
              setParamsFilter({ ...paramsFilter })
              setValueDateTimeSearch({})
              if (SetParamsFilterQuantity) {
                delete paramsFilter.page
                delete paramsFilter.page_size
                SetParamsFilterQuantity({ ...paramsFilter })
              }
            }
          }}
          dropdownStyle={{
            maxHeight: 400,
            overflow: 'auto',
          }}
          dropdownRender={(menu) => (
            <>
              {permissions.includes(PERMISSIONS.tuy_chon)  ? (
                <RangePicker
                  id="date-picker"
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect()
                  }}
                  disabledDate={(current) => {
                    // Can not select days before today and today
                    return current && current > moment().endOf('day')
                  }}
                  onClick={() => {
                    if (!open) setOpen(true)
                  }}
                  onOk={() => {
                    if(!open) toggleOpenSelect()}}
                  showTime
                  format={'YYYY-MM-DD'}
                  value={valueDateSearch}
                  onChange={(dates, dateStrings) => {
                    setDatePicker(dates, dateStrings)
                  }}
                  style={{ width: '100%' }}
                />
              ) : null}
              {menu}
            </>
          )}
        >
          {permissions.includes(PERMISSIONS.hom_nay) ? (
            <Option value="today">{t('common.today')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.hom_qua)  ? (
            <Option value="yesterday">{t('common.yesterday')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_nay)  ? (
            <Option value="this_week">{t('common.this_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_truoc)  ? (
            <Option value="last_week">{t('common.last_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_nay) ? (
            <Option value="this_month">{t('common.this_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_truoc) ? (
            <Option value="last_month">{t('common.last_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_nay) ? (
            <Option value="this_year">{t('common.this_year')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_truoc) ? (
            <Option value="last_year">{t('common.last_year')}</Option>
          ) : null}
        </Select>
      ) : null}
    </Tooltip>
  )
}
