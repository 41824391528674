import React, { useEffect, useState } from 'react'
import { Col, Row, Space, Rate } from 'antd'
import styles from '../feeds.module.scss'

export const BodyPost = ({ item }) => {
  const [readMore, setReadMore] = useState(false)
  const hashTag = [
    '#EKATA',
  ]

  if(!item) return <></>

  return (
    <div style={{ paddingBottom: '20px' }} className={styles['container-item']}>
      <div>
        <Space size={0} wrap={true} style={{ justifyContent: 'space-between', display: 'flex' }}>
          <div className={styles['container-name-drep']}>
            <p>
              Sản phẩm: {item?.products?.name} - Mã sản phẩm: {item?.products?.sku}
            </p>
          </div>
          <div>
            <Rate className={styles['rate']} allowHalf defaultValue={4} />
            <div className={styles['rate']}>
              <p>Đã bán 1234</p>
            </div>
          </div>
        </Space>
        <Row>
          <div className={styles['text-drep']}>
            <div>
              <span>
                {item.products?.description?.length < 300
                  ? item.products?.description
                  : readMore
                  ? item.products?.description + ' '
                  : item.products?.description?.slice(0, 300) + '...'}
              </span>
              {item.products?.description?.length > 300 && (
                <span className="readMore" onClick={() => setReadMore(!readMore)}>
                  {readMore ? (
                    <strong style={{ fontSize: 14, color: 'black' }}>Hide Description</strong>
                  ) : (
                    <strong style={{ fontSize: 14, color: 'black' }}>Read More</strong>
                  )}
                </span>
              )}
            </div>
          </div>

        </Row>
        <div>
            {hashTag.map((item, index) => (
              <div
                style={{
                  fontSize: '14px',
                  color: '#4F4F4F',
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                key={index}
              >
                {item}
              </div>
            ))}
          </div>
      </div>
    </div>
  )
}
