import styles from './shipping.module.scss'
import {
  ACTION,
  IMAGE_DEFAULT,
  PERMISSIONS,
  ROUTES,
  PAGE_SIZE,
  FILTER_SIZE,
  FILTER_COL_HEIGHT,
} from 'consts'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { socket } from '../../socket'
import localStorage from '../../utils/localStorage'
import { socketListenerRole } from '../../redux/actions/socket'

import { Button, Col, Form, Input, notification, Row, Popconfirm, Switch, Tooltip } from 'antd'

//apis
import {
  getShippings,
  deleteShippings,
  connectShippingGHN,
  connectShippingGHTK,
  updateShipping,
  connectShippingViettelPost,
} from 'apis/shipping'

//components
import Permission from 'components/permission'
import TitlePage from 'components/title-page'
import CloseIcon from 'assets/icons/close.svg'
import Modal from 'components/Modal'
import Ghtk from './ValueDefaultShipping/ghtk'
import Ghn from './ValueDefaultShipping/ghn'
import ViettelPost from './ValueDefaultShipping/viettel-post'
export default function Shipping() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const typingTimeoutRef = useRef(null)
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [shippings, setShippings] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [shippingCompanyName, setShippingCompanyName] = useState('')
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: PAGE_SIZE })
  const [valueSearch, setValueSearch] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const _connectShipping = async (body) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      if (shippingCompanyName === 2 || shippingCompanyName === 3){
        if (String(body.token).length < 6 || body.token == undefined) {
          dispatch({ type: ACTION.LOADING, data: false })
          notification.error({ message: 'Vui lòng nhập token hợp lệ' })
          return
        }
      }
      
      if (shippingCompanyName.shipping_company_id === 3 && body.shop_id == undefined) {
        dispatch({ type: ACTION.LOADING, data: false })
        notification.error({ message: 'Vui lòng nhập Shop Id hợp lệ' })
        return
      }
      let res
      if (shippingCompanyName.shipping_company_id === 3) res = await connectShippingGHN(body)
      else if (shippingCompanyName.shipping_company_id === 2) res = await connectShippingGHTK(body)
      else if (shippingCompanyName.shipping_company_id === 5)
        res = await connectShippingViettelPost(body)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          setModalVisible(false)
          notification.success({ message: res.data.message || 'Kích hoạt thành công' })
          _getShippings()
        } else {
          setModalVisible(false)
          notification.error({
            message: res.data.message || 'Kích hoạt thất bại, vui lòng kiểm tra khoá Token',
          })
        }
      } else
        notification.error({
          message: res.data.message || 'Kích hoạt thất bại, vui lòng kiểm tra khoá Token',
        })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _getShippings = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getShippings(paramsFilter)
      if (res.status === 200) setShippings(res.data.data)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _deleteShippings = async (id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteShippings([id])
      if (res.status === 200) {
        if (res.data.success) {
          _getShippings()
          notification.success({ message: 'Xóa đối tác vận chuyển thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xóa đối tác vận chuyển thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa đối tác vận chuyển thất bại, vui lòng thử lại!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (e) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(e)
    }
  }

  const onSearch = (e) => {
    setValueSearch(e.target.value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      if (value) paramsFilter.name = value
      else delete paramsFilter.name

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const _onChangeActive = async (id, active) => {
    try {
      var response = await updateShipping({ active: active, branch_id: branchIdApp }, id)

      if (response.status == 200) {
        notification.success({
          message: `Cập nhật thành công`,
        })
        _getShippings()
      } else {
        notification.error({
          message: response.data.message ? response.data.message : `Cập nhật thất bại`,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const ModalConnectShipping = ({ children, connectShipping, shipping }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle} style={{ width: '100%' }}>
          {children}
        </div>
        <Modal
          visible={visible}
          title={`Kết nối tự động vận chuyển ${shipping.name}`}
          onCancel={toggle}
          style={{ top: 20 }}
          width="60%"
          closeIcon={<img src={CloseIcon} alt="" />}
          footer={null}
        >
          {shipping.shipping_company_id === 2 ? (
            <Ghtk connectShipping={connectShipping} close={toggle} />
          ) : shipping.shipping_company_id === 3 ? (
            <Ghn connectShipping={connectShipping} close={toggle} />
          ) : <ViettelPost connectShipping={connectShipping} close={toggle} />}
        </Modal>
      </>
    )
  }


  useEffect(() => {
    _getShippings()
  }, [paramsFilter])

  useEffect(() => {
    if (!modalVisible) form.resetFields()
  }, [modalVisible])

  return (
    <div className="card">
      <TitlePage
        isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Đối tác vận chuyển
          </Row>
        }
      >
        <Permission permissions={[PERMISSIONS.them_doi_tac_van_chuyen]}>
          <Button
            onClick={() => history.push(ROUTES.SHIPPING_CREATE)}
            icon={<PlusCircleOutlined />}
            type="primary"
            size="large"
          >
            Thêm vận chuyển
          </Button>
        </Permission>
      </TitlePage>

      {permissions.includes(PERMISSIONS.xem_danh_sach_doi_tac_van_chuyen)?(<>
        <Row gutter={[20, 20]} style={{ padding: '20px 10px' }} justify="space-between">
        {shippings.map((shipping) => (
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Row
              justify="space-between"
              style={{
                boxShadow: '0px 4px 25px rgba(37, 107, 254, 0.25)',
                padding: 20,
                borderRadius: 10,
                backgroundColor: 'white',
              }}
            >
              <div>
                <img alt="" style={{ width: 80, height: 80 }} src={shipping.image} />
                <p style={{ fontWeight: 'bold' }}>
                  {shipping.name || '\n'} - {shipping.phone || ''}
                </p>
              </div>

              <div style={{ textAlign: 'end', display: 'flex', flexDirection: 'column' }}>
                <div style={{ textAlign: 'center', alignSelf: 'center' }}>{'Kích hoạt'}</div>
                <Permission permissions={[]}>
                  <Switch
                    defaultChecked={shipping.active}
                    checked={shipping.active}
                    style={{ width: 60, alignSelf: 'center' }}
                    title={'Kích hoạt'}
                    onChange={(checked) => {
                      _onChangeActive(shipping.shipping_company_id, checked)
                    }}
                  />
                </Permission>

                <br />

                <a style={{ textAlign: 'center' }}>Liên kết tự động là gì?</a>

                <Permission permissions={[]}>
                  <ModalConnectShipping connectShipping={_connectShipping} shipping={shipping}>
                    <Button
                      disabled={shipping.shipping_company_id === 1}
                      onClick={() => {
                        setShippingCompanyName(shipping)
                        setModalVisible(true)
                        if (shipping.token != '' && shipping.token != undefined) {
                          form.setFieldsValue({ token: shipping.token })
                        }
                        if (shipping.shop_id != undefined)
                          form.setFieldsValue({ shop_id: shipping.shop_id })
                      }}
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      {String(shipping.token).length > 10
                        ? 'Đã liên kết, bấm để sửa'
                        : 'Bấm để liên kết tự động'}
                    </Button>
                  </ModalConnectShipping>
                </Permission>
                <Permission permissions={[PERMISSIONS.xoa_doi_tac_van_chuyen]}>
                  <Popconfirm
                    okText="Đồng ý"
                    cancelText="Từ chối"
                    onConfirm={() => _deleteShippings(shipping.shipping_company_id)}
                    title="Bạn có muốn xóa đối tác vận chuyển này không?"
                  >
                    <Button type="danger" style={{ border: 'none' }}>
                      Xoá
                    </Button>
                  </Popconfirm>
                </Permission>
              </div>
            </Row>
          </Col>
        ))}
      </Row></>):null}
    </div>
  )
}
