import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import styles from './refund-form.module.scss'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { IMAGE_DEFAULT, ROUTES, TIME_FORMAT } from 'consts'
import { formatCash, removeUnicode } from 'utils'
import { useTranslation } from 'react-i18next'
import { PERMISSIONS } from 'consts'
import { TableProduct } from './tableProduct'

//antd
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  InputNumber,
  notification,
  Select,
  Space,
  Form,
  Checkbox,
  Avatar,
  Switch,
  Timeline,
  Tooltip,
  Spin,
  Tag,
  Modal,
  Dropdown,
} from 'antd'
import Permission from 'components/permission'
//icons
import {
  ArrowLeftOutlined,
  UserOutlined,
  SearchOutlined,
  CloseOutlined,
  CloseCircleTwoTone,
  DownOutlined,
} from '@ant-design/icons'
import noData from 'assets/icons/no-data.png'

//apis
import { getOrdersCreateRefund, addOrderRefund } from 'apis/order'

//components
import TitlePage from 'components/title-page'
import TextArea from 'antd/lib/input/TextArea'
import PaymentMethods from './change-payment'
import ModalProductLocation from './quantityProductModal'
import moment from 'moment'

const RefundCreate = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)

  const [ellipsis] = useState(true)

  //object order create
  const [orderRefund, setOrderRefund] = useState({
    code_order: '',
    products: [],
    sumCostPaid: 0,
    totalProduct: 0,
    branch_id: branchIdApp,
    is_auto_update_inventory: true,
    is_received: true,
    is_auto_create_report: true,
    is_payment: true,
    total_discount: 0,
    total_tax: 0,
    total_cost: 0,
    increasePoint: 0,
    decreasePoint: 0,
    customer_debt: 0,
  })
  const [listPayments, setListPayments] = useState([])
  const [customers, setCustomers] = useState()
  const [order, setOrders] = useState([])
  const [listOrderSelected, setListOrderSelected] = useState()
  const [orderId, setOrdersId] = useState()
  const [checkTmp, setCheckTmp] = useState(0)
  const [date, setDate] = useState(new Date())
  const [dataCodeRefund] = useState(localStorage.getItem('app_1.0.10_refundCode'))
  const [loadingButton, setLoadingButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState()
  const [paymentAmount, setPaymentAmount] = useState(0)

  const [formRefund] = Form.useForm()
  const [checked, setChecked] = useState(false)
  const [visiblePayments, setVisiblePayments] = useState(false)

  const _getOrders = async () => {
    try {
      setLoading(true)
      const res = await getOrdersCreateRefund({ branch_id: branchIdApp, refund_create: 1 ,status: 200, decreasePoint:0})
      if (res.status === 200) {
        setOrders(res.data.data)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  const _getPrice = (record, orderRefund) => {
    let price = 0
    if (orderRefund && orderRefund.total_cost === 0) {
      return price
    } else {
      price =
        record.refund_quantity *
        (record.price -
          record.total_discount / record.quantity -
          ((record.price - record.total_discount / record.quantity) /
            (orderRefund.total_cost + orderRefund.total_discount)) *
            orderRefund.total_discount +
          (record.price -
            record.total_discount / record.quantity -
            ((record.price - record.total_discount / record.quantity) /
              (orderRefund.total_cost + orderRefund.total_discount)) *
              orderRefund.total_discount) *
            (orderRefund.total_tax / orderRefund.total_cost))
      return Number(price.toFixed())
    }
  }
  const _getPriceOrderNew = (product, orderRefundNew, total_discountTmp) => {
    let price = 0
    price =
      product.refund_quantity *
      (product.price -
        product.total_discount / product.quantity -
        ((product.price - product.total_discount / product.quantity) /
          (orderRefundNew.total_cost + (orderRefundNew.total_discount - total_discountTmp))) *
          (orderRefundNew.total_discount - total_discountTmp) +
        (product.price -
          product.total_discount / product.quantity -
          ((product.price - product.total_discount / product.quantity) /
            (orderRefundNew.total_cost + (orderRefundNew.total_discount - total_discountTmp))) *
            (orderRefundNew.total_discount - total_discountTmp)) *
          (orderRefundNew.total_tax / orderRefundNew.total_cost) || 0)
    return Number(price.toFixed())
  }

  const _onUpdateOrderSelected = (_listOrderSelected) => {
    if (_listOrderSelected == null || typeof _listOrderSelected == 'undefined') return
    var orderSelect = {}
    setImeiTotal([])
    orderSelect = order.find((_order) => _order.code == _listOrderSelected)
    var orderRefundNew = {
      code_order: orderSelect.code,
      products: [],
      sumCostPaid: 0,
      totalProduct: 0,
      branch_id: branchIdApp,
      is_auto_update_inventory: true,
      is_received: true,
      is_auto_create_report: true,
      is_payment: true,
      total_discount: orderSelect.total_discount,
      total_tax: orderSelect.total_tax,
      total_cost: orderSelect.total_cost,
      increasePoint: orderSelect.increasePoint,
      decreasePoint: orderSelect.decreasePoint,
      customer_debt: orderSelect.customer_debt,
    }
    setOrdersId(orderSelect.order_id)
    setListOrderSelected(_listOrderSelected)
    setChecked(true)
    setCustomers(orderSelect.customer_info)
    let lo_arr = []

    orderSelect.order_details.map((item) => {
      if (item?.refund_location.length === 0) {
        lo_arr = item.location.reduce((arr, e) => {
          if (!e.refund_quantity) {
            e['refund_quantity_max'] = e.quantity
            e['refund_quantity'] = e.quantity
            e['total_refund_quantity'] = 0
            e['refund_quantity_new'] = e.quantity
          }
          arr.push(e)
          return arr
        }, [])
      } else {
        lo_arr = item.refund_location.reduce((arr, e) => {
          e['refund_quantity_max'] = e.quantity - e.refund_quantity
          e['total_refund_quantity'] = e.refund_quantity
          e['refund_quantity_new'] = e.quantity - e.refund_quantity
          e['refund_quantity'] = e.quantity - e.refund_quantity
          arr.push(e)
          return arr
        }, [])
      }
      // let arrImei = [...item.imei]
      if (item.refund_imei.length > 0) {
        item.refund_imei.map((e) => {
          let index = item.imei.findIndex((item) => item === e)
          item.imei.splice(index, 1)
        })
      }
      const _item = {
        ...item,
        total_refund_quantity: item.refund_quantity || 0,
        refund_quantity: item.quantity - (item.refund_quantity || 0),
        refund_from_order_info: 1 || {},
        location_refund: lo_arr,
        imei_change: [],
      }
      orderRefundNew.products.push(_item)
    })
    let total_discountTmp = 0
    for (const i of orderRefundNew.products) {
      total_discountTmp = total_discountTmp + (i.total_discount || 0)
    }
    // tổng tiền của tất cả sản phẩm
    if (orderRefundNew.total_cost === 0) {
      orderRefundNew.sumCostPaid = 0
    } else {
      orderRefundNew.sumCostPaid = orderRefundNew.products.reduce(
        (total, current) =>
          total + (_getPriceOrderNew(current, orderRefundNew, total_discountTmp) || 0),
        0
      )
    }

    // tổng số lượng hàng trả
    orderRefundNew.totalProduct = orderRefundNew.products.reduce(
      (total, current) => total + (current.quantity - (current.total_refund_quantity || 0)),
      0
    )
    setOrderRefund({
      ...orderRefundNew,
      products: orderRefundNew.products,
      sumCostPaid: orderRefundNew.sumCostPaid,
      totalProduct: orderRefundNew.totalProduct,
      total_discount: orderRefundNew.total_discount - total_discountTmp || 0,
    })
    _editInvoice(0,[])
    setCheckTmp(orderRefundNew.totalProduct)
    const listProductNew = orderRefundNew.products.filter(
      (item) => item.total_refund_quantity < item.quantity
    )
    if (listProductNew.length === 0) {
      notification.warning({ key: 'none', message: 'Đơn hàng đã trả toàn bộ' })
    } else {
      if (orderSelect.customer_debt > 0) notification.warn({ message: 'Đơn hàng có nợ' })
      else
        notification.success({
          key: 'addProduct',
          message: 'Đã thêm sản phẩm của đơn hàng vào phiếu',
        })
    }
  }

  const _editProductInOrder = (attribute, value, index) => {
    if (index !== -1) {
      let orderRefundNew = { ...orderRefund }
      orderRefundNew.products[index][attribute] = value
      //tổng tiền của 1 sản phẩm
      orderRefundNew.products[index].sumCost =
        orderRefundNew.products[index].refund_quantity *
        (orderRefundNew.products[index].price -
          orderRefundNew.products[index].total_discount / orderRefundNew.products[index].quantity)
      // tổng tiền của tất cả sản phẩm
      orderRefundNew.sumCostPaid = orderRefundNew.products.reduce(
        (total, current) => total + _getPrice(current, orderRefundNew),
        0
      )
      // tổng số lượng hàng trả
      orderRefundNew.totalProduct = orderRefundNew.products.reduce(
        (total, current) => total + current.refund_quantity,
        0
      )
      if (orderRefundNew.totalProduct === checkTmp) setChecked(true)
      else setChecked(false)
      setOrderRefund(orderRefundNew)
    }
  }
  const _checkboxAll = (e) => {
    setChecked(e.target.checked)
    const orderRefundNew = { ...orderRefund }
    orderRefundNew.products.map(
      (item) => (item.refund_quantity = item.quantity - item.total_refund_quantity)
    )

    orderRefundNew.sumCostPaid = orderRefundNew.products.reduce(
      (total, current) => total + _getPrice(current, orderRefundNew),
      0
    )
    // tổng số lượng hàng trả
    orderRefundNew.totalProduct = orderRefundNew.products.reduce(
      (total, current) => total + (current.refund_quantity || 0),
      0
    )
    setOrderRefund({ ...orderRefundNew })
  }
  const toolTipModal = (record) => (
    <>
      <table style={{ width: 300 }}>
        <tr style={{ borderBottom: '1px solid #ddd' }}>
          <th style={{ width: '50%' }}>Đơn giá</th>
          <td>{formatCash(record.refund_quantity * record.price)}</td>
        </tr>
        <tr>
          <td style={{ width: '50%' }}>
            Chiết khấu (
            {record.price > 0 ? ((record.total_discount / record.quantity / record.price) * 100).toFixed(1):0})%
          </td>
          <td>
            <div>
              {formatCash(record.refund_quantity * (record.total_discount / record.quantity))}
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: '50%' }}>Chiết khấu tổng đơn</td>
          <td>
            <div>
              {record.price > 0 && record.total_discount < record.price ? formatCash(
                (
                  record.refund_quantity *
                  (((record.price - record?.total_discount / record.quantity) /
                    (orderRefund.total_cost + orderRefund.total_discount)) *
                    orderRefund.total_discount)
                ).toFixed(0)
              ): 0}
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: '50%' }}>
            Thuế ({orderRefund.total_cost > 0 ?((orderRefund.total_tax / orderRefund.total_cost) * 100).toFixed(1):0})%
          </td>
          <td>
            <div>
              {formatCash(
                record.refund_quantity *
                  ((record.price -
                    record?.total_discount / record.quantity -
                    ((record.price - record?.total_discount / record.quantity) /
                      (orderRefund.total_cost + orderRefund.total_discount)) *
                      orderRefund.total_discount) *
                    (orderRefund.total_tax / orderRefund.total_cost))
              )}
            </div>
          </td>
        </tr>
      </table>
    </>
  )
  const [imeiTotal, setImeiTotal] = useState([])
  const RemoveItemLocation = (product, e, index) => {
    let indexProduct = orderRefund.products.findIndex(
      (e) => e.variant_info.variant_id === product?.variant_info?.variant_id
    )
    let arrReturn = [...product.location_refund]

    arrReturn[index].refund_quantity = 0

    _editProductInOrder(
      'refund_quantity',
      arrReturn.reduce((total, item) => (total += item.refund_quantity), 0),
      indexProduct
    )
    _editProductInOrder('location_refund', arrReturn, indexProduct)
  }
  const RemoveItemImei = (product, index) => {
    let indexProduct = orderRefund.products.findIndex(
      (e) => e.variant_info.variant_id === product?.variant_info?.variant_id
    )
    let new_imei = [...product.imei]
    let new_imei_change = [...product.imei_change]
    let a = new_imei[index]
    new_imei.splice(index, 1)
    new_imei_change.push(a)
    let audi = [...imeiTotal]

    let i = audi.findIndex((e) => e.product_id === product.product_id)
    if (i === -1) {
      let b = {
        product_id: product.product_id,
        arr: new_imei_change,
      }
      audi.push(b)
    } else {
      audi[i].arr = new_imei_change
    }

    setImeiTotal(audi)

    _editProductInOrder('refund_quantity', new_imei.length, indexProduct)
    _editProductInOrder('imei', new_imei, indexProduct)
    _editProductInOrder('imei_change', new_imei_change, indexProduct)
  }
  const ChangeItemImei = (product, index) => {
    let indexProduct = orderRefund.products.findIndex(
      (e) => e.variant_info.variant_id === product?.variant_info?.variant_id
    )
    let new_imei = [...product.imei]
    let new_imei_change = [...product.imei_change]
    let a = new_imei_change[index]
    new_imei_change.splice(index, 1)
    new_imei.push(a)
    let audi = [...imeiTotal]

    let i = audi.findIndex((e) => e.product_id === product.product_id)
    if (i === -1) {
      let b = {
        product_id: product.product_id,
        arr: new_imei_change,
      }
      audi.push(b)
    } else {
      audi[i].arr = new_imei_change
    }

    setImeiTotal(audi)
    _editProductInOrder('refund_quantity', new_imei.length, indexProduct)
    _editProductInOrder('imei', new_imei, indexProduct)
    _editProductInOrder('imei_change', new_imei_change, indexProduct)
  }

  const MenuItem = ({ product }) => {
    return (
      <div
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          backgroundColor: '#fff',
          padding: '10px',
        }}
      >
        {Object(imeiTotal.find((item) => item.product_id == product.product_id)).arr?.map(
          (item, index_imei) => (
            <div
              onClick={() => {
                ChangeItemImei(product, index_imei)
              }}
              className={styles['imei-item']}
              key={index_imei}
            >
              <span className={styles['imei-item-span']}>{item}</span>
            </div>
          )
        )}
      </div>
    )
  }
  const RenderTypeAdvanced = ({ product, index }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    switch (product.type_advanced) {
      case 1:
        return (
          <InputNumber
            style={{ width: 70, marginRight: 12 }}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '').replaceAll('.', '') || 1
              if (value > product.quantity - (product.total_refund_quantity || 0)) {
                notification.error({
                  message: `Số lượng trả không thể lớn hơn ${
                    product.quantity - (product.total_refund_quantity || 0)
                  }`,
                })
                return
              }
              const indexProduct = orderRefund.products.findIndex(
                (e) => e.variant_info.variant_id === product?.variant_info?.variant_id
              )
              if (value >= 0) _editProductInOrder('refund_quantity', +value, indexProduct)
            }}
            defaultValue={product.refund_quantity}
            min={0}
            max={product.quantity - (product.total_refund_quantity || 0)}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Nhập số lượng"
          />
        )
      case 2:
        return (
          <>
            <InputNumber
              style={{ width: 70, marginRight: 12 }}
              value={product.refund_quantity}
              disabled={true}
            />
            <ModalProductLocation
              index={index}
              product={product}
              ellipsis={ellipsis}
              _editProductInOrder={_editProductInOrder}
              toggle={toggle}
              visible={visible}
            />
          </>
        )

      case 3:
        return (
          <div>
            <InputNumber
              style={{ width: 70, marginRight: 12 }}
              value={product.refund_quantity}
              disabled={true}
            />
            <Dropdown overlay={<MenuItem product={product} index={index} />} trigger={['click']}>
              <Input
                placeholder="Chọn iMei..."
                style={{ height: '28px', width: '120px' }}
                onClick={(e) => e.preventDefault()}
              />
            </Dropdown>
          </div>
        )

      default:
        return <></>
    }
  }
  const columns = [
    {
      title: `STT`,
      render: (text, record, index) => index + 1,
    },
    {
      title: `Mã sản phẩm`,
      dataIndex: 'sku',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'title',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'title',
      render: (data, record) => (
        <img
          src={record?.variant_info?.image[0] ? record?.variant_info?.image[0] : record?.product_info?.images[0] ? record?.product_info?.images[0] : IMAGE_DEFAULT}
          style={{ width: 50, height: 50 }}
          alt=""
        />
      ),
    },
    {
      title: 'Số lượng mua',
      render: (data, record) => (
        <div style={{ width: 80, textAlign: 'center', fontWeight: 'bold' }}>{record.quantity}</div>
      ),
    },
    {
      title: 'Số lượng đã trả',
      render: (data, record) => (
        <div style={{ width: 80, textAlign: 'center', fontWeight: 'bold' }}>
          {record.total_refund_quantity}
        </div>
      ),
    },
    {
      title: 'Số lượng trả',
      render: (data, record, index) => (
        <div style={{ display: 'flex' }}>
          <RenderTypeAdvanced product={record} index={index} />
        </div>
      ),
    },
    {
      title: 'Giá hàng trả',
      render: (data, record) => (
        <div
          onBlur={(e) => {
            const value = e.target.value.replaceAll(',', '')
            const indexProduct = orderRefund.products.findIndex(
              (e) => e.variant_id === record?.variant_info?.variant_id
            )
            _editProductInOrder('price', +value, indexProduct)
          }}
        >
          <Tooltip placement="bottom" title={toolTipModal(record)}>
            {formatCash(_getPrice(record, orderRefund))}
          </Tooltip>
        </div>
      ),
    },

    {
      title: 'Lý do trả',
      render: (data, record) => (
        <>
          <Select
            onChange={(e) => {
              const indexProduct = orderRefund.products.findIndex(
                (item) => item.variant_info.variant_id === record?.variant_info?.variant_id
              )
              if (e == 'Lý do khác') {
                _editProductInOrder('other_reason', 1, indexProduct)
              } else {
                _editProductInOrder('refund_reason', e, indexProduct)
                _editProductInOrder('other_reason', 0, indexProduct)
              }
            }}
            style={{ width: '20em' }}
            placeholder="Lý do trả"
          >
            <Select.Option value="Chưa nhận được hàng">Chưa nhận được hàng</Select.Option>
            <Select.Option value="Thiếu hàng">Thiếu hàng</Select.Option>
            <Select.Option value="Người bán gửi sai hàng">Người bán gửi sai hàng</Select.Option>
            <Select.Option value="Hàng bể vỡ">Hàng bể vỡ</Select.Option>
            <Select.Option value="Hàng lỗi, không hoạt động">
              Hàng lỗi, không hoạt động
            </Select.Option>
            <Select.Option value="Hàng giả/nhái (cần bằng chứng)">
              Hàng giả/nhái (cần bằng chứng)
            </Select.Option>
            <Select.Option value="Khác với mô tả">Hàng đã qua sử dụng</Select.Option>
            <Select.Option value="Lý do khác">Lý do khác</Select.Option>
          </Select>
          {record.other_reason === 1 ? (
            <Input
              onBlur={(e) => {
                const value = e.target.value
                const indexProduct = orderRefund.products.findIndex(
                  (e) => e.variant_info.variant_id === record?.variant_info?.variant_id
                )
                _editProductInOrder('refund_reason', value, indexProduct)
              }}
              style={{ width: '10em' }}
              placeholder="Lý do trả"
            />
          ) : null}
        </>
      ),
    },
  ]
  const RenderRowTypeAdvanced = ({ product, index }) => {
    switch (product.type_advanced) {
      case 2:
        return (
          <div>
            <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
              {product?.location_refund?.length > 0 ? (
                product?.location_refund?.map((e, index) => (
                  <div style={{ display: 'flex' }}>
                    {e.refund_quantity > 0 ? (
                      <Tag
                        style={{
                          padding: 4,
                          marginBottom: 4,
                          backgroundColor: '#cccc',
                          borderRadius: 4,
                        }}
                      >
                        {e.code} | {e.exp} | SL: <strong>{e.refund_quantity}</strong>
                        <CloseCircleTwoTone
                          style={{ marginLeft: 4 }}
                          onClick={() => RemoveItemLocation(product, e, index)}
                        />
                      </Tag>
                    ) : (
                      <></>
                    )}
                  </div>
                ))
              ) : (
                <p style={{ marginLeft: 40, color: 'red', marginBottom: 0, fontSize: 12 }}>
                  Sản phẩm chưa có lô trả, vui lòng chọn...{' '}
                </p>
              )}
            </div>
          </div>
        )
      case 3:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product?.imei?.length > 0 ? (
              product?.imei?.map((e, index) => (
                <div style={{ display: 'flex' }}>
                  <Tag
                    style={{
                      padding: 4,
                      marginBottom: 4,
                      backgroundColor: '#cccc',
                      borderRadius: 4,
                    }}
                  >
                    <strong>{e}</strong>
                    <CloseCircleTwoTone
                      style={{ marginLeft: 4 }}
                      onClick={() => RemoveItemImei(product, index)}
                    />
                  </Tag>
                </div>
              ))
            ) : (
              <p style={{ marginLeft: 40, color: 'red', marginBottom: 0, fontSize: 12 }}>
                Sản phẩm chưa có iMei trả, vui lòng chọn...{' '}
              </p>
            )}
          </div>
        )
      default:
        return <></>
    }
  }
  const onCreateRefundOrder = async (status) => {
    setLoadingButton(true)
    try {
      await formRefund.validateFields()

      if (orderRefund.totalProduct > 0) {
        // for(let product of orderRefund.products){
        //   if(product.type_advanced === 2 && product?.location_refund?.length > 0 ){
        //     let quantity = product?.location_refund?.reduce((total, item) => (total += item.refund_quantity), 0)
        //     console.log(quantity, product.refund_quantity)
        //     if(product.refund_quantity > quantity){
        //       notification.warning({
        //         message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
        //       })

        //     }
        //   }
        // }
        const dataList = []
        orderRefund.products.forEach((product) => {
          if (product.type_advanced === 2 && product?.location_refund?.length > 0) {
            let quantity = product?.location_refund?.reduce(
              (total, item) => (total += item.refund_quantity),
              0
            )
            if (product.refund_quantity > quantity) {
              return notification.warning({
                message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
              })
            }
          }
          const data = {
            product_id: product.product_id,
            variant_id: product.variant_id,
            quantity: product.quantity || 1,
            total_refund_quantity: product.refund_quantity,
            refund_quantity: product.refund_quantity,
            refund_reason: product.refund_reason || '',
            refund_from_order_info: product.refund_from_order_info,
            refund_price: _getPrice(product, orderRefund),
            total_discount:
              Number((product.total_discount +
                (product.refund_quantity *
                  (((product.price - product?.total_discount / product.quantity) /
                    (orderRefund.total_cost + orderRefund.total_discount)) *
                    orderRefund.total_discount))).toFixed())|| 0,
            price: product.price || 0,
            type_advanced: product.type_advanced || 1,
            imei_refund: product.type_advanced === 3 ? product.imei : [],
            location_refund: product.type_advanced === 2 ? product.location_refund : [],
          }
          if (data.refund_quantity > 0) {
            dataList.push(data)
          }
        })
        let listTmp = [...listPayments]

        if (orderRefund.sumCostPaid === 0) {
          listTmp.push({
            name: 'TIỀN MẶT',
            payment_method_id: 1,
            value: 0,
          })
        }
        const data = {
          branch_id: orderRefund.branch_id,
          order_details: dataList,
          status: status,
          note: note,
          payment_infos: listTmp,
          payment_amount: parseFloat(paymentAmount || 0),
          total_refund: orderRefund.sumCostPaid,
          customer_id: customers.customer_id,
          list_order_select: listOrderSelected,
          orderId: orderId,
          is_payment: orderRefund.is_payment,
          is_received: orderRefund.is_received,
          is_auto_create_report: orderRefund.is_auto_create_report,
          is_auto_update_inventory: orderRefund.is_auto_update_inventory,
          reference: formRefund.getFieldsValue().reference_payment,
          increasePoint: orderRefund.increasePoint,
          decreasePoint: orderRefund.decreasePoint,
          customer_debt: orderRefund.customer_debt,
        }
        if (paymentAmount !== orderRefund.sumCostPaid) {
          notification.warning({ key: 'money', message: 'Số tiền hoàn trả không hợp lệ !' })
        } else {
          const res = await addOrderRefund(data)
          if (res.data.success) {
            notification.success({ key: 'successful', message: 'Tạo phiếu trả hàng thành công' })
            history.push('/refund')
          } else {
            notification.warning({
              key: 'short',
              message: res.data.message || 'Vui lòng điền đầy đủ thông tin!',
            })
          }
        }
      } else {
        notification.warning({
          key: 'unsuccessful',
          message: 'Phiếu trả hàng không có sản phẩm trả',
        })
      }
      setLoadingButton(false)
    } catch (err) {
      setLoadingButton(false)
      console.log(err)
    }
  }

  const _editInvoice = (value, payments) => {
    setPaymentAmount(value)
    setListPayments(payments)
  }
  useEffect(() => {
    _getOrders()
  }, [branchIdApp])

  const [inputText, setInputText] = useState('')
  let onSearch = (e) => {
    var lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }
  const listProductNew = orderRefund.products.filter(
    (item) => item.total_refund_quantity < item.quantity
  )

  const filteredData = listProductNew.filter((e) => {
    if (inputText === '') {
      return e
    } else {
      return (
        removeUnicode(e.sku.toLowerCase()).includes(inputText) ||
        removeUnicode(e.title.toLowerCase()).includes(inputText)
      )
    }
  })

  const _updatepayment = (e) => {
    let listNew = [...listPayments]
    let value = paymentAmount
    value = value - listNew[e].value
    setPaymentAmount(value)
    listNew.splice(e, 1)

    setListPayments(listNew)
  }

  useEffect(() => {
    if (dataCodeRefund == null || !dataCodeRefund) return
    if (order.length > 0) {
      _onUpdateOrderSelected(String(dataCodeRefund))
      localStorage.removeItem('app_1.0.10_refundCode')
    }
  }, [dataCodeRefund, order])
  return (
    <div className="card">
      <Spin spinning={loadingButton} tip="Loading..." size="large">
        <TitlePage
          isAffix
          title={
            <Row
              align="middle"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(ROUTES.REFUND)}
            >
              <ArrowLeftOutlined style={{ marginRight: 5 }} />
              Tạo phiếu trả hàng
            </Row>
          }
        >
          <Space>
            <Button
              loading={loadingButton}
              onClick={() => onCreateRefundOrder(200)}
              size="large"
              type="primary"
            >
              Tạo và thực hiện
            </Button>
          </Space>
        </TitlePage>
        <Form form={formRefund} >
          <Row style={{ marginTop: 20 }}>
            <Col span={24}>
              <Row>
                <Col span={11} className={styles['block']} style={{ marginBottom: 10, backgroundColor:'white'}}>
                  <Row justify="space-between" className={styles['title']}>
                    <div>Chọn từ đơn hàng mua (Không thể trả đơn hàng thanh toán bằng điểm) </div>
                  </Row>
                  <Row justify="space-between" align="middle" wrap={false}>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Tìm kiếm từ mã đơn hàng"
                      onChange={_onUpdateOrderSelected}
                      value={listOrderSelected}
                      filterOption={(input, option) =>
                        removeUnicode(option.value.toString())
                          .toLowerCase()
                          .trim()
                          .includes(removeUnicode(input).toLowerCase().trim())
                      }
                      dropdownRender={(menu) => (
                        <div>
                          {loading ? (
                            <Spin
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                              }}
                              size="middle"
                            />
                          ) : (
                            menu
                          )}
                        </div>
                      )}
                    >
                      {order &&
                        order.map((item, index) => (
                          <Select.Option value={item.code} key={item.code}>
                            <Row key={item.code}>
                              #
                              {item.code +
                                ` (khách hàng: ${
                                  item.customer_info.phone +
                                  ' - ' +
                                  item.customer_info.first_name +
                                  ' ' +
                                  item.customer_info.last_name
                                })`}
                            </Row>
                          </Select.Option>
                        ))}
                    </Select>
                  </Row>
                  {customers ? (
                    <div style={{ marginTop: 20 }}>
                      <div className={styles['title']}>Thông tin khách hàng</div>
                      <Row style={{ width: '100%', margin: 15 }}>
                        <Row style={{ width: '100%', marginBottom: 6 }}>
                          <div style={{ display: 'flex' }}>
                            <div style={{ margin: 10 }}>
                              <Avatar
                                style={{
                                  backgroundColor: '#0089DF',
                                }}
                                icon={<UserOutlined />}
                              />
                            </div>
                            <div>
                              <div style={{ color: '#0089DF', fontSize: 20, fontWeight: 600 }}>
                                {customers.first_name} {customers.last_name}
                              </div>
                              <div style={{ fontWeight: 'bold' }}>{customers.phone}</div>
                            </div>
                            <div>
                              <div style={{ margin: '10px 10px 0 100px' }}>
                                Công nợ hiện tại: {customers.debt}
                              </div>
                            </div>
                          </div>
                        </Row>
                      </Row>
                    </div>
                  ) : null}
                  <TextArea
                    onChange={(e) => setNote(e.target.value)}
                    style={{ width: '100%', marginTop: 6 }}
                    placeholder="Ghi chú"
                    rows={3}
                  />
                </Col>
                <Col span={11} className={styles['block']} style={{ flexDirection: 'column', marginLeft: 30,marginBottom:10, backgroundColor:'white'}}>
                    <div className={styles['title']}>Thông tin thanh toán</div>
                    <Form.Item name="date" className="custom-form-item" label="Thời gian">
                      <Input
                        style={{ marginLeft: 50, width: '90% ' }}
                        defaultValue={`${moment().format(TIME_FORMAT.DATE_TIME)}`}
                      />
                    </Form.Item>
                    <Form.Item
                        name="reference_payment"
                        className="custom-form-item"
                        label="Tham chiếu"
                    >
                      <Input style={{ width: '92%', marginLeft: 37 }} />
                    </Form.Item>

                    <div style={{display:'flex', marginBottom:5}}>
                      <div style={{width:'10em'}}>Số tiền hoàn trả:</div>
                      <Input
                        disabled={true}
                        style={{ marginLeft: 13,width: '97%',height:'33px' }}
                        required
                        value={formatCash(orderRefund.sumCostPaid)}
                      />
                    </div>
                    <div className={styles['block']}>
                      <Row style={{ width: '100%', borderBottom: '1px solid #d9d9d9' }}>
                        <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                            Phương thức thanh toán
                          </Col>
                          <Col>Giá trị</Col>
                          <Col></Col>
                        </Row>
                        <Timeline style={{ width: '100%', margin: 10 }}>
                          {listPayments.map((payment, index) => (
                            <Timeline.Item>
                              <Row style={{ width: '100%' }}>
                                <Col span={15}>{payment.name}</Col>
                                <Col span={6}>{formatCash(payment.value)}</Col>
                                <Col span={3}>
                                  <div
                                    style={{ border: 'none', cursor: 'pointer' }}
                                    onClick={() => _updatepayment(index)}
                                  >
                                    <CloseOutlined style={{ color: 'red' }} />
                                  </div>
                                </Col>
                              </Row>
                            </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '10px 0 10px 0',
                      }}
                    >
                      <div>
                        <PaymentMethods
                          editInvoice={_editInvoice}
                          setVisible={setVisiblePayments}
                          visible={visiblePayments}
                          sumCostPaidData={orderRefund.sumCostPaid}
                          listPaymentsR={listPayments}
                        />
                        {paymentAmount !== orderRefund.sumCostPaid ? (
                        <div style={{color:'red'}}>Vui lòng điền số tiền hoàn trả phù hợp</div>
                      ) : null}
                      </div>
                      
                    </div>

                    <div className={styles['block']}>
                      <Row style={{ width: '100%' }}>
                        <Col span={16} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Checkbox
                              onChange={(value) => {
                                setOrderRefund({
                                  ...orderRefund,
                                  is_auto_create_report: !orderRefund.is_auto_create_report,
                                })
                              }}
                              value={orderRefund.is_auto_create_report}
                              defaultChecked={true}
                            />
                            <div style={{ marginLeft: 5 }}>{'Tự động tạo phiếu chi'}</div>
                          </Col>
                          <Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ textAlign: 'center', alignSelf: 'center' }}>
                              {'Đã thanh toán'}
                            </div>
                            <Switch
                              onChange={(checked) => {
                                setOrderRefund({ ...orderRefund, is_payment: checked })
                                if (checked === true)
                                  notification.success({ message: 'Đã thanh toán' })
                                else notification.warning({ message: 'Chưa thanh toán' })
                              }}
                              checked={orderRefund.is_payment ? true : false}
                              style={{ alignSelf: 'center' }}
                            />
                          </Col>
                      </Row>
                    </div>
                </Col>
              </Row>

              <div className={styles['block']} style={{backgroundColor:'white'}}>
                <div
                  className={styles['title']}
                  style={{ display: 'flex', justifyContent: 'space-between'}}
                >
                  <div>{t('common.product')}</div>
                  <Checkbox checked={checked} onChange={_checkboxAll}>
                    Trả tất cả sản phẩm
                  </Checkbox>
                </div>
                <Row>
                  <Col sm={6}>
                    <Input
                      prefix={<SearchOutlined />}
                      placeholder="Tìm kiếm theo tên hoặc mã sản phẩm"
                      value={inputText}
                      onChange={onSearch}
                    ></Input>
                  </Col>
                </Row>

                <TableProduct
                  pagination={false}
                  columns={columns}
                  size="small"
                  dataSource={orderRefund.products}
                  rowKey="variant_id"
                  defaultExpandAllRows={true}
                  expandable={{
                    expandedRowKeys: orderRefund.products.map((o) => o.variant_id),
                    defaultExpandAllRows: true,
                    expandedRowRender: (record, index) =>
                      (
                        <RenderRowTypeAdvanced
                          style={{ height: 20 }}
                          product={record}
                          index={index}
                        />
                      ) || [],
                    rowExpandable: (record, index) =>
                      record.type_advanced === 2 || record.type_advanced === 3,
                  }}
                  locale={{
                    emptyText: (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 200,
                        }}
                      >
                        <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                        <h4 style={{ fontSize: 15, color: '#555' }}>
                          {t('sell.your_order_has_no_products')}
                        </h4>
                      </div>
                    ),
                  }}
                />
                <div style={{ textAlign: 'right' }}>
                  <h3>Tổng tiền sản phẩm hoàn trả: {formatCash(+orderRefund.sumCostPaid)} </h3>
                  <h3>Tổng số lượng hàng trả : {formatCash(+orderRefund.totalProduct)} </h3>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  )
}

export default RefundCreate
