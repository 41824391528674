import BranchForm from '../branch-form'
import { PERMISSIONS, IMAGE_DEFAULT } from 'consts'
import Permission from 'components/permission'
import { DeleteOutlined } from '@ant-design/icons'
import { compare } from 'utils'
import { useSelector } from 'react-redux'

import moment from 'moment'
import jwtDecode from 'jwt-decode'
import localStorage from '../../../utils/localStorage'
import { Button, Checkbox, Popconfirm, Popover, Space, Switch, notification } from 'antd'

function getPagination(paramsFilter, countBranch, onSetParamFilter) {
  return {
    position: ['bottomLeft'],
    current: paramsFilter.page,
    pageSize: paramsFilter.page_size,
    pageSizeOptions: [20, 30, 50, 100],
    showQuickJumper: true,
    total: countBranch,
    onChange: (page, pageSize) => onSetParamFilter({ ...paramsFilter, page, page_size: pageSize }),
  }
}

function getColumns(params) {
  const dataUser = jwtDecode(localStorage.getItem('accessToken'))

  const permissions = dataUser
    ? [...dataUser.data._role.menu_list, ...dataUser.data._role.permission_list]
    : []

  const {
    columns,
    paramsFilter,
    onGetBranches,
    _editBranch,
    _deleteBranch,
    updateDefaultBranch,
    defaultBranch,
  } = params
  const { page, page_size } = paramsFilter

  const changeHandler = (evt) => {
    const { value } = evt.target

    if (value === defaultBranch) return

    updateDefaultBranch(value)
  }

  const listObj = {
    stt: {
      width: 50,
      render: (_, __, index) => (page - 1) * page_size + index + 1,
    },
    code: {
      sorter: (a, b) => compare(a, b, 'code'),
      render: (_, record) =>
        permissions.includes(PERMISSIONS.xem_chi_tiet_chi_nhanh) ? (
          <BranchForm reloadData={onGetBranches} record={record}>
            <a>{record.code}</a>
          </BranchForm>
        ) : (
          <span style={{cursor:'pointer', color:'#5F73E2'}} onClick={() => notification.warning({message:'Permission denied!'})}>#{record.code}</span>
        ),
    },
    name: {
      sorter: (a, b) => compare(a, b, 'name'),
    },
    create_date: {
      render: (text) => (text ? moment(text).format('YYYY-MM-DD HH:mm') : ''),
      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
    },
    phone: {
      sorter: (a, b) => compare(a, b, 'phone'),
    },
    image: {
      render: (_, { logo }) => (
        <Popover
          content={<img src={logo || IMAGE_DEFAULT} alt="" style={{ width: 380, height: 380 }} />}
        >
          <img
            src={logo || IMAGE_DEFAULT}
            alt=""
            style={{ width: 55, height: 55, objectFit: 'cover' }}
          />
        </Popover>
      ),
    },

    address: {
      render: (_, record) => (
        <p>{record?.address + ',' + record?.ward + ',' + record?.district + ',' + record?.province}</p>
      ),
    },
    creator: {
      render: (_, record) => <>{record?._creator?.first_name + ' ' + record?._creator?.last_name}</>,
    },
    action: {
      render: (_, record) => (
        <Space size="middle">
          <Permission permissions={[PERMISSIONS.cap_nhat_chi_nhanh]}>
            <div>
              <div>Mở bán</div>
              <Switch
                checked={record.active}
                onChange={(checked) => _editBranch({ active: checked }, record.branch_id)}
              />
            </div>
          </Permission>
        </Space>
      ),
    },
    default_branch: {
      render: ({ branch_id }) => {
        return (
          <Checkbox
            checked={defaultBranch === branch_id}
            value={branch_id}
            onChange={changeHandler}
          ></Checkbox>
        )
      },
    },
  }

  return columns.map((column) => {
    const { key } = column

    const values = listObj[key]

    return values ? { ...column, ...values } : column
  })
}

export { getPagination, getColumns }
