import { Table } from "antd";
import styled from "styled-components";

export const TableCustom = styled(Table)`
  .ant-table-expanded-row > .ant-table-cell {
    display: none;
  }
  .ant-table-expanded-row > .ant-table-cell:has(.table-child) {
    display: table-cell;
    background: rgb(245, 245, 245) !important;
    :hover{
        background: rgb(245, 245, 245) !important;
    }
  }
`