import React, { useEffect, useState } from 'react'
import styles from 'components/Shipping/shipping.module.scss'
import { Form, Input, Radio, Checkbox, Row, Col } from 'antd'

//components
import { Button } from 'components/Button/Button'

export default function Ghtk({connectShipping}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const connect = async () => {
    try {
      const data = form.getFieldValue()
      const body = {
        username: data.email,
        password:data.password
      }
      await connectShipping(body)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Form
      form={form}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      // layout="horizontal"
      onFinish={connect}
    >
      <Form.Item label="Email" name="email" rules={[{ required: true , message: 'Vui lòng nhập email'}]}>
        <Input placeholder="Nhập Email" />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[{ required: true , message: 'Vui lòng nhập password'}]}>
        <Input.Password placeholder="Nhập mật khẩu" />
      </Form.Item>
      <Button
        style={{
          backgroundColor: '#6D24CF',
          border: 'none',
          color: 'white',
          borderRadius: '5px',
        }}
        htmlType="submit"
        text="Kết nối"
      />
      
    </Form>
  )
}
