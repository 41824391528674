import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION } from './consts'
import { clearBrowserCache } from 'utils'
import jwt_decode from 'jwt-decode'
//components
import Views from 'views'
import Loading from 'components/loading/Loading'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import localStorage from "./utils/localStorage";
//apis
import { getCurrentBusiness } from 'apis/business'
import { socket } from 'socket'
import { changeBranchRoleUser } from 'redux/actions/changeBranch'
import {io} from 'socket.io-client'
import { ACTION_TYPE } from 'redux/actions'
import SocketClient from './socketClient'
import { notification } from 'antd'
function App() {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdStorage = Number(localStorage.getItem('branch_id'))
  const checkBusiness = async () => {
    try {
      let res 
      if(branchIdStorage) {
        res = await getCurrentBusiness({branch_id: branchIdStorage})
      } else {
        res = await getCurrentBusiness()
      }
      if (res.status !== 200) {
        notification.warning({message: "Tài khoản của bạn đã tạm thời bị khoá quyền truy cập"})
      }else {
        dispatch({ type: ACTION.LOGIN, data: res.data.data })
        localStorage.setItem('accessToken', res.data.data.accessToken)
        const dataUser = jwt_decode(res.data.data.accessToken)
        if (dataUser.data.role === 1 || Array.isArray(dataUser.data.branch_id)) {
          dispatch({ type: 'SET_BRANCH_ID_Array', data: dataUser.data.branch_id })
          dispatch({ type: 'SET_BRANCH_ID', data: dataUser.data.branch_default_id })
          localStorage.setItem('branch_id', dataUser.data.branch_default_id)
        } else{
          dispatch({ type: 'SET_BRANCH_ID', data: dataUser.data.branch_default_id })
          localStorage.setItem('branch_id', dataUser.data.branch_default_id)
        }
        setIsLoading(false)
      }
    } catch (error) {
      //window.location.href = 'http://' + process.env.REACT_APP_ECOM_HOST
    }
  }
  const checkSubdomain = async () => {
    if (window.location.hostname == process.env.REACT_APP_HOST) {
      //window.location.href = 'http://' + process.env.REACT_APP_ECOM_HOST
    }
  }
  const socketListenerStaff = () => {
    socket.on(`staff:update:${dataUser?._business?.business_id}${dataUser?.user_id}`, data => {
    dispatch(changeBranchRoleUser({data: data.branch_id }))
  })}

  
  // const configCKeditor = () => {
  //   DecoupledEditor.create(document.querySelector('#editor'))
  //     .then((editor) => {
  //       console.log('Editor was initialized', editor)

  //       // Append the toolbar to the <body> element.
  //       document.body.appendChild(editor.ui.view.toolbar.element)
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  const GUEST_PATH = [
    '/connect-device/',
    // 
  ];
  
  useEffect(() => {
    const socket = io(process.env.REACT_APP_POS_SOCKET);
    dispatch({type: ACTION_TYPE.SOCKET, payload: socket})
    return () => socket.close()
  },[dispatch])

  useEffect(() => {
    const {pathname} = window.location;

    for (const path of GUEST_PATH) {
      if (pathname.includes(path)) {
        
        setIsLoading(false)
        return;
      }
    }

    checkBusiness()
    checkSubdomain()
    clearBrowserCache()
    socketListenerStaff()
    // configCKeditor()
  }, [])

  return isLoading ? (
    <></>
  ) : (
    <>
      <SocketClient />
      <Loading />
      <Views />
    </>
  )
}

export default App
