import React, { useEffect, useState } from 'react'
import { Button, Modal, Row, Card, Skeleton, Divider, notification } from 'antd'
import QRCode from 'qrcode.react'
import { getOrders } from 'apis/order'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ACTION } from 'consts'
//apis
import { syncPoint, getOrderCustomer } from 'apis/customer'
const ConnectHistorySell = ({ customer, isBranchClosed, setCheckSyncPoint,getCustomers }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [historyOrder, setHistoryOrder] = useState([true])
  const [countHistoryOrder, setCountHistoryOrder] = useState(0)
  const [loading, setLoading] = useState(false)
  const branch = useSelector((state) => state.branch)
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const _getOrders = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getOrderCustomer({customer_id :customer?.customer_id})
      if (res.status === 200) {
        let dataHistory = res.data.data.filter(e => e.increasePoint > 0 || e.decreasePoint > 0)
        setHistoryOrder(dataHistory)
        setCountHistoryOrder(dataHistory.length)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _syncPoint = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let body = {
        time_stamp: moment().format(),
        business_phone: dataUser._business.business_phone,
        customer_phone: customer?.phone,
        funtion_id: 11,
      }
      const res = await syncPoint(body)
      if (res.status === 200) {
        if (res.data.success === true) {
          notification.success({ message: res.data.message || 'Đồng bộ thành công' })
          handleCancel()
          setCheckSyncPoint(true)
          getCustomers()
        } else notification.warning({ message: res.data.message || 'Đồng bộ không thành công' })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  useEffect(()=>{
    _getOrders()
  },[customer])

  return (
    <>
      <Button
        disabled={isBranchClosed}
        type="primary"
        onClick={showModal}
        style={{ cursor: `${isBranchClosed ? 'not-allowed' : 'pointer'}` }}
      >
        Đồng bộ
      </Button>
      <Modal
        width={700}
        title="Thông tin đồng bộ"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Đóng
          </Button>,
        ]}
      >
        <Row justify="center" wrap={false} align="middle">
          <QRCode
            id="qrCodeSync"
            size={150}
            value={`${moment().format()},${dataUser._business.business_phone},${customer?.phone},11`}
          />
        </Row>

        <p style={{ textAlign: 'center', marginTop: '1rem' }}>
          <strong>Quét mã để đồng bộ tích điểm cũ</strong>
        </p>
        <Button onClick={_syncPoint}>Đồng bộ</Button>
        <div
          id="scrollableDiv"
          style={{
            height: 350,
            overflow: 'auto',
            backgroundColor: '#DCDCDC',
          }}
        >
          <InfiniteScroll
            dataLength={historyOrder.length}
            hasMore={historyOrder.length > 10}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }
            endMessage=""
            scrollableTarget="scrollableDiv"
          >
            {countHistoryOrder > 0 && historyOrder.map((item, index) => (
              <Card
                bodyStyle={{ padding: 10 }}
                size="small"
                key={index}
                style={
                  item.bill_status == 'COMPLETE'
                    ? { backgroundColor: '#F6FFED', width: '100%', marginTop: 7, marginBottom: 7 }
                    : { marginTop: 7, marginBottom: 7, width: '100%' }
                }
              >
                {branch.branches.map(
                  (branch) =>
                    branch.branch_id == item.branch_id && (
                      <p style={{ marginBottom: 5 }}>
                        <strong>
                          Mua hàng tại {branch.name} - {branch.address}{' '}
                        </strong>
                      </p>
                    )
                )}
                <div>
                  <p
                    style={
                      item.bill_status == 'COMPLETE'
                        ? { backgroundColor: '#B7EB8F', marginBottom: 0, textAlign: 'center' }
                        : { textAlign: 'center' }
                    }
                  >
                    {item.bill_status}
                  </p>
                  <p style={{ marginBottom: 0 }}>Mã đơn hàng: {item.code}</p>
                  <p style={{ marginBottom: 0 }}>
                    Đặt hàng: {moment(item.create_date).format('YYYY-MM-DD hh:mm')}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Tích điểm: {item.increasePoint - item.decreasePoint}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Thành tiền: {String(item.final_cost).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}đ
                  </p>
                </div>
              </Card>
            ))}
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  )
}
export default ConnectHistorySell
