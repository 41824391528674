import React, { useEffect, useState } from 'react'
import styles from './import-inventory.module.css'
import { ROUTES, PERMISSIONS, IMAGE_DEFAULT, FILTER_SIZE } from 'consts'

//antd
import { Col, Row, Steps } from 'antd'
import { useStepsForm } from 'sunflower-antd'

// components
import Step1 from './steps/step-1'
import Step2 from './steps/step-2'
import Step3 from './steps/step-3'
import { useSelector } from 'react-redux'
import { socket } from '../../socket'
import TitlePage from 'components/title-page'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

export default function ImportInventoryExcel() {
  const history = useHistory()

  const { Step } = Steps
  const [currentInventory, setCurrentInventory] = useState(0)
  const [totalInventory, setTotalInventory] = useState(0)
  const [dataError, setDataError] = useState({})
  const [filePath, setFilePath] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const user = useSelector((state) => state.login.dataUser)

  const { current, stepsProps, submit, gotoStep } = useStepsForm({
    isBackValidate: false,
    async submit(values) {
      switch (values.step) {
        case 0:
          gotoStep(0)
          setCurrentStep(0)
          return
        case 1:
          setFilePath(values.path)
          gotoStep(1)
          setCurrentStep(1)
          return
        case 2:
          gotoStep(2)
          setCurrentStep(2)
          return
      }
      return values
    },
  })

  const formList = [
    <Step1 gotoStep={gotoStep} current={current} submit={submit} />,
    <Step2
      filePath={filePath}
      gotoStep={gotoStep}
      current={current}
      submit={submit}
      setDataError={setDataError}
    />,
    <Step3 total={totalInventory} current={currentInventory} dataError={dataError} />,
  ]

  const socketListener = () => {
    socket.on(`import-inventory:inventory:${user.user_id}`, async (res) => {
      if (currentStep != 2) {
        setCurrentStep(2)
        await gotoStep(2)
      }
      setCurrentInventory(res.current)
      setTotalInventory(res.total)
    })
  }

  useEffect(() => {
    socketListener()
  }, [socket])

  return (
    <div className="card">
      <TitlePage
        title={
          <Row
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.IMPORT_INVENTORIES)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Nhập hàng bằng file excel
          </Row>
        }
      ></TitlePage>
      <Row>
        <div className={styles['container']}>
          <Col className={styles['import-steps']}>
            <Row justify="center">
              <Col span={19}>
                <Steps {...stepsProps}>
                  <Step disabled={true} title="Chọn file import" />
                  <Step disabled={true} title="Xác nhận dữ liệu" />
                  <Step disabled={true} title="Trạng thái" />
                </Steps>
              </Col>
            </Row>
            <Row>
              <div style={{ width: '100%' }}>{formList[current]}</div>
            </Row>
          </Col>
        </div>
      </Row>
    </div>
  )
}
