import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { ROUTES, ACTIONS_SCREEN } from 'consts'
import { compare } from 'utils'

//antd
import { Input, Row, Col, Select, Table, Button, Popconfirm, notification } from 'antd'

//icons
import { ArrowLeftOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons'

//components
import TitlePage from 'components/title-page'
import FilterDate from 'components/filter-date'

//apis
import { getActions} from 'apis/action'
import { useSelector } from 'react-redux'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function ActivityDiary() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [activityDiary, setActivityDiary] = useState([])
  const [layouts, setLayouts] = useState([])
  const typingTimeoutRef = useRef(null)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [valueTime, setValueTime] = useState()
  const [valueSearch, setValueSearch] = useState('')
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
  })
  const [countAction, setCountAction] = useState(0)

  const onFilters = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.name = value
      else delete paramsFilter.name
      setParamsFilter({ ...paramsFilter, page: 1, page_size: 20 })
    }, 650)
  }
  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Người thực hiện',
      sortDirections: ['descend'],
      render: (text, record) =>
        record._performer
          ? `${record._performer.first_name + ' ' + record._performer.last_name}`
          : '',
    },
    {
      title: 'Hành động',
      dataIndex: 'name',
      sorter: (a, b) => compare(a, b, 'name'),
    },
    {
      title: 'Thao tác',
      dataIndex: 'type',
      render: (text, record) => _getDepartment(record.type),
      sorter: (a, b) => compare(a, b, 'type'),
    },
    {
      title: 'Giao diện',
      dataIndex: 'properties',
      render: (text, record) => text && onConvertNametoMenu(text),
      sorter: (a, b) => compare(a, b, 'properties'),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'branch_name',
      render: (text, record) => record?.branch?.name ,
    },
    {
      title: 'Thời gian thao tác',
      dataIndex: 'date',
      render: (text, record) => (text ? moment(text).format('YYYY-MM-DD H:mm:ss') : ''),
      // sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
  ]

  const onConvertNametoMenu = (name) => {
    var index = layouts.findIndex((layout) => layout.name == name)

    if (index >= 0) {
      return layouts[index].label
    } else {
      return name
    }
  }

  const _getActionsHistory = async () => {
    try {
      setLoading(true)
      const res = await getActions(paramsFilter)
      if (res.status === 200) {
        setCountAction(res.data.count)
        setActivityDiary(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _getDepartment = (e) => {
    if (e == 1) return 'Tạo'
    if (e == 2) return 'Cập nhật'
    if (e == 3) return 'Xóa'
  }

  const onClickClear = async () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20, check:1 })
  }

  useEffect(() => {
    if(paramsFilter.check) _getActionsHistory()
  }, [paramsFilter])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setParamsFilter({...paramsFilter, from_date: result.from_date, to_date: result.to_date, check:1})
    setValueTime(result.valueDate)
  },[dataUser])
  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            align="middle"
            onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
            style={{ cursor: 'pointer' }}
          >
            <ArrowLeftOutlined />
            <div style={{ marginLeft: 8 }}>Nhật ký hoạt động</div>
          </Row>
        }
      >
        <Button
          style={{ display: Object.keys(paramsFilter).length <= 2 && 'none' }}
          onClick={onClickClear}
          type="primary"
          size="large"
        >
          Xóa tất cả lọc
        </Button>
      </TitlePage>

      <Row
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
        >
          <FilterDate
          size='default'
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            title="tạo hành động"
            valueTime={valueTime}
            setValueTime={setValueTime}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Select
            value={paramsFilter.type || ''}
            style={{ width: '100%' }}
            placeholder="Lọc thao tác"
            optionFilterProp="children"
            bordered={true}
            allowClear
            onChange={(value) => onFilters('type', value)}
          >
            <Select.Option value="">Tất cả thao tác</Select.Option>
            <Select.Option value="1">Tạo</Select.Option>
            <Select.Option value="2">Cập nhật</Select.Option>
            <Select.Option value="3">Xóa</Select.Option>
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Select
            value={paramsFilter.properties || ''}
            style={{
              width: '100%',
            }}
            placeholder="Lọc giao diện"
            optionFilterProp="children"
            bordered={true}
            allowClear
            showSearch
            optionLabelProp="children"
            onChange={(value) => onFilters('properties', value)}
          >
            <Select.Option value="">Tất cả màn hình</Select.Option>
            <Select.Option value="Bảo hành">Bảo hành</Select.Option>
            <Select.Option value="Chi nhánh">Chi nhánh</Select.Option>
            <Select.Option value="Chuyển hàng">Chuyển hàng</Select.Option>
            <Select.Option value="Chốt ca">Chốt ca</Select.Option>
            <Select.Option value="Đơn hàng">Đơn hàng</Select.Option>
            <Select.Option value="Đối soát vận chuyển">Đối soát vận chuyển</Select.Option>
            <Select.Option value="Đối tác vận chuyển">Đối tác vận chuyển</Select.Option>
            <Select.Option value="Điều chỉnh giá">Điều chỉnh giá</Select.Option>
            <Select.Option value="Hóa đơn">Hóa đơn</Select.Option>
            <Select.Option value="Sản phẩm">Sản phẩm</Select.Option>
            <Select.Option value="Khuyến mãi">Khuyến mãi</Select.Option>
            <Select.Option value="Kiểm hàng cơ bản">Kiểm hàng cơ bản</Select.Option>
            <Select.Option value="Kiểm hàng nâng cao">Kiểm hàng nâng cao</Select.Option>
            <Select.Option value="Khách hàng">Khách hàng</Select.Option>
            <Select.Option value="Lịch làm việc">Lịch làm việc</Select.Option>
            <Select.Option value="Nhập hàng">Nhập hàng</Select.Option>
            <Select.Option value="Nhóm sản phẩm">Nhóm sản phẩm</Select.Option>
            <Select.Option value="Nhân viên">Nhân viên</Select.Option>
            <Select.Option value="Nhà cung cấp">Nhà cung cấp</Select.Option>
            <Select.Option value="Phương thức thanh toán">Phương thức thanh toán</Select.Option>
            <Select.Option value="Phân quyền">Phân quyền</Select.Option>
            <Select.Option value="Phiếu thu chi"> Phiếu thu chi</Select.Option>
            <Select.Option value="Thuế">Thuế</Select.Option>
            <Select.Option value="Tích điểm">Tích điểm</Select.Option>
            <Select.Option value="Thông tin cửa hàng">Thông tin cửa hàng</Select.Option>
            <Select.Option value="Trả hàng">Trả hàng</Select.Option>

            {/* {layouts.map((layout, index) => (
              <Select.Option value={layout.name} key={index}>
                {layout.label}
              </Select.Option>
            ))}  */}
          </Select>
        </Col>
      </Row>

      <Table
        loading={loading}
        size="small"
        columns={columns}
        dataSource={activityDiary}
        style={{ width: '100%' }}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
          total: countAction,
        }}
      />
    </div>
  )
}
