import { useEffect, useState, useRef, useCallback } from 'react'
import styles from './customer-update.module.scss'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import TitlePage from 'components/title-page'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  ACTION,
  PAGE_SIZE,
  FILTER_SIZE,
  PAGE_SIZE_OPTIONS,
  IMAGE_DEFAULT,
  PERMISSIONS,
} from 'consts'

import { compare, formatCash, compareCustom, validatePhone } from 'utils'

import { ROUTES } from 'consts'
// antd
import {
  Select,
  Button,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Space,
  Modal,
  Tabs,
  Tag,
  Table,
  Spin
} from 'antd'
import { ArrowLeftOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons'
//apis
import { getCustomers, getCustomerTypes } from 'apis/customer'

import {  getOrderCustomer } from 'apis/customer'
import ColumnsOrder from './columnsOrder'

import ConnectStatus from '../order-update/connect-status'
const { RangePicker } = DatePicker

const { TabPane } = Tabs
const { Option } = Select
export default function CustomerDetail(data, close) {
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()

  const [districts, setDistricts] = useState([])
  const [provinces, setProvinces] = useState([])
  const [ward, setWard] = useState([])
  const [customerTypes, setCustomerTypes] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const [optionSearchName, setOptionSearchName] = useState('code')
  const typingTimeoutRef = useRef(null)

  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [columns, setColumns] = useState([])
  const [countCustomer, setCountCustomer] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: PAGE_SIZE })

  const [tableLoading, setTableLoading] = useState(false)
  const code = useParams()

  const [customers, setCustomers] = useState([])
  const [order, setOrder] = useState([])
  const [orderDebt, setOrderDebt] = useState([])
  const [countOrder, setCountOrder] = useState(0)
  const [countOrderDebt, setCountOrderDebt] = useState(0)



  const initForm = useCallback(async () => {
    form.setFieldsValue({
      // type: 'Vãng Lai',
      gender: 0,
      birthday: moment(new Date('1991-01-01')),
      district: districts.length > 0 && districts[0].district_name,
      province: provinces.length > 0 && provinces[0].province_name,
      ward: ward.length > 0 && ward[0].ward_name,
    })
  }, [districts, form, provinces, ward])

  const _getCustomerTypes = async () => {
    try {
      const res = await getCustomerTypes()
      if (res.status === 200) setCustomerTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getCustomers = async () => {
    try {
      setTableLoading(true)
      const res = await getCustomers({ code: code?.code })
      if (res.status === 200) {
        setCustomers(res.data.data[0])
        setCountCustomer(res.data.count)
        _getOrderCustomers(res.data.data[0].customer_id)
      }
      setTableLoading(false)
    } catch (e) {
      console.log(e)
      setTableLoading(false)
    }
  }
  const checkLinkImageEnbale = async (link) => {
    try {
      await fetch(link)
      return link
    } catch (err) {
      return IMAGE_DEFAULT
    }
  }
  const _getOrderCustomers = async (id) => {
    try {
      setTableLoading(true)
      const res = await getOrderCustomer({ customer_id: id })
      if (res.status === 200) {
        setOrder(res.data.data)
        setOrderDebt(res.data.data.filter(e => e.customer_debt > 0))
        setCountOrder(res.data.count)
        setCountOrderDebt(res.data.data.filter(e => e.customer_debt > 0).length)

        for (var i = 0; i < res.data.data.length; i++) {
          for (var j = 0; j < res.data.data[i].order_details.length; j++) {
            res.data.data[i].order_details[j].variant_info.image[0] = await checkLinkImageEnbale(
              res.data.data[i].order_details[j].variant_info.image[0]
            )
          }
        }
      }
      
      setTableLoading(false)
    } catch (e) {
      console.log(e)
      setTableLoading(false)
    }
  }

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setValueSearch('')
  }
  const _onSearch = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter[optionSearchName] = value
      else delete paramsFilter[optionSearchName]

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }
  const _onChangeDate = (date, dateString) => {
    if (date) {
      paramsFilter.from_date = dateString[0]
      paramsFilter.to_date = dateString[1]
    } else {
      delete paramsFilter.from_date
      delete paramsFilter.to_date
    }
    setParamsFilter({ ...paramsFilter, page: 1 })
  }
  useEffect(() => {
    _getCustomerTypes()
    _getCustomers()
  }, [code])

  return (
    <div className="card">
      <TitlePage
        isAffix={true}
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.CUSTOMER)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            <div>{'Chi tiết khách hàng'}</div>
          </Row>
        }
      >
        <Space>
          <Button icon={<ReloadOutlined />} size="large" onClick={() => history.go(0)}>
            Tải lại
          </Button>
        </Space>
      </TitlePage>
      <Spin spinning={tableLoading} tip='Loading...'>
      <div>
        <div className={styles['container-top']}>
          <div className={styles['title-container']}>Thông tin cá nhân</div>
          <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
          <div className={styles['container-info']}>
            <div>
              <p className={styles['title-info']}>
                Họ và tên: {customers?.first_name + ' ' + customers?.last_name}
              </p>
              <p className={styles['title-info']}>Số điện thoại: {customers?.phone} </p>
              <p className={styles['title-info']}>Mã khách hàng: {customers?.code}</p>
            </div>
            <div>
              <p className={styles['title-info']}>
                Giới tính:{' '}
                {!customers?.gender
                  ? ''
                  : (customers?.gender === 0 && 'Nam') ||
                    (customers?.gender === 1 && 'Nữ') ||
                    (customers?.gender === 2 && 'Khác')}
              </p>
              <p className={styles['title-info']}>
                Địa chỉ: {customers?.address !== '' ? customers?.address : ''}
                {customers?.ward !== '' ? ', ' + customers?.ward || '' : ''}
                {customers?.district !== '' ? ', ' + customers?.district || '' : ''}
                {customers?.province !== '' && (', ' + customers?.province || '')}
              </p>
              <p className={styles['title-info']}>
                Loại khách hàng: {customers?._type ? customers?._type.name : ''}
              </p>
            </div>
            <div>
              <p className={styles['title-info']}>Email: {customers?.email || ''}</p>
              <p className={styles['title-info']}>
                Sinh nhật: {customers?.birthday && moment(customers?.birthday).format('DD-MM-YYYY')}
              </p>
              <p className={styles['title-info']}>
                Điểm tích luỹ: {customers?.point && formatCash(customers?.point)}
              </p>
            </div>
          </div>
        </div>
        <div className={styles['container-bot']}>
          <div className={styles['title-container']}>Thông tin mua hàng</div>
          <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
          <Tabs type="card">
            <TabPane tab="Lịch sử mua sắm" key="1">
              <h1> Tổng số đơn đã mua: {order.filter(e => e.order_status != 'refund-order').length} đơn hàng</h1>
              <h1> Tổng số đơn đã trả: {order.filter(e => e.order_status == 'refund-order').length} đơn hàng</h1>

              <h1>
                Tổng chi tiêu tại cửa hàng: {formatCash(parseFloat(customers?.order_total_cost))} vnđ
              </h1>
              <div style={{ marginTop: 15 }}>
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  style={{ marginRight: 0, marginLeft: 0 }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    style={{
                      border: '1px solid #d9d9d9',
                      borderRadius: 5,
                    }}
                  >
                    <Input
                      style={{ width: '60%' }}
                      prefix={<SearchOutlined />}
                      name="name"
                      value={valueSearch}
                      onChange={_onSearch}
                      placeholder={t('common.search_by')}
                      bordered={false}
                      allowClear
                    />
                    <Select
                      showSearch
                      size={FILTER_SIZE}
                      style={{ width: '40%', borderLeft: '1px solid #d9d9d9' }}
                      value={optionSearchName}
                      onChange={(value) => {
                        delete paramsFilter[optionSearchName]
                        setOptionSearchName(value)
                      }}
                      bordered={false}
                    >
                      <Select.Option value="code">{t('order_list.code_orders')}</Select.Option>
                    </Select>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={6}
                      style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '5px',
                      }}
                    >
                      <RangePicker
                        size={FILTER_SIZE}
                        onChange={_onChangeDate}
                        style={{ width: '100%' }}
                        className="br-15__date-picker"
                        ranges={{
                          Today: [moment(), moment()],
                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        bordered={false}
                      />
                    </Col>
                  </Col>
                  <Button
                    style={{
                      display: Object.keys(paramsFilter).length <= 2 && 'none',
                    }}
                    onClick={onClickClear}
                    type="primary"
                  >
                    {t('common.clear_all_filters')}
                  </Button>
                </Row>
              </div>
              <Table
                size="large"
                rowKey="order_id"
                scroll={{ y: 600 }}
                loading={tableLoading}
                columns={ColumnsOrder.map((column) => {
                  if (column.key === 'stt')
                    return {
                      ...column,
                      width: 50,
                      render: (text, record, index) =>
                        (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                    }
                  if (column.key === 'code')
                    return {
                      ...column,
                      sorter: (a, b) => compare(a, b, 'code'),
                      render: (text, record) => (
                        <ConnectStatus
                          permissions={[PERMISSIONS.chi_tiet_don_hang]}
                          record={record}
                          location={1}
                        />
                      ),
                    }
                  if (column.key === 'create_date')
                    return {
                      ...column,
                      render: (text, record) => text && moment(text).format('DD/MM/YYYY HH:mm'),
                      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                    }
                  if (column.key === 'channel')
                    return {
                      ...column,
                      render: (text) => <span>{text}</span>,
                    }
                  if (column.key === 'customer')
                    return {
                      ...column,
                      sorter: (a, b) =>
                        compareCustom(
                          a.customer ? `${a.customer.first_name} ${a.customer.last_name}` : '',
                          b.customer ? `${b.customer.first_name} ${b.customer.last_name}` : ''
                        ),
                      render: (text, record) =>
                        record.customer
                          ? `${record.customer.first_name} ${record.customer.last_name}`
                          : '',
                    }
                  if (column.key === 'order_status')
                    return {
                      ...column,
                      render: (text, record) => {
                        // eslint-disable-next-line default-case
                        switch (record.order_status) {
                          case 'waiting-confirm':
                            return <Tag color="gold">Chờ xác nhận</Tag>
                          case 'waiting-pickup':
                            return <Tag color="orange">Chờ lấy hàng</Tag>
                          case 'delivering':
                            return <Tag color="blue">Đang giao</Tag>
                          case 'complete':
                            return <Tag color="green">Hoàn thành</Tag>
                          case 'cancel-order':
                            return <Tag color="red">Đơn huỷ</Tag>
                          case 'refund-order':
                            return <Tag color="magenta">Đơn trả hàng</Tag>
                        }
                      },
                      sorter: (a, b) => compare(a, b, 'order_status'),
                    }
                  if (column.key === 'customer_debt')
                    return {
                      ...column,
                      sorter: (a, b) => compare(a, b, 'customer_debt'),
                      render: (text) =>text > 0 ?  '-' + formatCash(parseFloat(text)) + ' vnđ' : '',
                    }
                    if (column.key === 'point')
                    return {
                      ...column,
                      render: (text, record) => record.order_status == 'complete' ? record.increasePoint - record.decreasePoint : record.order_status == 'refund-order' ? - record.increasePoint - record.decreasePoint || 0 : ''
                    }
                  if (column.key === 'customer_paid')
                    return {
                      ...column,
                      sorter: (a, b) => compare(a, b, 'customer_paid'),
                      render: (text) => formatCash(parseFloat(text)) + ' vnđ',
                    }

                  return column
                })}
                style={{ width: '100%', marginTop: 25 }}
                pagination={{
                  current: paramsFilter.page,
                  pageSize: paramsFilter.page_size,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showQuickJumper: true,
                  onChange: (page, pageSize) =>
                    setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
                  total: countOrder,
                }}
                dataSource={order}
                columnsDefault={ColumnsOrder}
                nameColumn="ColumnsOrder"
                setColumns={setColumns}
              />
            </TabPane>
            <TabPane tab="Công nợ" key="2">
              <h1> Tổng công nợ: {formatCash(parseFloat(customers?.debt))} vnđ</h1>
              <div style={{ marginTop: 15 }}>
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  style={{ marginRight: 0, marginLeft: 0 }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    style={{
                      border: '1px solid #d9d9d9',
                      borderRadius: 5,
                    }}
                  >
                    <Input
                      style={{ width: '60%' }}
                      prefix={<SearchOutlined />}
                      name="name"
                      value={valueSearch}
                      onChange={_onSearch}
                      placeholder={t('common.search_by')}
                      bordered={false}
                      allowClear
                    />
                    <Select
                      showSearch
                      size={FILTER_SIZE}
                      style={{ width: '40%', borderLeft: '1px solid #d9d9d9' }}
                      value={optionSearchName}
                      onChange={(value) => {
                        delete paramsFilter[optionSearchName]
                        setOptionSearchName(value)
                      }}
                      bordered={false}
                    >
                      <Select.Option value="code">{t('order_list.code_orders')}</Select.Option>
                    </Select>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={6}
                      style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '5px',
                      }}
                    >
                      <RangePicker
                        size={FILTER_SIZE}
                        onChange={_onChangeDate}
                        style={{ width: '100%' }}
                        className="br-15__date-picker"
                        ranges={{
                          Today: [moment(), moment()],
                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        bordered={false}
                      />
                    </Col>
                  </Col>
                  <Button
                    style={{
                      display: Object.keys(paramsFilter).length <= 2 && 'none',
                    }}
                    onClick={onClickClear}
                    type="primary"
                  >
                    {t('common.clear_all_filters')}
                  </Button>
                </Row>
              </div>
              <Table
                size="large"
                rowKey="order_id"
                scroll={{ y: 600 }}
                loading={tableLoading}
                columns={ColumnsOrder.map((column) => {
                  if (column.key === 'stt')
                    return {
                      ...column,
                      width: 50,
                      render: (text, record, index) =>
                        (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                    }
                  if (column.key === 'code')
                    return {
                      ...column,
                      sorter: (a, b) => compare(a, b, 'code'),
                      render: (text, record) => (
                        <ConnectStatus
                          permissions={[PERMISSIONS.chi_tiet_don_hang]}
                          record={record}
                          location={1}
                        />
                      ),
                    }
                  if (column.key === 'create_date')
                    return {
                      ...column,
                      render: (text, record) => text && moment(text).format('DD/MM/YYYY HH:mm'),
                      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                    }
                  if (column.key === 'channel')
                    return {
                      ...column,
                      render: (text) => <span>{text}</span>,
                    }
                  if (column.key === 'customer')
                    return {
                      ...column,
                      sorter: (a, b) =>
                        compareCustom(
                          a.customer ? `${a.customer.first_name} ${a.customer.last_name}` : '',
                          b.customer ? `${b.customer.first_name} ${b.customer.last_name}` : ''
                        ),
                      render: (text, record) =>
                        record.customer
                          ? `${record.customer.first_name} ${record.customer.last_name}`
                          : '',
                    }
                  if (column.key === 'order_status')
                    return {
                      ...column,
                      render: (text, record) => {
                        // eslint-disable-next-line default-case
                        switch (record.order_status) {
                          case 'waiting-confirm':
                            return <Tag color="gold">Chờ xác nhận</Tag>
                          case 'waiting-pickup':
                            return <Tag color="orange">Chờ lấy hàng</Tag>
                          case 'delivering':
                            return <Tag color="blue">Đang giao</Tag>
                          case 'complete':
                            return <Tag color="green">Hoàn thành</Tag>
                          case 'cancel-order':
                            return <Tag color="red">Đơn huỷ</Tag>
                          case 'refund-order':
                            return <Tag color="magenta">Đơn trả hàng</Tag>
                        }
                      },
                      sorter: (a, b) => compare(a, b, 'order_status'),
                    }
                    if (column.key === 'point')
                    return {
                      ...column,
                      render: (text, record) =>record.order_status == 'complete' ? record.increasePoint - record.decreasePoint : record.order_status == 'refund-order' ? -record.increasePoint - record.decreasePoint : ''
                    }
                  if (column.key === 'customer_debt')
                    return {
                      ...column,
                      sorter: (a, b) => compare(a, b, 'customer_debt'), 
                      render: (text) => text > 0 ?  '-' + formatCash(parseFloat(text)) + ' vnđ' : '',
                    }
                    

                  if (column.key === 'customer_paid')
                    return {
                      ...column,
                      sorter: (a, b) => compare(a, b, 'customer_paid'),
                      render: (text) => formatCash(parseFloat(text)) + ' vnđ',
                    }

                  return column
                })}
                style={{ width: '100%', marginTop: 25 }}
                pagination={{
                  current: paramsFilter.page,
                  pageSize: paramsFilter.page_size,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showQuickJumper: true,
                  onChange: (page, pageSize) =>
                    setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
                  total: countOrderDebt,
                }}
                dataSource={orderDebt}
              />
            </TabPane>
            <TabPane tab="Ghi chú" key="3">
              Ghi chú
            </TabPane>
          </Tabs>
        </div>
      </div>
      </Spin>
      
    </div>
  )
}
