import React, { useEffect, useState } from 'react'
// import styles from './import-customer.module.css'

//antd
import { Col, Row, Steps } from 'antd'
import { useStepsForm } from 'sunflower-antd'

// components
import Step1 from './steps/step-1'
import Step2 from './steps/step-2'
import { useSelector } from 'react-redux'

export default function FormImportFile({
  open,
  setOpen,
  setProductChange,
  productChange,
}) {
  const { Step } = Steps
  const [dataError, setDataError] = useState({})
  const [filePath, setFilePath] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const user = useSelector((state) => state.login.dataUser)

  const { current, stepsProps, submit, gotoStep } = useStepsForm({
    isBackValidate: false,
    async submit(values) {
      // eslint-disable-next-line default-case
      switch (values.step) {
        case 0:
          gotoStep(0)
          setCurrentStep(0)
          return
        case 1:
          setFilePath(values.path)
          gotoStep(1)
          setCurrentStep(1)
          return
      }
      return values
    },
  })

  const formList = [
    <Step1 gotoStep={gotoStep} current={current} submit={submit} setOpen={setOpen} />,
    <Step2
      filePath={filePath}
      gotoStep={gotoStep}
      current={current}
      submit={submit}
      setDataError={setDataError}
      setProductChange={setProductChange}
      setOpen={setOpen}
      productChange={productChange}
    />,
  ]

  return (
    <Row justify="center">
      <div>
        <Col>
          <Row>
            <Col span="24">
              <Steps {...stepsProps}>
                <Step disabled={true} title="Chọn file import" />
                <Step disabled={true} title="Xác nhận dữ liệu" />
                <Step disabled={true} title="Trạng thái" />
              </Steps>
            </Col>
          </Row>
          <Row>
            <div style={{ width: '100%' }}>{formList[current]}</div>
          </Row>
        </Col>
      </div>
    </Row>
  )
}
