export const ACTION = {
  CHECK_SUB_DOMAIN: 'CHECK_SUB_DOMAIN',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_STORE: 'GET_STORE',
  SELECT_VALUE: 'SELECT_VALUE',
  ERROR: 'ERROR',
  CHANGE_SIDER: 'CHANGE_SIDER',
}

export const ROLE_DEFAULT = {
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
  BUSINESS: 'BUSINESS',
}

export const ACTIONS_SCREEN = {
  1: 'Tạo',
  2: 'Cập nhật',
  3: 'Xóa',
}

//status product
export const STATUS_PRODUCT = {
  all: 'all',
  shipping_stock: 'shipping_stock',
  available_stock: 'available_stock',
  low_stock: 'low_stock',
  out_stock: 'out_stock',
}

export const SHIP_STATUS_ORDER = {
  DRAFT: 'DRAFT',
  WAITING_FOR_SHIPPING: 'WAITING_FOR_SHIPPING',
  SHIPPING: 'SHIPPING',
  COMPLETE: 'COMPLETE',
  CANCEL: 'CANCEL',
}

export const ROUTES = {
  STAFF: '/manage-staff',
  CHECK_SUBDOMAIN: '/business-login',
  BUSINESSES: '/businesses',
  LOGIN: '/login',
  REGISTER: '/register',
  IMPORT_REPORT_FILE: '/import-report-file',
  IMPORT_CUSTOMER: '/import-customer',
  IMPORT_PRODUCT: '/import-products',
  PRODUCT_CHECK: '/product-check',
  ORDER_LIST: '/order-list',
  REPORTS: '/reports',
  SALES_REPORT: '/sales-report',
  STOCK_ADJUSTMENTS: '/stock-adjustments',
  STOCK_ADJUSTMENTS_CREATE: '/stock-adjustments/create',
  STOCK_ADJUSTMENTS_UPDATE: '/stock-adjustments/detail',
  STOCK_ADJUSTMENTS_ADVANCED: '/stock-adjustments-advanced',
  STOCK_ADJUSTMENTS_ADVANCED_UPDATE: `/stock-adjustments-advanced`,
  REPORT_VARIANT: '/report-variant',
  REPORT_INVENTORY: '/report-inventory',
  REPORT_INVENTORY_DATE: '/report-inventory-date',
  REPORT_IMPORT_EXPORT_INVENTORY: '/report-import-export-inventory',
  REPORT_IMPORT_EXPORT_INVENTORY_VARIANT: '/report-import-export-inventory-variant',
  SHIPPING_CONTROL: '/shipping-control',
  SHIPPING_CONTROL_ADD: '/shipping-control/add',
  SHIPPING_CONTROL_UPDATE: '/shipping-control/update',
  DELIVERY_CONTROL: '/delivery-control',
  GUARANTEE: '/guarantee',
  GUARANTEE_ADD: '/guarantee-create',
  SHIPPING_PRODUCT: '/shipping-product',
  CLIENT_MANAGEMENT: '/client-management',
  BRANCH_MANAGEMENT: '/branch',
  CONFIGURATION_STORE: '/configuration-store',
  OTP: '/otp',
  VERIFY_ACCOUNT: '/verify-account',
  PASSWORD_NEW: '/password-new',
  FORGET_PASSWORD: '/forget-password',
  OVERVIEW: '/overview',
  SELL: '/sell',
  CONNECT_DEVICE: '/connect-device',
  REFUND: '/refund',
  STORE: '/store',
  RECEIPTS_PAYMENT: '/receipts-payment',
  ACTIVITY_DIARY: '/activity-diary',
  SHIPPING_PRODUCT_ADD: '/shipping-product-add',
  SHIPPING_PRODUCT_UPDATE: '/shipping-product-update',
  ORDER_CREATE: '/order-create',
  ORDER_UPDATE: '/order-update',
  ROLE_PERMISSION: '/role-permission',

  INVENTORY: '/inventory',
  PRODUCT: '/product',
  PAYMENT: '/payment',
  TAX: '/tax',
  EMPLOYEE: '/employee',
  SHIPPING: '/shipping',
  SHIPPING_CREATE: '/shipping/create',
  CUSTOMER: '/customer',
  CUSTOMER_TYPE: '/customer-type',
  CUSTOMER_DETAIL: '/customer-detail',
  SUPPLIER: '/supplier',
  PROMOTION: '/promotion',
  ROLE: '/role',
  PRODUCT_ADD: '/product-create',
  PRODUCT_UPDATE: '/product-detail',
  PRODUCT_BARCODE: '/product-barcode',
  POINT: '/point',
  CREATE_CATEGORY: '/create-category',
  DETAIL_CATEGORY: '/detail-category',
  CATEGORIES: '/categories',
  SETTING: '/setting',
  SETTING_BILL: '/setting-bill',
  SETTING_PAYMENT: '/setting-payment',
  OFFER_LIST: '/offer-list',
  OFFER_LIST_CREATE: '/offer-list/create',
  BLOG: '/blog',
  BLOG_CREATE: '/blog/create',
  BRAND: '/brand',
  BRAND_CREATE: '/brand/create',
  CHANNEL: '/channel',
  // CHANNEL_CREATE:'/channel/create',
  CONTACT: '/contact',
  IMPORT_INVENTORIES: '/import-inventories',
  IMPORT_SMART_PRODUCT: '/import-smart-product',
  IMPORT_INVENTORY_EXCEL: '/import-inventories-excel',
  IMPORT_INVENTORY_CREATE: '/import-inventory-create',
  IMPORT_INVENTORY_UPDATE: '/import-inventory-update',
  PRICE_ADJUSTMENTS: '/price-adjustments',
  PRICE_ADJUSTMENTS_CREATE: '/price-adjustments/create',
  PRICE_ADJUSTMENTS_UPDATE: '/price-adjustments/update',
  CONFIG_INVOICE_SALE: '/config-invoice-sale',
  CONFIG_INVOICE_DELIVERY: '/config-invoice-delivery',
  PRICE_CHANGE_HISTORY: '/price-change-history',
  SALE_HISTORY: '/sale-history',
  RETURN_HISTORY: '/return-history',
  REFUND_CREATE: '/refund-create',
  REFUND_DETAIL: '/refund-detail',
  LIST_ORDER_FOLLOW_PAYMENT: '/list-order-follow-payment',
  LOCK_SHIFT: '/lock-shift',
  PROMOTION_FORM: '/promotion-form',
  SCHEDULE_STAFF: '/schedule-staff',
  SCHEDULE_DETAIL: `/schedule-staff-admin/:id`,
  SCHEDULE_REGISTER: `/schedule-staff/:id`,
  PROMOTION_UPDATE: '/promotion-detail',
  FINANCE_TYPE: '/finance-type',

  DETAIL_BUSINESS: `/business`,
  ROLL_CALL:`/roll-call`,
}

export const PERMISSIONS = {
  //Permission menu
  //tong quan*
  tong_quan: 'tong_quan',
  //ban hang*
  ban_hang: 'ban_hang',
  //don hang*
  danh_sach_don_hang: 'danh_sach_don_hang',
  //danh sach don hang
  xem_danh_sach_don_hang:'xem_danh_sach_don_hang',
  don_hang: 'don_hang',
  tao_don_hang: 'tao_don_hang',
  chi_tiet_don_hang: 'chi_tiet_don_hang',
  cap_nhat_don_hang: 'cap_nhat_don_hang',
  xoa_don_hang: 'xoa_don_hang',
  import_don_hang: 'import_don_hang',
  export_don_hang: 'export_don_hang',

  //tra hang
  tra_hang: 'tra_hang',
  xem_danh_sach_tra_hang: 'xem_danh_sach_tra_hang',
  xem_chi_tiet_tra_hang: 'xem_chi_tiet_tra_hang',
  tao_phieu_tra_hang: 'tao_phieu_tra_hang',
  cap_nhat_phieu_tra_hang: 'cap_nhat_phieu_tra_hang',
  xoa_phieu_tra_hang: 'xoa_phieu_tra_hang',
  import_phieu_tra_hang: 'import_phieu_tra_hang',
  export_phieu_tra_hang: 'export_phieu_tra_hang',

  //chot ca
  chot_ca: 'chot_ca',
  xem_danh_sach_chot_ca: 'xem_danh_sach_chot_ca',
  tao_phieu_chot_ca: 'tao_phieu_chot_ca',

  //van chuyen *
  van_chuyen: 'van_chuyen',

  //giao hang
  xem_danh_sach_giao_hang:'xem_danh_sach_giao_hang',
  quan_li_giao_hang: 'quan_li_giao_hang',
  xem_chi_tiet_phieu_giao_hang: 'xem_chi_tiet_phieu_giao_hang',
  them_phieu_giao_hang: 'them_phieu_giao_hang',
  cap_nhat_giao_hang: 'cap_nhat_giao_hang',
  //doi soat van chuyen
  xem_danh_sach_doi_soat_van_chuyen:'xem_danh_sach_doi_soat_van_chuyen',
  doi_soat_van_chuyen: 'doi_soat_van_chuyen',
  xem_chi_tiet_phieu_doi_soat_van_chuyen: 'xem_chi_tiet_phieu_doi_soat_van_chuyen',
  them_phieu_doi_soat_van_chuyen: 'them_phieu_doi_soat_van_chuyen',
  cap_nhat_doi_soat_van_chuyen: 'cap_nhat_doi_soat_van_chuyen',
  xoa_phieu_doi_soat_van_chuyen: 'xoa_phieu_doi_soat_van_chuyen',
  export_phieu_doi_soat_van_chuyen: 'export_phieu_doi_soat_van_chuyen',

  //doi tac van chuyen
  xem_danh_sach_doi_tac_van_chuyen:'xem_danh_sach_doi_tac_van_chuyen',
  doi_tac_van_chuyen: 'doi_tac_van_chuyen',
  them_doi_tac_van_chuyen: 'them_doi_tac_van_chuyen',
  cap_nhat_doi_tac_van_chuyen: 'cap_nhat_doi_tac_van_chuyen',
  xoa_doi_tac_van_chuyen: 'xoa_doi_tac_van_chuyen',

  //san pham*
  san_pham: 'san_pham',
  //danh sach san pham
  xem_danh_sach_san_pham: 'xem_danh_sach_san_pham',
  quan_li_san_pham: 'quan_li_san_pham',
  //them san pham
  them_san_pham: 'them_san_pham',
  chi_tiet_san_pham: 'chi_tiet_san_pham',
  export_san_pham: 'export_san_pham',
  cap_nhat_san_pham: 'cap_nhat_san_pham',
  xoa_san_pham: 'xoa_san_pham',
  in_ma_san_pham: 'in_ma_san_pham',
  mo_ban_san_pham: 'mo_ban_san_pham',
  //nhom san pham
  xem_danh_sach_nhom_san_pham: 'xem_danh_sach_nhom_san_pham',
  nhom_san_pham: 'nhom_san_pham',
  tao_nhom_san_pham: 'tao_nhom_san_pham',
  xem_chi_tiet_nhom_san_pham: 'xem_chi_tiet_nhom_san_pham',
  xoa_nhom_san_pham: 'xoa_nhom_san_pham',
  cap_nhat_nhom_san_pham: 'cap_nhat_nhom_san_pham',
  //lich su ban hang
  lich_su_ban_hang: 'lich_su_ban_hang',
  //lich su tra hang
  lich_su_tra_hang: 'lich_su_tra_hang',
  //lich su dieu chinh gia
  lich_su_thay_doi_gia: 'lich_su_thay_doi_gia',

  //quan ly kho*
  quan_li_kho: 'quan_li_kho',

  //dieu chinh gia
  dieu_chinh_gia: 'dieu_chinh_gia',
  xem_danh_sach_dieu_chinh_gia :'xem_danh_sach_dieu_chinh_gia',
  xem_chi_tiet_phieu_dieu_chinh_gia: 'xem_chi_tiet_phieu_dieu_chinh_gia',
  cap_nhat_phieu_dieu_chinh: 'cap_nhat_phieu_dieu_chinh',
  tao_phieu_dieu_chinh: 'tao_phieu_dieu_chinh',
  export_phieu_dieu_chinh: 'export_phieu_dieu_chinh',
  //nhap hang
  nhap_hang: 'nhap_hang',
  xem_danh_sach_nhap_hang:'xem_danh_sach_nhap_hang',
  xem_chi_tiet_phieu_nhap_hang: 'xem_chi_tiet_phieu_nhap_hang',
  them_phieu_nhap_hang: 'them_phieu_nhap_hang',
  cap_nhat_phieu_nhap_hang: 'cap_nhat_phieu_nhap_hang',
  xoa_phieu_nhap_hang: 'xoa_phieu_nhap_hang',
  export_nhap_hang: 'export_nhap_hang',
  //kiem hang co ban
  kiem_hang_co_ban: 'kiem_hang_co_ban',
  xem_danh_sach_kiem_hang_co_ban: 'xem_danh_sach_kiem_hang_co_ban',
  xem_chi_tiet_phieu_kiem_hang_co_ban: 'xem_chi_tiet_phieu_kiem_hang_co_ban',
  them_phieu_kiem_hang_co_ban: 'them_phieu_kiem_hang_co_ban',
  cap_nhat_phieu_kiem_hang_co_ban: 'cap_nhat_phieu_kiem_hang_co_ban',
  can_bang_phieu_kiem_hang_co_ban: 'can_bang_phieu_kiem_hang_co_ban',
  export_phieu_kiem_hang_co_ban: 'export_phieu_kiem_hang_co_ban',
  xoa_phieu_kiem_hang_co_ban: 'xoa_phieu_kiem_hang_co_ban',
  //kiem hang nang cao
  kiem_hang_nang_cao: 'kiem_hang_nang_cao',
  xem_danh_sach_kiem_hang_nang_cao :'xem_danh_sach_kiem_hang_nang_cao',
  xem_chi_tiet_phieu_kiem_hang_nang_cao: 'xem_chi_tiet_phieu_kiem_hang_nang_cao',
  them_phieu_kiem_hang_nang_cao: 'them_phieu_kiem_hang_nang_cao',
  export_phieu_kiem_hang_nang_cao: 'export_phieu_kiem_hang_nang_cao',
  cap_nhat_phieu_kiem_hang_nang_cao: 'cap_nhat_phieu_kiem_hang_nang_cao',
  can_bang_phieu_kiem_hang_nang_cao: 'can_bang_phieu_kiem_hang_nang_cao',
  xoa_phieu_kiem_hang_nang_cao: 'xoa_phieu_kiem_hang_nang_cao',
  //phieu chuyen hang
  phieu_chuyen_hang: 'phieu_chuyen_hang',
  xem_danh_sach_chuyen_hang:'xem_danh_sach_chuyen_hang',
  xem_chi_tiet_phieu_chuyen_hang: 'xem_chi_tiet_phieu_chuyen_hang',
  tao_phieu_chuyen_hang: 'tao_phieu_chuyen_hang',
  cap_nhat_phieu_chuyen_hang: 'cap_nhat_phieu_chuyen_hang',
  xoa_phieu_chuyen_hang: 'xoa_phieu_chuyen_hang',
  export_phieu_chuyen_hang: 'export_phieu_chuyen_hang',

  //quan ly nhan vien*
  quan_li_nhan_vien: 'quan_li_nhan_vien',
  //danh sach nhan vien
  danh_sach_nhan_vien: 'danh_sach_nhan_vien',
  xem_danh_sach_nhan_vien: 'xem_danh_sach_nhan_vien',
  xem_chi_tiet_nhan_vien: 'xem_chi_tiet_nhan_vien',
  them_nhan_vien: 'them_nhan_vien',
  cap_nhat_nhan_vien: 'cap_nhat_nhan_vien',
  xoa_nhan_vien: 'xoa_nhan_vien',

  //lich lam viec
  lich_lam_viec: 'lich_lam_viec',
  xem_danh_sach_lich_lam_viec:'xem_danh_sach_lich_lam_viec',
  xem_chi_tiet_lich_lam_viec: 'xem_chi_tiet_lich_lam_viec',
  cau_hinh_ca_lam_viec: 'cau_hinh_ca_lam_viec',
  them_lich_lam_viec: 'them_lich_lam_viec',
  cap_nhat_lich_lam_viec: 'cap_nhat_lich_lam_viec',
  xoa_lich_lam_viec: 'xoa_lich_lam_viec',
  xem_lich_lam_viec_nhan_vien: 'xem_lich_lam_viec_nhan_vien',
  //nha cung cap*
  quan_li_nha_cung_cap: 'quan_li_nha_cung_cap',
  xem_danh_sach_nha_cung_cap:'xem_danh_sach_nha_cung_cap',
  xem_chi_tiet_nha_cung_cap: 'xem_chi_tiet_nha_cung_cap',
  them_nha_cung_cap: 'them_nha_cung_cap',
  xoa_nha_cung_cap: 'xoa_nha_cung_cap',
  cap_nhat_nha_cung_cap: 'cap_nhat_nha_cung_cap',
  export_nha_cung_cap: 'export_nha_cung_cap',

  //khach hang*
  quan_li_khach_hang: 'quan_li_khach_hang',
  xem_danh_sach_khach_hang:'xem_danh_sach_khach_hang',
  xem_chi_tiet_khach_hang: 'xem_chi_tiet_khach_hang',
  them_khach_hang: 'them_khach_hang',
  cap_nhat_khach_hang: 'cap_nhat_khach_hang',
  xoa_khach_hang: 'xoa_khach_hang',
  export_khach_hang: 'export_khach_hang',

  //bao cao*
  danh_sach_bao_cao: 'danh_sach_bao_cao',
  bao_cao_ton_kho: 'bao_cao_ton_kho',
  bao_cao_xuat_nhap_ton: 'bao_cao_xuat_nhap_ton',
  bao_cao_ban_hang: 'bao_cao_ban_hang',
  bao_cao_ban_hang_theo_phuong_thuc_thanh_toan: 'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
  bao_cao_ton_kho_theo_lo: 'bao_cao_ton_kho_theo_lo',

  //thu chi
  xem_danh_sach_phieu_thu_chi:'xem_danh_sach_phieu_thu_chi',
  bao_cao_thu_chi: 'bao_cao_thu_chi',
  xem_chi_tiet_phieu_thu_chi: 'xem_chi_tiet_phieu_thu_chi',
  them_phieu_thu_chi: 'them_phieu_thu_chi',
  cap_nhat_phieu_thu_chi: 'cap_nhat_phieu_thu_chi',
  xoa_phieu_thu_chi: 'xoa_phieu_thu_chi',
  export_phieu_thu_chi: 'export_phieu_thu_chi',
  //loai phieu thu chi
  xem_danh_sach_loai_phieu_thu_chi:'xem_danh_sach_loai_phieu_thu_chi',
  xem_chi_tiet_loai_phieu_thu_chi:'xem_chi_tiet_loai_phieu_thu_chi',
  loai_phieu_thu_chi: 'loai_phieu_thu_chi',
  them_loai_phieu_thu_chi: 'them_loai_phieu_thu_chi',
  cap_nhat_loai_phieu_thu_chi: 'cap_nhat_loai_phieu_thu_chi',
  xoa_loai_phieu_thu_chi: 'xoa_loai_phieu_thu_chi',

  //cau hinh*
  cau_hinh: 'cau_hinh',
  //quan ly chi nhanh
  xem_danh_sach_chi_nhanh:'xem_danh_sach_chi_nhanh',
  xem_chi_tiet_chi_nhanh:'xem_chi_tiet_chi_nhanh',
  cau_hinh_chi_nhanh: 'cau_hinh_chi_nhanh',
  them_chi_nhanh: 'them_chi_nhanh',
  cap_nhat_chi_nhanh: 'cap_nhat_chi_nhanh',

  //phan quyen
  cau_hinh_phan_quyen: 'cau_hinh_phan_quyen',
  them_vai_tro_phan_quyen: 'them_vai_tro_phan_quyen',
  cap_nhat_vai_tro_phan_quyen: 'cap_nhat_vai_tro_phan_quyen',
  xoa_vai_tro_phan_quyen: 'xoa_vai_tro_phan_quyen',

  //cau hinh hoa don
  cau_hinh_hoa_don: 'cau_hinh_hoa_don',
  xem_danh_sach_hoa_don: 'xem_danh_sach_hoa_don',
  cau_hinh_mau_hoa_don: 'cau_hinh_mau_hoa_don',

  //quan ly bao hanh
  xem_danh_sach_bao_hanh:'xem_danh_sach_bao_hanh',
  xem_chi_tiet_bao_hanh:'xem_chi_tiet_bao_hanh',
  cau_hinh_bao_hanh: 'cau_hinh_bao_hanh',
  them_bao_hanh: 'them_bao_hanh',
  cap_nhat_bao_hanh: 'cap_nhat_bao_hanh',
  xoa_bao_hanh: 'xoa_bao_hanh',
  export_bao_hanh: 'export_bao_hanh',

  //quan ly thue
  xem_danh_sach_thue:'xem_danh_sach_thue',
  xem_chi_tiet_thue:'xem_chi_tiet_thue',
  cau_hinh_thue: 'cau_hinh_thue',
  them_thue: 'them_thue',
  cap_nhat_thue: 'cap_nhat_thue',
  xoa_thue: 'xoa_thue',

  //quan ly thanh toan
  xem_danh_sach_phuong_thuc_thanh_toan:'xem_danh_sach_phuong_thuc_thanh_toan',
  xem_chi_tiet_phuong_thuc_thanh_toan:'xem_chi_tiet_phuong_thuc_thanh_toan',
  cau_hinh_phuong_thuc_thanh_toan: 'cau_hinh_phuong_thuc_thanh_toan',
  them_phuong_thuc_thanh_toan: 'them_phuong_thuc_thanh_toan',
  cap_nhat_phuong_thuc_thanh_toan: 'cap_nhat_phuong_thuc_thanh_toan',
  xoa_phuong_thuc_thanh_toan: 'xoa_phuong_thuc_thanh_toan',

  //quan ly khuyen mai
  xem_danh_sach_khuyen_mai: 'xem_danh_sach_khuyen_mai',
  cau_hinh_khuyen_mai: 'cau_hinh_khuyen_mai',
  xem_chi_tiet_khuyen_mai: 'xem_chi_tiet_khuyen_mai',
  them_khuyen_mai: 'them_khuyen_mai',
  cap_nhat_khuyen_mai: 'cap_nhat_khuyen_mai',
  xoa_khuyen_mai: 'xoa_khuyen_mai',

  //quan ly tich diem
  cau_hinh_tich_diem: 'cau_hinh_tich_diem',
  cap_nhat_tich_diem: 'cap_nhat_tich_diem',
  xem_danh_sach_khach_hang_tich_diem: 'xem_danh_sach_khach_hang_tich_diem',
  // nhap xuat file
  cau_hinh_nhap_xuat_file: 'cau_hinh_nhap_xuat_file',

  // nhat ky hoat dong
  cau_hinh_nhat_ki_hoat_dong: 'cau_hinh_nhat_ki_hoat_dong',

  // Bo loc
  bo_loc_thoi_gian: 'bo_loc_thoi_gian',
  hom_nay: 'hom_nay',
  hom_qua: 'hom_qua',
  tuan_nay: 'tuan_nay',
  tuan_truoc: 'tuan_truoc',
  thang_nay: 'thang_nay',
  thang_truoc: 'thang_truoc',
  nam_nay: 'nam_nay',
  nam_truoc: 'nam_truoc',
  tuy_chon: 'tuy_chon',

  //điểm danh
  diem_danh: 'diem_danh',
  xem_danh_sach_diem_danh: 'xem_danh_sach_diem_danh',
  cap_nhat_diem_danh: 'cap_nhat_diem_danh',
}

export const VERSION_APP = process.env.REACT_APP_VERSION

export const regexPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/

//nhập chữ có khoảng trắng
export const regexRemoveUnicodeNoNumber =
  /^[a-zA-Z0-9@*%$#.,~{}!^+-/?& aàảãáạăằẳẵắặâầẩẫấậAÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬdđDĐeèẻẽéẹêềểễếệEÈẺẼÉẸÊỀỂỄẾỆiìỉĩíịIÌỈĨÍỊoòỏõóọôồổỗốộơờởỡớợOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢuùủũúụưừửữứựUÙỦŨÚỤƯỪỬỮỨỰyỳỷỹýỵYỲỶỸÝỴ]*$/

//nhập chữ có khoảng trắng
export const regexRemoveUnicode =
  /^[a-zA-Z0-9@*%$#.,~{}!^+-/?& aàảãáạăằẳẵắặâầẩẫấậAÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬdđDĐeèẻẽéẹêềểễếệEÈẺẼÉẸÊỀỂỄẾỆiìỉĩíịIÌỈĨÍỊoòỏõóọôồổỗốộơờởỡớợOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢuùủũúụưừửữứựUÙỦŨÚỤƯỪỬỮỨỰyỳỷỹýỵYỲỶỸÝỴ]*$/

export const IMAGE_DEFAULT =
  'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/04/06/28053404-5779-4c1f-905c-d7a0d62a5513/noimage.png'
export const LOGO_DEFAULT =
  'https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/09/02/95131dfc-bf13-4c49-82f3-6c7c43a7354d_logo_quantribanhang 1.png'

export const FILTER_SIZE = ''
export const FILTER_COL_HEIGHT = ''
export const PAGE_SIZE = 20
export const PAGE_SIZE_OPTIONS = [20, 40, 50, 60, 80, 100]
export const POSITION_TABLE = ['bottomLeft']

export const CONDITION_NAME = {
  name: 'Tên sản phẩm',
  description: 'Mô tả',
  sku: 'SKU',
  weight: 'Cân nặng',
  height: 'Chiều cao',
  width: 'Chiều rộng',
  quantity: 'Số lượng',
  price_import: 'Giá nhập',
  price_sale: 'Giá bán',
}

export const CONDITION_OPERATOR = {
  is_equal_to: 'giống',
  is_not_equal_to: 'không giống',
  is_greater_than: 'nhiều hơn',
  is_less_than: 'ít hơn',
  contains: 'chứa',
  does_not_contains: 'không chứa',
  is_not_empty: 'trống',
  is_empty: 'không trống',
}
export const BILL_STATUS_ORDER = {
  DRAFT: 'Lưu nháp',
  PROGRESSING: 'Đang xử lý',
  COMPLETE: 'Hoàn thành',
  CANCEL: 'Huỷ bỏ',
  SHIPPING: 'Đang vận chuyển',
  IN_PRODUCTION: 'Đang bàn giao vận chuyển',
  REFUND: 'Hoàn Tiền',
}
export const TIME_FORMAT = {DATE_TIME_MINUTE: 'DD-MM-YYYY HH:mm', DATE_TIME: 'DD-MM-YYYY HH:mm:ss', DATE: 'DD-MM-YYYY', FILTER: 'YYYY-MM-DD' }
