export const searchFunctionLeft = (e, setFilteredResults, setCount, products) => {
  let data = e.target.value
  data = data.trim()
  if (data !== '') {
    const filteredData = products.filter((item) => {
      return (
        Object.values(item.name).join('').toLowerCase().includes(data.toLowerCase()) ||
        Object.values(item.sku).join('').toLowerCase().includes(data.toLowerCase())
      )
    })
    setFilteredResults(filteredData)
    setCount(filteredData.length)
  } else {
    setFilteredResults(products)
  }
  //khi search hoặc filter thi reset page ve 1
}

export const searchFunctionRight = (e, productChange, setProductFilter, setValueSearch) => {
  setValueSearch(e.target.value)
  let data = e.target.value
  
  data = data.trim()
  if (data !== '') {
    const filteredData = productChange.filter((item) => {
      return (
        Object.values(item.title).join('').toLowerCase().includes(data.toLowerCase()) ||
        Object.values(item.sku).join('').toLowerCase().includes(data.toLowerCase())
      )
    })
    setProductFilter(filteredData)
  } else {
    setProductFilter(productChange)
  }
  //khi search hoặc filter thi reset page ve 1
}
