import React, { useEffect, useState, useRef } from 'react'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION, PAGE_SIZE, PERMISSIONS } from 'consts'
import { socketEcom } from 'socket'
//antd
import { Popconfirm, Input, Row, Col, Select, Table, Button, Space, notification, Tag } from 'antd'
import { SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

//apis
import { getDistricts, getProvinces } from 'apis/address'
import { getAllBranch } from 'apis/branch'
import { getRoles } from 'apis/role'
import { deleteStaff, getListStaff } from 'apis/manage-staff'

//components
import TitlePage from 'components/title-page'
import columnsEmployee from './columns'
import StatusRole from './create-staff-form'
import StaffForm from './edit-staff-form'
import FilterDate from 'components/filter-date'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

const { Option } = Select
export default function Staff() {
  const typingTimeoutRef = useRef(null)
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const user = useSelector((state) => state.login.dataUser)
  const [roles, setRoles] = useState([])
  const [districts, setDistricts] = useState([])
  const [branches, setBranches] = useState([])
  const [provinces, setProvinces] = useState([])
  const [users, setUsers] = useState([])
  const [admin, setAdmin] = useState([])
  const [countUser, setCountUser] = useState([])
  const [loading, setLoading] = useState(false)
  const [Address, setAddress] = useState({ province: [], district: [], branch: [] })
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [valueSearch, setValueSearch] = useState('')
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [valueTime, setValueTime] = useState()

  const [filStatus, setFilStatus] = useState()

  const [onForm, setOnForm] = useState(false)

  const onSearch = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter.searchWeb = value
      else delete paramsFilter.searchWeb

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }
  const _clearFilters = () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE , check:1})
    setValueSearch('')
    setFilStatus('all')
    setValueTime()
  }
  const filterStatus = (value) =>{
    setFilStatus(value);
    // eslint-disable-next-line default-case
    switch (value) {
      case 'all':
        return setParamsFilter({...paramsFilter,status: '', is_delete: '', page:1})
      case '1':
        return setParamsFilter({...paramsFilter, status: 1, is_delete: 0, page:1})
      case '2':
        return setParamsFilter({...paramsFilter, status: 0, is_delete: 0, page:1})
      case '3':
        return setParamsFilter({...paramsFilter, status: 1, is_delete: 1, page:1})
      case '4':
        return setParamsFilter({...paramsFilter, status: 0, is_delete: 1, page:1})
      case '5':
        return setParamsFilter({...paramsFilter, status: -1, is_delete: 0, page:1})
    }
  }
  const _deleteUser = async (id, notification_staff_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        notification_staff_id: notification_staff_id,
        user_id: id,
        branch_id: branchIdApp,
      }
      const res = await deleteStaff(body)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật nhân viên thành công' })
          _getEmployees()
        } else
          notification.error({
            message: res.data.message || 'Cập nhật nhân viên thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật nhân viên thất bại, vui lòng thử lại',
        })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }
  const _getEmployees = async () => {
    try {
      setLoading(true)
      const res = await getListStaff({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        setUsers(res.data.data.filter((employee) => employee.role_id !== 1))
        // setUserFilStatus(res.data.data.filter((employee) => employee.role_id !== 1))
        setAdmin(res.data.data.find((employee) => employee.role_id === 1))
        setCountUser(res.data.count)
      }

      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getAddress = async (api, callback, key, params) => {
    try {
      const res = await api(params)
      if (res.status === 200) {
        callback((e) => {
          return { ...e, [key]: res.data.data }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
  const _getDistricts = async () => {
    try {
      const res = await getDistricts()
      if (res.status === 200) setDistricts(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getProvinces = async () => {
    try {
      const res = await getProvinces()
      if (res.status === 200) setProvinces(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getBranches = async () => {
    try {
      const res = await getAllBranch()
      if (res.status === 200) setBranches(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getRoles = async () => {
    try {
      const res = await getRoles({ active: true, branch_id: branchIdApp })
      if (res.status === 200) {
        const roles = res.data.data
        setRoles([...roles])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const socketListener = () => {
    socketEcom.on(`reply-staff:action:${user._business.business_id}`, async (res) => {
      _getEmployees()
    })
  }

  useEffect(() => {
    socketListener()
  }, [socketEcom])

  useEffect(() => {
    _getBranches()
    _getProvinces()
    _getDistricts()
  }, [])
  useEffect(() => {
    _getRoles()
  }, [branchIdApp])
  useEffect(() => {
    getAddress(getProvinces, setAddress, 'province')
    getAddress(getDistricts, setAddress, 'district')
  }, [])
  useEffect(() => {
    if(paramsFilter.check) _getEmployees()
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueSearch('')
    setFilStatus('all')
    if(dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)){
      setParamsFilter({page: 1, page_size: PAGE_SIZE , check:1})
      setValueTime()
    }else {
      setParamsFilter({page: 1, page_size: PAGE_SIZE , from_date: result.from_date, to_date: result.to_date, check:1})
      setValueTime(result.valueDate)
    }
  },[dataUser])
  return (
    <div className="card">
      <TitlePage title="Quản lý nhân viên" isAffix>
        <Space>
          <Button
            style={{ display: Object.keys(paramsFilter).length < 3 && 'none' }}
            onClick={_clearFilters}
            type="danger"
          >
            Xóa bộ lọc
          </Button>
          <StatusRole
            setOnForm={setOnForm}
            roles={roles}
            dataStaff={_getEmployees}
            branches={branches}
            admin={admin}
          />
        </Space>
      </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_nhan_vien) ? (
        <>
          <Row
            gutter={[6, 16]}
            style={{ marginTop: 15, border: '1px solid #d9d9d9', borderRadius: 5, backgroundColor: 'white' }}
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder="Tìm kiếm theo tên, số điện thoại nhân viên"
                onChange={onSearch}
                value={valueSearch}
                bordered={false}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              style={{ borderLeft: '1px solid #d9d9d9', borderRight: '1px solid #d9d9d9' }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Vai trò"
                showSearch
                allowClear
                value={paramsFilter.role_id || 'all'}
                onChange={(value) => {
                  paramsFilter.role_id = value
                  if (value === 'all') {
                    delete paramsFilter.role_id
                  }
                  setParamsFilter({ ...paramsFilter, page:1 })
                }}
                defaultValue="all"
                optionFilterProp="children"
                bordered={false}
              >
                <Option value="all">Tất cả vai trò</Option>
                {roles
                  ?.filter((item) => item.role_id !== 1)
                  .map((e, index) => (
                    <Option value={e.role_id} key={index}>
                      {e.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              style={{ borderRight: '1px solid #d9d9d9' }}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Trạng thái"
                optionFilterProp="children"
                onChange={(value) => {
                  filterStatus(value)
                }}
                value={filStatus || 'all'}
                defaultValue="all"
                bordered={false}
              >
                <Option value="all">Tất cả trạng thái</Option>
                <Option value="1">Đang làm việc</Option>
                <Option value="2">Chưa xác nhận</Option>
                <Option value="3">Đã nghỉ việc</Option>
                <Option value="4">Huỷ lời mời</Option>
                <Option value="5">Đã từ chối</Option>
              </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
              <FilterDate
                style={{ marginTop: 3 }}
                bordered={false}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                title="mời nhân viên"
                valueTime={valueTime}
                setValueTime={setValueTime}
                screen={1}
              />
            </Col>
          </Row>

          <Table
            loading={loading}
            rowKey="code"
            size="small"
            scroll={{ y: 500 }}
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) =>
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
              total: countUser,
            }}
            columns={columnsEmployee.map((column) => {
              if (column.key === 'stt')
                return { ...column, render: (text, record, index) => (paramsFilter.page -1 ) * paramsFilter.page_size + index + 1 }
              if (column.key === 'code')
                return { ...column, render: (text, record, index) => <span>#{text}</span> }
              if (column.key === 'name')
                return {
                  ...column,
                  render: (text, record) => (
                    <span>{record.first_name + ' ' + record.last_name}</span>
                  ),
                }
              if (column.key === 'branch')
                return {
                  ...column,
                  render: (text, record) =>
                    branches
                      .filter((item) => item.branch_id === record.branch_id)
                      .map((item) => <span>{item.name}</span>),
                }

              if (column.key === 'phone') return { ...column, render: (data) => <>{data.phone}</> }

              if (column.key === 'email')
                return {
                  ...column,
                  render: (text, record) => <span>{record.email}</span>,
                }
              if (column.key === 'address')
                return {
                  ...column,
                  render: (text, record) => (
                    <span>
                      {record.address && ' ,' + record.address}
                      {record.branch && ' ,' + record.branch}
                      {record.district && ' ,' + record.district}
                      {record.province && ' ,' + record.province}
                    </span>
                  ),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                  render: (data) => data && moment(data).format('DD/MM/YYYY HH:mm'),
                }
              if (column.key === 'status')
                return {
                  ...column,
                  render: (data, record) =>
                    record && record.role_id !== 1 ? (
                      record.is_delete == false ? (
                        record.status == 0 ? (
                          <Tag style={{ width: '100px' }}>Chưa Xác Nhận</Tag>
                        ) : record.status == 1 ? (
                          <Tag style={{ width: '100px', backgroundColor: '#B7EB8F' }} >
                            Đang làm việc
                          </Tag>
                        ) : (
                          <Tag style={{ width: '100px', backgroundColor: '#FF4500' }}>
                            Đã Từ Chối
                          </Tag>
                        )
                      ) : record.status == 0 ? (
                        <Tag style={{ width: '100px', backgroundColor: '#CDC9C9' }}>
                          Huỷ lời mời
                        </Tag>
                      ) : (
                        <Tag style={{ width: '100px', backgroundColor: '#CDC9C9' }}>
                          Đã nghỉ việc
                        </Tag>
                      )
                    ) : (
                      <></>
                    ),
                }
              if (column.key === 'role')
                return {
                  ...column,
                  render: (data, record) => record._role && <Tag color={ record._role.active === false && "magenta"}>{record._role.name}</Tag>,
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) =>
                    record.role_id !== 1 ? (
                      record.is_delete === false ? (
                        record.status === 1 ? (
                          <Row>
                            <Space>
                              {permissions.includes(PERMISSIONS.xoa_nhan_vien) ? (
                                <Popconfirm
                                  title="Bạn có muốn xóa nhân viên này không?"
                                  okText="Đồng ý"
                                  cancelText="Từ chối"
                                  onConfirm={() =>
                                    _deleteUser(
                                      record.user_id,
                                      record.notification_staff_id,
                                      branchIdApp
                                    )
                                  }
                                >
                                  <Button icon={<DeleteOutlined />} type="primary" danger />
                                </Popconfirm>
                              ) : (
                                <Button disabled icon={<DeleteOutlined />} type="primary" danger />
                              )}
                              {permissions.includes(PERMISSIONS.xem_chi_tiet_nhan_vien) ||
                              permissions.includes(PERMISSIONS.cap_nhat_nhan_vien) ? (
                                <StaffForm
                                  record={record}
                                  reloadData={_getEmployees}
                                  roles={roles}
                                  provinces={provinces}
                                  districts={districts}
                                  branches={branches}
                                >
                                  <Button icon={<EditOutlined />} type="primary" />
                                </StaffForm>
                              ) : (
                                  <Button disabled icon={<EditOutlined />} type="primary" />
                              )}
                            </Space>
                          </Row>
                        ) : (
                          <Row>
                            <Space>
                              {permissions.includes(PERMISSIONS.xoa_nhan_vien) ? (
                                <Popconfirm
                                  title="Bạn có muốn xóa nhân viên này không?"
                                  okText="Đồng ý"
                                  cancelText="Từ chối"
                                  onConfirm={() =>
                                    _deleteUser(
                                      record.user_id,
                                      record.notification_staff_id,
                                      branchIdApp
                                    )
                                  }
                                >
                                  <Button icon={<DeleteOutlined />} type="primary" danger />
                                </Popconfirm>
                              ) : (
                                <Button disabled icon={<DeleteOutlined />} type="primary" danger />
                              )}
                            </Space>
                          </Row>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    ),
                }

              return column
            })}
            dataSource={users}
            style={{ width: '100%', marginTop: 10 }}
          />
        </>
      ) : null}
    </div>
  )
}
