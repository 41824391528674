import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import styles from './finance-type.module.scss'
import { useSelector } from 'react-redux'
import { compare } from 'utils'
import { useHistory, Link } from 'react-router-dom'
import { PAGE_SIZE, ROUTES, FILTER_SIZE, PERMISSIONS } from 'consts'

//antd
import {
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Affix,
  Tabs,
} from 'antd'

//icons
import {
  DeleteOutlined,
  PlusCircleOutlined,
  EditFilled,
  ArrowLeftOutlined,
} from '@ant-design/icons'

//components
import FinanceTypeFormPayment from './finance-type-payment-form'
import FinanceTypeFormReceipts from './finance-type-receipts-form'
import SettingColumns from 'components/setting-columns'
import columnType from './columns'
import TitlePage from 'components/title-page'
import Permissions from 'components/permission'
import FilterDate from 'components/filter-date'
import { sortBy } from 'lodash'
import columns from './columns'
//apis
import {
  getFinanceTypePayment,
  deleteFinanceTypesPayment,
  getFinanceTypeReceipts,
  deleteFinanceTypeReceipts,
  updateFinanceTypesPayment,
} from 'apis/finance-type'

const { Option } = Select
const { RangePicker } = DatePicker
const { TabPane } = Tabs
export default function CustomerType() {
  const typingTimeoutRef = useRef(null)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const history = useHistory()
  // const [columns, setColumns] = useState([])
  const [countFinanceTypePayment, setCountFinanceTypePayment] = useState(0)
  const [countFinanceTypeReceipts, setCountFinanceTypeReceipts] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 20 })
  const [paramsFilter1, setParamsFilter1] = useState({ page: 1, page_size: 20 })
  const [valueTime, setValueTime] = useState()
  const [valueTime1, setValueTime1] = useState()

  const [tableLoading, setTableLoading] = useState(false)
  const [financeTypesPayment, setFinanceTypesPayment] = useState([])
  const [financeTypesReceipts, setFinanceTypesReceipts] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const [valueSearch1, setValueSearch1] = useState('')

  const [optionSearch, setOptionSearch] = useState('name')
  const [optionSearch1, setOptionSearch1] = useState('name')

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const onSearch = (e) => {
    setValueSearch(e.target.value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }
  const onSearch1 = (e) => {
    setValueSearch1(e.target.value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      if (value) paramsFilter1[optionSearch1] = value
      else delete paramsFilter1[optionSearch1]

      setParamsFilter1({ ...paramsFilter1, page: 1 })
    }, 750)
  }
  const ModalTypeFinancePayment = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          style={{ top: 20 }}
          // onCancel={toggle}
          closable={false}
          width={800}
          footer={null}
          title={`${record ? 'Cập nhật' : 'Tạo'} loại phiếu chi  `}
          visible={visible}
        >
          <FinanceTypeFormPayment
            record={record}
            close={toggle}
            text={record ? 'Lưu' : 'Tạo'}
            reload={_getFinanceTypesPayment}
          />
        </Modal>
      </>
    )
  }
  const ModalTypeFinanceReceipts = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          style={{ top: 20 }}
          // onCancel={toggle}
          closable={false}
          width={800}
          footer={null}
          title={`${record ? 'Cập nhật' : 'Tạo'} loại phiếu thu `}
          visible={visible}
        >
          <FinanceTypeFormReceipts
            record={record}
            close={toggle}
            text={record ? 'Lưu' : 'Tạo'}
            reload={_getFinanceTypesReceipts}
          />
        </Modal>
      </>
    )
  }
  function clearParam(){
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setParamsFilter1({ page: 1, page_size: PAGE_SIZE })
  }
  const _getFinanceTypesPayment = async () => {
    try {
      setTableLoading(true)
      const res = await getFinanceTypePayment({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        setFinanceTypesPayment(res.data.data)
        setCountFinanceTypePayment(res.data.count)
      }
      setTableLoading(false)
    } catch (error) {
      console.log(error)
      setTableLoading(false)
    }
  }
  const _deleteFinanceTypesPayment = async (id) => {
    try {
      setTableLoading(true)
      const res = await deleteFinanceTypesPayment({type_id: id, branch_id: branchIdApp})
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getFinanceTypesPayment()
          notification.success({ message: 'Xóa loại phiếu chi thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xóa loại phiếu chi thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa loại phiếu chi thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
      setTableLoading(false)
    }
  }
  const _getFinanceTypesReceipts = async () => {
    try {
      setTableLoading(true)
      const res = await getFinanceTypeReceipts({ ...paramsFilter1, branch_id: branchIdApp })
      if (res.status === 200) {
        setFinanceTypesReceipts(res.data.data)
        setCountFinanceTypeReceipts(res.data.count)
      }
      setTableLoading(false)
    } catch (error) {
      console.log(error)
      setTableLoading(false)
    }
  }
  const _deleteFinanceTypeReceipts = async (id) => {
    try {
      setTableLoading(true)
      const res = await deleteFinanceTypeReceipts({type_id: id, branch_id: branchIdApp})
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getFinanceTypesReceipts()
          notification.success({ message: 'Xóa loại phiếu chi thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xóa loại phiếu chi thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa loại phiếu chi thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
      setTableLoading(false)
    }
  }

  useEffect(() => {
    _getFinanceTypesPayment()
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    _getFinanceTypesReceipts()
  }, [paramsFilter1, branchIdApp])
  return (
    <div className="card">
        <TitlePage
        isAffix
          title={
            <Row
              wrap={false}
              align="middle"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(ROUTES.RECEIPTS_PAYMENT)}
            >
              <ArrowLeftOutlined style={{ marginRight: 10 }} />
              Quản lý loại phiếu
            </Row>
          }
        >
          <Space>
            {/* <SettingColumns
              columnsDefault={columnType}
              setColumns={setColumns}
              columns={columns}
              nameColumn="columnType"
            /> */}
            {permissions.includes(PERMISSIONS.them_loai_phieu_thu_chi) ? (
              <>
                {' '}
                <ModalTypeFinancePayment>
                  <Button
                    size="large"
                    icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                    type="primary"
                    ant-click-animating-without-extra-node="false"
                  >
                    Thêm loại phiếu chi
                  </Button>
                </ModalTypeFinancePayment>
                <ModalTypeFinanceReceipts>
                  <Button
                    size="large"
                    icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                    type="primary"
                  >
                    Thêm loại phiếu thu
                  </Button>
                </ModalTypeFinanceReceipts>
              </>
            ) : (
              <>
                {' '}
                <ModalTypeFinancePayment>
                  <Button
                    size="large"
                    icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                    type="primary"
                    disabled
                  >
                    Thêm loại phiếu chi
                  </Button>
                </ModalTypeFinancePayment>
                <ModalTypeFinanceReceipts>
                  <Button
                    size="large"
                    icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                    type="primary"
                    disabled
                  >
                    Thêm loại phiếu thu
                  </Button>
                </ModalTypeFinanceReceipts>
              </>
            )}
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_loai_phieu_thu_chi)?(
      <Tabs defaultActiveKey={'1' || '2'} onTabClick={() => clearParam()} style={{backgroundColor:'white', padding:5}}>
        <TabPane tab={<span className={styles['tabpane']}>Loại phiếu chi</span>} key="1">
          <Row gutter={[8, 16]} style={{ marginTop: 15 }}>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Row
                wrap={false}
                style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: 5 }}
              >
                <Input
                  style={{ width: '100%', borderRight: '1px solid #d9d9d9' }}
                  placeholder="Tìm kiếm theo..."
                  value={valueSearch}
                  onChange={(e) => onSearch(e)}
                  allowClear
                  bordered={false}
                />
                <Select
                  style={{ width: 180 }}
                  value={optionSearch}
                  onChange={(value) => {
                    delete paramsFilter[optionSearch]
                    setOptionSearch(value)
                  }}
                  bordered={false}
                >
                  <Option value="name">Tên loại phiếu chi</Option>
                  <Option value="code"> Mã loại phiếu chi</Option>
                </Select>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <Row style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: 5 }}>
              <FilterDate
                size="default"
                bordered={false}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                title="tạo loại phiếu chi"
                valueTime={valueTime}
                setValueTime={setValueTime}
              />
              </Row>
            </Col>
          </Row>
          <Table
            style={{ width: '100%', marginTop: 10 }}
            rowKey="type_id"
            scroll={{ y: 400 }}
            loading={tableLoading}
            columns={columns.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  render: (text, record, index) => index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.xem_chi_tiet_loai_phieu_thu_chi) ? (
                      <ModalTypeFinancePayment record={record}>
                        <a>{record.code}</a>
                      </ModalTypeFinancePayment>
                    ) : (
                      <span style={{cursor:'pointer', color:'#5F73E2'}} onClick={() => notification.warning({message:'Permission denied!'})}>{record.code}</span>
                    ),
                  sorter: (a, b) => compare(a, b, 'code'),
                }
              if (column.key === 'name')
                return {
                  ...column,
                  render: (text, record) => <p>{record.name}</p>,
                  sorter: (a, b) => compare(a, b, 'name'),
                }
              if (column.key === 'description')
                return {
                  ...column,
                  render: (text, record) => record.description,
                  sorter: (a, b) => compare(a, b, 'description'),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) =>
                    record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) => (
                    <Permissions permissions={[PERMISSIONS.xoa_loai_phieu_thu_chi]}>
                      <div className={styles['display']}>
                        <Popconfirm
                          title="Bạn có muốn xóa loại phiếu chi này không?"
                          cancelText="Từ chối"
                          okText="Đồng ý"
                          onConfirm={() => _deleteFinanceTypesPayment(record.type_id)}
                        >
                          <Button danger type="primary" icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </div>
                    </Permissions>
                  ),
                }

              return column
            })}
            dataSource={financeTypesPayment}
            size="small"
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 70, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
              },
              total: countFinanceTypePayment,
            }}
          />
        </TabPane>
        <TabPane tab={<span className={styles['tabpane']}>Loại phiếu thu</span>} key="2" >
          <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Row
                wrap={false}
                style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: 5 }}
              >
                <Input
                  style={{ width: '100%', borderRight: '1px solid #d9d9d9' }}
                  placeholder="Tìm kiếm theo..."
                  value={valueSearch1}
                  onChange={(e) => onSearch1(e)}
                  allowClear
                  bordered={false}
                />
                <Select
                  style={{ width: 180 }}
                  value={optionSearch1}
                  onChange={(value) => {
                    delete paramsFilter1[optionSearch1]
                    setOptionSearch1(value)
                  }}
                  bordered={false}
                >
                  <Option value="name">Tên loại phiếu thu</Option>
                  <Option value="code">Mã loại phiếu thu</Option>
                </Select>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <Row style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: 5 }}>
              <FilterDate
                size="default"
                bordered={false}
                paramsFilter={paramsFilter1}
                setParamsFilter={setParamsFilter1}
                title="tạo loại phiếu thu"
                valueTime={valueTime1}
                setValueTime={setValueTime1}
              />
              </Row>
            </Col>
          </Row>
          <Table
            style={{ width: '100%', marginTop: 10 }}
            rowKey="type_id"
            scroll={{ y: 400 }}
            loading={tableLoading}
            columns={columns.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  render: (text, record, index) => index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.xem_chi_tiet_loai_phieu_thu_chi) ? (
                      <ModalTypeFinanceReceipts record={record}>
                        <a>{record.code}</a>
                      </ModalTypeFinanceReceipts>
                    ) : (
                      <span style={{cursor:'pointer', color:'#5F73E2'}} onClick={() => notification.warning({message:'Permission denied!'})}>{record.code}</span>
                    ),
                  sorter: (a, b) => compare(a, b, 'code'),
                }
              if (column.key === 'name')
                return {
                  ...column,
                  render: (text, record) => <p>{record.name}</p>,
                  sorter: (a, b) => compare(a, b, 'name'),
                }
              if (column.key === 'description')
                return {
                  ...column,
                  render: (text, record) => record.description,
                  sorter: (a, b) => compare(a, b, 'description'),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) =>
                    record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) => (
                    <div className={styles['display']}>
                      <>
                        {permissions.includes(PERMISSIONS.xoa_loai_phieu_thu_chi) ? (
                          <Popconfirm
                            title="Bạn có muốn xóa loại phiếu thu này không?"
                            cancelText="Từ chối"
                            okText="Đồng ý"
                            onConfirm={() => _deleteFinanceTypeReceipts(record.type_id)}
                          >
                            <Button danger type="primary" icon={<DeleteOutlined />} />
                          </Popconfirm>
                        ) : (
                          <Popconfirm
                            title="Bạn có muốn xóa loại phiếu thu này không?"
                            cancelText="Từ chối"
                            okText="Đồng ý"
                            onConfirm={() => _deleteFinanceTypeReceipts(record.type_id)}
                          >
                            <Button danger type="primary" icon={<DeleteOutlined />} />
                          </Popconfirm>
                        )}
                      </>
                    </div>
                  ),
                }

              return column
            })}
            dataSource={financeTypesReceipts}
            size="small"
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter1.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 70, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setParamsFilter1({ ...paramsFilter1, page: page, page_size: pageSize })
              },
              total: countFinanceTypePayment,
            }}
          />
        </TabPane>
      </Tabs>):null}
      
    </div>
  )
}
