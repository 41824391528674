import React, { useState, useEffect, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'
import {
  ROUTES,
  PERMISSIONS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  FILTER_SIZE,
  ACTION,
  IMAGE_DEFAULT,
} from 'consts'
import { compare, formatCash } from 'utils'
import { useReactToPrint } from 'react-to-print'
import { useSelector, useDispatch } from 'react-redux'
//antd
import {
  Input,
  Button,
  Row,
  DatePicker,
  Table,
  Select,
  Space,
  Popconfirm,
  notification,
  Col,
  Affix,
  Drawer,
  Collapse,
  Timeline,
  Modal,
  Tag,
} from 'antd'

//icons
import { PlusCircleOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons'

//components
import PrintOrder from 'components/print/print-order'
import columnsDelivery from './columnsDelivery'
import TitlePage from 'components/title-page'
import { useTranslation } from 'react-i18next'
import FilterDate from 'components/filter-date'
import ConnectStatus from '../order-update/connect-status'

//apis
import { getOrders, updateOrder, getLinkInvoice } from 'apis/order'
import { getEmployees } from 'apis/employee'
import { getAllBranch } from 'apis/branch'
import { getShippings } from 'apis/shipping'
import { getEnumPlatform, getShippingStatus } from 'apis/enum'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

const { Panel } = Collapse
export default function DeliveryControl() {
  const { t } = useTranslation()
  let printOrderRef = useRef()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  // const branchData = useSelector((state) => state.branch.branches)
  const history = useHistory()
  const [shippingCompany, setShippingCompany] = useState([])
  const [platforms, setPlatForms] = useState([])
  const typingTimeoutRef = useRef(null)
  const [dataPrint, setDataPrint] = useState(null)
  const [statusShipping, setStatusShipping] = useState([])
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [countOrder, setCountOrder] = useState(0)
  const [totalCostOrder, setTotalCostOrder] = useState({})
  const [employees, setEmployees] = useState([])
  const [valueTime, setValueTime] = useState()

  const [optionSearchName, setOptionSearchName] = useState('code')
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [paramsFilterOther, setParamsFilterOther] = useState({})
  const [valueSearch, setValueSearch] = useState('')
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const toggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer)
  }

  const Print = () => (
    <div style={{ display: 'none' }}>
      <PrintOrder ref={printOrderRef} data={dataPrint} />
    </div>
  )

  const _onCancelOrder = async (order_id) => {
    try {
      const res = await updateOrder(
        { ship_status: 'CANCEL', status: 500, order_status: 'cancel-order' },
        order_id
      )
      if (res.status === 200) {
        if (res.data.success) {
          _getDeliveryOrders()
          notification.success({ message: 'Cập nhật thành công' })
        } else
          notification.error({
            message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại',
          })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
    } catch (error) {
      console.log(error)
    }
  }

  const _onPrintInvoiceTracking = async (order_id, tracking_number) => {
    try {
      const res = await getLinkInvoice({ order_id: order_id, tracking_number: tracking_number })
      if (res.status === 200) {
        if (res.data.success) {
          window.open(res.data.data, '_blank')
        } else
          notification.error({
            message: res.data.message || 'Có sự cố khi tải phiếu, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Có sự cố khi tải phiếu, vui lòng thử lại',
        })
    } catch (error) {
      console.log(error)
    }
  }

  const ModalUpdateTrackingNumberOrShipping = ({
    children,
    id = '',
    tracking_number = null,
    shipping = null,
    shipping_company_id,
    shipping_info,
  }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [_trackingNumber, setTrackingNumber] = useState(tracking_number || shipping || '')
    const [_shipping_company_id, setShippingCompanyId] = useState(shipping_company_id)
    const _onUpdate = async () => {
      try {
        var _index = shippingCompany.findIndex(
          (ship) => ship.shipping_company_id == _shipping_company_id
        )
        shipping_info.tracking_number = _trackingNumber

        if (_index >= 0) shipping_info.shipping_name = shippingCompany[_index].name
        const body = { shipping_info: shipping_info, shipping_company_id: _shipping_company_id }
        const res = await updateOrder(body, id)
        if (res.status === 200) {
          if (res.data.success) {
            _getDeliveryOrders()
            notification.success({ message: 'Cập nhật thành công' })
          } else
            notification.error({
              message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại',
            })
        } else
          notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <>
        <a onClick={toggle}>{children}</a>
        <Modal
          onOk={_onUpdate}
          title={`Cập nhật ${
            tracking_number !== null && (tracking_number !== '' || tracking_number === '')
              ? 'mã vận đơn'
              : ''
          }${shipping !== null && (shipping !== '' || shipping === '') ? 'đơn vị vận chuyển' : ''}`}
          onCancel={toggle}
          visible={visible}
          okText={'Cập nhật'}
        >
          <Input
            onChange={(e) => setTrackingNumber(e.target.value)}
            value={_trackingNumber}
            placeholder="Nhập mã vận đơn"
          />

          <Select
            value={_shipping_company_id}
            onChange={setShippingCompanyId}
            showSearch
            placeholder="Chọn đơn vị vận chuyển mới"
            style={{ width: '100%', marginTop: 20 }}
            defaultValue={_shipping_company_id}
          >
            {shippingCompany.map((shipping, index) => (
              <Select.Option value={shipping.shipping_company_id} key={index}>
                {shipping.name}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      </>
    )
  }

  const _onSearch = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter[optionSearchName] = value.trim()
      else delete paramsFilter[optionSearchName]

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _onChangeFilter = (attribute = '', value = '') => {
    paramsFilter.page = 1
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    if (attribute == 'ship_status' && value == 'all') {
      delete paramsFilter[attribute]
    }
    if (attribute == 'shipping_company_id' && value == 'all') {
      delete paramsFilter[attribute]
    }
    setParamsFilter({ ...paramsFilter })
  }

  const _onChangeOtherFilter = (attribute = '', value = '') => {
    if (value) paramsFilterOther[attribute] = value
    else {
      delete paramsFilterOther[attribute]
      delete paramsFilter[attribute]
    }
    setParamsFilterOther({ ...paramsFilterOther })
  }

  const _submitFilterOther = () => {
    setParamsFilter({ ...paramsFilter, ...paramsFilterOther })
  }

  const columnsProduct = [
    {
      title: 'Mã sản phẩm',
      dataIndex: 'sku',
      sorter: (a, b) => compare(a, b, 'sku'),
    },
    {
      title: 'Ảnh',
      dataIndex: 'image',
      render: (data) => (
        <img
          src={data && data[0] ? data[0] : IMAGE_DEFAULT}
          style={{ maxWidth: 60, maxHeight: 60 }}
          alt=""
        />
      ),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'title',
      sorter: (a, b) => compare(a, b, 'title'),
    },
    {
      title: 'Đơn vị',
      sorter: (a, b) => compare(a, b, 'unit'),
      dataIndex: 'unit',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      sorter: (a, b) => compare(a, b, 'quantity'),
    },
    {
      title: 'Đơn giá',
      sorter: (a, b) => compare(a, b, 'price'),

      render: (text, record) => (record.price ? formatCash(+record.price) : 0),
    },
    {
      title: 'Chiết khấu',
      sorter: (a, b) => compare(a, b, 'discount'),

      render: (text, record) => (record.discount ? formatCash(+record.discount) : 0),
    },
    {
      title: 'Thuế',
      dataIndex: '',
      // sorter: (a, b) => compare(a, b, 'price'),
    },
    {
      title: 'Thành tiền',
      sorter: (a, b) => compare(a, b, 'total_cost'),
      render: (text, record) => (record.total_cost ? formatCash(+record.total_cost) : 0),
    },
  ]

  const _getDeliveryOrders = async () => {
    try {
      setLoading(true)
      const res = await getOrders({ ...paramsFilter, branch_id: branchIdApp, is_delivery: true, role_id: dataUser?.role_id })
      if (res.status === 200) {
        setOrders(res.data.data)
        setCountOrder(res.data.count)
        let total = {
          total_cod_shipping: 0,
          total_fee_shipping:0,
          total_cod_fee:0
        }
        res.data.data.forEach(element => {
          total.total_cod_shipping += element.shipping_info.cod
          total.total_fee_shipping += element.shipping_info?.fee_shipping
        });
        total.total_cod_fee = total.total_cod_shipping + total.total_fee_shipping 
        setTotalCostOrder(total)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getEmployees = async () => {
    try {
      const res = await getEmployees({ role_id: 2 })
      if (res.status === 200) setEmployees(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getShippingCompany = async () => {
    try {
      const res = await getShippings({active: true})
      if (res.status === 200){
        setShippingCompany(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getPlatform = async () => {
    try {
      const res = await getEnumPlatform()
      if (res.status === 200) setPlatForms(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
    setValueSearch('')
  }
  const _getShippingStatus = async () => {
    try {
      const res = await getShippingStatus()
      if (res.status === 200) setStatusShipping(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getEmployees()
    _getShippingCompany()
    _getPlatform()
    _getShippingStatus()
  }, [])

  useEffect(() => {
    if (paramsFilter.check) _getDeliveryOrders()
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setParamsFilter({
      page: 1, page_size: PAGE_SIZE,
      from_date: result.from_date,
      to_date: result.to_date,
      check: 1,
    })
    setValueTime(result.valueDate)
    setValueSearch('')
  }, [dataUser])
  return (
    <div className="card">
      <Drawer
        title="Bộ lọc khác"
        placement="right"
        onClose={toggleDrawer}
        visible={visibleDrawer}
        extra={
          <Button onClick={_submitFilterOther} type="primary">
            Lọc
          </Button>
        }
      >
        <Collapse defaultActiveKey={['1']} ghost>
          <Panel header="Đối tác giao hàng" key="1">
            <Select
              placeholder="Đối tác giao hàng"
              style={{ width: '100%' }}
              size={FILTER_SIZE}
              allowClear
              // value={paramsFilterOther.shipping_company_id || null}
              onChange={(value) => _onChangeOtherFilter('shipping_company_id', value)}
            >
              {shippingCompany.map((item) => (
                <Select.Option value={item.shipping_company_id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Panel>

          <Panel header="Nguồn" key="5">
            <Select
              placeholder="Nguồn"
              style={{ width: '100%' }}
              size={FILTER_SIZE}
              allowClear
              // value={paramsFilterOther.platform || null}
              onChange={(value) => _onChangeOtherFilter('platform', value)}
            >
              {platforms.map((item) => (
                <Select.Option value={item.name}>{item.label}</Select.Option>
              ))}
            </Select>
          </Panel>
          <Panel header="Nhân viên tạo" key="6">
            <Select
              onChange={(value) => _onChangeOtherFilter('employee_name', value)}
              showSearch
              size={FILTER_SIZE}
              placeholder="Chọn nhân viên"
              style={{ width: '100%' }}
              allowClear
            >
              {employees.map((employee, index) => (
                <Select.Option value={employee.first_name + ' ' + employee.last_name} key={index}>
                  {employee.first_name} {employee.last_name}
                </Select.Option>
              ))}
            </Select>
          </Panel>
        </Collapse>
      </Drawer>
      <Print />
        <TitlePage title="Quản lý phiếu giao hàng" isAffix>
          <Space>
            <>
              <Button
                style={{
                  display: Object.keys(paramsFilter).length <= 2 && 'none',
                }}
                onClick={onClickClear}
                type="danger"
              >
                Xóa tất cả lọc
              </Button>

            <Button
              onClick={toggleDrawer}
              type="primary"
            >
              Bộ lọc khác
            </Button>
              {/* {permissions.includes(PERMISSIONS.tao_don_hang) ? (
                <Button
                  onClick={() =>
                    history.push({ pathname: ROUTES.ORDER_CREATE, title: 'delivery-control' })
                  }
                  size="large"
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Tạo thủ công
                </Button>
              ) : (
                <Button
                  disabled
                  onClick={() =>
                    history.push({ pathname: ROUTES.ORDER_CREATE, title: 'delivery-control' })
                  }
                  size="large"
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Tạo thủ công
                </Button>
              )} */}
            </>
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_giao_hang) ? (
        <>
            <Row
              gutter={[16, 16]}
              justify="space-between"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
              >
                <Input
                  style={{ width: '60%' }}
                  prefix={<SearchOutlined />}
                  name="name"
                  value={valueSearch}
                  onChange={_onSearch}
                  placeholder="Tìm kiếm đơn hàng"
                  bordered={true}
                  allowClear
                />
                <Select
                  showSearch
                  size={FILTER_SIZE}
                  style={{ width: '40%'}}
                  value={optionSearchName}
                  onChange={(value) => {
                    delete paramsFilter[optionSearchName]
                    setOptionSearchName(value)
                  }}
                  bordered={true}
                >
                  <Select.Option value="code">Mã đơn hàng</Select.Option>
                  <Select.Option value="tracking_number">Mã vận đơn</Select.Option>
                  <Select.Option value="customer_name">Tên khách hàng</Select.Option>
                  <Select.Option value="customer_phone">SĐT khách hàng</Select.Option>
                  {/* <Select.Option value="address">Địa chỉ giao hàng</Select.Option> */}
                </Select>
              </Col>

              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                  >
                    <FilterDate
                    size='default'
                      bordered={true}
                      paramsFilter={paramsFilter}
                      setParamsFilter={setParamsFilter}
                      title="tạo đơn giao hàng"
                      valueTime={valueTime}
                      setValueTime={setValueTime}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                  >
                    <Select
                      size={FILTER_SIZE}
                      value={paramsFilter.ship_status || 'all'}
                      onChange={(value) => _onChangeFilter('ship_status', value)}
                      showSearch
                      placeholder="Lọc theo trạng thái giao hàng"
                      style={{ width: '100%' }}
                      bordered={true}
                      defaultValue="all"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.trim().toLowerCase())
                      }
                    >
                      <Select.Option value="all">Tất cả trạng thái</Select.Option>
                      {statusShipping.map((status, index) => (
                        <Select.Option value={status.name} key={index}>
                          {status.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                  >
                    <Select
                      value={paramsFilter.shipping_company_id || 'all'}
                      onChange={(value) => _onChangeFilter('shipping_company_id', value)}
                      showSearch
                      defaultValue="all"
                      size={FILTER_SIZE}
                      placeholder="Lọc theo đơn vị vận chuyển"
                      style={{ width: '100%' }}
                      bordered={true}
                    >
                      <Select.Option value="all">Tất cả đơn vị vận chuyển</Select.Option>
                      {shippingCompany?.map((shipping, index) => (
                        <Select.Option value={shipping.shipping_company_id} key={index}>
                          {shipping.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          <Table
            size="small"
            rowKey="order_id"
            loading={loading}
            scroll={{ y: 500 }}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <div
                    style={{
                      paddingTop: 17,
                      paddingBottom: 17,
                      maxHeight: '400px',
                      overflowY: 'scroll',
                    }}
                  >
                    <div className="table-product-in-order">
                      <Table
                        pagination={false}
                        size="small"
                        style={{ width: '100%' }}
                        columns={columnsProduct}
                        dataSource={record.order_details}
                        summary={() => (
                          <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={2}>
                              <div style={{ fontSize: 14.7 }}>
                                <Row wrap={false} justify="space-between">
                                  <div>Tổng tiền ({record.order_details.length} sản phẩm)</div>
                                  <div>
                                    {record.total_cost ? formatCash(+record.total_cost) : 0}
                                  </div>
                                </Row>
                                <Row wrap={false} justify="space-between">
                                  <div>Chiết khấu</div>
                                  <div>
                                    {record.promotion
                                      ? `${formatCash(+(record.promotion.value || 0))} ${
                                          record.promotion.type && record.promotion.type !== 'VALUE'
                                            ? '%'
                                            : ''
                                        }`
                                      : 0}
                                  </div>
                                </Row>
                                <Row wrap={false} justify="space-between">
                                  <div>Phí giao hàng</div>
                                  <div>
                                    {record.shipping_info
                                      ? formatCash(+record.shipping_info.cod || 0)
                                      : 0}
                                  </div>
                                </Row>
                                <Row
                                  wrap={false}
                                  justify="space-between"
                                  style={{ fontWeight: 600 }}
                                >
                                  <div>Khách phải trả</div>
                                  <div>
                                    {record.final_cost ? formatCash(+record.final_cost || 0) : 0}
                                  </div>
                                </Row>
                              </div>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      />
                    </div>
                    <Timeline style={{ marginTop: 30 }}>
                      {record.trackings?.map((e) => {
                        return (
                          <Timeline.Item>
                            <div>
                              <div style={{ fontWeight: 600, fontSize: 18 }}>{e.label}</div>
                              <div style={{ color: '#95a5a6' }}>
                                <div>{e.time_update ? e.time_update : 'Đang cập nhật'}</div>
                              </div>
                            </div>
                          </Timeline.Item>
                        )
                      })}
                    </Timeline>
                  </div>
                )
              },
            }}
            columns={columnsDelivery.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }

              if (column.key === 'code')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'code'),
                  render: (text, record) => 
                    permissions.includes(PERMISSIONS.chi_tiet_don_hang) ? (
                      <ConnectStatus record={record} location={1} />
                    ) : (
                      <div 
                      style={{
                        color:
                          (record.order_status === 'waiting-confirm' && 'black') ||
                          (record.order_status === 'waiting-pickup' && 'black') ||
                          (record.order_status === 'delivering' && 'orange') ||
                          (record.order_status === 'complete' && 'green') ||
                          (record.order_status === 'cancel-order' && 'red') ||
                          (record.order_status === 'refund-order' && '#ff7089'),
                        cursor: 'pointer',
                      }}
                      onClick={() => notification.warning({ message: 'Permission denied!' })}>
                        {record.code}
                      </div>
                    ),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) => text && moment(text).format('DD/MM/YYYY HH:mm'),
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                }
              if (column.key === 'customer')
                return {
                  ...column,
                  render: (text, record) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {record.customer_info && (
                        <span>
                          {record.customer_info.first_name + ' ' + record.customer_info.last_name}
                        </span>
                      )}
                      {record.customer_info && <span>{record.customer_info.phone}</span>}
                    </div>
                  ),
                }

              if (column.key === 'shipping_info')
                return {
                  ...column,
                  render: (text, record) => {
                    return (
                      <div>
                        {record.shipping_info && <span>{record.shipping_info.address}</span>}
                      </div>
                    )
                  },
                  sorter: (a, b) => compare(a, b, 'bill_status'),
                }
              if (column.key === 'ship_status')
                return {
                  ...column,
                  render: (text, record) => {
                    const status = statusShipping.find((s) => s.name === text)
                    if (status == undefined) return
                    var color = 'orange'
                    if (status.name == 'COMPLETE') color = 'success'

                    if (status.name == 'CANCEL') color = 'error'
                    return (
                      <div>
                        <Tag color={color} style={{ width: '100%', textAlign: 'center' }}>
                          {status ? status.label : ''}
                        </Tag>

                        {text == 'DRAFT' && (
                          <>
                            {permissions.includes(PERMISSIONS.cap_nhat_giao_hang) ? (
                              <Popconfirm
                                onConfirm={() => _onCancelOrder(record.order_id)}
                                title="Bạn có muốn huỷ giao đơn này không?"
                                okText="Đồng ý"
                                cancelText="Huỷ"
                              >
                                <Button style={{ width: '100%', color: 'red', marginTop: 10 }}>
                                  {'Huỷ giao'}
                                </Button>
                              </Popconfirm>
                            ) : (
                              <Button
                                disabled
                                style={{ width: '100%', color: 'red', marginTop: 10 }}
                              >
                                {'Huỷ giao'}
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    )
                  },
                  sorter: (a, b) => compare(a, b, 'bill_status'),
                }
              if (column.key === 'tracking_number')
                return {
                  ...column,
                  render: (text, record) =>
                    // permissions.includes(PERMISSIONS.cap_nhat_giao_hang) ? (
                    //   <div>
                    //     <ModalUpdateTrackingNumberOrShipping
                    //       id={record.order_id}
                    //       shipping_company_id={record.shipping_company_id}
                    //       shipping_info={record.shipping_info}
                    //       tracking_number={record.shipping_info?.tracking_number || ''}
                    //       permissions={[PERMISSIONS.cap_nhat_giao_hang]}
                    //     >
                    //       <div style={{ alignSelf: 'center', textAlign: 'center', width: '100%' }}>
                    //         {record.shipping_info?.tracking_number || 'Bấm cập nhật'}
                    //       </div>
                    //     </ModalUpdateTrackingNumberOrShipping>{' '}
                    //     {record.is_auto_create_shipping && (
                    //       <Button
                    //         style={{
                    //           display: 'flex',
                    //           justifyContent: 'center',
                    //           alignItems: 'center',
                    //           textAlign: 'center',
                    //         }}
                    //         onClick={(e) => {
                    //           _onPrintInvoiceTracking(
                    //             record.order_id,
                    //             record.shipping_info.tracking_number
                    //           )
                    //         }}
                    //         type="link"
                    //       >
                    //         {'In vận đơn'}
                    //       </Button>
                    //     )}
                    //   </div>
                    // ) : (
                      record.shipping_info?.tracking_number || 'Bấm cập nhật'
                    // ),
                }
              if (column.key === 'shipping_name')
                return {
                  ...column,
                  render: (text, record) => record?.shipping_company_info?.name,
                  // record.shipping_info && (
                  //   <ModalUpdateTrackingNumberOrShipping
                  //     id={record.order_id}
                  //     shipping={record.shipping_info?.shipping_name || ''}
                  //   >
                  //     {record.shipping_info?.shipping_name || 'Chưa có'}
                  //   </ModalUpdateTrackingNumberOrShipping>
                  // ),
                }
              if (column.key === 'total_cod')
                return {
                  ...column,
                  render: (text, record) =>
                    record.shipping_info && formatCash(record.shipping_info.cod),
                }
              if (column.key === 'fee_shipping')
                return {
                  ...column,
                  render: (text, record) => <>
                    <span>{ record.shipping_info && formatCash(record.shipping_info?.fee_shipping)}{' '}</span>
                    ({record.payer_shipping ? <span>Cửa hàng trả</span> : <span>Khách trả</span>})
                  </>
                   
                }
                if (column.key === 'customer_paid')
                return {
                  ...column,
                  render: (text, record) =>
                    record.payer_shipping ? formatCash(record.shipping_info.cod) : formatCash(record.shipping_info.cod + record.shipping_info?.fee_shipping)
                }
              return column
            })}
            style={{ width: '100%' }}
            pagination={{
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) =>
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
              total: countOrder,
            }}
            dataSource={orders}
            summary={(pageData) => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell>
                    <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                  </Table.Summary.Cell>
                  {columnsDelivery.map((e, index) => {
                    if (e.key === 'total_cod')
                      return (
                        <Table.Summary.Cell>
                          {formatCash(totalCostOrder.total_cod_shipping)}{' '}
                          VND
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'fee_shipping')
                      return (
                        <Table.Summary.Cell>
                          {formatCash(totalCostOrder.total_fee_shipping)}{' '}
                          VND
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'total_price_product')
                      return (
                        <Table.Summary.Cell>
                          {formatCash(totalCostOrder.total_cod_fee)}{' '}
                          VND
                        </Table.Summary.Cell>
                      )
                    return <Table.Summary.Cell></Table.Summary.Cell>
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </>
      ) : null}
    </div>
  )
}
