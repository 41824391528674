import React from 'react'
import { Col, Rate, Row, Space } from 'antd'
import {
  HeartOutlined,
  MessageOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import CommentAndFeedBacks from '../comment-feedbacks'
import UserNewFeedImg from 'assets/img/user-new-feed.png'
import LogoIcon from 'assets/img/store-default.png'
import { SubscriberCard } from 'components/card/card'
import { Button } from 'components/Button/Button'
import styles from './feeds.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getPostsSystem } from 'redux/actions/posts'
import { ImagesPost } from './items/imagesPost'
import { BodyPost } from './cardPost/bodyPost'
import { FooterPost } from './cardPost/footerPost'
import moment from 'moment'

function NewFeeds() {
  const posts = useSelector((state) => state.posts.posts)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPostsSystem())
  }, [])

  return (
    <Row
    justify='center'
    >
      {posts?.map((item, index) => (
        <div
          style={{
            background: '#fff',
            padding: '25px 0',
            marginBottom: '25px',
            borderRadius: 10,
            width: '70%',
          }}
          key={index}
        >
          <div className={styles['container']}>
            <SubscriberCard
              classes="subscriber-feeds"
              name="Benny Kenn"
              description={moment(item?.create_date).fromNow()}
              avatar={{
                status: true,
                image:
                  item?.business?.business_logo?.length > 0 ? item.business.business_logo : LogoIcon,
              }}
            >
              <Button classes="btn__blue" text="+ Theo dõi" />
            </SubscriberCard>
            <div style={{ padding: 10 }}>
              <ImagesPost images={item.products?.images} />
            </div>
          </div>

          <BodyPost item={item} />
          <FooterPost item={item} />

          <CommentAndFeedBacks item={item} />
        </div>
      ))}
    </Row>
  )
}

export default NewFeeds
