import { get, patch, post } from './httpClient'

export const getOrderCarrier = (query) => get('/shipping-control/getOrderCarrier', query)
export const getShippingControls = (query) => get('/shipping-control/getShippingControls', query)
export const getOneShippingControl = (query) => get('/shipping-control/getOneShippingControl', query)
export const createShippingControl = (body) => post('/shipping-control/createShippingControl', body)
export const updateShippingControl = (body) => patch('/shipping-control/updateShippingControl', body)
export const deleteShippingControl = (body) => patch('/shipping-control/deleteShippingControl', body)

export const getRowsExcel = (filePath, start_date, end_date) =>
  post('/shipping-control/createShippingControl/get-rows-excel', { filePath, start_date, end_date })