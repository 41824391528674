import { Avatar, Card, Space, Typography } from 'antd'
import styled from 'styled-components'
import styles from './card.module.scss'

const CardStyled = styled(Card)`
  .ant-card-meta-title {
    margin: 0 !important;
  }

  .ant-card-body {
    padding: 0 !important;
  }

  p {
    margin: 0;
  }

  .ant-avatar-image {
    border-radius: 6px;
  }
`

function SubscriberCard(props) {
  const { avatar, name, description, classes, children } = props
  const { status = false, image } = avatar

  const BASE_CLASS = 'card-subscriber'

  return (
    <Space size={0} className={`${styles[BASE_CLASS]} ${styles[`card-${classes}`]}`}>
      <CardStyled bordered={false}>
        <CardStyled.Meta
          className={`${styles[`${BASE_CLASS}__meta`]} ${styles[`card-${classes}__meta`]}`}
          avatar={
            <div className={styles[`${BASE_CLASS}__avatar-box`]}>
              {status && <i className={styles[`${BASE_CLASS}__avatar-status`]}></i>}
              <Avatar size={50} src={image} className={styles[`${BASE_CLASS}__avatar`]} />
            </div>
          }
          title={
            <p className={`${styles[`${BASE_CLASS}__title`]} ${styles[`card-${classes}__title`]}`}>
              {name}
            </p>
          }
          description={
            <p
              className={`${styles[`${BASE_CLASS}__description`]} ${
                styles[`card-${classes}__description`]
              }`}
            >
              {description}
            </p>
          }
        />
      </CardStyled>
      {children}
    </Space>
  )
}

function EmptyList({ text = 'Danh sách rỗng', classes }) {
  return (
    <Typography.Title className={`${classes} ${styles['card__list-empty']}`}>
      {text}
    </Typography.Title>
  )
}

export { SubscriberCard, EmptyList }
