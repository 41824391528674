import React from 'react'
import { ACTION, ROUTES, PERMISSIONS, LOGO_DEFAULT } from 'consts'
import {
  GoldOutlined,
  DashboardOutlined,
  RollbackOutlined,
  CarOutlined,
  UserAddOutlined,
  RotateLeftOutlined,
  SettingOutlined,
  ControlOutlined,
  SlidersOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ShopOutlined,
  LineChartOutlined,
  CalendarOutlined,
  FileSearchOutlined,
  MedicineBoxOutlined,
  TagOutlined,
  FileProtectOutlined,
  UsergroupAddOutlined,
  DollarOutlined,
} from '@ant-design/icons'

const menu = [
  {
    pathsChild: [],
    path: ROUTES.OVERVIEW,
    title: 'Tổng quan',
    permissions: [PERMISSIONS.tong_quan],
    icon: <DashboardOutlined />,
  },
  {
    pathsChild: [],
    path: ROUTES.SELL,
    title: 'Bán hàng',
    permissions: [PERMISSIONS.ban_hang],
    icon: <ShoppingCartOutlined />,
  },

  {
    pathsChild: [],
    path: 'order-list',
    title: 'Đơn hàng',
    permissions: [
      // đơn hàng
      PERMISSIONS.danh_sach_don_hang,
      PERMISSIONS.don_hang,
      PERMISSIONS.xem_danh_sach_don_hang,
      PERMISSIONS.chi_tiet_don_hang,
      PERMISSIONS.tao_don_hang,
      PERMISSIONS.cap_nhat_don_hang,
      PERMISSIONS.xoa_don_hang,
      PERMISSIONS.export_don_hang,
      //trả hàng
      PERMISSIONS.tra_hang,
      PERMISSIONS.xem_danh_sach_tra_hang,
      PERMISSIONS.xem_chi_tiet_tra_hang,
      PERMISSIONS.tao_phieu_tra_hang,
      PERMISSIONS.cap_nhat_phieu_tra_hang,
      PERMISSIONS.xoa_phieu_tra_hang,
      PERMISSIONS.export_phieu_tra_hang,
      
    ],
    icon: <ShoppingOutlined />,
    menuItems: [
      {
        path: ROUTES.ORDER_LIST,
        title: 'Danh sách đơn hàng',
        permissions: [
          PERMISSIONS.danh_sach_don_hang,
          PERMISSIONS.chi_tiet_don_hang,
          PERMISSIONS.tao_don_hang,
          PERMISSIONS.cap_nhat_don_hang,
          PERMISSIONS.xoa_don_hang,
          PERMISSIONS.export_don_hang,
        ],
        pathsChild: [ROUTES.ORDER_LIST, ROUTES.ORDER_CREATE, ROUTES.ORDER_UPDATE],
      },
      {
        path: ROUTES.REFUND,
        title: 'Trả hàng',
        permissions: [
          PERMISSIONS.tra_hang,
          PERMISSIONS.xem_danh_sach_tra_hang,
          PERMISSIONS.xem_chi_tiet_tra_hang,
          PERMISSIONS.tao_phieu_tra_hang,
          PERMISSIONS.cap_nhat_phieu_tra_hang,
          PERMISSIONS.xoa_phieu_tra_hang,
          PERMISSIONS.export_phieu_tra_hang,
        ],
        pathsChild: [ROUTES.REFUND, ROUTES.REFUND_CREATE, ROUTES.REFUND_UPDATE],
      },
    ],
  },

  {
    pathsChild: [],
    icon: <CalendarOutlined />,
    title: 'Sản phẩm',
    path: 'product',
    permissions: [
      PERMISSIONS.san_pham,
      PERMISSIONS.quan_li_san_pham,
      PERMISSIONS.xem_danh_sach_san_pham,
      PERMISSIONS.them_san_pham,
      PERMISSIONS.chi_tiet_san_pham,
      PERMISSIONS.cap_nhat_san_pham,
      PERMISSIONS.xoa_san_pham,
      PERMISSIONS.export_san_pham,
      PERMISSIONS.in_ma_san_pham,
      PERMISSIONS.mo_ban_san_pham,
      PERMISSIONS.nhom_san_pham,
      PERMISSIONS.tao_nhom_san_pham,
      PERMISSIONS.xem_danh_sach_nhom_san_pham,
      PERMISSIONS.xem_chi_tiet_nhom_san_pham,
      PERMISSIONS.xoa_nhom_san_pham,
      PERMISSIONS.cap_nhat_nhom_san_pham,
      PERMISSIONS.lich_su_thay_doi_gia,
      PERMISSIONS.lich_su_tra_hang,
      PERMISSIONS.lich_su_ban_hang,
      PERMISSIONS.dieu_chinh_gia,
      PERMISSIONS.xem_danh_sach_phieu_dieu_chinh_gia,
      PERMISSIONS.xem_chi_tiet_phieu_dieu_chinh_gia,
      PERMISSIONS.tao_phieu_dieu_chinh,
      PERMISSIONS.cap_nhat_phieu_dieu_chinh,
      PERMISSIONS.export_phieu_dieu_chinh,
    ],
    menuItems: [
      {
        path: ROUTES.PRODUCT,
        title: 'Danh sách sản phẩm',
        permissions: [
          PERMISSIONS.xem_danh_sach_san_pham,
          PERMISSIONS.them_san_pham,
          PERMISSIONS.chi_tiet_san_pham,
          PERMISSIONS.cap_nhat_san_pham,
          PERMISSIONS.xoa_san_pham,
          PERMISSIONS.export_san_pham,
          PERMISSIONS.in_ma_san_pham,
          PERMISSIONS.mo_ban_san_pham,
        ],
        pathsChild: [ROUTES.PRODUCT_UPDATE],
      },
      {
        path: ROUTES.PRODUCT_ADD,
        title: 'Thêm sản phẩm',
        permissions: [PERMISSIONS.them_san_pham],
        pathsChild: [ROUTES.PRODUCT_ADD],
      },
      {
        path: ROUTES.IMPORT_SMART_PRODUCT,
        title: 'Thêm nhanh sản phẩm',
        permissions: [PERMISSIONS.them_san_pham],
        pathsChild: [ROUTES.IMPORT_SMART_PRODUCT],
      },
      {
        path: ROUTES.PRODUCT_BARCODE,
        title: 'In mã vạch',
        permissions: [PERMISSIONS.in_ma_san_pham],
        pathsChild: [ROUTES.PRODUCT_BARCODE],
      },
      {
        pathsChild: [ROUTES.CATEGORY],
        path: ROUTES.CATEGORIES,
        title: 'Nhóm sản phẩm',
        permissions: [
          PERMISSIONS.nhom_san_pham,
          PERMISSIONS.tao_nhom_san_pham,
          PERMISSIONS.xem_danh_sach_nhom_san_pham,
          PERMISSIONS.xem_chi_tiet_nhom_san_pham,
          PERMISSIONS.xoa_nhom_san_pham,
          PERMISSIONS.cap_nhat_nhom_san_pham,
        ],
        icon: <SlidersOutlined />,
      },
      {
        pathsChild: [ROUTES.PRICE_ADJUSTMENTS_CREATE, ROUTES.PRICE_ADJUSTMENTS_UPDATE],
        icon: (
          <svg
            style={{ width: 14, height: 14 }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 416C0 398.3 14.33 384 32 384H86.66C99 355.7 127.2 336 160 336C192.8 336 220.1 355.7 233.3 384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H233.3C220.1 476.3 192.8 496 160 496C127.2 496 99 476.3 86.66 448H32C14.33 448 0 433.7 0 416V416zM192 416C192 398.3 177.7 384 160 384C142.3 384 128 398.3 128 416C128 433.7 142.3 448 160 448C177.7 448 192 433.7 192 416zM352 176C384.8 176 412.1 195.7 425.3 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H425.3C412.1 316.3 384.8 336 352 336C319.2 336 291 316.3 278.7 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H278.7C291 195.7 319.2 176 352 176zM384 256C384 238.3 369.7 224 352 224C334.3 224 320 238.3 320 256C320 273.7 334.3 288 352 288C369.7 288 384 273.7 384 256zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H265.3C252.1 156.3 224.8 176 192 176C159.2 176 131 156.3 118.7 128H32C14.33 128 0 113.7 0 96C0 78.33 14.33 64 32 64H118.7C131 35.75 159.2 16 192 16C224.8 16 252.1 35.75 265.3 64H480zM160 96C160 113.7 174.3 128 192 128C209.7 128 224 113.7 224 96C224 78.33 209.7 64 192 64C174.3 64 160 78.33 160 96z" />
          </svg>
        ),
        path: ROUTES.PRICE_ADJUSTMENTS,
        title: 'Điều chỉnh giá',
        permissions: [
          PERMISSIONS.dieu_chinh_gia,
          PERMISSIONS.xem_danh_sach_phieu_dieu_chinh_gia,
          PERMISSIONS.xem_chi_tiet_phieu_dieu_chinh_gia,
          PERMISSIONS.tao_phieu_dieu_chinh,
          PERMISSIONS.cap_nhat_phieu_dieu_chinh,
          PERMISSIONS.export_phieu_dieu_chinh,
        ],
      },
      {
        path: ROUTES.PRICE_CHANGE_HISTORY,
        title: 'Lịch sử điều chỉnh giá',
        permissions: [PERMISSIONS.lich_su_thay_doi_gia],
        pathsChild: [],
      },
      {
        path: ROUTES.SALE_HISTORY,
        title: 'Lịch sử bán hàng',
        permissions: [PERMISSIONS.lich_su_ban_hang],
        pathsChild: [],
      },
      {
        path: ROUTES.RETURN_HISTORY,
        title: 'Lịch sử trả hàng',
        permissions: [PERMISSIONS.lich_su_tra_hang],
        pathsChild: [],
      },
    ],
  },
  {
    pathsChild: [],
    path: 'price-adjustments',
    title: 'Quản lý kho',
    permissions: [
      PERMISSIONS.quan_li_kho,
      PERMISSIONS.nhap_hang,
      PERMISSIONS.them_phieu_nhap_hang,
      PERMISSIONS.xem_danh_sach_phieu_nhap_hang,
      PERMISSIONS.xem_chi_tiet_phieu_nhap_hang,
      PERMISSIONS.cap_nhat_phieu_nhap_hang,
      PERMISSIONS.xoa_phieu_nhap_hang,
      PERMISSIONS.export_nhap_hang,
      PERMISSIONS.kiem_hang_co_ban,
      PERMISSIONS.xoa_phieu_kiem_hang_co_ban,
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_co_ban,
      PERMISSIONS.them_phieu_kiem_hang_co_ban,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_co_ban,
      PERMISSIONS.can_bang_phieu_kiem_hang_co_ban,
      PERMISSIONS.export_phieu_kiem_hang_co_ban,
      PERMISSIONS.kiem_hang_nang_cao,
      PERMISSIONS.xoa_phieu_kiem_hang_nang_cao,
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_nang_cao,
      PERMISSIONS.them_phieu_kiem_hang_nang_cao,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao,
      PERMISSIONS.can_bang_phieu_kiem_hang_nang_cao,
      PERMISSIONS.export_phieu_kiem_hang_nang_cao,
      PERMISSIONS.phieu_chuyen_hang,
      PERMISSIONS.xem_phieu_chuyen_hang,
      PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang,
      PERMISSIONS.tao_phieu_chuyen_hang,
      PERMISSIONS.cap_nhat_phieu_chuyen_hang,
      PERMISSIONS.xoa_phieu_chuyen_hang,
      PERMISSIONS.export_phieu_chuyen_hang,
    ],
    icon: <SettingOutlined />,
    menuItems: [
      
      {
        icon: <MedicineBoxOutlined />,
        path: ROUTES.IMPORT_INVENTORIES,
        title: 'Nhập hàng',
        permissions: [
          PERMISSIONS.nhap_hang,
          PERMISSIONS.them_phieu_nhap_hang,
          PERMISSIONS.xem_danh_sach_phieu_nhap_hang,
          PERMISSIONS.xem_chi_tiet_phieu_nhap_hang,
          PERMISSIONS.cap_nhat_phieu_nhap_hang,
          PERMISSIONS.xoa_phieu_nhap_hang,
          PERMISSIONS.export_nhap_hang,
        ],
        pathsChild: [],
      },
      {
        path: ROUTES.STOCK_ADJUSTMENTS,
        title: 'Kiểm hàng cơ bản',
        permissions: [
          PERMISSIONS.kiem_hang_co_ban,
          PERMISSIONS.xem_danh_sach_phieu_kiem_hang_co_ban,
          PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_co_ban,
          PERMISSIONS.them_phieu_kiem_hang_co_ban,
          PERMISSIONS.cap_nhat_phieu_kiem_hang_co_ban,
          PERMISSIONS.can_bang_phieu_kiem_hang_co_ban,
          PERMISSIONS.export_phieu_kiem_hang_co_ban,
        ],
        pathsChild: [ROUTES.STOCK_ADJUSTMENTS_CREATE, ROUTES.STOCK_ADJUSTMENTS_UPDATE],
      },
      {
        path: ROUTES.STOCK_ADJUSTMENTS_ADVANCED,
        title: 'Kiểm hàng nâng cao',
        permissions: [
          PERMISSIONS.kiem_hang_nang_cao,
          PERMISSIONS.xem_phieu_kiem_hang_nang_cao,
          PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_nang_cao,
          PERMISSIONS.them_phieu_kiem_hang_nang_cao,
          PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao,
          PERMISSIONS.can_bang_phieu_kiem_hang_nang_cao,
          PERMISSIONS.export_phieu_kiem_hang_nang_cao,
        ],
        pathsChild: [ROUTES.STOCK_ADJUSTMENTS_ADVANCED_UPDATE],
      },
      {
        path: ROUTES.SHIPPING_PRODUCT,
        title: 'Phiếu chuyển hàng',
        permissions: [
          PERMISSIONS.phieu_chuyen_hang,
          PERMISSIONS.xem_phieu_chuyen_hang,
          PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang,
          PERMISSIONS.tao_phieu_chuyen_hang,
          PERMISSIONS.cap_nhat_phieu_chuyen_hang,
          PERMISSIONS.xoa_phieu_chuyen_hang,
          PERMISSIONS.export_phieu_chuyen_hang,
        ],
        pathsChild: [ROUTES.SHIPPING_PRODUCT_ADD],
      },
    ],
  },

  {
    pathsChild: [],
    icon: <GoldOutlined />,
    path: ROUTES.SUPPLIER,
    title: 'Nhà cung cấp',
    permissions: [
      PERMISSIONS.quan_li_nha_cung_cap,
      PERMISSIONS.xem_nha_cung_cap,
      PERMISSIONS.xem_chi_tiet_nha_cung_cap,
      PERMISSIONS.them_nha_cung_cap,
      PERMISSIONS.cap_nhat_nha_cung_cap,
      PERMISSIONS.xoa_nha_cung_cap,
      PERMISSIONS.export_nha_cung_cap,
    ],
  },
  {
    pathsChild: [],
    path: ROUTES.CUSTOMER,
    title: 'Khách hàng',
    permissions: [
      PERMISSIONS.quan_li_khach_hang,
      PERMISSIONS.xem_khach_hang,
      PERMISSIONS.xem_chi_tiet_khach_hang,
      PERMISSIONS.them_khach_hang,
      PERMISSIONS.cap_nhat_khach_hang,
      PERMISSIONS.xoa_khach_hang,
      PERMISSIONS.export_khach_hang,
    ],
    icon: <UserAddOutlined />,
  },
  {
    pathsChild: [
      ROUTES.RECEIPTS_PAYMENT,
      ROUTES.REPORT_INVENTORY,
      ROUTES.REPORT_VARIANT,
      ROUTES.SALES_REPORT,
      ROUTES.REPORT_IMPORT_EXPORT_INVENTORY,
      ROUTES.REPORT_IMPORT_EXPORT_INVENTORY_VARIANT,
      ROUTES.LIST_ORDER_FOLLOW_PAYMENT,
    ],
    path: ROUTES.REPORTS,
    title: 'Báo cáo',
    permissions: [
      PERMISSIONS.danh_sach_bao_cao,
      PERMISSIONS.bao_cao_ton_kho,
      PERMISSIONS.bao_cao_xuat_nhap_ton,
      PERMISSIONS.bao_cao_ton_kho_theo_lo,
      PERMISSIONS.bao_cao_ban_hang_theo_phuong_thuc_thanh_toan,
      PERMISSIONS.bao_cao_ban_hang,
      PERMISSIONS.bao_cao_thu_chi,
      PERMISSIONS.xem_thu_chi,
      PERMISSIONS.them_phieu_thu_chi,
      PERMISSIONS.xoa_phieu_thu_chi,
      PERMISSIONS.cap_nhat_phieu_thu_chi,
      PERMISSIONS.export_phieu_thu_chi,
      PERMISSIONS.loai_phieu_thu_chi,
      PERMISSIONS.xem_loai_phieu_thu_chi,
      PERMISSIONS.them_loai_phieu_thu_chi,
      PERMISSIONS.cap_nhat_loai_phieu_thu_chi,
      PERMISSIONS.xoa_loai_phieu_thu_chi,
      //chốt ca
      PERMISSIONS.chot_ca,
      PERMISSIONS.xem_danh_sach_chot_ca,
      PERMISSIONS.tao_phieu_chot_ca,
    ],
    icon: <LineChartOutlined />,
    menuItems: [
      {
        pathsChild: [],
        path: ROUTES.REPORT_INVENTORY,
        title: 'Tồn kho',
        permissions: [PERMISSIONS.bao_cao_ton_kho],
      },
      {
        pathsChild: [],
        path: ROUTES.REPORT_IMPORT_EXPORT_INVENTORY,
        title: 'Xuất nhập tồn',
        permissions: [PERMISSIONS.bao_cao_xuat_nhap_ton],
      },
      {
        pathsChild: [],
        path: ROUTES.REPORT_INVENTORY_DATE,
        title: 'Tồn kho theo lô',
        permissions: [PERMISSIONS.bao_cao_ton_kho_theo_lo],
      },
      {
        pathsChild: [],
        path: ROUTES.SALES_REPORT,
        title: 'Bán hàng ',
        permissions: [PERMISSIONS.bao_cao_ban_hang],
      },
      {
        pathsChild: [],
        path: ROUTES.LIST_ORDER_FOLLOW_PAYMENT,
        title: 'Bán hàng theo PTTT',
        permissions: [PERMISSIONS.bao_cao_ban_hang_theo_phuong_thuc_thanh_toan],
      },
      {
        pathsChild: [],
        path: ROUTES.RECEIPTS_PAYMENT,
        title: 'Thu chi',
        permissions: [
          PERMISSIONS.bao_cao_thu_chi,
          PERMISSIONS.xem_thu_chi,
          PERMISSIONS.them_phieu_thu_chi,
          PERMISSIONS.xoa_phieu_thu_chi,
          PERMISSIONS.cap_nhat_phieu_thu_chi,
          PERMISSIONS.export_phieu_thu_chi,
          // PERMISSIONS.loai_phieu_thu_chi,
          // PERMISSIONS.xem_loai_phieu_thu_chi,
          // PERMISSIONS.them_loai_phieu_thu_chi,
          // PERMISSIONS.xoa_loai_phieu_thu_chi,
          // PERMISSIONS.cap_nhat_loai_phieu_thu_chi,
        ],
      },
      {
        path: ROUTES.LOCK_SHIFT,
        title: 'Chốt ca',
        permissions: [
          PERMISSIONS.chot_ca,
          PERMISSIONS.xem_danh_sach_chot_ca,
          PERMISSIONS.tao_phieu_chot_ca,
        ],
        pathsChild: [ROUTES.LOCK_SHIFT],
      },
    ],
  },

  {
    pathsChild: [
      ROUTES.GUARANTEE,
      ROUTES.TAX,
      ROUTES.PAYMENT,
      ROUTES.ACTIVITY_DIARY,
      ROUTES.SHIPPING,
      ROUTES.POINT,
      ROUTES.PROMOTION,
      ROUTES.IMPORT_REPORT_FILE,
      ROUTES.ROLE,
      ROUTES.BRANCH_MANAGEMENT,
    ],
    path: ROUTES.CONFIGURATION_STORE,
    title: 'Cấu hình',
    permissions: [
      PERMISSIONS.cau_hinh,
      PERMISSIONS.quan_li_chi_nhanh,
      PERMISSIONS.cau_hinh_chi_nhanh,
      PERMISSIONS.them_chi_nhanh,
      PERMISSIONS.cap_nhat_chi_nhanh,
      PERMISSIONS.cau_hinh_phan_quyen,
      PERMISSIONS.xem_phan_quyen,
      PERMISSIONS.them_vai_tro_phan_quyen,
      PERMISSIONS.cap_nhat_vai_tro_phan_quyen,
      PERMISSIONS.xoa_vai_tro_phan_quyen,
      PERMISSIONS.cau_hinh_hoa_don,
      PERMISSIONS.xem_danh_sach_hoa_don,
      PERMISSIONS.cau_hinh_mau_hoa_don,
      PERMISSIONS.cau_hinh_bao_hanh,
      PERMISSIONS.xem_bao_hang,
      PERMISSIONS.xem_danh_sach_bao_hanh,
      PERMISSIONS.them_bao_hanh,
      PERMISSIONS.cap_nhat_bao_hanh,
      PERMISSIONS.xoa_bao_hanh,
      PERMISSIONS.export_bao_hanh,
      PERMISSIONS.cau_hinh_thue,
      PERMISSIONS.them_thue,
      PERMISSIONS.cap_nhat_thue,
      PERMISSIONS.xoa_thue,
      PERMISSIONS.cau_hinh_phuong_thuc_thanh_toan,
      PERMISSIONS.them_phuong_thuc_thanh_toan,
      PERMISSIONS.cap_nhat_phuong_thuc_thanh_toan,
      PERMISSIONS.xoa_phuong_thuc_thanh_toan,
      PERMISSIONS.cau_hinh_khuyen_mai,
      PERMISSIONS.xem_danh_sach_khuyen_mai,
      PERMISSIONS.xem_chi_tiet_khuyen_mai,
      PERMISSIONS.them_khuyen_mai,
      PERMISSIONS.cap_nhat_khuyen_mai,
      PERMISSIONS.xoa_khuyen_mai,
      PERMISSIONS.cau_hinh_tich_diem,
      PERMISSIONS.cap_nhat_tich_diem,
      PERMISSIONS.xem_danh_sach_khach_hang_tich_diem,
      PERMISSIONS.cau_hinh_nhap_xuat_file,
      PERMISSIONS.cau_hinh_nhat_ki_hoat_dong,
    ],
    icon: <ControlOutlined />,
    menuItems: [
      {
        pathsChild: [],
        icon: <ShopOutlined />,
        path: ROUTES.BRANCH_MANAGEMENT,
        title: 'Chi nhánh',
        permissions: [
          PERMISSIONS.quan_li_chi_nhanh,
          PERMISSIONS.cau_hinh_chi_nhanh,
          PERMISSIONS.xem_chi_nhanh,
          PERMISSIONS.them_chi_nhanh,
          PERMISSIONS.cap_nhat_chi_nhanh,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.ROLE,
        title: 'Phân quyền',
        permissions: [
          PERMISSIONS.cau_hinh_phan_quyen,
          PERMISSIONS.xem_phan_quyen,
          PERMISSIONS.them_vai_tro_phan_quyen,
          PERMISSIONS.cap_nhat_vai_tro_phan_quyen,
          PERMISSIONS.xoa_vai_tro_phan_quyen,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.SETTING_BILL,
        title: 'Cấu hình hóa đơn',
        permissions: [
          PERMISSIONS.cau_hinh_hoa_don,
          PERMISSIONS.xem_danh_sach_hoa_don,
          PERMISSIONS.cau_hinh_mau_hoa_don,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.GUARANTEE,
        title: 'Quản lý bảo hành',
        permissions: [
          PERMISSIONS.xem_danh_sach_bao_hanh,
          PERMISSIONS.xem_chi_tiet_bao_hanh,
          PERMISSIONS.them_bao_hanh,
          PERMISSIONS.cap_nhat_bao_hanh,
          PERMISSIONS.xoa_bao_hanh,
          PERMISSIONS.export_bao_hanh,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.TAX,
        title: 'Quản lý thuế',
        permissions: [
          PERMISSIONS.cau_hinh_thue,
          PERMISSIONS.xem_thue,
          PERMISSIONS.them_thue,
          PERMISSIONS.cap_nhat_thue,
          PERMISSIONS.xoa_thue,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.PAYMENT,
        title: 'Quản lý thanh toán',
        permissions: [
          PERMISSIONS.cau_hinh_phuong_thuc_thanh_toan,
          PERMISSIONS.xem_phuong_thuc_thanh_toan,
          PERMISSIONS.them_phuong_thuc_thanh_toan,
          PERMISSIONS.cap_nhat_phuong_thuc_thanh_toan,
          PERMISSIONS.xoa_phuong_thuc_thanh_toan,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.PROMOTION,
        title: 'Khuyển mãi',
        permissions: [
          PERMISSIONS.cau_hinh_khuyen_mai,
          PERMISSIONS.xem_danh_sach_khuyen_mai,
          PERMISSIONS.xem_chi_tiet_khuyen_mai,
          PERMISSIONS.them_khuyen_mai,
          PERMISSIONS.cap_nhat_khuyen_mai,
          PERMISSIONS.xoa_khuyen_mai,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.POINT,
        title: 'Tích điểm',
        permissions: [
          PERMISSIONS.cau_hinh_tich_diem,
          PERMISSIONS.cap_nhat_tich_diem,
          PERMISSIONS.xem_danh_sach_khach_hang_tich_diem,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.IMPORT_REPORT_FILE,
        title: 'Nhập xuất file',
        permissions: [PERMISSIONS.cau_hinh_nhap_xuat_file],
      },
      {
        pathsChild: [],
        path: ROUTES.ACTIVITY_DIARY,
        title: 'Nhật ký hoạt động',
        permissions: [PERMISSIONS.cau_hinh_nhat_ki_hoat_dong],
      },
    ],
  },

  {
    pathsChild: [],
    path: 'manage-staff',
    title: 'Quản lý nhân viên',
    permissions: [
      PERMISSIONS.quan_li_nhan_vien,
      PERMISSIONS.danh_sach_nhan_vien,
      PERMISSIONS.xem_nhan_vien,
      PERMISSIONS.xem_chi_tiet_nhan_vien,
      PERMISSIONS.them_nhan_vien,
      PERMISSIONS.cap_nhat_nhan_vien,
      PERMISSIONS.xoa_nhan_vien,
      PERMISSIONS.lich_lam_viec,
      PERMISSIONS.xem_danh_sach_lich_lam_viec,
      PERMISSIONS.xem_chi_tiet_lich_lam_viec,
      PERMISSIONS.cau_hinh_ca_lam_viec,
      PERMISSIONS.them_lich_lam_viec,
      PERMISSIONS.cap_nhat_lich_lam_viec,
      PERMISSIONS.xoa_lich_lam_viec,
    ],
    icon: <UsergroupAddOutlined />,
    menuItems: [
      {
        pathsChild: [],
        path: ROUTES.STAFF,
        title: 'Danh sách nhân viên',
        permissions: [
          PERMISSIONS.danh_sach_nhan_vien,
          PERMISSIONS.xem_nhan_vien,
          PERMISSIONS.xem_chi_tiet_nhan_vien,
          PERMISSIONS.them_nhan_vien,
          PERMISSIONS.cap_nhat_nhan_vien,
          PERMISSIONS.xoa_nhan_vien,
        ],
      },
      {
        pathsChild: [],
        path: ROUTES.SCHEDULE_STAFF,
        title: 'Lịch làm việc',
        permissions: [
          PERMISSIONS.lich_lam_viec,
          PERMISSIONS.xem_danh_sach_lich_lam_viec,
          PERMISSIONS.xem_chi_tiet_lich_lam_viec,
          PERMISSIONS.cau_hinh_ca_lam_viec,
          PERMISSIONS.them_lich_lam_viec,
          PERMISSIONS.cap_nhat_lich_lam_viec,
          PERMISSIONS.xoa_lich_lam_viec,
        ],
      },
      {
        path: ROUTES.ROLL_CALL,
        title: 'Ca làm việc',
        permissions: [PERMISSIONS.diem_danh, PERMISSIONS.xem_danh_sach_diem_danh, PERMISSIONS.cap_nhat_diem_danh],
        pathsChild: [ROUTES.ROLL_CALL],
      },
    ],
  },

  {
    pathsChild: [],
    path: ROUTES.SETTING,
    title: 'Cài đặt chung',
    permissions: [],
    icon: <SettingOutlined />,
  },
  {
    pathsChild: [],
    path: 'delivery-control',
    title: 'Vận chuyển',
    permissions: [
      PERMISSIONS.van_chuyen,
      PERMISSIONS.quan_li_giao_hang,
      PERMISSIONS.xem_chi_tiet_phieu_giao_hang,
      PERMISSIONS.them_phieu_giao_hang,
      PERMISSIONS.cap_nhat_giao_hang,
      PERMISSIONS.doi_soat_van_chuyen,
      PERMISSIONS.xem_chi_tiet_phieu_doi_soat_van_chuyen,
      PERMISSIONS.them_phieu_doi_soat_van_chuyen,
      PERMISSIONS.cap_nhat_doi_soat_van_chuyen,
      PERMISSIONS.xoa_phieu_doi_soat_van_chuyen,
      PERMISSIONS.export_phieu_doi_soat_van_chuyen,
      PERMISSIONS.doi_tac_van_chuyen,
      PERMISSIONS.them_doi_tac_van_chuyen,
      PERMISSIONS.cap_nhat_doi_tac_van_chuyen,
      PERMISSIONS.xoa_doi_tac_van_chuyen,
    ],
    icon: <SettingOutlined />,
    menuItems: [
      {
        path: ROUTES.DELIVERY_CONTROL,
        title: 'Giao hàng',
        permissions: [
          PERMISSIONS.quan_li_giao_hang,
          PERMISSIONS.xem_chi_tiet_phieu_giao_hang,
          PERMISSIONS.tao_don_hang,
          PERMISSIONS.cap_nhat_giao_hang,
        ],
        icon: (
          <svg
            style={{ width: 14, height: 14 }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M112 0C85.49 0 64 21.49 64 48V96H16C7.163 96 0 103.2 0 112C0 120.8 7.163 128 16 128H272C280.8 128 288 135.2 288 144C288 152.8 280.8 160 272 160H48C39.16 160 32 167.2 32 176C32 184.8 39.16 192 48 192H240C248.8 192 256 199.2 256 208C256 216.8 248.8 224 240 224H16C7.163 224 0 231.2 0 240C0 248.8 7.163 256 16 256H208C216.8 256 224 263.2 224 272C224 280.8 216.8 288 208 288H64V416C64 469 106.1 512 160 512C213 512 256 469 256 416H384C384 469 426.1 512 480 512C533 512 576 469 576 416H608C625.7 416 640 401.7 640 384C640 366.3 625.7 352 608 352V237.3C608 220.3 601.3 204 589.3 192L512 114.7C499.1 102.7 483.7 96 466.7 96H416V48C416 21.49 394.5 0 368 0H112zM544 237.3V256H416V160H466.7L544 237.3zM160 464C133.5 464 112 442.5 112 416C112 389.5 133.5 368 160 368C186.5 368 208 389.5 208 416C208 442.5 186.5 464 160 464zM528 416C528 442.5 506.5 464 480 464C453.5 464 432 442.5 432 416C432 389.5 453.5 368 480 368C506.5 368 528 389.5 528 416z" />
          </svg>
        ),
        pathsChild: [],
      },
      {
        path: ROUTES.SHIPPING_CONTROL,
        title: 'Đối soát V/C',
        permissions: [
          PERMISSIONS.doi_soat_van_chuyen,
          PERMISSIONS.xem_doi_soat_van_chuyen,
          PERMISSIONS.xem_chi_tiet_phieu_doi_soat_van_chuyen,
          PERMISSIONS.them_phieu_doi_soat_van_chuyen,
          PERMISSIONS.cap_nhat_doi_soat_van_chuyen,
          PERMISSIONS.xoa_phieu_doi_soat_van_chuyen,
          PERMISSIONS.export_phieu_doi_soat_van_chuyen,
        ],
        icon: <CarOutlined />,
        pathsChild: [ROUTES.SHIPPING_CONTROL_ADD],
      },
      {
        path: ROUTES.SHIPPING,
        title: 'Đối tác V/C',
        permissions: [
          PERMISSIONS.doi_tac_van_chuyen,
          PERMISSIONS.xem_doi_tac_van_chuyen,
          PERMISSIONS.them_doi_tac_van_chuyen,
          PERMISSIONS.cap_nhat_doi_tac_van_chuyen,
          PERMISSIONS.xoa_doi_tac_van_chuyen,
        ],
        pathsChild: [],
      },
    ],
  },
]

export default menu
