import { getRowsExcel } from 'apis/import-stock-adjustment'
import React, { useEffect, useState } from 'react'
import styles from '../import-stock.module.css'
import { useDispatch } from 'react-redux'

//antd
import { Button, Col, Row, Table, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ACTION } from 'consts'

export default function Step2({ filePath, gotoStep, current, submit, exit,id }) {
  const [excelRows, setExcelRows] = useState([])
  const [skuArr, setSkuArr] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const sharedOnCell = (_) => {
    if (_.error === '{ERROR}') {
      return {
        colSpan: 0,
      }
    }

    return {}
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 60,
      colSpan: 1,
      align: 'center',
      render: (text, record) => (record.error === '{ERROR}' ? '' : text),
    },
    {
      title: 'Mã sản phẩm (*)',
      dataIndex: 'sku',
      onCell: (_) => ({
        colSpan: _.error === '{ERROR}' ? 6 : 1,
      }),
      render: (text, record) =>
        record.error === '{ERROR}' ? (
          <span style={{ color: '#ff9800' }}>{record.error_msg}</span>
        ) : (
          text
        ),
    },
    {
      title: 'Mã phiên bản (*)',
      dataIndex: 'variant_sku',
      onCell: sharedOnCell,
    },
    {
      title: 'Số lượng thực tế (*)',
      dataIndex: 'real_quantity',
      onCell: sharedOnCell,
    },
    {
      title: 'Mã lô ',
      dataIndex: 'location_check',
      onCell: sharedOnCell,
    },
    {
      title: 'Serial/Imei',
      dataIndex: 'imei_check',
      onCell: sharedOnCell,
    },
    {
      title: 'Lý do chênh lệch',
      dataIndex: 'note',
      onCell: sharedOnCell,
    },
  ]
  async function _getRowsExcel(filePath,id) {
    dispatch({ type: ACTION.LOADING, data: true })
    setTimeout(async () => {
      setLoading(true)
      let res = await getRowsExcel(filePath, id)
      if (res.status == 200) {
        setSkuArr(res.data.skuArr)
        setExcelRows(res.data.data)
      }
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
    }, 5000)
  }

  async function submitProcessImportProduct() {
    exit(skuArr)
    gotoStep(2)
  }

  useEffect(() => {
    _getRowsExcel(filePath, id)
  }, [filePath])

  return (
    <div className={styles['step-2']}>
      <div className={styles['step-2-header']}>
        <div className={styles['step-container']}>
          <Row justify="center" className={styles['group-button']}>
            <Col style={{ marginRight: 10 }}>
              <Button type="default" onClick={() => submit({ step: 0 })}>
                Quay lại
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={submitProcessImportProduct}>
                Thực hiện Import
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        {loading === true ? (
          <></>
        ) : (
          <>
            <p style={{ color: 'red' }}>
              Dưới đây là dữ liệu của 300 dòng đầu tiên trong file excel bạn vừa chọn. Vui lòng kiểm
              tra và xác nhận. Những dữ liệu cảnh báo sẽ không được thêm vào
            </p>
            <Table
              rowKey="phone"
              scroll={{ x: 400, y: 450 }}
              columns={columns}
              dataSource={excelRows}
              size="large"
              pagination={false}
            />
          </>
        )}
      </div>
    </div>
  )
}
