import { useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { PERMISSIONS } from 'consts'
import styles from '../schedule-staff.module.scss'
// antd
import { Row,  notification,  List, Space, Popconfirm } from 'antd'
import moment from 'moment'
import { getRolesPermission } from 'redux/actions/role'

//apis
import { getRoles } from 'apis/role'
import { getShift,deleteShift } from 'apis/schedule-staff'

//component
import { Button } from 'components/Button/Button'
import Permission from 'components/permission'
import PlusCircleIcon from 'assets/icons/plus-circle.svg'
import RecycleIcon from 'assets/icons/recycle-schedule.svg'
import ShiftFormCreate from './shift'
import ShiftUpdate from './updateShift'
import CloseIcon from 'assets/icons/close.svg'
import Modal from 'components/Modal'

export default function ShiftList() {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [shift, setShift] = useState([])
  const roles = useSelector((state) => state.role)

  const [loadingShift, setLoadingShift] = useState(false)
  const dispatch = useDispatch()
 
  const _getShift = async () => {
    try {
        setLoadingShift(true)
      const res = await getShift({ branch_id: branchIdApp })
      if (res.status === 200) {
        setShift(res.data.data)
      }
      setLoadingShift(false)
    } catch (error) {
      setLoadingShift(false)
      console.log(error)
    }
  }
  const _deleteShift = async (id) => {
    try {
      const res = await deleteShift(id)
      if (res.status === 200) {
        notification.success({ message: 'Xóa ca thành công!' })
        _getShift()
      } else
        notification.error({
          message: res.data.message || 'Xóa ca thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
    }
  }

  const ModalShiftCreate = ({ children,getShift, shifts }) => {
  const [visibleShift, setVisibleShift] = useState(false)

    const toggleShift = () =>{
        setVisibleShift(!visibleShift)
    }
    return (
      <>
        <div onClick={toggleShift} style={{width:'100%'}}>{children}</div>
        <Modal
          onCancel={() =>{
            getShift()
            toggleShift()
          } }
          footer={null}
          title="Thêm ca"
          visible={visibleShift}
          closeIcon={<img src={CloseIcon} alt="" />}
          width="40%"
        >
          <ShiftFormCreate getShift={getShift} close={toggleShift} shifts={shifts}/>
        </Modal>
      </>
    )
  }
  const ModalShiftUpdate = ({ children, record,getShift, shifts }) => {
    const [visibleShiftUpdate, setVisibleShiftUpdate] = useState(false)

  const toggleShiftUpdate = () => setVisibleShiftUpdate(!visibleShiftUpdate)
      return (
        <>
          <div onClick={toggleShiftUpdate} style={{width:'100%'}}>{children}</div>
          <Modal
            onCancel={toggleShiftUpdate}
            footer={null}
            title={`${record && record?.title}`}
            visible={visibleShiftUpdate}
            closeIcon={<img src={CloseIcon} alt="" />}
            width="40%"
          >
            <ShiftUpdate record={record} getShift={getShift} close={toggleShiftUpdate} shifts={shifts}/>
          </Modal>
        </>
      )
    }
  useEffect(() => {
    _getShift()
      dispatch(getRolesPermission({ branch_id: branchIdApp }))
  
  }, [branchIdApp])
  return (
     <>
     <List
        style={{overflow: 'auto', height: '600px'}}
        itemLayout="horizontal"
        dataSource={shift}
        renderItem={(item) => (
          <List.Item>
            <Space className={styles['btnShift']} >
            <ModalShiftUpdate record={item} getShift={_getShift}  shifts={shift}>
              <div>
                <h2 className={styles.btnShift__title}>{item.title}</h2>
                <h3 className={styles.btnShift__time}>
                  {moment(item.time_start).format('HH:mm')} -{' '}
                  {moment(item.time_end).format('HH:mm')}
                </h3>
              </div>
            </ModalShiftUpdate>
              <div style={{ width: 200 }}>
                {item.role.lenght === 0 ? null :
              <span>
                Gồm bộ phận:{' '}
              {item.role.map((_role) =>
                  roles.map(
                    (roles) =>
                      roles.role_id == _role.role_id && (
                        <span className={styles.btnShift__info}>
                          {roles.name}: ({_role.total} người), 
                        </span>
                      )
                  )
                )}
            </span>
        }
              </div>
              <Permission permissions={[PERMISSIONS.xoa_lich_lam_viec]}>
                <Popconfirm
                  title="Bạn có muốn xóa ca này không?"
                  cancelText="Từ chối"
                  okText="Đồng ý"
                  onConfirm={() => _deleteShift(item.shift_id)}
                >
                  <Button type="button" icon={() => <img src={RecycleIcon} alt="" />} />
                </Popconfirm>
              </Permission>
            </Space>
            
          </List.Item>
        )}
      />
      <Row style={{ width: '100%', marginTop: '50px' }}>
        <ModalShiftCreate getShift={_getShift} shifts={shift}>
        <Button
              type="submit"
              text=" Thêm ca"
              classes="btn__blue-fill"
              loading={loadingShift}
              icon={() => <img src={PlusCircleIcon} alt="" />}
              style={{
                display: 'inline-block',
                padding: '6px 12px',
                fontSize: '14px',
                fontWeight: 500,
              }}
            />
        </ModalShiftCreate>
      </Row>
     </>
  )
}
