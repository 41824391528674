import { notification } from 'antd'

export const syncPrice = (valuePrice, valueTypePrice, productChange, setProductChange) => {
  if ((valueTypePrice != 4 && !valuePrice) || !valueTypePrice)
    return notification.error({
      key: 'invalid data',
      message: 'Vui lòng chọn chế độ giảm và nhập giá trị giảm giá!',
    })

  let new_data = [...productChange]

  // eslint-disable-next-line default-case
  switch (valueTypePrice) {
    //fil all to price
    case 1: new_data = new_data.map(item => ({
        ...item, 
        new_price: valuePrice || 0
    }))
      break
    //fil all to %price
    case 2: new_data = new_data.map(item => ({
        ...item, 
        new_price: ((item.price - (item.price*valuePrice/100)) > 0 ? (item.price - (item.price*valuePrice/100)) : 0) || 0
    }))
      break
    //fil all -price
    case 3: new_data = new_data.map(item => ({
        ...item, 
        new_price: (item.price - valuePrice > 0 ? (item.price - valuePrice) : 0) || 0
    }))
      break
    //fil all old price 
    case 4: new_data = new_data.map(item => ({
        ...item, 
        new_price: item.default_price || 0
    }))
      break
    }
    setProductChange(new_data)
}
