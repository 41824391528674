const initialState = []
const shipping_company = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHIPPING_COMPANY': {
      state = action.data
      return [...state]
    }
    default:
      return state
  }
}
export default shipping_company
