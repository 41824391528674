import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

//apis
import { createFileHistory } from 'apis/action'
import { uploadFileToExport } from 'apis/upload'
import { ACTION } from 'consts'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'


const exportToCSV = async (csvData, fileName, actionName = 'Xuất file excel', dispatch) => {
  dispatch({ type: ACTION.LOADING, data: true })

  const ws = XLSX.utils.json_to_sheet(csvData)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })

  const url = await uploadFileToExport(data, fileName + fileExtension)
  const body = {
    action_name: actionName,
    file_name: fileName + fileExtension,
    type: 'EXPORT',
    property: '',
    links: [url],
  }
  await createFileHistory(body)

  FileSaver.saveAs(data, fileName + fileExtension)
  dispatch({ type: ACTION.LOADING, data: false })

}
export default exportToCSV
