import moment from 'moment'
import {
  Select,
  Row,
  Col,
  notification,
  Button,
  Input,
  Form,
  InputNumber,
  Space,
  Upload,
  DatePicker,
  Switch,
  Radio,
} from 'antd'
import TitlePage from 'components/title-page'
import {
  PlusOutlined,
  ArrowLeftOutlined,
  ReloadOutlined,
} from '@ant-design/icons'

import React, { useEffect, useState, useRef } from 'react'
import styles from './add.module.scss'
import { ROUTES } from 'consts'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Apis
import { addPromotion, updatePromotion, getPromotions } from 'apis/promotion'
import { removeAccents, removeUnicode } from 'utils'
import { uploadFile } from 'apis/upload'
import { getCustomers, getCustomerTypes, addCustomerType } from 'apis/customer'

//components
import exportToCSV from 'components/ExportCSV/export'
import { getCategories } from 'apis/category'
import {getProductsSystem} from 'redux/actions/products'
//language
const { Option } = Select

export default function PromotionAdd({ children, reload, record }) {
  const { products } = useSelector((state) => state.products)
  const dispatch = useDispatch()
  const history = useHistory()
  const [showVoucher, setShowVoucher] = useState('show')
  const [form] = Form.useForm()
  const [checkedVoucher, setCheckedVoucher] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [imageView, setImageView] = useState('')
  const [fileUpload, setFileUpload] = useState(null)
  const [customers, setCustomers] = useState([])
  const [customerTypes, setCustomerTypes] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [type, setType] = useState('')
  const [loadingBtn, setLoadingBtn] = useState(false)
  const typingTimeoutRef = useRef(null)
  const [checkValue, setCheckValue] = useState('VALUE')
  const [typeVoucher, setTypeVoucer] = useState(1)

  const [productIds, setProductIds] = useState([])
  const [productTypeIds, setProductTypeIds] = useState([])
  const [customerIds, setCustomerIds] = useState([])
  const [customerTypeIds, setCustomerTypeIds] = useState([])
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [checkPromotion, setCheckPromotion] = useState([])
  const [input, setInput] = useState(true)
  const [checkPublic, setCheckPublic] = useState(false)
  const [dateStart, setDateStart] = useState()
  const [dateEnd, setDateEnd] = useState()

  const toggle = () => {
    setIsVisible(!isVisible)
  }
  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const _createType = async () => {
    try {
      if (!type) {
        notification.warning({ message: 'Vui lòng nhập loại khách hàng' })
        return
      }
      setLoadingBtn(true)
      let body ={
         name: type 
      }
      const res = await addCustomerType(body)
      if (res.status === 200) {
        if (res.data.success) {
          await _getCustomerTypes()
          notification.success({ message: 'Tạo loại khách hàng thành công' })
          setType('')
        } else
          notification.error({
            message: res.data.message || 'Tạo loại khách hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Tạo loại khách hàng thất bại, vui lòng thử lại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
    }
  }
  const _getCustomerTypes = async () => {
    try {
      const res = await getCustomerTypes()
      if (res.status === 200) setCustomerTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getProductTypes = async () => {
    try {
      const res = await getCategories()
      if (res.status === 200) setProductTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getCustomerTypes()
    _getProductTypes()
  }, [])
  const _getPromotionsToExport = async () => {
    try {
      const res = await getPromotions()
      if (res.status === 200) {
        let dataExport = []
        res.data.data.map((e) => {
          dataExport.push({
            'Mã khuyến mãi': e.promotion_code || '',
            'Tên khuyến mãi': e.name || '',
            'Điều kiện áp dụng': e.order_value_require || '',
            'Loại khuyến mãi': e.type === 'VALUE' ? 'Giá trị' : 'Phần trăm',
            'Giá trị khuyến mãi': e.value,
            'Giá trị cảnh báo': e.warning_value,
            'Số lượng khuyến mãi':
              e.limit_quantity === 0 ? 'Không giới hạn số lượng' : e.limit_quantity,
            'Thời hạn khuyến mãi': e.end_date,
          })
        })
        exportToCSV(dataExport, 'Danh sách khuyến mãi','Xuất file excel',dispatch)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getPromotion = async () => {
    try {
      const res = await getPromotions()
      if (res.status === 200) {
        setCheckPromotion(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onFinish = async (values) => {
    if (checkPromotion.find((item) => item.promotion_code === values.promotion_code)) {
      return notification.error({ message: 'Mã khuyến mãi đã tồn tại vui lòng tạo mã mới' })
    }
    if (checkPromotion.find((item) => item.name === values.name)) {
      return notification.error({ message: 'Tên khuyến mãi đã tồn tại vui lòng tạo tên mới' })
    }
    if (values.warning_value > values.order_value_require) {
      return notification.error({ message: 'Giá trị cảnh báo không được lớn hơn hạn mức áp dụng' })
    }
    if (values.value > values.order_value_require) {
      return notification.error({
        message: 'Giá trị khuyến mãi không được lớn hơn hạn mức áp dụng',
      })
    }
    if (values.value > values.order_value_require) {
      return notification.error({
        message: 'Giá trị khuyến mãi không được lớn hơn hạn mức áp dụng',
      })
    }
    if (values.type === 'PERCENT' && values.value > 100) {
      return notification.error({ message: 'Giá trị khuyến mãi phần trăm không được lớn hơn 100%' })
    }
    try {
      const image = await uploadFile(fileUpload)
      const obj = {
        name: values.name,
        image: image || imageView || '',
        branch_id: branchIdApp,
        promotion_code: values.promotion_code,
        type: values.type,
        value: values.value,
        has_voucher: showVoucher === 'show',
        limit: {
          amount: values.amount ? parseInt(values.amount) : 0,
          stores: values.store ? values.store : [],
        },
        limit_quantity: values.limit_quantity,
        order_value_require: values.order_value_require || '',
        max_discount_value: values.max_discount_value || '',
        warning_value: values.warning_value || 0,
        description: values.description || '',
        start_date: values.start_date || null,
        end_date: values.end_date || null,
        apply_customer_type_ids: typeVoucher === 2 ? customerTypeIds : [],
        apply_customer_ids: typeVoucher === 2 ? customerIds : [],
        apply_product_type_ids: typeVoucher === 3 ? productTypeIds : [],
        apply_product_ids: typeVoucher === 3 ? productIds : [],
        type_voucher: typeVoucher,
        status: checkPublic === true ? 1 : 0,
      }
      let res = await addPromotion(obj)
      // else res = await updatePromotion(record.promotion_id, obj)
      if (res.status === 200) {
        toggle()
        form.resetFields()
        if (checkedVoucher === true) {
          _getPromotionsToExport()
        }
        notification.success({
          key: 'promotion_success',
          message: res.data.message || 'Thêm khuyến mãi thành công',
        })
        history.goBack()
      } else {
        notification.warning({
          key: 'promotion_fail',
          message: res.data.message || 'Thêm khuyến mãi không thành công',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
  const generateCode = (value) => {
    let tmp = value.toUpperCase()
    tmp = tmp.replace(/\s/g, '')
    tmp = removeAccents(tmp)
    return tmp
  }
 
  const _getCustomer = async () => {
    try {
      const res = await getCustomers()
      if (res.status === 200) {
        setCustomers(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getCustomer()
    _getPromotion()
    dispatch(getProductsSystem({branchIdApp: branchIdApp}))
  }, [branchIdApp])
 
  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        start_date: record.start_date !== '' && moment(record.start_date),
        end_date: record.end_date !== '' && moment(record.end_date),
      })
    } else {
      form.resetFields()
    }
  }, [form, record])

  const sharedProps = {
    style: { width: '100%' },
  }
  return (
    <div className="card">
      <div onClick={toggle}>{children}</div>
      <TitlePage
        isAffix={true}
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.PROMOTION)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            <div>{'Thêm mới khuyến mãi'}</div>
          </Row>
        }
      >
        <Space>
          <Button
            icon={<ReloadOutlined />}
            style={{ display: !record && 'none' }}
            size="large"
            onClick={() => history.go(0)}
          >
            Tải lại
          </Button>
          
        </Space>
      </TitlePage>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row style={{backgroundColor:'white', borderRadius:5, margin:'5px 0', padding:5, border:'solid 1px rgba(184, 184, 184, 0.486) '}}>
          <Col span={21} >
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 10 }}>
              <p
                className={styles['promotion-add__title']}
                style={{ marginBottom: 0, marginRight: 8 }}
              >
                Bật chia sẻ voucher
              </p>
              <Switch
                style={{ marginTop: 4 }}
                defaultChecked={false}
                onChange={() => {
                  setCheckPublic(!checkPublic)
                }}
              />
            </div>
            <div>Chia sẻ voucher thì mọi người dùng trên hệ thống Ekata sẽ thấy được voucher</div>
          </Col>
          <Col span={3}>
            <Button className={styles['promotion_add_button']} size="large" type="primary" htmlType="submit" >
                  {!record ? 'Thêm khuyến mãi' : 'Lưu'}
          </Button>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={12}>
            <div className={styles['promotion-add__box']}>
              <div
                style={{ textAlign: 'center', fontSize: '20px' }}
                className={styles['promotion-add__title']}
              >
                Thông tin khuyến mãi
              </div>
              <Form.Item
                className={styles['promotion-add__title']}
                name="name"
                rules={[{ required: true, message: 'Vui lòng nhập tên khuyến mãi' }]}
                label="Tên chương trình khuyến mãi"
              >
                <Input
                  placeholder="Nhập tên khuyến mãi"
                  size="large"
                  onChange={(e) => {
                    form.setFieldsValue({
                      promotion_code: generateCode(e.target.value),
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                className={styles['promotion-add__title']}
                name="promotion_code"
                rules={[{ required: true, message: 'Vui lòng nhập mã khuyến mãi' }]}
                label="Mã khuyến mãi"
              >
                <Input placeholder="Nhập mã khuyến mãi" size="large" />
              </Form.Item>
              <Form.Item
                className={styles['promotion-add__title']}
                name="limit_quantity"
                rules={[{ required: true, message: 'Vui lòng nhập số lượng khuyến mãi' }]}
                label="Số lượng khuyến mãi"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Nhập số lượng khuyến mãi"
                  size="large"
                  defaultValue={0}
                  min={0}
                />
              </Form.Item>

              <Row gutter={20}>
                <Col span={6}>
                  <div style={{ fontWeight: 'bold', font: '16px', marginBottom: '10px' }}>
                    Hình ảnh
                  </div>
                  <Upload
                    fileList={[]}
                    name="avatar"
                    listType="picture-card"
                    className="upload-category-image"
                    showUploadList={false}
                    onChange={(info) => {
                      const isJpgOrPng =
                        info.file.type === 'image/jpeg' ||
                        info.file.type === 'image/png' ||
                        info.file.type === 'image/jpg'

                      const isLt2M = info.file.size / 1024 / 1024 < 2

                      if (!isJpgOrPng) {
                        notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
                      }

                      if (!isLt2M) {
                        notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
                      }

                      if (isJpgOrPng && isLt2M) {
                        if (typingTimeoutRef.current) {
                          clearTimeout(typingTimeoutRef.current)
                        }
                        typingTimeoutRef.current = setTimeout(async () => {
                          if (info.file.status !== 'done') info.file.status = 'done'
                          getBase64(info.file.originFileObj, (imageUrl) => setImageView(imageUrl))
                          setFileUpload(info.file.originFileObj)
                        }, 450)
                      }
                    }}
                  >
                    {imageView ? (
                      <img
                        src={imageView}
                        alt="avatar"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Tải lên</div>
                      </div>
                    )}
                  </Upload>
                </Col>
                <Col span={18}>
                  <div className={styles['promotion-add__title']}>Mô tả</div>
                  <Form.Item name="description">
                    <Input.TextArea style={{ height: 130 }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className={styles['promotion-add__title']}>Thời gian áp dụng</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%' }}>
                    <div> Thời gian bắt đầu</div>
                    <Form.Item name="start_date">
                      <DatePicker
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss'),
                        }}
                        format="YYYY/MM/DD HH:mm:ss"
                        size="large"
                        style={{ width: '100%', marginTop: '7px' }}
                        onChange={(dates, dateStrings) => {
                          setDateStart(dateStrings)
                        }}
                        disabledDate={(current) => {
                          return current && current > moment(dateEnd).endOf('day')
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ width: '50%', marginLeft: '10px' }}>
                    {' '}
                    <div> Thời gian kết thúc</div>
                    <Form.Item name="end_date">
                      <DatePicker
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss'),
                        }}
                        format="YYYY/MM/DD HH:mm:ss"
                        size="large"
                        style={{ width: '100%', marginTop: '7px' }}
                        onChange={(dates, dateStrings) => {
                          setDateEnd(dateStrings)
                        }}
                        disabledDate={(current) => {
                          return current && current < moment(dateStart).endOf('day')
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Row>

              <div style={{ display: 'flex', marginTop: 20, marginBottom: 10 }}>
                <p
                  className={styles['promotion-add__title']}
                  style={{ marginBottom: 0, marginRight: 8 }}
                >
                  Bật cảnh báo
                </p>
                <Switch
                  style={{ marginTop: 4 }}
                  checked={!input}
                  onChange={() => {
                    setInput(!input)
                  }}
                />
              </div>
              {!input ? (
                <div>
                  <div>Vui lòng nhập giá trị mà đơn hàng cần đạt đến để hiển thị thông báo.</div>
                  <Form.Item name="warning_value">
                    <InputNumber
                      style={{ width: '100%', borderRadius: 15 }}
                      placeholder="Giá trị nhắc nhở"
                      size="large"
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      {...sharedProps}
                    />
                  </Form.Item>
                </div>
              ) : (
                ''
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className={styles['promotion-add__box']}>
              <div
                style={{ textAlign: 'center', fontSize: '20px' }}
                className={styles['promotion-add__title']}
              >
                Điều kiện áp dụng
              </div>

              <div className={styles['promotion-add__title']}>Loại điều kiện áp dụng</div>
              <Radio.Group
                onChange={(e) => {
                  setTypeVoucer(e.target.value)
                }}
                value={typeVoucher}
              >
                <Radio value={1}>Mặc định</Radio>
                <Radio value={2}>Theo khách hàng</Radio>
                <Radio value={3}>Theo sản phẩm</Radio>
              </Radio.Group>

              {typeVoucher === 2 && (
                <div>
                  <div style={{ marginTop: '20px' }} className={styles['promotion-add__title']}>
                    Đối tượng áp dụng
                  </div>

                  <Row gutter={20}>
                    <Col span={12}>
                      <div>Loại khách hàng</div>
                      <Form.Item name="apply_customer_type_ids">
                        <Select
                          style={{ width: '100%', marginTop: '7px' }}
                          allowClear
                          mode="multiple"
                          showSearch
                          placeholder="Chọn loại khách hàng"
                          size="large"
                          dropdownRender={(menu) => (
                            <div>
                              {menu}
                              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                <Input
                                  value={type}
                                  onPressEnter={_createType}
                                  placeholder="Nhập loại khách hàng mới"
                                  onChange={(e) => setType(e.target.value)}
                                />
                                <Button
                                  loading={loadingBtn}
                                  onClick={_createType}
                                  type="primary"
                                  style={{ marginLeft: 10 }}
                                >
                                  Tạo mới
                                </Button>
                              </div>
                            </div>
                          )}
                          onChange={(e) => {
                            setCustomerTypeIds(e)
                          }}
                          filterOption={(input, option) =>
                            removeUnicode(option.key.toString())
                              .toLowerCase()
                              .trim()
                              .includes(removeUnicode(input).toLowerCase().trim())
                          }
                        >
                          {customerTypes.map((type, index) => (
                            <Option key={type.name} value={type.type_id}>{type.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div>Khách hàng</div>
                      <Form.Item name="apply_customer_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%', marginTop: '7px' }}
                          allowClear
                          placeholder="Chọn khách hàng"
                          size="large"
                          dropdownRender={(menu) => <div>{menu}</div>}
                          onChange={(e) => {
                            setCustomerIds(e)
                          }}
                          filterOption={(input, option) =>
                            removeUnicode(option.key.toString())
                              .toLowerCase()
                              .trim()
                              .includes(removeUnicode(input).toLowerCase().trim())
                          }
                        >
                          {customers.map((customer, index) => (
                            <Option key={customer.phone} value={customer.customer_id}>{customer.phone}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}

              {typeVoucher === 3 && (
                <div>
                  <div style={{ marginTop: '20px' }} className={styles['promotion-add__title']}>
                    Đối tượng áp dụng
                  </div>

                  <Row gutter={20}>
                    <Col span={12}>
                      <div>Loại Sản phẩm</div>
                      <Form.Item name="apply_product_type_ids">
                        <Select
                          style={{ width: '100%', marginTop: '7px' }}
                          allowClear
                          mode="multiple"
                          showSearch
                          placeholder="Chọn loại sản phẩm"
                          size="large"
                          dropdownRender={(menu) => <div>{menu}</div>}
                          onChange={(e) => {
                            setProductTypeIds(e)
                          }}
                          filterOption={(input, option) =>
                            removeUnicode(option.key.toString())
                              .toLowerCase()
                              .trim()
                              .includes(removeUnicode(input).toLowerCase().trim())
                          }
                        >
                          {productTypes.map((type, index) => (
                            <Option key={type.category_id} value={type.category_id}>{type.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div>Sản phẩm</div>
                      <Form.Item name="apply_product_ids">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%', marginTop: '7px' }}
                          allowClear
                          placeholder="Chọn sản phẩm"
                          size="large"
                          dropdownRender={(menu) => <div>{menu}</div>}
                          onChange={(e) => {
                            setProductIds(e)
                          }}
                          filterOption={(input, option) =>
                            removeUnicode(option.key.toString())
                              .toLowerCase()
                              .trim()
                              .includes(removeUnicode(input).toLowerCase().trim())
                          }
                        >
                          {products.map((product, index) => (
                            <Option key={product.title} value={product.variant_id}>{product.title}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}

              <div style={{ marginTop: '20px' }} className={styles['promotion-add__title']}>
                Tùy chọn khuyến mãi
              </div>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="type" initialValue="VALUE" label="Loại khuyến mãi">
                    <Select
                      onClick={(e) => {
                        if (e.target.outerText === 'Phần trăm') {
                          setCheckValue('PERCENT')
                        } else {
                          setCheckValue('VALUE')
                        }
                      }}
                      placeholder="Chọn loại khuyến mãi"
                      size="large"
                    >
                      <Option value="VALUE">Giá trị</Option>
                      <Option value="PERCENT">Phần trăm</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="value" label="Giá trị khuyến mãi">
                    <InputNumber
                      placeholder="Nhập giá trị khuyến mãi"
                      size="large"
                      max={checkValue === 'PERCENT' && 100}
                      min={0}
                      style={{ width: '100%', borderRadius: '15px' }}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="order_value_require" label="Hạn mức áp dụng">
                    <InputNumber
                      style={{ width: '100%', borderRadius: 15 }}
                      size="large"
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                {checkValue === 'PERCENT' && (
                  <Col span={12}>
                    <Form.Item name="max_discount_value" label="Khuyến mãi tối đa">
                      <InputNumber
                        style={{ width: '100%', borderRadius: 15 }}
                        size="large"
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
