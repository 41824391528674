import React from 'react'
import { Button, Col, Image, Row, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
export const ImagesPost = ({ images }) => {
  let newImages = images?.filter((item) => item.length)
  if(!newImages || newImages.length === 0) return <></>
  if (newImages?.length === 1) {
    return (<Row style={{ background: '#f0f0f0', padding: '10px' }} justify="center" gutter={[20, 0]}>
      <Col span={12}>
        <img
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
            borderRadius: 10,
            boxShadow: '1px 1px 5px grey',
          }}
          src={newImages[0]}
          alt=""
        />
      </Col>
    </Row>)
  } else if (newImages?.length === 2) {
    return(<Row style={{ background: '#f0f0f0', padding: '10px' }} justify="center" gutter={[20, 0]}>
      <Col span={12}>
        <img
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
            borderRadius: 10,
            boxShadow: '1px 1px 5px grey',
          }}
          src={newImages[0]}
          alt=""
        />
      </Col>
      <Col span={12}>
        <img
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
            borderRadius: 10,
            boxShadow: '1px 1px 5px grey',
          }}
          src={newImages[1]}
          alt=""
        />
      </Col>
    </Row>)
  } else if (newImages?.length === 3) {
    return (
      <Row style={{ background: '#f0f0f0', padding: '10px' }} justify="center" gutter={[20, 0]}>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '400px',
              objectFit: 'cover',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[0]}
            alt=""
          />
        </Col>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              marginBottom: '20px',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[1]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[2]}
            alt=""
          />
        </Col>
      </Row>
    )
  } else if (newImages?.length === 4) {
    return (
      <Row style={{ background: '#f0f0f0', padding: '10px' }} justify="center" gutter={[20, 0]}>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              marginBottom: '20px',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[0]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[1]}
            alt=""
          />
        </Col>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              marginBottom: '20px',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[2]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[3]}
            alt=""
          />
        </Col>
      </Row>
    )
  } else {
    return (
      <Row style={{ background: '#f0f0f0', padding: '10px' }} justify="center" gutter={[20, 0]}>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              marginBottom: '20px',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[0]}
            alt=""
          />
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[1]}
            alt=""
          />
        </Col>
        <Col span={12}>
          <img
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              marginBottom: '20px',
              borderRadius: 10,
              boxShadow: '1px 1px 5px grey',
            }}
            src={newImages[2]}
            alt=""
          />
          <div style={{ position: 'relative' }}>
            <img
              style={{
                width: '100%',
                height: '190px',
                objectFit: 'cover',
                borderRadius: 10,
                boxShadow: '1px 1px 5px grey',
                display: 'block',
                opacity: 0.7,
              }}
              src={newImages[3]}
              alt=""
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: 'bold', fontSize: 40, color: 'white' }}>
                +{newImages.length - 3}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}
