import React, { useEffect, useRef, useState, useCallback } from 'react'
import localStorage from '../../utils/localStorage'
import styles from './order-create-shipping.module.scss'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { ROUTES, PERMISSIONS, IMAGE_DEFAULT } from 'consts'
import { filterOptionProduct, formatCash, removeUnicode } from 'utils'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ListDeliveryAddress from './list-delivery-address/list-delivery-address'
import PaymentMethods from './payment-methods'
import GHN from '../../components/Shipping/ghn'
import GHTK from '../../components/Shipping/ghtk'
import ViettelPost from '../../components/Shipping/viettelpost'

import { TableProduct } from './tableProduct'
import { SelectImei } from './selectImei'
import { sortBy } from 'lodash'

//antd
import {
  Row,
  Col,
  Divider,
  Input,
  Button,
  Table,
  InputNumber,
  notification,
  Form,
  Select,
  Radio,
  Spin,
  Tooltip,
  Space,
  DatePicker,
  Checkbox,
  Popover,
  Typography,
  Popconfirm,
  Tag,
  Modal,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  CloseCircleTwoTone,
  CheckOutlined,
  PlusSquareFilled,
  UserOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
  SearchOutlined,
  PlusSquareOutlined,
  CreditCardFilled,
  CarFilled,
  EditOutlined,
} from '@ant-design/icons'
import noData from 'assets/icons/no-data.png'
//apis
import { getPromotions } from 'apis/promotion'
import { getCustomers } from 'apis/customer'
import { getAllBranch } from 'apis/branch'
import { addTax, getTaxs, updateTax } from 'apis/tax'
import { getShippings } from 'apis/shipping'
import { getProducts } from 'apis/product'

//components
import Permission from 'components/permission'
import CustomerForm from 'views/customer-create/customer-form'
import TitlePage from 'components/title-page'
import { addOrder, getOrders } from 'apis/order'
import { getPayments } from 'apis/payment'
import ScanProduct from 'views/sell/scan-product'
import PromotionAvailable from './promotion'
import { ModalProductLocation } from './quantityProductModal'
import BranchForm from 'views/branch/branch-form'
import { getProductsSystem } from 'redux/actions/products'

export default function OrderCreateShipping() {
  const { t } = useTranslation()
  let history = useHistory()
  const { Option } = Select
  const dispatch = useDispatch()

  const [formInfoOrder] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { Paragraph, Text } = Typography

  const [loadingProduct, setLoadingProduct] = useState(false)
  const [productsSearch, setProductsSearch] = useState([])
  const [channel, setChannel] = useState('')
  const [infoBranch, setInfoBranch] = useState()
  const [paymentMethodDefault, setPaymentMethodDefault] = useState({})
  const [disabled, setDisabled] = useState(false)

  //object order create
  const [orderCreate, setOrderCreate] = useState({
    name: 'Đơn 1',
    type: 'default',
    customer: null,
    order_details: [], //danh sách sản phẩm trong hóa đơn
    payments: [{ ...paymentMethodDefault }], //hình thức thanh toán
    sumCostPaid: 0, // tổng tiền của tất cả sản phẩm
    discount: {},
    promotion: {
      name: '',
      value: 0,
      order_value_require: 0,
    },
    list_tax: [], //ds thuế áp dụng
    fee_shipping: 0, //phí giao hàng
    shipping_info: null, //địa chỉ nhận hàng
    discount_invoice: 0,
    discount_product: 0,
    VAT: 0,
    noteInvoice: '',
    salesChannel: '', //kênh bán hàng
    isDelivery: true, //mặc định là hóa đơn giao hàng
    deliveryCharges: 0, //phí giao hàng
    deliveryAddress: null, //địa chỉ nhận hàng
    shipping: null, //đơn vị vận chuyển
    billOfLadingCode: '',
    moneyToBePaidByCustomer: 0, // tổng tiền khách hàng phải trả
    prepay: 0, //tiền khách thanh toán trước
    moneyGivenByCustomer: 0, //tiền khách hàng đưa
    excessCash: 0, //tiền thừa
    tax_list: [], //thuế VAT
    imei_new: [], // imei sản phẩm
    locations_new: [], // lô của sản phẩm
  })
  const [taxList, setTaxList] = useState([])
  const [voucher, setvoucher] = useState('')
  const [methodSell, setMethodSell] = useState('ship')
  const [ellipsis] = useState(true)

  const [paramsFilterSearchHeader, setParamsFilterSearchHeader] = useState({
    page: 1,
    page_size: 50,
  })
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [customerInfo, setCustomerInfo] = useState(null)
  const [customers, setCustomers] = useState([])
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [dataShipping, setDataShipping] = useState([])
  const [selectedShipping, setSelectedShipping] = useState(1)
  const [checkingNumber, setCheckingNumber] = useState('')
  const [shippingInfo, setShippingInfo] = useState()
  const [paymentsMethod, setPaymentsMethod] = useState([])
  const dataUser = useSelector((state) => state.login.dataUser)
  const { products, productsFil } = useSelector((state) => state.products)
  const [productQuantity, setProductQuantity] = useState([])

  const [shippingInfoExtent, setShippingInfoExtent] = useState()

  const _editOrder = (attribute, value) => {
    const orderCreateNew = { ...orderCreate }
    orderCreateNew[attribute] = value
    // tổng tiền của tất cả sản phẩm
    orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
      (total, current) => total + current.quantity * current.price,
      0
    )

    // tổng giảm giá của tất cả sản phẩm
    var discount_product = orderCreateNew.order_details.reduce(
      (total, current) => total + (current.discount || 0) * current.quantity,
      0
    )
    //tổng giá trị ds thuế

    orderCreateNew.fee_shipping = orderCreateNew.fee_shipping || 0

    //discount có 2 loại
    //nếu type = value thì cộng
    // nếu type = percent thì nhân

    orderCreateNew.total_discount = 0

    if (orderCreateNew.promotion.discount > 0) {
      orderCreateNew.total_discount += orderCreateNew.promotion.discount
    }

    orderCreateNew.total_discount += discount_product
    orderCreateNew.total_discount += orderCreateNew.discount_invoice

    //tổng tiền khách hàng phải trả
    orderCreateNew.moneyToBePaidByCustomer =
      orderCreateNew.sumCostPaid + orderCreateNew.fee_shipping - orderCreateNew.total_discount
    //tổng tiền khách trả

    if (orderCreateNew.moneyToBePaidByCustomer < 0) orderCreateNew.moneyToBePaidByCustomer = 0

    orderCreateNew.discount_product = discount_product

    orderCreateNew.list_taxSum = orderCreateNew.list_tax.reduce(
      (total, current) =>
        total +
        (current.value / 100) * (orderCreateNew.sumCostPaid - orderCreateNew.total_discount),
      0
    )
    //tiền thừa
    // const excessCashNew =
    //   (orderCreateNew.isDelivery
    //     ? orderCreateNew.prepay
    //     : orderCreateNew.moneyGivenByCustomer) -
    //   orderCreateNew.moneyToBePaidByCustomer
    orderCreateNew.moneyToBePaidByCustomer += orderCreateNew.list_taxSum

    if (!orderCreateNew.isDelivery && attribute === 'payments') {
      const sumCostPaid = value.reduce((total, current) => total + current.value, 0)
      const excessCash = sumCostPaid - orderCreateNew.moneyToBePaidByCustomer
      orderCreateNew.excessCash = excessCash >= 0 ? excessCash : 0
    }

    //khi có 1 phương thức thanh toán
    if (orderCreateNew.payments.length === 1) {
      if (orderCreateNew.isDelivery) orderCreateNew.prepay = orderCreateNew.moneyToBePaidByCustomer
      else orderCreateNew.moneyGivenByCustomer = orderCreateNew.moneyToBePaidByCustomer

      orderCreateNew.payments = [
        {
          ...orderCreateNew.payments[0],
          value: orderCreateNew.moneyToBePaidByCustomer,
        },
      ]
    }

    if (attribute !== 'moneyGivenByCustomer') {
      orderCreateNew.moneyGivenByCustomer =
        orderCreateNew.sumCostPaid + orderCreateNew.fee_shipping - orderCreateNew.total_discount
    }

    // orderUpdate = orderCreateNew

    setOrderCreate({ ...orderCreateNew })
  }

  const toggleDisableCode = () => {
    setDisabled(!disabled)
  }

  const _addProductToOrder = (product) => {
    if (product.inventory === 'normal' && product.is_open === false) {
      return notification.warning({
        key: 'warning_sell_normal_product',
        message: 'Sản phẩm tồn kho cơ bản hiện tại đang không được mở bán',
      })
    }
    const productInsufficientQuantity = () =>
      notification.warning({
        key: 'warning_create_a_pre_order',
        message: (
          <div>
            <div>{t('sell.warning_create_a_pre_order')}</div>
          </div>
        ),
        style: { width: 350 },
      })

    if (product.inventory === 'advanced' && product.total_quantity === 0) {
      return notification.warning({
        key: 'warning_create_a_pre_order',
        message: (
          <div>
            <div>{t('sell.warning_create_a_pre_order')}</div>
          </div>
        ),
        style: { width: 350 },
      })
    }
    if (product.inventory === 'normal' && product.is_open === false) {
      return notification.warning({
        key: 'warning_sell_normal_product',
        message: 'Sản phẩm tồn kho cơ bản hiện tại đang không được mở bán',
      })
    }
    if (product) {
      if (product.total_quantity !== 0 || product.inventory === 'normal' || product.is_pre_order) {

        //check product có đủ số lượng
        if (
          product.total_quantity !== 0 ||
          product.inventory === 'normal' ||
          product.is_pre_order
        ) {
          const orderCreateNew = { ...orderCreate }
          const indexProduct = orderCreateNew.order_details.findIndex((e) => e._id === product._id)

          //nếu đã có sẵn trong cart rồi thì tăng số lượng và tổng tiền của sản phẩm đó lên
          //nếu chưa có thì push vào giỏ hàng
          if (indexProduct !== -1) {
            if (orderCreateNew.order_details[indexProduct].quantity < product.total_quantity || product.inventory === 'normal' ) {
              orderCreateNew.order_details[indexProduct].quantity++

              orderCreateNew.order_details[indexProduct].sumCost =
                +orderCreateNew.order_details[indexProduct].quantity *
                +orderCreateNew.order_details[indexProduct].price

              //thuế VAT của mỗi sản phẩm
              orderCreateNew.order_details[indexProduct].VAT_Product =
                orderCreateNew.order_details[indexProduct]._taxes &&
                orderCreateNew.order_details[indexProduct]._taxes.length
                  ? (
                      (orderCreateNew.order_details[indexProduct]._taxes.reduce(
                        (total, current) => total + current.value,
                        0
                      ) /
                        100) *
                      orderCreateNew.order_details[indexProduct].sumCost
                    ).toFixed(0)
                  : 0
            } else
              notification.warning({
                message: t('sell.not_have_enough_quantity_to_sell'),
              })
          } else {
            if (product.inventory === 'normal') {
              orderCreateNew.order_details.push({
                ...product,
                unit: '', //đơn vị
                quantity: 1, //số lượng sản phẩm
                sumCost: product.price, // tổng giá tiền
                VAT_Product:
                  product._taxes && product._taxes.length
                    ? (
                        (product._taxes.reduce((total, current) => total + current.value, 0) /
                          100) *
                        product.price
                      ).toFixed(0)
                    : 0,
              })
            } else {
              if (product.type_advanced === 2) {
                let today = moment().startOf('date').unix()

                let new_arr = product?.location_advanced?.location.filter(item => moment(item.exp).startOf('date').unix() > today )
                let new_data = sortBy(new_arr, [
                  (o) => {
                    return o.exp
                  },
                ])
                let new_data_location = [
                  {
                    location_id: new_data[0]?.location_id,
                    quantity: 1,
                    code: new_data[0]?.code,
                    exp: new_data[0]?.exp,
                  },
                ]

                orderCreateNew.order_details.push({
                  ...product,
                  quantity: 1, //số lượng sản phẩm
                  locations_new: product.type_advanced === 2 ? new_data_location : [],
                  unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                  sumCost: product.price, // tổng giá tiền
                  tax_product:
                    product._taxes && product._taxes.length
                      ? (
                          (product._taxes.reduce((total, current) => total + current.value, 0) /
                            100) *
                          product.price
                        ).toFixed(0)
                      : 0,
                })
              }
              if (product.type_advanced === 3) {
                // let new_data_imei = []
                // new_data_imei.push(product?.imei[0])
                orderCreateNew.order_details.push({
                  quantity: 0, //số lượng sản phẩm
                  ...product,
                  // imei_new: product.type_advanced === 3 ? new_data_imei : [],
                  imei_new: [],
                  unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                  sumCost: product.price, // tổng giá tiền
                  tax_product:
                    product._taxes && product._taxes.length
                      ? (
                          (product._taxes.reduce((total, current) => total + current.value, 0) /
                            100) *
                          product.price
                        ).toFixed(0)
                      : 0,
                })
              } else {
                if (product.type_advanced === 1) {
                  orderCreateNew.order_details.push({
                    quantity: 1, //số lượng sản phẩm
                    ...product,
                    unit: product.units && product.units.length ? product.units[0].name : 'Cái', //đơn vị
                    sumCost: product.price, // tổng giá tiền
                    tax_product:
                      product._taxes && product._taxes.length
                        ? (
                            (product._taxes.reduce((total, current) => total + current.value, 0) /
                              100) *
                            product.price
                          ).toFixed(0)
                        : 0,
                  })
                }
                // else {
                //   if(product.inventory === 'nomal'){
                //     orderCreateNew.order_details.push({
                //       ...product,
                //       unit: '', //đơn vị
                //       quantity: 1, //số lượng sản phẩm
                //       sumCost: product.price, // tổng giá tiền
                //       VAT_Product:
                //         product._taxes && product._taxes.length
                //           ? (
                //               (product._taxes.reduce((total, current) => total + current.value, 0) / 100) *
                //               product.price
                //             ).toFixed(0)
                //           : 0,
                //     })
                //   }
                // }
              }
            }
          }

          // tổng tiền của tất cả sản phẩm
          orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
            (total, current) => total + current.sumCost,
            0
          )

          //tổng thuế VAT của tất cả sản phẩm
          orderCreateNew.sum_tax_list = orderCreateNew.order_details.reduce(
            (total, current) => total + +current.VAT_Product,
            0
          )

          //discount có 2 loại
          //nếu type = value thì cộng
          // nếu type = percent thì nhân
          if (orderCreateNew.discount.type === 'VALUE') {
            orderCreateNew.moneyToBePaidByCustomer =
              orderCreateNew.moneyToBePaidByCustomer - orderCreateNew.discount
          } else {
            orderCreateNew.moneyToBePaidByCustomer -=
              (+orderCreateNew.discount / 100) * orderCreateNew.moneyToBePaidByCustomer
          }

          let promotionValue = 0
          if (orderCreateNew.promotion.type === 'VALUE') {
            orderCreateNew.moneyToBePaidByCustomer =
              orderCreateNew.moneyToBePaidByCustomer - orderCreateNew.promotion.value
            promotionValue = orderCreateNew.promotion.value
          } else {
            orderCreateNew.moneyToBePaidByCustomer -=
              (orderCreateNew.promotion.value / 100) * orderCreateNew.moneyToBePaidByCustomer
            promotionValue =
              (orderCreateNew.promotion.value / 100) * orderCreateNew.moneyToBePaidByCustomer
          }

          //tổng tiền khách hàng phải trả
          orderCreateNew.moneyToBePaidByCustomer = +(
            orderCreateNew.sumCostPaid +
            orderCreateNew.sum_tax_list +
            (orderCreateNew.isDelivery ? orderCreateNew.deliveryCharges : 0) -
            orderCreateNew.discount -
            promotionValue
          ).toFixed(0)

          if (orderCreateNew.payments.length === 1) {
            if (orderCreateNew.isDelivery)
              orderCreateNew.prepay = orderCreateNew.moneyToBePaidByCustomer
            else orderCreateNew.moneyGivenByCustomer = orderCreateNew.moneyToBePaidByCustomer

            orderCreateNew.payments = [
              {
                ...orderCreateNew.payments[0],
                value: orderCreateNew.moneyToBePaidByCustomer,
              },
            ]
          }

          //tiền thừa
          const excessCashNew =
            (orderCreateNew.isDelivery
              ? orderCreateNew.prepay
              : orderCreateNew.moneyGivenByCustomer) - orderCreateNew.moneyToBePaidByCustomer

          orderCreateNew.excessCash = excessCashNew >= 0 ? excessCashNew : 0
          setOrderCreate({ ...orderCreateNew })
        } else
          notification.warning({
            message: t('sell.not_have_enough_quantity_to_sell'),
          })
      } else {
        productInsufficientQuantity()
      }
    }
  }

  const _editProductInOrder = (attribute, value, index) => {
    if (index !== -1) {
      const orderCreateNew = { ...orderCreate }

      orderCreateNew.order_details[index][attribute] = value
      if (orderCreateNew.order_details[index].quantity <= 0) {
        orderCreateNew.order_details[index].quantity = 1
      }
      orderCreateNew.discount = orderCreateNew.discount || {}
      //tổng tiền của 1 sản phẩm
      orderCreateNew.order_details[index].sumCost =
        +orderCreateNew.order_details[index].quantity * +orderCreateNew.order_details[index].price -
        (orderCreateNew.order_details[index].discount *
          orderCreateNew.order_details[index].quantity || 0)

      if (orderCreateNew.order_details[index].sumCost < 0)
        orderCreateNew.order_details[index].sumCost = 0

      //thuế VAT của mỗi sản phẩm
      orderCreateNew.order_details[index].VAT_Product =
        orderCreateNew.order_details[index]._taxes &&
        orderCreateNew.order_details[index]._taxes.length
          ? (
              (orderCreateNew.order_details[index]._taxes.reduce(
                (total, current) => total + current.value,
                0
              ) /
                100) *
              orderCreateNew.order_details[index].sumCost
            ).toFixed(0)
          : 0

      //tổng thuế VAT của tất cả các sản phẩm
      orderCreateNew.VAT = orderCreateNew.order_details.reduce(
        (total, current) => total + +current.VAT_Product,
        0
      )

      // tổng tiền của tất cả sản phẩm
      orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
        (total, current) => total + current.price * current.quantity,
        0
      )

      // //tổng giá trị ds thuế
      orderCreateNew.list_taxSum = orderCreateNew.list_tax.reduce(
        (total, current) => total + (current.value / 100) * orderCreateNew.sumCostPaid,
        0
      )

      orderCreateNew.deliveryCharges = orderCreateNew.deliveryCharges || 0

      // Bắt đầu tính discount của sản phẩm
      var discount_product = 0
      orderCreateNew.order_details.map((product_detail) => {
        discount_product += (product_detail.discount || 0) * product_detail.quantity
      })
      orderCreateNew.total_discount = 0
      orderCreateNew.discount_invoice = 0
      orderCreateNew.promotion = 0
      orderCreateNew.list_tax = []

      // Cộng discount sản phẩm vào discount đơn hàng
      orderCreateNew.total_discount += discount_product

      if (orderCreateNew.discount.discount > 0)
        orderCreateNew.total_discount += orderCreateNew.discount.discount

      //tổng tiền khách hàng phải trả
      orderCreateNew.moneyToBePaidByCustomer =
        orderCreateNew.sumCostPaid +
        (orderCreateNew.VAT ? orderCreateNew.VAT : 0) +
        (orderCreateNew.isDelivery ? orderCreateNew.deliveryCharges : 0) -
        orderCreateNew.total_discount

      if (orderCreateNew.moneyToBePaidByCustomer < 0) orderCreateNew.moneyToBePaidByCustomer = 0
      //discount có 2 loại
      //nếu type = value thì cộng
      // nếu type = percent thì nhân
      orderCreateNew.discount_product = discount_product
      orderCreateNew.moneyToBePaidByCustomer += orderCreateNew.list_taxSum

      //mặc định cho số tiền cần thanh toán = số tiền phải trả
      //khi có 1 phương thức thanh toán
      if (orderCreateNew.payments.length === 1) {
        if (orderCreateNew.isDelivery)
          orderCreateNew.prepay = orderCreateNew.moneyToBePaidByCustomer
        else orderCreateNew.moneyGivenByCustomer = orderCreateNew.moneyToBePaidByCustomer

        orderCreateNew.payments = [
          {
            ...orderCreateNew.payments[0],
            value: orderCreateNew.moneyToBePaidByCustomer,
          },
        ]
      }

      //tiền thừa
      const excessCashNew =
        (orderCreateNew.isDelivery ? orderCreateNew.prepay : orderCreateNew.moneyGivenByCustomer) -
        orderCreateNew.moneyToBePaidByCustomer

      orderCreateNew.excessCash = excessCashNew >= 0 ? excessCashNew : 0

      setOrderCreate({ ...orderCreateNew })
    }
  }

  useEffect(() => {
    const getOrderCode = async () => {
      const res = await getOrders({role_id: dataUser?.role_id})
      if (res?.data?.data[0]) {
        let newRes = res?.data?.data[0].code
        formInfoOrder.setFieldsValue({ code_id: newRes })
      }
    }
    getOrderCode()
  }, [])

  const _removeProductToOrder = (indexProduct) => {
    notification.error({
      key: 'DeleteProductData',
      message: 'Bạn vừa xoá sản phẩm vui lòng kiểm tra lại giảm giá đơn hàng!',
    })
    if (indexProduct !== -1) {
      var _productsSearch = [...productsSearch]
      _productsSearch[indexProduct].is_choose = false
      setProductsSearch(_productsSearch)

      const orderCreateNew = { ...orderCreate }
      orderCreateNew.order_details.splice(indexProduct, 1)

      //tổng thuế VAT của tất cả các sản phẩm
      orderCreateNew.sum_tax_list = orderCreateNew.order_details.reduce(
        (total, current) => total + +current.VAT_Product,
        0
      )

      // tổng tiền của tất cả sản phẩm
      orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
        (total, current) => total + current.sumCost,
        0
      )

      //discount có 2 loại
      //nếu type = value thì cộng
      // nếu type = percent thì nhân
      if (orderCreateNew.discount.type === 'VALUE') {
        orderCreateNew.moneyToBePaidByCustomer =
          orderCreateNew.moneyToBePaidByCustomer - orderCreateNew.discount
      } else {
        orderCreateNew.moneyToBePaidByCustomer -=
          (+orderCreateNew.discount / 100) * orderCreateNew.moneyToBePaidByCustomer
      }

      if (orderCreateNew.promotion.type === 'VALUE') {
        orderCreateNew.moneyToBePaidByCustomer =
          orderCreateNew.moneyToBePaidByCustomer - orderCreateNew.promotion.value
      } else {
        orderCreateNew.moneyToBePaidByCustomer -=
          (+orderCreateNew.promotion.value / 100) * orderCreateNew.moneyToBePaidByCustomer
      }

      //khi có 1 phương thức thanh toán
      if (orderCreateNew.payments.length === 1) {
        if (orderCreateNew.isDelivery)
          orderCreateNew.prepay = orderCreateNew.moneyToBePaidByCustomer
        else orderCreateNew.moneyGivenByCustomer = orderCreateNew.moneyToBePaidByCustomer

        orderCreateNew.payments = [
          {
            ...orderCreateNew.payments[0],
            value: orderCreateNew.moneyToBePaidByCustomer,
          },
        ]
      }

      //tổng tiền khách hàng phải trả
      orderCreateNew.moneyToBePaidByCustomer = +(
        orderCreateNew.sumCostPaid +
        orderCreateNew.sum_tax_list +
        (orderCreateNew.isDelivery ? orderCreateNew.deliveryCharges : 0) -
        orderCreateNew.discount -
        orderCreateNew.promotion.value
      ).toFixed(0)

      //tiền thừa
      const excessCashNew =
        (orderCreateNew.isDelivery ? orderCreateNew.prepay : orderCreateNew.moneyGivenByCustomer) -
        orderCreateNew.moneyToBePaidByCustomer

      orderCreateNew.excessCash = excessCashNew >= 0 ? excessCashNew : 0

      setOrderCreate({ ...orderCreateNew })
    }
  }
  const ModalCustomerForm = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          style={{ top: 20 }}
          onCancel={toggle}
          width={800}
          footer={null}
          title={`${record ? t('common.update') : t('common.add_client')}`}
          visible={visible}
        >
          <CustomerForm
            record={record}
            close={toggle}
            text={record ? t('common.save') : t('common.add')}
            reload={_getCustomers}
          />
        </Modal>
      </>
    )
  }

  const _getCustomerAfterEditCustomer = async () => {
    try {
      setLoadingCustomer(true)
      const res = await getCustomers({ customer_id: customerInfo?.customer_id || '' })
      if (res.status === 200)
        if (res.data.data.length)
          // setCustomerInfo(res.data.data[0])
          setLoadingCustomer(false)
    } catch (error) {
      setLoadingCustomer(false)
      console.log(error)
    }
  }

  const onChangeMethodSell = (selected) => {
    setMethodSell(selected.target.value)
  }

  const ModalNoteProduct = ({ product, index }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [note, setNote] = useState(product.note)

    return (
      <>
        <div
          onClick={toggle}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: 'gray',
            cursor: 'pointer',
          }}
        >
          <Popover
            content={
              note ? (
                <Row wrap={false} justify="space-between" align="middle">
                  <Paragraph
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                      maxWidth: 450,
                      width: 450,
                    }}
                  >
                    {note}
                  </Paragraph>
                </Row>
              ) : (
                ''
              )
            }
            style={{ maxWidth: 450 }}
            placement="bottomLeft"
          >
            <Text
              style={ellipsis ? { width: 50 } : undefined}
              className={styles['sell-product__item-note']}
            >
              {note || 'Ghi chú'}
            </Text>
          </Popover>

          <EditOutlined style={{ marginLeft: 5 }} />
        </div>
        <Modal
          title={product && product.title}
          onCancel={() => {
            toggle()
            setNote(product.note || '')
          }}
          onOk={() => {
            _editProductInOrder('note', note, index)
          }}
          visible={visible}
        >
          <div>
            {t('common.note')}
            <Input.TextArea
              onChange={(e) => setNote(e.target.value)}
              defaultValue={note}
              placeholder={t('common.enter_a_note')}
              rows={4}
              style={{ width: '100%' }}
            />
          </div>
        </Modal>
      </>
    )
  }
  const ModalDiscountProduct = ({ product, index }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [discount, setDiscount] = useState(product.discount || '')
    const [discount1, setDiscount1] = useState(Math.round((product.discount / product.price) * 100))
    const setDataDiscount = (check, value) => {
      // const valueNew = value.replaceAll(',', '').replaceAll('%', '')
      if (check == true) {
        setDiscount(Math.round(value * 100) / 100)
        setDiscount1(Math.round((Math.round(value * 100) / 100 / product.price) * 100 * 100) / 100)
      } else {
        setDiscount1(Math.round(value * 100) / 100)
        setDiscount((value * product.price) / 100)
      }
    }
    // const [discount, setDiscount] = useState(product.discount || '')

    // const setDataDiscount = (check, value) => {
    //   const valueNew = value.replaceAll(',', '').replaceAll('%', '')
    //   if (check == true) {
    //     setDiscount(Math.round(valueNew * 100) / 100)
    //   } else {
    //     setDiscount((valueNew * product.price) / 100)
    //   }
    // }

    return (
      <>
        <div
          onClick={toggle}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: 'gray',
            cursor: 'pointer',
          }}
        >
          <Text style={{ fontSize: 15 }}>
            {String(discount).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
          </Text>
          <EditOutlined style={{ marginLeft: 5 }} />
        </div>
        <Modal
          title={product && product.title}
          onCancel={() => {
            toggle()
            setDiscount(product.discount || '')
          }}
          visible={visible}
          footer={[
            discount >= 0 && discount <= product.price ? (
              <Button
                key="cancel"
                onClick={() => {
                  _editProductInOrder('discount', discount, index)
                }}
              >
                Áp dụng
              </Button>
            ) : (
              <span style={{ color: 'red' }}>
                Chiết khấu không được vượt quá đơn giá sản phẩm và không nhỏ hơn 0
              </span>
            ),
          ]}
        >
          <Row>
            <Col span={8}>
              <Text>Giá chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                onChange={(value) => {
                  setDataDiscount(true, value)
                }}
                // onBlur={(e) => setDataDiscount(true, e.target.value)}
                defaultValue={product.discount || 0}
                value={discount}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={8}>
              <Text>Phần trăm chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                // onBlur={(e) => setDataDiscount(false, e.target.value)}
                onChange={(value) => {
                  setDataDiscount(false, value)
                }}
                value={discount1}
                defaultValue={Math.round((product.discount / product.price) * 100 * 100) / 100}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
  const ModalDiscountInvoice = ({ invoices, isBranchClosed }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    // const [discount, setDiscount] = useState(invoices.discount_invoice || '')

    // const setDataDiscount = (check, value) => {
    //   const valueNew = value.replaceAll(',', '').replaceAll('%', '')
    //   if (check == true) {
    //     setDiscount(Math.round(valueNew * 100) / 100)
    //   } else {
    //     setDiscount((valueNew * (invoices.sumCostPaid - invoices.discount_product)) / 100)
    //   }
    // }
    const [discount, setDiscount] = useState(invoices.discount_invoice || '')
    const [discount1, setDiscount1] = useState(
      Math.round(
        (invoices.discount_invoice / (invoices.sumCostPaid - invoices.discount_product)) * 100 * 100
      ) / 100
    )

    const setDataDiscount = (check, value) => {
      // const valueNew = value.replaceAll(',', '').replaceAll('%', '')
      if (check == true) {
        setDiscount1(
          Math.round(
            (Math.round(value * 100) / 100 / (invoices.sumCostPaid - invoices.discount_product)) *
              100 *
              100
          ) / 100
        )
        setDiscount(Math.round(value * 100) / 100)
      } else {
        setDiscount1(Math.round(value * 100) / 100)
        setDiscount((value * (invoices.sumCostPaid - invoices.discount_product)) / 100)
      }
    }

    return (
      <>
        <div
          onClick={toggle}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: 'gray',
            cursor: 'pointer',
          }}
        >
          <Text style={{ fontSize: 15 }}>
            {String(discount).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
          </Text>
          <EditOutlined style={{ marginLeft: 5 }} />
        </div>
        <Modal
          title="Chiết khấu đơn hàng"
          onCancel={() => {
            toggle()
            setDiscount(invoices.discount_invoice || '')
          }}
          // onOk={() => {
          //   const value = discount.replaceAll(',', '')
          //   _editProductInInvoices('discount', value, index)
          // }}
          visible={visible}
          footer={[
            discount >= 0 && discount <= invoices.sumCostPaid - (invoices.discount_product || 0) ? (
              <Button
                disabled={isBranchClosed}
                key="cancel"
                onClick={() => {
                  _editOrder('discount_invoice', discount)
                }}
                style={{ cursor: `${isBranchClosed} ? 'not-allowed' ? 'pointer'` }}
              >
                Áp dụng
              </Button>
            ) : (
              <span style={{ color: 'red' }}>
                Chiết khấu không được vượt quá giá trị đơn hàng và không nhỏ hơn 0
              </span>
            ),
          ]}
        >
          <Row>
            <Col span={8}>
              <Text>Giá chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                onChange={(value) => {
                  setDataDiscount(true, value)
                }}
                defaultValue={invoices.discount_invoice || 0}
                min={0}
                value={discount}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={8}>
              <Text>Phần trăm chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                onChange={(value) => {
                  setDataDiscount(false, value)
                }}
                defaultValue={
                  Math.round(
                    (invoices.discount_invoice /
                      (invoices.sumCostPaid - invoices.discount_product)) *
                      100 *
                      100
                  ) / 100
                }
                value={discount1}
                min={0}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
        </Modal>
      </>
    )
  }

  const ItemQuantity = ({ product }) => {
    return (
      <InputNumber
        style={{ width: 70 }}
        onBlur={(e) => {
          const value = e.target.value.replaceAll(',', '')
          if (product.inventory === 'advanced') {
            if (product.inventory === 'advanced' && product.type_advanced === 1 && value > product.total_quantity ) {
              notification.error({
                message: `Số lượng nhập vượt quá số lượng tối đa có thể bán của sản phẩm ${product.title}, vui lòng thử lại`,
              })
              return
            }
            if (product.type_advanced === 3) {
              if (value < product?.imei_new?.length) {
                notification.error({
                  message: `Số lượng bán đang không bằng số lượng imei được chọn. Vui lòng sửa số lượng của sản phẩm ${product.title}!`,
                })
                return
              }
              if (value > product?.imei_new?.length) {
                notification.error({
                  message: `Vui lòng chọn iMei cho sản phẩm ${product.title}!`,
                })
                return
              }
              if (!product.imei_new) {
                notification.error({
                  message: `Vui lòng chọn iMei cho sản phẩm ${product.title}!`,
                })
                return
              }
            } else {
              if (product.type_advanced === 2) {
                // let quantity = product.locations_new.reduce(
                //   (total, item) => (total += item.quantity),
                //   0
                // )
                // if (product.quantity > quantity) {
                //   return notification.error({
                //     message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
                //   })
                // }
                if ( value > product.total_quantity) {
                  console.log(1)
                  notification.error({
                    message: `Số lượng nhập vượt quá số lượng tối đa có thể bán của sản phẩm ${product.title}, vui lòng thử lại`,
                  })
                  // return
                }
                if (product?.locations_new?.length === 0) {
                  notification.error({
                    message: `Vui lòng chọn lô cho sản phẩm ${product.title}!`,
                  })
                  // return
                }
                if (!product.locations_new) {
                  notification.error({
                    message: `Vui lòng chọn lô cho sản phẩm ${product.title}!`,
                  })
                  // return
                }
              }
            }
            const indexProduct = orderCreate.order_details.findIndex(
              (e) => e.variant_id === product.variant_id
            )
            _editProductInOrder('quantity', +value, indexProduct)
          } else {
            const indexProduct = orderCreate.order_details.findIndex(
              (e) => e.variant_id === product.variant_id
            )
            _editProductInOrder('quantity', +value, indexProduct)
          }
        }}
        defaultValue={product.quantity || 1}
        min={1}
        max={product.inventory === 'advanced' && product.total_quantity}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        placeholder="Nhập số lượng"
      />
    )
  }
  const RenderRowTypeAdvanced = ({ product, index }) => {
    switch (product.type_advanced) {
      case 2:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product?.locations_new?.length > 0 ? (
              product?.locations_new?.map((e, index) => (
                <div style={{ display: 'flex' }}>
                  {e.quantity > 0 ? (
                    <Tag
                      style={{
                        padding: 4,
                        marginBottom: 4,
                        backgroundColor: '#cccc',
                        borderRadius: 4,
                      }}
                    >
                      {e.code} | {e.exp} | SL: <strong>{e.quantity}</strong>
                      <CloseCircleTwoTone
                        style={{ marginLeft: 4 }}
                        onClick={() => RemoveItemLocation(product, e, index)}
                      />
                    </Tag>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <p style={{ marginLeft: 40, color: 'red', marginBottom: 0, fontSize: 12 }}>
                Sản phẩm chưa có lô, vui lòng chọn...{' '}
              </p>
            )}
          </div>
        )
      case 3:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product?.imei_new?.length > 0 ? (
              product?.imei_new?.map((e, index) => (
                <div style={{ display: 'flex' }}>
                  <Tag
                    style={{
                      padding: 4,
                      marginBottom: 4,
                      backgroundColor: '#cccc',
                      borderRadius: 4,
                    }}
                  >
                    <strong>{e}</strong>
                    <CloseCircleTwoTone
                      style={{ marginLeft: 4 }}
                      onClick={() => RemoveItemImei(product, e, index)}
                    />
                  </Tag>
                </div>
              ))
            ) : (
              <p style={{ marginLeft: 40, color: 'red', marginBottom: 0, fontSize: 12 }}>
                Sản phẩm chưa có iMei, vui lòng chọn...{' '}
              </p>
            )}
          </div>
        )
      default:
        return <></>
    }
  }
  const RemoveItemLocation = (product, e, index) => {
    const indexProduct = orderCreate.order_details.findIndex(
      (e) => e.variant_id === product.variant_id
    )
    let new_location = [...product.locations_new]
    new_location.splice(index, 1)
    setProductQuantity(new_location)
    _editProductInOrder(
      'quantity',
      new_location.reduce((total, item) => (total += item.quantity), 0),
      indexProduct
    )
    _editProductInOrder('locations_new', new_location, indexProduct)
  }

  const RemoveItemImei = (product, e, index) => {
    const indexProduct = orderCreate.order_details.findIndex(
      (e) => e.variant_id === product.variant_id
    )
    let new_imei = [...product.imei_new]
    new_imei.splice(index, 1)
    // setProductQuantity(new_location)
    _editProductInOrder('quantity', new_imei.length, indexProduct)
    _editProductInOrder('imei_new', new_imei, indexProduct)
  }
  const RenderRowTypeAdvancedLocation = useCallback(({ product, index }) => {
    switch (product.type_advanced) {
      case 2:
        return (
          <ModalProductLocation
            product={product}
            variant_id={product.variant_id}
            index={index}
            ellipsis={ellipsis}
            _editProductInInvoices={_editProductInOrder}
            setProductQuantity={setProductQuantity}
          />
        )
      case 3:
        return (
          <SelectImei
            variant_id={product.variant_id}
            index={index}
            _editProductInInvoices={_editProductInOrder}
            imei_new={product.imei_new}
          />
        )
      default:
        return <></>
    }
  }, [])

  const columns = [
    {
      title: `STT`,
      dataIndex: 'stt',
      width: 50,
      render: (data, record, index) => <div>{index + 1}</div>,
    },
    {
      title: 'SKU',

      dataIndex: 'sku',
    },
    {
      title: t('common.product'),
      dataIndex: 'title',
      render: (data, record, index) => (
        <div style={{ display: 'flex' }}>
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: 16,
            }}
          >
            <div>{record.title}</div>
            <ModalNoteProduct product={record} index={index} />
          </div>
          <RenderRowTypeAdvancedLocation product={record} index={index} />
        </div>
      ),
    },
    {
      title: t('sell.quantity'),
      render: (data, record) => <ItemQuantity product={record} />,
    },
    {
      title: t('common.unit'),
      render: (data, record) => (
        <Row>
          <div>{record.unit || 'Cái'}</div>
        </Row>
      ),
    },
    {
      title: t('common.unit_price'),
      render: (data, record) => (
        <Row>
          <div>{record.price}</div>
        </Row>
      ),
    },
    {
      title: 'Chiết khấu',
      render: (data, record, index) => <ModalDiscountProduct product={record} index={index} />,
    },
    {
      title: t('common.amount_to_be_paid'),
      align: 'right',
      render: (data, record) => formatCash(+record.sumCost || 0),
    },
    {
      title: '',
      render: (data, record) => (
        <CloseOutlined
          onClick={() => {
            const indexProduct = orderCreate.order_details.findIndex((e) => e._id === record._id)
            _removeProductToOrder(indexProduct)
          }}
          style={{ fontSize: 12, color: 'red', cursor: 'pointer' }}
        />
      ),
    },
  ]
  const _editShipping = (value) => {
    let shippingNew = {
      tags: value.tags,
      transport: value.transport,
      pick_money: value.pick_money,
      note: value.note,
      pickup: value.pickup,
      kgProduct: value.kgProduct,
    }
    setShippingInfoExtent(shippingNew)
  }
  const createOrder = async () => {
    if (voucher) {
      try {
        const res = await getPromotions({ voucher })
        if (!res.data.success) {
          notification.error({
            message: 'Voucher không tồn tại hoặc đã được sử dụng',
          })
          return
        }
      } catch (e) {
        notification.error({
          message: 'Voucher không tồn tại hoặc đã được sử dụng',
        })
        return
      }
    }
    if (customerInfo === null)
      return notification.error({
        message: `Vui lòng chọn khách hàng thanh toán`,
      })
    if (
      methodSell === 'ship' &&
      (!deliveryAddress ||
        !deliveryAddress.district ||
        !deliveryAddress.province ||
        !deliveryAddress.ward)
    ) {
      return notification.error({
        message: `Vui lòng điền đầy đủ địa chỉ giao hàng để gọi vận chuyển`,
      })
    }
    for (let product of orderCreate.order_details) {
      if (product.type_advanced === 2 && product?.locations_new?.length > 0) {
        let quantity = product?.locations_new?.reduce((total, item) => (total += item.quantity), 0)
        if (product.quantity > quantity) {
          return notification.error({
            message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          })
        }
        if (product.quantity < quantity) {
          return notification.error({
            message: `Số lượng bán đang không bằng số lượng lô được nhập. Vui lòng sửa số lượng của sản phẩm ${product.title}`,
          })
        }
      }
      if (product.type_advanced === 2 && !product.locations_new) {
        return notification.error({
          message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
        })
      }
      if (product.type_advanced === 2 && product?.locations_new?.length === 0) {
        return notification.error({
          message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
        })
      }
    }
    for (let product of orderCreate.order_details) {
      if (product.quantity > product?.imei_new?.length) {
        return notification.error({
          message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
        })
      }
      if (product.quantity < product?.imei_new?.length) {
        return notification.error({
          message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
        })
      }
      if (product.type_advanced === 3 && !product.imei_new) {
        return notification.error({
          message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
        })
      }
      if (product.type_advanced === 3 && product?.imei_new?.length === 0) {
        return notification.error({
          message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
        })
      }
    }
    try {
      const formData = formInfoOrder.getFieldsValue()
      const dataList = orderCreate.order_details.map((product) => {
        let productDiscount = 0
        productDiscount = product.quantity * product.discount

        const data = {
          product_id: product.product_id,
          type_advanced: product.type_advanced,
          sku: product.sku,
          variant_id: product.variant_id,
          options: product.options,
          price: product.price || 0,
          quantity: product.quantity,
          total_cost: product.price * product.quantity - productDiscount,
          discount: product.discount,
          final_cost: product.price * product.quantity - productDiscount,
          location: product.type_advanced === 2 ? product.locations_new : [],
          imei: product.type_advanced === 3 ? product.imei_new : [],
        }

        return data
      })

      let promotionValue = 0
      if (orderCreate.promotion && orderCreate.promotion.type === 'VALUE') {
        promotionValue = orderCreate.promotion.value
      } else {
        promotionValue = (orderCreate.promotion.value / 100) * orderCreate.moneyToBePaidByCustomer
      }

      let paymentMethodData

      paymentMethodData = orderCreate.payments

      const body = {
        sale_location: { branch_id: branchIdApp },
        branch_id: branchIdApp,
        channel: channel,
        customer_id: customerInfo.customer_id,
        order_details: dataList,
        discount_product: orderCreate.discount_product,
        discount_invoice: orderCreate.discount_invoice,
        payments: paymentMethodData,
        tags: formData.tags || [],
        list_taxSum: orderCreate.list_taxSum,
        list_tax: orderCreate.list_tax,
        transport: '1',
        order_type: methodSell === 'ship' ? 'online' : 'offline',
        total_cost: orderCreate.sumCostPaid - orderCreate.total_discount,
        total_discount: orderCreate.total_discount || 0,
        final_cost: orderCreate.moneyToBePaidByCustomer,
        total_tax: orderCreate.list_taxSum || 0,
        total_payment: orderCreate.sumCostPaid || 0, // thành tiền tất cả sản phẩm
        total_promotion: orderCreate.promotion.discount || 0,
        voucher: orderCreate.promotion ? orderCreate.promotion.name || '' : '',
        promotion_id: orderCreate.promotion ? orderCreate.promotion.promotion_id || '' : '',
        latitude: '50.50',
        longitude: '50.50',
        note: formData.note || '',
        is_delivery: true,
        is_auto_create_shipping: selectedShipping !== 1 ? true : false,
        shipping_company_id: selectedShipping,
        shipping_info: {
          ...deliveryAddress,
          ...shippingInfoExtent,
          cod: orderCreate.moneyToBePaidByCustomer - orderCreate.fee_shipping,
          tracking_number: checkingNumber,
          fee_shipping: orderCreate.fee_shipping || 0,
        },
        fee_shipping: orderCreate.fee_shipping || 0,
        customer_paid: orderCreate.moneyGivenByCustomer || 0,
        customer_debt: orderCreate.moneyGivenByCustomer - orderCreate.moneyToBePaidByCustomer || 0,
        order_status: methodSell === 'ship' ? 'waiting-pickup' : 'waiting-confirm',
        status: methodSell === 'ship' ? 100 : 101,
      }
      const res = await addOrder(body)
      if (res.data.success) {
        notification.success({ message: 'Tạo đơn hàng thành công' })
        history.push(`order-update-waiting-comfirm/${res.data.data.code}`)
      } else {
        notification.error({ message: res.data.message })
      }
    } catch (err) {
      console.log(err)
      notification.error({
        message: `${err}`,
      })
    }
  }

  const getData = async (api, callback) => {
    try {
      const res = await api({ branch_id: branchIdApp })
      if (res.status === 200) callback(res.data.data)
    } catch (e) {
      console.log(e)
    }
  }

  const _getCustomers = async () => {
    try {
      setLoadingCustomer(true)
      const res = await getCustomers()
      if (res.status === 200) setCustomers(res.data.data)
      setLoadingCustomer(false)
    } catch (error) {
      setLoadingCustomer(false)
      console.log(error)
    }
  }
  const _getBranches = async () => {
    try {
      const res = await getAllBranch()
      if (res.status === 200) {
        const data = res.data.data.filter((item) => item.active)
        let info = res.data.data.find((e) => e.branch_id === branchIdApp)
        setInfoBranch(info)
        formInfoOrder.setFieldsValue({ branch_id: data[0].branch_id })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const [availableOrder, setAvailableOrder] = useState(false)
  const [visiblePayments, setVisiblePayments] = useState(false)

  const onCheckPaymentValid = (payments) => {
    var value_payment = 0
    if (payments.map !== undefined) {
      payments.map((item) => {
        value_payment += item.value
      })
      if (value_payment >= orderCreate.moneyToBePaidByCustomer) {
        setAvailableOrder(true)
      } else {
        setAvailableOrder(false)
      }
    }
  }
  useEffect(() =>{
      dispatch(getProductsSystem({branchIdApp: branchIdApp}))
  },[branchIdApp])
  const _getProductsSearch = async () => {
    try {
      setLoadingProduct(true)
      const res = await getProducts({
        branch_id: branchIdApp,
        merge: true,
        detach: true,
        ...paramsFilterSearchHeader,
      })
      if (res.status === 200) {
        let new_data = res.data.data.map((e) => ({
          ...e.variants,
          active: e.active,
          is_pre_order: e.is_pre_order,
          unit: e.unit,
          description: e.description,
          inventory: e.inventory,
          type_advanced: e.type_advanced || 1,
        }))
        const data = new_data.map((e) => {
          if (e.type_advanced === 3) {
            return {
              ...e,
              ...e.location_advanced?.location?.reduce((arr, item) => {
                arr['quantity'] += 0
                arr['imei'] = arr.imei.concat(item.imei)
                return arr
              }),
            }
          }
          return e
        })
        setProductsSearch(data)
      }
      setLoadingProduct(false)
    } catch (error) {
      console.log(error)
      setLoadingProduct(false)
    }
  }
  const _getShippings = async () => {
    try {
      const res = await getShippings()
      if (res.status === 200) {
        setSelectedShipping(res.data.data[0].shipping_company_id)
        setDataShipping(res.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        let paymentMethodDefault = ''
        res.data.data.map((e) => {
          if (e.default && e.active) paymentMethodDefault = e
        })
        if (paymentMethodDefault) {
          const pDefault = {
            name: paymentMethodDefault.name,
            value: 0,
            payment_method_id: paymentMethodDefault.payment_method_id,
          }
          setPaymentsMethod(pDefault)
          _editOrder('payments', [pDefault])
          setPaymentMethodDefault(pDefault)
        } else {
          const dataActive = res.data.data
            .filter((e) => e.active)
            .map((e) => {
              return e
            })
          _editOrder('payments', [
            {
              name: dataActive[0].name,
              value: 0,
              payment_method_id: dataActive[0].payment_method_id,
            },
          ])
          setPaymentMethodDefault({
            name: dataActive[0].name,
            value: 0,
            payment_method_id: dataActive[0].payment_method_id,
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const changeShipping = (e) => {
    setSelectedShipping(e)
    let shippingNew = dataShipping.find((item) => item.shipping_company_id === e)
    if (shippingNew) setShippingInfo(shippingNew)
  }
  useEffect(() => {
    // checkPermissionViewDetail()
    // _getBranchEmployee()
    _getCustomers()
    _getCustomerAfterEditCustomer()
    _getShippings()
    _getPayments()
  }, [])
  useEffect(() => {
    _getProductsSearch()
  }, [branchIdApp, paramsFilterSearchHeader])

  useEffect(() => {
    getData(getTaxs, setTaxList)
    _getBranches()
  }, [branchIdApp])

  return (
    <div className="card">
      <TitlePage
        isAffix
        title={
          <Row align="middle" style={{ cursor: 'pointer' }} onClick={() => history.push(ROUTES.ORDER_LIST)}>
            <ArrowLeftOutlined style={{ marginRight: 5 }} />
            {t('order_list.create_an_order')}
          </Row>
        }
      >
        <Button onClick={createOrder} size="large" type="primary">
          {t('order_create.create_menu_and_browse')}
        </Button>
      </TitlePage>
      <Form layout="vertical" form={formInfoOrder}>
        <Row gutter={30} style={{ marginTop: 20 }}>
          <Col span={16}>
            <div className={styles['block']} style={{ marginBottom: 33 }}>
              <Row justify="space-between" className={styles['title']}>
                <div>{t('order_create.customer_information')}</div>
              </Row>
              <Row justify="space-between" align="middle" wrap={false}>
                <Select
                  notFoundContent={loadingCustomer ? <Spin /> : null}
                  dropdownClassName="dropdown-select-search-product"
                  allowClear
                  showSearch
                  style={{ width: '100%', marginRight: 20 }}
                  placeholder={t('sell.finding_customers')}
                  value={null}
                >
                  {customers.map((customer, index) => (
                    <Select.Option value={customer.phone} key={index}>
                      <Row
                        style={{ padding: '7px 13px' }}
                        align="middle"
                        justify="space-between"
                        wrap={false}
                        onClick={(e) => {
                          setCustomerInfo(customer)
                          setDeliveryAddress(customer)
                        }}
                      >
                        <div>
                          <p style={{ fontWeight: 600, marginBottom: 0 }}>
                            {customer.first_name + ' ' + customer.last_name}
                          </p>
                          <p style={{ marginBottom: 0 }}>{customer.phone}</p>
                        </div>
                        <CheckOutlined
                          style={{
                            color: 'green',
                            fontSize: 18,
                            display:
                              customerInfo && customerInfo._id === customer._id ? '' : 'none',
                          }}
                        />
                      </Row>
                    </Select.Option>
                  ))}
                </Select>
                <Permission permissions={[PERMISSIONS.them_khach_hang]}>
                  <ModalCustomerForm>
                    <Tooltip placement="bottom" title={t('sell.add_new_customer')}>
                      <PlusSquareFilled
                        style={{ fontSize: 34, color: '#0362BA', cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </ModalCustomerForm>
                </Permission>
              </Row>

              <Row
                wrap={false}
                align="middle"
                style={{
                  display: !customerInfo && 'none',
                  marginTop: 15,
                }}
              >
                <UserOutlined style={{ fontSize: 28, marginRight: 15 }} />
                <div style={{ width: '100%' }}>
                  <Row wrap={false} align="middle">
                    {customerInfo ? (
                      <Permission permissions={[PERMISSIONS.cap_nhat_khach_hang]}>
                        {/* <Link to={{ pathname: ROUTES.CUSTOMER_DETAIL + '/' + customerInfo.code, state: customerInfo }} > */}
                        <a style={{ fontWeight: 600, marginRight: 5 }}>
                          {customerInfo && customerInfo.first_name + ' ' + customerInfo.last_name}
                        </a>
                        {/* </Link> */}
                      </Permission>
                    ) : (
                      <div></div>
                    )}

                    <span style={{ fontWeight: 500 }}> - {customerInfo && customerInfo.phone}</span>
                  </Row>
                  <Row wrap={false} justify="space-between" align="middle">
                    <div>
                      <span style={{ fontWeight: 600 }}>{t('sell.debts')} </span>
                      <span>{customerInfo && customerInfo.debt}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 600 }}>{t('sell.current_score')} </span>
                      <span>{customerInfo && customerInfo.point}</span>
                    </div>
                  </Row>
                </div>

                <CloseCircleTwoTone
                  style={{ cursor: 'pointer', marginLeft: 20, fontSize: 23 }}
                  onClick={() => {
                    setDeliveryAddress(null)
                    setCustomerInfo(null)
                  }}
                />
              </Row>

              <Divider />
              {deliveryAddress && (
                <>
                  <Row justify="space-between">
                    <span className={styles['payment-title']}>{t('common.delivery_address')}</span>
                  </Row>
                  {deliveryAddress && (
                    <div style={{ fontSize: 14.7 }}>
                      <div>
                        {`${deliveryAddress.first_name} ${deliveryAddress.last_name}`} -{' '}
                        {deliveryAddress.phone}
                      </div>
                      <div>
                        {deliveryAddress?.address || ''}
                        {deliveryAddress.ward &&
                          (', ' + deliveryAddress.ward.ward_name + ', ' ||
                            ', ' + deliveryAddress.ward + ',' ||
                            '')}

                        {deliveryAddress.district &&
                          (deliveryAddress.district.district_name + ', ' ||
                            deliveryAddress.district + ', ' ||
                            '')}

                        {deliveryAddress.province &&
                          (deliveryAddress.province.province_name ||
                            deliveryAddress.province ||
                            '')}
                      </div>
                    </div>
                  )}
                  <ListDeliveryAddress
                    customer={customerInfo}
                    address={deliveryAddress}
                    setNewDeliveryAddress={setDeliveryAddress}
                  />
                </>
              )}
            </div>

            <div className={styles['block']}>
              <div className={styles['title']}>{t('common.product')}</div>

              <Row>
                <Col span={22}>
                  <div className="select-product-sell">
                    <Select
                      style={{ width: '100%' }}
                      notFoundContent={loadingProduct ? <Spin size="small" /> : null}
                      dropdownClassName="dropdown-select-search-product"
                      allowClear
                      showSearch
                      value={null}
                      clearIcon={<CloseOutlined style={{ color: 'black' }} />}
                      suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
                      className={styles['search-product']}
                      placeholder={t('sell.add_product_to_invoice')}
                      filterOption={(input, option) =>
                        removeUnicode(option.key.toString())
                          .toLowerCase()
                          .trim()
                          .includes(removeUnicode(input).toLowerCase().trim())
                      }
                      onChange={(value, option) => {
                        if (value !== null && value !== undefined && value !== '') {
                          let index = value.lastIndexOf('/')
                          let resultIndex = value.substring(index + 1)
                          let productResults = [...products]

                          if (productResults[resultIndex].inventory === 'normal') {
                            if (productResults[resultIndex].is_open === false) {
                              return notification.error({
                                message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                              })
                            } else {
                              _addProductToOrder(productResults[resultIndex])
                            }
                          } else {
                            if (productResults[resultIndex].total_quantity === 0) {
                              return notification.error({
                                message: 'Sản phẩm không đủ số lượng bán',
                              })
                            }
                            _addProductToOrder(productResults[resultIndex])
                          }

                          if (productResults.length === 0) {
                            return notification.error({
                              message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                            })
                          }
                        }
                      }}
                      dropdownRender={(menu) => (
                        <div>
                          <Permission permissions={[PERMISSIONS.ban_hang]}>
                            <Row
                              wrap={false}
                              align="middle"
                              style={{ cursor: 'pointer' }}
                              onClick={() => window.open(ROUTES.PRODUCT_ADD, '_blank')}
                            >
                              <Row align="middle" style={{ paddingLeft: 15, height: 50 }}>
                                <PlusSquareOutlined style={{ fontSize: 19 }} />
                              </Row>
                              <span style={{ marginLeft: 20, marginBottom: 0, fontSize: 16 }}>
                                {t('sell.add_new_products')}
                              </span>
                            </Row>
                          </Permission>
                          {loadingProduct ? <Spin size="middle" /> : menu}
                        </div>
                      )}
                    >
                      {products &&
                        products.map(
                          (data, index) =>
                            data && (
                              <Select.Option
                                key={data.title + data.sku + data.barcode + '/' + index + ''}
                              >
                                <Row align="middle" wrap={false} style={{ padding: '7px 13px' }}>
                                  <img
                                    src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                                    alt=""
                                    style={{
                                      minWidth: 55,
                                      minHeight: 55,
                                      maxWidth: 55,
                                      maxHeight: 55,
                                      objectFit: 'cover',
                                    }}
                                  />

                                  <div style={{ width: '100%', marginLeft: 15 }}>
                                    <Row wrap={false} justify="space-between">
                                      <span
                                        style={{
                                          maxWidth: 300,
                                          marginBottom: 0,
                                          fontWeight: 600,
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          WebkitLineClamp: 1,
                                          WebkitBoxOrient: 'vertical',
                                          display: '-webkit-box',
                                        }}
                                      >
                                        {data.title}
                                      </span>
                                      <span style={{ marginBottom: 0, fontWeight: 500 }}>
                                        {formatCash(data.price)}
                                      </span>
                                    </Row>
                                    <Row wrap={false} justify="space-between">
                                      <span style={{ marginBottom: 0, fontWeight: 500 }}>
                                        {data.active ? (
                                          t('sell.on_sale')
                                        ) : (
                                          <div style={{ color: '#ff6666' }}>
                                            {t('sell.sale_off')}
                                          </div>
                                        )}
                                      </span>
                                      <span
                                        style={{
                                          marginBottom: 0,
                                          color: 'gray',
                                          display: !data.is_pre_order && 'none',
                                        }}
                                      >
                                        pre-order
                                      </span>
                                    </Row>
                                    <Row wrap={false} justify="space-between">
                                      <span style={{ marginBottom: 0, color: 'gray' }}>
                                        {data.sku}
                                      </span>
                                      {data.inventory !== 'normal' ? (
                                        <span style={{ marginBottom: 0, color: 'gray' }}>
                                          {t('sell.can_be_sold')}: {formatCash(data.total_quantity)}
                                        </span>
                                      ) : data.is_open === true ? (
                                        <span style={{ marginBottom: 0, color: 'gray' }}>
                                          Còn hàng
                                        </span>
                                      ) : (
                                        <span style={{ marginBottom: 0, color: 'gray' }}>
                                          Hết hàng
                                        </span>
                                      )}
                                    </Row>
                                  </div>
                                </Row>
                              </Select.Option>
                            )
                        )}
                    </Select>
                  </div>
                </Col>
                <Col span={2}>
                  <div
                    style={{
                      background: '#5F73E2',
                      marginLeft: '10px',
                      border: '1px solid blue',
                      borderRadius: '5px',
                    }}
                  >
                    <ScanProduct
                      productsCurrent={orderCreate.order_details}
                      addProductToCartInvoice={_addProductToOrder}
                    />
                  </div>
                </Col>
              </Row>

              <TableProduct
                pagination={false}
                columns={columns}
                rowKey="variant_id"
                size="small"
                defaultExpandAllRows={true}
                dataSource={[...orderCreate.order_details]}
                expandable={{
                  expandedRowKeys: orderCreate.order_details.map((o) => o.variant_id),
                  defaultExpandAllRows: true,
                  expandedRowRender: (record, index) =>
                    <RenderRowTypeAdvanced product={record} index={index} /> || [],
                  rowExpandable: (record, index) =>
                    record.type_advanced === 2 || record.type_advanced === 3,
                }}
                locale={{
                  emptyText: (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200,
                      }}
                    >
                      <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                      <h4 style={{ fontSize: 15, color: '#555' }}>
                        {t('sell.your_order_has_no_products')}
                      </h4>
                    </div>
                  ),
                }}
                summary={() => (
                  <Table.Summary.Row
                    style={{ display: orderCreate.order_details.length === 0 && 'none' }}
                  >
                    <Table.Summary.Cell colSpan={5}>
                      <div className={styles['block']} style={{ marginTop: 100, width: '100%' }}>
                        <Row wrap={false} align="middle" style={{ fontWeight: 600 }}>
                          <CarFilled style={{ fontSize: 17 }} />
                          <div style={{ margin: '0px 5px' }}>{'Phương thức giao hàng'}</div>
                        </Row>
                        <div style={{ marginTop: 10 }}>
                          <Radio.Group
                            onChange={(value) => onChangeMethodSell(value)}
                            value={methodSell}
                          >
                            <Radio value={'ship'}>{'Gọi vận chuyển'}</Radio>
                            <Radio value={'store'}>{'Nhận tại nơi bán'}</Radio>
                            <Radio value={'pre-order'}>{'Đặt trước (giao sau)'}</Radio>
                          </Radio.Group>

                          {methodSell === 'ship' &&
                            (deliveryAddress &&
                            deliveryAddress.district &&
                            deliveryAddress.province &&
                            deliveryAddress.ward ? (
                              infoBranch &&
                              infoBranch.district != '' &&
                              infoBranch.province != '' &&
                              infoBranch.ward != '' ? (
                                <Space>
                                  <Row span={12}>
                                    <Select
                                      defaultValue={selectedShipping}
                                      value={selectedShipping}
                                      style={{ width: '100%' }}
                                      onChange={(e) => changeShipping(e)}
                                    >
                                      {dataShipping.map((data) => (
                                        <Option value={data.shipping_company_id}>
                                          {data.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    {selectedShipping === 3 && (
                                      <GHN
                                      deliveryAddress={deliveryAddress}
                                      branchInfo={infoBranch}
                                      />
                                    )}
                                    {selectedShipping === 2 && (
                                      <GHTK
                                        deliveryAddress={deliveryAddress}
                                        branchInfo={infoBranch}
                                        customer={customerInfo}
                                        shipping_info={shippingInfo}
                                        edit={_editShipping}
                                      />
                                    )}{selectedShipping === 5 && (
                                      <ViettelPost
                                        deliveryAddress={deliveryAddress}
                                        branchInfo={infoBranch}
                                        customer={customerInfo}
                                        shipping_info={shippingInfo}
                                      />
                                    )}
                                    <Input
                                      style={{ marginTop: 10 }}
                                      // disabled={!selectedShipping}
                                      placeholder="Mã vận đơn"
                                      onChange={(e) => setCheckingNumber(e.target.value)}
                                      disabled={disabled}
                                    />
                                    <Checkbox onClick={toggleDisableCode} style={{ marginTop: 5 }}>
                                      {'Tự động gọi vận chuyển khi tạo đơn'}
                                    </Checkbox>
                                  </Row>
                                </Space>
                              ) : (
                                <Row span={12}>
                                  <div style={{ color: 'red', display: 'flex' }}>
                                    Vui lòng điền đầy đủ địa chỉ của cửa hàng
                                    <BranchForm reloadData={_getBranches} record={infoBranch}>
                                      <span
                                        style={{
                                          textDecoration: 'underline',
                                          color: '#5b6be8',
                                          marginLeft: 7,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Tại đây
                                      </span>
                                    </BranchForm>
                                  </div>
                                </Row>
                              )
                            ) : (
                              <Row span={12}>
                                <div style={{ color: 'red' }}>
                                  Vui lòng điền đầy đủ địa chỉ giao hàng để gọi vận chuyển
                                </div>
                              </Row>
                            ))}

                          {methodSell === 'store' && <div></div>}

                          {methodSell === 'pre-order' && <div>{'Chọn thời gian: '}</div>}
                        </div>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={4}>
                      <div style={{ fontSize: 14.4 }}>
                        <Row wrap={false} justify="space-between">
                          <div>
                            {t('common.total_payment')} ({orderCreate.order_details.length}{' '}
                            {t('common.product')})
                          </div>
                          <div>
                            {orderCreate.sumCostPaid
                              ? formatCash(+orderCreate.sumCostPaid - orderCreate.discount_product)
                              : 0}
                          </div>
                        </Row>
                        <Row
                          style={{
                            height: 20,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          wrap={false}
                          justify="space-between"
                        ></Row>
                        <Row
                          style={{ marginTop: 5, marginBottom: 5 }}
                          wrap={false}
                          justify="space-between"
                        >
                          <div>{t('common.discount')}</div>
                          <div>
                            <ModalDiscountInvoice invoices={orderCreate} />
                          </div>
                        </Row>
                        <Row wrap={false} justify="space-between">
                          <div>Khuyến mãi: </div>
                          <div>
                            <PromotionAvailable
                              invoiceCurrent={orderCreate}
                              editOrder={_editOrder}
                              checkData={orderCreate.promotion.promotion_code}
                            />
                          </div>
                        </Row>
                        <Row wrap={false} justify="space-between">
                          <div>
                            {orderCreate.promotion.promotion_code !== undefined ? (
                              <Row justify="space-between" wrap={false} align="middle">
                                <div>
                                  <a style={{ padding: 0 }}>
                                    {orderCreate.promotion.promotion_code}
                                  </a>
                                  <Popconfirm
                                    title="Bạn muốn gỡ khuyến mãi này?"
                                    onConfirm={() => {
                                      _editOrder('promotion', {})
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      size="small"
                                      style={{ marginLeft: '7px' }}
                                      icon={<CloseOutlined />}
                                    ></Button>
                                  </Popconfirm>
                                </div>
                              </Row>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div>
                            <p>{formatCash(orderCreate.promotion.discount)}</p>
                          </div>
                        </Row>
                        <Row wrap={false} justify="space-between">
                          <div>Thuế</div>
                          <Select
                            maxTagCount="responsive"
                            // size="small"
                            showSearch
                            style={{
                              width: 200,
                              height: 30,
                            }}
                            placeholder="Chọn thuế"
                            optionFilterProp="children"
                            allowClear
                            mode="tags"
                            value={orderCreate.list_tax.map((tax) => tax.tax_id)}
                            onChange={(value) => {
                              const taxListNew = taxList.filter((tax) => value.includes(tax.tax_id))
                              _editOrder('list_tax', taxListNew)
                            }}
                          >
                            {taxList.map((tax, index) => (
                              <Select.Option value={tax.tax_id} key={index}>
                                {tax.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                        <Row wrap={false} align="middle">
                          <div style={{ width: '100%', marginBottom: 10 }}>
                            {orderCreate.list_tax.map((tax) => (
                              <Row justify="space-between" wrap={false} align="middle">
                                <p style={{ marginBottom: 0 }}>
                                  {tax.name} ({tax.value} %)
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                  {formatCash(orderCreate.list_taxSum)}
                                </p>
                              </Row>
                            ))}
                          </div>
                        </Row>
                        <Row wrap={false} justify="space-between" align="middle">
                          <div>{t('common.delivery_charges')}</div>
                          <Form.Item
                            rules={[{ required: true, message: 'Vui lòng nhập phí ship' }]}
                          >
                            <InputNumber
                              style={{ minWidth: 120 }}
                              onBlur={(e) => {
                                const value = e.target.value.replaceAll(',', '')
                                _editOrder('fee_shipping', +value)
                              }}
                              min={0}
                              size="small"
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              defaultValue={orderCreate.fee_shipping}
                            />
                          </Form.Item>
                        </Row>
                        <Row wrap={false} justify="space-between" style={{ fontWeight: 600 }}>
                          <div>{t('common.guest_must_pay')}</div>
                          <div>{formatCash(+orderCreate.moneyToBePaidByCustomer || 0)}</div>
                        </Row>
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            </div>
            <div className={styles['block']} style={{ marginTop: 30 }}>
              <Row wrap={false} align="middle" style={{ fontWeight: 600 }}>
                <CreditCardFilled style={{ fontSize: 17 }} />
                <div style={{ margin: '0px 5px' }}>Thông tin thanh toán</div>
              </Row>
              <Divider />
              <div>
                <Row justify="space-between" wrap={false} align="middle">
                  <span
                    style={{ marginBottom: 0, fontWeight: '700', color: '#0877de', fontSize: 15 }}
                  >
                    {t('common.payment_method') + '(F8):'}
                  </span>
                  <PaymentMethods
                    onCheckPaymentValid={onCheckPaymentValid}
                    setVisible={setVisiblePayments}
                    visible={visiblePayments}
                    moneyToBePaidByCustomer={orderCreate.moneyToBePaidByCustomer}
                    invoices={orderCreate}
                    editInvoice={_editOrder}
                  />
                </Row>

                {!availableOrder && (
                  <div style={{ color: 'red' }}>{'Vui lòng điền số tiền thanh toán phù hợp'}</div>
                )}
                <div style={{ marginBottom: 10 }}>
                  <Space size="middle">
                    {orderCreate.payments.map((payment) => (
                      <i style={{ color: '#637381' }}>
                        {payment.name} (
                        {formatCash(payment.value || orderCreate.moneyToBePaidByCustomer)})
                      </i>
                    ))}
                  </Space>
                </div>

                <Row justify="space-between" wrap={false}>
                  <div style={{ width: '45%' }}>
                    <div>{t('order_create.date_of_payment')}</div>
                    <DatePicker
                      defaultValue={moment(new Date(), 'DD/MM/YYYY HH:mm:ss')}
                      format="DD/MM/YYYY HH:mm:ss"
                      showTime={{ defaultValue: moment(new Date(), 'HH:mm:ss') }}
                      style={{ width: '100%' }}
                      placeholder={t('order_create.select_payment_date')}
                    />
                  </div>
                  <div style={{ width: '45%' }}>
                    <div>{t('order_create.reference')}</div>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      max={orderCreate.moneyToBePaidByCustomer}
                      placeholder={t('order_create.enter_reference')}
                    />
                  </div>
                </Row>
              </div>
            </div>
          </Col>

          <Col span={8}>
            <div className={styles['block']} style={{ marginBottom: 30 }}>
              <div className={styles['title']}>{t('common.order_information')}</div>

              <Form.Item style={{ marginBottom: '3px' }} name="staff_sell_id">
                Bán bởi :{' '}
                <Select
                  defaultValue={dataUser.user_id}
                  style={{
                    width: 120,
                  }}
                  bordered={false}
                >
                  <Select.Option key={dataUser.user_id} value={dataUser.user_id}>
                    {dataUser.first_name} {dataUser.last_name}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: '8px' }} name="channel_id">
                Nguồn :{' '}
                <Select
                  defaultValue="Web"
                  style={{
                    width: 120,
                  }}
                  onChange={(e) => setChannel(e.target.value)}
                  bordered={false}
                >
                  <Option value="Web">Web</Option>
                  <Option value="Facebook">Facebook</Option>
                  <Option value="Instagram">Instagram</Option>
                  <Option value="TiktokShop">TiktokShop</Option>
                  <Option value="Shopee">Shopee</Option>
                </Select>
              </Form.Item>

              <Form.Item label={t('common.code_orders')} name="code_id">
                <Input placeholder={t('common.enter_code_orders')} />
              </Form.Item>
            </div>

            <div className={styles['block']}>
              <div className={styles['title']}>
                {t('common.note')}{' '}
                <Tooltip
                  title={
                    <div style={{ textAlign: 'center' }}>
                      {t('order_create.add_note_information')}
                    </div>
                  }
                >
                  <InfoCircleTwoTone style={{ fontSize: 12 }} />
                </Tooltip>
              </div>
              <Form.Item name="note">
                <Input.TextArea rows={2} placeholder={t('common.enter_a_note')} />
              </Form.Item>

              <div className={styles['title']}>
                {'Nhãn '}
                <Tooltip
                  title={
                    <div style={{ textAlign: 'center' }}>
                      {t('order_create.select_or_add_tags_for_orders')}
                    </div>
                  }
                >
                  <InfoCircleTwoTone style={{ fontSize: 12 }} />
                </Tooltip>
              </div>
              <Form.Item name="tags">
                <Select mode="tags" placeholder={t('common.enter_tags')}></Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
