import { get, post, patch } from './httpClient'

export const checkDomain = (prefix) => post('/appinfo/checkdomain', { prefix })
export const getBusinesses = (query) => get('/business', query)
export const updateBusinesses = (body, id) => patch('/business/update/' + id, body)

export const updateConfigInvoiceSale = (body) => post('/appinfo/config-invoice-sale', body)
export const updateConfigInvoiceDelivery = (body) => post('/appinfo/config-invoice-complete', body)

export const getConfigInvoiceSale = () => get('/appinfo/config-invoice-sale')
export const getConfigInvoiceDelivery = () => get('/appinfo/config-invoice-complete')

export const settingCommon = (body) => post('/appinfo/setting-common', body)
