import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Layout, Row } from 'antd'
import LayoutTable from './layout-items/layout-table'
import LayoutForm from './layout-items/layout-form'
import LayoutHeader from './layout-items/layout-header'
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux'
import { getVariantsSystem } from '../../redux/actions/variantSell';
export default function ImportSmartProduct() {
  const [products, setProducts] = useState([])
  const [disableField, setDisableField] = useState(false)
  const [editProduct, setEditProduct] = useState(null)
  const [fileUpload, setFileUpload] = useState(null)
  const [loading, setLoading] = useState(false)
  const [productsError, setProductsError] = useState([])
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dispatch = useDispatch()
  
  const headers = [
    { label: "Lỗi", key: "error" },
    { label: "Mã hàng", key: "sku" },
    { label: "Tên hàng", key: "name" },
    { label: "Giá hàng", key: "price" },
    { label: "Giá nhập", key: "import_price" },
    { label: "Số lượng", key: "quantity" },
    { label: "Đơn vị", key: "unit" },
    { label: "Mô tả", key: "note" },
];

useEffect(() =>{
  if(branchIdApp) {
    dispatch(getVariantsSystem({branchIdApp: branchIdApp}))
  }
},[branchIdApp])

  return (
    <Layout style={{ padding: 20, backgroundColor: '#eeeff3' }}>
      <LayoutHeader
        setDisableField={setDisableField}
        setProducts={setProducts}
        setEditProduct={setEditProduct}
        products={products}
        fileUpload={fileUpload}
        setLoading={setLoading}
        loading={loading}
        setProductsError={setProductsError}
        productsError={productsError}
      />
      <Divider style={{marginTop: 0}}/>
      <Layout style={{ backgroundColor: 'white' }}>
        <Row gutter={[20, 8]}>
          <Col span={17}>
            <div style={{justifyContent: 'end', display: 'flex', marginBottom: '10px'}}>
            {productsError.length > 0 && <Button
                type="primary"
            >
                <CSVLink
                    headers={headers}
                    data={productsError}
                    filename={'Sản phẩm lỗi'}
                    style={{ "textDecoration": "none", "color": "#fff" }}
                >
                    Tải file lỗi
                </CSVLink>
            </Button>}
            </div>
            <LayoutTable
              products={products}
              setProducts={setProducts}
              setEditProduct={setEditProduct}
              loading={loading}
            />
          </Col>
          <Col span={7}>
            <LayoutForm
              setProducts={setProducts}
              products={products}
              disableField={disableField}
              editProduct={editProduct}
              setEditProduct={setEditProduct}
              setFileUpload={setFileUpload}
              loading={loading}
            />
            <span>
              <i>
                Chỉ nhập sản phẩm mới và bổ sung số lượng đối với sản phẩm tồn kho thường
              </i>
            </span>
          </Col>
        </Row>
      </Layout>
    </Layout>
  )
}
