let initialState = {
  branchId: '',
  branchIdArray: [],
  trigger: false,
  branches: [],
}

const branch = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BRANCH_ID': {
      return { ...state, branchId: action.data || '' }
    }
    case 'SET_BRANCH_ID_Array': {
      return { ...state, branchIdArray: action.data || '' }
    }

    case 'SET_BRANCHES': {
      const branchesNew = [...action.data]
      return { ...state, branches: branchesNew }
    }

    case 'TRIGGER_RELOAD_BRANCH': {
      return { ...state, trigger: !state.trigger }
    }

    default:
      return state
  }
}

export default branch
