import { combineReducers } from 'redux'
import login from './login.js'
import store from './store.js'
import branch from './branch.js'
import invoice from './invoice.js'
import setting from './setting.js'
import menu from './menu.js'
import district from './districts.js'
import province from './provinces.js'
import ward from './wards'
import shipping_company from './shipping-company.js'
import products from './products'
import socket from './socket'
import posts from './posts'
import feed from './feed'
import idAll from './id-all'
import loading from './loading'
import connect_device from './connect-device'
import role from './role'
import variant from './variant'
import indexInvoice from './indexInvoice.js'
const rootReducers = combineReducers({
  login,
  store,
  branch,
  invoice,
  setting,
  menu,
  district,
  province,
  ward,
  shipping_company,
  products,
  socket,
  posts,
  feed,
  idAll,
  loading,
  connect_device,
  role,
  variant,
  indexInvoice
})
export default rootReducers
