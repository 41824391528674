const columnsProduct = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: '3%',
  },
  {
    title: 'Ảnh',
    key: 'image',
    align: 'center',
    width: '6%',
  },
  {
    title: 'Sản phẩm',
    dataIndex: 'name',
    key: 'name-product',
    align: 'center',
    width:'10%',
  },
  {
    title: 'Mã SKU',
    key: 'sku_1',
    align: 'center',
    width:'10%',
  },
  {
    title: 'Giá bán lẻ',
    key: 'cost_1',
    align: 'center',
    width:'8%',
  },
  {
    title: 'Có thể bán',
    key: 'open_sell_quantity',
    align: 'center',
    width:'8%',
  },
  {
    title: 'Số lượng tồn',
    key: 'total_inventory',
    align: 'center',
    width:'8%',
  },
  {
    title: 'Ngày khởi tạo',
    key: 'create_date',
    align: 'center',
    width:'10%',
  },
  {
    title: 'Ghi chú',
    key: 'description',
    align: 'center',
  },
  {
    title: 'Hành động',
    key: 'active',
    align: 'center',
    width:'9%',
  },
]

export default columnsProduct
