import { LoadMenu } from './menu'
import { Districts } from './districts'
import { Provinces } from './provinces'
import { Wards } from './wards'
import { ShippingCompanies } from './shipping-company'
import { Products } from './products'
import { LoadFeed } from './feed'
import { IdAll } from './id-all'
export async function Preload() {
  await Promise.all([
    LoadMenu(),
    // Districts(),
    // Provinces(),
    // Wards(),
    ShippingCompanies(),
    // Products(),
    LoadFeed(),
    // IdAll(),
  ])
}
