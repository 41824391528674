import React, { useEffect, useRef, useState } from 'react'
import styles from '../point.module.scss'
//antd
import { Row, Select, Radio, Col, Checkbox, InputNumber } from 'antd'
//api
import { getAllBranch } from 'apis/branch'
import { getCategories } from 'apis/category'
import { useSelector } from 'react-redux'
import { getProducts } from 'apis/product'
import { getCustomerTypes } from 'apis/customer'
import removeVietnameseTones from 'utils/removeUnicode'
import { removeUnicode } from 'utils'

export default function AdvancedSetting(props) {
  const {
    setConfig,
    config,
    orderPoint,
    setOrderPoint,
    setLoading
  } = props
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [branches, setBranches] = useState([])
  const [dataCategories, setDataCategories] = useState([])
  const [dataProduct, setDataProduct] = useState([])
  const [customerTypes, setCustomerTypes] = useState([])

  const _getCustomerTypes = async (params) => {
    try {
      setLoading(true)
      const res = await getCustomerTypes(params)
      if (res.status === 200) setCustomerTypes(res.data.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const _getBranches = async (params) => {
    try {
      setLoading(true)
      const res = await getAllBranch(params)
      if (res.data.success) {
        setBranches(res.data.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const _getCategories = async (params) => {
    try {
      setLoading(true)
      const res = await getCategories(params)
      if (res.status === 200) setDataCategories(res.data.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const _getProducts = async () => {
    try {
      setLoading(true)
      const res = await getProducts()
      if (res.status === 200) setDataProduct(res.data.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    _getProducts()
  }, [branchIdApp])
  useEffect(() => {
    _getBranches()
    _getCategories()
    _getCustomerTypes()
  }, [])
  return (
    <Row justify="space-between" className={styles['point-bottom']}>
      <Col md={6} lg={6} xl={6}>
        <h3>Thiết lập nâng cao</h3>
        <p>Những thiết lập liên quan đến thanh toán và đối tượng được áp dụng.</p>
      </Col>
      <Col md={16} lg={16} xl={16} className={styles['point-bottom-right']}>
        <Row className={styles['point-bottom-right-payment']}>
          <h4>THANH TOÁN</h4>
          <p>Tỷ lệ quy điểm ra tiền</p>
          <div style={{ color: 'red' }}>1 điểm = 1đ</div>
          <div className={styles['format-display']}>
            <div>
              <Checkbox
                checked={orderPoint.order_require_check}
                onChange={(e) =>
                  setOrderPoint({
                    ...orderPoint,
                    order_require_check: e.target.checked,
                  })
                }
              >
                Cho phép thanh toán bằng điểm sau
              </Checkbox>
              <InputNumber
                disabled={!orderPoint.order_require_check}
                onChange={(e) =>
                  setOrderPoint({
                    ...orderPoint,
                    order_require: e,
                  })
                }
                value={orderPoint.order_require}
                min={0}
                style={{ width: '15%' }}
              />{' '}
              lần mua
            </div>
            <Checkbox
              checked={config.accumulate_for_payment_point}
              onChange={(e) =>
                setConfig({
                  ...config,
                  accumulate_for_payment_point: e.target.checked,
                })
              }
            >
              Tích điểm khi khách hàng thanh toán hóa đơn bằng điểm
            </Checkbox>
            <Checkbox
              checked={config.accumulate_for_fee_shipping}
              onChange={(e) =>
                setConfig({
                  ...config,
                  accumulate_for_fee_shipping: e.target.checked,
                })
              }
            >
              Tích điểm cho giá trị thanh toán bao gồm phí vận chuyển
            </Checkbox>
          </div>
        </Row>

        <Row className={styles['point-bottom-right-content']}>
          <h4>KHÁCH HÀNG ÁP DỤNG TÍCH ĐIỂM</h4>
          <Radio.Group
            className={styles['point-bottom-right-radio']}
            onChange={(e) => setConfig({ ...config, all_customer_type: e.target.value })}
            value={config.all_customer_type}
          >
            <Radio value={true}>Tất cả khách hàng</Radio>
            <Radio value={false}>Theo nhóm khách hàng</Radio>
          </Radio.Group>
          <Row>
            <Select
              mode="multiple"
              placeholder="Chọn loại khách hàng"
              size="small"
              value={config.customer_type_id}
              onChange={(e) => setConfig({ ...config, customer_type_id: e })}
              style={{
                display: `${config.all_customer_type === false ? 'block' : 'none'}`,
                width: '50%',
                marginTop: 10,
              }}
              filterOption={(input, option) =>
                removeUnicode(option.children.toString())
                  .toLowerCase()
                  .trim()
                  .includes(removeUnicode(input).toLowerCase().trim())
              }
            >
              {customerTypes.length !== 0
                ? customerTypes.map((type, index) => (
                    <Select.Option value={type.type_id} key={index}>
                      {type.name}
                    </Select.Option>
                  ))
                : ''}
            </Select>
          </Row>
        </Row>

        <Row className={styles['point-bottom-right-content']}>
          <h4>NHÓM SẢN PHẨM ÁP DỤNG TÍCH ĐIỂM</h4>
          <Radio.Group
            className={styles['point-bottom-right-radio']}
            onChange={(e) => setConfig({ ...config, all_category: e.target.value })}
            value={config.all_category}
          >
            <Radio value={true}>Tất cả sản phẩm</Radio>
            <Radio value={false}>Theo loại sản phẩm</Radio>
          </Radio.Group>
          <Row>
            <Select
              mode="multiple"
              placeholder="Chọn nhóm sản phẩm"
              size="small"
              value={config.category_id}
              onChange={(e) => setConfig({ ...config, category_id: e })}
              style={{
                display: `${config.all_category === false ? 'block' : 'none'}`,
                width: '50%',
                marginTop: 10,
              }}
              filterOption={(input, option) =>
                removeUnicode(option.children.toString())
                  .toLowerCase()
                  .trim()
                  .includes(removeUnicode(input).toLowerCase().trim())
              }
            >
              {dataCategories.length !== 0
                ? dataCategories.map((e) => (
                    <Select.Option value={e.category_id}>{e.name}</Select.Option>
                  ))
                : ''}
            </Select>
          </Row>
        </Row>

        <Row className={styles['point-bottom-right-content']}>
          <h4>SẢN PHẨM ÁP DỤNG TÍCH ĐIỂM</h4>
          <Radio.Group
            className={styles['point-bottom-right-radio']}
            onChange={(e) => setConfig({ ...config, all_product: e.target.value })}
            value={config.all_product}
          >
            <Radio value={true}>Tất cả sản phẩm</Radio>
            <Radio value={false}>Theo sản phẩm</Radio>
          </Radio.Group>
          <Row>
            <Select
              mode="multiple"
              placeholder="Chọn sản phẩm"
              size="small"
              value={config.product_id}
              onChange={(e) => {
                setConfig({ ...config, product_id: e })
              }}
              style={{
                display: `${config.all_product === false ? 'block' : 'none'}`,
                width: '50%',
                marginTop: 10,
              }}
              filterOption={(input, option) =>
                removeUnicode(option.children.toString())
                  .toLowerCase()
                  .trim()
                  .includes(removeUnicode(input).toLowerCase().trim())
              }
            >
              {dataProduct.length !== 0
                ? dataProduct.map((e, index) => (
                    <Select.Option
                      key={e.name + e.sku + e.barcode + '/' + index + ''}
                      value={e.product_id}
                    >
                      {e.name}
                    </Select.Option>
                  ))
                : ''}
            </Select>
          </Row>
        </Row>

        <Row className={styles['point-bottom-right-content']}>
          <h4>CHI NHÁNH ÁP DỤNG TÍCH ĐIỂM</h4>
          <Radio.Group
            className={styles['point-bottom-right-radio']}
            onChange={(e) => setConfig({ ...config, all_branch: e.target.value })}
            value={config.all_branch}
          >
            <Radio value={true}>Tất cả chi nhánh</Radio>
            <Radio value={false}>Theo từng chi nhánh</Radio>
          </Radio.Group>
          <Row>
            <Select
              mode="multiple"
              placeholder="Chọn chi nhánh"
              size="small"
              value={config.branch_id}
              onChange={(e) => setConfig({ ...config, branch_id: e })}
              style={{
                display: `${config.all_branch === false ? 'block' : 'none'}`,
                width: '50%',
                marginTop: 10,
              }}
              filterOption={(input, option) =>
                removeUnicode(option.children.toString())
                  .toLowerCase()
                  .trim()
                  .includes(removeUnicode(input).toLowerCase().trim())
              }
            >
              {branches.map((e) => (
                <Select.Option value={e.branch_id}>{e.name}</Select.Option>
              ))}
            </Select>
          </Row>
        </Row>
      </Col>
    </Row>
  )
}
