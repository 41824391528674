import React, { useState, useEffect } from 'react'
import { Button, Col, Input, Modal, notification, Row, Select } from 'antd'
import { removeUnicode } from 'utils'
import { getLocationImei } from 'apis/location'
import { useSelector } from 'react-redux'

const ItemProductImei = ({ quantity, editProductInOrder, variant_id, listProduct }) => {
  const [open, setOpen] = useState(false)
  const [listImei, setListImei] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const showModal = () => {
    setOpen(true)
  }
  const [iMei, setImei] = useState([])
  const _getLocationImei = async () => {
    try {
      const res = await getLocationImei({ variant_id: variant_id, branch_id: branchIdApp })
      if (res.status == 200) {
          setImei(res.data.data.imei)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    _getLocationImei()
  }, [branchIdApp])

  const handleChangeImei = (item) => {
    let new_data = [...listImei]
    for(let data of item) {
      data = data.trim()
      if(isNaN(data)) {
        item = new_data
      } else {
        if(data < 0 || data.length == 0) {
          item = new_data
        }
      }
    }
    item = item.map(e => e.trim())
    let new_arr = [...new Set(item)]

    let arrErr = iMei.find(item => new_arr.includes(item))
    if(arrErr) {
      return notification.error({key: 'existImei', message: 'Imei đã tồn tại trong hệ thống, vui lòng kiểm tra lại!'})
    }

    setListImei(new_arr)
    let indexProduct = listProduct.findIndex((e) => e.variant_id === variant_id)
    editProductInOrder('quantity', +new_arr.length, indexProduct)
    editProductInOrder('imei', new_arr, indexProduct)
  }

  return (
    <Row justify="center">
      <Button
        // disabled={loading === true ? true : disabledImport === true ? true : false}
        onClick={showModal}
        size="small"
        style={{
          width: 120,
          borderRadius: '10px',
          boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
          transition: 'all .3s ease-in',
        }}
      >
        NHẬP IMEI
      </Button>
      <Modal
        width={500}
        height={400}
        title="Nhập imei"
        closable={false}
        visible={open}
        footer={[
          <Row justify="end" gutter={[16, 1]}>
            <Col>
              <Button onClick={() => setOpen(false)}>Xác nhận</Button>
            </Col>
          </Row>,
        ]}
      >
        <h1 style={{ fontSize: 14 }}>Vui lòng nhập đủ imei cho số lượng hiện tại</h1>
        <p>Số lượng hiện tại: {quantity || 1}</p>
        <Select
          value={listImei}
          max
          style={{ width: '100%' }}
          mode="tags"
          placeholder="Vui lòng nhập"
          onChange={(item) => {
            handleChangeImei(item)
          }}
        />
      </Modal>
    </Row>
  )
}
export default ItemProductImei
