import React from 'react'
import {
  AndroidOutlined,
  AppleOutlined,
  PrinterFilled,
  FireFilled,
  EditFilled,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { Tabs, Affix, Space, Button, Switch, Col, Divider, Row, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { ROUTES, PERMISSIONS, IMAGE_DEFAULT, FILTER_SIZE } from 'consts'
import Permission from 'components/permission'
import {
  FileExcelOutlined,
  FormOutlined,
  DollarOutlined,
  TeamOutlined,
  CreditCardOutlined,
  ClusterOutlined,
  PartitionOutlined,
  AlertOutlined,
  FieldTimeOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'
import TitlePage from 'components/title-page'
import moment from 'moment'
import styles from './setting-bill.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export default function SettingBill() {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Cấu hình mẫu hoá đơn
          </Row>
        }
      ></TitlePage>

      <div className={`${styles['configuration_content']} ${styles['card']}`}>
        <div style={{ marginBottom: 10, color: '#1A3873', fontSize: '1.25rem', fontWeight: '700' }}>
          Danh sách mẫu hoá đơn
        </div>
        <Row gutter={[16, 16]}>
          <Permission permissions={[PERMISSIONS.cau_hinh_mau_hoa_don]}>
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.CONFIG_INVOICE_SALE}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <DollarOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Cấu hình hoá đơn bán hàng
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: '0.75rem',
                      }}
                    >
                      Thiết lập mẫu hoá đơn
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>
          <Permission permissions={[PERMISSIONS.cau_hinh_mau_hoa_don]}>
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.CONFIG_INVOICE_DELIVERY}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <DollarOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Cấu hình hoá đơn giao hàng
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: '0.75rem',
                      }}
                    >
                      Thiết lập mẫu hoá đơn
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>
        </Row>
      </div>
    </div>
  )
}
