
export const handleCell = (record, index, data, entity, page_size, type = 0) => {
    let firstIndex = type === 0 ? data.findIndex(e => e[entity] === record[entity]) : data.findIndex(e => e[entity] === record[entity] && e.type === record.type)
    let listDataFilter = type === 0 ? data.filter(e => e[entity] === record[entity]) : data.filter(e => e[entity] === record[entity] && e.type === record.type)
    if (listDataFilter.length > 1) {
      if(firstIndex === index){
        return {
          rowSpan: listDataFilter.length,
        };
      }else if(index === page_size){
        let new_data = data.slice(index ,data.length)
        let amount = new_data.filter(e => e[entity] === record[entity])
        return {rowSpan : amount.length}
      }else return {
        colSpan: 0
      }
      
    }
    return {};
  };