import React, { useEffect, useState, useCallBack } from 'react'
import { Row, Modal, Button, InputNumber, Table, Popover, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './refund-form.module.scss'
import { EditOutlined } from '@ant-design/icons'
const ModalProductLocation = ({ product, index, ellipsis, _editProductInOrder }) => {
  const { Text } = Typography
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [productLocation, setProductLocation] = useState([...product.location_refund])
  const { t } = useTranslation()
  const handeChangeQuantity = (value, index) => {
    let new_product = [...productLocation]
    new_product[index].refund_quantity_new = value
    setProductLocation(new_product)
  }
  const columns = [
    {
      title: 'Mã lô',
      dataIndex: 'location_id',
      render: (text, record) => record.location_id,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'exp',
      render: (text, record) => record.exp,
    },
    {
      title: 'Số lượng mua',
      dataIndex: 'quantity',
      render: (text, record) => record.quantity,
    },
    {
      title: 'Số lượng đã trả',
      dataIndex: 'refund_quantity',
      render: (text, record) => record.total_refund_quantity,
    },
    {
      title: 'Số lượng trả',
      dataIndex: 'quantity_sell',
      render: (text, record, index) => (
        <InputNumber
          onChange={(value) => handeChangeQuantity(value, index)}
          className="show-handler-number"
          style={{ width: '100%' }}
          value={record.refund_quantity_new}
          bordered={false}
          max={record.refund_quantity_max}
          min={0}
          disabled={record.refund_quantity_max === 0 ? true : false}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder={t('sell.quantity')}
        />
      ),
    },
  ]

  useEffect(() => {
    setProductLocation([...product.location_refund])
  },[visible])

  return (
    <>
      <div
        onClick={() => {
          toggle()
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          color: 'gray',
          cursor: 'pointer',
        }}
      >
        <Popover style={{ maxWidth: 450 }} placement="bottomLeft">
          <Text
            style={ellipsis ? { width: 70 } : undefined}
            className={styles['sell-product__item-note']}
          >
            {'Chọn lô trả'}
          </Text>
        </Popover>

        <EditOutlined style={{ marginLeft: 5 }} />
      </div>
      <Modal
        title={`Chọn lô trả cho sản phẩm ${product.title}`}
        width={800}
        closable={false}
        visible={visible}
        footer={[
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button
                onClick={() => {
                  _editProductInOrder(
                    'location_refund',
                    productLocation.map((item) => ({
                      ...item,
                      refund_quantity_new: item.refund_quantity,
                    })),
                    index
                  )
                  toggle()
                }}
              >
                Đóng
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  _editProductInOrder(
                    'refund_quantity',
                    productLocation.reduce((total, item) => (total += item.refund_quantity_new), 0),
                    index
                  )
                  _editProductInOrder(
                    'location_refund',
                    productLocation.map((item) => ({
                      ...item,
                      refund_quantity: item.refund_quantity_new,
                    })),
                    index
                  )
                  toggle()
                }}
              >
                Xác nhận
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Table
          size="small"
          rowKey="location_id"
          scroll={{ y: 500 }}
          columns={columns}
          style={{ width: '100%' }}
          dataSource={productLocation}
        />
      </Modal>
    </>
  )
}

export default ModalProductLocation
