const columnsOrder = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: 50,
  },
  {
    title: 'Mã phiếu trả',
    dataIndex: 'refund_order_code',
    key: 'code',
  },
  {
    title: 'Mã đơn mua',
    dataIndex: 'code_order',
    key: 'code_order',
  },
  {
    title: 'Khách hàng',
    key: 'customer',
  },
  {
    title: 'P.T Thanh Toán',
    key: 'payment_infos',
  },
  {
    title: 'Số tiền hoàn trả',
    key: 'payment_amount',
  },
  {
    title: 'Ngày tạo phiếu',
    key: 'create_date',
  },
  {
    title: 'Nhân viên trả hàng',
    key: 'employee',
  },
  {
    title: 'Cập nhật cuối',
    key: 'last_update',
  },
  {
    title: 'Ghi chú',
    key: 'note',
  },
  {
    title: 'Trạng thái nhận hàng',
    key: 'receive_status',
  },
  {
    title: 'Trạng thái hoàn tiền',
    key: 'refund_status',
  },

]

export default columnsOrder
