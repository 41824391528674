import React, { useEffect, useState } from 'react'
import localStorage from "../../utils/localStorage";
import { copyText } from 'utils'
import { ACTION, VERSION_APP } from 'consts'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyList from 'currency-list'
import timezones from 'timezones-list'
import DropdownLanguage from '../../components/dropdown-language'
import { socket } from '../../socket'
//antd
import {
  Upload,
  Tabs,
  Table,
  Button,
  Row,
  Popconfirm,
  Modal,
  Input,
  notification,
  Col,
  Select,
  Form,
  Space,
} from 'antd'

//icons
import { LoadingOutlined, PlusOutlined, CopyOutlined, PlusCircleOutlined } from '@ant-design/icons'

//apis
import { uploadFile } from 'apis/upload'
import { getBusinesses, updateBusinesses, settingCommon } from 'apis/app'

export default function Setting() {
  const dispatch = useDispatch()
  const setting = useSelector((state) => state.setting)
  const [isLoading, setIsLoading] = useState(false)
  const dataUser = useSelector((state) => state.login.dataUser)

  const [imageUrl, setImageUrl] = useState('')
  const [loading, setLoading] = useState('')
  const [list_currency, setListCurrency] = useState([])
  const [form] = Form.useForm()
  const columnsLanguage = [
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'logo',
    },
    {
      title: 'File',
      dataIndex: 'file',
    },
    {
      width: 110,
      title: '',
      render: (text, record) => (
        <Popconfirm title="Bạn có muốn xóa file này không ?">
          <Button type="primary" danger>
            Xóa file
          </Button>
        </Popconfirm>
      ),
    },
  ]

  const calendar_backup = [
    {
      name: 'Hằng ngày (0 giờ)',
      value: 'day',
    },
    {
      name: 'Hằng tuần (chủ nhật, 0 giờ)',
      value: 'week',
    },
    {
      name: 'Hằng tháng (ngày 1, 0 giờ)',
      value: 'month',
    },
  ]

  let dataMockup = []
  for (let i = 0; i < 2; i++)
    dataMockup.push({
      name: 'Ngôn ngữ ' + i++,
      logo: '',
      file: 'File' + 1,
    })

  const _uploadLogo = async (file) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const company_logo = await uploadFile(file)
      if (company_logo) _updateSettingApp({ company_logo })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
    }
  }

  const onSaveSettingCommon = async () => {
    try {
      const body = form.getFieldsValue()
      notification.success({ message: 'Cài đặt thành công' })
    } catch (err) {
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
    }
  }

  const ModalImportFile = () => {
    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    return (
      <>
        <Button onClick={toggle} icon={<PlusCircleOutlined />} type="primary">
          Tải file
        </Button>
        <Modal title={<a>Tải file mẫu</a>} visible={visible} onCancel={toggle}>
          <div>
            <div>Tên file</div>
            <Input placeholder="Nhập tên file" />
          </div>
          <Button
            icon={<PlusOutlined />}
            style={{ width: 150, height: 100, marginTop: 15 }}
          ></Button>
        </Modal>
      </>
    )
  }

  const _updateSettingApp = async (body) => {
    try {
      setLoading(true)
      const res = await updateBusinesses(body, setting.system_user_id)
      console.log(res)
      if (res.status === 200) {
        document.querySelector("link[rel*='icon']").href = body.company_logo || ''
        _getBusiness()
        notification.success({ message: 'Cập nhật thành công!' })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
        })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getBusiness = async () => {
    try {
      setLoading(true)
      const res = await getBusinesses({ _business: true })
      if (res.status === 200)
        if (res.data.data)
          if (localStorage.getItem('accessToken')) {
            const dataUser = jwt_decode(localStorage.getItem('accessToken'))
            if (dataUser && dataUser.data) {
              const business = res.data.data.find(
                (e) =>
                  e._business && e._business.business_name === dataUser.data._business.business_name
              )
              if (business) dispatch({ type: 'GET_SETTING_APP', data: business._business || {} })
            }
          }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const FormSettingCommon = () => {
    return (
      <Form form={form} style={{ width: '100%' }} layout="vertical">
        <Col
          span={24}
          style={{
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Row
            style={{
              width: '100%',
              height: 55,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Col span={4}>
              <div>{'Cài đặt Timezone'}</div>
            </Col>
            <Form.Item style={{ marginBottom: 0 }} name="timezone">
              <Col span={10}>
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  placeholder="Chọn timezone"
                  style={{ width: 350 }}
                >
                  {timezones.map((timezone, index) => (
                    <Select.Option key={index} value={timezone.tzCode}>
                      {timezone.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Form.Item>
          </Row>
          <Row
            style={{
              width: '100%',
              height: 55,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Col span={4}>
              <div>{'Cài đặt đơn vị tiền tệ'}</div>
            </Col>
            <Form.Item style={{ marginBottom: 0 }} name="currency">
              <Col span={10}>
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  placeholder="Chọn đơn vị"
                  style={{ width: 350 }}
                >
                  {list_currency.map((currency, index) => (
                    <Select.Option key={index} value={currency.code}>
                      {`${currency.name} (${currency.code} - ${currency.symbol_native})`}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Form.Item>
          </Row>
          <Row
            style={{
              width: '100%',
              height: 55,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Col span={4}>
              <div>{'Cài đặt lịch sao lưu (backup)'}</div>
            </Col>
            <Form.Item style={{ marginBottom: 0 }} name="time_backup">
              <Col span={10}>
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  placeholder="Chọn thời gian"
                  style={{ width: 350 }}
                >
                  {calendar_backup.map((backup, index) => (
                    <Select.Option key={index} value={backup.value}>
                      {`${backup.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Form.Item>
          </Row>

          {/* <Row
            style={{
              width: '100%',
              height: 55,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Col span={4}>
              <div>{'Cài đặt Email doanh nghiệp'}</div>
            </Col>
            <Form.Item style={{ marginBottom: 0 }} name="email">
              <Col span={6}>
                <Input style={{ width: '100%' }}></Input>
              </Col>
            </Form.Item>
          </Row>

          <Row
            style={{
              width: '100%',
              height: 55,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Col span={4}>
              <div>{'Cài đặt Hotline doanh nghiệp'}</div>
            </Col>
            <Form.Item style={{ marginBottom: 0 }} name="hotline">
              <Col span={6}>
                <Input></Input>
              </Col>
            </Form.Item>
          </Row> */}

          <Row
            style={{
              width: '100%',
              height: 55,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Col span={4}></Col>
            <Col
              span={10}
              style={{ flexDirection: 'row', justifyContent: 'flex-end', display: 'flex' }}
            >
              <Button
                onClick={(e) => {
                  onSaveSettingCommon()
                }}
                style={{ width: 100, marginRight: 10 }}
                type={'primary'}
              >
                {'Lưu'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    )
  }
  useEffect(() => {
    _getBusiness()
    var data = CurrencyList.getAll('en_US')

    const propertyValues = Object.values(data)
    setListCurrency(propertyValues)
  }, [])

  return (
    <div className="card">
      <Tabs size="large" type="card" style={{backgroundColor:'white', padding:5}}>
        <Tabs.TabPane tab="Cài đặt chung" key="0">
          <Row justify="end" style={{ marginBottom: 15 }}>
            <FormSettingCommon />
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Ngôn ngữ" key="1">
          <Row justify="end" style={{ marginBottom: 15 }}>
            <Space>
              <DropdownLanguage />
              <ModalImportFile />
            </Space>
          </Row>
          <Table
            size="small"
            dataSource={dataMockup}
            columns={columnsLanguage}
            style={{ width: '100%' }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Chi tiết phiên bản" key="2">
          <div>
            <div>Version: {VERSION_APP}</div>
            <div>
              <div>Chi tiết:</div>
              <div>- Bổ sung giao diện nhập kho</div>
              <div>- Bổ sung chức năng tạo đơn nhập kho</div>
              <div>- Chỉnh sửa chức năng import sản phẩm bằng file excel</div>
              <div>- Cho phép tải file đính kèm khi tạo sản phẩm</div>
              <div>- Xuất file excel các đơn hàng nhập </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kết nối API" key="3">
          Phát triển sau
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Logo" key="4">
          <Upload
            name="avatar"
            listType="picture-card"
            className="upload-category-image"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            onChange={(info) => {
              if (info.file.status === 'uploading') info.file.status = 'done'
            }}
            data={_uploadLogo}
          >
            {setting && setting.company_logo ? (
              <img src={setting.company_logo} alt="avatar" style={{ width: '100%' }} />
            ) : (
              <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Logo</div>
              </div>
            )}
          </Upload>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Khác" key="5">
          <div>Lấy link hình ảnh</div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="upload-category-image"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            data={async (file) => {
              setLoading(true)
              const url = await uploadFile(file)
              setImageUrl(url)
              setLoading(false)
            }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Tải lên</div>
              </div>
            )}
          </Upload>
          <h3>
            {imageUrl}{' '}
            <CopyOutlined
              style={{
                display: !imageUrl && 'none',
                color: '#5B6BE8',
                cursor: 'pointer',
                marginLeft: 7,
                fontSize: 19,
              }}
              onClick={() => copyText(imageUrl)}
            />
          </h3>
        </Tabs.TabPane> */}
      </Tabs>
    </div>
  )
}
