import React, { useState, useEffect } from 'react'
import { ACTION, regexPhone } from 'consts/index'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Input, Button, Row, Col, notification, Select, Form, Upload, Drawer } from 'antd'

//icons
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'

//apis
import { getProvinces, getDistricts } from 'apis/address'
import { addBusiness, updateBusiness } from 'apis/business'
import { uploadFile } from 'apis/upload'

const { Option } = Select
export default function BusinessesAdd({ reloadData, children, record }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const [provinces, setProvinces] = useState([])
  const [districtMain, setDistrictMain] = useState([])
  const [districtsDefault, setDistrictsDefault] = useState([])
  const [image, setImage] = useState('')

  const _uploadImage = async (file) => {
    try {
      setLoading(true)
      const url = await uploadFile(file)
      setImage(url || '')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _addOrEditBranch = async () => {
    try {
      await form.validateFields()

      const dataForm = form.getFieldsValue()

      if (dataForm.phone && !regexPhone.test(dataForm.phone)) {
        notification.warning({ message: t('business.invalid_contact_number') })
        return
      }

      dispatch({ type: ACTION.LOADING, data: true })

      const body = {
        ...dataForm,
        company_logo: image || '',
        verify_with: 'PHONE',
        active: true,
        branch_id: branchIdApp
      }

      let res
      if (record) res = await updateBusiness(record.business_id, body)
      else res = await addBusiness(body)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          reloadData()
          notification.success({ message: `${record ? t('business.successful_business_update') : t('business.create_a_successful_business')}` })
          setVisible(false)
          dispatch({ type: 'TRIGGER_RELOAD_BRANCH' })
        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? t('business.business_update_failed_please_try_again') : t('business.business_creation_failed_please_try_again')}`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${record ? t('business.business_update_failed_please_try_again') : t('business.business_creation_failed_please_try_again')}`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getProvinces = async () => {
    try {
      const res = await getProvinces()
      if (res.status === 200) setProvinces(res.data.data)

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _getDistricts = async () => {
    try {
      const res = await getDistricts()
      if (res.status === 200) {
        setDistrictMain(res.data.data)
        setDistrictsDefault(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getProvinces()
    _getDistricts()

    if (location.state && location.state === 'show-modal-create-branch') setVisible(true)
  }, [])

  useEffect(() => {
    if (visible) {
      if (!record) {
        form.resetFields()
        setImage('')
      } else {
        form.setFieldsValue({ ...record })
        setImage(record.company_logo || '')
      }
    }
  }, [visible])

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Drawer
        footer={
          <Row justify="end">
            <Button
              loading={loading}
              onClick={_addOrEditBranch}
              size="large"
              type="primary"
              style={{ minWidth: 120 }}
            >
              {record ? t('common.update') : t('common.add')}
            </Button>
          </Row>
        }
        title={record ? t('business.business_update') : t('business.business_creation')}
        centered
        width="70%"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        {t('common.picture')}
        <Upload
          className="upload-shipping"
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          data={_uploadImage}
        >
          {image ? (
            <img src={image} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>{t('common.upload')}</div>
            </div>
          )}
        </Upload>
        <Form form={form} layout="vertical">
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('common.phone')}</div>}
                name="username"
                rules={[
                  { required: record ? false : true, message: t('common.please_enter_the_phone_number') },
                ]}
              >
                <Input size="large" disabled={record ? true : false} placeholder={t('common.enter_your_phone_number')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11} style={{ display: record && 'none' }}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('common.password')}</div>}
                name="password"
                rules={[{ required: record ? false : true, message: t('common.please_enter_a_password') }]}
              >
                <Input.Password size="large" placeholder={t('common.enter_password')} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('business.business_name')}</div>}
                name="business_name"
                rules={[{ required: true, message: t('business.please_enter_business_name') }]}
              >
                <Input size="large" placeholder={t('business.enter_business_name')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="company_name"
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('business.company_name')}</div>}
                rules={[{ required: true, message: t('business.please_enter_company_name') }]}
              >
                <Input placeholder={t('business.enter_company_name')} size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('business.mail')}</div>}
                name="company_email"
                rules={[{ type: 'email', required: true, message: t('business.please_enter_your_email_in_the_correct_format') }]}
              >
                <Input size="large" placeholder={t('business.enter_email')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="company_phone"
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('business.company_phone_number')}</div>}
                rules={[{ message: t('business.please_enter_your_phone_number_in_the_correct_format'), pattern: /^(0|\+[0-9]{2,3})[0-9]{9}$/ }]}
              >
                <Input style={{ width: '100%' }} placeholder={t('business.enter_company_phone_number')} size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('business.company_website')}</div>}
                name="company_website"
              >
                <Input size="large" placeholder={t('business.enter_company_website')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="company_address"
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('common.address')}</div>}
                rules={[{ required: true, message: t('common.please_enter_your_address') }]}
              >
                <Input placeholder={t('common.enter_address')} size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="company_province"
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('common.province_city')}</div>}
              >
                <Select
                  size="large"
                  onChange={(value) => {
                    if (value) {
                      const districtsNew = districtsDefault.filter((e) => e.province_name === value)
                      setDistrictMain([...districtsNew])
                    } else setDistrictMain([...districtsDefault])
                  }}
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={t('common.select_province_city')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {provinces.map((values, index) => {
                    return (
                      <Option value={values.province_name} key={index}>
                        {values.province_name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="company_district"
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('common.district')}</div>}
              >
                <Select
                  size="large"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={t('common.select_district')}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {districtMain.map((values, index) => {
                    return (
                      <Option value={values.district_name} key={index}>
                        {values.district_name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('common.tax_code')}</div>}
                name="tax_code"
                rules={[{ required: true, message: t('common.please_enter_your_tax_code') }]}
              >
                <Input placeholder={t('common.enter_tax_code')} size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                rules={[{ message: t('business.please_select_a_pricing_method'), required: true }]}
                name="price_recipe"
                label={<div style={{ color: 'black', fontWeight: '600' }}>{t('business.how_to_calculate_the_price')}</div>}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder={t('business.choose_how_to_calculate_the_price')}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="FIFO">{t('business.first_in_first_out_FIFO_products')}</Option>
                  <Option value="LIFO">{t('business.last_in_first_out_LIFO_products')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  )
}
