import { Space } from 'antd'

import TitleSchedule from '../TitleSchedule'
import styles from './HeaderSchedule.module.scss'

function HeaderSchedule({ children, classesTitle = '', classes = '', subTitle, title }) {

  return (
    <Space className={`${styles.header} ${classes}`}>
      <TitleSchedule classes={classesTitle} subTitle={subTitle} title={title} />
      {children}
    </Space>
  )
}

export default HeaderSchedule
