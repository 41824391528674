import { get, post, patch, destroy } from './httpClient'

export const getPriceAdjustments = (query) => get('/fix-price', query)
export const addPriceAdjustments = (body) => post('/fix-price/create', body)
export const updatePriceAdjustments = (body, id) => patch('/fix-price/update/' + id, body)
export const savePriceAdjustments = (body, id) => patch('/fix-price/save/' + id, body)
export const confirmPriceAdjustments = (body, id) => patch('/fix-price/confirm/' + id, body)
export const deletePriceAdjustments = (id,body) => destroy('/fix-price/delete/' + id,body)
export const importPriceAdjustments = (formData) => post('/fix-price/import-file', formData)

export const getRowsExcel = (filePath, valueProductMenu) =>
  post('/fix-price/fix-price/get-rows-excel', { filePath, valueProductMenu })
export const startImportproduct = (body) =>
  post('/fix-price/fix-price/start-import', body)