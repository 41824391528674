import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'

const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: 50,
  },
  {
    title: 'Mã phiếu',
    key: 'code',
  },
  {
    title: 'Tiêu đề',
    key: 'title',
  },
  {
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Ngày tạo',
    key: 'create_date',
  },
  {
    title: 'Số lượng thực tế',
    key: 'real_quantity',
  },
  {
    title: 'Số lượng hệ thống',
    key: 'system_quantity',
  },
  {
    title: 'Nhân viên tạo',
    key: 'creator_info',
  },
  {
    title: 'Hành động',
    key: 'action',
    align: 'center',
  },
]

export default columns
