import React, { useEffect, useState } from 'react'
import localStorage from '../../utils/localStorage'
import styles from './order-create-shipping.module.scss'
import { useHistory, useParams, Link } from 'react-router-dom'
import { ROUTES, PERMISSIONS } from 'consts'
import { formatCash } from 'utils'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

//antd
import {
  Row,
  Col,
  Divider,
  Input,
  Button,
  Table,
  notification,
  Form,
  Select,
  Tooltip,
  Space,
  Tag,
  Spin,
} from 'antd'

//icons
import {
  CaretRightOutlined,
  ArrowLeftOutlined,
  UserOutlined,
  InfoCircleTwoTone,
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import noData from 'assets/icons/no-data.png'

//apis
import { getOrders, updateOrder } from 'apis/order'

//components
import TitlePage from 'components/title-page'
import UpdateStatus from './items/update-status'
import UpdatePayment from './items/update-payment'
import PrintTemplate from './items/print-template'
import { TableProduct } from './items/tableProduct'

export default function CompleteUpdate() {
  const { t } = useTranslation()
  let history = useHistory()

  const [formInfoOrder] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const { code } = useParams()
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [loading, setLoading] = useState(false)
  const [showInfoDelivery, setShowInfoDelivery] = useState(false)
  const [infoBranch, setInfoBranch] = useState(
    localStorage.getItem('branchSell') ? JSON.parse(localStorage.getItem('branchSell')) : null
  )

  //object order create
  const [orderUpdate, setOrderUpdate] = useState({
    name: 'Đơn 1',
    type: 'default',
    customer: null,
    order_details: [], //danh sách sản phẩm trong hóa đơn
    payments: [], //hình thức thanh toán
    type_payment: '', //hình thức thanh toán
    sumCostPaid: 0, // tổng tiền của tất cả sản phẩm
    discount: null,
    VAT: 0,
    noteInvoice: '',
    salesChannel: '', //kênh bán hàng
    isDelivery: true, //mặc định là hóa đơn giao hàng
    deliveryCharges: 0, //phí giao hàng
    deliveryAddress: null, //địa chỉ nhận hàng
    shipping: null, //đơn vị vận chuyển
    billOfLadingCode: '',
    moneyToBePaidByCustomer: 0, // tổng tiền khách hàng phải trả
    prepay: 0, //tiền khách thanh toán trước
    moneyGivenByCustomer: 0, //tiền khách hàng đưa
    excessCash: 0, //tiền thừa
  })

  const [customerInfo, setCustomerInfo] = useState(null)
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [listPayments, setListPayments] = useState([])

  const columns = [
    {
      title: `STT`,
      dataIndex: 'stt',
      render: (data, record, index) => (
        <Row>
          <div>{index + 1}</div>
        </Row>
      ),
    },
    {
      title: `SKU`,
      dataIndex: 'sku',
      render: (data, record) => (
        <Link
          to={{
            pathname: ROUTES.PRODUCT_UPDATE + '/' + record.product_info.sku,
          }}
          target="_blank"
        >
          {record.sku}
        </Link>
      ),
    },
    {
      title: t('common.product'),
      dataIndex: 'title',
      render: (data, record) => (
        <Row>
          <div>{record.title}</div>
        </Row>
      ),
    },
    {
      title: t('common.note'),
      dataIndex: 'note',
      render: (data, record) =>
        !record.note && record.type_advanced === 2 ? (
          <>{record.location.length} lô được chọn</>
        ) : !record.note && record.type_advanced === 3 ? (
          <>{record.imei.length} imei được chọn</>
        ) : (
          <div>{record.note}</div>
        ),
    },
    {
      title: t('sell.quantity'),
      render: (data, record) => (
        <Row>
          <div>{record.quantity}</div>
        </Row>
      ),
    },
    {
      title: t('common.unit'),
      render: (data, record) => (
        <Row>
          <div>{record.unit || 'Cái'}</div>
        </Row>
      ),
    },
    {
      title: t('common.unit_price'),
      render: (data, record) => (
        <Row>
          <div>{formatCash(record.price)}</div>
        </Row>
      ),
    },
    {
      title: 'Chiết khấu',
      render: (data, record) => (
        <Row>
          <div>{formatCash(record.total_discount / record.quantity)}</div>
        </Row>
      ),
    },
    {
      title: t('common.amount_to_be_paid'),
      align: 'right',
      render: (data, record) =>
        formatCash(
          +record.sumCost - record.total_discount || +record.total_cost - record.total_discount || 0
        ),
    },
  ]
  const _updateOrder = async () => {
    const formData = formInfoOrder.getFieldsValue()
    try {
      const data = {
        tags: formData.tags || [],
        note: formData.note || '',
        payments: listPayments,
      }
      let res = await updateOrder(data, orderUpdate.order_id)
      if (res.status === 200) {
        notification.success({
          message: `Cập nhật đơn hàng thành công!`,
        })
        history.push('/order-list')
      } else {
        notification.error({
          message: res.data.message || 'Cập nhật đơn hàng thất bại!',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const _editInvoice = (value) => {
    let index = value.data.lastIndexOf('-')
    let name_method = value.data.slice(0, index)
    let payment_method_id = Number(value.data.slice(index + 1, value.length))
    let newData = [...listPayments]

    let indexPayment = newData.findIndex((item) => item.paymentIdOld === value.idOld)
    newData[indexPayment].name = name_method
    newData[indexPayment].payment_method_id = payment_method_id
    setListPayments(newData)
  }
  const _getBranchEmployee = () => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      const data = jwt_decode(accessToken)
      if (!infoBranch) {
        if (data.data._branch) {
          localStorage.setItem('branchSell', JSON.stringify(data.data._branch))
          setInfoBranch(data.data._branch)
        }
      }
    } else history.push(ROUTES.LOGIN)
  }
  const getOrderDetail = async () => {
    try {
      if (code) {
        setLoading(true)
        var response = await getOrders({
          order_id: code,
          branch_id: branchIdApp,
          detail: 1,
          role_id: dataUser?.role_id,
        })
        if (response.data.data.length > 0) {
          const data = response.data.data[0]
          setOrderUpdate({
            ...data,
            order_details: data.order_details.map((item) => {
              const body = {
                ...item,
                sumCost: item.total_cost,
              }
              return body
            }),
            moneyToBePaidByCustomer: data.final_cost,
            sumCostPaid: data.total_cost,
            deliveryCharges: data.shipping_info.fee_shipping,
            type_payment: data.payments
              ? data.payments.length >= 2
                ? 'Thanh toán một phần'
                : 'Thanh toán sau'
              : 'Thanh toán sau',
            value_promotion: data.promotion
              ? data.promotion.type === 'VALUE'
                ? data.promotion.value
                : ((data.promotion.value * data.total_cost) / 100).toFixed()
              : 0,
          })
          formInfoOrder.setFieldsValue({
            order_code: data.code,
            tags: data.tags,
            note: data.note,
            branch_id: data.branch_id,
          })
          setCustomerInfo(data.customer_info)
          setDeliveryAddress(data.customer_info)
          setListPayments(
            data.payments.map((item) => {
              return {
                ...item,
                paymentIdOld: item.payment_method_id,
              }
            })
          )
          setLoading(false)
        } else history.push(ROUTES.ORDER_LIST)
      }
    } catch (err) {}
  }
  const RenderRowTypeAdvanced = ({ product, index }) => {
    switch (product.type_advanced) {
      case 2:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product.location ? (
              product.location.map((e, index) => (
                <div style={{ display: 'flex' }}>
                  {e.quantity > 0 ? (
                    <Tag
                      style={{
                        padding: 4,
                        marginBottom: 4,
                        backgroundColor: '#cccc',
                        borderRadius: 4,
                      }}
                    >
                      {e.code} | {e.exp} | SL: <strong>{e.quantity}</strong>
                    </Tag>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <strong>Sản phẩm chưa có lô </strong>
            )}
          </div>
        )
      case 3:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product.imei ? (
              product.imei.map((e, index) => (
                <div style={{ display: 'flex' }}>
                  <Tag
                    style={{
                      padding: 4,
                      marginBottom: 4,
                      backgroundColor: '#cccc',
                      borderRadius: 4,
                    }}
                  >
                    <strong>{e}</strong>
                  </Tag>
                </div>
              ))
            ) : (
              <strong>Sản phẩm chưa có iMei </strong>
            )}
          </div>
        )
      default:
        return <></>
    }
  }
  useEffect(() => {
    _getBranchEmployee()
    getOrderDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchIdApp, code])
  useEffect(() => {
    if (!permissions.includes(PERMISSIONS.chi_tiet_don_hang)) history.push(ROUTES.ORDER_LIST)
  }, [branchIdApp])
  return (
    <div className="card">
      <TitlePage
        isAffix
        title={
          <Row
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.ORDER_LIST)}
          >
            <ArrowLeftOutlined style={{ marginRight: 5 }} />
            {t('order_list.update_an_order')}
          </Row>
        }
      >
        <div>
          <Space>
            <PrintTemplate />
            <Button
              onClick={async () => {
                let update = await UpdateStatus('delivering', orderUpdate?.order_id)
                if (update === true) {
                  history.push(`/order-update-cancel-order/${orderUpdate?.code}`)
                }
              }}
              disabled
              size="large"
              type="danger"
            >
              Huỷ đơn hàng
            </Button>
            <Button onClick={_updateOrder} size="large" type="primary">
              Lưu
            </Button>
            <Link
              to={{
                pathname: ROUTES.REFUND_CREATE,
              }}
              onClick={() => {
                localStorage.setItem('refundCode', orderUpdate.code)
              }}
              target="_blank"
              rel="noreferrer"
            >
              <Button size="large" type="danger">
                Trả hàng
              </Button>
            </Link>
          </Space>
        </div>
      </TitlePage>
      <Form layout="vertical" form={formInfoOrder}>
        <Spin spinning={loading} tip="Loading...">
          <Row gutter={16}>
            <Col span={16}>
              <div className={styles['block']}>
                <Row justify="space-between" className={styles['title']}>
                  <div>{t('order_create.customer_information')}</div>
                </Row>

                <Row
                  wrap={false}
                  align="middle"
                  style={{
                    display: !customerInfo && 'none',
                    marginTop: 15,
                  }}
                >
                  <UserOutlined style={{ fontSize: 28, marginRight: 15 }} />
                  <div style={{ width: '100%' }}>
                    <Row wrap={false} align="middle">
                      {customerInfo ? (
                        <Link
                          to={{
                            pathname: ROUTES.CUSTOMER_DETAIL + '/' + customerInfo.code,
                          }}
                          target="_blank"
                        >
                          {customerInfo && customerInfo.first_name + ' ' + customerInfo.last_name}
                        </Link>
                      ) : (
                        <div></div>
                      )}

                      <span style={{ fontWeight: 500 }}>
                        {' '}
                        - {customerInfo && customerInfo.phone}
                      </span>
                    </Row>
                    <Row wrap={false} justify="space-between" align="middle">
                      <div>
                        <span style={{ fontWeight: 600 }}>{t('sell.debts')} </span>
                        <span>{customerInfo && customerInfo.debt}</span>
                      </div>
                      <div>
                        <span style={{ fontWeight: 600 }}>{t('sell.current_score')} </span>
                        <span>{customerInfo && customerInfo.point}</span>
                      </div>
                    </Row>
                  </div>
                </Row>

                <Divider />
                {deliveryAddress && (
                  <>
                    <Row justify="space-between">
                      <span className={styles['payment-title']}>
                        {t('common.delivery_address')}
                      </span>
                    </Row>
                    {deliveryAddress && (
                      <div style={{ fontSize: 14.7 }}>
                        <div>
                          {`${deliveryAddress.first_name} ${deliveryAddress.last_name}`} -{' '}
                          {deliveryAddress.phone}
                        </div>
                        <div>
                          {deliveryAddress.address &&
                          deliveryAddress.district &&
                          deliveryAddress.province
                            ? `${deliveryAddress.address}, ${deliveryAddress.district}, ${deliveryAddress.province}`
                            : null}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className={styles['block']} >
                <TableProduct
                  pagination={false}
                  columns={columns}
                  size="small"
                  dataSource={[...orderUpdate.order_details]}
                  rowKey="variant_id"
                  defaultExpandAllRows={true}
                  expandable={{
                    expandedRowKeys: orderUpdate.order_details.map((o) => o.variant_id),
                    defaultExpandAllRows: true,
                    expandedRowRender: (record, index) =>
                      (
                        <RenderRowTypeAdvanced
                          style={{ height: 20 }}
                          product={record}
                          index={index}
                        />
                      ) || [],
                    rowExpandable: (record, index) =>
                      record.type_advanced === 2 || record.type_advanced === 3,
                  }}
                  locale={{
                    emptyText: (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 200,
                        }}
                      >
                        <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                        <h4 style={{ fontSize: 15, color: '#555' }}>
                          {t('sell.your_order_has_no_products')}
                        </h4>
                      </div>
                    ),
                  }}
                  summary={(e) => (
                    <Table.Summary.Row
                      style={{ display: orderUpdate.order_details.length === 0 && 'none' }}
                    >
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={6}>
                        <div style={{ fontSize: 14.4, marginLeft: '42%' }}>
                          <Row wrap={false} justify="space-between">
                            <div>
                              {t('common.total_payment')} ({orderUpdate.order_details.length}{' '}
                              {t('common.product')})
                            </div>
                            <div>{formatCash(orderUpdate.total_payment) || 0}</div>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <div>
                              Khuyến mãi:{' '}
                              {orderUpdate?.promotion?.type === 'PERCENT' &&
                                orderUpdate?.promotion?.value + '%'}{' '}
                            </div>
                            <div>{formatCash(orderUpdate.total_promotion)}</div>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <div>{t('common.discount')}</div>
                            <div>{formatCash(orderUpdate.total_discount)}</div>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <div>VAT</div>
                            <div>{formatCash(orderUpdate.total_tax || 0)}</div>
                          </Row>
                          <Row wrap={false} justify="space-between" align="middle">
                            <div>{t('common.delivery_charges')}</div>
                            <div>
                              {orderUpdate.deliveryCharges &&
                                formatCash(orderUpdate.deliveryCharges)}
                            </div>
                          </Row>
                          <Row wrap={false} justify="space-between" style={{ fontWeight: 600 }}>
                            <div>{t('order_create.guest_must_pay')}</div>
                            <div>
                              {formatCash(+orderUpdate.moneyToBePaidByCustomer.toFixed() || 0)}
                            </div>
                          </Row>
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </div>
              <div className={styles['block']}>
                <Row wrap={false} align="middle" style={{ fontWeight: 600 }}>
                  <CheckCircleOutlined style={{ color: 'green', fontSize: 20 }} />
                  <div style={{ margin: '0px 5px' }}>Đã thanh toán toàn bộ</div>
                  <Tooltip
                    title={
                      <div style={{ textAlign: 'center' }}>
                        {t('order_create.confirm_the_order')}
                      </div>
                    }
                  >
                    <InfoCircleTwoTone style={{ fontSize: 12 }} />
                  </Tooltip>
                </Row>
                <Divider style={{ marginTop: 15, marginBottom: 10 }} />
                <Row style={{ background: '#e6f7ff', padding: 10 }} gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    Khách phải trả: <strong>{formatCash(orderUpdate.final_cost)}đ</strong>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    Đã thanh toán:{' '}
                    <strong>
                      {formatCash(
                        orderUpdate.customer_paid > orderUpdate.final_cost
                          ? orderUpdate.final_cost
                          : orderUpdate.customer_paid
                      )}
                      đ
                    </strong>
                  </Col>
                  <Col align="end" xs={24} sm={24} md={6} lg={6} xl={6}>
                    Còn phải trả:{' '}
                    <strong style={{ color: 'red' }}>
                      {formatCash(orderUpdate.customer_debt)}
                    </strong>
                  </Col>
                </Row>
                <Row
                  style={{ background: '#e6f7ff', padding: 10, marginBottom: 7 }}
                  gutter={[16, 16]}
                >
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div >
                      <div style={{ display: 'flex', width: '18em' }}>Tiền khách đưa:</div>
                      <div>
                        {listPayments &&
                          listPayments.map((item, index) => (
                            <div
                              style={{
                                display: 'flex',
                                // justifyContent: 'space-between',
                                paddingTop: 2,
                                width: '18em',
                                marginLeft: '1em',
                              }}
                            >
                              <span style={{ marginLeft: 5 }}>
                                {item.name}: <strong style={{ marginLeft: 5 }}>{' ' + formatCash(item.value)}đ</strong>
                              </span>
                              {dataUser.user_id === orderUpdate.creator_id ||
                              dataUser.role_id === 1 ? (
                                <>
                                  {' '}
                                  {item.payment_method_id === 6 ||
                                  item.payment_method_id === 2 ||
                                  orderUpdate.status === 400 ? null : (
                                    <UpdatePayment
                                      method={item}
                                      editInvoice={_editInvoice}
                                      idOld={item.paymentIdOld}
                                    />
                                  )}
                                </>
                              ) : null}
                            </div>
                          ))}
                      </div>
                    </div>
                    {orderUpdate.excessCash !== 0 ? (
                      <div style={{ marginTop: 7 }}>
                        <div style={{ display: 'flex', width: '18em' }}>Tiền thừa:</div>
                        <div>
                          {orderUpdate?.payment_refund &&
                            orderUpdate?.payment_refund?.map((item, index) => (
                              <div
                                style={{
                                  display: 'flex',
                                  paddingTop: 0,
                                  marginLeft: '1em',
                                  width: '18em',
                                }}
                              >
                                {item.name}:{' '}
                                <strong style={{ marginLeft: 4 }}>
                                  {formatCash(' ' + item.value)}đ
                                </strong>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>
                </Row>
                <div style={{ marginTop: 10 }}></div>
              </div>

              <div className={styles['block']}>
                <Row wrap={false} align="middle" style={{ fontWeight: 600 }}>
                  <CheckCircleOutlined style={{ color: 'green', fontSize: 20 }} />
                  <div style={{ margin: '0px 5px' }}>{t('order_create.package_and_delivery')}</div>
                  <Tooltip
                    title={
                      <div style={{ textAlign: 'center' }}>
                        {t('order_create.confirm_the_order_has_been_packed')}
                      </div>
                    }
                  ></Tooltip>
                </Row>
                <Divider style={{ marginTop: 15, marginBottom: 10 }} />

                <Row gutter={[12, 12]} onClick={() => setShowInfoDelivery(!showInfoDelivery)}>
                  <Col style={{ marginTop: -2 }}>
                    <CaretRightOutlined
                      style={{ color: 'blue', fontSize: 10, marginRight: '10px' }}
                    />
                  </Col>
                  <Col>
                    <span style={{ color: '#389e0d' }}>Đã giao hàng</span>
                  </Col>
                  <Col>
                    {showInfoDelivery === true ? (
                      <CaretUpOutlined style={{ color: '#8c8c8c' }} />
                    ) : (
                      <CaretDownOutlined style={{ color: '#8c8c8c' }} />
                    )}
                  </Col>
                  <Col>
                    <span style={{ color: '#8c8c8c' }}>
                      {moment(orderUpdate.create_date).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                  </Col>
                </Row>
                {showInfoDelivery === true ? (
                  <div style={{ marginLeft: 20, marginTop: 13 }}>
                    Hình thức giao hàng: Nhận tại cửa hàng
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Col>

            <Col span={8}>
              <div className={styles['block']}>
                <div className={styles['title']}>{t('common.order_information')}</div>

                <Form.Item style={{ marginBottom: '8px' }} name="order_id">
                  Bán tại :{' '}
                  <span>
                    {orderUpdate &&
                      orderUpdate.sale_location &&
                      orderUpdate.sale_location.name &&
                      orderUpdate.sale_location.name}
                  </span>
                </Form.Item>

                <Form.Item style={{ marginBottom: '8px' }} name="staff_sell_id">
                  Bán bởi :{' '}
                  <span>
                    {orderUpdate.employee && orderUpdate.employee.first_name}{' '}
                    {orderUpdate.employee && orderUpdate.employee.last_name}
                  </span>
                </Form.Item>
                <Form.Item style={{ marginBottom: '8px' }} name="order_id">
                  Nguồn : <span>{orderUpdate.channel}</span>
                </Form.Item>

                <Form.Item style={{ marginBottom: '8px' }} name="order_id">
                  Ngày bán :{' '}
                  <span>{moment(orderUpdate.create_date).format('DD/MM/YYYY HH:mm:ss')}</span>
                </Form.Item>
                <Form.Item style={{ marginBottom: '8px' }} name="code_id">
                  {t('common.code_orders')}:{' '}
                  {orderUpdate.status === 200 ? (
                    <span style={{ color: 'green' }}>{orderUpdate.code}</span>
                  ) : orderUpdate.status === 101 ? (
                    <span style={{ color: 'gold' }}>{orderUpdate.code}</span>
                  ) : orderUpdate.status === 102 ? (
                    <span style={{ color: 'orange' }}>{orderUpdate.code}</span>
                  ) : orderUpdate.status === 100 ? (
                    <span style={{ color: 'black' }}>{orderUpdate.code}</span>
                  ) : orderUpdate.status === 400 ? (
                    <span style={{ color: '#ff7089' }}>{orderUpdate.code}</span>
                  ) : (
                    <span style={{ color: 'red' }}>{orderUpdate.code}</span>
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: '8px' }} name="order_id">
                  Trạng thái : {' '}
                  {orderUpdate.status === 200 ? (
                    <span>Đơn hoàn thành</span>
                  ) : orderUpdate.status === 100 ? (
                    <span>Đơn chờ xác nhận</span>
                  ) : orderUpdate.status === 102 ? (
                    <span>Đơn đang giao</span>
                  ) : orderUpdate.status === 101 ? (
                    <span>Đơn chờ lấy hàng</span>
                  ) : orderUpdate.status === 400 ? (
                    <span>Đơn đã trả</span>
                  ) : (
                    <span>Đơn đã hủy</span>
                  )}
                </Form.Item>
              </div>
              <div className={styles['block']}>
                <div className={styles['title']}>
                  {t('common.note')}{' '}
                  <Tooltip
                    title={
                      <div style={{ textAlign: 'center' }}>
                        {t('order_create.add_note_information')}
                      </div>
                    }
                  >
                    <InfoCircleTwoTone style={{ fontSize: 12 }} />
                  </Tooltip>
                </div>
                <Form.Item name="note">
                  <Input.TextArea rows={2} placeholder={t('common.enter_a_note')} />
                </Form.Item>

                <div className={styles['title']}>
                  {t('order_create.title_tags')}{' '}
                  <Tooltip
                    title={
                      <div style={{ textAlign: 'center' }}>
                        {t('order_create.select_or_add_tags_for_orders')}
                      </div>
                    }
                  >
                    <InfoCircleTwoTone style={{ fontSize: 12 }} />
                  </Tooltip>
                </div>
                <Form.Item name="tags">
                  <Select mode="tags" placeholder={t('order_create.enter_tags')}></Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </div>
  )
}
