import React, { useState, useEffect, useRef } from 'react'
import columns from './columns'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PAGE_SIZE, PERMISSIONS } from 'consts'
import moment from 'moment'
import { compareCustom, removeUnicode } from 'utils'
import Permission from 'components/permission'

//apis
import { getStaff } from 'apis/manage-staff'

import { getSchedule, deleteSchedule, getShift } from 'apis/schedule-staff'

import TitlePage from 'components/title-page'
import Modal from 'components/Modal'

//antd
import { Row, Select, Table, Col, Input, Button, Space, Popconfirm, notification } from 'antd'

//icons
import {
  CloseOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

//components
import FilterDate from 'components/filter-date'
import ScheduleStaffForm from './schedule-staff-form'
import Shiftform from './shift/index'
import CloseIcon from 'assets/icons/close.svg'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function Schedule() {
  const typingTimeoutRef = useRef(null)

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [valueTime, setValueTime] = useState()
  const [schedule, setSchedule] = useState([])

  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })

  const [valueSearch, setValueSearch] = useState('')
  const [employees, setEmployees] = useState([])
  const [countSchedule, setCountSchedule] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.searchAll = value
      else delete paramsFilter.searchAll
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const onFilters = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
    setValueSearch('')
    setValueTime()
  }

  const _getSchedule = async () => {
    try {
      setTableLoading(true)
      const res = await getSchedule({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        setSchedule(res.data.data)
        setCountSchedule(res.data.count)
      }
      setTableLoading(false)
    } catch (error) {
      console.log(error)
      setTableLoading(false)
    }
  }
  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200)
        setEmployees(res.data.data.filter((item) => item.status === 1 || item.role_id === 1))
    } catch (error) {
      console.log(error)
    }
  }

  const _deleteSchedule = async (id) => {
    try {
      const res = await deleteSchedule(id)
      if (res.status === 200) {
        _getSchedule()
        notification.success({ message: 'Xóa lịch thành công!' })
      } else
        notification.error({
          message: res.data.message || 'Xóa lịch thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
    }
  }
  const ModalScheduleStaff = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          centered
          onCancel={toggle}
          footer={null}
          visible={visible}
          closeIcon={<img src={CloseIcon} alt="" />}
          title="Tạo lịch"
          style={{ padding: 0, maxWidth: '526px' }}
          width="100%"
        >
          <ScheduleStaffForm record={record} close={toggle} text="Tạo" reload={_getSchedule} />
        </Modal>
      </>
    )
  }

  const ModalShift = ({ children, record }) => {
    const [visibleShift, setVisibleShift] = useState(false)
    const toggleShift = () => setVisibleShift(!visibleShift)

    return (
      <>
        <div onClick={toggleShift}>{children}</div>
        <Modal
          centered
          onCancel={toggleShift}
          footer={null}
          title="Danh sách ca làm việc"
          visible={visibleShift}
          closeIcon={<img src={CloseIcon} alt="" />}
          style={{ padding: 0, maxWidth: '759px' }}
          width="100%"
        >
          <Shiftform record={record} close={toggleShift} />
        </Modal>
      </>
    )
  }
  useEffect(() => {
    _getEmployees()
  }, [branchIdApp])
  useEffect(() => {
    if (paramsFilter.check) _getSchedule()
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueSearch('')
    if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
      setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
      setValueTime()
    } else {
      setParamsFilter({
        page: 1,
        page_size: PAGE_SIZE,
        from_date: result.from_date,
        to_date: result.to_date,
        check: 1,
      })
      setValueTime(result.valueDate)
    }
  }, [dataUser])
  return (
    <div className="card">
      <TitlePage title="Danh sách lịch làm việc" isAffix>
        <Space>
          <Permission permissions={[PERMISSIONS.cau_hinh_ca_lam_viec]}>
            <ModalShift>
              <Button
                size="large"
                icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                type="primary"
                onClick={() => getShift()}
              >
                Cấu hình ca làm việc
              </Button>
            </ModalShift>
          </Permission>
          <Permission permissions={[PERMISSIONS.them_lich_lam_viec]}>
            <ModalScheduleStaff>
              <Button
                size="large"
                icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                type="primary"
              >
                Thêm lịch làm việc
              </Button>
            </ModalScheduleStaff>
          </Permission>
        </Space>
      </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_lich_lam_viec) ? (
        <>
          <Row
            align="middle"
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 5,
              marginTop: 10,
              marginBottom: 10,
              backgroundColor: 'white',
            }}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={7}
              xl={7}
              style={{
                borderRadius: 5,
                borderRight: '1px solid #d9d9d9',
              }}
            >
              <Input
                style={{ width: '100%' }}
                prefix={<SearchOutlined />}
                name="name"
                value={valueSearch}
                onChange={(e) => onSearch(e)}
                placeholder="Tìm kiếm lịch làm việc"
                bordered={false}
                allowClear
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <FilterDate
                style={{ with: '100%', borderRight: '1px solid #d9d9d9' }}
                bordered={false}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                width="100%"
                title="tạo lịch"
                valueTime={valueTime}
                setValueTime={setValueTime}
                screen={1}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <Select
                clearIcon={<CloseOutlined />}
                allowClear
                bordered={false}
                showSearch
                optionFilterProp="children"
                placeholder="Nhân viên tạo"
                style={{ width: '100%', borderRight: '1px solid #d9d9d9' }}
                value={paramsFilter.creator_id || 'all'}
                defaultValue={''}
                onChange={(value) => onFilters('creator_id', value)}
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                }
              >
                <Select.Option value="all">Tất cả nhân viên</Select.Option>
                {employees.map((employee, index) => (
                  <Select.Option value={employee.user_id} key={index}>
                    {employee.first_name} {employee.last_name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={3} xl={3}>
              <Button
                style={{ display: Object.keys(paramsFilter).length < 3 && 'none', width: '100%' }}
                onClick={onClickClear}
                type="primary"
                danger
              >
                Xóa tất cả lọc
              </Button>
            </Col>
          </Row>

          <Table
            loading={loading}
            dataSource={schedule}
            size="small"
            scroll={{ y: 620 }}
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 70, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
              },
              total: countSchedule,
            }}
            columns={columns.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  render: (text, record, index) => index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  render: (text, record) =>
                    dataUser.user_id == record.creator_id || dataUser.role_id === 1 ? (
                      permissions.includes(PERMISSIONS.xem_chi_tiet_lich_lam_viec) ||
                      permissions.includes(PERMISSIONS.cap_nhat_lich_lam_viec) ||
                      permissions.includes(PERMISSIONS.xem_lich_lam_viec_nhan_vien) ? (
                        <Link to={`/schedule-staff-admin/` + record.schedule_id}>
                          <div href>{record.code}</div>
                        </Link>
                      ) : (
                        <div
                        style={{ color: '#5F73E2', cursor: 'pointer' }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        {record.code}
                      </div>
                      )
                    ) : permissions.includes(PERMISSIONS.xem_chi_tiet_lich_lam_viec) ? (
                      <Link to={`/schedule-staff/` + record.schedule_id}>
                        <div >{record.code}</div>
                      </Link>
                    ) : (
                      <div
                        style={{ color: '#5F73E2', cursor: 'pointer' }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        {record.code}
                      </div>
                    ),
                }
              if (column.key === 'title')
                return {
                  ...column,
                  render: (text, record) => record.title,
                  sorter: (a, b) =>
                    compareCustom(a.title ? `${a.title}` : '', b.title ? `${b.title}` : ''),
                }
              if (column.key === 'start_date')
                return {
                  ...column,
                  sorter: (a, b) => moment(a.start_range).unix() - moment(b.start_range).unix(),
                  render: (text, record) =>
                    record.start_range && moment(record.start_range).format('DD/MM/YYYY'),
                }

              if (column.key === 'end_date')
                return {
                  ...column,
                  sorter: (a, b) => moment(a.end_range).unix() - moment(b.end_range).unix(),
                  render: (text, record) =>
                    record.end_range && moment(record.end_range).format('DD/MM/YYYY'),
                }

              if (column.key === 'creator')
                return {
                  ...column,
                  sorter: (a, b) =>
                    compareCustom(
                      a._creator ? `${a._creator.first_name} ${a._creator.last_name}` : '',
                      b._creator ? `${b._creator.first_name} ${b._creator.last_name}` : ''
                    ),
                  render: (text, record) =>
                    record._creator
                      ? `${record._creator.first_name} ${record._creator.last_name}`
                      : '',
                }

              if (column.key === 'note')
                return {
                  ...column,
                  // render:(text, record) => record.note,
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) =>
                    dataUser.role_id == 1 || dataUser.user_id == record.creator_id ? (
                      <Permission permissions={[PERMISSIONS.xoa_lich_lam_viec]}>
                        <Popconfirm
                          title="Bạn có muốn xóa lịch này không?"
                          cancelText="Từ chối"
                          okText="Đồng ý"
                          onConfirm={() => _deleteSchedule(record.schedule_id)}
                        >
                          <Button danger type="primary" icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Permission>
                    ) : null,
                }
              return column
            })}
          />
        </>
      ) : null}
    </div>
  )
}
