import React, { useEffect, useState } from 'react'
import localStorage from "../../utils/localStorage";
import { t } from 'i18next'

//components antd
import { Modal, Button, Checkbox } from 'antd'

import { SettingOutlined } from '@ant-design/icons'

export default function SettingColumns({
  columns,
  setColumns,
  columnsDefault,
  nameColumn,
  width = 700,
  btn = (
    <Button
      style={{ borderWidth: 1, borderColor: 'orange' }}
      icon={<SettingOutlined />}
      size="large"
    >
      {t('common.adjust_column')}
    </Button>
  ),
}) {
  const [visible, setVisible] = useState(false)

  const toggle = () => setVisible(!visible)

  useEffect(() => {
    if (!localStorage.getItem(nameColumn)) {
      localStorage.setItem(nameColumn, JSON.stringify(columnsDefault))
      setColumns([...columnsDefault])
    } else setColumns(JSON.parse(localStorage.getItem(nameColumn)))
  }, [])

  return (
    <>
      <div onClick={toggle}>{btn}</div>

      <Modal
        width={width}
        title={t('common.adjust_the_column_displayed_on_the_list_page')}
        visible={visible}
        footer={null}
        onCancel={toggle}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {columnsDefault.map((columnDefault, index) => (
            <div style={{ width: '33.333333%', marginBottom: 10 }} key={index}>
              <Checkbox
                onChange={(event) => {
                  let columnsNew = [...columns]
                  if (event.target.checked) {
                    columnsNew.splice(index, 0, { ...columnDefault })
                  } else {
                    const indexHidden = columns.findIndex((c) => c.title === columnDefault.title)
                    columnsNew.splice(indexHidden, 1)
                  }
                  //lưu setting columns lên localstorage
                  localStorage.setItem(nameColumn, JSON.stringify(columnsNew))
                  setColumns([...columnsNew])
                }}
                defaultChecked={
                  columns.filter((column) => column.title === columnDefault.title).length
                }
              >
                {columnDefault.title}
              </Checkbox>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}
