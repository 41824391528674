import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  Row,
  Card,
  Skeleton,
  Divider,
  Col,
  Dropdown,
  Space,
  Menu,
  Radio,
  Input,
  notification,
  Spin,
} from 'antd'
import QRCode from 'qrcode.react'
import { getOrders } from 'apis/order'
import { useSelector } from 'react-redux'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getCustomers, getOneCustomer, updateDeliveryAddress } from 'apis/customer'
import DeliveryAddress from './delivery-address'
import EditDeliveryAddress from './edit-delivery-address'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
const ListDeliveryAddress = ({ editInvoice, address, customer }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('default')
  const _getDeliveryAddress = async () => {
    try {
      const res = await getOneCustomer(customer?.phone)
      if (res.status === 200) {
        setDeliveryAddress(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _deleteDeliveryAddress = async () => {
    try {
      setLoading(true)
      await updateDeliveryAddress(customer.customer_id, {
        index: value,
        is_delete: 1,
      })
      notification.success({ message: 'Xoá địa chỉ giao hàng thành công' })
      _getDeliveryAddress()
      setTimeout(() => {
        setLoading(false)
      })
      setValue('default')
    } catch (error) {
      console.log(error)
    }
  }
  const menu = (
    <Menu
      items={[
        {
          label:
            value === 'default' ? (
              <></>
            ) : (
              <EditDeliveryAddress
                customer={customer}
                index={value}
                address={deliveryAddress !== null && deliveryAddress.customerDelivery[value]}
                getDeliveryAddress={_getDeliveryAddress}
              />
            ),
          key: '0',
        },
        {
          label:
            value === 'default' ? (
              <></>
            ) : (
              <Button onClick={_deleteDeliveryAddress} style={{ width: '100%' }}>
                Xoá
              </Button>
            ),
          key: '1',
        },
      ]}
    />
  )

  const _handleDeliveryAddress = () => {
    try {
      if (value === 'default') {
        editInvoice('shipping_info', {
          address: deliveryAddress.address,
          name: deliveryAddress.first_name + ' ' + deliveryAddress.last_name,
          phone: deliveryAddress.phone,
          // province_id: deliveryAddress.province_id,
          // district_id: deliveryAddress.district_id,
          // ward_code: deliveryAddress.ward_code,
          // province:
          //   deliveryAddress.province &&
          //   (deliveryAddress.province.province_name || deliveryAddress.province || ''),
          // district:
          //   deliveryAddress.district &&
          //   (deliveryAddress.district.district_name || deliveryAddress.district || ''),
          // ward:
          //   deliveryAddress.ward && (deliveryAddress.ward.ward_name || deliveryAddress.ward || ''),
          first_name: deliveryAddress.first_name,
          last_name: deliveryAddress.last_name,
        })
      } else {
        editInvoice('shipping_info', {
          address: deliveryAddress.customerDelivery[value].address,
          name:
            deliveryAddress.customerDelivery[value].first_name +
            ' ' +
            deliveryAddress.customerDelivery[value].last_name,
          phone: deliveryAddress.customerDelivery[value].phone,
          // province_id: deliveryAddress.customerDelivery[value].province.province_id,
          // district_id: deliveryAddress.customerDelivery[value].district.district_id,
          // ward_code: deliveryAddress.customerDelivery[value].ward.ward_code,

          // province: deliveryAddress.customerDelivery[value].province.province_name,
          // district: deliveryAddress.customerDelivery[value].district.district_name,
          // ward: deliveryAddress.customerDelivery[value].ward.ward_name,

          first_name: deliveryAddress.customerDelivery[value].first_name,
          last_name: deliveryAddress.customerDelivery[value].last_name,
        })
      }
      notification.success({ message: 'Xác nhận địa chỉ giao hàng thành công' })

      setIsModalVisible(false)
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Vui lòng chọn địa chỉ giao hàng trước khi xác nhận' })
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }
  

  const handleCancel = () => {
    if (value === 'default') {
      _handleDeliveryAddress()
    }
    setIsModalVisible(false)
  }

  useEffect(() => {
    setValue('default')
  }, [customer])

  useEffect(() => {
    _getDeliveryAddress()
  }, [customer, isModalVisible, address])

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
      }}
      spin
    />
  )

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Thay đổi
      </Button>
      {deliveryAddress !== null && (
        <Modal
          width={700}
          title={`Thông tin địa chỉ - ${
            deliveryAddress.first_name + ' ' + deliveryAddress.last_name
          }`}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={_handleDeliveryAddress}>
              Xác nhận
            </Button>,
          ]}
        >
          <div
            id="scrollableDiv"
            style={{
              height: 350,
              overflow: 'auto',
              backgroundColor: '#DCDCDC',
            }}
          >
            <InfiniteScroll
              dataLength={
                (deliveryAddress &&
                  deliveryAddress.customerDelivery &&
                  deliveryAddress.customerDelivery.length) ||
                0
              }
              hasMore={
                (deliveryAddress &&
                  deliveryAddress.customerDelivery &&
                  deliveryAddress.customerDelivery.length > 5) ||
                0
              }
              loader={
                <Skeleton
                  avatar
                  paragraph={{
                    rows: 1,
                  }}
                  active
                />
              }
              endMessage=""
              scrollableTarget="scrollableDiv"
            >
              <Card
                onClick={() => setValue('default')}
                style={value === 'default' ? { backgroundColor: 'rgb(194, 220, 253)' } : {}}
                bodyStyle={{ padding: 10 }}
                size="small"
              >
                <div>
                  <Row>
                    <Col span={1}>
                      <Radio checked={value === 'default' ? true : false}></Radio>
                    </Col>
                    <Col span={18}>
                      <h3>
                        <strong>
                          {deliveryAddress.first_name + ' ' + deliveryAddress.last_name}
                        </strong>
                        | <span style={{ fontSize: '15px' }}>{deliveryAddress.phone}</span>
                      </h3>
                      <p style={{ marginBottom: 0 }}>
                        Địa chỉ: {deliveryAddress.address && (deliveryAddress.address || '')}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {deliveryAddress.ward &&
                          (deliveryAddress.ward.ward_name ||', '+deliveryAddress.ward || '')}
                        
                        {deliveryAddress.district &&
                          (deliveryAddress.district.district_name ||
                            ', '+deliveryAddress.district ||
                            '')}
                        
                        {deliveryAddress.province &&
                          (deliveryAddress.province.province_name ||
                            ', '+deliveryAddress.province ||
                            '')}
                      </p>
                    </Col>
                    <Col>
                      <a>Địa chỉ mặc định</a>
                    </Col>
                  </Row>
                </div>
              </Card>
              {loading === true ? (
                <Spin indicator={antIcon} />
              ) : (
                deliveryAddress.customerDelivery &&
                deliveryAddress.customerDelivery.map((item, index) => (
                  <Card
                    onClick={() => setValue(index)}
                    style={value === index ? { backgroundColor: 'rgb(194, 220, 253)' } : {}}
                    bodyStyle={{ padding: 10 }}
                    size="small"
                    key={index}
                  >
                    <div>
                      <Row>
                        <Col span={1}>
                          <Radio checked={value === index ? true : false}></Radio>
                        </Col>
                        <Col span={18}>
                          <h3>
                            <strong>{item.first_name + ' ' + item.last_name}</strong> |{' '}
                            <span style={{ fontSize: '15px' }}>{item.phone}</span>
                          </h3>
                          <p style={{ marginBottom: 0 }}>
                            Địa chỉ: {item.address && (item.address || '')}
                          </p>
                          {/* <p style={{ marginBottom: 0 }}>
                            {item.ward && (item.ward.ward_name || item.ward || '')},{' '}
                            {item.district && (item.district.district_name || item.district || '')},{' '}
                            {item.province && (item.province.province_name || item.province || '')}
                          </p> */}
                        </Col>

                        <Col>
                          <Row>
                            <Dropdown trigger={['click']} overlay={menu}>
                              <Button type="primary" onClick={(e) => e.preventDefault()}>
                                <Space>
                                  Tuỳ chỉnh
                                  <DownOutlined />
                                </Space>
                              </Button>
                            </Dropdown>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ))
              )}
            </InfiniteScroll>
          </div>
          <div style={{ marginTop: '10px' }}>
            <DeliveryAddress
              address={address}
              customer={deliveryAddress}
              getDeliveryAddress={_getDeliveryAddress}
            />
          </div>
        </Modal>
      )}
    </>
  )
}
export default ListDeliveryAddress
