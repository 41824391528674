import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button, Col, Input, Modal, notification, Row } from 'antd'
import React, { useState } from 'react'

const AddRoleModal = ({ newRole, setNewRole, _addRole, roleCheck, visibleAddRole, handleModalAddRole }) => {
  const [name, setName] = useState('')

  const addRole = () => {
    if (name.length > 0) {
      let new_đata = [...newRole]
      if (newRole.find((item) => item == name)) {
        notification.error({ key: 'is exited name', message: 'Tên vai trò đã được thêm' })
      } else {
        if (new_đata.length + roleCheck.length >= 20) {
          notification.error({
            key: 'is max value is 20',
            message: 'Chỉ được thêm tối đa 20 vai trò',
          })
        } else {
          setName('')
          new_đata.push(name)
        }
      }
      setNewRole(new_đata)
    } else {
      notification.error({ key: 'invalid name', message: 'Vui lòng nhập tên vai trò' })
    }
  }

  const deleteRole = (index) => {
    let new_đata = [...newRole]
    new_đata.splice(index, 1)
    setNewRole(new_đata)
  }

  return (
    <Modal
      visible={visibleAddRole}
      closable={false}
      title="Thêm vai trò"
      footer={[
        <Row gutter={[12]} justify="end">
          <Col>
            <Button
              onClick={() => {
                handleModalAddRole()
                setNewRole([])
                setName('')
              }}
            >
              Đóng
            </Button>
          </Col>
          <Col>
            <Button type="primary"
            onClick={async () => {
                try {
                    if(newRole.length === 0) return notification.error({ key: 'invalid arr', message: 'Vui lòng nhập vai trò cần thêm' })
                    await _addRole(newRole)
                } catch (error) {
                    notification.error({ key: 'invalid arr', message: error})
                }
            }}
            >Xác nhận</Button>
          </Col>
        </Row>,
      ]}
    >
      <span style={{ fontSize: '15px', width: '120px', fontWeight: '500' }}>Tên vai trò:</span>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          size="large"
          name="name"
          value={name}
          onChange={(e) => {
            e.target.value = e.target.value.trim()
            setName(e.target.value)
          }}
          placeholder="Nhập tên vai trò mới"
        />
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <Button onClick={addRole} style={{ height: '100%' }}>
            <UserAddOutlined />
            Thêm
          </Button>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <span style={{ fontSize: '13px', fontWeight: '400', color: 'red' }}>
          * Vui lòng không được tạo quá 20 vai trò !
        </span>
      </div>

      <div style={{ marginTop: '5px', display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            width: '49%',
            marginRight: '2%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div>
            <span style={{ fontSize: '15px', width: '120px', fontWeight: '500' }}>
              Danh sách vai trò đã tạo:
            </span>
          </div>

          <div>
            {roleCheck?.map((item, index) => (
              <div style={{ marginLeft: '30px' }} key={index}>
                - {item.name}
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            width: '49%',
            borderLeft: '1px solid silver',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div>
            <span style={{ fontSize: '15px', width: '120px', fontWeight: '500' }}>
              Danh sách vai trò mới thêm:
            </span>
          </div>
          <div>
            {newRole?.map((item, index) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    marginRight: '7px',
                  }}
                  key={index}
                >
                  - {item}
                </div>
                <DeleteOutlined
                  onClick={() => deleteRole(index)}
                  style={{ color: 'red', cursor: 'pointer' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddRoleModal
