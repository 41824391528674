import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './header-sell.module.scss'
import { Bell } from 'utils/icon'
import { ACTION } from 'consts'
import DefaultLogo from 'assets/img/default_logo_ekata.png'

import { Dropdown, Avatar, Badge, Row, Empty } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
const HeaderProfile = ({widthData}) => {
  const user = useSelector((state) => state.login.dataUser)
  const dispatch = useDispatch()

  const NotifyContent = () => (
    <div className={styles['notificationBox']}>
      <div className={styles['title']}>Thông báo</div>
      <div className={styles['content']}>
        <Empty />
      </div>
    </div>
  )

  const onSignOut = () => {
    dispatch({ type: 'UPDATE_INVOICE', data: [] })
    dispatch({ type: ACTION.LOGOUT })
  }
  const content = (
    <div className={styles['user_information']}>
      <div>
        <a onClick={onSignOut} style={{ color: '#565656', paddingLeft: 10 }}>
          <div>
            <ExportOutlined style={{ fontSize: '1rem', marginRight: 10, color: '#565656' }} />
            Đăng xuất
          </div>
        </a>
      </div>
    </div>
  )
  return (
    <Row wrap={false} align="middle" >
      <div style={{ marginTop: 8, marginRight: 15, marginLeft: 15 }}>
        <Dropdown overlay={<NotifyContent />} placement="bottom" trigger="click">
          <Badge count={0} showZero size="small" offset={[-3, 3]}>
            <Bell style={{ color: 'rgb(253, 170, 62)', cursor: 'pointer' }} />
          </Badge>
        </Dropdown>
      </div>
      <Dropdown overlay={content} trigger="click">
        <Row align="middle" wrap={false} style={{ cursor: 'pointer' }}>
        {widthData > 1480 && <Avatar
            src={(user && user.avatar) || DefaultLogo}
            style={{ color: '#FFF', backgroundColor: '#FDAA3E', width: 35, height: 35 }}
          />}
          <span
            style={{
              textTransform: 'capitalize',
              marginLeft: 5,
              color: 'white',
              fontWeight: 600,
            }}
          >
            {user?.first_name}<br></br> {user?.last_name}
          </span>
        </Row>
      </Dropdown>
    </Row>
  )
}
export default HeaderProfile
