import { get, patch, post, destroy } from './httpClient'

export const getCustomerTypes = (query) => get('/customer/type', query)
export const addCustomerType = (body) => post('/customer/type/create', body)
export const getCustomers = (query) => get('/customer', query)
export const getCustomerList = (query) => get('/customer/getWeb', query)
export const getOrderCustomer = (query) => get('/customer/ordercustomer', query)
export const getOneCustomer = (id) => get(`/customer/getOneCustomer/${id}`)
export const updateDeliveryAddress = (id, body) => patch(`/customer/updateDeliveryAddress/${id}`, body)

export const addCustomer = (body) => post('/customer/create', body)
export const deleteCustomer = (body) => destroy('/customer/delete', body)
export const updateCustomer = (id, body) => patch(`/customer/update/${id}`, body)
export const importCustomers = (formData) => post('/customer/create/file', formData)
export const syncPoint = (body) => post('/customer/sync-point', body)

