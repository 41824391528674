import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styles from './promotion.module.scss'
import { Modal, Row, Col, Button, Checkbox, Space, Input, notification, Image, Popover } from 'antd'
import { CheckCircleOutlined, CheckCircleFilled } from '@ant-design/icons'
import gift from 'assets/icons/gift.png'
import { formatCash } from 'utils'
import DefaultLogo from 'assets/img/default_logo_ekata.png'

//apis
import { getPromotions, checkVoucher } from 'apis/promotion'
import { ACTION } from 'consts'
import { useDispatch } from 'react-redux'

export default function PromotionAvailable({ invoiceCurrent, editInvoice }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [voucherCheck, setVoucherCheck] = useState('')
  const [availableApply, setAvailableApply] = useState(false)

  const [promotions, setPromotions] = useState([])
  const [promotionCheck, setPromotionCheck] = useState(null)
  const [promotionFind, setPromotionFind] = useState(null)
  const [checkPromotionData, setCheckPromotionData] = useState(false)

  const dispatch = useDispatch()

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  let nowDay = moment().startOf('date').unix()

  const PromotionItem = ({ promotion }) => (
    <Popover
      content={
        moment(promotion?.start_date).startOf('date').unix() > nowDay ? (
          <span style={{ color: 'red' }}>
            Khuyến mãi sẽ bắt đầu vào ngày {moment(promotion.start_date).format('DD-MM-YYYY')}
          </span>
        ) : (
          <span style={{ color: 'red' }}>
            Khuyến mãi này yêu cầu hoá đơn tối thiểu {formatCash(promotion.order_value_require)}
            <br></br>
            <strong>
              {' '}
              Bạn còn thiếu{' '}
              {formatCash(
                promotion.order_value_require -
                  (invoiceCurrent.sumCostPaid - invoiceCurrent.discount_product)
              )}
            </strong>
          </span>
        )
      }
    >
      <div className={styles['container']}>
        <div className={styles['img']}>
          <img
            src={(promotion.image && promotion.image) || DefaultLogo}
            alt=""
            style={{ width: 104, height: 104, padding: 3, objectFit: 'cover' }}
          />
        </div>
        <Checkbox
          style={{ marginLeft: 8 }}
          checked={
            checkPromotionData === true
              ? promotionCheck && promotionCheck._id === promotion._id
                ? true
                : false
              : false
          }
          // checked={promotionCheck && promotionCheck._id === promotion._id ? true : false}
          onClick={(e) => {
            const checked = e.target.checked
            if (checked) {
              let today = moment().startOf('date').unix()
              let someday = moment(promotion.start_date).startOf('date').unix()

              if (someday > today) {
                notification.warning({
                  key: `Thông báo chưa đến ngày kích hoạt ${promotion.promotion_id}`,
                  message: `Khuyến mãi sẽ bắt đầu vào ngày ${moment(promotion.start_date).format(
                    'DD/MM/YYYY'
                  )}!`,
                })
                return
              }
              if (invoiceCurrent.moneyToBePaidByCustomer >= promotion.max_discount_value) {
                if (invoiceCurrent.order_details.length !== 0) setPromotionCheck(promotion)
                else
                  notification.warning({
                    message: t('sell.your_order_has_no_products_please_select_the_product_first'),
                  })
              } else
                notification.warning({
                  message: t('sell.your_order_value_is_not_eligible_to_apply_for_this_promotion'),
                })
              if (
                (promotion.order_value_require != 0 &&
                  invoiceCurrent.sumCostPaid < promotion.order_value_require) ||
                invoiceCurrent.order_details.length === 0
              ) {
                if (invoiceCurrent.sumCostPaid < promotion.order_value_require) {
                  notification.warning({
                    message: `Cần mua tối thiểu ${formatCash(
                      promotion.order_value_require
                    )} để được giảm giá`,
                  })
                  return
                }
              }
            } else {
              setPromotionCheck(null)
              setVoucherCheck('')
              setAvailableApply(false)
            }
          }}
        >
          <div className={styles['content']}>
            <h2 className={styles['title']}>
              Giảm {formatCash(promotion.value)} {promotion.type === 'VALUE' ? 'VNĐ' : '%'}
            </h2>
            <p className={styles['note']}>
              Đơn tối thiểu {formatCash(promotion.order_value_require) + 'VNĐ'} trở lên
            </p>
            <p className={styles['note']}>
              Giảm tối đa{' '}
              {formatCash(
                promotion.type === 'VALUE' ? promotion.value : promotion.max_discount_value
              ) + ' VNĐ'}
            </p>
            <p className={styles['note']}>
              Số lượng còn lại:{' '}
              {promotion.limit_quantity > 0 ? promotion.voucher_quantity : 'Không giới hạn'}
            </p>
            <p style={{ fontSize: 10, fontWeight: 'bold', marginTop: 5 }}>
              Thời gian khuyến mãi: {moment(promotion.start_date).format('DD/MM/YYYY')} -{' '}
              {moment(promotion.end_date).format('DD/MM/YYYY')}
            </p>
          </div>
        </Checkbox>
      </div>
    </Popover>
  )

  const _getPromotions = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getPromotions()
      let today = moment().startOf('date').unix()
      if (res.status === 200) {
        try {
          let newData = res.data.data.reduce((arr, item) => {
            if (item.end_date) {
              let someday = moment(item.end_date).startOf('date').unix()
              if (today < someday) {
                arr.push(item)
              }
            } else {
              arr.push(item)
            }
            return arr
          }, [])
          let newData2 = newData.reduce((arr, item) => {
            if (item.limit_quantity === 0) {
              arr.push(item)
            } else {
              if (item.voucher_quantity > 0) {
                arr.push(item)
              }
            }
            return arr
          }, [])
          setPromotions(newData2.filter((e) => !e.has_voucher && e.is_active === true))
          dispatch({ type: ACTION.LOADING, data: false })
        } catch (error) {
          dispatch({ type: ACTION.LOADING, data: false })
          console.log(error)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _applyVoucher = () => {
    editInvoice('discount', promotionCheck)
    toggle()
  }

  const _checkVoucher = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      setLoading(true)
      const res = await checkVoucher(voucherCheck)
      if (res.status === 200) {
        if (res.data.data) setPromotionFind(res.data.data)
        else
          notification.warning({
            message: res.data.message || t('sell.voucher_check_failed_please_try_again'),
          })
      } else
        notification.warning({
          message: res.data.message || t('sell.voucher_check_failed_please_try_again'),
        })

      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      setLoading(false)

      console.log(error)
    }
  }
  useEffect(() => {
    _getPromotions()
  }, [])

  return (
    <div style={{ marginBottom: 14 }}>
      {promotions && promotions.length !== 0 ? (
        <a onClick={toggle}>Hoá đơn sắp đủ điều kiện nhận KM</a>
      ) : (
        ''
      )}

      <Modal
        width={900}
        visible={visible}
        title="Khuyến mãi"
        onCancel={toggle}
        footer={
          <Row justify="end">
            {/* <Button
              onClick={_applyVoucher}
              type="primary"
              style={{
                backgroundColor: '#0877DE',
                borderRadius: 5,
                borderColor: '#0877DE',
              }}
            >
              {t('common.apply')}
            </Button> */}
          </Row>
        }
      >
        <Row style={{ overflowY: 'auto', maxHeight: 600 }}>
          <Row style={{ marginBottom: 30 }}>
            <Col span={12}>
              <h3 style={{ marginBottom: 0, fontSize: 17 }}>
                {t('sell.check_voucher_promotion_code')}
              </h3>
            </Col>
            <Col span={12}>
              <Space wrap={false}>
                <Input
                  value={voucherCheck}
                  onChange={(e) => setVoucherCheck(e.target.value)}
                  placeholder={t('sell.enter_voucher_promotion_code')}
                  style={{ width: 300 }}
                />
                <Button
                  onClick={_checkVoucher}
                  loading={loading}
                  type="primary"
                  style={{
                    backgroundColor: '#0877DE',
                    borderRadius: 5,
                    borderColor: '#0877DE',
                  }}
                >
                  {t('common.check')}
                </Button>
              </Space>
            </Col>
          </Row>
          <Col>
            <div className={styles['displaygrid']}>
              {promotionFind === null ? (
                promotions.length > 0 &&
                promotions.map((promotion) => <PromotionItem promotion={promotion} />)
              ) : (
                <PromotionItem promotion={promotionFind} />
              )}
            </div>

            {promotions.length == 0 && (
              <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
                {'Không có chương trình khuyến mãi khả dụng'}
              </div>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
