import React, { useEffect, useState } from 'react'
import { Preload } from 'preloads'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { ACTION, ROUTES } from 'consts'

//base layout
import BaseLayout from 'components/Layout/index'
import Authentication from 'components/authentication'

//components
import Permission from 'components/permission'
import { PERMISSIONS } from 'consts'

//views
import Businesses from './businesses'
import LoginBusiness from './login-business'
import DetailBusiness from './businesses/detail'

import Login from './login'
import Register from './register'
import ProductCheck from './product-check'
import OrderList from './order-list'
import SalesReport from './sales-report'
import ReportInventory from './report-inventory'
import ShippingControl from './shipping-control'
import ShippingControlForm from './shipping-control/shipping-control-form'
import Guarantee from './guarantee'
import GuaranteeForm from './guarantee/guarantee-form'
import ShippingProduct from './shipping-product'
import ShippingProductUpdate from './shipping-product/shipping-product-update'

import ClientManagement from './client-management'
import Branch from './branch'
import Reports from './reports'
import ReportVariant from './report-variant'
import ReportImportExportInventory from './report-import-export-inventory'
import StockAdjustments from './stock-adjustments'
import StockAdjustmentsCreate from './stock-adjustments/stock-adjustments-create'
import StockAdjustmentsUpdate from './stock-adjustments/stock-adjustments-update'

import StockAdjustmentsAdvanced from './stock-adjustments-advanced'
import StockAdjustmentsAdvancedUpdate from './stock-adjustments-advanced-form'
import ShippingControlUpdate from './shipping-control/shipping-control-update'
import ShippingProductForm from './shipping-product/shipping-product-form'
import RolePermission from './role-permission'

import ConfigurationStore from './configuration-store'
import OTP from './otp'
import Setting from './setting'
import ReceiptsAndPayment from './receipts-and-payment'
import PasswordNew from './password-new'
import ForgetPassword from './forget-password'
import Overview from './overview'
import Sell from './sell'
import ConnectDevice from './connect-device'
import Refund from './refund'
import RefundOrderCreate from './refund-create'
import RefundOrderUpdate from './refund-update'
import Store from './store'
import VerifyAccount from './verify-account'
import ActivityDiary from './activity-diary'
import PriceChangeHistory from './price-change-history'
import SaleHistory from './sale-history'
import ReturnHistory from './return-history'

import NotFound from './not-found/404'

import OrderCreate from './order-create'
import OrderUpdate from './order-update'

import Categories from './categories'
import CreateCategory from './category-create'
import UpdateCategory from './category-update'

import Inventory from './inventory'
import OfferList from './offer-list'
import Product from './product'
import ProductCreate from './product-create'
import ProductUpdate from './product-update'
import Payment from './payment'
import Tax from './tax'
import Employee from './employee'
import Shipping from './shipping'
import CustomerList from './customer-list'
import CustomerType from './customer-type'
import CustommerDetail from './customer-list/customer-detail'
import CustomerUpdate from './customer-update'
import Supplier from './supplier'
import Promotion from './promotion'
import Role from './role'
import PriceAdjustments from './price-adjustments'
import PriceAdjustmentsCreate from './price-adjustments/price-adjustments-create/index'
import PriceAdjustmentsUpdate from './price-adjustments-update'

import Point from './point'
import OfferListCreate from './offer-list-create'
import Blog from './blog'
import BlogCreate from './blog-create'
import Brand from './brand'
import SettingBill from './setting-bill'
import BrandCreate from './brand-create'
import Channel from './channel'
import Contact from './contact'
import ImportInventories from './import-inventories'
import ImportSmartProduct from './import-smart-product'
import ImportInventoryCreate from './import-inventory-create'
import ImportInventoryExcel from './import-inventories/import-inventory'
import ImportInventoryUpdate from './import-inventory-update'
import ImportReportFile from './import-report-file'
import DeliveryControl from './delivery-control'
import ShippingForm from './shipping/shipping-form'

import ConfigInvoiceSale from './config-invoice-sale'
import ConfigInvoiceDelivery from './config-invoice-delivery'
import ReportListOrderFollowPayment from './list-order-follow-payment'
import ListOrderFollowPayment from './list-order-follow-payment'
import LockShift from './lock-shift'
import Staff from './staff/staff'
import PromotionForm from './promotion/promotion-form'
import PromotionUpdate from './promotion-update'
import ProductBarcode from './product-barcode'
import ScheduleStaff from './schedule-staff'
import ScheduleDetail from './schedule-staff/detail-Schedule'
import ScheduleRegister from './schedule-staff/register/schedule-register'
import FinanceType from './finance-type'
import ReportInventoryByDate from './report-inventory-by-date'
import ImportCustomer from './import-customer'
import ImportProduct from './import-products'
import SettingPayment from './setting-payment'
import RollCall from './roll-call'

import { io } from 'socket.io-client'
import { socket } from '../socket'
import { useDispatch, useSelector } from 'react-redux'
import localStorage from '../utils/localStorage'
import { PERMISSIONS_APP } from './role-permission/items/tree/tree-column'

const DEFINE_ROUTER = [
  {
    path: ROUTES.PRODUCT_CHECK,
    Component: () => <ProductCheck />,
    title: 'Kiểm hàng cuối ngày',
    permissions: [PERMISSIONS.kiem_hang_cuoi_ngay],
    exact: true,
  },
  {
    path: ROUTES.PRICE_ADJUSTMENTS,
    Component: () => <PriceAdjustments />,
    title: 'Danh sách phiếu điều chỉnh',
    permissions: [
      PERMISSIONS.xem_danh_sach_dieu_chinh_gia,
      PERMISSIONS.xem_chi_tiet_phieu_dieu_chinh_gia,
      PERMISSIONS.tao_phieu_dieu_chinh,
      PERMISSIONS.cap_nhat_phieu_dieu_chinh,
      PERMISSIONS.export_phieu_dieu_chinh,
    ],
    exact: true,
  },
  {
    path: ROUTES.PRICE_ADJUSTMENTS_CREATE,
    Component: () => <PriceAdjustmentsCreate />,
    title: 'Thêm mới phiếu điều chỉnh',
    permissions: [PERMISSIONS.tao_phieu_dieu_chinh],
    exact: true,
  },
  {
    path: ROUTES.PRICE_ADJUSTMENTS_UPDATE + '/:code',
    Component: () => <PriceAdjustmentsUpdate />,
    title: 'Chi tiết phiếu điều chỉnh',
    permissions: [
      PERMISSIONS.cap_nhat_phieu_dieu_chinh,
      PERMISSIONS.xem_chi_tiet_phieu_dieu_chinh_gia,
    ],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_ADD,
    Component: () => <ProductCreate />,
    title: 'Thêm sản phẩm',
    permissions: [PERMISSIONS.them_san_pham],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_UPDATE + '/:sku',
    Component: () => <ProductUpdate />,
    title: 'Chi tiết sản phẩm',
    permissions: [PERMISSIONS.cap_nhat_san_pham, PERMISSIONS.chi_tiet_san_pham],
    exact: true,
  },

  {
    path: ROUTES.ORDER_LIST,
    Component: () => <OrderList />,
    title: 'Danh sách đơn hàng',
    permissions: [
      PERMISSIONS.xem_danh_sach_don_hang,
      PERMISSIONS.tao_don_hang,
      PERMISSIONS.chi_tiet_don_hang,
      PERMISSIONS.cap_nhat_don_hang,
      PERMISSIONS.export_don_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.SALES_REPORT,
    Component: () => <SalesReport />,
    title: 'Báo cáo bán hàng',
    permissions: [PERMISSIONS.bao_cao_ban_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_INVENTORY,
    Component: () => <ReportInventory />,
    title: 'Báo cáo tồn kho',
    permissions: [PERMISSIONS.bao_cao_ton_kho],
    exact: true,
  },
  {
    path: ROUTES.REPORT_INVENTORY_DATE,
    Component: () => <ReportInventoryByDate />,
    title: 'Báo cáo tồn kho theo lô',
    permissions: [PERMISSIONS.bao_cao_ton_kho_theo_lo],
    exact: true,
  },
  {
    path: ROUTES.REPORTS,
    Component: () => <Reports />,
    title: 'Danh sách báo cáo',
    permissions: [
      PERMISSIONS.danh_sach_bao_cao,
      PERMISSIONS.bao_cao_ton_kho,
      PERMISSIONS.bao_cao_xuat_nhap_ton,
      PERMISSIONS.bao_cao_ban_hang,
      PERMISSIONS.bao_cao_ban_hang_theo_phuong_thuc_thanh_toan,
      PERMISSIONS.bao_cao_ton_kho_theo_lo,

      PERMISSIONS.bao_cao_thu_chi,
      PERMISSIONS.them_phieu_thu_chi,
      PERMISSIONS.xoa_phieu_thu_chi,
      PERMISSIONS.cap_nhat_phieu_thu_chi,
      PERMISSIONS.export_phieu_thu_chi,

      PERMISSIONS.loai_phieu_thu_chi,
    ],
    exact: true,
  },
  {
    path: ROUTES.STOCK_ADJUSTMENTS,
    Component: () => <StockAdjustments />,
    title: 'Kiểm hàng',
    permissions: [
      PERMISSIONS.xem_danh_sach_kiem_hang_co_ban,
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_co_ban,
      PERMISSIONS.them_phieu_kiem_hang_co_ban,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_co_ban,
      PERMISSIONS.can_bang_phieu_kiem_hang_co_ban,
      PERMISSIONS.export_phieu_kiem_hang_co_ban,
      PERMISSIONS.xoa_phieu_kiem_hang_co_ban,
    ],
    exact: true,
  },
  {
    path: ROUTES.STOCK_ADJUSTMENTS_CREATE,
    Component: () => <StockAdjustmentsCreate />,
    title: 'Tạo phiếu kiểm hàng',
    permissions: [PERMISSIONS.them_phieu_kiem_hang_co_ban],
    exact: true,
  },
  {
    path: ROUTES.STOCK_ADJUSTMENTS_UPDATE + '/:code',
    Component: () => <StockAdjustmentsUpdate />,
    title: 'Chi tiết phiếu kiểm hàng',
    permissions: [
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_co_ban,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_co_ban,
      PERMISSIONS.can_bang_phieu_kiem_hang_co_ban,
      PERMISSIONS.xoa_phieu_kiem_hang_co_ban,
    ],
    exact: true,
  },
  {
    path: ROUTES.STOCK_ADJUSTMENTS_ADVANCED,
    Component: () => <StockAdjustmentsAdvanced />,
    title: 'Kiểm hàng nâng cao',
    permissions: [
      PERMISSIONS.xem_danh_sach_kiem_hang_nang_cao,
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_nang_cao,
      PERMISSIONS.them_phieu_kiem_hang_nang_cao,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao,
      PERMISSIONS.can_bang_phieu_kiem_hang_nang_cao,
      PERMISSIONS.export_phieu_kiem_hang_nang_cao,
      PERMISSIONS.xoa_phieu_kiem_hang_nang_cao
    ],
    exact: true,
  },
  {
    path: ROUTES.STOCK_ADJUSTMENTS_ADVANCED_UPDATE + '/:code',
    Component: () => <StockAdjustmentsAdvancedUpdate />,
    title: 'Chi tiết phiếu Kiểm hàng nâng cao',
    permissions: [
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_nang_cao,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao,
    ],
    exact: true,
  },
  {
    path: ROUTES.REPORT_IMPORT_EXPORT_INVENTORY,
    Component: () => <ReportImportExportInventory />,
    title: 'Báo cáo nhập xuất tồn',
    permissions: [PERMISSIONS.bao_cao_xuat_nhap_ton],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_CUSTOMER,
    Component: () => <ImportCustomer />,
    title: 'Nhập danh sách khách hàng',
    permissions: [PERMISSIONS.them_khach_hang],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_PRODUCT,
    Component: () => <ImportProduct />,
    title: 'Nhập danh sách sản phẩm',
    permissions: [PERMISSIONS.them_san_pham],
    exact: true,
  },
  {
    path: ROUTES.PRICE_CHANGE_HISTORY,
    Component: () => <PriceChangeHistory />,
    title: 'Lịch sử điều chỉnh giá',
    permissions: [PERMISSIONS.lich_su_thay_doi_gia],
    exact: true,
  },
  {
    path: ROUTES.SALE_HISTORY,
    Component: () => <SaleHistory />,
    title: 'Lịch sử bán hàng',
    permissions: [PERMISSIONS.lich_su_ban_hang],
    exact: true,
  },
  {
    path: ROUTES.RETURN_HISTORY,
    Component: () => <ReturnHistory />,
    title: 'Lịch sử trả hàng',
    permissions: [PERMISSIONS.lich_su_tra_hang],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_CONTROL,
    Component: () => <ShippingControl />,
    title: 'Đối soát vận chuyển',
    permissions: [
      PERMISSIONS.xem_danh_sach_doi_soat_van_chuyen,
      PERMISSIONS.xem_chi_tiet_phieu_doi_soat_van_chuyen,
      PERMISSIONS.them_phieu_doi_soat_van_chuyen,
      PERMISSIONS.cap_nhat_doi_soat_van_chuyen,
      PERMISSIONS.xoa_phieu_doi_soat_van_chuyen,
      PERMISSIONS.export_phieu_doi_soat_van_chuyen,
    ],
    exact: true,
  },
  {
    path: ROUTES.DELIVERY_CONTROL,
    Component: () => <DeliveryControl />,
    title: 'Quản lý giao hàng',
    permissions: [
      PERMISSIONS.xem_danh_sach_giao_hang,
      PERMISSIONS.xem_chi_tiet_phieu_giao_hang,
      PERMISSIONS.tao_don_hang,
      PERMISSIONS.cap_nhat_giao_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_CONTROL_ADD,
    Component: () => <ShippingControlForm />,
    title: 'Tạo phiếu đối soát vận chuyển',
    permissions: [PERMISSIONS.them_phieu_doi_soat_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_CONTROL_UPDATE + '/:code',
    Component: () => <ShippingControlUpdate />,
    title: 'Chi tiết phiếu đối soát vận chuyển',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.GUARANTEE,
    Component: () => <Guarantee />,
    title: 'Quản lý bảo hành',
    permissions: [
      PERMISSIONS.xem_danh_sach_bao_hanh, 
      PERMISSIONS.xem_chi_tiet_bao_hanh, 
      PERMISSIONS.cau_hinh_bao_hanh,
      PERMISSIONS.xem_bao_hang,
      PERMISSIONS.them_bao_hanh,
      PERMISSIONS.cap_nhat_bao_hanh,
      PERMISSIONS.xoa_bao_hanh,
      PERMISSIONS.export_bao_hanh,
    ],
    exact: true,
  },
  {
    path: ROUTES.GUARANTEE_ADD,
    Component: () => <GuaranteeForm />,
    title: 'Thêm bảo hành',
    permissions: [PERMISSIONS.them_bao_hanh, PERMISSIONS.cap_nhat_bao_hanh, PERMISSIONS.xem_chi_tiet_bao_hanh],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_PRODUCT,
    Component: () => <ShippingProduct />,
    title: 'Quản lý chuyển hàng',
    permissions: [
      PERMISSIONS.xem_danh_sach_chuyen_hang,
      PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang,
      PERMISSIONS.tao_phieu_chuyen_hang,
      PERMISSIONS.cap_nhat_phieu_chuyen_hang,
      PERMISSIONS.xoa_phieu_chuyen_hang,
      PERMISSIONS.export_phieu_chuyen_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.CONFIG_INVOICE_SALE,
    Component: () => <ConfigInvoiceSale />,
    title: 'Cấu hình hoá đơn bán hàng',
    permissions: [
      PERMISSIONS.cau_hinh_hoa_don,
      PERMISSIONS.xem_danh_sach_hoa_don,
      PERMISSIONS.cau_hinh_mau_hoa_don,
    ],
    exact: true,
  },
  {
    path: ROUTES.CONFIG_INVOICE_DELIVERY,
    Component: () => <ConfigInvoiceDelivery />,
    title: 'Cấu hình hoá đơn giao hàng',
    permissions: [PERMISSIONS.cau_hinh_hoa_don, PERMISSIONS.cau_hinh_mau_hoa_don],
    exact: true,
  },
  {
    path: ROUTES.CLIENT_MANAGEMENT,
    Component: () => <ClientManagement />,
    title: 'Quản lý client',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.LIST_ORDER_FOLLOW_PAYMENT,
    Component: () => <ReportListOrderFollowPayment />,
    title: 'Báo cáo bán hàng theo phương thức thanh toán',
    permissions: [PERMISSIONS.bao_cao_ban_hang_theo_phuong_thuc_thanh_toan],
    exact: true,
  },
  {
    path: ROUTES.BRANCH_MANAGEMENT,
    Component: () => <Branch />,
    title: 'Quản lý chi nhánh ',
    permissions: [
      PERMISSIONS.xem_danh_sach_chi_nhanh,
      PERMISSIONS.xem_chi_tiet_chi_nhanh,
      PERMISSIONS.cau_hinh_chi_nhanh,
      PERMISSIONS.them_chi_nhanh,
      PERMISSIONS.cap_nhat_chi_nhanh,
    ],
    exact: true,
  },
  {
    path: ROUTES.CONFIGURATION_STORE,
    Component: () => <ConfigurationStore />,
    title: 'Cấu hình thông tin cửa hàng',
    permissions: [
      PERMISSIONS.cau_hinh,
      PERMISSIONS.cau_hinh_chi_nhanh,
      PERMISSIONS.them_chi_nhanh,
      PERMISSIONS.xem_danh_sach_chi_nhanh,
      PERMISSIONS.xem_chi_tiet_chi_nhanh,
      PERMISSIONS.cap_nhat_chi_nhanh,
      PERMISSIONS.cau_hinh_phan_quyen,
      PERMISSIONS.xem_phan_quyen,
      PERMISSIONS.them_vai_tro_phan_quyen,
      PERMISSIONS.cap_nhat_vai_tro_phan_quyen,
      PERMISSIONS.xoa_vai_tro_phan_quyen,
      PERMISSIONS.cau_hinh_hoa_don,
      PERMISSIONS.xem_danh_sach_hoa_don,
      PERMISSIONS.cau_hinh_mau_hoa_don,
      PERMISSIONS.cau_hinh_bao_hanh,
      PERMISSIONS.xem_danh_sach_bao_hanh,
      PERMISSIONS.xem_chi_tiet_bao_hanh,
      PERMISSIONS.them_bao_hanh,
      PERMISSIONS.cap_nhat_bao_hanh,
      PERMISSIONS.xoa_bao_hanh,
      PERMISSIONS.export_bao_hanh,
      PERMISSIONS.cau_hinh_thue,
      PERMISSIONS.xem_danh_sach_thue,
      PERMISSIONS.xem_chi_tiet_thue,
      PERMISSIONS.them_thue,
      PERMISSIONS.cap_nhat_thue,
      PERMISSIONS.xoa_thue,
      PERMISSIONS.cau_hinh_phuong_thuc_thanh_toan,
      PERMISSIONS.xem_danh_sach_phuong_thuc_thanh_toan,
      PERMISSIONS.xem_chi_tiet_phuong_thuc_thanh_toan,
      PERMISSIONS.them_phuong_thuc_thanh_toan,
      PERMISSIONS.cap_nhat_phuong_thuc_thanh_toan,
      PERMISSIONS.xoa_phuong_thuc_thanh_toan,
      PERMISSIONS.cau_hinh_khuyen_mai,
      PERMISSIONS.xem_danh_sach_khuyen_mai,
      PERMISSIONS.xem_chi_tiet_khuyen_mai,
      PERMISSIONS.them_khuyen_mai,
      PERMISSIONS.cap_nhat_khuyen_mai,
      PERMISSIONS.xoa_khuyen_mai,
      PERMISSIONS.cau_hinh_tich_diem,
      PERMISSIONS.cap_nhat_tich_diem,
      PERMISSIONS.xem_danh_sach_khach_hang_tich_diem,
      PERMISSIONS.cau_hinh_nhap_xuat_file,
      PERMISSIONS.cau_hinh_nhat_ki_hoat_dong,
    ],
    exact: true,
  },
  {
    path: ROUTES.OVERVIEW,
    Component: () => <Overview />,
    title: 'Tổng quan',
    permissions: [PERMISSIONS.tong_quan],
    exact: true,
  },
  {
    path: ROUTES.STORE,
    Component: () => <Store />,
    title: 'Quản lý cửa hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.POINT,
    Component: () => <Point />,
    title: 'Tích điểm',
    permissions: [
      PERMISSIONS.cau_hinh_tich_diem,
      PERMISSIONS.cap_nhat_tich_diem,
      PERMISSIONS.xem_danh_sach_khach_hang_tich_diem,
    ],
    exact: true,
  },
  {
    path: ROUTES.ACTIVITY_DIARY,
    Component: () => <ActivityDiary />,
    title: 'Nhật ký hoạt động',
    permissions: [PERMISSIONS.cau_hinh_nhat_ki_hoat_dong],
    exact: true,
  },
  {
    path: ROUTES.ORDER_CREATE,
    Component: () => <OrderCreate />,
    title: 'Tạo đơn hàng',
    permissions: [PERMISSIONS.tao_don_hang],
    exact: true,
  },
  {
    path: ROUTES.ORDER_UPDATE + '/:code',
    Component: () => <OrderUpdate />,
    title: 'Chi tiết đơn hàng',
    permissions: [PERMISSIONS.chi_tiet_don_hang, PERMISSIONS.cap_nhat_don_hang],
    exact: true,
  },

  {
    path: ROUTES.INVENTORY,
    Component: () => <Inventory />,
    title: 'Quản lý kho',
    permissions: [
      PERMISSIONS.xem_danh_sach_dieu_chinh_gia,
      PERMISSIONS.xem_chi_tiet_phieu_dieu_chinh_gia,
      PERMISSIONS.tao_phieu_dieu_chinh,
      PERMISSIONS.cap_nhat_phieu_dieu_chinh,
      PERMISSIONS.export_phieu_dieu_chinh,
      PERMISSIONS.xem_danh_sach_nhap_hang,
      PERMISSIONS.xem_chi_tiet_phieu_nhap_hang,
      PERMISSIONS.them_phieu_nhap_hang,
      PERMISSIONS.cap_nhat_phieu_nhap_hang,
      PERMISSIONS.xoa_phieu_nhap_hang,
      PERMISSIONS.export_nhap_hang,
      PERMISSIONS.xem_danh_sach_kiem_hang_co_ban,
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_co_ban,
      PERMISSIONS.them_phieu_kiem_hang_co_ban,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_co_ban,
      PERMISSIONS.can_bang_phieu_kiem_hang_co_ban,
      PERMISSIONS.export_phieu_kiem_hang_co_ban,
      PERMISSIONS.xem_danh_sach_kiem_hang_nang_cao,
      PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_nang_cao,
      PERMISSIONS.them_phieu_kiem_hang_nang_cao,
      PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao,
      PERMISSIONS.can_bang_phieu_kiem_hang_nang_cao,
      PERMISSIONS.export_phieu_kiem_hang_nang_cao,
      PERMISSIONS.xem_danh_sach_chuyen_hang,
      PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang,
      PERMISSIONS.tao_phieu_chuyen_hang,
      PERMISSIONS.cap_nhat_phieu_chuyen_hang,
      PERMISSIONS.xoa_phieu_chuyen_hang,
      PERMISSIONS.export_phieu_chuyen_hang,
    ],
    exact: true,
  },

  {
    path: ROUTES.PRODUCT,
    Component: () => <Product />,
    title: 'Quản lý sản phẩm',
    permissions: [
      PERMISSIONS.xem_danh_sach_san_pham,
      PERMISSIONS.them_san_pham,
      PERMISSIONS.chi_tiet_san_pham,
      PERMISSIONS.cap_nhat_san_pham,
      PERMISSIONS.xoa_san_pham,
      PERMISSIONS.export_san_pham,
      PERMISSIONS.in_ma_san_pham,
      PERMISSIONS.mo_ban_san_pham,
    ],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_BARCODE,
    Component: () => <ProductBarcode />,
    title: 'In Mã Sản Phẩm',
    permissions: [PERMISSIONS.in_ma_san_pham],
    exact: true,
  },
  {
    path: ROUTES.PAYMENT,
    Component: () => <Payment />,
    title: 'Quản lý hình thức thanh toán',
    permissions: [
      PERMISSIONS.cau_hinh_phuong_thuc_thanh_toan,
      PERMISSIONS.xem_danh_sach_phuong_thuc_thanh_toan,
      PERMISSIONS.xem_chi_tiet_phuong_thuc_thanh_toan,
      PERMISSIONS.them_phuong_thuc_thanh_toan,
      PERMISSIONS.cap_nhat_phuong_thuc_thanh_toan,
      PERMISSIONS.xoa_phuong_thuc_thanh_toan,
    ],
    exact: true,
  },
  {
    path: ROUTES.TAX,
    Component: () => <Tax />,
    title: 'Quản lý thuế',
    permissions: [
      PERMISSIONS.cau_hinh_thue,
      PERMISSIONS.xem_danh_sach_thue,
      PERMISSIONS.xem_chi_tiet_thue,
      PERMISSIONS.them_thue,
      PERMISSIONS.cap_nhat_thue,
      PERMISSIONS.xoa_thue,
    ],
    exact: true,
  },

  {
    path: ROUTES.IMPORT_REPORT_FILE,
    Component: () => <ImportReportFile />,
    title: 'Nhập xuất File',
    permissions: [PERMISSIONS.cau_hinh_nhap_xuat_file],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING,
    Component: () => <Shipping />,
    title: 'Quản lý đối tác vận chuyển',
    permissions: [
      PERMISSIONS.xem_danh_sach_doi_tac_van_chuyen,
      PERMISSIONS.them_doi_tac_van_chuyen,
      PERMISSIONS.cap_nhat_doi_tac_van_chuyen,
      PERMISSIONS.xoa_doi_tac_van_chuyen,
    ],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_CREATE,
    Component: () => <ShippingForm />,
    title: 'Thêm đối tác vận chuyển',
    permissions: [PERMISSIONS.them_doi_tac_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.CUSTOMER,
    Component: () => <CustomerList />,
    title: 'Quản lý khách hàng',
    permissions: [
      PERMISSIONS.xem_danh_sach_khach_hang,
      PERMISSIONS.xem_chi_tiet_khach_hang,
      PERMISSIONS.them_khach_hang,
      PERMISSIONS.cap_nhat_khach_hang,
      PERMISSIONS.xoa_khach_hang,
      PERMISSIONS.export_khach_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.CUSTOMER_TYPE,
    Component: () => <CustomerType />,
    title: 'Quản lý loại khách hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CUSTOMER_DETAIL + '/:code',
    Component: () => <CustommerDetail />,
    title: 'Chi tiết khách hàng',
    permissions: [PERMISSIONS.xem_chi_tiet_khach_hang, PERMISSIONS.cap_nhat_khach_hang],
    exact: true,
  },
  {
    path: ROUTES.SUPPLIER,
    Component: () => <Supplier />,
    title: 'Quản lý nhà cung cấp',
    permissions: [
      PERMISSIONS.xem_danh_sach_nha_cung_cap,
      PERMISSIONS.xem_chi_tiet_nha_cung_cap,
      PERMISSIONS.them_nha_cung_cap,
      PERMISSIONS.cap_nhat_nha_cung_cap,
      PERMISSIONS.xoa_nha_cung_cap,
      PERMISSIONS.export_nha_cung_cap,
    ],
    exact: true,
  },
  {
    path: ROUTES.PROMOTION,
    Component: () => <Promotion />,
    title: 'Khuyến mãi',
    permissions: [
      PERMISSIONS.xem_danh_sach_khuyen_mai,
      PERMISSIONS.cau_hinh_khuyen_mai,
      PERMISSIONS.xem_chi_tiet_khuyen_mai,
      PERMISSIONS.them_khuyen_mai,
      PERMISSIONS.cap_nhat_khuyen_mai,
      PERMISSIONS.xoa_khuyen_mai,
    ],
    exact: true,
  },
  {
    path: ROUTES.ROLE,
    Component: () => <RolePermission />,
    title: 'Quản lý phân quyền',
    permissions: [
      PERMISSIONS.cau_hinh_phan_quyen,
      PERMISSIONS.xem_phan_quyen,
      PERMISSIONS.them_vai_tro_phan_quyen,
      PERMISSIONS.cap_nhat_vai_tro_phan_quyen,
      PERMISSIONS.xoa_vai_tro_phan_quyen,
    ],
  },
  {
    path: ROUTES.SHIPPING_PRODUCT_ADD,
    Component: () => <ShippingProductForm />,
    title: 'Thêm phiếu chuyển hàng',
    permissions: [
      PERMISSIONS.tao_phieu_chuyen_hang,
      PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang,
      PERMISSIONS.cap_nhat_phieu_chuyen_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.SHIPPING_PRODUCT_UPDATE  + '/:code',
    Component: () => <ShippingProductUpdate />,
    title: 'Chi tiết phiếu chuyển hàng',
    permissions: [
      PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang,
      PERMISSIONS.cap_nhat_phieu_chuyen_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.CATEGORIES,
    Component: () => <Categories />,
    title: 'Nhóm sản phẩm',
    permissions: [
      PERMISSIONS.xem_danh_sach_nhom_san_pham,
      PERMISSIONS.tao_nhom_san_pham,
      PERMISSIONS.xem_chi_tiet_nhom_san_pham,
      PERMISSIONS.xoa_nhom_san_pham,
      PERMISSIONS.cap_nhat_nhom_san_pham,
    ],
    exact: true,
  },
  {
    path: ROUTES.CREATE_CATEGORY,
    Component: () => <CreateCategory />,
    title: 'Tạo nhóm sản phẩm',
    permissions: [PERMISSIONS.tao_nhom_san_pham],
    exact: true,
  },
  {
    path: ROUTES.DETAIL_CATEGORY + '/:code',
    Component: () => <UpdateCategory />,
    title: 'Chi tiết nhóm sản phẩm',
    permissions: [PERMISSIONS.xem_chi_tiet_nhom_san_pham, PERMISSIONS.cap_nhat_nhom_san_pham],
    exact: true,
  },
  {
    path: ROUTES.SETTING,
    Component: () => <Setting />,
    title: 'Cài đặt',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.OFFER_LIST,
    Component: () => <OfferList />,
    title: 'Danh sách ưu đãi',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.OFFER_LIST_CREATE,
    Component: () => <OfferListCreate />,
    title: 'Tạo ưu đãi',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.BLOG,
    Component: () => <Blog />,
    title: 'Danh sách bài viết',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.BLOG_CREATE,
    Component: () => <BlogCreate />,
    title: 'Tạo bài viết',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.BRAND,
    Component: () => <Brand />,
    title: 'Danh sách thương hiệu',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.BRAND_CREATE,
    Component: () => <BrandCreate />,
    title: 'Tạo thương hiệu',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CHANNEL,
    Component: () => <Channel />,
    title: 'Danh sách kênh',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_INVENTORIES,
    Component: () => <ImportInventories />,
    title: 'Nhập hàng',
    permissions: [
      PERMISSIONS.xem_danh_sach_nhap_hang,
      PERMISSIONS.xem_chi_tiet_phieu_nhap_hang,
      PERMISSIONS.them_phieu_nhap_hang,
      PERMISSIONS.cap_nhat_phieu_nhap_hang,
      PERMISSIONS.xoa_phieu_nhap_hang,
      PERMISSIONS.export_nhap_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_SMART_PRODUCT,
    Component: () => <ImportSmartProduct />,
    title: 'Nhập nhanh sản phẩm',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_INVENTORY_EXCEL,
    Component: () => <ImportInventoryExcel />,
    title: 'Nhập hàng excel',
    permissions: [PERMISSIONS.them_phieu_nhap_hang],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_INVENTORY_CREATE,
    Component: () => <ImportInventoryCreate />,
    title: 'Tạo đơn hàng nhập kho',
    permissions: [PERMISSIONS.them_phieu_nhap_hang],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_INVENTORY_UPDATE + '/:code',
    Component: () => <ImportInventoryUpdate />,
    title: 'Chi tiết đơn hàng nhập kho',
    permissions: [PERMISSIONS.xem_chi_tiet_phieu_nhap_hang, PERMISSIONS.cap_nhat_phieu_nhap_hang],
    exact: true,
  },
  {
    path: ROUTES.RECEIPTS_PAYMENT,
    Component: () => <ReceiptsAndPayment />,
    title: 'Báo cáo thu chi',
    permissions: [
      PERMISSIONS.bao_cao_thu_chi,
      PERMISSIONS.xem_danh_sach_phieu_thu_chi,
      PERMISSIONS.xem_chi_tiet_phieu_thu_chi,
      PERMISSIONS.xem_thu_chi,
      PERMISSIONS.them_phieu_thu_chi,
      PERMISSIONS.xoa_phieu_thu_chi,
      PERMISSIONS.cap_nhat_phieu_thu_chi,
      PERMISSIONS.export_phieu_thu_chi,
      // PERMISSIONS.loai_phieu_thu_chi,
      // PERMISSIONS.xem_loai_phieu_thu_chi,
      // PERMISSIONS.them_loai_phieu_thu_chi,
      // PERMISSIONS.xoa_loai_phieu_thu_chi,
      // PERMISSIONS.cap_nhat_loai_phieu_thu_chi,
    ],
    exact: true,
  },
  {
    path: ROUTES.SELL,
    Component: () => <Sell />,
    title: 'Bán hàng',
    permissions: [PERMISSIONS.ban_hang],
    exact: true,
  },
  {
    path: ROUTES.REFUND,
    Component: () => <Refund />,
    title: 'Trả hàng',
    permissions: [
      PERMISSIONS.tra_hang,
      PERMISSIONS.xem_danh_sach_tra_hang,
      PERMISSIONS.xem_chi_tiet_tra_hang,
      PERMISSIONS.cap_nhat_phieu_tra_hang,
      PERMISSIONS.export_phieu_tra_hang,
      PERMISSIONS.tao_phieu_tra_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.REFUND_CREATE,
    Component: () => <RefundOrderCreate />,
    title: 'Tạo phiếu trả hàng',
    permissions: [PERMISSIONS.tao_phieu_tra_hang],
    exact: true,
  },
  {
    path: ROUTES.REFUND_DETAIL + '/:code',
    Component: () => <RefundOrderUpdate />,
    title: 'Xem phiếu trả hàng',
    permissions: [
      // PERMISSIONS.xem_danh_sach_tra_hang,
      PERMISSIONS.xem_chi_tiet_tra_hang,
      PERMISSIONS.cap_nhat_phieu_tra_hang,
    ],
    exact: true,
  },
  {
    path: ROUTES.SETTING_BILL,
    Component: () => <SettingBill />,
    title: 'Cài đặt hoá đơn',
    permissions: [
      // PERMISSIONS.cau_hinh_hoa_don,
      PERMISSIONS.xem_danh_sach_hoa_don,
      PERMISSIONS.cau_hinh_mau_hoa_don,
    ],
    exact: true,
  },
  {
    path: ROUTES.LIST_ORDER_FOLLOW_PAYMENT,
    Component: () => <ListOrderFollowPayment />,
    title: 'Phương thức thanh toán',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_PAYMENT,
    Component: () => <SettingPayment/>,
    title: 'Cấu hình thanh toán',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.LOCK_SHIFT,
    Component: () => <LockShift />,
    title: 'Danh sách chốt ca',
    permissions: [PERMISSIONS.xem_danh_sach_chot_ca],
    exact: true,
  },
  {
    path: ROUTES.STAFF,
    Component: () => <Staff />,
    title: 'Quản lý nhân viên',
    permissions: [
      PERMISSIONS.xem_danh_sach_nhan_vien,
      PERMISSIONS.xem_chi_tiet_nhan_vien,
      PERMISSIONS.them_nhan_vien,
      PERMISSIONS.cap_nhat_nhan_vien,
      PERMISSIONS.xoa_nhan_vien,
    ],
    exact: true,
  },
  {
    path: ROUTES.PROMOTION_FORM,
    Component: () => <PromotionForm />,
    title: 'Tạo khuyến mãi',
    permissions: [PERMISSIONS.them_khuyen_mai],
    exact: true,
  },
  {
    path: ROUTES.PROMOTION_UPDATE + '/:code',
    Component: () => <PromotionUpdate />,
    title: 'Chi tiết khuyến mãi',
    permissions: [PERMISSIONS.xem_chi_tiet_khuyen_mai, PERMISSIONS.cap_nhat_khuyen_mai],
    exact: true,
  },
  {
    path: ROUTES.SCHEDULE_STAFF,
    Component: () => <ScheduleStaff />,
    title: 'Xếp Lịch Làm Việc',
    permissions: [
      PERMISSIONS.xem_danh_sach_lich_lam_viec,
      PERMISSIONS.xem_chi_tiet_lich_lam_viec,
      PERMISSIONS.cau_hinh_ca_lam_viec,
      PERMISSIONS.them_lich_lam_viec,
      PERMISSIONS.cap_nhat_lich_lam_viec,
      PERMISSIONS.xoa_lich_lam_viec,
      PERMISSIONS.xem_lich_lam_viec_nhan_vien
    ],
    exact: true,
  },
  {
    path: ROUTES.SCHEDULE_DETAIL,
    Component: () => <ScheduleDetail />,
    title: 'Chi tiết Lịch Làm Việc',
    permissions: [PERMISSIONS.cap_nhat_lich_lam_viec, PERMISSIONS.xem_chi_tiet_lich_lam_viec],
    exact: true,
  },
  {
    path: ROUTES.SCHEDULE_REGISTER,
    Component: () => <ScheduleRegister />,
    title: 'Chi tiết Lịch Làm Việc',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ROLE_PERMISSION,
    Component: () => <RolePermission />,
    title: 'Chi tiết Phân Quyền',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.FINANCE_TYPE,
    Component: () => <FinanceType />,
    title: 'Quản lý loại thu chi',
    permissions: [
      PERMISSIONS.loai_phieu_thu_chi,
      PERMISSIONS.xem_danh_sach_loai_phieu_thu_chi,
      PERMISSIONS.xem_chi_tiet_loai_phieu_thu_chi,
      PERMISSIONS.them_loai_phieu_thu_chi,
      PERMISSIONS.xoa_loai_phieu_thu_chi,
      PERMISSIONS.cap_nhat_loai_phieu_thu_chi,
    ],
    exact: true,
  },
  {
    path: ROUTES.DETAIL_BUSINESS,
    Component: () => <DetailBusiness />,
    title: 'Chi tiết cửa hàng',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ROLL_CALL,
    Component: () => <RollCall />,
    title: 'Ca làm việc',
    permissions: [PERMISSIONS.diem_danh, PERMISSIONS.xem_danh_sach_diem_danh, PERMISSIONS.cap_nhat_diem_danh],
    exact: true,
  },
]

const AUTH_ROUTER = [
  {
    path: ROUTES.CHECK_SUBDOMAIN,
    Component: () => <LoginBusiness />,
    exact: true,
    title: 'Login business',
    permissions: [],
  },
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    exact: true,
    title: 'Login',
    permissions: [],
  },
  {
    path: ROUTES.REGISTER,
    Component: () => <Register />,
    exact: true,
    title: 'Register',
    permissions: [],
  },
  {
    path: ROUTES.OTP,
    Component: () => <OTP />,
    exact: true,
    title: 'OTP',
    permissions: [],
  },
  {
    path: ROUTES.VERIFY_ACCOUNT,
    Component: () => <VerifyAccount />,
    title: 'Xác thực tài khoản',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PASSWORD_NEW,
    Component: () => <PasswordNew />,
    exact: true,
    title: 'New password',
    permissions: [],
  },
  {
    path: ROUTES.FORGET_PASSWORD,
    Component: () => <ForgetPassword />,
    exact: true,
    title: 'Forget password',
    permissions: [],
  },
  {
    path: ROUTES.CONTACT,
    Component: () => <Contact />,
    title: 'Liên hệ',
    permissions: [],
    exact: true,
  },
]

const GUEST_ROUTER = [
  {
    path: ROUTES.CONNECT_DEVICE + '/:code',
    Component: () => <ConnectDevice />,
    title: 'Dữ liệu bán hàng',
    permissions: [],
    exact: true,
  },

]

export default function Views() {
  const GUEST_ROUTER = [
    {
      path: ROUTES.CONNECT_DEVICE + '/:code',
      Component: () => <ConnectDevice/>,
      title: 'Dữ liệu bán hàng',
      permissions: [],
      exact: true,
    },
  ]
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)
  const socketUrl = process.env.REACT_APP_SSO_SOCKET
  const socket = io(socketUrl)
  const { ekataSession, user_id } = useSelector((state) => state.login.dataUser)
  localStorage.setItem('collapsed', JSON.stringify(false))
  useEffect(() => {
    socket.on(`auth:logout:staff:${ekataSession}:${user_id}`, function (res) {
      dispatch({ type: ACTION.LOGOUT })
      dispatch({ type: 'UPDATE_INVOICE', data: [] })
    })
  })
  Preload()
  return (
    <BrowserRouter>
      <Switch>
        {dataUser?.data?._role?.permission_list?.length === 0 ? (
          <Route path="/" exact={true}>
            <Redirect to={ROUTES.SETTING} />
          </Route>
        ) : (
          <Route path="/" exact={true}>
            <Redirect to={ROUTES.DETAIL_BUSINESS} />
          </Route>
        )}

        {DEFINE_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
              <Authentication {...rest} dataUser={dataUser}>
              {rest.path === ROUTES.SELL ? (
                <Component />
              ) : (
                <BaseLayout>
                  <Component />
                </BaseLayout>
              )}
            </Authentication>
          </Route>
        ))}

        {AUTH_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}

        {GUEST_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
