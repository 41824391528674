import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { compare, formatCash } from 'utils'
import { useHistory } from 'react-router-dom'
import { ACTION, FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE, ROUTES } from 'consts'
import { useDispatch, useSelector } from 'react-redux'

//antd
import { Row, Col, Button, Table, DatePicker, Select } from 'antd'

//icons
import { ArrowLeftOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'

//components
import TitlePage from 'components/title-page'
import columnsSalesReport from './columns'
import exportToCSV from 'components/ExportCSV/export'
import FilterDate from 'components/filter-date'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

//apis
import { getReportOrder } from 'apis/report'
import { getProducts } from 'apis/product'

export default function ReportFinancial() {
  const dispatch = useDispatch()
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [valueTime, setValueTime] = useState()

  const [loading, setLoading] = useState(false)
  const [countReport, setCountReport] = useState(0)
  const [product, setProduct] = useState([])
  const [valueFilter, setValueFilter] = useState()
  const [salesReport, setSalesReport] = useState([])
  const [total, setTotal] = useState({
    sale_quantity: 0,
    total_revenue: 0,
    base_price: 0,
    gross_profit: 0,
    profit_rate: 0,
  })

  const _clearFilters = async () => {
    setValueFilter()
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check:1 })
    setValueTime()
  }

  const _getReportOrderToExportExcel = async () => {
    let reportNew = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let new_params_filter = {...paramsFilter}
      delete new_params_filter.page
      delete new_params_filter.page_size
      const res = await getReportOrder({...new_params_filter, branch_id: branchIdApp})
      if (res.status === 200) {
        if (!valueId) {
          res.data.data.map((e, index) => {
            reportNew.push({
              STT: index + 1,
              'Mã hàng': e.product ? e.product.code : '',
              'Tên hàng': e.product ? e.product.name : '',
              ĐVT: e.product ? e.product.unit : '',
              'Số lượng': formatCash(e.sale_quantity || 0),
              'Doanh thu': formatCash(e.total_revenue || 0),
              'Giá vốn': formatCash(e.base_price || 0),
              'Lợi nhuận gộp': formatCash(e.gross_profit || 0),
              '% Lợi nhuận': formatCash(e.profit_rate || 0),
            })
          })
        } else {
          let newProduct = res.data.data.filter((item) => item.sku === valueId)
          newProduct.map((e, index) => {
            reportNew.push({
              STT: index + 1,
              'Mã hàng': e.product ? e.product.code : '',
              'Tên hàng': e.product ? e.product.name : '',
              ĐVT: e.product ? e.product.unit : '',
              'Số lượng': formatCash(e.sale_quantity || 0),
              'Doanh thu': formatCash(e.total_revenue || 0),
              'Giá vốn': formatCash(e.base_price || 0),
              'Lợi nhuận gộp': formatCash(e.gross_profit || 0),
              '% Lợi nhuận': formatCash(e.profit_rate || 0),
            })
          })
          
        }
        
      }
      exportToCSV(reportNew, 'Báo cáo bán hàng','Xuất file excel',dispatch)
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })

    }
  }
  const [valueId, setValueId] = useState()

  const _getReportOrder = async () => {
    try {
      setLoading(true)
      const res = await getReportOrder({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        let totalNew = {
          sale_quantity: 0,
          total_revenue: 0,
          base_price: 0,
          gross_profit: 0,
          profit_rate: 0,
        }
        setCountReport(res.data.count)
        let reportNew = res.data.data.map((e) => ({
          code: e.product ? e.product.code : '',
          name: e.product ? e.product.name : '',
          unit: e.product ? e.product.unit : '',
          mfg: e.mfg || '',
          exp: e.exp || '',
          sale_quantity: e.sale_quantity || 0,
          total_revenue: e.total_revenue || 0,
          base_price: e.base_price || 0,
          gross_profit: e.gross_profit || 0,
          profit_rate: e.profit_rate || 0,
        }))
        setSalesReport([...reportNew])
          reportNew.map((e) => {
            totalNew.sale_quantity += e.sale_quantity || 0
            totalNew.total_revenue += e.total_revenue || 0
            totalNew.base_price += e.base_price || 0
            totalNew.gross_profit += e.gross_profit || 0
            totalNew.profit_rate += e.profit_rate || 0
          })
          setTotal(totalNew)
        // if (!valueId) {
        //   setSalesReport([...reportNew])
        //   reportNew.map((e) => {
        //     totalNew.sale_quantity += e.sale_quantity || 0
        //     totalNew.total_revenue += e.total_revenue || 0
        //     totalNew.base_price += e.base_price || 0
        //     totalNew.gross_profit += e.gross_profit || 0
        //     totalNew.profit_rate += e.profit_rate || 0
        //   })
        //   setTotal(totalNew)
        // } else {
        //   let newProduct = res.data.data.filter((item) => item.sku === valueId)
        //   let reportNew = newProduct.map((e) => ({
        //     code: e.product ? e.product.code : '',
        //     name: e.product ? e.product.name : '',
        //     unit: e.product ? e.product.unit : '',
        //     mfg: e.mfg || '',
        //     exp: e.exp || '',
        //     sale_quantity: e.sale_quantity || 0,
        //     total_revenue: e.total_revenue || 0,
        //     base_price: e.base_price || 0,
        //     gross_profit: e.gross_profit || 0,
        //     profit_rate: e.profit_rate || 0,
        //   }))
        //   setSalesReport([...reportNew])
        //   reportNew.map((e) => {
        //     totalNew.sale_quantity += e.sale_quantity || 0
        //     totalNew.total_revenue += e.total_revenue || 0
        //     totalNew.base_price += e.base_price || 0
        //     totalNew.gross_profit += e.gross_profit || 0
        //     totalNew.profit_rate += e.profit_rate || 0
        //   })
        //   setTotal(totalNew)
        // }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const _getProducts = async () => {
    try {
      const res = await getProducts()
      if (res.status === 200) setProduct(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getProducts()
  }, [branchIdApp])
  useEffect(() => {
    if(paramsFilter.check) _getReportOrder()
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setParamsFilter({page:1, page_size: PAGE_SIZE, from_date: result.from_date, to_date: result.to_date, check:1})
    setValueTime(result.valueDate)
    setValueFilter()
    setValueId()
  },[dataUser])
  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.REPORTS)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Báo cáo bán hàng
          </Row>
        }
      >
        <Button
          id='exportFile'
          icon={<VerticalAlignTopOutlined />}
          onClick={_getReportOrderToExportExcel}
          style={{ backgroundColor: 'green', borderColor: 'green' }}
          size="large"
          type="primary"
        >
          Xuất excel
        </Button>
      </TitlePage>

      <Row gutter={[10,16]} style={{ marginTop: 10, marginBottom: 10 }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={8}
          style={{ height: FILTER_COL_HEIGHT}}
        >
          <FilterDate
          size='default'
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            title=''
            valueTime={valueTime}
            setValueTime={setValueTime}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={6}
        >
          <Select
            size={FILTER_SIZE}
            allowClear
            bordered={true}
            defaultValue={''}
            showSearch
            onChange={(value) => {
              setValueFilter(value);
              let resultIndex
              if (value !== undefined) {
                let index = value.lastIndexOf('+')
                resultIndex = value.substring(index + 1)
              }
              setParamsFilter({...paramsFilter, sku: resultIndex, page: 1})
              setValueId(resultIndex)
            }}
            value={valueFilter || ''}
            placeholder="Lọc theo sản phẩm"
            style={{ width: '100%' }}
          >
            <Select.Option value="">Tất cả sản phẩm</Select.Option>
            {product.map((_product, index) => (
              <Select.Option
                value={`${_product.name}+${_product.sku}`}
                key={index}
              >
                {_product.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Button
          onClick={_clearFilters}
          style={{
            marginLeft: 10,
            display: Object.keys(paramsFilter).length < 3 && 'none',
            width: '10%',
          }}
          danger
          type="primary"
        >
          Xóa bộ lọc
        </Button>
      </Row>

      <Table
        columns={columnsSalesReport.map((column) => {
          if (column.key === 'stt') return { ...column, render: (text, record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1 }
          if (column.key === 'code') return { ...column, sorter: (a, b) => compare(a, b, 'code') }
          if (column.key === 'name') return { ...column, sorter: (a, b) => compare(a, b, 'name') }
          if (column.key === 'unit') return { ...column, sorter: (a, b) => compare(a, b, 'unit') }
          if (column.key === 'sale_quantity')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'sale_quantity'),
              render: (text, record) =>
                record.sale_quantity && formatCash(record.sale_quantity || 0),
            }
          if (column.key === 'total_revenue')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'total_revenue'),
              render: (text, record) =>
                record.total_revenue && formatCash(record.total_revenue || 0),
            }
          if (column.key === 'gross_profit')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'gross_profit'),
              render: (text, record) => record.gross_profit && formatCash(record.gross_profit || 0),
            }
          if (column.key === 'base_price')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'base_price'),
              render: (text, record) => record.base_price && formatCash(record.base_price || 0),
            }
          if (column.key === 'profit_rate')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'profit_rate'),
              render: (text, record) => record.profit_rate && formatCash(record.profit_rate || 0),
            }
          return column
        })}
        loading={loading}
        dataSource={salesReport}
        size="small"
        bordered
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          defaultPageSize: 20,
          pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
          total: countReport,
        }}
        scroll={{ y: 500 }}
        style={{ width: '100%' }}
        summary={(pageData) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <div style={{ fontWeight: 700 }}>Tổng</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{formatCash(total.sale_quantity || 0)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{formatCash(total.total_revenue || 0)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{formatCash(total.base_price || 0)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{formatCash(total.gross_profit || 0)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{formatCash(total.profit_rate || 0)}</div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
