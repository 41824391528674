import { Card, Rate } from 'antd'
import styled from 'styled-components'
import { formatCash } from 'utils'
import styles from './Product.module.scss'

const CardStyled = styled(Card)`
  .ant-card-body {
    padding: 12px;
  }
`

function Description(props) {
  const { price, priceOld, amount, deal, discount, rate } = props

  return (
    <div className={styles.product__description}>
      <div className={styles['product__block']}>
        <span className={styles.product__discount}>Giảm {discount}</span>
        <span className={styles.product__deal}>{deal}</span>
      </div>
      <div className={`${styles['product__block']} ${styles['product__block-price']}`}>
        <span className={styles.product__priceOld}>{priceOld}</span>
        <span className={styles.product__price}>{formatCash(price)}đ</span>
      </div>
      <div className={styles['product__block']}>
        <Rate allowHalf className={styles.product__rate} value={rate} />
        <span className={styles.product__amount}>Đã bán {amount}</span>
      </div>
    </div>
  )
}

function Product({ product }) {
  const { image, name, priceOld, price, amount, deal, discount, rate } = product

  return (
    <CardStyled
      hoverable
      cover={<img src={image} alt="" className={styles.product__img} />}
      className={styles.product}
    >
      <Card.Meta
        className={styles.product__meta}
        title={<p className={styles.product__title}>{name}</p>}
        description={
          <Description
            price={price}
            priceOld={priceOld}
            amount={amount}
            deal={deal}
            rate={rate}
            discount={discount}
          />
        }
      />
      <Card.Meta />
    </CardStyled>
  )
}

export default Product
