const initialState = {
  loading: false,
  variants: [],
  count: 2,
  variantFil: [],
  countFil: 50,
}

export const UpdateVariant = (data, data_state) => {
  const new_data = data_state.map((item) => {
    let handle_check = data.find(e => e.variant_id === item.variant_id)
    if(handle_check){
      if(item.type_advanced > 0){
        item.total_quantity = item.total_quantity - handle_check.quantity
      }
      if(handle_check.type_advanced === 2) {
        let new_arr_location = handle_check.location.filter(e => e.quantity !== 0)
        item.location_advanced.location = item.location_advanced.location.map((e) => {
          let index_location_quantity = new_arr_location.findIndex(lo => lo.location_id === e.location_id)
          if(index_location_quantity !== -1) {
            e.quantity = e.quantity - new_arr_location[index_location_quantity].quantity
          }
          return e
        })
      }
    }
    return item
  })
  return new_data
}

const variants = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VARIANTSELL': {
      return {
        variants: action.data.variants,
        variantFil:
          action.data.variants.length > 0 ? action.data.variants.slice(0, 50) : state.variantFil,
        counts: action.data.counts,
        countFil: 50,
      }
    }
    case 'SELL_CHANGE_VARIANT': {
      return {
        ...state,
        variants: UpdateVariant(action.data, state.variants),
      }
    }
    default:
      return state
  }
}
export default variants
