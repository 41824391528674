import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Modal, Row, notification } from 'antd'
import './item-style.css'
export const ModalVariant = ({ data, setVariants, variants, isModeUpdate,productDetail, newData}) => {
  const [visible, setVisible] = useState(false)
  const [description, setDescription] = useState(data.description)

  const editPrice = () => {
    let newDataVariant = [...variants]
    let indexVariant = newDataVariant.findIndex((e) => e.sku === data.sku)
    newDataVariant[indexVariant].description = description
    setVariants([...newDataVariant])
    setVisible(false)
  }

  const closeEditPrice = () => {
    setDescription(data.description)
    setVisible(false)
  }
  const InputAttribute = ({ value, variant }) => {
    const [valueAttribute, setValueAttribute] = useState(value)

    return (
      <Input
        disabled={
          isModeUpdate
            ? productDetail?.variants?.find((item) => item.title === value)
              ? true
              : false
            : true
        }
        placeholder="Nhập tên thuộc tính"
        size="large"
        defaultValue={value}
        onBlur={() => {
          let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
          if (regex.test(valueAttribute)) {
            return notification.error({
              message: 'Không được nhập kí tự đặc biệt ở tên phiên bản ',
            })
          } else {
            let variantsNew = [...variants]
            const index = variantsNew.findIndex((e) => e.title === variant.title)
            variantsNew[index].title = newData(valueAttribute)
            setVariants([...variantsNew])
          }
        }}
        onChange={(e) => setValueAttribute(e.target.value)}
        style={{ width: '100%' }}
      />
    )
  }
  useEffect(() => {
    setDescription(data.description)
  }, [data.description])

  return (
    <>
      <InputAttribute value={data.title} variant={data} />
      <div onClick={() => {
        if(isModeUpdate) {
          setVisible(true)
        } else {
          return
        }
        }
        } type="text"
        style={{textAlign:'center'}}>
        <a>Sửa mô tả</a>
      </div>
      <Modal
        visible={visible}
        closable={false}
        footer={[
          <Row gutter={[16, 0]} justify="end">
            <Col>
              <Button onClick={() => closeEditPrice()}>Đóng</Button>
            </Col>
            <Col>
              <Button onClick={() => editPrice()} type="primary">Xác nhận</Button>
            </Col>
          </Row>,
        ]}
        title="Mô tả phiên bản"
        width={500}
      >
        <Row gutter={[32, 0]}>
          <Col span={24}>
          <Input.TextArea
            placeholder="Mô tả sản phẩm"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={7}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}
