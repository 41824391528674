import {notification} from 'antd'

export const addProductToStore = (record, productChange, setProductChange) => {
    if(!record) return
    let new_data = [...productChange]
    let item = record?.variants.map((e) => ({
      product_id: record?.product_id,
      variant_id: e?.variant_id,
      sku: e?.sku,
      title: e?.title,
      price: e?.price || 0,
      default_price: e?.default_price || 0,
      new_price: e?.price || 0,
    }))
    if(new_data.length === 0) return setProductChange(item)
    let arr_id = new_data.map(e => e?.variant_id) 
    if(item.find(e => arr_id.includes(e.variant_id))) return notification.error({message: `Sản phẩm đã được thêm vào danh sách điều chỉnh`})
    setProductChange([...new_data, ...item])
}

export const addAllProductToStore = (products, setProductChange) => {
  setProductChange([])
  let item = products.map((e) => ({
    product_id: e.product_id,
    variant_id: e.variant_id,
    sku: e.sku,
    title: e.title,
    price: e.price || 0,
    default_price: e.default_price || 0,
    new_price: e.price || 0,
  }))
  setProductChange(item)
}

export const addVariantToStore = (record, productChange, setProductChange) => {
    if(!record) return
    let new_data = [...productChange]
    let item = {
      product_id: record.product_id,
      variant_id: record.variant_id,
      sku: record.sku,
      title: record.title,
      price: record.price || 0,
      default_price: record.default_price || 0,
      new_price: record.price || 0,
    }
    if(new_data.length === 0) return setProductChange([item])
    let arr_id = new_data.map(e => e.variant_id) 
    if(arr_id.includes(item.variant_id)) return notification.error({message: `Sản phẩm đã được thêm vào danh sách điều chỉnh`})
    setProductChange([...new_data, item])
}
