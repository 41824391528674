import { ACTION } from 'consts'
import jwt_decode from 'jwt-decode'
import localStorage from "../../utils/localStorage";

// nhận data từ server
const initialState = {
  dataUser: {},
  loading: false,
  username: '',
}
let login = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOGIN: {
      if (action.data.accessToken) {
        var data = jwt_decode(action.data.accessToken)
        return {
          ...state,
          dataUser: data.data,
          username: data.data.username,
        }
      }

      return {
        ...state,
      }
    }


    case 'UPDATE_DATA_USER': {
      return {
        ...state,
        dataUser: action.data,
      }
    }
    case 'UPDATE_DATA_BUSINESS': {
      return {
        ...state,
        _business: action.data,
      }
    }

    case 'SAVE_BRANCH_NAME': {
      return {
        ...state,
        branchName: action.data || '',
      }
    }

    case ACTION.LOGOUT: {
      localStorage.clear()
      window.location.href = window.location.href = 'http://' + process.env.REACT_APP_ECOM_HOST
      return {
        ...state,
        dataUser: {},
      }
    }

    case ACTION.LOADING: {
      state.loading = action.data
      return { ...state }
    }

    default:
      return state
  }
}
export default login
