import styles from './tab.module.scss'

function Tab({ classes, text, icon: Icon }) {
  return (
    <span className={`${styles.tab} ${classes}`}>
      <Icon />
      <span className={styles['tab__text']}>{text}</span>
    </span>
  )
}

export default Tab
