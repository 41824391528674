import React, { useEffect, useState, useRef } from 'react'
import localStorage from '../../utils/localStorage'
import moment from 'moment'
import { PAGE_SIZE, PERMISSIONS, ROUTES } from 'consts'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

//components
import columnsPrice from './columns'
import SettingColumns from 'components/setting-columns'
import TitlePage from 'components/title-page'
import Permissions from 'components/permission'
import FilterDate from 'components/filter-date'
import ImportCSV from 'components/ImportCSV'

//antd
import { Row, Col, Input, Button, Space, Table, Affix, notification, Popconfirm, Tag } from 'antd'

//icons
import { SearchOutlined } from '@ant-design/icons'

//apis
import {
  getPriceAdjustments,
  updatePriceAdjustments,
  deletePriceAdjustments,
} from 'apis/price-adjustments'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function PriceAdjustments() {
  const typingTimeoutRef = useRef(null)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [valueSearch, setValueSearch] = useState('')
  const [countPriceAdjustments, setCountPriceAdjustments] = useState(0)
  const [priceAdjustments, setPriceAdjustments] = useState([])
  const [loading, setLoading] = useState(false)
  const [valueTime, setValueTime] = useState()

  const _getPriceAdjustments = async () => {
    try {
      setLoading(true)
      const res = await getPriceAdjustments({ ...paramsFilter })
      if (res.status === 200) {
        setPriceAdjustments(res.data.data)
        setCountPriceAdjustments(res.data.count)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const _deletePriceAdjustments = async (id) => {
    try {
      setLoading(true)
      const res = await deletePriceAdjustments(id)
      if (res.status === 200) {
        if (res.data.success) {
          _getPriceAdjustments()
          notification.success({ message: 'Xóa phiếu thành công' })
        } else
          notification.error({
            message: res.data.message || `Xóa phiếu điều chỉnh thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message: res.data.message || `Xóa phiếu điều chỉnh thất bại, vui lòng thử lại!`,
        })
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const _updatePriceAdjustments = async (body, id) => {
    try {
      setLoading(true)
      const res = await updatePriceAdjustments(body, id)
      if (res.status === 200) {
        if (res.data.success) {
          _getPriceAdjustments()
          notification.success({
            message: `${
              body.status === 'COMPLETE' ? 'Hoàn thành' : 'Cập nhật'
            } phiếu điều chỉnh thành công`,
          })
        } else
          notification.error({
            message:
              res.data.message ||
              `${
                body.status === 'COMPLETE' ? 'Hoàn thành' : 'Cập nhật'
              } phiếu điều chỉnh thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${
              body.status === 'COMPLETE' ? 'Hoàn thành' : 'Cập nhật'
            } phiếu điều chỉnh thất bại, vui lòng thử lại!`,
        })
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.trim()
      if (value) paramsFilter.code = value
      else delete paramsFilter.code
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check:1 })
    setValueSearch('')
    setValueTime()
  }
  useEffect(() => {
    if(paramsFilter.check) _getPriceAdjustments()
  }, [paramsFilter])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueSearch('')
    if(dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)){
      setParamsFilter({ page: 1, page_size: PAGE_SIZE, check:1})
      setValueTime()
    }else {
      setParamsFilter({ page: 1, page_size: PAGE_SIZE, from_date: result.from_date, to_date: result.to_date, check:1})
      setValueTime(result.valueDate)
    }
  },[dataUser])
  return (
    <div className="card">
        <TitlePage title="Phiếu điều chỉnh" isAffix>
          <Space>
            <Button
              style={{
                display: Object.keys(paramsFilter).length <= 2 && 'none',
              }}
              onClick={onClickClear}
              type="danger"
            >
              Xoá tất cả lọc
            </Button>
            {permissions.includes(PERMISSIONS.tao_phieu_dieu_chinh) ? (
              <>
                <Link to={ROUTES.PRICE_ADJUSTMENTS_CREATE}>
                  <Button type="primary" size="large">
                    Tạo phiếu điều chỉnh
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Link to={ROUTES.PRICE_ADJUSTMENTS_CREATE}>
                  <Button type="primary" size="large" disabled>
                    Tạo phiếu điều chỉnh
                  </Button>
                </Link>
              </>
            )}
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_dieu_chinh_gia) ? (<>
        <Row gutter={[10, 16]} style={{ marginTop: 8 }}>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          <Input
            style={{ width: '100%' }}
            allowClear
            onChange={_onSearch}
            prefix={<SearchOutlined />}
            placeholder="Tìm kiếm theo mã phiếu điều chỉnh"
            value={valueSearch}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <FilterDate
            size="default"
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            title="tạo phiếu điều chỉnh giá"
            valueTime={valueTime}
            setValueTime={setValueTime}
            screen={1}
          />
        </Col>
      </Row>

      <Table
        size="small"
        dataSource={priceAdjustments}
        columns={columnsPrice.map((column) => {
          if (column.key === 'stt')
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          if (column.key === 'code')
            return {
              ...column,
              render: (text, record) =>
                permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_dieu_chinh_gia) ? (
                  <Link to={{ pathname: ROUTES.PRICE_ADJUSTMENTS_UPDATE + '/' + record.code }}>
                    {'#' + record.code}
                  </Link>
                ) : (
                  <div
            style={{ color: '#5F73E2', cursor: 'pointer' }}
            onClick={() => notification.warning({ message: 'Permission denied!' })}
          >
            {record.code}
          </div>
                ),
            }
          if (column.key === 'create_date')
            return {
              ...column,
              render: (text, record) => moment(record.create_date).format('DD/MM/YYYY, hh:mm'),
            }
          if (column.key === 'creator')
            return {
              ...column,
              render: (text, record) =>
                record.creator && record.creator.first_name + ' ' + record.creator.last_name,
            }
          if (column.key === 'last_update')
            return {
              ...column,
              render: (text, record) => moment(record.last_update).format('DD/MM/YYYY, hh:mm'),
            }
          if (column.key === 'updater')
            return {
              ...column,
              render: (text, record) =>
                record.updater && record.updater.first_name + ' ' + record.updater.last_name,
            }
          if (column.key === 'status') {
            return {
              ...column,
              render: (text, record) => {
                if (record.status === 'COMPLETE') {
                  return <Tag color="success">Đã điều chỉnh</Tag>
                } else if (record.status === 'DELETE') {
                  return <Tag color="error">Đã huỷ (không thể điều chỉnh)</Tag>
                } else {
                  return permissions.includes(PERMISSIONS.dieu_chinh_phieu) ? (
                    <Popconfirm
                      onConfirm={() =>
                        _updatePriceAdjustments({ status: 'COMPLETE' }, record.fix_price_id)
                      }
                      title="Bạn có muốn điều chỉnh phiếu này không?"
                      okText="Điều chỉnh"
                      cancelText="Huỷ"
                    >
                      <Tag style={{ cursor: 'pointer' }} color="processing">
                        Chưa điều chỉnh (nhấn để điều chỉnh)
                      </Tag>
                    </Popconfirm>
                  ) : (
                    <Tag color="processing">Chưa điều chỉnh</Tag>
                  )
                }
              },
            }
          }
          return column
        })}
        loading={loading}
        style={{ width: '100%', marginTop: 10 }}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countPriceAdjustments,
        }}
      />
      </>) : null}
      
    </div>
  )
}
