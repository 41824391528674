import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { ACTION, PAGE_SIZE, PERMISSIONS, ROUTES } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { removeUnicode } from 'utils'
//components
import columnsStock from './columns'
import exportToCSV from 'components/ExportCSV/export'
import TitlePage from 'components/title-page'
import { _getProductChange } from '../../components/Product/getProductChange'
//antd
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  Table,
  Select,
  Affix,
  notification,
  Popconfirm,
} from 'antd'
//icons
import { SearchOutlined, VerticalAlignTopOutlined, DeleteOutlined } from '@ant-design/icons'

//apis
import {
  getCheckInventoryNote,
  deleteCheckInventoryNote,
  updateCheckInventoryNoteBalance,
} from 'apis/inventory-note'
import { getStaff } from 'apis/manage-staff'
import FilterDate from 'components/filter-date/filter'
import removeVietnameseTones from 'utils/removeUnicode'
import { DefaultFilterData } from 'utils/DefaultFilterData'

const { Option } = Select
export default function StockAdjustments() {
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [valueTime, setValueTime] = useState()
  const [valueSearch, setValueSearch] = useState('')
  const [inventoryNote, setInventoryNote] = useState([])
  const [inventoryNoteShow, setInventoryNoteShow] = useState([])

  const [countInventoryNote, setCountInventoryNote] = useState(0)
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)

  const _getCheckInventoryNote = async () => {
    try {
      setLoading(true)
      const res = await getCheckInventoryNote({ branch_id: branchIdApp })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setInventoryNote(result.data)
        setValueSearch('')
        if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
          })
          setValueTime()
        } else {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
            from_date: result.defaultTime.from_date,
            to_date: result.defaultTime.to_date,
          })
          setValueTime(result.valueDate)
        }
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const _getUserList = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })

      if (res.status === 200) {
        if (res.data.success)
          setUserList(res.data.data.filter((item) => item.status === 1 || item.role_id === 1))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _onSearch = (e) => {
    setValueSearch(e)
    e = removeVietnameseTones(e)
    if (e.includes(' ')) {
      e = new RegExp(e.replace(/ /g, '.*'))
    } else {
      e = new RegExp(e)
    }
    paramsFilter['search'] = e
  }
  const _onClearFilters = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setValueTime()
  }
  const _onFilter = (attribute = '', value = '') => {
    const paramsFilterNew = { ...paramsFilter }
    if (value) paramsFilterNew[attribute] = value
    else delete paramsFilterNew[attribute]
    setParamsFilter({ ...paramsFilterNew })
  }
  const _balance = async (value) => {
    try {
      let body
      body = {
        title: inventoryNote.title,
        status: 2,
      }
      let res
      res = await updateCheckInventoryNoteBalance(body, value.inventoryNoteId)
      if (res.status === 200) {
        if (res.data.success) {
          _getCheckInventoryNote()
          notification.success({
            message: `Cân bằng thành công`,
          })
        } else
          notification.error({
            message: res.data.message || `Cân bằng thất bại!`,
          })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _getStockAdjustmentToExport = async () => {
    let dataExport = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      dataExport = inventoryNoteShow.map((item, index) => ({
        STT: index + 1,
        'Mã phiếu': item.code || '',
        'Kho kiểm hàng ': item?.branch?.name || '',
        'Trạng thái': item.status === 2 ? 'Cân bằng' : 'Chưa cân bằng',
        'Ngày tạo': item.create_date || '',
        'Ngày kiểm': item.last_update || '',
        'Nhân viên tạo': item?.creator_info?.first_name + ' ' + item?.creator_info?.last_name || '',
        'Ghi chú': item.note || '',
      }))
      exportToCSV(dataExport, 'Phiếu kiểm hàng','Xuất file excel',dispatch)
    } catch (e) {
      console.log(e)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _deleteInventoryNotes = async (id) => {
    try {
      const res = await deleteCheckInventoryNote({ inventoryNoteId: id, branch_id: branchIdApp })
      if (res.status === 200) {
        notification.success({ message: 'Xóa phiếu kiểm thành công' })
        _getCheckInventoryNote()
      } else
        notification.error({
          message: res.data.message || 'Xóa phiếu kiểm thất bại, vui lòng thử lại',
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    let arr = [...inventoryNote]
    const filtered = arr.filter((current) => {
      let matchStatus = true
      let matchTime = true
      let matchSearch = true
      let matchUser = true

      if (paramsFilter.creator_id && paramsFilter.creator_id !== 'all')
        matchUser = current?.creator_id === paramsFilter.creator_id
      if (paramsFilter.status && paramsFilter.status !== 'all')
        matchStatus = current?.status === paramsFilter.status
      if (paramsFilter.search) {
        let newObject = {
          name: current.title,
          code: current.code,
          real_quantity: current.real_quantity,
          system_quantity: current.system_quantity,
          create_date: current.create_date,
          creator: current?.creator_info?.first_name + ' ' + current?.creator_info?.last_name,
          note: current.note,
          status: current.status === 1 ? 'Lưu nháp' : 'Đã cân bằng',
        }
        newObject = removeVietnameseTones(Object.values(newObject).join(' ').toLocaleLowerCase())
        matchSearch = paramsFilter.search.test(newObject)
      }

      if (paramsFilter.to_date)
        matchTime =
          moment(current?.create_date).unix() >= paramsFilter.from_date &&
          moment(current?.create_date).unix() <= paramsFilter.to_date
      return matchStatus && matchSearch && matchTime && matchUser
    })
    setInventoryNoteShow(filtered)
    setCountInventoryNote(filtered.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, valueSearch])

  useEffect(() => {
    _getCheckInventoryNote()
    _getUserList()
  }, [branchIdApp, dataUser])

  return (
    <div className="card">
        <TitlePage title="Phiếu kiểm hàng" isAffix>
          <Space>
            <Button
              size="large"
              onClick={_onClearFilters}
              type="primary"
              danger
              style={{ display: Object.keys(paramsFilter).length <= 2 && 'none' }}
            >
              Xóa bộ lọc
            </Button>

            <Button
              id="exportFile"
              size="large"
              onClick={_getStockAdjustmentToExport}
              icon={<VerticalAlignTopOutlined />}
              style={{ backgroundColor: 'green', borderColor: 'green', color: 'white' }}
              disabled={
                permissions.includes(PERMISSIONS.export_phieu_kiem_hang_co_ban) ? false : true
              }
            >
              Xuất excel
            </Button>

            <Link to={ROUTES.STOCK_ADJUSTMENTS_CREATE}>
              <Button
                type="primary"
                size="large"
                disabled={
                  permissions.includes(PERMISSIONS.them_phieu_kiem_hang_co_ban) ? false : true
                }
              >
                Tạo phiếu kiểm
              </Button>
            </Link>
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_kiem_hang_co_ban) ? (
        <>
          <Row
            gutter={[0, 16]}
            style={{
              marginLeft: 0,
              marginRight: 0,
              marginTop: 10,
              border: '1px solid #d9d9d9',
              borderRadius: 5,
              backgroundColor:'white'
            }}
          >
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Input
                prefix={<SearchOutlined />}
                value={valueSearch}
                allowClear
                onChange={(e) => _onSearch(e.target.value)}
                placeholder="Tìm kiếm theo mã phiếu kiểm hàng"
                bordered={false}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{ borderRight: '1px solid #d9d9d9', borderLeft: '1px solid #d9d9d9' }}
            >
              <FilterDate
                style={{ marginTop: 3 }}
                bordered={false}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                title="tạo phiếu kiểm hàng"
                valueTime={valueTime}
                setValueTime={setValueTime}
                screen={1}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{ borderRight: '1px solid #d9d9d9', borderLeft: '1px solid #d9d9d9' }}
            >
              <Select
                placeholder="Lọc theo trạng thái"
                allowClear
                showSearch
                defaultValue={'all'}
                bordered={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                }
                value={paramsFilter.status || 'all'}
                onChange={(value) => _onFilter('status', value)}
                style={{ width: '100%' }}
              >
                <Option value="all">Tất cả trạng thái</Option>
                <Option value={1}>Lưu nháp</Option>
                <Option value={2}>Đã cân bằng</Option>
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Select
                onChange={(value) => _onFilter('creator_id', value)}
                value={paramsFilter.creator_id || 'all'}
                style={{ width: '100%' }}
                placeholder="Lọc theo nhân viên tạo"
                allowClear
                showSearch
                bordered={false}
                filterOption={(input, option) =>
                  removeUnicode(option.key.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
              >
                <Option value="all" key="Tất cả nhân viên">
                  Tất cả nhân viên
                </Option>
                {userList.map((item, index) => {
                  return (
                    <Option value={item.user_id} key={item.first_name + item.last_name}>
                      {item.first_name} {item.last_name}
                    </Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
          <Table
            size="small"
            scroll={{ y: 650 }}
            dataSource={inventoryNoteShow}
            columns={columnsStock.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,

                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_co_ban) ? (
                      <Link
                        to={{
                          pathname: ROUTES.STOCK_ADJUSTMENTS_UPDATE + '/' + record.code,
                          state: record,
                        }}
                      >
                        {'#' + record.code}
                      </Link>
                    ) : (
                      <div
                        style={{ color: '#5F73E2', cursor: 'pointer' }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        #{record.code}
                      </div>
                    ),
                }
              if (column.key === 'title')
                return {
                  ...column,
                  render: (text, record) => record.title,
                }
              if (column.key === 'system_quantity')
                return {
                  ...column,
                  render: (text, record) => record.system_quantity,
                }
              if (column.key === 'real_quantity')
                return {
                  ...column,
                  render: (text, record) => record.real_quantity,
                }
              if (column.key === 'status')
                return {
                  ...column,

                  render: (text, record) =>
                    record.status === 2 ? (
                      <div
                        style={{
                          textAlign: 'center',
                          background: '#39cb7f',
                        }}
                      >
                        Đã cân bằng
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: 'center',
                          background: '#2baaff',
                        }}
                      >
                        Lưu nháp
                      </div>
                    ),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) => moment(record.create_date).format('DD/MM/YYYY, HH:mm'),
                }

              if (column.key === 'note')
                return {
                  ...column,
                  render: (text, record) => record.note,
                }

              if (column.key === 'creator_info')
                return {
                  ...column,
                  render: (text, record) =>
                    record.creator_info
                      ? record.creator_info.first_name + ' ' + record.creator_info.last_name
                      : '',
                }
              if (column.key === 'balance')
                return {
                  ...column,
                  render: (text, record) =>
                    record.status === 1 ? (
                      <Button
                        style={{ width: 'auto' }}
                        disabled={
                          permissions.includes(PERMISSIONS.can_bang_phieu_kiem_hang_co_ban)
                            ? false
                            : true
                        }
                        type="primary"
                        onClick={() => _balance(record)}
                      >
                        Cân bằng
                      </Button>
                    ) : null,
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) =>
                    record.status === 1 && record.creator_id === dataUser.user_id ? (
                      <>
                        {permissions.includes(PERMISSIONS.xoa_phieu_kiem_hang_co_ban) ? (
                          <Popconfirm
                            title="Bạn có muốn xóa phiếu kiểm hàng cơ bản này không?"
                            okText="Đồng ý"
                            cancelText="Từ chối"
                            onConfirm={() => _deleteInventoryNotes(record.inventoryNoteId)}
                          >
                            <Button icon={<DeleteOutlined />} type="primary" danger />
                          </Popconfirm>
                        ) : (
                          <Button disabled icon={<DeleteOutlined />} type="primary" danger />
                        )}
                      </>
                    ) : null,
                }

              return column
            })}
            loading={loading}
            style={{ width: '100%', marginTop: 10 }}
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
              },
              total: countInventoryNote,
            }}
          />
        </>
      ) : null}
    </div>
  )
}
