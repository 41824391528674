import { ACTION_TYPE } from '../actions'

const SocketReducer = (state = [], action) => {
    switch(action.type){
        case ACTION_TYPE.SOCKET:
            return action.payload
        default:
            return state
    }
}

export default SocketReducer