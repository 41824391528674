const columns = [
  { title: 'STT', key: 'stt' ,align: 'center', width:'5%'},
  {
    title: 'Mã lịch làm việc',
    dataIndex: 'code',
    key: 'code',
    width:'15%'
  },
  {
    title: 'Tiêu đề',
    key: 'title',

  },
  {
    title: 'Ngày bắt đầu',
    key: 'start_date',
    align: 'center',
    width:'10%'
  },
  {
    title: 'Ngày kết thúc',
    key: 'end_date',
    align: 'center',
    width:'10%'
  },

  {
    title: 'Người tạo',
    key: 'creator',
    width:'15%'
  },
  {
    title: 'Hành động',
    key: 'action',
    align: 'center',
    width:'10%'
  },
]

export default columns
