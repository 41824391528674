const columns = [
  {
    title: 'STT',
    dataIndex: 'index',
    key:'stt',
    width: '6%',
    align: 'center',
  },
  {
    title: 'Mã chứng từ',
    dataIndex: 'code',
    key: 'code',
    width: '15%'
  },
  {
    title: 'Thao tác',
    key: 'type',
    width:'10%'
  },
  {
    title: 'Phương thức thanh toán',
    key: 'payment',
    width:'15%'
  },
  {
    title: 'Giá trị',
    key: 'value',
    width:'10%',
    align: 'center'
  },
  {
    title: 'Ngày tạo',
    key: 'date',
  },
  {
    title: 'Người tạo',
    key: 'creator',
  },
  {
    title: 'Ghi chú',
    key: 'note',
  },
  
]

export default columns
