import React, { useState } from 'react'
import styles from './style.module.scss'

import { Modal, Table, notification } from 'antd'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { PERMISSIONS, ROUTES } from 'consts'
import { useSelector } from 'react-redux'
import { compare } from 'utils'

export default function ListDeliveryModal(props) {
  const { quantity, data } = props
  const [visible, setVisible] = useState(false)
  const toggle = () =>{
    if(quantity > 0) setVisible(!visible)
}

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: 60,
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Nơi chuyển',
      key: 'export_location',
    },
    {
      title: 'Nơi nhận',
      key: 'import_location',
    },
    {
      title: 'Số lượng',
      key: 'quantity',
      align: 'center',
    },
  ]
  return (
    <>
      <div className={styles['delivery']} onClick={toggle}>
        <div className={styles['delivery__title']}>Đang chuyển</div>
        <div className={styles['delivery__quantity']}>{quantity}</div>
      </div>

      <Modal width="40%" footer={null} title="Phiếu đang chyển" visible={visible} onCancel={toggle}>
        <Table
          size="small"
          width="100%"
          scroll={{ y: 400 }}
          columns={columns.map((column) => {
            if (column.key === 'stt')
              return {
                ...column,
                render: (data, record, index) => index + 1,
              }
            if (column.key === 'code')
              return {
                ...column,
                width: 140,
                render: (text, record) =>
                  permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang) ? (
                    <Link
                      style={{
                        color: 'blue',
                      }}
                      to={{
                        pathname: ROUTES.SHIPPING_PRODUCT_UPDATE + '/' + record.code,
                      }}
                    >
                      #{record.code}
                    </Link>
                  ) : (
                    <span
                      style={{ cursor: 'pointer', color: '#5F73E2' }}
                      onClick={() => notification.warning({ message: 'Permission denied!' })}
                    >
                      #{record.code}
                    </span>
                  ),
                  sorter: (a, b) => compare(a, b, 'code'),
                }
            if (column.key === 'export_location')
              return {
                ...column,
                render: (text, record) =>
                  record.export_location_info && record.export_location_info.name,
                sorter: (a, b) => compare(a.import_location_info, b.import_location_info, 'name'),
              }
            if (column.key === 'import_location')
              return {
                ...column,
                render: (text, record) =>
                  record.import_location_info && record.import_location_info.name,
                sorter: (a, b) => compare(a.import_location_info, b.import_location_info, 'name'),
              }
            if (column.key === 'quantity')
              return {
                ...column,
                render: (data) => data.total_quantity,
                sorter: (a, b) => compare(a, b, 'total_quantity', Number),
              }
            if (column.key === 'note')
              return {
                ...column,
                render: (text) => text,
              }

            return column
          })}
          dataSource={data}
        />
      </Modal>
    </>
  )
}
