import React, { useEffect, useState } from 'react'
import styles from './import-stock.module.css'

//antd
import { Col, Row, Steps, Modal, Button } from 'antd'
import { useStepsForm } from 'sunflower-antd'
import { DownloadOutlined } from '@ant-design/icons'

// components
import Step1 from './steps/step-1'
import Step2 from './steps/step-2'
import Step3 from './steps/step-3'

export default function ImportStock({ importProduct,id }) {
  const { Step } = Steps
  const [filePath, setFilePath] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const { current, stepsProps, submit, gotoStep } = useStepsForm({
    isBackValidate: false,
    async submit(values) {
      switch (values.step) {
        case 0:
          gotoStep(0)
          setCurrentStep(0)
          return
        case 1:
          setFilePath(values.path)
          gotoStep(1)
          setCurrentStep(1)
          return
        case 2:
          gotoStep(2)
          setCurrentStep(2)
          return
        default:
          return values
      }
    },
  })
  const _exit2 = (value) => {
    importProduct(value)
  }
  const _exit3 = () => {
    gotoStep(0)
    toggle()
  }

  const formList = [
    <Step1 gotoStep={gotoStep} current={current} submit={submit} />,
    <Step2
      filePath={filePath}
      gotoStep={gotoStep}
      current={current}
      submit={submit}
      exit={_exit2}
      id={id}
    />,
    <Step3 toggle={_exit3} />,
  ]

  return (
    <div>
      <Button
        icon={<DownloadOutlined />}
        type="primary"
        style={{ marginLeft: '10px' }}
        onClick={toggle}
      >
        Nhập Excel
      </Button>
      <Modal width={1500} height={400} title="Nhập sản phẩm" footer={false} visible={visible} onCancel={toggle}>
        <div>
          <Row>
            <div className={styles['container']}>
              <Col className={styles['import-steps']}>
                <Row>
                  <Col span="5" style={{ marginBottom: 20 }}>
                    <h2>Nhập sản phẩm kiểm</h2>
                  </Col>
                  <Col span="19">
                    <Steps {...stepsProps}>
                      <Step disabled={true} title="Chọn file import" />
                      <Step disabled={true} title="Xác nhận dữ liệu" />
                      <Step disabled={true} title="Trạng thái" />
                    </Steps>
                  </Col>
                </Row>
                <Row>
                  <div style={{ width: '100%' }}>{formList[current]}</div>
                </Row>
              </Col>
            </div>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
