
import { Button, Result} from 'antd';
import React from 'react';

export default function Step3({toggle}) {
   
    return (
        <Result
        status="success"
        title="Import sản phẩm kiểm thành công"
        extra={[
          <Button onClick={toggle}>Hoàn thành</Button>,
        ]}
      />)
}