import { Modal } from 'antd'
import styled from 'styled-components'

const ModalStyled = styled(Modal)`
  .ant-modal {
    background: red !important;
  }
  .ant-modal-title {
    font-weight: 700 !important;
    font-size: 24px !important;
    color: #000 !important;
  }

  .ant-modal-content {
    padding: 30px !important;
    box-shadow: 0px 1px 28px rgba(0, 0, 0, 0.12);
    border-radius: 14px;
  }
  .ant-modal-header {
    border: 0 !important;
  }
`
export default ModalStyled
