
import {getVariantsSell} from 'apis/product'
import { ACTION } from 'consts';

export const getVariantsSystem = ({branchIdApp}) => async (dispatch) => {
    try {
          if (branchIdApp) {
          dispatch({type: ACTION.LOADING, data: true})
            const res = await getVariantsSell({branch_id:branchIdApp, sort: true})
            if(res.status === 200){
              dispatch({
                type: 'SET_VARIANTSELL',
                data: {
                  variants: res.data.data,
                  counts: res.data.count,
                },
              })
            }
          }
          dispatch({type: ACTION.LOADING, data: false})
        } catch (error) {
          dispatch({type: ACTION.LOADING, data: false})
          console.log(error)
    }
  };
