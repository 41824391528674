import { get, post, destroy, patch } from './httpClient'

export const apiOrderPromotion = (body) => post('/order/create', body)
export const apiOrderVoucher = (body) => post('/order/create', body)
export const addOrder = (body) => post('/order/create', body)
export const getLinkInvoice = (body) => post('/order/get-link-invoice', body)
export const updateOrder = (body, id) => patch(`/order/update/${id}`, body)
export const updateOrderStatus = (body, id) => patch(`/order/update-status/${id}`, body)
export const getOrders = (query) => get('/order/getWeb', query)
export const deleteOrders = (ids) => destroy('/order/delete', { order_id: ids })
export const getStatusOrder = () => get('/enum/order')

export const getOrdersCreateRefund = (query) => get('/order//get-refund-create', query)

export const getOrderRefund = (query) => get('/order-refund', query)

export const addOrderRefund = (body) => post('/order-refund/create', body)
export const updateOrderRefund = (body, id) => patch(`/order-refund/update/${id}`, body)
export const getOrderPayment = (query) => get('/order/getOrderPayment', query)
export const importOrder = (formData) => post('/order/file/create', formData)
export const verifyDebt = (body, order_id) => patch(`/order/verify/${order_id}`, body)
export const updateStatusCheckOtp = (body, id) => patch(`/order/update-status-checkOtp/${id}`, body)
