const columns = [
    { title: 'STT', key: 'stt' },
    { title: 'Mã loại phiếu ', key: 'code' },
    { title: 'Tên loại phiếu ', key: 'name' },
    { title: 'Mô Tả', key: 'description' },
    { title: 'Ngày tạo', key: 'create_date' },
    { title: 'Hành động', key: 'action' },
  ]
  
  export default columns
  