import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { formatCash } from 'utils'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'
import delay from 'delay'
import { useSelector } from 'react-redux'
//components
import TitlePage from 'components/title-page'

//antd
import { Row, Tabs } from 'antd'
//icons
import { ArrowLeftOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import InventoryVariant from './inventory-variant'
import InventoryProduct from './inventory-product'
const { TabPane } = Tabs

export default function ReportInventory() {
  const history = useHistory()
  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.REPORTS)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Tồn kho
          </Row>
        }
      ></TitlePage>

      <Tabs defaultActiveKey="1" style={{backgroundColor:'white', padding:5}}>
        <TabPane tab={<span>Theo Sản Phẩm</span>} key="1">
          <InventoryProduct />
        </TabPane>

        <TabPane tab={<span>Theo Phiên Bản</span>} key="2">
          <InventoryVariant />
        </TabPane>
      </Tabs>
    </div>
  )
}
