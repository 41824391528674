import styled from 'styled-components'
import { Table } from 'antd'
export const TableProduct = styled(Table)`

// .ant-table-tbody > tr > td {
//     height: 88px;
// }
.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-cell{
    padding: 0 !important;
}
.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
    display: none !important;
}
.ant-table-container table > thead > tr:first-child th:first-child {
    display: none !important;

}
.ant-table-row-expand-icon-cell {
    display: none !important;
}

`
