import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION } from 'consts'
// antd
import { Button, Input, Form, Row, Col, notification, message } from 'antd'

//apis
import {
  addFinanceTypeReceipts,
  updateFinanceTypeReceipts,
  getFinanceTypeReceipts,
} from 'apis/finance-type'
import TextArea from 'antd/lib/input/TextArea'

export default function FinanceTypeFormReceipts({ record, close, reload, text = 'Tạo' }) {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [receiptsTypes, setReceiptsTypes] = useState([])
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const onFinish = async (values) => {
    if (!record && receiptsTypes.length >= 10)
      return message.warn('Chỉ được phép tạo tối đa 10 loại phiếu thu, vui lòng xóa để tạo thêm !')
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        ...values,
        branch_id: branchIdApp,
        name: values.name.trim(),
        code: values.code || '',
        description: values.description || '',
        discount: values.discount || '',
      }
      let res
      if (record) res = await updateFinanceTypeReceipts(record.type_id, body)
      else res = await addFinanceTypeReceipts(body)
      if (res.status === 200) {
        if (res.data.success) {
          reload()
          notification.success({
            message: `${record ? 'Cập nhật' : 'Thêm'} loại phiếu thu thành công`,
          })
          close()
          initForm()
        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? 'Cập nhật' : 'Thêm'} loại phiếu thu thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${record ? 'Cập nhật' : 'Thêm'} kloại phiếu thu thất bại, vui lòng thử lại!`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const initForm = () => {
    form.setFieldsValue({})
  }

  const _getsetReceiptsTypes = async () => {
    try {
      const res = await getFinanceTypeReceipts()
      if (res.status === 200) setReceiptsTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getsetReceiptsTypes()
    if (!record) initForm()
    else {
      form.setFieldsValue({
        ...record,
      })
    }
  }, [])

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Tên loại phiếu thu"
            name="name"
            rules={[{ required: true, message: 'Vui lòng nhập tên loại phiếu thu', whitespace:true }]}
          >
            <Input allowClear size="large" placeholder="Nhập tên loại phiếu thu" />
          </Form.Item>
          <Form.Item label="Mã loại phiếu thu" name="code">
            <Input style={{ width: '100%' }} size="large" placeholder="Nhập mã loại phiếu thu" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item label="Mô tả" name="description">
            <TextArea rows={6} placeholder="Mô tả" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
      <Button onClick={() => close()} size="large" style={{marginRight: 8, width:'6em'}}>Đóng</Button>
        <Form.Item>
          {text == 'Lưu' && (
            <Button style={{ width: 100 }} type="primary" htmlType="submit" size="large">
              {text}
            </Button>
          )}
          {text == 'Tạo' && (
            <Button style={{ width: 100 }} type="primary" htmlType="submit" size="large">
              {text}
            </Button>
          )}
        </Form.Item>
      </Row>
    </Form>
  )
}
