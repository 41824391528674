import { Button, Col, Collapse, Row, Switch, Tree, Popconfirm } from 'antd'
import styled from 'styled-components'
import { PERMISSIONS_APP } from './data'
import { ACTION, PERMISSIONS } from 'consts'
import Permission from 'components/permission'
import React, { useState, useEffect } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { deleteRole } from 'apis/role'


const RefreshDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 0;
`

const RefreshBtn = styled(Button)`
  width: 120px;
  margin: 2px;
  border: 1px solid orange;
`
function RefreshRolesBtn({ getRoles }) {
  const clickHandle = () => getRoles()

  return (
    <RefreshDiv>
      <RefreshBtn onClick={clickHandle} size="large">
        Tải lại
      </RefreshBtn>
    </RefreshDiv>
  )
}

const IconStyed = styled.i`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${({ bgColor }) => bgColor};
`

const EmptyRoles = ({ text }) => <h1 style={{ textAlign: 'center' }}>{text}</h1>

const isAdminOrBusinessRole = (permissionName, dataUserName) => {
  if (permissionName === 'ADMIN') return true

  if (permissionName === 'BUSINESS' && dataUserName !== 'ADMIN') return true

  return false
}



function Roles(props) {
  const {
    keyPanel: key,
    onSetKey,
    generateTreeData,
    disableRole,
    rolePermission,
    dataUser,
    onCheckPermisisons,
    deleteRole,
    updateRole,
    listCheck
  } = props
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const { name: dataUserRolesName } = dataUser._role
  const changeHandle = (values) => onSetKey(values)
  if (!rolePermission.length) return <EmptyRoles text="Danh sách vai trò rỗng" />

  return rolePermission
    ?.filter((item) => item.role_id !== 1)
    .map((values, index) => {
      const { name, active, role_id } = values

      if (isAdminOrBusinessRole(name, dataUserRolesName)) return null

      const ExtraElement = (
        <Row>
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ marginRight: '5px', marginTop: '3px' }}
          >
            <Button
              onClick={()=> updateRole(values.role_id)}
              style={{ marginRight: '5px'}}
              type="primary"
              // disabled={listCheck.length > 0 ? false : true}
            >
              Cập nhật
            </Button>
            {permissions.includes(PERMISSIONS.cap_nhat_vai_tro_phan_quyen) ? (
              <Switch
                style={{ marginRight: '8px' }}
                checked={active}
                onChange={(checked) => disableRole({ active: checked }, role_id)}
              />
            ) : (
           <></>
            )}

            {permissions.includes(PERMISSIONS.xoa_vai_tro_phan_quyen) ? (
              <Popconfirm
                onConfirm={() => deleteRole(values.role_id)}
                title="Bạn muốn xoá vai trò này?"
                okText="Đồng ý"
                cancelText="Không"
                disabled={
                  values.role_id === 2 ||
                  values.role_id === 3 ||
                  values.role_id === 4 ||
                  values.type_role === 1
                }
              >
                <Button
                  disabled={
                    values.role_id === 2 ||
                    values.role_id === 3 ||
                    values.role_id === 4 ||
                    values.type_role === 1
                  }
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            ) : (
             <></>
            )}
          </div>
        </Row>
      )
      return (
        <div key={index}>
          <Collapse accordion activeKey={key} onChange={changeHandle} expandIconPosition="start">
            <Collapse.Panel header={name} key={role_id} extra={ExtraElement}>
          
              <Tree
                checkable
                onCheck={(checkedKeys, info) =>
                  onCheckPermisisons(checkedKeys, info, values.role_id)
                }
                
                checkedKeys={listCheck.length === 0 ? values.permission_list : listCheck}
                showIcon={false}
                defaultExpandAll={true}
                treeData={[...generateTreeData(PERMISSIONS_APP, values)]}
              />
            </Collapse.Panel>
          </Collapse>
        </div>
      )
    })
}

function Main({ getRoles, ...rest }) {
  return (
    <div>
      <RefreshRolesBtn getRoles={getRoles} />
      <Roles {...rest} />
    </div>
  )
}

export default Main
