import styled from 'styled-components'
import { Table } from 'antd'
export const TableProduct = styled(Table)`

.disabled-row {
    background: #cccc !important;
    pointer-events: none;
  }

`
