import { Button, Drawer, Input, Row, Space, Tree } from 'antd'
import styled from 'styled-components'

const AddRoleTitle = styled.div`
  color: #000;
  margin-bottom: 0.5rem;
  fontsize: 1rem;
  fontweight: 600;
`

const IconStyled = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ bg }) => bg};
  margin-right: 7px;
`

function TreeTitle({ list }) {
  return (
    <Space>
      {list.map(({ iconColor, text }) => (
        <Row align="middle">
          <IconStyled bg={iconColor}></IconStyled> {text}
        </Row>
      ))}
    </Space>
  )
}

function AddRoleDrawer(props) {
  const { onClose, visible, onClickAddRole, onChangeName, name, onCheck, treeData } = props
  const closeHandle = () => onClose()

  const clickHandle = () => onClickAddRole()

  const changeHandle = (e) => onChangeName(e)

  const checkHandle = (selectedKeysValue, info) => onCheck(selectedKeysValue, info)

  const SaveBtn = (
    <Row justify="end">
      <Button onClick={clickHandle} style={{ width: 100 }} type="primary" size="large">
        Lưu
      </Button>
    </Row>
  )



  return (
    <Drawer
      title="Thêm vai trò"
      width={950}
      onClose={closeHandle}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={SaveBtn}
    >
      <div>
        <div>
          <AddRoleTitle>Tên vai trò</AddRoleTitle>
          <div>
            <Input
              size="large"
              name="name"
              value={name}
              onChange={changeHandle}
              placeholder="Nhập tên vai trò mới"
            />
          </div>
        </div>
      
        <Tree
          style={{ marginTop: '10px' }}
          checkable
          defaultExpandAll
          onCheck={checkHandle}
          treeData={[...treeData]}
        />
      </div>
    </Drawer>
  )
}

export default AddRoleDrawer
