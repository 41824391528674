import { t } from 'i18next'

const columnsOrderNew = [
  {
    title: t('common.no'),
    dataIndex: 'stt',
    key: 'stt',
    width: '5%',
    responsive: ['xl'],
  },
  {
    title: t('common.code_orders'),
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: t('common.date_created'),
    dataIndex: 'create_date',
    key: 'create_date',
    responsive: ['xl'],
  },
  {
    title: t('common.customer_name'),
    key: 'customer',
    width: '8%',
  },
  {
    title: t('common.sales_channel'),
    dataIndex: 'channel',
    key: 'channel',
  },
  {
    title: 'Số lượng',
    dataIndex: 'total_quantity',
    key: 'total_quantity',
    with:'3%'
  },
  {
    title: t('common.total_price_product'),
    dataIndex: 'total_price_product',
    key: 'total_price_product',
  },
  {
    title: t('common.total_discount_order'),
    dataIndex: 'total_discount_order',
    key: 'total_discount_order',
  },
  {
    title: t('common.fee_shipping'),
    key: 'fee_shipping',
  },
  {
    title: t('common.guest_had_paid'),
    dataIndex: 'customer_paid',
    key: 'customer_paid',
  },
  {
    title: t('common.total_profit'),
    key: 'total_profit',
  },
  {
    title: t('common.payment_methods'),
    key: 'payments_method',
  },
  {
    title: t('common.staff'),
    key: 'employee',
    responsive: ['xl'],
  },
  {
    title: t('common.status'),
    dataIndex: 'bill_status',
    key: 'bill_status',
  },
  {
    title: t('common.note'),
    dataIndex: 'note',
    key: 'note',
  },
]

export default columnsOrderNew
