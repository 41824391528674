import { t } from 'i18next'

const columnsProduct = [
  {
    title: t('common.no'),
    key: 'stt',
  },
  {
    title: t('common.avatar'),
    key: 'company_logo',
  },
  {
    title: t('common.representative_phone_number'),
    dataIndex: 'company_phone',
  },
  {
    title: t('business.sub_domain'),
    dataIndex: 'prefix',
  },
  {
    title: t('common.business_name'),
    dataIndex: 'business_name',
  },
  {
    title: t('common.email'),
    dataIndex: 'company_email',
  },
  {
    title: t('common.phone'),
    dataIndex: 'company_phone',
  },
  {
    title: t('common.tax_code'),
    dataIndex: 'tax_code',
  },
  {
    title: t('common.address'),
    dataIndex: 'company_address',
  },
  {
    title: '',
    key: 'action',
  },
]

export default columnsProduct
