import {
  Layout,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  notification,
  Spin,
  Divider,
  Badge,
  AutoComplete,
} from 'antd'
import { uploadFile } from 'apis/upload'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UploadImage from '../items/upload-image'
import { socket } from '../../../socket'
import { _getProductChange } from 'components/Product/getProductChange'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { getProductsSystem } from 'redux/actions/products'

const { Content } = Layout

const LayoutForm = ({
  setProducts,
  products,
  disableField,
  editProduct,
  setEditProduct,
  setFileUpload,
  loading,
}) => {
  const [form] = Form.useForm()
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  // const { variants, variantFil  }= useSelector((state) => state.variant)
  const variants = useSelector((state) => state.products.products)

  const dispatch = useDispatch()
  const [imageView, setImageView] = useState('')
  const [nameProductCheck, setNameProductCheck] = useState(null)
  const [options, setOptions] = useState([])

  const onSearch = (searchText) => {
    let newData = [...variants]
    newData = newData.filter((item) => item.type_advanced === 1)
    if (searchText == '') {
      newData = newData?.map((item) => {
        return { value: item.sku }
      })
    } else {
      if (searchText !== null) {
        newData = newData
          ?.filter((item) => item.sku?.toLowerCase().includes(searchText.toLowerCase()))
          .map((item) => {
            return { value: item.sku }
          })
      }
    }

    setOptions([...new Set(newData)])
  }

  const addProduct = () => {
    let productCurrent = [...products]
    
    const dataForm = form.getFieldsValue()
    if (dataForm.sku.trim() == '' || dataForm.name.trim() == '') {
      return notification.error({
        key: 'Validate sku and name',
        message: 'Không được để trống ở mã hoặc tên sản phẩm',
      })
    }
    let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/;
    if (regex.test(dataForm.sku)) {
      return notification.error({
        key: 'Validate sku',
        message: 'Không được nhập kí tự đặc biệt ở mã sản phẩm',
      })
    }
    if (regex.test(dataForm.name)) {
      return notification.error({
        key: 'Validate name',
        message: 'Không được nhập kí tự đặc biệt ở tên sản phẩm',
      })
    }
    let quantity_check = 0
    if (disableField == true) {
      quantity_check = 1
    }
    let itemProduct = {
      image: imageView || '',
      sku: dataForm.sku.trim(),
      name: dataForm.name.trim(),
      quantity: dataForm.quantity || quantity_check,
      price: dataForm.price || 0,
      import_price: dataForm.import_price || 0,
      unit: dataForm.unit || '',
      note: dataForm.note || '',
    }

    if (editProduct == null) {
      if (disableField == true) {
        if (productCurrent.length === 1000) {
          return notification.error({
            key: 'Validate product Length',
            message: 'Chỉ được bổ sung số lượng của tối đa 1000 sản phẩm',
          })
        }
        if (!variants.find((item) => item.sku == dataForm.sku.trim())) {
          return notification.error({
            key: 'Validate product 2',
            message: 'Sản phẩm không tồn tại trong hệ thống',
          })
        } else {
          let new_data = variants.find((item) => item.sku == dataForm.sku.trim())
          if(new_data?.type_advanced !== 1)
          return notification.error({
            key: 'Validate product 3',
            message: 'Sản phẩm không thuộc sản phẩm tồn kho thường',
          })
        }
      } else {
        if (productCurrent.length === 1000) {
          return notification.error({
            key: 'Validate product Length2',
            message: 'Chỉ được thêm mới tối đa 1000 sản phẩm',
          })
        }
        if (
          variants.find((item) => item.sku == dataForm.sku.trim()) ||
          variants.find((item) => item.title == dataForm.name.trim()) || variants.find((item) => item.product_sku == dataForm.sku.trim())
        ) {
          return notification.error({
            key: 'Validate product',
            message: 'Sản phẩm đã tồn tại trong hệ thống',
          })
        }
      }

      if (
        (disableField == false && products?.find((item) => item.name === dataForm.name)) ||
        products?.find((item) => item.sku === dataForm.sku)
      ) {
        return notification.error({
          key: 'Validate Name/sku',
          message: 'Sản phẩm đã được thêm vào danh sách sản phẩm',
        })
      }
    } else {
      if (disableField == false) {
        let productArr = [...productCurrent]
        if (
          variants.find((item) => item.sku == dataForm.sku) ||
          variants.find((item) => item.title == dataForm.name) || variants.find((item) => item.product_sku == dataForm.sku)
        ) {
          return notification.error({
            key: 'Validate product 404',
            message: 'Sản phẩm tồn tại trong hệ thống',
          })
        }
        productArr = productArr.filter((item) => item.sku !== dataForm.sku)

        if (
          productArr?.find((item) => item.name === dataForm.name) ||
          productArr?.find((item) => item.sku === dataForm.sku)
        ) {
          return notification.error({
            key: 'Validate Name/sku',
            message: 'Sản phẩm đã tồn tại trong danh sách sản phẩm sẽ thêm',
          })
        }
      }

      let indexOld = productCurrent.findIndex((item) => item.sku == dataForm.sku)
      productCurrent.splice(indexOld, 1)
    }
    productCurrent.push(itemProduct)
    form.setFieldsValue({
      sku: '',
      name: '',
      price: 0,
      quantity: 1,
      import_price: 0,
      unit: '',
    })
    setImageView('')
    setProducts([...productCurrent])
    setEditProduct(null)
    setNameProductCheck(null)
  }
  const socketListener = () => {
    socket.on(`product:action:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
      try {
        notification.success({key: 'socket', message: 'Số lượng sản phẩm đã thay đổi'})
        if (res.productList.length > 0) {
          let newData = await _getProductChange(branchIdApp, res.productList)
          dispatch({
            type: 'SET_NEW_PRODUCTS',
            data: {
              products: newData.products,
              ids: res.productList,
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  useEffect(() => {
    socketListener()
  }, [socket])

  useEffect(() => {
    let newData = [...variants]
    newData = newData
      ?.filter((item) => item.type_advanced == 1)
      .map((item) => {
        return { value: item.sku }
      })
    newData = [...new Set(newData)]
    setOptions(newData)
  }, [variants])

  useEffect(() => {
    if (editProduct !== null) {
      form.setFieldsValue({
        ...editProduct,
        import_price: editProduct.import_price,
        unit: editProduct.unit,
      })
      setImageView(editProduct.image)
    }
  }, [editProduct])

  useEffect(() => {
    if (disableField === true) {
      form.setFieldsValue({ quantity: 1 })
    } else {
      form.setFieldsValue({ quantity: 0 })
    }
  }, [disableField])

  useEffect(() => {
    if (nameProductCheck) {
      let dataProduct = variants
        .filter((item) => item.type_advanced == 1)
        .find((item) => item.sku == nameProductCheck)
      if (dataProduct) {
        form.setFieldsValue({
          name: dataProduct.title,
          price: dataProduct.price,
          import_price: dataProduct.import_price,
          unit: dataProduct.unit || '',
        })
        setImageView(dataProduct.image[0] || '')
      } else {
        form.setFieldsValue({
          name: '',
          price: 0,
          import_price: 0,
          unit: '',
        })
      }
    } else {
      form.setFieldsValue({
        name: '',
        price: 0,
        import_price: 0,
        unit: '',
      })
      setImageView('')
    }
  }, [nameProductCheck])
  useEffect(() =>{
      dispatch(getProductsSystem({branchIdApp: branchIdApp}))
  
  },[branchIdApp])
  return (
    <Spin tip="Loading..." spinning={loading}>
      <Content
        style={{
          padding: 20,
          backgroundColor: '#bdd3ff',
          borderRadius: '10px',
          boxShadow: '3px 3px 3px #85a5ff ',
        }}
      >
        <Form form={form} onFinish={() => addProduct()}>
          <Row justify="center">
            <Col>
              <h1 style={{ fontSize: 20 }}>
                {editProduct == null ? 'Thêm sản phẩm' : 'Sửa sản phẩm'}
              </h1>
            </Col>
          </Row>
          <span style={{ fontWeight: '600' }}>Mã sản phẩm:</span>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng nhập mã sản phẩm/SKU!' }]}
            name="sku"
            style={{ marginBottom: '8px' }}
          >
            {disableField === true ? (
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                style={{
                  width: '100%',
                }}
                options={options}
                onSearch={onSearch}
                disabled={disableField && editProduct ? true : false}
                onSelect={(e) => {
                  if (disableField === true) {
                    setNameProductCheck(e)
                  }
                }}
              >
                <Input
                  disabled={disableField && editProduct ? true : false}
                  placeholder="Mã sản phẩm"
                  onChange={(e) => {
                    if (disableField === true) {
                      setNameProductCheck(e.target.value)
                    }
                  }}
                />
              </AutoComplete>
            ) : (
              <Input disabled={disableField ? true : false} placeholder="Mã sản phẩm" />
            )}
          </Form.Item>
          <span style={{ fontWeight: '600' }}>Tên sản phẩm:</span>

          <Form.Item
            rules={
              disableField == false && [{ message: 'Vui lòng nhập tên sản phẩm', required: true }]
            }
            style={{ marginBottom: '8px' }}
            name="name"
          >
            <Input
              placeholder={disableField ? '' : 'Tên sản phẩm'}
              disabled={disableField ? true : false}
            />
          </Form.Item>

          <Row gutter={[24, 0]}>
            <Col span={12}>
              <span style={{ fontWeight: '600' }}>Giá sản phẩm:</span>
              <Form.Item style={{ marginBottom: '8px' }} name="price">
                <InputNumber
                  placeholder={disableField ? '' : 'Giá sản phẩm'}
                  disabled={disableField ? true : false}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span style={{ fontWeight: '600' }}>Số lượng:</span>

              <Form.Item style={{ marginBottom: '8px' }} name="quantity">
                <InputNumber
                  defaultValue={disableField === true ? 1 : 0}
                  min={disableField === true ? 1 : 0}
                  placeholder="Số lượng"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col span={12}>
              <span style={{ fontWeight: '600' }}>Giá nhập:</span>

              <Form.Item style={{ marginBottom: '8px' }} name="import_price">
                <InputNumber min={0} placeholder="Giá nhập sản phẩm" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span style={{ fontWeight: '600' }}>Đơn vị tính:</span>
              <Form.Item name="unit" style={{ marginBottom: '0px' }}>
                <Input
                  placeholder={disableField ? '' : 'Đơn vị'}
                  disabled={disableField ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <span style={{ fontWeight: '600' }}>Mô tả:</span>
              <Form.Item name="note">
                <Input.TextArea rows={5} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span style={{ fontWeight: '600' }}>Hình ảnh:</span>
              <div>
                <Badge
                  count={
                    disableField === false
                      ? imageView && (
                          <CloseCircleTwoTone
                            onClick={() => setImageView('')}
                            twoToneColor="red"
                            style={{ fontSize: 24 }}
                          />
                        )
                      : null
                  }
                >
                  <UploadImage
                    setImageView={setImageView}
                    imageView={imageView}
                    setFileUpload={setFileUpload}
                    disableField={disableField}
                  />
                </Badge>
              </div>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType="submit" style={{ width: '100%' }}>
              {disableField
                ? 'Nhập hàng mới'
                : editProduct == null
                ? 'Thêm sản phẩm'
                : 'Sửa sản phẩm'}
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Spin>
  )
}
export default LayoutForm
