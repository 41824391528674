
const columnsOrder = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: 500,
  },
  {
    title: 'Mã chứng từ',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: 'Kênh bán hàng',
    dataIndex: 'channel',
    key: 'channel',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'order_status',
    key: 'order_status',
  },
  {
    title: 'Đã thanh toán',
    dataIndex: 'customer_paid',
    key: 'customer_paid',
  },
 
  {
    title: 'Chưa thanh toán',
    dataIndex: 'customer_debt',
    key: 'customer_debt',
  },
  {
    title: 'Giá trị tích điểm',
    key: 'point',
  },
]

export default columnsOrder
