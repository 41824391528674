import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Tooltip, message, notification, Upload, Popconfirm } from 'antd'
import localStorage from 'utils/localStorage'

import Cookies from 'js-cookie'
import { getRowsExcel } from 'apis/import-barcode'
import { useDispatch } from 'react-redux'
import { ACTION } from 'consts'
const ImportPrint = ({ importProduct, setProductsError, branch_id }) => {
  let accessToken = localStorage.getItem('accessToken')
  const dispatch = useDispatch()
  if (!accessToken) {
    accessToken = Cookies.get('accessToken')
  }
  const [excelRows, setExcelRows] = useState([])
  const [productAdd, setProductAdd] = useState([])
  const [filePath, setFilePath] = useState('')
  const inputFile = useRef()

  const onConfirm = () => {
    inputFile.current.click()
  }

  const props = {
    name: 'file',
    action:
      process.env.REACT_APP_API_ENDPOINT_DEV +
      '/import-barcode/upload-excel',
    headers: {
      'X-Ekata-Site': window.location.hostname,
      'X-Ekata-Token': accessToken,
    },
    async onChange(info) {
      var extension = info.file.name.split('.').pop().toLowerCase()
      const allowedExtensions = ['xlsx', 'xls', 'xlsm']
      if (allowedExtensions.includes(extension)) {
        const { status } = info.file
        if (status === 'done') {
          let body ={
            branch_id: branch_id,
            filePath: info.file.response.path
          }
          _getRowsExcel(body)
        } else if (status === 'error') {
          message.error(`${info.file.name} tải lên bị lỗi`)
        }
      } else {
        if (info.file.status !== 'removed') {
          notification.warning({ key: 'fail file', message: 'Yêu cầu nhập file đúng định dạng' })
        }
      }
    },
  }

  async function _getRowsExcel(file) {
    try {
    dispatch({type: ACTION.LOADING,data: true })

      let res = await getRowsExcel(file)
      if (res.status == 200) {
        setProductAdd(res.data.skuArr)
        setProductsError(res.data.skuArrError)
        setExcelRows(res.data.data)
      }
    dispatch({type: ACTION.LOADING,data: false })
  } catch (error) {
    dispatch({type: ACTION.LOADING,data: false })
    console.log(error)
    }
  }

  useEffect(() => {
    if (excelRows.length > 0) {
      importProduct(productAdd)
    }
  }, [excelRows])

  return (
    <div>
      <Upload {...props} showUploadList={false}>
        <Tooltip>
          <Button
            style={{
              width: 150,
              borderRadius: '10px',
              boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
              transition: 'all .3s ease-in',
              marginLeft: '1em',
              display: 'none',
            }}
            type="primary"
            ref={inputFile}
          >
            Nhập file Excel
          </Button>
        </Tooltip>
      </Upload>
      <Popconfirm
        title="Tối đa 100 sản phẩm trên một lần nhập file"
        onConfirm={onConfirm}
        okText="Đồng ý"
        cancelText="Huỷ bỏ"
      >
        <Button
          style={{
            width: 150,
            borderRadius: '10px',
            boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
            transition: 'all .3s ease-in',
            marginLeft: '1em',
          }}
          type="primary"
        >
          Nhập file Excel
        </Button>
      </Popconfirm>
    </div>
  )
}

export default ImportPrint
