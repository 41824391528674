const columns = [
    {
      title: 'STT',
      key: 'stt',
      align: 'center',
      width: '7%'
    },
    {
      title: 'Tên nhân viên',
      key: 'full_name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
    },
    {
      title: 'Giờ vào ca',
      dataIndex: 'start_time',
      align: 'center',
      key: 'start_time',
    },
    {
      title: 'Giờ ra ca',
      dataIndex: 'end_time',
      align: 'center',
      key: 'end_time',
    },
    {
      title: 'Thời gian làm',
      key: 'time_work',
      align:'center',
      width:'10%'
    },
    {
      title: 'Tên ca',
      dataIndex: 'shift_name',
      key: 'shift_name',
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'updator',
      key: 'updator',
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width:'7%'
    },
  ]
  
  export default columns
  