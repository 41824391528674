import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import localStorage from '../../../utils/localStorage'
import styled from 'styled-components'
import styles from './detail_business.module.scss'
import {ACTION} from 'consts'

import { Card, Tabs } from 'antd'
import jwt_decode from 'jwt-decode'
import { ShopOutlined, DiffOutlined } from '@ant-design/icons'
//api
import { detailBusiness, getProductList } from 'apis/business'
import Tab from 'components/newfeed/tab'
import NewFeedsLayout from 'components/Layout/NewFeeds/NewFeeds.layout'
import NewFeeds from 'components/newfeed/feeds'
import Products from './products'
import Profile from 'components/Profile/Profile'

import ProductImageOne from 'assets/img/Product-1.png'
import ProductImageTwo from 'assets/img/Product-2.png'
import ProductImageThree from 'assets/img/Product-3.png'
import ProductImageFour from 'assets/img/Product-4.png'
import ProductImageFive from 'assets/img/Product-5.png'
import ProductImageSix from 'assets/img/Product-6.png'
import ProductImageSeven from 'assets/img/Product-7.png'
import ProductImageEight from 'assets/img/Product-8.png'
import ProductImageNine from 'assets/img/Product-9.png'

// import AvatarProfileImage from 'assets/img/avatar-profile.png'

import DefaultLogo from 'assets/img/default_logo_ekata.png'
import logo_default from 'assets/img/bg-profile.png'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { socket } from '../../../socket'
const TabsStyled = styled(Tabs)`
  .ant-tabs-content {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-nav-list > .ant-tabs-tab {
    width: 50%;
    margin: 0;
    justify-content: center;
  }
`

const tabPaneData = [
  {
    key: 'NewFeeds',
    classes: styles.tabpane__newfeeds,
    content: NewFeeds,
    tab: () => <Tab classes={styles.tabpane} text="Bản tin" icon={DiffOutlined} />,
  },
  {
    key: 'Products',
    classes: styles.tabpane__products,
    content: Products,
    tab: () => <Tab classes={styles.tabpane} text="Sản phẩm" icon={ShopOutlined} />,
  },
]

const { TabPane } = Tabs
export default function Detail_business() {
  const dataUser = localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken')).data
    : {}
  const user_phone = dataUser.phone
  const feed = useSelector((state) => state.feed)
  const [productList, setProductList] = useState([])

  const dispatch = useDispatch()
  const profileData = {
    avatar:
      dataUser._business.business_logo !== '' ? dataUser._business.business_logo : DefaultLogo,
    bgAvatar:
      dataUser._business.business_cover_image !== ''
        ? dataUser._business.business_cover_image
        : logo_default,
    name: dataUser._business.business_name,
    status: dataUser._business.business_description || '',
    follower: '100M',
    following: 6,
    quantity: 250,
    feedbackRate: '98%',
    feedbackTime: 'Vài phút',
    rate: '2.5/5',
    finishedRate: '100%',
    setting: user_phone === dataUser._business.business_phone ? true : false,
  }

  useEffect(() => {
    let newProduct = [...feed]
    newProduct = newProduct
      .map((item) => ({
        ...item,
        image: (item?.images && Object(item.images.find(item => item.length > 0))) || '',
        priceOld: '450.000đ',
        amount: '3.1k',
        deal: 'Mua kèm Deal sốc',
        discount: '30%',
      }))
    setProductList(newProduct)
  }, [feed])

  return (
    <div className={styles['body']}>
      <Profile profile={profileData} classes={styles['container-tabs__topbusiness']} />

      <NewFeedsLayout classes={styles['new-feeds__layout']}>
        <TabsStyled defaultActiveKey={1} className={styles['container-tabs']}>
          {tabPaneData.map((item) => {
            const { key, classes, tab: Tab, content: Content } = item

            return (
              <TabPane key={key} tab={<Tab />} className={classes}>
                <Content list={productList} />
              </TabPane>
            )
          })}
        </TabsStyled>
      </NewFeedsLayout>
    </div>
  )
}
