import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION } from 'consts'
// antd
import { Select, Button, Input, Form, Row, Col, notification, InputNumber } from 'antd'

//apis
import { updateCustomerType, getCustomerType, addCustomerType } from 'apis/customer-type'
import TextArea from 'antd/lib/input/TextArea'

const { Option } = Select

export default function CustomerTypeForm({ record, close, reload, text = 'Tạo' }) {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const onFinish = async (values) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        ...values,
        branch_id: branchIdApp,
        name: values.name,
        code: values.code || '',
        description: values.description || '',
        discount: values.discount || '',
      }
      let res
      if (record) res = await updateCustomerType(record.type_id, body)
      else res = await addCustomerType(body)

      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          reload && reload()
          notification.success({ message: `${record ? 'Cập nhật' : 'Thêm'} khách hàng thành công` })
          close()
          initForm()
        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? 'Cập nhật' : 'Thêm'} khách hàng thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${record ? 'Cập nhật' : 'Thêm'} khách hàng thất bại, vui lòng thử lại!`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const initForm = () => {
    form.setFieldsValue({})
  }

  useEffect(() => {
    if (!record) initForm()
    else {
      form.setFieldsValue({
        ...record,
      })
    }
  }, [])

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Tên Nhóm"
            name="name"
            rules={[{ required: true, message: 'Vui lòng nhập tên nhóm' }]}
          >
            <Input allowClear size="large" placeholder="Nhập tên nhóm" />
          </Form.Item>
          <Form.Item label="Mã Nhóm" name="code">
            <Input style={{ width: '100%' }} size="large" placeholder="Nhập mã nhóm" />
          </Form.Item>

          <Form.Item label="Chiết Khấu (%)" name="discount">
            <InputNumber
              addonAfter="%"
              style={{ width: '100%' }}
              size="large"
              placeholder="Nhập chiết khấu"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Form.Item label="Mô Tả" name="description">
            <TextArea rows={6} placeholder="Mô Tả" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Form.Item>
          {text == 'Lưu' && (
            // <Permission permissions={[PERMISSIONS.them_khach_hang]}>
            <Button style={{ width: 100 }} type="primary" htmlType="submit" size="large">
              {text}
            </Button>
            // </Permission>
          )}
          {text == 'Tạo' && (
            // <Permission permissions={[PERMISSIONS.them_khach_hang]}>
            <Button style={{ width: 100 }} type="primary" htmlType="submit" size="large">
              {text}
            </Button>
            // </Permission>
          )}
        </Form.Item>
      </Row>
    </Form>
  )
}
