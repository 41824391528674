import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import styles from './import-inventory.module.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ROUTES, PERMISSIONS, IMAGE_DEFAULT, ACTION } from 'consts'
import { filterOptionProduct, formatCash } from 'utils'
import removeVietnameseTones from 'utils/removeUnicode'
import moment from 'moment'
import noData from 'assets/icons/no-data.png'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import { CSVLink } from 'react-csv'

//antd
import {
  Row,
  Col,
  Divider,
  Input,
  Button,
  Table,
  InputNumber,
  Form,
  Select,
  Radio,
  Spin,
  Tooltip,
  Space,
  DatePicker,
  Upload,
  Modal,
  notification,
  Drawer,
  Checkbox,
  Popover,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
  SearchOutlined,
  PlusSquareOutlined,
  CreditCardFilled,
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'

//apis
import { getProducts } from 'apis/product'
import { getAllBranch } from 'apis/branch'
import { uploadFile } from 'apis/upload'
import { getSuppliers } from 'apis/supplier'
import { getEmployees } from 'apis/employee'
import {
  createOrderImportInventory,
  updateOrderImportInventory,
  getOrdersImportInventory,
} from 'apis/inventory'
import { addTax, getTaxs, updateTax, deleteTax } from 'apis/tax'

//components
import Permission from 'components/permission'
import TitlePage from 'components/title-page'
import delay from 'delay'
import Barcode from 'react-barcode'
import html2pdf from 'html2pdf.js'
import { searchComponent } from 'utils/search'
import { getProductsSystem } from 'redux/actions/products'
import ImportPrint from './item/importPrint'
const pageStyle = `
@media all{
  .pageBreak{
    display: none
  }
};
@media print{
  .pageBreak{
    page-break-before: always;
  }
}
`;
const headers = [
  { label: 'Lỗi', key: 'error' },
  { label: 'Mã phiên bản', key: 'variant_sku' },
  { label: 'Tên sản phẩm', key: 'variant_name' },
  { label: 'Số lượng', key: 'quantity' },
  { label: 'Đơn giá', key: 'price' },
]
const { TextArea } = Input
export default function ProductBarcode() {
  const printRef = useRef()
  const [firstItem, setFirstItem] = useState([])
  const [listItem, setListItem] = useState([])
  const [checkNameBC, setCheckNameBC] = useState([])
  const [checkCostBC, setCheckCostBC] = useState([])

  const [typePage, setTypePage] = useState('72x22')

  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataUser = useSelector((state) => state.login.dataUser)
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const query = new URLSearchParams(location.search)
  const product_ids = query.get('product_ids')
  const order_id = query.get('order_id')
  let { code } = useParams()
  const [taxList, setTaxList] = useState([])
  const [users, setUsers] = useState([])
  const [branches, setBranches] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [file, setFile] = useState('')
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [productsSearch, setProductsSearch] = useState([])
  const [importLocation, setImportLocation] = useState({})

  const [orderCurrent, setOrderCurrent] = useState()

  const [supplierId, setSupplierId] = useState()
  const [productsSupplier, setProductsSupplier] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [visibleProductsToSupplier, setVisibleProductsToSupplier] = useState(false)
  const toggleProductsToSupplier = () => {
    setVisibleProductsToSupplier(!visibleProductsToSupplier)
    setProductsSupplier([])
    setSelectedProducts([])
    setSupplierId()
  }
  const [filteredResults, setFilteredResults] = useState([])
  const { products, productsFil } = useSelector((state) => state.products)
  const [productsError, setProductsError] = useState([])

  //object order create
  const [orderCreate, setOrderCreate] = useState({
    order_details: [], //danh sách sản phẩm trong hóa đơn
    type_payment: 'PAID', //hình thức thanh toán
    sumCostPaid: 0, // tổng tiền của tất cả sản phẩm
    deliveryCharges: 0, //phí giao hàng
    moneyToBePaidByCustomer: 0, // tổng tiền khách hàng phải trả (Tổng tiền thanh toán)
    tax_list: [],
    sum_tax_list: 0,
    discount: 0,
  })
  const handleCheckTypePage = (event) => {
    setTypePage(event.target.value)
  }
  const handleCheckName = (event) => {
    var updatedList = [...checkNameBC]
    if (event.target.checked) {
      updatedList.splice(checkNameBC.indexOf(event.target.value), 1)
    } else {
      updatedList = [...checkNameBC, event.target.value]
    }
    setCheckNameBC(updatedList)
  }
  const checkedName = checkNameBC.length ? <></> : ''
  const handleCheckCost = (event) => {
    var updatedList = [...checkCostBC]
    if (event.target.checked) {
      updatedList.splice(checkCostBC.indexOf(event.target.value), 1)
    } else {
      updatedList = [...checkCostBC, event.target.value]
    }
    setCheckCostBC(updatedList)
  }
  const checkedCost = checkCostBC.length ? <></> : 0
  const handleDownloadPDF = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument
      if (document) {
        const html = document.getElementsByTagName('html')[0]
        await html2pdf().from(html).save()
      }
    },
  })
  const _editOrder = (attribute, value) => {
    const orderCreateNew = { ...orderCreate }
    const new_value = value.map((item) => item.variant_id)
    const indexProduct = orderCreateNew.order_details.findIndex((e) =>
      new_value.includes(e.variant_id)
    )
    if (indexProduct === -1) {
      if (attribute == 'order_details') {
        orderCreateNew[attribute] = orderCreateNew[attribute].concat(value)
      } else {
        orderCreateNew[attribute] = value
      }

      //tổng giá trị ds thuế
      orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
        (total, current) => total + (current.value / 100) * orderCreateNew.sumCostPaid,
        0
      )

      //tổng tiền khách hàng phải trả (thành tiền)
      //tổng tiền sp + phí ship + VAT (mặc định 10%)
      orderCreateNew.moneyToBePaidByCustomer = +(
        orderCreateNew.sumCostPaid +
        orderCreateNew.deliveryCharges +
        orderCreateNew.sum_tax_list -
        orderCreateNew.discount
      ).toFixed(0)

      form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
      setOrderCreate({ ...orderCreateNew })
      checkItem(orderCreateNew)
    } else notification.warning({ message: 'Sản phẩm đã được thêm' })
  }
  const _addProductToOrder = (product) => {
    if (product) {
      const orderCreateNew = { ...orderCreate }
      const indexProduct = orderCreateNew.order_details.findIndex(
        (e) => e.variant_id === product.variant_id
      )

      if (indexProduct === -1) {
        orderCreateNew.order_details.push({ ...product, sumCost: product.import_price || 0 })
        // tổng tiền của tất cả sản phẩm
        orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
          (total, current) => total + current.sumCost,
          0
        )
        //tổng giá trị ds thuế
        orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
          (total, current) => total + (current.value / 100) * orderCreateNew.sumCostPaid,
          0
        )
        //tổng tiền khách hàng phải trả (thành tiền)
        //tổng tiền sp + phí ship + VAT (mặc định 10%)
        orderCreateNew.moneyToBePaidByCustomer = +(
          orderCreateNew.sumCostPaid +
          orderCreateNew.deliveryCharges +
          orderCreateNew.sum_tax_list
        ).toFixed(0)

        form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
        setOrderCreate({ ...orderCreateNew })
        checkItem(orderCreateNew)
      } else notification.warning({ message: 'Sản phẩm đã được thêm' })
    }
  }
  const _importProduct = (value) => {
    dispatch({ type: ACTION.LOADING, data: true })
    const orderCreateNew = { ...orderCreate }

    if (!value) return
    let newProductError = [...productsError]

    value.map((item) => {
      let product = products.find((product) => product.sku === item.variant_sku)
      const index = orderCreateNew.order_details.findIndex(
        (e) => e.variant_id === product.variant_id
      )
      if (index === -1) {
        product.quantity = item.quantity
        product.price = item.price
        orderCreateNew.order_details.push({ ...product, sumCost: product.import_price || 0 })
        // tổng tiền của tất cả sản phẩm
        orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
          (total, current) => total + current.sumCost,
          0
        )
        //tổng giá trị ds thuế
        orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
          (total, current) => total + (current.value / 100) * orderCreateNew.sumCostPaid,
          0
        )
        //tổng tiền khách hàng phải trả (thành tiền)
        //tổng tiền sp + phí ship + VAT (mặc định 10%)
        orderCreateNew.moneyToBePaidByCustomer = +(
          orderCreateNew.sumCostPaid +
          orderCreateNew.deliveryCharges +
          orderCreateNew.sum_tax_list
        ).toFixed(0)
      } else {
        let objectProduct = {
          error: `Phiên bản sản phẩm đã được thêm`,
          variant_sku: item.variant_sku || '', //mã phiên bản sản phẩm
          variant_name: item.variant_name || '', // tên sản phẩm
          quantity: item.quantity || '', //số lượng nhập
          price: item.price || '', 
        }
        newProductError.push(objectProduct)
      }
    })
    setOrderCreate({ ...orderCreateNew })
    checkItem(orderCreateNew)
    setProductsError(newProductError)
    dispatch({ type: ACTION.LOADING, data: false })
  }
  const _editProductInOrder = (attribute = '', value, index) => {
    if (index !== -1) {
      const orderCreateNew = { ...orderCreate }
      orderCreateNew.order_details[index][attribute] = value

      //tổng tiền của 1 sản phẩm
      orderCreateNew.order_details[index].sumCost =
        +orderCreateNew.order_details[index].quantity *
        +orderCreateNew.order_details[index].import_price

      // tổng tiền của tất cả sản phẩm
      orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
        (total, current) => total + current.sumCost,
        0
      )
      form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
      setOrderCreate({ ...orderCreateNew })
      checkItem(orderCreateNew)
    }
  }

  const _removeProductToOrder = (indexProduct) => {
    if (indexProduct !== -1) {
      const orderCreateNew = { ...orderCreate }
      orderCreateNew.order_details.splice(indexProduct, 1)

      // tổng tiền của tất cả sản phẩm
      orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
        (total, current) => total + current.sumCost,
        0
      )
      setOrderCreate({ ...orderCreateNew })
      form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
      checkItem(orderCreateNew)
    }
  }
  const columnsProductsToSupplier = [
    {
      title: 'Tên Sản phẩm',
      dataIndex: 'product_name',
    },
    {
      title: 'Tên phiên bản',
      dataIndex: 'title',
    },
    {
      title: 'Đơn giá nhập',
      dataIndex: 'import_price',
    },
  ]

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      render:(text,record, index) => index + 1,
      width: '5%',
      align: 'center',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
    },
    {
      title: 'Mã BarCode',
      dataIndex: 'barcode',
    },
    {
      title: 'Tên phiên bản',
      dataIndex: 'title',
    },

    {
      title: 'Số Lượng',
      width: 100,

      render: (data, record, index) => (
        <InputNumber
          style={{ width: 90, padding: 3 }}
          onChange={(e) => {
            _editProductInOrder('quantity', +e, index)
          }}
          value={record.quantity}
          defaultValue={record.quantity || 0}
          min={1}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder="Số Lượng In"
        />
      ),
    },
    {
      width: 130,
      title: () => {
        return <div>{'Đơn giá'}</div>
      },
      render: (data, record, index) => (
        <InputNumber
          style={{ width: '100%', padding: 3 }}
          onChange={(e) => {
            _editProductInOrder('price', +e, index)
          }}
          value={record.price}
          defaultValue={record.price || record.import_price || 0}
          min={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder="Nhập đơn giá"
        />
      ),
    },
    {
      width: 25,
      title: '',
      render: (data, record, index) => (
        <DeleteOutlined
          style={{ color: 'red', fontSize: 16 }}
          onClick={() => _removeProductToOrder(index)}
        />
      ),
    },
  ]

  const _getBranches = async () => {
    try {
      const res = await getAllBranch()
      if (res.status === 200) {
        setBranches(res.data.data)

        // default value
        if (!code) {
          setImportLocation({ branch_id: dataUser && dataUser.data && dataUser.data.branch_id })
          form.setFieldsValue({
            import_location: dataUser && dataUser.data && dataUser.data.branch_id,
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getUsers = async () => {
    try {
      const res = await getEmployees()
      if (res.status === 200) {
        setUsers(res.data.data)

        if (!code)
          form.setFieldsValue({
            order_creator_id: dataUser && dataUser.data && dataUser.data.user_id,
            receiver_id: dataUser && dataUser.data && dataUser.data.user_id,
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getSuppliers = async () => {
    try {
      const res = await getSuppliers()
      if (res.status === 200) setSuppliers(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getProductsByIds = async (ids) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getProducts({
        merge: true,
        detach: true,
        bulk_query: ids,
        branch_id: branchIdApp,
        barcode: true,
      })
      if (res.status === 200) {
        _editOrder('order_details', res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _getProductsToSupplier = async (supplierId) => {
    try {
      setLoadingProduct(true)
      const res = await getProducts({ merge: true, detach: true, supplier_id: supplierId })
      if (res.status === 200) {
        setProductsSupplier([...res.data.data])
        setSelectedProducts([])
      }
      setLoadingProduct(false)
    } catch (error) {
      console.log(error)
      setLoadingProduct(false)
    }
  }

  const _getProductsSearch = async () => {
    try {
      setLoadingProduct(true)
      const res = await getProducts({ merge: true, detach: true, branch_id: branchIdApp })
      if (res.status === 200) {
        const query = new URLSearchParams(location.search)
        const _id = query.get('_id')
        if (_id) {
          const productFind = res.data.data.find((e) => e._id === _id)
          if (productFind) _addProductToOrder(productFind)
        }

        setProductsSearch([...res.data.data])
      }
      setLoadingProduct(false)
    } catch (error) {
      console.log(error)
      setLoadingProduct(false)
    }
  }

  const _getTaxList = async () => {
    try {
      const res = await getTaxs({ branch_id: branchIdApp })
      if (res.status === 200) {
        setTaxList(res.data.data)

        if (!code) {
          if (orderCreate.tax_list.length === 0 && !product_ids) {
            const taxDefault = res.data.data.find((e) => e.default)
            _editOrder('tax_list', taxDefault ? [taxDefault] : [])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrderDetail = async (code) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getOrdersImportInventory({ code: code })
      if (res.status === 200)
        if (res.data.data && res.data.data.length) {
          initOrder(res.data.data[0])
          await delay(100)
          form.setFieldsValue({ code: '', complete_date: '' })
        }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const initOrder = (orderDetail) => {
    setOrderCreate({
      ...orderCreate,
      order_details: orderDetail.products,
      type_payment: orderDetail.payment_status || '',
      sumCostPaid: orderDetail.total_cost || 0,
      deliveryCharges: orderDetail.fee_shipping || 0,
      moneyToBePaidByCustomer: orderDetail.final_cost || 0,
      tax_list: orderDetail.list_tax || [],
      sum_tax_list: orderDetail.total_tax,
      discount: orderDetail.total_discount,
    })
    setImportLocation(orderDetail.import_location ? { ...orderDetail.import_location } : {})
    form.setFieldsValue({
      ...orderDetail,
      import_location: orderDetail.import_location ? orderDetail.import_location.branch_id : '',
      complete_date: orderDetail.complete_date ? moment(orderDetail.complete_date) : null,
      moneyToBePaidByCustomer: orderDetail.payment_amount || 0,
      paid: orderDetail.payment_amount || 0,
      order_creator_id: orderDetail.order_creator_id,
      receiver_id: orderDetail.receiver_id,
    })
  }

  const checkItem = async (orderCreate) => {
    try {
      let data = []
      for (let i = 0; i < orderCreate.order_details.length; i++) {
        if (orderCreate.order_details[i].quantity >= 1) {
          for (let e = 0; e < orderCreate.order_details[i].quantity; e++) {
            data.push(orderCreate.order_details[i])
            listItem.splice(0)
            listItem.push(...data)
          }
        }
      }
      setListItem(listItem)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getBranches()
    _getSuppliers()
    _getProductsSearch()
    _getUsers()
    _getTaxList()
  }, [])
  useEffect(() => {
    if (!code) {
      form.setFieldsValue({ type_print: typePage })

      if (code) _getOrderDetail(code)
      if (product_ids) _getProductsByIds(product_ids)
    } else initOrder()
  }, [])
  useEffect(() => {
    let item = orderCreate.order_details.find((item) => item !== undefined)
    setFirstItem(item)
  }, [orderCreate.order_details.map((item) => item.name)])
  useEffect(() => {
    dispatch(getProductsSystem({ branchIdApp: branchIdApp }))
  }, [branchIdApp])
  return (
    <div className="card">
      <Form layout="vertical" form={form}>
        <TitlePage
          isAffix={true}
          title={
            <Row
              onClick={() => history.push(ROUTES.PRODUCT)}
              align="middle"
              style={{ color: 'black', cursor: 'pointer' }}
            >
              <ArrowLeftOutlined style={{ marginRight: 5 }} />
              In Mã Vạch
            </Row>
          }
        >
          <Space>
            <ReactToPrint
              trigger={() => (
                <Popover
                  placement="leftTop"
                  content={
                    orderCreate.order_details.length == 0 &&
                    'Vui lòng thêm sản phẩm vào danh sách in mã vạch!'
                  }
                >
                  <Button disabled={orderCreate.order_details.length === 0 ? true : false}>
                    Print
                  </Button>
                </Popover>
              )}
              content={() => printRef.current}
              pageStyle={pageStyle}
            />
            {/* <Button onClick={handleDownloadPDF}>Download</Button> */}
          </Space>
        </TitlePage>

        <Row gutter={30} style={{ marginTop: 10 }}>
          <div className={styles['block']}>
            <Row justify="space-between" align="middle" wrap={false}>
              <div className={styles['title']}>Sản phẩm</div>
              <Button type="link" onClick={toggleProductsToSupplier}>
                Chọn nhanh từ nhà cung cấp
              </Button>
              <Modal
                style={{ top: 20 }}
                footer={null}
                title="Chọn sản phẩm từ nhà cung cấp"
                width={820}
                visible={visibleProductsToSupplier}
                onCancel={toggleProductsToSupplier}
              >
                <Row justify="space-between" wrap={false} align="middle">
                  <Select
                    value={supplierId}
                    onChange={(value) => {
                      setSupplierId(value)
                      _getProductsToSupplier(value)
                    }}
                    filterOption={(inputValue, option) =>
                      removeVietnameseTones(option.props.children)
                        .toLowerCase()
                        .includes(removeVietnameseTones(inputValue).toLowerCase().trim())
                    }
                    showSearch
                    style={{ width: 250, marginBottom: 10 }}
                    placeholder="Chọn nhà cung cấp"
                  >
                    {suppliers.map((supplier, index) => (
                      <Select.Option key={supplier.supplier_id}>{supplier.name}</Select.Option>
                    ))}
                  </Select>
                  <Button
                    onClick={() => {
                      if (selectedProducts.length == 0) {
                        return notification.error({
                          key: 'error key variant print',
                          message: 'Chưa có sản phẩm nào trong danh sách in!',
                        })
                      }
                      const products = productsSupplier.filter((product) =>
                        selectedProducts.includes(product.variant_id)
                      )
                      _editOrder('order_details', products)
                      toggleProductsToSupplier()
                    }}
                    type="primary"
                    style={{ display: !productsSupplier.length && 'none' }}
                  >
                    Xác nhận
                  </Button>
                </Row>
                <Table
                  rowSelection={{
                    selectedRowKeys: selectedProducts,
                    onChange: setSelectedProducts,
                  }}
                  rowKey="variant_id"
                  size="small"
                  loading={loadingProduct}
                  dataSource={productsSupplier}
                  columns={columnsProductsToSupplier}
                  pagination={false}
                  style={{ width: '100%' }}
                  scroll={{ y: '60vh' }}
                />
              </Modal>
            </Row>
            <Row  wrap={false} style={{ marginBottom: 10 }}>
            <a href="https://s3.ap-southeast-1.wasabisys.com/ekata-staging/public/2023/07/10/1688980830854-212156615-PrintBarcode.xlsx">
            <Button>Tải về file import mẫu</Button>
          </a>
          <ImportPrint
            importProduct={_importProduct}
            setProductsError={setProductsError}
            branch_id={branchIdApp}
          />
          <Button
            type="primary"
            style={{ marginLeft: '1em' }}
            disabled={productsError.length > 0 ? false : true}
          >
            <CSVLink
              headers={headers}
              data={productsError}
              filename={'Sản phẩm lỗi'}
              style={{ textDecoration: 'none' }}
            >
              Tải file lỗi
            </CSVLink>
          </Button>
          <div className="select-product-sell" style={{width: '65%'}}>
 
 <Select
       notFoundContent={loadingProduct ? <Spin size="small" /> : null}
       dropdownClassName="dropdown-select-search-product"
       allowClear
       showSearch
       clearIcon={<CloseOutlined style={{ color: 'black' }} />}
       suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
       style={{ width: '100%', marginBottom: 15 }}
       placeholder="Tìm kiếm sản phẩm theo tên"
       onSearch={(searchValue) =>
         searchComponent(
           searchValue,
           setFilteredResults,
           products.filter((item) => item.inventory !== 'normal')
         )
       }
       filterOption={(inputValue, option) =>
         (option.props.children = option.props.children.toString().toLowerCase().trim())
       }
       value={null}
       onChange={(value, option) => {
         if (value !== null && value !== undefined && value !== '') {
           let index = value.lastIndexOf('/')
           let resultIndex = value.substring(index + 1)
           let productResults = []
           if (filteredResults && filteredResults.length > 0) {
             productResults = filteredResults
           } else {
             productResults = productsFil.filter((item) => item.inventory !== 'normal')
           }
           productResults[resultIndex].quantity =
             productResults[resultIndex].total_quantity

           _addProductToOrder(productResults[resultIndex])
           setFilteredResults([])
         }
       }}
       dropdownRender={(menu) => (
         <div>
           <Permission permissions={[PERMISSIONS.them_san_pham]}>
             <Row
               wrap={false}
               align="middle"
               style={{ cursor: 'pointer' }}
               onClick={() => window.open(ROUTES.PRODUCT_ADD, '_blank')}
             >
               <div
                 style={{
                   paddingLeft: 15,
                   width: 45,
                   height: 50,
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                 }}
               >
                 <PlusSquareOutlined style={{ fontSize: 19 }} />
               </div>
               <p style={{ marginLeft: 20, marginBottom: 0, fontSize: 16 }}>
                 Thêm sản phẩm mới
               </p>
             </Row>
           </Permission>
           {menu}
         </div>
       )}
     >
       {filteredResults &&
         productsFil &&
         (filteredResults.length > 0
           ? filteredResults
           : productsFil.filter((item) => item.inventory !== 'normal')
         ).map(
           (data, index) =>
             data && (
               <Select.Option
                 key={data.title + data.sku + data.barcode + '/' + index + ''}
               >
                 <Row align="middle" wrap={false} style={{ padding: '7px 13px' }}>
                   <img
                     src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                     alt=""
                     style={{
                       minWidth: 55,
                       minHeight: 55,
                       maxWidth: 55,
                       maxHeight: 55,
                       objectFit: 'cover',
                     }}
                   />

                   <div style={{ width: '100%', marginLeft: 15 }}>
                     <Row wrap={false} justify="space-between">
                       <span
                         style={{
                           maxWidth: 300,
                           marginBottom: 0,
                           fontWeight: 600,
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           WebkitLineClamp: 1,
                           WebkitBoxOrient: 'vertical',
                           display: '-webkit-box',
                         }}
                       >
                         {data.title}
                       </span>
                       <span style={{ marginBottom: 0, fontWeight: 500 }}>
                         {formatCash(data.import_price)}
                       </span>
                     </Row>
                   </div>
                 </Row>
               </Select.Option>
             )
         )}
     </Select>          
   </div>
            </Row>
            <Table
              scroll={{ y: 400 }}
              sticky
              pagination={false}
              columns={columns}
              size="small"
              dataSource={[...orderCreate.order_details]}
              locale={{
                emptyText: (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 200,
                    }}
                  >
                    <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                    <h4 style={{ fontSize: 15, color: '#555' }}>
                      Đơn hàng của bạn chưa có sản phẩm
                    </h4>
                  </div>
                ),
              }}
            />
          </div>
        </Row>

        <Row gutter={16} style={{ marginTop: 25 }}>
          <Col span={17}>
            <div className={styles['block']}>
              <Row>
                <Col span={12}>
                  <div>
                    <Row wrap={false} align="middle" style={{ fontWeight: 600 }}>
                      <CreditCardFilled style={{ fontSize: 17, marginRight: 5 }} />
                      <div>Thiết Lập Mẫu Tem</div>
                    </Row>
                    <div style={{ marginTop: 10 }}>
                      <Form.Item>
                        <Space size="small" direction="vertical">
                          <Checkbox defaultChecked={true} onChange={handleCheckName}>
                            Tên Sản Phẩm
                          </Checkbox>
                          <Checkbox defaultChecked={true} onChange={handleCheckCost}>
                            Giá Bán
                          </Checkbox>
                        </Space>
                      </Form.Item>
                    </div>

                    <Divider />
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ border: '1px dashed', width: '272.12598425px', height: '100px' }}>
                    {firstItem && (
                      <div key={firstItem._id}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            float: 'left',
                            alignItems: 'center',
                            height: '84px',
                            width: '100%',
                            marginTop: '3px',
                          }}
                        >
                          {checkedName === '' ? <div
                            style={{
                              marginTop: '2px',
                              fontSize: 12,
                              lineHeight: 1,
                              fontFamily:'sans-serif',
                              fontWeight: 500,
                            }}
                            // level={3}
                          >
                            {checkedName || firstItem.title}
                          </div> : null}
                          
                          <Barcode
                            width={checkedName !== '' ? '1.5%' : 1.5}
                            height={checkedName !== '' ? 40 : 60}
                            format="CODE128"
                            value={
                              firstItem.barcode &&
                              (firstItem.barcode.length > 0 ? firstItem.barcode : firstItem.sku)
                            }
                            font="tahoma"
                            textAlign="center"
                            textPosition="bottom"
                            fontSize={15}
                          />
                          <div style={{ fontSize: 12, lineHeight: 1, fontWeight: 'bold' }}>
                            {checkedCost || formatCash(firstItem.price) + ' VNĐ'}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          {/* <div style={{ display: 'none' }}>
            <div ref={printRef}>
              {listItem &&
                listItem.map((product, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        float: 'left',
                        alignItems: 'center',
                        height: '90px',
                        width: '50%',
                        marginTop: '50px',
                        // marginLeft:0
                      }}
                    >
                      <div
                        style={{
                          marginTop: '2px',
                          fontSize: 12,
                          // lineHeight: 2,
                          fontFamily:'sans-serif',
                          fontWeight: 400,
                        }}
                        level={3}
                      >
                        {checkedName || product.title}  
                      </div>
                      <Barcode
                        width={checkedName !== '' ? '1.5%' : 1.5}
                        height={checkedName !== '' ? 40 : 60}
                        format="CODE128"
                        value={
                          product?.barcode !== '' || product?.barcode !== undefined
                            ? product?.barcode
                            : product.sku
                        }
                        font="tahoma"
                        textAlign="center"
                        textPosition="bottom"
                      />
                      <div style={{ fontSize: 15, lineHeight: 1, fontWeight: 'bold' }}>
                        {checkedCost || formatCash(product.price) + ' VNĐ'}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div> */}
          <Col span={7}>
            <div className={styles['block']}>
              <Row wrap={false} align="middle" style={{ fontWeight: 600, marginBottom: 20 }}>
                <div>Chọn Khổ Giấy In</div>
              </Row>
              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <div>Khổ 1 tem</div>
                  <Form.Item name="type_print">
                    <Radio.Group onChange={handleCheckTypePage}>
                      <Space size="small" direction="vertical">
                        <Radio value="40x30">Khổ 40x30 mm</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <div style={{ border: '1px dashed', width: '100px', height: '90px' }}></div>
                </Col>
                {typePage === '40x30' && (
                    <div style={{ display: 'none' }}>
                      <div ref={printRef}>
                        {listItem &&
                          listItem.map((product, index) => (
                            <div key={index}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  float: 'left',
                                  alignItems: 'center',
                                  height: '82px',
                                  width: '50%',
                                  marginTop: '2px',
                                }}
                              >
                                {checkedName === '' ? <div  
                                  style={{
                                    marginTop: '2px',
                                    fontSize: 10,
                                    lineHeight: 1,
                                    fontFamily:'sans-serif',
                                    fontWeight: 400,
                                  }}
                                >
                                  {checkedName || product.title}
                                </div> : null}
                                <Barcode
                                  width={1.5}
                                  height={60}
                                  format="CODE128"
                                  value={product.barcode.length > 0 ? product.barcode : product.sku}
                                  font="tahoma"
                                  textAlign="center"
                                  textPosition="bottom"
                                />
                                <div style={{ fontSize: 13, lineHeight: 1, fontWeight: 'bold' }}>
                                  {checkedCost || formatCash(product.price) + ' VNĐ'}
                                </div>
                              </div>
                              <div style={{
                                  float: 'left',
                                  height: '82px',
                                  width: '50%',
                                  marginTop: '2px',
                                }}></div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
              </Row>
              <Divider />

              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <div>Khổ 2 tem</div>
                  <Form.Item name="type_print">
                    <Radio.Group onChange={handleCheckTypePage}>
                      <Space size="small" direction="vertical">
                        <Radio value="72x22">Khổ 72x22 mm</Radio>
                        <Radio value="74x22">Khổ 74x22 mm</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <div style={{ border: '1px dashed', width: '100px', height: '90px' }}></div>
                </Col>
                {(typePage === '72x22' ||
                  typePage === '74x22') && (
                    <div style={{ display: 'none' }}>
                      <div ref={printRef}>
                        {listItem &&
                          listItem.map((product, index) => (
                            <div key={index}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  float: 'left',
                                  alignItems: 'center',
                                  height: '82px',
                                  width: '50%',
                                  marginTop: '2px',
                                }}
                              >
                               {checkedName === '' ? <div 
                                style={{
                                  fontSize: 10,
                                  fontFamily:'sans-serif'
                                }}
                               >
                                {checkedName || product.title}
                               </div> : null}
                                
                                <Barcode
                                  // width={checkedName !== '' ? '1.5%' : 1.5}
                                  // height={checkedName !== '' ? 40 : 60}
                                  width={1.5}
                                  height={60}
                                  format="CODE128"
                                  value={product.barcode.length > 0 ? product.barcode : product.sku}
                                  font="tahoma"
                                  textAlign="center"
                                  textPosition="bottom"
                                  background= "#ffffff"
                                  lineColor= "#000000"
                                />
                                <div style={{ fontSize: 13, lineHeight: 1, fontWeight: 'bold', }}>
                                  {checkedCost || formatCash(product.price) + ' VNĐ'}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
              </Row>

              <Divider />

              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <div>Khổ 3 tem</div>
                  <Form.Item name="type_print">
                    <Radio.Group onChange={handleCheckTypePage}>
                      <Space size="small" direction="vertical">
                        <Radio value="110x22">Khổ 110x22 mm</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <div style={{ border: '1px dashed', width: '100px', height: '90px' }}></div>
                </Col>
                {typePage === '110x22' && (
                  <div style={{ display: 'none' }}>
                    <div ref={printRef}>
                      {listItem &&
                        listItem.map((product, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                float: 'left',
                                alignItems: 'center',
                                height: '82px',
                                width: '33.33%',
                                marginTop: '2px',
                              }}
                            >
                              {checkedName === '' ?<div
                                style={{
                                  fontSize: 10,
                                  fontFamily:'sans-serif',
                                }}
                              >
                                {checkedName || product.title}
                              </div> : null}
                              <Barcode
                                width={checkedName !== '' ? '1.5%' : '1%'}
                                height={60}
                                format="CODE128"
                                value={product.barcode.length > 0 ? product.barcode : product.sku}
                                font="tahoma"
                                textAlign="center"
                                textPosition="bottom"
                              />
                              <div style={{ fontSize: 12, lineHeight: 1, fontWeight: 'bold' }}>
                                {checkedCost || formatCash(product.price) + ' VNĐ'}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </Row>
              <Divider />
              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <div>Khổ Giấy A4</div>
                  <Form.Item name="type_print">
                    <Radio.Group onChange={handleCheckTypePage}>
                      <Space size="small" direction="vertical">
                        <Radio value="no145">Tomy No.145 - 65 tem</Radio>
                        <Radio value="no146">Tomy No.146 - 180 tem</Radio>
                        <Radio value="no138">Tomy No.138 - 100 tem</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <div style={{ border: '1px dashed', width: '100px', height: '90px' }}></div>
                </Col>
                {typePage === 'no145' && (
                  <div style={{ display: 'none' }}>
                    <div ref={printRef}>
                      {listItem &&
                        listItem.map((product, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                float: 'left',
                                alignItems: 'center',
                                height: '82px',
                                width: '20%',
                                marginTop: '1px',
                              }}
                            >
                              <div
                                style={{
                                  marginTop: '2px',
                                  fontSize: 10,
                                  lineHeight: 2,
                                  fontFamily:'sans-serif',
                                  fontWeight: 400,
                                }}
                                // level={3}
                              >
                                {checkedName || product.title}
                              </div>
                              <Barcode
                                width={1}
                                height={60}
                                format="CODE128"
                                value={product.barcode.length > 0 ? product.barcode : product.sku}
                                font="tahoma"
                                textAlign="center"
                                textPosition="bottom"
                              />
                              <div style={{ fontSize: 8, lineHeight: 1, fontWeight: 600 }}>
                                {checkedCost || formatCash(firstItem.price) + ' VNĐ'}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {typePage === 'no146' && (
                  <div style={{ display: 'none' }}>
                    <div ref={printRef}>
                      {listItem &&
                        listItem.map((product, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                float: 'left',
                                alignItems: 'center',
                                height: '53.97637795275591px',
                                width: '10%',
                                marginTop: '7px',
                              }}
                            >
                              <div
                                style={{
                                  marginTop: '2px',
                                  fontSize: 5,
                                  lineHeight: 2,
                                  fontFamily:'sans-serif',
                                  fontWeight: 400,
                                }}
                                level={3}
                              >
                                {checkedName || product.title}
                              </div>
                              <Barcode
                                height={40}
                                format="CODE128"
                                value={product.barcode.length > 0 ? product.barcode : product.sku}
                                font="tahoma"
                                textAlign="center"
                                textPosition="bottom"
                              />
                              <div style={{ fontSize: 7, lineHeight: 1, fontWeight: 600 }}>
                                {checkedCost || formatCash(firstItem.price) + ' VNĐ'}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {typePage === 'no138' && (
                  <div style={{ display: 'none' }}>
                    <div ref={printRef}>
                      {listItem &&
                        listItem.map((product, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                float: 'left',
                                alignItems: 'center',
                                height: '47.24409448818898px',
                                width: '20%',
                                marginTop: '5.3px',
                              }}
                            >
                              <div
                                style={{
                                  marginTop: '2px',
                                  fontSize: 5,
                                  lineHeight: 2,
                                  fontFamily:'sans-serif',
                                  fontWeight: 400,
                                }}
                                level={3}
                              >
                                {checkedName || product.title}
                              </div>
                              <Barcode
                                height={40}
                                format="CODE128"
                                value={product.barcode.length > 0 ? product.barcode : product.sku}
                                font="tahoma"
                                textAlign="center"
                                textPosition="bottom"
                              />
                              <div style={{ fontSize: 7, lineHeight: 1, fontWeight: 600 }}>
                                {checkedCost || formatCash(firstItem.price) + ' VNĐ'}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </Row>

              <Divider />
              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  <div>Khổ Giấy A5</div>
                  <Form.Item name="type_print">
                    <Radio.Group onChange={handleCheckTypePage}>
                      <Space size="small" direction="vertical">
                        <Radio value="no108">Tomy No.108 - 40 tem</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <div style={{ border: '1px dashed', width: '100px', height: '90px' }}></div>
                </Col>
                {typePage === 'no108' && (
                  <div style={{ display: 'none' }}>
                    <div ref={printRef}>
                      {listItem &&
                        listItem.map((product, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                float: 'left',
                                alignItems: 'center',
                                height: '88.58267716535433px',
                                width: '20%',
                                marginTop: '12.3px',
                              }}
                            >
                              <div
                                style={{
                                  marginTop: '2px',
                                  fontSize: 12,
                                  lineHeight: 2,
                                  fontFamily:'sans-serif',
                                  fontWeight: 400,
                                }}
                                level={3}
                              >
                                {checkedName || product.title}
                              </div>
                              <Barcode
                                height={40}
                                format="CODE128"
                                value={product.barcode.length > 0 ? product.barcode : product.sku}
                                font="tahoma"
                                textAlign="center"
                                textPosition="bottom"
                              />
                              <div style={{ fontSize: 14, lineHeight: 1, fontWeight: 600 }}>
                                {checkedCost || formatCash(firstItem.price) + ' VNĐ'}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
