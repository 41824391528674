import React, { useEffect, useState } from 'react'
import styles from './overview.module.scss'
import { LineChart } from 'react-chartkick'
import { formatCash, removeUnicode } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import noData from 'assets/icons/no-data.png'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { IMAGE_DEFAULT, ACTION, FILTER_SIZE } from 'consts'

//antd
import { Row, Col, Tooltip, Select, Spin, Button,Input } from 'antd'

//icons antd
import { ShoppingCartOutlined, InfoCircleOutlined,ToTopOutlined,SearchOutlined } from '@ant-design/icons'
//component
import FilterDate from 'components/filter-date'
import ModalProduct from './item/modalAllBranch'
import exportToCSV from 'components/ExportCSV/export'
//apis
import { getStatistical, getStatisticalChart, getStatisticalProduct } from 'apis/statistic'
import { getAllBranch } from 'apis/branch'
import { getProductOfToStock } from 'apis/report'

const Overview = () => {
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)
  const branches = useSelector((state) => state.branch.branches)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { t } = useTranslation()
  const [statisticalProduct, setStatisticalProduct] = useState({})
  const [statisticalToday, setStatisticalToday] = useState({})
  const [statisticalChart, setStatisticalChart] = useState([])
  const [loadingSkeleton, setLoadingSkeleton] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [brachs, setBranchs] = useState([])
  const [valueTime, setValueTime] = useState()
  const [paramsFilter, setParamsFilter] = useState({
    to_date: moment().format('YYYY-MM-DD'),
    from_date: moment().subtract(29, 'days').format('YYYY-MM-DD'),
  })
  const [loading, setLoading] = useState(false)
  const [loadingTopSell, setLoadingTopSell] = useState(false)

  const [reportInventory, setReportInventory] = useState([])
  const [paramsFilterInventory, setParamsFilterInventory] = useState({
    today: true,
    page: 1,
  })
  const [valueTimeInventory, setValueTimeInventory] = useState('today')
  const [valueSearch, setValueSearch] = useState('')

  const SALES = [
    t('overview.total_order'),
    t('overview.total_cost_of_capital'),
    t('overview.total_revenue'),
    t('overview.total_profit'),
    t('overview.total_online'),
    t('overview.total_offline'),
    t('overview.total_refund'),
    t('overview.total_order_cancel'),
  ]

  const _getBranches = async () => {
    try {
      let branchTmp = dataUser.branch_id
      let result = []
      if (dataUser.role_id === 1) {
        result = [...branches]
        result.unshift({
          branch_id: 0,
          name: 'Tất cả chi nhánh',
        })
        setParamsFilter({ ...paramsFilter })
      } else {
        for (let i of branchTmp) {
          ;[...branches].map((e) => {
            if (e.branch_id === i) result.push(e)
          })
        }
        setParamsFilter({ ...paramsFilter, branch_id: branchIdApp })
      }

      setBranchs(result)
    } catch (error) {
      console.log(error)
    }
  }
  const _getStatistical = async () => {
    try {
      setLoadingSkeleton(true)
      const resToday = await getStatistical({
        ...paramsFilter,
      })
      if (resToday.status === 200) setStatisticalToday(resToday.data.data)
      setLoadingSkeleton(false)
    } catch (e) {
      setLoadingSkeleton(false)
      console.log(e)
    }
  }

  const _getStatisticalChart = async () => {
    try {
      const resChart = await getStatisticalChart({
        ...paramsFilter,
      })
      if (resChart.status === 200) {
        setStatisticalChart(resChart.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const _getStatisticalProduct = async () => {
    try {
      setLoadingTopSell(true)
      const resProduct = await getStatisticalProduct({ ...paramsFilter })
      if (resProduct.status === 200) setStatisticalProduct(resProduct.data.data)
      setLoadingTopSell(false)
    } catch (e) {
      setLoadingTopSell(false)
      console.log(e)
    }
  }

  const _onChangeFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    if (attribute === 'branch_id' && value === 0) {
      delete paramsFilter[attribute]
    }

    setParamsFilter({ ...paramsFilter })
  }
  const _reportInventory = async () => {
    try {
      setLoading(true)
      const res = await getProductOfToStock({
        ...paramsFilterInventory,
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        setReportInventory(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _getProductOutToExport = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let dataExport = []

      reportInventory &&
        reportInventory.map((product, key_index) => {
            const obj = {
              STT: key_index + 1,
              'Tên sản phẩm': product.title || '',
              'SKU sản phẩm': product.sku || '',
              'Tồn kho tổng': product.quantity_system || 0,
              'Tồn kho tại chi nhánh xuất file': product.quantity_branch || 0,
            }
            dataExport.push(obj)
        })
      exportToCSV(dataExport, 'Danh sách sản phẩm hết hàng', 'Xuất excel tổng quan', dispatch)
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    const value = e.target.value.trim()
    if (value) paramsFilterInventory.search = value
    else delete paramsFilterInventory.search
    setParamsFilterInventory({ ...paramsFilterInventory })
  }
  useEffect(() => {
    _reportInventory()
  }, [paramsFilterInventory])
  useEffect(() => {
    _getStatistical()
    _getStatisticalChart()
    _getStatisticalProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter])
  useEffect(() => {
    setParamsFilter({ ...paramsFilter, branch_id: branchIdApp })
    setParamsFilterInventory({today: true,page: 1})
    setValueTimeInventory('today')
  }, [branchIdApp])
  //get width device
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else setIsMobile(false)
  }, [])
  useEffect(() => {
    _getBranches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches])
  return (
    <Spin spinning={loadingSkeleton} tip="Loaing..." size="large">
      <div>
        <div className={styles['card-overview']}>
          <div className={styles['dashboard_manager_balance_title']}>
            <div>{t('overview.SALES_PARAMETER')}</div>
            <div style={{ display: 'flex', position: 'absolute', right: 40 }}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={16}
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '5px 5px 5px 5px',
                  marginRight: 4,
                  maxWidth: 250,
                  minWidth: 250,
                }}
              >
                <FilterDate
                  style={{ marginTop: 3 }}
                  bordered={false}
                  paramsFilter={paramsFilter}
                  setParamsFilter={setParamsFilter}
                  title=""
                  valueTime={valueTime}
                  setValueTime={setValueTime}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={16}
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '5px 5px 5px 5px',
                  width: '100%',
                  maxWidth: 500,
                  minWidth: 400,
                }}
              >
                <Select
                  value={paramsFilter.branch_id || brachs[0]?.branch_id}
                  onChange={(value) => {
                    let index = value.findIndex((item) => item === 0)

                    if (index !== -1 && value.length > 1) {
                      if (index === 0) {
                        value.splice(index, 1)
                      } else {
                        value = 0
                      }
                    }
                    if (value.length === 0) {
                      value = brachs[0]?.branch_id
                    }

                    _onChangeFilter('branch_id', value)
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    removeUnicode(option.key.toString())
                      .toLowerCase()
                      .trim()
                      .includes(removeUnicode(input).toLowerCase().trim())
                  }
                  size={FILTER_SIZE}
                  placeholder="Chọn chi nhánh"
                  style={{ width: '100%' }}
                  bordered={false}
                  mode="multiple"
                  defaultValue={brachs[0]?.branch_id}
                >
                  {brachs.map((brach, index) => (
                    <Select.Option value={brach.branch_id} key={brach.name}>
                      {brach.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </div>
          </div>
          <Row justify="space-between" style={{ width: '100%' }}>
            {SALES.map((e, index) => (
              <div
                key={index}
                style={{
                  width: '25%',
                  padding: 10,
                  borderRight: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderBottom: '1px solid gray',
                }}
              >
                <Row justify="space-between" wrap={false} style={{ fontWeight: 600, fontSize: 18 }}>
                  <div>
                    <ShoppingCartOutlined /> {e}
                  </div>
                  {e === t('overview.total_order') ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng đơn hàng của chi nhánh, của cả cửa hàng bán được trong 30 ngày gần
                          nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : e === t('overview.total_cost_of_capital') ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng giá vống của chi nhánh, của cả cửa hàng bán được trong 30 ngày gần
                          nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : e === t('overview.total_revenue') ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng doanh thu của chi nhánh, của cả cửa hàng bán được trong 30 ngày gần
                          nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : e === t('overview.total_refund') ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng lợi nhuận của chi nhánh, của cả cửa hàng bán được trong 30 ngày gần
                          nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : e === t('overview.total_online') ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng đơn hàng online của chi nhánh, của cả cửa hàng bán được trong 30 ngày
                          gần nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : e === t('overview.total_offline') ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng đơn hàng tại cửa hàng hoặc đơn offline của chi nhánh, của cả cửa hàng
                          bán được trong 30 ngày gần nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : e === t('overview.total_order_cancel') ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng đơn hàng bị trả của chi nhánh, của cả cửa hàng bán được trong 30 ngày
                          gần nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'center' }}>
                          Tổng đơn hàng bị hủy của chi nhánh, của cả cửa hàng bán được trong 30 ngày
                          gần nhất
                        </div>
                      }
                    >
                      <InfoCircleOutlined style={{ fontSize: 12 }} />
                    </Tooltip>
                  )}
                </Row>
                <span style={{ marginBottom: 0, fontWeight: 700, fontSize: 17, color: '#5B6BE8' }}>
                  {e === t('overview.total_order')
                    ? formatCash(statisticalToday?.total_order)
                    : e === t('overview.total_cost_of_capital')
                    ? formatCash(statisticalToday?.total_base_price)
                    : e === t('overview.total_revenue')
                    ? formatCash(statisticalToday?.total_revenue)
                    : e === t('overview.total_refund')
                    ? formatCash(statisticalToday?.order_refund)
                    : e === t('overview.total_online')
                    ? formatCash(statisticalToday?.order_online)
                    : e === t('overview.total_offline')
                    ? formatCash(statisticalToday?.order_offline)
                    : e === t('overview.total_order_cancel')
                    ? formatCash(statisticalToday?.order_cancel)
                    : formatCash(statisticalToday?.total_profit)}
                </span>
              </div>
            ))}
          </Row>
        </div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <div
              style={{ marginRight: !isMobile && 7, marginTop: 0, height: 400, marginBottom: 15 }}
              className={styles['card-overview']}
            >
              <div className={styles['dashboard_manager_revenue_title']}>
                <Row align="middle">{t('overview.monthly_revenue_chart')}</Row>
              </div>
              <div>
                <LineChart data={statisticalChart} />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{ marginBottom: isMobile && 15 }}>
            <div
              style={{
                marginLeft: !isMobile ? 7 : 0,
                marginTop: 0,
                height: 400,
                marginBottom: 15,
              }}
              className={styles['card-overview']}
            >
              <div className={styles['dashboard_manager_bottom_row_col_parent_top']}>
                <div>{t('overview.top_10_best_selling_products')}</div>
              </div>
              <div style={{ width: '100%', overflowY: 'scroll', paddingTop: 10 }}>
                <Spin tip='loading...' spinning={loadingTopSell}>
                  {statisticalProduct.length ? (
                    statisticalProduct.map((e, index) => {
                      return (
                        <Row
                          align="middle"
                          style={
                            index % 2
                              ? { marginBottom: 8, background: '#F7F8FA' }
                              : { marginBottom: 8 }
                          }
                        >
                          <Col span={5}>
                            <img
                              alt=""
                              src={
                                e?.image[0] ? e.image[0] : e.images[0]
                                  ? e.images[0]
                                  : IMAGE_DEFAULT
                              }
                              width="50px"
                              height="50px"
                            />
                          </Col>
                          <Col span={12}>
                            <Row>{e?.title || ''}</Row>
                            <Row style={{ fontWeight: 500 }}>
                              {t('common.sold')} {e?.sale_quantity} {t('common.product')}
                            </Row>
                          </Col>
                        </Row>
                      )
                    })
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                      <h4 style={{ fontSize: 15, color: '#555' }}>
                        {t('overview.there_are_no_best_selling_products_yet')}
                      </h4>
                    </div>
                  )}
                </Spin>
                
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <div
              style={{ marginRight: !isMobile && 7, marginTop: 0, height: 400, marginBottom: 15 }}
              className={styles['card-overview']}
            ></div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{ marginBottom: isMobile && 15 }}>
            <div
              style={{
                marginLeft: !isMobile ? 7 : 0,
                marginTop: 0,
                height: 400,
                marginBottom: 15,
              }}
              className={styles['card-overview']}
            >
              <div className={styles['dashboard_manager_bottom_row_col_parent_top']}>
                <div>{t('overview.product_is_out_of_stock')}</div>
                <div className={styles['header_product_out_stock']}>
                  <Input
                    style={{ width: '40%' }}
                    name="name"
                    size={FILTER_SIZE}
                    value={valueSearch}
                    onChange={onSearch}
                    prefix={<SearchOutlined />}
                    placeholder="Tìm kiếm sản phẩm"
                    allowClear
                    bordered={true}
                  />
                <Button
                  id="exportFile"
                  style={{ backgroundColor: 'green', borderColor: 'green', margin:'0px 10px' }}
                  size="middle"
                  onClick={_getProductOutToExport}
                  type="primary"
                  icon={<ToTopOutlined />}
                >
                  Xuất excel
                </Button>
                <FilterDate
                  style={{
                    marginTop: 3,
                    width: '50%',
                    border: '1px solid rgb(197, 184, 184)',
                    borderRadius: '5px',
                    height:'100%'
                  }}
                  bordered={false}
                  paramsFilter={paramsFilterInventory}
                  setParamsFilter={setParamsFilterInventory}
                  title=""
                  valueTime={valueTimeInventory}
                  setValueTime={setValueTimeInventory}
                />
                </div>
                
              </div>
              <div style={{ width: '100%', overflowY: 'scroll', paddingTop: 10 }}>
              <Spin tip="Loading..." spinning={loading}>
                {reportInventory.length ? (
                    reportInventory.map((e, index) => {
                      return <ModalProduct product={e} index={index} />
                    })
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                      <h4 style={{ fontSize: 15, color: '#555' }}>
                        {t('overview.there_are_no_out_of_stock_products_yet')}
                      </h4>
                    </div>
                  )}
              </Spin>
                
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  )
}
export default Overview
