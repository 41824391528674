import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'consts'
const ConnectStatus = ({ record }) => {

  const routes =(record)=> {
    if(record?.order_info?.order_status){
      return ROUTES.ORDER_UPDATE + '/' + record?.order_info?.order_id
    }else{
      return `${ROUTES.REFUND_DETAIL + '/' +record?.order_info?.code}`
    }
  }
  return (
    <Link
      style={{
        color:
          (record?.order_info?.order_status === 'waiting-confirm' && 'black') ||
          (record?.order_info?.order_status === 'waiting-pickup' && 'black') ||
          (record?.order_info?.order_status === 'delivering' && 'orange') ||
          (record?.order_info?.order_status === 'complete' && 'green') ||
          (record?.order_info?.order_status === 'cancel-order' && 'red') ||
          (record?.order_info?.order_status === 'refund-order' && '#ff7089'),
      }}
      to={{
        pathname: routes(record)
      }}
      target='_blank'
    >
      {'#' + record?.order_info?.code}
    </Link>
  )
}

export default ConnectStatus
