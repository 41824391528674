import { notification } from 'antd'
import { updateOrderStatus } from 'apis/order'

const UpdateStatus = async (data, id) => {
  try {
    const body = { status: data }
    let res = await updateOrderStatus(body, id)
    if (res.status === 200) {
      notification.success({ message: 'Cập nhật trạng thái đơn hàng thành công!' })
      return true
    } else {
      notification.error({ message: 'Cập nhật trạng thái đơn hàng thất bại!' })
      return false
    }
  } catch (error) {
    console.log(error)
  }
}

export default UpdateStatus
