import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Space,  Form,Row} from 'antd'
import { ArrowLeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons'
import TitlePage from 'components/title-page'
import ScheduleWeekStaff from './schedule-week-staff'

import {
  getShift,
  getOneSchedule,
  getStaffShift,
  getStaffShiftWithAdmin
} from 'apis/schedule-staff'
import { getStaff } from 'apis/manage-staff'

import { getRoles } from 'apis/role'
import { ROUTES } from 'consts'

export default function ScheduleDetail() {
  const user = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const history = useHistory()
  const { id } = useParams()
  const [roles, setRoles] = useState([])
  const [schedule, setSchedule] = useState({})
  const [shift, setShift] = useState([])
  const [form] = Form.useForm()
  const [staffShift, setStaffShift] = useState([])
  const [employee, setEmployee] = useState([])
  const [staffShifts, setStaffShifts] = useState([])
  const _getEmployee = async () => {
    try {
      const res = await getStaff({branch_id: branchIdApp, is_delete: false})
      if (res.status === 200) {
        setEmployee(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const _getSchedule = async (id) => {
    try {
      const res = await getOneSchedule(id, {branch_id: branchIdApp})
        if (res.status === 200){
        if(res.data.data){
          setSchedule(res.data.data)
        }else{
          history.push(ROUTES.SCHEDULE_STAFF)
        }
        }
    } catch (error) {
      console.log(error)
    }
  }
  const _getShift = async () => {
    try {
      const res = await getShift()
      if (res.status === 200) {
        setShift(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getRoles = async () => {
    try {
      const res = await getRoles({ active: true, branch_id: branchIdApp })
      const res1 = await getRoles()
      const dedault = res1.data.data.filter(
        (role) => role.role_id === 2 || role.role_id === 3 || role.role_id === 4
      )
      if (res.status === 200) {
        const role_branch = res.data.data.filter(
          (role) => role.role_id > 4 && role.branch_id === branchIdApp
        )
        role_branch.push(...dedault)
        setRoles([...role_branch])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getStaffShiftWithAdmin = async () => {
    try {
      const res = await getStaffShiftWithAdmin(id)
      if (res.status === 200) {
        setStaffShifts(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
 
  const _getStaffShift = async (id) => {
    try {
      const res = await getStaffShift(id)
      if (res.status === 200) {
        setStaffShift(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getStaffShift(id)
    _getStaffShiftWithAdmin()
  }, [schedule])

  useEffect(() => {
    _getEmployee()
  },[branchIdApp])
  
  useEffect(() => {
    _getSchedule(id)
    _getRoles()
    _getShift()
  }, [branchIdApp])

  return (
    <div className="carddetail">
      <TitlePage
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer', fontSize:24, marginLeft:20,fontWeight:700 }}
            onClick={() => history.push(ROUTES.SCHEDULE_STAFF)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8,  }} />
            Lịch làm việc {`${schedule.title}`}
          </Row>
        }
      >
        <Space style={{ marginRight: 80}}>
          <div>
          <p>
            <span style={{fontWeight:700}}>Từ:</span>
            <span style={{marginLeft:6, fontWeight:700, color: '#1e4db7'}}>{schedule.start_range ?? ''}</span>
          </p>
          <p >
            <span style={{fontWeight:700}}>Đến:</span>
            <span style={{marginLeft:6, fontWeight:700,color: '#1e4db7'}}>{schedule.end_range ?? ''}</span>
          </p>
          </div>
         
        </Space>
      </TitlePage>

      <ScheduleWeekStaff
        staffShift={staffShift}
        staffShifts={staffShifts}
        schedule={schedule}
        employee={employee}
        shifts={shift}
        roles={roles}
        id={id}
        user={user}
        _getStaffShift={_getStaffShift}
      />

    </div>
  )
}
