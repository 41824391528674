import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION } from 'consts'
// antd
import { Form, notification, Row } from 'antd'
import { createSchedule } from 'apis/schedule-staff'

import { DatePickerRangePicker, FormItem, InputForm } from 'components/Form'
import { Button } from 'components/Button/Button'

import styles from './schedule-staff.module.scss'
import styled from 'styled-components'

import PlusCircleIcon from 'assets/icons/plus-circle.svg'
import ClockIcon from 'assets/icons/clock.svg'
//apis

const FormItemStyled = styled(FormItem)`
  .ant-form-item-control {
    max-width: 277px;
    width: 70%;
  }
`

export default function ScheduleStaffForm({ close, reload }) {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [dates, setDates] = useState(null)
  const [dataDate, setDataDate] = useState([])
  const [startRange, setStartRange] = useState('')
  const [endRange, setEndRange] = useState('')
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const disabledDate = (current) => {
    // Can not select days before today and today
    // return current && current < moment().endOf('day')
    // return current > moment() || current < moment().subtract(30, 'day');
    if (!dates) {
      return false
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7
    return !!tooEarly || !!tooLate
  }

  const handleChangeDay = (range) => {
    if(range){
      let startRange = range[0].format('YYYY-MM-DD')
      let endRange = range[1].format('YYYY-MM-DD')
  
      let dataDay = []
      //Get All day, month, year with EndDay
      var dayStart = range[0].format('DD')
      var monthStart = range[0].format('MM')
      var yearStart = range[0].format('YYYY')
  
      var dayEnd = range[1].format('DD')
      var monthEnd = range[1].format('MM')
      var yearEnd = range[1].format('YYYY')
  
      //Get Last day of month
      function getLastDayOfMonth(year, month) {
        let newDay = new Date(year, month)
        newDay.setDate(newDay.getDate()-1)
        return newDay.getDate()
      }
  
      if (range[0].format('YYYY-MM') === range[1].format('YYYY-MM')) {
        for (let i = parseInt(dayStart); i <= parseInt(dayEnd); i++) {
          if (i < 10) {
            dataDay.push({ day: `${yearStart}-${monthStart}-0${i}` })
          } else dataDay.push({ day: `${yearStart}-${monthStart}-${i}` })
        }
      } else if (range[0].format('YYYY-MM') < range[1].format('YYYY-MM')) {
        for (let i = parseInt(dayStart); i <= getLastDayOfMonth(yearStart, monthStart ); i++) {
          if (i < 10) {
            dataDay.push({ day: `${yearStart}-${monthStart}-0${i}` })
          } else dataDay.push({ day: `${yearStart}-${monthStart}-${i}` })
        }
        for (let j = 1; j <= dayEnd; j++) {
          if (j < 10) {
            dataDay.push({ day: `${yearEnd}-${monthEnd}-0${j}` })
          } else dataDay.push({ day: `${yearEnd}-${monthEnd}-${j}` })
        }
      }
      if (dataDay.length > 7) {
        notification.warning({ message: 'Chọn thời gian trong vòng 7 ngày' })
      } else {
        setDataDate(dataDay)
        setStartRange(startRange)
        setEndRange(endRange)

      }
    }
    
  }

  const onFinish = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const dataForm = form.getFieldsValue()

      const body = {
        ...dataForm,
        title: dataForm.title,
        all_day: dataDate,
        start_range: startRange,
        end_range: endRange,
        branch_id: branchIdApp,
      }
      if (dataDate.length == 0) {
        notification.warning({ message: 'Chọn thời gian trong vòng 7 ngày' })
      } else {
        let res = await createSchedule(body)
        if (res.status === 200) {
          if (res.data.success) {
            reload && reload()
            notification.success({ message: 'Thêm lịch thành công' })
            close()
          } else
            notification.error({
              message: res.data.message,
            })
        } else
          notification.error({
            message: res.data.message || 'Thêm lịch thất bại, vui lòng thử lại!',
          })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  // useEffect(() => {
  //   setDataDate(dataDate)
  // }, [dataDate])

  return (
    <Form onFinish={onFinish} form={form}>
      <Row style={{ width: '100%' }}>
        <FormItemStyled
          className={styles['form-item']}
          label="Tên Lịch Làm"
          name="title"
          rules={[{ required: true, message: 'Vui lòng nhập tên lịch' }]}
          // normalize={(value) => value.trim()}
        >
          <InputForm allowClear placeholder="Nhập tên của lịch" />
        </FormItemStyled>
        <FormItemStyled
          className={styles['form-item']}
          label="Lịch Làm"
          name="day"
          rules={[{ required: true, message: 'Vui lòng điền lịch' }]}
        >
          <DatePickerRangePicker
            showTime
            format={'YYYY-MM-DD'}
            suffixIcon={<img src={ClockIcon} alt="" />}
            className={styles['form-item']}
            onChange={e => handleChangeDay(e)}
            disabledDate={disabledDate}
            value={dataDate}
            onCalendarChange={(val) => setDates(val)}
          />
        </FormItemStyled>
      </Row>
      <Row style={{ width: '100%', marginTop: '30px' }}>
        <FormItem style={{ minWidth: '130px', margin: '0 0 0 auto' }}>
          <Button
            type="submit"
            classes="btn__blue-fill"
            icon={() => <img src={PlusCircleIcon} alt="" />}
            text="Thêm Lịch"
            style={{
              display: 'inline-block',
              padding: '6px 12px',
              fontSize: '14px',
              fontWeight: 500,
            }}
          />
        </FormItem>
      </Row>
    </Form>
  )
}
