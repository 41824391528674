import React, { useState, useEffect,useCallback, } from 'react'

import styles from './sell.module.scss'
import { ModalProductLocation } from '../sell/items/quantityProductModal'
import { SelectImei } from '../sell/items/selectImei'
import { DetailProductModal } from '../sell/items/detailProductModal'
import { formatCash} from 'utils'
import {  useSelector } from 'react-redux'
import { getProducts } from 'apis/product'

import {
  Row,
  Select,
  Tooltip,
  Modal,
  Button,
  Divider,
  Input,
  InputNumber,
  Table,
  Popover,
  Spin,
  Tag,
  notification,
  Col,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
//icons antd
import {
  CloseCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

export const ProductInfo = (props) => {
  const {
    products,
    _editInvoice,
    _removeProductToCartInvoice,
    invoices,
    _editProductInInvoices,
    setProductQuantity,
    _addProductToCartInvoice,
    indexInvoice,
    changeProductOfBranch
  } = props
  const { Paragraph, Text } = Typography
  const [ellipsis] = useState(true)

  const [infoBranch, setInfoBranch] = useState({})
  const { t } = useTranslation()
  // const indexInvoice = useSelector(state => state.indexInvoice)

  const ModalQuantityProductInStores = ({ product, btn }) => {
    const toggle = () => setVisible(!visible)
    const [visible, setVisible] = useState(false)
    const [productAnotherbranch, setProductAnotherBranch] = useState([])

    const column = [
      {
        title: t('sell.branch'),
        dataIndex: 'name',
        render: (text, record) => record.name,
      },
      {
        title: t('sell.quantity'),
        render: (text, record) => formatCash(record.quantity || 0),
      },
    ]

    const content = (
      <div>
        <Row justify="space-between">
          <span>{t('sell.price')}</span>
          <span>{formatCash(product ? product.price : 0)}</span>
        </Row>
        <Row justify="space-between">
          <span>{t('sell.can_be_sold')}</span>
          {product && product.inventory === 'normal' ? (
            <span>{product.is_open === false ? 'Hết hàng' : 'Còn hàng'}</span>
          ) : (
            <span>{formatCash(product ? product.total_quantity : 0)}</span>
          )}
        </Row>
      </div>
    )
    useEffect(() => {
      for (let i in invoices[indexInvoice].order_details) {
        let product = products.find(
          (e) => e.product_id === invoices[indexInvoice].order_details[i].product_id && e.variant_id === invoices[indexInvoice].order_details[i].variant_id
        )
        if (product) _editProductInInvoices('price', product.price, i,indexInvoice)
      }
    }, [products])
    return (
      <div onClick={(e) => e.stopPropagation()}>
        {btn ? (
          <Button
            type="primary"
            onClick={toggle}
            style={{
              cursor: 'pointer',
            }}
          >
            {btn}
          </Button>
        ) : (
          <Popover
            content={content}
            placement="bottom"
            title={
              <Row
                wrap={false}
                justify="space-between"
                align="middle"
                style={{ maxWidth: 450, minWidth: 250 }}
              >
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': '1',
                    '-webkit-box-orient': 'vertical',
                    display: '-webkit-box',
                    marginRight: '10px',
                  }}
                >
                  {product && product.title}
                </p>
              </Row>
            }
          >
            <ExclamationCircleOutlined
              style={{ color: '#1991FF', fontSize: 12, cursor: 'pointer', marginLeft: 6 }}
            />
          </Popover>
        )}

        <Modal
          width={700}
          footer={
            <Row justify="end">
              <Button onClick={toggle}>{t('common.close')}</Button>
            </Row>
          }
          visible={visible}
          onCancel={toggle}
          title={product && product.title}
        >
          <Table
            pagination={false}
            style={{ width: '100%' }}
            columns={column}
            size="small"
            dataSource={productAnotherbranch.locations}
          />
        </Modal>
      </div>
    )
  }
  const ModalDiscountProduct = ({ checkDiscount, countProductVoucher, product, index }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [discount, setDiscount] = useState(product.discount || '')
    const [discount1, setDiscount1] = useState(((product.discount / product.price) * 100).toFixed(2))
    const setDataDiscount = (check, value) => {
      // const valueNew = value.replaceAll(',', '').replaceAll('%', '')
      if (check == true) {
        setDiscount(Math.round(value * 100) / 100)
        setDiscount1(Math.round((Math.round(value * 100) / 100 / product.price) * 100 * 100) / 100)
      } else {
        setDiscount1(Math.round(value * 100) / 100)
        setDiscount((value * product.price) / 100)
      }
    }
    return (
      <>
        <div
          onClick={toggle}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: 'gray',
            cursor: 'pointer',
          }}
        >
          {checkDiscount ? (
            <Text style={{ fontSize: 15 }}>
              {String(
                product.price * product.quantity >=
                  invoices[indexInvoice]?.discount?.order_value_require
                  ? invoices[indexInvoice]?.discount?.discount / countProductVoucher
                  : 0
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
            </Text>
          ) : (
            <Text style={{ fontSize: 15 }}>
              {String(discount).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
            </Text>
          )}
        </div>
        <Modal
          title={product && product.title}
          onCancel={() => {
            toggle()
            setDiscount(product.discount || '')
          }}
          visible={visible}
          footer={[
            discount >= 0 && discount <= product.price ? (
              <Button
                key="cancel"
                onClick={() => {
                  _editProductInInvoices('discount', discount, index,indexInvoice)
                }}
              >
                Áp dụng
              </Button>
            ) : (
              <span style={{ color: 'red' }}>
                Chiết khấu không được vượt quá đơn giá sản phẩm và không nhỏ hơn 0
              </span>
            ),
          ]}
        >
          <Row>
            <Col span={8}>
              <Text>Giá chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                onChange={(value) => {
                  setDataDiscount(true, value)
                }}
                defaultValue={product.discount || 0}
                value={discount}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={8}>
              <Text>Phần trăm chiết khấu</Text>
            </Col>
            <Col span={8} offset={8}>
              <InputNumber
                onChange={(value) => {
                  setDataDiscount(false, value)
                }}
                value={discount1}
                defaultValue={Math.round((product.discount / product.price) * 100 * 100) / 100}
                style={{ width: '100%', marginTop: '-5px' }}
                bordered={false}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                placeholder={t('common.discount')}
              />
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
  const ItemQuantity = ({ product, index }) => {
    return (
      <InputNumber
        key={index}
        onBlur={async (e) => {
          const value = e.target.value.replaceAll('-', '').replaceAll(',', '').replaceAll('.', '')
          if (
            product.inventory === 'advanced' &&
            product.type_advanced === 1 &&
            value > product.total_quantity
          ) {
            notification.error({
              message: `Số lượng nhập vượt quá số lượng tối đa có thể bán của sản phẩm ${product.title}, vui lòng thử lại`,
            })
            return
          }
          if (product.type_advanced === 3) {
            if (value < product?.imei_new?.length) {
              notification.error({
                message: `Số lượng bán đang không bằng số lượng imei được chọn. Vui lòng sửa số lượng của sản phẩm ${product.title}!`,
              })
              return
            }
            if (value > product?.imei_new?.length) {
              notification.error({
                message: `Vui lòng chọn iMei cho sản phẩm ${product.title}!`,
              })
              // return
            }
            if (!product.imei_new) {
              notification.error({
                message: `Vui lòng chọn iMei cho sản phẩm ${product.title}!`,
              })
              return
            }
          } else {
            if (product.type_advanced === 2) {
              if (value > product.total_quantity) {
                notification.error({
                  message: `Số lượng nhập vượt quá số lượng tối đa có thể bán của sản phẩm ${product.title}, vui lòng thử lại`,
                })
              }
              if (product?.locations_new?.length === 0) {
                notification.error({
                  message: `Vui lòng chọn lô cho sản phẩm ${product.title}!`,
                })
              }
              if (!product.locations_new) {
                notification.error({
                  message: `Vui lòng chọn lô cho sản phẩm ${product.title}!`,
                })
              }
            }
          }

          _editProductInInvoices('quantity', +value, index,indexInvoice)
          setProductQuantity([], index)
        }}
        defaultValue={product.quantity}
        className="show-handler-number"
        style={{ width: '100%' }}
        bordered={false}
        max={product.inventory === 'advanced' && product.total_quantity}
        min={product.type_advanced === 1 || product.type_advanced === 0 ? 1 : 0}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        placeholder={t('sell.quantity')}
      />
    )
  }
  const ItemUnit = ({ product, index }) => (
    <Select
      disabled
      showSearch
      onChange={(value) => {
        _editProductInInvoices('unit', value, index,indexInvoice)
        if (value) {
          if (product.units) {
            const variantFind = product.units.find((e) => e.name == value)
            if (variantFind) _editProductInInvoices('price', +variantFind.price, index,indexInvoice)
          }
        }
      }}
      defaultValue={product.unit || undefined}
      style={{ width: '100%' }}
      placeholder={t('common.unit')}
      bordered={false}
    >
      {product.units ? (
        product.units.map((unit, index) => (
          <Select.Option key={index} value={unit.name}>
            {unit.name}
          </Select.Option>
        ))
      ) : (
        <Select.Option value={t('common.peace')}>{t('common.peace')}</Select.Option>
      )}
    </Select>
  )
  const ModalNoteProduct = ({ product, index }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [note, setNote] = useState(product.note)

    return (
      <>
        <div
          onClick={toggle}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: 'gray',
            cursor: 'pointer',
          }}
        >
          <Popover
            content={
              note ? (
                <Row wrap={false} justify="space-between" align="middle">
                  <Paragraph
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                      maxWidth: 450,
                      width: 450,
                    }}
                  >
                    {note}
                  </Paragraph>
                </Row>
              ) : (
                ''
              )
            }
            style={{ maxWidth: 450 }}
            placement="bottomLeft"
          >
            <Text
              style={ellipsis ? { width: 50 } : undefined}
              className={styles['sell-product__item-note']}
            >
              {note || 'Ghi chú'}
            </Text>
          </Popover>

          <EditOutlined style={{ marginLeft: 5 }} />
        </div>
        <Modal
          title={product && product.title}
          onCancel={() => {
            toggle()
            setNote(product.note || '')
          }}
          onOk={() => {
            _editProductInInvoices('note', note, index,indexInvoice)
          }}
          visible={visible}
        >
          <div>
            {t('common.note')}
            <Input.TextArea
              onChange={(e) => setNote(e.target.value)}
              defaultValue={note}
              placeholder={t('common.enter_a_note')}
              rows={4}
              style={{ width: '100%' }}
            />
          </div>
        </Modal>
      </>
    )
  }
  const RemoveItemLocation = useCallback((product, e, index) => {
    const indexProduct1 = invoices[indexInvoice].order_details.findIndex(
      (e) => e.variant_id === product?.variant_id
    )
    let new_location = [...product.locations_new]
    new_location.splice(index, 1)

    setProductQuantity(new_location)
    _editProductInInvoices(
      'quantity',
      new_location.reduce((total, item) => (total += item.quantity), 0),
      indexProduct1,
      indexInvoice
    )
    _editProductInInvoices('locations_new', new_location, indexProduct1,indexInvoice)
  }, [])
  const RemoveItemImei = useCallback((product, e, index) => {
    let invoiceNew = JSON.parse(JSON.stringify(invoices[indexInvoice].order_details))
    const indexProduct1 = invoiceNew.findIndex(
      (e) => e.variant_id === product?.variant_id
    )
    let new_imei = JSON.parse(JSON.stringify(product.imei_new))
    new_imei.splice(index, 1)
    if (new_imei.length === 0) {
      // _editProductInInvoices('quantity', 0, indexProduct1,indexInvoice)
      _editProductInInvoices('imei_new', new_imei, indexProduct1,indexInvoice)
    } else {
      // _editProductInInvoices('quantity', new_imei.length, indexProduct1,indexInvoice)
      _editProductInInvoices('imei_new', new_imei, indexProduct1,indexInvoice)
    }
  }, [indexInvoice])

  const RenderRowTypeAdvancedLocation = useCallback(({ product, index }) => {
    switch (product.type_advanced) {
      case 2:
        return (
          <ModalProductLocation
            product={product}
            variant_id={product.variant_id}
            index={index}
            ellipsis={ellipsis}
            _editProductInInvoices={_editProductInInvoices}
            setProductQuantity={setProductQuantity}
          />
        )
      case 3:
        return (
          <SelectImei
            variant_id={product.variant_id}
            index={index}
            _editProductInInvoices={_editProductInInvoices}
            imei_new={product.imei_new}
          />
        )
      default:
        return <></>
    }
  }, [])
  const RenderRowTypeAdvanced = useCallback(({ product, index }) => {
  switch (product.type_advanced) {
      case 2:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product?.locations_new?.length > 0 ? (
              product.locations_new.map((e, index) => (
                <div style={{ display: 'flex' }}>
                  {e.quantity > 0 ? (
                    <Tag
                      style={{
                        padding: 4,
                        marginBottom: 4,
                        backgroundColor: '#cccc',
                        borderRadius: 4,
                      }}
                    >
                      {e.code} | {e.exp} | SL: <strong>{e.quantity}</strong>
                      <CloseCircleTwoTone
                        style={{ marginLeft: 4 }}
                        onClick={() => RemoveItemLocation(product, e, index)}
                      />
                    </Tag>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <p style={{ marginLeft: 20, color: 'red', marginBottom: 0, fontSize: 12 }}>
                Sản phẩm chưa có lô, vui lòng chọn...
              </p>
            )}
          </div>
        )
      case 3:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product?.imei_new?.length > 0 ? (
              product.imei_new.map((e, index_item) => (
                <div style={{ display: 'flex' }}>
                  <Tag
                    style={{
                      padding: 4,
                      marginBottom: 4,
                      backgroundColor: '#cccc',
                      borderRadius: 4,
                    }}
                  >
                    <strong>{e}</strong>
                    <CloseCircleTwoTone
                      style={{ marginLeft: 4 }}
                      onClick={() => RemoveItemImei(product, e, index_item)}
                    />
                  </Tag>
                </div>
              ))
            ) : (
              <p style={{ marginLeft: 20, color: 'red', marginBottom: 0, fontSize: 12 }}>
                Sản phẩm chưa có iMei, vui lòng chọn...{' '}
              </p>
            )}
          </div>
        )
      default:
        return <></>
    }
  }, [indexInvoice])
  const ModalSkuProduct = ({ product, index }) => {
    const [visible, setVisible] = useState(false)

    const [sku, setSku] = useState(product.sku || '')
    const [variant, setVariant] = useState(null)
    const [variants, setVariants] = useState([])
    const [loading, setLoading] = useState(false)

    const toggle = () => {
      setVisible(!visible)
      setSku(product.sku || '')
    }

    const _getVariantsByProductId = async () => {
      try {
        setLoading(true)
        const res = await getProducts({
          branch_id: infoBranch.branch_id || '',
          merge: true,
          detach: true,
          product_id: product.product_id,
        })
        if (res.status === 200) {
          const variantList = res.data.data.map((e) => e.variants)
          setVariants(variantList.filter((variant) => variant.total_quantity))
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    const _updateProductInCart = () => {
      if (variant) {
        let productsNew = invoices[indexInvoice].order_details
        productsNew[index] = {
          ...variant,
          imei_new: [],
          unit: variant.units && variant.units.length ? variant.units[0].name : 'Cái', //đơn vị
          price: variant.price, //giá sản phẩm
          quantity: 1, //số lượng sản phẩm
          sumCost: variant.price, // tổng giá tiền
          tax_product:
            variant._taxes && variant._taxes.length
              ? (
                  (variant._taxes.reduce((total, current) => total + current.value, 0) / 100) *
                  variant.price
                ).toFixed(0)
              : 0,
        }

        _editInvoice('order_details', [...productsNew])
      }

      setVisible(false)
    }

    useEffect(() => {
      if (visible) {
        _getVariantsByProductId()
      }
    }, [visible])

    return (
      <>
        <Tooltip title={product.sku}>
          <a
            style={{ marginLeft: 8 }}
            className={styles['sell-product__item-sku']}
            onClick={toggle}
          >
            {product.sku}
          </a>
        </Tooltip>
        <Modal
          cancelText={t('common.cancel')}
          okText={t('common.update')}
          title={t('common.update_attributes')}
          onCancel={() => {
            toggle()
            setSku(product.sku || '')
          }}
          onOk={_updateProductInCart}
          visible={visible}
        >
          <div>
            {t('common.attribute_name')}
            <Select
              loading={loading}
              showSearch
              optionFilterProp="children"
              value={sku}
              onChange={(value) => {
                const skuProduct = invoices[indexInvoice].order_details.find((v) => v.sku === value)
                if (skuProduct)
                  notification.warning({
                    message: t('sell.already_in_the_cart_choose_another'),
                  })
                else {
                  setSku(value)
                  const variantFind = variants.find((e) => e.sku === value)
                  setVariant(variantFind)
                }
              }}
              placeholder={t('common.select_property_name')}
              style={{ width: '100%' }}
            >
              {variants.map((variant, index) => (
                <Select.Option value={variant.sku || ''} key={index}>
                  {variant.sku || ''}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        wrap={false}
        className={styles['sell-product-header']}
      >
        <Col span={1} className={styles['header-stt']}>
          {t('common.no')}
        </Col>
        <Col span={6} className={styles['header-name']}>
          {t('common.product_s_name')}
        </Col>
        <Col span={3} className={styles['header-unit']}>
          {t('common.unit')}
        </Col>
        <Col span={3} className={styles['header-quantity']}>
          {t('sell.quantity')}
        </Col>
        <Col span={3} className={styles['header-price']}>
          {t('common.unit_price')}
        </Col>
        <Col span={3} className={styles['header-price']}>
          {t('common.discount')}
        </Col>
        <Col span={2} className={styles['header-sum-price']}>
          {t('common.total_payment')}
        </Col>
      </Row>
      <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
        {invoices[indexInvoice]?.order_details && invoices[indexInvoice].order_details.map((product, index) => {
          return (
            <Row key={index}>
              <Row
                align="middle"
                justify="space-between"
                wrap={false}
                className={styles['sell-product__item']}
                id={`variant_${product.variant_id}`}
              >
                <Col span={1}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 15,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 0,
                        width: 30,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          width: 30,
                          marginRight: 4,
                        }}
                      >
                        <DeleteOutlined
                          onClick={() => _removeProductToCartInvoice(index)}
                          style={{ color: 'red', marginRight: 15, cursor: 'pointer' }}
                        />
                        <DetailProductModal
                          product={product}
                          addProductToCartInvoice={_addProductToCartInvoice}
                          changeProductOfBranch={changeProductOfBranch}
                        />
                      </div>
                      {index + 1}
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <Row wrap={false} align="middle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          // flexDirection: 'column',
                        }}
                      >
                        <span className={styles['sell-product__item-name']}>{product.title}</span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minWidth: 180,
                          maxWidth: 180,
                          textAlign: 'center',
                        }}
                      >
                        <ModalQuantityProductInStores
                          style={{ marginRight: 8 }}
                          product={product}
                        />

                        <ModalSkuProduct product={product} index={index} />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ModalNoteProduct product={product} index={index} />
                      </div>
                    </div>
                    <RenderRowTypeAdvancedLocation product={product} index={index} />
                    {invoices[indexInvoice]?.discount?.apply_product_ids?.find(
                      (item) => item == product.variant_id
                    ) && (
                      <Tag color="geekblue" style={{ borderRadius: 5 }}>
                        {invoices[indexInvoice]?.discount?.promotion_code || ''}
                      </Tag>
                    )}
                  </Row>
                </Col>
                <Col span={3} className={styles['sell-product__item-unit']}>
                  <ItemUnit product={product} index={index} />
                </Col>
                <Col span={3} className={styles['sell-product__item-unit']} >
                  <ItemQuantity product={product} index={index} />
                </Col>
                <Col align="middle" span={3} className={styles['sell-product__item-unit']}>
                  <span>{formatCash(product.price)}</span>
                </Col>
                <Col span={3} className={styles['sell-product__item-unit']}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spin
                        indicator={null}
                        spinning={
                          invoices[indexInvoice]?.discount?.apply_product_ids?.find(
                            (item) => item == product.variant_id
                          )
                            ? true
                            : false
                        }
                      >
                        <ModalDiscountProduct
                          checkDiscount={invoices[indexInvoice]?.discount?.apply_product_ids?.find(
                            (item) => item == product.variant_id
                          )}
                          countProductVoucher={
                            invoices[indexInvoice]?.discount?.countProductVoucher
                          }
                          product={product}
                          index={index}
                        />
                      </Spin>
                    </div>
                  </div>
                </Col>
                <Col span={2} style={{ marginBottom: 0, fontWeight: 600, textAlign: 'right' }}>
                  {product.type_advanced === 3
                    ? formatCash(product.quantity * product.price)
                    : formatCash(product.sumCost)}{' '}
                </Col>
              </Row>
              <Row key={index} className={styles['row_bonus']}>
                <RenderRowTypeAdvanced product={product} index={index} />
              </Row>
            </Row>
          )
        })}
      </div>
    </>
  )
}
