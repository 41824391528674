import React, { useState, useEffect, useRef } from 'react'
import styles from './product.module.scss'
import moment from 'moment'
import { ACTION, ROUTES, PERMISSIONS, regexRemoveUnicodeNoNumber } from 'consts'
import {  formatCash, removeAccents, removeUnicode } from 'utils'
import { useHistory, Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import delay from 'delay'
//components
import NotSupportMobile from 'components/not-support-mobile'
import TitlePage from 'components/title-page'
import Permission from 'components/permission'
import CreateCategory from 'views/category-create'
import ModalPicture from './modal-picture'
import { socket } from '../../socket'
import ConnectLink from './connect-link.js'
import { ModalPrice } from './items/modal-price'
import { ModalVariant} from './items/modalVariant'
//antd
import {
  Button,
  Table,
  Input,
  Form,
  Row,
  Col,
  InputNumber,
  Select,
  Upload,
  notification,
  Popconfirm,
  Tooltip,
  Space,
  Modal,
  TreeSelect,
  Badge,
  Drawer,
  Divider,
  Radio,
  Switch,
  Tag,
  Spin,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
  EditOutlined,
  FileImageOutlined,
  DollarOutlined,
  ReloadOutlined,
  CloseCircleFilled,
  UploadOutlined,
} from '@ant-design/icons'

//apis
import { getCategories } from 'apis/category'
import { getSuppliers } from 'apis/supplier'
import { uploadFiles, uploadFile } from 'apis/upload'
import { getWarranties } from 'apis/warranty'
import {
  getChangePriceHistory,
  updateProduct,
  getOneProduct,
  updateProductOnline,
} from 'apis/product'
import { getInventories } from 'apis/inventory'
import { _getProductChange } from '../../components/Product/getProductChange'
export default function ProductAdd() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const typingTimeoutRef = useRef(null)
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const { sku } = useParams()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [imageBig, setImageBig] = useState([])
  const [modalDefaultImg, setModalDefaultImg] = useState(null)
  const toogleModalPicture = () => {
    setImageBig([])
    setIsModalVisible(!isModalVisible)
  }

  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingImageCount, setLoadingImageCount] = useState(0)
  const [visibleListProduct, setVisibleListProduct] = useState(false)
  const [keyTab, setKeyTab] = useState('1')
  const [isRenderFirst, setIsRenderFirst] = useState(false)
  const [files, setFiles] = useState([])
  const [idsWarranty, setIdsWarranty] = useState([''])
  const [warranties, setWarranties] = useState([])
  const [attributes, setAttributes] = useState([{ option: '', values: [] }])
  const [variants, setVariants] = useState([])
  const [selectRowKeyVariant, setSelectRowKeyVariant] = useState([])
  const [imagesPreviewProduct, setImagesPreviewProduct] = useState([]) //url image
  const [isMobile, setIsMobile] = useState(false)
  const [description, setDescription] = useState('')
  const [suppliers, setSuppliers] = useState([])
  const [supplier, setSupplier] = useState('') // dung o variant
  const [imagesProduct, setImagesProduct] = useState(['', '', '', '', '', '', '', '', ''])
  const [loadingFile, setLoadingFile] = useState(false)

  const [coverimageProduct, setCoverImageProduct] = useState([])
  const [loadingChangePriceHistory, setLoadingChangePriceHistory] = useState(false)
  const [changePriceHistory, setChangePriceHistory] = useState([])
  const [skuProductWithEdit, setSkuProductWithEdit] = useState('')
  const [productDetail, setProductDetail] = useState()
  const [isModeUpdate, setModeUpdate] = useState(false)
  const [bulkPrices, setBulkPrices] = useState([
    { min_quantity_apply: 1, max_quantity_apply: 999, price: 0 },
  ]) //giá sỉ
  const [loadInfoImage, setLoadInfoImage] = useState(false)
  const [loadInfoAnother, setLoadInfoAnother] = useState(false)
  const [loadHistory, setLoadHistory] = useState(false)
  const [loadHistory2, setLoadHistory2] = useState(false)
  const [inventoryCheck, setInventoryCheck] = useState()
  const [typeAdvanced, setTypeAdvanced] = useState()
  const [inventory, setInventory] = useState([])
  const toggleDrawerListProduct = () => {
    setVisibleListProduct(!visibleListProduct)
  }
  const [loadingImageVariant, setLoadingImageVariant] = useState(false)

  const addAttribute = () => {
    let attributesNew = [...attributes]
    attributesNew.push({ option: '', values: [] })
    setAttributes([...attributesNew])
  }

  const removeAttribute = (index) => {
    let attributesNew = [...attributes]
    attributesNew.splice(index, 1)
    setAttributes([...attributesNew])
  }
  const uploadVideoProduct = async (file) => {
    if (file.type !== 'video/mp4') {
      return notification.error({ message: 'Video phải có định dạng MP4' })
    }
    if (file.size > 1024 * 1024 * 25) {
      return notification.error({ message: 'Video không được quá 30MB' })
    }
    setLoadingFile(true)
    const url = await uploadFile(file)
    setLoadingFile(false)
    const filesNew = [...files]
    filesNew.push(url)
    setFiles([...filesNew])
  }
  // const checkPermissionViewDetail = () => {
  //   const token = localStorage.getItem('accessToken')
  //   const context = token && jwt_decode(token)
  //   const allPermission = [...context.data._role.menu_list, ...context.data._role.permission_list]
  //   if (!allPermission.includes(PERMISSIONS.chi_tiet_san_pham)) {
  //     message.warning('Bạn không có quyền xem chi tiết sản phẩm', 2)
  //     history.replace(ROUTES.OVERVIEW)
  //   }
  // }

  const addValueVariant = () => {
    let variantsNew = []

    const dataForm = form.getFieldsValue()

    const initVariant = {
      image: imagesProduct || [],
      price: dataForm.price || 0,
      bulk_price: dataForm.price || 0,
      whole_sale_price: dataForm.whole_sale_price,
      import_price: dataForm.import_price || 0,
      enable_bulk_price: true,
      quantity: 0,
    }
    if (!dataForm) return
    if (attributes.length !== 0) {
      //trường hợp chỉ có 1 attribute
      if (attributes.length === 1) {
        attributes[0].values.map((value, index) => {
          variantsNew.push({
            title: `${(dataForm.name || '').toUpperCase()} ${String(value).toUpperCase()}`,
            sku: `${dataForm.sku || ''}-${String(value).toUpperCase()}`,
            options: [{ name: attributes[0].option, value: String(value) }],
            ...initVariant,
          })
        })
      } else if (attributes.length === 2) {
        //trường hợp có 2 attribute
        if (!attributes[1].values.length) {
          attributes[1].values.map((value) => {
            variantsNew.push({
              title: `${(dataForm.name || '').toUpperCase()} ${String(value).toUpperCase()}`,
              sku: `${dataForm.sku || ''}-${String(value).toUpperCase()}`,
              options: [{ name: attributes[1].option, value: String(value) }],
              ...initVariant,
            })
          })
        }

        if (!attributes[0].values.length) {
          attributes[0].values.map((value) => {
            variantsNew.push({
              title: `${(dataForm.name || '').toUpperCase()} ${String(value).toUpperCase()}`,
              sku: `${dataForm.sku || ''}-${String(value).toUpperCase()}`,
              options: [{ name: attributes[0].option, value: String(value) }],
            })
          })
        }

        if (attributes[0].values.length && attributes[1].values.length)
          attributes[0].values.map((v0) => {
            attributes[1].values.map((v1) => {
              variantsNew.push({
                title: `${(dataForm.name || '').toUpperCase()} ${String(v0).toUpperCase()} ${String(
                  v1
                ).toUpperCase()}`,
                sku: `${dataForm.sku || ''}-${String(v0).toUpperCase()}-${String(
                  v1
                ).toUpperCase()}`,
                options: [
                  { name: attributes[0].option, value: String(v0) },
                  { name: attributes[1].option, value: String(v1) },
                ],
                ...initVariant,
              })
            })
          })
      } else if (attributes.length === 3) {
        attributes[0].values.map((v0) => {
          attributes[1].values.map((v1) => {
            attributes[2].values.map((v2) => {
              variantsNew.push({
                title: `${(dataForm.name || '').toUpperCase()} ${String(v0).toUpperCase()} ${String(
                  v1
                ).toUpperCase()} ${String(v2).toUpperCase()}`,
                sku: `${dataForm.sku || ''}-${String(v0).toUpperCase()}-${String(
                  v1
                ).toUpperCase()}-${String(v2).toUpperCase()}`,
                options: [
                  { name: attributes[0].option, value: String(v0) },
                  { name: attributes[1].option, value: String(v1) },
                  { name: attributes[2].option, value: String(v2) },
                ],
                ...initVariant,
              })
            })
          })
        })
      }
    }

    variantsNew = variantsNew.map((e) => {
      const variantCurrent = variants.find((v) => v.title === e.title)
      if (variantCurrent) return variantCurrent
      else return e
    })

    setVariants([...variantsNew])
  }

  const _updateProductOnline = async (body, id) => {
    try {
      let res = await updateProductOnline(body, id)

      if (res.status === 200) {
        if (res.data.success) {
          initProductWithEditProduct()
          notification.success({ message: 'Cập nhật thành công!', duration: 1 })
        } else
          notification.error({
            message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
    }
  }

  const [checkQuantity, setCheckQuantity] = useState(false)

  const _getSuppliers = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getSuppliers()
      if (res.status === 200) {
        setSuppliers(res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const addOrUpdateProduct = async () => {
    if (isModeUpdate) {
      const formProduct = form.getFieldsValue()

      await form.validateFields()
      if (variants.length === 0) {
        notification.error({ message: 'Vui lòng nhập ít nhất một thuộc tính' })
        return
      }

      //validated, prices
      for (let i = 0; i < variants.length; ++i)
        if (!variants[i].price) {
          variants[i].price = formProduct.price
        }

      try {
        dispatch({ type: ACTION.LOADING, data: true })
        let body = {
          sku: formProduct.sku,
          whole_sale_price: formProduct.whole_sale_price || 0,
          import_price: formProduct.import_price || 0,
          barcode: formProduct.barcode,
          name: formProduct.name,
          category_id: formProduct.category_id,
          supplier_id: formProduct.supplier_id,
          length: formProduct.length || '',
          width: formProduct.width || '',
          height: formProduct.height || '',
          weight: formProduct.weight || '',
          unit: formProduct?.unit?.trim() || '',
          inventory: formProduct.inventory,
          files: files || [],
          warranties: idsWarranty,
          description: description ? description.split('&nbsp;').join('') : '',
          images: imagesProduct || [],
          cover_image: coverimageProduct || [],
          price_recipe: formProduct.price_recipe || 'FIFO',
          type_advanced: Number(formProduct.type_advanced),
          branch_id: branchIdApp,
        }
        body.attributes = attributes

        const variantsNew = variants.map((v) => ({ ...v, supplier: supplier || '' }))
        body.variants = variantsNew
        if (checkQuantity === true) {
          dispatch({ type: ACTION.LOADING, data: false })
          notification.error({
            message: 'Số lượng tối đa lớn hơn hoặc bằng số lượng tối thiểu!',
          })
          return
        }
        let res = await updateProduct(body, productDetail.product_id)
        if (res.status === 200) {
          notification.success({
            message: `Cập nhật sản phẩm thành công!`,
          })
          initProductWithEditProduct()

          setModeUpdate(!isModeUpdate)
          // socketListener()
        }
        dispatch({ type: ACTION.LOADING, data: false })
      } catch (error) {
        console.log(error)
        dispatch({ type: ACTION.LOADING, data: false })
      }
    } else {
      setModeUpdate(true)
    }
  }

  const [categories, setCategories] = useState([])
  const _getCategories = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getCategories()
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) {
          const category = res.data.data.find((category) => category.active && category.default)
          if (!sku) {
            if (category) form.setFieldsValue({ category_id: [category.category_id] })
            else form.setFieldsValue({ category_id: [res.data.data[0].category_id] })
          }
          setCategories(res.data.data.filter((e) => e.active))
        }
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  /* list input variants */
  const uploadImage = async (file, indexVariant) => {
    try {
      setLoadingImageVariant({ index: indexVariant, loading: true })

      let variantsNew = [...variants]

      const url = await uploadFile(file)
      if (url) variantsNew[indexVariant].image = [url]

      setVariants([...variantsNew])
      setLoadingImageVariant({ index: indexVariant, loading: false })
    } catch (error) {
      console.log(error)
      setLoadingImageVariant({ index: indexVariant, loading: false })
    }
  }

  const UploadImageProduct = ({ variant }) => (
    <Upload
      name="avatar"
      listType="picture-card"
      className="upload-variant-image"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      data={(file) => {
        const isJpgOrPng =
          file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'

        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJpgOrPng) {
          notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
        }

        if (!isLt2M) {
          notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
        }

        if (isJpgOrPng && isLt2M) {
          const indexVariant = variants.findIndex((ob) => ob.title === variant.title)
          if (indexVariant !== -1) uploadImage(file, indexVariant)
        }
      }}
    >
      {variant.image && variant.image.length ? (
        <img
          src={variant.image[0] || ''}
          alt=""
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      ) : (
        <PlusOutlined />
      )}
    </Upload>
  )

  const columnsHistoryBulkPrices = [
    {
      title: 'Mã phiếu',
      render: (text, record) => (
        <Link
          target="_blank"
          to={{ pathname: ROUTES.PRICE_ADJUSTMENTS_UPDATE + '/' + record.code }}
        >
          {'#' + record.code}
        </Link>
      ),
    },
    {
      title: 'Tên phiên bản',
      render: (text, record) => record?.variant_info.title ,
    },
    {
      title: 'Giá lẻ trước',
      render: (text, record) => formatCash(record.from_price || 0),
    },
    {
      title: 'Chêch lệch',
      render: (text, record) => formatCash((record.to_price || 0) - (record.from_price || 0)),
    },
    {
      title: 'Nhân viên',
      render: (text, record) =>
        record?.creator_info?.first_name + ' ' + record?.creator_info?.last_name,
    },
    {
      title: 'Giá lẻ điều chỉnh',
      render: (text, record) => formatCash(record.to_price || 0),
    },
    {
      title: 'Ngày ghi nhận',
      render: (text, record) =>
        record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
    },
    {
      title: 'Giá bán sỉ',
      render: (text, record, index) => <HistoryBulkPrices variant={record} />,
    },
  ]

  const _type = (Object) => {
    let name = ''
    if (Object.type === 'export') name = 'Bán hàng'
    if (Object.type === 'balance-export-product') name = 'Cân bằng kho'
    if (Object.type === 'balance-import-product') name = 'Cân bằng kho'
    if (Object.type === 'balance-export-product-advanced') name = 'Cân bằng kho'
    if (Object.type === 'balance-import-product-advanced') name = 'Cân bằng kho'
    if (Object.type === 'transport-import-product') name = 'Nhận hàng'
    if (Object.type === 'transport-export-product') name = 'Chuyển hàng'
    if (Object.type === 'import') name = 'Nhập hàng'
    if (Object.type === 'import-refund') name = 'Trả hàng'
    if (Object.type === 'import-smart') name = 'Nhập sản phẩm nhanh'

    return name
  }
  const columnsInventory = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã xuất nhập',
      render: (text, record, index) => record.code,
    },
    {
      title: 'SKU',
      render: (text, record) => record?.variant_info?.sku,
    },
    {
      title: 'Tên phiên bản',
      render: (text, record) => record?.variant_info?.title,
    },
    {
      title: 'Thao tác',
      render: (text, record) => _type(record),
    },
    {
      title: 'Số lượng thay đổi',
      width: 150,
      render: (text, record) => {
        if (record.import_quantity - record.export_quantity > 0)
          return (
            <div style={{ textAlign: 'center' }}>
              +{record.import_quantity - record.export_quantity}
            </div>
          )
        else
          return (
            <div style={{ textAlign: 'center' }}>
              {record.import_quantity - record.export_quantity}
            </div>
          )
      },
    },
    {
      title: 'iMei xuất nhập',
      // width: 300,
      render: (text, record) => (
        <div>{record.type_advanced === 3 ? record.imei.map((e) => <Tag>{e}</Tag>) : ''}</div>
      ),
    },
    {
      title: 'Lô xuất nhập',
      // width: 300,
      render: (text, record) =>
        record.type_advanced === 2 ? (
          <Tag>
            {record.mfg} - {record.exp}
          </Tag>
        ) : (
          ''
        ),
    },
    {
      title: 'Tồn kho',
      width: 110,

      render: (text, record) => record.quantity,
    },
    {
      title: 'Mã chứng từ',
      width: 210,

      render: (text, record) => (
        <ConnectLink permissions={[PERMISSIONS.chi_tiet_don_hang]} record={record} />
      ),
    },
    {
      title: 'Nhân viên',
      width: 180,

      render: (text, record) =>
        record?.creator_info?.first_name + ' ' + record.creator_info?.last_name,
    },
    {
      title: 'Ngày ghi nhận',
      render: (text, record) => moment(record.create_date).format('DD-MM-YYYY, HH:mm'),
    },
  ]
  const _getChangePriceHistory = async (id) => {
    try {
      setLoadingChangePriceHistory(true)
      const res = await getChangePriceHistory({
        product_id: id,
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        if (res.data.data?.length > 0) {
          setLoadHistory(true)
        }
        setChangePriceHistory(res.data.data)
      }
      setLoadingChangePriceHistory(false)
    } catch (error) {
      setLoadingChangePriceHistory(false)
    }
  }
  const _getInventories = async (id) => {
    try {
      dispatch({type: ACTION.LOADING, data: true})
      const res = await getInventories({
        product_id: id,
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        if (res.data.data.length > 0) {
          setLoadHistory2(true)
        }
        setInventory(res.data.data)
      }
      dispatch({type: ACTION.LOADING, data: false})
    } catch (error) {
      dispatch({type: ACTION.LOADING, data: false})
      console.log(error);
    }
  }

  const ModalAddGuarantee = ({ disabled = false }) => {
    return (
      <>
        <Tooltip title="Thêm chính sách bảo hành">
          <Link target="_blank" to={'/guarantee'}>
            <Button
              style={{ marginLeft: 10 }}
              size="large"
              type="primary"
              icon={<PlusOutlined />}
            />
          </Link>
        </Tooltip>
      </>
    )
  }

  const newData = (value) => {
    value.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/g,
      ''
    )
    return value
  }

  const InputSku = ({ value, variant }) => {
    const [valueSku, setValueSku] = useState(value)

    return (
      <Input
        disabled={
          isModeUpdate
            ? productDetail?.variants?.find((item) => item.sku === value)
              ? true
              : false
            : true
        }
        placeholder="Nhập mã phiên bản"
        size="large"
        value={valueSku}
        defaultValue={value}
        onBlur={() => {
          let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
          if (regex.test(valueSku)) {
            return notification.error({ message: 'Không được nhập kí tự đặc biệt ở mã phiên bản' })
          } else {
            let variantsNew = [...variants]
            const index = variantsNew.findIndex((e) => e.title === variant.title)
            variantsNew[index].sku = newData(valueSku)
            setVariants([...variantsNew])
          }
        }}
        onChange={(e) => setValueSku(e.target.value)}
        style={{ width: '100%' }}
      />
    )
  }
  const InputQuantity = ({ value, variant }) => {
    const [valueQuantity, setValueQuantity] = useState(value)

    return (
      <Input
        disabled={true}
        style={{ width: '100%' }}
        placeholder="Nhập số lượng"
        className="br-15__input"
        size="large"
        defaultValue={formatCash(value)}
        min={0}
        onBlur={() => {
          let variantsNew = [...variants]
          const index = variantsNew.findIndex((e) => e.sku === variant.sku)
          variantsNew[index].quantity = valueQuantity
          setVariants([...variantsNew])
        }}
        onInput={(e) => setValueQuantity(e.target.value.replace(/[^\d]/g, ''))}
      />
    )
  }

  const UploadAllVariant = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [image, setImage] = useState('')
    const [loading, setLoading] = useState(false)

    const upload = () => {
      if (image !== '') {
        const variantsNew = [...variants]

        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)
          variantsNew[indexVariant].image = [image]
        })

        setVariants([...variantsNew])

        toggle()
      } else {
        notification.warning({ message: 'Vui lòng chọn 1 ảnh!' })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) setImage('')
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<FileImageOutlined />}>
          Chỉnh sửa ảnh
        </Button>
        <Modal
          visible={visible}
          title="Chọn ảnh"
          onCancel={toggle}
          onOk={upload}
          footer={
            <Row justify="end">
              <Space>
                <Button onClick={() => setVisible(false)}>Đóng</Button>
                <Button loading={loading} type="primary" onClick={upload}>
                  Lưu
                </Button>
              </Space>
            </Row>
          }
        >
          <Upload
            name="avatar"
            fileList={[]}
            listType="picture-card"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            data={async (file) => {
              const isJpgOrPng =
                file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'

              const isLt2M = file.size / 1024 / 1024 < 2

              if (!isJpgOrPng) {
                notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
              }

              if (!isLt2M) {
                notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
              }

              if (isJpgOrPng && isLt2M && file) {
                setLoading(true)
                const url = await uploadFile(file)
                if (url) setImage(url || '')
                setLoading(false)
              }
            }}
          >
            {image ? <img src={image} alt="" style={{ width: '100%' }} /> : <PlusOutlined />}
          </Upload>
        </Modal>
      </>
    )
  }
  const EditSku = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [valueSku, setValueSku] = useState('')

    const edit = () => {
      if (valueSku) {
        let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
        if (regex.test(valueSku)) {
          return notification.error({
            message: 'Không được nhập kí tự đặc biệt ở mã phiên bản ',
          })
        }
        let variantsNew = [...variants]

        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)
          variantsNew[indexVariant].sku = valueSku
        })

        setVariants([...variantsNew])
        toggle()
      } else {
        return notification.error({
          key: 'error invalid variant',
          message: 'Vui lòng nhập mã phiên bản!',
        })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) setValueSku('')
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<EditOutlined />}>
          Chỉnh sửa sku
        </Button>
        <Modal visible={visible} onCancel={toggle} onOk={edit} title="Nhập sku">
          <Input
            placeholder="Nhập sku"
            size="large"
            value={valueSku}
            onChange={(e) => setValueSku(e.target.value)}
            style={{ width: '100%' }}
          />
        </Modal>
      </>
    )
  }

  const EditAttribute = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [valueAttribute, setValueAttribute] = useState('')

    const edit = () => {
      if (valueAttribute) {
        let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
        if (regex.test(valueAttribute)) {
          return notification.error({
            message: 'Không được nhập kí tự đặc biệt ở tên thuộc tính',
          })
        }
        let variantsNew = [...variants]
        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)
          variantsNew[indexVariant].title = valueAttribute
        })

        setVariants([...variantsNew])
        toggle()
      } else {
        return notification.error({
          key: 'error invalid variant',
          message: 'Vui lòng nhập tên thuộc tính!',
        })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) setValueAttribute('')
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<EditOutlined />}>
          Chỉnh sửa tên thuộc tính
        </Button>
        <Modal visible={visible} onCancel={toggle} onOk={edit} title="Nhập tên thuộc tính">
          <Input
            placeholder="Nhập tên thuộc tính"
            size="large"
            value={valueAttribute}
            onChange={(e) => setValueAttribute(e.target.value)}
            style={{ width: '100%' }}
          />
        </Modal>
      </>
    )
  }

  const EditPrice = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [valueSalePrice, setValueSalePrice] = useState('')

    const edit = () => {
      if (valueSalePrice) {
        let variantsNew = [...variants]

        selectRowKeyVariant.map((key) => {
          const indexVariant = variantsNew.findIndex((ob) => ob.title === key)

          variantsNew[indexVariant].price = valueSalePrice
        })

        setVariants([...variantsNew])

        toggle()
      } else {
        return notification.error({
          key: 'error invalid variant',
          message: 'Vui lòng nhập giá bán!',
        })
      }
    }

    //reset
    useEffect(() => {
      if (!visible) {
        setValueSalePrice('')
      }
    }, [visible])

    return (
      <>
        <Button size="large" onClick={toggle} icon={<DollarOutlined />}>
          Chỉnh sửa giá
        </Button>
        <Modal visible={visible} onCancel={toggle} onOk={edit} title="Nhập giá">
          <Space size="middle" direction="vertical">
            <div>
              <span style={{ marginBottom: 0 }}>Giá bán</span>
              <InputNumber
                placeholder="Nhập giá bán"
                className="br-15__input"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                size="large"
                min={0}
                onChange={(value) => setValueSalePrice(value)}
                style={{ width: '100%' }}
              />
            </div>
          </Space>
        </Modal>
      </>
    )
  }

  const HistoryBulkPrices = ({ variant }) => (
    <Space direction="vertical">
      {variant.bulk_prices &&
        variant.bulk_prices.map((bulkPrice, index) => {
          return (
            <div>
              {index === 0 ? '' : <Divider style={{ margin: '5px 0px' }} />}
              <Space size="large">
                <div>
                  <div style={{ whiteSpace: 'nowrap' }}>Số lượng tối thiểu</div>
                  <div>{bulkPrice.min_quantity_apply}</div>
                </div>
                <div>
                  <div style={{ whiteSpace: 'nowrap' }}>Số lượng tối đa</div>
                  <div>{bulkPrice.max_quantity_apply}</div>
                </div>
                <div>
                  <div style={{ whiteSpace: 'nowrap' }}>Giá trước khi điều chỉnh</div>
                  <div>{formatCash(bulkPrice.from_price || 0)}</div>
                </div>
                <div>
                  <div style={{ whiteSpace: 'nowrap' }}>Chênh lệch</div>
                  <div>{formatCash((bulkPrice.to_price || 0) - (bulkPrice.from_price || 0))}</div>
                </div>
                <div>
                  <div style={{ whiteSpace: 'nowrap' }}>Giá sau khi điều chỉnh</div>
                  <div>{formatCash(bulkPrice.to_price || 0)}</div>
                </div>
              </Space>
            </div>
          )
        })}
    </Space>
  )

  const columnsVariant = [
    {
      width: 90,
      title: 'Hình ảnh',
      render: (text, record, index) => (
        <Spin spinning={loadingImageVariant.index == index && loadingImageVariant.loading}>
          {record?.image[0]?.length > 0 ? (
            <Badge
              count={
                <CloseCircleFilled
                  onClick={() => {
                    let variantsNew = [...variants]
                    variantsNew[index].image[0] = ''
                    setVariants([...variantsNew])
                  }}
                  style={{ fontSize: 18, color: '#ff4d4f', cursor: 'pointer' }}
                />
              }
            >
              <UploadImageProduct variant={record} />
            </Badge>
          ) : (
            <UploadImageProduct variant={record} />
          )}
        </Spin>
      ),
    },
    {
      title: 'Tên phiên bản',
      render: (text, record) => 
      <ModalVariant 
        data={record}
        setVariants={setVariants}
        variants={variants}
        isModeUpdate={isModeUpdate}
        productDetail={productDetail}
        newData={newData}
      />,
    },
    {
      title: 'Mã phiên bản',
      render: (text, record) => <InputSku value={record.sku} variant={record} />,
    },
    {
      width: 180,
      title: 'Giá phiên bản',
      render: (text, record) => (
        <ModalPrice
          data={record}
          setVariants={setVariants}
          variants={variants}
          isModeUpdate={isModeUpdate}
        />
      ),
    },
    {
      width: 90,
      title: 'Số lượng',
      render: (text, record) =>
        typeAdvanced == 1 && <InputQuantity value={record.total_quantity} variant={record} />,
    },
  ]

  const _getWarranties = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getWarranties({ branch_id: branchIdApp })
      if (res.status === 200) {
        setWarranties(res.data.data)
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const initProductWithEditProduct = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
    if (sku) {
      var response = await getOneProduct({ sku: sku, branch_id: branchIdApp})
      if (response.status == 200) {
        const product = response.data.data
        setProductDetail(product)
        delete product.sumBasePrice
        delete product.sumImportPrice
        delete product.sumQuantity
        delete product.sumSalePrice

        setInventoryCheck(product.inventory)
        setTypeAdvanced(product.type_advanced)
        setDescription(product.description)
        let checkLengthImg = product?.images?.filter((item) => item?.length > 0)

        if (checkLengthImg.length > 0) {
          setLoadInfoImage(true)
        } else {
          setLoadInfoImage(false)
        }

        if (product?.description?.length > 0 || product?.category_id?.length > 0) {
          setLoadInfoAnother(true)
        }
        form.setFieldsValue({ ...product, import_price: product.import_price })

        setImagesPreviewProduct(product.variants[0].image || [])

        setSkuProductWithEdit(product.variants[0].sku)
        setBulkPrices(product.variants[0].bulk_prices || [])
        setAttributes(product.attributes)
        setVariants(product.variants)
        setImagesProduct(product.images || [])
        setCoverImageProduct(product.cover_image || [])

        setDescription(product.description || '')

        setFiles(product.files)

        product.variants.map((item) => item.price)

        _getChangePriceHistory(product.product_id)
      _getInventories(product.product_id)
      } else {
        form.setFieldsValue({ unit: 'Cái' })
      }

      await delay(100)
      setIsRenderFirst(true)
      // setKeyTab('3')
      await delay(100)
      setKeyTab('1')
    } else {
      history.push(ROUTES.OVERVIEW)
    }
    dispatch({ type: ACTION.LOADING, data: false})
    } catch (error) {
    dispatch({ type: ACTION.LOADING, data: false})
    console.log(error);
    }
    
  }
  const socketListener = () => {
    socket.on(`product:action:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
      try {
        notification.success({key: 'socket', message: 'Số lượng sản phẩm đã thay đổi'})
        if (res.productList.length > 0) {
          let newData = await _getProductChange(branchIdApp, res.productList)
          dispatch({
            type: 'SET_NEW_PRODUCTS',
            data: {
              products: newData.products,
              ids: res.productList,
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  useEffect(() => {
    socketListener()
  }, [socket])
  //get width device
  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true)
    else setIsMobile(false)
  }, [])

  useEffect(() => {
    if (isRenderFirst) addValueVariant()
  }, [attributes])

  //update product
  useEffect(() => {
    initProductWithEditProduct()
    setTimeout(() =>{
      _getSuppliers()
      _getCategories()
      _getWarranties()
      
    },1000)
    
  }, [sku,branchIdApp])
  useEffect(() => {
    if(!permissions.includes(PERMISSIONS.chi_tiet_san_pham)){ 
      history.push(ROUTES.PRODUCT)
      notification.warning({message: 'Permission denied!'})}
  },[dataUser, branchIdApp])
  return !isMobile ? (
    <>
      {productDetail && (
        <div className="card">
          <TitlePage
            isAffix={true}
            title={
              <Row
                wrap={false}
                align="middle"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(ROUTES.PRODUCT)}
              >
                <ArrowLeftOutlined style={{ marginRight: 8 }} />
                <div>{isModeUpdate ? 'Cập nhật sản phẩm' : 'Xem chi tiết sản phẩm'}</div>
              </Row>
            }
          >
            {!productDetail.is_delete ? <Space>
              <>
                <span>Mở bán online</span>
                {permissions.includes(PERMISSIONS.cap_nhat_san_pham) ? (
                  <Switch
                    checked={productDetail.active_online || false}
                    onClick={() => {
                      let checkLengthImg = productDetail.images.filter((item) => item?.length > 0)
                      let checkLengthImgNew = imagesProduct.filter((item) => item?.length > 0)
                      if (productDetail.active_online === false) {
                        if (checkLengthImg.length < 1 && checkLengthImgNew.length < 1) {
                          return notification.error({
                            key: 'error img',
                            message: 'Thông tin ảnh yêu cầu bán online là 1 ảnh và đầy đủ mô tả',
                          })
                        } else {
                          _updateProductOnline(
                            { active_online: !productDetail.active_online },
                            productDetail.product_id
                          )
                        }
                      } else {
                        _updateProductOnline(
                          { active_online: !productDetail.active_online },
                          productDetail.product_id
                        )
                      }
                    }}
                  />
                ) : (
                  <Switch disabled={true} />
                )}
              </>

              <Button
                icon={<ReloadOutlined />}
                style={{ display: !sku && 'none' }}
                size="large"
                onClick={() => history.go(0)}
              >
                Tải lại
              </Button>
              <>
                {permissions.includes(PERMISSIONS.cap_nhat_san_pham) ? (
                  <Button
                    icon={<EditOutlined />}
                    size="large"
                    type="primary"
                    onClick={addOrUpdateProduct}
                  >
                    {isModeUpdate ? 'Lưu' : 'Chỉnh Sửa'}
                  </Button>
                ) : (
                  <Button
                    disabled
                    icon={<EditOutlined />}
                    size="large"
                    type="primary"
                    onClick={addOrUpdateProduct}
                  >
                    {isModeUpdate ? 'Lưu' : 'Chỉnh Sửa'}
                  </Button>
                )}
              </>
            </Space> : null}
            
          </TitlePage>
          <Form
            form={form}
            layout="vertical"
            style={{ width: '100%', marginTop: 10, display: 'flex' }}
          >
            <div className={styles['container-left']}>
              <div className={styles['container-ttcb']}>
                <div className={styles['title-container']}>Thông tin chung</div>
                <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
                <Row>
                  <Col>
                    <Form.Item
                      label="Tên sản phẩm"
                      name="name"
                      rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }]}
                      style={{ width: 300, marginRight: 8 }}
                    >
                      <Input
                        disabled="true"
                        size="large"
                        placeholder="Nhập tên sản phẩm"
                        onBlur={(e) => {
                          const generatedItemsSku = e.target.value.split(' ')
                          const valueSku = generatedItemsSku
                            .map((items) => (items[0] ? removeAccents(items[0]).toUpperCase() : ''))
                            .join('')

                          form.setFieldsValue({ sku: valueSku })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{ width: 300, marginRight: 8 }}
                      label="Mã (SKU)"
                      name="sku"
                      rules={[{ message: 'Vui lòng nhập mã sản phẩm/SKU', required: true }]}
                    >
                      <Input disabled="true" size="large" placeholder="Nhập mã" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label="Barcode"
                      name="barcode"
                      style={{ width: 300, marginRight: 8 }}
                    >
                      <Input
                        disabled={isModeUpdate ? false : true}
                        size="large"
                        placeholder="Nhập barco de"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{ width: 300, marginRight: 8 }}
                      label="Đơn vị"
                      name="unit"
                      initialValue={'Cái'}
                      rules={[
                        {
                          message: 'Vui lòng chỉ nhập ký tự chữ!',
                          pattern: regexRemoveUnicodeNoNumber,
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Đơn vị" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {productDetail?.type_advanced === 0 ? (
                <div className={styles['container-ttcb']}>
                  <div className={styles['title-container']}>Giá sản phẩm</div>
                  <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
                  <Row>
                    <Col span={7} style={{ margin: '0.5em' }}>
                      <Form.Item
                        className={styles['input']}
                        label="Giá bán lẻ"
                        name="price"
                        rules={[
                          {
                            required: true,
                            pattern: /^[0-9]*$/,
                            message: 'Giá bán lẻ không hợp lệ',
                          },
                        ]}
                      >
                        <InputNumber
                          disabled={isModeUpdate ? false : true}
                          controls={false}
                          size="large"
                          min={0}
                          placeholder="Nhập giá bán"
                          style={{ width: '100%' }}
                          className="br-15__input"
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={7} style={{ margin: '0.5em' }}>
                      <Form.Item
                        className={styles['input']}
                        label="Giá bán sỉ"
                        name="whole_sale_price"
                      >
                        <InputNumber
                          disabled={isModeUpdate ? false : true}
                          controls={false}
                          size="large"
                          min={0}
                          placeholder="Nhập giá bán sỉ"
                          style={{ width: '100%' }}
                          className="br-15__input"
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={7} style={{ margin: '0.5em' }}>
                      <Form.Item className={styles['input']} label="Giá nhập" name="import_price">
                        <InputNumber
                          disabled={isModeUpdate ? false : true}
                          controls={false}
                          size="large"
                          min={0}
                          placeholder="Nhập giá nhập"
                          style={{ width: '100%' }}
                          className="br-15__input"
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                    <a>
                      {dataUser &&
                        dataUser.data &&
                        dataUser.data.price_recipe &&
                        `* Giá vốn được tính theo công thức ${dataUser.data.price_recipe}`}
                    </a>
                  </Col>
                </div>
              ) : null}

              <div className={styles['container-ttcb']}>
                <Row gutter={[32, 0]}>
                  <Col span={21}>
                    <div className={styles['title-container']}>Hình ảnh sản phẩm</div>
                  </Col>
                  <Col span={3}>
                    <Switch
                      checked={loadInfoImage}
                      onChange={() => setLoadInfoImage(!loadInfoImage)}
                    />
                  </Col>
                </Row>
                <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
                {loadInfoImage === true ? (
                  <>
                    <Row style={{ marginBottom: 10 }}>
                      {imagesProduct.map((image, index) =>
                        image ? (
                          <div className={styles['create-product-image-upload']}>
                            <Badge
                              count={
                                <CloseCircleFilled
                                  onClick={() => {
                                    const imagesNew = [...imagesProduct]
                                    imagesNew[index] = ''
                                    setImagesProduct([...imagesNew])
                                  }}
                                  style={{ fontSize: 18, color: '#ff4d4f', cursor: 'pointer' }}
                                />
                              }
                            >
                              <img
                                src={image}
                                alt=""
                                style={{ width: 104, height: 104, padding: 3, objectFit: 'cover' }}
                              />
                            </Badge>
                            <Row style={{ width: 104, marginTop: 3 }} justify="center">
                              {index === 0 ? (
                                <div style={{ color: 'black' }}>Ảnh bìa</div>
                              ) : (
                                <div style={{ color: 'gray' }}>Hình ảnh {index}</div>
                              )}
                            </Row>
                          </div>
                        ) : (
                          <Spin spinning={index < loadingImageCount ? loadingImage : false}>
                            <div>
                              <Upload
                                accept="image/*"
                                multiple
                                style={{ width: 'max-content', marginRight: 10, marginBottom: 10 }}
                                name="avatar"
                                listType="picture-card"
                                className={styles['create-product-upload-img']}
                                showUploadList={false}
                                onChange={async (info) => {
                                  let newCount = (imagesProduct?.filter(
                                    (item) => item?.length > 0
                                  )).length
                                  setLoadingImageCount(info?.fileList?.length + newCount)
                                  if (
                                    info.file.type !== 'image/png' &&
                                    info.file.type !== 'image/jpg' &&
                                    info.file.type !== 'image/jpeg'
                                  ) {
                                    return notification.error({
                                      key: 'check file img',
                                      message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!',
                                    })
                                  }
                                  if (info.file.size > 1024 * 1024 * 2) {
                                    return notification.error({
                                      key: 'check file img',
                                      message: 'Hình ảnh không được lớn hơn 2MB!',
                                    })
                                  }
                                  if (typingTimeoutRef.current) {
                                    clearTimeout(typingTimeoutRef.current)
                                  }
                                  typingTimeoutRef.current = setTimeout(async () => {
                                    setLoadingImage(true)
                                    const urls = await uploadFiles(
                                      info.fileList.map((file) => file.originFileObj)
                                    )
                                    if (urls) {
                                      const imagesProductNew = [...imagesProduct]
                                      for (let i = 0; i < urls.length; i++)
                                        for (let j = 0; j < imagesProductNew.length; j++)
                                          if (!imagesProductNew[j]) {
                                            imagesProductNew[j] = urls[i]
                                            break
                                          }
                                      setImagesProduct([...imagesProductNew])
                                    }
                                    setLoadingImage(false)
                                    setLoadingImageCount(0)
                                  }, 450)
                                }}
                              >
                                <div>
                                  <PlusOutlined />
                                  {/* <div style={{ marginTop: 8 }}>T?i l�n</div> */}
                                </div>
                              </Upload>
                              <Row style={{ width: 75, marginTop: 3 }} justify="center">
                                {index === 0 ? (
                                  <div style={{ color: 'black' }}>Ảnh bìa</div>
                                ) : (
                                  <div style={{ color: 'gray' }}>Hình ảnh {index}</div>
                                )}
                              </Row>
                            </div>
                          </Spin>
                        )
                      )}
                    </Row>
                    <div style={{ marginBottom: 10, marginTop: 25 }}>
                      <span style={{ fontWeight: 500 }}>Video sản phẩm</span>
                      <br />
                      <Upload
                        accept="video/*"
                        fileList={files?.map((file, index) => {
                          const fileSplit = file?.split('/') || []
                          const nameFile = fileSplit.length > 0 && fileSplit[fileSplit.length - 1]
                          return {
                            uid: index,
                            name: nameFile ? nameFile : 'file',
                            status: 'done',
                            url: file,
                          }
                        })}
                        onRemove={(file) => {
                          const indexRemove = files.findIndex((url) => url === file)
                          if (indexRemove) {
                            const filesNew = [...files]
                            filesNew.splice(indexRemove, 1)
                            setFiles([...filesNew])
                          }
                        }}
                        data={uploadVideoProduct}
                        onChange={(info) => {
                          if (info.file.status !== 'done') info.file.status = 'done'
                        }}
                      >
                        <Button
                          loading={loadingFile}
                          style={{ width: 120 }}
                          icon={<UploadOutlined />}
                        >
                          Chọn file
                        </Button>
                      </Upload>
                      <Row gutter={[30, 0]}>
                        <Col span={8}>
                          {files.map((video) => (
                            <Col span={24}>
                              <video width="100%" height="240" controls>
                                <source
                                  style={{ width: 200, height: 150 }}
                                  src={video}
                                  type="video/mp4"
                                />
                              </video>
                            </Col>
                          ))}
                        </Col>
                        <Col span={16}>
                          <ul style={{ color: '#b7b7b7' }}>
                            <li>Kích thước: Tối đa 25Mb</li>
                            <li>Định dạng: MP4 </li>
                            <li>
                              Lưu ý: sản phẩm có thể hiển thị trong khi video đang được xử lý. Video
                              sẽ tự động hiển thị sau khi đã xử lý thành công..
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <span>Thông tin hình ảnh sản phẩm</span>
                )}
              </div>

              <div className={styles['container-ttcb']}>
                <Row gutter={[32, 0]}>
                  <Col span={21}>
                    <div className={styles['title-container']}>Thông tin bổ sung</div>
                  </Col>
                  <Col span={3}>
                    <Switch
                      checked={loadInfoAnother}
                      onChange={() => setLoadInfoAnother(!loadInfoAnother)}
                    />
                  </Col>
                </Row>
                <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>

                {loadInfoAnother === true ? (
                  <>
                    <Col>
                      <Row wrap={false} align="middle">
                        <Form.Item
                          // rules={[{ required: true, message: 'Vui lòng chọn nhà cung cấp' }]}
                          label="Nhà cung cấp"
                          name="supplier_id"
                          style={{ width: 300, marginRight: 8 }}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            size="large"
                            style={{ width: '100%' }}
                            placeholder="Chọn nhà cung cấp"
                            onChange={(value) => {
                              const supplier = suppliers.find((s) => s.supplier_id === value)
                              supplier && setSupplier(supplier.name)
                            }}
                          >
                            {suppliers?.map((values, index) => {
                              return (
                                <Select.Option value={values.supplier_id} key={index}>
                                  {values.name}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                        <Permission permissions={[PERMISSIONS.them_nha_cung_cap]}>
                          <Tooltip title="Tạo nhà cung cấp">
                            <Link target="_blank" to="/supplier">
                              <Button size="large" type="primary" icon={<PlusOutlined />} />
                            </Link>
                          </Tooltip>
                        </Permission>
                      </Row>
                    </Col>
                    <Col>
                      <Row wrap={false} align="middle">
                        <Form.Item
                          style={{ width: 300, marginRight: 8 }}
                          // rules={[{ required: true, message: 'Vui lòng chọn nhóm sản phẩm' }]}
                          label="Nhóm sản phẩm"
                          name="category_id"
                        >
                          <TreeSelect
                            showCheckedStrategy={TreeSelect.SHOW_ALL}
                            size="large"
                            style={{ width: '100%' }}
                            treeNodeFilterProp="title"
                            maxTagCount="responsive"
                            placeholder="Chọn nhóm sản phẩm"
                            allowClear
                            multiple
                            treeDefaultExpandAll
                          >
                            {categories.map((category) => (
                              <TreeSelect.TreeNode
                                value={category.category_id}
                                title={category.name}
                              >
                                {category.children_category.map((child) => (
                                  <TreeSelect.TreeNode value={child.category_id} title={child.name}>
                                    {child.children_category.map((e) => (
                                      <TreeSelect.TreeNode value={e.category_id} title={e.name}>
                                        {e.name}
                                      </TreeSelect.TreeNode>
                                    ))}
                                  </TreeSelect.TreeNode>
                                ))}
                              </TreeSelect.TreeNode>
                            ))}
                          </TreeSelect>
                        </Form.Item>

                        <Permission permissions={[PERMISSIONS.tao_nhom_san_pham]}>
                          <Tooltip title="Tạo nhóm sản phẩm">
                            <Link target="_blank" to={'/categories'}>
                              <Button size="large" type="primary" icon={<PlusOutlined />} />
                            </Link>
                          </Tooltip>
                        </Permission>
                      </Row>
                    </Col>
                    <Row>
                      <Col>
                        <Form.Item
                          style={{ width: 300, marginRight: 8 }}
                          rules={[
                            {
                              pattern: /^[0-9]*$/,
                              message: 'Cân nặng chỉ cho phép nhập số dương!',
                            },
                          ]}
                          label="Khối lượng (g)"
                          name="weight"
                        >
                          <Input
                            allowClear
                            style={{ width: '100%' }}
                            className="br-15__input"
                            size="large"
                            placeholder="Khối lượng (g)"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ width: '100%', marginTop: 20 }}>
                      <Col>
                        <div>Thêm chính sách bảo hành (nếu có)</div>
                        <Row wrap={false}>
                          <Select
                            size="large"
                            mode="multiple"
                            showSearch
                            style={{ width: 300, marginRight: 8 }}
                            placeholder="Chọn chính sách bảo hành"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => setIdsWarranty(value)}
                            value={idsWarranty}
                          >
                            <Select.Option value={''}>
                              Không áp dụng chính sách bảo hành
                            </Select.Option>
                            {warranties.map((values, index) => (
                              <Select.Option value={values.warranty_id} key={index}>
                                {values.name}
                              </Select.Option>
                            ))}
                          </Select>
                          <ModalAddGuarantee />
                        </Row>
                      </Col>
                    </Row>
                    <Col style={{ marginTop: 2, marginBottom: 15 }}>
                      <div>Mô tả sản phẩm</div>
                      <Input.TextArea
                        placeholder="Mô tả sản phẩm"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={7}
                      />
                    </Col>
                  </>
                ) : (
                  <span>Thông tin bổ sung sản phẩm</span>
                )}
              </div>
            </div>
            <div className={styles['container-right']}>
              <div className={styles['container-ttcb']}>
                <div className={styles['title-container']}>Tuỳ chọn tồn kho</div>
                <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
                <Row>
                  <Form.Item name="inventory" label="Tuỳ chọn tồn kho">
                    <Radio.Group
                      onChange={(e) => {
                        setInventoryCheck(e.target.value)
                      }}
                    >
                      <Tooltip
                        title={
                          productDetail?.inventory === 'advanced' && (
                            <span>Không thể thay đổi sang sản phẩm không có tồn kho</span>
                          )
                        }
                        color="geekblue"
                      >
                        <Radio
                          disabled={productDetail?.inventory === 'advanced' ? true : false}
                          value="normal"
                        >
                          Sản phẩm không tồn kho
                        </Radio>
                      </Tooltip>

                      <Tooltip
                        title={
                          productDetail?.inventory === 'normal' && (
                            <span>
                              Thay đổi từ sản phẩm không tồn kho sang sản phẩm có tồn kho.<br></br>{' '}
                              Sẽ không thể quay về sản phẩm không có tồn kho sau khi bấm cập nhật？
                            </span>
                          )
                        }
                        color="geekblue"
                      >
                        <Radio value="advanced">Sản phẩm có tồn kho</Radio>
                      </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                </Row>
                {inventoryCheck === 'advanced' && (
                  <Row>
                    <Form.Item name="type_advanced" label="Tuỳ chọn loại của sản phẩm có tồn kho">
                      <Radio.Group
                        onChange={(e) => {
                          setTypeAdvanced(e.target.value)
                        }}
                      >
                        <Radio value={1}>Tồn thường</Radio>
                        <Radio value={2}>Tồn theo lô & HSD</Radio>
                        <Radio value={3}>Tồn theo iMei</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                )}
              </div>
              <div className={styles['container-ttcb']}>
                <Row gutter={[32, 0]}>
                  <Col span={21}>
                    <div className={styles['title-container']}>Danh sách phiên bản</div>
                  </Col>
                </Row>
                <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
                {inventoryCheck === 'normal' ? (
                  <span>Đối với sản phẩm tồn kho cơ bản, hệ thống không hỗ trợ thêm phiên bản</span>
                ) : (
                  <div style={{ width: '100%', marginTop: 10 }}>
                    <div
                      style={{
                        marginBottom: 16,
                        border: '1px solid #f0f0f0',
                        // padding: 16,
                        width: '100%',
                      }}
                    >
                      <div style={{ borderBottom: '1px solid #f0f0f0', width: '100%' }}>
                        <div style={{ width: '100%', padding: 8 }}>
                          <h3 style={{ marginBottom: 0, fontWeight: 700 }}>Thuộc tính</h3>
                        </div>
                      </div>
                      {attributes.map((e, index) => {
                        const RenderInput = () => (
                          <Input
                            size="large"
                            placeholder="Nhập tên thuộc tính"
                            defaultValue={e.option}
                            onBlur={(event) => {
                              const optionName = event.target.value
                              const option = attributes.find(
                                (attr) => attr.option === optionName && optionName
                              )
                              if (option) {
                                notification.warning({ message: 'Bạn đã thêm thuộc tính này rồi' })
                                attributes[index].option = ''
                                return
                              }
                              attributes[index].option = optionName
                            }}
                            style={{ width: '100%' }}
                          />
                        )
                        return (
                          <Row
                            style={{ width: '100%', marginBottom: 15, marginLeft: 6 }}
                            justify="start"
                            gutter={10}
                            align="middle"
                          >
                            <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                              <span style={{ marginBottom: 0 }}>Tên thuộc tính</span>
                              <RenderInput />
                            </Col>
                            <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                              <span style={{ marginBottom: 0 }}>Giá trị</span>
                              <Select
                                mode="tags"
                                size="large"
                                style={{ width: '100%' }}
                                placeholder="Nhập giá trị"
                                value={e.values.map((v) => v)}
                                onDeselect={(v) => {
                                  //remove tag
                                  let items = [...attributes]
                                  for (let i of productDetail.attributes) {
                                    if (i.values.find((item) => item === v)) {
                                      return notification.error({
                                        key: 'Giá trị thuộc tính cũ',
                                        message: 'Không thể xoá giá trị thuộc tính cũ!',
                                      })
                                    } else {
                                      const indexRemove = e.values.findIndex((f) => f === v)
                                      if (indexRemove !== -1) {
                                        items[index].values.splice(indexRemove, 1)
                                        setAttributes([...items])
                                      }
                                    }
                                  }
                                }}
                                onSelect={(e) => {
                                  //add tag
                                  let items = [...attributes]

                                  const splitValue = e.split(',')
                                  let newValue = [...new Set(splitValue)]

                                  for (let i = 0; i < items.length; i++) {
                                    for (let j = 0; j < items[i].values.length; j++) {
                                      if (items[i].values[j] === e) {
                                        notification.error({ message: 'Giá trị đã có!' })
                                        return
                                      }
                                    }
                                  }
                                  newValue.map((v) => {
                                    const checkAttribute = /[^A-Za-z\d ]/gi.test(removeUnicode(v))

                                    if (v && checkAttribute === false)
                                      items[index].values.push(v.trim())
                                    else
                                      notification.warning({
                                        message: 'Vui lòng không sử dụng ký tự đặc biệt!',
                                      })
                                  })
                                  setAttributes([...items])
                                }}
                                optionLabelProp="label"
                              ></Select>
                            </Col>
                            <Popconfirm
                              title="Bạn có muốn xoá thuộc tính này?"
                              onConfirm={() => removeAttribute(index)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <CloseOutlined
                                style={{
                                  cursor: 'pointer',
                                  color: 'red',
                                  fontSize: 18,
                                  marginTop: 22,
                                  marginLeft: 5,
                                  display: attributes.length === 1 && 'none',
                                  visibility: 'hidden',
                                }}
                              />
                            </Popconfirm>
                          </Row>
                        )
                      })}{' '}
                      <Tooltip title="Tối đa tạo 3 thuộc tính">
                        <Button
                          size="large"
                          style={{
                            marginTop: 17,
                            display: (attributes.length === 3) && 'none',
                          }}
                          onClick={addAttribute}
                          disabled={false}
                        >
                          Thêm thuộc tính
                        </Button>
                      </Tooltip>
                    </div>

                    <div style={{ marginBottom: 16, border: '1px solid #f0f0f0', width: '100%' }}>
                      <div
                        style={{
                          marginLeft: 10,
                          marginTop: 10,
                          marginBottom: 20,
                          display: !selectRowKeyVariant.length && 'none',
                        }}
                      >
                        <Space wrap>
                          <UploadAllVariant />
                          {productDetail && <EditSku />}
                          {productDetail && <EditAttribute />}
                          <EditPrice />
                        </Space>
                      </div>
                      <Table
                        rowKey="title"
                        columns={columnsVariant}
                        dataSource={variants}
                        pagination={false}
                        rowSelection={{
                          selectedRowKeys: selectRowKeyVariant,
                          onChange: (selectedRowKeys, selectedRows) => {
                            setSelectRowKeyVariant(selectedRowKeys)
                          },
                        }}
                        size="small"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
          <div>
          <div className={styles['container-history']}>
                <Row gutter={[32, 0]}>
                  <Col span={21}>
                    <div className={styles['title-container']}>Lịch sử điều chỉnh giá</div>
                  </Col>
                  <Col span={3}>
                    <Switch checked={loadHistory} onChange={() => setLoadHistory(!loadHistory)} />
                  </Col>
                </Row>
                <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
                {loadHistory && (
                  <Table
                    loading={loadingChangePriceHistory}
                    scroll={{ x: 'max-content' }}
                    dataSource={changePriceHistory || []}
                    size="small"
                    columns={columnsHistoryBulkPrices}
                  />
                )}
              </div>
              <div className={styles['container-history']}>
                <Row gutter={[32, 0]}>
                  <Col span={21}>
                    <div className={styles['title-container']}>Lịch sử xuất nhập</div>
                  </Col>
                  <Col span={3}>
                    <Switch
                      checked={loadHistory2}
                      onChange={() => setLoadHistory2(!loadHistory2)}
                    />
                  </Col>
                </Row>
                <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
                {loadHistory2 && (
                  <Table
                    scroll={{ x: 'max-content' }}
                    dataSource={inventory || []}
                    size="small"
                    columns={
                      columnsInventory
                      //
                    }
                  />
                )}
              </div>
          </div>
          <Drawer
            width="70%"
            title="Tạo nhóm sản phẩm"
            placement="right"
            onClose={toggleDrawerListProduct}
            visible={visibleListProduct}
          >
            <CreateCategory
              title="product-form"
              toggle={toggleDrawerListProduct}
              reload={_getCategories}
            />
          </Drawer>
          <Modal
            title="Danh sách hình ảnh"
            visible={isModalVisible}
            onCancel={toogleModalPicture}
            footer={false}
          >
            <ModalPicture
              imageBig={imageBig}
              setImageBig={setImageBig}
              modalDefaultImg={modalDefaultImg}
              imageData={imagesProduct}
              toogleModalPicture={toogleModalPicture}
            />
          </Modal>
        </div>
      )}

      {!productDetail && <div>{'Loading ....'}</div>}
    </>
  ) : (
    <NotSupportMobile />
  )
}
