import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { compare, formatCash } from 'utils'
import { useHistory } from 'react-router-dom'
import { FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE } from 'consts'
import { useSelector } from 'react-redux'
import FilterDate from 'components/filter-date/filter'

//antd
import { Col, Row, Table, Tag, Button, Select, TreeSelect, Tooltip,Modal } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'

//apis
import { getReportInventory } from 'apis/report'
import { getCategories } from 'apis/category'
import { getProducts } from 'apis/product'

//component
import ImportDetail from './import-detail'
import CloseIcon from 'assets/icons/close.svg'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'
import { use } from 'i18next'

export default function ReportInventory() {
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [valueFilter, setValueFilter] = useState()
  const [categories, setCategories] = useState([])
  const [product, setProduct] = useState([])
  const [total, setTotal] = useState()
  const [reportInventory, setReportInventory] = useState([])
  const [valueTime, setValueTime] = useState()
  const [reportInventoryToExport, setReportInventoryToExport] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [countReport, setCountReport] = useState(0)

  const _onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _clearFilters = async () => {
    await _reportInventory()
    setValueFilter()
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, chekc:1 })
  }
  const ModalImportDetail = ({ children, value }) => {
    const [visibleInventory, setVisibleInventory] = useState(false)
    const toggleinventory = () => setVisibleInventory(!visibleInventory)

    return (
      <>
        <Tooltip placement="topLeft" title="Xem nhập hàng">
        <div onClick={toggleinventory} style={{cursor:'pointer'}}>{children}</div>
        </Tooltip>

        <Modal
          onCancel={toggleinventory}
          footer={null}
          title="Lịch sử nhập hàng"
          visible={visibleInventory}
          closeIcon={<img src={CloseIcon} alt="" />}
          width="80%"
        >
          <ImportDetail record={value} prod={1} close={toggleinventory} />
        </Modal>
      </>
    )
  }
  const columnsDefault = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => index + 1,
      width: 75,
      align: 'center', 
    },
    {
      title: 'Tên sản phẩm',
      render: (text, record) => (
          <ModalImportDetail value={record}>{record.name}</ModalImportDetail>
      ),
      fixed: 'left',
    },
    {
      title: 'ĐVT',
      dataIndex: 'unit',
      fixed: 'left',
    },
    {
      title: 'Nhóm',
      render: (text, record) =>
        record.categories ? record.categories.map((category) => <Tag>{category.name}</Tag>) : '',
      fixed: 'left',
    },
    {
      title: 'Mã SKU',
      dataIndex: 'sku',
      fixed: 'left',
    },
    {
      title: 'Số lượng tồn',
      render: (text, record) => record.quantity,
      sorter: (a, b) => compare(a, b, 'quantity'),
    },
    {
      title: (
        <div>
          {'Giá nhập '}  
          <Tooltip title="Thiết lập công thức tính giá nhập ở phần cấu hình">
          <InfoCircleTwoTone style={{ fontSize: 12 }} />
        </Tooltip>
        </div>
        
      ),
      // render: (text, record) => `-`+formatCash(record.import_price),
      // sorter: (a, b) => compare(a, b, 'import_price'),
      render: (text, record) => '-',

    },
    {
      title: (
        <div>
          {'Giá trị tồn kho '}  
          <Tooltip title="Thiết lập công thức tính giá nhập ở phần cấu hình">
          <InfoCircleTwoTone style={{ fontSize: 12 }} />
        </Tooltip>
        </div>
        
      ),
      // render: (text, record) =>formatCash(record.import_price),
      // sorter: (a, b) => compare(a, b, 'import_price'),
      render:(text, record) => '-',
    },
    {
      title: 'Giá bán',
      render: (text, record) => formatCash(record.price),
      sorter: (a, b) => compare(a, b, 'price'),
    },
    {
      title: 'Tổng giá bán sản phẩm',
      render: (text, record) => formatCash(record.price * record.quantity),
    },
  ]
  const [columns, setColumns] = useState(columnsDefault)
  const [columnsExport, setColumnsExport] = useState(columnsDefault)

  const _reportInventory = async () => {
    try {
      setLoading(true)
      const res = await getReportInventory({
        ...paramsFilter,
        type: 'product',
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        setCountReport(res.data.count)
        setReportInventory(res.data.data)
        setTotal(res.data.total)
        
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _reportInventoryToExport = async () => {
    try {
      setLoading(true)
      const res = await getReportInventory({
        ...paramsFilter,
        type: 'product',
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        setCountReport(res.data.count)
        setReportInventoryToExport(res.data.data)
        setTotal(res.data.total)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _getCategories = async () => {
    try {
      const res = await getCategories()
      if (res.status === 200) setCategories(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getProducts = async () => {
    try {
      const res = await getProducts()
      if (res.status === 200)
        setProduct(res.data.data.filter((item) => item.inventory === 'advanced'))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getCategories()
    _getProducts()
  }, [])
useEffect(() => {
  let result = DefaultTimeFilter(dataUser.role_id, permissions)
  setParamsFilter({page:1, page_size: PAGE_SIZE, from_date: result.from_date, to_date: result.to_date, check:1})
  setValueTime(result.valueDate)
  setValueFilter()
},[dataUser])
  useEffect(() => {
    if(paramsFilter.check) _reportInventory()
  }, [paramsFilter,branchIdApp])
  return (
    <div>
      <Row
        gutter={[8, 16]}
        style={{
          marginBottom: 20,
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={7}
          xl={7}
        >
          <FilterDate
          size='default'
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            title=''
            valueTime={valueTime}
            setValueTime={setValueTime}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7} style={{ height: FILTER_COL_HEIGHT }}>
          <TreeSelect
            size={FILTER_SIZE}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            bordered={true}
            multiple
            treeDefaultExpandAll
            style={{ width: '100%' }}
            placeholder="Lọc theo nhóm sản phẩm"
            value={paramsFilter.category_id && paramsFilter.category_id.split('---').map((e) => +e) || 0}
            onChange={(value) => {
              let index = value.findIndex((item) => item === 0)
                      if (index !== -1 && value.length > 1) {
                        if (index === 0) {
                          value.splice(index, 1)
                        } else {
                          value = 0
                        }
                      }
              if (value.length) setParamsFilter({ ...paramsFilter, category_id: value.join('---') })
              else {
                delete paramsFilter.category_id
                setParamsFilter({ ...paramsFilter })
              }
            }}
            allowClear
            defaultValue={0}
          >
            <TreeSelect.TreeNode value={0} title="Tất cả nhóm sản phẩm"></TreeSelect.TreeNode>
            {categories.map((category) => (
              <TreeSelect.TreeNode value={category.category_id} title={category.name}>
                {category.children_category.map((child) => (
                  <TreeSelect.TreeNode value={child.category_id} title={child.name}>
                    {child.children_category &&
                      child.children_category.map((e) => (
                        <TreeSelect.TreeNode value={e.category_id} title={e.name}>
                          {e.name}
                        </TreeSelect.TreeNode>
                      ))}
                  </TreeSelect.TreeNode>
                ))}
              </TreeSelect.TreeNode>
            ))}
          </TreeSelect>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={7}
          xl={7}
        >
          <Select
            size={FILTER_SIZE}
            allowClear
            bordered={true}
            defaultValue={'all'}
            value={valueFilter || 'all'}
            onChange={(value) => {
              setValueFilter(value)
              let resultIndex
              if (value !== undefined) {
                let index = value.lastIndexOf('+')
                resultIndex = value.substring(index + 1)
              }
              _onFilter('product_sku', resultIndex)
            }}
            placeholder="Lọc theo sản phẩm"
            style={{ width: '100%' }}
            showSearch
          >
            <Select.Option value={'all'}>Tất cả sản phẩm</Select.Option>
            {product &&
              product.map((_product, index) => (
                <Select.Option
                  value={`${_product.name}+${_product.sku}`}
                  key={index}
                >
                  {_product.name}
                </Select.Option>
              ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
          <Button
            onClick={_clearFilters}
            style={{
              display: Object.keys(paramsFilter).length < 3 && 'none',
              width: '100%',
            }}
            danger
            type="primary"
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      <div className="report-variant" style={{ display: 'none' }}>
        <Table
          bordered
          size="small"
          style={{ width: '100%' }}
          columns={columnsExport}
          dataSource={reportInventoryToExport}
          pagination={false}
          summary={(pageData) => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <div style={{ fontWeight: 700 }}>Tổng</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }} index={5}>{formatCash(total?.totalQuantity || 0)}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }} index={6}>-{formatCash(total?.totalImportPrice || 0)}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }} index={8}>{formatCash(total?.totalPrice || 0)}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }} index={9}>{formatCash(total?.totalSumPrice || 0)}</div>
                </Table.Summary.Cell>

              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
      <Table
        bordered
        loading={loading}
        size="small"
        scroll={{ y: 500 }}
        style={{ width: '100%' }}
        columns={columns}
        dataSource={reportInventory}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
          total: countReport,
        }}
        summary={(pageData) => (
          <Table.Summary fixed>
             <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <div style={{ fontWeight: 700 }}>Tổng</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }} index={5}>{formatCash(total?.totalQuantity || 0)}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }} index={6}>-</div>
                  {/* <div style={{ fontWeight: 700 }} index={6}>-{formatCash(total?.totalImportPrice || 0)}</div> */}
                </Table.Summary.Cell>
                <Table.Summary.Cell style={{ fontWeight: 700 }} index={7}>
                <div style={{ fontWeight: 700 }} index={7}>-</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }} index={8}>{formatCash(total?.totalPrice || 0)}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }} index={9}>{formatCash(total?.totalSumPrice || 0)}</div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
