const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: '5%',
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code',
    width: '6%',
  },
  {
    title: 'Loại hình phiếu',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Loại phiếu',
    dataIndex: 'typefinance',
    key: 'typefinance',
  },
  {
    title: 'Hình thức thanh toán',
    key: 'payment',
  },
  {
    title: 'Trạng thái',
    key: 'status',
  },
  {
    title: 'Số tiền',
    key: 'money',
  },
  {
    title: 'Người thanh toán',
    key: 'payer',
  },
  {
    title: 'Người nhận',
    key: 'receiver',
  },
  {
    title: 'Mô tả',
    key: 'note',
  },
  // {
  //   title: 'Ngày giao dịch',
  //   key: 'time_transaction',
  // },
  {
    title: 'Ngày tạo phiếu',
    key: 'create_date',
  },
  {
    title: 'Người tạo phiếu',
    key: 'creator',
  },
  {
    title: 'Ngày cập nhật',
    key: 'last_update',
  },
  {
    title: 'Người cập nhật',
    key: 'updator',
  },
  {
    title: 'Hành động',
    key: 'action',
    align: 'center',
    width: '5%',
  },
]

export default columns
