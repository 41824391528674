import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { ACTION, PAGE_SIZE, PERMISSIONS, ROUTES } from 'consts'
import { Link } from 'react-router-dom'
import styles from './customer-update.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { compare, formatCash, removeUnicode } from 'utils'
import _ from 'lodash'

//antd
import {
  Input,
  Button,
  Row,
  Col,
  Select,
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Affix,
  Spin,
} from 'antd'

//icons
import { DeleteOutlined, DownloadOutlined, PlusCircleOutlined, EditFilled } from '@ant-design/icons'

//components
import CustomerForm from './customer-form'
import columnCustomer from './columns'
import TitlePage from 'components/title-page'
import exportCustomers from 'components/ExportCSV/export'
import { useTranslation } from 'react-i18next'
import FilterDate from 'components/filter-date/filter'

//apis
import { getCustomerList, deleteCustomer, getCustomerTypes } from 'apis/customer'
import removeVietnameseTones from 'utils/removeUnicode'
import { DefaultFilterData } from 'utils/DefaultFilterData'
const { Option } = Select
export default function Customer() {
  const typingTimeoutRef = useRef(null)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [countCustomer, setCountCustomer] = useState(0)
  const [valueTime, setValueTime] = useState()
  const [paramsFilter, setParamsFilter] = useState({})
  const [pagination, setPagination] = useState({ page: 1, pageSize: 20 })
  const [tableLoading, setTableLoading] = useState(false)
  const [customers, setCustomers] = useState([])
  const [customerShow, setCustomerShow] = useState([])

  const [customerTypes, setCustomerTypes] = useState([])

  const [valueSearch, setValueSearch] = useState('')

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [total, setTotal] = useState()
  const handleTotal = (data) => {
    let total = {
      order_quantity: 0,
      point: 0,
      used_point: 0,
      order_total_cost: 0,
      debt: 0,
      orderPoint: 0,
    }
    data.forEach((element) => {
      total.order_quantity += element.order_quantity
      total.point += element.point
      total.used_point += element.used_point
      total.order_total_cost += element.order_total_cost
      total.debt += element.debt
      total.orderPoint += element.orderPoint
    })
    return total
  }
  const onSearch = (e) => {
    setValueSearch(e)

    e = removeVietnameseTones(e)
    if (e.includes(' ')) {
      e = new RegExp(e.replace(/ /g, '.*'))
    } else {
      e = new RegExp(e)
    }
    paramsFilter['search'] = e
  }
  function onChangeTypeCustomer(value) {
    if (value) paramsFilter.type_id = value
    else delete paramsFilter.type_id
    setParamsFilter({ ...paramsFilter })
  }
  const onClickClear = async () => {
    setValueSearch('')
    setValueTime()
    setParamsFilter({})
    setCustomerShow(customers)
    setCountCustomer(customers.length)
    setTotal(handleTotal(customers))
  }
  useEffect(() => {
    let arr = [...customers]
    const filtered = arr.filter((current) => {
      let matchType = true
      let matchTime = true
      let matchSearch = true
      if (paramsFilter.type_id && paramsFilter.type_id !== 'all')
        matchType = current?.type_id === paramsFilter.type_id
      if (paramsFilter.search && paramsFilter.search !== ' ') {
        let newObject = removeVietnameseTones(Object.values(current).join(' ').toLocaleLowerCase())
        matchSearch = paramsFilter.search.test(newObject)
      }

      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date
      return matchType && matchSearch && matchTime
    })
    setCustomerShow(filtered)
    setCountCustomer(filtered.length)
    setTotal(handleTotal(filtered))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, valueSearch])
  const ModalCustomer = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          style={{ top: 20 }}
          onCancel={toggle}
          width={800}
          footer={null}
          title={`${record ? 'Cập nhật' : 'Tạo'} khách hàng `}
          visible={visible}
        >
          <CustomerForm
            record={record}
            close={toggle}
            text={record ? 'Lưu' : 'Tạo'}
            reload={_getCustomers}
          />
        </Modal>
      </>
    )
  }

  const _getCustomerToExport = async () => {
    let dataExport = []
    try {
    dispatch({ type: ACTION.LOADING, data: true })
    // const res = await getCustomers({ branch_id: branchIdApp })
    //   if (res.status === 200) {
        dataExport = customerShow.map((item, index) => ({
          STT: index + 1,
          'Mã khách hàng': item.code || '',
          'Tên khách hàng': (item.first_name || '') + ' ' + (item.last_name || ''),
          'Loại khách hàng': item._type ? item._type.name : '',
          'Liên hệ': item.phone || '',
          'Tổng số đơn hàng': item.order_quantity || 0,
          'Điểm tích luỹ': item.point || 0,
          'Số điểm đã dùng': item.used_point || 0,
          'Tổng chi tiêu tại cửa hàng': item.order_total_cost || 0,
          'Ngày tạo': item.create_date ? moment(item.create_date).format('DD-MM-YYYY HH:mm') : '',
          'Ngày sinh': item.birthday ? moment(item.birthday).format('DD-MM-YYYY') : '',
          'Địa chỉ': `${item.address && item.address + ', '}${
            item.district && (item.district || item.district.district_name || '') + ', '
          }${item.province && (item.province || item.province.province_name || '')}`,
        }))
      // }
      exportCustomers(dataExport, 'Danh sách khách hàng','Xuất file excel', dispatch)
    } catch (e) {
      console.log(e)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _getCustomers = async () => {
    try {
      setLoading(true)
      setTableLoading(true)
      const res = await getCustomerList({ branch_id: branchIdApp,role_id: dataUser?.role_id })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setCustomers(_.orderBy(result.data, 'code', 'desc'))
        setValueSearch('')
        if(dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)){
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
          })
          setValueTime()
        }else{
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
            from_date: result.defaultTime.from_date,
            to_date: result.defaultTime.to_date,
          })
          setValueTime(result.valueDate)
        }
      }
      setLoading(false)
      setTableLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
      setTableLoading(false)
    }
  }

  const _deleteCustomer = async (id) => {
    try {
      setTableLoading(true)
      const res = await deleteCustomer({customer_id: id, branch_id: branchIdApp })
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getCustomers()
          notification.success({ message: 'Xóa khách hàng thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xóa khách hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa khách hàng thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
      setTableLoading(false)
    }
  }

  const _getCustomerTypes = async () => {
    try {
      const res = await getCustomerTypes()
      if (res.status === 200) setCustomerTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getCustomerTypes()
  }, [])

  useEffect(() => {
    _getCustomers()
  }, [branchIdApp,dataUser])

  return (
    <Spin spinning={loading} tip="loading...">
      <div className="card">
          <TitlePage title="Quản lý khách hàng" isAffix>
            <Space>
              <>
                {permissions.includes(PERMISSIONS.export_khach_hang) ? (
                  <Button
                  id='exportFile'
                    onClick={_getCustomerToExport}
                    icon={<DownloadOutlined />}
                    style={{ backgroundColor: 'green', borderColor: 'green' }}
                    type="primary"
                    size="large"
                  >
                    Xuất excel
                  </Button>
                ) : (
                  <Button
                    disabled
                    onClick={_getCustomerToExport}
                    icon={<DownloadOutlined />}
                    // style={{ backgroundColor: 'green', borderColor: 'green' }}
                    type="primary"
                    size="large"
                  >
                    Xuất excel
                  </Button>
                )}
              </>

              <Link target="_blank" to={{ pathname: ROUTES.CUSTOMER_TYPE }}>
                <Button type="primary" size="large">
                  Quản lý loại khách hàng
                </Button>
              </Link>
              <>
                {permissions.includes(PERMISSIONS.them_khach_hang) ? (
                  <Link to="/import-customer">
                    <Button icon={<DownloadOutlined />} type="primary" size="large">
                      Nhập khách hàng
                    </Button>
                  </Link>
                ) : (
                  <Link to="/import-customer">
                    <Button disabled icon={<DownloadOutlined />} type="primary" size="large">
                      Nhập khách hàng
                    </Button>
                  </Link>
                )}
              </>

              <>
                {permissions.includes(PERMISSIONS.them_khach_hang) ? (
                  <ModalCustomer>
                    <Button
                      size="large"
                      icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                      type="primary"
                    >
                      Thêm khách hàng
                    </Button>
                  </ModalCustomer>
                ) : (
                    <Button
                      disabled
                      size="large"
                      icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                      type="primary"
                    >
                      Thêm khách hàng
                    </Button>
                )}
              </>
            </Space>
          </TitlePage>
        {permissions.includes(PERMISSIONS.xem_danh_sach_khach_hang)?(<>
          <Row gutter={[8, 16]} style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={12} lg={7} xl={7}>
            <Row wrap={false} style={{ width: '100%', borderRadius: 5 }}>
              <Input
                style={{ width: '100%', borderRight: '1px solid #d9d9d9' }}
                placeholder="Tìm kiếm theo..."
                onChange={(e) => onSearch(e.target.value)}
                allowClear
                value={valueSearch}
                bordered={true}
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={7} xl={7}>
            <FilterDate
              size="default"
              bordered={true}
              title="tạo khách hàng"
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
              valueTime={valueTime}
              setValueTime={setValueTime}
              screen={1}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={7} xl={7}>
            <Select
              style={{ width: '100%' }}
              placeholder="Lọc theo loại khách hàng"
              value={paramsFilter.type_id || 'all'}
              onChange={onChangeTypeCustomer}
              allowClear
              bordered={true}
              defaultValue={'all'}
              showSearch
              filterOption={(input, option) =>
                removeUnicode(option.children.toString())
                  .toLowerCase()
                  .trim()
                  .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
              }
            >
              <Select.Option value="all">Tất cả loại khách hàng</Select.Option>
              {customerTypes.map((type, index) => (
                <Option value={type.type_id} key={index}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={12} lg={3} xl={3}>
            <Button
              style={{
                display:
                  Object.keys(paramsFilter).length >= 1 || valueSearch !== '' 
                    ? 'inline'
                    : 'none',
                width: '100%',
              }}
              onClick={onClickClear}
              type="primary"
            >
              Xóa tất cả lọc
            </Button>
          </Col>
        </Row>
        <Table
          style={{ width: '100%', marginTop: 10 }}
          rowKey="customer_id"
          scroll={{ y: 450 }}
          loading={tableLoading}
          columns={columnCustomer.map((column) => {
            if (column.key === 'stt')
              return {
                ...column,
                width: 60,
                render: (text, record, index) =>
                  (pagination.page - 1) * pagination.pageSize + index + 1,
              }
            if (column.key === 'code')
              return {
                ...column,
                render: (text, record) =>
                  permissions.includes(PERMISSIONS.xem_chi_tiet_khach_hang) ? (
                    <Link
                      to={{ pathname: ROUTES.CUSTOMER_DETAIL + '/' + record.code, state: record }}
                    >
                      {record.code}
                    </Link>
                  ) : (
                    <div
                        style={{ color: '#5F73E2', cursor: 'pointer' }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        {record.code}
                      </div>
                  ),
                sorter: (a, b) => compare(a, b, 'code'),
              }
            if (column.key === 'name')
              return {
                ...column,
                render: (text, record) => (
                  <p>
                    {record.first_name == null
                      ? '' + ' ' + record.last_name
                      : record.first_name + ' ' + record.last_name}
                  </p>
                ),
                sorter: (a, b) => a.first_name.length - b.first_name.length,
              }
            if (column.key === 'type')
              return {
                ...column,
                render: (text, record) => (record._type ? record._type.name : ''),
                sorter: (a, b) => compare(a, b, 'type'),
              }
            if (column.key === 'phone')
              return {
                ...column,
                render: (text, record) => record.phone || '',
                sorter: (a, b) => compare(a, b, 'phone'),
              }
            if (column.key === 'point')
              return {
                ...column,
                render: (text, record) => record.point && formatCash(record.point),
                sorter: (a, b) => compare(a, b, 'point'),
              }
            if (column.key === 'used_point')
              return {
                ...column,
                render: (text, record) => record.used_point && formatCash(record.used_point),
                sorter: (a, b) => compare(a, b, 'used_point'),
              }
            if (column.key === 'order_quantity')
              return {
                ...column,
                render: (text, record) =>
                  record.order_quantity && formatCash(record.order_quantity),
                sorter: (a, b) => compare(a, b, 'order_quantity'),
              }
            if (column.key === 'order_total_cost')
              return {
                ...column,
                render: (text, record) =>
                  record.order_total_cost && formatCash(record.order_total_cost),
                sorter: (a, b) => compare(a, b, 'order_total_cost'),
              }
            if (column.key === 'debt')
              return {
                ...column,
                render: (text, record) => record.debt && formatCash(record.debt),
                sorter: (a, b) => compare(a, b, 'debt'),
              }
            if (column.key === 'create_date')
              return {
                ...column,
                render: (text, record) =>
                  record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
                sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
              }
            if (column.key === 'birthday')
              return {
                ...column,
                render: (text, record) =>
                  record.birthday && moment(record.birthday).format('DD-MM-YYYY'),
                sorter: (a, b) => moment(a.birthday || '').unix() - moment(b.birthday || '').unix(),
              }
            if (column.key === 'address')
              return {
                ...column,
                render: (text, record) => (
                  <p className={styles['text-height']}>
                    {' '}
                    {record.address && record.address + ', '}
                    {record.district &&
                      (record.district.district_name || record.district || '') + ', '}
                    {record.province && (record.province.province_name || record.province || '')}
                  </p>
                ),
                sorter: (a, b) => compare(a, b, 'address'),
              }
            if (column.key === 'action')
              return {
                ...column,
                render: (text, record) =>
                  record.customer_id !== 1 ? (
                    <div className={styles['display']}>
                      <>
                        {permissions.includes(PERMISSIONS.cap_nhat_khach_hang) ? (
                          <ModalCustomer record={record}>
                            <Button danger type="primary" icon={<EditFilled />} />
                          </ModalCustomer>
                        ) : (
                          <ModalCustomer record={record}>
                            <Button disabled danger type="primary" icon={<EditFilled />} />
                          </ModalCustomer>
                        )}
                      </>
                      <>
                        {permissions.includes(PERMISSIONS.xoa_khach_hang) ? (
                          <Popconfirm
                            title="Bạn có muốn xóa khách hàng này không?"
                            cancelText="Từ chối"
                            okText="Đồng ý"
                            onConfirm={() => _deleteCustomer(record.customer_id)}
                          >
                            <Button danger type="primary" icon={<DeleteOutlined />} />
                          </Popconfirm>
                        ) : (
                          <Button disabled danger type="primary" icon={<DeleteOutlined />} />
                        )}
                      </>
                    </div>
                  ) : (
                    <></>
                  ),
              }

            return column
          })}
          dataSource={customerShow}
          size="small"
          pagination={{
            position: ['bottomLeft'],
            current: pagination.page,
            pageSize: pagination.pageSize,
            pageSizeOptions: [20, 30, 40, 50, 70, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => setPagination({ page, pageSize }),
            total: countCustomer,
          }}
          summary={(pageData) => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {columnCustomer.map((e, index) => {
                  if (e.key === 'stt')
                    return (
                      <Table.Summary.Cell>
                        <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                      </Table.Summary.Cell>
                    )
                  if (e.key === 'point')
                    return <Table.Summary.Cell>{formatCash(total?.point || 0)}</Table.Summary.Cell>
                  if (e.key === 'used_point')
                    return (
                      <Table.Summary.Cell>{formatCash(total?.used_point || 0)}</Table.Summary.Cell>
                    )
                  if (e.key === 'order_quantity')
                    return (
                      <Table.Summary.Cell>
                        {formatCash(total?.order_quantity || 0)}
                      </Table.Summary.Cell>
                    )
                  if (e.key === 'order_total_cost')
                    return (
                      <Table.Summary.Cell>
                        {formatCash(total?.order_total_cost || 0)}
                      </Table.Summary.Cell>
                    )
                  if (e.key === 'debt')
                    return <Table.Summary.Cell>{formatCash(total?.debt || 0)}</Table.Summary.Cell>
                  return <Table.Summary.Cell></Table.Summary.Cell>
                })}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        </>):null}
        
      </div>
    </Spin>
  )
}
