import { formatCash } from 'utils'
import moment from 'moment'
import DefaultLogo from 'assets/img/default_logo_ekata.png'

export const convertTemplate = (order_example, _value, dataUser) => {
    if (order_example === undefined) return ''

    _value = String(_value).replace(
      /{dia_chi_shop}/gi,
      '22 đường số 9, Quang Trung, Gò Vấp, Hồ Chí Minh'
    )
    let content_product_details = `<table style="border-collapse: collapse; border: 0.5px dotted #c1c1c1;width:100%">`
    content_product_details += `
    <tr style="font-family:Arial,sans-serif;font-size: 9px;font-weight: 600">
    <td style="padding: 1%; width: 25%; text-align: left;border: 0.5px dotted #c1c1c1"><span>Tên SP</span></td>
    <td style="padding: 1%; width: 10%; text-align: right;border: 0.5px dotted #c1c1c1"><span>SL </span></td>
    <td style="padding: 1%; width: 20%; text-align: right;border: 0.5px dotted #c1c1c1"><span>Đơn giá </span></td>
    <td style="padding: 1%; width: 20%; text-align: right;border: 0.5px dotted #c1c1c1"><span>T.Tiền </span></td>
</tr>`
    for (let i = 0; i < order_example.order_details.length; i++) {
      content_product_details += `<tr>
			<td style="padding: 1%; width: 25%; text-align: left; border: 0.5px dotted #c1c1c1"><span>${
        order_example.order_details[i].title
      }</span></td>
			<td style="padding: 1%; width: 10%; text-align: right;border: 0.5px dotted #c1c1c1"><span>${
        order_example.order_details[i].quantity
      }</span></td>
      <td style="padding: 1%; width: 20%; text-align: right;border: 0.5px dotted #c1c1c1"><span>${formatCash(
        order_example.order_details[i].price -
          order_example.order_details[i].total_discount / order_example.order_details[i].quantity
      )}</td>
			<td style="padding: 1%; width: 20%; text-align: right;border: 0.5px dotted #c1c1c1">${formatCash(
        order_example.order_details[i].price * order_example.order_details[i].quantity -
          order_example.order_details[i].total_discount
      )}</td>
		</tr>`
    }
    
    content_product_details += '</table>'
    var footer = `<tr>
      <td style="text-align: right;width: 30%;">
      <img src="${DefaultLogo}" style="width: 30px; height: 30px;">
        </td>
      <td style="text-align: left">
          <strong>Ekata.vn</strong>
      </td> 
      </tr>` 
      let fee_shipping = ``
      if(order_example.is_delivery && !order_example.payer_shipping){
        fee_shipping = `<tr>
        <td  width="50%" nowrap>Phí ship: </td>
        <td  style="text-align: right;margin-left: 40px">${formatCash(order_example.shipping_info.fee_shipping)}</td>
      </tr>`
      }
      
    _value = String(_value).replace(
      /{logo}/,
      dataUser._business.business_logo ? dataUser._business.business_logo : DefaultLogo
    )
    _value = String(_value).replace(/{business_name}/gi, dataUser._business.business_name)
    _value = String(_value).replace(/{business_address}/gi, dataUser._business.business_address)
    _value = String(_value).replace(/{business_phone}/gi, dataUser._business.business_phone)
    _value = String(_value).replace(/{phone}/gi, dataUser._business.business_phone)

    _value = String(_value).replace(
      /{sdt_shop}/gi,
      order_example.sale_location.length > 0 ? order_example.sale_location[0].phone : ''
    )

    if (order_example.employee) {
      _value = String(_value).replace(
        /{nhan_vien_ban_hang}/gi,
        order_example.employee.first_name + ' ' + order_example.employee.last_name
      )
    }

    if (order_example.create_date !== undefined) {
      _value = String(_value).replace(
        /{ngay_ban}/gi,
        moment(order_example.create_date).format('HH:mm yyyy/MM/DD')
      )

      _value = String(_value).replace(
        /{ngay_ban_hoa_don}/gi,
        moment(order_example.create_date).format('HH:mm yyyy/MM/DD')
      )
    }

    _value = String(_value).replace(/{tieu_de_hoa_don}/gi, 'HÓA ĐƠN BÁN HÀNG')

    if (order_example.customer) {
      _value = String(_value).replace(/{sdt_kh}/gi, order_example.customer.phone)
    }
    if (order_example.customer) {
      _value = String(_value).replace(/{diem_tich_luy}/gi, order_example.customer.point || 0)
    }
    if (order_example.customer) {
      _value = String(_value).replace(/{dia_chi_kh}/gi, order_example.customer.address)
    }

    if (order_example.customer) {
      _value = String(_value).replace(
        /{ten_kh}/gi,
        order_example.customer.first_name + ' ' + order_example.customer.last_name
      )
    }
    _value = String(_value).replace(
      /{tong_tien}/gi,
      formatCash(
        order_example.order_details.map((item) => item.total_cost).reduce((a, b) => a + b) -
          order_example.order_details.map((item) => item.total_discount).reduce((a, b) => a + b)
      )
    )

    _value = String(_value).replace(/{ma_hoa_don}/gi, '#' + order_example.code)
    _value = String(_value).replace(/{ghi_chu}/gi, order_example.note)
    _value = String(_value).replace(/{danh_sach_san_pham}/gi, content_product_details)
    _value = String(_value).replace(/{VAT}/gi, formatCash(order_example.total_tax))
    _value = String(_value).replace(/{ship}/gi, fee_shipping)

    _value = String(_value).replace(
      /{tax%}/gi,
      formatCash(order_example.list_tax.map((item) => `${item.value}%`))
    )
    if(order_example.is_delivery && order_example.payer_shipping){

    }
    _value = String(_value).replace(/{khach_phai_tra}/gi, formatCash(order_example.final_cost))
    _value = String(_value).replace(/{tien_khach_tra}/gi, formatCash(order_example.customer_paid))
    _value = String(_value).replace(/{tien_thua}/gi, formatCash(order_example.excessCash))
    let total_discount_Tmp = order_example.total_discount
    for (let i = 0; i < order_example.order_details.length; i++) {
      total_discount_Tmp = total_discount_Tmp - order_example.order_details[i].total_discount
    }
    _value = String(_value).replace(/{chiet_khau}/gi, formatCash(total_discount_Tmp))
    _value = String(_value).replace(/{ }/gi, footer)

    return _value
  }
