import removeVietnameseTones from './removeUnicode'

export const searchComponent = (searchValue, setFilteredResults, products) => {
  searchValue = removeVietnameseTones(searchValue)
  searchValue = searchValue.trim()
  searchValue = searchValue.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/g,
    ''
  )
  if (searchValue.length > 0) {
    const filteredData = products.filter((item) => {
      let newObject = { title: item.title, sku: item.sku, barcode: item.barcode }
      return removeVietnameseTones(Object.values(newObject).join('').toLowerCase()).includes(
        searchValue.toLowerCase().trim()
      )
    })
    setFilteredResults(filteredData)
  } else {
    setFilteredResults([])
  }
}

export const searchComponentProduct = (
  searchValue,
  setFilteredResults,
  setTotalResults,
  setCount,
  products
) => {
  searchValue = removeVietnameseTones(searchValue)
  if (searchValue.includes(' ')) {
    searchValue = new RegExp(searchValue.replace(/ /g, '.*'))
  } else {
    searchValue = new RegExp(searchValue)
  }
  const filteredData = products.filter((item) => {
    let newObject = {
      name: item.name,
      sku: item.sku,
      barcode: item.barcode,
      price: item.price,
      description: item.description,
    }
    newObject = removeVietnameseTones(Object.values(newObject).join(' ').toLocaleLowerCase())
    return searchValue.test(newObject)
  })
  let totalObject = { total_inventory: 0, open_sell_quantity: 0 }
  filteredData.forEach((element) => {
    totalObject.open_sell_quantity += element.open_sell_quantity
    totalObject.total_inventory += element.total_inventory
  })
  setFilteredResults(filteredData)
  setTotalResults(totalObject)
  setCount(filteredData.length)
}

export const searchComponentAll = (searchValue, data) => {
  searchValue = removeVietnameseTones(searchValue)
  if (searchValue.includes(' ')) {
    searchValue = new RegExp(searchValue.replace(/ /g, '.*'))
  } else {
    searchValue = new RegExp(searchValue)
  }
  const filteredData = data.filter((item) => {
    let newObject = removeVietnameseTones(Object.values(item).join(' ').toLocaleLowerCase())
    return searchValue.test(newObject)
  })
    return filteredData
}
