import { Badge, Button, notification, Switch } from 'antd'
import React, { useState } from 'react'
import styles from '../styles/card-item-payment.module.scss'
import ModalInfoPayment from './modalInfoPayment'

const CardItemPayment = ({item}) => {

    const [active, setActive] = useState(false)

    const handleActivePayment = (e) => {
        if(item.check == false) return notification.error({key: 'check-connect', message: 'Vui lòng liên kết!'})
        setActive(e)
    }

  return (
    <Badge.Ribbon color={item.check == true ? "green" : "red"} text={item.check == true ? "Đã liên kết" : "Chưa liên kết"}>
    <div className={styles['card-item']}>
        <div className={styles['left-card']}>
            <img alt='' src={item.icon}/>
            <p>{item.name}</p>
        </div>
        <div className={styles['right-card']}>
            <Switch checked={active} onChange={handleActivePayment}/>
            <ModalInfoPayment item={item}/>
        </div>
    </div>
    </Badge.Ribbon>
  )
}

export default CardItemPayment