import React, { useEffect, useState } from 'react'
import { Col, Row, Space, Rate, notification } from 'antd'
import styles from '../feeds.module.scss'
import { CheckCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { Button } from 'components/Button/Button'
import { formatCash } from 'utils'
import { InteractionIcons } from '../items/interactionIcons'
// import { addToCart, getCartBusiness } from 'apis/cart'

export const FooterPost = ({ item }) => {
  const [cartCheck, setCartCheck] = useState(false)


  return (
    <div style={{ paddingTop: '0px', paddingBottom: '3px' }} className={styles['container-item']}>
      <Row className={styles['container-content']}>
        <Col style={{ display: 'inline-block', width: '70%' }}>
          <h1 className={styles['text-price']}>
            {' '}
            {item.products?.variants?.length > 1
              ? (Math.min(...item.products?.variants?.map((item) => item.price)) !==
                Math.max(...item.products?.variants?.map((item) => item.price)))
                ? `${formatCash(
                    Math.min(...item.products?.variants?.map((item) => item.price))
                  )} - ${formatCash(Math.max(...item.products?.variants?.map((item) => item.price)))}`
                : `${formatCash(item.products?.variants[0].price)}`
              : `${formatCash(item.products?.variants[0].price)}`}{' '}
            VND
          </h1>

          <InteractionIcons item={item} />

          <Row>
            <p className={styles['text-like']}>
              <a>{item.likes.length} người</a> đã thích sản phẩm này
            </p>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
