import styles from '../import-stock.module.css'
import localStorage from '../../../utils/localStorage'

//antd
import { message, notification, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import React, { useState } from 'react'

const { Dragger } = Upload

export default function Step1({ submit }) {
  let accessToken = localStorage.getItem('accessToken')
  if (!accessToken) {
    accessToken = Cookies.get('accessToken')
  }

  const props = {
    name: 'file',
    action: process.env.REACT_APP_API_ENDPOINT_DEV + '/import-stock-adjustment/stockAjustment/upload-excel',
    headers: {
      'X-Ekata-Site': window.location.hostname,
      'X-Ekata-Token': accessToken,
    },
    async onChange(info) {
      var extension = info.file.name.split('.').pop().toLowerCase()
      const allowedExtensions =  ['xlsx', 'xls', 'xlsm']
      let check = allowedExtensions.includes(extension)

      if(!check){
        notification.warning({key: 'fail file',message: 'Yêu cầu nhập file đúng định dạng'})
      }else{
        const { status } = info.file
        if (status === 'done') {
          await submit({ step: 1, path: info.file.response.path })
        } else if (status === 'error') {
          message.error(`${info.file.name} tải lên bị lỗi`)
        }
      } 
    },
  }

  return (
    <>
      <div>
        <a href="https://s3.ap-southeast-1.wasabisys.com/ekata-production/public/2023/01/30/1675068158789-708175723-KiemHang.xlsx">
          Tải về file import mẫu
        </a>
        <br />
        <br />
        <span>
          <strong>Chú ý:</strong>
          <br />- Chỉ nhập sản phẩm nâng cao
        </span>
      </div>
      <div className={styles['step-1']}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Nội dung hint</p>
        </Dragger>
      </div>
    </>
  )
}
