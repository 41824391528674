import React, { useState, useEffect, useRef } from 'react'
import styles from './style.module.scss'
import columns from './columns'
import { useHistory } from 'react-router-dom'
import { ACTION, PAGE_SIZE, PERMISSIONS, ROUTES, TIME_FORMAT } from 'consts'
import moment from 'moment'
import { compare, compareCustom } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

//antd
import { Row, Select, Table, Col, Input, Button, Modal, Form, notification, TimePicker } from 'antd'

//icons
import {
  CloseOutlined,
  ArrowLeftOutlined,
  SearchOutlined,
} from '@ant-design/icons'

//components
import TitlePage from 'components/title-page'
import FilterDate from 'components/filter-date'
import Permission from 'components/permission'

//apis
import { getStaff } from 'apis/manage-staff'
import { getRollCall, updateRollCall } from 'apis/roll-call'
export default function RollCall() {
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const history = useHistory()
  const typingTimeoutRef = useRef(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [employees, setEmployees] = useState([])
  const [rollCalls, setRollCalls] = useState([])
  const [count, setCount] = useState(0)
  const [hours, setHours] = useState('')
  const [rollCallUpdate, setRollCallUpdate] = useState({})
  const [timeStartShow, setTimStartShow] = useState('')
  const [timeEndShow, setTimeEndShow] = useState('')
  const [timeStart, setTimStart] = useState('')
  const [timeEnd, setTimeEnd] = useState('')
  const [timeStartChange, setTimStartChange] = useState(false)
  const [timeEndChange, setTimeEndChange] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [valueSearch, setValueSearch] = useState('')
  const [valueTime, setValueTime] = useState()
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.trim()

      if (value) paramsFilter.search = value
      else delete paramsFilter.search
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }
  const onFilters = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }
  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setValueSearch('')
    setValueTime()
  }

  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp,status: 1 })
      if (res.status === 200) setEmployees(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getRollcall = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getRollCall({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        setRollCalls(res.data.data)
        setCount(res.data.count)
        let sum =
          addLeadingZero(Math.floor(res.data.sumTime / 3600)) +
          'h' +
          addLeadingZero(Math.floor((res.data.sumTime % 3600) / 60))
        setHours(sum)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _update = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldValue()
      let start_date = moment(rollCallUpdate.start_time).format('YYYY-MM-DD')
      const body = {
        branch_id: branchIdApp,
        roll_call_id: rollCallUpdate.roll_call_id,
        start_time: timeStartChange
          ? moment(`${start_date} ${timeStart}`).format()
          : rollCallUpdate.start_time,
        end_time: timeEndChange
          ? moment(`${start_date} ${timeEnd}`).format()
          : rollCallUpdate.end_time,
      }
      if(timeEndChange || timeStartChange){
        if(moment(`${start_date} ${timeEnd}`).hours() < moment(`${start_date} ${timeStart}`).hours()){
          notification.error({ message: 'Giờ ra ca không thể nhỏ hơn giờ vào ca' })
          return
        }
      }
      const res = await updateRollCall(body)
      if (res.status === 200) {
        notification.success({ message: res.data.message || 'Cập nhật thành công' })
        toggle()
        _getRollcall()
        setRollCallUpdate()
        form.resetFields()
      } else {
        notification.error({ message: res.data.message || 'Cập nhật thất bại' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  function addLeadingZero(number) {
    return number < 10 ? `0${number}` : `${number}`
  }
  useEffect(() => {
    _getRollcall()
    _getEmployees()
  }, [paramsFilter, branchIdApp])
  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.REPORTS)}
          >
            Danh sách ca làm việc của nhân viên
          </Row>
        }
      ></TitlePage>
      <Row align="middle" style={{ border: '1px solid #d9d9d9', borderRadius: 5, marginTop: 10,marginBottom:10, backgroundColor:'white' }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          style={{
            borderRadius: 5,
            borderRight: '1px solid #d9d9d9',
          }}
        >
          <Input
            style={{ width: '100%' }}
            prefix={<SearchOutlined />}
            name="name"
            value={valueSearch}
            onChange={onSearch}
            placeholder="Tìm kiếm ..."
            bordered={false}
            defaultValue={''}
            allowClear
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <FilterDate
            style={{ with: '100%', borderRight: '1px solid #d9d9d9' }}
            bordered={false}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            width="100%"
            title="làm việc"
            valueTime={valueTime}
            setValueTime={setValueTime}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Select
            clearIcon={<CloseOutlined />}
            allowClear
            bordered={false}
            showSearch
            optionFilterProp="children"
            placeholder="Nhân viên"
            style={{ width: '100%', borderRight: '1px solid #d9d9d9' }}
            value={paramsFilter.phone || 'all'}
            defaultValue={'all'}
            onChange={(value) => onFilters('phone', value)}
          >
            <Select.Option value="all">Tất cả nhân viên</Select.Option>
            {employees.map((employee, index) => (
              <Select.Option value={employee.phone} key={index}>
                {employee.first_name} {employee.last_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Button
            style={{ display: Object.keys(paramsFilter).length < 3 && 'none', width: '100%' }}
            onClick={onClickClear}
            type="primary"
            danger
          >
            Xóa tất cả lọc
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={rollCalls}
        size="small"
        scroll={{ y: 500 }}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: [20, 30, 40, 50, 70, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: count,
        }}
        columns={columns.map((column) => {
          if (column.key === 'stt') {
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          }
          if (column.key === 'full_name')
            return {
              ...column,
              render: (text, record) => record.user.full_name,
              sorter: (a, b) => compare(a?.user, b?.user, 'full_name'),
            }
          if (column.key === 'phone') {
            return {
              ...column,
              render: (text, record) => record.phone,
            }
          }
          if (column.key === 'start_time')
            return {
              ...column,
              sorter: (a, b) =>
                compareCustom(
                  a.start_time ? `${a.start_time}` : '',
                  b.start_time ? `${b.start_time}` : ''
                ),
              render: (text, record) => moment(record.start_time).format(TIME_FORMAT.DATE_TIME_MINUTE),
            }
          if (column.key === 'end_time')
            return {
              ...column,
              sorter: (a, b) =>
                compareCustom(a.end_time ? `${a.end_time}` : '', b.end_time ? `${b.end_time}` : ''),
              render: (text, record) => record.end_time ? moment(record.end_time).format(TIME_FORMAT.DATE_TIME_MINUTE) : '',
            }
          if (column.key === 'time_work')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'time_work'),
              render: (text, record) => (
                <div style={{ fontWeight: '600' }}>
                  {addLeadingZero(Math.floor(record.time_work / 3600)) +
                    'h' +
                    addLeadingZero(Math.floor((record.time_work % 3600) / 60))}
                </div>
              ),
            }
          if (column.key === 'shift_name')
            return {
              ...column,
              render: (text, record) => record?.shift?.title,
            }
          if (column.key === 'updator')
            return {
              ...column,
              sorter: (a, b) =>
                compareCustom(
                  a.updator ? `${a.updator.first_name} ${a.updator.last_name}` : '',
                  b.updator ? `${b.updator.first_name} ${b.updator.last_name}` : ''
                ),
              render: (text, record) => (
                <>
                  <div>
                    {record.update_at
                      ? `${record.updator.first_name} ${record.updator.last_name}`
                      : ''}
                  </div>
                  <div>{record.update_at ? moment(record.update_at).format(TIME_FORMAT.DATE_TIME) : ''}</div>
                </>
              ),
            }
          if (column.key === 'action')
            return {
              ...column,
              render: (text, record) => 
                record?.end_time !== '' ? (<div
                  onClick={() => {
                    setRollCallUpdate(record)
                    setTimStartShow(record?.start_time !== 'Invalid date' ? record.start_time : '')
                    setTimeEndShow(record?.end_time !== 'Invalid date' ? record.end_time : '')
                    setTimStart(record?.start_time !== 'Invalid date' ? moment(record.start_time).format('HH:mm') : '')
                    setTimeEnd(record?.end_time !== 'Invalid date' ? moment(record.end_time).format('HH:mm') : '')
                    toggle()
                  }}
                  style={{ color: '#335bd3', cursor: 'pointer' }}
                >
                  Cập nhật
                </div>) : null
              
              ,
            }
          return column
        })}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <div style={{ fontWeight: 700 }}>Tổng cộng</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700, textAlign: 'center' }}>{hours}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <Modal
        visible={visible}
        width={700}
        onCancel={toggle}
        title="Cập nhật ca làm việc"
        closeIcon={<CloseOutlined onClick={toggle} />}
        footer={
          <Row justify="end">
            <Button onClick={toggle} loading={loading} size="large" style={{ width: 120 }}>
              Đóng
            </Button>
            <Permission permissions={[PERMISSIONS.cap_nhat_diem_danh]}>
              <Button
                onClick={_update}
                loading={loading}
                size="large"
                type="primary"
                style={{ width: 120 }}
              >
                Cập nhật
              </Button>
            </Permission>
          </Row>
        }
      >
        <div>
          <Form requiredMark={false} form={form} layout="vertical" style={{ width: '100%' }}>
            <Row style={{ marginBottom: '24px' }}>
              <Col span={12}>
                <div style={{ display: 'flex' }} className={styles['name']}>
                  <div style={{ marginRight: '10px' }}>Họ tên:</div>
                  <div>
                    {rollCallUpdate?.user?.first_name} {rollCallUpdate?.user?.last_name}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ display: 'flex' }} className={styles['name']}>
                  <div style={{ marginRight: '10px' }}>Số điện thoại:</div>
                  <div>{rollCallUpdate?.phone}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                  <TimePicker
                    value={timeStartShow !== '' ? dayjs(timeStartShow, 'HH:mm') :''}
                    style={{ height: '40px' }}
                    format={'HH:mm'}
                    onChange={(value, time) =>{
                      setTimStartShow(value)
                       setTimStart(time)
                       setTimStartChange(true)
                      }}
                  />
              </Col>
              <Col span={12}>
                  <TimePicker
                    value={timeEndShow !== '' ? dayjs(timeEndShow, 'HH:mm') :''}
                    style={{ height: '40px' }}
                    format={'HH:mm'}
                    onChange={(value, time) =>{
                      setTimeEndShow(value)
                      setTimeEnd(time)
                      setTimeEndChange(true)
                    }}
                  />
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}
