import { getPosts, likePostFeed, unLikePostFeed } from 'apis/feed'

export const getPostsSystem = () => async (dispatch) => {
    try {
      const res = await getPosts()
      dispatch({ type: 'SET_POSTS', payload: res.data.data });
    } catch (error) {
      console.log(error)
    }
  };
export const likePost = ({post, user}) => async (dispatch) => {
    const newPost = {...post, likes: [...post.likes, user.user_id]}
    dispatch({ type: 'UPDATE_POST', payload: newPost})
  
    try {
      await likePostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };
  
export const unLikePost = ({post, user}) => async (dispatch) => {
    const newPost = {...post, likes: post.likes.filter(id => id !== user.user_id )}
    dispatch({ type: 'UPDATE_POST', payload: newPost})
    try {
      await unLikePostFeed(post.post_id);
    } catch (error) {
      console.log(error)  
    }
  };