import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import styles from './promotion.module.scss'
import {
  Modal,
  Row,
  Col,
  Button,
  Checkbox,
  Space,
  Input,
  notification,
  Image,
  Tooltip,
  Popover,
  Spin,
} from 'antd'
import { CheckCircleOutlined, CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons'
import gift from 'assets/icons/gift.png'
import { formatCash } from 'utils'
//apis
import { getPromotions, checkVoucher } from 'apis/promotion'
import DefaultLogo from 'assets/img/default_logo_ekata.png'
import { ACTION } from 'consts'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function PromotionAvailable({ invoiceCurrent, editInvoice, checkData }) {
  const { t } = useTranslation()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [voucherCheck, setVoucherCheck] = useState('')

  const [availableApply, setAvailableApply] = useState(false)

  const [checkPromotionData, setCheckPromotionData] = useState(false)

  const [promotions, setPromotions] = useState([])
  const [promotionCheck, setPromotionCheck] = useState({})
  const [promotionFind, setPromotionFind] = useState(null)
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const dispatch = useDispatch()
  const PromotionItem = ({ promotion }) => {
    if (promotion == undefined) return

    let item_ids, checkId, newDataProduct, sumCostProduct
    if (promotion?.type_voucher === 3) {
      item_ids = invoiceCurrent?.order_details?.map((item) => item.variant_id)
      checkId = promotion?.apply_product_ids?.filter((item) => item_ids?.includes(item))
      newDataProduct = invoiceCurrent?.order_details?.filter((item) =>
        checkId?.includes(item.variant_id)
      )
      sumCostProduct = newDataProduct?.reduce(
        (total, item) => (total += item.quantity * (item.price - (item.discount || 0))),
        0
      )
    }
    return (
      // <Row>
      //   <Col xs={6} sm={6}>
      //     <Checkbox
      //       disabled={
      //         invoiceCurrent.moneyToBePaidByCustomer < promotion.max_discount ||
      //         invoiceCurrent.order_details.length === 0
      //       }
      //       checked={promotionCheck && promotionCheck._id === promotion._id ? true : false}
      //       onClick={(e) => {
      //         const checked = e.target.checked
      //         if (checked) {
      //           if (invoiceCurrent.order_details.length == 0) {
      //             notification.warning({
      //               message: t('sell.your_order_has_no_products_please_select_the_product_first'),
      //             })
      //             return
      //           }

      //           if (promotion.order_value_require != 0) {
      //             if (invoiceCurrent.moneyToBePaidByCustomer < promotion.order_value_require) {
      //               notification.warning({
      //                 message: `Cần mua tối thiểu ${formatCash(
      //                   promotion.order_value_require
      //                 )} để được giảm giá`,
      //               })
      //               return
      //             }
      //           }

      //           // Tính toán giá trị được giảm
      //           if (promotion.type == 'PERCENT') {
      //             promotion.discount =
      //               parseInt(
      //                 parseInt(promotion.value) *
      //                   parseInt(invoiceCurrent.moneyToBePaidByCustomer || 0)
      //               ) / 100
      //             if (
      //               promotion.discount > promotion.max_discount_value &&
      //               promotion.max_discount_value > 0
      //             ) {
      //               promotion.discount = promotion.max_discount_value
      //             }
      //           } else {
      //             promotion.discount = promotion.value
      //             if (
      //               promotion.discount > promotion.max_discount_value &&
      //               promotion.max_discount_value > 0
      //             ) {
      //               promotion.discount = promotion.max_discount_value
      //             }
      //           }

      //           setPromotionCheck({ ...promotion })
      //           setVoucherCheck(promotion.promotion_code)
      //           setAvailableApply(true)
      //         } else {
      //           setPromotionCheck({})
      //           setVoucherCheck('')
      //           setAvailableApply(false)
      //         }
      //       }}
      //     >
      //       {promotion.name}
      //     </Checkbox>
      //   </Col>
      //   <Col xs={6} sm={6}>
      //     <p style={{ textAlign: 'center' }}>
      //       {formatCash(promotion.value)} {promotion.type === 'VALUE' ? '' : '%'}
      //     </p>
      //   </Col>
      //   <Col xs={6} sm={6}>
      //     <p style={{ textAlign: 'center' }}>
      //       {promotion.order_value_require > 0
      //         ? formatCash(promotion.order_value_require)
      //         : 'Luôn áp dụng'}
      //     </p>
      //   </Col>

      //   <Col xs={6} sm={6}>
      //     <p style={{ textAlign: 'center' }}>
      //       {promotion.max_discount_value > 0
      //         ? formatCash(promotion.max_discount_value)
      //         : 'Không giới hạn'}
      //     </p>
      //   </Col>
      // </Row>
      <div className={styles['container']}>
        <div className={styles['img']}>
          <Image src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
        </div>
        <Checkbox
          style={{ marginLeft: 8 }}
          // disabled={
          //   invoiceCurrent.moneyToBePaidByCustomer < promotion.order_value_require ||
          //   invoiceCurrent.order_details.length === 0 ||
          //   promotion.is_active === false
          //   // invoiceCurrent.deliveryAddress.type_id != promotion.apply_customer_type_ids
          // }
          disabled={
            invoiceCurrent.moneyToBePaidByCustomer < promotion.max_discount ||
            invoiceCurrent.order_details.length === 0
          }
          checked={promotionCheck && promotionCheck._id === promotion._id ? true : false}
          onClick={(e) => {
            const checked = e.target.checked
            if (checked) {
              if (invoiceCurrent.order_details.length == 0) {
                notification.warning({
                  message: t('sell.your_order_has_no_products_please_select_the_product_first'),
                })
                return
              }
              if (promotion.order_value_require != 0) {
                if (invoiceCurrent.sumCostPaid < promotion.order_value_require) {
                  notification.warning({
                    message: `Cần mua tối thiểu ${formatCash(
                      promotion.order_value_require
                    )} để được giảm giá`,
                  })
                  return
                }
              }

              // Tính toán giá trị được giảm
              if (promotion.type == 'PERCENT') {
                promotion.discount =
                  parseInt(
                    parseInt(promotion.value) *
                      parseInt(invoiceCurrent.sumCostPaid || 0)
                  ) / 100
                if (
                  promotion.discount > promotion.max_discount_value &&
                  promotion.max_discount_value > 0
                ) {
                  promotion.discount = promotion.max_discount_value
                }
              } else {
                promotion.discount = promotion.value
                if (
                  promotion.discount > promotion.max_discount_value &&
                  promotion.max_discount_value > 0
                ) {
                  promotion.discount = promotion.max_discount_value
                }
              }

              setPromotionCheck({ ...promotion })
              setVoucherCheck(promotion.promotion_code)
              setAvailableApply(true)
            } else {
              setPromotionCheck({})
              setVoucherCheck('')
              setAvailableApply(false)
            }
          }}
          // checked={promotionCheck && promotionCheck._id === promotion._id ? true : false}
          // onClick={(e) => {
          //   const checked = e.target.checked
          //   if (checked) {
          //     if (invoiceCurrent.moneyToBePaidByCustomer >= promotion.max_discount_value) {
          //       if (invoiceCurrent.order_details.length !== 0) setPromotionCheck(promotion)
          //       else
          //         notification.warning({
          //           message: t('sell.your_order_has_no_products_please_select_the_product_first'),
          //         })
          //     } else
          //       notification.warning({
          //         message: t('sell.your_order_value_is_not_eligible_to_apply_for_this_promotion'),
          //       })
          //   } else setPromotionCheck(null)
          // }}
        >
          <div className={styles['content']}>
            <h2 className={styles['title']}>
              Giảm {formatCash(promotion.value)} {promotion.type === 'VALUE' ? 'VNĐ' : '%'}
            </h2>
            <p className={styles['note']}>
              Đơn tối thiểu {formatCash(promotion.order_value_require) + 'VNĐ'} trở lên
            </p>
            <p className={styles['note']}>
              Giảm tối đa {formatCash(promotion.max_discount_value) + 'VNĐ'}
            </p>
            <p className={styles['note']}>Số lượng còn lại: {promotion.limit_quantity}</p>
          </div>
        </Checkbox>
        {promotion.is_active === true ? (
          <div style={{ width: 32 }}>
            <CheckCircleFilled style={{ color: '#de3434' }} className={styles['icon']} />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
  const _getPromotions = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getPromotions()
      let today = moment().startOf('date').unix()
      if (res.status === 200) {
        try {
          let newData = res.data.data.reduce((arr, item) => {
            if (item.end_date) {
              let someday = moment(item.end_date).startOf('date').unix()
              if (today < someday) {
                arr.push(item)
              }
            } else {
              arr.push(item)
            }
            return arr
          }, [])
          let newData2 = newData.reduce((arr, item) => {
            if (item.limit_quantity === 0) {
              arr.push(item)
            } else {
              if (item.voucher_quantity > 0) {
                arr.push(item)
              }
            }
            return arr
          }, [])
          setPromotions(newData2.filter((e) => !e.has_voucher && e.is_active === true))
        } catch (error) {
          console.log(error)
        }
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _applyVoucher = async () => {
    if (promotionCheck.discount > invoiceCurrent.moneyToBePaidByCustomer) {
      notification.warning({
        message: 'Khuyến mãi không được quá giá trị đơn hàng',
      })
    } else {
      try {
        const res = await getPromotions()
        if (res.status === 200) {
          let newCheckPromotion = res.data.data.find(
            (item) => item.promotion_code === promotionCheck.promotion_code
          )
          if (newCheckPromotion.limit_quantity > 0 && newCheckPromotion.voucher_quantity !== 0) {
            editInvoice('discount', promotionCheck)
            toggle()
          } else {
            notification.warning({
              key: 'newcheckPromotionQuantiti',
              message: 'Mã khuyến mãi đã hết lượt áp dụng!',
            })
          }
        }
      } catch (error) {}
    }
  }
  const _removeVoucher = () => {
    setCheckPromotionData(false)
    setPromotionCheck({})
    setVoucherCheck('')
    editInvoice('discount', {})
  }
  useEffect(() => {
    if (Object.keys(invoiceCurrent.discount).length === 0) {
      setCheckPromotionData(false)
      setPromotionCheck({})
      setVoucherCheck('')
    } else {
      setPromotionCheck(invoiceCurrent.discount)
      setCheckPromotionData(false)
      setVoucherCheck(invoiceCurrent.discount.promotion_code)
    }
  }, [invoiceCurrent.discount, visible])
  const _checkVoucher = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      if (availableApply) {
        setVoucherCheck('')
        setAvailableApply(false)
        setPromotionCheck({})
      } else {
        setLoading(true)
        const res = await checkVoucher(voucherCheck)
        if (res.status === 200) {
          if (res.data.data) setPromotionFind(res.data.data)
          else
            notification.warning({
              message: res.data.message || t('sell.voucher_check_failed_please_try_again'),
            })
        } else
          notification.warning({
            message: res.data.message || t('sell.voucher_check_failed_please_try_again'),
          })

        setLoading(false)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      setLoading(false)

      console.log(error)
    }
  }
  useEffect(() => {
    if (checkData) {
      setCheckPromotionData(true)
    } else {
      setCheckPromotionData(false)
    }
    if (checkData === undefined || checkData === null) {
      setCheckPromotionData(true)
      setPromotionCheck({})
    }
    _getPromotions()
  }, [checkData, visible])

  return (
    <div>
      {promotions && promotions.length !== 0 ? (
        <img
          onClick={toggle}
          src={gift}
          alt=""
          style={{ width: 16, height: 16, marginLeft: 8, cursor: 'pointer' }}
        />
      ) : (
        ''
      )}

      <Modal
        width={900}
        visible={visible}
        title="Khuyến mãi"
        onCancel={toggle}
        footer={
          <Row gutter={[24, 8]}>
            <Col span={6}>
              <Button
                onClick={_removeVoucher}
                type="primary"
                style={{
                  backgroundColor: 'white',
                  borderRadius: 5,
                  color: 'black',
                  borderColor: '#0877DE',
                }}
              >
                Huỷ áp dụng khuyến mãi
              </Button>
            </Col>
            <Col span={12}>
              {availableApply && promotionCheck.discount && (
                <div
                  style={{
                    textAlign: 'center',
                    color: 'black',
                    alignSelf: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}
                >
                  {'Giá trị được giảm giá: ' + formatCash(promotionCheck.discount) + ' VNĐ'}
                </div>
              )}
            </Col>
            <Col span={6}>
              <Button
                onClick={(e) => {
                  setVisible(false)
                }}
                type="primary"
                style={{
                  backgroundColor: 'white',
                  borderRadius: 5,
                  color: 'black',
                  borderColor: '#0877DE',
                  width: 80,
                }}
              >
                {t('common.close')}
              </Button>
              <Button
                disabled={!availableApply}
                onClick={_applyVoucher}
                type="primary"
                style={{
                  backgroundColor: '#0877DE',
                  borderRadius: 5,
                  borderColor: '#0877DE',
                }}
              >
                {t('common.apply')}
              </Button>
            </Col>
          </Row>
        }
      >
        {/* <Row>
          <Col xs={6} sm={6}>
            <h3 style={{ textAlign: 'center' }}>{t('sell.choose_1_promotion')}</h3>
          </Col>
          <Col xs={6} sm={6}>
            <h3 style={{ textAlign: 'center' }}>{t('sell.reduced_value')}</h3>
          </Col>
          <Col xs={6} sm={6}>
            <h3 style={{ textAlign: 'center' }}>{t('sell.order_limits_apply')}</h3>
          </Col>
          <Col xs={6} sm={6}>
            <h3 style={{ textAlign: 'center' }}>{t('sell.order_discount_limit_promotion')}</h3>
          </Col>
        </Row> */}
        {promotions.length > 0 &&
          promotions.map((promotion) => <PromotionItem promotion={promotion} />)}
        {promotions.length == 0 && (
          <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
            {'Không có chương trình khuyến mãi khả dụng'}
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          <h3 style={{ marginBottom: 0, fontSize: 17 }}>
            {t('sell.check_voucher_promotion_code')}
          </h3>
          <Space wrap={false}>
            <Input
              disabled={availableApply}
              value={voucherCheck}
              onChange={(e) => setVoucherCheck(e.target.value)}
              placeholder={t('sell.enter_voucher_promotion_code')}
              style={{ width: 300 }}
            />
            <Button
              onClick={_checkVoucher}
              loading={loading}
              type="primary"
              style={{
                backgroundColor: availableApply ? 'orange' : '#0877DE',
                borderRadius: 5,
                borderColor: '#0877DE',
              }}
            >
              {availableApply ? 'Sử dụng sau' : t('common.check')}
            </Button>
          </Space>
          {promotionFind && (
            <div style={{ marginTop: 20 }}>
              <PromotionItem promotion={promotionFind} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
