import React, { useState } from 'react'
import { Row, Select, Button, Spin, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusSquareFilled, CheckOutlined } from '@ant-design/icons'
import { PERMISSIONS } from 'consts'
import { useSelector } from 'react-redux'
import { validatePhone } from 'utils'
import Logo from 'assets/img/logo-icon.png'
const CustomerInfo = ({
  isBranchClosed,
  loadingCustomer,
  systemCustomers,
  anotherCustomers,
  setCustomers,
  toggleCustomer,
  inputRef,
  customers,
  invoices,
  indexInvoice,
  _editInvoice,
  addUserToCustomer,
  permissions,
  ModalAddCustomer,
  setCheckSyncPoint
}) => {
  const { t } = useTranslation()
  const dataUser = useSelector((state) => state.login.dataUser)
  const { ward, district, province } = useSelector((state) => state)
  const [phoneCheck, setPhoneCheck] = useState(0)

  return (
    <Row justify="space-between" align="middle" wrap={false}>
      <Select
        disabled={isBranchClosed()}
        notFoundContent={loadingCustomer ? <Spin /> : null}
        dropdownClassName="dropdown-select-search-product"
        allowClear
        showSearch
        style={{ width: '100%', marginRight: 20 }}
        placeholder={t('sell.finding_customers')}
        onSearch={(e) => {
          setPhoneCheck(e)
          if (e.length > 9) {
            if (systemCustomers.some((item) => item.phone === e) === true) {
                setCustomers(systemCustomers)
            } else {
              setCustomers(anotherCustomers.filter((item) => item.phone === e))
            }
          } else {
            setCustomers(systemCustomers)
          }

          const evenEnterKey = (event) => {
            if (event.keyCode == 13) {
              if (!validatePhone(inputRef.current)) {
              } else {
                toggleCustomer()
              }
              window.removeEventListener('keydown', evenEnterKey)
              inputRef.current = null
            }
          }
          if (
            e.length > 0 &&
            !systemCustomers.find((item) => item?.phone?.includes(e)) &&
            !anotherCustomers.find((item) => item?.phone?.includes(e))
          ) {
            inputRef.current = e
            window.addEventListener('keydown', evenEnterKey)
          }
        }}
        value={null}
        onChange={(e) => {
          let customer = customers.find((item) => item.phone === e)
          var _shipping_info = {
            ...invoices[indexInvoice].shipping_info,
            phone: customer.phone,
            first_name: customer.first_name,
            last_name: customer.last_name,
            address: customer.address,
            province_id:
              customer.province &&
              customer.province.length > 0 &&
              province
                .filter((item) => item.province_name === customer.province)
                .map((item) => item.province_id)
                .toString(),
            province: customer.province,
            district_id:
              customer.district &&
              customer.district.length > 0 &&
              district
                .filter((item) => item.district_name === customer.district)
                .map((item) => item.district_id)
                .toString(),
            district: customer.district,
            ward_code:
              customer.ward &&
              customer.ward.length > 0 &&
              ward
                .filter((item) => item.ward_name === customer.ward)
                .map((item) => item.ward_code)
                .toString(),
            ward: customer.ward,
          }
          _editInvoice('shipping_info', _shipping_info)
          _editInvoice('customer', customer)
          _editInvoice('name', `${customer.first_name} ${customer.last_name}`)
          if (phoneCheck.length > 9) {
            if (systemCustomers.map((item) => item.phone).includes(customer.phone) == true) {
              addUserToCustomer(true, customer)
            } else {
              addUserToCustomer(false, customer)
            }
          }
          setCheckSyncPoint(customer.auto_sync)
        }}
      >
        {customers.map((customer, index) => (
          <Select.Option value={customer.phone} key={index}>
            <Row
              style={{ padding: '7px 13px' }}
              align="middle"
              justify="space-between"
              wrap={false}
            >
              <div>
                {anotherCustomers.findIndex(e => e.phone === customer.phone) >= 0 ? 
                <img width={20} style={{marginRight:4}} src={Logo} alt='ekata-logo'></img>: null}
                <span style={{ fontWeight: 600, marginBottom: 0 }}>
                  {customer.first_name == null
                    ? ' ' + ' ' + customer.last_name + ' '
                    : customer.first_name + ' ' + customer.last_name + ' '}
                </span>
                <span style={{ marginBottom: 0 }}>{customer.phone}</span>
              </div>
              <CheckOutlined
                style={{
                  color: 'green',
                  fontSize: 18,
                  display:
                    invoices[indexInvoice] &&
                    invoices[indexInvoice].customer &&
                    invoices[indexInvoice].customer.phone === customer.phone
                      ? ''
                      : 'none',
                }}
              />
            </Row>
          </Select.Option>
        ))}
      </Select>
      {permissions.includes(PERMISSIONS.them_khach_hang) ? (
        <ModalAddCustomer />
      ) : (
        <Button
          disabled
          onClick={toggleCustomer}
          icon={
            <PlusSquareFilled
              disabled={isBranchClosed()}
              style={{ color: '#0362BA', fontSize: 34 }}
            />
          }
          style={{
            padding: 0,
            border: 'none',
          }}
        />
      )}
    </Row>
  )
}

export default CustomerInfo
