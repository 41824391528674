import { HeartFilled, HeartOutlined, MessageOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { likePost, unLikePost } from 'redux/actions/posts'
import styles from '../feeds.module.scss'

export const InteractionIcons = ({ item }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.login.dataUser)

  const [like, setLike] = useState(false)
  const [loadLike, setLoadLike] = useState(false)

  const handleLike = async () => {
    if (loadLike) return

    setLoadLike(true)
    await dispatch(likePost({ post: item, user }))
    setLoadLike(false)
  }
  const handleUnLike = async () => {
    if (loadLike) return

    setLoadLike(true)
    await dispatch(unLikePost({ post: item, user }))
    setLoadLike(false)
  }

  useEffect(()=>{
    if(item.likes.find(id => id === user.user_id)){
      setLike(true)
    }else{
      setLike(false)
    }
  },[item.likes, user.user_id])


  return (
    <Row className={styles['container-icon']}>
      <div className={styles.icon}>
        {
            like
            ? <HeartFilled style={{color: 'red'}} onClick={handleUnLike}/>
            : <HeartOutlined onClick={handleLike} />
        }
      </div>
      <div className={styles.icon}>
        <MessageOutlined />
      </div>
      <div className={styles.icon}>
        <ShareAltOutlined />
      </div>
    </Row>
  )
}
