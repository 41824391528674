import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from './style.scss'
import { PERMISSIONS } from 'consts'
import Permission from 'components/permission'

import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  notification,
  Card,
  Divider,
  Spin,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { getAllUserSystem, createStaff } from 'apis/manage-staff'
import { validatePhone } from 'utils'
import { DeleteOutlined } from '@ant-design/icons'

const StatusRole = ({ record, branches, dataStaff, admin, roles = [] }) => {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { Option } = Select
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const [visible2, setVisible2] = useState(false)
  const toggle = () => setVisible2(!visible2)

  const [postData, setPostData] = useState({
    loading: false,
    error: false,
    data: [],
  })
  const [dataUser, setDataUser] = useState([])
  const { phone, role, description } = dataUser
  const [validDataUser, setValidDataUser] = useState(0)

  const [dataStaffs, setDataStaffs] = useState([])

  const _getAllUser = async () => {
    try {
      setLoading(true)
      const res = await getAllUserSystem({})
      if (res.status === 200) {
        setDataUser(res.data.data.filter((employee) => employee.phone !== admin.phone))
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const showModal = () => {
    setVisible(true)
  }


  const handleAddDataStaff = () => {
    let newData = [...dataStaffs]

    form.validateFields()
    const dataForm = form.getFieldsValue()
    if (!dataForm.phone) {
      return notification.error({ key: 'phoneValid', message: 'Vui lòng chọn số điện thoại' })
    }
    if (!dataForm.role_id) {
      return notification.error({ key: 'phoneValid', message: 'Vui lòng chọn vai trò' })
    }
    if (newData.find((item) => item.phone == dataForm.phone)) {
      return notification.error({
        key: 'phoneExisted  ',
        message: 'Số điện thoại đã được thêm vào danh sách',
      })
    }
    let newUser = dataUser.find((item) => item.phone == dataForm.phone)
    let newRole = roles.find((item) => item.role_id == dataForm.role_id)

    if (!newUser) {
      return notification.error({ key: 'phoneNotExisted', message: 'Số điện thoại không tồn tại!' })
    }

    if (!newRole) {
      return notification.error({ key: 'roleNotExisted', message: 'Vai trò không tồn tại!' })
    }

    let dataStaff = {
      phone: dataForm.phone || '',
      branch_id: branchIdApp || '',
      role_id: dataForm.role_id || '',
      description: dataForm.description || '',
      first_name: newUser.first_name,
      last_name: newUser.last_name,
      role_name: newRole.name,
    }
    newData.push(dataStaff)
    setDataStaffs(newData)
    form.setFieldsValue({ phone: undefined, role_id: undefined, description: undefined })
    setValidDataUser(0)
  }
  const handleDeleteDataStaff = (phone) => {
    let newData = [...dataStaffs]
    newData = newData.filter(item => item.phone != phone)
    setDataStaffs(newData)
  }

  const _addOrEditUser = async () => {
    try {
      setLoading(true)
      let res
        let body = { dataStaffs, branchIdApp }
      res = await createStaff(body)
      if (res.status === 200) {
        if (res.data.success) {
          toggle()
          dataStaff()
          setDataStaffs([])
          setVisible(false)
          setLoading(false)
          form.setFieldsValue({ phone: undefined, role_id: undefined, description: undefined })
          setValidDataUser(0)
          notification.success({
            message: 'Thêm nhân viên thành công',
          })
        } else
          notification.error({
            message: res.data.message || 'Thêm nhân viên thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Thêm nhân viên thất bại, vui lòng thử lại',
        })
    } catch (err) {
      setVisible(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    _getAllUser()
  }, [admin])

  return (
    <Spin spinning={loading}>
      <div className={styles['form_modal']}>
        <Permission permissions={[PERMISSIONS.them_nhan_vien]}>
          <Button type="primary" size="large" onClick={showModal}>
            Thêm Nhân Viên
          </Button>
        </Permission>
        <Modal
          visible={visible}
          title="Thêm nhân viên"
          cancelText="Cancel"
          closable={false}
          footer={[
            <Button key="cancel" onClick={() => setVisible(false)}>
              Đóng
            </Button>,
            <Button disabled={dataStaffs.length > 0 ? false : true} type="primary" loading={postData.loading} onClick={_addOrEditUser}>
              Gửi lời mời

            </Button>,
          ]}
          width={650}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: 'public',
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Người dùng" name="phone" rules={[{ required: true, message: 'Vui lòng chọn nhân viên muốn thêm' }]}>
                  <Select
                    value={phone}
                    showSearch
                    onSearch={(e) => {
                      if (e.length > 9) {
                        setValidDataUser(e)
                      } else {
                        setValidDataUser(0)
                      }
                    }}
                    dropdownStyle={validDataUser.length > 9 ? '' : { display: 'none' }}
                    placeholder="Nhập số điện thoại"
                  >
                    {validDataUser.length > 9 &&
                      dataUser
                        ?.filter((item) => item.phone == validDataUser)
                        .map((user, index) => (
                          <Option value={user.phone} key={index}>
                            {user.first_name + ' ' + user.last_name + ' '}
                            {user.phone}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Vai trò" name="role_id" rules={[{ required: true, message: 'Vui lòng chọn vai trò' }]}>
                  <Select 
                  value={role} 
                  placeholder="Chọn vai trò">
                    {roles
                      ?.filter((item) => item.role_id !== 1)
                      .map((role, index) => (
                        <Option value={role.role_id} key={index}>
                          {role.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Mô tả" name="description">
              <TextArea value={description} rows={2} type="textarea" />
            </Form.Item>
            {postData.error && (
              <>
                <br />
                <span style={{ color: 'red' }}>{postData.data}</span>
              </>
            )}
          </Form>
          <Button
            onClick={() => {
              handleAddDataStaff()
            }}
            style={{ width: '100%', marginBottom: 10 }}
          >
            Thêm nhân viên
          </Button>

          <div
            style={{
              width: '100%',
              background: '#5F73E2',
              color: 'white',
              padding: 10,
              marginTop: 10,
              textAlign: 'center',
              borderRadius: '10px 10px 0 0',
            }}
          >
            <Row justify="center" gutter={[12, 0]}>
              <Col span={7}>
                <strong>Tên nhân viên</strong>
              </Col>
              <Col span={7}>
                <strong>Số điện thoại</strong>
              </Col>
              <Col span={7}>
                <strong>Vai trò</strong>
              </Col>
              <Col span={3}></Col>
            </Row>
          </div>
          <div
            style={{
              height: '200px',
              width: '100%',
              background: '#f0f5ff',
              padding: 10,
              textAlign: 'center',
              borderRadius: '0 0 10px 10px',
              overflowY: 'scroll',
            }}
          >
            {dataStaffs?.map((staff, index) => (
              <Row key={index} justify="center" gutter={[12, 0]}>
                <Col style={{ borderColor: '0 0 0 black' }} span={7}>
                  {staff.first_name + ' ' + staff.last_name}
                </Col>
                <Col span={7}>{staff.phone}</Col>
                <Col span={7}>{staff.role_name}</Col>
                <Col span={3}>
                  <DeleteOutlined style={{ color: 'red' }} onClick={() => handleDeleteDataStaff(staff.phone)} />
                </Col>
                <Divider style={{ margin: 5, background: '#d6e4ff' }} />
              </Row>
            ))}
          </div>
        </Modal>
      </div>
    </Spin>
  )
}
export default StatusRole
