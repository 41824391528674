/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import {
  AppstoreOutlined,
  AuditOutlined,
  ClusterOutlined,
  DollarOutlined,
  DollarTwoTone,
  DownOutlined,
  DownSquareOutlined,
  FullscreenOutlined,
  InboxOutlined,
  LineChartOutlined,
  MenuOutlined,
  FileDoneOutlined
} from '@ant-design/icons'
import { Button, Dropdown, Space } from 'antd'
import { PERMISSIONS, ROUTES } from 'consts'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ScreenZoom from 'views/sell/screen-zoom'
import MenuBar from 'assets/icons/menuBar.png'
import styles from './dropdown.module.scss'
const DropdownGroupButton = ({ widthData, permissions, toggleLockShift, toggle, setModalConnectDevice,toggleRollCall }) => {
  const { t } = useTranslation()
  const dataUser = useSelector((state) => state.login.dataUser)

  const NotifyContent = () => (
    <div className={styles['dropdown-menu']}>
      <div className={styles['dropdown-item']}>
        <LineChartOutlined style={{ color: '#5F73E2', marginRight: '5px' }} />
        <Link to={ROUTES.OVERVIEW} target="_blank">
          Tổng quan
        </Link>
      </div>
      <div className={styles['dropdown-item']}>
        <DollarOutlined style={{ color: '#5F73E2', marginRight: '5px' }} />
        <Link to={ROUTES.RECEIPTS_PAYMENT} target="_blank">
          Q/L Thu Chi
        </Link>
      </div>
      <div className={styles['dropdown-item']}>
        <FullscreenOutlined style={{ color: '#5F73E2', marginRight: '5px' }} />
        <ScreenZoom widthData={widthData} />
      </div>
      <div className={styles['dropdown-item']}>
        <a onClick={() => setModalConnectDevice(true)}>
          <ClusterOutlined style={{ color: '#5F73E2', marginRight: '5px' }} />Kết nối thiết bị
        </a>
      </div>
      {widthData < 1700 &&
        (permissions.includes(PERMISSIONS.diem_danh) || dataUser.role_id === 1 ? (
          <div className={styles['dropdown-item']}>
          <a onClick={toggleRollCall}>
              <FileDoneOutlined  style={{ color: '#5F73E2', marginRight: '5px' }} />
              Điểm danh
            </a>
            </div>
        ) : (
          <></>
        ))
        }
      {widthData < 1580 &&
        (permissions.includes(PERMISSIONS.tao_phieu_tra_hang) ? (
          <div className={styles['dropdown-item']}>
            <InboxOutlined style={{ color: '#5F73E2', marginRight: '5px' }} />
            <Link to={ROUTES.REFUND} target="_blank">
              Trả hàng
            </Link>
          </div>
        ) : (
          <></>
        ))}
      {widthData < 1480 &&
        (permissions.includes(PERMISSIONS.tao_phieu_chot_ca) ? (
          <div className={styles['dropdown-item']}>
            <a onClick={toggleLockShift}>
              <AuditOutlined style={{ color: '#5F73E2', marginRight: '5px' }} />
              Chốt ca
            </a>
          </div>
        ) : (
          <></>
        ))}
      {widthData < 1380 && <div className={styles['dropdown-item']}>
        <a onClick={toggle}>
          <AppstoreOutlined style={{ color: '#5F73E2', marginRight: '5px' }} />
          {t('sell.shortcut_keys')}
        </a>
      </div> }
    </div>
  )

  return (
    <div style={{ marginLeft: '5px', marginRight: '5px' }}>
      <Dropdown overlay={<NotifyContent />}>
        <img
          style={{ width: '33px', cursor: 'pointer' }}
          src={MenuBar}
        />
        {/* <DownSquareOutlined style={{cursor: 'pointer', fontSize: 30}} /> */}
      </Dropdown>
    </div>
  )
}

export default DropdownGroupButton
