import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { socketListenerRole } from 'redux/actions/socket'

const SocketClient = () => {
  const { socket, connect_device } = useSelector((state) => state)
  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const {invoices} = useSelector((state) => state.invoice)

  const dispatch = useDispatch()
  // joinUser
  useEffect(() => {
    if(dataUser && branchIdApp) {
      let new_invoice = {
        invoice: invoices,
        key_invoice: 0
      }
      socket.emit('joinUser', {...dataUser,...new_invoice, branch_id: branchIdApp})
    }
  }, [socket, dataUser, branchIdApp])

  useEffect(() => {
    if(dataUser && branchIdApp) {
      socket.emit('checkTokenConnectDevice', {...dataUser, branch_id: branchIdApp})
    }
  }, [socket, dataUser, branchIdApp])

  useEffect(() => {
    socket.on('validTokenConnectDevice', data =>{
      let link = `socket_id=${data.socket_id}token=${data.token}`
      dispatch({type: 'VALID_CONNECT', payload: link})
      if(connect_device) {
        dispatch({type: 'UPDATE_INVOICE', data: data.invoice})
      }
    })
    return () => socket.off('validTokenConnectDevice')
  },[socket, dispatch, connect_device])

  useEffect(() => {
    if(dataUser && dataUser._business && dataUser._business.business_id) {
      socket.on(`role:update:${dataUser._business.business_id}:${dataUser.role_id}`, async data => {
        if(data) {
          dispatch(socketListenerRole({res: data}))
        }
      })
    return () => socket.off(`role:update:${dataUser._business.business_id}:${dataUser.role_id}`)
    }
  },[socket, dispatch, dataUser])

  return <></>
}
export default SocketClient
