const columns = [
  {
    title: 'STT',
    key: 'stt',
    width: 60,
  },
  {
    title: 'Mã nhân viên',
    dataIndex:'code',
    key: 'code',
  },
  {
    title: 'Tên nhân viên',
    key: 'name',
  },
  {
    title: 'Thông tin nhân viên',
    key: 'information',
  },
  // {
  //   title: 'Số điện thoại',
  //   dataIndex: 'username',
  //   key: 'phone',
  // },
  // {
  //   title: 'Địa chỉ',
  //   key: 'address',
  // },
  // {
  //   title: 'Ngày sinh',
  //   key: 'birth_day',
  //   dataIndex: 'birth_day',
  // },
  // {
  //   title: 'Email',
  //   dataIndex: 'email',
  //   key: 'email',
  // },
  {
    title: 'Vai trò',
    key: 'role',
  },
  {
    title: 'Thời gian đăng kí',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: 'Hành động',
    key: 'action',
  },
]

export default columns
