import { Dropdown, Input } from 'antd'
import React, { useState } from 'react'
import styles from './style.module.scss'
import { getLocationImei } from 'apis/location'
import { useSelector } from 'react-redux'

export const SelectImei = (props) => {
  const { variant_id, index, _editProductInInvoices, imei_new } = props
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [imeiChoose, setImeiChoose] = useState([])
  const [imeiTotal, setImeiTotal] = useState([])
  const indexInvoice = useSelector(state => state.indexInvoice)
  const _getLocationImei = async () => {
    try {
      const res = await getLocationImei({ variant_id: variant_id, branch_id: branchIdApp })
      if (res.status == 200) {
        if (imei_new.length > 0) {
          let new_data = res.data.data.imei.filter((item) => !imei_new.includes(item))
          setImeiTotal(new_data)
          setImeiChoose(imei_new)
        } else {
          setImeiTotal(res.data.data.imei)
          setImeiChoose([])
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const SelectItemImei = (item, index_imei) => {
    let new_imei_choose = [...imeiChoose]
    let new_imei_total = [...imeiTotal]
    new_imei_choose.push(item)
    new_imei_total.splice(index_imei, 1)
    setImeiChoose([...new_imei_choose])
    setImeiTotal([...new_imei_total])
    _editProductInInvoices('imei_new', [...new_imei_choose], index,indexInvoice)
    // setTimeout(_editProductInInvoices('quantity', new_imei_choose.length, index,indexInvoice),1000)
  }

  const MenuItem = () => (
    <div
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        backgroundColor: '#fff',
        padding: '10px',
      }}
    >
      {imeiTotal?.map((item, index_imei) => (
        <div
          onClick={() => {
            SelectItemImei(item, index_imei)
          }}
          className={styles['imei-item']}
          key={index_imei}
        >
          <span className={styles['imei-item-span']}>{item}</span>
        </div>
      ))}
    </div>
  )

  return (
    <div>
      <Dropdown overlay={<MenuItem />} trigger={['click']}>
        <Input
          placeholder="Chọn iMei..."
          style={{ height: '28px' }}
          onClick={_getLocationImei}
        />
      </Dropdown>
    </div>
  )
}
