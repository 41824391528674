import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import styles from './style.module.scss'
import { ConfigProvider, notification, Modal, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import localStorage from '../../../utils/localStorage'

import { verifyOtpPoint, sendOtpPoint, checkOrderCustomer } from 'apis/point'
import { verifyDebt } from 'apis/order'
export default function CountDownComponent(props) {
  const {
    invoices,
    availableOrder,
    isBranchClosed,
    createOrder,
    anotherCustomers,
    setOverlayPage,
    point,
    indexInvoice,
    handlePrint,
    deleteInvoiceAfterCreateOrder
  } = props
  const [otp, setOtp] = useState('000000')
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [errOtp, setErrOtp] = useState('')
  const [counter, setCounter] = useState({})
  const [phoneToDelete, setPhoneToDelete] = useState(null)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  
  const _sendOtp = async () => {
    try {
      setErrOtp('')
      let index = anotherCustomers.findIndex((e) => e.phone === invoices[indexInvoice]?.customer?.phone)
      const checkPoint = invoices[indexInvoice].payments.find((item) => item.payment_method_id === 2)
      const checkDebt = invoices[indexInvoice].payments.find((item) => item.payment_method_id === 6)
      if (index === -1 && checkDebt) {
        notification.warning({key:'user_ekata', message: 'Khách hàng chưa phải là user Ekata' })
        return
      }
      if (checkPoint) {
        if (!point.active) {
          notification.error({key:'point_is_off', message: 'Thanh toán bằng điểm đã tắt!' })
          return
        }
        if (checkPoint?.value / point.exchange_money_rate > invoices[indexInvoice]?.customer?.point) {
          notification.warning({key:'not_enough_points', message: 'Số điểm của khách hàng không đủ thanh toán!' })
          return
        }
        // let resOrder = await checkOrderCustomer({
        //   phone: invoices[indexInvoice]?.customer?.phone,
        //   created_at: anotherCustomers[index].created_at,
        // })
        // if (resOrder === 200) {
        //   if(!resOrder.data.success){
        //     notification.error({key:'sync_point', message: resOrder.data.message || 'Khách hàng chưa đồng bộ điểm!' })
        //     return
        //   }
        // }
      }
      let _create = await createOrder(100)
      if(_create){
        let arrPhone = localStorage.getItem('arrPhone') ? JSON.parse(localStorage.getItem('arrPhone')) : []
        let indexPhone = arrPhone.findIndex(e => e === invoices[indexInvoice]?.customer?.phone)
        if(counter[invoices[indexInvoice]?.customer?.phone]){
          if (counter[invoices[indexInvoice]?.customer?.phone] < 1) {
            const body = { phone: invoices[indexInvoice]?.customer?.phone }
            let res = await sendOtpPoint(body)
            if (res.status === 200 && res.data.success) {
              notification.success({ message: res.data.message })
              localStorage.setItem(invoices[indexInvoice]?.customer?.phone, 300)
              setCounter({ ...counter, [invoices[indexInvoice]?.customer?.phone]: 300 })
              setOverlayPage(true)
              if(indexPhone === -1){
                arrPhone.push(invoices[indexInvoice]?.customer?.phone)
                localStorage.setItem('arrPhone', JSON.stringify(arrPhone))
              }
            } else {
              notification.error({ message: res.data.message })
            }
          }
        }else{
          const body = { phone: invoices[indexInvoice]?.customer?.phone }
            let res = await sendOtpPoint(body)
            if (res.status === 200 && res.data.success) {
              notification.success({ message: res.data.message })
              localStorage.setItem(invoices[indexInvoice]?.customer?.phone, 300)
              if(indexPhone === -1){
                arrPhone.push(invoices[indexInvoice]?.customer?.phone)
                localStorage.setItem('arrPhone', JSON.stringify(arrPhone))
              }
              setCounter({ ...counter, [invoices[indexInvoice]?.customer?.phone]: 300 })
              setOverlayPage(true)
            } else {
              notification.error({ message: res.data.message })
            }
        }
        toggle()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _resendOtp = async () => {
    try {
      setErrOtp('')
      const body = { phone: invoices[indexInvoice]?.customer?.phone }
      let res = await sendOtpPoint(body)
      if (res.status === 200 && res.data.success) {
        notification.success({ message: res.data.message })
        setCounter({ ...counter, [invoices[indexInvoice]?.customer?.phone]: 300 })
        localStorage.setItem(invoices[indexInvoice]?.customer?.phone, 300)
        setOtp('000000')
      } else {
        notification.error({ message: res.data.message })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _verifyOtp = async () => {
    try {
      if (otp?.length === 0) return setErrOtp('Vui lòng nhập mã OTP')
      const body = { phone: invoices[indexInvoice]?.customer?.phone, otp_code: otp }
      let res = await verifyOtpPoint(body)
      if (res.status === 200 && res.data.success) {
        const body_order = {
          status: 200,
          payments: invoices[indexInvoice].payments,
          paymentRefund: invoices[indexInvoice].paymentRefund
        }
        let response = await verifyDebt(body_order, invoices[indexInvoice].order_id)
        if (response.status === 200) {
          notification.success({
            message: `Xác nhận đơn hàng thành công!`,
          })
          setCounter({ ...counter, [invoices[indexInvoice]?.customer?.phone]: 0 })
          let arrPhone = JSON.parse(localStorage.getItem('arrPhone'))
          if(arrPhone.length <= 1){
            localStorage.removeItem('arrPhone')
            localStorage.removeItem(invoices[indexInvoice]?.customer?.phone)
          }else{
            setPhoneToDelete(invoices[indexInvoice]?.customer?.phone)
          }
          handlePrint()
          deleteInvoiceAfterCreateOrder()
          toggle()
          setErrOtp('')
          setOverlayPage(false)
          setOtp('000000')
        } else {
          notification.error({
            message: res.data.message || 'Xác nhận đơn hàng thất bại!',
          })
        }
      } else {
        setErrOtp(res.data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // lấy thời gian opt của khách hàng để hiển thị
  const _countdown = function () {
    let arrPhone = localStorage.getItem('arrPhone') ? JSON.parse(localStorage.getItem('arrPhone')) : []
    if(phoneToDelete){
      let index = arrPhone.findIndex(e => e === phoneToDelete)
      if(index !== -1) arrPhone.splice(index, 1)
      localStorage.setItem('arrPhone', JSON.stringify(arrPhone))
      setTimeout(localStorage.removeItem(phoneToDelete),2000)
      setPhoneToDelete(null)
      let updatedCounter = { ...counter };
      delete updatedCounter[phoneToDelete]
      setCounter(updatedCounter)
    }else{
        if (counter[invoices[indexInvoice]?.customer?.phone] >= 1 && arrPhone.length > 0) {
          const updatedCounter = { ...counter };
          for(let item of arrPhone) {
              if (updatedCounter[item] >= 1) {
                updatedCounter[item]--; 
                localStorage.setItem(item, updatedCounter[item]);
              }
          }
          setCounter(updatedCounter);
        var minutes_tmp = Math.floor(parseInt(localStorage.getItem(invoices[indexInvoice]?.customer?.phone)) / 60) || 0
        var seconds_tmp = parseInt(localStorage.getItem(invoices[indexInvoice]?.customer?.phone)) % 60 || 0
        if (minutes_tmp < 10) {
          minutes_tmp = '0' + minutes_tmp
        }
        if (seconds_tmp < 10) {
          seconds_tmp = '0' + seconds_tmp
        }
      }
    }
    if(errOtp == 'Mã OTP đã hết hiệu lực!'){
      minutes_tmp = undefined
      seconds_tmp = undefined
    }
    setMinutes(minutes_tmp)
    setSeconds(seconds_tmp)
  }
 
  useEffect(() => {
    const updatedCounter = {...counter}
    let arrPhone = localStorage.getItem('arrPhone') ? JSON.parse(localStorage.getItem('arrPhone')) : []
    for(let item of arrPhone) {
        updatedCounter[item] = parseInt(localStorage.getItem(item)) 
    }
    setCounter(updatedCounter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => { 
    let countdownInterval = setTimeout(() => {
        _countdown()
    }, 1000);

    // Xóa interval khi unmount
    return () => {
      clearTimeout(countdownInterval);
    };
  },[counter[invoices[indexInvoice]?.customer?.phone]])

  return (
    <>
      <Button
        disabled={!availableOrder || isBranchClosed()}
        onClick={() => _sendOtp()}
        size="large"
        type="primary"
        style={{
          minWidth: 250,
          backgroundColor: '#0877DE',
          borderColor: '#0877DE',
          marginLeft: 10,
          cursor: `${!availableOrder || isBranchClosed() ? 'not-allowed' : 'pointer'}`,
        }}
      >
        {invoices[indexInvoice].isDelivery ? t('sell.create_a_delivery_order') : t('sell.payment')} (F2)
      </Button>
      <Modal
        width={540}
        visible={visible}
        footer={null}
        closeIcon={<CloseOutlined onClick={toggle} />}
      >
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Inter',
              colorTextPlaceholder: '#667085',
              colorPrimary: '#335BD3',
            },
          }}
        >
          <div className={styles['layout-otp']}>
            <div className={styles['layout-otp-form']}>
              <div className={styles['form-otp-responsive']}>
                <p className={styles['title']}>Xác thực thanh toán</p>
                <p className={styles['note']}>
                  Mã xác thực đã được gửi về <span>{invoices[indexInvoice]?.customer?.phone}</span>
                </p>
                <p className={styles['count-length-otp']}>Mã 6 chữ số</p>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={`${styles['custom-container-otp']}`}
                  inputStyle={`${styles['custom-otp']}`}
                  shouldAutoFocus={true}
                />
                {/* {_countdown()} */}
                <div>
                  <p className={styles['note-2']}>
                    {minutes !== undefined && seconds !== undefined ? (
                      <>
                      <p style={{color: '#F04438'}}>{errOtp}</p>
                      <p>Mã OTP có hiệu lực trong vòng {minutes + ':' + seconds} giây.</p>
                      </>
                    ): (
                      <p>
                      <p style={{color: '#F04438'}}>{errOtp}</p>
                      <span onClick={() => _resendOtp()}>Gửi lại mã OTP</span>
                    </p>
                    )}
                  </p>
                </div>
                <div onClick={_verifyOtp}>
                  <Button className={styles['btn-verify']}>Xác nhận</Button>
                </div>
              </div>
            </div>
          </div>
        </ConfigProvider>
      </Modal>
    </>
  )
}
