import { get, patch, post, destroy } from './httpClient'

export const getCheckInventoryNoteAdvanced = (query) => get('/inventory-note-advanced', query)
export const createCheckInventoryNoteAdvanced = (body) =>
  post('/inventory-note-advanced/create', body)
export const updateCheckInventoryNoteAdvanced = (body, id) =>
  patch(`/inventory-note-advanced/update/${id}`, body)
export const pushLocationInventoryProduct = (body, id) =>
  patch('/inventory-note-advanced/pushlocation/' + id, body)
export const deleteLocationInventoryProduct = (body, id) =>
  patch('/inventory-note-advanced/pulllocation/' + id, body)
export const updateInventoryListLocation = (body, id) =>
  patch('/inventory-note-advanced/update-listLocation/' + id, body)
export const deleteCheckInventoryNoteAdvanced = (body) =>
  destroy('/inventory-note-advanced/delete', body)
