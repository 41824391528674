import { Table } from 'antd'
import React from 'react'

const TableData = ({ dataOrders, dataOrdersCount }) => {
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
    },
    {
      title: 'Mã vận đơn',
      dataIndex: 'tracking_number',
      key: 'tracking_number',
      render: (text, record) => <span style={{ color: 'red' }}>{record.tracking_number || '-'}</span>,
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code_order',
      key: 'code_order',
    },
    {
      title: 'COD hệ thống',
      dataIndex: 'cod_system',
      key: 'cod_system',
    },
    {
      title: 'COD đối tác',
      dataIndex: 'cod_partner',
      key: 'cod_partner',
      render: (text, record) => <span style={{ color: 'red' }}>{record.cod_partner || '-'}</span>,
    },
    {
      title: 'Phí GH hệ thống',
      dataIndex: 'cost_ship_system',
      key: 'cost_ship_system',
    },
    {
      title: 'Phí GH đối tác',
      dataIndex: 'cost_ship_partner',
      key: 'cost_ship_partner',
      render: (text, record) => <span style={{ color: 'red' }}>{record.cost_ship_partner || '-'}</span>,
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={dataOrders}
      scroll={{
        y: 250,
      }}
      size="small"
    />
  )
}

export default TableData
