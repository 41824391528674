import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory, Link } from 'react-router-dom'
import { ROUTES } from 'consts'
import localStorage from '../../utils/localStorage'

import ScreenZoom from './screen-zoom'

import { Button, Space, Tooltip } from 'antd'

export default function HeaderGroupButton({setModalConnectDevice}) {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Space size="middle" wrap={false} style={{ marginLeft: 10 }}>
      <ScreenZoom />
      <Tooltip title={t('sell.go_to_the_revenue_and_expenditure_management_page')}>
        <Link to={ROUTES.RECEIPTS_PAYMENT} target="_blank">
          <img
            src="https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/10/16/6cb46f92-43da-4d2e-9ba1-16598b2c9590/notes 1.png"
            alt=""
            style={{ width: 24, height: 24, cursor: 'pointer' }}
            onClick={() => localStorage.setItem('collapsed', JSON.stringify(false))}
          />
        </Link>
      </Tooltip>
      <Tooltip title={'Về màn hình tổng quan'}>
        <Link to={ROUTES.OVERVIEW} target="_blank">
          <img
            src="https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/10/16/6cb46f92-43da-4d2e-9ba1-16598b2c9590/report 1.png"
            alt=""
            style={{ width: 24, height: 24, cursor: 'pointer' }}
            onClick={() => localStorage.setItem('collapsed', JSON.stringify(false))}
          />
        </Link>
      </Tooltip>
      <Tooltip title={'Kết nối thiết bị khác'}>
          <img
            src="https://s3.ap-southeast-1.wasabisys.com/ekata-production/public/2022/12/24/1671849180151-471282821-pngegg.png"
            alt=""
            style={{ width: 24, height: 24, cursor: 'pointer' }}
            onClick={() => setModalConnectDevice(true)}
          />
      </Tooltip>
    </Space>
  )
}
