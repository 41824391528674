import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { formatCash, removeUnicode } from 'utils'
import { ACTION, FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE, PERMISSIONS, ROUTES } from 'consts'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import delay from 'delay'

//components
import columnsImportInventories from './columns'
import exportToCSV from 'components/ExportCSV/export'
import TitlePage from 'components/title-page'
import PrintImportInventory from 'components/print/print-import-inventory'
import FilterDate from 'components/filter-date/filter'
import { useTranslation } from 'react-i18next'
import { DefaultFilterData } from 'utils/DefaultFilterData'

//antd
import {
  Row,
  Col,
  Space,
  Select,
  Table,
  Button,
  Modal,
  Popconfirm,
  notification,
  Input,
  Tooltip,
  Affix,
  Tag,
  Popover,
} from 'antd'

//icons
import {
  DeleteOutlined,
  PrinterOutlined,
  SearchOutlined,
  VerticalAlignTopOutlined,
  EditFilled,
} from '@ant-design/icons'

//apis
import {
  getOrdersImportInventory,
  deleteOrderImportInventory,
  updateOrderImportInventory,
} from 'apis/inventory'
import { getProducts } from 'apis/product'
import { getStaff } from 'apis/manage-staff'

export default function ImportInventories() {
  let printOrderRef = useRef()
  const typingTimeoutRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const handlePrint = useReactToPrint({ content: () => printOrderRef.current })
  const { t } = useTranslation()

  const [employees, setEmployees] = useState([])
  const [statusList, setStatusList] = useState([])
  const [dataPrint, setDataPrint] = useState(null)
  const [ordersInventory, setOrdersInventory] = useState([])
  const [ordersInventoryShow, setOrdersInventoryShow] = useState([])
  const [countOrder, setCountOrder] = useState(0)

  const [loading, setLoading] = useState(false)

  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
  })
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [fileTemplated, setFileTemplated] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const [sumFinalCost, setSumFinalCost] = useState(0)
  const [sumPaymentAmount, setSumPaymentAmount] = useState(0)
  const [sumQuantity, setSumQuantity] = useState(0)
  const [valueTime, setValueTime] = useState()

  const Print = () => (
    <div style={{ display: 'none' }}>
      <PrintImportInventory ref={printOrderRef} data={dataPrint} />
    </div>
  )

  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.code = value
      else delete paramsFilter.code
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const ModalDownloadProducts = ({ variants }) => {
    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    const columnsProduct = [
      {
        title: 'STT',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'SKU Phiên bản',
        dataIndex: 'sku',
        render: (text, record) => record?.sku || '',
      },
      {
        title: 'Phiên bản',
        render: (text, record) => record?.title || '',
      },
      {
        title: 'Giá nhập',
        render: (text, record) => record.import_price && formatCash(record.import_price || 0),
      },
      {
        title: 'Số lượng nhập',
        render: (text, record) => record.quantity && formatCash(record.quantity || 0),
      },
      {
        title: 'Thành tiền',
        render: (text, record) => record.total_cost && formatCash(record.total_cost || 0),
      },
      {
        title: 'NSX - HSD',
        render: (text, record) => `${record?.mfg} - ${record?.exp}` || '',
      },
      {
        title: 'Imei',
        render: (text, record) => record?.imei?.join(', ') || '',
      },
      {
        title: 'Ngày tạo',
        render: (text, record) =>
          record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
      },
    ]

    return (
      <>
        <div style={{ color: '#6074E2', cursor: 'pointer' }} onClick={toggle}>
          Tải xuống
        </div>
        <Modal
          width="80%"
          footer={
            <Row justify="end">
              <Button
                type="primary"
                onClick={() => {
                  const dataExport = variants.map((product, index) => ({
                    STT: index + 1,
                    SKU: product.sku || '',
                    'Phiên bản': product.title || '',
                    'Giá nhập': formatCash(product?.import_price) || 0,
                    'Số lượng nhập': product.quantity && formatCash(product.quantity || 0),
                    'Thành tiền': product.total_cost && formatCash(product.total_cost || 0),
                    'NSX - HSD': `${product?.mfg} - ${product?.exp}` || '',
                    Imei: product?.imei?.join(', ') || '',
                    'Ngày tạo':
                      product.create_date && moment(product.create_date).format('DD-MM-YYYY HH:mm'),
                  }))
                  exportToCSV(dataExport, 'Danh sách sản phẩm','Xuất file excel',dispatch)
                }}
              >
                Tải xuống
              </Button>
            </Row>
          }
          title="Danh sách sản phẩm"
          onCancel={toggle}
          visible={visible}
          style={{ top: 20 }}
        >
          <Table
            dataSource={variants}
            size="small"
            style={{ width: '100%' }}
            scroll={{ y: '60vh' }}
            pagination={false}
            columns={columnsProduct}
          />
        </Modal>
      </>
    )
  }

  const _onFilter = (attribute = '', value = '') => {
    const paramsFilterNew = { ...paramsFilter }
    if (value) paramsFilterNew[attribute] = value
    else delete paramsFilterNew[attribute]
    if (attribute == 'status' && value == 'all') {
      delete paramsFilter[attribute]
    }
    if (attribute == 'creator_id' && value == 'all') {
      delete paramsFilter[attribute]
    }
    if (attribute == 'verifier_id' && value == 'all') {
      delete paramsFilter[attribute]
    }
    setParamsFilter({ ...paramsFilterNew })
  }

  const _acceptOrderImportInventory = async (id) => {
    try {
      setLoading(true)
      const res = await updateOrderImportInventory({ status: 'COMPLETE' }, id)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getOrdersImportInventory()
          notification.success({ message: 'Nhập hàng thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Nhập hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({ message: res.data.message || 'Nhập hàng thất bại, vui lòng thử lại!' })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _deleteOrderImportInventory = async (id) => {
    try {
      const res = await deleteOrderImportInventory({ order_id: id, branch_id: branchIdApp })
      if (res.status === 200) {
        if (res.data.success) {
          _getOrdersImportInventory()
          notification.success({ message: 'Xóa đơn nhập hàng thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xóa đơn nhập hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa đơn nhập hàng thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrdersImportInventory = async () => {
    try {
      setLoading(true)
      const res = await getOrdersImportInventory({ branch_id: branchIdApp })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser.role_id, permissions, res.data.data)
        setOrdersInventory(result.data)
        setParamsFilter({
          page: 1, page_size: PAGE_SIZE,
          from_date: result.defaultTime.from_date,
          to_date: result.defaultTime.to_date,
        })
        setValueTime(result.valueDate)
        setValueSearch('')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) setEmployees(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getProducts = async () => {
    try {
      const res = await getProducts({ get_all: true, branch_id: branchIdApp })
      if (res.status === 200) {
        if (res.data.data) {
          const productsExport = []
          res.data.data.map((e) => {
            e.variants.map((variant) => {
              productsExport.push({
                'Mã phiếu nhập': '',
                'Mã sản phẩm (*)': e?.sku || '',
                'Mã phiên bản (*)': variant?.sku || '',
                'Giá nhập (*)': 0,
                'Số lượng nhập (*)': 0,
                NSX: '',
                HSD: '',
              })
            })
          })
          setFileTemplated(productsExport.map((product, index) => ({ STT: index + 1, ...product })))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrdersImportInventoryToExport = async () => {
    let dataExport = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      // let new_params_filter = { ...paramsFilter }
      // delete new_params_filter.page
      // delete new_params_filter.page_size
      // const res = await getOrdersImportInventory({ ...new_params_filter, branch_id: branchIdApp })
      // if (res.status === 200) {
      dataExport = ordersInventoryShow.map((item, index) => ({
        STT: index + 1,
        'Mã phiếu': item?.code || '',
        'Địa điểm nhận hàng ': item?.import_location_info?.name || '',
        'Ngày nhập hàng': moment(item?.complete_date).format('DD/MM/YYYY hh:mm') || '',
        'Tổng tiền': item?.final_cost || '',
        'Đã thanh toán': item?.payment_amount || '',
        'Tổng số lượng nhập': item?.total_quantity || '',
        'Người tạo đơn': item?._creator?.first_name + ' ' + item?._creator?.last_name || '',
        'Người xác nhận phiếu':
          item?._verifier?.first_name + ' ' + item?._verifier?.last_name || '',
        'Ngày xác nhận phiếu': moment(item?.verify_date).format('DD-MM-YYYY HH:mm'),
        'Trạng thái': item?.status || '',
      }))
      // }

      exportToCSV(dataExport, 'Danh sách phiếu nhập hàng','Xuất file excel', dispatch)
    } catch (e) {
      console.log(e)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  useEffect(() => {
    let arr = [...ordersInventory]
    const filteredOrder = arr.filter((current) => {
      let matchCreator = true
      let matchVerifier = true
      let matchStatus = true
      let matchTime = true
      let matchSearch = true

      if (paramsFilter.creator_id && paramsFilter.creator_id !== 'all')
        matchCreator = current?.creator_id === paramsFilter.creator_id

      if (paramsFilter.status && paramsFilter.status !== 'all')
        matchStatus = current?.status === paramsFilter.status
      if (paramsFilter.verifier_id && paramsFilter.verifier_id !== 'all')
        matchVerifier = current?.verifier_id === paramsFilter.verifier_id
      if (paramsFilter.code)
        matchSearch = removeUnicode(current.code.toString())
          .toLowerCase()
          .trim()
          .includes(removeUnicode(paramsFilter.code).toLowerCase().trim())

      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date

      return matchCreator && matchVerifier && matchStatus && matchTime && matchSearch
    })
    setOrdersInventoryShow(filteredOrder)
    setCountOrder(filteredOrder.length)

    let total = {
      sumFinalCost: 0,
      sumPaymentAmount: 0,
      sumQuantity: 0,
    }
    filteredOrder.forEach((e) => {
      total.sumFinalCost += e.final_cost
      total.sumPaymentAmount += e.payment_amount
      total.sumQuantity += e.total_quantity
    })
    setSumFinalCost(total.sumFinalCost)
    setSumPaymentAmount(total.sumPaymentAmount)
    setSumQuantity(total.sumQuantity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter])
  useEffect(() => {
    _getProducts()
    _getEmployees()
  }, [branchIdApp])

  useEffect(() => {
    _getOrdersImportInventory()
  }, [branchIdApp, dataUser])

  return (
    <div className="card">
      <Print />
        <TitlePage title="Nhập hàng" isAffix>
          <Space>
            <Button
              size="large"
              onClick={() => {
                setParamsFilter({ page: 1, page_size: 20 })
                setValueTime()
                setValueSearch()
              }}
              style={{ display: Object.keys(paramsFilter).length === 2 && 'none' }}
              danger
              type="primary"
            >
              Xóa bộ lọc
            </Button>
            <Button
              id="exportFile"
              disabled={permissions.includes(PERMISSIONS.export_nhap_hang) ? false : true}
              size="large"
              onClick={() => _getOrdersImportInventoryToExport()}
              icon={<VerticalAlignTopOutlined />}
              style={{
                backgroundColor: permissions.includes(PERMISSIONS.export_nhap_hang)
                  ? 'green'
                  : '#ccc',
                borderColor: permissions.includes(PERMISSIONS.export_nhap_hang) ? 'green' : '#ccc',
                color: 'white',
              }}
            >
              Xuất phiếu nhập hàng
            </Button>

            <>
              {permissions.includes(PERMISSIONS.them_phieu_nhap_hang) ? (
                <>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => history.push(ROUTES.IMPORT_INVENTORY_EXCEL)}
                  >
                    Nhập file excel
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => history.push(ROUTES.IMPORT_INVENTORY_CREATE)}
                  >
                    Tạo đơn nhập hàng
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled
                    size="large"
                    type="primary"
                    onClick={() => history.push(ROUTES.IMPORT_INVENTORY_EXCEL)}
                  >
                    Nhập file excel
                  </Button>
                  <Button
                    disabled
                    size="large"
                    type="primary"
                    onClick={() => history.push(ROUTES.IMPORT_INVENTORY_CREATE)}
                  >
                    Tạo đơn nhập hàng
                  </Button>
                </>
              )}
            </>
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_nhap_hang) ? (
        <>
          <div>
            <Row style={{ marginTop: 8, border: '1px solid #d9d9d9', borderRadius: 5,backgroundColor:'white' }}>
              {/* <Space wrap={true}> */}
              <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ height: FILTER_COL_HEIGHT }}>
                <Input
                  allowClear
                  style={{ width: '100%' }}
                  size={FILTER_SIZE}
                  value={valueSearch}
                  onChange={onSearch}
                  prefix={<SearchOutlined />}
                  placeholder="Tìm kiếm theo số hóa đơn"
                  bordered={false}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                style={{
                  borderLeft: '1px solid #d9d9d9',
                  borderRight: '1px solid #d9d9d9',
                }}
              >
                <FilterDate
                  style={{ marginTop: 3 }}
                  bordered={false}
                  paramsFilter={paramsFilter}
                  setParamsFilter={setParamsFilter}
                  title="tạo đơn nhập hàng"
                  valueTime={valueTime}
                  setValueTime={setValueTime}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                style={{
                  height: FILTER_COL_HEIGHT,
                  borderRight: '1px solid #d9d9d9',
                  width: '100%',
                }}
              >
                <Select
                  placeholder="Lọc theo trạng thái"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  size={FILTER_SIZE}
                  bordered={false}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue="all"
                  value={paramsFilter.status || 'all'}
                  onChange={(value) => _onFilter('status', value)}
                  style={{ width: '100%' }}
                >
                  <Select.Option value="all">Tất cả trạng thái</Select.Option>
                  {statusList.map((status, index) => (
                    <Select.Option value={status.name} index={index}>
                      {status.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
                <Select
                  placeholder="Lọc theo người tạo đơn"
                  allowClear
                  showSearch
                  size={FILTER_SIZE}
                  filterOption={(input, option) =>
                    removeUnicode(option.children.toString())
                      .toLowerCase()
                      .trim()
                      .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                  }
                  defaultValue="all"
                  bordered={false}
                  style={{ borderRight: '1px solid #d9d9d9', width: '100%' }}
                  value={paramsFilter.creator_id || 'all'}
                  onChange={(value) => _onFilter('creator_id', value)}
                >
                  <Select.Option value="all">Tất cả người tạo đơn</Select.Option>
                  {employees.map((employee, index) => (
                    <Select.Option key={index} value={employee.user_id}>
                      {`${employee.first_name} ${employee.last_name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={5} md={5} lg={5} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
                <Select
                  placeholder="Lọc theo người xác nhận phiếu"
                  showSearch
                  allowClear
                  size={FILTER_SIZE}
                  filterOption={(input, option) =>
                    removeUnicode(option.children.toString())
                      .toLowerCase()
                      .trim()
                      .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                  }
                  bordered={false}
                  style={{ width: '100%' }}
                  defaultValue="all"
                  value={paramsFilter.verifier_id || 'all'}
                  onChange={(value) => _onFilter('verifier_id', value)}
                >
                  <Select.Option value="all">Tất cả người xác nhận phiếu</Select.Option>
                  {employees.map((employee, index) => (
                    <Select.Option
                      key={index}
                      value={employee.user_id}
                    >{`${employee.first_name} ${employee.last_name}`}</Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 10 }}>
            <Table
              scroll={{ y: '49vh' }}
              loading={loading}
              size="small"
              rowKey={'order_id'}
              dataSource={ordersInventoryShow}
              columns={columnsImportInventories.map((column) => {
                if (column.key === 'stt')
                  return {
                    ...column,
                    width: 50,
                    render: (text, record, index) =>
                      (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                  }
                if (column.key === 'code')
                  return {
                    ...column,
                    render: (text, record) =>
                      permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_nhap_hang) ? (
                        <Link to={{ pathname: ROUTES.IMPORT_INVENTORY_UPDATE + '/' + record.code }}>
                          {'#' + record.code}
                        </Link>
                      ) : (
                        <div
                          style={{ color: '#5F73E2', cursor: 'pointer' }}
                          onClick={() => notification.warning({ message: 'Permission denied!' })}
                        >
                          #{record.code}
                        </div>
                      ),
                  }
                if (column.key === 'location')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.import_location_info && record.import_location_info.name,
                  }
                if (column.key === 'create_date')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
                  }
                if (column.key === 'final_cost')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.final_cost && formatCash(record.final_cost || 0),
                  }
                if (column.key === 'payment_amount')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.payment_amount && formatCash(record.payment_amount || 0),
                  }
                if (column.key === 'total_quantity')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.total_quantity && formatCash(record.total_quantity || 0),
                  }
                if (column.key === 'location')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.import_location_info && record.import_location_info.name,
                  }
                if (column.key === 'creator')
                  return {
                    ...column,
                    render: (text, record) =>
                      record._creator &&
                      `${record._creator.first_name || ''} ${record._creator.last_name || ''}`,
                  }
                if (column.key === 'verifier')
                  return {
                    ...column,
                    render: (text, record) =>
                      record._verifier &&
                      `${record._verifier.first_name || ''} ${record._verifier.last_name || ''}`,
                  }
                if (column.key === 'verify_date')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.verify_date && moment(record.verify_date).format('DD-MM-YYYY HH:mm'),
                  }
                if (column.key === 'status')
                  return {
                    ...column,
                    render: (text, record) => (
                      <>
                        <Tag
                          style={{ width: '100%', textAlign: 'center' }}
                          color={record.status === 'COMPLETE' && 'success'}
                        >
                          {record.status}
                        </Tag>
                        {record.status == 'DRAFT' && (
                          <>
                            <Popconfirm
                              disabled={
                                permissions.includes(PERMISSIONS.cap_nhat_phieu_nhap_hang)
                                  ? false
                                  : true
                              }
                              onConfirm={() => _acceptOrderImportInventory(record.order_id)}
                              okText="Đồng ý"
                              cancelText="Từ chối"
                              title="Bạn có muốn nhập đơn hàng này không?"
                            >
                              <Button
                                disabled={
                                  permissions.includes(PERMISSIONS.cap_nhat_phieu_nhap_hang)
                                    ? false
                                    : true
                                }
                                style={{ width: '100%', marginTop: 4 }}
                                type="primary"
                              >
                                Nhập hàng
                              </Button>
                            </Popconfirm>
                          </>
                        )}
                      </>
                    ),
                  }
                if (column.key === 'action')
                  return {
                    ...column,
                    render: (text, record) => (
                      <Space direction="vertical">
                        <Space>
                          <Popover
                            content={
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  height: 80,
                                }}
                              >
                                <>
                                  {permissions.includes(PERMISSIONS.xoa_phieu_nhap_hang) ? (
                                    <Tooltip>
                                      <Popconfirm
                                        onConfirm={() =>
                                          _deleteOrderImportInventory(record.order_id)
                                        }
                                        title="Bạn có muốn xóa đơn nhập hàng này không?"
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            height: 50,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <div style={{ padding: 3 }}>{'Xoá đơn nhập'}</div>
                                          <Button icon={<DeleteOutlined />} danger type="primary" />
                                        </div>
                                      </Popconfirm>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip>
                                      <Popconfirm title="Bạn có muốn xóa đơn nhập hàng này không?">
                                        <div
                                          style={{
                                            width: '100%',
                                            height: 50,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <div style={{ padding: 3 }}>{'Xoá đơn nhập'}</div>
                                          <Button
                                            disabled
                                            icon={<DeleteOutlined />}
                                            danger
                                            type="primary"
                                          />
                                        </div>
                                      </Popconfirm>
                                    </Tooltip>
                                  )}
                                </>
                                <>
                                  {permissions.includes(PERMISSIONS.export_nhap_hang) ? (
                                    <Tooltip>
                                      <div
                                        style={{
                                          width: '100%',
                                          height: 50,
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <div style={{ padding: 3 }}>{'In đơn nhập'}</div>
                                        <Button
                                          onClick={async () => {
                                            setDataPrint(record)
                                            await delay(100)
                                            handlePrint()
                                          }}
                                          icon={<PrinterOutlined />}
                                          type="primary"
                                        />
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip>
                                      <div
                                        style={{
                                          width: '100%',
                                          height: 50,
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <div style={{ padding: 3 }}>{'In đơn nhập'}</div>
                                        <Button
                                          disabled
                                          icon={<PrinterOutlined />}
                                          type="primary"
                                        />
                                      </div>
                                    </Tooltip>
                                  )}
                                </>
                              </div>
                            }
                          >
                            <div style={{ cursor: 'pointer' }}>Thao tác</div>
                            <div
                              style={{
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                              }}
                            >
                              <EditFilled
                                style={{ cursor: 'pointer', alignSelf: 'center' }}
                                size={20}
                              />
                            </div>
                          </Popover>
                        </Space>
                      </Space>
                    ),
                  }
                if (column.key === 'products')
                  return {
                    ...column,
                    render: (text, record) => (
                      <ModalDownloadProducts
                        variants={
                          record.variants.map((item) => ({
                            ...item,
                            ...record.products.find((e) => e.variant_id == item.variant_id),
                          })) || []
                        }
                      />
                    ),
                  }
                return column
              })}
              style={{ width: '100%' }}
              pagination={{
                position: ['bottomLeft'],
                current: paramsFilter.page,
                pageSize: paramsFilter.page_size,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                showQuickJumper: true,
                onChange: (page, pageSize) =>
                  setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
                total: countOrder,
                columnsImportInventories,
              }}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                    </Table.Summary.Cell>
                    {columnsImportInventories.map((e, index) => {
                      if (e.key === 'total_quantity')
                        return <Table.Summary.Cell>{sumQuantity}</Table.Summary.Cell>
                      if (e.key === 'final_cost')
                        return (
                          <Table.Summary.Cell>
                            {sumFinalCost && formatCash(sumFinalCost)} VND
                          </Table.Summary.Cell>
                        )
                      if (e.key === 'payment_amount')
                        return (
                          <Table.Summary.Cell>
                            {sumPaymentAmount && formatCash(sumPaymentAmount)} VND
                          </Table.Summary.Cell>
                        )
                      return <Table.Summary.Cell></Table.Summary.Cell>
                    })}
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}
