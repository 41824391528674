import { useDispatch } from 'react-redux'
import { getAllProductPublic } from 'apis/feed'

export async function LoadFeed() {
  const dispatch = useDispatch()
  try {
    const res = await getAllProductPublic()
    dispatch({
      type: 'SET_ALL_PRODUCTS',
      data: res.data.data,
    })
  } catch (error) {
    console.log(error)
  }
}
