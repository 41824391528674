import React, { useState } from 'react'
import { PERMISSIONS, TIME_FORMAT } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import styles from './stock-adjustments.module.scss'
import { removeUnicode } from 'utils'
//components
import ModalCheck from './modal-check'
import exportCsv from 'components/ExportCSV/export'

//antd
import { Row, Col, Input, Button, Table, Form, Select, notification, Popconfirm } from 'antd'

//icons
import {
  SearchOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
//apis
import { deleteLocationInventoryProduct, getCheckInventoryNoteAdvanced } from 'apis/inventory-node-advanced'
import moment from 'moment'
export default function ContainerLeft({
  form,
  current,
  inventoryNote,
  productArr,
  amountSystem,
  _changeUser,
  userChange,
  _updateProductChange,
  productChange,
  users,
  setListProduct,
  setDetailLocation,
  setVoteDetail,
  _getCheckInventoryNote,
  _updateInventoryUserAndProduct,
  checker,
  setSample,
  sampleList,
}) {
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const dispatch = useDispatch()
  const [inputTextVote, setInputTextVote] = useState('')
  let onSearchVote = (e) => {
    var lowerCase = e.target.value
    setInputTextVote(lowerCase)
  }
  const detailVote = (e) => {
    inventoryNote.locationInventoryProduct.map((item) => {
      if (item.vote_id === e) {
        setListProduct(item.listProduct)
        setDetailLocation(1)
        setVoteDetail(item)
        setSample(item.sample)
        form.setFieldsValue({
          locations: item.location,
          inventoryCheckers: item.check_by,
          note: item.note,
        })
      }
    })
  }
  function getEmployeeInfo(user_id) {
    let user = [...users]
    let tmp = user.find((employee) => employee.user_id === user_id)
    return tmp && <div>{tmp.first_name.charAt() + '.' + tmp.last_name}</div>
  }
  const deleteVote = async (e) => {
    try {
      if (checker === false) {
        notification.error({ message: 'Bạn không có quyền xóa vị trí này!' })
        return
      }
      if (dataUser.user_id !== e.check_by && dataUser.role_id !== 1) {
        notification.error({ message: 'Bạn không có quyền xóa vị trí này!' })
        return
      }
      const body = {
        vote_id: e.vote_id,
        real_quantity: e.real_quantity,
        last_update_old: inventoryNote.last_update
      }
      let res = await deleteLocationInventoryProduct(body, inventoryNote.inventoryNoteId)
      if(res.status === 200){
        if(res.data.success){
          _getCheckInventoryNote()
          notification.success({ message: 'Xóa vị trí kiểm thành công' })
        }else{
          notification.warning({ message: res.data.message || 'Xóa vị trí kiểm không thành công' })
        }
      }
      
    } catch (error) {
      console.log(error)
    }
  }
  const filteredDataVote =
    inventoryNote?.locationInventoryProduct &&
    inventoryNote?.locationInventoryProduct.filter((e) => {
      if (inputTextVote === '') {
        return e
      } else {
        return removeUnicode(e.location.toLowerCase()).includes(inputTextVote.toLowerCase().trim())
      }
    })
  const findUser = (id) => {
    let user = users.find((e) => e.user_id === id)
    if (user) {
      return user.first_name + ' ' + user.last_name
    } else return ''
  }
  const exportProduct = async (inventoryNote) => {
    try {
      let index = 1
      let dataExport = []
      let res = await getCheckInventoryNoteAdvanced({
        code: inventoryNote.code,
        branch_id: inventoryNote.branch_id,
        detail: 1,
      })
      if(res.status === 200){
        res.data.data[0].locationInventoryProduct.map((vote) => {
          vote.listProduct.forEach((e) => {
            let obj = {
              STT: index,
              'Mã sản phẩm': e.sku,
              'Ngày kiểm': moment(vote.last_update || inventoryNote.last_update).format(TIME_FORMAT.DATE_TIME_MINUTE),
              'Người kiểm': findUser(e.checked[e.checked.length - 1].check_by),
              'Vị trí kiểm': vote.location,
              'Số lượng': e.real_quantity,
              'Hàng trưng bày': vote.sample ? 'Yes' : 'No',
            }
            dataExport.push(obj)
            index += 1
          })
        })
      }else{
        inventoryNote.locationInventoryProduct.map((vote) => {
          vote.listProduct.forEach((e) => {
            let obj = {
              STT: index,
              'Mã sản phẩm': e.sku,
              'Ngày kiểm': moment(vote.last_update || inventoryNote.last_update).format(TIME_FORMAT.DATE_TIME_MINUTE),
              'Người kiểm': findUser(e.checked[e.checked.length - 1].check_by),
              'Vị trí kiểm': vote.location,
              'Số lượng': e.real_quantity,
              'Hàng trưng bày': vote.sample ? 'Yes' : 'No',
            }
            dataExport.push(obj)
            index += 1
          })
        })
      }
      await exportCsv(dataExport, 'Danh sách sản phẩm kiểm kho', 'Xuất file excel', dispatch)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <div className={styles['containerCON']}>
        <Form layout="vertical" form={form}>
          <Row
            style={{
              borderBottom: '1px solid rgb(197, 184, 184)',
              justifyContent: 'space-between',
            }}
          >
            <h3>Thông tin phiếu kiểm hàng</h3>
            {current >= 1 ? (
              <ModalCheck
                data={inventoryNote.locationInventoryProduct}
                product={productArr}
                amountSystem={amountSystem}
                status={inventoryNote.status}
                code={inventoryNote.code}
              />
            ) : null}
          </Row>
          <Row gutter={16} style={{ marginTop: 15, height: 58 }}>
            <Col span={12}>
              <Form.Item label="Tiêu đề" name="title">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Thời gian tạo" name="date">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 15 }}>
            <Col span={12}>
              <Form.Item label="Người tạo phiếu" name="create_by">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Cập nhật lần cuối" name="last_update">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 15 }}>
            <Col span={9}>
              <Form.Item label="Thêm nhân viên kiểm" name="inventoryCheck">
                <Select
                  size="middle"
                  style={{ width: '100%' }}
                  placeholder="Thêm nhân viên kiểm"
                  allowClear
                  mode="multiple"
                  onChange={(value) => {
                    let resultIndex = []
                    if (value !== undefined) {
                      value.map((_value) => {
                        let index = _value.lastIndexOf('+')
                        let result = _value.substring(index + 1)
                        resultIndex.push(Number(result))
                      })
                    }
                    _changeUser(resultIndex)
                  }}
                  showSearch
                >
                  {userChange.map((e) => (
                    <Select.Option value={`${e.first_name}${e.last_name}+${e.user_id}`}>
                      {e.first_name} {e.last_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Thêm sản phẩm cần kiểm" name="productCheck">
                <Select
                  size="middle"
                  style={{ width: '100%' }}
                  placeholder="Thêm sản phẩm cần kiểm"
                  allowClear
                  mode="multiple"
                  onChange={(value) => {
                    let resultIndex = []
                    if (value !== undefined) {
                      value.map((_value) => {
                        let index = _value.lastIndexOf('+')
                        let result = _value.substring(index + 1)
                        resultIndex.push(Number(result))
                      })
                    }
                    _updateProductChange(resultIndex)
                  }}
                  showSearch
                  disabled={
                    inventoryNote.allProduct ||
                    inventoryNote.status == 2 ||
                    inventoryNote.status == 3
                      ? true
                      : false
                  }
                  filterOption={(input, option) =>
                    removeUnicode(option.key.toString())
                      .toLowerCase()
                      .trim()
                      .includes(removeUnicode(input).toLowerCase().trim())
                  }
                >
                  {productChange &&
                    productChange.map((e) => (
                      <Select.Option key={e.title + ''} value={`${e.title}+${e.product_id}`}>
                        {e.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Form.Item>
              <Col span={5}>
                {permissions.includes(PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao) ? (
                  <Button
                    style={{ marginTop: 30, marginLeft: 10 }}
                    type="primary"
                    onClick={_updateInventoryUserAndProduct}
                  >
                    Cập nhật
                  </Button>
                ) : (
                  <Button style={{ marginTop: 30, marginLeft: 10 }} type="primary" disabled>
                    Cập nhật
                  </Button>
                )}
              </Col>
            </Form.Item>
          </Row>
        </Form>
      </div>
      <div className={styles['containerCON']}>
        <Row style={{ borderBottom: '1px solid rgb(197, 184, 184)' }}>
          <Col span={16}>
            <h3>Danh sách vị trí kiểm hàng</h3>
          </Col>
          <Col span={8}>
            {users.length > 0 ? 
            <Button
            id="exportFile"
            icon={<UploadOutlined />}
            style={{
              backgroundColor: '#66AE43',
              borderColor: '#66AE43',
              borderRadius: 5,
              color: 'white',
              fontWeight: 600,
            }}
            onClick={() => exportProduct(inventoryNote)}
          >
            Xuất excel
          </Button>: null}
          </Col>
        </Row>
        <Col span={20} style={{ marginTop: 20, marginLeft: 30 }}>
          <Input
            style={{ width: '100%' }}
            prefix={<SearchOutlined />}
            name="nameVote"
            value={inputTextVote}
            onChange={onSearchVote}
            placeholder="Tìm kiếm vị trí đã kiểm"
            bordered={true}
            allowClear
          />
        </Col>

        <div>
          <Row justify="space-between" align="middle" wrap={false} className={styles['list-vote']}>
            <Col span={2} className={styles['header-name']}>
              STT
            </Col>
            <Col span={5} className={styles['header-name']}>
              Vị trí
            </Col>
            <Col span={2} className={styles['header-name']}>
              Trưng bày
            </Col>
            <Col span={4} className={styles['header-name']}>
              Số lượng
            </Col>
            <Col span={6} className={styles['header-name']}>
              Người tạo
            </Col>
            <Col span={4} className={styles['header-name']}>
              Hành động
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              overflow: 'scroll',
              height: 'auto',
              maxHeight: '450px',
            }}
          >
            {filteredDataVote &&
              filteredDataVote.map((record, index) => {
                return (
                  <Row
                    key={index}
                    align="middle"
                    justify="space-between"
                    wrap={false}
                    className={styles['vote__item']}
                    id={record.vote_id}
                  >
                    <Col span={2}>
                      <div
                        style={{
                          marginBottom: 0,
                          width: 30,
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        {record.vote_id}
                      </div>
                    </Col>
                    <Col span={5}>
                      <div
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => detailVote(record.vote_id)}
                      >
                        {record.location}
                      </div>
                    </Col>
                    <Col span={2} className={styles['header-name']}>
                      {record.sample ? (
                        <CheckCircleOutlined style={{ fontSize: 16, color: '#09d159' }} />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: 16, color: 'red' }} />
                      )}
                    </Col>
                    <Col span={4}>
                      <span className={styles['vote__item-name']}>{record.real_quantity}</span>
                    </Col>
                    <Col span={6}>{getEmployeeInfo(record.check_by)}</Col>

                    <Col span={2}>
                      {(current < 1 && inventoryNote.creator_id == dataUser.user_id) ||
                      (current < 1 && record.check_by == dataUser.user_id) ? (
                        <Popconfirm
                          title="Bạn có muốn xóa vị trí kiểm này không?"
                          okText="Đồng ý"
                          cancelText="Từ chối"
                          onConfirm={() => deleteVote(record)}
                        >
                          <Button type="primary" danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                      ) : null}
                    </Col>
                  </Row>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}
