import React, { useEffect, useState } from 'react'
import styles from './refund-form.module.scss'

import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { IMAGE_DEFAULT, ROUTES } from 'consts'
import { useSelector, useDispatch } from 'react-redux'

import { formatCash, removeUnicode } from 'utils'
import { useTranslation } from 'react-i18next'
import { PERMISSIONS } from 'consts'
//antd
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  notification,
  Select,
  Space,
  Form,
  Checkbox,
  Switch,
  Avatar,
  Timeline,
  Tooltip,
  Spin
} from 'antd'
import Permission from 'components/permission'
//icons
import { ArrowLeftOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons'
import noData from 'assets/icons/no-data.png'

//apis
import { getOrderRefund, updateOrderRefund } from 'apis/order'
import { getPayments } from 'apis/payment'

//components

import TitlePage from 'components/title-page'
import moment from 'moment'

export default function RefundCreate() {
  const { Option } = Select
  const { t } = useTranslation()
  let history = useHistory()
  let { code } = useParams()
  const [loadingButton, setLoadingButton] = useState(false)
  const { TextArea } = Input
  const [orderRefund, setOrderRefund] = useState({
    products: [],
    sumCostPaid: 0,
    totalProduct: 0,
    order_refund_id: 0,
    branch_id: 1,
    is_received: null,
    is_payment: null,
    is_auto_create_report: 0,
    is_auto_update_inventory: 0,
    reference: 0,
    status: 0,
    customer_info: 0,
    refund_reason: '',
    payment_amount: 0,
    note: '',
    code_order: '',
    total_refund: 0,
    total_discount: 0,
    total_tax: 0,
    total_cost: 0,
    creator_id: 0,
  })
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)

  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const [listPayments, setListPayments] = useState([])
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState()
  const [payments, setPayments] = useState()
  const [paymentAmount, setPaymentAmount] = useState()

  const [formRefund] = Form.useForm()
  const [formPayment] = Form.useForm()
  const _getOrderR = async () => {
    try {
      setLoading(true)
      const res = await getOrderRefund({
        branch_id: branchIdApp,
        refund_order_code: code,
        role_id: dataUser?.role_id
      })
      if (res.status === 200) {
        if (res.data.data[0]) {
          formRefund.setFieldsValue(res.data.data[0])

          var products = []
          res.data.data[0].order_details.map((item) => {
            item.title = item.product_info.name
            item.sku = item.product_info.sku
            item.quantity = item.quantity || 0
            item.refund_quantity = item.refund_quantity || 0
            item.price = item.variant_info.price
            item.refund_from_order_info = item.refund_from_order_info || {}
            products.push(item)
          })

          // tổng tiền của tất cả sản phẩm
          var sumCostPaid = products.reduce(
            (total, current) => total + current.refund_quantity * current.price,
            0
          )
          // tổng số lượng hàng trả
          var totalProduct = products.reduce((total, current) => total + current.refund_quantity, 0)

          formPayment.setFieldsValue('reference', res.data.data[0].reference)
          setOrderRefund({
            products: products,
            sumCostPaid: sumCostPaid,
            totalProduct: totalProduct,
            order_refund_id: res.data.data[0].order_refund_id,
            branch_id: res.data.data[0].branch_refund_id,
            is_received: res.data.data[0].is_received,
            is_payment: res.data.data[0].is_payment,
            is_auto_create_report: res.data.data[0].is_auto_create_report,
            is_auto_update_inventory: res.data.data[0].is_auto_update_inventory,
            reference: res.data.data[0].reference,
            status: res.data.data[0].status,
            customer_info: res.data.data[0].customer_info,
            refund_reason: res.data.data[0].refund_reason,
            payment_amount: res.data.data[0].payment_amount,
            code_order: res.data.data[0].code_order,
            total_refund: res.data.data[0].total_refund,
            total_discount: products[0].refund_from_order_info.total_discount,
            total_tax: products[0].refund_from_order_info.total_tax,
            total_cost: products[0].refund_from_order_info.total_cost,
            creator_id: res.data.data[0].creator_id,
          })
          setNote(res.data.data[0].note)
          setPaymentAmount(sumCostPaid)
          setListPayments(res.data.data[0].payment_infos)
          setListPayments(
            res.data.data[0].payment_infos.map((item) => {
              return {
                ...item,
                paymentIdOld: item.payment_method_id,
              }
            })
          )
        } else history.push(ROUTES.REFUND)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) setPayments(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const toolTipModal = (record) => (
    <>
      <table style={{ width: 300 }}>
        <tr style={{ borderBottom: '1px solid #ddd' }}>
          <th style={{ width: '50%' }}>Đơn giá</th>
          <td>{formatCash(record.refund_quantity * record.price)}</td>
        </tr>
        <tr>
          <td style={{ width: '50%' }}>
            Chiết khấu (
            {((record.total_discount / record.quantity / record.price) * 100).toFixed(1)})%
          </td>
          <td>
            <div>
              {formatCash(record.refund_quantity * (record.total_discount / record.quantity))}
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: '50%' }}>Chiết khấu tổng đơn</td>
          <td>
            <div>
              {formatCash(
                (
                  record.refund_quantity *
                  (((record.price - record?.total_discount / record.quantity) /
                    (orderRefund.total_cost + orderRefund.total_discount)) *
                    orderRefund.total_discount)
                ).toFixed(0)
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: '50%' }}>
            Thuế ({((orderRefund.total_tax / orderRefund.total_cost) * 100).toFixed(1)})%
          </td>
          <td>
            <div>
              {formatCash(
                record.refund_quantity *
                  ((record.price -
                    record?.total_discount / record.quantity -
                    ((record.price - record?.total_discount / record.quantity) /
                      (orderRefund.total_cost + orderRefund.total_discount)) *
                      orderRefund.total_discount) *
                    (orderRefund.total_tax / orderRefund.total_cost))
              )}
            </div>
          </td>
        </tr>
      </table>
    </>
  )
  const columns = [
    {
      title: `STT`,
      render: (text, record, index) => index + 1,
    },
    {
      title: `Mã sản phẩm`,
      render: (data, record) => record?.variant_info.sku
    },
    {
      title: 'Tên sản phẩm',
      render: (data, record) => record?.variant_info.title
    },
    {
      title: 'Hình ảnh',
      render: (data, record) => (
        <img
          src={record?.variant_info?.image[0] ? record?.variant_info?.image[0] : record?.product_info?.images[0] ? record?.product_info?.images[0] : IMAGE_DEFAULT}
          style={{ width: 50, height: 50 }}
          alt=""
        />
      ),
    },
    {
      title: 'SL mua',
      render: (data, record) => (
        <div style={{ width: 80, textAlign: 'center', fontWeight: 'bold' }}>{record.quantity}</div>
      ),
    },
    {
      title: 'SL Đã trả',
      render: (data, record) => (
        <div style={{ width: 80, textAlign: 'center', fontWeight: 'bold' }}>
          {record.refund_quantity}
        </div>
      ),
    },
    {
      title: 'Giá hàng trả',
      render: (data, record) => (
        <div style={{ width: 80, textAlign: 'center', fontWeight: 'bold' }}>
          <Tooltip placement="bottom" title={toolTipModal(record)}>
            {formatCash(record.refund_price)}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Lý do trả',
      render: (data, record) => record.refund_reason,
    },
  ]

  const onUpdateRefundOrder = async (status) => {
    try {
      setLoadingButton(true)
      await formRefund.validateFields()
      if (paymentAmount > orderRefund.sumCostPaid) {
        notification.error({
          message: 'Số tiền hoàn trả không được lớn hơn số tiền khách đã thanh toán',
        })
        return
      }
      const data = {
        status: status,
        note: note,
        payment_infos: listPayments,
        // payment_amount: paymentAmount || 0,
        order_details: orderRefund.products,
        reference: orderRefund.reference,
        is_payment: orderRefund.is_payment,
        is_received: orderRefund.is_received,
      }
      const res = await updateOrderRefund(data, orderRefund.order_refund_id)
      if (res.data.success) {
        notification.success({ message: 'Cập nhật phiếu trả hàng thành công' })
        // history.push('/refund')
      } else {
        notification.warning({ message: res.data.message || 'Vui lòng điền đầy đủ thông tin!' })
      }
      setLoadingButton(false)
    } catch (err) {
      setLoadingButton(false)
      console.log(err)
    }
  }

  const onChangeSelect = (value, i) => {
    let listPaymentNew = [...listPayments]

    let index = payments.findIndex((item) => item.payment_method_id === value)
    listPaymentNew[i].name = payments[index].name
    listPaymentNew[i].payment_method_id = value

    setListPayments(listPaymentNew)
  }

  const [inputText, setInputText] = useState('')
  let onSearch = (e) => {
    var lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }
  const listProductNew = orderRefund.products.filter((item) => item.refund_quantity > 0)

  const filteredData = listProductNew.filter((e) => {
    if (inputText === '') {
      return e
    } else {
      return (
        removeUnicode(e.sku.toLowerCase()).includes(inputText) ||
        removeUnicode(e.title.toLowerCase()).includes(inputText)
      )
    }
  })
  useEffect(() => {
    _getPayments()
  }, [])
  useEffect(() => {
    _getOrderR()
  }, [branchIdApp, code])
  useEffect(() => {
    if (permissions.includes(PERMISSIONS.xem_chi_tiet_tra_hang < 0)) history.push(ROUTES.REFUND)
  }, [branchIdApp])
  return (
    <div className="card">
      <TitlePage
            isAffix
            title={
              <Row
                align="middle"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(ROUTES.REFUND)}
              >
                <ArrowLeftOutlined style={{ marginRight: 5 }} />
                Thông tin phiếu
              </Row>
            }
          >
            <Space>
              {permissions.includes(PERMISSIONS.cap_nhat_phieu_tra_hang) &&
              (dataUser.user_id === orderRefund.creator_id || dataUser.role_id === 1) ? (
                <Button
                  loading={loadingButton}
                  onClick={() => onUpdateRefundOrder(201)}
                  size="large"
                  type="primary"
                >
                  Cập nhật phiếu
                </Button>
              ) : null}
            </Space>
      </TitlePage>
      <Form form={formRefund} >
        <Spin spinning={loading} tip="Loading...">
          <Row style={{ marginTop: 20}}>
                <Col span={24}>
                  <Row>
                    <Col span={8} className={styles['block']} style={{ marginBottom: 33,backgroundColor:'white' }}>
                      <Row justify="space-between" className={styles['title']}>
                        <div className={styles['title']}>Thông tin khách hàng</div>
                          <Row style={{ width: '100%', marginTop:20, marginBottom:20 }}>
                            <div style={{ display: 'flex' }}>
                              <div style={{ margin: 10 }}>
                                <Avatar
                                  style={{
                                    backgroundColor: '#0089DF',
                                  }}
                                  icon={<UserOutlined />}
                                />
                              </div>
                              <div>
                                <div style={{ color: '#0089DF', fontSize: 20, fontWeight: 600 }}>
                                  {orderRefund.customer_info.first_name}{' '}
                                  {orderRefund.customer_info.last_name}
                                </div>
                                <div style={{ fontWeight: 'bold' }}>
                                  {orderRefund.customer_info.phone}
                                </div>
                              </div>
                              <div>
                                <div style={{ margin: '10px 10px 0 100px' }}>
                                  Công nợ hiện tại: {orderRefund.customer_info.debt}
                                </div>
                              </div>
                            </div>
                          </Row>
                        
                      </Row>
                    </Col>
                    <Col span={7} style={{ flexDirection: 'column', marginLeft: 30  }}>
                      <div className={styles['block']} style={{ marginBottom: 33,backgroundColor:'white' }}>
                        <Row style={{ width: '100%' }}>
                          <Col span={24} style={{ flexDirection: 'column' }}>
                            <div className={styles['title']}>Thông tin phiếu</div>
                            <Input
                              style={{ width: '100%', marginTop: 6 }}
                              value={`Mã đơn mua: ${orderRefund.code_order}`}
                              disabled={true}
                            />
                            <TextArea
                              rows={2}
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              style={{ width: '100%', marginTop: 6 }}
                              placeholder="Ghi chú"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={7} style={{ flexDirection: 'column', marginLeft: 30  }}>
                      <div className={styles['block']} style={{ marginBottom: 33,backgroundColor:'white' }}>
                        <div className={styles['title']}>Thông tin thanh toán</div>
                        <Form.Item name="date" className="custom-form-item" label="Thời gian">
                          <Input
                            disabled={true}
                            defaultValue={moment(orderRefund.create_date).format('DD/MM/YYYY HH:mm')}
                          />
                        </Form.Item>
                        <Form.Item
                          name="reference_payment"
                          className="custom-form-item"
                          label="Tham chiếu"
                        >
                          <Input disabled={true} />
                        </Form.Item>
                        <Form.Item
                          name="payment_amount"
                          className="custom-form-item"
                          label="Số tiền hoàn trả"
                        >
                          <Input
                            required
                            min={0}
                            value={formatCash(paymentAmount)}
                            disabled
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>

                        <div className={styles['block']}>
                          <Row style={{ width: '100%', borderBottom: '1px solid #d9d9d9' }}>
                            <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                              Phương thức thanh toán
                            </Col>
                            <Col>Giá trị</Col>
                          </Row>
                          <Timeline style={{ width: '100%', margin: 10 }}>
                            {listPayments.map((payment, index) => (
                              <Row style={{ width: '100%' }}>
                                <Col span={15}>
                                  <Select
                                    style={{ width: '80%' }}
                                    defaultValue={payment.name}
                                    onChange={(e) => onChangeSelect(e, index)}
                                    disabled={
                                      (dataUser?.user_id == orderRefund?.creator_id ||
                                        dataUser?.role_id) == 1
                                        ? false
                                        : true
                                    }
                                  >
                                    {payments &&
                                      payments.map((item) => (
                                        <Option value={item.payment_method_id}>{item.name}</Option>
                                      ))}
                                  </Select>
                                </Col>
                                <Col>{formatCash(payment.value)}</Col>
                              </Row>
                            ))}
                          </Timeline>
                        </div>

                        <div className={styles['block']}>
                          <Row style={{ width: '100%' }}>
                            <Col span={16} style={{ display: 'flex', flexDirection: 'row' }}>
                              <Checkbox
                                disabled={true}
                                onChange={(value) => {
                                  setOrderRefund({
                                    ...orderRefund,
                                    is_auto_create_report: !orderRefund.is_auto_create_report,
                                  })
                                }}
                                value={orderRefund.is_auto_create_report}
                                defaultChecked={true}
                              />
                              <div style={{ marginLeft: 5 }}>{'Tự động tạo phiếu chi'}</div>
                            </Col>
                            <Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
                              <div style={{ textAlign: 'center', alignSelf: 'center' }}>
                                {'Đã thanh toán'}
                              </div>
                              <Switch
                                disabled={true}
                                onChange={(checked) => {
                                  setOrderRefund({ ...orderRefund, is_payment: checked })
                                }}
                                checked={orderRefund.is_payment ? true : false}
                                style={{ alignSelf: 'center' }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className={styles['block']}>
                    <Row>
                      <Col sm={6}>
                        <Input
                          prefix={<SearchOutlined />}
                          placeholder="Tìm kiếm theo tên hoặc mã sản phẩm"
                          value={inputText}
                          onChange={onSearch}
                        ></Input>
                      </Col>
                    </Row>
                    <Table
                      pagination={false}
                      columns={columns}
                      size="small"
                      dataSource={filteredData}
                      locale={{
                        emptyText: (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: 200,
                            }}
                          >
                            <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                            <h4 style={{ fontSize: 15, color: '#555' }}>
                              {t('sell.your_order_has_no_products')}
                            </h4>
                          </div>
                        ),
                      }}
                    />
                    <div style={{ textAlign: 'right' }}>
                      <h3>Tổng tiền sản phẩm hoàn trả: {formatCash(+orderRefund.total_refund)} </h3>
                      <h3>Tổng số lượng hàng trả : {formatCash(+orderRefund.totalProduct)} </h3>
                    </div>
                  </div>
                </Col>
          </Row>
        </Spin>
      </Form>
    </div>
  )
}
