import React, { useEffect, useState } from 'react'
import {
  Row,
  Modal,
  Button,
  InputNumber,
  Table,
  Popover,
  Col,
  Typography,
  notification,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { TableProductLocation } from './tableProduct'

// import styles from '../sell.module.scss'
import { EditOutlined } from '@ant-design/icons'
import { useCallback } from 'react'
import { getLocationExpiry } from 'apis/location'
import { useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import moment from 'moment'

export const ModalProductLocation = ({
  product,
  variant_id,
  addProductToCartInvoice,
  index,
  ellipsis,
  selectedRowKeys,
  onSelectChange,
  _editProductInInvoices,
  setProductQuantity,
  productQuantity,
}) => {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { Paragraph, Text } = Typography
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const { t } = useTranslation()
  const [locationExpiry, setLocationExpiry] = useState([])

  const _getLocationExpiry = async () => {
    try {
      const res = await getLocationExpiry({ variant_id: variant_id, branch_id: branchIdApp })
      if (res.status == 200) {
        let new_data = sortBy(res.data.data, [
          (o) => {
            return o.exp
          },
        ])
        // for(let location of new_data){
        //   let today = moment().startOf('date').unix()
        //   let someday = moment(location.start_date).startOf('date').unix()

        // }
        for (let i of new_data) {
          let index = product.locations_new.findIndex((item) => item.location_id == i.location_id)
          if (index !== -1) {
            i.quantity_choose = product.locations_new[index].quantity || 0
          } else {
            i.quantity_choose = 0
          }
        }
        setLocationExpiry(new_data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handeChangeQuantity = (value, index) => {
    let new_product = [...locationExpiry]
    new_product[index].quantity_choose = value
    setLocationExpiry(new_product)
  }

  const columns = [
    {
      title: 'Mã lô',
      dataIndex: 'location_id',
      render: (text, record) => record.location_id,
    },
    {
      title: 'Ngày sản xuất',
      dataIndex: 'mfg',
      render: (text, record) => record.mfg,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'exp',
      render: (text, record) =>  moment(record.exp).startOf('date').unix() < moment().startOf('date').unix() ? <strong>{record.exp}</strong> : record.exp
    },
    {
      title: 'Có thể bán',
      dataIndex: 'quantity',
      render: (text, record) => record.quantity,
    },
    {
      title: 'Số lượng bán',
      dataIndex: 'quantity_sell',
      render: (text, record, index) => (
        <InputNumber
          onChange={(value) => {
            handeChangeQuantity(value, index)
          }}
          className="show-handler-number"
          style={{ width: '100%' }}
          value={record.quantity_choose}
          bordered={false}
          max={record.quantity}
          min={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder={t('sell.quantity')}
          disabled={
            moment(record.exp).startOf('date').unix() < moment().startOf('date').unix()
              ? true
              : false
          }
        />
      ),
    },
  ]

  useEffect(() => {
    let new_product = []
    if (
      product?.quantity !=
      product?.locations_new?.reduce((total, item) => (total += item.quantity), 0)
    ) {
      let today = moment().startOf('date').unix()

      let new_arr = product?.location_advanced?.location.filter(
        (item) => moment(item.exp).startOf('date').unix() > today
      )
      let new_data = sortBy(new_arr, [
        (o) => {
          return o.exp
        },
      ])
      let total_quantity = product?.quantity
      new_product = new_data.reduce((arr, item) => {
        arr.push({
          location_id: item?.location_id,
          quantity:
            total_quantity - item?.quantity > 0
              ? item?.quantity
              : total_quantity - item?.quantity <= 0
              ? total_quantity
              : total_quantity - item?.quantity,
          code: item?.code,
          exp: item?.exp,
        })
        total_quantity = total_quantity - item?.quantity <= 0 ? 0 : total_quantity - item?.quantity
        return arr
      }, [])
      _editProductInInvoices(
        'quantity',
        new_product.reduce((total, item) => (total += item.quantity), 0),
        index
      )
      _editProductInInvoices('locations_new', new_product, index)
      _getLocationExpiry(variant_id)
      toggle()
      // setProductLocation(new_product)
    }
  }, [product?.quantity])
  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation()
          toggle()
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          color: 'gray',
          cursor: 'pointer',
        }}
      >
        <Popover style={{ maxWidth: 450 }} placement="bottomLeft">
          <Text
            style={ellipsis ? { width: 50 } : undefined}
            // className={styles['sell-product__item-note']}
            onClick={async (e) => {
              e.preventDefault()
              await _getLocationExpiry()
            }}
          >
            {'Chọn lô'}
          </Text>
        </Popover>
        <EditOutlined style={{ marginLeft: 5 }} />
      </div>
      <Modal
        title={`Chọn lô sản phẩm ${product.title}`}
        width={800}
        closable={false}
        visible={visible}
        footer={[
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button
                onClick={() => {
                  toggle()
                }}
              >
                Đóng
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={(e) => {
                  // if (
                  //   product.locations_new.reduce((total, item) => (total += item.quantity), 0) === 0
                  // ) {
                  //   notification.error({
                  //     message: `Vui lòng chọn lô và nhập số lượng hợp lệ!`,
                  //   })
                  //   return
                  // }
                  _editProductInInvoices(
                    'quantity',
                    locationExpiry
                      .filter((item) => item.quantity_choose !== 0)
                      .reduce((total, item) => (total += item.quantity_choose), 0),
                    index
                  )
                  _editProductInInvoices(
                    'locations_new',
                    locationExpiry
                      .filter((item) => item.quantity_choose !== 0)
                      .map((item) => ({
                        ...item,
                        quantity: item.quantity_choose,
                      })),
                    index
                  )
                  toggle()
                }}
              >
                Xác nhận
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <TableProductLocation
          size="small"
          rowKey="location_id"
          scroll={{ y: 500 }}
          columns={columns}
          style={{ width: '100%' }}
          dataSource={locationExpiry}
          rowClassName={record => moment(record.exp).startOf('date').unix() < moment().startOf('date').unix() && "disabled-row"}

        />
      </Modal>
    </div>
  )
}
