import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import styles from './promotion.module.scss'
import { Modal, Row, Col, Button, Checkbox, Space, Input, notification, Image, Popover } from 'antd'
import gift from 'assets/icons/gift.png'
import { formatCash } from 'utils'
//apis
import { getPromotions, checkVoucher } from 'apis/promotion'
import DefaultLogo from 'assets/img/default_logo_ekata.png'

export default function PromotionAvailable({ invoiceCurrent, editOrder, checkData }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [voucherCheck, setVoucherCheck] = useState('')

  const [availableApply, setAvailableApply] = useState(false)

  const [checkPromotionData, setCheckPromotionData] = useState(false)

  const [promotions, setPromotions] = useState([])
  const [promotionCheck, setPromotionCheck] = useState({})
  const [promotionFind, setPromotionFind] = useState(null)

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const PromotionItem = ({ promotion }) => {
    if (promotion == undefined) return
    return (
      <Popover>
        <div className={styles['container']}>
          <div className={styles['img']}>
            <Image preview={false} src={(promotion.image && promotion.image) || DefaultLogo} />
          </div>

          <Checkbox
            disabled={
              invoiceCurrent.sumCostPaid - invoiceCurrent.discount_product <
                promotion.max_discount || invoiceCurrent.order_details.length === 0
            }
            checked={
              checkPromotionData === true
                ? promotionCheck && promotionCheck._id === promotion._id
                  ? true
                  : false
                : false
            }
            onClick={(e) => {
              const checked = e.target.checked
              if (checked) {
                let today = moment().startOf('date').unix()
                let someday = moment(promotion.start_date).startOf('date').unix()

                if (someday > today) {
                  notification.warning({
                    key: `Thông báo chưa đến ngày kích hoạt ${promotion.promotion_id}`,
                    message: `Khuyến mãi sẽ bắt đầu vào ngày ${moment(promotion.start_date).format(
                      'DD/MM/YYYY'
                    )}!`,
                  })
                  return
                }
                if (invoiceCurrent.order_details.length == 0) {
                  notification.warning({
                    message: t('sell.your_order_has_no_products_please_select_the_product_first'),
                  })
                  return
                }

                if (promotion.order_value_require != 0) {
                  if (
                    invoiceCurrent.sumCostPaid - invoiceCurrent.discount_product <
                    promotion.order_value_require
                  ) {
                    notification.warning({
                      message: `Cần mua tối thiểu ${formatCash(
                        promotion.order_value_require
                      )} để được giảm giá`,
                    })
                    return
                  }
                }

                // Tính toán giá trị được giảm
                if (promotion.type == 'PERCENT') {
                  promotion.discount =
                    parseInt(
                      parseInt(promotion.value) *
                        parseInt(invoiceCurrent.sumCostPaid - invoiceCurrent.discount_product || 0)
                    ) / 100
                  if (
                    promotion.discount > promotion.max_discount_value &&
                    promotion.max_discount_value > 0
                  ) {
                    promotion.discount = promotion.max_discount_value
                  }
                } else {
                  promotion.discount = promotion.value
                  if (
                    promotion.discount > promotion.max_discount_value &&
                    promotion.max_discount_value > 0
                  ) {
                    promotion.discount = promotion.max_discount_value
                  }
                }

                setPromotionCheck({ ...promotion })
                setVoucherCheck(promotion.promotion_code)
                setAvailableApply(true)
                setCheckPromotionData(true)
              } else {
                setPromotionCheck({})
                setVoucherCheck('')
                setAvailableApply(false)
                setCheckPromotionData(false)
              }
            }}
          >
            <div className={styles['content']}>
              <h2 className={styles['title']}>
                Giảm {formatCash(promotion.value)} {promotion.type === 'VALUE' ? ' VNĐ' : '%'}
              </h2>
              <p className={styles['note']}>
                Đơn tối thiểu {formatCash(promotion.order_value_require) + ' VNĐ'} trở lên
              </p>
              <p className={styles['note']}>
                Giảm tối đa{' '}
                {formatCash(
                  promotion.type === 'VALUE' ? promotion.value : promotion.max_discount_value
                ) + ' VNĐ'}
              </p>
              <p className={styles['note']}>
                Số lượng còn lại:{' '}
                {promotion.limit_quantity > 0 ? promotion.voucher_quantity : 'Không giới hạn'}
              </p>
              <p style={{ fontSize: 10, fontWeight: 'bold', marginTop: 5 }}>
                Thời gian khuyến mãi: {moment(promotion.start_date).format('DD/MM/YYYY')} -{' '}
                {moment(promotion.end_date).format('DD/MM/YYYY')}
              </p>
            </div>
          </Checkbox>
        </div>
      </Popover>
    )
  }
  const _getPromotions = async () => {
    try {
      const res = await getPromotions()
      let today = moment().startOf('date').unix()
      if (res.status === 200) {
        try {
          let newData = res.data.data.reduce((arr, item) => {
            if (item.end_date) {
              let someday = moment(item.end_date).startOf('date').unix()
              if (today < someday) {
                arr.push(item)
              }
            } else {
              arr.push(item)
            }
            return arr
          }, [])
          let newData2 = newData.reduce((arr, item) => {
            if (item.limit_quantity === 0) {
              arr.push(item)
            } else {
              if (item.voucher_quantity > 0) {
                arr.push(item)
              }
            }
            return arr
          }, [])
          setPromotions(newData2.filter((e) => !e.has_voucher && e.is_active === true))
        } catch (error) {
          console.log(error)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _applyVoucher = async () => {
    if (promotionCheck.discount > invoiceCurrent.moneyToBePaidByCustomer) {
      notification.warning({
        message: 'Khuyến mãi không được quá giá trị đơn hàng',
      })
    } else {
      try {
        const res = await getPromotions()
        if (res.status === 200) {
          let newCheckPromotion = res.data.data.find(
            (item) => item.promotion_code === promotionCheck.promotion_code
          )
          if (newCheckPromotion.limit_quantity > 0 && newCheckPromotion.voucher_quantity !== 0) {
            editOrder('promotion', promotionCheck)
            toggle()
          } else {
            notification.warning({
              key: 'newcheckPromotionQuantiti',
              message: 'Mã khuyến mãi đã hết lượt áp dụng!',
            })
          }
        }
      } catch (error) {}
    }
  }
  const _removeVoucher = () => {
    setCheckPromotionData(false)
    setPromotionCheck({})
    setVoucherCheck('')
    editOrder('promotion', {})
  }

  useEffect(() => {
    _removeVoucher()
    editOrder('discount_invoice', 0)
  }, [invoiceCurrent.sumCostPaid])

  const _checkVoucher = async () => {
    try {
      if (availableApply) {
        setVoucherCheck('')
        setAvailableApply(false)
        setPromotionCheck({})
      } else {
        setLoading(true)
        const res = await checkVoucher(voucherCheck)
        if (res.status === 200) {
          if (res.data.data) setPromotionFind(res.data.data)
          else
            notification.warning({
              message: res.data.message || t('sell.voucher_check_failed_please_try_again'),
            })
        } else
          notification.warning({
            message: res.data.message || t('sell.voucher_check_failed_please_try_again'),
          })

        setLoading(false)
      }
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }
  useEffect(() => {
    if (checkData) {
      setCheckPromotionData(true)
    } else {
      setCheckPromotionData(false)
    }
    if (checkData === undefined || checkData === null) {
      setCheckPromotionData(true)
      setPromotionCheck({})
    }
    _getPromotions()
  }, [checkData, visible])

  return (
    <div>
      {promotions && promotions.length !== 0 ? (
        <img
          onClick={toggle}
          src={gift}
          alt=""
          style={{ width: 16, height: 16, marginLeft: 8, cursor: 'pointer' }}
        />
      ) : (
        ''
      )}

      <Modal
        width={900}
        visible={visible}
        title="Khuyến mãi"
        onCancel={toggle}
        footer={
          <Row gutter={[24, 8]}>
            <Col span={6}>
              <Button
                onClick={_removeVoucher}
                type="primary"
                style={{
                  backgroundColor: 'white',
                  borderRadius: 5,
                  color: 'black',
                  borderColor: '#0877DE',
                }}
              >
                Huỷ áp dụng khuyến mãi
              </Button>
            </Col>
            <Col span={12}>
              {availableApply && promotionCheck.discount && (
                <div
                  style={{
                    textAlign: 'center',
                    color: 'black',
                    alignSelf: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}
                >
                  {'Giá trị được giảm giá: ' + formatCash(promotionCheck.discount) + ' VNĐ'}
                </div>
              )}
            </Col>
            <Col span={6}>
              <Button
                onClick={(e) => {
                  setVisible(false)
                }}
                type="primary"
                style={{
                  backgroundColor: 'white',
                  borderRadius: 5,
                  color: 'black',
                  borderColor: '#0877DE',
                  width: 80,
                }}
              >
                {t('common.close')}
              </Button>
              <Button
                disabled={!availableApply}
                onClick={_applyVoucher}
                type="primary"
                style={{
                  backgroundColor: '#0877DE',
                  borderRadius: 5,
                  borderColor: '#0877DE',
                }}
              >
                {t('common.apply')}
              </Button>
            </Col>
          </Row>
        }
      >
        <Row style={{ overflowY: 'auto', maxHeight: 600 }}>
          <Row style={{ marginBottom: 30 }}>
            <Col span={12}>
              <h3 style={{ marginBottom: 5, fontSize: 17 }}>
                {t('sell.check_voucher_promotion_code')}
              </h3>
            </Col>
            <Col span={12}>
              <Space wrap={false}>
                <Input
                  disabled={availableApply}
                  value={voucherCheck}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setVoucherCheck('')
                      setPromotionFind(null)
                    } else {
                      setVoucherCheck(e.target.value)
                    }
                  }}
                  placeholder={t('sell.enter_voucher_promotion_code')}
                  style={{ width: 300 }}
                />
                <Button
                  onClick={_checkVoucher}
                  loading={loading}
                  type="primary"
                  style={{
                    backgroundColor: availableApply ? 'orange' : '#0877DE',
                    borderRadius: 5,
                    borderColor: '#0877DE',
                  }}
                >
                  {availableApply ? 'Sử dụng sau' : t('common.check')}
                </Button>
              </Space>
            </Col>
          </Row>
          <Col>
            <div className={styles['displaygrid']}>
              {promotionFind === null ? (
                promotions.length > 0 &&
                promotions.map((promotion) => <PromotionItem promotion={promotion} />)
              ) : (
                <PromotionItem promotion={promotionFind} />
              )}
            </div>

            {promotions.length == 0 && (
              <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
                {'Không có chương trình khuyến mãi khả dụng'}
              </div>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
