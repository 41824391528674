import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import styles from '../schedule-staff.module.scss'
import { sortBy } from 'lodash'
import { useSelector } from 'react-redux'

import { Space, notification, Table } from 'antd'

import {
  createStaffShift,
  updateStaffShift,
  getShiftForRegister,
} from 'apis/schedule-staff'
import ModalRegisterShift from './modal-register-shift'
import styled from 'styled-components'

const TableSyled = styled(Table)`
  .ant-table-thead .ant-table-cell {
    padding: 0;
  }
  .ant-table-row > .ant-table-cell:last-child,
  .ant-table-thead .ant-table-cell:last-child {
    border-right: 0;
  }

  .ant-table-row:last-child > .ant-table-cell {
    padding-bottom: 40px;
  }

  .ant-table-row .ant-table-cell {
    padding: 6px;
    border-top: none;
    width: 190px;
    border-right: 1px solid #dfdfdf;
    border-bottom: none;
  }
  .ant-table-thead .ant-table-cell:last-child > .table__header {
    border-right: 0;
  }
`
const ShopTableStyled = styled(Table)`
  .ant-table-thead .ant-table-cell {
    height: 63px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;

    &:nth-child(1),
    &:nth-child(3) {
      background: #1e4db7;
    }

    &:nth-child(1) {
      border-radius: 12px 0px 0px 0px;
    }

    &:nth-child(2) {
      background: #3b6cdc;
      padding: 14px 17px;
    }

    &:nth-child(3) {
      padding: 14px 15px;
    }

    &:nth-child(n + 4) {
      background: #fff;
      padding: ;
    }
  }
  .ant-table-row > .ant-table-cell:last-child,
  .ant-table-thead .ant-table-cell:last-child {
    border-right: 0;
  }
`

const ScheduleWeek = ({ staffShifts, staffShift,schedule, shifts, employee, id, roles, user,_getStaffShift }) => {
  const [dayWeek, setDayWeek] = useState([])
  const [shiftForRegister, setShiftForRegister] = useState([])
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [shiftArray, setShiftArray] = useState([])
  const [headers, setHeaders] = useState([])
  const [tableData, setTableData] = useState([])
  const [columnShopTable, setColumnShopTable] = useState([])
  const [shopTableData, setShopTableData] = useState([])

  const [shiftSchedule, setShiftSchedule] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [predate, setPredate] = useState()

  const [visible2, setVisible2] = useState(false)
  const toggle = () => setVisible2(!visible2)
  const showModal = (cal) => {
    _getDataStaffShift(id)
    setIsModalVisible(true)
    setPredate(cal)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    _getDataStaffShift(id)
  }


  const [shiftStaffDay, setShiftStaffDay] = useState([])
  const _getDataStaffShift = async (id) => {
    try {
      if (predate) {
          shiftArrayNewData(staffShift)
          const itemData = staffShift.filter(
            (item) => item.day == predate && item.staff_id == user.user_id
          )
          setShiftStaffDay(itemData)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (predate) {
        shiftArrayNewData(staffShift)
        const itemData = staffShift.filter(
          (item) => item.day == predate && item.staff_id == user.user_id
        )
        setShiftStaffDay(itemData)
    }
  },[predate, schedule])

  const _getShiftForRegister = async (id, day) => {
    try {
      const res = await getShiftForRegister(id, day)
      if (res.status === 200) {
        setShiftForRegister(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getDateNow = () => {
    const date = new Date()

    return date.getDate() + '/' + (date.getMonth() + 1)
  }
  const getTheadStyles = (isDateNow) => {
    const normal = {
      header: {
        padding: '12px 0',
        borderBottom: '1px solid #dfdfdf',
        cursor: 'pointer',
      },
      date: {
        color: '#91919f',
        fontWeight: 400,
      },
    }
    const styles = {
      header: {
        padding: '12px 0 8px 0',
        borderBottom: '4px solid #0BB2FB',
        cursor: 'pointer',
      },

      date: {
        fontWeight: 700,
        color: '#0BB2FB',
      },
    }

    return isDateNow ? styles : normal
  }
  const getCellStyles = (isDateNow, index) => {
    if (!isDateNow) return null
    if(index <= 0){ return {
      slot: {
        background: '#D7F3FF',
      },
      missing: {
        background: '#5DE49E',
      },
    }}else return {
      slot: {
        background: '#FFDDBE',
      },
      missing: {
        background: '#FD5662',
        color: '#fff',
      },
    }

  }

  const getColumns = (dayList) => {
    return dayList.map((item) => {
      const date = moment(item.day).format('DD/M')

      const isDateNow = getDateNow() === date

      const theadStyles = getTheadStyles(isDateNow)

      return {
        key: item.day,
        dataIndex: item.day,
        title: () => (
          <div
            key={item.day}
            className={styles.table__header}
            style={theadStyles.header}
            onClick={() => {
              if(moment().unix() < moment(item.day).unix()){
                showModal(item.day)
              }else{
                notification.warning({message:`Không thể đăng ký ca làm việc ngày ${item.day}`})
              }
            }}
          >
            <h2 className={styles.table__day}>{item.dayTh}</h2>

            <span className={styles.table__date} style={theadStyles.date}>
              {date}
            </span>
          </div>
        ),

        render: (_, record) => {
          let result

          const found = Object.values(record).some((recordValue) => {
            return Object.keys(recordValue).some((key) => {
              if (key !== item.day) return false

              result = { cell: recordValue[key], index: recordValue.slotIndex }
              return true
            })
          })

          if (!found) return null

          const { cell, index } = result

          const cellStyles = getCellStyles(isDateNow, cell.missing)

          const timeRange =
            cell.time_start || cell.time_end
              ? `${moment(cell.time_start).format('HH:mm')} - ${moment(cell.time_end).format(
                  'HH:mm'
                )}`
              : '-'

          return (
            <div key={item.day} className={styles.table__cell}>
              <div
                className={styles.table__info}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (timeRange !== '-'){
                    if(moment().unix() < moment(item.day).unix()){
                      showModal(item.day)
                    }else{
                      notification.warning({message:`Không thể đăng ký ca làm việc ngày ${item.day}`})
                    }
                  }
                }}
              >
                <Space className={styles.table__slot} style={cellStyles && cellStyles.slot}>
                  <div>
                    <span className={styles.table__time}>{timeRange}</span>
                    <span className={cell.title ? styles.table__title : null}>{cell.title}</span>
                  </div>
                  {cell.missing === 0 ? null : (
                    <span
                      className={styles.table__missing}
                      style={cellStyles && cellStyles.missing}
                    >
                      {cell.missing}
                    </span>
                  )}
                </Space>

                <div
                  className={
                    cell.staffs.length
                      ? `${styles['table__staff-box']} ${
                          cell.staffs.length <= 2 && styles['table__staff-box-deco']
                        }`
                      : styles['table__staff-box-empty']
                  }
                >
                  {cell.staffs.length ? (
                    <ul className={styles.table__staffs}>
                      {cell.staffs.map((staff) => {
                        if (!staff) return null
                        return (
                          <li key={staff._id} className={styles.table__staff}>
                            {Object(staff.first_name).toString().charAt()}.{staff.last_name}
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <span className={styles['table__staff-empty']}>Chưa có nhân viên</span>
                  )}
                </div>
              </div>
            </div>
          )
        },
      }
    })
  }

  const getDataSource = (dayWeek) => {
    const schedule = {
      one: [],
      two: [],
      three: [],
      four: [],
      five: [],
    }

    dayWeek.forEach((item) => {
      Array.isArray(item.Shifts) &&
        item.Shifts.forEach((shift, index) => {
          const slot = { [item.day]: shift, slotIndex: index }
          switch (index) {
            case 0:
              schedule.one.push(slot)
              break
            case 1:
              schedule.two.push(slot)
              break
            case 2:
              schedule.three.push(slot)
              break
            case 3:
              schedule.four.push(slot)
              break
            default:
              schedule.five.push(slot)
              break
          }
        })
    })

    return Object.values(schedule).map((value) => ({ ...value }))
  }
  const getTableShopHeaderValue = (key, object) => {
    switch (key) {
      case 'name':
        return 'Họ Tên'
      case 'choose':
        return 'Số ca sắp xếp'
      case 'register':
        return 'Số ca đăng ký'
      default:
        return object[key]
    }
  }
  const getShopTableSlotCellStyles = (isDateNow, index) => {
    if (!isDateNow) return null

    index += 1

    if (index % 3 === 0) return { background: '#1E4DB7' }

    if ((index - 2) % 3 === 0) return { background: '#0BB2FB' }

    return null
  }
  const getShopData = (staffDataShift) => {
    if (!staffDataShift.length) return null

    return staffDataShift.map((shift) => ({
      name: `${shift.first_name} ${shift.last_name}`,
      register: shift.shiftRegister,
      choose: shift.shiftChoose,
      ...shift.all_day,
    }))
  }

  const getTableShopHeader = (key, object) => {
    switch (key) {
      case 'name':
        return {
          text: 'Họ Tên',
          classCell: 'tbShop__cell-name',
          classHeader: 'tbShop__header-name',
        }
      case 'choose':
        return {
          text: 'Số ca sắp xếp',
          classCell: 'tbShop__cell-choose',
          classHeader: 'tbShop__header-choose',
        }
      case 'register':
        return {
          text: 'Số ca đăng ký',
          classCell: 'tbShop__cell-register',
          classHeader: 'tbShop__header-register',
        }
      default:
        return {
          ...object[key],
          classCell: 'tbShop__cell-slot',
          classHeader: 'tbshop__header-slot',
        }
    }
  }
  const isObjectType = (value) => Object.prototype.toString.call(value) === '[object Object]'
  const getColumnShopTable = (shopData) => {
    if (!shopData) return null

    return Object.keys(shopData).map((key) => {
      const header = getTableShopHeaderValue(key, shopData)
      const headerTest = getTableShopHeader(key, shopData)

      const date = moment(header.day).format('DD/M')

      return {
        key,
        dataIndex: key,
        align: 'center',
        className: styles[headerTest.classCell],
        title: () => {
          if (headerTest.text) return <span key={Math.random()}>{headerTest.text}</span>

          return (
            <div key={Math.random()}>
              <h2 className={styles.tbShop__day}>{header.dayTh}</h2>

              <span className={styles.tbShop__date}>{date}</span>
            </div>
          )
        },

        render: (_, record, index) => {
          const isDateNow = getDateNow() === date

          const cellStylesDateNow = getShopTableSlotCellStyles(isDateNow, index)

          const value = record[key]

          return (
            <div key={Math.random()}>
              {isObjectType(value) ? (
                value?.shifts?.length ? (
                  <ul className={styles.tbShop__slots}>
                    {value.shifts.map((shift) =>
                      shift.title ? (
                        <li
                          className={styles.tbShop__slot}
                          style={{
                            cellStylesDateNow,
                            background: _setBackgroundStatus(shift.status),
                          }}
                        >
                          <p style={{ color: '#fff042', margin: 0 }}>{shift.title}</p>
                          {moment(shift.time_register_start).format('HH:00') === moment(shift.time_start).format('HH:00') &&
                          moment(shift.time_register_end).format('HH:00') === moment(shift.time_end).format('HH:00') ? null : (
                            <p>
                              ({moment(shift.time_register_start).format('HH:mm')} -{' '}
                              {moment(shift.time_register_end).format('HH:mm')})
                            </p>
                          )}
                        </li>
                      ) : null
                    )}
                  </ul>
                ) : null
              ) : (
                value
              )}
            </div>
          )
        },
      }
    })
  }

  const background = (item) => {
    const shiftId = item.shift_id
    const date = predate
    if (typeof shiftArray[date] == 'undefined') {
      shiftArray[date] = []
    }
    let shiftArrTmp = shiftArray[date]
    shiftArrTmp = shiftArrTmp.filter((item) => item.is_delete != true)
    const arr = shiftArrTmp.map((item) => item.shift_id)

    return arr.includes(shiftId)
      ? [
          { color: '#52C41A', background: '#F6FFED', borderColor: '#B7EB8F', height: '150px' },
          1,
        ]
      : [
          {
            height: '150px',
            color: '#5F73E2',
            background: '#F0F5FF',
            borderColor: '#E6ECFF',
          },
          0
        ]
  }
  const shiftArrayNewData = (s) => {
    const arr = []

    for (const staffShift of s) {
      const date = staffShift.day
      if (typeof arr[date] == 'undefined') {
        arr[date] = []
      }
      let itemShift = {
        branch_id: branchIdApp,
        staff_id: staffShift.staff_id,
        schedule_id: staffShift.schedule_id,
        shift_id: staffShift.shift_id,
        day: staffShift.day,
        is_delete: staffShift.is_delete,
        shift_for_register_id: staffShift.shift_for_register_id,
        time_register_start: staffShift.time_register_start,
        time_register_end:staffShift.time_register_end
      }
      arr[date].push(itemShift)
    }
    setShiftArray(arr)
  }

  const onFinish = async () => {
    try {
      const date = predate
      if (shiftArray[date].length == 0) {
        notification.error({ message: 'Vui lòng chọn ít nhất 1 ca đăng kí' })
      }
      let res
      for (let p = 0; p < shiftArray[date].length; p++) {
        let body = shiftArray[date][p]
        res = await createStaffShift(body)
      }
      setIsModalVisible(false)
      if (res.status === 200) {
        if (res.data.success) {
          toggle()
          notification.success({
            message: 'Đăng kí ca thành công',
          })
        } else
          notification.error({
            message: res.data.message || 'Đăng kí ca thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Đăng kí ca thất bại, vui lòng thử lại',
        })
      _getDataStaffShift(id)
      // _getSchedule(id)
      _getStaffShift(id)
    } catch (error) {
      console.log(error)
    }
  }
  const onUpdate = async () => {
    try {
      const date = predate
      if (shiftArray[date].length == 0) {
        notification.error({ message: 'Vui lòng chọn ít nhất 1 ca đăng kí' })
      }
      let res
      for (let p = 0; p < shiftArray[date].length; p++) {
        let body = shiftArray[date][p]

        res = await updateStaffShift(body)
      }
      setIsModalVisible(false)
      if (res.status === 200) {
        if (res.data.success) {
          toggle()
          notification.success({
            message: 'Chỉnh sửa đăng kí ca thành công',
          })
        } else
          notification.error({
            message: res.data.message || 'Chỉnh sửa Đăng kí ca thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Chỉnh sửa Đăng kí ca thất bại, vui lòng thử lại',
        })
        // _getSchedule(id)
      _getDataStaffShift(id)
      _getStaffShift(id)
    } catch (error) {
      console.log(error)
    }
  }
  const addItemShift = (register) => {
    const date = predate
    const shiftId = register.shift_id
    let itemShift
    itemShift = {
      branch_id: branchIdApp,
      staff_id: user.user_id,
      schedule_id: Number(id),
      shift_id: shiftId,
      is_delete: false,
      shift_for_register_id: register.shift_for_register_id,
      day: predate,
      time_register_start: register.time_register_start,
      time_register_end: register.time_register_end,
    }

    if (typeof shiftArray[date] == 'undefined') {
      shiftArray[date] = []
    }

    let shiftArrayTmp = shiftArray[date]

    let index = shiftArrayTmp.findIndex((item) => item.shift_id === shiftId)
    if (index !== -1) {
      shiftArrayTmp[index].is_delete = !shiftArrayTmp[index].is_delete
      if(shiftArrayTmp[index].is_delete === false) {
        shiftArrayTmp[index].time_register_start = register.time_register_start
        shiftArrayTmp[index].time_register_end = register.time_register_end
      }
    } else {
      shiftArrayTmp.push(itemShift)
    }
    shiftArray[date] = shiftArrayTmp
    setShiftArray(shiftArray)
    setShiftSchedule([])

  }
  useEffect(() => {
    const dataTest = async () => {
      try {
        if (schedule && schedule.all_day) {
          for (let i = 0; i < schedule.all_day.length; i++) {
            if (schedule.all_day[i].day == predate) {
              if (!schedule.all_day[i].shifts) {
                setShiftSchedule([])
              } else setShiftSchedule(schedule.all_day[i].shifts)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    dataTest()
  }, [schedule, predate, shiftSchedule])

  const _getDaysOfWeekBetweenDates = (sDate = schedule.start_range, eDate = schedule.end_range) => {
    if (schedule.start_range && schedule.end_range) {
      const startDate = moment(sDate)
      const endDate = moment(eDate)

      endDate.add(1, 'day')

      let daysOfWeek = []

      let i = 0

      const dayNames = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy']

      while (i < endDate && startDate < endDate) {
        daysOfWeek.push({ dayTh: dayNames[startDate.day()], day: startDate.format('YYYY-MM-DD') })
        startDate.add(1, 'day')
        i++
      }
      return setDayWeek(daysOfWeek)
    }
  }
  useEffect(() => {
    _getDaysOfWeekBetweenDates()
  }, [schedule])

  // useEffect(() => {
  //   _getDataStaffShift(id)
  // }, [predate, schedule])

  useEffect(() => {
    _getShiftForRegister(id, predate)
  }, [predate])

  const getStaffWithDataShift = () => {
    let staffs = { ...user }
    for (const dayIndex in dayWeek) {
      let { day, dayTh } = dayWeek[dayIndex]

      if (typeof staffs.all_day == 'undefined') {
        staffs.all_day = {}
      }

      const staffShiftByDayAndUserId = staffShift.filter((item) => item.day == day)
      const shiftRegister = staffShift.length
      const shiftChoose = staffShift.filter((item) => item.status == 1).length
      staffs.shiftRegister = shiftRegister
      staffs.shiftChoose = shiftChoose
      staffs.all_day[day] = {
        day,
        dayTh,
        shifts: staffShiftByDayAndUserId.map((item) => {
          const shiftInfo = shifts.find((item2) => item.shift_id == item2.shift_id)
          const status = item.status
          const time_register_start = item.time_register_start
          const time_register_end = item.time_register_end
          let shiftAddStatus = { ...shiftInfo, status, time_register_start, time_register_end }
          return shiftAddStatus
        }),
      }
    }
    let arrTmp = []
    arrTmp.push(staffs)
    const shopData = getShopData(arrTmp)
    if (shopData) {
      setColumnShopTable(getColumnShopTable(shopData[0]))
      setShopTableData(shopData)
    }
  }

  const _setBackgroundStatus = (e) => {
    if (e === 0) return '#a8a2a2'
    if (e === 1) return '#0089DF'
  }

  const _getShiftWithDay = () => {
    for (const dayIndex in dayWeek) {
      let { day, dayTh } = dayWeek[dayIndex]

      const ShiftByDay = shiftForRegister.filter((item) => item.day == day)

      dayWeek[dayIndex] = {
        day,
        dayTh,
        Shifts: ShiftByDay.map((item) => {
          const shiftInfo = shifts.find((item2) => item.shift_id == item2.shift_id)
          const staffShiftByDayAndUserId = staffShifts.filter(
            (item3) => item3.day == day && item.shift_id == item3.shift_id && item3.status == 1
          )
          let staff = staffShiftByDayAndUserId.map((item) => {
            const staffInfo = employee.find((item2) => item.staff_id == item2.user_id)
            return staffInfo
          })
          let amountPeople = staffShiftByDayAndUserId.length
          let amountNeedTmp = 0
          let missing = 0
          if (shiftInfo) {
            for (let i = 0; i < shiftInfo.role.length; i++) {
              amountNeedTmp = amountNeedTmp + shiftInfo.role[i].total
            }
            missing = amountNeedTmp - amountPeople
          }
          let shiftAndStaff = { ...shiftInfo, staffs: staff, missing: missing }

          return shiftAndStaff
        }),
      }
      dayWeek[dayIndex].Shifts = sortBy(dayWeek[dayIndex].Shifts, [
        (o) => {
          return o.time_start
        },
      ])
    }
    setHeaders(getColumns(dayWeek))
    setTableData(getDataSource(dayWeek))
  }
  useEffect(() => {
    getStaffWithDataShift()
    _getShiftWithDay()
  }, [staffShifts, staffShift, shifts, employee, schedule, user])

  // useEffect(() => {
  //   _getSchedule(id)
  // }, [id])

  return (
    <div>
      <div className={styles['WeekStaff']}>
        <h1 style={{ textAlign: 'center', fontSize: 35 }}>Lịch đăng ký</h1>
        <div className={styles.schedule__layout}>
          <TableSyled columns={headers} dataSource={tableData} pagination={false} />
        </div>
      </div>

      <h1 style={{ textAlign: 'center', fontSize: 35 }}>Lịch theo tuần</h1>

      <div className={styles.schedule__layout}>
        <ShopTableStyled columns={columnShopTable} dataSource={shopTableData} pagination={false} />
      </div>

      <ModalRegisterShift
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        onFinish={onFinish}
        addItemShift={addItemShift}
        onUpdate={onUpdate}
        shiftStaffDay={shiftStaffDay}
        employee={user}
        shiftSchedule={shiftSchedule}
        background={background}
        roles={roles}
        setShiftStaffDay={setShiftStaffDay}
        predate={predate}
      />
    </div>
  )
}

export default ScheduleWeek
