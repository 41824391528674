import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { PERMISSIONS, ROUTES, FILTER_COL_HEIGHT, PAGE_SIZE_OPTIONS, PAGE_SIZE } from 'consts'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//antd
import { Popconfirm, Input, Button, Row, Col, Table, notification, Form, Switch, Space } from 'antd'

//icon antd
import { PlusCircleOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons'

//components
import { compare, formatCash } from 'utils'
import TitlePage from 'components/title-page'

//api
import { getPromotions, updatePromotion, deletePromotion } from 'apis/promotion'
import FilterDate from 'components/filter-date'

//language
import { useTranslation } from 'react-i18next'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function Promotion() {
  const [visible, setVisible] = useState(false)
  const [sumPromotion, setSumPromotion] = useState(0)
  const [countPromotion, setCountPromotion] = useState(0)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 10,
  })
  const [listPromotion, setListPromotion] = useState()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const typingTimeoutRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [valueTime, setValueTime] = useState()

  const onClose = () => {
    setVisible(false)
  }

  const columnsPromotion = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: '4%',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã khuyến mãi',
      width: '10%',
      dataIndex: 'code',
      render: (text, record) =>
        permissions.includes(PERMISSIONS.xem_chi_tiet_khuyen_mai) ? (
          <a
            href
            onClick={() =>
              history.push({
                pathname: ROUTES.PROMOTION_UPDATE + '/' + record.promotion_id,
                state: record,
              })
            }
          >
            {record.code}
          </a>
        ) : (
          <div
            style={{ color: '#5F73E2', cursor: 'pointer' }}
            onClick={() => notification.warning({ message: 'Permission denied!' })}
          >
            {record.code}
          </div>
        ),
      sorter: (a, b) => compare(a, b, 'promotion_id', Number),
    },
    {
      title: 'Tên khuyến mãi',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => compare(a, b, 'name'),
    },
    {
      title: 'Số lượng khuyến mãi',
      sorter: (a, b) => compare(a, b, 'limit_quantity', Number),
      render: (text, record) => (
        <span style={{ float: 'right' }}>
          {record.limit_quantity !== 0 ? record.limit_quantity : 'Không giới hạn số lượng'}
        </span>
      ),
    },
    {
      title: 'Số lượng còn lại',
      sorter: (a, b) => compare(a, b, 'voucher_quantity', Number),
      render: (text, record) => (
        <span style={{ float: 'right' }}>
          {record.limit_quantity !== 0 ? record.voucher_quantity : 'Không giới hạn số lượng'}
        </span>
      ),
    },
    {
      title: 'Điều kiện áp dụng',
      dataIndex: 'order_value_require',
      key: 'order_value_require',
      sorter: (a, b) => compare(a, b, 'order_value_require', Number),
      render: (text, record) => <span style={{ float: 'right' }}>{formatCash(text)}</span>,
      width: '8%',
    },
    {
      title: 'Giá trị khuyến mãi',
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => compare(a, b, 'value', Number),
      render: (text, record) => <span style={{ float: 'right' }}>{formatCash(text)}</span>,
      width: '8%',
    },
    {
      title: 'Loại khuyến mãi',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => compare(a, b, 'type'),
      render: (text, record) => <span>{record.type === 'VALUE' ? 'Giá trị' : 'Phần trăm'}</span>,
    },
    {
      title: 'Thời hạn khuyến mãi',
      dataIndex: 'end_date',
      render: (text, record) =>
        record.end_date !== '' && moment(record.end_date).format('YYYY-MM-DD'),
      sorter: (a, b) => compare(a, b, 'end_date'),
    },
    {
      title: 'Hành động',
      dataIndex: 'is_active',
      render: (text, record) => (
        <Space size="middle">
          <>
            {permissions.includes(PERMISSIONS.cap_nhat_khuyen_mai) ? (
              <Switch
                checked={text}
                onChange={(checked) => _updatePromotion(record.promotion_id, checked)}
              />
            ) : (
              <Switch
                disabled={true}
                checked={text}
                onChange={(checked) => _updatePromotion(record.promotion_id, checked)}
              />
            )}
          </>
          <>
            {permissions.includes(PERMISSIONS.xoa_khuyen_mai) ? (
              <Popconfirm
                onConfirm={() => _deletePromotion(record.promotion_id)}
                title="Bạn muốn xoá khuyến mãi này?"
                okText="Đồng ý"
                cancelText="Không"
              >
                <Button type="primary" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            ) : (
              <Button disabled type="primary" danger icon={<DeleteOutlined />} />
            )}
          </>
        </Space>
      ),
    },
  ]
  const openNotification = (e) => {
    notification.success({
      description: e ? 'Kích hoạt khuyến mãi thành công' : 'Hủy kích hoạt khuyến mãi thành công',
    })
  }

  const _updatePromotion = async (id, values) => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await updatePromotion(id, { is_active: values })
      if (res.status === 200) {
        if (res.data.success) {
          openNotification(values)
          onClose()
          form.resetFields()
          _getPromotions()
        }
      } else {
        notification.error({ message: res.data.message })
      }
      dispatch({ type: 'LOADING', data: false })
    } catch (e) {
      console.log(e)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _deletePromotion = async (value) => {
    try {
      const body = { promotion_id: value, branch_id: branchIdApp }
      const res = await deletePromotion(body)
      if (res.status === 200) {
        if (res.data.success) {
          _getPromotions()
          notification.success({ message: 'Xóa khuyến mãi thành công' })
        } else
          notification.error({
            message: res.data.message || 'Xóa khuyến mãi thất bại',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa khuyến mãi thất bại',
        })
    } catch (err) {
      console.log(err)
    }
  }

  const _getPromotions = async () => {
    try {
      setLoading(true)
      const res = await getPromotions({
        ...paramsFilter,
      })
      if (res.status === 200) {
        setSumPromotion(res.data.total_quantity)
        setListPromotion(res.data.data)
        setCountPromotion(res.data.count)
      } else {
        throw res
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  const _clearFilters = () => {
    setValueSearch(null)
    setParamsFilter({
      page: 1,
      page_size: PAGE_SIZE,
      check: 1,
    })
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoặc filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }
  useEffect(() => {
    if (paramsFilter.check) _getPromotions()
  }, [branchIdApp, paramsFilter])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueSearch('')
    if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
      setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
      setValueTime()
    } else {
      setParamsFilter({
        page: 1,
        page_size: PAGE_SIZE,
        from_date: result.from_date,
        to_date: result.to_date,
        check: 1,
      })
      setValueTime(result.valueDate)
    }
  }, [dataUser])
  return (
      <div className="card">
        <TitlePage
        isAffix
          title={
            <Row
              align="middle"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
            >
              <ArrowLeftOutlined style={{ marginRight: 8 }} />
              Quản lý khuyến mãi
            </Row>
          }
        >
          <Space>
            <Link to={ROUTES.PROMOTION_FORM}>
              <Button
                disabled={permissions.includes(PERMISSIONS.them_khuyen_mai) ? false : true}
                size="large"
                type="primary"
                icon={<PlusCircleOutlined />}
              >
                Thêm khuyến mãi
              </Button>
            </Link>
          </Space>
        </TitlePage>
        {permissions.includes(PERMISSIONS.xem_danh_sach_khuyen_mai) ?(<>
          <Row style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ height: FILTER_COL_HEIGHT }}>
            <Input
              style={{ width: '100%' }}
              placeholder="Tìm kiếm theo mã và tên khuyến mãi"
              bordered={true}
              onChange={_search}
              allowClear
              value={valueSearch}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{ height: FILTER_COL_HEIGHT}}
          >
            <FilterDate
            size='default'
              bordered={true}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
              title="tạo khuyến mãi"
              valueTime={valueTime}
              setValueTime={setValueTime}
              screen={1}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ height: FILTER_COL_HEIGHT }}>
            <Button
              type="primary"
              danger
              onClick={_clearFilters}
              size="middle"
              icon={<DeleteOutlined />}
              style={{
                display: Object.keys(paramsFilter).length <= 2 && 'none',
                background: '#FF7089',
                border: 'none',
              }}
            >
              Xóa bộ lọc
            </Button>
          </Col>
        </Row>

        <div
          style={{
            width: '100%',
            marginTop: '1rem',
            border: '1px solid rgb(243, 234, 234)',
          }}
        >
          <Table
            size="small"
            rowKey="promotion_id"
            loading={loading}
            columns={columnsPromotion}
            dataSource={listPromotion}
            scroll={{ y: 600 }}
            pagination={{
              position: 'bottomLeft',
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) =>
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
              total: countPromotion,
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell>
                    <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                  </Table.Summary.Cell>
                  {columnsPromotion.map((e, index) => {
                    if (e.key === 'name')
                      return (
                        <Table.Summary.Cell>
                          {' '}
                          <div style={{ whiteSpace: 'nowrap', textAlign: 'end', fontWeight: 700 }}>
                            {sumPromotion}
                          </div>
                        </Table.Summary.Cell>
                      )

                    return <Table.Summary.Cell></Table.Summary.Cell>
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
        </>):null}
        
      </div>
  )
}
