import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { formatCash, removeUnicode } from 'utils'
import { Link, useHistory } from 'react-router-dom'
import { FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE, PERMISSIONS, ROUTES } from 'consts'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { t } from 'i18next'

//components
import TitlePage from 'components/title-page'

//antd
import { Col, Row, DatePicker, Table, Tag, Button, Select, TreeSelect, Space } from 'antd'

//icons
import { ArrowLeftOutlined } from '@ant-design/icons'

//apis
import { getInventoryByDate } from 'apis/report'
import { getCategories } from 'apis/category'
import { getVariants } from 'apis/product'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'
import { check } from 'prettier'

const { Option } = Select

export default function ReportInventoryByDate() {
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [valueFilter, setValueFilter] = useState()
  const [categories, setCategories] = useState([])
  const [product, setProduct] = useState([])
  const [filteredResults, setFilteredResults] = useState([])
  const [reportInventory, setReportInventory] = useState([])
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [isOpenSelectExp, setIsOpenSelectExp] = useState(false)

  const [dataStatus, setDataStatus] = useState([
    { id: 1, value: true, name: 'Còn hạn' },
    { id: 2, value: false, name: 'Hết hạn' },
  ])
  const [reportInventoryToExport, setReportInventoryToExport] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [valueTime, setValueTime] = useState() //dùng để hiện thị value trong filter by time
  const [valueTimeMfg, setValueTimeMfg] = useState() //dùng để hiện thị value trong filter by time
  const [countReport, setCountReport] = useState(0)
  const [sumInventory, setSumInventory] = useState(0)

  //dùng để hiện thị date trong filter by date
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({})
  const [dateNow, setDateNow] = useState(moment().format('YYYY-MM-DD'))

  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const toggleOpenSelectEXP = () => setIsOpenSelectExp(!isOpenSelectExp)

  const _onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const onFilterStatus = (status) => {
    if (status == 1) {
      delete paramsFilter.exp_to_date
      paramsFilter.exp_from_date = moment().format('yyyy-MM-DD')
    } else if (status == 2) {
      delete paramsFilter.exp_from_date
      paramsFilter.exp_to_date = moment().subtract(1, 'days').format('yyyy-MM-DD')
    } else {
      delete paramsFilter.exp_from_date
      delete paramsFilter.exp_to_date
    }

    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _clearFilters = async () => {
    await _reportInventory()
    setValueFilter()
    setValueTime()
    setValueTimeMfg()
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check:1 })
  }

  const columnsDefault = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => index + 1,
      fixed: 'left',
      width: 75,
    },
    {
      title: 'Mã lô',
      fixed: 'left',
      // width: 100,
      render: (text, record, index) =>
        record.code ? record.code : 'Phiếu đã xoá',
        // <Link
        // >
        //   {record.code ? record.code : 'Phiếu đã xoá'}
        // </Link>
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'name',
      fixed: 'left',
      render: (text, record, index) => (
        <div>
          {record?.product_info?._categories?.length > 0
            ? record?.product_info?._categories[0].name
            : 'Chưa có'}
        </div>
      ),
      // width: 150,
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      fixed: 'left',
      render: (text, record, index) => <div>{record?.product_info?.name}</div>,
      // width: 150,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      fixed: 'left',
      render: (text, record, index) => <div>{record?.product_info?.sku}</div>,
    },
    {
      title: 'Tên phiên bản',
      fixed: 'left',
      render: (text, record, index) => <div>{record?.variant_info?.title}</div>,
      // width: 150,
    },
    {
      title: 'SKU phiên bản',
      fixed: 'left',
      render: (text, record, index) => <div>{record?.variant_info?.sku}</div>,
    },
    // {
    //   title: 'Đơn vị tính',
    //   dataIndex: 'unit',
    //   fixed: 'left',
    //   render: (text, record, index) => <div>{record.product_info.unit}</div>,
    // },
    {
      title: 'Ngày sản xuất',
      dataIndex: 'mfg',
      fixed: 'left',
      render: (text, record, index) => <div>{record?.mfg}</div>,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'exp',
      fixed: 'left',
      render: (text, record, index) => <div>{record?.exp}</div>,
    },
    {
      title: 'Số ngày đến hạn',
      dataIndex: 'unit',
      fixed: 'left',
      render: (text, record, index) => (
        <div>
          {record.exp > dateNow ? (
            <Tag
              style={{
                fontSize: 16,
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
              color={'green'}
            >
              {parseInt((moment(record.exp).unix() - moment(dateNow).unix()) / 86400)}
            </Tag>
          ) : record.exp === dateNow ? (
            <Tag
              style={{
                fontSize: 16,
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
              color={'green'}
            >
              {'0'}
            </Tag>
          ) : (
            <Tag
              style={{
                fontSize: 16,
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
              color={'red'}
            >
              {'-'}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Trạng thái sản phẩm',
      dataIndex: 'status',
      fixed: 'left',
      render: (text, record, index) =>
        record.exp >= dateNow ? (
          <Tag
            style={{
              fontSize: 14,
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
            color={'green'}
          >
            {'Còn hạn'}
          </Tag>
        ) : (
          <Tag
            style={{
              fontSize: 14,
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
            color={'red'}
          >
            {'Hết hạn'}
          </Tag>
        ),
      // width: 50,
    },
    {
      title: 'Tồn kho',
      dataIndex: 'unit',
      render: (text, record, index) => (
        <div
          style={{
            width: '100%',
            fontSize: 16,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {record.quantity}
        </div>
      ),
      // width: 50,
    },
  ]

  const [columns, setColumns] = useState(columnsDefault)
  const [columnsExport, setColumnsExport] = useState(columnsDefault)

  const _reportInventory = async () => {
    try {
      setLoading(true)
      const res = await getInventoryByDate({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status == 200) {
        let arrNew = _.sortBy(res.data.data, ['variant_info.sku', 'exp', 'mfg'])
        setCountReport(res.data.count)
        setReportInventory(arrNew)
        var sum_inventory_quantity = 0
        res.data.data.map((item) => {
          sum_inventory_quantity += item.quantity
        })
        setSumInventory(sum_inventory_quantity)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _reportInventoryToExport = async () => {
    try {
      setLoading(true)
      const res = await getInventoryByDate({ type: 'product' })
      if (res.status === 200) {
        setCountReport(res.data.count)
        const columnsNew = [...columnsDefault]
        let reportNew = []
        let warehousesNameNew = []

        res.data.data.map((e) => {
          let report = {
            code: e.product ? e.product.code : '',
            name: e.product ? e.product.name : '',
            unit: e.product ? e.product.unit : '',
            categories: e.product ? e.product._categories : [],
          }

          e.warehouse.map((w) => {
            if (w.branch) report[w.branch.name] = { quantity: w.quantity || 0, price: w.price || 0 }
          })

          reportNew.push(report)
        })

        res.data.data.map((e) => {
          e.warehouse.map((item) => {
            if (item.branch) {
              const findBranch = columnsNew.find((e) => e.title === item.branch.name)
              if (!findBranch) {
                const branchName = item.branch ? item.branch.name : ''
                const column = {
                  title: branchName,
                  children: [
                    {
                      title: 'Số lượng',
                      render: (text, record) =>
                        record[branchName] ? formatCash(record[branchName].quantity || 0) : 0,
                    },
                    {
                      title: 'Thành tiền',
                      render: (text, record) =>
                        record[branchName] ? formatCash(record[branchName].price || 0) : 0,
                    },
                  ],
                }

                warehousesNameNew.push(branchName)
                columnsNew.push(column)
              }
            }
          })
        })

        setReportInventoryToExport([...reportNew])
        setColumnsExport([...columnsNew])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _getCategories = async () => {
    try {
      const res = await getCategories()
      if (res.status === 200) setCategories(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getProducts = async () => {
    try {
      const res = await getVariants()
      if (res.status === 200) setProduct(res.data.data.filter((item) => item.type_advanced === 2))
      setFilteredResults(res.data.data.filter((item) => item.type_advanced === 2))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(paramsFilter.check) _reportInventory()
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueFilter()
    if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
      setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
      setValueTime()
      setValueTimeMfg()
    } else {
      setParamsFilter({
        page: 1,
        page_size: PAGE_SIZE,
        exp_to_date: result.to_date,
        exp_from_date: result.from_date,
        mfg_to_date: result.to_date,
        mfg_from_date: result.from_date,
        check: 1,
      })
      setValueTime(`${result.valueDate}_exp`)
      setValueTimeMfg(`${result.valueDate}_mfg`)
    }
  }, [dataUser])
  useEffect(() => {
    _getCategories()
    _getProducts()
  },[branchIdApp])
  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.REPORTS)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Tồn kho theo ngày hết hạn
          </Row>
        }
      >
        <Space>
          <Button
            onClick={_clearFilters}
            style={{
              display: Object.keys(paramsFilter).length < 3 && 'none',
              width: '100%',
            }}
            danger
            type="primary"
          >
            Xóa bộ lọc
          </Button>
        </Space>
      </TitlePage>

      <Row
        gutter={[8, 16]}
        style={{
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        {permissions.includes(PERMISSIONS.bo_loc_thoi_gian) || dataUser.role_id === 1 ? (
        <Col xs={24} sm={24} md={12} lg={8} xl={5}>
          <Select
            open={isOpenSelectExp}
            onBlur={() => {
              if (isOpenSelectExp) toggleOpenSelectEXP()
            }}
            onClick={() => {
              if (!isOpenSelectExp) toggleOpenSelectEXP()
            }}
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder="Lọc theo ngày sản xuất"
            optionFilterProp="children"
            bordered={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={valueTimeMfg}
            onChange={async (value) => {
              setValueTimeMfg(value)
              paramsFilter.page = 1

              //xoa params search date hien tai
              const p = Object.keys(valueDateTimeSearch)
              if (p.length) delete paramsFilter[p[0]]

              delete paramsFilter.exp_from_date
              delete paramsFilter.exp_to_date

              if (isOpenSelectExp) toggleOpenSelectEXP()

              if (value) {
                const searchDate = Object.fromEntries([[value, true]]) // them params search date moi

                setParamsFilter({ ...paramsFilter, ...searchDate })
                setValueDateTimeSearch({ ...searchDate })
              } else {
                setParamsFilter({ ...paramsFilter })
                setValueDateTimeSearch({})
              }
            }}
            dropdownRender={(menu) => (
              <>
              {permissions.includes(PERMISSIONS.tuy_chon) || dataUser.role_id === 1 ? (
                <DatePicker.RangePicker
                  onFocus={() => {
                    if (!isOpenSelectExp) toggleOpenSelectEXP()
                  }}
                  onBlur={() => {
                    if (isOpenSelectExp) toggleOpenSelectEXP()
                  }}
                  showTime
                  format={'YYYY-MM-DD'}
                  // value={valueDateSearch}
                  onChange={(dates, dateStrings) => {
                    //khi search hoac filter thi reset page ve 1
                    paramsFilter.page = 1
                    if (isOpenSelectExp) toggleOpenSelectEXP()

                    //nếu search date thì xoá các params date
                    delete paramsFilter.today_mfg
                    delete paramsFilter.yesterday_mfg
                    delete paramsFilter.this_week_mfg
                    delete paramsFilter.last_week_mfg
                    delete paramsFilter.last_month_mfg
                    delete paramsFilter.this_month_mfg
                    delete paramsFilter.this_year_mfg
                    delete paramsFilter.last_year_mfg

                    //Kiểm tra xem date có được chọn ko
                    //Nếu ko thì thoát khỏi hàm, tránh cash app
                    //và get danh sách order
                    if (!dateStrings[0] && !dateStrings[1]) {
                      delete paramsFilter.mfg_from_date
                      delete paramsFilter.mfg_to_date

                      setValueTimeMfg()
                    } else {
                      const dateFirst = dateStrings[0]
                      const dateLast = dateStrings[1]
                      setValueTimeMfg(`${dateFirst} -> ${dateLast}`)

                      dateFirst.replace(/-/g, '/')
                      dateLast.replace(/-/g, '/')

                      paramsFilter.mfg_from_date = dateFirst
                      paramsFilter.mfg_to_date = dateLast
                    }

                    setParamsFilter({ ...paramsFilter })
                  }}
                  style={{ width: '100%' }}
                />):null}
                {menu}
              </>
            )}
          >
            {permissions.includes(PERMISSIONS.hom_nay) || dataUser.role_id === 1 ? (
            <Option value="today_mfg">{t('common.today')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.hom_qua) || dataUser.role_id === 1 ? (
            <Option value="yesterday_mfg">{t('common.yesterday')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_nay) || dataUser.role_id === 1 ? (
            <Option value="this_week_mfg">{t('common.this_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_truoc) || dataUser.role_id === 1 ? (
            <Option value="last_week_mfg">{t('common.last_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_nay)|| dataUser.role_id === 1 ? (
            <Option value="this_month_mfg">{t('common.this_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_truoc)|| dataUser.role_id === 1 ? (
            <Option value="last_month_mfg">{t('common.last_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_nay)|| dataUser.role_id === 1 ? (
            <Option value="this_year_mfg">{t('common.this_year')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_truoc)|| dataUser.role_id === 1 ? (
            <Option value="last_year_mfg">{t('common.last_year')}</Option>
          ) : null}
          </Select>
        </Col>):null}
        {permissions.includes(PERMISSIONS.bo_loc_thoi_gian) || dataUser.role_id === 1 ? (
        <Col xs={24} sm={24} md={24} lg={8} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
          <Select
            open={isOpenSelect}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect()
            }}
            onClick={() => {
              if (!isOpenSelect) toggleOpenSelect()
            }}
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder="Lọc theo ngày hết hạn"
            optionFilterProp="children"
            bordered={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={valueTime}
            onChange={async (value) => {
              setValueTime(value)
              paramsFilter.page = 1

              //xoa params search date hien tai
              const p = Object.keys(valueDateTimeSearch)
              if (p.length) delete paramsFilter[p[0]]

              delete paramsFilter.mfg_from_date
              delete paramsFilter.mfg_to_date

              if (isOpenSelect) toggleOpenSelect()

              if (value) {
                const searchDate = Object.fromEntries([[value, true]]) // them params search date moi

                setParamsFilter({ ...paramsFilter, ...searchDate })
                setValueDateTimeSearch({ ...searchDate })
              } else {
                setParamsFilter({ ...paramsFilter })
                setValueDateTimeSearch({})
              }
            }}
            dropdownRender={(menu) => (
              <>
              {permissions.includes(PERMISSIONS.tuy_chon) || dataUser.role_id === 1 ? (
                <DatePicker.RangePicker
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect()
                  }}
                  showTime
                  format={'YYYY-MM-DD'}
                  // value={valueDateSearch}
                  onChange={(dates, dateStrings) => {
                    //khi search hoac filter thi reset page ve 1
                    paramsFilter.page = 1
                    if (isOpenSelect) toggleOpenSelect()

                    //nếu search date thì xoá các params date
                    delete paramsFilter.today_exp
                    delete paramsFilter.yesterday_exp
                    delete paramsFilter.this_week_exp
                    delete paramsFilter.last_week_exp
                    delete paramsFilter.last_month_exp
                    delete paramsFilter.this_month_exp
                    delete paramsFilter.this_year_exp
                    delete paramsFilter.last_year_exp

                    //Kiểm tra xem date có được chọn ko
                    //Nếu ko thì thoát khỏi hàm, tránh cash app
                    //và get danh sách order
                    if (!dateStrings[0] && !dateStrings[1]) {
                      delete paramsFilter.exp_from_date
                      delete paramsFilter.exp_to_date

                      setValueTime()
                    } else {
                      const dateFirst = dateStrings[0]
                      const dateLast = dateStrings[1]
                      setValueTime(`${dateFirst} -> ${dateLast}`)

                      dateFirst.replace(/-/g, '/')
                      dateLast.replace(/-/g, '/')

                      paramsFilter.exp_from_date = dateFirst
                      paramsFilter.exp_to_date = dateLast
                    }

                    setParamsFilter({ ...paramsFilter })
                  }}
                  style={{ width: '100%' }}
                />):null}
                {menu}
              </>
            )}
          >
            {permissions.includes(PERMISSIONS.hom_nay) || dataUser.role_id === 1 ? (
            <Option value="today_exp">{t('common.today')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.hom_qua) || dataUser.role_id === 1 ? (
            <Option value="yesterday_exp">{t('common.yesterday')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_nay) || dataUser.role_id === 1 ? (
            <Option value="this_week_exp">{t('common.this_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_truoc) || dataUser.role_id === 1 ? (
            <Option value="last_week_exp">{t('common.last_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_nay)|| dataUser.role_id === 1 ? (
            <Option value="this_month_exp">{t('common.this_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_truoc)|| dataUser.role_id === 1 ? (
            <Option value="last_month_exp">{t('common.last_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_nay)|| dataUser.role_id === 1 ? (
            <Option value="this_year_exp">{t('common.this_year')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_truoc)|| dataUser.role_id === 1 ? (
            <Option value="last_year_exp">{t('common.last_year')}</Option>
          ) : null}
          </Select>
        </Col>):null}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={4}
        >
          <Select
            size={FILTER_SIZE}
            allowClear
            bordered={true}
            placeholder="Lọc theo trạng thái"
            style={{ width: '100%' }}
            value={paramsFilter.status || 0}
            defaultValue={0}
            onChange={(id) => {
              setParamsFilter({ ...paramsFilter, status: id })
            }}
          >
            <Select.Option value={0}>Tất cả trạng thái</Select.Option>
            {dataStatus.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
          <TreeSelect
            style={{
              width: '100%',
            }}
            placeholder="Lọc theo nhóm sản phẩm "
            allowClear
            multiple
            size={FILTER_SIZE}
            // treeNodeFilterProp="name"
            treeDefaultExpandAll
            bordered={true}
            maxTagCount="responsive"
            value={
              paramsFilter.category_id && paramsFilter.category_id.split('---').map((e) => +e) || 0
            }
            filterTreeNode={(input, TreeNode) =>
              removeUnicode(TreeNode.title)
                .toLowerCase()
                .trim()
                .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
            }
            onChange={(value) => {
              let index = value.findIndex((item) => item === 0)
              if (index !== -1 && value.length > 1) {
                if (index === 0) {
                  value.splice(index, 1)
                } else {
                  value = 0
                }
              }
              if (value.length) setParamsFilter({ ...paramsFilter, category_id: value.join('---') })
              else {
                delete paramsFilter.category_id
                setParamsFilter({ ...paramsFilter })
              }
            }}
            // defaultValue={0}
            showSearch
          >
            <TreeSelect.TreeNode value={0} title="Tất cả nhóm">
              Tất cả nhóm
            </TreeSelect.TreeNode>

            {categories.map((category, index) => (
              <TreeSelect.TreeNode value={category.category_id} title={category.name} key={index}>
                {category.children_category.map((child) => (
                  <TreeSelect.TreeNode value={child.category_id} title={child.name}>
                    {child.children_category &&
                      child.children_category.map((e) => (
                        <TreeSelect.TreeNode value={e.category_id} title={e.name}>
                          {e.name}
                        </TreeSelect.TreeNode>
                      ))}
                  </TreeSelect.TreeNode>
                ))}
              </TreeSelect.TreeNode>
            ))}
          </TreeSelect>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={5}
          style={{
            height: FILTER_COL_HEIGHT,
          }}
        >
          <Select
            size={FILTER_SIZE}
            allowClear
            showSearch
            bordered={true}
            onChange={(value) => {
              let resultIndex
              if (value !== undefined) {
                let index = value.lastIndexOf('+')
                resultIndex = value.substring(index + 1)
              }

              _onFilter('variant_id', resultIndex)
              setFilteredResults(product)
              setValueFilter(value)
            }}
            value={valueFilter || 'all'}
            placeholder="Lọc theo sản phẩm"
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              removeUnicode(option.children.toString())
                .toLowerCase()
                .trim()
                .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
            }
          >
            <Select.Option value="all">Tất cả sản phẩm</Select.Option>

            {filteredResults.map((_product, index) => (
              <Select.Option value={`${_product.title}+${_product.sku}`} key={index}>
                {_product.title}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Table
        bordered
        loading={loading}
        size="small"
        scroll={{ y: 600 }}
        style={{ width: '100%' }}
        columns={columns}
        dataSource={reportInventory}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          defaultPageSize: 20,
          pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
          total: countReport,
        }}
        summary={(pageData) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <div style={{ fontWeight: 700 }}>Tổng</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>

              <Table.Summary.Cell index={11}>
                <div
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    fontWeight: 700,
                  }}
                >
                  {sumInventory}
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
