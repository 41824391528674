import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES, PERMISSIONS, FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE, ACTION } from 'consts'
import { compare } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
//antd
import {
  Switch,
  Input,
  Button,
  Row,
  Col,
  Table,
  notification,
  Space,
  Select,
  Popconfirm,
} from 'antd'

//icons
import {
  FileExcelOutlined,
  PlusCircleOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

//apis
import {
  getWarranties,
  updateWarranty,
  deleteWarranty,
  addWarrantyWithFile,
} from 'apis/warranty'
import { getEmployees } from 'apis/employee'

//components
import exportToCSV from 'components/ExportCSV/export'
import TitlePage from 'components/title-page'
import { convertFields, guarantee } from 'components/ExportCSV/fieldConvert'
import ImportCSV from 'components/ImportCSV'
import FilterDate from 'components/filter-date'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

function removeFalse(a) {
  return Object.keys(a)
    .filter((key) => a[key] !== '' && a[key] !== undefined)
    .reduce((res, key) => ((res[key] = a[key]), res), {})
}
export default function Guarantee() {
  const dispatch = useDispatch()
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [users, setUsers] = useState([])
  const [warrantyList, setWarrantyList] = useState([])
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const onSearch = (value) => setParamsFilter({ ...paramsFilter, search: value.target.value })
  const [valueTime, setValueTime] = useState()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const warrantyUpdate = async (id, data) => {
    try {
      const res = await updateWarranty(id, data)
      if (res.data.success) {
        notification.success({
          message: 'Thành công',
          description: `${data.active ? 'Kích hoạt' : 'Vô hiệu hóa'} thành công`,
        })
      }
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'Thất bại',
        description: `${data.active ? 'Kích hoạt' : 'Vô hiệu hóa'} thất bại`,
      })
    }
  }

  const _removeGuarantee = async (id) => {
    try {
      const res = await deleteWarranty({ warranty_id: [id] })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa chương trình bảo hành thành công!' })
          getWarranty()
        } else
          notification.error({
            message: res.data.message || 'Xóa chương trình bảo hành thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa chương trình bảo hành thất bại, vui lòng thử lại!',
        })
    } catch (e) {
      console.log(e)
    }
  }

  const columnsPromotion = [
    {
      title: 'STT',
      width: 60,
      render(data, record, index) {
        return (paramsFilter.page - 1) * paramsFilter.page_size + index + 1
      },
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'code',
      render: (text, record) =>
        permissions.includes(PERMISSIONS.xem_chi_tiet_bao_hanh) ? (
          <a href onClick={() => history.push({ pathname: ROUTES.GUARANTEE_ADD, state: record })}>
            {record.code}
          </a>
        ) : (
          <span style={{cursor:'pointer', color:'#5F73E2'}} onClick={() => notification.warning({message:'Permission denied!'})}>{record.code}</span>
        ),
      sorter: (a, b) => compare(a, b, 'code'),
    },
    {
      title: 'Tên bảo hành',
      dataIndex: 'name',
      sorter: (a, b) => compare(a, b, 'name'),
    },
    {
      title: 'Loại bảo hành',
      dataIndex: 'type',
      sorter: (a, b) => compare(a, b, 'type'),
    },
    {
      title: 'Thời hạn bảo hành',
      dataIndex: 'time',
      render(data) {
        return data + ' tháng'
      },
      sorter: (a, b) => a.time - b.time,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      sorter: (a, b) => compare(a, b, 'description'),
    },
    {
      title: 'Người tạo',
      render: (text, record) =>
        record._creator && record._creator.first_name + ' ' + record._creator.last_name,
      sorter: (a, b) =>
        (a._creator && a._creator.first_name + ' ' + a._creator.last_name).length -
        (b._creator && b._creator.first_name + ' ' + b._creator.last_name).length,
    },
    {
      title: '',
      dataIndex: 'active',
      render(data, record) {
        return (
          <Space>
            <>
              {permissions.includes(PERMISSIONS.cap_nhat_bao_hanh) ? (
                <Switch
                  defaultChecked={data}
                  onChange={(e) => warrantyUpdate(record.warranty_id, { active: e })}
                />
              ) : (
                <Switch disabled={true} />
              )}{' '}
            </>

            <>
              {permissions.includes(PERMISSIONS.xoa_bao_hanh) ? (
                <Popconfirm
                  onConfirm={() => _removeGuarantee(record.warranty_id)}
                  title="Bạn có muốn xóa bảo hành này không?"
                  okText="Đồng ý"
                  cancelText="Từ chối"
                >
                  <Button type="primary" danger icon={<DeleteOutlined />} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  onConfirm={() => _removeGuarantee(record.warranty_id)}
                  title="Bạn có muốn xóa bảo hành này không?"
                  okText="Đồng ý"
                  cancelText="Từ chối"
                >
                  <Button disabled type="primary" danger icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </>
          </Space>
        )
      },
    },
  ]

  const getWarranty = async () => {
    try {
      const res = await getWarranties({
        ...paramsFilter,
        _creator: true,
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        setWarrantyList(res.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const _getUsers = async () => {
    try {
      const res = await getEmployees()
      if (res.status === 200) setUsers(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getUsers()
  }, [])

  useEffect(() => {
    if (paramsFilter.check) getWarranty({ ...removeFalse(paramsFilter) })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
      setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
      setValueTime()
    } else {
      setParamsFilter({
        page: 1, page_size: PAGE_SIZE,
        from_date: result.from_date,
        to_date: result.to_date,
        check: 1,
      })
      setValueTime(result.valueDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser])
  return (
      <div className="card">
        <TitlePage
        isAffix
          title={
            <Row
              onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
              wrap={false}
              align="middle"
              style={{ cursor: 'pointer' }}
            >
              <ArrowLeftOutlined style={{ marginRight: 8 }} />
              <div>Quản lý bảo hành</div>
            </Row>
          }
        >
          <Space>
            <>
              {permissions.includes(PERMISSIONS.export_bao_hanh) ? (
                <Button
                id='exportFile'
                  icon={<FileExcelOutlined />}
                  style={{ backgroundColor: '#008816', color: 'white' }}
                  size="large"
                  onClick={() =>{
                    dispatch({ type: ACTION.LOADING, data: true })
                    exportToCSV(
                      warrantyList.map((e) => convertFields(e, guarantee, true)),
                      'bao_hanh',
                      'Xuất file excel',
                      dispatch
                    )
                  }
                  }
                >
                  Xuất excel
                </Button>
              ) : (
                <Button
                  disabled
                  icon={<FileExcelOutlined />}
                  // style={{ backgroundColor: '#008816', color: 'white' }}
                  size="large"
                >
                  Xuất excel
                </Button>
              )}
            </>
            <>
              {permissions.includes(PERMISSIONS.them_bao_hanh) ? (
                <>
                  <ImportCSV
                    size="large"
                    txt="Import phiếu bảo hành"
                    upload={addWarrantyWithFile}
                    title="Nhập phiếu bảo hành bằng file excel"
                    fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/05/31/b2e48ee8-f3d4-4047-90e7-5baf22100ed6/guarantee.xlsx"
                    reload={getWarranty}
                  />
                  <Link to={ROUTES.GUARANTEE_ADD}>
                    <Button
                      icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                      type="primary"
                      size="large"
                    >
                      Tạo phiếu bảo hành
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <Button
                    disabled
                    icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                    type="primary"
                    size="large"
                  >
                    Import phiếu bảo hành
                  </Button>
                  <Link to={ROUTES.GUARANTEE_ADD}>
                    <Button
                      disabled
                      icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                      type="primary"
                      size="large"
                    >
                      Tạo phiếu bảo hành
                    </Button>
                  </Link>
                </>
              )}
            </>
          </Space>
        </TitlePage>
        {permissions.includes(PERMISSIONS.xem_danh_sach_bao_hanh)?(<>
          <Row wrap={false} style={{ marginTop: '1rem' }}>
              <Col xs={24} sm={24} md={7} lg={7} xl={7} style={{ height: FILTER_COL_HEIGHT }}>
                <Input
                  size={FILTER_SIZE}
                  placeholder="Tìm kiếm theo mã, theo tên"
                  value={paramsFilter.search}
                  onChange={onSearch}
                  enterButton
                  allowClear
                  bordered={true}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={7}
                lg={7}
                xl={7}
                style={{
                  height: FILTER_COL_HEIGHT,
                }}
              >
                <FilterDate
                size='default'
                  bordered={true}
                  paramsFilter={paramsFilter}
                  setParamsFilter={setParamsFilter}
                  title="tạo bảo hành"
                  valueTime={valueTime}
                  setValueTime={setValueTime}
                  screen={1}
                />
              </Col>
              <Col xs={24} sm={24} md={7} lg={7} xl={7} style={{ height: FILTER_COL_HEIGHT }}>
                <Select
                  size={FILTER_SIZE}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  showSearch
                  placeholder="Chọn người tạo"
                  style={{ width: '100%' }}
                  value={paramsFilter.creator_id || 'all'}
                  onChange={(value) => {
                    if (value) paramsFilter.creator_id = value
                    else paramsFilter.creator_id = undefined

                    if (value == 'all') {
                      delete paramsFilter.creator_id
                    }

                    paramsFilter.page = 1
                    setParamsFilter({ ...paramsFilter })
                  }}
                  defaultValue="all"
                  bordered={true}
                >
                  <Select.Option value="all">Tất cả người tạo</Select.Option>
                  {users.map((user, index) => (
                    <Select.Option key={index} value={user.user_id}>
                      {user.first_name || ''} {user.last_name || ''}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
         
          <Col xs={24} sm={24} md={3} lg={3} xl={3} style={{ marginLeft: 10 }}>
            <Button
              type="primary"
              danger
              onClick={() => {
                setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
                setValueTime()
              }}
            >
              Xóa bộ lọc
            </Button>
          </Col>
        </Row>

        <div
          style={{
            width: '100%',
            marginTop: '1rem',
            border: '1px solid rgb(243, 234, 234)',
          }}
        >
          <Table
            size="small"
            columns={columnsPromotion}
            pagination={{
              onChange: (page, page_size) => setParamsFilter({ ...paramsFilter, page, page_size }),
            }}
            dataSource={warrantyList}
            style={{ width: '100%' }}
          />
        </div></>):null}
        
      </div>
  )
}
