import { formatCash } from 'utils'
import { Button, Checkbox, Modal, Row, Select, Col, Timeline } from 'antd'
import React, { useState, useEffect } from 'react'
import { getPayments } from 'apis/payment'
import { EditOutlined } from '@ant-design/icons'

const { Option } = Select

const ChangeDeliveryMethod = ({ method, editInvoice, idOld }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [payments, setPayments] = useState()
  const [paymentFinal, setPaymentFinal] = useState({})

  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) setPayments(res.data.data.filter(e => e.payment_method_id !== 2 && e.payment_method_id !== 6))
    } catch (error) {
      console.log(error)
    }
  }

  const _editInvoice = () => {
    setIsModalOpen(!isModalOpen)
    editInvoice(paymentFinal)
  }
  const changePayment = (e) => {
    setPaymentFinal({ data: e, idOld: idOld })
  }

  useEffect(() => {
    _getPayments()
  }, [])
  return (
    <>
      <p
        style={{ marginBottom: 0, color: '#1890ff', cursor: 'pointer', padding: 0 }}
        onClick={() => setIsModalOpen(true)}
      >
        <Button
          style={{ width: 24, height: 24, marginLeft: 10 }}
          icon={<EditOutlined />}
          type="primary"
        />
      </p>

      <Modal
        footer={
          <Row justify="end">
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                width: 100,
                borderColor: '#3579FE',
                borderRadius: 5,
              }}
            >
              Đóng
            </Button>

            <Button
              type="primary"
              style={{
                width: 100,
                borderRadius: 5,
              }}
              onClick={() => _editInvoice()}
            >
              Ghi nhận
            </Button>
          </Row>
        }
        closable={false}
        title="Thay đổi phương thức thanh toán"
        visible={isModalOpen}
      >
        <div>Chọn phương thức thanh toán</div>
        <div>
          <Timeline style={{ margin: 10 }}>
            <Row style={{ width: '100%' }}>
              <Col span={15}>
                <Select
                  style={{ width: 300 }}
                  defaultValue={method.name}
                  onChange={(e) => changePayment(e)}
                >
                  {payments &&
                    payments.map((item) => (
                      <Option value={`${item.name}-${item.payment_method_id}`}>{item.name}</Option>
                    ))}
                </Select>
              </Col>
              <Col style={{ marginLeft: 40 }}>Giá trị: {formatCash(method.value)}</Col>
            </Row>
          </Timeline>
        </div>
      </Modal>
    </>
  )
}
export default ChangeDeliveryMethod
