import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION, IMAGE_DEFAULT, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import {
  compare,
  compareCustom,
  formatCash,
  removeUnicode
} from 'utils'
import moment from 'moment'

//components
import TitlePage from 'components/title-page'

//antd
import {
  Row,
  Form,
  Upload,
  Input,
  Button,
  notification,
  Table,
  Tag,
  Col,
  Select,
  Spin,
  Badge,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
  CloseOutlined,
  SearchOutlined,
  CloseCircleFilled,
} from '@ant-design/icons'

//apis
import { uploadFile } from 'apis/upload'
import { updateCategory, getOneCategory } from 'apis/category'
import { useTranslation } from 'react-i18next'
import { getProductsSystem } from '../../redux/actions/products'

export default function Category({ title, toggle, reload }) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const typingTimeoutRef = useRef(null)
  const [categoryModel, setCategoryModel] = useState()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const { code } = useParams()

  let regexCategoryName = /[a-zA-Z0-9\+]/
  const [loadingProduct, setLoadingProduct] = useState(false)

  const [fileUpload, setFileUpload] = useState(null)
  const [imageView, setImageView] = useState('')
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 20 })
  const [products, setProducts] = useState([])
  const [dataProducts, setDataProducts] = useState([])

  const [productsUpdate, setProductsUpdate] = useState([])
  const [loading, setLoading] = useState(false)
  const [countProduct, setCountProduct] = useState(0)

  const [filteredResults, setFilteredResults] = useState([])
  const { productsFil } = useSelector((state) => state.products)
  const productsData = useSelector((state) => state.products.products)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [match, setMatch] = useState('all')
  const [conditions, setConditions] = useState([
    { name: 'name', operator: 'is_equal_to', value: '' },
  ])
  const columnsProduct = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      key: 'name-product',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Nhóm sản phẩm',
      key: 'category',
    },
    {
      title: 'Nhà cung cấp',
      key: 'supplier',
    },
    {
      title: 'Ngày tạo',
      key: 'create_date',
    },
    {
      title: 'Hành động',
      width: 90,
      key: 'action',
    },
  ]

  const _removeProductToCategory = (id) => {
    const productsNew = [...products]
    const productsUpdateNew = [...productsUpdate]
    const dataProductNew = [...dataProducts]

    const indexProduct = productsNew.findIndex((p) => p.product_id === id)
    const indexDataProduct = dataProductNew.findIndex((p) => p.product_id === id)


    if (indexProduct !== -1) {
      productsNew.splice(indexProduct, 1)
    }
    if (indexDataProduct !== -1) {
      productsUpdateNew.push(id)
    }

    setProductsUpdate([...productsUpdateNew])
    setProducts([...productsNew])
  }

  const columnsVariant = [
    {
      title: 'Hình ảnh',
      key: 'image',
      render: (text, record) =>
        record.image ? (
          <img
            src={record.image || IMAGE_DEFAULT}
            alt=""
            style={{ width: 75, height: 75, objectFit: 'contain' }}
          />
        ) : (
          <img src={IMAGE_DEFAULT} alt="" style={{ width: 75, height: 75 }} />
        ),
    },
    {
      title: 'Thuộc tính',
      dataIndex: 'title',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
    },
  ]

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const _addOrUpdateCategory = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      if (!regexCategoryName.test(dataForm.name)) {
        notification.error({ message: 'Tên nhóm sản phẩm ko hợp lệ' })
        return
      }
      dispatch({ type: ACTION.LOADING, data: true })
      let productNew = products.map(e => e.product_id)
      const image = await uploadFile(fileUpload)
      const body = {
        ...dataForm,
        parent_id: -1,
        image: image || imageView || '',
        default: dataForm.default || false,
        description: dataForm.description || '',
        branch_id: branchIdApp || '',
        condition: {
          must_match: match,
          function: conditions,
        },
        name: dataForm.name.trim(),
        products: productNew,
        productOut: productsUpdate
      }
      let res = await updateCategory(body, categoryModel.category_id)
 

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `${'Cập nhật nhóm sản phẩm thành công'}`,
          })
          if (title === 'product-form') {
            toggle()
            reload()
          } else {
            history.push(ROUTES.CATEGORIES)
          }
        } else
          notification.error({
            message: res.data.mess || res.data.message || `Cập nhật'nhóm sản phẩm thất bại!`,
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || `'Cập nhật nhóm sản phẩm thất bại!`,
        })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const addProduct = async (data) => {
    const listProductNew = [...products]
    var variantNew = {
      product_id: data.product_id,
      creator: data._creator,

      sku: data.sku,
      name: data.name,
      unit: data.unit,
      images: data.images,
      total_quantity: data.total_quantity,
      total_inventory: 0,
      real_quantity: 1,
      diff_reason: '',
      system_quantity: data.total_quantity,
    }

    if (listProductNew.length === 0) listProductNew.push(variantNew)
    else {
      const indexVariant = products.findIndex((p) => p.product_id === data.product_id)
      if (indexVariant === -1) listProductNew.push(variantNew)
    }
    setProducts([...listProductNew])
  }
 
  const onLoadDetail = async () => {
    if (code) {
      try {
        const res = await getOneCategory({ code: code })
        if (res.status == 200 ){
          form.setFieldsValue({ ...res.data.data })
          setImageView(res.data.data.image || '')
          setCategoryModel(res.data.data)
          setProducts(res.data.data?.products)
          setDataProducts(res.data.data?.products)
        setCountProduct(res.data.count?.products?.length)
        } else {
          notification.error({ message: 'Nhóm sản phẩm không tồn tại!' })
        }
      } catch (err) {
        notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
      }
    }
  }

  useEffect(() => {
    onLoadDetail()
  }, [])

  useEffect(() =>{
    if(!permissions.includes(PERMISSIONS.xem_chi_tiet_nhom_san_pham)) history.push(ROUTES.CATEGORIES)
    if(branchIdApp) {
      dispatch(getProductsSystem({branchIdApp: branchIdApp}))
    }
  },[branchIdApp])

  return (
    <div className="card">
      <div style={{ display: title === 'product-form' && 'none' }}>
        <TitlePage
          isAffix={true}
          title={
            <Row
              align="middle"
              onClick={() => history.push(ROUTES.CATEGORIES)}
              style={{ cursor: 'pointer' }}
            >
              <ArrowLeftOutlined />
              <div style={{ marginLeft: 8 }}>{'Cập nhật'}</div>
            </Row>
          }
        >
          {permissions.includes(PERMISSIONS.cap_nhat_nhom_san_pham) ? (
            <Button size="large" type="primary" onClick={_addOrUpdateCategory}>
              Cập nhật
            </Button>
          ) : (
            <Button disabled size="large" type="primary" onClick={_addOrUpdateCategory}>
              Cập nhật
            </Button>
          )}
        </TitlePage>
      </div>
      <Form layout="vertical" form={form} >
        <Row style={{ margin: '25px 0px',backgroundColor:'white',padding:10 }}>
          <div style={{ width: '60%' }}>
            {/* <Form.Item valuePropName="checked" name="default">
              <Checkbox>Chọn làm mặc định</Checkbox>
            </Form.Item> */}
            <div>Hình ảnh</div>
            <Upload
              fileList={[]}
              name="avatar"
              listType="picture-card"
              className="upload-category-image"
              showUploadList={false}
              onChange={(info) => {
                const isJpgOrPng =
                  info.file.type === 'image/jpeg' ||
                  info.file.type === 'image/png' ||
                  info.file.type === 'image/jpg'

                const isLt2M = info.file.size / 1024 / 1024 < 2

                if (!isJpgOrPng) {
                  notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
                }

                if (!isLt2M) {
                  notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
                }

                if (isJpgOrPng && isLt2M) {
                  if (typingTimeoutRef.current) {
                    clearTimeout(typingTimeoutRef.current)
                  }
                  typingTimeoutRef.current = setTimeout(async () => {
                    if (info.file.status !== 'done') info.file.status = 'done'
                    getBase64(info.file.originFileObj, (imageUrl) => setImageView(imageUrl))
                    setFileUpload(info.file.originFileObj)
                  }, 450)
                }
              }}
            >
              {imageView ? (
                <Badge
                  count={
                    <CloseCircleFilled
                      onClick={(e) => {
                        e.stopPropagation()
                        setImageView('')
                      }}
                      style={{ fontSize: 18, color: '#ff4d4f', cursor: 'pointer' }}
                    />
                  }
                >
                  <img
                    src={imageView}
                    alt=""
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Badge>
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </div>
          <div style={{ width: '40%' }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên nhóm sản phẩm',
                  // pattern: regexCategoryName,
                },
              ]}
              name="name"
              label="Tên nhóm sản phẩm"
            >
              <Input placeholder="Nhập tên nhóm sản phẩm" style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </Row>
      </Form>

      <div style={{ display: !location.state && 'none' }}>
        <Row>
          <h3>Danh sách sản phẩm</h3>
          <Col style={{ marginLeft: 20 }} span={16}>
            <Select
              style={{ width: '100%', marginBottom: 10 }}
              notFoundContent={loadingProduct ? <Spin size="small" /> : null}
              dropdownClassName="dropdown-select-search-product"
              allowClear
              showSearch
              clearIcon={<CloseOutlined style={{ color: 'black' }} />}
              suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
              placeholder={'Tìm kiếm theo tên sản phẩm '}
              value={null}
              filterOption={(input, option) =>
                removeUnicode(option.key.toString())
                  .toLowerCase()
                  .trim()
                  .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
              }
              onChange={(value, option) => {
                if (value !== null && value !== undefined && value !== '') {
                  let index = value.lastIndexOf('/')
                  let resultIndex = value.substring(index + 1)
                  if (productsData[resultIndex].inventory === 'normal') {
                    if (productsData[resultIndex].is_open === false) {
                      return notification.error({
                        message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                      })
                    } else {
                      addProduct(productsData[resultIndex])
                    }
                  } else {
                    addProduct(productsData[resultIndex])
                  }

                  if (productsData.length === 0) {
                    return notification.error({
                      message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                    })
                  }
                }
              }}
            >
              {productsData && productsData.map(
                  (data, index) =>
                    data && (
                      <Select.Option key={data.title + data.sku + data.barcode + '/' + index + ''}>
                      <Row
                        align="middle"
                        wrap={false}
                        style={{
                          padding: '7px 13px',
                          backgroundColor: data.is_choose == true ? 'rgb(231, 233, 251)' : 'unset',
                        }}
                        onClick={(e) => {
                          addProduct(data)
                          e.stopPropagation()
                        }}
                      >
                        <img
                          src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                          alt=""
                          style={{
                            minWidth: 40,
                            minHeight: 40,
                            maxWidth: 40,
                            maxHeight: 40,
                            objectFit: 'cover',
                          }}
                        />

                        <div style={{ width: '100%', marginLeft: 15 }}>
                          <Row wrap={false} justify="space-between">
                            <span
                              style={{
                                maxWidth: 450,
                                marginBottom: 0,
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                              }}
                            >
                              {data.title}
                            </span>
                            <p style={{ marginBottom: 0, fontWeight: 500 }}>
                              {formatCash(data.price)}
                            </p>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <p style={{ marginBottom: 0, color: 'gray' }}>{data.sku}</p>
                          </Row>
                        </div>
                      </Row>
                    </Select.Option>
                    )
                )}
            </Select>
          </Col>
          <Table
            loading={loading}
            style={{ width: '100%' }}
            rowKey="product_id"
            expandable={{
              expandedRowRender: (record, index) => {
                return (
                  <div key={index} style={{ marginTop: 25, marginBottom: 25 }}>
                    <Table
                      style={{ width: '100%' }}
                      pagination={false}
                      columns={columnsVariant}
                      dataSource={record.variants}
                      size="small"
                    />
                  </div>
                )
              },
            }}
            columns={columnsProduct.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              if (column.key === 'name-product')
                return {
                  ...column,
                  render: (text, record) => text,
                  sorter: (a, b) => compare(a, b, 'name'),
                }

              if (column.key === 'sku')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'sku'),
                }

              if (column.key === 'category')
                return {
                  ...column,
                  sorter: (a, b) =>
                    compareCustom(
                      a._categories && a._categories.length ? a._categories[0].name : '',
                      b._categories && b._categories.length ? b._categories[0].name : ''
                    ),
                  render: (text, record) =>
                    record._categories &&
                    record._categories.map((category, index) => (
                      <Tag key={index} closable={false}>
                        {category.name}
                      </Tag>
                    )),
                }

              if (column.key === 'supplier')
                return {
                  ...column,
                  sorter: (a, b) =>
                    compareCustom(
                      a.supplier_info ? a.supplier_info.name : '',
                      b.supplier_info ? b.supplier_info.name : ''
                    ),
                  render: (text, record) => record.supplier_info && record.supplier_info.name,
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),

                  render: (text, record) =>
                    record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
                }
              if (column.key === 'action')
                return {
                  ...column,

                  render: (text, record) => (
                    <Button
                      disabled={
                        permissions.includes(PERMISSIONS.cap_nhat_nhom_san_pham) ? false : true
                      }
                      icon={<DeleteOutlined />}
                      type="primary"
                      danger
                      onClick={() => _removeProductToCategory(record.product_id)}
                    />
                  ),
                }

              return column
            })}
            dataSource={products}
            size="small"
            scroll={{ y: 500 }}
            pagination={{
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) =>
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
            }}
          />
        </Row>
      </div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Button
          size="large"
          type="primary"
          onClick={_addOrUpdateCategory}
          style={{ display: title === 'product-form' ? 'block' : 'none', width: '30%' }}
        >
          Tạo nhóm sản phẩm
        </Button>
      </div>
    </div>
  )
}
