import React, { useState, useEffect, useCallback } from 'react'
import styles from './layout.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION, ROUTES,} from 'consts'
import { Link, useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import { Bell } from 'utils/icon'
import DefaultLogo from 'assets/img/default_logo_ekata.png'
import logo_default from 'assets/img/store-default.png'
import localStorage from '../../utils/localStorage'
import { removeUnicode } from 'utils'
import { cloneDeep } from 'lodash'
import { changeBranchRoleUser } from '../../redux/actions/changeBranch'
import {
  Layout,
  Menu,
  Select,
  Dropdown,
  BackTop,
  Affix,
  Avatar,
  Badge,
  Empty,
  Row,
  Input,
  Space,
  message,
  Col,
} from 'antd'

import {
  LogoutOutlined,
  ExportOutlined,
  ShopOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'

//components
import Permission from 'components/permission'
import MenuBar from 'assets/icons/menuBar.png'
import ModalUser from './modal-user'
import MenuPemission from './MenuPemission'

//apis
import { getAllBranch } from 'apis/branch'

const { Sider } = Layout
const BaseLayout = (props) => {
  const location = useLocation()
  const routeMatch = useRouteMatch()

  const sMenuData = useSelector((state) => state.menu)
  const dispatch = useDispatch()
  const WIDTH_MENU_OPEN = 230
  const WIDTH_MENU_CLOSE = 60


  const [filteredResults, setFilteredResults] = useState([])

  const branchIdApp = useSelector((state) => state.branch.branchId)
  const branches = useSelector((state) => state.branch.branches)
  const triggerReloadBranch = useSelector((state) => state.branch.trigger)
  const user = useSelector((state) => state.login.dataUser)
  const isCollapsed = localStorage.getItem('collapsed')
    ? JSON.parse(localStorage.getItem('collapsed'))
    : false
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const [isMobile, setIsMobile] = useState(false)
  const [openKeys, setOpenKeys] = useState([])
  const [branchesDropDown, setBranchesDropDown] = useState([])
  const [listMenu, setListMenu] = useState([])

  const listMenuIntoAdmin = () => {
    try {
      let menuTmp = cloneDeep(sMenuData)
      let MenuPemissionTmp = cloneDeep(MenuPemission)
      menuTmp = menuTmp.filter((item) => item.status == 4)
      for (let index in menuTmp) {
        const menuData = MenuPemissionTmp.find((item) => item.path == menuTmp[index].url)

        menuTmp[index].path = menuTmp[index].url
        menuTmp[index].title = menuTmp[index].name
        menuTmp[index].subMenu = menuTmp[index].subMenu ?? []
        menuTmp[index].permissions = menuData.permissions ?? []
        menuTmp[index].pathsChild = menuData.pathsChild ?? []

        let menuItems = menuData.menuItems ?? []
        if (menuItems.length > 0) {
          let subMenu = menuTmp[index].subMenu.filter((item) => item.status == 4)
          for (let index2 in subMenu) {
            const menuData2 = menuItems.find((item) => item.path == subMenu[index2].url)
            subMenu[index2].path = subMenu[index2].url
            subMenu[index2].title = subMenu[index2].name
            subMenu[index2].permissions = menuData2.permissions ?? []
            subMenu[index2].pathsChild = menuData2.pathsChild ?? []
          }
          menuTmp[index].menuItems = subMenu
        }
      }
      setListMenu(menuTmp)
      setFilteredResults(menuTmp)
    } catch (error) {
      console.log(error)
    }
  }

  const rootSubmenuKeys = [
    // 'store',
    // 'warehouse',
    // 'offer',
    // 'report',
    // 'transport',
    // 'commerce',
    // 'product',
  ]
  const onOpenChange = (keys) => {
    if (collapsed) {
      delete rootSubmenuKeys[6]
    }
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
      setOpenKeys(keys)
    } else {
      localStorage.setItem('openKey', latestOpenKey)
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  const _getBranches = async () => {
    try {
      const res = await getAllBranch()
      if (res.status === 200) {
        dispatch({
          type: 'SET_BRANCHES',
          data: res.data.data,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  var toggle = () => {
    localStorage.setItem('collapsed', JSON.stringify(!collapsed))
    setCollapsed(!collapsed)
  }

  const searchItems = (searchValue) => {
    if (searchValue !== '') {
      const filteredData = cloneDeep(listMenu).reduce((carry, item) => {
        let flag = removeUnicode(item.title).toLowerCase().includes(searchValue.toLowerCase())
        if (item.subMenu) {
          item.subMenu = item.subMenu.filter((item) =>
            removeUnicode(item.title).toLowerCase().includes(searchValue.toLowerCase())
          )
          if (item.menuItems) {
            item.menuItems = item.menuItems.filter((item) =>
              removeUnicode(item.name).toLowerCase().includes(searchValue.toLowerCase())
            )
          }
        }
        if (flag || (item.subMenu && item.subMenu.length)) {
          carry.push(item)
        }
        return carry
      }, [])
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(listMenu)
    }
  }

  useEffect(() => {
    listMenuIntoAdmin()
  }, [sMenuData])
  
  const renderMenuItem = (_menu) => (
    <Permission permissions={_menu.permissions} key={_menu.path}>
      {_menu.menuItems ? (
        <Menu.SubMenu
          key={_menu.path}
          onClick={_menu.path === ROUTES.SELL ? toggle : () => {}}
          style={{
            backgroundColor:
              (location.pathname === _menu.path || _menu.pathsChild.includes(location.pathname)) &&
              '#e7e9fb',
            width: '100%',
            display: 'block',
          }}
          icon={
            <Link
              style={{
                fontSize: '0.8rem',
                color:
                  location.pathname === _menu.path || _menu.pathsChild.includes(location.pathname)
                    ? '#5F73E2'
                    : 'rgba(0, 0, 0, 0.85)',
              }}
              to={_menu.path}
            >
              {_menu.icon}
            </Link>
          }
          title={
            <Link
              style={{
                fontSize: '0.8rem',
                color:
                  location.pathname === _menu.path || _menu.pathsChild.includes(location.pathname)
                    ? '#5F73E2'
                    : 'rgba(0, 0, 0, 0.85)',
              }}
              to={_menu.path}
            >
              {_menu.title}
            </Link>
          }
        >
          {_menu.menuItems.map((e) => (
            <Permission permissions={e.permissions} key={e.path}>
              <Menu.Item
                key={e.path}
                style={{
                  fontSize: '0.8rem',
                  backgroundColor:
                    (location.pathname === e.path || e.pathsChild.includes(location.pathname)) &&
                    '#e7e9fb',
                }}
              >
                <Link to={e.path}>{e.title}</Link>
              </Menu.Item>
            </Permission>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item
          key={_menu.path}
          style={{
            fontSize: '0.8rem',
          }}
          icon={_menu.icon}
          onClick={_menu.path === ROUTES.SELL ? toggle : () => {}}
        >
          <Link
            to={_menu.path}
            onClick={(evt) => {
              if (_menu.path !== ROUTES.SELL) return
              const isBranchOpen = branches.some((branch) => branch.active)
              if (isBranchOpen) return
              message.error('Tất cả chi nhánh đã ngừng bán !')
              evt.preventDefault()
            }}
          >
            {_menu.title}
          </Link>
        </Menu.Item>
      )}
    </Permission>
  )

  const onSignOut = () => {
    dispatch({ type: 'UPDATE_INVOICE', data: [] })
    dispatch({ type: ACTION.LOGOUT })
  }

  const content = (
    <div className={styles['user_information']}>
      <div>
        <ModalUser user={user}>
          <div style={{ color: '#565656', paddingLeft: 10 }}>
            <UserOutlined
              style={{ fontSize: '1rem', marginRight: 10, color: '#565656', marginBottom: 10 }}
            />
            Thông tin cá nhân
          </div>
        </ModalUser>

        <Link to="/business" style={{ color: '#565656', paddingLeft: 10 }}>
          <div>
            <ShopOutlined
              style={{ fontSize: '1rem', marginRight: 10, color: '#565656', marginBottom: 10 }}
            />
            Chi tiết cửa hàng
          </div>
        </Link>
        <a onClick={onSignOut} style={{ color: '#565656', paddingLeft: 10 }}>
          <div>
            <ExportOutlined style={{ fontSize: '1rem', marginRight: 10, color: '#565656' }} />
            Đăng xuất
          </div>
        </a>
      </div>
    </div>
  )

  const NotifyContent = () => (
    <div className={styles['notificationBox']}>
      <div className={styles['title']}>Thông báo</div>
      <div className={styles['content']}>
        <Empty />
      </div>
    </div>
  )

  const saveScrollTop = () => {
    setTimeout(() => {
      const scrollTopMenu = document.querySelector('#scrollTopMenu')
      const scrollTop = localStorage.getItem('scrollTop')
      if (scrollTopMenu && scrollTop) {
        scrollTopMenu.scrollTop = scrollTop
        localStorage.removeItem('scrollTop')
      }
    }, 100)
  }

  const loadBranches = useCallback(async () => {
    if (user.role_id === 1) return setBranchesDropDown(branches)

    const userBranchIdList = Array.isArray(user.branch_id) ? [...user.branch_id] : [user.branch_id]

    const list = userBranchIdList.map((branchIdUser) => {
      return branches.find((branch) => branchIdUser === branch.branch_id)
    })

    const listFiltered = list.filter((item) => item !== undefined)

    setBranchesDropDown(listFiltered)
  }, [branches, user.branch_id, user.role_id])


  useEffect(() => {
    if (localStorage.getItem('openKey')) setOpenKeys([localStorage.getItem('openKey')])
  }, [])

  useEffect(() => {
    _getBranches()
  }, [triggerReloadBranch])

  //get width device
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
      setCollapsed(true)
    } else setIsMobile(false)
  }, [])

  useEffect(() => {
    saveScrollTop()
  }, [])

  useEffect(() => {
    loadBranches()
  }, [loadBranches])

  return (
    <Layout style={{ backgroundColor: 'white', height: '100%' }}>
      <BackTop style={{ right: 10, bottom: 15 }} />
      <div className="output" />
      <Sider
        trigger={null}
        collapsible
        width={isMobile ? '100%' : WIDTH_MENU_OPEN}
        collapsedWidth={isMobile ? 0 : WIDTH_MENU_CLOSE}
        style={{
          backgroundColor: 'white',
          zIndex: isMobile && 6000,
          height: '100vh',
          position: 'fixed',
        }}
        collapsed={collapsed}
      >
        <Row
          justify="center"
          style={{
            display: collapsed ? 'none' : 'flex',
          }}
        >
          <img
            src={
              user && user?._business?.business_logo ? user?._business?.business_logo : DefaultLogo
            }
            style={{ objectFit: 'cover', height: '15vh', width: '60%', borderRadius: '50%' }}
            alt=""
          />
          {user.data != undefined && (
            <div style={{ fontWeight: 'bold', fontSize: 17, color: 'black', paddingTop: 10 }}>
              {user.data._role.name}
            </div>
          )}
        </Row>
        <Row
          style={{
            display: collapsed ? 'none' : 'flex',
          }}
          justify="center"
        >
          <h2 style={{ color: 'darkblue', fontFamily: 'ui-serif' }}>
            {user && user?._business?.business_name}
          </h2>
        </Row>
        <Row>
          {!collapsed ? (
            <div style={{ margin: '10px' }}>
              <Input.Search
                allowClear
                placeholder="Tìm kiếm chức năng"
                onChange={(e) => searchItems(removeUnicode(e.target.value))}
                style={{ width: '100%', padding: '5px' }}
              />
            </div>
          ) : (
            <></>
          )}
        </Row>
        <Menu
          id="scrollTopMenu"
          style={{
            height: `calc(80vh - ${collapsed ? 4 : 96}px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          theme="light"
          onClick={(e) => {
            const scrollTopMenu = document.querySelector('#scrollTopMenu')
            if (scrollTopMenu) localStorage.setItem('scrollTop', scrollTopMenu.scrollTop)

            if (e.keyPath && e.keyPath.length === 1) localStorage.removeItem('openKey')
          }}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          selectedKeys={routeMatch.path}
          mode="inline"
        >
          {filteredResults.map(renderMenuItem)}

          <Menu.Item key={ROUTES.LOGIN} onClick={onSignOut} icon={<LogoutOutlined />}>
            <Link to={ROUTES.LOGIN}>Đăng xuất</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: collapsed ? WIDTH_MENU_CLOSE : WIDTH_MENU_OPEN }}>
        <Affix offsetTop={0}>
          <Row
            wrap={isMobile}
            justify="space-between"
            align="middle"
            style={{ backgroundColor: '#5b6be8' }}
          >
            <Col>
              <Row
                align="middle"
                wrap={false}
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
                justify={isMobile && 'space-between'}
              >
                <img
                  style={{ width: '30px', cursor: 'pointer', marginRight:18 }}
                  src={MenuBar}
                  onClick={toggle}
                  alt='menu'
                />
                <Row align="middle">
                  <div style={{ color: 'white', marginRight: 8 }}>Chi nhánh:</div>
                  <Select
                    style={{ width: isMobile ? '90%' : 250 }}
                    placeholder="Chi nhánh"
                    key={branchIdApp}
                    defaultValue={branchIdApp}
                    autoFocus={true}
                    onChange={(value, { isActive }) => {
                      if (!isActive) message.error('Chi nhánh đã ngừng bán!')
                      dispatch(changeBranchRoleUser({ data: value, user }))
                      dispatch({ type: 'SET_BRANCH_ID', data: value })
                      localStorage.setItem('branch_id', value)
                    }}
                  >
                    {branchesDropDown.map(({ branch_id, name, active }) => (
                      <Select.Option key={branch_id} isActive={active} value={branch_id}>
                        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                          {name}{' '}
                          {active ? (
                            <CheckCircleOutlined style={{ color: 'rgb(91, 107, 232)' }} />
                          ) : (
                            <MinusCircleOutlined style={{ color: '#ADB5BD' }} />
                          )}
                        </Space>
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </Row>
            </Col>
            <Col>
              <Row wrap={false} align="middle" style={{ marginRight: 10 }}>
                <div style={{ marginTop: 8, marginRight: 15 }}>
                  <Dropdown overlay={<NotifyContent />} placement="bottom" trigger="click">
                    <Badge count={0} showZero size="small" offset={[-3, 3]}>
                      <Bell style={{ color: 'rgb(253, 170, 62)', cursor: 'pointer' }} />
                    </Badge>
                  </Dropdown>
                </div>
                <Dropdown overlay={content} trigger="click">
                  <Row align="middle" wrap={false} style={{ cursor: 'pointer' }}>
                    <Avatar
                      style={{ width: '32px', height: '32px' }}
                      src={user?.avatar !== '' ? user?.avatar : logo_default}
                    />
                    <span
                      style={{
                        textTransform: 'capitalize',
                        marginLeft: 5,
                        color: 'white',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {user && user?.first_name + ' ' + user?.last_name}
                    </span>
                  </Row>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Affix>
        <div style={{ backgroundColor: '#f0f2f5', width: '100%' }}>{props.children}</div>
      </Layout>
    </Layout>
  )
}

export default React.memo(BaseLayout)
