import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION } from 'consts/index'

import styles from './dropdown.module.scss'
import { Modal, Button, Table, Input, InputNumber, Form, notification, Select } from 'antd'
import moment from 'moment'
import { formatCash } from 'utils'
import PrintLockShift from 'components/print/print-lockShift'
import { useReactToPrint } from 'react-to-print'
import { socket } from '../../../../socket'

//apis
import { addLockShift, getFinance, getOrderPayment } from 'apis/lockShift'
import { getStaff } from 'apis/manage-staff'
import { getAllBranch } from 'apis/branch'

export default function LockShiftForm({ toggleLockShift, visibleLockShift }) {
  const { Option } = Select

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const printLockShiftRef = useRef()

  const [branch, setBranch] = useState([])
  const [payments, setPayments] = useState([])
  const [finances, setFinances] = useState([])
  const [users, setUsers] = useState([])
  const [turnoverOrder, setTurnoverOrder] = useState(0)
  const [systemCash, setSystemCash] = useState(0)
  const [sumReceiptCash, setSumReceiptCash] = useState(0)
  const [sumPaymentCash, setSumPaymentCash] = useState(0)
  const [sumReven, setSumReven] = useState(0)

  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [visiblePrintLockShift, setVisiblePrintLockShift] = useState(false)

  const togglePrintLockShift = () => setVisiblePrintLockShift(!visiblePrintLockShift)
  const _getBranch = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getAllBranch({ branch_id: branchIdApp })
      if (res.status === 200) setBranch(res.data.data)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }
  const _getUsers = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) {
        setUsers(res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }

  const columns = [
    { title: 'Phương thức thanh toán', key: '_id', dataIndex: 'method' },
    {
      title: 'Giá trị',
      key: '_id',
      dataIndex: 'value',
      render: (value, index) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
  ]
  const SumValue = (id, payments) => {
    let sum = 0
    payments.map((item) => {
      if (item._id === id) {
        sum += item.value
      }
    })
    return sum
  }

  const columnReven = [
    {
      title: 'Loại thu chi',
      key: '_id',
      // dataIndex: `_id`,
      render: (record, index) => (
        <>
          <p style={{ fontSize: 15, marginBottom: 0 }}>{record._id}</p>
          {record?.payments.map((e) => (
            <span style={{ fontSize: 11 }}>{e + ' '}</span>
          ))}
        </>
      ),
    },
    {
      title: 'Giá trị',
      key: '_id',
      dataIndex: 'value',
      render: (value, index) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
  ]

  const [employee, setEmployee] = useState([])
  const [cash, setCash] = useState(0)
  const [note, setNote] = useState('')

  const onFinish = () => {
    togglePrintLockShift()
    handlePrint()
  }

  const statusLockShift = (cash,systemCash,sumReceiptCash,sumPaymentCash) => {
    // trạng thái tiền mặt : hệ thống + thu - chi - tiền thực tế
    let statusCash = systemCash + sumReceiptCash - sumPaymentCash - cash
    if (statusCash > 0) return 'Thiếu: ' + statusCash
    if (statusCash < 0) return 'Thừa: ' + Math.abs(statusCash)
    if ((statusCash = 0)) return statusCash
  }

  const _addOLockShift = async (value) => {
    try {
      let sumCash = 0
      let sumRevenNew = 0
      let sumPayTmp
      let sumReceiptTmp
      const resPayment = await getOrderPayment({
        today: moment().format('DD/MM/YYYY'),
        branch_id: branchIdApp,
      })
      if (resPayment.status === 200) {
        setPayments(resPayment.data.data)
        setTurnoverOrder(resPayment.data.sum || 0)
        resPayment.data.data.map((item) => {
          if (item._id === 1) {
            sumCash += item.value
          }
        })
        setSystemCash(sumCash)
      }
      const resFinances = await getFinance({
        today: moment().format('DD/MM/YYYY'),
        branch_id: branchIdApp,
      })
      if (resFinances.status === 200) {
        let new_finances = resFinances.data.data.filter(
          (e) => e.source === 'PAYMENT' || e.source === 'RECEIVED'
        )
        setFinances(new_finances)
        //lấy tổng tiền chi
        sumPayTmp = new_finances
          .filter((item) => item.source === 'PAYMENT')
          .reduce(
            function ({ sum, sumCash }, e) {
              sum += e.value
              sumCash += e.sumCash
              return { sum, sumCash }
            },
            { sum: 0, sumCash: 0 }
          )
        // lấy tổng tiền thu
        sumReceiptTmp = new_finances
          .filter((item) => item.source === 'RECEIVED')
          .reduce(
            function ({ sum, sumCash }, e) {
              sum += e.value
              sumCash += e.sumCash
              return { sum, sumCash }
            },
            { sum: 0, sumCash: 0 }
          )

        // sumReceipt = sumReceiptTmp.sum // tổng tiền phiếu thu
        setSumReceiptCash(sumReceiptTmp.sumCash) // tổng thu theo tiền mặt
        // sumPayment = sumPayTmp.sum // tổng tiền phiếu chi
        setSumPaymentCash(sumPayTmp.sumCash) // tổng chi theo tiền mặt

        sumRevenNew = sumReceiptTmp.sum -  sumPayTmp.sum // tổng thu chi = thu - chi
        setSumReven(sumRevenNew)
      }
      
      setEmployee(value.employee)
      setCash(value.cash)
      setNote(value.note)
      dispatch({ type: ACTION.LOADING, data: true })

      const body = {
        employee_name: value.employee,
        business_name: dataUser._business.business_name,
        branch_id: branchIdApp,
        turnover: resPayment.data.sum || 0,
        sumReven: sumRevenNew,
        systemCash: sumCash + sumReceiptTmp.sumCash - sumPayTmp.sumCash,
        realCash: value.cash,
        status: formatCash(statusLockShift(value.cash,sumCash,sumReceiptTmp.sumCash,sumPayTmp.sumCash)),
        note: value.note,
      }
      let res = await addLockShift(body)
      if (res.status === 200) {
        if (res.data.success) {
          setTimeout(() => {
            togglePrintLockShift()
            toggleLockShift()
          },500)
        } else
          notification.error({
            message: res.data.message || 'Chốt ca thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Chốt ca thất bại, vui lòng thử lại!',
        })
      form.resetFields()
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const dataPrint = {
    employee_name: employee.map((employee) => {
      const empInfo = users.find((item) => item.user_id == employee)
      return empInfo && empInfo.first_name + ' ' + empInfo.last_name
    }),
    business_name: dataUser._business.business_name,
    business_address: dataUser._business.business_address,
    business_phone: dataUser._business.business_phone,
    branch: branch[0] ? branch[0] : '',
    systemCash: systemCash,
    realCash: cash,
    status: formatCash(statusLockShift(cash,systemCash,sumReceiptCash,sumPaymentCash)),
    note: note,
    dataPayments: payments,
    user: dataUser.first_name + ' ' + dataUser.last_name,
    dataReven: finances,
    sumReceipt: sumReceiptCash,
    sumPayment: sumPaymentCash,
    turnoverOrder: turnoverOrder
  }

  const handlePrint = useReactToPrint({
    content: () => printLockShiftRef.current,
    copyStyles: false,
  })
  const Print = () => (
    <div style={{ display: 'none' }}>
      <PrintLockShift ref={printLockShiftRef} data={dataPrint} />
    </div>
  )

  useEffect(() => {
    _getUsers()
    _getBranch()
  }, [branchIdApp])
  return (
    <>
      <Print />
      <Modal
        width={600}
        footer={null}
        title="Chốt ca"
        visible={visibleLockShift}
        onCancel={toggleLockShift}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 10,
          }}
          onFinish={_addOLockShift}
          autoComplete="off"
        >
          <Form.Item
            label="Tên nhân viên"
            name="employee"
            rules={[
              {
                required: true,
                message: 'Chọn nhân viên!',
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              style={{ width: '100%' }}
              placeholder="Chọn nhân viên"
              optionFilterProp="children"
            >
              {users.length &&
                users
                  .filter(
                    (item) => (item.status === 1 && item.is_delete === false) || item.role_id === 1
                  )
                  .map((user, index) => (
                    <Option key={index} value={user.user_id}>
                      {user.first_name + ' ' + user.last_name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Tiền mặt thực tế"
            name="cash"
            rules={[
              {
                required: true,
                message: 'Nhập tiền mặt thực tế!',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
          <Form.Item label="Ghi chú" name="note">
            <Input.TextArea size="large" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Chốt ca
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width={400}
        title="Xem đơn chốt ca"
        visible={visiblePrintLockShift}
        onCancel={togglePrintLockShift}
        onOk={onFinish}
        style={{ alignItems: 'center' }}
        okText="In đơn chốt ca"
        cancelText="Hủy bỏ"
      >
        <div ref={printLockShiftRef}>
          <div style={{ textAlign: 'center' }}>
            <h1>{dataUser._business.business_name}</h1>
            {branch[0] ? (
              <>
                <h3>{`Chi nhánh: ${branch[0].name}`}</h3>
                <div className={styles['title']}>
                  <p>Địa chỉ: </p>
                  <p style={{ marginLeft: 8 }}>{branch[0].address}</p>
                </div>
              </>
            ) : (
              <div className={styles['title']}>
                <p>Địa chỉ: </p>
                <p style={{ marginLeft: 8 }}>{dataUser._business.business_address}</p>
              </div>
            )}
            <div className={styles['title']}>
              <p>Điện thoại: </p>
              <p style={{ marginLeft: 8 }}>{dataUser._business.business_phone}</p>
            </div>
            <div className={styles['title']}>
              <p>Thời điểm: </p>
              <p style={{ marginLeft: 8 }}>{moment().format('DD-MM-YYYY HH:mm')}</p>
            </div>
            <div className={styles['title']}>
              <p>Nhân viên: </p>
              {employee.map((item) => {
                const userName = users.find((user) => user.user_id === item)
                return (
                  userName && (
                    <p style={{ marginLeft: 8 }}>
                      {userName.first_name + ' ' + userName.last_name}
                    </p>
                  )
                )
              })}
            </div>
          </div>
          <h2 style={{ textAlign: 'center', marginBottom: 0 }}>Đơn chốt ca</h2>
          <Table pagination={false} dataSource={payments} columns={columns} className="chot-ca" />
          <h2 style={{ textAlign: 'center', marginBottom: 0 }}>Thu chi</h2>
          <Table
            pagination={false}
            columns={columnReven}
            dataSource={finances}
            style={{ marginBottom: 16, marginTop: 23 }}
            className="chot-ca"
          />
          <div style={{ textAlign: 'center' }}>
            <div className={styles['title']}>
              <p>Tiền mặt đúng: </p>
              <p style={{ marginLeft: 8 }}>
                {formatCash(systemCash + sumReceiptCash - sumPaymentCash)}
              </p>
            </div>
            <div className={styles['title']}>
              <p>Tiền mặt thực tế: </p>
              <p style={{ marginLeft: 8 }}>{formatCash(cash)}</p>
            </div>
            <div className={styles['title']}>
              <p>Tổng doanh thu: </p>
              <p style={{ marginLeft: 8 }}>{formatCash(turnoverOrder)}</p>
            </div>
            <div className={styles['title']}>
              <p>Tình trạng chốt ca: </p>
              <p style={{ marginLeft: 8 }}>{formatCash(statusLockShift(cash,systemCash,sumReceiptCash,sumPaymentCash))}</p>
            </div>
            <div className={styles['title']}>
              <p>Nhân viên chốt ca: </p>
              <p style={{ marginLeft: 8 }}>{dataUser.first_name + ' ' + dataUser.last_name}</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
