import styles from '../import-product.module.css'
import localStorage from '../../../utils/localStorage'

//antd
import { Col, message, notification, Row, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import React, { useState } from 'react'

const { Dragger } = Upload

export default function Step1({ submit }) {
  let accessToken = localStorage.getItem('accessToken')

  const [checkFile, setCheckFile] = useState(false)
  if (!accessToken) {
    accessToken = Cookies.get('accessToken')
  }
  const props = {
    beforeUpload: (file) => {
      let allowedExtensions = /(\.xlsx|\.xls)$/i
      if (!allowedExtensions.exec(file.name)) {
        setCheckFile(false)
        return notification.error({ message: `${file.name} không phải là file excel` })
      } else {
        setCheckFile(true)
      }
    },

    name: 'file',
    action:
      checkFile === true &&
      process.env.REACT_APP_API_ENDPOINT_DEV + '/import-product/product/upload-excel',
    headers: {
      'X-Ekata-Site': window.location.hostname,
      'X-Ekata-Token': accessToken,
    },

    async onChange(info) {
      if (checkFile === true) {
        const { status } = info.file
        if (status === 'done') {
          await submit({ step: 1, path: info.file.response.path })
        } else if (status === 'error') {
          message.error(`${info.file.name} tải lên bị lỗi`)
        }
      }
    },
  }

  return (
    <Row gutter={[32, 8]}>
      <Col span={8}>
        <div style={{ margin: '16px' }}>
          <a href="https://s3.ap-southeast-1.wasabisys.com/ekata-production/public/2022/12/21/1671615338626-810698253-mau-nhap-san-pham (1).xlsx">
            Tải về file import sản phẩm mẫu
          </a>
          <br />
          <br />
          <span>
            <strong>Chú ý:</strong>
            <br />- Chỉ nhập sản phẩm đối với sản phẩm nâng cao - sản phẩm được quản lý tồn kho theo
            số lượng
            <br />- Mỗi sản phẩm chỉ có tối đa 3 thuộc tính.
          </span>
        </div>
      </Col>
      <Col span={16}>
        <div className={styles['step-1']}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Nội dung hint</p>
          </Dragger>
        </div>
      </Col>
    </Row>
  )
}
