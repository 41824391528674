import { get, patch, post, destroy } from './httpClient'

// loai phieu chi
export const getFinanceTypePayment = (query) => get('/finance-type/getFinanceTypePayment', query)
export const addFinanceTypePayment = (body) => post('/finance-type/createFinanceTypePayment', body)
export const deleteFinanceTypesPayment = (body) => destroy('/finance-type/deleteFinanceTypesPayment', body)
export const updateFinanceTypesPayment = (id, body) => patch(`/finance-type/updateFinanceTypesPayment/${id}`, body)

// loai phieu thu
export const getFinanceTypeReceipts = (query) => get('/finance-type/getFinanceTypeReceipts', query)
export const addFinanceTypeReceipts = (body) => post('/finance-type/createFinanceTypeReceipts', body)
export const deleteFinanceTypeReceipts = (body) => destroy('/finance-type/deleteFinanceTypeReceipts', body)
export const updateFinanceTypeReceipts = (id, body) => patch(`/finance-type/updateFinanceTypeReceipts/${id}`, body)