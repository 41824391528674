import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {  createShift } from 'apis/schedule-staff'

import styles from '../schedule-staff.module.scss'
// antd
import { Row, Col, notification, Spin, Form } from 'antd'
import moment from 'moment'
//components
import { FormItem, InputForm, TimePickerRangePicker } from 'components/Form'

import { Button } from 'components/Button/Button'
import ShiftUpdate from './updateShift'
import PlusCircleIcon from 'assets/icons/plus-circle.svg'
import ClockIcon from 'assets/icons/clock.svg'
import CloseIcon from 'assets/icons/close.svg'
import Modal from 'components/Modal'

export default function ShiftForm({ getShift, close, shifts}) {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [data, setData] = useState()
  const [roleArray, setRoleArray] = useState([])
  const [loadingShift, setLoadingShift] = useState(false)
  const [form] = Form.useForm()
  const [visibleShiftUpdate, setVisibleShiftUpdate] = useState(false)

  const toggleShiftUpdate = () => setVisibleShiftUpdate(!visibleShiftUpdate)
  useEffect(() => {
    document.getElementById('name').focus()
  },[])

  const _addShift = async () => {
    try {
      setLoadingShift(true)
      const dataForm = form.getFieldsValue()
      let time = ((moment(dataForm.time[1]) - moment(dataForm.time[0])) / 3600000).toFixed()
        const body = {
          title: dataForm.title,
          branch_id: branchIdApp,
          time_start: dataForm.time[0],
          time_end: dataForm.time[1],
          role: roleArray,
          work_time: time,
        }
        let res = await createShift(body)
      if (res.status === 200) {
        notification.success({ message: 'Thêm ca thành công' })
        setData(res.data.data)
        form.setFieldsValue({
          title: '',
          time: [],
        })
        toggleShiftUpdate()
        close()
      } else {
        notification.error({ message: res.data.message || 'Thêm ca thất bại' })
      }
      setLoadingShift(false)
    } catch (error) {
      setLoadingShift(false)
      console.log(error)
    }
  }
  function rangeMinutes(start, end) {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  function disabledDateTime() {
    return {
      disabledHours: () => [],
      disabledMinutes: () => rangeMinutes(0, 60),
      disabledSeconds: () => [0, 60],
    }
  }
  return (
    <Spin spinning={loadingShift} tip="Loading..." size="large">
      <Form onFinish={_addShift} form={form}>
        <Row className={styles.row}>
          <Col className={styles.column__left}>
            <FormItem
              label="Tên ca"
              name="title"
              rules={[{ required: true, message: 'Vui lòng nhập tên ca' }]}
              // normalize={(value) => value.trim()}
            >
              <InputForm id='name' placeholder="Ca sáng" classes={styles.input__ca} allowClear />
            </FormItem>
          </Col>
          <Col className={styles.column__right}>
            <FormItem
              label="Thời gian"
              name="time"
              rules={[{ required: true, message: 'Vui lòng nhập thời gian làm việc' }]}
            >
              <TimePickerRangePicker
                suffixIcon={<img src={ClockIcon} alt="" />}
                showTime={{ format: 'HH' }}
                format="HH:00"
                classes={styles.input__time}
                allowClear
                disabledTime={() => disabledDateTime()}
              />
            </FormItem>
          </Col>
        </Row>

        <Row style={{ width: '100%', marginTop: '50px' }}>
          <FormItem style={{ minWidth: '130px', display: 'inline-block', marginLeft: 'auto' }}>
            <Button
              type="submit"
              text=" Thêm ca"
              classes="btn__blue-fill"
              loading={loadingShift}
              icon={() => <img src={PlusCircleIcon} alt="" />}
              style={{
                display: 'inline-block',
                padding: '6px 12px',
                fontSize: '14px',
                fontWeight: 500,
              }}
            />
          </FormItem>
        </Row>
      </Form>
      <Modal
        onCancel={() => {
          getShift()
          toggleShiftUpdate()
        }}
        footer={null}
        title={`${data && data?.title}`}
        visible={visibleShiftUpdate}
        closeIcon={<img src={CloseIcon} alt="" />}
        width="40%"
      >
        <ShiftUpdate record={data} getShift={getShift} close={toggleShiftUpdate} shifts={shifts}/>
      </Modal>
    </Spin>
  )
}
