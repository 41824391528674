import React, { useState, useEffect } from 'react'
import { ACTION, regexPhone } from 'consts/index'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { PERMISSIONS, IMAGE_DEFAULT, FILTER_COL_HEIGHT, FILTER_SIZE } from 'consts'
import { Input, Button, Row, Col, notification, Select, Form, Upload, Drawer, Checkbox } from 'antd'

//icons
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'

//apis
import { getDistricts, getProvinces, getWards } from 'apis/address'

import { addBranch, updateBranch,updateDefault } from 'apis/branch'
import { updateUser } from 'apis/users'
import { uploadFile } from 'apis/upload'
import Permission from 'components/permission'
import SelectLocation from 'components/select-location'

const { Option } = Select
export default function BranchAdd({ reloadData, children, record }) {
  const dispatch = useDispatch()
  const [ward, setWard] = useState([])
  const [provinceName, setProvinceName] = useState('')
  const [districtName, setDistrictsName] = useState('')
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wardValue, setWardValue] = useState({})

  const location = useLocation()
  const [form] = Form.useForm()

  const user = useSelector((state) => state.login.dataUser)

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const [paramsLocation, setParamLocation] = useState({})

  const [selectedItems, setSelectedItems] = useState([])
  const [isCheckConnectGHN, setIsCheckConnectGHN] = useState(false)

  const [image, setImage] = useState('')

  const [isDefaultBranch, setIsDefaultBranch] = useState(false)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const _uploadImage = async (file) => {
    try {
      setLoading(true)
      const url = await uploadFile(file)
      setImage(url || '')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _getDistricts = async () => {
    try {
      const res = await getDistricts()
      if (res.status === 200) {
        setDistricts(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getProvinces = async () => {
    try {
      const res = await getProvinces()
      if (res.status === 200) {
        setProvinces(res.data.data)
        if (res.data.data && res.data.data.length && !record) {
          setProvinceName(res.data.data[0].province_name)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getWard = async () => {
    try {
      const res = await getWards()
      if (res.status === 200) setWard(res.data.data)
      if (res.data.data && res.data.data.length && !record) {
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getDistricts()
    _getProvinces()
    _getWard()
  }, [])
  const _addOrEditBranch = async () => {
    try {
      await form.validateFields()

      const dataForm = form.getFieldsValue()

      if (dataForm.phone && !regexPhone.test(dataForm.phone)) {
        notification.warning({ message: 'Số điện thoại liên hệ không hợp lệ' })
        return
      }

      dispatch({ type: ACTION.LOADING, data: true })

      const body = {
        ...dataForm,
        logo: image || '',
        address: dataForm.address || '',
        lat: '',
        lng: '',
        accumulate_point: false,
        use_point: false,
        province_id: dataForm.province,
        district_id: dataForm.district,
        ward_code: dataForm.ward,
        branch_id_action: branchIdApp,
        default: isDefaultBranch
      }
      const res = record ? await updateBranch(body, record.branch_id) : await addBranch(body)
      if (res.status === 200) {
        if (res.data.success) {
          if (isDefaultBranch) {
            await updateDefault(record ? record.branch_id : res.data.branch_id)
          }
          reloadData()
          notification.success({ message: `${record ? 'Cập nhật' : 'Thêm'} chi nhánh thành công` })
          setVisible(false)
          dispatch({ type: 'TRIGGER_RELOAD_BRANCH' })
        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? 'Cập nhật' : 'Thêm'} chi nhánh thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${record ? 'Cập nhật' : 'Thêm'} chi nhánh thất bại, vui lòng thử lại!`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  useEffect(() => {
    if (location.state && location.state === 'show-modal-create-branch') setVisible(true)
  }, [])
  useEffect(() => {
    if (visible) {
      if (!record) {
        form.resetFields()
        setImage('')
      } else {
        form.setFieldsValue({ ...record })
        setImage(record.logo || '')
        setIsDefaultBranch(record.default)
      }
    }
  }, [visible])

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Drawer
        footer={
          <Row justify="end">
            {!record && (
              <Permission permissions={[PERMISSIONS.them_chi_nhanh]}>
                <Button
                  loading={loading}
                  onClick={_addOrEditBranch}
                  size="large"
                  type="primary"
                  style={{ minWidth: 120 }}
                >
                  {'Thêm'}
                </Button>
              </Permission>
            )}

            {record && (
              <Permission permissions={[PERMISSIONS.cap_nhat_chi_nhanh]}>
                <Button
                  loading={loading}
                  onClick={_addOrEditBranch}
                  size="large"
                  type="primary"
                  style={{ minWidth: 120 }}
                >
                  {'Cập nhật'}
                </Button>
              </Permission>
            )}
          </Row>
        }
        title={record ? 'Cập nhật chi nhánh' : 'Thêm chi nhánh'}
        centered
        width="70%"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        Hình ảnh
        <Upload
          className="upload-shipping"
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          data={_uploadImage}
        >
          {image ? (
            <img src={image} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Tải lên</div>
            </div>
          )}
        </Upload>
        <Form form={form} layout="vertical">
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>Tên chi nhánh</div>}
                name="name"
                rules={[{ required: true, message: 'Vui lòng nhập tên chi nhánh' }]}
              >
                <Input size="large" placeholder="Nhập tên chi nhánh" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                rules={[{ message: 'Vui lòng chọn loại chi nhánh', required: true }]}
                name="type"
                label={<div style={{ color: 'black', fontWeight: '600' }}>Loại chi nhánh</div>}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Chọn loại chi nhánh"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Warehouse">Kho</Option>
                  <Option value="Store">Cửa hàng</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>Liên hệ</div>}
                name="phone"
                initialValue="0123456789"
                rules={[{ required: true, message: 'Vui lòng nhập liên hệ' }]}
              >
                <Input size="large" placeholder="Nhập liên hệ" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item label="Tỉnh/thành phố" name="province"
                rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
              
              >
                <Select
                  allowClear
                  size="large"
                  placeholder="Chọn tỉnh/thành phố"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={provinceName}
                  onChange={(value) => {
                    setProvinceName(value)
                    form.setFieldsValue({ district: undefined })
                  }}
                >
                  {provinces.map((e, index) => (
                    <Option value={e.province_id} key={index}>
                      {e.province_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" align="middle" style={{ marginTop: 15 }}>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item label="Quận/huyện" name="district" 
                rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
              
              >
                <Select
                  allowClear
                  size="large"
                  placeholder="Chọn quận/huyện"
                  showSearch
                  optionFilterProp="children"
                  value={districtName}
                  onChange={(value) => {
                    setDistrictsName(value)
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {districts
                    .filter((d) => !provinceName || d.province_id === provinceName)
                    .map((e, index) => (
                      <Option value={e.district_id} key={index}>
                        {e.district_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item label="Phường/xã" name="ward"
                rules={[{ required: true, message: 'Vui lòng chọn phường xã' }]}
              
              >
                <Select
                  allowClear
                  size="large"
                  placeholder="Chọn phường/xã"
                  showSearch
                  onChange={(e) => {
                    const value = ward.filter((value) => value.ward_name === e)
                    value.map((item) => setWardValue(item))
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ward
                    .filter((d) => !districtName || d.district_id === districtName)
                    .map((e, index) => (
                      <Option value={e.ward_code} key={index}>
                        {e.ward_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" align="middle" style={{ marginTop: 15 }}>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="address"
                label={<div style={{ color: 'black', fontWeight: '600' }}>Số nhà, tên đường</div>}
                rules={[{ required: true, message: 'Vui lòng nhập số nhà, tên đường' }]}
              >
                <Input placeholder="Số nhà, Tên đường" size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" align="middle">
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item
                label={<div style={{ color: 'black', fontWeight: '600' }}>Số nhà, tên đường</div>}
              >
                <Checkbox
                  checked={isCheckConnectGHN}
                  onChange={(e) => setIsCheckConnectGHN(e.target.checked)}
                >
                  {'Kết nối Giao hàng nhanh giúp bạn tự gọi vận chuyển (Tuỳ chọn)'}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Form.Item>
                <Checkbox
                  id="default_branch"
                  checked={isDefaultBranch}
                  onChange={() => setIsDefaultBranch((value) => !value)}
                >
                  Chi nhánh mặc định
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {isCheckConnectGHN && (
            <Row justify="space-between" align="middle">
              <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                <Form.Item
                  name="shop_ghn_id"
                  label={
                    <div style={{ color: 'black', fontWeight: '600' }}>Vui lòng điền SHOP ID</div>
                  }
                >
                  <Input placeholder="Vui lòng điền SHOP ID" size="large" />
                </Form.Item>
                <a>{'Hướng dẫn lấy Shop ID giao hàng nhanh'}</a>
              </Col>
            </Row>
          )}
        </Form>
      </Drawer>
    </>
  )
}
