import React, { useState, useEffect, useRef, useCallback } from 'react'
import styles from './select-location.module.scss'

//antd
import { Col, Row, Select } from 'antd'

//apis
import { getDistricts, getProvinces, getWards } from 'apis/address'

export default function SelectLocation({ setParamLocation, paramsLocation, style, placeholder }) {
  const [districts, setDistricts] = useState([])
  const [district, setDistrict] = useState({})

  const [provinces, setProvinces] = useState([])
  const [province, setProvince] = useState({})

  const [wards, setWards] = useState([])
  const [ward, setWard] = useState({})

  const [valueDisPlay, setValueDisplay] = useState(placeholder || 'Vui lòng chọn tỉnh/quận/xã')

  const toggleMouseLeave = () => {}
  const selectRef = useRef()

  const _getDistricts = async () => {
    try {
      const res = await getDistricts()
      if (res.status === 200) setDistricts(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getProvinces = async () => {
    try {
      const res = await getProvinces()
      if (res.status === 200) setProvinces(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getWards = async () => {
    try {
      const res = await getWards()
      if (res.status === 200) setWards(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getDistricts()
    _getProvinces()
    _getWards()
  }, [])

  const _onSubmit = (_province, _district, _ward) => {
    selectRef.current.blur()
    setValueDisplay(
      _province[0].province_name + ' | ' + _district[0].district_name + ' | ' + _ward.ward_name
    )
  }

  return (
    <div>
      <Select
        ref={selectRef}
        allowClear
        bordered={true}
        value={valueDisPlay}
        onChange={(e) => {
          if (!e) {
            setParamLocation({ ...paramsLocation, province_id: '', district_id: '', ward_code: '' })
          }
        }}
        style={style}
        placeholder={placeholder || 'Chọn tỉnh/quận/xã'}
        // size="large"
        dropdownRender={() => (
          <Row
            className={styles['dropdown-container']}
            // justify="space-between"
            onMouseLeave={toggleMouseLeave}
          >
            <Col className={styles['dropdown-container']} span={8}>
              {province &&
                provinces.map((_province, index) => (
                  <Row
                    key={index}
                    style={{
                      color: '#000',
                      padding: '2px 10px',
                      margin: '1px 3px',
                      cursor: 'pointer',
                      backgroundColor:
                        paramsLocation.province_id === _province.province_id ||
                        province.province_id === _province.province_id
                          ? '#5F73E2'
                          : '',
                      borderRadius: 10,
                    }}
                    value={_province.province_id}
                    onMouseEnter={() => {
                      setProvince(_province)
                    }}
                    onClick={() => {
                      setProvince(_province)
                      setParamLocation({
                        ...paramsLocation,
                        province_id: _province.province_id,
                        province: _province,
                      })
                    }}
                  >
                    {_province.province_name}
                  </Row>
                ))}
            </Col>
            <Col className={styles['dropdown-container']} span={8}>
              {province &&
                districts.map((_district, index) =>
                  province.province_id === _district.province_id ? (
                    <Row
                      key={index}
                      style={{
                        color: '#000',
                        padding: '2px 10px',
                        margin: '1px 3px',
                        cursor: 'pointer',
                        backgroundColor:
                          paramsLocation.district_id === _district.district_id ||
                          district.district_id === _district.district_id
                            ? '#5F73E2'
                            : '',
                        borderRadius: 10,
                      }}
                      value={_district.district_id}
                      onMouseEnter={() => {
                        setDistrict(_district)
                      }}
                      onClick={() => {
                        setDistrict(_district)
                        setParamLocation({
                          ...paramsLocation,
                          province_id: province.province_id,
                          district_id: _district.district_id,
                          district: _district,
                        })
                      }}
                    >
                      {_district.district_name}
                    </Row>
                  ) : (
                    ''
                  )
                )}
            </Col>

            <Col className={styles['dropdown-container']} span={8}>
              {district &&
                wards.map((_ward, index) =>
                  district.district_id === _ward.district_id ? (
                    <Row
                      key={index}
                      style={{
                        color: '#000',
                        padding: '2px 10px',
                        margin: '1px 3px',
                        cursor: 'pointer',
                        backgroundColor:
                          paramsLocation.ward_code === _ward.ward_code ||
                          ward.ward_code === _ward.ward_code
                            ? '#5F73E2'
                            : '',
                        borderRadius: 10,
                      }}
                      value={_ward.ward_code}
                      onMouseEnter={() => {
                        setWard(_ward)
                      }}
                      onClick={() => {
                        setWard(_ward)

                        var province_selected = provinces.filter(
                          (p) => p.province_id == _ward.province_id
                        )
                        var district_selected = districts.filter(
                          (d) => d.district_id == _ward.district_id
                        )

                        _onSubmit(province_selected, district_selected, _ward)
                        setParamLocation({
                          ...paramsLocation,
                          district_id: district.district_id,
                          province_id: province.province_id,
                          ward_code: _ward.ward_code,
                          ward: _ward,
                        })
                      }}
                    >
                      {_ward.ward_name}
                    </Row>
                  ) : (
                    ''
                  )
                )}
            </Col>
          </Row>
        )}
      ></Select>
    </div>
  )
}
