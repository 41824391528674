import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

//antd
import { Modal, Row, Button, InputNumber, Space, Popconfirm, Input, Select, Col, Alert } from 'antd'

//icons
import { DeleteOutlined } from '@ant-design/icons'

import { formatCash } from 'utils'

//apis
import { getPayments } from 'apis/payment'
import { ROUTES } from 'consts'
import { getPoint } from 'apis/point'
import _ from 'lodash'

export default function PaymentMethods({
  editInvoice,
  invoices,
  moneyToBePaidByCustomer,
  setVisible,
  visible,
  onCheckPaymentValid,
}) {
  const { t } = useTranslation()
  const toggle = () => setVisible(!visible)

  const [paymentsMethod, setPaymentsMethod] = useState([])
  const [payments, setPayments] = useState([])
  const [paymentMethodDefault, setPaymentMethodDefault] = useState({}) //hình thức thanh toán mặc định

  const [point, setPoint] = useState({})
  const [costPaid, setCostPaid] = useState(0)
  const [excessCash, setExcessCash] = useState(0)

  const inputRef = useRef()

  const inputValue = (attribute, value, index) => {
    let paymentsNew = [...payments]
    if (attribute === 'Thanh toán bằng điểm')
      paymentsNew[index].value = +value * point.exchange_money_rate
    else paymentsNew[index].value = +value

    const sumCostPaid = paymentsNew.reduce((total, current) => total + current.value, 0)
    const excessCash = sumCostPaid - moneyToBePaidByCustomer

    setExcessCash(excessCash >= 0 ? excessCash : 0)
    setCostPaid(sumCostPaid)
    setPayments([...paymentsNew])
    onCheckPaymentValid([...paymentsNew])
  }

  const _addPaymentMethod = (paymentIdListSeleted) => {
    var _payment_new = []
    var _payment_current = [...payments]
    var _paymentListCurrent = [...paymentsMethod]

    for (var j = 0; j < paymentIdListSeleted.length; j++) {
      var index = _paymentListCurrent.findIndex(
        (_p) => _p.payment_method_id == paymentIdListSeleted[j]
      )
      if (index >= 0) {
        _payment_new.push({
          name: _paymentListCurrent[index].name,
          value: 0,
          payment_method_id: _paymentListCurrent[index].payment_method_id,
        })
      }
      if (_payment_new.length > 0) {
        _payment_new[0].value = moneyToBePaidByCustomer
      }
    }

    

    // Gán giá trị số tiền thanh toán ở mảng cũ cho mảng mới
    for (var i = 0; i < _payment_new.length; i++) {
      var isFind = false

      for (var j = 0; j < _payment_current.length; j++) {
        if (_payment_new[i].payment_method_id == _payment_current[j].payment_method_id) {
          _payment_new[i].value = _payment_current[j].value
          isFind = true
          break
        }
      }

      // Trường hợp xoá hết phương thức thanh toán và add lại cái đầu tiên
      if (!isFind) {
        _payment_new[0].value = moneyToBePaidByCustomer
      }
    }
    setPayments(_payment_new)
  }


  const _removePaymentMethod = (payment_method_id) => {
    let paymentsNew = [...payments]
    paymentsNew = paymentsNew.filter(
      (item) => parseInt(item.payment_method_id) !== parseInt(payment_method_id)
    )

    const sumCostPaid = paymentsNew.reduce((total, current) => total + current.value, 0)
    const excessCash = sumCostPaid - moneyToBePaidByCustomer

    setExcessCash(excessCash >= 0 ? excessCash : 0)
    setCostPaid(sumCostPaid)
    setPayments([...paymentsNew])
  }

  const _savePayments = () => {
    editInvoice('payments', payments)
  }

  const _exit = () => {
    toggle()
    setPayments(invoices.payments && invoices.payments)
  }


  const _getPoint = async () => {
    try {
      const res = await getPoint()
      if (res.data) {
        if (res.data.data && res.data.data.length) setPoint(res.data.data[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        let paymentMethodDefault = ''
        if (res.data.data.length == 0) return

        setPaymentsMethod(
          res.data.data
            .filter((e) => e.active)
            .map((e) => {
              if (e.default) paymentMethodDefault = e
              return e
            })
        )

        if (paymentMethodDefault) {
          setPaymentMethodDefault({
            name: paymentMethodDefault.name,
            value: moneyToBePaidByCustomer,
            payment_method_id: paymentMethodDefault.payment_method_id,
          })
          setPayments([
            {
              name: res.data.data[0].name,
              value: moneyToBePaidByCustomer,
              payment_method_id: res.data.data[0].payment_method_id,
            },
          ])
        } else {
          setPaymentMethodDefault({
            name: res.data.data[0].name,
            value: moneyToBePaidByCustomer,
            payment_method_id: res.data.data[0].payment_method_id,
          })
          setPayments([
            {
              name: res.data.data[0].name,
              value: moneyToBePaidByCustomer,
              payment_method_id: res.data.data[0].payment_method_id,
            },
          ])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getPayments()
    _getPoint()
  }, [])

  useEffect(() => {
    onCheckPaymentValid([...payments])
    if (inputRef.current !== undefined) inputRef.current.focus()
  }, [])

  useEffect(() => {
    if (payments.length == 1 && payments[0].payment_method_id == -1) {
      setPayments([
        {
          name: payments[0].name,
          value: 0,
          payment_method_id: payments[0].payment_method_id,
        },
      ])
    }
  }, [moneyToBePaidByCustomer])

  useEffect(() => {
    if (visible) {
      if (!invoices.payments.length) {
        console.log(paymentMethodDefault)
        setPayments([paymentMethodDefault])
      } else {
        // console.log(invoices[indexInvoice].payments)
        if (
          invoices.payments[0].payment_method_id == -1 &&
          invoices.payments.length == 1
        ) {
          invoices.payments[0].value = moneyToBePaidByCustomer
        }
        setPayments([...invoices.payments])
      }

      // if (invoices.isDelivery) setCostPaid(invoices.prepay)
      setCostPaid(costPaid || invoices.moneyToBePaidByCustomer)

      setExcessCash(excessCash)
    } 
  }, [visible])

  return (
    <>
      <p onClick={_exit} style={{ marginBottom: 0, color: '#1890ff', cursor: 'pointer' }}>
        {payments.length > 0 &&
          payments.map((payment_method) => {
            if (payment_method.value > 0)
              return (
                <div>{payment_method.name + ' (' + formatCash(payment_method.value) + ')'}</div>
              )
          })}
        {[...payments].filter((item) => item.value > 0).length == 0 && <div>{'Bấm chọn'}</div>}
      </p>
      <Modal
        width={540}
        footer={
          <Row justify="end">
            <Button style={{ width: 100, borderRadius: 5 }} onClick={_exit}>
              Chọn
            </Button>
          </Row>
        }
        title={t('sell.payment_methods')}
        onCancel={toggle}
        visible={visible}
      >
        <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
          {t('sell.can_choose_multiple_payment_methods')}
        </p>
        <Space direction="vertical" size="middle" style={{ width: '100%', fontSize: 18 }}>
          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <p>{t('common.guest_must_pay')}</p>
            <p>{formatCash(moneyToBePaidByCustomer)}</p>
          </Row>

          <Row wrap={false} justify="space-between" align="middle">
            <Select
              value={payments.map(
                (e, index) =>
                  e.payment_method_id
              )}
              placeholder={t('sell.choose_a_form_of_payment')}
              style={{ width: '100%' }}
              mode="multiple"
              allowClear
              onDeselect={(a, b) => {
                _removePaymentMethod(b.key)
              }}
              onChange={(list) => {
                console.log(list)
                _addPaymentMethod(list)
              }}
            >
              {paymentsMethod.map((paymentMethod, index) => (
                <Select.Option
                  value={paymentMethod.payment_method_id}
                  key={paymentMethod.payment_method_id}
                >
                  {paymentMethod.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Space direction="vertical" style={{ width: '100%' }}>
            {payments &&
              payments.map((payment, index) => {
                const SelectPayments = () => (
                  <Input
                    value={payment.name}
                    style={{ pointerEvents: 'none' }}
                    bordered={false}
                    placeholder={t('sell.select_a_payment_method')}
                  />
                )
                const InputValue = () => (
                  <div>
                    <InputNumber
                      min={0}
                      ref={inputRef}
                      onBlur={(e) => {
                        const value = e.target.value.replaceAll(',', '')
                        inputValue(payment.name, value, index)
                        _savePayments()
                      }}
                      onPressEnter={(e) => {
                        inputRef.current.blur()
                      }}
                      defaultValue={payment.value || moneyToBePaidByCustomer}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: 130, position: 'relative' }}
                      placeholder={t('sell.customer_pay')}
                      bordered={false}
                    />
                  </div>
                )

                return (
                  <>
                    <Row justify="space-between" align="middle" wrap={false}>
                      <Col span={18}>
                        <SelectPayments />
                      </Col>
                      <Col span={6}>
                        <div style={{ borderBottom: '0.75px solid #C9C8C8' }}>
                          <InputValue />
                        </div>
                      </Col>
                    </Row>
                    {payment.payment_method_id === -2 ? (
                      <span style={{ color: 'red', fontSize: 12, marginLeft: 10 }}>
                        1 điểm = {formatCash(point.exchange_money_rate)}
                      </span>
                    ) : (
                      ''
                    )}
                  </>
                )
              })}
          </Space>
          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <p>{t('sell.total_amount_paid_by_customer')}</p>
            <p>{formatCash(costPaid)}</p>
          </Row>
          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <p>{t('common.money_refund')}</p>
            <p>{formatCash(excessCash)}</p>
          </Row>
          <Link to={ROUTES.PAYMENT} target="_blank" style={{ fontSize: 14 }}>
            {t('sell.set_default_payment_method_in_Configuration')}
          </Link>
        </Space>
      </Modal>
    </>
  )
}