import axios from 'axios'
import {stringify} from 'querystring'
import Cookies from 'js-cookie';
import localStorage from "../utils/localStorage";
import jwt_decode from "jwt-decode";

const getCurrentToken = () => {
  let accessTokenFromCookie = Cookies.get('accessToken');
  let userFromCookie = jwt_decode(accessTokenFromCookie);
  let accessTokenFromStorage = localStorage.getItem('accessToken');
  if (!accessTokenFromStorage || accessTokenFromStorage == 'undefined') {
    accessTokenFromStorage = accessTokenFromCookie;
  }
  let userFromStorage = jwt_decode(accessTokenFromStorage);

  if (userFromCookie.data.user_id == userFromStorage.data.user_id) {
    return accessTokenFromStorage;
  } else {
    return accessTokenFromCookie;
  }
}

export const FetchAPI = async (
  path,
  method,
  headers,
  body,
  endpoint = process.env.REACT_APP_API_ENDPOINT_DEV
) => {

  let accessToken = getCurrentToken();

  const defaultHeaders = {
    'Content-type': 'application/json',
    'X-Ekata-Site': window.location.hostname,
    'X-Ekata-Token': accessToken,
  }
  if (typeof headers === 'object') Object.assign(defaultHeaders, headers)

  try {
    return await axios({url: endpoint + path, method, headers: defaultHeaders, data: body})
  } catch (error) {
    if (error.response && error.response.status !== 401) {
      return error.response
    }
    return {
      status: 401,
    }
  }
}
export const get = (path, query = {}, headers = {}, endpoint) =>
  FetchAPI(`${path}?${stringify(query)}`, 'GET', headers, null, endpoint)
export const post = (path, body, headers, endpoint) =>
  FetchAPI(path, 'POST', headers, body, endpoint)
export const patch = (path, body, headers, endpoint) =>
  FetchAPI(path, 'PATCH', headers, body, endpoint)
export const destroy = (path, body, headers, endpoint) =>
  FetchAPI(path, 'DELETE', headers, body, endpoint)
