import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES, PERMISSIONS } from 'consts'
const ConnectStatus = ({ record ,location}) => {
  return (
    <Link
      style={{
        color:
          (record?.order_status === 'waiting-confirm' && 'black') ||
          (record?.order_status === 'waiting-pickup' && 'black') ||
          (record?.order_status === 'delivering' && 'orange') ||
          (record?.order_status === 'complete' && 'green') ||
          (record?.order_status === 'cancel-order' && 'red') ||
          (record?.order_status === 'refund-order' && '#ff7089'),
      }}
      to={{pathname: record.status === 100 ? ROUTES.SELL : ROUTES.ORDER_UPDATE + '/' + record?.order_id , state : record.status === 100 ? {...record} : 0}}
      target= {location === 1 ? '_blank' : '_self'} 
     
    >
      {'#' + record?.code}
    </Link>
  )
}

export default ConnectStatus
