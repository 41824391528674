import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { validatePhone } from 'utils'
import { PERMISSIONS, TIME_FORMAT } from 'consts'

//components
import Permission from 'components/permission'
//antd
import {
  Form,
  Drawer,
  Row,
  Col,
  Button,
  Input,
  notification,
  DatePicker,
  Select,
  Radio,
  Space,
  Modal,
  InputNumber,
} from 'antd'

//apis
import { updateStaff, getPayRoll } from 'apis/manage-staff'

export default function StaffForm({
  children,
  reloadData,
  record,
  provinces = [],
  roles = [],
  districts = [],
}) {
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [province, setProvince] = useState('')
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [wage, setWage] = useState(0)
  const [checkPointTime, setCheckPointTime] = useState(0)
  const [timeWorking, setTimeWorking] = useState(0)
  const [payRoll, setPayRoll] = useState(0)
  const [timePayRoll, setTimePayRoll] = useState(0)


  const _addOrEditUser = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      setLoading(true)
      const body = {
        ...dataForm,
        role_id: dataForm.role_id,
        wage: wage,
        check_point_time: checkPointTime,
        total_working_time: timeWorking,
        // avatar: '',
        // first_name: dataForm.first_name || '',
        // last_name: dataForm.last_name || '',
        // email: dataForm.email || '',
        // phone: dataForm.phone || '',
        // birth_day: dataForm.birthday || '',
        // address: dataForm.address || '',
        // district: dataForm.district || '',
        // province: dataForm.province || '',
      }

      let res
      if (record) res = await updateStaff(body, record.staff_user_id)

      if (res.status === 200) {
        if (res.data.success) {
          toggle()
          reloadData()
          notification.success({
            message: `${record ? 'Cập nhật' : ''} nhân viên thành công`,
          })
        } else
          notification.error({
            message:
              res.data.message ||
              `${record ? 'Cập nhật' : ''} nhân viên thất bại, vui lòng thử lại`,
          })
      } else
        notification.error({
          message:
            res.data.message || `${record ? 'Cập nhật' : ''} nhân viên thất bại, vui lòng thử lại`,
        })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const _getPayRoll = async () =>{
    let res = await getPayRoll({phone: record.phone, branch_id: branchIdApp})
    console.log(res);
    if(res.status === 200){
      setTimePayRoll(`${Math.floor(res.data.time/ 3600)} giờ ${Math.floor((res.data.time % 3600) / 60)} phút`)
      setPayRoll(res.data.payRoll)
      
    }
  }
  useEffect(() => {
    if (visible) {
      if (!record) {
        form.resetFields()
        form.setFieldsValue({
          branch_id: +branchIdApp,
          province: provinces.length ? provinces[0].province_name : '',
          district: districts.length ? districts[0].district_name : '',
        })
        setProvince(provinces.length ? provinces[0].province_name : '')
      } else {
        setProvince(record.province || '')
        form.setFieldsValue({
          ...record,
          birth_day: record.birth_day ? moment(record.birth_day).format(TIME_FORMAT.DATE) : null,
          phone: record.phone,
          create_date: record.create_date
            ? moment(record.create_date).format(TIME_FORMAT.DATE)
            : null,
        })
        setWage(record.wage)
        setCheckPointTime(record.check_point_time)
        setTimeWorking(record.total_working_time)
        setPayRoll(0)
        setTimePayRoll(0)
      }
    }
  }, [visible])

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal
        width="70%"
        footer={
          <Row justify="end">
            {record ? (
              <Permission permissions={[PERMISSIONS.cap_nhat_nhan_vien]}>
                <>
                  <Button
                    onClick={_getPayRoll}
                    loading={loading}
                    size="large"
                    type="primary"
                    style={{backgroundColor: 'green',borderColor:'green' }}
                  >
                    Tính lương tháng này
                  </Button>

                  <Button
                    onClick={_addOrEditUser}
                    loading={loading}
                    size="large"
                    type="primary"
                    style={{ width: 120 }}
                  >
                    Cập nhật
                  </Button>
                </>
                
              </Permission>
            ) : (
              <></>
            )}
            <Button onClick={toggle} loading={loading} size="large" style={{ width: 120 }}>
              Đóng
            </Button>
          </Row>
        }
        closable={false}
        title={`${record ? 'Cập nhật' : ''} nhân viên`}
        placement="right"
        // onCancel={toggle}
        visible={visible}
      >
        <Form layout="vertical" form={form}>
          <Row gutter={24} justify="space-between" align="middle">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Số điện thoại"
                name="username"
                rules={[
                  { required: record ? false : true, message: 'Vui lòng nhập số điện thoại!' },
                ]}
              >
                <Input
                  defaultValue={record.phone}
                  disabled={record ? true : false}
                  placeholder="Nhập số điện thoại"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label="Họ" name="first_name">
                <Input disabled={record ? true : false} placeholder="Nhập họ" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Tên"
                name="last_name"
                rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
              >
                <Input disabled={record ? true : false} placeholder="Nhập tên" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="space-between" align="middle">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label="Email" name="email">
                <Input
                  defaultValue={record.email}
                  disabled={record ? true : false}
                  placeholder="Nhập email"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label="Ngày sinh" name="birthday">
                <Input
                  defaultValue={moment(record.date_of_birth).format('DD-MM-YYYY')}
                  disabled={record ? true : false}
                  placeholder="Chọn ngày sinh"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label="Địa chỉ" name="address">
                <Input disabled={record ? true : false} placeholder="Nhập địa chỉ" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="space-between" align="middle">
            <Col xs={24} sm={12} md={12} lg={8} xl={4}>
              <Form.Item label="Ngày tham gia" name="create_date">
                <Input
                  defaultValue={moment(record.create_date).format(TIME_FORMAT.DATE)}
                  disabled={record ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={4}>
              <Form.Item label="Tổng thời gian làm việc" name="total_working_time">
                <InputNumber
                  disabled={true}
                  value={timeWorking}
                  onChange={(e) => setTimeWorking(e)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4} xl={4}>
              <Form.Item label="Thời gian check point" name="check_point_time">
                <Select 
                  value={checkPointTime}
                  onChange={(e) => setCheckPointTime(e)}
                >
                  <Select.Option value={1}>1 Tháng</Select.Option>
                  <Select.Option value={2}>2 Tháng</Select.Option>
                  <Select.Option value={3}>3 Tháng</Select.Option>
                  <Select.Option value={4}>4 Tháng</Select.Option>
                  <Select.Option value={5}>5 Tháng</Select.Option>
                  <Select.Option value={6}>6 Tháng</Select.Option>
                  <Select.Option value={7}>7 Tháng</Select.Option>
                  <Select.Option value={8}>8 Tháng</Select.Option>
                  <Select.Option value={9}>9 Tháng</Select.Option>
                  <Select.Option value={10}>10 Tháng</Select.Option>
                  <Select.Option value={11}>11 Tháng</Select.Option>
                  <Select.Option value={12}>12 Tháng</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4} xl={4}>
              <Form.Item label="Mức lương" name="wage">
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  value={wage}
                  onChange={(e) => setWage(e)}
                />{' '}
                / giờ
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4} xl={4}>
              <Form.Item label="Tổng giờ làm trong tháng" >
                <Input value={timePayRoll}/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4} xl={4}>
              <Form.Item label="Lương tháng này">
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  value={payRoll}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Form.Item
              initialValue={(roles && roles[1] && roles[1].role_id) || ''}
              name="role_id"
              label="Vai trò"
              rules={[{ required: true, message: 'Vui lòng chọn vai trò' }]}
            >
              <Radio.Group defaultValue={-2}>
                {roles
                  ?.filter((item) => item.role_id !== 1)
                  .map((role, index) => (
                    <Radio value={role.role_id} key={index}>
                      {role.name}
                    </Radio>
                  ))}
              </Radio.Group>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
