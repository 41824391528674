import React, { useState, useEffect } from 'react'
import { getInventories } from 'apis/inventory'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { formatCash } from 'utils'
import { Link } from 'react-router-dom'

//antd
import { Table } from 'antd'
const columnsInventory = [
  {
    title: 'STT',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Mã chứng từ',
    render: (text, record) => (<Link to={`/import-inventory-update/${record.code_source}`}>{record.code_source}</Link>) ,
  },
  {
    title: 'SKU phiên bản',
    render: (text, record) => record?.variant_info?.sku,
  },
  {
    title: 'Tên phiên bản',
    render: (text, record) => record?.variant_info?.title,
  },
  {
    title: 'Số lượng nhập',
    render: (text, record) => record.import_quantity
  },
  {
    title: 'Giá nhập',
    render: (text, record) => formatCash(record.import_price),
  },
  {
    title: 'Tổng tiền',
    render: (text, record) => formatCash(record.import_price * record.import_quantity),
  },
  {
    title: 'Nhân viên',
    render: (text, record) =>
      record?.creator_info?.first_name + ' ' + record.creator_info?.last_name,
  },
  {
    title: 'Ngày ghi nhận',
    render: (text, record) => moment(record.create_date).format('DD-MM-YYYY, HH:mm'),
  },
]
export default function ImportDetail({record, prod}) {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [inventory, setInventory] = useState([])
  const _getInventories = async () => {
    try {
        if(prod === 1){
            const res = await getInventories({
                product_id: record.product_id,
                branch_id: branchIdApp,
              })
              if (res.status === 200) {
                setInventory(res.data.data.filter(e => e.source === 'import-order'))
              }
        }else{
            const res = await getInventories({
                variant_id: record.variant_id,
                product_id: record.product_id,
                branch_id: branchIdApp,
              })
              if (res.status === 200) {
                setInventory(res.data.data.filter(e => e.source === 'import-order'))
              }
        }
      
    } catch (error) {}
  }
  useEffect(() => {
    _getInventories()
  }, [record, branchIdApp])

  return (
    <>
      <Table
        scroll={{ x: 'max-content' }}
        dataSource={inventory || []}
        size="small"
        columns={columnsInventory}
      />
    </>
  )
}
