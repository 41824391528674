import { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import React from 'react'

export default function ModalPicture({ imageData, modalDefaultImg, imageBig, setImageBig }) {
  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <img
          src={imageBig.length === 0 ? modalDefaultImg[0] : imageBig}
          alt=""
          style={{ width: '100%', height: 400, objectFit: 'contain' }}
        />
      </div>
      <Row gutter={[15, 15]}>
        {imageData.length !== 0
          ? imageData.map((img) => (
              <Col span={4}>
                <img
                  src={img}
                  alt=""
                  style={{ width: '100%', height: 50, objectFit: 'cover' }}
                  onClick={() => setImageBig(img)}
                />
              </Col>
            ))
          : ''}
      </Row>
    </div>
  )
}
