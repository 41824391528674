import React, { useEffect, useState } from 'react'
import styles from './connect-device.module.scss'
import { formatCash } from 'utils'
import Logo from 'assets/img/default_logo_ekata.png'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
const ConnectDevice = (props) => {
  const { socket } = useSelector((state) => state)
  const {invoices} = useSelector(state => state.invoice)

  const { code } = useParams()
  const [dataInvoice, setDataInvoice] = useState([])
  const [keyInvoice, setKeyInvoice] = useState(0)
  useEffect(() => {
    socket.emit(`joinRoom`, code)
    socket.on(`sendInvoiceDevice`, (data) => {
      setDataInvoice(data.invoice)
      setKeyInvoice(data.key_invoice)
    })
    return () => socket.off('sendInvoiceDevice')
  }, [socket])

  useEffect(() => {
    if(invoices.length > 0) {
      setDataInvoice([...invoices])
    }
  },[])

  return (
    <div className={styles['background-container']}>
      <div>
        <img style={{ height: '150px' }} src={Logo} />
      </div>
      <div>
        <h1 className={styles['text-h1']}>Ekata</h1>
      </div>
      <div className={styles['cart-order']}>
        <h1>Thông tin đơn hàng</h1>

        <div className={styles['header-data']}>
          <div className={styles['header-item-0']}>
            <span className={styles['header-span']}>Số lượng</span>
          </div>
          <div className={styles['header-item-1']}>
            <span className={styles['header-span']}>Đơn giá</span>
          </div>
          <div className={styles['header-item-2']}>
            <span className={styles['header-span']}>Thành tiền</span>
          </div>
        </div>

        {dataInvoice.length > 0 &&
          dataInvoice[keyInvoice]?.order_details?.map((item, index) => (
            item &&
            <div key={index} className={styles['body-data']}>
              <div className={styles['body-item-0']}>
                <span className={styles['body-span']}>{item.title || ''}</span>
                <div className={styles['body-span']}>{item.quantity || 0}</div>
              </div>
              <div className={styles['body-item-1']}>
                <span className={styles['body-span']}>{item.discount ? formatCash( item.price - item.discount || 0) : formatCash( item.price || 0)}</span>
              </div>
              <div className={styles['body-item-2']}>
                <span className={styles['body-span']}>
                  {formatCash((item.sumCost) ||0)}
                </span>
              </div>
            </div>
          ))}
        <div style={{ marginTop: '3%' }}></div>
        {dataInvoice.length > 0 && (
          <>
            <div className={styles['footer-data']}>
              <div className={styles['footer-item-0']}>
                <span className={styles['footer-span']}>Tổng tiền hàng</span>
              </div>
              <div className={styles['footer-item-1']}>
                <span className={styles['footer-span']}>{formatCash(dataInvoice[keyInvoice]?.sumCostPaid || 0)}</span>
              </div>
            </div>
            <div className={styles['footer-data']}>
              <div className={styles['footer-item-0']}>
                <span className={styles['footer-span']}>Tổng giảm giá đơn hàng</span>
              </div>
              <div className={styles['footer-item-1']}>
                <span className={styles['footer-span']}>{formatCash(dataInvoice[keyInvoice]?.discount_invoice + dataInvoice[keyInvoice]?.discount_product + (dataInvoice[keyInvoice]?.discount.discount || 0) || 0)}</span>
              </div>
            </div>
            {/* <div className={styles['footer-data']}>
              <div className={styles['footer-item-0']}>
                <span className={styles['footer-span']}>Chiết khấu</span>
              </div>
              <div className={styles['footer-item-1']}>
                <span className={styles['footer-span']}>{formatCash(dataInvoice[keyInvoice]?.discount_invoice || 0)}</span>
              </div>
            </div> */}

            {/* <div className={styles['footer-data']}>
              <div className={styles['footer-item-0']}>
                <span className={styles['footer-span']}>Khuyến mãi</span>
              </div>
              <div className={styles['footer-item-1']}>
                <span className={styles['footer-span']}>{formatCash(dataInvoice[keyInvoice]?.total_discount - dataInvoice[keyInvoice]?.discount_invoice - dataInvoice[keyInvoice]?.discount_product || 0)}</span>
              </div>
            </div> */}
            {dataInvoice[keyInvoice]?.total_tax > 0 ? 
            <div className={styles['footer-data']}>
              <div className={styles['footer-item-0']}>
                <span className={styles['footer-span']}>Thuế</span>
              </div>
              <div className={styles['footer-item-1']}>
                <span className={styles['footer-span']}>{formatCash(dataInvoice[keyInvoice]?.total_tax || 0)}</span>
              </div>
            </div>
            : null}
          </>
        )}
        {dataInvoice.length > 0 && (
          <>
        <div className={styles['end-data']}>
          <div className={styles['end-item-0']}>
            <span className={styles['end-span']}>Khách phải trả</span>
          </div>
          <div className={styles['end-item-1']}>
            <span className={styles['end-span']}>{formatCash(dataInvoice[keyInvoice]?.moneyToBePaidByCustomer || 0)}</span>
          </div>
        </div>

        <div className={styles['end-data']}>
          <div className={styles['end-item-0']}>
            <span className={styles['end-span']}>Khách đã trả</span>
          </div>
          <div className={styles['end-item-1']}>
            <span className={styles['end-span']}>{formatCash(dataInvoice[keyInvoice]?.moneyGivenByCustomer || 0)}</span>
          </div>
        </div>
        </>
        )}
      </div>
    </div>
  )
}

export default ConnectDevice
