import { ACTION } from 'consts'
import { getRoles } from 'apis/role'

export const getRolesPermission = ({branch_id}) => async (dispatch) => {
    try {
        dispatch({ type: ACTION.LOADING, data: true })
        const res = await getRoles({ branch_id: branch_id })

        let new_data = res.data.data.filter(item => item.role_id !== 1)
  
        if (res.status === 200) {
            dispatch({ type: 'SET_ROLES', data: new_data })
        }

        dispatch({ type: ACTION.LOADING, data: false })
      } catch (error) {
        dispatch({ type: ACTION.LOADING, data: false })
      }
  };