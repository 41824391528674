const initialState = []
const feed = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_PRODUCTS': {
      state = action.data
      return [...state]
    }
    default:
      return state
  }
}
export default feed
