import React, { useState, useEffect } from 'react'
import {  removeUnicode } from 'utils'
import moment from 'moment'
import { useSelector } from 'react-redux'

//apis
import { addFinances} from 'apis/finance'
import { getPayments } from 'apis/payment'
import { getFinanceTypePayment } from 'apis/finance-type'
import { getAllUserSystem } from 'apis/manage-staff'

//antd
import {
  Row,
  Space,
  Button,
  Input,
  Select,
  Form,
  InputNumber,
  notification,
  DatePicker,
  AutoComplete,
} from 'antd'

//components
const { Option } = Select
export default function ModalCreatePaymentOrReceipts({ type, children, record, reload, close }) {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [form] = Form.useForm()
  const [payments, setPayments] = useState([])
  const [financeTypePayments, setFinanceTypePayments] = useState([])
  const [paymentMethodDefault, setPaymentMethodDefault] = useState({})
  const [dataUsers, setDataUsers] = useState([])
  const [dataUserPhone, setDataUserPhone] = useState([])
  const [loading, setLoading] = useState(false)

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const onFinish = async (values) => {
    try {
      setLoading(true)
      let index = values.phone_payer.lastIndexOf('-')

      let phone = values.phone_payer.slice(0, index)
      let name = values.phone_payer.slice(index + 1, values.phone_payer.length)
      const body = {
        source: 'PAYMENT',
        note: values.note || '',
        branch_id: branchIdApp,
        time_transaction: values.time_transaction || '',
        phone_payer: phone|| '',
        value: values.value,
        name_payer: name,
        typefinance: values.typefinance,
        payment_method: paymentMethodDefault.method,
        payment_method_id: paymentMethodDefault.payment_method_id,
        type: 'Phiếu chi',
        status: 3,
      }
  
      let res = await addFinances(body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: `Thêm phiếu thành công` })
          reload()
          close()
          form.resetFields()
        } else
          notification.error({
            message: 'Tạo phiếu thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: 'Tạo phiếu thất bại, vui lòng thử lại!',
        })
      setLoading(false)
    } catch (error) {
      setVisible(false)
      console.log(error);
    }
  }

  const _getFinanceTypePayment = async () => {
    try {
      const res = await getFinanceTypePayment()
      if (res.status === 200) setFinanceTypePayments(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getAllUser = async () => {
    try {
      const res = await getAllUserSystem({})
      if (res.status === 200) {
        setDataUsers(res.data.data.filter((employee) => employee.phone))
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    _getAllUser()
  }, [])
  useEffect(() => {
    _getFinanceTypePayment()
  }, [])
 
  
  const searchUserPhone = (e) => {
    if (e.length > 9) {
      let newUser = [...dataUsers]
      newUser = newUser
        .filter((item) => item.phone == e)
        .map((item) => {
          return {
            value: item.phone + '-' + item.first_name + ' ' + item.last_name,
          }
        })
      setDataUserPhone(newUser)
    }
  }


  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        setPayments(res.data.data)
        const payment = res.data.data.find((e) => e.default)

        setPaymentMethodDefault({
          method: payment.name,
          payment_method_id: payment.payment_method_id,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getPayments()
  }, [])
  

  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row justify="space-between">
            <Row wrap={false}>
              <Form.Item
                name="typefinance"
                label="Loại phiếu chi"
                rules={[{ required: true, message: 'Vui lòng chọn loại phiếu ' }]}
              >
                <Select
                  allowClear
                  placeholder="Chọn loại phiếu chi"
                  size="large"
                  style={{ width: 280 }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    removeUnicode(option.children.toString())
                      .toLowerCase()
                      .trim()
                      .includes(removeUnicode(input).toLowerCase().trim())
                  }
                >
                  {financeTypePayments.map((type, index) => (
                    <Option value={type.name} key={index}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
            <Form.Item
              name="value"
              label="Nhập giá trị thanh toán"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập giá trị',
                },
              ]}
            >
              <InputNumber
                value=""
                min={0}
                placeholder="Nhập giá trị "
                style={{ width: 280 }}
                size="large"
                className="br-15__input"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Row>
          <Row justify="space-between">
            <Form.Item
              label="Người nhận"
              name="phone_payer"
              rules={[{ required: true, message: 'Vui lòng chọn số điện thoại!', whitespace: true}]}
            >
              <AutoComplete
                className="br-15__input"
                popupClassName="certain-category-search-dropdown"
                style={{
                  width: 280,
                }}
                options={dataUserPhone}
                onSearch={searchUserPhone}
              >
                <Input
                  style={{ height: 38 }}
                  placeholder="Nhập số điện thoại hoặc tên người nhận"
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              name="payment_infos"
              label="Hình thức thanh toán"
              rules={[{ required: true, message: 'Vui lòng chọn hình thức thanh toán' }]}
            >
              <Select
                size="large"
                className="br-15__input"
                showSearch
                optionFilterProp="children"
                allowClear
                placeholder="Chọn hình thức thanh toán"
                style={{ width: 280 }}
                onChange={(list) => {
                  setPaymentMethodDefault({
                    method: String(
                      payments
                        .filter((item) => item.payment_method_id === list)
                        .map((e, index) => e.name)
                    ),
                    payment_method_id: list,
                  })
                }}
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
              >
                {payments.map((payment, index) => (
                  <Select.Option key={payment.payment_method_id} value={payment.payment_method_id}>
                    {payment.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Row>

          <Row justify="space-between">
            {/* <Form.Item name="time_transaction" label="Thời gian thực hiện giao dịch (nếu có)">
              <DatePicker
                showTime={{
                  defaultValue: moment('00:00:00', 'HH:mm:ss'),
                }}
                disabledDate={(current) => {
                  // Can not select days before today and today
                  return current && current > moment().endOf('day')
                }}
                format="YYYY/MM/DD HH:mm:ss"
                size="large"
                style={{ width: '280px', marginTop: '7px' }}
              />
            </Form.Item> */}
            <Form.Item name="note" label="Mô tả">
              <Input.TextArea rows={4} placeholder="Nhập Mô tả" style={{ width: 280 }} />
            </Form.Item>
          </Row>
        </Space>

        <Row justify="end">
          <Button
            style={{
              backgroundColor: '#DE7C08',
              borderColor: '#DE7C08',
              borderRadius: 5,
              color: 'white',
              fontWeight: 600,
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            size="large"
          >
            {record ? 'Cập nhật' : 'Tạo phiếu chi'}
          </Button>
        </Row>
      </Form>
    </>
  )
}
