import React, { useEffect, useState, memo } from 'react'
import styles from './price-adjustments.module.scss'
import { useHistory, Link, useLocation, useParams } from 'react-router-dom'
import { ROUTES, IMAGE_DEFAULT, PERMISSIONS } from 'consts'
import { filterOptionProduct, formatCash } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
//components
import TitlePage from 'components/title-page'
import Permission from 'components/permission'
import { socket } from '../../socket'
import { _getProductChange } from '../../components/Product/getProductChange'
//antd
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  InputNumber,
  Form,
  Select,
  Spin,
  notification,
  Space,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  CloseOutlined,
  SearchOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import noData from 'assets/icons/no-data.png'

//apis
import { getProducts } from 'apis/product'
import {
  updatePriceAdjustments,
  addPriceAdjustments,
  getPriceAdjustments,
  confirmPriceAdjustments,
  deletePriceAdjustments,
  savePriceAdjustments,
} from 'apis/price-adjustments'

export default function PriceAdjustments() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [dataHistoryPrice, setDataHistoryPrice] = useState(null)
  const location = useLocation()
  let { code } = useParams()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const query = queryString.parse(location.search)
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [listProduct, setListProduct] = useState([])

  const _confirmPriceAdjustments = async (body, id) => {
    const dataForm = form.getFieldsValue()
    body.note = dataForm.note?.trim() || ''
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await confirmPriceAdjustments(body, id)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `${
              body.status === 'COMPLETE' ? 'Hoàn thành' : 'Cập nhật'
            } phiếu điều chỉnh thành công`,
          })
          history.push({ pathname: ROUTES.PRICE_ADJUSTMENTS })
        } else
          notification.error({
            message:
              res.data.message ||
              `${
                body.status === 'COMPLETE' ? 'Hoàn thành' : 'Cập nhật'
              } phiếu điều chỉnh thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${
              body.status === 'COMPLETE' ? 'Hoàn thành' : 'Cập nhật'
            } phiếu điều chỉnh thất bại, vui lòng thử lại!`,
        })
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _deletePriceAdjustments = async (id) => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await deletePriceAdjustments(id, { branch_id: branchIdApp })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `Huỷ phiếu điều chỉnh thành công`,
          })
          history.push({ pathname: ROUTES.PRICE_ADJUSTMENTS })
        } else
          notification.error({
            message: res.data.message || `Huỷ phiếu điều chỉnh thất bại`,
          })
      } else
        notification.error({
          message: res.data.message || `Huỷ phiếu điều chỉnh thất bại, vui lòng thử lại!`,
        })
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _savePriceAdjustments = async (id) => {
    try {
      const dataForm = form.getFieldsValue()
      let body = { note: dataForm.note?.trim() || '' }
      dispatch({ type: 'LOADING', data: true })
      const res = await savePriceAdjustments(body, id)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `Cập nhật phiếu điều chỉnh thành công`,
          })
        } else
          notification.error({
            message: res.data.message || `Cập nhật phiếu điều chỉnh thất bại`,
          })
      } else
        notification.error({
          message: res.data.message || `Cập nhật phiếu điều chỉnh thất bại, vui lòng thử lại!`,
        })
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const columns = [
    {
      title: 'Mã SP',
      dataIndex: 'sku',
    },
    {
      title: 'Tên Sản phẩm',
      dataIndex: 'title',
    },
    {
      title: 'Giá lẻ hiện tại',
      render: (text, record) => formatCash(record.price || 0),
    },
    {
      width: 150,
      title: 'Giá lẻ điều chỉnh',
      render: (text, record) => formatCash(record.new_price || 0),
    },
    {
      title: 'Chênh lệch',
      render: (text, record) => formatCash((record.price || 0) - (record.new_price || 0)),
    },
  ]
  const RequestDetail = async (history_fix_price_code) => {
    try {
      // request
      setLoading(true);
      var response_history_fix_price = await getPriceAdjustments({ code: history_fix_price_code })
      if (
        response_history_fix_price.status == 200 &&
        response_history_fix_price.data.data.length > 0
      ) {
        form.setFieldsValue({ ...response_history_fix_price.data.data[0] })
        setDataHistoryPrice({ ...response_history_fix_price.data.data[0] })
        setListProduct(
          response_history_fix_price.data.data[0].lists.map((product) => ({
            ...product,
            price_after_adjustment: product.price_new || 0,
          }))
        )
      } else {
        notification.error({ message: 'Phiếu điều chỉnh đã bị xoá hoặc không tìm thấy' })
        history.goBack()
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại' })
      history.goBack()
    }
  }

  const socketListener = () => {
    socket.on(`product:action:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
      try {
        notification.success({ key: 'socket', message: 'Số lượng sản phẩm đã thay đổi' })
        if (res.productList.length > 0) {
          let newData = await _getProductChange(branchIdApp, res.productList)
          dispatch({
            type: 'SET_NEW_PRODUCTS',
            data: {
              products: newData.products,
              ids: res.productList,
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  useEffect(() => {
    socketListener()
  }, [socket])

  useEffect(() => {
    if (code) {
      RequestDetail(code)
    }
  }, [])

  const renderTitle = () => {
    if (dataHistoryPrice?.status == 'PROGRESSING')
      return (
        <Link to={ROUTES.PRICE_ADJUSTMENTS}>
          <Row
            align="middle"
            style={{
              fontSize: 18,
              color: 'black',
              fontWeight: 600,
              width: 'max-content',
            }}
          >
            <ArrowLeftOutlined style={{ marginRight: 5 }} />
            Chi tiết phiếu điều chỉnh giá bán
          </Row>
        </Link>
      )

    if (dataHistoryPrice?.status == 'DELETE')
      return (
        <Link to={ROUTES.PRICE_ADJUSTMENTS}>
          <Row
            align="middle"
            style={{
              fontSize: 18,
              color: 'black',
              fontWeight: 600,
              width: 'max-content',
            }}
          >
            <ArrowLeftOutlined style={{ marginRight: 5 }} />
            Chi tiết phiếu điều chỉnh giá bán
          </Row>
        </Link>
      )

    if (dataHistoryPrice?.status == 'COMPLETE')
      return (
        <Link to={ROUTES.PRICE_ADJUSTMENTS}>
          <Row
            align="middle"
            style={{
              fontSize: 18,
              color: 'black',
              fontWeight: 600,
              width: 'max-content',
            }}
          >
            <ArrowLeftOutlined style={{ marginRight: 5 }} />
            Chi tiết phiếu điều chỉnh giá bán
          </Row>
        </Link>
      )
  }
  useEffect(() => {
    if (permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_dieu_chinh_gia) < 0) {
      history.push(ROUTES.PRICE_ADJUSTMENTS)
      notification.warning({ message: 'Permission denied!' })
    }
  }, [dataUser, branchIdApp])
  return (
    <div className="card">
      <TitlePage isAffix={true} title={renderTitle()}>
          {dataHistoryPrice ? (
            <div className={styles['group-button-action-form']}>
              {/* {dataHistoryPrice.status == 'COMPLETE' && (
                <Button
                  type="primary"
                  size="large"
                  className={styles['button-action']}
                  onClick={(e) => {
                    _createOrUpdatePriceAdjustments('DELETE')
                  }}
                >
                  {'Hoàn tác (Điều chỉnh ngược)'}
                </Button>
              )} */}
              {dataHistoryPrice.status == 'COMPLETE' && (
                <Button
                  className={styles['button-action']}
                  disabled={dataHistoryPrice.status == 'COMPLETE'}
                  type="primary"
                  size="large"
                >
                  {'Phiếu Đã Hoàn Thành'}
                </Button>
              )}

              {dataHistoryPrice.status == 'PROGRESSING' && (
                <>
                  {permissions.includes(PERMISSIONS.cap_nhat_phieu_dieu_chinh) ? (
                    <>
                      <Button
                        type="primary"
                        size="large"
                        className={styles['button-action']}
                        onClick={(e) => {
                          e.stopPropagation()
                          _savePriceAdjustments(dataHistoryPrice.fix_price_id)
                        }}
                      >
                        {'Lưu'}
                      </Button>
                      <Button
                        type="danger"
                        size="large"
                        className={styles['button-action']}
                        onClick={(e) => {
                          e.stopPropagation()
                          _deletePriceAdjustments(dataHistoryPrice.fix_price_id)
                        }}
                      >
                        {'Huỷ phiếu'}
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className={styles['button-action']}
                        onClick={(e) => {
                          e.stopPropagation()
                          _confirmPriceAdjustments(dataHistoryPrice, dataHistoryPrice.fix_price_id)
                        }}
                      >
                        {'Xác nhận phiếu'}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        disabled
                        type="primary"
                        size="large"
                        className={styles['button-action']}
                      >
                        {'Cập nhật'}
                      </Button>
                      <Button
                        disabled
                        type="primary"
                        size="large"
                        className={styles['button-action']}
                      >
                        {'Xác nhận phiếu'}
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <Space>
              <Permission permissions={[PERMISSIONS.tao_phieu_dieu_chinh]}>
                <Button type="primary" size="large" className={styles['button-action']}>
                  Tạo phiếu
                </Button>
              </Permission>

              <Permission permissions={[PERMISSIONS.cap_nhat_phieu_dieu_chinh]}>
                <Button type="primary" size="large" className={styles['button-action']}>
                  Tạo và điều chỉnh
                </Button>
              </Permission>
            </Space>
          )}
      </TitlePage>
      <Spin spinning={loading} tip='Loading...'>
      <Form layout="vertical" form={form}>
        <Row gutter={30} style={{ marginTop: 25 }}>
          <Col span={24}>
            <div className={styles['block']}>
              <Row justify="space-between" align="middle" wrap={false}>
                <div className={styles['title']}>Thông tin sản phẩm</div>
              </Row>

              <Table
                scroll={{ x: 'max-content' }}
                pagination={false}
                columns={columns}
                bordered={true}
                size="small"
                dataSource={listProduct}
                locale={{
                  emptyText: (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200,
                      }}
                    >
                      <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                      <h4 style={{ fontSize: 15, color: '#555' }}>
                        Phiếu điều chỉnh của bạn chưa có sản phẩm nào
                      </h4>
                    </div>
                  ),
                }}
              />
            </div>
          </Col>
        </Row>

        <Col span={12} style={{ marginTop: 10 }}>
          <div className={styles['block']} style={{ marginBottom: 30 }}>
            {/* <div className={styles['title']}>Thông tin đơn hàng</div> */}
            {/* <Form.Item label="Mã phiếu" name="code">
                <Input placeholder="Nhập mã phiếu" />
              </Form.Item> */}
            <Form.Item name="note" label="Ghi chú (lí do điều chỉnh)">
              <Input.TextArea
                disabled={dataHistoryPrice != undefined && dataHistoryPrice.status == 'DELETE'}
                rows={2}
                placeholder="Nhập ghi chú"
              />
            </Form.Item>
          </div>
        </Col>
      </Form>
      </Spin>
    </div>
  )
}
