import React, { useRef, useState } from 'react'
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Input,
  List,
  notification,
  Popover,
  Row,
  Tooltip,
} from 'antd'
import {
  CameraOutlined,
  CloseCircleTwoTone,
  FileGifOutlined,
  FileImageOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import styles from './comment.module.scss'
import avatarRound from 'assets/img/avatar-round.png'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFile } from 'apis/upload'
import { createComment } from 'redux/actions/comment'
import { reactions, imageShow, videoShow, MenuIcon, MenuGif } from './item'

function Comment({ children, item, onReply, setOnReply, comment_reply_id }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.login.dataUser)
  const [comments, setComments] = useState('')
  const [image, setImage] = useState([])
  const [stream, setStream] = useState(false)
  const videoRef = useRef()
  const refCanvas = useRef()
  const [tracks, setTracks] = useState(null)

  const handleChangeImages = (e) => {
    const files = [...e.target.files]
    let newImages = []

    files.forEach((file) => {
      if (!file) return notification.error({ message: 'File không tồn tại' })

      if (file.size > 1024 * 1024 * 5) {
        return notification.error({ message: 'The image/video largest is 5mb.' })
      }
      return newImages.push(file)
    })
    setImage([...newImages])
  }

  const deleteImages = () => {
    setImage([])
  }

  const handleStream = () => {
    setStream(true)

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          videoRef.current.srcObject = mediaStream
          videoRef.current.play()

          const track = mediaStream.getTracks()
          setTracks(track[0])
        })
        .catch((err) => console.log(err))
    }
  }

  const handleCapture = () => {
    const width = videoRef.current.clientWidth
    const height = videoRef.current.clientHeight

    refCanvas.current.setAttribute('width', width)
    refCanvas.current.setAttribute('height', height)

    const ctx = refCanvas.current.getContext('2d')
    ctx.drawImage(videoRef.current, 0, 0, width, height)
    let URL = refCanvas.current.toDataURL()
    setImage([{ camera: URL }])
  }

  const handleStopStream = (e) => {
    e.preventDefault()
    if (tracks) {
      tracks.stop()
    }
    setStream(false)
  }

  const handleSubmitComment = async (e) => {
    try {
      e.preventDefault()
      if (!comments.trim()) {
        if (onReply) return setOnReply(false)
        return notification.error({ key: 'message', message: 'Vui lòng nhập nội dung comment' })
      }
      let type_image = ''
      if (image.length > 0) {
        type_image = image[0].type.slice(0, 5)
      }

      const url_image = await uploadFile(image[0])
      const newComment = {
        comments,
        likes: [],
        user: user,
        image: url_image,
        type_image: type_image,
        comment_reply_id: onReply && onReply.comment_id,
        tag: onReply && onReply.user,
        tag_business_id: null,
        business_id: user?._business?.business_id,
      }
      if (comment_reply_id) {
        newComment.comment_reply_id = comment_reply_id
      }

      dispatch(createComment({ post: item, newComment, user }))
      // socketListener()
      setComments('')
      setImage([])

      if (onReply) return setOnReply(false)
    } catch (error) {
      console.log(error)
    }
  }

  // const socketListener = () => {
  //   socketEcom.on(`noti-comment:action:${user.user_id}`, async (res) => {
  //     dispatch(getNotificationsSystem())
  //   })
  //   socketEcom.on(`comment:action`, async (res) => {
  //     dispatch(getPostsSystem())
  //   })
  // }

  // useEffect(() => {
  //   socketListener()
  // }, [])

  return (
    <div>
      <div className={styles['container-add-cmt']}>
        <div className={styles['container-avt']}>
          <Avatar size={50} src={avatarRound} />
        </div>
        <div className={styles['container-input-add-cmt']}>
          <div className={styles['dashboard_manager_bottom_row_col_parent_top']}></div>
          <div className={styles['container-input']}>
            <Input
              prefix={children}
              className={styles['input-add-cmt']}
              placeholder="Thêm bình luận..."
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              suffix={
                <Tooltip>
                  <Dropdown
                    placement="bottom"
                    trigger="click"
                    overlay={<MenuIcon comments={comments} setComments={setComments} />}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Tooltip placement="top" title="Biểu tượng cảm xúc">
                        <SmileOutlined className={styles['icon-cmt']} />
                      </Tooltip>
                    </a>
                  </Dropdown>
                  {image.length == 0 && (
                    <>
                      {stream ? (
                        <Tooltip placement="top" title="Chụp ảnh">
                          <CameraOutlined className={styles['icon-cmt']} onClick={handleCapture} />
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip placement="top" title="Bật máy ảnh">
                            <CameraOutlined onClick={handleStream} className={styles['icon-cmt']} />
                          </Tooltip>
                          <Popover content={<MenuGif />} title="" trigger="click">
                            <Tooltip placement="top" title="file gif">
                              <FileGifOutlined className={styles['icon-cmt']} />
                            </Tooltip>
                          </Popover>
                          <label style={{ cursor: 'pointer' }}>
                            <Tooltip placement="top" title="Ảnh từ thiết bị">
                              <FileImageOutlined className={styles['icon-cmt']} />
                            </Tooltip>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept="image/*,video/*"
                              onChange={handleChangeImages}
                              style={{ display: 'none' }}
                            />
                          </label>
                        </>
                      )}
                    </>
                  )}
                </Tooltip>
              }
            />
            <div>
              <Button onClick={handleSubmitComment}>Đăng</Button>
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col>
          {stream && (
            <div style={{ marginTop: '5px' }}>
              <Badge
                count={
                  <CloseCircleTwoTone
                    onClick={handleStopStream}
                    twoToneColor="red"
                    style={{ fontSize: 24 }}
                  />
                }
              >
                <div className="stream position-relative">
                  <video
                    style={{
                      marginLeft: '70px',
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: 10,
                      boxShadow: '1px 1px 5px grey',
                    }}
                    autoPlay
                    muted
                    ref={videoRef}
                    width="100%"
                    height="100%"
                  />
                  <canvas ref={refCanvas} style={{ display: 'none' }} />
                </div>
              </Badge>
            </div>
          )}
        </Col>
        <Col>
          {image.length > 0 ? (
            <div style={{ marginTop: '5px' }}>
              <Badge
                count={
                  <CloseCircleTwoTone
                    onClick={deleteImages}
                    twoToneColor="red"
                    style={{ fontSize: 24 }}
                  />
                }
              >
                <div id="file_img">
                  {image[0].camera ? (
                    imageShow(image[0].camera)
                  ) : image[0].url ? (
                    <>
                      {image[0].url.match(/video/i)
                        ? videoShow(image[0].url)
                        : imageShow(image[0].url)}
                    </>
                  ) : (
                    <>
                      {image[0].type.match(/video/i)
                        ? videoShow(URL.createObjectURL(image[0]))
                        : imageShow(URL.createObjectURL(image[0]))}
                    </>
                  )}
                </div>
              </Badge>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Comment
