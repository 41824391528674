import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styles from './schedule-staff.module.scss'
import { sortBy } from 'lodash'
import Slider from 'react-slick'
import { useHistory } from 'react-router-dom'
import { ACTION, PERMISSIONS, ROUTES } from 'consts'

import { Select, notification, Modal, Col, Row, Tag, Table, Space, Popconfirm, List } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

import {
  getOneSchedule,
  deleteShiftForRegister,
  addStaffToShift,
  createShiftForRegister,
  deleteStaffToShift,
  getShiftForRegister,
} from 'apis/schedule-staff'

//component
import styled from 'styled-components'
import HeaderSchedule from 'components/Schedule/HeaderSchedule'
import TimeSchedule from 'components/Schedule/TimeSchedule'
import { Button } from 'components/Button/Button'
import RecycleIcon from 'assets/icons/recycle-schedule.svg'
import { useDispatch, useSelector } from 'react-redux'
import Permission from 'components/permission'

const TableSyled = styled(Table)`
  .ant-table-thead .ant-table-cell {
    padding: 0;
  }
  .ant-table-row > .ant-table-cell:last-child,
  .ant-table-thead .ant-table-cell:last-child {
    border-right: 0;
  }

  .ant-table-row:last-child > .ant-table-cell {
    padding-bottom: 40px;
  }

  .ant-table-row .ant-table-cell {
    padding: 6px;
    border-top: none;
    width: 190px;
    border-right: 1px solid #dfdfdf;
    border-bottom: none;
  }
  .ant-table-thead .ant-table-cell:last-child > .table__header {
    border-right: 0;
  }
`

const ShopTableStyled = styled(Table)`
  .ant-table-thead .ant-table-cell {
    height: 63px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;

    &:nth-child(1),
    &:nth-child(3) {
      background: #1e4db7;
    }

    &:nth-child(1) {
      border-radius: 12px 0px 0px 0px;
    }

    &:nth-child(2) {
      background: #3b6cdc;
      padding: 14px 17px;
    }

    &:nth-child(3) {
      padding: 14px 15px;
    }

    &:nth-child(n + 4) {
      background: #fff;
      padding: ;
    }
  }
  .ant-table-row > .ant-table-cell:last-child,
  .ant-table-thead .ant-table-cell:last-child {
    border-right: 0;
  }
`
const SliderStyled = styled(Slider)`
  .slick-dots {
    bottom: -15px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-arrow {
    transform: translate(0, -100%);
  }
  .slick-next::before,
  .slick-prev::before {
    background: #fff;
    color: #b0a4a4ed;
  }
  .slick-prev {
    left: -30px;
  }
  .slick-next {
    right: -30px;
  }
`

const ScheduleWeek = (props) => {
  const {
    staffShifts,
    shifts,
    employee,
    id,
    roles,
    dateEnd,
    dateStart,
    _getArrayStaffRegister,
    _deleteShiftAllday,
    staffWithShift,
    _getSchedule,
    schedule,
  } = props
  const [dayWeek, setDayWeek] = useState([])
  const [staffDataShift, setStaffDataShift] = useState([])
  let history = useHistory()
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  // const [schedule, setSchedule] = useState({})
  const [shiftForRegister, setShiftForRegister] = useState([])
  const [shiftSchedule, setShiftSchedule] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [visible, setVisible] = useState()
  const [visibleShifts, setVisibleShifts] = useState(false)
  const [predate, setPredate] = useState()
  const [dayTmp, setDayTmp] = useState('hai')
  const [headers, setHeaders] = useState([])
  const [tableData, setTableData] = useState([])
  const [shiftDetail, setShiftDetail] = useState()

  const [columnShopTable, setColumnShopTable] = useState([])
  const [shopTableData, setShopTableData] = useState([])
  const showModal = (cell, cal, day) => {
    setIsModalVisible(true)
    setPredate(cal)
    setDayTmp(day)
    setShiftDetail(cell)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const showModalAddShift = () => {
    setVisible(true)
  }
  const handleCancelShift = () => {
    setVisible(false)
  }
  const handleShowShifts = (cal, day) => {
    setVisibleShifts(true)
    setPredate(cal)
    setDayTmp(day)
  }
  const handleCancelShifts = () => {
    setVisibleShifts(false)
  }

  const getDateNow = () => {
    const date = new Date()

    return date.getDate() + '/' + (date.getMonth() + 1)
  }
  const getTheadStyles = (isDateNow) => {
    const normal = {
      header: {
        padding: '12px 0',
        borderBottom: '1px solid #dfdfdf',
        cursor: 'pointer',
      },
      date: {
        color: '#91919f',
        fontWeight: 400,
      },
    }

    const styles = {
      header: {
        padding: '12px 0 8px 0',
        borderBottom: '4px solid #0BB2FB',
        cursor: 'pointer',
      },

      date: {
        fontWeight: 700,
        color: '#0BB2FB',
      },
    }

    return isDateNow ? styles : normal
  }

  const getCellStyles = (isDateNow, index) => {
    if (!isDateNow) return null
    switch (index) {
      case 0:
        return {
          slot: {
            background: '#D7F3FF',
          },
          missing: {
            background: '#5DE49E',
          },
        }
      case 1:
        return {
          slot: {
            background: '#FFDDBE',
          },
          missing: {
            background: '#FD5662',
            color: '#fff',
          },
        }
      default:
        return {
          slot: {
            background: '#DDE0FF',
          },
          missing: {
            color: '#fff',
            background: '#1E4DB7',
          },
        }
    }
  }

  const getColumns = (dayList) => {
    return dayList.map((item) => {
      const date = moment(item.day).format('DD/M')

      const isDateNow = getDateNow() === date

      const theadStyles = getTheadStyles(isDateNow)
      return {
        key: item.day,
        dataIndex: item.day,
        title: () => (
          <div
            key={item.day}
            className={styles.table__header}
            style={theadStyles.header}
            onClick={() => {
              handleShowShifts(item.day, item.dayTh)
            }}
          >
            <h2 className={styles.table__day}>{item.dayTh}</h2>

            <span className={styles.table__date} style={theadStyles.date}>
              {date}
            </span>
          </div>
        ),

        render: (_, record) => {
          let result

          const found = Object.values(record).some((recordValue) => {
            return Object.keys(recordValue).some((key) => {
              if (key !== item.day) return false

              result = { cell: recordValue[key], index: recordValue.slotIndex }
              return true
            })
          })

          if (!found) return null

          const { cell, index } = result
          const cellStyles = getCellStyles(isDateNow, cell?.missing)
          const timeRange =
            cell?.time_start || cell?.time_end
              ? `${moment(cell?.time_start).format('HH:mm')} - ${moment(cell?.time_end).format(
                  'HH:mm'
                )}`
              : '-'
          return (
            <div key={item.day} className={styles.table__cell}>
              <div
                className={styles.table__info}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (timeRange !== '-') showModal(cell, item.day, item.dayTh)
                }}
              >
                <Space className={styles.table__slot} style={cellStyles && cellStyles.slot}>
                  <div>
                    <span className={styles.table__time}>{timeRange}</span>
                    <span className={cell?.title ? styles.table__title : null}>{cell?.title}</span>
                  </div>
                  {cell?.missing === 0 ? null : (
                    <span
                      className={styles.table__missing}
                      style={cellStyles && cellStyles.missing}
                    >
                      {cell?.missing}
                    </span>
                  )}
                </Space>

                <div
                  className={
                    cell?.staffs.length
                      ? `${styles['table__staff-box']} ${
                          cell?.staffs.length <= 2 && styles['table__staff-box-deco']
                        }`
                      : styles['table__staff-box-empty']
                  }
                >
                  {cell?.staffs.length ? (
                    <ul className={styles.table__staffs}>
                      {cell?.staffs.map((staff) => {
                        if (!staff) return null
                        return (
                          <li
                            key={staff._id}
                            className={styles.table__staff}
                            style={{
                              color:
                                moment(staff.time_register_start).format('HH') ==
                                  moment(cell?.time_start).format('HH') &&
                                moment(staff.time_register_end).format('HH') ==
                                  moment(cell?.time_end).format('HH')
                                  ? '#1e4db7'
                                  : 'red',
                            }}
                          >
                            {Object(staff.first_name).toString().charAt()}.{staff.last_name}
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <span className={styles['table__staff-empty']}>Chưa có nhân viên</span>
                  )}
                </div>
              </div>
            </div>
          )
        },
      }
    })
  }

  const getDataSource = (dayWeek) => {
    const schedule = {
      one: [],
      two: [],
      three: [],
      four: [],
      five: [],
    }

    dayWeek.forEach((item) => {
      Array.isArray(item.Shifts) &&
        item.Shifts.forEach((shift, index) => {
          const slot = { [item.day]: shift, slotIndex: index }
          switch (index) {
            case 0:
              schedule.one.push(slot)
              break
            case 1:
              schedule.two.push(slot)
              break
            case 2:
              schedule.three.push(slot)
              break
            case 3:
              schedule.four.push(slot)
              break
            default:
              schedule.five.push(slot)
              break
          }
        })
    })

    return Object.values(schedule).map((value) => ({ ...value }))
  }

  const getShopData = (staffDataShift) => {
    if (!staffDataShift.length) return null

    return staffDataShift.map((shift) => ({
      name: `${shift.first_name} ${shift.last_name}`,
      register: shift.shiftRegister,
      choose: shift.shiftChoose,
      ...shift.all_day,
    }))
  }

  const getShopTableSlotCellStyles = (isDateNow, index) => {
    if (!isDateNow) return null

    index += 1

    if (index % 3 === 0) return { background: '#1E4DB7' }

    if ((index - 2) % 3 === 0) return { background: '#0BB2FB' }

    return null
  }

  const getTableShopHeaderValue = (key, object) => {
    switch (key) {
      case 'name':
        return 'Họ Tên'
      case 'choose':
        return 'Số ca sắp xếp'
      case 'register':
        return 'Số ca đăng ký'
      default:
        return object[key]
    }
  }

  const getTableShopHeader = (key, object) => {
    switch (key) {
      case 'name':
        return {
          text: 'Họ Tên',
          classCell: 'tbShop__cell-name',
          classHeader: 'tbShop__header-name',
        }
      case 'choose':
        return {
          text: 'Số ca sắp xếp',
          classCell: 'tbShop__cell-choose',
          classHeader: 'tbShop__header-choose',
        }
      case 'register':
        return {
          text: 'Số ca đăng ký',
          classCell: 'tbShop__cell-register',
          classHeader: 'tbShop__header-register',
        }
      default:
        return {
          ...object[key],
          classCell: 'tbShop__cell-slot',
          classHeader: 'tbshop__header-slot',
        }
    }
  }

  const isObjectType = (value) => Object.prototype.toString.call(value) === '[object Object]'

  const getColumnShopTable = (shopData) => {
    if (!shopData) return null

    return Object.keys(shopData).map((key) => {
      const header = getTableShopHeaderValue(key, shopData)
      const headerTest = getTableShopHeader(key, shopData)

      const date = moment(header.day).format('DD/M')

      return {
        key,
        dataIndex: key,
        align: 'center',
        className: styles[headerTest.classCell],
        title: () => {
          if (headerTest.text) return <span key={Math.random()}>{headerTest.text}</span>

          return (
            <div key={Math.random()}>
              <h2 className={styles.tbShop__day}>{header.dayTh}</h2>

              <span className={styles.tbShop__date}>{date}</span>
            </div>
          )
        },

        render: (_, record, index) => {
          const isDateNow = getDateNow() === date

          const cellStylesDateNow = getShopTableSlotCellStyles(isDateNow, index)

          const value = record[key]

          return (
            <div key={Math.random()}>
              {isObjectType(value) ? (
                value?.shifts?.length ? (
                  <ul className={styles.tbShop__slots}>
                    {value.shifts.map((shift) =>
                      shift.title ? (
                        <li
                          className={styles.tbShop__slot}
                          style={{
                            cellStylesDateNow,
                            background: _setBackgroundStatus(shift.status),
                          }}
                        >
                          <p style={{ color: '#fff042', margin: 0 }}>{shift.title}</p>
                          {moment(shift.time_register_start).format('HH:00') ===
                            moment(shift.time_start).format('HH:00') &&
                          moment(shift.time_register_end).format('HH:00') ===
                            moment(shift.time_end).format('HH:00') ? null : (
                            <p>
                              ({moment(shift.time_register_start).format('HH:mm')} -{' '}
                              {moment(shift.time_register_end).format('HH:mm')})
                            </p>
                          )}
                        </li>
                      ) : null
                    )}
                  </ul>
                ) : null
              ) : (
                value
              )}
            </div>
          )
        },
      }
    })
  }

  const _getShiftForRegister = async (id, day) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getShiftForRegister(id, day)
      if (res.status === 200) {
        setShiftForRegister(res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _createShiftForRegister = async (id, shift_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let body = {
        day: predate,
        shift_id: shift_id,
        branch_id: branchIdApp,
      }
      const res = await createShiftForRegister(id, body)
      if (res.status === 200) {
        notification.success({ message: 'Thêm ca làm thành công' })
        _getSchedule(id)
      } else
        notification.warning({
          message: res.data.message || 'Thêm ca làm thất bại, vui lòng thử lại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const dataTest = async () => {
    try {
      if (schedule && schedule.all_day) {
        for (let i = 0; i < schedule.all_day.length; i++) {
          if (schedule.all_day[i].day == predate) {
            if (!schedule.all_day[i].shifts) {
              setShiftSchedule([])
            } else setShiftSchedule(schedule.all_day[i].shifts)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _copyShiftForAllDay = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let res
      dataTest()
      for (let i = 0; i < schedule.all_day.length; i++) {
        if (schedule.all_day[i] != predate) {
          for (let j = 0; j < shiftSchedule.length; j++) {
            let body = {
              day: schedule.all_day[i].day,
              shift_id: shiftSchedule[j].shift_id,
              branch_id: branchIdApp,
            }
            res = await createShiftForRegister(id, body)
          }
        }
      }
      if (res.status === 200) {
        notification.success({ message: 'Thêm ca làm thành công' })
        _getSchedule(id)
      } else notification.warning({ message: 'Thêm ca làm thất bại, vui lòng thử lại' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _deleteShiftForRegister = async (id, shift_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let body = {
        schedule_id: schedule.schedule_id,
        day: predate,
        shift_id: shift_id,
      }
      const res = await deleteShiftForRegister(body, id)
      if (res.status === 200) {
        notification.success({ message: res.data.message || 'Xóa ca thành công' })
        _getSchedule(id)
      } else
        notification.error({
          message: res.data.message || 'Xóa ca thất bại, vui lòng thử lại!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _addStaffToShift = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      let body = {
        staff_shift_id: value,
      }
      const res = await addStaffToShift(id, body)
      if (res.status === 200) {
        let index = staffShifts.findIndex((item) => item.staff_shift_id === value)
        staffShifts[index].status = 1
        notification.success({ message: res.data.message || 'Thêm nhân viên vào ca thành công' })
        _getSchedule(id)
      } else
        notification.warning({
          message: res.data.message || 'Thêm nhân viên vào ca thất bại, vui lòng thử lại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _deleteStaffToShift = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      let body = {
        staff_shift_id: value,
      }
      const res = await deleteStaffToShift(id, body)
      if (res.status === 200) {
        let index = staffShifts.findIndex((item) => item.staff_shift_id === value)
        staffShifts[index].status = 0
        notification.success({ message: 'Xóa nhân viên khỏi ca thành công' })
        _getSchedule(id)
      } else
        notification.warning({
          message: res.data.message || 'Xóa nhân viên khỏi ca thất bại, vui lòng thử lại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getDaysOfWeekBetweenDates = (sDate = schedule.start_range, eDate = schedule.end_range) => {
    const startDate = moment(sDate)
    const endDate = moment(eDate)

    endDate.add(1, 'day')

    const daysOfWeek = []

    let i = 0

    const dayNames = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy']

    while (i < endDate && startDate < endDate) {
      daysOfWeek.push({ dayTh: dayNames[startDate.day()], day: startDate.format('YYYY-MM-DD') })
      startDate.add(1, 'day')
      i++
    }
    daysOfWeek && setDayWeek(daysOfWeek)
  }
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props

    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }

    let empInfo = staffShifts.find((item) => item.staff_shift_id == value)
    let shiftInfo = shifts.find((item) => item.shift_id == empInfo.shift_id)
    const fullName = (
      <span>
        <span>{empInfo.userInfo.first_name + ' ' + empInfo.userInfo.last_name}</span>
        {empInfo.time_register_start == shiftInfo.time_start &&
        empInfo.time_register_end == shiftInfo.time_end ? null : (
          <span style={{ marginLeft: '5px' }}>
            ({moment(empInfo.time_register_start).format('HH:mm')} -{' '}
            {moment(empInfo.time_register_end).format('HH:mm')})
          </span>
        )}
      </span>
    )

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {fullName}
      </Tag>
    )
  }

  useEffect(() => {
    _getDaysOfWeekBetweenDates()
  }, [schedule])

  const getStaffWithDataShift = () => {
    dispatch({ type: 'LOADING', data: true })
    let staffs = []
    let new_arr = []
    staffWithShift.forEach((item) =>
      item.staffs.reduce((arr, current) => {
        if (current.shiftStaffRegistered.length > 0) {
          new_arr.push(current.staff_id)
        }
        return arr
      }, [])
    )
    new_arr = [...new Set(new_arr)]
    let arrTmp = employee.filter((e) => new_arr.includes(e.user_id))
    for (let i = 0; i < arrTmp.length; i++) {
      staffs[i] = arrTmp[i]
    }

    for (const dayIndex in dayWeek) {
      for (let staffInfex in staffs) {
        let staff = staffs[staffInfex]
        let { user_id } = staff
        let { day, dayTh } = dayWeek[dayIndex]

        if (typeof staff.all_day == 'undefined') {
          staff.all_day = {}
        }

        const staffShiftByDayAndUserId = staffShifts.filter(
          (item) => item.day == day && item.staff_id == user_id
        )
        const shiftRegister = staffShifts.filter((item) => item.staff_id == user_id).length
        const shiftChoose = staffShifts.filter(
          (item) => item.staff_id == user_id && item.status == 1 && item.branch_id == branchIdApp
        ).length
        staff.shiftRegister = shiftRegister
        staff.shiftChoose = shiftChoose
        staff.all_day[day] = {
          day,
          dayTh,
          shifts: staffShiftByDayAndUserId.map((item) => {
            const shiftInfo = shifts.find((item2) => item.shift_id == item2.shift_id)
            const status = item.status
            const time_register_start = item.time_register_start
            const time_register_end = item.time_register_end
            let shiftAddStatus = { ...shiftInfo, status, time_register_start, time_register_end }
            return shiftAddStatus
          }),
        }

        staffs[staffInfex] = staff
      }
    }
    const shopData = getShopData(staffs)
    if (shopData) {
      setColumnShopTable(getColumnShopTable(shopData[0]))
      setShopTableData(shopData)
    }
    setStaffDataShift(staffs)
    dispatch({ type: 'LOADING', data: false })
  }
  const getStaffWithDay = () => {
    for (const dayIndex in dayWeek) {
      let { day, dayTh } = dayWeek[dayIndex]

      const ShiftByDay = shiftForRegister.filter((item) => item.day == day)

      dayWeek[dayIndex] = {
        day,
        dayTh,
        Shifts: ShiftByDay.map((item) => {
          const shiftInfo = shifts.find((item2) => item.shift_id == item2.shift_id)
          const staffShiftByDayAndUserId = staffShifts.filter(
            (item3) => item3.day == day && item.shift_id == item3.shift_id && item3.status == 1
          )
          let staff = staffShiftByDayAndUserId.map((item) => {
            const staffInfo = employee.find((item2) => item.staff_id == item2.user_id)
            let staffNew = {
              ...staffInfo,
              time_register_start: item.time_register_start,
              time_register_end: item.time_register_end,
            }
            return staffNew
          })
          let tmp = 0
          staffShiftByDayAndUserId.map((e) => {
            if (e.checkAmount > tmp) {
              tmp = e.checkAmount
            }
          })
          let amountNeedTmp = 0
          let missing = 0
          if (shiftInfo) {
            for (let i = 0; i < shiftInfo.role.length; i++) {
              amountNeedTmp = amountNeedTmp + shiftInfo.role[i].total
            }
            missing = amountNeedTmp - tmp
          }
          let shiftAndStaff = { ...shiftInfo, staffs: staff, missing: missing }

          return shiftAndStaff
        }),
      }
      dayWeek[dayIndex].Shifts = sortBy(dayWeek[dayIndex].Shifts, [
        (o) => {
          return o.time_start
        },
      ])
    }
    setHeaders(getColumns(dayWeek))
    setTableData(getDataSource(dayWeek))
  }

  const _setBackgroundStatus = (e) => {
    if (e === 0) return '#a8a2a2'
    if (e === 1) return '#0089DF'
  }

  const background = (item) => {
    let arr = [...shiftSchedule]
    if (arr.findIndex((e) => e.shift_id === item.shift_id) >= 0) {
      return {
        color: '#52C41A',
        background: '#F6FFED',
        borderColor: '#B7EB8F',
        height: '150px',
        display: 'flex',
        cursor: 'pointer',
      }
    } else {
      return {
        height: '150px',
        color: '#5F73E2',
        background: '#F0F5FF',
        borderColor: '#E6ECFF',
        display: 'flex',
        cursor: 'pointer',
      }
    }
  }
  useEffect(() => {
    getStaffWithDataShift()
    getStaffWithDay()
  }, [staffShifts, shifts, employee, shiftForRegister, schedule, staffWithShift])

  useEffect(() => {
    _getShiftForRegister(id, predate)
    dataTest()
  }, [schedule, predate])

  return (
    <div className={styles.card__detail}>
      <div>
        <Space style={{ justifyContent: 'space-between', marginBottom: 40 }}>
          <ArrowLeftOutlined
            style={{ marginTop: '10px', position: 'absolute', fontSize: '30px' }}
            onClick={() => history.push(ROUTES.SCHEDULE_STAFF)}
          />
          <h2
            style={{ margin: '0px 30px', position: 'absolute', fontSize: '30px', fontWeight: 700 }}
          >
            Lịch làm việc {`${schedule.title}`}
          </h2>
        </Space>

        <HeaderSchedule
          title="Lịch đã xếp cho nhân viên"
          subTitle="Thêm ca làm việc vào lịch để nhân viên có thể đăng ký. Ở từng ca hiển thị số lượng nhân viên còn thiếu trong ca."
          classes={styles['schedule__layout-header']}
        >
          <TimeSchedule
            dateEnd={dateEnd}
            dateStart={dateStart}
            _getArrayStaffRegister={_getArrayStaffRegister}
            _deleteShiftAllday={_deleteShiftAllday}
          />
        </HeaderSchedule>

        <div className={styles.schedule__layout}>
          <TableSyled columns={headers} dataSource={tableData} pagination={false} />
        </div>
      </div>

      {shopTableData.length === 0 ? null : (
        <div style={{ marginTop: '50px' }}>
          <HeaderSchedule
            title="Lịch làm việc theo nhân viên cửa hàng"
            subTitle="Ca làm việc được nhân viên đăng ký có màu xám và ca làm việc được xếp có màu xanh."
            classes={styles['schedule__layout-header']}
          />
          <div className={styles.schedule__layout}>
            <ShopTableStyled
              columns={columnShopTable}
              dataSource={shopTableData}
              pagination={false}
            />
          </div>
        </div>
      )}
      <Modal
        width="50%"
        title={`Thông tin ca ${shiftDetail && shiftDetail.title} ${dayTmp} ${moment(predate).format(
          'DD/MM/yyyy'
        )}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {shiftDetail && (
          <div>
            <div className={styles.btnShift} style={{ marginTop: '25px' }}>
              <div className={styles['btnShiftName']} style={{ marginBottom: '10px' }}>
                <h1 className={styles.btnShift__title}>{shiftDetail.title.toUpperCase()}</h1>
                <h1 className={styles.btnShift__time}>
                  {moment(shiftDetail.time_start).format('HH:mm')} -{' '}
                  {moment(shiftDetail.time_end).format('HH:mm')}
                </h1>
              </div>
              <div>
                <SliderStyled dots={true}>
                  {shiftDetail.role &&
                    shiftDetail.role.map((_role) =>
                      roles.map(
                        (_roles) =>
                          _roles.role_id === _role.role_id && (
                            <div>
                              <div style={{ position: 'relative', padding: '10px 0' }}>
                                <ul style={{ paddingLeft: 20 }} className={styles.btnShift__items}>
                                  <li>
                                    Chức vụ:{' '}
                                    <span style={{ width: 110, marginRight: 20 }}>
                                      {_roles.name}
                                    </span>
                                  </li>
                                  <li>
                                    Số lượng: <span>{_role.total}</span>
                                  </li>
                                  <li>
                                    <div className={styles.btnShift__control}>
                                      <span style={{ width: 110 }}>Chọn nhân viên:</span>
                                      <Select
                                        mode="multiple"
                                        placeholder="Chọn nhân viên vào ca"
                                        tagRender={tagRender}
                                        value={
                                          staffShifts &&
                                          staffShifts
                                            .filter(
                                              (_staff) =>
                                                _staff?.staff_shift_id &&
                                                _staff?.ShiftForRegisters?.day == predate &&
                                                _staff?.ShiftForRegisters?.shift_id ==
                                                  shiftDetail?.shift_id &&
                                                _staff?.status == 1 &&
                                                _staff?.userInfo.role_id == _role?.role_id
                                            )
                                            .map((item) => item?.staff_shift_id)
                                        }
                                        onSelect={_addStaffToShift}
                                        onDeselect={_deleteStaffToShift}
                                        options={
                                          staffShifts &&
                                          staffShifts
                                            .filter(
                                              (_staff) =>
                                                _staff?.ShiftForRegisters?.day == predate &&
                                                _staff?.ShiftForRegisters?.shift_id ==
                                                  shiftDetail?.shift_id &&
                                                _staff?.status == 0 &&
                                                _role?.role_id == _staff?.userInfo?.role_id
                                            )
                                            .map((item) => ({
                                              label:
                                                item?.userInfo?.first_name +
                                                ' ' +
                                                item?.userInfo?.last_name +
                                                ' (' +
                                                `${moment(item.time_register_start).format(
                                                  'HH:mm'
                                                )}` +
                                                '-' +
                                                `${moment(item.time_register_end).format(
                                                  'HH:mm'
                                                )})`,
                                              value: item?.staff_shift_id,
                                            }))
                                        }
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )
                      )
                    )}
                </SliderStyled>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        width="50%"
        title={`Thông tin ca làm việc ${dayTmp} ${moment(predate).format('DD/MM/yyyy')}`}
        visible={visibleShifts}
        onCancel={handleCancelShifts}
        footer={null}
      >
        <div style={{ height: '600px', overflow: 'scroll' }}>
          {shiftSchedule &&
            shiftSchedule.map((_shifts) => (
              <div className={styles.btnShift} style={{ marginTop: '25px' }}>
                <div className={styles['btnShiftName']} style={{ marginBottom: '10px' }}>
                  <h1 className={styles.btnShift__title}>{_shifts.title.toUpperCase()}</h1>
                  <h1 className={styles.btnShift__time}>
                    {moment(_shifts.time_start).format('HH:mm')} -{' '}
                    {moment(_shifts.time_end).format('HH:mm')}
                  </h1>
                </div>
                <div>
                  <SliderStyled dots={true}>
                    {_shifts.role &&
                      _shifts.role.map((_role) =>
                        roles.map(
                          (_roles) =>
                            _roles.role_id === _role.role_id && (
                              <div>
                                <div style={{ position: 'relative', padding: '10px 0' }}>
                                  <Permission permissions={[PERMISSIONS.xoa_lich_lam_viec]}>
                                    <Popconfirm
                                      title="Bạn có muốn xóa ca này không?"
                                      cancelText="Từ chối"
                                      okText="Đồng ý"
                                      onConfirm={() =>
                                        _deleteShiftForRegister(id, _shifts.shift_id)
                                      }
                                    >
                                      <Button
                                        type="button"
                                        icon={() => <img src={RecycleIcon} alt="" />}
                                        style={{
                                          position: 'absolute',
                                          padding: 0,
                                          width: 'auto',
                                          top: 10,
                                          right: 5,
                                        }}
                                      />
                                    </Popconfirm>
                                  </Permission>
                                  <ul
                                    style={{ paddingLeft: 20 }}
                                    className={styles.btnShift__items}
                                  >
                                    <li>
                                      Chức vụ:{' '}
                                      <span style={{ width: 110, marginRight: 20 }}>
                                        {_roles.name}
                                      </span>
                                    </li>
                                    <li>
                                      Số lượng: <span>{_role.total}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                        )
                      )}
                  </SliderStyled>
                </div>
              </div>
            ))}
        </div>

        <Row style={{ marginTop: 30 }}>
          <Button
            type="button"
            classes="btn-primary"
            text="Sao chép cho tất cả ngày"
            onClick={_copyShiftForAllDay}
            style={{ width: 'auto' }}
          />
          <Button
            type="button"
            text="Thêm ca"
            classes="btn__blue-fill"
            onClick={showModalAddShift}
            style={{ marginLeft: 'auto', width: 'auto' }}
          />
        </Row>
      </Modal>
      <Modal
        onCancel={handleCancelShift}
        width={'33%'}
        footer={null}
        title="Thêm ca"
        visible={visible}
      >
        <List
          style={{ overflow: 'auto', height: '600px' }}
          itemLayout="horizontal"
          dataSource={shifts}
          renderItem={(item, index) => (
            <List.Item>
              <div
                style={background(item)}
                className={styles['btnShift']}
                onClick={() => {
                  _createShiftForRegister(id, item.shift_id)
                }}
              >
                <div>
                  <h2>{item.title}</h2>
                  <h3>
                    {moment(item.time_start).format('HH:mm')} đến{' '}
                    {moment(item.time_end).format('HH:mm')}
                  </h3>
                </div>
                <div style={{ width: '15em', overflow: 'auto', height: '9em' }}>
                  {item.role &&
                    item.role.map((_role) =>
                      roles.map(
                        (roles) =>
                          roles.role_id === _role.role_id && (
                            <>
                              <h3 style={{ float: 'left' }}>{roles.name}:</h3>
                              <h3 style={{ marginLeft: 105 }}> {_role.total}</h3>
                            </>
                          )
                      )
                    )}
                </div>
              </div>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  )
}

export default ScheduleWeek
