import React, { useState, useEffect, useRef } from 'react'
import { ACTION, FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE, PERMISSIONS } from 'consts'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { compare } from 'utils'
import ImportCSV from 'components/ImportCSV'
import { formatCash, tableSum } from 'utils'
import { useTranslation } from 'react-i18next'
//antd
import {
  Select,
  Input,
  Button,
  Table,
  Row,
  Col,
  Space,
  Popconfirm,
  notification,
  Affix,
} from 'antd'

//icons
import {
  DeleteOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons'

//components
import TitlePage from 'components/title-page'
import SupplierForm from './supplier-form'
import columnsSupplier from './columns'
import FilterDate from 'components/filter-date'

//apis
import { getEmployees } from 'apis/employee'
import { getProvinces, getDistricts } from 'apis/address'
import { getSuppliers, deleteSupplier } from 'apis/supplier'
import FilterProvinceDistrict from 'components/filter-district-province'
import { useDispatch } from 'react-redux'
import exportToCSV from 'components/ExportCSV/export'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

const { Option } = Select
export default function Supplier() {
  const typingTimeoutRef = useRef(null)
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const { t } = useTranslation()

  const [countSupplier, setCountSupplier] = useState(0)
  const [suppliers, setSuppliers] = useState([])
  const [users, setUsers] = useState([])
  const [districts, setDistricts] = useState([])
  const [provinces, setProvinces] = useState([])
  const [valueTime, setValueTime] = useState()

  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
  })
  const [selectedItems, setSelectedItems] = useState([])
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const onSearch = (e) => {
    const value = e.target.value

    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _deleteSupplier = async (id) => {
    try {
      setLoading(true)
      const res = await deleteSupplier(id)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa nhà cung cấp thành công!' })
          _getSuppliers()
        } else
          notification.error({
            message: res.data.message || 'Xóa nhà cung cấp thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa nhà cung cấp thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getSuppliers = async () => {
    try {
      setLoading(true)
      const res = await getSuppliers({ ...paramsFilter, _creator: true })
      if (res.status === 200) {
        setSuppliers(res.data.data)
        setCountSupplier(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _getSupplierToExport = async () => {
    let dataExport = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let new_params_filter = { ...paramsFilter }
      delete new_params_filter.page
      delete new_params_filter.page_size
      const res = await getSuppliers({ ...new_params_filter, creator_info: true })
      if (res.status === 200) {
        dataExport = res.data.data.map((item, index) => ({
          STT: index + 1,
          'Mã nhà cung cấp': item.code || '',
          'Tên nhà cung cấp ': item.name || '',
          Email: item.email || '',
          'Ngày tạo': item.create_date || '',
          'Liên hệ': item.phone || '',
          'Địa chỉ': item.address || '',
          // 'Người tạo': item._creator.first_name + item._creator.last_name || '',
        }))
      }
      exportToCSV(dataExport, 'Danh sách nhà cung cấp','Xuất file excel',dispatch)
    } catch (e) {
      console.log(e)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onClickClear = () => {
    setValueSearch('')
    setSelectedItems([])
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
    setValueTime()
  }

  const _getDistricts = async () => {
    try {
      setLoading(true)
      const res = await getDistricts()
      if (res.status === 200) setDistricts(res.data.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _getProvinces = async () => {
    try {
      setLoading(true)
      const res = await getProvinces()
      if (res.status === 200) setProvinces(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _getUsers = async () => {
    try {
      const res = await getEmployees()
      if (res.status === 200) setUsers(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (paramsFilter.check) _getSuppliers()
  }, [paramsFilter])

  useEffect(() => {
    _getProvinces()
    _getDistricts()
    _getUsers()
  }, [])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueSearch('')
    if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
      setParamsFilter({ page: 1, page_size: PAGE_SIZE, check: 1 })
      setValueTime()
    } else {
      setParamsFilter({
        page: 1,
        page_size: PAGE_SIZE,
        from_date: result.from_date,
        to_date: result.to_date,
        check: 1,
      })
      setValueTime(result.valueDate)
    }
  }, [dataUser])
  const _onFilter = async (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]

    paramsFilter.page = 1
    setParamsFilter({ ...paramsFilter })
  }

  return (
    <div className="card">
        <TitlePage title="Quản lý nhà cung cấp" isAffix>
          <Space>
            <Col style={{ display: Object.keys(paramsFilter).length < 3 && 'none' }}>
              <Button onClick={onClickClear} type="danger">
                Xóa tất cả lọc
              </Button>
            </Col>
            {permissions.includes(PERMISSIONS.export_nha_cung_cap) ? (
              <Button
                id="exportFile"
                onClick={_getSupplierToExport}
                icon={<VerticalAlignTopOutlined />}
                style={{ backgroundColor: 'green', borderColor: 'green' }}
                type="primary"
                size="large"
              >
                Xuất File
              </Button>
            ) : (
              <Button disabled icon={<VerticalAlignTopOutlined />} type="primary" size="large">
                Xuất File
              </Button>
            )}
            {permissions.includes(PERMISSIONS.them_nha_cung_cap) ? (
              <ImportCSV
                size="large"
                txt="Nhập File"
                //upload={importCustomers}
                title="Tạo/cập nhật nhà cung cấp hàng loạt"
                fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/16/d5f3fe01-765d-40d0-9d8b-62ffaf61e057/CustomerImport.xlsx"
                //reload={_getCustomers}
              />
            ) : (
              <Button
                disabled
                onClick={_getSupplierToExport}
                icon={<DownloadOutlined />}
                type="primary"
                size="large"
              >
                Nhập File
              </Button>
            )}

            {permissions.includes(PERMISSIONS.them_nha_cung_cap) ? (
              <SupplierForm reloadData={_getSuppliers}>
                <Button size="large" type="primary" icon={<PlusCircleOutlined />}>
                  Thêm nhà cung cấp
                </Button>
              </SupplierForm>
            ) : (
              <Button disabled size="large" type="primary" icon={<PlusCircleOutlined />}>
                Thêm nhà cung cấp
              </Button>
            )}
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_nha_cung_cap) ? (
        <>
          <Row style={{ marginTop: '1rem', border: '1px solid #d9d9d9', borderRadius: 5,backgroundColor: 'white' }}>
            <Col xs={24} sm={24} md={8} lg={8} xl={6} style={{ height: FILTER_COL_HEIGHT }}>
              <Input
                value={valueSearch}
                size={FILTER_SIZE}
                enterButton
                onChange={onSearch}
                placeholder="Tìm kiếm theo mã, theo tên"
                allowClear
                bordered={false}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={6}
              style={{
                height: FILTER_COL_HEIGHT,
                borderLeft: '1px solid #d9d9d9',
              }}
            >
              <FilterDate
                style={{ marginTop: 3 }}
                bordered={false}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                title="tạo nhà cung cấp"
                valueTime={valueTime}
                setValueTime={setValueTime}
                screen={1}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6} style={{ height: FILTER_COL_HEIGHT }}>
              <FilterProvinceDistrict
                width_size={400}
                setParamsFilter={setParamsFilter}
                paramsFilter={paramsFilter}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                title="order-list"
                style={{
                  width: '100%',
                  borderRight: '1px solid #d9d9d9',
                  borderLeft: '1px solid #d9d9d9',
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6} style={{ height: FILTER_COL_HEIGHT }}>
              <Select
                allowClear
                showSearch
                size={FILTER_SIZE}
                style={{ width: '100%' }}
                placeholder="Lọc theo người tạo"
                optionFilterProp="children"
                defaultValue={''}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={paramsFilter.creator_id || ''}
                onChange={(value) => _onFilter('creator_id', value)}
                bordered={false}
              >
                <Select.Option value="">Tất cả nhân viên</Select.Option>

                {users.map((user, index) => (
                  <Option value={user.user_id} key={index}>
                    {user.first_name || ''} {user.last_name || ''}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Table
            style={{ marginTop: 12, width: '100%' }}
            scroll={{ y: 400 }}
            size="small"
            rowKey="_id"
            columns={columnsSupplier.map((column) => {
              if (column.key === 'stt')
                return { ...column, width: 50, render: (text, record, index) => index + 1 }
              if (column.key === 'code')
                return {
                  ...column,
                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.xem_chi_tiet_nha_cung_cap) ? (
                      <SupplierForm reloadData={_getSuppliers} record={record}>
                        <a>{text}</a>
                      </SupplierForm>
                    ) : (
                      <div
                        style={{ color: '#5F73E2', cursor: 'pointer' }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        {text}
                      </div>
                    ),
                  sorter: (a, b) => compare(a, b, 'code'),
                }
              if (column.key === 'name')
                return { ...column, sorter: (a, b) => compare(a, b, 'name') }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) => (text ? moment(text).format('YYYY-MM-DD HH:mm') : ''),
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                }
              if (column.key === 'email')
                return { ...column, sorter: (a, b) => compare(a, b, 'email') }
              if (column.key === 'phone')
                return { ...column, sorter: (a, b) => compare(a, b, 'phone') }
              if (column.key === 'address')
                return {
                  ...column,
                  render: (text, record) =>
                    `${record.address && record.address + ', '}${
                      record.district && record.district + ', '
                    }${record.province && record.province}`,
                }
              if (column.key === 'creator')
                return {
                  ...column,
                  render: (text, record) =>
                    record._creator && record._creator.first_name + ' ' + record._creator.last_name,
                  sorter: (a, b) =>
                    (a._creator && a._creator.first_name + ' ' + a._creator.last_name).length -
                    (b._creator && b._creator.first_name + ' ' + b._creator.last_name).length,
                }
              if (column.key === 'debt')
                return {
                  ...column,
                  render: (text, record) => <p>Bổ sung sau</p>,
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) => (
                    <>
                      {permissions.includes(PERMISSIONS.xoa_nha_cung_cap) ? (
                        <Popconfirm
                          onConfirm={() => _deleteSupplier(record.supplier_id)}
                          title="Bạn có muốn xóa nhà cung cấp này không?"
                          okText="Đồng ý"
                          cancelText="Từ chối"
                        >
                          <Button type="primary" danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                      ) : (
                        <Button disabled type="primary" danger icon={<DeleteOutlined />} />
                      )}
                    </>
                  ),
                }

              return column
            })}
            dataSource={suppliers}
            loading={loading}
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                paramsFilter.page = page
                paramsFilter.page_size = pageSize
                setParamsFilter({ ...paramsFilter })
              },
              total: countSupplier,
            }}
            summary={(pageData) => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {columnsSupplier.map((e, index) => {
                    if (e.key === 'stt')
                      return (
                        <Table.Summary.Cell>
                          <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'debt')
                      return (
                        <Table.Summary.Cell>
                          {formatCash(tableSum(pageData, 'debt'))}
                        </Table.Summary.Cell>
                      )
                    return <Table.Summary.Cell></Table.Summary.Cell>
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </>
      ) : null}
    </div>
  )
}
