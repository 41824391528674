import React, { useState, useEffect, useRef } from 'react'
import columnsReceiptsPayment from './columns'
import { compareCustom, formatCash, removeUnicode, tableSum } from 'utils'
import { useHistory, Link } from 'react-router-dom'
import { ROUTES, FILTER_COL_HEIGHT, PERMISSIONS, TIME_FORMAT, PAGE_SIZE, ACTION } from 'consts'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { compare } from 'utils'
import ModalFormReceipt from './FormReceipt'
import ModalFormPayment from './FormPayment'

import ModalFormUpdate from './FormUpdateReceipt'
import { useTranslation } from 'react-i18next'

//apis
import { getFinancesWeb, updateFinances } from 'apis/finance'
import { getFinanceTypePayment, getFinanceTypeReceipts } from 'apis/finance-type'
import { getPayments } from 'apis/payment'
import { getStaff } from 'apis/manage-staff'

//antd
import {
  Row,
  Space,
  Button,
  Input,
  Select,
  Table,
  Modal,
  Form,
  Checkbox,
  notification,
  Tag,
  Col,
  Popconfirm,
} from 'antd'

//icons
import { ArrowLeftOutlined, SearchOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import Permissions from 'components/permission'

//components
import TitlePage from 'components/title-page'
import FilterDate from 'components/filter-date/filter'
import exportCsv from 'components/ExportCSV/export'
import ConnectLink from './ConnectLink'
import { DefaultFilterData } from 'utils/DefaultFilterData'
export default function ReceiptsAndPayment() {
  const { t } = useTranslation()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [allBranch, setAllBranch] = useState(false)
  const [totalFinance, setTotalFinance] = useState(0)
  const history = useHistory()
  const dispatch = useDispatch()
  // const dataUser = useSelector((state) => state.login.dataUser)
  const [form] = Form.useForm()
  const typingTimeoutRef = useRef(null)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [loading, setLoading] = useState(false)
  const [payments, setPayments] = useState([])
  const [employees, setEmployees] = useState([])
  const [finances, setFinances] = useState([])
  const [financeShow, setFinanceShow] = useState([])
  const [valueTime, setValueTime] = useState()
  const [profitFinance, setProfitFinance] = useState([])
  const [countFinance, setCountFinance] = useState([])
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: PAGE_SIZE })
  const [valueSearch, setValueSearch] = useState('')
  const [financeTypes, setFinanceTypes] = useState([])
  const ModalCreatePaymentOrReceipts = ({ type, children, record, reload, close }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [form] = Form.useForm()
    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Button
          onClick={toggle}
          style={{
            backgroundColor: type === 'payment' ? '#DE7C08' : '#0877DE',
            borderColor: type === 'payment' ? '#DE7C08' : '#0877DE',
            borderRadius: 5,
            color: 'white',
            fontWeight: 600,
            display: record && 'none',
          }}
        >
          {type === 'payment' ? 'Tạo phiếu chi' : 'Tạo phiếu thu'}
        </Button>

        <Modal
          width={650}
          onCancel={() => {
            toggle()
            form.resetFields()
          }}
          visible={visible}
          title={record ? 'Cập nhật' : type === 'payment' ? 'Tạo phiếu chi' : 'Tạo phiếu thu'}
          footer={false}
        >
          {!record ? (
            type === 'payment' ? (
              <>
                <Permissions permissions={[PERMISSIONS.them_phieu_thu_chi]}>
                  <ModalFormPayment
                    record={record}
                    close={toggle}
                    text={record ? 'Lưu' : 'Tạo'}
                    reload={_getFinances}
                  />
                </Permissions>
              </>
            ) : (
              <>
                <Permissions permissions={[PERMISSIONS.them_phieu_thu_chi]}>
                  <ModalFormReceipt
                    record={record}
                    close={toggle}
                    text={record ? 'Lưu' : 'Tạo'}
                    reload={_getFinances}
                  />
                </Permissions>
              </>
            )
          ) : (
            <Permissions permissions={[PERMISSIONS.cap_nhat_phieu_thu_chi]}>
              <ModalFormUpdate
                record={record}
                close={toggle}
                text={record ? 'Lưu' : 'Tạo'}
                reload={_getFinances}
              />
            </Permissions>
          )}
        </Modal>
      </>
    )
  }
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.trim()
      if (value) paramsFilter.code = value
      else delete paramsFilter.code
      setParamsFilter({ ...paramsFilter })
    }, 200)
  }

  const onFilters = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter })
  }
  useEffect(() => {
    let arr = [...finances]
    const filteredFinal = arr.filter((current) => {
      let matchCreator = true
      let matchPayment = true
      let matchType = true
      let matchTime = true
      let matchSearch = true
      let matchFinanceType = true
      if (paramsFilter.creator_id && paramsFilter.creator_id !== 'all')
        matchCreator = current?.creator_id === paramsFilter.creator_id
      if (paramsFilter.payment_method_id && paramsFilter.payment_method_id !== 'all')
        matchPayment = current.payment_method_id === paramsFilter.payment_method_id
      if (paramsFilter.type && paramsFilter.type !== 'all')
        matchType = paramsFilter.type.includes(current.type)
      if (paramsFilter.financeTypes && paramsFilter.financeTypes !== 'all')
        matchFinanceType = paramsFilter.financeTypes.includes(current.typefinance.toString().trim())
      if (paramsFilter.code){
        let newObject = {
          code: current.code,
          type: current.type,
          typefinance: current.typefinance,
          payer: current?.payer_info?.first_name + ' ' + current?.payer_info?.last_name,
          receiver: current?.receiver_info?.first_name + ' ' + current?.receiver_info?.last_name,
          value: current.value,
          note: current.note,
        }
        newObject = Object.values(newObject).join(' ').toLocaleLowerCase()
          matchSearch = removeUnicode(newObject.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.code).toLowerCase().trim())
      }
      
      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date

      return (
        matchCreator && matchPayment && matchType && matchTime && matchSearch && matchFinanceType
      )
    })
    setFinanceShow(filteredFinal)
    setCountFinance(filteredFinal.length)
    setProfitFinance(handleTotal(filteredFinal).profit)
    setTotalFinance(handleTotal(filteredFinal).total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter])

  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })

      if (res.status === 200) {
        if (res.data.success)
          setEmployees(res.data.data.filter((item) => item.status === 1 || item.role_id === 1))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        setPayments(res.data.data)
        const payment = res.data.data.find((e) => e.default)
        form.setFieldsValue({ payments: payment ? payment.payment_method_id : '' })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getFinanceTypes = async () => {
    try {
      let types = []
      const resP = await getFinanceTypePayment()
      const resR = await getFinanceTypeReceipts()
      if (resP.status === 200 && resR.status === 200) {
        types = [...resP.data.data, ...resR.data.data]
      }
      if (dataUser.role_id === 1) {
        let autoTypes = [
          { name: 'Phiếu chi nhập hàng' },
          { name: 'Phiếu chi trả hàng' },
          { name: 'Phiếu chi tiền thừa' },
          { name: 'Phiếu chi chuyển hàng' },
          { name: 'Phiếu chi ship' },
          { name: 'Phiếu thu bán hàng' },
          { name: 'Phiếu thu chuyển hàng' },
        ]
        types = [...types, ...autoTypes]
      }
      setFinanceTypes(types)
    } catch (error) {
      console.log(error)
    }
  }
  const returnStatus =(value) =>{
    let result = 'Hoàn thành'
    if(value === 2) result = 'Chờ thanh toán'
    if(value === 3) result = 'Chờ duyệt'
    if(value === 4) result = 'Từ chối'
    if(value === 5) result = 'Hủy'

    return result
  }
  const exportFinances = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const financesExport =
        financeShow &&
        financeShow.map((e) => ({
          'Mã phiếu': e.code || '',
          'Loại hình phiếu': e.type,
          'Loại phiếu': e.typefinance || '',
          'Trạng thái': returnStatus(e.status),
          'Số tiền': formatCash(e.value || 0),
          'Người thanh toán': e.payer_info
            ? `${e.payer_info.first_name} ${e.payer_info.last_name}`
            : e.name_payer,
          'Người nhận': e.receiver_info
            ? `${e.receiver_info.first_name} ${e.receiver_info.last_name}`
            : e.name_receiver,
          'Ghi chú': e.note || '',
          // 'Ngày giao dịch': moment(e.time_transaction).format(TIME_FORMAT.DATE_TIME),
          'Người tạo phiếu': e.creator_info
            ? `${e.creator_info.first_name} ${e.creator_info.last_name}`
            : '',
          'Ngày ghi nhận': e.create_date ? moment(e.create_date).format(TIME_FORMAT.DATE_TIME) : '',
        }))
      await exportCsv(financesExport, 'Danh sách phiếu thu chi','Xuất file excel',dispatch)

    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _cancelReceiptsPayment = async (receipt_id) => {
    const body = {
      status: 5,
    }
    try {
      const res = await updateFinances(receipt_id, body)
      if (res.status === 200) {
        _getFinances()
        notification.success({
          message: res.data.message || 'Huỷ phiếu thành công!',
        })
      } else {
        notification.error({
          message: res.data.message || 'Huỷ phiếu thất bại!',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const checkBranch = () => {
    if (allBranch === true) return null
    else return branchIdApp
  }
  const onClickClear = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setValueTime()
  }
  const handleTotal = (data) => {
    let total = {
      sum_finance_received: 0,
      sum_finance_payment: 0,
    }
    data.forEach((element) => {
      if (element.status === 1) {
        if (
          element.source === 'SALE' ||
          element.source === 'RECEIVED' ||
          element.source === 'TRANSPORTEXPORT'
        ) {
          total.sum_finance_received += element.value
        } else {
          total.sum_finance_payment -= element.value
        }
      }
    })
    let profit = total.sum_finance_received - total.sum_finance_payment
    return { total, profit }
  }
  const _getFinances = async () => {
    try {
      setLoading(true)
      const res = await getFinancesWeb({role_id: dataUser?.role_id, branch_id: checkBranch() })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setValueSearch()
        if (dataUser.role_id === 1) {
          setFinances(result.data)
        } else {
          let finances = result.data.filter(
            (item) => item.source === 'RECEIVED' || item.source === 'PAYMENT'
          )
          setFinances(finances)
        }
        setParamsFilter({
          page: 1,
          page_size: PAGE_SIZE,
          from_date: result.defaultTime.from_date,
          to_date: result.defaultTime.to_date,
        })
        setValueTime(result.valueDate)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const handleNote = (value) => {
    let index = value.note.lastIndexOf('#')
    let afterIndex = value.note.substring(index + 1)
    let beforeltIndex = value.note.substring(0, index)
    return (
      <div>
        {index !== -1 ? (
          <>
            {beforeltIndex} <ConnectLink source={value.source} code={afterIndex} order_id={value.order_id}/>
          </>
        ) : (
          <>{value.note}</>
        )}
      </div>
    )
  }
  useEffect(() => {
    _getEmployees()
    _getPayments()
    _getFinances()
    _getFinanceTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser, allBranch])
  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.REPORTS)}
          >
            <ArrowLeftOutlined style={{ marginRight: 10 }} />
            Danh sách phiếu thu chi
          </Row>
        }
      >
        <Space size="middle">
          <Checkbox checked={allBranch} onChange={(e) => setAllBranch(e.target.checked)}>
            {' '}
            <b>Tất cả chi nhánh</b>{' '}
          </Checkbox>
          <Button onClick={onClickClear} type="danger">
            Xóa tất cả lọc
          </Button>
          {permissions.includes(PERMISSIONS.export_phieu_thu_chi) ? (
            <>
              {' '}
              <Button
                id="exportFile"
                icon={<UploadOutlined />}
                style={{
                  backgroundColor: '#4E7DD9',
                  borderColor: '#4E7DD9',
                  borderRadius: 5,
                  color: 'white',
                  fontWeight: 600,
                }}
                onClick={exportFinances}
              >
                Xuất excel
              </Button>
            </>
          ) : (
            <>
              {' '}
              <Button
                icon={<UploadOutlined />}
                style={{
                  borderRadius: 5,
                  color: '#b7b7b7',
                  fontWeight: 600,
                }}
                disabled
              >
                Xuất excel
              </Button>
            </>
          )}

          {permissions.includes(PERMISSIONS.loai_phieu_thu_chi) ? (
            <>
              {' '}
              <Link to={{ pathname: ROUTES.FINANCE_TYPE }}>
                <Button
                  style={{
                    backgroundColor: '#66AE43',
                    borderColor: '#66AE43',
                    borderRadius: 5,
                    color: 'white',
                    fontWeight: 600,
                  }}
                >
                  Quản lý loại phiếu
                </Button>
              </Link>
            </>
          ) : (
            <>
              {' '}
              <Link to={{ pathname: ROUTES.FINANCE_TYPE }}>
                <Button
                  disabled
                  style={{
                    borderRadius: 5,
                    color: '#b7b7b7',
                    fontWeight: 600,
                  }}
                >
                  Quản lý loại phiếu
                </Button>
              </Link>
            </>
          )}

          {permissions.includes(PERMISSIONS.them_phieu_thu_chi) ? (
            <>
              {' '}
              <ModalCreatePaymentOrReceipts type="payment" />
              <ModalCreatePaymentOrReceipts type="receipt" />
            </>
          ) : (
            <>
              {' '}
              <Button
                disabled
                style={{
                  borderRadius: 5,
                  fontWeight: 600,
                }}
              >
                Tạo phiếu chi
              </Button>
              <Button
                disabled
                style={{
                  borderRadius: 5,
                  fontWeight: 600,
                }}
              >
                Tạo phiếu thu
              </Button>
            </>
          )}
        </Space>
      </TitlePage>

      {permissions.includes(PERMISSIONS.xem_danh_sach_phieu_thu_chi) ? (
        <>
          <Row
            align="middle"
            style={{ marginTop: 10, marginBottom:10 }}
          >
            <Col xs={24} sm={24} md={24} lg={7} xl={5}>
              <Input
                clearIcon={<CloseOutlined />}
                allowClear
                prefix={<SearchOutlined />}
                bordered={true}
                style={{ width: '100%' }}
                placeholder="Tìm kiếm theo mã phiếu"
                value={valueSearch}
                onChange={onSearch}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={3} xl={3}>
              <Select
                clearIcon={<CloseOutlined />}
                allowClear
                bordered={true}
                mode="multiple"
                placeholder="Chọn loại hình phiếu"
                style={{ width: '100%'}}
                value={paramsFilter.type || 'all'}
                defaultValue={'all'}
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                }
                onChange={(value) => {
                  let index = value.findIndex((item) => item === 'all')

                  if (index !== -1 && value.length > 1) {
                    if (index === 0) {
                      value.splice(index, 1)
                    } else {
                      value = 'all'
                    }
                  }
                  if (value.length === 0) {
                    value = 'all'
                  }

                  onFilters('type', value)
                }}
              >
                <Select.Option value={'all'}>Tất cả phiếu</Select.Option>
                <Select.Option value="Phiếu thu">Phiếu thu</Select.Option>
                <Select.Option value="Phiếu chi">Phiếu chi</Select.Option>
                {dataUser.role_id === 1 ? (
                  <>
                    <Select.Option value="Phiếu chi tự động">Phiếu chi tự động</Select.Option>
                    <Select.Option value="Phiếu thu tự động">Phiếu thu tự động</Select.Option>
                  </>
                ) : null}
                <Select.Option value="Phiếu chi phí ship">Phiếu chi phí ship</Select.Option>
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={3} xl={4}>
              <Select
                clearIcon={<CloseOutlined />}
                allowClear
                bordered={true}
                mode="multiple"
                placeholder="Chọn loại phiếu"
                style={{ width: '100%'}}
                value={paramsFilter.financeTypes || 'all'}
                defaultValue={'all'}
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                }
                onChange={(value) => {
                  let index = value.findIndex((item) => item === 'all')

                  if (index !== -1 && value.length > 1) {
                    if (index === 0) {
                      value.splice(index, 1)
                    } else {
                      value = 'all'
                    }
                  }
                  if (value.length === 0) {
                    value = 'all'
                  }

                  onFilters('financeTypes', value)
                }}
              >
                <Select.Option value={'all'}>Tất cả loại phiếu</Select.Option>
                {financeTypes.map((e, index) => (
                  <Select.Option key={index} value={e.name}>
                    {e.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={5} xl={4}>
              <Select
                clearIcon={<CloseOutlined />}
                allowClear
                bordered={true}
                showSearch
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                }
                placeholder="Lọc theo hình thức thanh toán"
                style={{ width: '100%'}}
                value={paramsFilter.payment_method_id || ''}
                onChange={(value) => onFilters('payment_method_id', value)}
              >
                <Select.Option value="">Tất cả phương thức thanh toán</Select.Option>
                {payments.map((payment, index) => (
                  <Select.Option key={index} value={payment.payment_method_id}>
                    {payment.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <Select
                clearIcon={<CloseOutlined />}
                allowClear
                bordered={true}
                showSearch
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                }
                placeholder="Người tạo phiếu"
                style={{ width: '100%'}}
                value={paramsFilter.creator_id || ''}
                onChange={(value) => onFilters('creator_id', value)}
              >
                <Select.Option value="">Tất cả nhân viên</Select.Option>

                {employees.map((employees, index) => (
                  <Select.Option key={index} value={employees.user_id}>
                    {(employees.first_name || '') + ' ' + (employees.last_name || '')}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={5} xl={4} style={{ height: FILTER_COL_HEIGHT }}>
              <FilterDate
              size='default'
                bordered={true}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                title="tạo phiếu thu / chi"
                valueTime={valueTime}
                setValueTime={setValueTime}
              />
            </Col>
          </Row>
          <div style={{ display: 'flex', backgroundColor:'white',paddingBottom:10 }}>
            <div>
              Tổng thu: <strong>{formatCash(totalFinance.sum_finance_received) + 'đ'}</strong>
            </div>
            <div style={{ marginLeft: 8 }}>
              Tổng chi: <strong>{formatCash(totalFinance.sum_finance_payment) + 'đ'}</strong>
            </div>
            <div style={{ marginLeft: 8 }}>
              Tổng lợi nhuận: <strong>{formatCash(profitFinance) + 'đ'}</strong>
            </div>
          </div>
          <Table
            loading={loading}
            size="small"
            rowKey="receipt_id"
            style={{ width: '100%' }}
            scroll={{ y: 450 }}
            columns={columnsReceiptsPayment.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'code'),
                  render: (text, record) =>
                    record.status === 5 ? (
                      <p style={{ marginBottom: 0 }}>{record.code}</p>
                    ) : permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_thu_chi) ? (
                      <>
                        <ModalCreatePaymentOrReceipts record={record}>
                          <a>{record.code}</a>
                        </ModalCreatePaymentOrReceipts>
                      </>
                    ) : (
                      <span style={{cursor:'pointer', color:'#5F73E2'}} onClick={() => notification.warning({message:'Permission denied!'})}>{record.code}</span>
                    ),
                }
              if (column.key === 'type')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'type'),
                  render: (text, record) => record.type,
                }
              if (column.key === 'typefinance')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'typefinance'),
                  render: (text, record) => record.typefinance,
                }
              if (column.key === 'payment')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'payment_method'),
                  render: (text, record) => record.payment_method,
                }

              if (column.key === 'money')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'value', Number),

                  render: (text, record) => record.value,
                }
              if (column.key === 'status')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'status'),
                  render: (text, record) =>
                    record.status && (
                      <Tag color={record.status === 1 ? 'success' : record.status === 2 ? 'processing' : record.status === 3 ? 'warning' : record.status === 4 ? 'error' : 'default'}>{returnStatus(record.status)}</Tag>
                    ),
                }
              if (column.key === 'creator')
                return {
                  ...column,
                  sorter: (a, b) =>
                    compareCustom(
                      a.creator_info
                        ? `${a.creator_info.first_name} ${a.creator_info.last_name}`
                        : '',
                      b.creator_info
                        ? `${b.creator_info.first_name} ${b.creator_info.last_name}`
                        : ''
                    ),
                  render: (text, record) =>
                    record.creator_info &&
                    `${record.creator_info.first_name} ${record.creator_info.last_name}`,
                }

              if (column.key === 'payer')
                return {
                  ...column,
                  render: (text, record) =>
                    record.payer_info
                      ? record.payer_info.first_name + ' ' + record.payer_info.last_name
                      : record.name_payer,
                }
              if (column.key === 'receiver')
                return {
                  ...column,
                  render: (text, record) =>
                    record.receiver_info
                      ? record.receiver_info.first_name + ' ' + record.receiver_info.last_name
                      : record.name_receiver,
                }

              if (column.key === 'note')
                return {
                  ...column,
                  render: (text, record) => handleNote(record),
                  sorter: (a, b) => compare(a, b, 'type'),
                }
              // if (column.key === 'time_transaction')
              //   return {
              //     ...column,
              //     sorter: (a, b) =>
              //       moment(a.time_transaction).unix() - moment(b.time_transaction).unix(),
              //     render: (text, record) =>
              //       record.time_transaction &&
              //       moment(record.time_transaction).format(TIME_FORMAT.DATE_TIME),
              //   }
              if (column.key === 'create_date')
                return {
                  ...column,
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                  render: (text, record) =>
                    record.create_date && moment(record.create_date).format(TIME_FORMAT.DATE_TIME),
                }
                if (column.key === 'last_update')
                return {
                  ...column,
                  sorter: (a, b) => moment(a.last_update).unix() - moment(b.last_update).unix(),
                  render: (text, record) =>
                    record.last_update && moment(record.last_update).format(TIME_FORMAT.DATE_TIME),
                }
                if (column.key === 'updator')
                return {
                  ...column,
                  sorter: (a, b) =>
                    compareCustom(
                      a.updator_info
                        ? `${a.updator_info.first_name} ${a.updator_info.last_name}`
                        : '',
                      b.updator_info
                        ? `${b.updator_info.first_name} ${b.updator_info.last_name}`
                        : ''
                    ),
                  render: (text, record) =>
                    record.updator_info &&
                    `${record.updator_info.first_name} ${record.updator_info.last_name}`,
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) => (
                    <Col>
                      {record.status === 'ĐÃ HUỶ' ||
                      record.source === 'SALE' ||
                      record.source === 'REFUND' ||
                      record.source === 'IMPORT' ||
                      record.source === 'TRANSPORTIMPORT' ||
                      record.source === 'TRANSPORTEXPORT' ? (
                        <>
                          {' '}
                          <Button
                            disabled
                            style={{ marginLeft: 2 }}
                            danger
                            type="primary"
                            icon={<CloseOutlined />}
                          />
                        </>
                      ) : (
                        <Permissions permissions={[PERMISSIONS.xoa_phieu_thu_chi]}>
                          <Popconfirm
                            title="Bạn có muốn huỷ phiếu này không? Thao tác không thể khôi phục"
                            cancelText="Từ chối"
                            okText="Đồng ý"
                            onConfirm={() => _cancelReceiptsPayment(record.receipt_id)}
                          >
                            {dataUser.user_id == record.creator_id || dataUser.role_id == 1 ? (
                              <Button
                                style={{ marginLeft: 2 }}
                                danger
                                type="primary"
                                icon={<CloseOutlined />}
                              />
                            ) : null}
                          </Popconfirm>
                        </Permissions>
                      )}
                    </Col>
                  ),
                }
              return column
            })}
            dataSource={financeShow}
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) =>
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
              total: countFinance,
            }}
            summary={(pageData) => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {columnsReceiptsPayment.map((e, index) => {
                    if (e.key === 'stt')
                      return (
                        <Table.Summary.Cell>
                          <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'money')
                      return (
                        <Table.Summary.Cell>
                          {/* {formatCash(tableSum(pageData, 'value'))} đ */}
                          {profitFinance && formatCash(profitFinance)} VND
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'payment_amount')
                      return (
                        <Table.Summary.Cell>
                          {formatCash(tableSum(pageData, 'payment_amount'))}
                        </Table.Summary.Cell>
                      )
                    return <Table.Summary.Cell></Table.Summary.Cell>
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </>
      ) : null}
    </div>
  )
}
