import React, { useState, useEffect } from 'react'
import styles from './filter.module.scss'

//antd
import { Col, Row, Select } from 'antd'

//apis
import { getDistricts, getProvinces } from 'apis/address'

export default function FilterProvinceDistrict({
  setParamsFilter,
  paramsFilter,
  width_size,
  selectedItems,
  setSelectedItems,
  title,
  style,
}) {
  const [districts, setDistricts] = useState([])
  const [districtId, setDistrictId] = useState()

  const [provinces, setProvinces] = useState([])
  const [provinceId, setProvinceId] = useState()

  const [branches, setBranches] = useState([])
  const [branchId, setBranchId] = useState()

  const toggleMouseLeave = () => {
    // setRegions([])
    // setRegionId([])
    // setCountryId([])
    // setBranches([])
    setBranchId([])
  }

  const _getDistricts = async () => {
    try {
      const res = await getDistricts()
      // console.log(res)
      if (res.status === 200) setDistricts(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getProvinces = async () => {
    try {
      const res = await getProvinces()
      // console.log(res)
      if (res.status === 200) setProvinces(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getDistricts()
    _getProvinces()
  }, [])

  return (
    <div>
      <Select
        allowClear
        bordered={false}
        value={selectedItems?.name}
        onChange={(e) => {
          if (!e) {
            setSelectedItems([])
            setParamsFilter({ ...paramsFilter, province_id: '', district_id: '' })
          }
        }}
        style={style}
        placeholder="Lọc theo tỉnh-thành phố/quận huyện"
        // size="large"
        dropdownRender={() => (
          <Row
            className={styles['dropdown-container']}
            // justify="space-between"
            onMouseLeave={toggleMouseLeave}
          >
            <Col className={styles['dropdown-container']} span={12}>
              {provinces.map((province, index) => (
                <Row
                  key={index}
                  style={{
                    color: '#000',
                    padding: '2px 10px',
                    margin: '1px 3px',
                    cursor: 'pointer',
                    backgroundColor:
                      paramsFilter.province_id === province.province_id ||
                      provinceId === province.province_id
                        ? '#5F73E2'
                        : '',
                    borderRadius: 10,
                  }}
                  value={province.province_id}
                  onMouseEnter={() => {
                    setProvinceId(province.province_id)
                    setBranchId([])
                  }}
                  onClick={() => {
                    setParamsFilter({
                      ...paramsFilter,
                      province_id: province.province_id,
                    })
                    setSelectedItems({ name: [province.province_name] })
                  }}
                >
                  {province.province_name}
                </Row>
              ))}
            </Col>
            <Col className={styles['dropdown-container']} span={12}>
              {districts.map((district, index) =>
                provinceId === district.province_id ? (
                  <Row
                    key={index}
                    style={{
                      color: '#000',
                      padding: '2px 10px',
                      margin: '1px 3px',
                      cursor: 'pointer',
                      backgroundColor:
                        paramsFilter.region_id === district.district_id ||
                        districtId === district.district_id
                          ? '#5F73E2'
                          : '',
                      borderRadius: 10,
                    }}
                    value={district.district_id}
                    onMouseEnter={() => {
                      setDistrictId(district.district_id)
                      setBranchId([])
                    }}
                    onClick={() => {
                      setParamsFilter({
                        ...paramsFilter,
                        province_id: provinceId,
                        district_id: district.district_id,
                      })
                      setSelectedItems({ name: [district.district_name] })
                    }}
                  >
                    {district.district_name}
                  </Row>
                ) : (
                  ''
                )
              )}
            </Col>
          </Row>
        )}
      ></Select>
    </div>
  )
}
