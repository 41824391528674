import { DownOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Layout,
  notification,
  Row,
  Select,
  Space,
  Menu,
  Dropdown,
  Popconfirm,
  Spin,
  Tooltip,
} from 'antd'
import { uploadFile } from 'apis/upload'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { addProductSmart } from '../../../apis/import-smart-product'
import ModalImport from '../import-excel/modal-import'
const { Header } = Layout

const LayoutHeader = ({
  setDisableField,
  setProducts,
  setEditProduct,
  products,
  fileUpload,
  setLoading,
  loading,
  setProductsError,
  productsError
}) => {
  const [valueProductMenu, setValueProductMenu] = useState(1)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [disabledImport, setDisabledImport] = useState(false)
  // const loadi

  const menu = (
    <Menu
      items={[
        {
          label:
            products?.length > 0 ? (
              <Popconfirm
                placement="left"
                title="Thay đổi sẽ mất toàn bộ thông tin trong bảng hiện tại? Bạn có đồng ý?"
                onConfirm={() => handleChange(1)}
                okText="Đồng ý"
                cancelText="Huỷ bỏ"
              >
                <div>Nhập sản phẩm mới</div>{' '}
              </Popconfirm>
            ) : (
              <div onClick={() => handleChange(1)}>Nhập sản phẩm mới</div>
            ),
          key: '0',
        },
        {
          label:
            products?.length > 0 ? (
              <Popconfirm
                placement="left"
                title="Thay đổi sẽ mất toàn bộ thông tin trong bảng hiện tại? Bạn có đồng ý?"
                onConfirm={() => handleChange(2)}
                okText="Đồng ý"
                cancelText="Huỷ bỏ"
              >
                <div>Bổ sung số lượng</div>{' '}
              </Popconfirm>
            ) : (
              <div onClick={() => handleChange(2)}>Bổ sung số lượng</div>
            ),
          key: '1',
        },
      ]}
    />
  )

  const handleChange = (e) => {
    setLoading(true)
    if (e == 2) {
      setDisableField(true)
    } else {
      setDisableField(false)
    }
    setValueProductMenu(e)
    setProducts([])
    setEditProduct(null)
    setDisabledImport(false)
    if (products?.length > 0) {
      notification.error({
        key: 'notification new product',
        message: 'Thay đổi phương thức nhập hàng, vui lòng nhập lại thông tin!',
      })
    }
    setTimeout(() => setLoading(false), 300)
  }

  const handleReset = () => {
    setLoading(true)
    setProducts([])
    setEditProduct(null)
    setDisabledImport(false)
    setTimeout(() => setLoading(false), 2000)
    setProductsError([])
  }

  const saveDataSystem = async () => {
    try {
      let body = {
        branch_id: branchIdApp,
        products: products,
        valueProductMenu: valueProductMenu,
      }
      setLoading(true)
      // const image = await uploadFile(fileUpload)
      let res = await addProductSmart(body)

      if (res.status === 200) {
        notification.success({ key: 'successData', message: 'Nhập sản phẩm thành công' })
        setProducts([])
        setEditProduct(null)
        setDisabledImport(false)
      }
      setTimeout(() => setLoading(false), 2000)
    } catch (error) {
      setLoading(false)
      notification.error({ key: 'errorDataSystem', message: `${error}` })
    }
  }

  return (
    <Header style={{ backgroundColor: 'white',borderRadius:'5px'  }}>
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        <div style={{ marginRight: '10px', width: '180px' }}>
          <h1 style={{ fontSize: 20, width: '180px' }}>Nhập hàng vào kho</h1>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'end',
          }}
        >
          <div style={{marginRight: '10px'}}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Tooltip title={loading === true ? 'Vui lòng đợi trong giây lát' : ''}>
                <Button
                  style={{
                    width: 170,
                  }}
                  onClick={(e) => e.preventDefault()}
                  disabled={loading === true ? true : false}
                >
                  {valueProductMenu == 1 ? 'Nhập sản phẩm mới' : 'Bổ sung số lượng'}{' '}
                  <DownOutlined />
                </Button>
              </Tooltip>
            </Dropdown>
          </div>
          <div style={{marginRight: '10px'}}>
            <a href="https://s3.ap-southeast-1.wasabisys.com/ekata-production/public/2022/11/07/1667788395553-687027156-mau-them-nhanh-san-pham.xlsx">
              <Button>Tải file import mẫu</Button>
            </a>
          </div>
          <div style={{marginRight: '10px'}}>
            <ModalImport
              setProducts={setProducts}
              valueProductMenu={valueProductMenu}
              products={products}
              setDisabledImport={setDisabledImport}
              disabledImport={disabledImport}
              loading={loading}
              setProductsError={setProductsError}
              productsError={productsError}
            />
          </div>
          <div style={{marginRight: '10px'}}>
            <Tooltip title={loading === true ? 'Vui lòng đợi trong giây lát' : ''}>
              <Button
                style={{
                  width: 150,
                  borderRadius: '10px',
                  boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
                  transition: 'all .3s ease-in',
                }}
                type={'danger'}
                disabled={loading === true ? true : false}
                onClick={() => handleReset()}
              >
                Tải lại kho hàng
              </Button>
            </Tooltip>
          </div>
          <div>
            <Tooltip title={loading === true ? 'Vui lòng đợi trong giây lát' : ''}>
              <Button
                onClick={async () => saveDataSystem()}
                style={{
                  width: 150,
                  borderRadius: '10px',
                  boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
                  transition: 'all .3s ease-in',
                }}
                type={'primary'}
                disabled={loading === true ? true : false}
              >
                Lưu lên máy chủ
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </Header>
  )
}
export default LayoutHeader
