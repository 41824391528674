const columns = [
  { title: 'STT', key: 'stt', width: 70 },
  { title: 'Mã phiếu đối soát', key: 'code' },
  { title: 'Danh sách đơn', key: 'list_order' },
  { title: 'Đối tác vận chuyển', key: 'shipping_company' },
  { title: 'Trạng thái', key: 'status' },
  { title: 'Tải file', key: 'file' },
  { title: 'Ngày tạo', key: 'create_date' },
  { title: 'Người tạo phiếu', key: 'creator_info' },
  { title: 'Ghi chú', key: 'note' },
  { title: 'Thao tác', key: 'action' },
]

export default columns
