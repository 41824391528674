import React, { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import FormImportFile from './index.js'
const ModalImport = ({
  checkSubmit,
  dataOrders,
  setDataOrders,
  setOrdersError,
  startDate,
  endDate
}) => {
  //valueProductMenu 1: sp moi 2:hang` moi
  const [open, setOpen] = useState(false)
  const showModal = () => {
    setOpen(true)
  }

  return (
    <>
      <Tooltip
      title={checkSubmit === true ? <span>Chọn đơn vị vận chuyển,<br></br> ngày nhận đơn/hoàn thành<br></br> để import file</span> : ""}
      >
        <Button
          disabled={checkSubmit === true ? true : false}
          onClick={showModal}
          size="large"
          style={{color: 'white', width: '100%', background: checkSubmit === true ? 'silver' : '#1bc47d', borderColor: checkSubmit === true ? 'silver' : '#1bc47d',fontWeight: 500}}
        >
          Import file đối soát
        </Button>
      </Tooltip>

      <Modal
        width={1500}
        height={400}
        title="Nhập file Excel"
        closable={false}
        visible={open}
        footer={null}
      >
        <FormImportFile
          open={open}
          setOpen={setOpen}
          dataOrders={dataOrders}
          setDataOrders={setDataOrders}
          setOrdersError={setOrdersError}
          startDate={startDate}
          endDate={endDate}
        />
      </Modal>
    </>
  )
}

export default ModalImport
