import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { IMAGE_DEFAULT, ACTION, FILTER_SIZE } from 'consts'

import { getProductAllBranch } from 'apis/report'
import { useTranslation } from 'react-i18next'
import { formatCash } from 'utils'
import { Row, Modal, Button, Table, Col } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

export default function ModalProcuctAllBranch({ product, index }) {
  const { t } = useTranslation()

  const toggle = () => setVisible(!visible)
  const [visible, setVisible] = useState(false)
  const [productAnotherbranch, setProductAnotherBranch] = useState([])
  const dispatch = useDispatch()
  const _getProductAnotherBranch = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getProductAllBranch({ variant_id: product.variant_id })
      if (res.status === 200) {
        setProductAnotherBranch(res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (e) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(e)
    }
  }

  const column = [
    {
      title: t('sell.branch'),
      dataIndex: 'name',
      render: (text, record) => record.name,
    },
    {
      title: t('sell.quantity'),
      render: (text, record) => formatCash(record.quantity || 0),
    },
  ]

  const content = (
    <div>
      <Row justify="space-between">
        <span>{t('sell.price')}</span>
        <span>{formatCash(product ? product.price : 0)}</span>
      </Row>
      <Row justify="space-between">
        <span>{t('sell.can_be_sold')}</span>
        {product && product.inventory === 'normal' ? (
          <span>{product.is_open === false ? 'Hết hàng' : 'Còn hàng'}</span>
        ) : (
          <span>{formatCash(product ? product.total_quantity : 0)}</span>
        )}
      </Row>
    </div>
  )

  useEffect(() => {
    if (visible) _getProductAnotherBranch()
  }, [visible])

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Row
        align="middle"
        style={
          index % 2
            ? { marginBottom: 8, background: '#F7F8FA', cursor: 'pointer' }
            : { marginBottom: 8, cursor: 'pointer' }
        }
        onClick={toggle}
      >
        <Col span={5}>
          <img
            alt=""
            src={product?.image[0] ? product.image[0] : IMAGE_DEFAULT}
            width="50px"
            height="50px"
          />
        </Col>
        <Col span={12}>
          <Row>{product?.title || ''}</Row>
          <Row style={{ fontWeight: 500 }}>
            Tồn kho hệ thống: {product?.quantity_system ? product?.quantity_system : 0}
          </Row>
        </Col>
      </Row>
      <Modal
        width={700}
        footer={
          <Row justify="end">
            <Button onClick={toggle}>{t('common.close')}</Button>
          </Row>
        }
        visible={visible}
        onCancel={toggle}
        title={product && product.title}
      >
        <Table
          pagination={false}
          style={{ width: '100%' }}
          columns={column}
          size="small"
          dataSource={productAnotherbranch}
        />
      </Modal>
    </div>
  )
}
