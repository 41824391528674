import React, { useEffect, useState } from 'react'
import { formatCash } from 'utils'
import { FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE} from 'consts'
import { useSelector } from 'react-redux'

//antd
import { Table, Row, Col, Button, Tag, Select } from 'antd'

import FilterDate from 'components/filter-date'

//apis
import { getReportImportExportInventory } from 'apis/report'
import { getProducts } from 'apis/product'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function ReportImportExportInventoryProduct() {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState([])
  const [product, setProduct] = useState([])
  const [valueFilter, setValueFilter] = useState()
  const [valueTime, setValueTime] = useState()
  const [countReport, setCountReport] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const _onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    if (attribute == 'product_id' && value == 'all') {
      delete paramsFilter[attribute]
    }
    setParamsFilter({ ...paramsFilter, page: 1 })
  }
  const _clearFilters = async () => {
    setValueFilter()
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check:1 })
  }
  

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => index + 1,
      width: 35,
      align:'center'
    },
    {
      title: 'Mã hàng',
      dataIndex: 'code',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Tên hàng',
      dataIndex: 'title',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Ngày sản xuất',
      render: (text, record) => text || 'Không có NSX',
      dataIndex: 'mfg',
      width: 75,
      fixed: 'left',
    },
    {
      title: 'Hạn sử dụng',
      render: (text, record) => text || 'Không có HSD',
      dataIndex: 'exp',
      width: 75,
      fixed: 'left',
    },
    {
      title: 'ĐVT',
      fixed: 'left',
      width: 35,
      render: (text, record) => (record.product.unit ? `${record.product.unit}` : ''),
    },
    {
      title: 'Nhóm',
      fixed: 'left',
      width: 100,
      render: (text, record) =>
        record.product._categories
          ? record.product._categories.map((category) => <Tag>{category.name}</Tag>)
          : '',
    },
    {
      title: 'Đầu kỳ',
      children: [
        {
          title: 'Số lượng',
          width: 75,
          render: (text, record) => (record.begin_quantity ? formatCash(record.begin_quantity) : 0),
        },
        {
          title: 'Thành tiền',
          width: 75,
          render: (text, record) => (record.begin_price ? formatCash(record.begin_price) : 0),
        },
      ],
    },
    {
      title: 'Nhập',
      children: [
        {
          title: 'Số lượng',
          width: 75,
          render: (text, record) =>
            record.import_quantity ? formatCash(record.import_quantity) : 0,
        },
        {
          title: 'Thành tiền',
          width: 75,
          render: (text, record) => (record.import_price ? formatCash(record.import_price) : 0),
        },
      ],
    },
    {
      title: 'Xuất',
      children: [
        {
          title: 'Số lượng',
          width: 75,
          render: (text, record) =>
            record.export_quantity ? formatCash(record.export_quantity) : 0,
        },
        {
          title: 'Thành tiền',
          width: 75,
          render: (text, record) => (record.export_price ? formatCash(record.export_price) : 0),
        },
      ],
    },
    {
      title: 'Cuối kỳ',
      children: [
        {
          title: 'Số lượng',
          width: 75,
          render: (text, record) => (record.end_quantity ? formatCash(record.end_quantity) : 0),
        },
        {
          title: 'Thành tiền',
          width: 75,
          render: (text, record) => (record.end_price ? formatCash(record.end_price) : 0),
        },
      ],
    },
    {
      title: 'Ghi chú',
      width: 75,
      dataIndex: 'note',
    },
  ]

  const _getReportImportExportInventory = async () => {
    try {
      setLoading(true)
      const res = await getReportImportExportInventory({ type: 'variant', ...paramsFilter, branch_id:branchIdApp })
      if (res.status === 200) {
        setReports(res.data.data.map((e) => ({ ...e, ...e.variant })))
        setCountReport(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const _getProducts = async () => {
    try {
      const res = await getProducts()
      if (res.status === 200)
        setProduct(res.data.data.filter((item) => item.inventory === 'advanced'))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getProducts()
  }, [])

  useEffect(() => {
    if(paramsFilter.check) _getReportImportExportInventory()
  }, [paramsFilter])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setParamsFilter({page:1, page_size:PAGE_SIZE, from_date: result.from_date, to_date: result.to_date, check:1})
    setValueTime(result.valueDate)
    setValueFilter()
  },[dataUser])
  return (
      <div>
        <Row
          gutter={[14, 14]}
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
        >
           <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ height: FILTER_COL_HEIGHT}}>
        <FilterDate
        size='default'
              bordered={true}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
              title=''
              valueTime={valueTime}
              setValueTime={setValueTime}
            />
        </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={6}
          >
            <Select
              size={FILTER_SIZE}
              allowClear
              bordered={true}
              defaultValue="all"
              placeholder="Lọc theo sản phẩm"
              style={{ width: '100%' }}
              showSearch
              value={valueFilter || 'all'}
              onChange={(value) => {
                setValueFilter(value)
                let resultIndex
                if (value !== undefined) {
                  let index = value.lastIndexOf('+')
                  resultIndex = value.substring(index + 1)
                }
                _onFilter('product_id', resultIndex)
              }}
            >
              <Select.Option value="all">Tất cả sản phẩm</Select.Option>

              {product.map((_product, index) => (
                <Select.Option
                value={`${_product.name}+${_product.sku}`}
                  key={index}
                >
                  {_product.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Button
            onClick={_clearFilters}
            style={{
              marginLeft: 10,
              display: Object.keys(paramsFilter).length < 3 && 'none',
              width: '10%',
            }}
            danger
            type="primary"
          >
            Xóa bộ lọc
          </Button>
        </Row>

        <div className="report-variant" style={{ display: 'none' }}>
          <Table
            style={{ width: '100%' }}
            columns={columns}
            dataSource={reports}
            size="small"
            bordered
            pagination={false}
            summary={(pageData) => (
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>Tổng</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.begin_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.begin_price, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.import_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.import_price, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.export_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.export_price, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.end_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(pageData.reduce((value, current) => value + current.end_price, 0))}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </div>

        <Table
          style={{ width: '100%' }}
          loading={loading}
          columns={columns}
          dataSource={reports}
          size="small"
          bordered
          scroll={{
            x: 2000,
            y: 500,
          }}
          pagination={{
            position: ['bottomLeft'],
            current: paramsFilter.page,
            defaultPageSize: 20,
            pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) =>
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
            total: countReport,
          }}
          summary={(pageData) => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <div style={{ fontWeight: 700 }}>Tổng</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.begin_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.begin_price, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.import_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.import_price, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.export_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.export_price, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(
                      pageData.reduce((value, current) => value + current.end_quantity, 0)
                    )}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <div style={{ fontWeight: 700 }}>
                    {formatCash(pageData.reduce((value, current) => value + current.end_price, 0))}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
  )
}
