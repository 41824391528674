import React, { useEffect, useRef, useState } from 'react'
import styles from '../style.module.scss'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { ROUTES, IMAGE_DEFAULT, PERMISSIONS, ACTION } from 'consts'
import { filterOptionProduct, formatCash, removeUnicode } from 'utils'
import { useDispatch, useSelector } from 'react-redux'

//components
import TitlePage from 'components/title-page'
import ModalProductImei from '../item/QuantityImei'
import ModalProductQuantity from '../item/QuantityProductLocation'
import ImportProduct from '../../import-stock-adjustment'

//antd
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  InputNumber,
  Form,
  Select,
  Spin,
  Modal,
  notification,
  Checkbox,
  Collapse,
} from 'antd'

//icons
import { ArrowLeftOutlined, CloseOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons'

//apis
import { getStaff } from 'apis/manage-staff'
import { getCategories } from 'apis/category'
import { createCheckInventoryNote, updateCheckInventoryNote } from 'apis/inventory-note'
import Permissions from 'components/permission'
import { getProductWarehouse } from 'redux/actions/productWarehouse'

const { Option } = Select
const { TextArea } = Input
const { Panel } = Collapse
export default function StockAdjustmentsForm() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const location = useLocation()

  const [loadingProduct, setLoadingProduct] = useState(false)
  const dataUser = useSelector((state) => state.login.dataUser)
  const [isModalQuickAddProduct, setIsModalQuickAddProduct] = useState(false)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const products = useSelector((state) => state.products.productWarehouse)
  const [categories, setCategories] = useState([])
  const [users, setUsers] = useState([])
  const [listProduct, setListProduct] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [allCategory, setAllCategory] = useState(false)
  const [paramsFilterSearchHeader, setParamsFilterSearchHeader] = useState({
    page: 1,
    page_size: 50,
  })
  function getSelectedKeys(checkedValues) {
    setAllCategory(false)
    setSelectedKeys(checkedValues)
    if (checkedValues.length == selectedCategory) setAllCategory(true)
  }

  const typingTimeoutRef = useRef(null)
  const _search = (value) => {
    value = value.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/g,
      ''
    )

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      //khi search hoặc filter thi reset page ve 1
      paramsFilterSearchHeader.page = 1

      if (value) {
        paramsFilterSearchHeader.search = value
      } else {
        value = ''
        delete paramsFilterSearchHeader.search
      }
      setParamsFilterSearchHeader({ ...paramsFilterSearchHeader })
    }, 450)
  }

  const addProduct = async (variant) => {
    const listProductNew = [...listProduct]
    var variantNew = {
      product_id: variant.product_id,
      variant_id: variant.variant_id,
      sku: variant.sku,
      title: variant.title,
      price: variant.price,
      unit: variant.unit,
      image: variant.image,
      total_quantity: variant.total_quantity || 0,
      total_inventory: 0,
      real_quantity: variant.type_advanced === 1 ? 1 : 0,
      diff_reason: '',
      system_quantity: variant.total_quantity || 0,
      type_advanced: variant.type_advanced,
      location_check: [],
      imei_check: [],
    }

    for (var i = 0; i < variant.locations.length; i++) {
      variantNew.total_inventory += variant.locations[i].quantity
    }

    if (listProductNew.length === 0) listProductNew.push(variantNew)
    else {
      const indexVariant = listProduct.findIndex((p) => p.variant_id === variant.variant_id)
      if (indexVariant === -1) listProductNew.push(variantNew)
      // else if (listProductNew[indexVariant].type_advanced == 1)
      //   listProductNew[indexVariant].real_quantity += 1
    }
    setListProduct([...listProductNew])
  }
  const _importProduct = (value) => {
    dispatch({ type: ACTION.LOADING, data: true })
    const listProductNew = [...listProduct]
    value.map((item) => {
      let variant = products.find((product) => product.sku === item.variant_sku)
      var variantNew = {
        product_id: variant.product_id,
        variant_id: variant.variant_id,
        sku: variant.sku,
        title: variant.title,
        unit: variant.unit,
        image: variant.image,
        price: variant.price,
        real_quantity: item.real_quantity,
        diff_reason: '',
        system_quantity: 0,
        total_quantity: item.total_quantity,
        location: '',
        checked: [],
        type_advanced: item.type_advanced,
        location_check: item.location_check || [],
        imei_check: item.imei_check || [],
      }
      for (var i = 0; i < variant.locations.length; i++) {
        variantNew.system_quantity += variant.locations[i].quantity
      }
      if(listProductNew.find(item => item.variant_id == variantNew.variant_id)) {
        notification.error({key: variantNew.variant_id, message: `Phiên bản ${variantNew.title} đã được thêm vào danh sách sản phẩm kiểm`})
      } else {
        listProductNew.push(variantNew)
      }
    })

    setListProduct([...listProductNew])
    dispatch({ type: ACTION.LOADING, data: false })
  }
  const deleteDataToCreate = (id) => {
    const cloneData = [...listProduct]
    const indexCloneData = cloneData.findIndex((item) => item.variant_id === id)
    if (indexCloneData !== -1) cloneData.splice(indexCloneData, 1)
    setListProduct(cloneData)
  }
  const _onCreateCheckInventoryNote = async () => {
    try {
      if (listProduct.length === 0) {
        notification.warning({ message: 'Vui lòng thêm sản phẩm vào phiếu kiểm' })
        return
      }
      dispatch({ type: 'LOADING', data: true })
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      let sumSystem = 0
      listProduct.map((product) => (sumSystem += product.system_quantity))
      let sumReal = 0
      listProduct.map((product) => (sumReal += product.real_quantity))

      const body = {
        ...dataForm,
        products: listProduct,
        status: 1,
        branch_id: branchIdApp,
        system_quantity: sumSystem,
        real_quantity: sumReal,
      }

      let res = await createCheckInventoryNote(body)

      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `Thêm phiếu kiểm hàng thành công`,
          })
          history.push({ pathname: ROUTES.STOCK_ADJUSTMENTS })
        } else
          notification.error({
            message: res.data.message || `Thêm phiếu kiểm hàng thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message: res.data.message || `Thêm phiếu kiểm hàng thất bại, vui lòng thử lại!`,
        })

      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'LOADING', data: false })
    }
  }
  const _onCreateCheckInventoryNoteBalance = async () => {
    try {
      if (listProduct.length === 0) {
        notification.warning({ message: 'Vui lòng thêm sản phẩm vào phiếu kiểm' })
        return
      }
      listProduct.map((product) => {
        if (product.type_advanced === 2) {
          if (product.location_check == []) {
            notification.warning({ message: `Vui lòng chọn lô sản phẩm ${product.title}` })
            return
          }
        }
        if (product.type_advanced === 3) {
          if (product.imei_check == []) {
            notification.warning({ message: `Vui lòng chọn imei sản phẩm ${product.title}` })
            return
          }
        }
      })
      dispatch({ type: 'LOADING', data: true })
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      let sumSystem = 0
      listProduct.map((product) => (sumSystem += product.system_quantity))
      let sumReal = 0
      listProduct.map((product) => (sumReal += product.real_quantity))
      const body = {
        ...dataForm,
        products: listProduct,
        status: 2,
        branch_id: branchIdApp,
        system_quantity: sumSystem,
        real_quantity: sumReal,
        title: dataForm.title,
      }
      let res
      if (!location.state) res = await createCheckInventoryNote(body)
      else res = await updateCheckInventoryNote(body, location.state.inventoryNoteId)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `Thêm phiếu kiểm hàng thành công`,
          })
          history.push({ pathname: ROUTES.STOCK_ADJUSTMENTS })
        } else
          notification.error({
            message: res.data.message || `Thêm phiếu kiểm hàng thất bại, vui lòng thử lại!`,
          })
      } else
        notification.error({
          message: res.data.message || `Thêm phiếu kiểm hàng thất bại, vui lòng thử lại!`,
        })

      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'LOADING', data: false })
    }
  }
  const _getProductsByCategories = async (query) => {
    try {
      dispatch({ type: 'LOADING', data: true })
        let productsAdd = []
        for (let category of selectedKeys) {
          for (const item of products) {
            const check = Array.isArray(item?.category_id)
              ? item?.category_id.indexOf(category)
              : -1
            if (check !== -1) {
              var variantNew = {
                product_id: item.product_id,
                variant_id: item.variant_id,
                sku: item.sku,
                title: item.title,
                price: item.price,
                unit: item.unit,
                image: item.image,
                total_quantity: item.total_quantity || 0,
                total_inventory: 0,
                real_quantity: item.type_advanced === 1 ? 1 : 0,
                diff_reason: '',
                system_quantity: item.total_quantity || 0,
                type_advanced: item.type_advanced,
                location_check: [],
                imei_check: [],
              }
              productsAdd.push(variantNew)

            }
          }
        }

        var productsList = [...listProduct]
        for (var i = 0; i < productsAdd.length; i++) {
          let index = productsList.findIndex((p) => productsAdd[i].variant_id == p.variant_id)
          if (index == -1) productsList.push(productsAdd[i])
        }
        setListProduct(productsList)
        setIsModalQuickAddProduct(false)
        setSelectedKeys([])
        setAllCategory(false)
      dispatch({ type: 'LOADING', data: false })
      setIsModalQuickAddProduct(false)
    } catch (err) {
      console.log(err)
      dispatch({ type: 'LOADING', data: false })
    }
  }
  const _getCategories = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getCategories()
      if (res.status === 200) {
        const categoriesNew = []
        res.data.data.map((e) => categoriesNew.push(e))
        res.data.data.map((e) => e.children_category.map((c) => categoriesNew.push(c)))
        res.data.data.map((e) =>
          e.children_category.map((c) => c.children_category.map((k) => categoriesNew.push(k)))
        )
        setCategories(categoriesNew)
        // setSelectedKeys(categoriesNew.map((category) => category.category_id))
        setSelectedCategory(categoriesNew.length)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }

  const _getUsers = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) {
        setUsers(res.data.data.filter((item) => item.status === 1 || item.role_id === 1))
      }
    } catch (err) {
      console.log(err)
    }
  }
  const _editProductNote = (attribute, value, index) => {
    const productsNew = [...listProduct]
    productsNew[index][attribute] = value
    setListProduct([...productsNew])
  }
  const RenderTypeAdvanced = ({ product, index }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    switch (product.type_advanced) {
      case 1:
        return (
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            min={0}
            // value={product.real_quantity || 0}
            defaultValue={product.real_quantity}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '').replaceAll('.', '')
              if (value >= 0) _editProductNote('real_quantity', +value, index)
            }}
          />
        )
      case 2:
        return (
          <div style={{ display: 'flex' }}>
            <InputNumber
              style={{ width: '40%' }}
              value={product.real_quantity || 0}
              disabled={true}
            />
            <ModalProductQuantity
              index={index}
              product={product}
              variant_id={product.variant_id}
              toggle={toggle}
              visible={visible}
              _edit={_editProductNote}
            />
          </div>
        )

      case 3:
        return (
          <div style={{ display: 'flex' }}>
            <InputNumber
              style={{ width: '40%' }}
              value={product.real_quantity || 0}
              disabled={true}
            />
            <ModalProductImei
              product={product}
              index={index}
              variant_id={product.variant_id}
              _edit={_editProductNote}
            />
          </div>
        )

      default:
        return (
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            min={0}
            value={product.real_quantity || 0}
            onBlur={(e) => {
              const value = e.target.value.replaceAll(',', '').replaceAll('.', '')
              if (value >= 0) _editProductNote('real_quantity', +value, index)
            }}
          />
        )
    }
  }
  const columns = [
    {
      title: 'Ảnh sản phẩm',
      render: (text, record) =>
        record.image ? (
          <img
            loading={'lazy'}
            src={record.image.length > 0 ? record.image[0] : ''}
            alt="Ảnh sản phẩm"
            style={{ width: 70, height: 70 }}
          />
        ) : (
          <img alt="Ảnh sản phẩm" style={{ width: 70, height: 70 }}></img>
        ),
    },
    {
      title: 'Mã SKU',
      render: (text, record) => record.sku,
    },
    {
      title: 'Tên Sản phẩm',
      render: (text, record) => record.title,
    },
    {
      title: 'Đơn vị',
      render: (text, record) => record.unit,
    },
    {
      title: 'Tồn kho hệ thống',
      render: (text, record) => record.total_quantity,
    },
    {
      // width: 200,
      title: 'Tồn kho thực tế',
      render: (text, record, index) => (
        <>
          <RenderTypeAdvanced
            product={record}
            index={index}
            listProduct={listProduct}
            setListProduct={setListProduct}
          />
        </>
      ),
    },
    {
      width: 200,
      title: 'Lý do chênh lệch',
      render: (text, record, index) => (
        <Input
          style={{ width: '100%' }}
          value={record.diff_reason}
          onChange={(value) => {
            const listProductNew = [...listProduct]
            listProductNew[index].diff_reason = value.target.value
            setListProduct([...listProductNew])
          }}
          placeholder="Nhập lý do"
        />
      ),
    },
    {
      width: 50,
      title: '',
      render: (text, record) => (
        <Button
          onClick={() => deleteDataToCreate(record.variant_id)}
          type="primary"
          danger
          icon={<DeleteOutlined />}
        />
      ),
    },
  ]
  useEffect(() => {
    _getCategories()
  }, [])
  useEffect(() => {
    if (location.state) {
      form.setFieldsValue({
        ...location.state,
        note_creator_id: location.state.creator,
      })
      setFormUpdate()
    } else {
      form.resetFields()
      setListProduct([])
    }
  }, [])
  useEffect(() =>{
    // if(branchIdApp) {
      dispatch(getProductWarehouse({branchIdApp: branchIdApp}))
      setListProduct([])
      form.setFieldsValue({
        inventorier_id: null,
        title:'',
        note:''
      })
    _getUsers()
    // }
  },[branchIdApp])
  const setFormUpdate = async () => {
    setListProduct(
      location.state.products.map((product) => ({
        ...product,
        ...product.variant_info,
        unit: product.product_info ? product.product_info.unit : '',
      }))
    )
    // _getProducts(location.state.branch_id)
  }
  return (
    <div className="card">
      <Form layout="vertical" form={form}>
        <TitlePage
          isAffix={true}
          title={
            <Link to={ROUTES.STOCK_ADJUSTMENTS}>
              <Row
                align="middle"
                style={{
                  fontSize: 18,
                  color: 'black',
                  fontWeight: 600,
                  width: 'max-content',
                }}
              >
                <ArrowLeftOutlined style={{ marginRight: 5 }} />
                Tạo phiếu kiểm hàng
              </Row>
            </Link>
          }
        >
          <div>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={_onCreateCheckInventoryNote}
            >
              Tạo
            </Button>

            <Permissions
              permissions={[PERMISSIONS.them_phieu_kiem_hang, PERMISSIONS.can_bang_phieu_kiem_hang]}
            >
              <Button
                style={{ minWidth: 100, marginLeft: 5 }}
                size="large"
                type="primary"
                htmlType="submit"
                onClick={_onCreateCheckInventoryNoteBalance}
              >
                {'Tạo và cân bằng'}
              </Button>
            </Permissions>
          </div>
        </TitlePage>
        <div className={styles['block']}>
        <Row>
          <h3>Thông tin phiếu kiểm hàng</h3>
        </Row>
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col span={8}>
            <Form.Item
              label="Tên phiếu"
              name="title"
              rules={[{ required: true, message: 'Vui lòng nhập tên phiếu kiểm!',whitespace: true }]}
            >
              <Input placeholder="Nhập tên" rows={1} style={{ maxWidth: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Nhân viên kiểm"
              name="inventorier_id"
              rules={[{ required: true, message: 'Vui lòng chọn nhân viên kiểm!' }]}
            >
              <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Chọn nhân viên"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  removeUnicode(option.key.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
              >
                {users.map((user, index) => (
                  <Option key={user.first_name + user.last_name} value={user.user_id}>
                    {user.first_name + ' ' + user.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Ghi chú"
              name="note"
              rules={[
                {
                  pattern:
                    /^[a-zA-Z0-9\sàáảạãặẳằắẵẩấầẫậâăÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪẽẻẹèéễếệểềêỉĩịìíọõỏôóốồổỗộơớờởỡợụùúũủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹđ]+$/,
                  message: 'Chỉ cho phép nhập ký tự chữ và số',
                },
              ]}
            >
              <TextArea placeholder="Nhập ghi chú" rows={1} style={{ maxWidth: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </div>
        

        <div className={styles['block']}>
          <h3>Danh sách sản phẩm</h3>
          <Row>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} style={{ display: 'flex' }}>
              <Button
                onClick={() => setIsModalQuickAddProduct(true)}
                style={{ width: '70%' }}
                type="primary"
              >
                Thêm nhóm hàng
              </Button>
              <ImportProduct importProduct={_importProduct} />
            </Col>
            <Col xs={24} sm={12} md={12} lg={16} xl={16}>
              <Select
                notFoundContent={loadingProduct ? <Spin size="small" /> : null}
                dropdownClassName="dropdown-select-search-product"
                allowClear
                showSearch
                clearIcon={<CloseOutlined style={{ color: 'black' }} />}
                suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
                style={{ width: '100%', marginBottom: 15 }}
                placeholder="Thêm sản phẩm vào phiếu kiểm"
                onSearch={_search}
                value={null}
                filterOption={(input, option) =>
                  removeUnicode(option.key.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
                onChange={(value, option) => {
                  if (value !== null && value !== undefined && value !== '') {
                    let index = value.lastIndexOf('/')
                    let resultIndex = value.substring(index + 1)
                    addProduct(products[resultIndex])
                    delete paramsFilterSearchHeader.search
                  }
                }}
                dropdownRender={(menu) => <div>{menu}</div>}
              >
                {products &&
                  products.map((data, index) => (
                    <Select.Option key={data.title + data.sku + data.barcode + '/' + index + ''}>
                      <Row
                        align="middle"
                        wrap={false}
                        style={{
                          padding: '7px 13px',
                          backgroundColor: data.is_choose == true ? 'rgb(231, 233, 251)' : 'unset',
                        }}
                        onClick={(e) => {
                          addProduct(data)
                          e.stopPropagation()
                        }}
                      >
                        <img
                          src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                          alt=""
                          style={{
                            minWidth: 40,
                            minHeight: 40,
                            maxWidth: 40,
                            maxHeight: 40,
                            objectFit: 'cover',
                          }}
                        />

                        <div style={{ width: '100%', marginLeft: 15 }}>
                          <Row wrap={false} justify="space-between">
                            <span
                              style={{
                                maxWidth: 450,
                                marginBottom: 0,
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                              }}
                            >
                              {data.title}
                            </span>
                            <p style={{ marginBottom: 0, fontWeight: 500 }}>
                              {formatCash(data.price)}
                            </p>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <p style={{ marginBottom: 0, color: 'gray' }}>{data.sku}</p>
                          </Row>
                        </div>
                      </Row>
                    </Select.Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Table
            scroll={{ y: 400 }}
            pagination={false}
            columns={columns}
            size="small"
            dataSource={listProduct}
          />
        </div>
      </Form>

      <Modal
        title="Chọn nhiều sản phẩm"
        visible={isModalQuickAddProduct}
        onOk={() => _getProductsByCategories({ category_id: selectedKeys.join('---') })}
        okText="Thêm vào danh sách"
        onCancel={() => {
          setIsModalQuickAddProduct(false)
          setSelectedKeys([])
        }}
        width="70%"
      >
        <Checkbox
          checked={allCategory}
          onChange={(e) => {
            setAllCategory(e.target.checked)
            if (e.target.checked)
              setSelectedKeys(categories.map((category) => category.category_id))
            else setSelectedKeys([])
          }}
        >
          Tất cả nhóm sản phẩm
        </Checkbox>

        <Panel className="edit-collapse-panel" key="1" />
        <Checkbox.Group value={selectedKeys} style={{ width: '100%' }} onChange={getSelectedKeys}>
          <Row gutter={[0, 15]}>
            {categories.map((category) => (
              <Col span={6}>
                <Checkbox value={category.category_id}>{category.name}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Modal>
    </div>
  )
}
