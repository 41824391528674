import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import styles from './customer-type.module.scss'
import { useSelector } from 'react-redux'
import { compare } from 'utils'

//antd
import {
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  Table,
  notification,
  Space,
  Popconfirm,
  Modal,
  Affix,
} from 'antd'

//icons
import { DeleteOutlined, PlusCircleOutlined, EditFilled } from '@ant-design/icons'

//components
import CustomerTypeForm from './customer-type-form'
import SettingColumns from 'components/setting-columns'
import columnType from './columns'
import TitlePage from 'components/title-page'

//apis
import {deleteCustomerType, getCustomerType } from 'apis/customer-type'

const { Option } = Select
const { RangePicker } = DatePicker

export default function CustomerType() {

  const typingTimeoutRef = useRef(null)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [columns, setColumns] = useState([])
  const [countCustomerType, setCountCustomerType] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 20 })
  const [tableLoading, setTableLoading] = useState(false)

  const [customerTypes, setCustomerTypes] = useState([])

  const [valueSearch, setValueSearch] = useState('')
  const [optionSearch, setOptionSearch] = useState('name')

  const [valueDateSearch, setValueDateSearch] = useState(null) //dùng để hiện thị date trong filter by date
  const [valueTime, setValueTime] = useState() //dùng để hiện thị value trong filter by time
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({})
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)

  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const ModalTypeCustomer = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          style={{ top: 20 }}
          onCancel={toggle}
          width={800}
          footer={null}
          title={`${record ? 'Cập nhật' : 'Tạo'} nhóm `}
          visible={visible}
        >
          <CustomerTypeForm
            record={record}
            close={toggle}
            text={record ? 'Lưu' : 'Tạo'}
            reload={_getCustomerTypes}
          />
        </Modal>
      </>
    )
  }

  const _getCustomerTypes = async () => {
    try {
      setTableLoading(true)
      const res = await getCustomerType({...paramsFilter, branch_id: branchIdApp})
      console.log(res)
      if (res.status === 200) {
        setCustomerTypes(res.data.data)
        setCountCustomerType(res.data.count)
      }
      setTableLoading(false)
    } catch (error) {
      console.log(error)
      setTableLoading(false)

    }
  }
  const _deleteCustomerType = async (id) => {
    try {
      setTableLoading(true)
      const res = await deleteCustomerType({type_id: id, branch_id:branchIdApp})
      console.log(res)
      setTableLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getCustomerTypes()
          notification.success({ message: 'Xóa Loại khách hàng thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xóa Loại khách hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa Loại khách hàng thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
      setTableLoading(false)
    }
  }

  useEffect(() => {
    _getCustomerTypes()
  }, [paramsFilter, branchIdApp])


  return (
    <div className="card">
        <TitlePage title="Quản lý loại khách hàng" isAffix>
          <Space>
            <SettingColumns
              columnsDefault={columnType}
              setColumns={setColumns}
              columns={columns}
              nameColumn="columnType"
            />

            <ModalTypeCustomer>
                <Button
                  size="large"
                  icon={<PlusCircleOutlined style={{ fontSize: '1rem' }} />}
                  type="primary"
                >
                  Thêm Loại Khách Hàng
                </Button>
            </ModalTypeCustomer>

          </Space>
        </TitlePage>
      <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Row wrap={false} style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: 5,backgroundColor: 'white' }}>
            <Input
              style={{ width: '100%', borderRight: '1px solid #d9d9d9' }}
              placeholder="Tìm kiếm theo..."
              value={valueSearch}
              onChange={(e) => onSearch(e)}
              allowClear
              bordered={false}
            />
            <Select
              style={{ width: 180 }}
              value={optionSearch}
              onChange={(value) => {
                delete paramsFilter[optionSearch]
                setOptionSearch(value)
              }}
              bordered={false}
            >
              <Option value="name">Tên Nhóm</Option>
              <Option value="code">Mã Nhóm</Option>
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <Row style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: 5, backgroundColor:'white' }}>
              <Select
                open={isOpenSelect}
                onBlur={() => {
                  if (isOpenSelect) toggleOpenSelect()
                }}
                onClick={() => {
                  if (!isOpenSelect) toggleOpenSelect()
                }}
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Lọc theo ngày tạo"
                optionFilterProp="children"
                bordered={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={valueTime}
                onChange={async (value) => {
                  setValueTime(value)

                  paramsFilter.page = 1

                  //xoa params search date hien tai
                  const p = Object.keys(valueDateTimeSearch)
                  if (p.length) delete paramsFilter[p[0]]

                  setValueDateSearch(null)
                  delete paramsFilter.from_date
                  delete paramsFilter.to_date

                  if (isOpenSelect) toggleOpenSelect()

                  if (value) {
                    const searchDate = Object.fromEntries([[value, true]]) // them params search date moi

                    setParamsFilter({ ...paramsFilter, ...searchDate })
                    setValueDateTimeSearch({ ...searchDate })
                  } else {
                    setParamsFilter({ ...paramsFilter })
                    setValueDateTimeSearch({})
                  }
                }}
                dropdownRender={(menu) => (
                  <>
                    <RangePicker
                      onFocus={() => {
                        if (!isOpenSelect) toggleOpenSelect()
                      }}
                      onBlur={() => {
                        if (isOpenSelect) toggleOpenSelect()
                      }}
                      value={valueDateSearch}
                      onChange={(dates, dateStrings) => {
                        //khi search hoac filter thi reset page ve 1
                        paramsFilter.page = 1

                        if (isOpenSelect) toggleOpenSelect()

                        //nếu search date thì xoá các params date
                        delete paramsFilter.to_day
                        delete paramsFilter.yesterday
                        delete paramsFilter.this_week
                        delete paramsFilter.last_week
                        delete paramsFilter.last_month
                        delete paramsFilter.this_month
                        delete paramsFilter.this_year
                        delete paramsFilter.last_year

                        //Kiểm tra xem date có được chọn ko
                        //Nếu ko thì thoát khỏi hàm, tránh cash app
                        //và get danh sách order
                        if (!dateStrings[0] && !dateStrings[1]) {
                          delete paramsFilter.from_date
                          delete paramsFilter.to_date

                          setValueDateSearch(null)
                          setValueTime()
                        } else {
                          const dateFirst = dateStrings[0]
                          const dateLast = dateStrings[1]
                          setValueDateSearch(dates)
                          setValueTime(`${dateFirst} -> ${dateLast}`)

                          dateFirst.replace(/-/g, '/')
                          dateLast.replace(/-/g, '/')

                          paramsFilter.from_date = dateFirst
                          paramsFilter.to_date = dateLast
                        }

                        setParamsFilter({ ...paramsFilter })
                      }}
                      style={{ width: '100%' }}
                    />
                    {menu}
                  </>
                )}
              >
                <Option value="today">Hôm nay</Option>
                <Option value="yesterday">Hôm qua</Option>
                <Option value="this_week">Tuần này</Option>
                <Option value="last_week">Tuần trước</Option>
                <Option value="this_month">Tháng này</Option>
                <Option value="last_month">Tháng trước</Option>
                <Option value="this_year">Năm này</Option>
                <Option value="last_year">Năm trước</Option>
              </Select>
          </Row>
        </Col>
      </Row>
      <Table
        style={{ width: '100%', marginTop: 10 }}
        rowKey="type_id"
        scroll={{ y: 400 }}
        loading={tableLoading}
        columns={columns.map((column) => {
          if (column.key === 'stt')
              return {
                ...column,
              render: (text, record, index) => index + 1,
            }
          if (column.key === 'code')
            return {
              ...column,
              render: (text, record) => (
                <ModalTypeCustomer record={record}>
                  <a>{record.code}</a>
                </ModalTypeCustomer>
              ),
              sorter: (a, b) => compare(a, b, 'code'),
            }
          if (column.key === 'name')
            return {
              ...column,
              render: (text, record) => <p>{record.name}</p>,
              sorter: (a, b) => compare(a,b, 'name')
            }
          if (column.key === 'description')
            return {
              ...column,
              render: (text, record) => (record.description),
              sorter: (a, b) => compare(a, b, 'description'),
            }
          if (column.key === 'discount')
            return {
              ...column,
              render: (text, record) => record.discount + " %" || 0 + " %",
              sorter: (a, b) => compare(a, b, 'discount'),
            }
          if (column.key === 'create_date')
            return {
              ...column,
              render: (text, record) =>
                record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
              sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
            }
          if (column.key === 'action')
            return {
              ...column,
              render: (text, record) => (
                <div className={styles['display']}>
                  {/* <Permission permissions={[PERMISSIONS.xoa_loai_khach_hang]}> */}
                    <Popconfirm
                      title="Bạn có muốn xóa loại khách hàng này không?"
                      cancelText="Từ chối"
                      okText="Đồng ý"
                      onConfirm={() => _deleteCustomerType(record.type_id)}
                    >
                      <Button danger type="primary" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  {/* </Permission> */}
                </div>
              ),
            }

          return column
        })}
        dataSource={customerTypes}
        size="small"
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: [20, 30, 40, 50, 70, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countCustomerType,
        }}
      />
    </div>
  )
}
