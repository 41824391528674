import { ACTION } from "consts"

  const loading = (state = false, action) => {
    switch (action.type) {
      case 'LOADING_SCREEN':
        return action.data
      default:
        return state
    }
  }
  
  export default loading
  