import React, { useState } from 'react'
import styles from './style.module.scss'
import { IMAGE_DEFAULT } from 'consts'
import { formatCash } from 'utils'

import { Row, InputNumber, Col, Tooltip } from 'antd'
//icons antd
import { DeleteOutlined,InfoCircleTwoTone } from '@ant-design/icons'
//component
import ModalProductQuantity from './QuantityProductLocation'
import ModalProductImei from './QuantityImei'

export const TableProduct = (props) => {
  const { products, users, current, listProduct, setListProduct, sampleList, setSampleList,_getSampleList, productArr } = props

  const _editProductNote = (attribute, value, index) => {
    const productsNew = [...listProduct]
    productsNew[index][attribute] = value
    setListProduct([...productsNew])
  }
  const deleteProduct = (id) => {
    let cloneData = [...listProduct]
    let sample_list = [...sampleList]
    const indexCloneData = cloneData.findIndex((item) => item.variant_id === id)
    if (indexCloneData !== -1) cloneData.splice(indexCloneData, 1)
    const indexSample = sample_list.findIndex((e) => e === id)
    if (indexSample !== -1) sample_list.splice(indexSample, 1)
    sample_list = [...new Set([...sample_list, ..._getSampleList()])]
    setSampleList(sample_list)
    setListProduct(cloneData)
  }
  const RenderTypeAdvanced = ({ product, index }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    switch (product.type_advanced) {
      case 2:
        return (
          <ModalProductQuantity
            index={index}
            product={product}
            variant_id={product.variant_id}
            toggle={toggle}
            visible={visible}
            _edit={_editProductNote}
          />
        )

      case 3:
        return (
          <ModalProductImei
            product={product}
            index={index}
            variant_id={product.variant_id}
            _edit={_editProductNote}
          />
        )

      default:
        return <></>
    }
  }
  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        wrap={false}
        className={styles['list-product-header']}
      >
        <Col span={1} className={styles['header-name']}>
          STT
        </Col>
        <Col span={2} className={styles['header-name']}>
          Ảnh sản phẩm
        </Col>
        <Col span={3} className={styles['header-name']}>
          Mã SKU
        </Col>
        <Col span={4} className={styles['header-name']}>
          Tên sản phẩm
        </Col>
        <Col span={2} className={styles['header-name']}>
          Đơn vị
        </Col>
        <Col span={3} className={styles['header-name']}>
          Người kiểm
        </Col>
        <Col span={2} className={styles['header-name']}>
          Giá bán
        </Col>
        <Col span={3} className={styles['header-name']}>
          Số lượng
        </Col>
        <Col span={3} className={styles['header-name']}>
          Hành động
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          overflow: 'scroll',
          height: 'auto',
          maxHeight: '450px',
        }}
      >
        {products.map((product, index) => {
          return (
            <Row key={index}>
              <Row
                align="middle"
                justify="space-between"
                wrap={false}
                className={styles['transport-product__item']}
                id={`variant_${product.variant_id}`}
              >
                <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      marginBottom: 0,
                      width: 30,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {index + 1}
                  </div>
                </Col>
                <Col span={2}>
                  <img
                    loading={'lazy'}
                    src={product?.image[0] ? product?.image[0] : productArr.find(e => e.variant_id === product.variant_id)?.image[0] ? Object(productArr.find(e => e.variant_id === product.variant_id).image[0]) : IMAGE_DEFAULT}
                    alt="Ảnh sản phẩm"
                    style={{ width: 70, height: 70, padding: 10 }}
                  />
                </Col>
                <Col span={3}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className={styles['transport-product__item-name']}>
                          {product.sku}
                        </span>
                        {sampleList && !sampleList.includes(product.variant_id) ? (
                          <Tooltip placement="top" title="Sản phẩm không phải hàng trưng bày">
                            <InfoCircleTwoTone style={{ fontSize: 16 }} twoToneColor={"red"} />
                          </Tooltip>
                        ) : null}
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className={styles['transport-product__item-name']}>
                          {product.title}
                        </span>
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col span={2}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {product.unit}
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col span={3}>
                  {product.check_by &&
                    product.check_by.map((item) => (
                      <div
                        style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}
                      >
                        <span>
                          {Object(
                            users.find((emp) => emp.user_id === item.user_id).first_name
                          ).charAt()}
                          .{Object(users.find((emp) => emp.user_id === item.user_id)).last_name}
                        </span>
                      </div>
                    ))}
                </Col>
                <Col span={2}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {formatCash(product.price)}
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col span={3}>
                  <>
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.', '')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      // min={0}
                      value={product.real_quantity || 0}
                      onChange={(value) => {
                        const listProductNew = [...listProduct]
                        let index_new = listProductNew.findIndex(item => item.variant_id === product.variant_id)
                        listProductNew[index_new].real_quantity = value
                        setListProduct([...listProductNew])
                      }}
                      disabled={
                        product.quantity - (product.total_refund_quantity || 0) === 0 ||
                        product.type_advanced === 2 ||
                        product.type_advanced === 3 ||
                        current >= 1
                          ? true
                          : false
                      }
                    />
                    <RenderTypeAdvanced
                      product={product}
                      index={index}
                      listProduct={listProduct}
                      setListProduct={setListProduct}
                    />
                  </>
                </Col>
                <Col span={2}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {current === 0 ? (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <DeleteOutlined
                            onClick={() => deleteProduct(product.variant_id)}
                            style={{ color: 'red' }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Row>
          )
        })}
      </div>
    </>
  )
}
