export const PERMISSIONS_APP = [
  {
    pParent: 'tong_quan',
  },
  {
    pParent: 'ban_hang',
  },
  {
    pParent: 'don_hang',
    pChildren: [
      {
        pParent: 'danh_sach_don_hang',
        pChildren: ['tao_don_hang', 'chi_tiet_don_hang', 'cap_nhat_don_hang', 'export_don_hang'],
      },
      {
        pParent: 'tra_hang',
        pChildren: [
          'xem_chi_tiet_tra_hang',
          'tao_phieu_tra_hang',
          'cap_nhat_phieu_tra_hang',
          'export_phieu_tra_hang',
        ],
      },

    ],
  },
  {
    pParent: 'van_chuyen',
    pChildren: [
      {
        pParent: 'quan_li_giao_hang',
        pChildren: ['xem_chi_tiet_phieu_giao_hang', 'cap_nhat_giao_hang'],
      },
      {
        pParent: 'doi_soat_van_chuyen',
        pChildren: [
          'xem_chi_tiet_phieu_doi_soat_van_chuyen',
          'them_phieu_doi_soat_van_chuyen',
          'cap_nhat_doi_soat_van_chuyen',
          'xoa_phieu_doi_soat_van_chuyen',
          'export_phieu_doi_soat_van_chuyen',
        ],
      },
      {
        pParent: 'doi_tac_van_chuyen',
        pChildren: [
          'them_doi_tac_van_chuyen',
          'cap_nhat_doi_tac_van_chuyen',
          'xoa_doi_tac_van_chuyen',
        ],
      },
    ],
  },
  {
    pParent: 'san_pham',
    pChildren: [
      {
        pParent: 'quan_li_san_pham',
        pChildren: [
          'chi_tiet_san_pham',
          'them_san_pham',
          'cap_nhat_san_pham',
          'in_ma_san_pham',
          'mo_ban_san_pham',
          'xoa_san_pham',
          'export_san_pham',
        ],
      },
      {
        pParent: 'nhom_san_pham',
        pChildren: [
          'xem_chi_tiet_nhom_san_pham',
          'tao_nhom_san_pham',
          'cap_nhat_nhom_san_pham',
          'xoa_nhom_san_pham',
        ],
      },
      { pParent: 'lich_su_thay_doi_gia', isParent: true },
      { pParent: 'lich_su_tra_hang', isParent: true },
      { pParent: 'lich_su_ban_hang', isParent: true },
    ],
  },
  {
    pParent: 'quan_li_kho',
    pChildren: [
      {
        pParent: 'dieu_chinh_gia',
        pChildren: [
          'xem_chi_tiet_phieu_dieu_chinh_gia',
          'tao_phieu_dieu_chinh',
          'cap_nhat_phieu_dieu_chinh',
        ],
      },
      {
        pParent: 'nhap_hang',
        pChildren: [
          'xem_chi_tiet_phieu_nhap_hang',
          'them_phieu_nhap_hang',
          'cap_nhat_phieu_nhap_hang',
          'xoa_phieu_nhap_hang',
          'export_nhap_hang',
        ],
      },
      {
        pParent: 'kiem_hang_co_ban',
        pChildren: [
          'xem_chi_tiet_phieu_kiem_hang_co_ban',
          'them_phieu_kiem_hang_co_ban',
          'cap_nhat_phieu_kiem_hang_co_ban',
          'can_bang_phieu_kiem_hang_co_ban',
          'xoa_phieu_kiem_hang_co_ban'
        ],
      },
      {
        pParent: 'kiem_hang_nang_cao',
        pChildren: [
          'xem_chi_tiet_phieu_kiem_hang_nang_cao',
          'them_phieu_kiem_hang_nang_cao',
          'cap_nhat_phieu_kiem_hang_nang_cao',
          'can_bang_phieu_kiem_hang_nang_cao',
          'xoa_phieu_kiem_hang_nang_cao'
        ],
      },
      {
        pParent: 'phieu_chuyen_hang',
        pChildren: [
          'xem_chi_tiet_phieu_chuyen_hang',
          'tao_phieu_chuyen_hang',
          'cap_nhat_phieu_chuyen_hang',
          'xoa_phieu_chuyen_hang',
          'export_phieu_chuyen_hang',
        ],
      },
    ],
  },
  {
    pParent: 'quan_li_nhan_vien',
    pChildren: [
      {
        pParent: 'danh_sach_nhan_vien',
        pChildren: [
          'xem_chi_tiet_nhan_vien',
          'them_nhan_vien',
          'cap_nhat_nhan_vien',
          'xoa_nhan_vien',
        ],
      },
      {
        pParent: 'lich_lam_viec',
        pChildren: [
          'xem_chi_tiet_lich_lam_viec',
          'cau_hinh_ca_lam_viec',
          'them_lich_lam_viec',
          'cap_nhat_lich_lam_viec',
          'xoa_lich_lam_viec',
        ],
      },
    ],
  },

  {
    pParent: 'quan_li_nha_cung_cap',
    pChildren: [
      'them_nha_cung_cap',
      'cap_nhat_nha_cung_cap',
      'xoa_nha_cung_cap',
      'export_nha_cung_cap',
    ],
  },
  {
    pParent: 'quan_li_khach_hang',
    pChildren: [
      'xem_chi_tiet_khach_hang',
      'them_khach_hang',
      'cap_nhat_khach_hang',
      'xoa_khach_hang',
      'export_khach_hang',
    ],
  },

  {
    pParent: 'danh_sach_bao_cao',
    pChildren: [
      {
        pParent: 'bao_cao_ton_kho',
        isParent: true,
      },
      {
        pParent: 'bao_cao_xuat_nhap_ton',
        isParent: true,
      },
      {
        pParent: 'bao_cao_ban_hang',
        isParent: true,
      },
      {
        pParent: 'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
        isParent: true,
      },
      {
        pParent: 'bao_cao_ton_kho_theo_lo',
        isParent: true,
      },

      {
        pParent: 'bao_cao_thu_chi',
        pChildren: [
          'them_phieu_thu_chi',
          'cap_nhat_phieu_thu_chi',
          'xoa_phieu_thu_chi',
          'export_phieu_thu_chi',
        ],
      },
      {
        pParent: 'chot_ca',
        pChildren: ['tao_phieu_chot_ca'],
      },
      {
        pParent: 'loai_phieu_thu_chi',
        pChildren: [
          'them_loai_phieu_thu_chi',
          'cap_nhat_loai_phieu_thu_chi',
          'xoa_loai_phieu_thu_chi',
        ],
      },
    ],
  },
  {
    pParent: 'cau_hinh',
    //thiếu ql cửa hàng permission them_cua_hang
    pChildren: [
      {
        pParent: 'cau_hinh_chi_nhanh',
        pChildren: ['them_chi_nhanh', 'cap_nhat_chi_nhanh'],
      },
      {
        pParent: 'cau_hinh_phan_quyen',
        pChildren: [
          'them_vai_tro_phan_quyen',
          'cap_nhat_vai_tro_phan_quyen',
          'xoa_vai_tro_phan_quyen',
        ],
      },
      {
        pParent: 'cau_hinh_hoa_don',
        pChildren: ['cau_hinh_mau_hoa_don'],
      },
      {
        pParent: 'cau_hinh_bao_hanh',
        pChildren: ['them_bao_hanh', 'cap_nhat_bao_hanh', 'xoa_bao_hanh', 'export_bao_hanh'],
      },
      {
        pParent: 'cau_hinh_thue',
        pChildren: ['them_thue', 'cap_nhat_thue', 'xoa_thue'],
      },
      {
        pParent: 'cau_hinh_phuong_thuc_thanh_toan',
        pChildren: [
          'them_phuong_thuc_thanh_toan',
          'cap_nhat_phuong_thuc_thanh_toan',
          'xoa_phuong_thuc_thanh_toan',
        ],
      },
      {
        pParent: 'cau_hinh_khuyen_mai',
        pChildren: [
          'xem_chi_tiet_khuyen_mai',
          'them_khuyen_mai',
          'cap_nhat_khuyen_mai',
          'xoa_khuyen_mai',
        ],
      },
      {
        pParent: 'cau_hinh_tich_diem',
        pChildren: ['cap_nhat_tich_diem', 'xem_danh_sach_khach_hang_tich_diem'],
      },
      { pParent: 'cau_hinh_nhap_xuat_file', isParent: true },
      { pParent: 'cau_hinh_nhat_ki_hoat_dong', isParent: true },
    ],
  },
  {
    pParent: 'bo_loc',
    pChildren: [
      'hom_nay',
      'hom_qua',
      'tuan_nay',
      'tuan_truoc',
      'thang_nay',
      'thang_truoc',
      'nam_nay',
      'nam_truoc',
      'tuy_chon',
    ],
  },
]
