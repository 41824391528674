import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES, PERMISSIONS } from 'consts'
const ConnectLink = ({ source, code, order_id }) => {
  const statusLinks = [
    {
      status: 'IMPORT',
      pathname: `${ROUTES.IMPORT_INVENTORY_UPDATE + '/' + code}`,
      permissions: `${PERMISSIONS.xem_chi_tiet_phieu_nhap_hang}`,
    },
    {
      status: 'SALE',
      pathname: `${ROUTES.ORDER_UPDATE + '/' + order_id}`,
      permissions: `${PERMISSIONS.chi_tiet_don_hang}`,
    },
    {
      status: 'TRANSPORTIMPORT',
      pathname: `${ROUTES.SHIPPING_PRODUCT_UPDATE + '/' + code}`,
      permissions: `${PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang}`,
    },
    {
      status: 'TRANSPORTEXPORT',
      pathname: `${ROUTES.SHIPPING_PRODUCT_UPDATE + '/' + code}`,
      permissions: `${PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang}`,
    },
    {
      status: 'REFUND',
      pathname: `${ROUTES.REFUND_DETAIL + '/' + code}`,
      permissions: `${PERMISSIONS.xem_chi_tiet_tra_hang}`,
    },
  ]

  return (
    <Link
      permissions={Object(statusLinks.find((item) => item.status === source)).permissions}
      style={{ color: 'green' }}
      to={{
        pathname: Object(statusLinks.find((item) => item.status === source)).pathname,
      }}
      target='_blank'
    >
      {'#' + code}
    </Link>
  )
}

export default ConnectLink
