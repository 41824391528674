import { notification } from "antd"
import { addPriceAdjustments } from "apis/price-adjustments"

export const SaveSystem = async (productChange, history, setLoading, note, branchIdApp) => {
    try {
        if(productChange?.length == 0) {
            return notification.error({key: 'error fix price 3', message: 'Vui lòng chọn sản phẩm'})
        }
        setLoading(true)
        let new_body = {
            lists: productChange,
            note: note,
            branch_id: branchIdApp
        }
        let res = await addPriceAdjustments(new_body)
        if(res.status === 200) {
            history.push('/price-adjustments')
            setLoading(false)
            return notification.success({key: 'success fix price', message: 'Đổi giá thành công'})
        } else {
            setLoading(false)
            return notification.error({key: 'error fix price', message: 'Đổi giá thất bại'})
        }
    } catch (error) {
        setLoading(false)
        return notification.error({key: 'error fix price 2', message: error})
    }
}