const initialState = 0
  const indexInvoice = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_INDEXINVOICE':
        const invoicesNew = action.data
        return  invoicesNew 
  
      default:
        return state
    }
  }
  
  export default indexInvoice
  