import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, DatePicker, Input, Row, Select, Table } from 'antd'
import TitlePage from 'components/title-page'
import { FILTER_SIZE, ROUTES } from 'consts'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

//apis
import { getFileHistory } from 'apis/action'
import FilterDate from 'components/filter-date'
import { useSelector } from 'react-redux'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function ImportReportFile() {
  const history = useHistory()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [valueTime, setValueTime] = useState()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
  })
  const [fileActionList, setFileActionList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('file_name')
  const [totalRecord, setTotalRecord] = useState(0)
  const typingTimeoutRef = useRef()

  const _search = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1
      delete paramsFilter.file_name
      delete paramsFilter.action_name
      if (value) paramsFilter[searchKey] = value
      else delete paramsFilter[searchKey]

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }
  const _getFileAction = async () => {
    try {
      setTableLoading(true)
      const res = await getFileHistory({...paramsFilter, branch_id: branchIdApp})
      if (res.data.success) {
        setFileActionList(res.data.data)
        setTotalRecord(res.data.count)
      }
      setTableLoading(false)
    } catch (err) {
      setTableLoading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    if(paramsFilter.check) _getFileAction()
  }, [paramsFilter])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setParamsFilter({...paramsFilter, from_date: result.from_date, to_date: result.to_date, check:1})
    setValueTime(result.valueDate)
  },[dataUser])
  const columns = [
    {
      title: 'STT',
      render(data, record, index) {
        return (paramsFilter.page - 1) * paramsFilter.page_size + index + 1
      },
      width: 70,
      align: 'center',
    },
    {
      title: 'Tên file',
      dataIndex: 'file_name',
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'creator_info',
      render(data) {
        return data && data.first_name + ' ' + data.last_name
      },
    },
    {
      title: 'Thời gian thao tác',
      dataIndex: 'create_date',
      render: (data) => moment(data).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Thao tác',
      dataIndex: 'action_name',
    },
    {
      title: 'Hành động',
      dataIndex: 'links',
      render: (data) => (
        <a href={data[0]} download={true} style={{ color: '#0017E3' }}>
          Tải xuống
        </a>
      ),
    },
  ]

  return (
    <div className="card">
      <TitlePage
        isAffix={true}
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Quản lý xuất/nhập
          </Row>
        }
      ></TitlePage>

      <Row justify="space-between" gutter={[16, 16]} style={{ margin: '20px 0px' }}>
        <Col
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={14}
          style={{
            marginTop: '1rem',
          }}
        >
          <Row>
            <Col span={12}>
              <FilterDate
              size='default'
                bordered={true}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                title='nhập/xuất File'
                valueTime={valueTime}
                setValueTime={setValueTime}
              />
            </Col>
            <Col span={12}>
              <Select
                showSearch
                size={FILTER_SIZE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%'}}
                allowClear
                bordered={true}
                placeholder="Lọc theo thao tác"
                //   suffixIcon={<SuffixIconCustom />}
                onChange={(e) => setParamsFilter({ ...paramsFilter, type: e })}
                defaultValue={''}
              >
                <Select.Option value="">Tất cả thao tác</Select.Option>
                <Select.Option value="IMPORT">Nhập file</Select.Option>
                <Select.Option value="EXPORT">Xuất file</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size="small"
        scroll={{ y: '56vh' }}
        style={{ width: '100%', marginTop: '1rem' }}
        columns={columns}
        dataSource={fileActionList}
        loading={tableLoading}
        pagination={{
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          total: totalRecord,
          onChange: (page, page_size) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: page_size }),
        }}
      />
    </div>
  )
}
