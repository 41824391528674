import React, { useEffect, useState, useRef } from 'react'
import { ROUTES, ACTION, IMAGE_DEFAULT, PERMISSIONS, PAGE_SIZE } from 'consts'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { compare, removeUnicode } from 'utils'
import moment from 'moment'

//components
import TitlePage from 'components/title-page'

//antd
import {
  Row,
  Col,
  Button,
  Space,
  Input,
  Select,
  Table,
  Popconfirm,
  notification,
  Affix,
} from 'antd'

//icons
import { DeleteOutlined } from '@ant-design/icons'

//apis
import { getCategories, deleteCategory } from 'apis/category'
import { getStaff } from 'apis/manage-staff'
import CategoryCreate from 'views/category-create'
import FilterDate from 'components/filter-date/filter'
import { DefaultFilterData } from 'utils/DefaultFilterData'
const { Option } = Select
export default function Category() {
  const dispatch = useDispatch()
  const typingTimeoutRef = useRef(null)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [employees, setEmployees] = useState([])

  const [categories, setCategories] = useState([])
  const [categorieShow, setCategorieShow] = useState([])

  const [countCategories, setCountCategories] = useState(0)
  const [visible, setVisible] = useState(false)
  const [valueTime, setValueTime] = useState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 20 })
  const [valueSearch, setValueSearch] = useState('')
  const [paramsFilter, setParamsFilter] = useState({})

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200)
        setEmployees(res.data.data.filter((e) => e.status === 1 || e.role_id === 1))
    } catch (error) {
      console.log(error)
    }
  }

  const _onSearch = (e) => {
    setValueSearch(e)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (e) paramsFilter['name'] = e.trim()
      else delete paramsFilter['name']

      setParamsFilter({ ...paramsFilter })
    }, 500)
  }
  const onChangeUserFilter = (value) => {
    if (value) paramsFilter.creator_id = value
    else delete paramsFilter.creator_id
    setParamsFilter({ ...paramsFilter })
  }

  const _deleteCategory = async (category_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteCategory({ category_id: category_id, branch_id: branchIdApp })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa nhóm sản phẩm thành công!' })
          _getCategories()
        } else
          notification.error({
            message: 'Xóa nhóm sản phẩm thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: 'Xóa nhóm sản phẩm thất bại, vui lòng thử lại!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _onClearFilters = () => {
    setValueSearch('')
    setParamsFilter({page: 1, page_size: PAGE_SIZE})
    setValueTime()
  }
  useEffect(() => {
    let arr = [...categories]
    const filtered = arr.filter((current) => {
      let matchCreator = true
      let matchTime = true
      let matchSearch = true
      if (paramsFilter.creator_id && paramsFilter.creator_id !== 'all')
        matchCreator = current?.creator_id === paramsFilter.creator_id
      if (paramsFilter.name)
        matchSearch = removeUnicode(current.name.toString())
          .toLowerCase()
          .trim()
          .includes(removeUnicode(paramsFilter.name).toLowerCase().trim())

      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date
      return matchCreator && matchSearch && matchTime
    })
    setCategorieShow(filtered)
    setCountCategories(filtered.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter])
  const _getCategories = async () => {
    try {
      setLoading(true)
      const res = await getCategories({ branch_id: branchIdApp})
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setCategories(result.data)
        setValueSearch('')
        if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
          })
          setValueTime()
        } else {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
            from_date: result.defaultTime.from_date,
            to_date: result.defaultTime.to_date,
          })
          setValueTime(result.valueDate)
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const columnsParent = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      render: (text, record, index) => (pagination.page - 1) * pagination.pageSize + index + 1,
    },
    {
      title: 'Mã nhóm',
      align: 'center',
      dataIndex: 'code',
      sorter: (a, b) => compare(a, b, 'code'),
    },
    {
      title: 'Hình ảnh',
      align: 'center',
      render: (text, record) => (
        <img
          src={record?.image?.length ? record.image : IMAGE_DEFAULT}
          alt=""
          style={{ width: 70, height: 70 }}
        />
      ),
    },
    {
      title: 'Tên nhóm',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'name'),
      render: (text, record) =>
        permissions.includes(PERMISSIONS.xem_chi_tiet_nhom_san_pham) ? (
          <Link to={{ pathname: ROUTES.DETAIL_CATEGORY + '/' + record.code, state: record }}>
            {record.name || ''}
          </Link>
        ) : (
          <div
            style={{ color: '#5F73E2', cursor: 'pointer' }}
            onClick={() => notification.warning({ message: 'Permission denied!' })}
          >
            {record.name}
          </div>
        ),
    },
    {
      title: 'Sản phẩm trong nhóm',
      align: 'center',
      render: (text, record) => record.totalProducts,
      // render: (text, record) => <ModalProductsCategory record={record} />,
    },

    {
      title: 'Ngày tạo',
      render: (text, record) => moment(record.create_date).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Người tạo',
      align: 'center',
      render: (text, record) =>
        record._creator_info &&
        `${record._creator_info.first_name} ${record._creator_info.last_name}`,
    },

    {
      title: 'Hành động',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <Space>
          {permissions.includes(PERMISSIONS.xoa_nhom_san_pham) ? (
            <Popconfirm
              onConfirm={() => _deleteCategory(record.category_id)}
              title="Bạn có muốn xóa nhóm sản phẩm này không?"
              okText="Đồng ý"
              cancelText="Từ chối"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          ) : (
            <Button disabled type="primary" danger icon={<DeleteOutlined />} />
          )}
        </Space>
      ),
    },
  ]
  useEffect(() => {
    _getEmployees()
    _getCategories()
  }, [branchIdApp, dataUser])

  return (
    <div className="card">
        <TitlePage title="Nhóm sản phẩm" isAffix>
          <div>
            <Button
              onClick={_onClearFilters}
              type="primary"
              size="large"
              danger
              style={{
                display:
                  valueSearch !== '' || valueTime !== ''
                    ? 'inline'
                    : 'none',
                marginRight: 10,
              }}
            >
              Xóa bộ lọc
            </Button>
            <>
              <Button
                disabled={permissions.includes(PERMISSIONS.tao_nhom_san_pham) ? false : true}
                size="large"
                type="primary"
                onClick={() => setVisible(true)}
              >
                Tạo nhóm sản phẩm
              </Button>
            </>
          </div>
        </TitlePage>
      <CategoryCreate visible={visible} setVisible={setVisible} getCategories={_getCategories} />
      {permissions.includes(PERMISSIONS.xem_danh_sach_nhom_san_pham) ? (
        <>
          <div style={{ marginBottom: 15}}>
            <Row style={{ marginTop: '1rem'}}>
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <Input
                  allowClear
                  value={valueSearch}
                  onChange={(e) => _onSearch(e.target.value)}
                  style={{ width: '100%' }}
                  placeholder="Tìm kiếm theo tên nhóm sản phẩm"
                  bordered={true}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                <Select
                  onChange={(value) => {
                    onChangeUserFilter(value)
                  }}
                  value={paramsFilter.creator_id || 'all'}
                  defaultValue={'all'}
                  showSearch
                  allowClear
                  placeholder="Tìm kiếm theo người tạo"
                  style={{
                    width: '100%',
                  }}
                  bordered={true}
                  filterOption={(input, option) =>
                    removeUnicode(option.key.toString())
                      .toLowerCase()
                      .trim()
                      .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                  }
                >
                  <Select.Option key="Tất cả nhân viên" value="all">
                    Tất cả nhân viên
                  </Select.Option>
                  {employees.map((item, index) => {
                    return (
                      <Option key={item.first_name + ' ' + item.last_name} value={item.user_id}>
                        {item.first_name} {item.last_name}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
              <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                <FilterDate
                  size="default"
                  bordered={true}
                  title="tạo nhóm sản phẩm"
                  paramsFilter={paramsFilter}
                  setParamsFilter={setParamsFilter}
                  valueTime={valueTime}
                  setValueTime={setValueTime}
                  screen={1}
                />
              </Col>
            </Row>
          </div>
          <Table
            rowKey="category_id"
            loading={loading}
            dataSource={categorieShow}
            columns={columnsParent}
            size="small"
            scroll={{ y: 500 }}
            pagination={{
              position: ['bottomLeft'],
              current: pagination.page,
              pageSize: pagination.pageSize,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setPagination({ page, pageSize })
              },
              total: countCategories,
            }}
          />
        </>
      ) : null}
    </div>
  )
}
