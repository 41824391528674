import { getRowsExcel, startImportInventory } from 'apis/import-inventory'
import React, { useEffect, useState } from 'react'
import styles from '../import-inventory.module.css'
import { useDispatch } from 'react-redux'

//antd
import { Button, Col, Radio, Row, Spin, Table } from 'antd'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { ACTION } from 'consts'

export default function Step2({ filePath, submit, gotoStep, current, setDataError }) {
  const [excelRows, setExcelRows] = useState([])
  const [excelRowsOrder, setExcelRowsOrder] = useState([])
  const [invoince, setInvoices] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const branchIdApp = useSelector((state) => state.branch.branchId)

  const sharedOnCell = (_) => {
    if (_.error === '{ERROR}') {
      return {
        colSpan: 0,
      }
    }

    return {}
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 60,
      colSpan: 1,
      align: 'center',
      render: (text, record) => (record.error === '{ERROR}' ? '' : text),
    },
    {
      title: 'Mã sản phẩm (*)',
      dataIndex: 'sku',
      onCell: (_) => ({
        colSpan: _.error === '{ERROR}' ? 10 : 1,
      }),
      render: (text, record) =>
        record.error === '{ERROR}' ? (
          <span style={{ color: '#ff9800' }}>{record.error_msg}</span>
        ) : (
          text
        ),
    },
    {
      title: 'Tên sản phẩm (*)',
      dataIndex: 'name',
      onCell: sharedOnCell,
    },
    {
      title: 'Mã phiên bản (*)',
      dataIndex: 'variant_sku',
      onCell: sharedOnCell,
    },
    {
      title: 'Tên phiên bản (*)',
      dataIndex: 'variant_name',
      onCell: sharedOnCell,
    },
    {
      title: 'Số lượng (*)',
      dataIndex: 'quantity',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá nhập hàng (*)',
      dataIndex: 'import_price',
      onCell: sharedOnCell,
    },
    {
      title: 'Mã lô',
      dataIndex: 'code',
      onCell: sharedOnCell,
    },
    {
      title: 'Ngày sản xuất',
      dataIndex: 'mfg',
      onCell: sharedOnCell,
    },
    {
      title: 'Hạn sử dụng',
      dataIndex: 'exp',
      onCell: sharedOnCell,
    },
    {
      title: 'Imei',
      dataIndex: 'imei',
      onCell: sharedOnCell,
    },
  ]

  const columnsOrder = [
    {
      title: 'Mã đơn nhập',
      dataIndex: 'code_invoice',
      onCell: sharedOnCell,
    },
    {
      title: 'Thuế tổng đơn (%)',
      dataIndex: 'total_tax',
      onCell: (_) => ({
        colSpan: _.error === '{ERROR}' ? 12 : 1,
      }),
      render: (text, record) =>
        record.error === '{ERROR}' ? (
          <span style={{ color: '#ff9800' }}>{record.error_msg}</span>
        ) : (
          text
        ),
    },
    {
      title: 'Chiết khấu tổng đơn (%,VNĐ)',
      dataIndex: 'total_discount',
      onCell: sharedOnCell,
    },
    {
      title: 'Phí giao hàng',
      dataIndex: 'fee_shipping',
      onCell: sharedOnCell,
    },
    {
      title: 'Thẻ tag',
      dataIndex: 'tag',
      onCell: sharedOnCell,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      onCell: sharedOnCell,
    },
  ]

  async function _getRowsExcel(filePath) {
    dispatch({ type: ACTION.LOADING, data: true })
    setTimeout(async () => {
      setLoading(true)
      let res = await getRowsExcel(filePath)
      if (res.status == 200) {
        setExcelRows(res.data.data)
        setExcelRowsOrder(res.data.dataOrder)
      }
      setLoading(false)
    dispatch({ type: ACTION.LOADING, data: false })
  }, 5000)
  }

  async function submitProcessImportInventory() {
    const data = { filePath, branch_id: branchIdApp }
    const res = await startImportInventory(data)
    if (res.status === 200) {
      gotoStep(2)
      setDataError(res.data.data)
    }
  }

  useEffect(() => {
    _getRowsExcel(filePath)
  }, [filePath])

  return (
    <div className={styles['step-2']}>
      <div className={styles['step-2-header']}>
        <div>
          <Row justify="center" className={styles['group-button']}>
            <Col style={{ marginRight: 10 }}>
              <Button type="default" onClick={() => submit({ step: 0 })}>
                Quay lại
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={submitProcessImportInventory}>
                Thực hiện Import
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <div>
        {loading === true ? (
          <></>
        ) : (
          <>
            <p style={{ color: 'red' }}>
              Dưới đây là dữ liệu của 100 dòng đầu tiên trong file excel bạn vừa chọn. Vui lòng kiểm
              tra và xác nhận. Những dữ liệu cảnh báo nhưng bị thiếu sẽ được thêm vào. Những dữ liệu
              tồn tại sẽ không được thêm
            </p>
            <div>
              <Table
                style={{ height: 150 }}
                size="small"
                columns={columnsOrder}
                dataSource={excelRowsOrder}
              />
              <Table
                rowKey="phone"
                scroll={{ y: 350 }}
                columns={columns}
                dataSource={excelRows}
                size="large"
                pagination={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
