import React, { useEffect, useRef, useState } from 'react'
import { compare, formatCash } from 'utils'
import { Link, useHistory } from 'react-router-dom'
import { ACTION, FILTER_COL_HEIGHT, FILTER_SIZE, PAGE_SIZE, PERMISSIONS, ROUTES } from 'consts'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

//antd
import {
  Affix,
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Table,
  Modal,
  Popconfirm,
  notification,
  Tag,
} from 'antd'
import {
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons'

//components
import ImportCsv from 'components/ImportCSV'
import TitlePage from 'components/title-page'
import columnsShippingControl from './columns'
import FilterDate from 'components/filter-date'
import exportToCSV from 'components/ExportCSV/export'
import Permission from 'components/permission'
//apis
import { addShippingControlWithFile, getShippings, updateShippingControl } from 'apis/shipping'
import { getShippingControls, deleteShippingControl } from 'apis/shipping-control'
import { getEmployees } from 'apis/employee'

export default function ShippingControl() {
  const history = useHistory()
  const typingTimeoutRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [valueSearch, setValueSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [countShippingControl, setCountShippingControl] = useState(0)
  const [shippingControlList, setShippingControlList] = useState([])
  const [shippingId, setShippingId] = useState('') //shipping id import
  const [shippings, setShippings] = useState([])
  const [valueTime, setValueTime] = useState()
  const [employees, setEmployees] = useState([])
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE  ,
  })

  const dataUser = useSelector((state) => state.login.dataUser)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const columnsOrder = [
    {
      title: 'Mã vận đơn',
      render: (text, record) => {
        return (
          <div style={{ color: record.status === 'issues' ? 'red' : 'black' }}>
            {record.tracking_number || ''}
          </div>
        )
      },
    },
    {
      title: 'Ngày nhận đơn',
      render: (text, record) => {
        return (
          <div style={{ color: record.status === 'issues' ? 'red' : 'black' }}>
            {moment(record.date_receive_order).format('yyyy/MM/DD') || 'Chưa có'}
          </div>
        )
      },
    },
    {
      title: 'Ngày hoàn thành',
      render: (text, record) => {
        return (
          <div style={{ color: record.status === 'issues' ? 'red' : 'black' }}>
            {moment(record.date_complete_order).format('yyyy/MM/DD') || 'Chưa có'}
          </div>
        )
      },
    },
    {
      title: 'Khối lượng',
      render: (text, record) => {
        return (
          <div style={{ color: record.status === 'issues' ? 'red' : 'black' }}>
            {record.weight || ''}
          </div>
        )
      },
    },
    {
      title: 'Tiền COD',
      render: (text, record) => {
        return (
          <div>
            <div style={{ color: record.status === 'issues' ? 'red' : 'black', width: '100%' }}>
              {formatCash(record.cod || 0)}
            </div>
            {record.status === 'issues' && (
              <div style={{ width: '100%' }}>{'Hệ thống: ' + formatCash(record.cod_fix || 0)}</div>
            )}
          </div>
        )
      },
    },
    {
      title: 'Phí bảo hiểm',
      render: (text, record) => {
        return (
          <div>
            <div style={{ color: record.status === 'issues' ? 'red' : 'black', width: '100%' }}>
              {formatCash(record.fee_insurance || 0)}
            </div>
            {record.status === 'issues' && (
              <div style={{ width: '100%' }}>
                {'Hệ thống: ' + formatCash(record.fee_insurance_fix || 0)}
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: 'Phí giao hàng',
      render: (text, record) => {
        return (
          <div>
            <div style={{ color: record.status === 'issues' ? 'red' : 'black', width: '100%' }}>
              {formatCash(record.fee_shipping || 0)}
            </div>
            {record.status === 'issues' && (
              <div style={{ width: '100%' }}>
                {'Hệ thống: ' + formatCash(record.fee_shipping_fix || 0)}
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: 'Phí lưu kho',
      render: (text, record) => {
        return (
          <div>
            <div style={{ color: record.status === 'issues' ? 'red' : 'black', width: '100%' }}>
              {formatCash(record.fee_warehouse || 0)}
            </div>
            {record.status === 'issues' && (
              <div style={{ width: '100%' }}>
                {'Hệ thống: ' + formatCash(record.fee_warehouse || 0)}
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: 'Trạng thái',
      render: (text, record) => {
        var label = ''
        var color = 'black'

        if (record.status === 'issues') {
          label = 'Chênh lệch'
          color = 'red'
        }

        if (record.status === 'resolved') {
          label = 'Đã giải quyết'
          color = 'blue'
        }

        if (record.status === 'done') {
          label = 'Cân bằng'
          color = 'green'
        }

        return <div style={{ color: color }}>{label}</div>
      },
    },
    {
      title: 'Vấn đề',
      render: (text, record) => {
        return (
          <div style={{ color: record.status === 'issues' ? 'red' : 'black' }}>
            {record.name_problem || ''}
          </div>
        )
      },
    },
  ]

  const enumStatusShipping = [
    {
      key: 'all',
      name: 'Tất cả trạng thái',
      value: 'all',
    },
    {
      key: '0',
      name: 'Lưu nháp',
      value: '0',
    },
    {
      key: '1',
      name: 'Đã đối soát',
      value: '1',
    },
  ]

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setValueSearch('')
  }

  const ModalViewDetail = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <Row
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
          type="primary"
          onClick={toggle}
        >
          <a>Xem chi tiết</a>
        </Row>
        <Modal
          width="75%"
          footer={null}
          style={{ top: 20 }}
          visible={visible}
          onCancel={toggle}
          title={`Chi tiết phiếu đối soát vận chuyển #${record.code}, được tạo ngày: ${moment(
            record.create_date
          ).format('yyyy/MM/DD')}`}
        >
          <Table
            scroll={{ y: '72vh' }}
            style={{ width: '100%' }}
            pagination={false}
            columns={columnsOrder}
            dataSource={record.list_order || []}
            size="small"
          />
        </Modal>
      </>
    )
  }

  const ModalUpdateNote = ({ record, children }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [note, setNote] = useState('')
    const [loading, setLoading] = useState(false)

    const _updateShippingControl = async () => {
      try {
        setLoading(true)
        const body = { note: note, status: 'resolved' }
        const res = await updateShippingControl(body, record.card_id)
        setLoading(false)
        if (res.status === 200) {
          if (res.data.success) {
            toggle()
            notification.success({ message: 'Cập nhật thành công' })
            _getShippingControlList()
          } else
            notification.error({
              message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại',
            })
        } else
          notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại' })
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    useEffect(() => {
      if (!visible) setNote('')
    }, [visible])

    return (
      <>
        <div
          onClick={() => {
            if (record.status === 'issues') {
              toggle()
            }
          }}
        >
          {children}
        </div>
        <Modal
          footer={
            <Row justify="end">
              <Space>
                <Button onClick={toggle}>Hủy</Button>
                <Button onClick={_updateShippingControl} loading={loading} type="primary">
                  Xử lý
                </Button>
              </Space>
            </Row>
          }
          visible={visible}
          onCancel={toggle}
          title={`Ghi chú lí do chênh lệch #${record.card_id}`}
        >
          <Input.TextArea
            placeholder="Nhập ghi chú"
            rows={4}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Modal>
      </>
    )
  }

  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.code = value
      else delete paramsFilter.code
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const _onFilter = (attribute = '', value = '') => {
    if (value !== null && value !== undefined) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    if (attribute == 'shipping_company_id' && value == 'all') {
      delete paramsFilter[attribute]
    }
    if (attribute == 'status' && value == 'all') {
      delete paramsFilter[attribute]
    }
    if (attribute == 'employee_id' && value == 'all') {
      delete paramsFilter[attribute]
    }
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _exportAllShippingControl = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getShippingControls({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        const dataExport = []
        res.data.data.map((e) =>
          e.table_data?.map((order) =>
            dataExport.push({
              'Mã vận đơn (*)': order.code_order || '',
              'Tiền COD hệ thống(*)': order.cod_system || '',
              'Tiền COD đối tác(*)': order.cod_partner || '',
              'Phí giao hàng hệ thống (*)': order.cost_ship_system || '',
              'Phí giao hàng đối tác (*)': order.cost_ship_partner || '',
            })
          )
        )
        exportToCSV(
          dataExport,
          'Danh sách phiếu đối soát vận chuyển',
          'Xuất file excel phiếu đối soát vận chuyển',
          dispatch
        )
      }
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getShippingControlList = async () => {
    try {
      setLoading(true)
      const res = await getShippingControls({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        setShippingControlList(res.data.data)
        setCountShippingControl(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _deleteShippingControl = async (id) => {
    try {
      let body = {
        shipping_control_id: id,
        branch_id: branchIdApp,
      }
      let res = await deleteShippingControl(body)
      if (res.data.success) {
        notification.success({ message: 'Xoá phiếu đối soát thành công!' })
        _getShippingControlList()
      } else {
        notification.error({ message: 'Xoá phiếu đối soát thất bại!' })
      }
    } catch (error) {
      return notification.error({ key: 'error data try catch', message: error })
    }
  }

  const _getShippings = async () => {
    try {
      setLoading(true)
      const res = await getShippings()
      if (res.status === 200) {
        setShippings(res.data.data)
        const defaultShippingId = res.data.data.find((item) => item.default === true)
        setShippingId(defaultShippingId.shipping_company_id)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getEmployees = async () => {
    try {
      setLoading(true)
      const res = await getEmployees({ branch_id: branchIdApp })
      if (res.status === 200) setEmployees(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    _getShippingControlList()
  }, [paramsFilter, branchIdApp])

  useEffect(() => {
    _getEmployees()
    _getShippings()
    onClickClear()
  }, [branchIdApp])

  return (
    <div className="card">
        <TitlePage title="Đối soát vận chuyển" isAffix>
          <Space>
            <Button
              style={{
                display: Object.keys(paramsFilter).length <= 2 && 'none',
              }}
              onClick={onClickClear}
              type="danger"
            >
              Xóa tất cả lọc
            </Button>

            {permissions.includes(PERMISSIONS.export_phieu_doi_soat_van_chuyen) ? (
              <Button
                id="exportFile"
                onClick={_exportAllShippingControl}
                icon={<VerticalAlignTopOutlined />}
                size="large"
                type="primary"
              >
                Xuất File
              </Button>
            ) : (
              <Button disabled icon={<VerticalAlignTopOutlined />} size="large" type="primary">
                Xuất File
              </Button>
            )}
            {permissions.includes(PERMISSIONS.them_phieu_doi_soat_van_chuyen) ? (
              <Button
                size="large"
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => history.push(ROUTES.SHIPPING_CONTROL_ADD)}
              >
                Thêm phiếu đối soát
              </Button>
            ) : (
              <Button
                disabled
                size="large"
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => history.push(ROUTES.SHIPPING_CONTROL_ADD)}
              >
                Thêm phiếu đối soát
              </Button>
            )}
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_doi_soat_van_chuyen) ? (
        <>
          <div style={{ marginTop: 10, }}>
            <Row style={{ width: '100%',marginBottom:10 }}>
              <Col xs={24} sm={24} md={24} lg={6} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
                <Input
                  allowClear
                  size={FILTER_SIZE}
                  prefix={<SearchOutlined />}
                  style={{ width: '100%' }}
                  placeholder="Tìm kiếm theo mã phiếu"
                  enterButton
                  bordered={true}
                  value={valueSearch}
                  onChange={onSearch}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
                <Select
                  size={FILTER_SIZE}
                  allowClear
                  bordered={true}
                  defaultValue="all"
                  placeholder="Lọc theo đơn vị vận chuyển"
                  style={{ width: '100%'}}
                  value={paramsFilter.shipping_company_id || 'all'}
                  onChange={(value) => _onFilter('shipping_company_id', value)}
                >
                  <Select.Option value="all">Tất cả đơn vị vận chuyển</Select.Option>
                  {shippings.map((shipping, index) => (
                    <Select.Option value={shipping.shipping_company_id} key={index}>
                      {shipping.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
                <FilterDate
                  bordered={true}
                  size="default"
                  paramsFilter={paramsFilter}
                  setParamsFilter={setParamsFilter}
                  title="tạo đơn vị vận chuyển"
                  valueTime={valueTime}
                  setValueTime={setValueTime}
                  screen={1}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={4} style={{ height: FILTER_COL_HEIGHT }}>
                <Select
                  allowClear
                  size={FILTER_SIZE}
                  bordered={true}
                  placeholder="Lọc theo người tạo phiếu"
                  style={{ width: '100%' }}
                  defaultValue="all"
                  value={paramsFilter.employee_id || 'all'}
                  onChange={(value) => _onFilter('employee_id', value)}
                >
                  <Select.Option value="all">Tất cả nhân viên</Select.Option>
                  {employees.map((employee, index) => (
                    <Select.Option value={employee.user_id} key={index}>
                      {employee.first_name} {employee.last_name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={5} style={{ height: FILTER_COL_HEIGHT }}>
                <Select
                  size={FILTER_SIZE}
                  allowClear
                  bordered={true}
                  placeholder="Lọc theo trạng thái"
                  defaultValue="all"
                  style={{ width: '100%'}}
                  value={paramsFilter.status || 'all'}
                  onChange={(value) => {
                    _onFilter('status', value)
                  }}
                >
                  {enumStatusShipping.map((enumStatus, index) => (
                    <Select.Option value={enumStatus.value} key={index}>
                      {enumStatus.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Table
              dataSource={shippingControlList}
              loading={loading}
              style={{ width: '100%'}}
              size="small"
              columns={columnsShippingControl.map((column) => {
                if (column.key === 'stt')
                  return { ...column, render: (text, record, index) => index + 1 }
                if (column.key === 'code')
                  return {
                    ...column,
                    render: (text, record) =>
                      permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_doi_soat_van_chuyen) ? (
                        <Link to={ROUTES.SHIPPING_CONTROL_UPDATE + '/' + record.code}>
                          <span>#{record.code}</span>
                        </Link>
                      ) : <span style={{cursor:'pointer', color:'#5F73E2'}} onClick={() => notification.warning({message:'Permission denied!'})}>#{record.code}</span>,
                    sort: (a, b) => compare(a, b, 'code'),
                  }
                if (column.key === 'shipping_company')
                  return {
                    ...column,
                    render: (text, record) =>
                      record?.shipping_company_info ? record.shipping_company_info?.name : '',
                  }
                if (column.key === 'file')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.link_file ? <a href={record.link_file}>Tải file</a> : '',
                  }
                if (column.key === 'creator_info')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.creator_info
                        ? `${record.creator_info.first_name} ${record.creator_info.last_name}`
                        : '',
                  }
                if (column.key === 'create_date')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.create_date && moment(record.create_date).format('DD/MM/YYYY HH:mm'),
                  }
                if (column.key === 'note')
                  return {
                    ...column,
                    render: (text, record) => record.note || '',
                  }
                if (column.key === 'status')
                  return {
                    ...column,
                    width: 200,
                    render: (text, record) => (
                      <Tag
                        style={{ cursor: 'pointer' }}
                        color={
                          (record.status === 0 && 'error') || (record.status === 1 && 'success')
                        }
                      >
                        {(record.status === 0 && 'Nháp') || (record.status === 1 && 'Hoàn thành')}
                      </Tag>
                    ),
                  }

                if (column.key === 'list_order')
                  return {
                    ...column,
                    render: (text, record) => (
                      <div>
                        <Row
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                          }}
                        >
                          {record.table_data.length}
                        </Row>
                      </div>
                    ),
                  }

                if (column.key === 'action')
                  return {
                    ...column,
                    render: (text, record) => (
                      <Space>
                        <Permission permissions={[PERMISSIONS.xoa_phieu_doi_soat_van_chuyen]}>
                          <Popconfirm
                            onConfirm={() => _deleteShippingControl(record.shipping_control_id)}
                            title="Bạn có muốn xóa phiếu này không?"
                          >
                            <Button type="primary" danger icon={<DeleteOutlined />} />
                          </Popconfirm>
                        </Permission>
                      </Space>
                    ),
                  }
                return column
              })}
              rowKey="_id"
              pagination={{
                position: ['bottomLeft'],
                current: paramsFilter.page,
                pageSize: paramsFilter.page_size,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                showQuickJumper: true,
                onChange: (page, pageSize) =>
                  setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
                total: countShippingControl,
              }}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}
