import React, {  useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';

const GHN = ({deliveryAddress, branchInfo}) => {
    const branches = useSelector((state) => state.branch)
    const [branch] = useState(branches.branches.find(item => item.branch_id === branches.branchId))

    return (
        <Card style={{ width: '100%', marginTop: '10px' }} size="small" title={`Giao hàng tại chi nhánh: ${branch.name}`}>
           <div>
        <strong>Địa chỉ nhận hàng: </strong> {branchInfo?.address}, {branchInfo?.ward},{' '}
        {branchInfo?.district}, {branchInfo?.province}
      </div>
      <div>
        <strong>Địa chỉ giao hàng: </strong>
        {deliveryAddress?.address},{' '}{deliveryAddress?.ward?.ward_name},{' '}{deliveryAddress?.district?.district_name},{' '}{deliveryAddress?.province?.province_name}
      </div> 

        </Card>
    )
}

export default GHN