import { CloseCircleFilled, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
//api 
import { getProducts } from 'apis/product'
//components
import LeftTable from '../table-items/left-table'
import {searchFunctionLeft} from '../function-items/search-item'


const LeftSide = (props) => {
  const branchIdApp = useSelector((state) => state.branch.branchId)


  const [productTable, setProductTable] = useState([])
  const [productTableShow, setProductTableShow] = useState([])
  const [productTableCount, setProductTableCount] = useState(0)

  const _getProducts = async () => {
    
    try {
      const res = await getProducts({
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        setProductTable(res.data.data)
        setProductTableShow(res.data.data)
        setProductTableCount(res.data.count)
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    _getProducts()
  }, [branchIdApp])
  return (
    <div style={{maxWidth: '700px'}}>
      <h1>Danh sách sản phẩm:</h1>
      <Input 
      onChange={(e) => searchFunctionLeft(e, setProductTableShow, setProductTableCount, productTable)}
      allowClear
      placeholder="Tìm kiếm theo mã SKU/ Tên phiên bản" suffix={<SearchOutlined />} />
      <div style={{ marginTop: '10px', boxShadow: '1px 1px 3px 1px silver' }}>
        <LeftTable {...props} productTable={productTable} productTableShow={productTableShow} productTableCount={productTableCount}/>
      </div>
    </div>
  )
}

export default LeftSide
