import styles from './button.module.scss'

function Button({ icon: Icon, text, classes, style, ...rest }) {
  return (
    <button className={`${styles.btn} ${styles[classes]}`} style={style} {...rest}>
      {Icon && <Icon />}
      {text}  
    </button>
  )
}

export { Button }
