import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ROUTES, PERMISSIONS, FILTER_SIZE, PAGE_SIZE } from 'consts'
import { useSelector } from 'react-redux'
//antd
import { Row, Col, Select, Table, Button } from 'antd'

//components
import TitlePage from 'components/title-page'
import { formatCash } from 'utils'
import FilterDate from 'components/filter-date'
import { removeUnicode } from 'utils'
import { handleCell } from 'components/handleCell'
//apis
import { getChangePriceHistory, getProducts } from 'apis/product'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function PriceChangeHistory() {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [product, setProduct] = useState([])

  const [loading, setLoading] = useState(false)
  const [changePriceHistory, setChangePriceHistory] = useState([])
  const [valueTime, setValueTime] = useState()

  const [valueSearch, setValueSearch] = useState('all')
  const [paramsFilter, setParamsFilter] = useState({})
  const [countAction, setCountAction] = useState(0)

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const _onFilter = (attribute = '', value = '') => {

    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1, })
  }
  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
      width: '5%',
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      render: (text, record) =>
        
          <Link
            target={'_blank'}
            to={{ pathname: ROUTES.PRICE_ADJUSTMENTS_UPDATE + '/' + record.code }}
          >
            {'#' + record.code}
          </Link>,
      onCell: (record, index) => handleCell(record, (paramsFilter.page - 1) * paramsFilter.page_size + index, changePriceHistory, 'fix_price_id',(paramsFilter.page - 1) * paramsFilter.page_size),
    },
    {
      title: 'SKU phiên bản',
      render: (text, record) => record?.variant_info?.sku,
    },
    {
      title: 'Tên phiên bản',
      dataIndex: 'name',
      render: (text, record) => record?.variant_info?.title,
    },
    {
      title: 'Giá trước điều chỉnh',
      render: (text, record) => formatCash(record?.from_price),
    },
    {
      title: 'Giá sau điều chỉnh',
      render: (text, record) => formatCash(record?.to_price),
    },
    {
      title: 'Chênh lệch',
      render: (text, record) => formatCash(record?.to_price - record?.from_price),
      width: '8%',
    },
    {
      title: 'Nhân viên',
      render: (text, record) =>
        record?.creator_info?.first_name + ' ' + record?.creator_info?.last_name,
    },
    // {
    //   title: 'Chi nhánh',
    //   render: (text, record) =>
    //     allBranch.map((item) => item.branch_id === record?.creator_info?.branch_id && item.name),
    // },
    {
      title: 'Ngày ghi nhận',
      render: (text, record) => moment(record?.create_date).format('DD-MM-YYYY, HH:mm'),
    },
  ]
  const _getChangePriceHistory = async () => {
    try {
      setLoading(true)
      const res = await getChangePriceHistory({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        setCountAction(res.data.count)
        setChangePriceHistory(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _getProducts = async () => {
    try {
      const res = await getProducts()
      if (res.status === 200) setProduct(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getProducts()
  }, [])

  const onClickClear = async () => {
    setValueSearch('all')
    setParamsFilter({ page: 1, page_size: PAGE_SIZE, check:1 })
  }
  useEffect(() => {
    if(paramsFilter.check) _getChangePriceHistory()
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setValueSearch('all')
    if(dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)){
      setParamsFilter({page: 1, page_size: PAGE_SIZE, check:1})
      setValueTime()
    }else {
      setParamsFilter({page: 1, page_size: PAGE_SIZE, from_date: result.from_date, to_date: result.to_date, check:1})
      setValueTime(result.valueDate)
    }
  },[dataUser])
  return (
    <div className="card">
      <TitlePage isAffix title={<Row align="middle">Lịch sử điều chỉnh giá</Row>}>
        <Button
          style={{ display: valueSearch == 'all' && 'none' }}
          onClick={onClickClear}
          type="primary"
          size="large"
        >
          Xóa tất cả lọc
        </Button>
      </TitlePage>
        <Row
          gutter={[16, 16]}
          style={{
            marginTop:8
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={6}
          >
            <FilterDate
              size='default'
              bordered={true}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
              title="điều chỉnh giá"
              valueTime={valueTime}
              setValueTime={setValueTime}
              screen={1}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={6}
          >
            <Select
              size={FILTER_SIZE}
              allowClear
              bordered={true}
              defaultValue={'all'}
              value={valueSearch}
              showSearch
              filterOption={(input, option) =>
                removeUnicode(option.children.toString())
                  .toLowerCase()
                  .trim()
                  .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
              }
              onChange={(value) => {
                let result
                if (value !== undefined) {
                  let index = value.lastIndexOf('+')
                  result = value.substring(index + 1)
                }
                _onFilter('product_sku', result)
                setValueSearch(value)
              }}
              placeholder="Lọc theo sản phẩm"
              style={{ width: '100%' }}
            >
              <Select.Option value="all">Tất cả sản phẩm</Select.Option>
              {product.map((_product, index) => (
                <Select.Option value={`${_product.name}+${_product.sku}`} key={index}>
                  {_product.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row
        gutter={[16, 16]}
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          borderRadius: 5,
        }}
      >
      <Table
        loading={loading}
        size="small"
        columns={columns}
        dataSource={changePriceHistory}
        style={{ width: '100%' }}
        scroll={{ y: 600 }}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          // pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) =>
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
          total: countAction,
        }}
      />
      </Row>
    </div>
  )
}
