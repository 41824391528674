import React, { useEffect, useState, useRef } from 'react'
import styles from './order-create-shipping.module.scss'
import {
  ACTION,
  FILTER_COL_HEIGHT,
  FILTER_SIZE,
  PAGE_SIZE,
  PERMISSIONS,
  ROUTES,
  TIME_FORMAT,
} from 'consts'
import { Link, useHistory } from 'react-router-dom'
import { PlusCircleOutlined, DeleteOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'
import moment from 'moment'
import { compare, compareCustom } from 'utils'
import { useDispatch, useSelector } from 'react-redux'

import {
  Input,
  Button,
  Row,
  Col,
  Select,
  Table,
  Space,
  Popconfirm,
  notification,
  Affix,
  Tag,
} from 'antd'

//components
import exportToCSV from 'components/ExportCSV/export'
import TitlePage from 'components/title-page'
import columnsProduct from './columns'
import FilterDate from 'components/filter-date/filter'
import DeliveryModal from './item/listDeliveryModal'
//apis
import { getTransportOrders, deleteTransportOrder } from 'apis/transport'
import removeVietnameseTones from 'utils/removeUnicode'
import { DefaultFilterData } from 'utils/DefaultFilterData'
export default function ShippingProduct() {
  const dispatch = useDispatch()
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [countTransportOrder, setCountTransportOrder] = useState(0)
  const [transportOrders, setTransportOrders] = useState([])
  const [transportOrderShow, setTransportOrderShow] = useState([])
  const [loading, setLoading] = useState(false)
  const [quantityDelivery, setQuantityDelivery] = useState(0)
  const [deliveries, setDeliveries] = useState([])

  const [valueSearch, setValueSearch] = useState('')

  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [paramsFilter, setParamsFilter] = useState({})
  const [valueTime, setValueTime] = useState()
  const _getTransportOrdersToExportExcel = async () => {
    let dataExport = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      // const res = await getTransportOrders({ branch_id: branchIdApp })
      // if (res.status === 200) {
      transportOrderShow.map((e, index) => {
        e.products.map((product) => {
          dataExport.push({
            STT: index,
            'Mã phiếu chuyển': e.code || '',
            'Mã phiên bản (*)': product.sku || '',
            'Tên phiên bản (*)': product.title || '',
            'Chi nhánh chuyển (*)': e.export_location_info && (e.export_location_info.name || ''),
            'Chi nhánh nhận (*)': e.import_location_info && (e.import_location_info.name || ''),
            'Số lượng (*)': product.quantity || 0,
            'Ngày xuất hàng':
              e.delivery_date === '' ? '' : moment(e.delivery_date).format(TIME_FORMAT.DATE_TIME),
            'Nhân viên xuất kho': e.delivery_info
              ? e.delivery_info.first_name + ' ' + e.delivery_info.last_name
              : '',
            'Ngày nhận hàng':
              e.verify_date === '' ? '' : moment(e.verify_date).format(TIME_FORMAT.DATE_TIME),
            'Nhân viên nhận hàng': e.verify_info
              ? e.verify_info.first_name + ' ' + e.verify_info.last_name
              : '',
            'Trạng thái':
              e?.status === 'COMPLETE'
                ? 'Hoàn thành'
                : e?.status === 'DRAFT'
                ? 'Lưu nháp'
                : e?.status === 'DELIVERY'
                ? 'Đang chuyển'
                : e?.status === 'CANCEL' && 'Đã hủy',
            'Ghi chú': e.note || '',
            'Ngày tạo':
              e.create_date === '' ? '' : moment(e.create_date).format(TIME_FORMAT.DATE_TIME),
            'Nhân viên tạo': e.creator_info
              ? e.creator_info.first_name + ' ' + e.creator_info.last_name
              : '',
          })
        })
      })
      // }
      exportToCSV(dataExport, 'Danh sách phiếu chuyển','Xuất file excel',dispatch)
    } catch (e) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _getTransportOrders = async () => {
    try {
      setLoading(true)
      const res = await getTransportOrders({ branch_id: branchIdApp })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setTransportOrders(result.data)
        setValueSearch('')
        if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
          })
          setValueTime()
        } else {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
            from_date: result.defaultTime.from_date,
            to_date: result.defaultTime.to_date,
          })
          setValueTime(result.valueDate)
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }
  const _deleteTransportOrder = async (id) => {
    try {
      setLoading(true)
      const res = await deleteTransportOrder({ id, branch_id: branchIdApp })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getTransportOrders()
          notification.success({ message: 'Xóa phiếu chuyển hàng thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xóa phiếu chuyển hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa phiếu chuyển hàng thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _onFilters = (value) => {
    if (value) paramsFilter.status = value
    else delete paramsFilter.status
    setParamsFilter({ ...paramsFilter })
  }

  const onSearch = (e) => {
    setValueSearch(e)

    e = removeVietnameseTones(e)
    if (e.includes(' ')) {
      e = new RegExp(e.replace(/ /g, '.*'))
    } else {
      e = new RegExp(e)
    }
    paramsFilter['search'] = e
  }
  useEffect(() => {
    let arr = [...transportOrders]
    const filtered = arr.filter((current) => {
      let matchStatus = true
      let matchTime = true
      let matchSearch = true
      if (paramsFilter.status && paramsFilter.status !== 'all')
        matchStatus = current?.status === paramsFilter.status
      if (paramsFilter.search && paramsFilter.search !== ' ') {
        let newObject = removeVietnameseTones(Object.values(current).join(' ').toLocaleLowerCase())
        matchSearch = paramsFilter.search.test(newObject)
      }

      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date
      return matchStatus && matchSearch && matchTime
    })
    setTransportOrderShow(filtered)
    setCountTransportOrder(filtered.length)
    let deliveries = filtered.reduce(({arr, sum}, e) => {
      if(e.status === 'DELIVERY') {
        arr.push(e)
        sum += 1
      }
      return {arr: arr, sum: sum}
    },{arr: [], sum: 0})
    setDeliveries(deliveries.arr)
    setQuantityDelivery(deliveries.sum)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, valueSearch])
  useEffect(() => {
    _getTransportOrders()
  }, [branchIdApp, dataUser])

  return (
    <div className="card">
        <TitlePage title="Quản lý phiếu chuyển hàng" isAffix>
          <Space>
            <Button
              onClick={() => {
                setValueSearch('')
                setValueTime()
                setParamsFilter({})
                setCountTransportOrder(transportOrders.length)
                setTransportOrderShow(transportOrders)
              }}
              style={{
                display:
                  Object.keys(paramsFilter).length >= 1 || valueSearch !== '' ? 'inline' : 'none',
              }}
              type="danger"
            >
              Xóa bộ lọc
            </Button>
            <Button
              id="exportFile"
              disabled={permissions.includes(PERMISSIONS.export_phieu_chuyen_hang) ? false : true}
              onClick={_getTransportOrdersToExportExcel}
              icon={<VerticalAlignTopOutlined />}
              style={{ backgroundColor: 'green', borderColor: 'green' }}
              type="primary"
              size="large"
            >
              Xuất excel
            </Button>
            <Button
              disabled={permissions.includes(PERMISSIONS.tao_phieu_chuyen_hang) ? false : true}
              size="large"
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => history.push(ROUTES.SHIPPING_PRODUCT_ADD)}
            >
              Tạo phiếu
            </Button>
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_chuyen_hang) ? (
        <>
          <div>
            <Row
              style={{
                marginTop: '1rem',
                border: '1px solid #d9d9d9',
                borderRadius: 5,
                marginBottom: 10,
                backgroundColor:'white',
              }}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={8} style={{ height: FILTER_COL_HEIGHT }}>
                <Input
                  placeholder="Tìm kiếm phiếu chuyển hàng"
                  onChange={(e) => onSearch(e.target.value)}
                  size={FILTER_SIZE}
                  value={valueSearch}
                  allowClear
                  bordered={false}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={8}
                style={{ borderRight: '1px solid #d9d9d9', borderLeft: '1px solid #d9d9d9' }}
              >
                <FilterDate
                  style={{ marginTop: 3 }}
                  bordered={false}
                  title="tạo phiếu chuyển hàng"
                  paramsFilter={paramsFilter}
                  setParamsFilter={setParamsFilter}
                  valueTime={valueTime}
                  setValueTime={setValueTime}
                  screen={1}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} style={{ height: FILTER_COL_HEIGHT }}>
                <Select
                  allowClear
                  size={FILTER_SIZE}
                  style={{ width: '100%' }}
                  placeholder="Lọc theo trạng thái"
                  value={paramsFilter.status || 'all'}
                  defaultValue={'all'}
                  bordered={false}
                  onChange={(value) => _onFilters(value)}
                >
                  <Select.Option value="all">Tất cả trạng thái</Select.Option>
                  <Select.Option value="DRAFT">Lưu nháp</Select.Option>
                  <Select.Option value="DELIVERY">Đang chuyển</Select.Option>
                  <Select.Option value="COMPLETE">Hoàn thành</Select.Option>
                  <Select.Option value="CANCEL">Đã huỷ</Select.Option>
                </Select>
              </Col>
            </Row>
          </div>
            <Row>
              <DeliveryModal quantity={quantityDelivery} data={deliveries}/>
            </Row>
          <Table
            size="small"
            loading={loading}
            scroll={{ y: 500 }}
            columns={columnsProduct.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  render: (data, record, index) =>
                    (pagination.page - 1) * pagination.pageSize + index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  width: 140,
                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang) ? (
                      <Link
                        style={{
                          color: 'blue',
                        }}
                        to={{
                          pathname: ROUTES.SHIPPING_PRODUCT_UPDATE + '/' + record.code,
                        }}
                      >
                        #{record.code}
                      </Link>
                    ) : (
                      <span
                        style={{ cursor: 'pointer', color: '#5F73E2' }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        #{record.code}
                      </span>
                    ),
                  sorter: (a, b) => compare(a, b, 'code'),
                }
              if (column.key === 'export_location')
                return {
                  ...column,
                  render: (text, record) =>
                    record.export_location_info && record.export_location_info.name,
                  sorter: (a, b) => compare(a.export_location_info, b.export_location_info, 'name'),
                }
              if (column.key === 'status')
                return {
                  ...column,
                  render: (text, record) => (
                    <>
                      <Tag
                        style={{ width: '100%', textAlign: 'center' }}
                        color={
                          (record.status === 'DRAFT' && 'gold') ||
                          (record.status === 'DELIVERY' && 'blue') ||
                          (record.status === 'COMPLETE' && 'green') ||
                          (record.status === 'CANCEL' && 'red')
                        }
                      >
                        {record.status === 'COMPLETE' && 'Hoàn thành'}
                        {record.status === 'DRAFT' && 'Lưu nháp'}
                        {record.status === 'DELIVERY' && 'Đang chuyển'}
                        {record.status === 'CANCEL' && 'Đã hủy'}
                      </Tag>
                    </>
                  ),
                  sorter: (a, b) => compare(a, b, 'status'),
                }
              if (column.key === 'import_location')
                return {
                  ...column,
                  render: (text, record) =>
                    record.import_location_info && record.import_location_info.name,
                  sorter: (a, b) => compare(a.import_location_info, b.import_location_info, 'name'),
                }
              if (column.key === 'quantity')
                return {
                  ...column,
                  render: (data) => data.total_quantity,
                  sorter: (a, b) => compare(a, b, 'total_quantity', Number),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (data) => moment(data).format(TIME_FORMAT.DATE_TIME),
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                }
              if (column.key === 'creator_info')
                return {
                  ...column,
                  sorter: (a, b) =>
                    compareCustom(
                      a.creator_info
                        ? `${a.creator_info.first_name} ${a.creator_info.last_name}`
                        : '',
                      b.creator_info
                        ? `${b.creator_info.first_name} ${b.creator_info.last_name}`
                        : ''
                    ),
                  render: (text) => text && text.first_name + ' ' + text.last_name,
                }

              if (column.key === 'note')
                return {
                  ...column,
                  render: (text) => text,
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) => (
                    <Space>
                      <Popconfirm
                        disabled={
                          permissions.includes(PERMISSIONS.xoa_phieu_chuyen_hang) &&
                          record.export_location === branchIdApp &&
                          record.status === 'DRAFT'
                            ? false
                            : true
                        }
                        okText="Đồng ý"
                        cancelText="Từ chối"
                        onConfirm={() => _deleteTransportOrder(record.order_id)}
                        title="Bạn có muốn xóa phiếu chuyển hàng này không?"
                      >
                        <Button
                          disabled={
                            permissions.includes(PERMISSIONS.xoa_phieu_chuyen_hang) &&
                            record.export_location === branchIdApp &&
                            record.status === 'DRAFT'
                              ? false
                              : true
                          }
                          icon={<DeleteOutlined />}
                          danger
                          type="primary"
                        />
                      </Popconfirm>
                    </Space>
                  ),
                }
              return column
            })}
            rowKey="order_id"
            pagination={{
              position: ['bottomLeft'],
              current: pagination.page,
              pageSize: pagination.pageSize,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => setPagination({ page, pageSize }),
              total: countTransportOrder,
            }}
            dataSource={transportOrderShow}
            style={{ width: '100%', marginTop: 10 }}
          />
        </>
      ) : null}
    </div>
  )
}
