import React, { useState, useEffect } from 'react'
import {removeUnicode} from 'utils'
import moment from 'moment'

//apis
import { updateFinances } from 'apis/finance'
import { getPayments } from 'apis/payment'
import { getFinanceTypePayment, getFinanceTypeReceipts } from 'apis/finance-type'

//antd
import {
  Row,
  Space,
  Button,
  Input,
  Select,
  Form,
  InputNumber,
  notification,
  Col,
  DatePicker,
} from 'antd'


//components
const { Option } = Select
export default function ModalCreatePaymentOrReceipts({ type, children, record, reload, close }) {
  const [form] = Form.useForm()
  const [payments, setPayments] = useState([])
  const [isModeUpdate, setModeUpdate] = useState(false)
  const [financeTypePayments, setFinanceTypePayments] = useState([])
  const [financeTypeReceipts, setFinanceTypeReceipts] = useState([])
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [statusChange, setStatusChange] = useState([])

  const onFinish = async (values) => {
    const body = {
      note: values.note || '',
      time_transaction: values.time_transaction || null,
      status: values.status 
    }
    let res = await updateFinances(record.receipt_id, body)
    if (res.status === 200) {
      if (res.data.success) {
        notification.success({ message: `Cập nhật phiếu thành công` })
        reload()
        close()
      } else
        notification.error({
          message: 'Cập nhật phiếu thất bại, vui lòng thử lại!'
        })
    } else
      notification.error({
        message:'Cập nhật phiếu thất bại, vui lòng thử lại!'
      })
  }

  const _getFinanceTypePayment = async () => {
    try {
      const res = await getFinanceTypePayment()
      if (res.status === 200) setFinanceTypePayments(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const _getFinanceTypeReceipts = async () => {
    try {
      const res = await getFinanceTypeReceipts()
      if (res.status === 200) setFinanceTypeReceipts(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getFinanceTypePayment()
    _getFinanceTypeReceipts()
  }, [])
  

  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        setPayments(res.data.data)
        const payment = res.data.data.find((e) => e.default)
        form.setFieldsValue({ payments: payment ? payment.payment_method_id : '' })
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    _getPayments()
  }, [])
  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        time_transaction: record.time_transaction !== '' ? moment(record.time_transaction) : moment(record.create_date),
        note: record.note ,
      })
      let status_change = []
      if(record.status === 3){
        status_change = [{name: 'Hoàn thành', value: 1},{name: 'Chờ duyệt', value: 3},{name: 'Từ chối', value:4}]
      }else if(record.status === 2){
        status_change = [{name: 'Hoàn thành', value: 1},{name: 'Chờ thanh toán', value: 2}]
      }else if(record.status === 4){
        status_change = [{name: 'Từ chối', value:4}]
      }else if(record.status === 1){
        status_change = [{name: 'Hoàn thành', value: 1}]
      }
      setStatusChange(status_change)
    } else {
      form.resetFields()
    }
  }, [record])
  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          {' '}
          <Row justify="space-between">
            <Col>
              <Form.Item name="typefinance" label={'Loại phiếu'}>
                {type === ('Phiếu thu' && 'Phiếu thu tự động') ? (
                  <Select
                    allowClear
                    placeholder="Chọn loại phiếu thu"
                    size="large"
                    style={{ width: 280 }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      removeUnicode(option.children.toString())
                        .toLowerCase()
                        .trim()
                        .includes(removeUnicode(input).toLowerCase().trim())
                    }
                  >
                    {financeTypePayments.map((type, index) => (
                      <Option value={type.type_id} key={index} disabled>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    disabled
                    allowClear
                    placeholder="Chọn loại phiếu chi"
                    size="large"
                    style={{ width: 280 }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {financeTypeReceipts.map((type, index) => (
                      <Option value={type.name} key={index}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Form.Item
              name="value"
              label="Nhập giá trị thanh toán"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng giá trị',
                },
              ]}
            >
              <InputNumber
                disabled={isModeUpdate ? false : true}
                value=""
                min={0}
                placeholder="Nhập giá trị "
                style={{ width: 280 }}
                size="large"
                className="br-15__input"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Row>
          <Row justify="space-between">
            {record.type === 'Phiếu chi tự động' || record.type === 'Phiếu chi' ? (
              <Form.Item label="Tên người nhận" disabled>
                <Input
                  defaultValue={record.name_receiver}
                  disabled
                  size="large"
                  style={{ width: 280 }}
                ></Input>
              </Form.Item>
            ) : (
              <Form.Item label="Người thanh toán" disabled>
                <Input
                  defaultValue={record.name_payer}
                  disabled
                  size="large"
                  style={{ width: 280 }}
                ></Input>
              </Form.Item>
            )}
            <Form.Item name="payment_method" label="Hình thức thanh toán">
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                size="large"
                placeholder="Chọn hình thức thanh toán"
                style={{ width: 280 }}
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
                disabled
              >
                {payments.map((payment, index) => (
                  <Select.Option key={index} value={payment.payment_method_id}>
                    {payment.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
          <Row justify="space-between">
            {/* <Form.Item name="time_transaction" label="Thời gian thực hiện giao dịch (nếu có)">
              <DatePicker
                showTime={{
                  defaultValue: moment('00:00:00', 'HH:mm:ss'),
                }}  
                disabledDate={(current) => {
                  // Can not select days before today and today
                  return current && current > moment().endOf('day')
                }}
                format="YYYY/MM/DD HH:mm:ss"
                size="large"
                style={{ width: '280px' }}
              />
            </Form.Item> */}
            <Form.Item
              name="status"
              label="Trạng thái"
            >
              <Select
                size="large"
                className="br-15__input"
                showSearch
                optionFilterProp="children"
                allowClear
                style={{ width: 280 }}
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
              >
                {statusChange.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="note" label="Mô tả">
              <Input.TextArea rows={4} placeholder="Nhập Mô tả" style={{ width: 280 }} />
            </Form.Item>
          </Row>
        </Space>

        <Row justify="end">
          <Button
            style={{
              backgroundColor: type === 'payment' ? '#DE7C08' : '#0877DE',
              borderColor: type === 'payment' ? '#DE7C08' : '#0877DE',
              borderRadius: 5,
              color: 'white',
              fontWeight: 600,
            }}
            type="primary"
            htmlType="submit"
            size="large"
          >
            {record ? 'Cập nhật' : type === 'payment' ? 'Tạo phiếu chi' : 'Tạo phiếu thu'}
          </Button>
        </Row>
      </Form>
    </>
  )
}
