import styles from './configuration-store.module.scss'
import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { PERMISSIONS, ROUTES } from 'consts'

//components
import Permission from 'components/permission'

//antd
import {
  FileExcelOutlined,
  FormOutlined,
  DollarOutlined,
  TeamOutlined,
  CreditCardOutlined,
  AlertOutlined,
  HomeOutlined,
} from '@ant-design/icons'

export default function ConfigurationStore() {
  return (
    <div className={styles['configuration']}>
      <Permission permissions={[]}>
        <div className={`${styles['configuration_content']} ${styles['card']}`}>
          <div
            style={{ marginBottom: 10, color: '#1A3873', fontSize: '1.25rem', fontWeight: '700' }}
          >
            Thông tin về cửa hàng
          </div>

          <Row gutter={[16, 16]}>
            <Permission
              permissions={[
                PERMISSIONS.cau_hinh_phan_quyen,
                PERMISSIONS.xem_phan_quyen,
                PERMISSIONS.them_vai_tro_phan_quyen,
                PERMISSIONS.cap_nhat_vai_tro_phan_quyen,
                PERMISSIONS.xoa_vai_tro_phan_quyen,
              ]}
            >
              <Col xs={24} sm={24} md={11} lg={8} xl={8}>
                <Link to={ROUTES.ROLE}>
                  <Row wrap={false}>
                    <div
                      className={styles['wrap-icon']}
                      style={{
                        backgroundColor: '#FCDFEF',
                        border: '1px solid #F060AE',
                        borderRadius: '0.25rem',
                      }}
                    >
                      <TeamOutlined style={{ color: '#F060AE' }} />
                    </div>
                    <div>
                      <div style={{ fontSize: '1rem', color: '#0015CD' }}>Quản lý phân quyền</div>
                      <div style={{ color: 'black', fontSize: '0.75rem' }}>
                        Tạo và quản lý quyền của tất cả tài khoản
                      </div>
                    </div>
                  </Row>
                </Link>
              </Col>
            </Permission>
            <Permission
              permissions={[
                PERMISSIONS.xem_chi_nhanh,
                PERMISSIONS.cau_hinh_chi_nhanh,
                PERMISSIONS.them_chi_nhanh,
                PERMISSIONS.cap_nhat_chi_nhanh,
              ]}
            >
              <Col xs={24} sm={24} md={11} lg={8} xl={8}>
                <Link to={ROUTES.BRANCH_MANAGEMENT}>
                  <Row wrap={false}>
                    <div
                      className={styles['wrap-icon']}
                      style={{
                        backgroundColor: '#FCF7EB',
                        border: '1px solid #EFC76E',
                        borderRadius: '0.25rem',
                      }}
                    >
                      <HomeOutlined style={{ color: '#EFC76E' }} />
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: '1rem',
                          color: '#0015CD',
                        }}
                      >
                        Chi nhánh
                      </div>
                      <div
                        style={{
                          color: 'black',
                          fontSize: '0.75rem',
                        }}
                      >
                        Tạo và quản lý chi nhánh của cửa hàng
                      </div>
                    </div>
                  </Row>
                </Link>
              </Col>
            </Permission>
          </Row>
        </div>
      </Permission>

      <div className={`${styles['configuration_content']} ${styles['card']}`}>
        <div style={{ marginBottom: 10, color: '#1A3873', fontSize: '1.25rem', fontWeight: '700' }}>
          Thông tin bán hàng
        </div>
        <Row gutter={[16, 16]}>
          <Permission
            permissions={[
              PERMISSIONS.cau_hinh_hoa_don,
              PERMISSIONS.xem_danh_sach_hoa_don,
              PERMISSIONS.cau_hinh_mau_hoa_don,
            ]}
          >
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.SETTING_BILL}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <DollarOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Cấu hình hoá đơn
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: '0.75rem',
                      }}
                    >
                      Thiết lập mẫu hoá đơn
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>
          <Permission
            permissions={[
              PERMISSIONS.cau_hinh_bao_hanh,
              PERMISSIONS.xem_bao_hang,
              PERMISSIONS.them_bao_hanh,
              PERMISSIONS.cap_nhat_bao_hanh,
              PERMISSIONS.xoa_bao_hanh,
              PERMISSIONS.export_bao_hanh,
            ]}
          >
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.GUARANTEE}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <DollarOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Quản lý bảo hành
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: '0.75rem',
                      }}
                    >
                      Thiết lập quản lý bảo hành
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>
          <Permission
            permissions={[
              PERMISSIONS.cau_hinh_thue,
              PERMISSIONS.xem_thue,
              PERMISSIONS.them_thue,
              PERMISSIONS.cap_nhat_thue,
              PERMISSIONS.xoa_thue,
            ]}
          >
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.TAX}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <DollarOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Quản lý thuế
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: '0.75rem',
                      }}
                    >
                      Thiết lập thuế nhập và bán hàng
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>
          <Permission
            permissions={[
              PERMISSIONS.cau_hinh_phuong_thuc_thanh_toan,
              PERMISSIONS.xem_phuong_thuc_thanh_toan,
              PERMISSIONS.them_phuong_thuc_thanh_toan,
              PERMISSIONS.cap_nhat_phuong_thuc_thanh_toan,
              PERMISSIONS.xoa_phuong_thuc_thanh_toan,
            ]}
          >
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.PAYMENT}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <CreditCardOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Quản lý thanh toán
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: '0.75rem',
                      }}
                    >
                      Thiết lập các hình thức thanh toán
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>

          <Permission
            permissions={[
              PERMISSIONS.cau_hinh_tich_diem,
              PERMISSIONS.cap_nhat_tich_diem,
              PERMISSIONS.xem_danh_sach_khach_hang_tich_diem,
            ]}
          >
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.POINT}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <AlertOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Tích điểm
                    </div>
                    <div style={{ color: 'black', fontSize: '0.75rem' }}>
                      Thiết lập và cấu hình tích điểm
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>
          <Permission
            permissions={[
              PERMISSIONS.cau_hinh_khuyen_mai,
              PERMISSIONS.xem_danh_sach_khuyen_mai,
              PERMISSIONS.xem_chi_tiet_khuyen_mai,
              PERMISSIONS.them_khuyen_mai,
              PERMISSIONS.cap_nhat_khuyen_mai,
              PERMISSIONS.xoa_khuyen_mai,
            ]}
          >
            <Col xs={24} sm={24} md={11} lg={8} xl={8}>
              <Link to={ROUTES.PROMOTION}>
                <Row wrap={false}>
                  <div
                    className={styles['wrap-icon']}
                    style={{
                      backgroundColor: '#FCF7EB',
                      border: '1px solid #EFC76E',
                      borderRadius: '0.25rem',
                    }}
                  >
                    <DollarOutlined style={{ color: '#EFC76E' }} />
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: '1rem',
                        color: '#0015CD',
                      }}
                    >
                      Quản lý khuyến mãi
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontSize: '0.75rem',
                      }}
                    >
                      Thiết lập khuyến mãi và bán hàng
                    </div>
                  </div>
                </Row>
              </Link>
            </Col>
          </Permission>
        </Row>
      </div>

      <Permission permissions={[PERMISSIONS.cau_hinh_nhap_xuat_file]}>
        <div className={`${styles['configuration_content']} ${styles['card']}`}>
          <div style={{ color: '#1A3873', fontSize: '1.25rem', fontWeight: '700' }}>Nhật ký</div>
          <Row gutter={[16, 16]}>
            <Permission permissions={[PERMISSIONS.cau_hinh_nhap_xuat_file]}>
              <Col xs={24} sm={11} md={11} lg={8} xl={8}>
                <Link to={ROUTES.IMPORT_REPORT_FILE}>
                  <Row wrap={false}>
                    <div
                      className={styles['wrap-icon']}
                      style={{
                        backgroundColor: '#D7E9DB',
                        border: '1px solid #388F4D',
                        borderRadius: '0.25rem',
                      }}
                    >
                      <FileExcelOutlined style={{ color: '#388F4D' }} />
                    </div>
                    <div>
                      <div style={{ fontSize: '1rem', color: '#0015CD' }}>Nhập/xuất file</div>
                      <div style={{ color: 'black', fontSize: '0.75rem' }}>
                        Quản lý nhập xuất file
                      </div>
                    </div>
                  </Row>
                </Link>
              </Col>
            </Permission>
            <Permission permissions={[PERMISSIONS.cau_hinh_nhat_ki_hoat_dong]}>
              <Col xs={24} sm={11} md={11} lg={8} xl={8}>
                <Link to={ROUTES.ACTIVITY_DIARY}>
                  <Row wrap={false}>
                    <div
                      className={styles['wrap-icon']}
                      style={{
                        backgroundColor: '#E9D4D5',
                        border: '1px solid #8F292F',
                        borderRadius: '0.25rem',
                      }}
                    >
                      <FormOutlined style={{ color: '#8F292F' }} />
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: '1rem',
                          color: '#0015CD',
                        }}
                      >
                        Nhật ký hoạt động
                      </div>
                      <div
                        style={{
                          color: 'black',
                          fontSize: '0.75rem',
                        }}
                      >
                        Quản lý toàn bộ thao tác, nhật ký hoạt động trong cửa hàng
                      </div>
                    </div>
                  </Row>
                </Link>
              </Col>
            </Permission>
          </Row>
        </div>
      </Permission>
    </div>
  )
}
