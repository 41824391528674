import styles from '../import-product.module.css'
import { Button, Progress, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ACTION, ROUTES } from 'consts'
import { downloadFile, findImportFile } from 'apis/import-product'
import fileDownload from 'js-file-download'
export default function Step3({ total, current, dataError }) {
  const history = useHistory()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  const option = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const [percentFormat, setPercentFormat] = useState('0')
  const formatter = new Intl.NumberFormat('en-US', option)

  const _downloadFile = async (e) => {
    try {
      e.preventDefault()
      const query = {
        import_id: dataError.import_id,
      }
      const res = await downloadFile(query)
      if (res.status === 200) {
        var blob = new Blob([decodeURIComponent('%ef%bb%bf'), res.data], {
          type: 'text/csv;charset=utf-8',
        })
        fileDownload(blob, 'test.csv')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _findImportFile = async () => {
    try {
      const query = {
        import_id: dataError.import_id,
      }
      const res = await findImportFile(query)
      if (res.status === 200) {
        setData(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (current === 0 && total === 0) {
      setPercentFormat(formatter.format(0))
      return
    }
    const percent = (current / total) * 100
    setPercentFormat(formatter.format(percent))
    if (formatter.format(percent) === '100.00') {
      _findImportFile()
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [current, total])

  return (
    <div className={styles['result']}>
      {percentFormat === '100.00' ? (
        <h2>Nhập sản phẩm thành công</h2>
      ) : (
        <h2>Đang nhập danh sách sản phẩm ...</h2>
      )}
      <Progress type="circle" percent={percentFormat} />
      {percentFormat === '100.00' && (
        <>
          {data?.errorCount > 0 && <h1>Tải về file sản phẩm không import được</h1>}
          {data?.errorCount > 0 && (
            <Spin spinning={loading}>
              <Button onClick={async (e) => _downloadFile(e)}>Download file lỗi</Button>
            </Spin>
          )}{' '}
          <Row
            align="middle"
            style={{ cursor: 'pointer', marginTop: 10, color: 'blue' }}
            onClick={() => history.push(ROUTES.PRODUCT, { reset: 1 })}
          >
            Quay về danh sách sản phẩm
          </Row>
        </>
      )}
    </div>
  )
}
