import { ConsoleSqlOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, notification, Row, Table, Tag } from 'antd'
import { PERMISSIONS } from 'consts'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//api
import { getProducts, getVariants } from 'apis/product'

//component
import { compare, formatCash } from 'utils'
import styles from './custom-items.module.scss'
import {TableCustom} from './table-custom'
import {columnsProduct} from './column'
import {addProductToStore, addVariantToStore, addAllProductToStore} from '../function-items/add-item'
const LeftTable = (props) => {
  const {productChange, setProductChange, productTableShow, productTableCount} = props
  const [loading, setLoading] = useState(false)
  
  const [variantsSystem, setVariantsSystem] = useState([])
  const [variantsSystemCount, setVariantsSystemCount] = useState([])

  const columnsVariant = [
    {
      title: 'Thuộc tính',
      dataIndex: 'title',
      align: 'center',
      render: (text, record) => <span style={{fontSize: 11}}>{record.title}</span>,
    },
    {
      title: 'Mã SKU',
      dataIndex: 'sku',
      align: 'center',
      render: (text, record) => <Tag color="geekblue">{record.sku}</Tag>,
    },
    {
      title: 'SL',
      align: 'center',
      render: (text, record) => <span>{formatCash(record.quantity || 0)}</span>,
    },
    {
      title: 'Giá ban đầu',
      align: 'center',
      render: (text, record) => <span>{formatCash(record.default_price || 0)}</span>,
    },
    {
      title: 'Giá thay đổi',
      align: 'center',
      render: (text, record) => <span>{formatCash(record.price || 0)}</span>,

    },
    {
      width: 70,
      align: 'center',
      render: (text, record) => {
        let new_data = [...productChange]
        let arr_id = new_data.map(e => e.variant_id)
        if(arr_id.includes(record.variant_id)) return
        return (<Button onClick={(e) => {
        e.stopPropagation()
        addVariantToStore(record, productChange, setProductChange)
      }} size='small' type='primary'>Chọn</Button>)}
    },
  ]

  
  const _getVariants = async () => {
    setLoading(true)
    try {
      const res = await getVariants()
      if (res.status === 200) {
        setVariantsSystem(res.data.data)
        setVariantsSystemCount(res.data.count)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    _getVariants()
  },[])
  

  return (
    <TableCustom
      rowKey="product_id"
      scroll={{ y: 500 }}
      columns={columnsProduct.map((column) => {
        if (column.key === 'product_id')
          return {
            ...column,
            width: 70,
            render: (text, record) => <span>{record.product_id}</span>,
          }
        if (column.key === 'name')
          return {
            ...column,
            render: (text, record) => <span>{record.name}</span>,
          }
        if (column.key === 'sku')
          return {
            ...column,
          render: (text, record) =>
          record?.variants.length === 1 ? (
            <span>{record?.variants[0]?.sku}</span>
          ) : (
            <span>{record?.variants.length} Phiên bản</span>
          ),
        }
        if (column.key === 'quantity')
          return {
            ...column,
            width: 50,
            render: (text, record) => <span>{formatCash(record.open_sell_quantity || 0)}</span>,
          }

        if (column.key === 'default_price')
          return {
            ...column,
            render: (text, record) => <span>{formatCash(record.default_price || 0)}</span>,
          }

        if (column.key === 'price')
          return {
            ...column,
            render: (text, record) => <span>{formatCash(record.price || 0)}</span>,
          }

        if (column.key === 'action')
          return {
            ...column,
            width: 70,
            render: (text, record) => {
              let new_data = [...productChange]
              let arr_id = new_data.map(e => e?.variant_id)
              if(record?.variants?.find(e => arr_id.includes(e?.variant_id))) return
              return (
              <Button onClick={(e) => {
                e.stopPropagation()
                addProductToStore(record, productChange, setProductChange)
              }} type="primary" size="small">
                Chọn
              </Button>
            )},
            title: <Button onClick={(e) => {
              e.stopPropagation()
              addAllProductToStore(variantsSystem, setProductChange)
            }} size='small'>Tất cả</Button>,
          }
        return column
      })}
      loading={loading}
      dataSource={productTableShow}
      size="small"
      pagination={{
        position: ['bottomLeft'],
        // current: paramsFilter.page,
        // pageSize: paramsFilter.page_size,
        pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
        showQuickJumper: true,
        // onChange: (page, pageSize) =>
        //   setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
        total: productTableCount,
      }}
      expandable={{
        expandedRowRender: (record, index, indent, expanded) => {
          if (record.variants.length > 1) {
            return (
              <Table
                key={index}
                className={`${styles['variant-columns']} table-child`}
                showHeader={false}
                pagination={false}
                columns={columnsVariant}
                dataSource={record.variants}
                size="small"
              />
            )
          }
        },
        expandIconColumnIndex: -1,
        expandRowByClick: true,
      }}
      className={styles['table-items-columns']}
    />
  )
}

export default LeftTable
