import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Card, Layout, Popconfirm, Row, Space, Spin, Table } from 'antd'
import React from 'react'
import { compare, formatCash } from 'utils'
import styles from '../import-smart-product.module.scss'
import columns from '../items/columns-product'

const { Content } = Layout

const LayoutTable = ({ products, setProducts, setEditProduct, loading }) => {
  const deleteProduct = (sku) => {
    let newProduct = products
    if (newProduct.length > 0) {
      newProduct = newProduct.filter((item) => item.sku != sku)
    }
    setProducts([...newProduct])
  }

  const editProduct = (record) => {
    setEditProduct(record)
  }

  return (
    <Content>
      <Card
        headStyle={{
          fontSize: 20,
        }}
        style={{
          borderRadius: '10px',
          boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
          transition: 'all .3s ease-in',
        }}
        size="small"
        title="Sản phẩm sẽ thêm"
      >
        <Spin tip="Loading..." spinning={loading}>
          <Table
            size="small"
            style={{ maxHeight: 600 }}
            scroll={{ y: 500 }}
            dataSource={products}
            columns={columns.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  render: (text, record, index) => <span>{index + 1}</span>,
                }
              if (column.key === 'image')
                return {
                  ...column,
                  render: (text, record) => (
                    <img style={{ width: '25px', height: '25px' }} src={`${record.image}`} />
                  ),
                }
              if (column.key === 'sku')
                return {
                  ...column,
                  render: (text, record) => <span>{text}</span>,
                  sorter: (a, b) => compare(a, b, 'sku'),
                }

              if (column.key === 'name')
                return {
                  ...column,
                  render: (text, record) => <span>{text}</span>,
                  sorter: (a, b) => compare(a, b, 'name'),
                }
              if (column.key === 'quantity')
                return {
                  ...column,
                  render: (text, record) => <span>{text}</span>,
                  sorter: (a, b) => compare(a, b, 'quantity'),
                }

              if (column.key === 'price')
                return {
                  ...column,
                  render: (text, record) => <span>{formatCash(record.price || 0)}</span>,
                }
              if (column.key === 'import_price')
                return {
                  ...column,
                  render: (text, record) => (
                    <span>{formatCash(record.import_price || 0)}</span>
                  ),
                }
              if (column.key === 'unit')
                return {
                  ...column,
                  render: (text, record) => <span>{text}</span>,
                  sorter: (a, b) => compare(a, b, 'unit'),
                }
              if (column.key === 'note')
                return {
                  ...column,
                  render: (text, record) => <span>{text}</span>,
                }
              if (column.key === 'action')
                return {
                  ...column,
                  render: (text, record) => (
                    <div>
                      <Space>
                        <Button onClick={() => editProduct(record)} icon={<EditOutlined />} />
                        <Popconfirm
                          onConfirm={() => deleteProduct(record.sku)}
                          title="Xoá sản phẩm？"
                          okText="Đồng ý"
                          cancelText="Huỷ bỏ"
                        >
                          <Button style={{ color: 'red' }} icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Space>
                    </div>
                  ),
                }
              return column
            })}
            className={styles['product-smart-columns']}
          />
        </Spin>
      </Card>
    </Content>
  )
}
export default LayoutTable
