import React, { useState, useEffect, useRef } from 'react'
import columns from './columns'
import { useHistory } from 'react-router-dom'
import { ACTION, PAGE_SIZE, PERMISSIONS, ROUTES, TIME_FORMAT } from 'consts'
import moment from 'moment'
import { compare, formatCash, compareCustom, removeUnicode } from 'utils'
import { useDispatch, useSelector } from 'react-redux'

//apis
import { getPayments } from 'apis/payment'
import { getOrderPayment } from 'apis/order'
import { getStaff } from 'apis/manage-staff'
import TitlePage from 'components/title-page'

//antd
import { Row, Select, Table, Col, Input, Button } from 'antd'

//icons
import {
  CloseOutlined,
  ArrowLeftOutlined,
  SearchOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons'

//components
import FilterDate from 'components/filter-date/filter'
import ConnectStatus from './connect-order'
import { handleCell } from 'components/handleCell'
import { DefaultFilterData } from 'utils/DefaultFilterData'
import exportToCSV from 'components/ExportCSV/export'

export default function ReceiptsAndPayment() {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const history = useHistory()
  const typingTimeoutRef = useRef(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [payments, setPayments] = useState([])
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [valueSearch, setValueSearch] = useState('')
  const [employees, setEmployees] = useState([])
  const [ordersPayment, setOrdersPayment] = useState([])
  const [ordersPaymentShow, setOrdersPaymentShow] = useState([])
  const [countOrderPayment, setCountOrderPayment] = useState()
  const [total, setTotal] = useState(0)
  const [valueTime, setValueTime] = useState()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.trim()

      if (value) paramsFilter.code = value
      else delete paramsFilter.code
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const onFilters = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setValueSearch('')
    setValueTime()
  }
  const handleTotal = (data) => {
    let total = data.reduce((sum, current) => (sum += current.value), 0)
    return total
  }
  const _getOrdersPayment = async () => {
    try {
      setLoading(true)
      const res = await getOrderPayment({ branch_id: branchIdApp })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setOrdersPayment(result.data)
        setValueSearch()
        setParamsFilter({
          page: 1,
          page_size: PAGE_SIZE,
          from_date: result.defaultTime.from_date,
          to_date: result.defaultTime.to_date,
        })
        setValueTime(result.valueDate)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) setEmployees(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        setPayments(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getReportOrderToExportExcel = async () => {
    let reportNew = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      ordersPaymentShow.forEach((e, index) => {
        reportNew.push({
          STT: index + 1,
          'Mã chứng từ': e.order_info.code,
          'Thao tác':
            e.type === 'SALE'
              ? 'Bán hàng'
              : e.type === 'REFUND'
              ? 'Trả hàng'
              : 'Tiền thừa trả khách',
          'Phương thức thanh toán': e.payment_name,
          'Giá trị': formatCash(e.value || 0),
          'Ngày tạo': moment(e?.order_info?.create_date).format(TIME_FORMAT.DATE_TIME),
          'Người tạo': e.creator_info
          ? `${e.creator_info.first_name} ${e.creator_info.last_name}`
          : '',
          'Ghi chú': e.order_info.note,
        })
      })
      exportToCSV(reportNew, 'Báo cáo bán hàng theo phương thức thanh toán', 'Xuất file excel',dispatch)
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  useEffect(() => {
    let arr = [...ordersPayment]
    const filtered = arr.filter((current) => {
      let matchType = true
      let matchTime = true
      let matchCode = true
      let matchUser = true

      if (paramsFilter.employee_id && paramsFilter.employee_id !== 'all')
        matchType = current?.employee_id === paramsFilter.employee_id
      if (paramsFilter.payment_method_id && paramsFilter.payment_method_id !== 'all')
        matchType = current?.payment_method_id === paramsFilter.payment_method_id
      if (paramsFilter.code) {
        matchCode = removeUnicode(current?.order_info?.code.toString())
          .toLowerCase()
          .trim()
          .includes(removeUnicode(paramsFilter.code).toLowerCase().trim())
      }

      if (paramsFilter.to_date)
        matchTime =
          moment(current?.create_date).unix() >= paramsFilter.from_date &&
          moment(current?.create_date).unix() <= paramsFilter.to_date
      return matchType && matchCode && matchTime && matchUser
    })
    setOrdersPaymentShow(filtered)
    setCountOrderPayment(filtered.length)
    setTotal(handleTotal(filtered))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, valueSearch])
  useEffect(() => {
    _getPayments()
    _getEmployees()
  }, [branchIdApp])
  useEffect(() => {
    _getOrdersPayment()
  }, [branchIdApp])
  return (
    <div className="card">
      <TitlePage
        isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.REPORTS)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Danh sách đơn hàng theo phương thức thanh toán
          </Row>
        }
      >
        <Button
          id="exportFile"
          icon={<VerticalAlignTopOutlined />}
          onClick={_getReportOrderToExportExcel}
          style={{ backgroundColor: 'green', borderColor: 'green' }}
          size="large"
          type="primary"
        >
          Xuất excel
        </Button>
      </TitlePage>
      <Row align="middle" style={{ marginTop: 10 }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={5}
        >
          <Input
            style={{ width: '100%' }}
            prefix={<SearchOutlined />}
            name="name"
            value={valueSearch}
            onChange={onSearch}
            placeholder="Tìm kiếm theo mã đơn hàng"
            bordered={true}
            defaultValue={''}
            allowClear
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={5}>
          <Select
            clearIcon={<CloseOutlined />}
            allowClear
            bordered={true}
            optionFilterProp="children"
            placeholder="Hình thức thanh toán"
            style={{ width: '100%'}}
            value={paramsFilter.payment_method_id || 'all'}
            defaultValue={'all'}
            onChange={(value) => onFilters('payment_method_id', value)}
          >
            <Select.Option value="all">Tất cả phương thức thanh toán</Select.Option>
            {payments.map((payment, index) => (
              <Select.Option key={index} value={payment.payment_method_id}>
                {payment.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={5}>
          <FilterDate
          size='default'
            style={{ with: '100%'}}
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            width="100%"
            title="tạo đơn hàng"
            valueTime={valueTime}
            setValueTime={setValueTime}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={5}>
          <Select
            clearIcon={<CloseOutlined />}
            allowClear
            bordered={true}
            showSearch
            optionFilterProp="children"
            placeholder="Nhân viên tạo"
            style={{ width: '100%'}}
            value={paramsFilter.employee_id || 'all'}
            defaultValue={'all'}
            onChange={(value) => onFilters('employee_id', value)}
          >
            <Select.Option value="all">Tất cả nhân viên</Select.Option>
            {employees.map((employee, index) => (
              <Select.Option value={employee.user_id} key={index}>
                {employee.first_name} {employee.last_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={4}>
          <Button
            style={{ display: Object.keys(paramsFilter).length < 3 && 'none', width: '100%' }}
            onClick={onClickClear}
            type="primary"
            danger
          >
            Xóa tất cả lọc
          </Button>
        </Col>
      </Row>
      <br />
      <Table
        loading={loading}
        dataSource={ordersPaymentShow}
        size="small"
        scroll={{ y: 620 }}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: [20, 30, 40, 50, 70, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countOrderPayment,
        }}
        columns={columns.map((column) => {
          if (column.key === 'stt') {
            return {    
              ...column,
              render: (text,record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1
            }
          }
          if (column.key === 'code')
            return {
              ...column,
              render: (text, record) => (
                <ConnectStatus permissions={[PERMISSIONS.chi_tiet_don_hang]} record={record} />
              ),
              sorter: (a, b) => compare(a?.order_info, b?.order_info, 'code'),
              onCell: (record, index) =>
                handleCell(
                  record,
                  (paramsFilter.page - 1) * paramsFilter.page_size + index,
                  ordersPaymentShow,
                  'order_id',
                  (paramsFilter.page - 1) * paramsFilter.page_size,1
                ),
            }
          if (column.key === 'type') {
            return {
              ...column,
              render: (text, record) =>
                record.type === 'SALE'
                  ? 'Bán hàng'
                  : record.type === 'REFUND'
                  ? 'Trả hàng'
                  : 'Tiền thừa trả khách',
            }
          }
          if (column.key === 'payment')
            return {
              ...column,
              sorter: (a, b) =>
                compareCustom(
                  a.payment_name ? `${a.payment_name}` : '',
                  b.payment_name ? `${b.payment_name}` : ''
                ),
              render: (text, record) => record.payment_name,
            }

          if (column.key === 'value')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'value'),
              render: (text, record) => record.value && formatCash(record.value || 0),
            }
          if (column.key === 'date')
            return {
              ...column,
              sorter: (a, b) =>
                moment(a?.order_info?.create_date).unix() -
                moment(b?.order_info?.create_date).unix(),
              render: (text, record) =>
                record?.order_info?.create_date &&
                moment(record?.order_info?.create_date).format(TIME_FORMAT.DATE_TIME),
            }

          if (column.key === 'creator')
            return {
              ...column,
              sorter: (a, b) =>
                compareCustom(
                  a.creator_info ? `${a.creator_info.first_name} ${a.creator_info.last_name}` : '',
                  b.creator_info ? `${b.creator_info.first_name} ${b.creator_info.last_name}` : ''
                ),
              render: (text, record) =>
                record.creator_info
                  ? `${record.creator_info.first_name} ${record.creator_info.last_name}`
                  : '',
            }

          if (column.key === 'note')
            return {
              ...column,
              render: (text, record) => record?.order_info?.note,
            }
          return column
        })}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <div style={{ fontWeight: 700 }}>Tổng</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700,textAlign:'center' }}>{formatCash(total)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
