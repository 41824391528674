import React, { useEffect, useState } from 'react'
import {
  Row,
  Modal,
  Button,
  Tag,
  Table,
  Popover,
  Col,
  Typography,
  notification,
} from 'antd'
import { useTranslation } from 'react-i18next'
// import styles from '../sell.module.scss'
import { EditOutlined } from '@ant-design/icons'
import { useCallback } from 'react'
import { getLocationImei } from 'apis/location'
import { useSelector } from 'react-redux'
import { sortBy } from 'lodash'

export const ModalProductiMei = ({
  product,
  variant_id,
  addProductToCartInvoice,
  index,
  ellipsis,
  selectedRowKeys,
  onSelectChange,
  _editProductInInvoices,
  setProductQuantity,
  productQuantity,
}) => {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { Paragraph, Text } = Typography
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const { t } = useTranslation()
  const [iMei, setImei] = useState([])

  const _getLocationImei = async () => {
    try {
      const res = await getLocationImei({ variant_id: variant_id, branch_id: branchIdApp })
      console.log(res)
      if (res.status == 200) {
          setImei(res.data.data.imei)
     
      }

    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div>
      <div
        onClick={(e) => {
          e.stopPropagation()
          toggle()
        }}
        style={{

          alignItems: 'center',
          fontSize: 12,
          color: 'gray',
          cursor: 'pointer',
        }}
      >
        <Popover style={{ maxWidth: 450 }} placement="bottomLeft">
          <Text
            style={ellipsis ? { width: 50 } : undefined}
            // className={styles['sell-product__item-note']}
            onClick={async (e) => {
              e.preventDefault()
              await _getLocationImei()
            }}
          >
            {'Xem chi tiết'}
          </Text>
        </Popover>
        <EditOutlined style={{ marginLeft: 5 }} />
      </div>
      <Modal
        title={`Danh sách iMei của sản phẩm ${product.title}`}
        width={800}
        closable={false}
        visible={visible}
        footer={[
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button
                onClick={() => {
                  toggle()
                }}
              >
                Đóng
              </Button>
            </Col>
  
          </Row>,
        ]}
      >
        <div>{iMei.map((e)=> (
          <Tag><strong>{e}</strong></Tag>
        ))}</div>
      </Modal>
    </div>
  )
}
