import React, { useEffect, useState } from 'react'
import styles from './import-customer.module.css'
import { ROUTES } from 'consts'
import { useHistory } from 'react-router-dom'

//antd
import { Col, Row, Steps } from 'antd'
import { useStepsForm } from 'sunflower-antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

// components
import Step1 from './steps/step-1'
import Step2 from './steps/step-2'
import Step3 from './steps/step-3'
import { useSelector } from 'react-redux'
import { socket } from '../../socket'
import TitlePage from 'components/title-page'

export default function ImportCustomer() {
  const { Step } = Steps
  const [currentCustomer, setCurrentCustomer] = useState(0)
  const [totalCustomer, setTotalCustomer] = useState(0)
  const [dataError, setDataError] = useState({})
  const [filePath, setFilePath] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const user = useSelector((state) => state.login.dataUser)
  const history = useHistory()

  const { current, stepsProps, submit, gotoStep } = useStepsForm({
    isBackValidate: false,
    async submit(values) {
      switch (values.step) {
        case 0:
          gotoStep(0)
          setCurrentStep(0)
          return
        case 1:
          setFilePath(values.path)
          gotoStep(1)
          setCurrentStep(1)
          return
        case 2:
          gotoStep(2)
          setCurrentStep(2)
          return
      }
      return values
    },
  })

  const formList = [
    <Step1 gotoStep={gotoStep} current={current} submit={submit} />,
    <Step2
      filePath={filePath}
      gotoStep={gotoStep}
      current={current}
      submit={submit}
      setDataError={setDataError}
    />,
    <Step3 total={totalCustomer} current={currentCustomer} dataError={dataError} />,
  ]

  const socketListener = () => {
    socket.on(`import:customer:${user.user_id}`, async (res) => {
      if (currentStep != 2) {
        setCurrentStep(2)
        await gotoStep(2)
      }
      setCurrentCustomer(res.current)
      setTotalCustomer(res.total)
    })
  }

  useEffect(() => {
    socketListener()
  }, [socket])

  return (
    <div className="card">
      <TitlePage
        title={
          <Row
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.CUSTOMER)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Nhập khách hàng bằng file excel
          </Row>
        }
      ></TitlePage>
      <Row>
        <div className={styles['container']}>
          <Col className={styles['import-steps']}>
            <Row>
              <Col span="5" style={{ marginBottom: 20 }}>
                <h2>Nhập khách hàng</h2>
              </Col>
              <Col span="19">
                <Steps {...stepsProps}>
                  <Step disabled={true} title="Chọn file import" />
                  <Step disabled={true} title="Xác nhận dữ liệu" />
                  <Step disabled={true} title="Trạng thái" />
                </Steps>
              </Col>
            </Row>
            <Row>
              <div style={{ width: '100%' }}>{formList[current]}</div>
            </Row>
          </Col>
        </div>
      </Row>
    </div>
  )
}
