import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'antd'
import Modal from 'components/Modal'
import CloseIcon from 'assets/icons/close.svg'
import GhtkForm from './ShippingInfo/ghtk'
import { Button } from 'components/Button/Button'

const GHTK = ({ deliveryAddress, branchInfo, customer,shipping_info, edit }) => {
  const branches = useSelector((state) => state.branch)
  const [branch] = useState(branches.branches.find((item) => item.branch_id === branches.branchId))
  const ModalGHTKForm = ({ children,deliveryAddress, customer, shipping_info, edit }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          style={{ top: 30 }}
          onCancel={toggle}
          width={'50%'}
          footer={null}
          title={
            <img src="https://khachhang.giaohangtietkiem.vn/web/logo.svg" alt="logoGHTK"></img>
          }
          visible={visible}
          closeIcon={<img src={CloseIcon} alt="" />}
        >
          <GhtkForm deliveryAddress={deliveryAddress} customer={customer} shipping_info={shipping_info} edit={edit} close={toggle} />
        </Modal>
      </>
    )
  }

  return (
    <Card
      style={{ width: '100%', marginTop: '10px' }}
      size="small"
      title={
        <div style={{display:"flex",justifyContent: 'space-between'}}>
          <h3>{`Giao hàng tại chi nhánh: ${branch.name}`}</h3>
          <ModalGHTKForm deliveryAddress={deliveryAddress} customer={customer} shipping_info={shipping_info} edit={edit}>
            <Button classes="btn__blue-fill" text="Mở rộng" />
          </ModalGHTKForm>
        </div>
      }
    >
      <div>
        <strong>Địa chỉ nhận hàng: </strong> {branchInfo?.address}, {branchInfo?.ward},{' '}
        {branchInfo?.district}, {branchInfo?.province}
      </div>
      <div>
        <strong>Địa chỉ giao hàng: </strong>
        {deliveryAddress?.address},{' '}{deliveryAddress?.ward?.ward_name},{' '}{deliveryAddress?.district?.district_name},{' '}{deliveryAddress?.province?.province_name}
      </div> 
    </Card>
  )
}
export default GHTK
