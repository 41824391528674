import React, { useState, useEffect, useRef } from 'react'
import styles from './order-list.module.scss'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ROUTES, PERMISSIONS, PAGE_SIZE, PAGE_SIZE_OPTIONS, FILTER_SIZE, ACTION } from 'consts'

import { compare, formatCash, removeUnicode } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

//antd
import { Input, Button, Row, Table, Select, Space, Col, Affix, Tag, notification } from 'antd'

//icons
import { PlusCircleOutlined, SearchOutlined, ToTopOutlined } from '@ant-design/icons'

//components
import FilterDate from 'components/filter-date/filter'
import columnsOrderNew from './columns'
import TitlePage from 'components/title-page'
import exportToCSV from 'components/ExportCSV/export'
import ImportCSV from 'components/ImportCSV'

//apis
import { getOrders, importOrder } from 'apis/order'
import { getStaff } from 'apis/manage-staff'
import { getStatusShipping } from 'apis/shipping'
import { getPayments } from 'apis/payment'
import ConnectStatus from '../order-update/connect-status'
import { DefaultFilterData } from 'utils/DefaultFilterData'

export default function OrderList() {
  const { t } = useTranslation()
  const typingTimeoutRef = useRef(null)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [statusOrder, setStatusOrder] = useState([])
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [orderShow, setOrderShow] = useState([])
  const dispatch = useDispatch()
  const [total_complete, setTotalComplete] = useState([])
  const [total_cancel, setTotalCancel] = useState([])
  const [total_refund, setTotalRefund] = useState([])
  const [total_pickup, setTotalpickup] = useState([])
  const [total_confirm, setTotalConfirm] = useState([])
  const [total_delivering, setTotalDelivering] = useState([])

  const [countOrder, setCountOrder] = useState(0)
  const [totalCostOrder, setTotalCostOrder] = useState({})
  const [employees, setEmployees] = useState([])
  const [payments, setPayments] = useState([])
  const [paramsFilter, setParamsFilter] = useState({})
  const [optionSearchName, setOptionSearchName] = useState('code')
  const [valueTime, setValueTime] = useState()
  const [valueSearch, setValueSearch] = useState('')

  const handleTotal = (data) => {
    let total = {
      total_quantity: 0,
      total_price_product: 0,
      total_discount_order: 0,
      total_customer_paid: 0,
      total_fee_shipping: 0,
      total_profit: 0
    }
    data.forEach((element) => {
      if(element.status !== 100){
        total.total_quantity += element.total_quantity
        let sum_price = element.order_details.reduce((total, item) => (total += (item.price * item.quantity)), 0)
        total.total_price_product += sum_price
        total.total_discount_order += element.total_discount
        total.total_customer_paid += element.final_cost
        total.total_fee_shipping += element.shipping_info.fee_shipping
        total.total_profit += element.payer_shipping ? element.final_cost + element.shipping_info.fee_shipping : element.final_cost
      }
    })
    return total
  }
  const _onSearch = (e) => {
    setValueSearch(e)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (e) paramsFilter[optionSearchName] = e.trim()
      else delete paramsFilter[optionSearchName]

      setParamsFilter({ ...paramsFilter })
    }, 500)
  }

  const _onChangeFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter })
  }

  useEffect(() => {
    let arr = [...orders]
    const filteredOrder = arr.filter((current) => {
      let matchCreator = true
      let matchPayment = true
      let matchStatus = true
      let matchTime = true
      let matchSearch = true
      let matchOrderType = true
      if (paramsFilter.order_type && paramsFilter.order_type !== 'all')
        matchOrderType = current?.order_type === paramsFilter.order_type
      if (paramsFilter.creator_id && paramsFilter.creator_id !== 'all')
        matchCreator = current?.creator_id === paramsFilter.creator_id
      if (paramsFilter.payment_method && paramsFilter.payment_method !== 'all')
        matchPayment =
          current.payments.findIndex((e) => e.payment_method_id === paramsFilter.payment_method) >=
          0
      if (paramsFilter.bill_status && paramsFilter.bill_status !== 'all')
        matchStatus = current?.status === paramsFilter.bill_status
      if (paramsFilter.code)
        matchSearch = removeUnicode(current.code.toString())
          .toLowerCase()
          .trim()
          .includes(removeUnicode(paramsFilter.code).toLowerCase().trim())
      if (paramsFilter.product_name) {
        let index = current?.order_details.findIndex((e) =>
          removeUnicode(e.title.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.product_name).toLowerCase().trim())
        )
        if (index === -1) matchSearch = false
      }
      if (paramsFilter.product_sku) {
        let index = current.order_details.findIndex((e) =>
          removeUnicode(e.sku.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.product_sku).toLowerCase().trim())
        )
        if (index === -1) matchSearch = false
      }
      if (paramsFilter.customer_name) {
        matchSearch =
          removeUnicode(current?.customer_info?.first_name.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.customer_name).toLowerCase().trim()) ||
          removeUnicode(current?.customer_info?.last_name.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.customer_name).toLowerCase().trim())
      }
      if (paramsFilter.customer_code)
        matchSearch = current.customer_info.code.includes(paramsFilter.customer_code)
      if (paramsFilter.customer_phone)
        matchSearch = current.customer_info.phone.includes(paramsFilter.customer_phone)
      if (paramsFilter.employee_name) {
        matchSearch =
          removeUnicode(current?.employee?.first_name.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.employee_name).toLowerCase().trim()) ||
          removeUnicode(current?.employee?.last_name.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.employee_name).toLowerCase().trim())
      }
      if (paramsFilter.note) {
        matchSearch =
          removeUnicode(current?.note.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.note).toLowerCase().trim())
      }
      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date

      return matchCreator && matchPayment && matchStatus && matchTime && matchSearch && matchOrderType
    })
    setOrderShow(filteredOrder)
    setCountOrder(filteredOrder.length)
    setTotalCostOrder(handleTotal(filteredOrder))
    setTotalComplete(filteredOrder.filter((e) => e.order_status === 'complete' || e.status === 200))
    setTotalCancel(
      filteredOrder.filter((e) => e.order_status === 'cancel-order' || e.status === 500)
    )
    setTotalRefund(
      filteredOrder.filter((e) => e.order_status === 'refund_order' || e.status === 400)
    )
    setTotalpickup(
      filteredOrder.filter((e) => e.order_status === 'waiting-pickup' || e.status === 100)
    )
    setTotalConfirm(
      filteredOrder.filter((e) => e.order_status === 'confirm-pickup' || e.status === 101)
    )
    setTotalDelivering(
      filteredOrder.filter((e) => e.order_status === 'delivering' || e.status === 102)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter])
  const _getOrders = async () => {
    try {
      setLoading(true)
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getOrders({ branch_id: branchIdApp, role_id: dataUser?.role_id })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setOrders(result.data)
        setParamsFilter({
          page: 1,
          page_size: PAGE_SIZE,
          from_date: result.defaultTime.from_date,
          to_date: result.defaultTime.to_date,
        })
        setValueTime(result.valueDate)
          setValueSearch('')
        }
        dispatch({ type: ACTION.LOADING, data: false })
        setLoading(false)
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) setEmployees(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getStatus = async () => {
    try {
      const res = await getStatusShipping()
      if (res.status === 200) setStatusOrder(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        setPayments(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _handleStatusExport = (status) =>{
    switch (status) {
      case 100:
        return "Chờ xác nhận"
      case 101:
        return "Chờ lấy hàng"
      case 102:
        return "Đang giao"
      case 200:
        return "Hoàn thành"
      case 400:
        return "Đơn trả hàng"
      default:
        return "Đơn huỷ"
    }
  }
  const _getOrdersToExport = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      // let new_params_filter = { ...paramsFilter }
      // delete new_params_filter.page
      // delete new_params_filter.page_size
      // const res = await getOrders({ ...new_params_filter, branch_id: branchIdApp })

      // if (res.status === 200) {
      let dataExport = []

      orderShow &&
        orderShow.map((order, key_index) => {
            const obj = {
              STT: key_index + 1,
              'Mã đơn hàng': order.code || '',
              'Tên chi nhánh': order.sale_location ? order.sale_location.name : '',
              'Kênh bán hàng': order.channel || '',
              'Trạng thái đơn hàng': _handleStatusExport(order.status),
              'Tên khách hàng': order.customer_info
                ? order.customer_info.first_name + ' ' + order.customer_info.last_name
                : '',
              'Số lượng': order.order_details.reduce((sum, e) => sum += e.quantity ,0) || 0,
              'Tổng tiền hàng': order.order_details.reduce((sum, e) => {
                sum += (e.price * e.quantity)
                return sum
              }, 0),
              'Khuyến mãi': order.total_discount || 0,
              'Phí vận chuyển': order.shipping_info.fee_shipping,
              'Khách đã trả': order.final_cost || 0,
              'Phương thức thanh toán': order.payments
                ? Array(order.payments.map((item) => item.name)).join(', ')
                : '',
              'Đơn vị vận chuyển': order.shipping_company_info
                ? order.shipping_company_info.name
                : '',
              'Mã vận đơn': order.shipping_info ? order.shipping_info.tracking_number : '',
              'Ngày tạo': moment(order.create_date).format('DD/MM/YYYY'),
              'Nhân viên': order.employee.first_name + ' ' + order.employee.last_name || 0,
              'Ghi chú': order.note,
            }
            dataExport.push(obj)
        })
      exportToCSV(dataExport, 'Danh sách đơn hàng', 'Xuất excel đơn hàng', dispatch)
      // }
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const ModalOptionExportOrders = () => {
    return permissions.includes(PERMISSIONS.export_don_hang) ? (
      <Button
        id="exportFile"
        style={{ backgroundColor: 'green', borderColor: 'green' }}
        size="large"
        onClick={_getOrdersToExport}
        type="primary"
        icon={<ToTopOutlined />}
      >
        Xuất excel
      </Button>
    ) : (
      <Button size="large" type="primary" disabled icon={<ToTopOutlined />}>
        Xuất excel
      </Button>
    )
  }
  const onClickClear = async () => {
    setValueSearch('')
    setValueTime('')
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
  }
  useEffect(() => {
    _getEmployees()
    _getStatus()
    _getPayments()
    _getOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser])
  return (
    <div className="card">
      
        <TitlePage title="Danh sách đơn hàng" isAffix>
          <Space>
            <Button
              style={{
                display:
                  Object.keys(paramsFilter).length >= 1 || valueTime !== '' ? 'inline' : 'none',
              }}
              onClick={onClickClear}
              type="danger"
            >
              {t('common.clear_all_filters')}
            </Button>
            <ModalOptionExportOrders />
            {/* <>
              {permissions.includes(PERMISSIONS.tao_don_hang) ? (
                <>
                  <ImportCSV
                    size="large"
                    txt="Import đơn hàng"
                    upload={importOrder}
                    title="Nhập đơn hàng bằng file excel"
                    fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/07/15/d1aebc72-1075-4abc-be9d-76ee3968a075/import_don_hang_mau.xlsx"
                    reload={() => _getOrders()}
                  />
                  <Link to={{ pathname: ROUTES.ORDER_CREATE, title: 'order-list' }}>
                    <Button size="large" type="primary" icon={<PlusCircleOutlined />}>
                      {t('order_list.create_an_order')}
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <Button disabled size="large" type="primary" icon={<PlusCircleOutlined />}>
                    Import đơn hàng
                  </Button>
                  <Link to={{ pathname: ROUTES.ORDER_CREATE, title: 'order-list' }}>
                    <Button disabled size="large" type="primary" icon={<PlusCircleOutlined />}>
                      {t('order_list.create_an_order')}
                    </Button>
                  </Link>
                </>
              )}
            </> */}
          </Space>
        </TitlePage>
      
      {permissions.includes(PERMISSIONS.xem_danh_sach_don_hang) ? (
        <>
          <div style={{ marginTop: 15 }}>
            <Row
              gutter={[8, 16]}
              justify="space-between"
              style={{ marginRight: 0, marginLeft: 0 }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
              >
                <Input
                  style={{ width: '60%'}}
                  prefix={<SearchOutlined />}
                  name="name"
                  value={valueSearch}
                  onChange={(e) => _onSearch(e.target.value)}
                  placeholder={t('common.search_by')}
                  bordered={true}
                  allowClear
                />
                <Select
                  showSearch
                  size={FILTER_SIZE}
                  style={{ width: '40%'}}
                  value={optionSearchName}
                  onChange={(value) => {
                    delete paramsFilter[optionSearchName]
                    setOptionSearchName(value)
                  }}
                  bordered={true}
                >
                  <Select.Option value="code">{t('order_list.code_orders')}</Select.Option>
                  <Select.Option value="product_name">{t('order_list.product_name')}</Select.Option>
                  <Select.Option value="product_sku">{t('order_list.product_sku')}</Select.Option>
                  <Select.Option value="customer_name">{t('common.customer_name')}</Select.Option>
                  <Select.Option value="customer_code">
                    {t('order_list.customer_code')}
                  </Select.Option>
                  <Select.Option value="customer_phone">
                    {t('order_list.customer_phone')}
                  </Select.Option>
                  <Select.Option value="note">
                    {t('order_list.note')}
                  </Select.Option>
                </Select>
              </Col>

              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={6}
                  >
                    <FilterDate
                    size='default'
                      bordered={true}
                      title="tạo đơn hàng"
                      paramsFilter={paramsFilter}
                      setParamsFilter={setParamsFilter}
                      valueTime={valueTime}
                      setValueTime={setValueTime}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={4}
                  >
                    <Select
                      size={FILTER_SIZE}
                      value={paramsFilter.bill_status || 'all'}
                      onChange={(value) => _onChangeFilter('bill_status', value)}
                      showSearch
                      defaultValue="all"
                      placeholder={t('order_list.filter_by_order_status')}
                      style={{ width: '100%' }}
                      bordered={true}
                      optionFilterProp="children"
                    >
                      <Select.Option value="all">Tất cả trạng thái</Select.Option>
                      <Select.Option value={100}>Chờ xác nhận</Select.Option>
                      <Select.Option value={101}>Chờ lấy hàng</Select.Option>
                      <Select.Option value={102}>Đang giao</Select.Option>
                      <Select.Option value={200}>Hoàn thành</Select.Option>
                      <Select.Option value={500}>Đã huỷ</Select.Option>
                      <Select.Option value={400}>Trả hàng</Select.Option>
                    </Select>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={4}
                  >
                    <Select
                      size={FILTER_SIZE}
                      value={paramsFilter.order_type || 'all'}
                      onChange={(value) => _onChangeFilter('order_type', value)}
                      showSearch
                      defaultValue="all"
                      placeholder={t('order_list.filter_by_order_status')}
                      style={{ width: '100%' }}
                      bordered={true}
                      optionFilterProp="children"
                    >
                      <Select.Option value="all">Tất cả loại đơn</Select.Option>
                      <Select.Option value="offline">Offline</Select.Option>
                      <Select.Option value="online">Online</Select.Option>
                    </Select>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={5}
                  >
                    <Select
                      size={FILTER_SIZE}
                      value={paramsFilter.payment_method || 'all'}
                      onChange={(value) => _onChangeFilter('payment_method', value)}
                      showSearch
                      defaultValue="all"
                      placeholder="Lọc theo phương thức thanh toán"
                      style={{ width: '100%' }}
                      bordered={true}
                    >
                      <Select.Option value="all">Tất cả phương thức thanh toán</Select.Option>
                      {payments.map((payment, index) => (
                        <Select.Option value={payment.payment_method_id} key={index}>
                          {payment.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={5}
                  >
                    <Select
                      value={paramsFilter.creator_id || 'all'}
                      onChange={(value) => _onChangeFilter('creator_id', value)}
                      showSearch
                      size={FILTER_SIZE}
                      placeholder={t('order_list.choose_an_employee')}
                      style={{ width: '100%' }}
                      bordered={true}
                      filterOption={(input, option) =>
                        removeUnicode(option.children.toString())
                          .toLowerCase()
                          .trim()
                          .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                      }
                    >
                      <Select.Option value="all">Tất cả nhân viên</Select.Option>
                      {employees.map((employee, index) => (
                        <Select.Option value={employee.user_id} key={index}>
                          {employee.first_name} {employee.last_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {loading ? null : 
          <>
          <div style={{ display: 'flex', marginTop: 8, marginBottom: 8, backgroundColor: 'white' }}>
            <div>
              Tổng đơn: <strong>{orderShow?.length}</strong>
            </div>
            <div style={{ marginLeft: 12 }}>
              Tổng đơn hoàn thành: <strong>{total_complete?.length}</strong>
            </div>
            <div style={{ marginLeft: 12 }}>
              Tổng đơn đang giao: <strong>{total_delivering?.length}</strong>
            </div>
            <div style={{ marginLeft: 12 }}>
              Tổng đơn đang chuẩn bị:{' '}
              <strong>{total_pickup?.length + total_confirm?.length}</strong>
            </div>
            <div style={{ marginLeft: 12 }}>
              Tổng đơn hủy: <strong>{total_cancel?.length}</strong>
            </div>
            <div style={{ marginLeft: 12 }}>
              Tổng đơn trả: <strong>{total_refund?.length}</strong>
            </div>
          </div>
          <Table
            size="small"
            rowKey="order_id"
            loading={loading}
            scroll={{ y: 500 }}
            columns={columnsOrderNew.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  align: 'center',
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  width: 192,
                  align: 'center',
                  sorter: (a, b) => compare(a, b, 'code'),
                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.chi_tiet_don_hang) ? (
                      <ConnectStatus record={record} location={0} />
                    ) : (
                      <div
                        style={{
                          color:
                            (record.order_status === 'waiting-confirm' && 'black') ||
                            (record.order_status === 'waiting-pickup' && 'black') ||
                            (record.order_status === 'delivering' && 'orange') ||
                            (record.order_status === 'complete' && 'green') ||
                            (record.order_status === 'cancel-order' && 'red') ||
                            (record.order_status === 'refund-order' && '#ff7089'),
                          cursor: 'pointer',
                        }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        {record.code}
                      </div>
                    ),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  align: 'center',
                  render: (text, record) => text && moment(text).format('DD/MM/YYYY HH:mm'),
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                }
              if (column.key === 'channel')
                return {
                  ...column,
                  align: 'center',
                  render: (text) => <span>{text}</span>,
                }
              if (column.key === 'total_quantity')
                return {
                  ...column,
                  align: 'center',
                  sorter: (a, b) => compare(a, b, 'total_quantity'),
                  render: (text, record) => <span>{record.total_quantity}</span>,
                }
              if (column.key === 'total_price_product')
                return {
                  ...column,
                  align: 'center',
                  sorter: (a, b) => compare(a, b, 'final_cost'),
                  render: (text, record) => {
                    let price = record.order_details.reduce((sum, e) => {
                      sum += (e.price * e.quantity)
                      return sum
                    }, 0)
                    return formatCash(price)
                  },
                  // record?.payer_shipping ? formatCash(record.final_cost) : formatCash(record.final_cost - record.shipping_info.fee_shipping)
                }
              if (column.key === 'total_discount_order')
                return {
                  ...column,
                  align: 'center',
                  sorter: (a, b) => compare(a, b, 'total_discount'),
                  render: (text, record) => <span>{formatCash(record.total_discount)}</span>,
                }
              if (column.key === 'customer')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) => (
                    <div>
                      <div>
                        {record?.customer_info?.first_name +
                          ' ' +
                          record?.customer_info?.last_name || ''}
                      </div>

                      {/* <a href={`tel:${record?.customer_info?.phone}`}>
                        {record?.customer_info?.phone}
                      </a> */}
                      {permissions.includes(PERMISSIONS.xem_chi_tiet_khach_hang) ? (
                          <Link
                            to={{ pathname: ROUTES.CUSTOMER_DETAIL + '/' + record?.customer_info?.code, state: record?.customer_info }}
                            target='_blank'
                          >
                            {record?.customer_info?.phone}
                          </Link>
                        ) : (
                          <div
                              style={{ color: '#5F73E2', cursor: 'pointer' }}
                              onClick={() => notification.warning({ message: 'Permission denied!' })}
                            >
                              {record?.customer_info?.phone}
                            </div>
                        )}
                    </div>
                  ),
                }
              if (column.key === 'employee')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) =>
                    record.employee
                      ? `${record.employee.first_name} ${record.employee.last_name}`
                      : '',
                }
              if (column.key === 'bill_status')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) => {
                    // eslint-disable-next-line default-case
                    switch (record.status) {
                      case 100:
                        return <Tag color="gold">Chờ xác nhận</Tag>
                      case 101:
                        return <Tag color="orange">Chờ lấy hàng</Tag>
                      case 102:
                        return <Tag color="blue">Đang giao</Tag>
                      case 200:
                        return <Tag color="green">Hoàn thành</Tag>
                      case 400:
                        return <Tag color="magenta">Đơn trả hàng</Tag>
                      default:
                        return <Tag color="red">Đơn huỷ</Tag>
                    }
                  },
                  sorter: (a, b) => compare(a, b, 'bill_status'),
                }
              if (column.key === 'payments_method')
                return {
                  ...column,
                  render: (text, record) =>
                    record.payments &&
                    record.payments.map((payment) => <p>{payment.name || ''}</p>),
                }
              if (column.key === 'customer_paid')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'customer_paid'),
                  render: (text, record) => formatCash(parseFloat(record?.final_cost)),
                }
              if (column.key === 'fee_shipping')
                return {
                  ...column,
                  render: (text, record) => (
                    <>
                      <span>{formatCash(record.shipping_info.fee_shipping)} </span>
                      <span>({record.payer_shipping ? 'Cửa hàng trả' : 'Khách trả'})</span>
                    </>
                  ),
                }
              if (column.key === 'total_profit')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'final_cost'),
                  render: (text, record) => record.payer_shipping ? 
                    formatCash(parseFloat(record?.final_cost) + record?.shipping_info?.fee_shipping) : formatCash(parseFloat(record?.final_cost))
                }
              if (column.key === 'note')
                return {
                  ...column,
                  render: (text, record) => record.note,
                }
              return column
            })}
            style={{ width: '100%' }}
            pagination={{
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
              },
              total: countOrder,
            }}
            dataSource={orderShow}
            summary={(pageData) => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {columnsOrderNew.map((e, index) => {
                    if (e.key === 'stt')
                      return (
                        <Table.Summary.Cell>
                          <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'total_quantity')
                      return (
                        <Table.Summary.Cell>
                          <div style={{ textAlign: 'center' }}>
                            {totalCostOrder?.total_quantity || 0}
                          </div>
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'total_price_product')
                      return (
                        <Table.Summary.Cell>
                          {formatCash(totalCostOrder?.total_price_product || 0)} VND
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'total_discount_order')
                      return (
                        <Table.Summary.Cell>
                          <div style={{ textAlign: 'center' }}>
                            {formatCash(totalCostOrder?.total_discount_order || 0)} VND
                          </div>
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'guest_must_pay')
                      return (
                        <Table.Summary.Cell>
                          {' '}
                          {formatCash(totalCostOrder?.total_customer_paid || 0)} VND
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'customer_paid')
                      return (
                        <Table.Summary.Cell>
                          {formatCash(totalCostOrder?.total_customer_paid || 0)} VND
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'fee_shipping')
                      return (
                        <Table.Summary.Cell>
                          {' '}
                          {formatCash(totalCostOrder?.total_fee_shipping || 0)} VND
                        </Table.Summary.Cell>
                      )
                    if (e.key === 'total_profit')
                      return (
                        <Table.Summary.Cell>
                          {' '}
                          {formatCash(totalCostOrder?.total_profit || 0)} VND
                        </Table.Summary.Cell>
                      )
                    return <Table.Summary.Cell></Table.Summary.Cell>
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
          </>}
          
        </>
      ) : null}
    </div>
  )
}
