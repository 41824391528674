export const Plus = (props) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.13281 5.44922H12.3633V8.50781H8.13281V13.2891H4.91016V8.50781H0.667969V5.44922H4.91016V0.867188H8.13281V5.44922Z"
      fill="currentColor"
    />
  </svg>
)

export const CarretDown = (props) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9222 0.620304C10.8379 0.534866 10.7375 0.4669 10.6269 0.420298C10.5162 0.373696 10.3975 0.349372 10.2774 0.348719C10.1574 0.348066 10.0384 0.371097 9.92722 0.416493C9.81608 0.461888 9.71498 0.528758 9.62972 0.613274L6.05376 4.15054L2.51649 0.574583C2.34603 0.402258 2.11409 0.304705 1.8717 0.303387C1.62932 0.302068 1.39633 0.397092 1.22401 0.567553C1.05168 0.738015 0.954129 0.969951 0.95281 1.21234C0.951492 1.45473 1.04651 1.68771 1.21698 1.86003L5.40153 6.09036C5.48587 6.1758 5.58623 6.24376 5.69687 6.29036C5.80751 6.33697 5.92626 6.36129 6.04631 6.36194C6.16636 6.3626 6.28537 6.33957 6.39651 6.29417C6.50765 6.24877 6.60874 6.1819 6.69401 6.09739L10.9243 1.91283C11.2746 1.5664 11.2777 0.979743 10.9222 0.620304Z"
      fill="currentColor"
    />
  </svg>
)

export const Bell = (props) => (
  <svg
    {...props}
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 21.2255L23.7262 20.9839C22.9493 20.2918 22.2694 19.498 21.7047 18.6242C21.088 17.4182 20.7183 16.1011 20.6175 14.7503V10.7718C20.6228 8.65016 19.8532 6.59958 18.4532 5.00535C17.0533 3.41112 15.1193 2.38298 13.0148 2.11409V1.07517C13.0148 0.790016 12.9015 0.516543 12.6999 0.314909C12.4982 0.113276 12.2247 0 11.9396 0C11.6544 0 11.381 0.113276 11.1793 0.314909C10.9777 0.516543 10.8644 0.790016 10.8644 1.07517V2.1302C8.77873 2.41847 6.86816 3.45282 5.4866 5.0417C4.10504 6.63057 3.34609 8.66629 3.35034 10.7718V14.7503C3.24947 16.1011 2.87982 17.4182 2.26309 18.6242C1.70809 19.4959 1.03908 20.2895 0.273826 20.9839L0 21.2255V23.4966H24V21.2255Z"
      fill="currentColor"
    />
    <path
      d="M9.8819 24.3422C9.95252 24.8527 10.2055 25.3204 10.5941 25.659C10.9827 25.9975 11.4806 26.184 11.996 26.184C12.5114 26.184 13.0093 25.9975 13.3979 25.659C13.7865 25.3204 14.0395 24.8527 14.1101 24.3422H9.8819Z"
      fill="currentColor"
    />
  </svg>
)
