import { RightOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Switch } from 'antd'
import React, { useState } from 'react'
import styles from './items.module.scss'
import TreeView from './tree/tree-view'
const RoleItem = ({
  _activeRole,
  _updateRole,
  _checkValueRole,
  _refreshValueRole,
  _deleteValueRole,
  data,
  roleCheck,
}) => {
  const [show, setShow] = useState(false)

  return (
    <div style={{ border: '1px solid silver' }}>
      <div
        onClick={(e) => {
          setShow(!show)
        }}
        className={styles['item-header']}
      >
        <div className={styles['item-header-1']}>
          <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
            <Switch
              style={{ marginRight: '8px' }}
              checked={data.active}
              onChange={(checked, event) => {
                event.stopPropagation()
                _activeRole({ active: checked }, data.role_id)
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>
              {show === false ? <RightOutlined /> : <DownOutlined />} {data.name}
            </span>
          </div>
        </div>
        <div className={styles['item-header-2']}>
          <div style={{ marginRight: '10px' }}>
            <Button
              onClick={async (e) => {
                e.stopPropagation()
                _refreshValueRole(data.role_id)
              }}
              type="default"
            >
              Tải lại
            </Button>
          </div>
          <div style={{ marginRight: '10px' }}>
            <Button
              onClick={async (e) => {
                e.stopPropagation()
                _updateRole(data.role_id)
              }}
              type="primary"
            >
              Cập nhật
            </Button>
          </div>
          <div>
            <Button
              onClick={async (e) => {
                e.stopPropagation()
                _deleteValueRole(data.role_id)
              }}
              type="danger"
            >
              Xoá
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          maxHeight: show ? '500px' : '0px',
          transition: 'all 1s',
          overflowY: 'scroll',
        }}
      >
        {show === true && (
          <div className={styles['item-tree']}>
            <TreeView
              _checkValueRole={_checkValueRole}
              show={show}
              data={data}
              roleCheck={roleCheck}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default RoleItem
