import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { notification, Spin } from 'antd'
import ScheduleWeek from './schedule-week'
import {
  getShift,
  getOneSchedule,
  getStaffShiftWithAdmin,
  addStaffToShiftAllDay,
  getAllDataRole,
  deleteShiftAllDay,
  getShiftForRegisterAdmin,
} from 'apis/schedule-staff'
import { getStaff } from 'apis/manage-staff'
import { getRoles } from 'apis/role'
import { PERMISSIONS, ROUTES } from 'consts'

export default function ScheduleDetail() {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { id } = useParams()
  const [roles, setRoles] = useState([])
  const [schedule, setSchedule] = useState({})
  const [shift, setShift] = useState([])
  const [staffShift, setStaffShift] = useState([])
  const [employee, setEmployee] = useState([])
  const [loading, setLoading] = useState(false)
  const [timeBroken, setTimeBroken] = useState([])
  const [shiftForRegister, setShiftForRegister] = useState([])
  const [staffWithShift, setStaffWithShift] = useState([])
  const [shiftWithStaff, setShiftWithStaff] = useState([])
  const history = useHistory()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const _getSchedule = async (id) => {
    try {
      setLoading(true)
      const res = await getOneSchedule(id, {branch_id: branchIdApp})
      if (res.status === 200){
        if(res.data.data){
          setSchedule(res.data.data)
        }else{
          history.push(ROUTES.SCHEDULE_STAFF)
        }
        }
        setLoading(false)
      if(res.data.data.branch_id !== branchIdApp) history.push(ROUTES.SCHEDULE_STAFF)
    } catch (error) {
      console.log(error)
    }
  }

  const _getShift = async () => {
    try {
      const res = await getShift({ branch_id: branchIdApp })
      if (res.status === 200) {
        setShift(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _deleteShiftAllday = async () => {
    try {
      setLoading(true)
      const res = await deleteShiftAllDay(id)
      if (res.status === 200) {
        _getSchedule(id)
        notification.success({ message: 'Xóa thành công' })
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const _getRoles = async () => {
    try {
      const res = await getRoles({ active: true, branch_id: branchIdApp })

      if (res.status === 200) {
        setRoles([...res.data.data])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getStaffShiftWithAdmin = async () => {
    try {
      const res = await getStaffShiftWithAdmin(id, { branch_id: branchIdApp })
      if (res.status === 200) {
        setStaffShift(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getShiftForRegister = async (id) => {
    try {
      const res = await getShiftForRegisterAdmin(id, { branch_id: branchIdApp })
      if (res.status === 200) {
        setShiftForRegister(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getEmployee = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp, is_delete: false })
      if (res.status === 200) {
        setEmployee(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getAllDataRole = async (id) => {
    try {
      const query = {
        id: id,
        branch_id: branchIdApp,
      }
      const res = await getAllDataRole({ ...query })
      if (res.status === 200) {
        setStaffWithShift(res.data.data.staffWithShift)
        setShiftWithStaff(res.data.data.shiftWithStaff)
        setTimeBroken(res.data.data.timeBroken)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // tính ngày tham gia bussiness
  const _getArrayStaffRegister = () => {
      // lấy số ca trung bình theo từng role
    let sumShiftWorking = []
    let sumDay
    let sumShift
    let amountNeedPeople
    let sumStaff
    let averageShift
    for (let a = 0; a < roles.length; a++) {
      sumDay = 0
      sumShift = 0
      amountNeedPeople = 0
      sumStaff = 0
      averageShift = 0
      for (const e of schedule.all_day) {
        //vòng lặp các ngày trong lịch
        let CheckDay
        for (let i = 0; i < e.shifts.length; i++) {
          //vòng lặp số ca trong 1 ngày
          for (let j = 0; j < e.shifts[i].role.length; j++) {
            //vòng lặp các role trong 1 ca
            if (e.shifts[i].role[j].role_id == roles[a].role_id) {
              // nếu ca có role hiện tại đang lặp
              if (CheckDay == e.day) {
                // nếu cờ check = ngày đang lặp
                sumShift = sumShift + 1 // tăng tổng ca lên 1
                amountNeedPeople = amountNeedPeople + e.shifts[i].role[j].total // tăng tổng số người cần lên
              } else {
                CheckDay = e.day // set check = ngày đang lặp
                sumDay = sumDay + 1 // tăng tổng ngày lên 1
                sumShift = sumShift + 1 // tăng tổng ca lên 1
                amountNeedPeople = amountNeedPeople + e.shifts[i].role[j].total // tăng tổng số người cần lên
              }
            }
          }
        }
      }
      // employee.forEach(item => (item.role_id == roles[a].role_id) && (sumStaff += 1))
      // eslint-disable-next-line no-loop-func
      staffWithShift.forEach(item => (item.role_id == roles[a].role_id) && item.staffs.reduce((arr, current) => {
        if(current.shiftStaffRegistered.length > 0) {
          sumStaff += 1
          arr.push(current)
        }
        return arr
      },[]) )

      //ca trung bình = tổng số yêu cầu / tổng số nhân viên
      averageShift = amountNeedPeople / sumStaff
      let c = {
        role_id: roles[a].role_id, //bộ phận
        sumDay: sumDay, // tổng ngày
        sumShift: sumShift, // tổng ca
        amountNeedPeople: amountNeedPeople, // số người cần
        sumStaff: sumStaff, //tổng số nhân viên
        averageShift: Number(averageShift.toFixed()) || 0,
      }
      sumShiftWorking.push(c) // mảng ca trung bình của từng role
    }

    //lấy số ngày tham gia business của user
    let dayWorking = []


    employee.forEach(item => {
      let ms = Math.ceil((new Date().getTime() - new Date(moment(item.create_date).format('YYYY-MM-DD')).getTime()) / (24 * 60 * 60 * 1000))
      let empDay = { user_id: item.user_id, day_working: ms, role_id: item.role_id }
      dayWorking.push(empDay)
    });
    // xếp lịch làm số đăng ký nhỏ hơn số yêu cầu
    let arrayStaffChoose = []
    let arrayMax = []

    for (let dataRoleId of shiftWithStaff) {
      for (let y = 0; y < staffWithShift?.length; y++) {
        if (dataRoleId.role_id == staffWithShift[y].role_id) {
          //check role cua 2 bang Staff va Shift
          for (let dataShiftId of dataRoleId.shifts) {
            //vòng lặp lấy ra các shifts trong role
            for (let dataDay of schedule.all_day) {
              // vòng lặp các ngày trong lịch
              let shiftAmount = 0

              // lấy thông tin của ca trong vòng lặp
              let indexShift = shift.findIndex((item) => item.shift_id == dataShiftId.shift_id)
              // lấy role của ca yêu cầu
              let dataShift = shift[indexShift].role.findIndex(
                (item) => item.role_id == dataRoleId.role_id
              )
              let amountRegister = 0
              dataShiftId.shiftStaffRegistered.forEach((item) => (item.day == dataDay.day) && (amountRegister += 1))
              for (let dataStaffId of staffWithShift[y].staffs) {
                // vòng lặp nhân viên đăng ký
                for (let dataStaffShift of dataShiftId.shiftStaffRegistered) {
                  //vòng lặp số ca đăng ký của 1 nhân viên
                  if (dataStaffShift.day == dataDay.day && dataStaffShift.staff_id == dataStaffId.staff_id) {
                    if (amountRegister <= shift[indexShift].role[dataShift].total) {
                      // nếu số nhân viên đăng ký < số nhân viên ca đó yêu cầu
                      if (shiftAmount < shift[indexShift].role[dataShift].total) {
                        // nếu số người được xếp vào làm việc < số nhân viên yêu cầu
                        shiftAmount += 1
                        let final = {
                          role_id: dataRoleId.role_id,
                          checkAmount: shiftAmount,
                          staffShift: dataStaffShift,
                        }
                        let index_final = arrayStaffChoose.findIndex(e => e.staffShift.staff_id === final.staffShift.staff_id && e.staffShift.shift_for_register_id === final.staffShift.shift_for_register_id)
                        if(index_final === -1) arrayStaffChoose.push(final) //add nhân viên đăng ký vào mảng được chọn để xếp lịch
                      }
                    } else {
                      // nhân viên đăng ký > số lượng yêu cầu
                      let fail = {
                        role_id: dataRoleId.role_id,
                        shift_id: Number(dataShiftId.shift_id),
                        day: dataDay.day,
                        staff_id: dataStaffId.staff_id,
                        staffShift: dataStaffShift,
                      }
                      arrayMax.push(fail) // add nhân viên đăng ký vào mảng chưa được xếp lịch
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    let arrMaxNew = JSON.parse(JSON.stringify(arrayMax))
    // Xếp ca cho nhân viên đăng ký > số lượng yêu cầu (kiểm tra tham số ngày tham gia, ca trung bình để xếp)
    for (const _role of roles) {
      // ca trung bình
      let shiftFine = 0
      // ngày đã tham gia
      let dayWithBusiness = 0

      for (
        let i = 0;
        i < arrayMax.length;
        i++ // vòng lặp ca đăng ký trong mảng đủ giờ
      ) {
        if (arrayMax[i].role_id == _role.role_id) {
          // nếu role đăng ký = role hiện tại trong vòng lặp

          
          //lấy số ngày đã tham gia cửa hàng của nhân viên thứ i
          let dayInBusinessI = dayWorking.findIndex(
            (index) => index.user_id == arrayMax[i].staffShift.staff_id
          )
          dayWithBusiness = dayWorking[dayInBusinessI].day_working

          // lấy ca trung bình của nhân viên thứ i
          let caTB = sumShiftWorking.findIndex((index) => index.role_id == arrayMax[i].role_id)
          shiftFine = sumShiftWorking[caTB].averageShift

          // lấy số ca đã được chọn của nhân viên thứ i
          let caChosse = arrayStaffChoose.filter(
            (item) => item.staffShift.staff_id == arrayMax[i].staffShift.staff_id
          )
          let receivedShift = caChosse.length
          //lấy số ca còn có thể nhận của nhân viên thứ i
          let index = shiftFine - receivedShift

          // chọn tạm nhân viên thứ i vào ca
          let staffChoose = arrayMax[i].staffShift
          let indexStaffChoose = i

          // tìm nhân viên tiếp theo
          for (let j = 1; j < arrayMax.length; j++) {
            if (j !== i) {
              //kiểm tra 2 nhân viên cùng đăng ký 1 ca
              if (
                arrayMax[i].role_id == arrayMax[j].role_id &&
                arrayMax[i].shift_id == arrayMax[j].shift_id &&
                arrayMax[i].day == arrayMax[j].day
              ) {
                //lấy số ngày đã tham gia cửa hàng của nhân viên thứ j
                let dayInBusiness = dayWorking.findIndex(
                  (index) => index.user_id == arrayMax[j].staffShift.staff_id
                )
                let dayWithBusinessNew = dayWorking[dayInBusiness].day_working

                // lấy ca trung bình của nhân viên thứ j
                let caTB_new = sumShiftWorking.findIndex(
                  (index) => index.role_id == arrayMax[j].role_id
                )
                let shiftFineNew = sumShiftWorking[caTB_new].averageShift

                // lấy số ca đã được chọn của nhân viên thứ j
                let caChosse_new = arrayStaffChoose.filter(
                  (item) => item.staffShift.staff_id == arrayMax[j].staffShift.staff_id
                )
                let receivedShiftNew = caChosse_new.length

                //lấy số ca còn có thể nhận của nhân viên thứ j
                let indexNew = shiftFineNew - receivedShiftNew
                // chọn tạm nhân viên thứ j vào ca để bắt đầu so sánh 2 nhân viên
                let staffChooseNew = arrayMax[j].staffShift

                //nếu số ca đã làm của nhân viên nào ít hơn thì thay đổi nhân viên được chọn thành nhân viên đó

                if(indexNew > 0){
                  if (indexNew > index) {
                    index = indexNew
                    receivedShift = receivedShiftNew
                    dayWithBusiness = dayWithBusinessNew
                    staffChoose = staffChooseNew
  
                    indexStaffChoose = j
                  } else if (indexNew == index) {
                    // nếu số ca làm bằng nhau thì kiểm tra ngày tham gia vào cửa hàng ai lớn hơn thì chọn nhân viên đó
                    if (dayWithBusinessNew > dayWithBusiness) {
                      index = indexNew
                      receivedShift = receivedShiftNew
                      dayWithBusiness = dayWithBusinessNew
                      staffChoose = staffChooseNew
  
                      indexStaffChoose = j
                    }
                  }
                }
                
              }
            }
            
          }
          let staffChooseAmount = arrayStaffChoose.filter((e) => e.role_id == _role.role_id && e.staffShift.shift_for_register_id == arrayMax[indexStaffChoose].staffShift.shift_for_register_id) // lấy số ca đăng ý đã được chọn trong role
          
          let shiftAmount = staffChooseAmount.length

          let indexShift = shift.findIndex(
            (item) => item.shift_id == arrayMax[indexStaffChoose].shift_id
          ) // lấy thông tin ca làm việc
          let dataShift = shift[indexShift].role.findIndex((item) => item.role_id == _role.role_id) // lấy thông tin role trong ca

          const averageShiftOne = sumShiftWorking.find((item) => item.role_id == _role.role_id) // lấy số ca trung bình
          let sumAverageShiftOne = averageShiftOne.averageShift
          //lấy số ca đã được làm việc của nhân viên
          let shiftChooseOne = arrayStaffChoose.filter(
            (item) => item.staffShift.staff_id == arrayMax[indexStaffChoose].staff_id
          )
          let arrayOne = shiftChooseOne.length
          //nếu số ca được chọn nhỏ hơn số ca trung bình
          if (arrayOne < sumAverageShiftOne) {
            // nếu số nhân viên đã được chọn nhỏ hơn số nhân viên yêu cầu
            if (shiftAmount < shift[indexShift].role[dataShift].total) {
              
              let index_in_choose = arrayStaffChoose.findIndex(e => e.staffShift.staff_id == staffChoose.staff_id && e.staffShift.shift_for_register_id == staffChoose.shift_for_register_id)
              if (index_in_choose === -1) {
              shiftAmount += 1 // tăng số nhân viên được chọn
                let final = {
                  role_id: _role.role_id,
                  checkAmount: shiftAmount,
                  staffShift: staffChoose,
                }
                  arrayStaffChoose.push(final)
                  let index_in_new = arrMaxNew.findIndex(e => e.staffShift.staff_id == staffChoose.staff_id && e.staffShift.shift_for_register_id == staffChoose.shift_for_register_id)
                  arrMaxNew.splice(index_in_new, 1)
                
              }
            }
          }
        }
      }
    }
    // xếp lịch còn trống nếu còn ca đăng ký
    for (const _role of roles) {
      let staffChooseRole = arrayStaffChoose.filter((item) => item.role_id === _role.role_id) // lấy số ca đăng ý đã được chọn trong role
      let staffMaxRole = arrMaxNew.filter((itemMax) => itemMax.role_id === _role.role_id) // lấy số ca đăng ký chưa được chọn trong ca
      // vòng lặp qua cá ngày
      for (let i = 0; i < schedule.all_day.length; i++) {
        // vòng lặp qua các ca trong ngày
        for (let j = 0; j < schedule.all_day[i].shifts.length; j++) {
          let shiftAmount = 0
          // lấy số ca đăng ký đã được chọn trong ca đang lặp
          let staffChooseAmount = staffChooseRole.filter(
            (item) =>
              item.staffShift.shift_for_register_id ==
              schedule.all_day[i].shifts[j].shift_for_register_id
          )
          shiftAmount = staffChooseAmount.length
          // kiểm tra ca hiện tại có role yêu cầu = role đang được lặp hay không
          let r = schedule.all_day[i].shifts[j].role.findIndex(
            (item) => item.role_id == _role.role_id
          )
          // nếu role tồn tại
          if (r !== -1) {
            // vòng lặp qua các ca đăng ký chưa được chọn thuộc role đang lặp
            for (const staffMax in staffMaxRole) {
              //nếu số lượng nhân viên nhỏ hơn số yêu cầu
              if (shiftAmount < schedule.all_day[i].shifts[j].role[r].total) {
                //nếu ca đăng ký thuộc ca đang lặp
                if (
                  staffMaxRole[staffMax].staffShift.shift_for_register_id ==
                  schedule.all_day[i].shifts[j].shift_for_register_id
                ) {
                  // lấy ca trung bình của nhân viên đang lặp
                  let caTB = sumShiftWorking.findIndex(
                    (index) => index.role_id == staffMaxRole[staffMax].role_id
                  )
                  let shiftFine = sumShiftWorking[caTB].averageShift
                  // lấy số ca đã được chọn của nhân viên đang lặp
                  let caChosse = arrayStaffChoose.filter(
                    (item) => item.staffShift.staff_id == staffMaxRole[staffMax].staffShift.staff_id
                  )
                  let receivedShift = caChosse.length

                  if (receivedShift < shiftFine) {
                    let indexOld = arrayStaffChoose.findIndex(
                      (e) =>
                        e.role_id == _role.role_id &&
                        e.staffShift.shift_for_register_id == staffMaxRole[staffMax].shift_for_register_id &&
                        e.staffShift.staff_id == staffMaxRole[staffMax].staff_id
                    )
                    if (indexOld === -1) {
                      // thêm ca đăng ký vào mảng được xếp lịch làm
                      shiftAmount += 1
                      let f = {
                        role_id: _role.role_id,
                        checkAmount: shiftAmount,
                        staffShift: staffMaxRole[staffMax].staffShift,
                      }
                      arrayStaffChoose.push(f)
                      // arrayMax.splice(indexStaffChoose, 1)
                    }
                    // staffMaxRole.splice(staffMax, 1)
                  }
                }
              } else break
            }
            for (const staffMax in staffMaxRole) {
              //nếu số lượng nhân viên nhỏ hơn số yêu cầu
              if (shiftAmount < schedule.all_day[i].shifts[j].role[r].total) {
                //nếu ca đăng ký thuộc ca đang lặp
                if (
                  staffMaxRole[staffMax].staffShift.shift_for_register_id ==
                  schedule.all_day[i].shifts[j].shift_for_register_id
                ) {
                  // lấy số ca đã được chọn của nhân viên đang lặp
                  let caChosse = arrayStaffChoose.filter(
                    (item) => item.staffShift.staff_id == staffMaxRole[staffMax].staffShift.staff_id
                  )
                  let receivedShift = caChosse.length

                  let staffMax_new = staffMaxRole.findIndex(
                    (e) =>
                      staffMaxRole[staffMax].role_id == e.role_id &&
                      staffMaxRole[staffMax].shift_id == e.shift_id &&
                      staffMaxRole[staffMax].day == e.day
                  )
                  //lấy số ca đã được chọn của nhân viên mới
                  let caChosseNew = arrayStaffChoose.filter(
                    (item) =>
                      item.staffShift.staff_id == staffMaxRole[staffMax_new].staffShift.staff_id
                  )
                  let receivedShift_new = caChosseNew.length

                  if (receivedShift_new < receivedShift) {
                    shiftAmount += 1
                    let final = {
                      role_id: _role.role_id,
                      checkAmount: shiftAmount,
                      staffShift: staffMaxRole[staffMax_new].staffShift,
                    }
                    let index_final = arrayStaffChoose.findIndex(e => e.staffShift.staff_id )
                    arrayStaffChoose.push(final) // thêm ca đăng ký vào mảng được xếp lịch làm
                    staffMaxRole.splice(staffMax_new, 1)
                  } else {
                    shiftAmount += 1
                    let final = {
                      role_id: _role.role_id,
                      checkAmount: shiftAmount,
                      staffShift: staffMaxRole[staffMax_new].staffShift,
                    }
                    arrayStaffChoose.push(final) // thêm ca đăng ký vào mảng được xếp lịch làm
                    staffMaxRole.splice(staffMax_new, 1)
                  }
                }
              } else break
            }
          }
        }
      }
    }
    //xếp lịch cho ca gãy
    if (timeBroken.length > 0) {
      for (const _role of roles) {
        let staffChooseRole = arrayStaffChoose.filter((item) => item.role_id == _role.role_id) // lấy số nhân viên được chọn trong role
        let staffMaxRole = timeBroken.filter((itemMax) => itemMax.role_id == _role.role_id) // lấy số đăng ký ca gãy theo role
        for (let i = 0; i < schedule.all_day.length; i++) {
          // vòng lặp số ngày trong lịch
          for (let j = 0; j < schedule.all_day[i].shifts.length; j++) {
            // vòng lặp số ca trong 1 ngày
            let shiftAmount = 0
            // lấy số người đã được chọn trong ca
            let staffChooseAmount = staffChooseRole.filter(
              (item) =>
                item.staffShift.shift_for_register_id ==
                schedule.all_day[i].shifts[j].shift_for_register_id
            )
            shiftAmount = staffChooseAmount.length
            //vị trí của ca có role trong vòng lặp
            let r = schedule.all_day[i].shifts[j].role.findIndex(
              (item) => item.role_id == _role.role_id
            )

            if (r !== -1) {
              // nếu role tồn tại trong ca
              let totalNew = schedule.all_day[i].shifts[j].role[r].total //số lượng nhân viên yêu cầu của ca
              // lọc ra mảng nhân viên đăng ký ca gãy của 1 ca
              let staffMaxNew = staffMaxRole.filter(
                (item) =>
                  item.staffShift?.shift_for_register_id ==
                  schedule.all_day[i]?.shifts[j]?.shift_for_register_id
              )
              for (let i = shiftAmount; i < totalNew; i++) {
                if (i < totalNew) {
                  let timeStartNew = Number(
                    moment(schedule.all_day[i]?.shifts[j]?.time_start).format('HH')
                  ) // thời gian bắt đầu của ca
                  do {
                    // tìm vị trí đăng ký có thời gian bắt đầu bằng thời gian bắt đầu của ca
                    let index = staffMaxNew.findIndex(
                      (e) =>
                        Number(moment(e?.staffShift?.time_register_start).format('HH')) ==
                        timeStartNew
                    )
                    let index_new
                    if (index_new != index) {
                      index_new = index
                      if (index == -1) {
                        // nếu không có vị trí nào
                        timeStartNew += 1 // tăng thời gian bắt đầu lên 1
                      } else {
                        // let indexOld = arrayStaffChoose.findIndex(
                        //   (e) =>
                        //     e.role_id == _role.role_id &&
                        //     e.shift_for_register_id == staffMaxNew[index].shift_for_register_id &&
                        //     e.staff_id == staffMaxNew[index].staff_id
                        // )
                        // if (indexOld == -1) {
                        let final = {
                          role_id: _role.role_id,
                          checkAmount: i,
                          staffShift: staffMaxNew[index].staffShift,
                        }
                        arrayStaffChoose.push(final) //add ca đăng ký vào mảng được chọn để xếp lịch
                        // }

                        timeStartNew = Number(
                          moment(staffMaxNew[index]?.staffShift?.time_register_end).format('HH')
                        ) // set thời gian bắt đầu bằng thời gian kết thúc của ca gãy vừa được chọn
                        staffMaxNew.splice(index, 1)
                      }
                    }
                  } while (
                    Number(moment(schedule.all_day[i]?.shifts[j]?.time_end).format('HH')) >
                    timeStartNew
                  ) // khi nào thời gian bắt đầu bằng thời gian kết thúc thì dừng
                }
              }
            }
          }
        }
      }
    }
    _addStaffToShiftAllDay(id, arrayStaffChoose)
   
  }

  const _addStaffToShiftAllDay = async (id, arrayStaffRegister) => {
    try {
      setLoading(true)
      for (let i = 0; i < arrayStaffRegister.length; i++) {
        let body = {
          checkAmount: arrayStaffRegister[i].checkAmount,
          staff_shift_id: arrayStaffRegister[i].staffShift.staff_shift_id
        }
        await addStaffToShiftAllDay(id, body)
      }
      notification.success({ message: 'Thêm nhân viên thành công' })
      _getSchedule(id)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getStaffShiftWithAdmin()
    _getShiftForRegister(id)
  }, [schedule])
  useEffect(() => {
    if(!permissions.includes(PERMISSIONS.xem_chi_tiet_lich_lam_viec)) history.push(ROUTES.SCHEDULE_STAFF)
    _getSchedule(id)
    _getRoles()
    _getShift()
    _getEmployee()
    _getAllDataRole(id)
  }, [branchIdApp])

  return (
    <>
      <Spin spinning={loading} tip="Loading..." size="large">
        <ScheduleWeek
          staffShifts={staffShift}
          employee={employee}
          shifts={shift}
          id={id}
          roles={roles}
          dateEnd={schedule.end_range}
          dateStart={schedule.start_range}
          _getArrayStaffRegister={_getArrayStaffRegister}
          _deleteShiftAllday={_deleteShiftAllday}
          staffWithShift={staffWithShift}
          _getSchedule={_getSchedule}
          schedule={schedule}
        />
      </Spin>
    </>
  )
}
