import React, { useEffect, useState, useRef } from 'react'
import { Link,useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

//antd
import {
  Modal,
  Row,
  Button,
  InputNumber,
  Space,
  Select,
  Col,
  Divider,
} from 'antd'
import { formatCash } from 'utils'

//apis
import { getPayments } from 'apis/payment'
import { ROUTES } from 'consts'

export const PaymentMethodModal = ({
  editInvoice,
  moneyToBePaidByCustomer,
  setVisible,
  visible,
  onCheckPaymentValid,
  isBranchClosed,
  paymentsOld,
  paymentRefundOld,
  indexInvoice,
  indexInvoiceOld,
  setIndexInvoiceOld,
}) => {
  const { t } = useTranslation()
  const toggle = () => {
    setVisible(!visible)
  }
  const location = useLocation()

  const Option = Select.Option
  const [paymentsMethod, setPaymentsMethod] = useState([])
  const [paymentsSelect, setPaymentsSelect] = useState([])
  const [paymentMethodDefault, setPaymentMethodDefault] = useState({}) //hình thức thanh toán mặc định

  const [paymentRefund, setPaymentRefund] = useState([])
  const [excessCash, setExcessCash] = useState(0)
  const ref = useRef()

  const changePaymentMethod = (value) => {
    let paymentsNew = [...paymentsMethod]
    let paymentSelectNew = [...paymentsSelect]

    let paymentRefundNew = [...paymentRefund]
    paymentsNew = paymentsNew
      .filter((item) => value.includes(item.payment_method_id))
      .map((item) => {
        return {
          payment_method_id: item.payment_method_id,
          name: item.name,
          value:
            Object(paymentSelectNew.find((e) => e.payment_method_id == item.payment_method_id))
              .value || 0,
        }
      })
    if (value.length > 0) {
      if (value.length > paymentSelectNew.length) {
        let newValue = value.pop()
        let indexPaymentSelect = paymentsNew.findIndex(
          (item) => item.payment_method_id === newValue
        )
        let totalPaymentSelect = paymentsNew.reduce((total, item) => (total += item.value), 0)
        if (totalPaymentSelect < moneyToBePaidByCustomer) {
          paymentsNew[indexPaymentSelect].value = moneyToBePaidByCustomer - totalPaymentSelect
        } else {
          paymentsNew[indexPaymentSelect].value = 0
        }
      }
    }
    const excessCashNew =
      paymentsNew.reduce((total, current) => total + current.value, 0) - moneyToBePaidByCustomer
    if (excessCashNew > 0) {
      setExcessCash(excessCashNew)
      paymentRefundNew[0].value = excessCashNew
    } else {
      setExcessCash(0)
      if (paymentRefundNew && paymentRefundNew.length > 0) {
        paymentRefundNew[0].value = 0
      }
    }
    if (paymentsNew.length === 1) {
      setPaymentsSelect([
        {
          name: paymentsNew[0].name,
          value: moneyToBePaidByCustomer,
          payment_method_id: paymentsNew[0].payment_method_id,
        },
      ])
      editInvoice('payments', [
        {
          name: paymentsNew[0].name,
          value: moneyToBePaidByCustomer,
          payment_method_id: paymentsNew[0].payment_method_id,
        },
      ])
      setPaymentRefund([
        {
          name: paymentRefundNew[0].name,
          value: 0,
          payment_method_id: paymentRefundNew[0].payment_method_id,
        }
      ])
      editInvoice('paymentRefund', [
        {
          name: paymentsNew[0].name,
          value: 0,
          payment_method_id: paymentsNew[0].payment_method_id,
        },
      ])
      setExcessCash(0)

    } else {
      setPaymentRefund(paymentRefundNew)
      setPaymentsSelect(paymentsNew)
    }
  }
  const changeCostPayment = (value, payment_id) => {
    let paymentsNew = [...paymentsSelect]
    let paymentRefundNew = [...paymentRefund]

    let indexPayment = paymentsNew.findIndex((item) => item.payment_method_id == payment_id)

    paymentsNew[indexPayment][`value`] = value

    const excessCashNew =
      paymentsNew.reduce((total, current) => total + current.value, 0) - moneyToBePaidByCustomer

    if (excessCashNew > 0) {
      setExcessCash(excessCashNew)
      paymentRefundNew[0].value = excessCashNew
    } else {
      setExcessCash(0)
      if (paymentRefundNew?.length > 0) {
        paymentRefundNew[0].value = 0
      }
    }
    setPaymentRefund(paymentRefundNew)
    setPaymentsSelect(paymentsNew)
  }

  const _savePayments = () => {
    onCheckPaymentValid(paymentsSelect)
    editInvoice('payments', paymentsSelect.filter(e => e.value > 0))
    editInvoice('paymentRefund', paymentRefund)
    editInvoice(
      'moneyGivenByCustomer',
      paymentsSelect?.reduce((total, item) => (total += item.value || 0), 0)
    )
    toggle()
  }

  const _exit = () => {
    setPaymentsSelect(paymentsOld)
    setPaymentRefund(
      paymentRefundOld && paymentRefundOld.length > 0
        ? paymentRefundOld
        : [
            {
              name: paymentMethodDefault.name,
              value: 0,
              payment_method_id: paymentMethodDefault.payment_method_id,
            },
          ]
    )
    setExcessCash(
      (paymentRefundOld && paymentRefundOld.length > 0 && paymentRefundOld[0].value) || 0
    )
    editInvoice('payments', paymentsOld)
    editInvoice('paymentRefund', paymentRefundOld)
    editInvoice(
      'moneyGivenByCustomer',
      paymentsSelect?.reduce((total, item) => (total += item.value || 0), 0)
    )
    toggle()
  }

  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        let paymentMethodDefault = {}
        if (res.data.data.length == 0) return

        setPaymentsMethod(
          res.data.data
            .filter((e) => e.active)
            .map((e) => {
              if (e.default) paymentMethodDefault = e
              return e
            })
        )
        setPaymentRefund([
          {
            name: paymentMethodDefault.name,
            value: excessCash,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
        setPaymentMethodDefault({
          name: paymentMethodDefault.name,
          value: moneyToBePaidByCustomer,
          payment_method_id: paymentMethodDefault.payment_method_id,
        })
        setPaymentsSelect([
          {
            name: paymentMethodDefault.name,
            value: moneyToBePaidByCustomer,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
      }
      onCheckPaymentValid([
        {
          name: paymentMethodDefault.name,
          value: moneyToBePaidByCustomer,
          payment_method_id: paymentMethodDefault.payment_method_id,
        },
      ])
      editInvoice('payments', [
        {
          name: paymentMethodDefault.name,
          value: moneyToBePaidByCustomer,
          payment_method_id: paymentMethodDefault.payment_method_id,
        },
      ])
      editInvoice('paymentRefund', [
        {
          name: paymentMethodDefault.name,
          value: excessCash,
          payment_method_id: paymentMethodDefault.payment_method_id,
        },
      ])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getPayments()
  }, [])

  useEffect(() => {
    if(indexInvoice === indexInvoiceOld){
      if (moneyToBePaidByCustomer == 0) {
        setPaymentsSelect([
          {
            name: paymentMethodDefault.name,
            value: 0,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
        setPaymentRefund([
          {
            name: paymentMethodDefault.name,
            value: 0,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
        setExcessCash(0)
      } else if(!location.state){
        setPaymentsSelect([
          {
            name: paymentMethodDefault.name,
            value: moneyToBePaidByCustomer,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
        setPaymentRefund([
          {
            name: paymentMethodDefault.name,
            value: 0,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
        editInvoice('payments', [
          {
            name: paymentMethodDefault.name,
            value: moneyToBePaidByCustomer,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
        editInvoice('paymentRefund', [
          {
            name: paymentMethodDefault.name,
            value: 0,
            payment_method_id: paymentMethodDefault.payment_method_id,
          },
        ])
        setExcessCash(0)
      }else{
        setPaymentsSelect([...location.state.payments.reduce((arr, e) =>{
          let obj ={
            name: e.name,
            value: moneyToBePaidByCustomer,
            payment_method_id: e.payment_method_id,
        }
        arr.push(obj)
        return arr
        },[])])
        setPaymentRefund([...location.state.payment_refund.reduce((arr, e) =>{
          let obj ={
            name: e.name,
            value: 0,
            payment_method_id: e.payment_method_id,
        }
        arr.push(obj)
        return arr
        },[])])
        setExcessCash(location.state.excessCash)
      }
    }else{
    setPaymentsSelect(paymentsOld)
    editInvoice('payments', paymentsOld)
      editInvoice('paymentRefund', paymentRefundOld)
      setIndexInvoiceOld(indexInvoice)
    }
    
  }, [moneyToBePaidByCustomer])

  return (
    <>
      <p onClick={toggle} style={{ marginBottom: 0, color: '#1890ff', cursor: 'pointer' }}>
        {paymentsSelect.length > 0 &&
          paymentsSelect.map((payment_method) => {
            if (payment_method.value > 0)
              return (
                <div>{payment_method.name + ' (' + formatCash(payment_method.value) + ')'}</div>
              )
          })}
        {[...paymentsSelect].filter((item) => item.value > 0).length == 0 && (
          <div>{'Bấm chọn'}</div>
        )}
      </p>
      <Modal
        bodyStyle={{ paddingTop: 10 }}
        width={540}
        footer={
          <Row justify="end">
            <Button
              onClick={_exit}
              style={{
                width: 100,
                borderColor: '#3579FE',
                borderRadius: 5,
              }}
            >
              Đóng
            </Button>
            <Button
              type="primary"
              disabled={isBranchClosed}
              onClick={_savePayments}
              style={{
                width: 100,
                borderRadius: 5,
                cursor: `${isBranchClosed ? 'not-allowed' : 'pointer'}`,
              }}
            >
              Chọn
            </Button>
          </Row>
        }
        closable={false}
        title={t('sell.payment_methods')}
        visible={visible}
      >
        <span style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
          {t('sell.can_choose_multiple_payment_methods')}
        </span>
        <Space direction="vertical" size="middle" style={{ width: '100%', fontSize: 18 }}>
          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <span>{t('common.guest_must_pay')}</span>
            <span>{formatCash(moneyToBePaidByCustomer)}</span>
          </Row>

          <Row wrap={false} justify="space-between" align="middle">
            <Select
              value={paymentsSelect.map((item) => item.payment_method_id)}
              defaultValue={paymentMethodDefault.payment_method_id}
              placeholder={t('sell.choose_a_form_of_payment')}
              style={{ width: '100%' }}
              mode="multiple"
              allowClear
              showSearch
              ref={ref}
              onChange={(list) => {
                ref.current.blur()
                changePaymentMethod(list)
              }}
            >
              {paymentsMethod.map((paymentMethod, index) => (
                <Option
                  value={paymentMethod.payment_method_id}
                  key={paymentMethod.payment_method_id}
                >
                  {paymentMethod.name}
                </Option>
              ))}
            </Select>
          </Row>
          <div>
            {paymentsSelect?.map((data, index) => {
              return (
                <Row key={data.payment_method_id} style={{ marginBottom: 10 }}>
                  <Col span={17}>
                    {data.name} {data.payment_method_id == 2 && `(1 điểm = 1 VNĐ)`}
                  </Col>
                  <Col span={7}>
                    <InputNumber
                      value={data.value}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => {
                        changeCostPayment(value, data.payment_method_id)
                      }}
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>
              )
            })}
          </div>

          <Divider style={{ background: '#e6f4ff', margin: 0 }} />

          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <span>{t('sell.total_amount_paid_by_customer')}</span>
            <span>
              {formatCash(paymentsSelect?.reduce((total, item) => (total += item.value || 0), 0))}
            </span>
          </Row>

          <Row justify="space-between" style={{ fontWeight: 600 }}>
            <span>{t('common.money_refund')}</span>
            <span>{formatCash(excessCash)}</span>
          </Row>
          <Row wrap={false} justify="space-between" align="middle">
            <Select
              placeholder={t('sell.choose_a_form_of_payment')}
              style={{ width: '100%' }}
              defaultValue={
                paymentRefund ? paymentRefund.length === 1 && paymentRefund[0].payment_method_id : 1
              }
              value={!paymentRefund[0] ? 1 : paymentRefund[0].payment_method_id === 6 ? 1 : paymentRefund[0].payment_method_id}
              onChange={(list) => {
                setPaymentRefund([
                  {
                    name: String(
                      paymentsMethod
                        .filter((item) => item.payment_method_id === list)
                        .map((e, index) => e.name)
                    ),
                    value: excessCash,
                    payment_method_id: list,
                  },
                ])
              }}
            >
              {paymentsMethod
                .filter((item) => item.payment_method_id != 6)
                .map(
                  (paymentMethod, index) =>
                    paymentMethod.name !== 'Thanh toán bằng điểm' && (
                      <Select.Option
                        value={paymentMethod.payment_method_id}
                        key={paymentMethod.payment_method_id}
                      >
                        {paymentMethod.name}
                      </Select.Option>
                    )
                )}
            </Select>
          </Row>
          <Link to={ROUTES.PAYMENT} target="_blank" style={{ fontSize: 14 }}>
            {t('sell.set_default_payment_method_in_Configuration')}
          </Link>
        </Space>
      </Modal>
    </>
  )
}
