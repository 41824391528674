import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//antd
import { Drawer, Row, Button, Input, Checkbox, Space, Spin, Tooltip } from 'antd'

//icons
import { SearchOutlined, UnorderedListOutlined } from '@ant-design/icons'

//apis
import { getCategories } from 'apis/category'
import { useSelector } from 'react-redux'

export default function FilterProductsByCategory({ setParamsFilter, paramsFilter, _getProductsRelated, valueSearchData }) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const [listCategory, setListCategory] = useState([])
  const dataUser = useSelector((state) => state.login.dataUser)

  const _getCategories = async () => {
    try {
      setLoading(true)
      const res = await getCategories()
      if (res.status === 200) setCategories(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (visible)
      setListCategory(paramsFilter.category_id ? paramsFilter.category_id.split('---') : [])
  }, [visible])

  useEffect(() => {
    _getCategories()
  }, [])
  return (
    <>
      <Tooltip title={t('sell.filter_by_product_group')}>
        <UnorderedListOutlined style={{ cursor: 'pointer' }} onClick={toggle} />
      </Tooltip>
      <Drawer
        width={450}
        visible={visible}
        title={t('sell.filter_by_product_group')}
        onClose={toggle}
        placement="left"
        footer={
          <Row justify="end">
            <Button
              onClick={() => {
                // if (listCategory.length) paramsFilter.category_id = listCategory.join('---')
                // else delete paramsFilter.category_id
                // setParamsFilter({ ...paramsFilter })
                _getProductsRelated(valueSearchData,1, listCategory)
                toggle()
              }}
              type="primary"
              style={{
                backgroundColor: '#0877DE',
                borderColor: '#0877DE',
                borderRadius: 8,
              }}
            >
              {t('common.confirm')}
            </Button>
          </Row>
        }
      >
        <Row justify="end" style={{ marginBottom: 15 }}>
          <a onClick={() => setListCategory([])}>{t('common.clear_all')}</a>
        </Row>
        <Input
          allowClear
          value={valueSearch}
          prefix={<SearchOutlined />}
          placeholder={t('sell.product_group_search')}
          onChange={(e) => setValueSearch(e.target.value)}
        />

        {loading ? (
          <div
            style={{
              width: '100%',
              height: '60%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        ) : (
          <Space direction="vertical" style={{ marginTop: 20 }}>
            {categories.map((category) => (
              <Checkbox
                checked={
                  listCategory.filter((e) => e == category.category_id).length ? true : false
                }
                onChange={(e) => {
                  const checked = e.target.checked
                  const listCategoryNew = [...listCategory]

                  if (checked) listCategoryNew.push(category.category_id)
                  else {
                    const indexRemove = listCategoryNew.findIndex((c) => c == category.category_id)
                    listCategoryNew.splice(indexRemove, 1)
                  }
                  setListCategory([...listCategoryNew])
                }}
              >
                {valueSearch && category.name.toLowerCase().includes(valueSearch.toLowerCase()) ? (
                  <mark style={{ backgroundColor: 'yellow' }}>{category.name}</mark>
                ) : (
                  category.name
                )}
              </Checkbox>
            ))}
            
            <Checkbox
                checked={
                  listCategory.filter((e) => e == -1).length
                }
                onChange={(e) => {
                  const checked = e.target.checked
                  const listCategoryNew = [...listCategory]

                  if (checked) {
                    listCategoryNew.push(-1)
                  } else {
                    const indexRemove = listCategoryNew.findIndex((c) => c == -1)
                    listCategoryNew.splice(indexRemove, 1)
                  }

                  setListCategory([...listCategoryNew])
                }}
              >
                Không thuộc nhóm nào
              </Checkbox>
          </Space>
        )}
      </Drawer>
    </>
  )
}
