export const columnsProduct = [
    {
      title: 'ID',
      dataIndex: 'product_id',
      key: 'product_id',
      align: 'center',
    },
    {
      title: 'Tên sản phẩm',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'sku',
      align: 'center',
      key: 'sku',

    },
    {
      title: 'SL',
      key: 'quantity',
      align: 'center',
    },
    {
      title: 'Giá ban đầu',
      key: 'default_price',
      align: 'center',
    },
    {
      title: 'Giá thay đổi',
      key: 'price',
      align: 'center',
    },
    {
      key: 'action',
      align: 'center',
    },
  ]