import React, { useEffect, useState } from 'react'

import { Form, notification, Row, Col, Input, InputNumber, Button, Select } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PERMISSIONS, regexRemoveUnicode, regexRemoveUnicodeNoNumber, ROUTES } from 'consts'

import { addWarranty, updateWarranty } from 'apis/warranty'
import { useSelector } from 'react-redux'

export default function GuaranteeAdd() {
  let history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()
  const [typeOption, setTypeOption] = useState('month')
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)

  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [validName, setValidName] = useState('')
  const [validType, setValidType] = useState('')

  const openNotification = () => {
    notification.success({
      message: 'Thành công',
      description: location.state ? 'Cập nhật phiếu bảo hành.' : 'Tạo phiếu bảo hành.',
    })
  }
  const onFinish = async (values) => {
    try {
      const body = {
        ...values,
        description: values.description ? values.description.trim() : '',
        type: values.type.trim() || '',
        name: values.name.trim() || '',
        type_option: typeOption,
      }
      let res
      if (location.state) res = await updateWarranty(location.state.warranty_id, body)
      else res = await addWarranty(body)
      if (res.status == 200) {
        openNotification()
        history.push(ROUTES.GUARANTEE)
      } else {
        notification.error({
          message: 'Thất bại',
          description: res.data.message,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() =>{
    if(!permissions.includes(PERMISSIONS.xem_chi_tiet_bao_hanh)) history.push(ROUTES.GUARANTEE)
  },[branchIdApp])
  useEffect(() => {
    if (location.state) {
      form.setFieldsValue({ ...location.state, description: location.state.description || '' })
    }
  }, [])

  return (
    <div className="card">
      <Form onFinish={onFinish} form={form} layout="vertical" style={{backgroundColor:'white', padding: 10}}>
        <Row
          style={{
            display: 'flex',
            borderBottom: '1px solid rgb(231, 219, 219)',
            paddingBottom: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Col style={{ width: '100%' }} xs={24} sm={24} md={24} lg={5} xl={5}>
            <Link
              to={ROUTES.GUARANTEE}
              style={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div>
                <ArrowLeftOutlined
                  style={{
                    color: 'black',
                    fontSize: '1rem',
                    fontWeight: '600',
                  }}
                />
              </div>
              <div
                style={{
                  color: 'black',
                  fontWeight: '600',
                  fontSize: '1rem',
                  marginLeft: '0.5rem',
                }}
              >
                {location.state ? 'Cập nhật phiếu bảo hành' : 'Tạo phiếu bảo hành'}
              </div>
            </Link>
          </Col>
        </Row>
        <Row
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginTop: 15,
          }}
        >
          <Col span={11}>
            <Form.Item
              label="Tên bảo hành"
              name="name"
              rules={[
                validName !== ''
                  ? {
                      required: true,
                      message: 'Vui lòng nhập tên bảo hành không chứa ký tự đặc biệt!',
                      pattern: regexRemoveUnicode,
                    }
                  : {
                      required: true,
                      message: 'Vui lòng nhập tên bảo hành!',
                    },
              ]}
            >
              <Input
                size="large"
                onChange={(e) => setValidName(e.target.value)}
                placeholder="Nhập tên bảo hành"
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Row gutter={[15, 15]}>
              <Col span={18}>
                <Form.Item
                  label="Thời hạn bảo hành"
                  name="time"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập thời hạn bảo hành',
                      pattern: /^[0-9]*$/,
                    },
                  ]}
                >
                  <InputNumber
                    parser={(value) =>
                      `${value}`.replaceAll('-', '').replaceAll(',', '').replaceAll('.', '')
                    }
                    placeholder="Nhập thời hạn bảo hành"
                    style={{ width: '100%' }}
                    size="large"
                    className="br-15__input"
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Ngày/Tháng/Năm">
                  <Select onChange={(e) => setTypeOption(e)} defaultValue="month">
                    <Select.Option value="day">Ngày</Select.Option>
                    <Select.Option value="month">Tháng</Select.Option>
                    <Select.Option value="year">Năm</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Loại bảo hành"
              name="type"
              rules={[
                validType !== ''
                  ? {
                      required: true,
                      message: 'Vui lòng nhập loại bảo hành không chứa ký tự đặc biệt!',
                      pattern: regexRemoveUnicode,
                    }
                  : {
                      required: true,
                      message: 'Vui lòng nhập loại bảo hành!',
                    },
              ]}
            >
              <Input
                onChange={(e) => setValidType(e.target.value)}
                size="large"
                placeholder="Nhập loại bảo hành"
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Mô tả" name="description">
              <Input.TextArea rows={5} placeholder="Nhập mô tả" />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            width: '100%',
            marginBottom: 20,
            justifyContent: 'flex-end',
          }}
        >
          <Button size="large" type="primary" htmlType="submit" style={{ width: 120 }}>
            {location.state ? 'Cập nhật' : 'Tạo'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}
