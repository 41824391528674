import { SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { searchFunctionRight } from '../function-items/search-item'
import RightTable from '../table-items/right-table'

const RightSide = (props) => {
  const {productChange} = props

  const [productFilter, setProductFilter] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  useEffect(() => setProductFilter([...productChange]), [productChange])
  return (
    <div style={{maxWidth: '700px'}}>
      <h1>Danh sách sản phẩm cập nhật:</h1>
      <Input 
      value={valueSearch}
      onChange={(e) => {
        searchFunctionRight(e, productChange, setProductFilter, setValueSearch)
      }}
      allowClear
      placeholder="Tìm kiếm theo mã SKU/ Tên phiên bản" suffix={<SearchOutlined />} />
      <div style={{ marginTop: '10px', boxShadow: '1px 1px 3px 1px silver' }}>
        <RightTable {...props} productFilter={productFilter} valueSearch={valueSearch} setValueSearch={setValueSearch}/>
      </div>
    </div>
  )
}

export default RightSide
