import { getRowsExcel, startImportCustomer } from 'apis/import-customer'
import React, { useEffect, useState } from 'react'
import styles from '../import-customer.module.css'
import { useDispatch } from 'react-redux'

//antd
import moment from 'moment'
import { Button, Col, Radio, Row, Spin, Table } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ACTION } from 'consts'

export default function Step2({ filePath, submit, gotoStep, current, setDataError }) {
  const [excelRows, setExcelRows] = useState([])
  const [keepCustomerPoint, setKeepCustomerPoint] = useState(true)
  const [notifyToUserAfterFinished, setNotifyToUserAfterFinished] = useState(true)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const sharedOnCell = (_) => {
    if (_.error === '{ERROR}') {
      return {
        colSpan: 0,
      }
    }

    return {}
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '5%',
      colSpan: 1,
      align: 'center',
      render: (text, record) => (record.error === '{ERROR}' ? '' : text),
    },
    {
      title: 'Họ tên (*)',
      dataIndex: 'full_name',
      width: '10%',
      onCell: (_) => ({
        colSpan: _.error === '{ERROR}' ? 10 : 1,
      }),
      render: (text, record) =>
        record.error === '{ERROR}' ? (
          <span style={{ color: '#ff9800' }}>{record.error_msg}</span>
        ) : (
          text
        ),
    },
    {
      title: 'Số điện thoại (*)',
      dataIndex: 'phone',
      onCell: sharedOnCell,
      width: '10%',
    },
    {
      title: 'Nhóm',
      dataIndex: 'group',
      onCell: sharedOnCell,
      width: '10%',

    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      onCell: sharedOnCell,
      align: 'center',
      width: '8%',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'date_of_birth',
      onCell: sharedOnCell,
      width: '10%',
      render: (text, record) =>
        record.date_of_birth ? moment(record.date_of_birth).format('DD/MM/YYYY') : '',
    },
    {
      title: 'Địa chỉ (*)',
      dataIndex: 'address',
      onCell: sharedOnCell,
    },
    // {
    //   title: 'Phường/Xã',
    //   dataIndex: 'ward',
    //   onCell: sharedOnCell,
    // },
    // {
    //   title: 'Quận/Huyện',
    //   dataIndex: 'district',
    //   onCell: sharedOnCell,
    // },
    // {
    //   title: 'Tỉnh/TP',
    //   dataIndex: 'province',
    //   onCell: sharedOnCell,
    // },
    {
      title: 'Điểm tích lũy',
      dataIndex: 'point',
      onCell: sharedOnCell,
      align: 'right',
      width: '8%',

    },
    {
      title: 'Điểm sử dụng',
      dataIndex: 'used_point',
      onCell: sharedOnCell,
      align: 'right',
      width: '8%',
    },
    {
      title: 'Đơn hàng',
      dataIndex: 'order_count',
      onCell: sharedOnCell,
      align: 'right',
      width: '8%',
    },
    {
      title: 'Tổng chi tiêu',
      dataIndex: 'total_pay',
      onCell: sharedOnCell,
      align: 'right',
      width: '8%',
    },
    {
      title: 'Công nợ',
      dataIndex: 'debt',
      onCell: sharedOnCell,
      align: 'right',
      width: '8%',
    },
  ]

  async function _getRowsExcel(filePath) {
      dispatch({ type: ACTION.LOADING, data: true })
      setTimeout(async () => {
      setLoading(true)
      let res = await getRowsExcel(filePath)
      if (res.status == 200) {
        setExcelRows(res.data.data)
      }
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
    }, 5000)
  }

  async function submitProcessImportCustomer() {
    const data = { filePath, keepCustomerPoint, notifyToUserAfterFinished }
    const res = await startImportCustomer(data)
    if (res.status === 200) {
      gotoStep(2)
      setDataError(res.data.data)
    }
  }

  useEffect(() => {
    _getRowsExcel(filePath)
  }, [filePath])

  return (
    <div className={styles['step-2']}>
      <div className={styles['step-2-header']}>
        <div className={styles['step-container']}>
          {/* <Row>
            <Col span={24} className={styles['header-item']}>
              <span style={{ marginRight: 10, fontWeight: 'bold' }}>Điểm khách hàng:</span>
              <Radio checked={keepCustomerPoint} onClick={() => setKeepCustomerPoint(true)}>
                Sử dụng điểm đã có trong cửa hàng
              </Radio>
              <Radio
                checked={keepCustomerPoint === false}
                onClick={() => setKeepCustomerPoint(false)}
              >
                Sử dụng điểm hiện tại trong file excel
              </Radio>
            </Col>
            <Col span={24} className={styles['header-item']}>
              <span style={{ marginRight: 10, fontWeight: 'bold' }}>Thông báo đến khách hàng:</span>
              <Radio
                checked={notifyToUserAfterFinished}
                onClick={() => setNotifyToUserAfterFinished(true)}
              >
                Thông báo đến khách hàng sau khi hoàn thành import
              </Radio>
              <Radio
                checked={notifyToUserAfterFinished === false}
                onClick={() => setNotifyToUserAfterFinished(false)}
              >
                Không thông báo
              </Radio>
            </Col>
          </Row> */}
          <Row justify="center" className={styles['group-button']}>
            <Col style={{ marginRight: 10 }}>
              <Button type="default" onClick={() => submit({ step: 0 })}>
                Quay lại
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={submitProcessImportCustomer}>
                Thực hiện Import
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        {loading === true ? (
          <></>
        ) : (
          <>
            <p style={{ color: 'red' }}>
              Dưới đây là dữ liệu của 100 dòng đầu tiên trong file excel bạn vừa chọn. Vui lòng kiểm
              tra và xác nhận.
            </p>
            <Table
              rowKey="phone"
              scroll={{ x: 400, y: 450 }}
              columns={columns}
              dataSource={excelRows}
              size="large"
              pagination={false}
            />
          </>
        )}
      </div>
    </div>
  )
}
