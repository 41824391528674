import { notification } from 'antd'
import { createRole, deleteRole, updateRole } from 'apis/role'
import { ACTION } from 'consts'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRolesPermission } from '../../redux/actions/role'
import Header from './layout-items/header'
import ListRole from './layout-items/list-role'
import AddRoleModal from './items/add-role-modal'

const RolePermission = () => {
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const roles = useSelector((state) => state.role)

  const [visibleAddRole, setVisibleAddRole] = useState(false)
  const [roleCheck, setRoleCheck] = useState()
  const handleModalAddRole = () => setVisibleAddRole(!visibleAddRole)
  const [newRole, setNewRole] = useState([])

  const _checkValueRole = (check, role_id, value, child, checked) => {
    if (check == true) {
      let role_data = [...roleCheck]
      let index = role_data.findIndex((item) => item.role_id === role_id)
      if (index !== -1) {
        if (child.pChildren) {
          const old_list = [...role_data[index].permission_list_old]
          role_data[index].permission_list_old = old_list

          let index_permission = role_data[index].permission_list_new.findIndex(
            (item) => item == value
          )

          let new_check = []
          new_check.push(value)
          for (let data of child.pChildren) {
            new_check.push(data.pParent)
            if (data.pChildren) {
              let new_arr = data.pChildren.map((item) => item.pParent)
              new_check = new_check.concat(new_arr)
            }
          }

          if (index_permission !== -1) {
            role_data[index].permission_list_new = role_data[index].permission_list_new.filter(
              (item) => !new_check.includes(item)
            )
          } else {
            role_data[index].permission_list_new =
              role_data[index].permission_list_new.concat(new_check)
            if (child.father) {
              role_data[index].permission_list_new.push(child.father)
            }
          }
        } else {
          const old_list = [...role_data[index].permission_list_old]
          role_data[index].permission_list_old = old_list

          let index_permission = role_data[index].permission_list_new.findIndex(
            (item) => item == value
          )

          if (index_permission !== -1) {
            role_data[index].permission_list_new.splice(index_permission, 1)
          } else {
            role_data[index].permission_list_new.push(value)
            if (child.father) {
              role_data[index].permission_list_new.push(child.father)
              if(child.grand) {
                role_data[index].permission_list_new.push(child.grand)
              }
            }
          }
        }
        role_data[index].permission_list_new = [...new Set(role_data[index].permission_list_new)]

        if (checked === true) {
          if (child.pChildren) {
            if (child.father) {
              let new_arr_check = child.pChildren.map((item) => item.pParent)
              let new_arr_check_father = new_arr_check.filter((item) => item !== child.pParent)
              let new_arr_check_child = new_arr_check_father.filter((item) =>
                role_data[index].permission_list_new.includes(item)
              )
              if (
                new_arr_check_child.length === 0 &&
                !role_data[index].permission_list_new.find((item) => child.brother.includes(item))
              ) {
                let index_grand = role_data[index].permission_list_new.findIndex(
                  (item) => item === child.father
                )
                role_data[index].permission_list_new.splice(index_grand, 1)
              }
            }
          } else {
            if (child.father) {
              if (!child.grand) {
                if (
                  !role_data[index].permission_list_new.find((item) => child.brother?.includes(item))
                ) {
                  let index_father = role_data[index].permission_list_new.findIndex(
                    (item) => item === child.father
                  )
                  role_data[index].permission_list_new.splice(index_father, 1)
                }
              } else {

                if (
                  !role_data[index].permission_list_new.find((item) => child.brother?.includes(item))
                ) {
                  let index_father = role_data[index].permission_list_new.findIndex(
                    (item) => item === child.father
                  )
                  role_data[index].permission_list_new.splice(index_father, 1)

                  if (child.uncle) {
                    if (
                      !role_data[index].permission_list_new.find((item) =>
                        child.uncle.includes(item)
                      )
                    ) {
                      let index_grand = role_data[index].permission_list_new.findIndex(
                        (item) => item === child.grand
                      )
                      role_data[index].permission_list_new.splice(index_grand, 1)
                    }
                  }
                }
              }
            }
          }
        }
      }
      setRoleCheck(role_data)
    }
  }

  const _addRole = async (body) => {
    try {
      let new_body = {
        arr: body,
        branch_id: branchIdApp,
      }
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await createRole(new_body)
      if (res.status === 200) {
        notification.success({ message: 'Thêm vai trò thành công' })
        dispatch(getRolesPermission({ branch_id: branchIdApp }))
        handleModalAddRole()
        setNewRole([])
      } else {
        notification.error({ message: res.data.message })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _refreshValueRole = (role_id) => {
    let role_data = [...roleCheck]

    let index = role_data.findIndex((item) => item.role_id === role_id)

    if (index !== -1) {
      const old_list = [...role_data[index].permission_list_old]
      role_data[index].permission_list_new = old_list
    }
    setRoleCheck(role_data)
  }

  const _updateRole = async (role_id) => {
    try {
      let role_data = [...roleCheck]
      let permission_check = Object(
        role_data.find((item) => item.role_id == role_id)
      ).permission_list_new
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateRole(
        {
          permission_list: permission_check,
        },
        role_id
      )
      if (res.status === 200) {
        notification.success({ message: 'Cập nhật thành công', duration: 1 })
        let index = role_data.findIndex((item) => item.role_id === role_id)
        if (index !== -1) {
          const new_list = [...role_data[index].permission_list_new]
          role_data[index].permission_list_old = new_list
        }
        setRoleCheck(role_data)
      } else {
        notification.error({ message: 'Cập nhật thất bại' })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _deleteValueRole = async (role_id) => {
    try {
      let role_data = [...roleCheck]
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteRole({role_id: role_id, branch_id: branchIdApp})
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        let new_role = role_data.filter((item) => item.role_id !== role_id)
        setRoleCheck(new_role)
        if (res.data.success) {
          notification.success({ message: 'Xóa vai trò thành công' })
        } else
          notification.error({
            message: res.data.message || 'Xóa vai trò không thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa vai trò không thất bại, vui lòng thử lại',
        })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }

  const _activeRole = async (body, role_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateRole(body, role_id)
      if (res.status === 200) {
        notification.success({ message: 'Cập nhật thành công' })
        dispatch(getRolesPermission({ branch_id: branchIdApp }))
      } else notification.error({ message: res.data.message || 'Cập nhật thành công' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    dispatch(getRolesPermission({ branch_id: branchIdApp }))
  }, [branchIdApp])

  useEffect(() => {
    if (roles) {
    let roles_data = [...roles]
      let new_data = roles_data.map((item) => ({
        ...item,
        role_id: item.role_id,
        permission_list_old: item.permission_list,
        permission_list_new: item.permission_list,
      }))
      setRoleCheck(new_data)
    }
  }, [roles,branchIdApp])

  return (
    <>
      <div className="card">
        <Header handleModalAddRole={handleModalAddRole} />
        {roleCheck && roleCheck.length > 0 && (
          <ListRole
            _activeRole={_activeRole}
            _updateRole={_updateRole}
            _checkValueRole={_checkValueRole}
            _refreshValueRole={_refreshValueRole}
            _deleteValueRole={_deleteValueRole}
            roles={roles}
            roleCheck={roleCheck}
          />
        )}
      </div>
      <AddRoleModal
        newRole={newRole}
        setNewRole={setNewRole}
        _addRole={_addRole}
        roleCheck={roleCheck}
        visibleAddRole={visibleAddRole}
        handleModalAddRole={handleModalAddRole}
      />
    </>
  )
}

export default RolePermission
