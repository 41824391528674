import { Row, Affix } from 'antd'
// import styles from './title-page.module.scss'

function TitlePage({ children, title = '', isAffix = false, top = 56 }) {
  if (isAffix)
    return (
      <Affix offsetTop={top}>
        <Row
          wrap={false}
          align="middle"
          justify="space-between"
          style={{
            borderBottom: '1px solid rgb(235, 223, 223)',
            paddingBottom: '1rem',
            padding: 15,
            backgroundColor: 'white',
            borderRadius: '5px',
          }}
        >
          <h3 style={{ fontSize: 19, marginBottom: 0 }}>{title}</h3>
          {children}
        </Row>
      </Affix>
    )

  return (
    <Row
      wrap={false}
      align="middle"
      justify="space-between"
      style={{
        borderBottom: '1px solid rgb(235, 223, 223)',
        paddingBottom: '1rem',
        paddingTop: 5,
        backgroundColor: 'white',
      }}
    >
      <h3 style={{ fontSize: 19, marginBottom: 0 }}>{title}</h3>
      {children}
    </Row>
  )
}

export default TitlePage
