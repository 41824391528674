import React from 'react'
import styles from '../point.module.scss'

import { Row, Col, Checkbox, Radio, InputNumber, Input } from 'antd'
export default function BasicSetting(props) {
  const { setConfig, config } = props
  return (
    <Row justify="space-between" className={styles['point-top']}>
      <Col md={6} lg={6} xl={6}>
        <h3>Thiết lập cơ bản</h3>
        <p>Những thiết lập chung nhất cho việc sử dụng tính năng trên phần mềm.</p>
      </Col>
      <Col md={16} lg={16} xl={16} className={styles['point-top-right']}>
        <Row className={styles['point-top-right-top']}>
          <Checkbox
            checked={config.active}
            onChange={(e) =>
              setConfig({
                ...config,
                active: e.target.checked,
              })
            }
          >
            <span style={{ fontWeight: 500 }}>Áp dụng tính năng tích điểm</span>
          </Checkbox>
        </Row>

        <Row className={styles['point-top-right-middle-1']}>
          <h4>CƠ CHẾ TÍCH ĐIỂM</h4>
          <Checkbox
            checked={config.accumulate_for_promotion_product}
            onChange={(e) =>
              setConfig({
                ...config,
                accumulate_for_promotion_product: e.target.checked,
              })
            }
          >
            Tích điểm cho đơn hàng giảm giá
          </Checkbox>
          <Checkbox
            checked={config.accumulate_for_refund_order}
            onChange={(e) =>
              setConfig({
                ...config,
                accumulate_for_refund_order: e.target.checked,
              })
            }
          >
            Trừ điểm khi khách hàng trả hàng
          </Checkbox>
          <Checkbox
            checked={config.accumulate_for_staff}
            onChange={(e) =>
              setConfig({
                ...config,
                accumulate_for_staff: e.target.checked,
              })
            }
          >
            Tích điểm cho nhân viên trong cửa hàng
          </Checkbox>
          <Checkbox
            checked={config.debt_orders}
            onChange={(e) =>
              setConfig({
                ...config,
                debt_orders: e.target.checked,
              })
            }
          >
            Tích điểm cho đơn hàng có công nợ
          </Checkbox>
        </Row>

        <Row className={styles['point-top-right-middle-2']}>
          <h4>HÌNH THỨC TÍCH ĐIỂM</h4>
          <Radio.Group
            onChange={(e) => setConfig({ ...config, stack_point: e.target.value })}
            value={config.stack_point}
          >
            <Radio value={false}>Tích điểm cố định</Radio>
            <Radio value={true}>Tích điểm lũy tiến - cộng dồn</Radio>
          </Radio.Group>
        </Row>

        <Row className={styles['point-top-right-bottom']}>
          <h4>TỶ LỆ QUY ĐỔI ĐIỂM</h4>
          <Row>
            <Radio.Group
              onChange={(e) => setConfig({ ...config, conversion_rate: e.target.value })}
              value={config.conversion_rate}
              style={{ margin: '10px 0 10px 0' }}
            >
              <Radio value={false}>Quy đổi theo phần trăm hóa đơn</Radio>
              <Radio value={true}>Quy đổi theo số tiền nhập</Radio>
            </Radio.Group>
          </Row>
          {config.conversion_rate ? (
            <Row>
              <Col span={10}>
                <InputNumber
                  defaultValue={1}
                  min={1}
                  value={config.exchange_point_rate}
                  onChange={(e) => setConfig({ ...config, exchange_point_rate: e })}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.', '')
                  }
                  // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />{' '}
                = 1 điểm
              </Col>
              <Col span={14} style={{ paddingLeft: 10 }}>
                Đơn vị tích điểm <Input defaultValue="Điểm" readOnly style={{ width: '10%' }} />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col span={10}>
                  <InputNumber
                    defaultValue={1}
                    min={1}
                    max={100}
                    value={config.percent}
                    onChange={(e) => setConfig({ ...config, percent: e })}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />{' '}
                  %
                </Col>
                <Col span={14} style={{ paddingLeft: 10 }}>
                  Đơn vị tích điểm <Input defaultValue="Điểm" readOnly style={{ width: '10%' }} />
                </Col>
              </Row>
              <Row>
                <span>Nhập số thập phân dưới dạng:</span>
                <span style={{ fontWeight: 'bold', marginLeft: 5 }}>5.8</span>
              </Row>
              <Row>
                <div>{`Ví dụ: Đơn hàng có hóa đơn 100.000đ :  ${(
                  (100000 * (config.percent || 1)) /
                  100
                ).toFixed()} điểm `}</div>
              </Row>
            </>
          )}
        </Row>
      </Col>
    </Row>
  )
}
