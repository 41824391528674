
import { getProductChange} from 'apis/product'

export const getProductWarehouse = ({branchIdApp}) => async (dispatch) => {
    try {
        if (branchIdApp) {
          dispatch({type: 'LOADING', data: true})
            const resWarehouse = await getProductChange({branch_id: branchIdApp, warehouse: true || '' })
            if (resWarehouse.status === 200) {
              dispatch({
                type: 'SET_PRODUCTS_WAREHOUSE',
                data: {
                  productWarehouse: resWarehouse.data.data,
                },
              })
              
            }
          dispatch({type: 'LOADING', data: false})
          }
    } catch (error) {
          dispatch({type: 'LOADING', data: false})
          console.log(error)
    }
  };
