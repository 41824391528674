import { get, patch, post, destroy } from './httpClient'

export const getOneSchedule = (id, query) => get(`/schedule/getOneSchedule/${id}`,query)
export const getSchedule = (query) => get('/schedule/getSchedule', query)
export const createSchedule = (body) => post('/schedule/createSchedule', body)
export const updateSchedule = (body, id) =>patch('/schedule/updateSchedule/' + id, body)
export const deleteSchedule = (id) =>destroy('/schedule/deleteSchedule', { schedule_id: id })


export const getShift = (query) => get('/shift/getShift', query)
export const createShift = (body) => post('/shift/createShift', body)
export const updateShift = (id, body) =>patch(`/shift/updateShift/${id}`, body)
export const deleteShift = (id) =>patch(`/shift/deleteShift/${id}`)

export const createShiftForRegister = (id, body) => post(`/shift/createShiftForRegister/${id}`, body)
export const deleteShiftForRegister = (body, id) => patch(`/shift/deleteShiftForRegister/${id}`, body)
export const getShiftForRegister = (id, day) => get(`/shift/getShiftForRegister/${id}`, day)
export const getShiftForRegisterAdmin = (id,query) => get(`/shift/getShiftForRegister/${id}`,query)

export const getStaffShiftWithAdmin = (id,query) => get(`/schedule-shift-staff/getStaffShiftWithAdmin/${id}`,query)
export const getStaffShift = (id,query) => get(`/schedule-shift-staff/getStaffShift/${id}`, query)
export const createStaffShift = (body) => post('/schedule-shift-staff/createStaffShift', body)
export const updateStaffShift = (body, id) =>patch('/schedule-shift-staff/updateStaffShift/' + id, body)

export const addStaffToShift = (id, body) =>patch(`/schedule-shift-staff/addStaffToShift/${id}`, body)
export const deleteStaffToShift = (id, body) =>patch(`/schedule-shift-staff/deleteStaffToShift/${id}`, body)

export const getAllDataRole = (query) => get(`/schedule-shift-staff/getAllDataRole/${Number(query.id)}`,query)
export const addStaffToShiftAllDay = (id, body) =>patch(`/schedule-shift-staff/addStaffToShiftAllDay/${id}`, body)
export const deleteShiftAllDay = (id) => patch(`/schedule-shift-staff/deleteStaffToShiftAllday/${id}`)

