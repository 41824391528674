// antd
import { ArrowLeftOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  Affix,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Upload,
} from 'antd'
// api
import { createBlog, updateBlog } from 'apis/blog'
import { uploadFiles } from 'apis/upload'
import { CKEditor } from 'ckeditor4-react'
import { ACTION, ROUTES } from 'consts'
// html react parser
import parse from 'html-react-parser'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { formatCash } from 'utils'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getConfigInvoiceDelivery, updateConfigInvoiceDelivery } from 'apis/app'
import styles from './config-invoice-delivery.module.scss'
var order_example = {
  _id: '62c00cc19da3af6f8e175cc7',
  order_id: 82,
  code: '000082',
  platform_id: 1,
  channel: 'POS',
  sale_location: {
    _id: '62b029a1c8543c2aa3eacdb2',
    branch_id: -1,
    code: '000001',
    name: 'Chi nhánh mặc định',
    logo: 'https://upsale.com.vn/app/logo.png',
    phone: '',
    email: '',
    fax: '',
    website: '',
    latitude: '',
    longitude: '',
    warehouse_type: 'Store',
    address: '',
    ward: '',
    district: '',
    province: '',
    accumulate_point: false,
    use_point: false,
    create_date: '2022-06-20T15:02:40+07:00',
    creator_id: -1,
    last_update: '2022-06-28T11:51:02+07:00',
    updater_id: -1,
    active: true,
    slug_name: 'chinhanhmacdinh',
    slug_warehouse_type: 'store',
    slug_address: '',
    slug_ward: '',
    slug_district: '',
    slug_province: '',
    GHN_shop_id: 0,
    business_id: null,
    slug_type: '',
    type: null,
  },
  customer_id: 2,
  employee_id: -1,
  order_details: [
    {
      product_id: 12,
      variant_id: 30,
      sku: 'TND',
      name: 'Thùng nhựa D',
      title: 'Thùng nhựa D',
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      price: 122222,
      base_prices: [
        {
          location_id: 73,
          branch_id: -1,
          product_id: 12,
          variant_id: 30,
          base_price: 0,
          quantity: 1,
        },
      ],
      quantity: 1,
      total_base_price: 0,
      total_cost: 122222,
      total_tax: 0,
      total_discount: 0,
      final_cost: 0,
      active: true,
      product_info: {
        _id: '62c00b7b034914652cc29fb5',
        product_id: 12,
        code: '000012',
        barcode: '',
        sku: 'TND',
        images: [],
        is_pre_order: false,
        name: 'Thùng nhựa D',
        slug: 'thung-nhua-d',
        price_recipe: 'FIFO',
        supplier_id: [],
        category_id: [],
        tax_id: [],
        warranties: [],
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        unit: '',
        brand_id: '',
        origin_code: '',
        status: '',
        description: '',
        tags: [],
        files: [],
        price: 0,
        sale_quantity: 0,
        create_date: '2022-07-02T16:10:19+07:00',
        creator_id: -1,
        last_update: '2022-07-02T16:10:19+07:00',
        updater_id: -1,
        active: true,
        slug_name: 'thung-nhua-d',
        slug_tags: [],
        variants: [
          {
            _id: '62c00b7b034914652cc29fb3',
            variant_id: 30,
            code: '000030',
            barcode: '',
            sku: 'TND',
            product_id: 12,
            title: 'Thùng nhựa D',
            image: [],
            options: [],
            supplier: '',
            import_price: 0,
            price: 122222,
            enable_bulk_price: false,
            bulk_prices: [
              {
                min_quantity: 1,
                max_quantity: 999,
                price: 122222,
              },
            ],
            create_date: '2022-07-02T16:10:19+07:00',
            creator_id: -1,
            last_update: '2022-07-02T16:10:19+07:00',
            updater_id: -1,
            active: true,
            slug_title: 'thung-nhua-d',
          },
        ],
        attributes: [],
      },
      variant_info: {
        _id: '62c00b7b034914652cc29fb3',
        variant_id: 30,
        code: '000030',
        barcode: '',
        sku: 'TND',
        product_id: 12,
        title: 'Thùng nhựa D',
        image: [],
        options: [],
        supplier: '',
        import_price: 0,
        price: 122222,
        enable_bulk_price: false,
        bulk_prices: [
          {
            min_quantity: 1,
            max_quantity: 999,
            price: 122222,
          },
        ],
        create_date: '2022-07-02T16:10:19+07:00',
        creator_id: -1,
        last_update: '2022-07-02T16:10:19+07:00',
        updater_id: -1,
        active: true,
        slug_title: 'thung-nhua-d',
      },
    },
  ],
  shipping_company_id: null,
  shipping_info: {
    tracking_number: '',
    to_name: '',
    to_phone: '',
    to_address: '',
    to_ward: '',
    to_district: '',
    to_province: '',
    to_province_code: '',
    to_postcode: '',
    to_country_code: '',
    return_name: '',
    return_phone: '',
    return_address: '',
    return_ward: '',
    return_district: '',
    return_province: '',
    return_province_code: '',
    return_postcode: '',
    return_country_code: '',
    fee_shipping: '',
    cod: '',
    delivery_time: '',
    complete_time: '',
  },
  voucher: '',
  promotion: null,
  total_quantity: 1,
  total_cost: 122222,
  total_tax: '0',
  total_discount: 0,
  final_cost: 122222,
  payments: [
    {
      method: 'Tiền mặt',
      value: 122222,
    },
  ],
  list_tax: [],
  payment_status: null,
  customer_paid: 122222,
  customer_debt: 0,
  bill_status: 'COMPLETE',
  ship_status: 'DRAFT',
  note: '',
  tags: [],
  create_day: '2022-07-02',
  create_month: '2022-07',
  create_year: '2022',
  create_date: '2022-07-02T16:15:45+07:00',
  creator_id: -1,
  verify_date: '',
  verifier_id: '',
  is_delivery: false,
  delivery_date: '',
  deliverer_id: '',
  complete_date: '',
  completer_id: '',
  cancel_date: '',
  canceler_id: '',
  is_refund: false,
  refund_date: '',
  refunder_id: '',
  refund_from_order_id: null,
  refund_to_order_id: null,
  product_handle: '',
  last_update: '2022-07-02T16:15:45+07:00',
  updater_id: -1,
  order_refund_id: null,
  total_cod: 0,
  total_base_price: 0,
  total_profit: 122222,
  trackings: [],
  customer_info: {
    _id: '62b3e2c351ae991ab223c8ea',
    customer_id: 2,
    code: '000002',
    phone: '0337711117',
    type_id: -1,
    email: 'admin@amin.com',
    first_name: 'd',
    last_name: 'uc',
    gender: 'male',
    birthday: 'Tue Jan 01 1991 07:00:00 GMT+0700 (Indochina Time)',
    address: 'q1',
    district: 'Huyện Xi Ma Cai',
    ward: {
      _id: '60ddb46085ac995c089ca91e',
      ward_code: '80212',
      ward_name: 'Xã Sín Chéng',
      district_id: 2264,
      district_name: 'Huyện Xi Ma Cai',
      province_id: 269,
      province_name: 'Lào Cai',
    },
    province: 'Lào Cai',
    district_id: 2264,
    ward_code: '80212',
    province_id: 269,
    balance: [],
    point: 2210,
    used_point: 1100,
    order_quantity: 18,
    order_total_cost: 97400000,
    create_date: '2022-06-23T10:49:23+07:00',
    creator_id: -1,
    last_update: '2022-06-23T10:49:23+07:00',
    updater_id: -1,
    active: true,
    slug_name: 'duc',
    slug_type: '',
    slug_gender: 'male',
    slug_address: 'q1',
    slug_district: 'huyenximacai',
    slug_province: 'laocai',
  },
  customer: {
    _id: '62b3e2c351ae991ab223c8ea',
    customer_id: 2,
    code: '000002',
    phone: '0337711117',
    type_id: -1,
    email: 'admin@amin.com',
    first_name: 'd',
    last_name: 'uc',
    gender: 'male',
    birthday: 'Tue Jan 01 1991 07:00:00 GMT+0700 (Indochina Time)',
    address: 'q1',
    district: 'Huyện Xi Ma Cai',
    ward: {
      _id: '60ddb46085ac995c089ca91e',
      ward_code: '80212',
      ward_name: 'Xã Sín Chéng',
      district_id: 2264,
      district_name: 'Huyện Xi Ma Cai',
      province_id: 269,
      province_name: 'Lào Cai',
    },
    province: 'Lào Cai',
    district_id: 2264,
    ward_code: '80212',
    province_id: 269,
    balance: [],
    point: 2222.2222,
    used_point: 1100,
    order_quantity: 19,
    order_total_cost: 97522222,
    create_date: '2022-06-23T10:49:23+07:00',
    creator_id: -1,
    last_update: '2022-06-23T10:49:23+07:00',
    updater_id: -1,
    active: true,
    slug_name: 'duc',
    slug_type: '',
    slug_gender: 'male',
    slug_address: 'q1',
    slug_district: 'huyenximacai',
    slug_province: 'laocai',
  },
  employee: {
    _id: '62b029a1c8543c2aa3eacdaa',
    user_id: -1,
    user_code: '000001',
    employee_id: -1,
    code: '000001',
    username: '0337711116',
    password: '$2b$10$efOOOD.lGHRwqLxU4j9LweDS8kzY7ILjyQyI0syckXPOFNetIDDjG',
    role_id: -1,
    email: 'duck@admin.com',
    avatar: 'https://upsale.com.vn/app/logo.png',
    first_name: '',
    last_name: 'testadd',
    name: 'testadd',
    birth_day: '2022-06-20T15:02:40+07:00',
    address: '',
    district: '',
    province: '',
    branch_id: 3,
    store_id: -1,
    otp_code: '822781',
    otp_timelife: '2022-06-20T15:07:40+07:00',
    last_login: '2022-07-02T17:47:37+07:00',
    create_date: '2022-06-20T15:02:40+07:00',
    creator_id: -1,
    last_update: '2022-06-20T15:02:40+07:00',
    updater_id: -1,
    active: true,
    slug_name: 'testadd',
    slug_address: '',
    slug_district: '',
    slug_province: '',
  },
}
export default function ConfigInvoiceSaleCreate() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const [content, setContent] = useState('')
  const [checked, setChecked] = useState(false)
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [contenEdit, setContentEdit] = useState(``)

  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked)
  }

  const onClosePreview = () => {
    setVisiblePreview(false)
  }

  const handleChangeContentEdit = (e) => {
    var value = e.editor.getData()
    var _value = value
    setContentEdit(value)
  }

  const convertTemplate = (_value) => {
    _value = String(_value).replace(
      /{dia_chi_shop}/gi,
      '22 đường số 9, Quang Trung, Gò Vấp, Hồ Chí Minh'
    )

    var content_product_details = ''
    for (var i = 0; i < order_example.order_details.length; i++) {
      content_product_details += `<tr><td style="height:15px;width:300">${
        order_example.order_details[i].title
      }</td>
      <td style="height:15px ;width:100px">${order_example.order_details[i].price}</td>
      <td style="height:15px;width:100px">${order_example.order_details[i].quantity}</td>
      <td style="height:15px;width:100px">${formatCash(
        order_example.order_details[i].price * order_example.order_details[i].quantity
      )}</td></tr>`
    }

    _value = String(_value).replace(/{sdt_shop}/gi, '0969025914')
    _value = String(_value).replace(/{nhan_vien_ban_hang}/gi, 'Huỳnh Trọng Mẫn')
    _value = String(_value).replace(/{ngay_ban}/gi, moment().format('yyyy/MM/DD'))
    _value = String(_value).replace(/{tieu_de_hoa_don}/gi, 'Hoá đơn bán hàng')
    _value = String(_value).replace(/{sdt_kh}/gi, '0969025911')
    _value = String(_value).replace(/{ten_kh}/gi, 'Văn Minh Hoàng')
    _value = String(_value).replace(/{ma_hoa_don}/gi, '#090909')
    _value = String(_value).replace(/{sdt_hoa_don}/gi, '0969025911')
    _value = String(_value).replace(/{ghi_chu}/gi, 'Kèm bảo hành 12 tháng')
    _value = String(_value).replace(/{ngay_ban_hoa_don}/gi, moment().format('HH:mm yyyy/MM/DD'))
    //_value = String(_value).replace(`<tr><td>&nbsp;</td></tr>`, content_product_details)

    return _value
  }

  const onLoadConfig = async () => {
    try {
      var response = await getConfigInvoiceDelivery()

      if (response.status == 200) {
        setContentEdit(response.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    onLoadConfig()
  }, [])

  const onSubmitInvoice = async () => {
    try {
      var response = await updateConfigInvoiceDelivery({ data: contenEdit, id: 2 })
      if (response.status == 200) {
        notification.success({
          message: 'Cài đặt mẫu hoá đơn thành công',
        })
      } else {
        notification.error({
          message: 'Cài đặt mẫu hoá đơn thất bại',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className={styles['body_blog']}>
      <div className={styles['body_blog_header']}>
        <div className={styles['body_blog_header_title']}>
          <ArrowLeftOutlined
            onClick={() => history.push(ROUTES.SETTING_BILL)}
            style={{
              fontSize: '20px',
              paddingRight: '10px',
              paddingLeft: '10px',
              paddingTop: '10px',
            }}
          />
          <span className={styles['body_blog_header_list_text']}>
            {'Cấu hình mẫu hoá đơn giao hàng'}
          </span>
        </div>
      </div>
      <div
        style={{ flexDirection: 'row', display: 'flex', width: '100%', justifyContent: 'flex-end' }}
      >
        <Button
          onClick={(e) => {
            setVisiblePreview(true)
          }}
          style={{ width: 100, marginRight: 10 }}
          type="primary"
        >
          {'Xem Trước'}
        </Button>
        <Button
          onClick={(e) => {
            onSubmitInvoice()
          }}
          style={{ width: 100 }}
          type="primary"
        >
          {'Lưu'}
        </Button>
      </div>
      <div className={styles['body_blog_content']}>
        <Form form={form} className={styles['body_blog_content_header']}>
          <div className={styles['item_form_invoice']}>
            {/* <h3>
              <span style={{ color: 'red' }}>*</span>Đường dẫn
            </h3>
            <Form.Item name="link" rules={[{ required: true, message: 'Vui lòng nhập đường dẫn' }]}>
              <Input style={{ width: '100%' }} placeholder="Nhập đường dẫn"></Input>
            </Form.Item> */}
            <h3>Điều chỉnh</h3>
            <CKEditor
              config={{ height: 500 }}
              initData={parse(contenEdit)}
              onChange={handleChangeContentEdit}
            />
          </div>

          {/* <div className={styles['item_form_invoice']}>
            <h3>Xem trước</h3>
            <CKEditor initData={contentPreview} onChange={handleChangeContentPreview} />
          </div> */}
        </Form>
      </div>

      <Modal
        style={{ top: 20 }}
        onCancel={onClosePreview}
        width={800}
        footer={null}
        title={`Xem trước mẫu in`}
        visible={visiblePreview}
      >
        {parse(convertTemplate(contenEdit))}
      </Modal>
    </div>
  )
}
