import { socket } from '../../socket'
import { notification } from 'antd'
import { _getProductChange } from '../Product/getProductChange'

//apis
import { getVariantsSell } from 'apis/product'

let handleData = async (res, branchIdApp, products, dispatch) => {
  if (res.productList.length > 0) {
    let newData = await _getProductChange(branchIdApp, res.productList)
    dispatch({
      type: 'SET_NEW_PRODUCTS',
      data: {
        products: newData.products,
        ids: res.productList,
      },
    })
    dispatch({
      type: 'SET_VARIANTSELL',
      data: {
        variants: products,
        counts: products.length,
      },
    })
    if(products.length > 0){
      for (let item of newData.products) {
        let index = products.findIndex((e) => e.variant_id === item.variant_id)
        products[index].total_quantity = item?.total_quantity
      }
    }
  }
}
export const socketTransport = (dataUser, users, branchIdApp, products, dispatch) => {
  socket.on(`transport:action:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
    try {
      let user = users.find((e) => e.user_id === res.updatedUser)
      if(user){
        notification.success({
          key: 'SocketTransport',
          message: `${user.first_name} ${user.last_name} vừa cập nhật số lượng sản phẩm`,
        })
      }else notification.success({
        key: 'SocketTransport',
        message: `Số lượng sản phẩm đã vừa được cập nhật`,
      })
      handleData(res, branchIdApp, products, dispatch)
    } catch (error) {
      console.log(error)
    }
  })
}
export const socketInventoryNote = (
  dataUser,
  users,
  branchIdApp,
  products,
  dispatch
) => {
  socket.on(`inventory-note:action:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
    try {
      let user = users.find((e) => e.user_id === res.updatedUser)
      if(user){
        notification.success({
          key: 'SocketInventoryNote',
          message: `${user.first_name} ${user.last_name} vừa cập nhật số lượng sản phẩm`,
        })
      }else notification.success({
        key: 'SocketInventoryNote',
        message: `Số lượng sản phẩm đã vừa được cập nhật`,
      })
      
      handleData(res, branchIdApp, products, dispatch)
    } catch (error) {
      console.log(error)
    }
  })
}
export const socketProductUpdate = (dataUser, users, branchIdApp, dispatch) => {
  socket.on(`product:update:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
    try {
      let user = users.find((e) => e.user_id === res.updatedUser)
      if(user){
        notification.success({
          key: 'UpdateProduct',
          message: `${user.first_name} ${user.last_name} vừa cập nhật sản phẩm`,
        })
      }else notification.success({
        key: 'UpdateProduct',
        message: `Sản phẩm vừa cập nhật`,
      })
      
      if (res.productList.length > 0) {
        const res = await getVariantsSell({ branch_id: branchIdApp })
        if (res.status === 200) {
          dispatch({
            type: 'SET_VARIANTSELL',
            data: {
              variants: res.data.data,
              counts: res.data.count,
            },
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  })
}
export const socketListener = (dataUser,users,branchIdApp,products,dispatch) => {
    socket.on(`product:action:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
      try {
        let user = users.find((e) => e.user_id === res.updatedUser)
        if(user){
          notification.success({
            key: 'UpdateProduct',
            message: `${user.first_name} ${user.last_name} vừa cập nhật số lượng sản phẩm`,
        })
        }notification.success({
          key: 'UpdateProduct',
          message: `Số lượng sản phẩm vừa được cập nhật`,
        })
        
       handleData(res, branchIdApp, products, dispatch)
      } catch (error) {
        console.log(error)
      }
    })
}