import React, { useState, useEffect } from 'react'
import { compare, removeUnicode } from 'utils'
import { FILTER_SIZE, PAGE_SIZE, ROUTES } from 'consts'
import { useSelector } from 'react-redux'

//antd
import { Row, Table, Button, Col, DatePicker, Select } from 'antd'

//components
import TitlePage from 'components/title-page'
import { useTranslation } from 'react-i18next'
import FilterDate from 'components/filter-date/filter'
import { handleCell } from 'components/handleCell'
//apis
import { getRefundHistory, getProducts } from 'apis/product'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { DefaultFilterData } from 'utils/DefaultFilterData'

export default function ReturnHistory() {
  const { t } = useTranslation()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [product, setProduct] = useState([])
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [loading, setLoading] = useState(false)
  const [refundHistory, setRefundHistory] = useState([])
  const [refundHistoryShow, setRefundHistoryShow] = useState([])

  const [sumQuantity, setSumQuantity] = useState(0)

  const [valueTime, setValueTime] = useState(``)
  const [paramsFilter, setParamsFilter] = useState({page: 1, page_size: PAGE_SIZE})
  const handleTotal = (data) => {
    let total = 0
    data.forEach(element => {
      total += element.refund_quantity
    });
    return total
  }
  const _onFilter = (attribute = '', value = '') => {
    if (attribute == 'product_sku') {
      let resultIndex
      if (value !== undefined) {
        let index = value.lastIndexOf('+')
        resultIndex = value.substring(index + 1)
      }
      paramsFilter[attribute] = resultIndex
      setParamsFilter({...paramsFilter})
    }
  }
  useEffect(() => {
    let arr = [...refundHistory]
    const filtered = arr.filter((current) => {
      let matchType = true
      let matchTime = true
      let matchSearch = true
      if (paramsFilter.product_sku && paramsFilter.product_sku !== 'all')
        matchType = current?.product_info.sku == paramsFilter.product_sku
      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date
      return matchType && matchSearch && matchTime
    })
    setRefundHistoryShow(filtered)
    setCountAction(filtered.length)
    setSumQuantity(handleTotal(filtered))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter])
  const [countAction, setCountAction] = useState(0)

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
      width:'5%',
      align:'center'
    },
    {
      title: 'Mã chứng từ',
      render: (text, record) => (
        <Link
          to={`${ROUTES.REFUND_DETAIL + '/' + record?.order_refund_code}`}
          style={{ color: 'red' }}
          target="_blank"
        >
          {record?.order_refund_code}
        </Link>
      ),
      onCell: (record,index) => handleCell(record, (paramsFilter.page - 1) * paramsFilter.page_size + index, refundHistoryShow, 'refund_order_id',(paramsFilter.page - 1) * paramsFilter.page_size)

    },
    {
      title: 'SKU phiên bản',
      render: (text, record) => record?.variant_info?.sku,
    },
    {
      title: 'Tên phiên bản',
      key: 'title',

      render: (text, record) => record?.variant_info?.title,
    },
    {
      title: 'Số lượng',
      dataIndex: 'refund_quantity',
      sorter: (a, b) => compare(a, b, 'refund_quantity'),
      align:'center',
      width: '8%'
    },
    // {
    //   title: 'Đơn giá',
    //   render: (text, record) => formatCash(record?.price),
    //   sorter: (a, b) => compare(a, b, 'price'),
    // },
    // {
    //   title: 'Thành tiền',
    //   render: (text, record) => formatCash(record?.quantity * record?.price),
    // },
    {
      title: 'Nhân viên',
      render: (text, record) =>
        record?.creator_info.first_name + ' ' + record.creator_info.last_name,
    },
    {
      title: 'Tồn kho',
      render: (text, record) => record?.product_inventory_quantity || 0,
      align:'center',
      width:'8%'
    },
    {
      title: 'Ngày ghi nhận',
      render: (text, record) => moment(record?.create_date).format('DD-MM-YYYY, HH:mm'),
    },
  ]

  const _getRefundHistory = async () => {
    try {
      setLoading(true)
      const res = await getRefundHistory({ branch_id: branchIdApp })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id,permissions, res.data.data)
        setRefundHistory(result.data)
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
            from_date: result.defaultTime.from_date,
            to_date: result.defaultTime.to_date
          })
          setValueTime(result.valueDate)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const onClickClear = async () => {
    setParamsFilter({page: 1, page_size: PAGE_SIZE})
    setValueTime()
  }
  const _getProducts = async () => {
    try {
      const res = await getProducts()
      if (res.status === 200) setProduct(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getRefundHistory()
    _getProducts()
  }, [branchIdApp, dataUser])

  return (
    <div className="card">
      <TitlePage isAffix title={<Row align="middle">Lịch sử trả hàng</Row>}>
        <Button
          style={{ display: Object.keys(paramsFilter).length <= 2 && 'none' }}
          onClick={onClickClear}
          type="primary"
          size="large"
        >
          Xóa tất cả lọc
        </Button>
      </TitlePage>
      <Row gutter={[16, 16]} style={{marginTop:8}}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={6}
        >
          <FilterDate
            size='default'
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            valueTime={valueTime}
            setValueTime={setValueTime}
            title="trả hàng"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={6}
        >
          <Select
            size={FILTER_SIZE}
            allowClear
            bordered={true}
            defaultValue={'all'}
            value={paramsFilter.product_sku || 'all'}
            showSearch
            filterOption={(input, option) =>
              removeUnicode(option.children.toString())
                .toLowerCase()
                .trim()
                .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
            }
            onChange={(value) => {
              _onFilter('product_sku', value)
            }}
            placeholder="Lọc theo sản phẩm"
            style={{ width: '100%' }}
          >
            <Select.Option value="all">Tất cả sản phẩm</Select.Option>
            {product.map((_product, index) => (
              <Select.Option value={`${_product.name}+${_product.sku}`} key={index}>
                {_product.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          marginLeft: '0px',
          marginRight: '0px',
          borderRadius: 5,
        }}
      >
        <Table
          loading={loading}
          size="small"
          columns={columns}
          dataSource={refundHistoryShow}
          style={{ width: '100%' }}
          scroll={{ y: 600 }}
          pagination={{
            position: ['bottomLeft'],
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => setParamsFilter({ ...paramsFilter,page: page,page_size: pageSize }),
            total: countAction,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                </Table.Summary.Cell>
                {columns.map((e, index) => {
                  if (e.key === 'title')
                    return (
                      <Table.Summary.Cell>
                        <div style={{textAlign:'center'}}>{sumQuantity}</div>
                      </Table.Summary.Cell>
                    )
                  return <Table.Summary.Cell></Table.Summary.Cell>
                })}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Row>
    </div>
  )
}
