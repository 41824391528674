import styles from './new-feed.module.scss'

function NewFeedsLayout({ classes = '', children }) {
  return (
    <div className={`${styles['container__layout']} ${classes}`}>
      <div className={styles['container__content']}>{children}</div>
    </div>
  )
}

export default NewFeedsLayout
