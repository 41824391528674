import React, { useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Upload, notification } from 'antd'
const UploadImage = ({ setImageView, imageView, setFileUpload, disableField }) => {
  const typingTimeoutRef = useRef(null)

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  return (
    <Upload
      disabled={disableField ? true : false}
      fileList={[]}
      name="avatar"
      listType="picture-card"
      showUploadList={false}
      onChange={(info) => {
        const isJpgOrPng =
          info.file.type === 'image/jpeg' ||
          info.file.type === 'image/png' ||
          info.file.type === 'image/jpg'

        const isLt2M = info.file.size / 1024 / 1024 < 2

        if (!isJpgOrPng) {
          notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
        }

        if (!isLt2M) {
          notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
        }

        if (isJpgOrPng && isLt2M) {
          if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current)
          }
          typingTimeoutRef.current = setTimeout(async () => {
            if (info.file.status !== 'done') info.file.status = 'done'
            getBase64(info.file.originFileObj, (imageUrl) => setImageView(imageUrl))
            setFileUpload(info.file.originFileObj)
          }, 450)
        }
      }}
    >
      {imageView ? (
        <img
          src={imageView}
          alt="avatar"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      ) : (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Tải lên</div>
        </div>
      )}
    </Upload>
  )
}
export default UploadImage
