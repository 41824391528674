import { useHistory } from 'react-router-dom'

import { Row, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { ROUTES, PERMISSIONS } from 'consts'

import TitlePage from 'components/title-page'
import Permission from 'components/permission'

function Header({ showDrawer }) {
  const history = useHistory()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const clickHandle = () => showDrawer()

  return (
    <TitlePage
      title={
        <Row
          align="middle"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
        >
          <ArrowLeftOutlined style={{ marginRight: 8 }} />
          Quản lý phân quyền
        </Row>
      }
    >
      {permissions.includes(PERMISSIONS.them_vai_tro_phan_quyen) ? (
        <Button onClick={clickHandle} type="primary" size="large">
          Thêm vai trò
        </Button>
      ) : (
        <></>
      )}
    </TitlePage>
  )
}

export default Header
