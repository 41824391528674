import { getCurrentBusiness } from 'apis/business'
import { ACTION } from 'consts'
import jwt_decode from 'jwt-decode'


export const socketListenerRole =
  ({ res }) =>
  async (dispatch) => {
    if(!res) return
    try {
      let res2 = await getCurrentBusiness()
      if (res2.status != 200) window.location.href = 'http://' + process.env.REACT_APP_ECOM_HOST
      else {
        dispatch({ type: ACTION.LOGIN, data: res2.data.data })
        localStorage.setItem('accessToken', res2.data.data.accessToken)
        let dataUser1 = jwt_decode(res2.data.data.accessToken)
        let dataUser = dataUser1.data
          let newPermisionList = res.data

          dataUser._role.permission_list = newPermisionList

          dispatch({
            type: 'UPDATE_DATA_USER',
            data: dataUser,
          })
      }

    } catch (error) {
      console.log(error)
    }
  }
