import React, { useEffect, useState } from 'react'
import styles from 'components/Shipping/shipping.module.scss'
import { Form, Input, Radio, Checkbox, Row, Col } from 'antd'

//components
import tokenGHTK from 'assets/shipping/ghtk_2.png'
import infoGHTK from 'assets/shipping/ghtk_1.png'
import { Button } from 'components/Button/Button'

export default function Ghtk({ connectShipping }) {
  const options = [
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Thư tín tài liệu</div>{' '}
          <div className={styles['font-span']}>Miễn phí</div>
        </div>
      ),
      value: 22,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Hàng dễ vỡ</div>{' '}
          <span className={styles['font-span']}>20% phí dịch vụ</span>
        </div>
      ),
      value: 1,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Thực phẩm khô</div>{' '}
          <span className={styles['font-span']}>10% phí dịch vụ</span>
        </div>
      ),
      value: 7,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Hàng nguyên hộp</div>{' '}
          <span className={styles['font-span']}>Phí 1,000đ</span>
        </div>
      ),
      value: 20,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Cho xem hàng</div>{' '}
          <span className={styles['font-span']}>Miễn phí</span>
        </div>
      ),
      value: 10,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Cho thử hàng/ đồng kiểm</div>{' '}
          <span className={styles['font-span']}>Phí 2,000đ</span>
        </div>
      ),
      value: 11,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Gọi shop khi khách không nhận hàng, không liên lạc được, sai thông tin</div>{' '}
          <span className={styles['font-span']}>Miễn phí</span>
        </div>
      ),
      value: 13,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Giao hàng 1 phần chọn sản phẩm</div>{' '}
          <span className={styles['font-span']}>Phí hoàn</span>
        </div>
      ),
      value: 17,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Giao hàng 1 phần đổi trả hàng</div>{' '}
          <span className={styles['font-span']}>Phí hoàn</span>
        </div>
      ),
      value: 18,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 300 }}>Không giao được thu</div>{' '}
          <span className={styles['font-span']}>Phí hoàn</span>
        </div>
      ),
      value: 19,
    },
  ]
  const [form] = Form.useForm()

  const connect = async () => {
    try {
      const data = form.getFieldValue()
      const body = {
        token: data.token,
        tags: data.tags || [],
        transport: data.transport || 0,
        pick_money: data.pick_money || 0,
        note: data.note || '',
        pickup: data.pickup || 0,
        kgProduct: data.kgProduct || 0,
      }
      await connectShipping(body)
    } catch (error) {
      console.log(error)
    }
  }

  return (
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
        // layout="horizontal"
        style={{ display: 'flex' }}
        onFinish={connect}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={14} style={{ borderBottom: '1px solid' }}>
            <Row style={{ width: '100%' }}>
              <Row style={{ width: '100%' }}>
                <Form.Item
                  style={{ fontWeight: 'bold', width: '100%' }}
                  label="Token API"
                  name="token"
                >
                  <Input placeholder="Token API" />
                </Form.Item>
              </Row>
              <Row style={{ width: '100%' }}>
                <Button
                  style={{
                    backgroundColor: '#6D24CF',
                    border: 'none',
                    width: '20%',
                    color: 'white',
                    borderRadius: '5px',
                  }}
                  htmlType="submit"
                  text="Kết nối"
                />
              </Row>
              <Row>
                <h2>Cài đặt các giá trị mặc định</h2>
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <h3 style={{ width: '100%' }}>Lấy và giao tận nơi</h3>
              <Form.Item style={{ width: '100%' }} name="kgProduct">
                <Radio.Group className={styles['point-bottom-right-radio']} defaultValue={0}>
                  <Radio value={0}>Hàng nhỏ Express (≤20kg)</Radio>
                  <Radio value={1}>{`Hàng lớn BBS (>20kg)`}</Radio>
                </Radio.Group>
              </Form.Item>
              <h3 style={{ width: '100%' }}>Hình thức giao hàng</h3>
              <Form.Item style={{ width: '100%' }} name="transport">
                <Radio.Group className={styles['point-bottom-right-radio']} defaultValue={0}>
                  <Radio value={0}>Bộ</Radio>
                  <Radio value={1}>Hàng không</Radio>
                </Radio.Group>
              </Form.Item>
              <h3 style={{ width: '100%' }}>Hình thức lấy hàng</h3>
              <Form.Item style={{ width: '100%' }} name="pickup">
                <Radio.Group className={styles['point-bottom-right-radio']} defaultValue={0}>
                  <Radio value={0}>Lấy hàng tận nơi</Radio>
                  <Radio value={1}>Gửi hàng tại bưu cục</Radio>
                </Radio.Group>
              </Form.Item>
              <h3 style={{ width: '100%' }}>Thông tin đơn hàng</h3>
              <Form.Item style={{ width: '100%' }} label="Tiền thu hộ" name="pick_money">
                <Input />
              </Form.Item>
              <Form.Item style={{ width: '100%' }} label="Ghi chú" name="note">
                <Input />
              </Form.Item>
              <Form.Item style={{ width: '100%' }} name="tags">
                <Checkbox.Group
                  style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                  options={options}
                  defaultValue={[]}
                />
              </Form.Item>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={10}>
            <div style={{ marginTop: 20, color: 'black', fontWeight: 'bold', fontSize: 22 }}>
              {'Hướng dẫn lấy Token'}
            </div>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>{'B1. Truy cập đường dẫn sau: '}</div>
            <a
              href="https://khachhang.giaohangtietkiem.vn/khach-hang/dang_nhap"
              target="_blank"
              rel="noreferrer"
            >
              {'https://khachhang.giaohangtietkiem.vn/khach-hang/dang_nhap'}
            </a>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              {'B2. Đăng nhập tài khoản (hoặc đăng ký nếu chưa có)'}
            </div>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              {'B3. Bấm chọn Thông tin tài khoản như hình dưới'}
            </div>
            <img src={infoGHTK} width="100%" height="auto" alt="infoGHTK"></img>

            <div style={{ fontWeight: 'bold', fontSize: 16, marginTop:20 }}>
              {'B4. Góc trên, bên trái, vui lòng nhấn icon copy API Token Key và điền vào phần mềm'}
            </div>
            <img src={tokenGHTK} width="100%" height="auto" alt="apiGHTK"></img>
          </Col>
        </Row>
      </Form>
  )
}
