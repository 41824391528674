const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: '5%',
    align: 'center'
  },
  {
    title: 'Mã phiếu',
    key: 'code',
  },
  {
    title: 'Trạng thái',
    key: 'status',
  },
  {
    title: 'Người điều chỉnh',
    key: 'updater',
  },
  {
    title: 'Người tạo phiếu',
    key: 'creator',
  },  
  {
    title: 'Ngày tạo',
    key: 'create_date',
  },

  {
    title: 'Ngày cập nhật cuối',
    key: 'last_update',
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
  },

]

export default columns
