import React, { useState } from 'react'

//antd
import {
  Row,
  Col,
  Divider,
} from 'antd'

import Header from './layout-items/header'
import LeftSide from './layout-items/left-side'
import RightSide from './layout-items/right-side'

export default function PriceAdjustments() {
  const [productChange, setProductChange] = useState([])
  const [valuePrice, setValuePrice] = useState(null)
  return (
    <div className="card" style={{paddingTop: 5}}>
      <Header setValuePrice={setValuePrice} valuePrice={valuePrice} productChange={productChange} setProductChange={setProductChange} />
      <Divider style={{ backgroundColor: 'silver', marginTop: 7, marginBottom: 10 }} />
      <Row gutter={[12]}>
        <Col xl={12}>
          <LeftSide productChange={productChange} setProductChange={setProductChange} />
        </Col>
        <Col xl={12}>
          <RightSide productChange={productChange} setProductChange={setProductChange} valuePrice={valuePrice} />
        </Col>
      </Row>
    </div>
  )
}
