import React, { useState, useEffect } from 'react'

import styles from './stock-adjustments.module.scss'

import { Table, Modal, Input, List, Space, Tag, Button } from 'antd'
import { formatCash, removeUnicode } from 'utils'
import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { sortBy } from 'lodash'
import { getInventories } from 'apis/inventory'
import { ACTION, TIME_FORMAT } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import exportCsv from 'components/ExportCSV/export'

export default function Check({ data, product, amountSystem, status, code }) {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [visibleLocation, setVisibleLocation] = useState(false)
  const [visibleImei, setVisibleImei] = useState(false)
  const [visibleProduct, setVisibleProduct] = useState(false)
  const [dataLocation, setDataLocation] = useState([])
  const [dataProductLocation, setDataProductLocation] = useState([])
  const [dataProductImei, setDataProductImet] = useState([])
  const [inventory, setInventory] = useState([])

  const toggle = () => setVisible(!visible)
  const toggleProduct = () => setVisibleProduct(!visibleProduct)
  const toggleLocation = () => setVisibleLocation(!visibleLocation)
  const toggleImei = () => setVisibleImei(!visibleImei)
  //danh sách lần kiểm
  const _getArray = () => {
    let locationData = JSON.parse(JSON.stringify(data))
    let dataCountCheck = []
    if (Array.isArray(locationData)) {
      locationData.map((list) =>
        list.listProduct.map((pr) => {
          let new_check = pr.checked.reduce((arr, current) => {
            let index = arr.findIndex((e) => e.countCheck === current.countCheck)
            if (index === -1) {
              current['location'] = pr.location
              current['variant_id'] = pr.variant_id
              arr.push(current)
            } else {
              arr[index].quantity = current.quantity
            }
            return arr
          }, [])
          new_check.map((check) => {
            let index = dataCountCheck.findIndex((e) => e.count == check.countCheck)
            if (index === -1) {
              let dataTmp = {
                count: check.countCheck, // lần kiểm
                system_quantity: amountSystem, // số lượng tổng sản phẩm trong lần kiểm (không thay đổi)
                arr_check: [check],
              }
              dataCountCheck.push(dataTmp)
            } else {
              dataCountCheck[index].arr_check.push(check)
            }
          })
        })
      )
      dataCountCheck.forEach((element, index) => {
        element['real_quantity'] = 0
        element.arr_check.forEach((check) => {
          element['real_quantity'] += check.quantity
          let new_index =
            dataCountCheck[index + 1] &&
            dataCountCheck[index + 1].arr_check.findIndex(
              (e) => e.location === check.location && e.variant_id === check.variant_id
            )
          if (new_index === -1) {
            dataCountCheck[index + 1] && dataCountCheck[index + 1].arr_check.push(check)
          }
        })
      })
      dataCountCheck = sortBy(dataCountCheck, 'count')

      setDataLocation(dataCountCheck)
    }
  }
  const [dataColumns, setDataColumns] = useState([])
  const [number, setNumber] = useState(0)
  //danh sách products
  const _DataColumns = (int) => {
    let array = []
    let arrDataTmp = JSON.parse(JSON.stringify(data))
    product.map((e) => {
      let prodTmp = {
        product_id: e.product_id,
        variant_id: e.variant_id,
        sku: e.sku,
        title: e.title,
        price: e.price,
        location: [],
        real_quantity: 0,
        system_quantity: e.total_quantity,
        type_advanced: e.type_advanced,
        location_check: e.location_check,
        imei_check: e.imei_check,
      }
      for (let i = 1; i <= int; i++) {
        let sumTmp = 0
        let realTmp = 0
        let tagLocation = ''

        arrDataTmp.map((item) => {
          let pr = item.listProduct.filter((list) => e.variant_id === list.variant_id)
          let arrCheckLocation = []

          pr.length > 0 &&
            pr.map((_pr) => {
              let new_check = _pr.checked.reduce((arr, current) => {
                let index = arr.findIndex((e) => e.countCheck === current.countCheck)
                if (index === -1) {
                  arr.push(current)
                } else {
                  arr[index].quantity = current.quantity
                }
                return arr
              }, [])
              new_check.map((eCheck) => {
                if (eCheck.countCheck <= i) {
                  let indexL = arrCheckLocation.findIndex(
                    (e_location) => item.location === e_location.location
                  )
                  if (indexL === -1) {
                    let objL = {
                      location: item.location,
                      quantity: eCheck.quantity,
                      countCheck: eCheck.countCheck,
                    }
                    arrCheckLocation.push(objL)
                  } else {
                    arrCheckLocation[indexL].quantity = eCheck.quantity
                    arrCheckLocation[indexL].countCheck = eCheck.countCheck
                  }
                }
              })
            })
          arrCheckLocation.forEach((element) => {
            sumTmp += element.quantity

            tagLocation = `${tagLocation}` + ' ' + `${element.location}  ${element.quantity};`
            if (element.countCheck === int) {
              realTmp += element.quantity
            }
          })
        })
        prodTmp.real_quantity = realTmp
        if (sumTmp !== 0) prodTmp.location.push(`${tagLocation}` + ' ' + `(Lần${i}_${sumTmp})`)
        prodTmp.real_quantity = sumTmp
      }
      if (status === 3) {
        let productNew = inventory.find((item) => item.variant_id === e.variant_id)
        if (productNew) {
          prodTmp.system_quantity =
            productNew.quantity - productNew.import_quantity + productNew.export_quantity
        }else{
          prodTmp.system_quantity = prodTmp.real_quantity
        }
      }
      array.push(prodTmp)
    })
    return array.filter(
      (item) =>
        (item.real_quantity !== 0 && item.system_quantity === 0) ||
        (item.real_quantity === 0 && item.system_quantity !== 0) ||
        (item.real_quantity !== 0 && item.system_quantity !== 0)
    )
  }
  const _getDataRowProduct = (int) => {
    let result = _DataColumns(int)
    setNumber(int)
    setDataColumns(result)
    toggleProduct()
  }
  const _dataLocationCheck = (variant, int) => {
    var arrLocationTmp = []
    let arrDataTmp = JSON.parse(JSON.stringify(data))
    arrDataTmp.map((item) => {
      if (item.countCheck <= int) {
        let pr = item.listProduct.filter((list) => variant.variant_id === list.variant_id)
        pr.map((_pr) => {
          let locations = [..._pr.location_check]
          for (let location of locations) {
            let indexLocation = arrLocationTmp.findIndex(
              (e) => e.location_id === location.location_id
            )
            if (indexLocation === -1) {
              arrLocationTmp.push(location)
            } else {
              arrLocationTmp[indexLocation].quantity_check += location.quantity_check
            }
          }
        })
      }
    })
    setDataProductLocation(arrLocationTmp)
    toggleLocation()
  }
  const _dataImeiCheck = (variant, int) => {
    let arrImeiTmp = []
    data.forEach((item) => {
      if (item.countCheck === int) {
        let pr = item.listProduct.filter((list) => variant.variant_id === list.variant_id)
        pr.forEach((_pr) => {
          arrImeiTmp = arrImeiTmp.concat(_pr?.imei_check)
        })
      }
    })
    setDataProductImet(arrImeiTmp)
    toggleImei()
  }
  const columnSum = [
    {
      title: <div style={{ textAlign: 'center' }}>Lần kiểm</div>,
      render: (text, record) => (
        <div
          style={{ textAlign: 'center' }}
          className={styles.view_history}
          onClick={() => _getDataRowProduct(record.count)}
        >{`Kiểm lần ${record.count}`}</div>
      ),
    },
    {
      title: <div style={{ textAlign: 'center' }}>SL hệ thống</div>,
      render: (text, record) => <div style={{ textAlign: 'center' }}>{record.system_quantity}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>SL thực tế</div>,
      render: (text, record) => <div style={{ textAlign: 'center' }}>{record.real_quantity}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Trạng thái</div>,
      render: (text, record) =>
        record.real_quantity > record.system_quantity ? (
          <div
            style={{
              backgroundColor: 'rgb(16 199 16)',
              color: 'aliceblue',
              textAlign: 'center',
              borderRadius: '5px',
              fontSize: '15px',
              fontWeight: 700,
            }}
          >{`Thừa ${record.real_quantity - record.system_quantity}`}</div>
        ) : record.real_quantity < record.system_quantity ? (
          <div
            style={{
              backgroundColor: 'rgb(211 54 54)',
              color: 'aliceblue',
              textAlign: 'center',
              borderRadius: '5px',
              fontSize: '15px',
              fontWeight: 700,
            }}
          >{`Thiếu ${record.system_quantity - record.real_quantity}`}</div>
        ) : null,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Xuất Excel</div>,
      render: (text, record) => (
        <div style={{ textAlign: 'center' }}>
          <Button
            id="exportFile"
            icon={<UploadOutlined />}
            style={{
              backgroundColor: '#66AE43',
              borderColor: '#66AE43',
              borderRadius: 5,
              color: 'white',
              fontWeight: 600,
            }}
            onClick={() => exportProduct(record.count)}
          />
        </div>
      ),
    },
  ]
  const columnProduct = [
    {
      width: '4%',
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      width: '10%',
      title: 'Mã SKU',
      render: (text, record) => <div>{record.sku}</div>,
    },
    {
      width: '15%',
      title: 'Tên sản phẩm',
      render: (text, record) => <div>{record.title}</div>,
    },
    {
      width: '10%',
      title: 'Giá bán',
      render: (text, record) => <div>{formatCash(record.price)}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Vị trí kiểm</div>,
      render: (text, record) =>
        record?.location.map((employee) => <Tag style={{ fontWeight: 'bold' }}>{employee}</Tag>),
    },
    {
      width: '10%',
      title: <div style={{ textAlign: 'center' }}>SL hệ thống</div>,
      render: (text, record) => <div style={{ textAlign: 'center' }}>{record.system_quantity}</div>,
    },
    {
      width: '10%',
      title: <div style={{ textAlign: 'center' }}>SL thực tế</div>,
      render: (text, record) =>
        record.type_advanced === 2 ? (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>{record.real_quantity}</div>{' '}
            <div
              onClick={() => {
                _dataLocationCheck(record, number)
              }}
              style={{ color: '#1E4DB7', cursor: 'pointer', textDecoration: 'underline' }}
            >
              Xem thêm
            </div>
          </div>
        ) : record.type_advanced === 3 ? (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>{record.real_quantity}</div>{' '}
            <div
              onClick={() => {
                _dataImeiCheck(record, number)
              }}
              style={{ color: '#1E4DB7', cursor: 'pointer', textDecoration: 'underline' }}
            >
              Xem thêm
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>{record.real_quantity}</div>
        ),
    },
    {
      width: '15%',
      title: <div style={{ textAlign: 'center' }}>Trạng thái</div>,
      render: (text, record) => {
        if (record.system_quantity > record.real_quantity)
          return (
            <div
              style={{
                backgroundColor: 'rgb(211 54 54)',
                color: 'aliceblue',
                textAlign: 'center',
                borderRadius: '5px',
                fontSize: '15px',
                fontWeight: 700,
              }}
            >{`Thiếu ${record.system_quantity - record.real_quantity}`}</div>
          )
        else if (record.system_quantity < record.real_quantity)
          return (
            <div
              style={{
                backgroundColor: 'rgb(16 199 16)',
                color: 'aliceblue',
                textAlign: 'center',
                borderRadius: '5px',
                fontSize: '15px',
                fontWeight: 700,
              }}
            >{`Thừa ${record.real_quantity - record.system_quantity}`}</div>
          )
        else
          return (
            <div
              style={{
                backgroundColor: 'rgb(67 142 255)',
                color: 'aliceblue',
                textAlign: 'center',
                borderRadius: '5px',
                fontSize: '15px',
                fontWeight: 700,
              }}
            >
              Cân bằng
            </div>
          )
      },
    },
  ]
  const columnsLocation = [
    {
      title: 'Mã lô',
      dataIndex: 'location_id',
      render: (text, record) => record.location_id,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'exp',
      render: (text, record) => record.exp,
    },
    {
      title: 'Số lượng tồn',
      render: (text, record, index) => <div>{record.quantity_check}</div>,
    },
  ]
  //tìm kiếm trong mảng
  const [inputText, setInputText] = useState('')
  let onSearch = (e) => {
    var lowerCase = e.target.value
    setInputText(lowerCase)
  }
  const filteredData =
    dataColumns &&
    dataColumns.filter((e) => {
      if (inputText === '') {
        return e
      } else {
        return (
          removeUnicode(e.sku.toLowerCase()).includes(inputText.toLowerCase().trim()) ||
          removeUnicode(e.title.toLowerCase()).includes(inputText.toLowerCase().trim())
        )
      }
    })
  const _getInventories = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getInventories({
        code_source: code,
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        setInventory(res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _getLocationExport = (location) => {
    let result = ''
    location.forEach((element, index) => {
      result += `${element}${index === location.length - 1 ? '': ','}`
    })
    return result
  }
  const exportProduct = async (int) => {
    try {
      let result = _DataColumns(int)
      let dataExport = result.map((e, index)=> ({
        'STT': index + 1,
        'Mã sản phẩm': e.sku,
        'Tên sản phẩm': e.sku,
        'Giá bán': e.price,
        'Vị trí kiểm': _getLocationExport(e.location),
        'Số lượng hệ thống': e.system_quantity,
        'Số lượng thực tế': e.real_quantity,
        'Trạng thái': e.system_quantity > e.real_quantity ? `Thiếu ${e.system_quantity - e.real_quantity}` : e.system_quantity < e.real_quantity ? `Thừa ${e.real_quantity - e.system_quantity}` : 'Cân bằng',
      }))
      await exportCsv(dataExport, 'Danh sách sản phẩm kiểm kho', 'Xuất file excel', dispatch)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getArray()
  }, [visible])
  useEffect(() => {
    if (status === 3) _getInventories()
  }, [status])
  return (
    <>
      <div className={styles.view_history} onClick={toggle}>
        Xem lịch sử kiểm
      </div>
      <Modal
        width={600}
        title="Lịch sử kiểm hàng"
        onOk={toggle}
        onCancel={toggle}
        visible={visible}
        footer={null}
      >
        <Table
          size="small"
          columns={columnSum}
          dataSource={dataLocation}
          style={{ width: '100%' }}
          pagination={false}
          scroll={{ y: 600 }}
        />
      </Modal>

      <Modal
        width={'80%'}
        title={`Kết quả kiểm lần ${number}`}
        onOk={toggleProduct}
        onCancel={toggleProduct}
        visible={visibleProduct}
        footer={null}
      >
        <Input
          style={{ width: '100%' }}
          prefix={<SearchOutlined />}
          name="nameVote"
          value={inputText}
          onChange={onSearch}
          placeholder="Tìm kiếm theo mã SKU hoặc tên sản phẩm"
          bordered={true}
          allowClear
        />

        <Table
          size="small"
          columns={columnProduct}
          dataSource={filteredData}
          style={{ width: '100%' }}
          scroll={{ y: 700 }}
        />
      </Modal>
      <Modal
        width={800}
        title="Số lượng sản phẩm theo lô"
        onOk={toggleLocation}
        onCancel={toggleLocation}
        visible={visibleLocation}
        footer={null}
      >
        <Table
          size="small"
          columns={columnsLocation}
          dataSource={dataProductLocation}
          style={{ width: '100%' }}
          scroll={{ y: 700 }}
        />
      </Modal>
      <Modal
        width={800}
        title="Số lượng sản phẩm theo imei"
        onOk={toggleImei}
        onCancel={toggleImei}
        visible={visibleImei}
        footer={null}
      >
        <List
          style={{
            overflow: 'auto',
            height: '500px',
            border: '1px solid #d9d9d9',
            borderRadius: '10px',
            padding: '10px',
          }}
          itemLayout="horizontal"
          dataSource={dataProductImei}
          renderItem={(item, index) => (
            <List.Item>
              <Space className={styles['btnShift']}>{item}</Space>
            </List.Item>
          )}
        />
      </Modal>
    </>
  )
}
