import React, { useEffect, useState } from 'react'
import { Button } from 'components/Button/Button'
import styles from '../shipping.module.scss'
import { Form, Input, Radio, Checkbox, Row, Col } from 'antd'

export default function Ghtk({ deliveryAddress,customer, shipping_info , edit, close}) {
  const options = [
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Thư tín tài liệu</div>{' '}
          <div className={styles['font-span']}>Miễn phí</div>
        </div>
      ),
      value: 22,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Hàng dễ vỡ</div>{' '}
          <span className={styles['font-span']}>20% phí dịch vụ</span>
        </div>
      ),
      value: 1,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Thực phẩm khô</div>{' '}
          <span className={styles['font-span']}>10% phí dịch vụ</span>
        </div>
      ),
      value: 7,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Hàng nguyên hộp</div>{' '}
          <span className={styles['font-span']}>Phí 1,000đ</span>
        </div>
      ),
      value: 20,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Cho xem hàng</div>{' '}
          <span className={styles['font-span']}>Miễn phí</span>
        </div>
      ),
      value: 10,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Cho thử hàng/ đồng kiểm</div>{' '}
          <span className={styles['font-span']}>Phí 2,000đ</span>
        </div>
      ),
      value: 11,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Gọi shop khi khách không nhận hàng, không liên lạc được, sai thông tin</div>{' '}
          <span className={styles['font-span']}>Miễn phí</span>
        </div>
      ),
      value: 13,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Giao hàng 1 phần chọn sản phẩm</div>{' '}
          <span className={styles['font-span']}>Phí hoàn</span>
        </div>
      ),
      value: 17,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Giao hàng 1 phần đổi trả hàng</div>{' '}
          <span className={styles['font-span']}>Phí hoàn</span>
        </div>
      ),
      value: 18,
    },
    {
      label: (
        <div className={styles['label-check']}>
          <div style={{ width: 200 }}>Không giao được thu</div>{' '}
          <span className={styles['font-span']}>Phí hoàn</span>
        </div>
      ),
      value: 19,
    },
  ]
  const [form] = Form.useForm()
  const [loading, setLoading]= useState(false)
  const _edid = async () => {
    try {
      setLoading(true)
      const data = form.getFieldValue()
      const body = {
        tags: data.tags || shipping_info?.tags,
        transport: data.transport || shipping_info?.transport,
        pick_money: data.pick_money || shipping_info?.pick_money,
        note: data.note || shipping_info?.note,
        pickup: data.pickup || shipping_info?.pickup,
        kgProduct: data.kgProduct || shipping_info?.kgProduct,
      }
      await edit(body)
      close()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      name: customer?.first_name + ' ' + customer?.last_name,
      SDT: customer?.phone,
      address: deliveryAddress?.address || customer?.customerDelivery[0]?.address,
      ward: deliveryAddress?.ward.ward_name || customer?.customerDelivery[0]?.ward.ward_name,
      district: deliveryAddress?.district?.district_name || customer?.customerDelivery[0]?.district.district_name,
      province: deliveryAddress?.province?.province_name || customer?.customerDelivery[0]?.province.province_name,
      kgProduct: shipping_info?.kgProduct,
      transport: shipping_info?.transport,
      pickup: shipping_info?.pickup,
      pick_money: shipping_info?.pick_money,
      note: shipping_info?.note,
      tags: shipping_info?.tags,
    })
  }, [customer, shipping_info])

  return (
    <Form
    form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      style={{ display: 'flex', width:'100%'}}
      onFinish={_edid}
    >
      <Row style={{width:'100%'}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <h3>Thông tin khách hàng</h3>
          <Form.Item label="Tên khách hàng" name="name" style={{ width: '100%' }}>
            <Input />
          </Form.Item>
          <Form.Item label="Số điện thoại" name="SDT">
            <Input />
          </Form.Item>
         
              <Form.Item label="Địa chỉ" name="address">
                <Input />
              </Form.Item>
        
              <Form.Item label="Phường/xã" name="ward">
                <Input />
              </Form.Item>
         
              <Form.Item label="Quận/Huyện" name="district">
                <Input />
              </Form.Item>
         
              <Form.Item label="Tỉnh/TP" name="province">
                <Input />
              </Form.Item>
    
          <h3>Lấy và giao tận nơi</h3>
          <Form.Item name="kgProduct">
            <Radio.Group className={styles['point-bottom-right-radio']}>
              <Radio value={0}>Hàng nhỏ Express (≤20kg)</Radio>
              <Radio value={1}>{`Hàng lớn BBS (>20kg)`}</Radio>
            </Radio.Group>
          </Form.Item>
          <h3>Hình thức giao hàng</h3>
          <Form.Item name="transport">
            <Radio.Group className={styles['point-bottom-right-radio']}>
              <Radio value={0}>Bộ</Radio>
              <Radio value={1}>Hàng không</Radio>
            </Radio.Group>
          </Form.Item>
          <h3>Hình thức lấy hàng</h3>
          <Form.Item name="pickup">
            <Radio.Group className={styles['point-bottom-right-radio']}>
              <Radio value={0}>Lấy hàng tận nơi</Radio>
              <Radio value={1}>Gửi hàng tại bưu cục</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <h3>Thông tin đơn hàng</h3>
          <Form.Item label="Tiền thu hộ" name="pick_money">
            <Input />
          </Form.Item>
          <Form.Item label="Ghi chú" name="note">
            <Input />
          </Form.Item>
          <Form.Item name="tags">
            <Checkbox.Group
              style={{ display: 'flex', flexDirection: 'column'}}
              options={options}
            />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} htmlType="submit" classes="btn__blue-fill" text="Lưu" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
