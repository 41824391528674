import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Tooltip, message, notification, Upload, Popconfirm, Progress } from 'antd'
import localStorage from 'utils/localStorage'
import { CSVLink } from 'react-csv';
import styles from './style.module.scss'
import Cookies from 'js-cookie'
import { getRowsExcel } from 'apis/import-smart-product.js'
const ModalImport = ({
  setProducts,
  valueProductMenu,
  products,
  setDisabledImport,
  disabledImport,
  loading,
  setProductsError,
  productsError
}) => {
  let accessToken = localStorage.getItem('accessToken')

  if (!accessToken) {
    accessToken = Cookies.get('accessToken')
  }
  const [excelRows, setExcelRows] = useState([])
  const [productAdd, setProductAdd] = useState([])
  const [filePath, setFilePath] = useState('')
  const inputFile = useRef()
  const [visible, setVisible] = useState(false)
  const [countProductImport, setCountProductImport] = useState(0)
  const toggle = () => setVisible(!visible)
  const option = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const formatter = new Intl.NumberFormat('en-US', option)
  const headers = [
    { label: "Lỗi", key: "error" },
    { label: "Mã hàng", key: "sku" },
    { label: "Tên hàng", key: "name" },
    { label: "Giá hàng", key: "price" },
    { label: "Giá nhập", key: "import_price" },
    { label: "Số lượng", key: "quantity" },
    { label: "Đơn vị", key: "unit" },
    { label: "Mô tả", key: "note" },
];
  const confirm = () => {
    inputFile.current.click()
  }

  const props = {
    name: 'file',
    action:
      process.env.REACT_APP_API_ENDPOINT_DEV +
      '/import-smart-product/createSmartProduct/upload-excel',
    headers: {
      'X-Ekata-Site': window.location.hostname,
      'X-Ekata-Token': accessToken,
    },
    async onChange(info) {
      var extension = info.file.name.split('.').pop().toLowerCase()
      const allowedExtensions = ['xlsx', 'xls', 'xlsm']
      if (allowedExtensions.includes(extension)) {
        const { status } = info.file
        if (status === 'done') {
          setFilePath(info.file.response.path)
        } else if (status === 'error') {
          message.error(`${info.file.name} tải lên bị lỗi`)
        }
      } else {
        if (info.file.status !== 'removed') {
          notification.warning({ key: 'fail file', message: 'Yêu cầu nhập file đúng định dạng' })
        }
      }
    },
  }
  const handleImport = async () => {
    if (products.length > 1000) {
      return notification.error({
        key: 'valiLengthStore',
        message: 'Chỉ được thêm tối đa 1000 sản phẩm',
      })
    }
    if (productAdd.length + products.length > 1000) {
      return notification.error({
        key: 'valiLengthStore',
        message: 'Chỉ được thêm tối đa 1000 sản phẩm',
      })
    }
    try {
      let newData = [...productAdd]
      if (products.length > 0) {
        for (let item of products) {
          let index = newData.find((e) => e.sku === item.sku || e.name === item.name)
          if (index) {
            let dataFil = newData.filter(
              (item) => item.sku !== index.sku || item.name !== index.name
            )
            newData = dataFil
          }
        }
      }
      setProducts([...products, ...newData])
      setDisabledImport(true)
    } catch (error) {
      console.log(error)
    }
  }
  async function _getRowsExcel(valueProductMenu) {
    try {
      let res = await getRowsExcel(filePath, valueProductMenu, products)
      if (res.status == 200) {
        setProductAdd(res.data.products)
        setProductsError(res.data.productsError)
        setExcelRows(res.data.data)
        setCountProductImport(res.data.data[res.data.data.length - 1].stt)
        toggle()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getRowsExcel(valueProductMenu)
  }, [filePath])

  useEffect(() => {
    if (excelRows.length > 0) {
      handleImport()
    }
  }, [excelRows])

  return (
    <>
      <Upload
        {...props}
        showUploadList={false}
        disabled={loading === true ? true : disabledImport === true ? true : false}
      >
        <Tooltip
          title={
            disabledImport === true ? 'Vui lòng tải lại kho hàng để sử dụng nhập excel mới' : ''
          }
        >
          <Button
            disabled={loading === true ? true : disabledImport === true ? true : false}
            ref={inputFile}
            style={{
              width: 150,
              borderRadius: '10px',
              boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
              transition: 'all .3s ease-in',
              display: 'none',
            }}
          >
            Nhập file Excel
          </Button>
        </Tooltip>
      </Upload>
      {!disabledImport ? (
        <Popconfirm
          placement="topLeft"
          title="Tối đa 1000 sản phẩm trên một lần nhập file"
          onConfirm={confirm}
          okText="Yes"
          cancelText="No"
        >
          <Button
            style={{
              width: 150,
              borderRadius: '10px',
              boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
              transition: 'all .3s ease-in',
            }}
          >
            Nhập file Excel
          </Button>
        </Popconfirm>
      ) : (
        <Button
          style={{
            width: 150,
            borderRadius: '10px',
            boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
            transition: 'all .3s ease-in',
          }}
          disabled={true}
        >
          Nhập file Excel
        </Button>
      )}

      <Modal 
        visible={visible}
        width={800}
        onOk={toggle}
        onCancel={toggle}
        footer={null}
        className={styles['result']}
        >
          <div className={styles['percent']}>
            <Progress type="circle" percent={formatter.format((products.length / countProductImport) * 100)} size={100}/>
            <h1 style={{marginLeft: 5}}>Import thành công <strong>{products.length} / {countProductImport}</strong> sản phẩm</h1>
          </div>
          
          {productsError.length > 0 && <h2>Tải về file sản phẩm không import được</h2>}
          {productsError.length > 0 && <Button
                type="primary"
            >
                <CSVLink
                    headers={headers}
                    data={productsError}
                    filename={'Sản phẩm lỗi'}
                    style={{ "textDecoration": "none", "color": "#fff" }}
                >
                    Tải file lỗi
                </CSVLink>
            </Button>}
      </Modal>
    </>
  )
}

export default ModalImport
