import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, FILTER_SIZE, PERMISSIONS, ACTION } from 'consts'
import { compare, compareCustom, formatCash, removeUnicode, tableSum } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import { ROUTES } from 'consts'
import { useTranslation } from 'react-i18next'
//antd
import {
  Input,
  Button,
  Row,
  DatePicker,
  Table,
  Select,
  Space,
  Col,
  Affix,
  Modal,
  notification,
} from 'antd'

//icons
import { SearchOutlined, PlusCircleOutlined, ToTopOutlined } from '@ant-design/icons'

//components
import PrintOrder from 'components/print/print-order'
import TitlePage from 'components/title-page'
import columnsOrder from './columns'
import FilterDate from 'components/filter-date/filter'

//apis
import { getOrderRefund } from 'apis/order'
import { getStaff } from 'apis/manage-staff'
import { getStatusShipping } from 'apis/shipping'
import Permission from 'components/permission'
import exportToCSV from 'components/ExportCSV/export'
import { DefaultFilterData } from 'utils/DefaultFilterData'

const { RangePicker } = DatePicker
export default function Refund() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const typingTimeoutRef = useRef(null)
  const { t } = useTranslation()
  const [statusOrder, setStatusOrder] = useState([])
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [countOrder, setCountOrder] = useState(0)
  const [sumRefund, setSumRefund] = useState(0)
  const [employees, setEmployees] = useState([])
  const [optionSearchName, setOptionSearchName] = useState('refund_order_code')
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
    // from_date: moment().subtract(30, `days`).format('YYYY-MM-DD'),
    // to_date: moment().format('YYYY-MM-DD'),
  })
  const [valueTime, setValueTime] = useState()
  const [orderShow, setOrderShow] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const _getOrderRefundToExport = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      // let new_params_filter = { ...paramsFilter }
      // delete new_params_filter.page
      // delete new_params_filter.page_size
      // const res = await getOrderRefund({
      //   // ...new_params_filter,
      //   branch_id: branchIdApp,
      //   bill_status: 'REFUND',
      // })

      let dataExport = []
      orderShow.map((e, index) => {
        let objOrderRefund = {
          STT: index + 1,
          'Mã phiếu trả': e.refund_order_code || '',
          'Mã đơn mua': e.code_order || '',
          'Khách hàng':
            e.customer_info?.first_name + e.customer_info?.last_name + '-' + e.customer_info?.phone,
          'Trạng thái nhận hàng': e.is_received ? 'Đã nhận hàng' : 'Chưa nhận hàng',
          'Trạng thái hoàn tiền': e.is_payment
            ? `Đã hoàn tiền (${formatCash(e.payment_amount)})`
            : 'Chưa hoàn tiền',
          'Phương thức thanh toán':
            Array(e.payment_infos.map((item) => item.name)).join(', ') || '',
          'Ngày tạo phiếu': moment(e.create_date).format('DD/MM/YYYY HH:mm'),
          'Nhân viên trả hàng': `${e.creator_info.first_name} ${e.creator_info.last_name}`,
          'Ghi chú': e.note,
        }
        dataExport.push(objOrderRefund)
      })

      exportToCSV(dataExport, 'Danh sách đơn trả hàng','Xuất file excel',dispatch)

    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _onSearch = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter[optionSearchName] = value.trim()
      else delete paramsFilter[optionSearchName]

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _onChangeFilter = (attribute = '', value = '') => {
    paramsFilter.page = 1
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter })
  }
  const _getOrders = async () => {
    try {
      setLoading(true)
      const res = await getOrderRefund({
        branch_id: branchIdApp,
        role_id: dataUser?.role_id
      })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser.role_id, permissions, res.data.data)
        setOrders(result.data)
        setParamsFilter({
          page: 1,
          page_size: PAGE_SIZE,
          from_date: result.defaultTime.from_date,
          to_date: result.defaultTime.to_date,
        })
        setValueTime(result.valueDate)
        setValueSearch('')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) {
        setEmployees(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getStatus = async () => {
    try {
      const res = await getStatusShipping()
      if (res.status === 200) setStatusOrder(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let arr = [...orders]
    const filteredOrder = arr.filter((current) => {
      let matchCreator = true
      let matchTime = true
      let matchSearch = true
      if (paramsFilter.creator_id && paramsFilter.creator_id !== 'all')
        matchCreator = current?.creator_id === paramsFilter.creator_id
      if (paramsFilter.refund_order_code)
        matchSearch = removeUnicode(current.refund_order_code.toString())
          .toLowerCase()
          .trim()
          .includes(removeUnicode(paramsFilter.refund_order_code).toLowerCase().trim())
      if (paramsFilter.code_order) {
        matchSearch = removeUnicode(current.code_order.toString())
          .toLowerCase()
          .trim()
          .includes(removeUnicode(paramsFilter.code_order).toLowerCase().trim())
      }
      if (paramsFilter.customer_name) {
        matchSearch =
          removeUnicode(current?.customer_info?.first_name.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.customer_name).toLowerCase().trim()) ||
          removeUnicode(current?.customer_info?.last_name.toString())
            .toLowerCase()
            .trim()
            .includes(removeUnicode(paramsFilter.customer_name).toLowerCase().trim())
      }
      if (paramsFilter.customer_phone)
        matchSearch = current.customer_info.phone.includes(paramsFilter.customer_phone)

      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date
      return matchCreator && matchTime && matchSearch
    })
    setOrderShow(filteredOrder)
    setCountOrder(filteredOrder.length)
    let sum = 0
    filteredOrder.map((e) => (sum += e.total_refund))
    setSumRefund(sum)
  }, [paramsFilter])
  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setValueSearch('')
    setValueTime('')
  }
  useEffect(() => {
    _getStatus()
  }, [])
  useEffect(() => {
    _getEmployees()
  }, [branchIdApp])

  useEffect(() => {
    _getOrders()
  }, [branchIdApp, dataUser])

  return (
    <div className="card">
        <TitlePage title="Danh sách hóa đơn trả hàng" isAffix>
          <Space>
            <>
              {permissions.includes(PERMISSIONS.export_phieu_tra_hang) ? (
                <Button
                  id="exportFile"
                  style={{ backgroundColor: 'green', borderColor: 'green' }}
                  size="large"
                  onClick={_getOrderRefundToExport}
                  type="primary"
                  icon={<ToTopOutlined />}
                >
                  Export phiếu trả hàng
                </Button>
              ) : (
                <Button disabled size="large" type="primary" icon={<PlusCircleOutlined />}>
                  Export phiếu trả hàng
                </Button>
              )}
            </>
            <>
              {permissions.includes(PERMISSIONS.tao_phieu_tra_hang) ? (
                <Button
                  onClick={() =>
                    history.push({ pathname: ROUTES.REFUND_CREATE, title: 'refund-form' })
                  }
                  size="large"
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Tạo phiếu trả hàng
                </Button>
              ) : (
                <Button
                  disabled
                  onClick={() =>
                    history.push({ pathname: ROUTES.REFUND_CREATE, title: 'refund-form' })
                  }
                  size="large"
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Tạo phiếu trả hàng
                </Button>
              )}
            </>
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_tra_hang) ? (
        <>
            <Row
              gutter={[8, 16]}
              justify="space-between"
              style={{marginTop: 10, marginBottom:10}}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={7}
                xl={7}
              >
                <Input
                  style={{ width: '60%' }}
                  prefix={<SearchOutlined />}
                  name="name"
                  value={valueSearch}
                  onChange={_onSearch}
                  placeholder="Tìm kiếm theo"
                  bordered={true}
                  allowClear
                />
                <Select
                  showSearch
                  size={FILTER_SIZE}
                  style={{ width: '40%', borderLeft: '1px solid #d9d9d9' }}
                  value={optionSearchName}
                  onChange={(value) => {
                    delete paramsFilter[optionSearchName]
                    setOptionSearchName(value)
                  }}
                  bordered={true}
                >
                  <Select.Option value="refund_order_code">Mã đơn trả hàng</Select.Option>
                  <Select.Option value="code_order">Mã đơn hàng</Select.Option>
                  <Select.Option value="customer_name">Tên khách hàng</Select.Option>
                  <Select.Option value="customer_phone">SĐT khách hàng</Select.Option>
                </Select>
              </Col>

              <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={24}
                >
                  <FilterDate
                    size='default'
                    bordered={true}
                    paramsFilter={paramsFilter}
                    setParamsFilter={setParamsFilter}
                    title="tạo đơn trả hàng"
                    valueTime={valueTime}
                    setValueTime={setValueTime}
                  />
                </Col>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={7}
                xl={7}
              >
                <Select
                  value={paramsFilter.creator_id || 'all'}
                  onChange={(value) => _onChangeFilter('creator_id', value)}
                  size={FILTER_SIZE}
                  allowClear
                  filterOption={(input, option) =>
                    removeUnicode(option.children.toString())
                      .toLowerCase()
                      .trim()
                      .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                  }
                  showSearch
                  placeholder="Chọn nhân viên"
                  style={{ width: '100%' }}
                  bordered={true}
                >
                  <Select.Option value="all">Tất cả nhân viên</Select.Option>
                  {employees.map((employee, index) => (
                    <Select.Option value={employee.user_id} key={index}>
                      {employee.first_name} {employee.last_name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                <Button
                  style={{
                    display: Object.keys(paramsFilter).length <= 2 && 'none',
                  }}
                  onClick={onClickClear}
                  type="primary"
                >
                  Xóa tất cả lọc
                </Button>
              </Col>
            </Row>

          <Table
            size="small"
            rowKey="order_refund_id"
            loading={loading}
            scroll={{ y: 500 }}
            columns={columnsOrder.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  align: 'center',

                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  width: 192,
                  align: 'center',
                  sorter: (a, b) => compare(a, b, 'refund_order_code'),
                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.xem_chi_tiet_tra_hang) ? (
                      <Link
                        to={{
                          pathname: ROUTES.REFUND_DETAIL + '/' + record.refund_order_code,
                          state: record,
                        }}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ color: record.status == 200 ? 'green' : 'orange' }}>
                          {' '}
                          {'#' + text}
                        </div>
                      </Link>
                    ) : (
                      <div
                        style={{
                          color: record.status == 200 ? 'green' : 'orange',
                          cursor: 'pointer',
                        }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        {' '}
                        {'#' + text}
                      </div>
                    ),
                }
              if (column.key === 'code_order')
                return {
                  ...column,
                  width: 192,
                  align: 'center',

                  sorter: (a, b) => compare(a, b, 'code_order'),
                  render: (text, record) =>
                    
                      <Link
                        style={{
                          color: '#ff7089',
                        }}
                        to={{ pathname: ROUTES.ORDER_UPDATE + '/' + record.order_id }}
                        target="_blank"
                      >
                        {'#' + record.code_order}
                      </Link>
                    
                }
              if (column.key === 'customer')
                return {
                  ...column,
                  width: 150,
                  align: 'center',

                  render: (text, record) => (
                    <div>
                      <div>
                        {record?.customer_info?.first_name +
                          ' ' +
                          record?.customer_info?.last_name || ''}
                      </div>

                      <a href={`tel:${record?.customer_info?.phone}`}>
                        {record?.customer_info?.phone}
                      </a>
                    </div>
                  ),
                }
              if (column.key === 'refund_status')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) =>
                    record?.is_payment === true ? `Đã hoàn tiền ` : 'Chưa hoàn tiền',
                }
              if (column.key === 'receive_status')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) =>
                    record?.is_received === true ? 'Đã nhận hàng' : 'Chưa nhận hàng',
                }
              if (column.key === 'payment_amount')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) => formatCash(record.payment_amount),
                }

              if (column.key === 'payment_infos')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) =>
                    record?.payment_infos?.map((payment) => (
                      <div>
                        {payment.name}
                        {/* : {formatCash(payment.value)}  */}
                      </div>
                    )),
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) =>
                    record.create_date && moment(record.create_date).format('DD/MM/YYYY HH:mm'),
                  sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                }
              if (column.key === 'employee')
                return {
                  ...column,
                  align: 'center',

                  sorter: (a, b) =>
                    compareCustom(
                      a.creator_info
                        ? `${a.creator_info.first_name} ${a.creator_info.last_name}`
                        : '',
                      a.creator_info
                        ? `${b.creator_info.first_name} ${b.creator_info.last_name}`
                        : ''
                    ),
                  render: (text, record) =>
                    record.creator_info
                      ? `${record.creator_info.first_name} ${record.creator_info.last_name}`
                      : '',
                }
              if (column.key === 'last_update')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) =>
                    record.last_update && moment(record.last_update).format('DD/MM/YYYY HH:mm'),
                }

              if (column.key === 'note')
                return {
                  ...column,
                  align: 'center',

                  render: (text, record) => record.note,
                }
              return column
            })}
            style={{ width: '100%' }}
            pagination={{
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) =>
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
              total: countOrder,
            }}
            dataSource={orderShow}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell>
                    <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                  </Table.Summary.Cell>
                  {columnsOrder.map((e, index) => {
                    if (e.key === 'payment_infos')
                      return (
                        <Table.Summary.Cell>
                          {sumRefund && formatCash(sumRefund)} VND
                        </Table.Summary.Cell>
                      )
                    return <Table.Summary.Cell></Table.Summary.Cell>
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </>
      ) : null}
    </div>
  )
}
