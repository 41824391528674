import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import styles from './product.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { ACTION, ROUTES, PERMISSIONS, IMAGE_DEFAULT, FILTER_SIZE, PAGE_SIZE, TIME_FORMAT } from 'consts'
import { compareCustom, formatCash, compare, removeUnicode } from 'utils'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TableProducts } from './productElement'

import {
  Switch,
  Upload,
  Select,
  notification,
  Button,
  Modal,
  Table,
  Input,
  Row,
  Col,
  Space,
  Popconfirm,
  Tag,
  TreeSelect,
  Badge,
  Affix,
  Popover,
} from 'antd'

//components
import columnsProduct from './columns'
import exportToCSV from 'components/ExportCSV/export'
import TitlePage from 'components/title-page'
import { useTranslation } from 'react-i18next'
import { searchComponentProduct } from 'utils/search'
import removeVietnameseTones from 'utils/removeUnicode'
import FilterDate from 'components/filter-date/filter'
//icons
import {
  PlusCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
  DeleteOutlined,
  PlusCircleFilled,
  CloseCircleFilled,
  ToTopOutlined,
  DownloadOutlined,
  EditFilled,
  SearchOutlined,
} from '@ant-design/icons'

//apis
import { getSuppliers } from 'apis/supplier'
import { getCategories } from 'apis/category'
import {
  getProducts,
  getListProducts,
  updateProduct,
  deleteProducts,
  updateSellNormalProduct,
  updateProductOnline,
} from 'apis/product'
import { uploadFile, uploadFiles } from 'apis/upload'
import { Fragment } from 'react'
import { DefaultFilterData } from 'utils/DefaultFilterData'

const { Option } = Select

const initialTodos = [] // same as above
const reducer = (state, action) => {
  switch (action.type) {
    case 'PRODUCT_ID':
      let indexState = state.findIndex((item) => item.page == action.page)
      let arrTmp = action.productIds.filter((item) => action.id.includes(item))
      if (indexState !== -1) {
        state[indexState].data = [...arrTmp]
      } else {
        let object_data = {
          page: action.page,
          data: arrTmp,
        }
        state.push(object_data)
      }
      return state
    case 'SET_PRODUCT_ID':
      return []
    default:
      return state
  }
}
export default function Product() {
  const [todos, dispatchId] = React.useReducer(reducer, initialTodos)

  const typingTimeoutRef = useRef(null)
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [productIDS, setProductIDS] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({})
  const [suppliers, setSuppliers] = useState([])
  const [products, setProducts] = useState([])
  const [productShow, setProductShow] = useState([])
  const [totalProducts, setTotalProducts] = useState({ total_inventory: 0, open_sell_quantity: 0 })
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) //list checkbox row, key = _id
  const [categories, setCategories] = useState([])
  const [valueSearch, setValueSearch] = useState('')
  const [countProduct, setCountProduct] = useState(0)
  // const [valueTime, setValueTime] = useState()
  const _getCategories = async () => {
    try {
      const res = await getCategories()
      if (res.status === 200) setCategories(res.data.data.filter((e) => e.active))
    } catch (error) {
      console.log(error)
    }
  }
  const _onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const lockProductNormal = async (checked, id) => {
    try {
      const body = {
        checked: checked,
      }
      let res = await updateSellNormalProduct(body, id)
      if (res.status === 200)
        notification.success({
          key: 'success_normal',
          message: res.data.message || 'Cập nhật thành công',
        })
      else notification.error({ key: 'error_normal', message: 'Cập nhật thất bại' })
    } catch (error) {
      console.log(error)
    }
  }

  const columnsVariant = [
    {
      title: 'Hình ảnh',
      key: 'image',
      align: 'center',
      width: 60,
    },
    {
      title: 'Thuộc tính',
      dataIndex: 'title',
      align: 'center',
      width: 160,
    },
    {
      title: 'Mã SKU',
      dataIndex: 'sku',
      align: 'center',
      render: (text, record) => <Tag color="geekblue">{record.sku}</Tag>,
      width: 250,
    },
    {
      title: 'Giá bán lẻ',
      render: (text, record) => formatCash(record.price || 0),
      width: 150,
    },
    {
      title: 'Có thể bán',
      render: (text, record) => formatCash(record.total_quantity || 0),
      width: 80,
    },
    {
      title: 'Số lượng tồn',
      align: 'center',
      render: (text, record) => formatCash(record.total_quantity || 0),
      width: 80,
    },
  ]

  const _getSuppliers = async () => {
    try {
      setLoading(true)
      const res = await getSuppliers()
      if (res.status === 200) setSuppliers(res.data.data)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const onSelectChange = (selectedRowKey) => {
    dispatchId({
      type: 'PRODUCT_ID',
      id: selectedRowKey,
      productIds: productIDS,
      page: paramsFilter.page,
    })
    setSelectedRowKeys(selectedRowKey)
  }
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value.trim()
      if (value) paramsFilter.search = value
      else delete paramsFilter.search
      setParamsFilter({ ...paramsFilter })
    }, 200)
  }

  const _getProductsToExport = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      // let new_params_filter = { ...paramsFilter }
      //   delete new_params_filter.page
      //   delete new_params_filter.page_size
      //   const res = await getProducts({ branch_id: branchIdApp, branch: true, ...new_params_filter })
      //   if (res.status === 200) {
      let dataExport = []
      productShow.map((e) => {
        const findCategory = categories.find((c) => e.category_id.includes(c.category_id))
        const findSupplier = suppliers.find((s) => s.supplier_id === e.supplier_id)
        let objProduct = {
          'Tên sản phẩm': e.name || '',
          'Mã sản phẩm': e.sku || '',
          'Bar code': e.barcode || '',
          'Nhóm sản phẩm': findCategory ? findCategory.name : '',
          'Nhà cung cấp': findSupplier ? findSupplier.name : '',
          'Loại tồn kho': e.inventory == 'advanced' ? 'Nâng cao' : 'Cơ bản',
          'Cân nặng': e.weight,
          'Đơn vị': e.unit,
          Thuế: 'Có',
          'Bảo hành': e.warranties && e.warranties.length ? 'Có' : 'Không',
          'Tình trạng': 'Mới',
          'Mô tả': e.description !== '' ? e.description : 'Chưa có mô tả',
          'Trạng thái': e.active ? 'Mở bán' : 'Ngừng bán',
          'Số lượng sản phẩm': e.open_sell_quantity || 0,
          'Giá nhập': e.import_price || 0,
          'Giá bán lẻ': e.price || 0,
          'Ngày tạo': moment(e.create_date).format(TIME_FORMAT.DATE_TIME),
          'Ngày cập nhật': moment(e.last_update).format(TIME_FORMAT.DATE_TIME)

        }

        e.variants.map((v) => {
          dataExport.push({
            ...objProduct,
            'Tên phiên bản': v?.title || '',
            'Mã phiên bản': v?.sku || '',
            'Giá bán lẻ': v?.price || 0,
            'Giá bán sỉ': v?.whole_sale_price || 0,
            'Giá nhập': v?.import_price || 0,
            'Số lượng': v?.total_quantity || 0,
            'Thuộc tính - Giá trị': v?.options
              ? v?.options[0]?.name + ' - ' + v?.options[0]?.value || ''
              : '',
          })
        })
      })
      exportToCSV(dataExport, 'Danh sách sản phẩm', 'Xuất file excel',dispatch)
      // }
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const checkLinkImageEnbale = async (link) => {
    try {
      await fetch(link)
      return link
    } catch (err) {
      return IMAGE_DEFAULT
    }
  }
  const _getProducts = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getListProducts({
        fix_price_histories: true,
        branch: true,
        branch_id: branchIdApp,
        role_id: dataUser?.role_id
      })
      if (res.status === 200) {
        setProductIDS(res.data.data.map((e) => e.product_id))
        setProducts(res.data.data)
        setParamsFilter({
          page: 1,
          page_size: PAGE_SIZE,
        })
        setValueSearch('')
        dispatchId({
          type: 'SET_PRODUCT_ID'
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    _getProducts()
  }, [branchIdApp, dataUser])

  useEffect(() => {
    _getSuppliers()
    _getCategories()
  }, [])
  const UpdateCategoryProducts = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [categoryIds, setCategoryIds] = useState([])

    useEffect(() => {
      if (!visible) setCategoryIds([])
    }, [visible])

    return (
      <Fragment key={Math.random()}>
        <>
          {permissions.includes(PERMISSIONS.cap_nhat_san_pham) ? (
            <Button size="large" onClick={toggle} type="primary">
              Cập nhật nhóm sản phẩm
            </Button>
          ) : (
            <Button size="large" onClick={toggle} disabled type="primary">
              Cập nhật nhóm sản phẩm
            </Button>
          )}
        </>

        <Modal
          title="Cập nhật nhóm sản phẩm"
          centered
          width={500}
          footer={null}
          visible={visible}
          onCancel={toggle}
        >
          <TreeSelect
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            allowClear
            multiple
            treeDefaultExpandAll
            size="large"
            style={{ width: '100%', marginBottom: 30 }}
            placeholder="Chọn nhóm sản phẩm"
            treeNodeFilterProp="title"
            onChange={(value) => {
              // const data = value.filter((item) => item)
              setCategoryIds(value)
            }}
            value={categoryIds}
          >
            {categories.map((category) => (
              <TreeSelect.TreeNode value={category.category_id} title={category.name}>
                {category.children_category.map((child) => (
                  <TreeSelect.TreeNode value={child.category_id} title={child.name}>
                    {child.children_category &&
                      child.children_category.map((e) => (
                        <TreeSelect.TreeNode value={e.category_id} title={e.name}>
                          {e.name}
                        </TreeSelect.TreeNode>
                      ))}
                  </TreeSelect.TreeNode>
                ))}
              </TreeSelect.TreeNode>
            ))}
          </TreeSelect>
          <Row justify="end">
            <Button
              onClick={async () => {
                try {
                  setLoading(true)

                  const listPromise = todos
                    .reduce((arr, index) => arr.concat(index.data), [])
                    .map(async (product_id) => {
                      const res = await updateProduct({ category_id: categoryIds }, product_id)
                      return res
                    })

                  await Promise.all(listPromise)
                  setLoading(false)
                  toggle()
                  await _getProducts()
                  notification.success({ message: `Cập nhật sản phẩm thành công!` })
                } catch (error) {
                  setLoading(false)
                  toggle()
                  console.log(error)
                }
              }}
              type="primary"
              size="large"
              disabled={categoryIds.length !== 0 ? false : true}
            >
              Cập nhật
            </Button>
          </Row>
        </Modal>
      </Fragment>
    )
  }

  const _deleteProduct = async (product_id) => {
    try {
      setLoading(true)
      const res = await deleteProducts({ product_id: product_id, branch_id: branchIdApp })
      if (res.status === 200) {
        if (res.data.success) {
          _getProducts()
          notification.success({ message: 'Xoá sản phẩm thành công!' })
        } else
          notification.error({
            message: res.data.message || 'Xoá sản phẩm thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xoá sản phẩm thất bại, vui lòng thử lại!',
        })

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const ModalImagesProduct = ({ product }) => {
    const [visible, setVisible] = useState(false)
    const [imagesProduct, setImagesProducts] = useState(product.images || [])
    const toggle = () => setVisible(!visible)
    const [loadingUpload, setLoadingUpload] = useState(false)

    const updateImageProduct = async (body) => {
      try {
        setLoadingUpload(true)
        let res = await updateProduct(body, product.product_id)
        setLoadingUpload(false)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Cập nhật hình ảnh thành công!', duration: 1 })
          } else
            notification.error({
              message: res.data.message || 'Cập nhật hình ảnh thất bại, vui lòng thử lại!',
            })
        } else
          notification.error({
            message: res.data.message || 'Cập nhật hình ảnh thất bại, vui lòng thử lại!',
          })
      } catch (error) {
        console.log(error)
        setLoadingUpload(false)
      }
    }

    return (
      <Fragment key={Math.random()}>
        <Popover
          placement="right"
          content={
            <img
              src={imagesProduct.length ? imagesProduct[0] || IMAGE_DEFAULT : IMAGE_DEFAULT}
              alt=""
              style={{ width: 300, height: 300}}
            />
          }
        >
          <img
            src={imagesProduct.length ? imagesProduct[0] || IMAGE_DEFAULT : IMAGE_DEFAULT}
            alt=""
            style={{ width: 55, height: 55, objectFit: 'cover', cursor: 'pointer' }}
            onClick={toggle}
          />
        </Popover>
        <Modal
          title="Danh sách hình ảnh sản phẩm"
          visible={visible}
          footer={false}
          onCancel={() => {
            toggle()
            _getProducts()
          }}
          width="90%"
          style={{ top: 20 }}
        >
          <Space size="large" wrap={true} style={{ width: '100%', marginBottom: 0 }}>
            {imagesProduct.map((image) => (
              <Badge
                count={
                  <CloseCircleFilled
                    onClick={() => {
                      const indexImage = imagesProduct.findIndex((img) => img === image)
                      if (indexImage !== -1) {
                        const imagesNew = [...imagesProduct]
                        imagesNew.splice(indexImage, 1)
                        setImagesProducts([...imagesNew])
                        updateImageProduct({ images: imagesNew })
                      }
                    }}
                    style={{ fontSize: 25, color: '#ff4d4f', cursor: 'pointer' }}
                  />
                }
              >
                <img src={image} alt="" style={{ width: 170, height: 170, objectFit: 'cover' }} />
              </Badge>
            ))}
            <Upload
              fileList={[]}
              multiple
              listType="picture-card"
              showUploadList={false}
              className={styles['product-upload-img']}
              onChange={(file) => {
                const isLt2M = file.file.size / 1024 / 1024 < 2
                const isJpgOrPng =
                  file.file.type === 'image/jpeg' ||
                  file.file.type === 'image/png' ||
                  file.file.type === 'image/jpg'

                if (!isJpgOrPng) {
                  notification.error({
                    message: 'Chỉ được sử dụng hình ảnh định dạng JPG,JPEG,PNG',
                  })
                  return
                }

                if (!isLt2M) {
                  notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
                  return
                }

                if (typingTimeoutRef.current) {
                  clearTimeout(typingTimeoutRef.current)
                }
                typingTimeoutRef.current = setTimeout(async () => {
                  setLoadingUpload(true)
                  const urls = await uploadFiles(file.fileList.map((e) => e.originFileObj))
                  setLoadingUpload(false)
                  if (urls) {
                    const imagesNew = [...imagesProduct, ...urls]
                    updateImageProduct({ images: imagesNew })
                    setImagesProducts([...imagesNew])
                  }
                }, 450)
              }}
            >
              <div>
                {loadingUpload ? (
                  <LoadingOutlined />
                ) : (
                  <PlusCircleFilled style={{ color: 'rgba(128, 128, 128, 0.3)', fontSize: 20 }} />
                )}
                <div style={{ marginTop: 8, color: '#808080' }}>Tải lên</div>
              </div>
            </Upload>
          </Space>
        </Modal>
      </Fragment>
    )
  }

  const ImagesVariant = ({ record, product }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [images, setImages] = useState([])
    const [imagesView, setImagesView] = useState([])

    const [loading, setLoading] = useState(false)

    const addFile = async (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
        return
      }

      if (isLt2M) {
        setLoading(true)
        const url = await uploadFile(file)
        setImages([...images, url])
        const fileNames = url.split('/')
        const fileName = fileNames[fileNames.length - 1]
        setImagesView([
          ...imagesView,
          { uid: imagesView.length, name: fileName, status: 'done', url: url, thumbUrl: url },
        ])
        setLoading(false)
      }
    }

    const _removeFile = (file) => {
      const imagesNew = [...images]
      const imagesViewNew = [...imagesView]

      const indexImage = images.findIndex((url) => url === file.url)
      const indexImageView = imagesView.findIndex((f) => f.url === file.url)

      if (indexImage !== -1) imagesNew.splice(indexImage, 1)
      if (indexImageView !== -1) imagesViewNew.splice(indexImageView, 1)

      setImages([...imagesNew])
      setImagesView([...imagesViewNew])
    }

    useEffect(() => {
      if (visible) {
        setImages(record.image || [])
        setImagesView(
          record.image
            ? record.image.map((image, index) => {
                const fileNames = image.split('/')
                const fileName = fileNames[fileNames.length - 1]
                return { uid: index, name: fileName, status: 'done', url: image, thumbUrl: image }
              })
            : []
        )
      }
    }, [visible])

    return (
      <>
        <div onClick={toggle} className={styles['variant-image']}>
          {record.image && record.image.length ? (
            <Popover
              placement="right"
              content={
                <img
                  src={record.image.length ? record.image[0] : IMAGE_DEFAULT}
                  alt=""
                  style={{ width: 300, height: 300 }}
                />
              }
            >
              <img
                src={record.image[0] || IMAGE_DEFAULT}
                alt=""
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </Popover>
          ) : (
            <img src={IMAGE_DEFAULT} alt="" style={{ width: '100%' }} />
          )}
        </div>
        <Modal
          footer={
            <Row justify="end">
              <Space>
                <Button style={{ minWidth: 100 }} onClick={toggle}>
                  Đóng
                </Button>
                <Button
                  onClick={async () => {
                    const body = {
                      variants: product.variants.map((e) => {
                        if (e.variant_id === record.variant_id) return { ...e, image: images }
                        return e
                      }),
                    }
                    await _updateProduct(body, product.product_id)
                    toggle()
                  }}
                  style={{ minWidth: 100 }}
                  type="primary"
                >
                  Lưu
                </Button>
              </Space>
            </Row>
          }
          width={700}
          onCancel={toggle}
          title="Cập nhật hình ảnh"
          visible={visible}
        >
          <Upload.Dragger
            accept="image/jpeg, image/png, image/jpg"
            fileList={imagesView}
            listType="picture"
            data={addFile}
            onRemove={_removeFile}
            name="file"
            onChange={(info) => {
              if (info.file.status !== 'done') info.file.status = 'done'
            }}
          >
            <p className="ant-upload-drag-icon">
              {loading ? <LoadingOutlined /> : <InboxOutlined />}
            </p>
            <p className="ant-upload-text">Nhấp hoặc kéo thả vào khu vực này để tải lên</p>
            <p className="ant-upload-hint">Hỗ trợ hình ảnh .PNG, .JPG,...</p>
          </Upload.Dragger>
        </Modal>
      </>
    )
  }

  const onClickClear = async () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: PAGE_SIZE })
    setSelectedRowKeys([])
    // setValueTime()
  }
  const onChangeCategoryValue = (id) => {
    if (id.length > 0) {
      let index = id.findIndex((item) => item === -1)
      if (index !== -1) {
        if (index === 0) {
          id.splice(index, 1)
        } else {
          id = []
        }
      }
    } else {
      id = []
    }
    if (id) paramsFilter.category_id = id
    else delete paramsFilter.category_id

    setParamsFilter({ ...paramsFilter })
  }
  const _updateProduct = async (body, id) => {
    try {
      setLoading(true)
      let res = await updateProduct(body, id)
      if (res.status === 200) {
        if (res.data.success) notification.success({ message: 'Cập nhật thành công!', duration: 1 })
        else
          notification.error({
            message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
        })

      await _getProducts()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _updateProductOnline = async (body, id) => {
    try {
      setLoading(true)
      let res = await updateProductOnline(body, id)
      if (res.status === 200) {
        if (res.data.success) notification.success({ message: 'Cập nhật thành công!', duration: 1 })
        else
          notification.error({
            message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
        })

      await _getProducts()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    let arr = [...products]
    const filtered = arr.filter((current) => {
      let matchCategory = true
      let matchSupllier = true
      let matchStatus = true
      let matchTime = true
      let matchSearch = true
      if (paramsFilter.supplier_id && paramsFilter.supplier_id !== 'all')
        matchSupllier = current?.supplier_id === paramsFilter.supplier_id
      if (paramsFilter.category_id && paramsFilter.category_id.length > 0) {
        let check = []
        for (let i in paramsFilter.category_id) {
          if (current?.category_id.includes(paramsFilter.category_id[i])) {
            check.push(paramsFilter.category_id[i])
          }
        }
        if (check.length === 0) matchCategory = false
      }
      matchStatus = current.is_delete === false
      if (paramsFilter.status) {
        switch (paramsFilter.status) {
          case 'all': {
            matchStatus = true
            break
          }
          case 'active': {
            matchStatus = current.active === true && current.is_delete === false
            break
          }
          case 'active_online': {
            matchStatus = current.active_online === true && current.is_delete === false
            break
          }
          case 'unactive': {
            matchStatus = current.active === false && current.is_delete === false
            break
          }
          case 'pre-order': {
            matchStatus = current.is_pre_order === true && current.is_delete === false
            break
          }
          case 'is_delete': {
            matchStatus = current.is_delete === true
            break
          }
          default:
            matchStatus = true
        }
      }
      if (paramsFilter.search) {
        let newObject = {
          name: current.name,
          sku: current.sku,
          barcode: current.barcode,
          price: current.price,
          description: current.description,
        }
        // let regex = new RegExp(
        //   removeUnicode(paramsFilter.search).toLowerCase().trim().replace(/\s+/g, '|'),
        //   'i'
        // )
        // console.log(regex);
        newObject = Object.values(newObject).join(' ').toLocaleLowerCase()
        let searchKeywords = paramsFilter.search.split(' ');
        // matchSearch = regex.test(removeUnicode(newObject).toLowerCase().trim())
        // matchSearch = removeUnicode(newObject.toString())
        //   .toLowerCase()
        //   .trim()
        //   .indexOf(removeUnicode(paramsFilter.search).toLowerCase().trim()) !== -1
        for (let keyword of searchKeywords) {
          if (removeUnicode(newObject).trim().indexOf(removeUnicode(keyword).toLowerCase()) === -1) {
              matchSearch = false;
              break;
          }
      }
      }

      if (paramsFilter.to_date)
        matchTime =
          moment(current.create_date).unix() >= paramsFilter.from_date &&
          moment(current.create_date).unix() <= paramsFilter.to_date
      return matchCategory && matchSearch && matchTime && matchSupllier && matchStatus
    })
    setProductShow(filtered)
    setCountProduct(filtered.length)
    let total = filtered.reduce((sum, item) => {
      sum += item.total_inventory
      return sum
    }, 0)
    let totalObject = { total_inventory: total, open_sell_quantity: total }
    setTotalProducts(totalObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, valueSearch])

  return (
    <>
      <div className="card">
          <TitlePage title="Danh sách sản phẩm" isAffix>
            <Space>
              {Object.keys(paramsFilter).length >= 1 || valueSearch !== '' ? (
                <Button size="large" onClick={onClickClear} type="primary" danger>
                  Xóa tất cả lọc
                </Button>
              ) : null}

              <>
                {permissions.includes(PERMISSIONS.export_san_pham) ? (
                  <Button
                    id="exportFile"
                    style={{ backgroundColor: 'green', borderColor: 'green' }}
                    size="large"
                    onClick={_getProductsToExport}
                    type="primary"
                    icon={<ToTopOutlined />}
                  >
                    Export sản phẩm
                  </Button>
                ) : (
                  <Button disabled icon={<DownloadOutlined />} type="primary" size="large">
                    Export sản phẩm
                  </Button>
                )}
              </>
              <>
                {permissions.includes(PERMISSIONS.them_san_pham) ? (
                  <>
                    <Link to="/import-products">
                      <Button icon={<DownloadOutlined />} type="primary" size="large">
                        Nhập sản phẩm
                      </Button>
                    </Link>
                    <Link to={ROUTES.PRODUCT_ADD}>
                      <Button size="large" type="primary" icon={<PlusCircleOutlined />}>
                        Thêm sản phẩm
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/import-products">
                      <Button disabled icon={<DownloadOutlined />} type="primary" size="large">
                        Nhập sản phẩm
                      </Button>
                    </Link>
                    <Link to={ROUTES.PRODUCT_ADD}>
                      <Button disabled size="large" type="primary" icon={<PlusCircleOutlined />}>
                        Thêm sản phẩm
                      </Button>
                    </Link>
                  </>
                )}
              </>
            </Space>
          </TitlePage>
        {permissions.includes(PERMISSIONS.xem_danh_sach_san_pham) ? (
          <>
            <Row gutter={[0, 16]} justify="space-between" style={{ marginTop: 10 }}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
              >
                <Input
                  style={{ width: '100%' }}
                  name="name"
                  size={FILTER_SIZE}
                  value={valueSearch}
                  onChange={onSearch}
                  prefix={<SearchOutlined />}
                  placeholder="Tìm kiếm sản phẩm"
                  allowClear
                  bordered={true}
                />
              </Col>

              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row gutter={[0, 20]}>
                  <Col xs={24} sm={24} md={24} lg={6} xl={8}>
                    <Select
                      size={FILTER_SIZE}
                      showSearch
                      style={{
                        width: '100%',
                      }}
                      filterOption={(inputValue, option) =>
                        removeVietnameseTones(option.props.children)
                          .toLowerCase()
                          .includes(removeVietnameseTones(inputValue).toLowerCase().trim())
                      }
                      placeholder="Lọc theo nhà cung cấp"
                      optionFilterProp="children"
                      bordered={true}
                      defaultValue="all"
                      value={paramsFilter.supplier_id || 'all'}
                      onChange={(value) => _onFilter('supplier_id', value)}
                    >
                      <Select.Option value="all">Tất cả nhà cung cấp</Select.Option>
                      {suppliers.map((supplier, index) => (
                        <Option key={supplier.supplier_id} value={supplier.supplier_id}>
                          {supplier.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={8}>
                    <Select
                      showSearch
                      size={FILTER_SIZE}
                      style={{width: '100%' }}
                      placeholder="Lọc theo trạng thái"
                      optionFilterProp="children"
                      bordered={true}
                      defaultValue="all"
                      value={paramsFilter.status || 'all'}
                      filterOption={(inputValue, option) =>
                        removeVietnameseTones(option.props.children)
                          .toLowerCase()
                          .includes(removeVietnameseTones(inputValue).toLowerCase().trim())
                      }
                      onChange={(value) => {
                        _onFilter('status', value)
                      }}
                    >
                      <Option value="all">Tất cả trạng thái</Option>
                      <Option value="active">Mở bán</Option>
                      <Option value="active_online">Mở bán online</Option>
                      <Option value="unactive">Ngừng bán</Option>
                      <Option value="pre-order">Pre-order</Option>
                      <Option value="is_delete">Đã xóa</Option>
                    </Select>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={8}
                  >
                    <TreeSelect
                      style={{
                        width: '100%'
                      }}
                      placeholder="Lọc theo nhóm sản phẩm "
                      allowClear
                      multiple
                      size={FILTER_SIZE}
                      treeNodeFilterProp="title"
                      treeDefaultExpandAll
                      bordered={true}
                      maxTagCount="responsive"
                      value={paramsFilter.category_id || -1}
                      defaultValue={-1}
                      onChange={onChangeCategoryValue}
                    >
                      <TreeSelect.TreeNode value={-1} title="Tất cả nhóm">
                        Tất cả nhóm
                      </TreeSelect.TreeNode>

                      {categories.map((category, index) => (
                        <TreeSelect.TreeNode
                          value={category.category_id}
                          title={category.name}
                          key={index}
                        >
                          {category.children_category.map((child) => (
                            <TreeSelect.TreeNode value={child.category_id} title={child.name}>
                              {child.children_category &&
                                child.children_category.map((e) => (
                                  <TreeSelect.TreeNode value={e.category_id} title={e.name}>
                                    {e.name}
                                  </TreeSelect.TreeNode>
                                ))}
                            </TreeSelect.TreeNode>
                          ))}
                        </TreeSelect.TreeNode>
                      ))}
                    </TreeSelect>
                  </Col>
                  {/* <Col
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '0px 5px 5px 0px',
                }}
              >
                <FilterDate
                  style={{ marginTop: 3 }}
                  bordered={false}
                  paramsFilter={paramsFilter}
                  setParamsFilter={setParamsFilter}
                  valueTime={valueTime}
                  setValueTime={setValueTime}
                  title="tạo sản phẩm"
                />
              </Col> */}
                </Row>
              </Col>
            </Row>

            <Row justify="space-between" style={{ width: '100%', marginTop: 10}}>
              <Space
                size="middle"
                style={{
                  display:
                    !todos.reduce((arr, index) => arr.concat(index.data), []).length && 'none',
                }}
              >
                <>
                  {permissions.includes(PERMISSIONS.cap_nhat_san_pham) ? (
                    <UpdateCategoryProducts />
                  ) : (
                    <Button size="large" type="primary" disabled>
                      Cập nhật nhóm sản phẩm
                    </Button>
                  )}
                </>
                <>
                  {permissions.includes(PERMISSIONS.them_phieu_nhap_hang) ? (
                    <Button
                      size="large"
                      type="primary"
                      onClick={() =>
                        history.push(
                          `${ROUTES.IMPORT_INVENTORY_CREATE}?product_ids=${todos
                            .reduce((arr, index) => arr.concat(index.data), [])
                            .join('--')}`
                        )
                      }
                    >
                      Nhập hàng SP đã chọn
                    </Button>
                  ) : (
                    <Button size="large" type="primary" disabled>
                      Nhập hàng SP đã chọn
                    </Button>
                  )}
                </>

                <>
                  {permissions.includes(PERMISSIONS.in_ma_san_pham) ? (
                    <Button
                      size="large"
                      type="primary"
                      onClick={() =>
                        history.push(
                          `${ROUTES.PRODUCT_BARCODE}?product_ids=${todos
                            .reduce((arr, index) => arr.concat(index.data), [])
                            .join('--')}`
                        )
                      }
                    >
                      In mã vạch
                    </Button>
                  ) : (
                    <Button size="large" type="primary" disabled>
                      In mã vạch
                    </Button>
                  )}
                </>
              </Space>
            </Row>

            <TableProducts
              className={styles['product-columns']}
              rowSelection={{
                selectedRowKeys: todos.reduce((arr, index) => arr.concat(index.data), []),
                onChange: onSelectChange,
              }}
              rowKey="product_id"
              scroll={{ y: '35em' }}
              expandable={{
                expandedRowRender: (record, index, indent, expanded) => {
                  if (record.variants.length > 1) {
                    return (
                      <Table
                        key={index}
                        style={{ maxWidth: '54%', marginLeft: 32 }}
                        className={`${styles['variant-columns']} table-child`}
                        showHeader={false}
                        pagination={false}
                        columns={columnsVariant.map((column) => {
                          if (column.key === 'image')
                            return {
                              ...column,
                              render: (text, variant) => (
                                <ImagesVariant
                                  key={Math.random()}
                                  record={variant}
                                  product={record}
                                />
                              ),
                            }
                          return column
                        })}
                        dataSource={record.variants}
                        size="small"
                      />
                    )
                  }
                },
                expandIconColumnIndex: -1,
                expandRowByClick: true,
              }}
              columns={columnsProduct.map((column) => {
                if (column.key === 'stt')
                  return {
                    ...column,
                    render: (text, record, index) => index + 1,
                  }
                if (column.key === 'image')
                  return {
                    ...column,
                    render: (text, record) => (
                      <ModalImagesProduct key={Math.random()} product={record} />
                    ),
                  }
                if (column.key === 'name-product')
                  return {
                    ...column,
                    render: (text, record) =>
                      permissions.includes(PERMISSIONS.chi_tiet_san_pham) ? (
                        <Link
                          to={{
                            pathname: ROUTES.PRODUCT_UPDATE + '/' + record.sku,
                          }}
                        >
                          {text}
                        </Link>
                      ) : (
                        <div
                          style={{ color: '#5F73E2', cursor: 'pointer' }}
                          onClick={() => notification.warning({ message: 'Permission denied!' })}
                        >
                          {text}
                        </div>
                      ),
                    sorter: (a, b) => compare(a, b, 'name'),
                  }

                if (column.key === 'sku_1')
                  return {
                    ...column,
                    render: (text, record) =>
                      record?.variants?.length === 1 ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <p>{record?.variants[0]?.sku}</p>
                        </div>
                      ) : (
                        <p>{record?.variants?.length} Phiên bản</p>
                      ),
                  }

                if (column.key === 'cost_1')
                  return {
                    ...column,
                    render: (text, record) => <p>{formatCash(record.price || 0)}</p>,
                  }
                if (column.key === 'open_sell_quantity')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.inventory === 'advanced' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {formatCash(record.open_sell_quantity || 0)}
                        </div>
                      ) : (
                        <>
                          <Switch
                            disabled={
                              permissions.includes(PERMISSIONS.cap_nhat_san_pham) &&
                              !record.is_delete
                                ? false
                                : true
                            }
                            defaultChecked={record.is_open}
                            onChange={(e) => lockProductNormal(e, record.product_id)}
                          />
                        </>
                      ),
                    sorter: (a, b) => compare(a, b, 'open_sell_quantity', Number),
                  }

                if (column.key === 'total_inventory')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.inventory === 'advanced' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {formatCash(record.total_inventory || 0)}
                        </div>
                      ) : (
                        <></>
                      ),
                    sorter: (a, b) => compare(a, b, 'total_inventory', Number),
                  }

                if (column.key === 'create_date')
                  return {
                    ...column,
                    sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
                    render: (text, record) =>
                      record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
                  }
                if (column.key === 'description')
                  return {
                    ...column,
                    sorter: (a, b) => compare(a, b, 'description'),
                    render: (text, record) => record.description,
                  }
                if (column.key === 'active')
                  return {
                    ...column,
                    render: (text, record) => (
                      <Space size="middle">
                        <Popover
                          content={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: '7em',
                              }}
                            >
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <div style={{ marginRight: 10 }}>Mở bán Online</div>
                                  <Switch
                                    disabled={
                                      permissions.includes(PERMISSIONS.cap_nhat_san_pham)
                                        ? false
                                        : true
                                    }
                                    checked={record.active_online || false}
                                    onClick={() => {
                                      let checkLengthImg = record.images.filter(
                                        (item) => item?.length > 0
                                      )
                                      if (record.active_online === false) {
                                        if (checkLengthImg.length < 1) {
                                          notification.error({
                                            key: 'error img',
                                            message:
                                              'Thông tin ảnh yêu cầu bán online là 1 ảnh và đầy đủ mô tả',
                                          })
                                        } else {
                                          _updateProductOnline(
                                            {
                                              active_online: !record.active_online,
                                              branch_id: branchIdApp,
                                            },
                                            record.product_id
                                          )
                                        }
                                      } else {
                                        _updateProductOnline(
                                          {
                                            active_online: !record.active_online,
                                            branch_id: branchIdApp,
                                          },
                                          record.product_id
                                        )
                                      }
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <div style={{ marginRight: 10 }}>Mở bán</div>
                                  <Switch
                                    disabled={
                                      permissions.includes(PERMISSIONS.mo_ban_san_pham)
                                        ? false
                                        : true
                                    }
                                    checked={record.active}
                                    onClick={() =>
                                      _updateProduct(
                                        { active: !record.active, branch_id: branchIdApp },
                                        record.product_id
                                      )
                                    }
                                  />
                                </div>
                                {/* <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}
                            >
                              <div style={{ marginRight: 10 }}>Pre-order</div>
                              <Switch
                                disabled={
                                  permissions.includes(PERMISSIONS.cap_nhat_san_pham) ? false : true
                                }
                                checked={record.is_pre_order}
                                onClick={() => {
                                  _updateProduct(
                                    {
                                      is_pre_order: !record.is_pre_order,
                                      branch_id: branchIdApp,
                                    },
                                    record.product_id
                                  )
                                }}
                              />
                            </div> */}
                                {!record.is_delete ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <div style={{ marginRight: 10 }}>Xóa sản phẩm</div>
                                    {permissions.includes(PERMISSIONS.xoa_san_pham) ? (
                                      <Popconfirm
                                        title="Bạn có muốn xóa sản phẩm này không?"
                                        okText="Đồng ý"
                                        cancelText="Từ chối"
                                        onConfirm={() => _deleteProduct(record.product_id)}
                                      >
                                        <Button
                                          disabled={
                                            permissions.includes(PERMISSIONS.xoa_san_pham)
                                              ? false
                                              : true
                                          }
                                          icon={<DeleteOutlined />}
                                          type="primary"
                                          danger
                                        />
                                      </Popconfirm>
                                    ) : (
                                      <Button
                                        disabled={
                                          permissions.includes(PERMISSIONS.xoa_san_pham)
                                            ? false
                                            : true
                                        }
                                        icon={<DeleteOutlined />}
                                        type="primary"
                                        danger
                                      />
                                    )}
                                  </div>
                                ) : null}
                              </>
                            </div>
                          }
                          title="Chọn thao tác"
                        >
                          <div style={{ cursor: 'pointer' }}>Thao tác</div>
                          <EditFilled style={{ cursor: 'pointer' }} size={20} />
                        </Popover>
                      </Space>
                    ),
                  }
                return column
              })}
              loading={loading}
              dataSource={productShow}
              size="small"
              pagination={{
                position: ['bottomLeft'],
                current: paramsFilter.page,
                pageSize: paramsFilter.page_size,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                showQuickJumper: true,
                onChange: (page, pageSize) =>
                  setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
                total: countProduct,
              }}
              summary={(pageData) => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <b style={{ whiteSpace: 'nowrap' }}>{t('common.total')}</b>
                    </Table.Summary.Cell>
                    {columnsProduct.map((e, index) => {
                      if (e.key === 'open_sell_quantity')
                        return (
                          <Table.Summary.Cell>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {totalProducts?.open_sell_quantity}
                            </div>
                          </Table.Summary.Cell>
                        )
                      if (e.key === 'total_inventory')
                        return (
                          <Table.Summary.Cell>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {totalProducts?.total_inventory}
                            </div>
                          </Table.Summary.Cell>
                        )
                      return <Table.Summary.Cell></Table.Summary.Cell>
                    })}
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </>
        ) : null}
      </div>
    </>
  )
}
