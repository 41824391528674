import React, { useEffect, useState } from 'react'
import styles from './payment.module.scss'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from 'consts'
import { PERMISSIONS } from 'consts'
import { removeUnicode, formatCash } from 'utils'
//antd
import {
  Row,
  Switch,
  Button,
  Modal,
  Input,
  Form,
  Table,
  notification,
  Space,
  Checkbox,
  Popconfirm,
} from 'antd'

//icons
import {
  CreditCardOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  PrinterOutlined,
} from '@ant-design/icons'

import CloseIcon from 'assets/icons/close.svg'

//components
import Permission from 'components/permission'
import TitlePage from 'components/title-page'

//apis
import { getPayments, addPayment, editPayment, deletePayment } from 'apis/payment'
import moment from 'moment'

export default function Payment() {
  const [form] = Form.useForm()
  const history = useHistory()

  const [id, setId] = useState('')
  const [isEditPayment, setIsEditPayment] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [customer, setCustomers] = useState([])
  const toggle = () => {
    setVisible(!visible)
    form.resetFields()
    setIsEditPayment(false)
    setId('')
  }
  const toggle1 = () => {
    setVisible1(!visible1)
    setIsEditPayment(false)
    setId('')
  }

  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 20 })
  const [loading, setLoading] = useState(false)

  const [payments, setPayments] = useState([])
  const [countPayment, setCountPayment] = useState(0)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: 70,
      align: 'center',
    },
    {
      title: 'Tên hình thức thanh toán',
      render: (text, record) =>
        permissions.includes(PERMISSIONS.xem_chi_tiet_phuong_thuc_thanh_toan) ? (
          record.payment_method_id === 1 ? (
            <div className={styles['payment-name']}>
              {record.name} {record.default && `(Mặc định)`}
            </div>
          ) : record.payment_method_id === 6 ? (
            <ModalListdebt>
              <div className={styles['payment-name']}>{record.name}</div>
            </ModalListdebt>
          ) : (
            <div
              className={styles['payment-name']}
              onClick={() => {
                setId(record.payment_method_id)
                setIsEditPayment(true)
                form.setFieldsValue({ ...record })
                updateBanNo(record.payment_method_id)
              }}
            >
              {record.name} {record.default && `(Mặc định)`}
            </div>
          )
        ) : (
          <span
            style={{ cursor: 'pointer', color: '#5F73E2' }}
            onClick={() => notification.warning({ message: 'Permission denied!' })}
          >
            {record.name} {record.default && `(Mặc định)`}
          </span>
        ),
    },
    {
      title: 'Người tạo',
      render: (text, record) =>
        record._creator && `${record._creator.first_name} ${record._creator.last_name}`,
    },
    {
      title: 'Ngày tạo',
      render: (text, record) =>
        record.create_date && moment(record.create_date).format('DD-MM-YYYY HH:mm'),
    },
    {
      title: 'Thanh toán trước',
      render: (text, record) => (
        <Checkbox
          onChange={(value) =>
            _updatePayment(
              { is_paid: value.target.checked, branch_id: branchIdApp },
              record.payment_method_id
            )
          }
          checked={record.is_paid}
        />
      ),
    },
    {
      title: 'Hành động',
      render: (text, record) => (
        <Space size="middle">
          <>
            <Switch
              disabled={
                permissions.includes(PERMISSIONS.cap_nhat_phuong_thuc_thanh_toan) ? false : true
              }
              checked={record.active}
              onChange={(value) =>
                _updatePayment({ active: value, branch_id: branchIdApp }, record.payment_method_id)
              }
            />
          </>

          <Popconfirm
            onConfirm={() => _deletePayment(record.payment_method_id)}
            title="Bạn có muốn xóa hình thức thanh toán này không?"
            disabled={permissions.includes(PERMISSIONS.xoa_phuong_thuc_thanh_toan) ? false : true}
          >
            <Button
              disabled={permissions.includes(PERMISSIONS.xoa_phuong_thuc_thanh_toan) ? false : true}
              type="primary"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const columnDebt = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: 70,
    },
    {
      title: 'Tên khách hàng',
      render: (text, record) => record.name,
    },
    {
      title: 'Số điện thoại',
      render: (text, record) => record.phone,
    },
    {
      title: 'Tổng công nợ',
      render: (text, record) => record.debt,
    },
    {
      // hiện tại - lần nợ cuối cùng
      title: 'Thời gian nợ',
      render: (text, record) => record.time,
    },
    {
      title: 'In giấy xác nhận nợ',
      align: 'center',
      render: (text, record) => (
        <Button type="primary">
          <PrinterOutlined style={{ size: 40 }} />
        </Button>
      ),
    },
  ]
  const customerDebt = [
    {
      name: 'Nguyễn Văn A',
      phone: '03********',
      debt: 10000000,
      time: '10 ngày',
    },
    {
      name: 'Nguyễn Văn A',
      phone: '03********',
      debt: 10000000,
      time: '10 ngày',
    },
    {
      name: 'Nguyễn Văn A',
      phone: '03********',
      debt: 10000000,
      time: '10 ngày',
    },
    {
      name: 'Nguyễn Văn A',
      phone: '03********',
      debt: 10000000,
      time: '10 ngày',
    },
  ]

  const ModalListdebt = ({ children, record }) => {
    const [visibleShift, setVisibleShift] = useState(false)
    const toggleShift = () => setVisibleShift(!visibleShift)

    return (
      <>
        <div onClick={toggleShift}>{children}</div>
        <Modal
          centered
          onCancel={toggleShift}
          footer={null}
          title={
            <>
              <p style={{ fontSize: 18, marginBottom: 5 }}>Danh sách công nợ</p>
              <p style={{ color: '#af7400', fontSize: 13, marginBottom: 0 }}>
                Phương thức thanh toán này chỉ hỗ trợ theo dõi công nợ của khách hàng, không có giá
                trị như chứng từ thu hồi nợ.
              </p>
            </>
          }
          visible={visibleShift}
          closeIcon={<img src={CloseIcon} alt="" />}
          style={{ padding: 0, maxWidth: '759px' }}
          width="100%"
        >
          <Table
            loading={loading}
            columns={columnDebt}
            dataSource={customerDebt}
            size="small"
            style={{ width: '100%' }}
            pagination={{
              position: ['bottomRight'],
              current: 1,
              pageSize: 5,
            }}
          />
        </Modal>
      </>
    )
  }
  const updateBanNo = async (e) => {
    if (e === 6) {
      payments.map((item) => {
        if (item.payment_method_id === e) {
          setConfig({
            information_verification: item.information_verification,
            have_account: item.have_account,
            agree_to_report: item.agree_to_report,
            owner_consent: item.owner_consent,
          })
        }
      })
      setVisible1(true)
    } else {
      setVisible(true)
    }
  }

  const _deletePayment = async (id) => {
    try {
      setLoading(true)
      const res = await deletePayment([id])
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa hình thức thanh toán thành công!' })
          _getPayments()
        } else
          notification.error({
            message: res.data.message || 'Xóa hình thức thanh toán thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa hình thức thanh toán thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _updatePayment = async (body, id) => {
    try {
      setLoading(true)
      body = { ...body, branch_id: branchIdApp }
      const res = await editPayment(body, id)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật hình thức thanh toán thành công!' })
          _getPayments()
          // toggle()
        } else
          notification.error({
            message:
              res.data.message || 'Cập nhật hình thức thanh toán thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật hình thức thanh toán thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const [config, setConfig] = useState({
    information_verification: false,
    have_account: false,
    agree_to_report: false,
    owner_consent: false,
  })
  const _updatePaymentBanNo = async (id) => {
    try {
      let data = {
        information_verification: config.information_verification,
        have_account: payments.active === true ? true : false,
        agree_to_report: config.agree_to_report,
        owner_consent: config.owner_consent,
        branch_id: branchIdApp,
      }
      setLoading(true)
      const res = await editPayment(data, id)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật hình thức thanh toán thành công!' })
          _getPayments()
        } else
          notification.error({
            message:
              res.data.message || 'Cập nhật hình thức thanh toán thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật hình thức thanh toán thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getPayments = async () => {
    try {
      setLoading(true)
      const res = await getPayments()
      if (res.status === 200) {
        setPayments(res.data.data)
        setCountPayment(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _addPayment = async () => {
    try {
      setLoading(true)
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      const res = await addPayment({ ...dataForm, branch_id: branchIdApp })
      // console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Thêm hình thức thanh toán thành công' })
          toggle()
          _getPayments()
        } else
          notification.error({ message: res.data.message || 'Thêm hình thức thanh toán thất bại!' })
      } else
        notification.error({ message: res.data.message || 'Thêm hình thức thanh toán thất bại!' })

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    _getPayments()
  }, [])

  return (
    <>
      <div className="card">
        <TitlePage
        isAffix
          title={
            <Row
              align="middle"
              onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
              style={{ cursor: 'pointer' }}
            >
              <ArrowLeftOutlined style={{ marginRight: 8 }} />
              Hình thức thanh toán
            </Row>
          }
        >
          <>
            {permissions.includes(PERMISSIONS.them_phuong_thuc_thanh_toan) ? (
              <Button size="large" type="primary" onClick={toggle} icon={<CreditCardOutlined />}>
                Thêm hình thức thanh toán
              </Button>
            ) : (
              <Button disabled size="large" type="primary" icon={<CreditCardOutlined />}>
                Thêm hình thức thanh toán
              </Button>
            )}
          </>
        </TitlePage>
        {permissions.includes(PERMISSIONS.xem_danh_sach_phuong_thuc_thanh_toan) ? (
          <Table
            loading={loading}
            columns={columns}
            dataSource={payments}
            size="small"
            style={{ width: '100%', marginTop: 20 }}
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                paramsFilter.page = page
                paramsFilter.page_size = pageSize
                setParamsFilter({ ...paramsFilter })
              },
              total: countPayment,
            }}
          />
        ) : null}
      </div>

      <Modal
        title={`${isEditPayment ? 'Cập nhật' : 'Thêm'} hình thức thanh toán`}
        visible={visible}
        onCancel={toggle}
        footer={
          <Row justify="end">
            <Space>
              <Button onClick={toggle}>Đóng</Button>
              <Button
                loading={loading}
                type="primary"
                onClick={async () => {
                  await form.validateFields()
                  const dataForm = form.getFieldsValue()
                  if (isEditPayment) {
                    _updatePayment({ ...dataForm }, id)
                    toggle()
                  } else _addPayment()
                }}
              >
                {isEditPayment ? 'Cập nhật' : 'Thêm'}
              </Button>
            </Space>
          </Row>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Vui lòng nhập tên hình thức thanh toán' }]}
            label="Hình thức thanh toán"
          >
            <Input placeholder="Nhập tên hình thức thanh toán" />
          </Form.Item>
          <Form.Item name="default" valuePropName="checked">
            <Checkbox>Chọn làm hình thức thanh toán mặc định</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Cập nhật hình thức thanh toán bán nợ"
        visible={visible1}
        onCancel={toggle1}
        footer={
          <Row justify="end">
            <Space>
              <Button onClick={toggle1}>Đóng</Button>
              <Button
                loading={loading}
                type="primary"
                onClick={async () => {
                  _updatePaymentBanNo(id)
                  toggle1()
                }}
              >
                Cập nhật
              </Button>
            </Space>
          </Row>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Vui lòng nhập tên hình thức thanh toán' }]}
            label="Hình thức thanh toán"
          >
            <Input placeholder="Nhập tên hình thức thanh toán" disabled />
          </Form.Item>
          <h3>Điều kiện để mua nợ</h3>
          <Form.Item className="defaultBanNo">
            <Checkbox
              checked={config.have_account}
              onChange={(e) => {
                setConfig({ ...config, have_account: e.target.checked })
              }}
            >
              Đã đăng ký tài khoản EKATA
            </Checkbox>

            <Checkbox
              checked={config.information_verification}
              onChange={(e) => {
                setConfig({ ...config, information_verification: e.target.checked })
              }}
            >
              Đã xác thực thông tin cá nhân
            </Checkbox>
            <Checkbox
              checked={config.agree_to_report}
              onChange={(e) => {
                setConfig({ ...config, agree_to_report: e.target.checked })
              }}
            >
              Đồng ý thông tin nhắc nợ 1 lần/tháng
            </Checkbox>
            <Checkbox
              checked={config.owner_consent}
              onChange={(e) => {
                setConfig({ ...config, owner_consent: e.target.checked })
              }}
            >
              Đồng ý của chủ cửa hàng khi có giao dịch nợ
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
