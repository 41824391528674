import { useEffect, useState } from 'react'
import { Card } from 'antd'
import {
  ShopOutlined,
  MessageOutlined,
  FieldTimeOutlined,
  StarOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import SettingsIcon from 'assets/icons/setting.svg'

import styles from './Profile.module.scss'

import UpdateBusiness from '../../views/businesses/update'
function Description(props) {
  const { follower, following, quantity, feedbackRate, feedbackTime, rate, finishedRate } = props

  const listData = [
    {
      icon: ShopOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Sản phẩm: <span className={styles['profile__item-number']}>{quantity}</span>
        </p>
      ),
    },
    {
      icon: MessageOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Tỉ lệ phản hồi: <span className={styles['profile__item-number']}>{feedbackRate}</span>
        </p>
      ),
    },
    {
      icon: FieldTimeOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Thời gian phản hồi: <span className={styles['profile__item-number']}>{feedbackTime}</span>
        </p>
      ),
    },
    {
      icon: StarOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Đánh giá shop: <span className={styles['profile__item-number']}>{rate}</span>
        </p>
      ),
    },
    {
      icon: CheckCircleOutlined,
      Content: () => (
        <p className={styles['profile__item-text']}>
          Tỉ lệ hoàn thành đơn:
          <span className={styles['profile__item-number']}>{finishedRate}</span>
        </p>
      ),
    },
  ]

  return (
    <div className={styles.profile__description}>
      <div className={styles.profile__media}>
        <div className={styles.profile__follow}>
          <span className={styles['profile__follow-number']}>{follower}</span>
          <span className={styles['profile__follow-text']}>Lượt theo dõi</span>
        </div>
        <div className={styles.profile__follow}>
          <span className={styles['profile__follow-number']}>{following}</span>
          <span className={styles['profile__follow-text']}>Đang theo dõi</span>
        </div>
      </div>
      <div className={styles.profile__statistic}>
        <ul>
          {listData.map((item) => {
            const { icon: Icon, Content } = item

            return (
              <li className={styles.profile__item}>
                <Icon className={styles['profile__item-icon']} />
                <Content />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

function Title({ status, name }) {
  return (
    <div className={styles.profile__title}>
      <h2 className={styles.profile__name}>{name}</h2>
      <p className={styles.profile__status}>{status}</p>
    </div>
  )
}

function Profile({ profile, classes }) {
  const {
    avatar,
    bgAvatar,
    name,
    status,
    follower,
    following,
    quantity,
    feedbackRate,
    feedbackTime,
    rate,
    finishedRate,
    setting,
  } = profile

  return (
    <Card
      cover={
        <div className={styles['profile__avatar-box']}>
          <div style={{ height: 171 }}>
            <img src={bgAvatar} alt="" className={styles['profile__bg-avatar']} />
          </div>
          <img src={avatar} alt="" className={styles.profile__avatar} />
          {setting ? (
          <div className={styles.profile__settings}>
              <UpdateBusiness>
                <img src={SettingsIcon} alt="" />
              </UpdateBusiness>
          </div>
          ) : null}
        </div>
      }
      className={`${styles.profile} ${classes}`}
    >
      <Card.Meta
        title={<Title name={name} status={status} />}
        style={{marginLeft:30}}
        description={
          <Description
            follower={follower}
            following={following}
            feedbackRate={feedbackRate}
            feedbackTime={feedbackTime}
            quantity={quantity}
            finishedRate={finishedRate}
            rate={rate}
          />
        }
      />
    </Card>
  )
}

export default Profile
