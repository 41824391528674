const initialState = [];
const role = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ROLES': {
        state = action.data
        return [...state]
      }
      default:
        return state
    }
    
};
export default role
