import React, { useState } from 'react'

import styles from './style.module.scss'
import { ModalProductLocation } from './quantityProductModal'
import { SelectImei } from './selectImei'
import { useDispatch, useSelector } from 'react-redux'

import { Row, InputNumber, notification, Col, Tag } from 'antd'
//icons antd
import { CloseCircleTwoTone, DeleteOutlined } from '@ant-design/icons'
import { ROUTES } from 'consts'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export const TableProduct = (props) => {
  const {
    products,
    _editProductInTransport,
    setProductQuantity,
    setProductsTransport,
    status,
    export_location,
  } = props
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const RemoveItemLocation = (product, e, index) => {
    const indexProduct1 = products.findIndex((e) => e.variant_id === product?.variant_id)
    let new_location = [...product.locations]
    new_location.splice(index, 1)
    setProductQuantity(new_location)
    _editProductInTransport(
      'quantity',
      new_location.reduce((total, item) => (total += item.quantity), 0),
      indexProduct1
    )
    _editProductInTransport('locations', new_location, indexProduct1)
  }
  const RemoveItemImei = (product, e, index) => {
    const indexProduct1 = products.findIndex((e) => e.variant_id === product?.variant_id)
    let new_imei = [...product.imei]
    new_imei.splice(index, 1)
    if (new_imei.length === 0) {
      _editProductInTransport('quantity', 0, indexProduct1)
      _editProductInTransport('imei', new_imei, indexProduct1)
    } else {
      _editProductInTransport('quantity', new_imei.length, indexProduct1)
      _editProductInTransport('imei', new_imei, indexProduct1)
    }
  }
  const _deleteProductInTransport = (index) => {
    const productsTransportNew = [...products]
    productsTransportNew.splice(index, 1)
    setProductsTransport([...productsTransportNew])
  }
  const RenderRowTypeAdvancedLocation = ({ product, index }) => {
    if (product.type_advanced == 1) {
      return (
        <InputNumber
          style={{ width: 120 }}
          onBlur={(e) => {
            let value = e.target.value.replaceAll(',', '')
            if (value > product.total_quantity) {
              notification.warning({
                key: 'warning_input_product3',
                message: 'Số lượng của sản phẩm không đủ! Vui lòng kiểm tra lại!',
              })
              _editProductInTransport('quantity', product.total_quantity, index)
              return
            }
            if (value <= 0) {
              notification.warning({
                key: 'warning_input_product3',
                message: 'Số lượng nhập không hợp lệ! Vui lòng kiểm tra lại!',
              })
              _editProductInTransport('quantity', 1, index)
              return
            }
            _editProductInTransport('quantity', +value, index)
          }}
          defaultValue={product.quantity}
          min={0}
          max={product.inventory === 'advanced' && product.total_quantity}
          placeholder="Nhập số lượng chuyển"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          disabled={status !== 'DRAFT' || export_location !== branchIdApp ? true : false}
        />
      )
    } else if (product.type_advanced == 2) {
      return (
        <ModalProductLocation
          product={product}
          variant_id={product.variant_id}
          index={index}
          _editProductInTransport={_editProductInTransport}
          exportLocation={branchIdApp}
          disabled={status !== 'DRAFT' || export_location !== branchIdApp ? true : false}
        />
      )
    } else {
      return (
        <>
          <InputNumber
            style={{ width: 120 }}
            onBlur={(e) => {
              let value = e.target.value.replaceAll(',', '')
              if (value > product.total_quantity) {
                _editProductInTransport('quantity', product.quantity, index)
                return
              }
              if (value <= 0) {
                _editProductInTransport('quantity', product.quantity, index)
                return
              }
              if (value != product.imei.length) {
                _editProductInTransport('quantity', product.quantity, index)
                return
              }
            }}
            defaultValue={product.quantity}
            min={0}
            max={product.inventory === 'advanced' && product.total_quantity}
            placeholder="Nhập số lượng chuyển"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            disabled={status !== 'DRAFT' || export_location !== branchIdApp ? true : false}
          />
          <SelectImei
            variant_id={product.variant_id}
            index={index}
            _editProductInTransport={_editProductInTransport}
            imei={product.imei}
            product={product}
            exportLocation={branchIdApp}
            disabled={status !== 'DRAFT' || export_location !== branchIdApp ? true : false}
          />
        </>
      )
    }
  }
  const RenderRowTypeAdvanced = ({ product, index }) => {
    switch (product.type_advanced) {
      case 2:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product?.locations?.length > 0 ? (
              product.locations.map((e, index) => (
                <div style={{ display: 'flex' }}>
                  {e.quantity > 0 ? (
                    <Tag
                      style={{
                        padding: 4,
                        marginBottom: 4,
                        backgroundColor: '#cccc',
                        borderRadius: 4,
                      }}
                    >
                      {e.code} | {e.exp} | SL: <strong>{e.quantity}</strong>
                      {status !== 'DRAFT' || export_location === branchIdApp ? (
                        <CloseCircleTwoTone
                          style={{ marginLeft: 4 }}
                          onClick={() => RemoveItemLocation(product, e, index)}
                        />
                      ) : null}
                    </Tag>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <p style={{ marginLeft: 20, color: 'red', marginBottom: 0, fontSize: 12 }}>
                Sản phẩm chưa có lô, vui lòng chọn...
              </p>
            )}
          </div>
        )
      case 3:
        return (
          <div style={{ marginTop: 4, display: 'flex', flexWrap: 'wrap' }}>
            {product?.imei?.length > 0 ? (
              product.imei.map((e, index_item) => (
                <div style={{ display: 'flex' }}>
                  <Tag
                    style={{
                      padding: 4,
                      marginBottom: 4,
                      backgroundColor: '#cccc',
                      borderRadius: 4,
                    }}
                  >
                    <strong>{e}</strong>
                    {status !== 'DRAFT' || export_location === branchIdApp ? (
                      <CloseCircleTwoTone
                        style={{ marginLeft: 4 }}
                        onClick={() => RemoveItemImei(product, e, index_item)}
                      />
                    ) : null}
                  </Tag>
                </div>
              ))
            ) : (
              <p style={{ marginLeft: 20, color: 'red', marginBottom: 0, fontSize: 12 }}>
                Sản phẩm chưa có iMei, vui lòng chọn...
              </p>
            )}
          </div>
        )
      default:
        return <></>
    }
  }
  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        wrap={false}
        className={styles['transport-product-header']}
      >
        <Col span={2} className={styles['header-name']}>
          STT
        </Col>
        <Col span={4} className={styles['header-name']}>
          SKU
        </Col>
        <Col span={5} className={styles['header-name']}>
          Tên sản phẩm
        </Col>
        <Col span={4} className={styles['header-name']}>
          Tồn kho
        </Col>
        <Col span={6} className={styles['header-name']}>
          Số lượng
        </Col>
        {status === 'DRAFT' && export_location === branchIdApp ? (
          <Col span={3} className={styles['header-name']}>
            Hành động
          </Col>
        ) : null}
      </Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          overflow: 'scroll',
          height: 'auto',
          maxHeight: '450px',
        }}
      >
        {products.map((product, index) => {
          return (
            <Row key={index}>
              <Row
                align="middle"
                justify="space-between"
                wrap={false}
                className={styles['transport-product__item']}
                id={`variant_${product.variant_id}`}
              >
                <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      marginBottom: 0,
                      width: 30,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {index + 1}
                  </div>
                </Col>
                <Col span={4}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Link
                          to={{
                            pathname: ROUTES.PRODUCT_UPDATE + '/' + `${product?.product_info?.sku ? product?.product_info?.sku : product?.product_sku}`,
                          }}
                          target="_blank"
                          className={styles['transport-product__item-sku']}
                        >
                          {product?.variant_info?.sku ? product?.variant_info?.sku : product.sku}
                        </Link>
                      </div>
                  </Row>
                </Col>
                <Col span={5}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className={styles['transport-product__item-name']}>
                          {product.title}
                        </span>
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className={styles['transport-product__item-name']}>
                          {product.total_quantity}
                        </span>
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <RenderRowTypeAdvancedLocation product={product} index={index} />
                      </div>
                    </div>
                  </Row>
                </Col>
                {status === 'DRAFT' && export_location === branchIdApp ? (
                  <Col span={3}>
                    <Row
                      wrap={false}
                      align="middle"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <DeleteOutlined
                            onClick={() => _deleteProductInTransport(index)}
                            style={{ color: 'red' }}
                          />
                        </div>
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </Row>
              <Row key={index} className={styles['row_bonus']}>
                <RenderRowTypeAdvanced product={product} index={index} />
              </Row>
            </Row>
          )
        })}
      </div>
    </>
  )
}
