import React, { cloneElement } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'
import { notification } from 'antd'

/**
 *
 * @param {Object} props
 * @param {Array<String>} props.permissions
 * @param {React.ReactChildren} props.children
 */
const Authentication = ({ permissions, title, children, dataUser, ...props }) => {
  const history = useHistory()


  //modify title
  document.title = title

  //check login ?
  if (!dataUser) return <Redirect to={ROUTES.LOGIN} />

  const permissionList = [...dataUser._role.permission_list, ...dataUser._role.menu_list]

  // permissions.length = 0 -> screen public
  // permissions.length > 0 -> check user có quyền truy cập vào màn hình này
  if (
    permissions.length === 0 ||
    permissions.filter((p) => permissionList.includes(p)).length > 0
  ) {
    return cloneElement(children, props)
  }

  notification.warning({ message: 'Permission Denied' })

  history.push(ROUTES.DETAIL_BUSINESS)

  return <div />
}

export default Authentication
