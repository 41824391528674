import { getCurrentBusiness } from 'apis/business'
import { ACTION } from 'consts'
import localStorage from "../../utils/localStorage";
import { notification } from 'antd';

export const changeBranchRoleUser = ({data}) => async (dispatch) => {
    try {
      let res = await getCurrentBusiness({branch_id: data})
        if(res.status === 200){
        dispatch({ type: ACTION.LOGIN, data: res.data.data })
        localStorage.setItem('accessToken', res.data.data.accessToken)
      }else{
        notification.warning({message: res.data.message || "Tài khoản của bạn đã tạm thời bị khoá quyền truy cập"})
      }

    } catch (error) {
      console.log(error)
    }
  }

