import styles from './TitleSchedule.module.scss'
import { Tooltip } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'

function TitleSchedule({ title, subTitle, classes = '' }) {
  return (
    <div className={[`${styles.title__box} ${classes}`]}>
      <h1 className={styles.title}>
        {title +' '}
        <Tooltip title={subTitle}>
          <InfoCircleTwoTone style={{ fontSize: 16 }} />
        </Tooltip>
        </h1>
    </div>
  )
}

export default TitleSchedule
