import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
//antd
import { Form, Modal, Row, Input, Select, InputNumber, Spin, Button, notification } from 'antd'

//apis
import { getDistricts, getProvinces, getWards } from 'apis/address'
import { updateDeliveryAddress } from 'apis/customer'
import SelectLocation from 'components/select-location'

export default function DeliveryAddress({ address, customer, getDeliveryAddress }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const [paramsLocation, setParamLocation] = useState()
  const [selectedItems, setSelectedItems] = useState([])
  const _updateDeliveryAddress = async (paramsLocation) => {
    try {
      await form.validateFields()

      const dataForm = form.getFieldsValue()

      if (
        !paramsLocation.province.province_id ||
        !paramsLocation.district.district_id ||
        !paramsLocation.ward.ward_code
      ) {
        return notification.warning({ message: 'Vui lòng chọn đẩy đủ thông tin địa chỉ' })
      }
      await updateDeliveryAddress(customer.customer_id, {
        address: dataForm.address,
        phone: dataForm.phone,
        branch_id: branchIdApp,
        first_name: dataForm.first_name,
        last_name: dataForm.last_name,
        province_id: paramsLocation.province.province_id,
        district_id: paramsLocation.district.district_id,
        ward_code: paramsLocation.ward.ward_code,
      })
      getDeliveryAddress()

      notification.success({ message: 'Thêm địa chỉ giao hàng mới thành công' })

      toggle()
    } catch (error) {
      notification.warning({ message: 'Vui lòng chọn đẩy đủ thông tin địa chỉ' })
    }
  }

  const _exit = () => {
    toggle()
    form.setFieldsValue({ ...address })
  }

  useEffect(() => {
    if (visible && address) {
      setParamLocation({})
      form.setFieldsValue({ ...address, name: address.first_name + ' ' + address.last_name })
    }
  }, [visible])

  return (
    <>
      <Button
        style={{ color: '#1890ff', cursor: 'pointer', display: !address && 'none' }}
        onClick={toggle}
      >
        Thêm địa chỉ mới
      </Button>
      <Modal
        onOk={() => _updateDeliveryAddress(paramsLocation)}
        width={800}
        okText={t('common.update')}
        cancelText={t('common.exit')}
        title={t('sell.change_of_delivery_address')}
        visible={visible}
        onCancel={_exit}
      >
        <Form form={form} layout="vertical">
          <Row justify="space-between" wrap={false}>
            <Form.Item
              style={{ width: 250 }}
              name="first_name"
              label={t('common.customer_s_last_name')}
              rules={[{ message: t('common.please_enter_customer_name'), required: true }]}
            >
              <Input placeholder={t('common.enter_customer_s_last_name')} />
            </Form.Item>

            <Form.Item
              style={{ width: 250 }}
              name="last_name"
              label={t('common.customer_name')}
              rules={[{ message: t('common.please_enter_customer_name'), required: true }]}
            >
              <Input placeholder={t('common.enter_customer_name')} />
            </Form.Item>
          </Row>

          <Row justify="space-between" wrap={false}>
            <Form.Item
              style={{ width: 250 }}
              name="address"
              label={t('common.address')}
              rules={[{ message: t('common.please_enter_your_address'), required: true }]}
            >
              <Input placeholder={t('common.enter_address')} />
            </Form.Item>

            <Form.Item
              style={{ width: 250 }}
              name="phone"
              label={t('common.phone')}
              rules={[{ message: t('common.please_enter_the_phone_number'), required: true }]}
            >
              <Input style={{ width: '100%' }} placeholder={t('common.enter_your_phone_number')} />
            </Form.Item>
          </Row>

          <SelectLocation
            setParamLocation={setParamLocation}
            paramsLocation={paramsLocation}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            style={{
              width: '100%',
              borderRight: '1px solid #d9d9d9',
              borderLeft: '1px solid #d9d9d9',
            }}
          />
        </Form>
      </Modal>
    </>
  )
}
