// const initialState = []

import { EditData } from "redux/actions/global";

const initialState = {
  loading: false,
  posts: [],
  result: 0,
  page: 2
}


const posts = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_POSTS': 
    return {
      ...state,
      posts: action.payload,
  };
    case 'UPDATE_POST':
      return {
        ...state,
        posts: EditData(state.posts, action.payload._id, action.payload)
    };
    default:
      return state
  }
}
export default posts
