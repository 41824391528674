import React from 'react'

import { useTranslation } from 'react-i18next'
import { Modal, Row} from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'

export default function KeyboardShortCuts({ visible, toggle }) {
  const { t } = useTranslation()

  const shortcuts = [
    {
      text: t('sell.payment'),
      icon: '(F2)',
    },
    {
      text: 'Tìm kiếm sản phẩm',
      icon: '(F3)',
    },
    {
      text: 'Chi tiết sản phẩm',
      icon: '(F4)',
    },
    {
      text: t('sell.adjust_payment_method'),
      icon: '',
    },
    {
      text: t('sell.create_another_order'),
      icon: '(F9)',
    },
    {
      text: t('sell.enter_new_customers'),
      icon: '(F10)',
    },
    {
      text: t('sell.increase_the_number_by_1_unit'),
      icon: <ArrowUpOutlined />,
    },
    {
      text: t('sell.reduce_quantity_by_1_unit'),
      icon: <ArrowDownOutlined />,
    },
  ]

  return (
    <Modal
      width={800}
      footer={null}
      title={t('sell.list_of_all_keyboard_shortcuts')}
      visible={visible}
      onCancel={toggle}
    >
      <Row justify="space-between" align="middle">
        {shortcuts.map((shortcut) => (
          <div
            style={{
              backgroundColor: '#B5CEFE',
              borderRadius: '5px',
              width: '230px',
              height: '55px',
              fontWeight: 600,
              marginBottom: 30,
              paddingLeft: 10,
              paddingRight: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ textAlign: 'center', marginBottom: 0 }}>{shortcut.text}</p>
            <p style={{ textAlign: 'center', marginBottom: 0 }}>{shortcut.icon}</p>
          </div>
        ))}
      </Row>
    </Modal>
  )
}
