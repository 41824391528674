const columns = [
  {
    title: 'STT',
    key: 'stt',
    width: 60,
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Nơi chuyển',
    key: 'export_location',
  },
  {
    title: 'Nơi nhận',
    key: 'import_location',
  },
  {
    title: 'Số lượng',
    key: 'quantity',
    align: 'center',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: 'Người tạo',
    dataIndex: 'creator_info',
    key: 'creator_info',
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'Hành động',
    key: 'action',
    align: 'center',
  },
]

export default columns
