import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'
import { rolesTranslate } from 'components/ExportCSV/fieldConvert'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { PERMISSIONS_APP } from './tree-column'
import styles from './tree.module.scss'
export const TreeItem = ({ _checkValueRole, show, item, role_id, checkDefault }) => {
  const [showChild, setShowChild] = useState(true)

  if (!item) return <></>

  return (
    <div className={styles['tree-main']}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{ 
            marginRight: '5px',
            cursor: 'pointer',
            marginLeft: item?.pChildren ? '' : '14px'
          }}
          onClick={() => {
            setShowChild(!showChild)
          }}
        >
          {item.pChildren ? (
            showChild === false ? (
              <CaretRightOutlined />
            ) : (
              <CaretDownOutlined />
            )
          ) : (
            ''
          )}
        </div>
        <div className={styles['check-box']}>
          <Checkbox
            onChange={(e) => {
              e.stopPropagation()
              if (show === true) {
                _checkValueRole(show, role_id, item.pParent, item, (checkDefault.find((e) => e == item.pParent) ? true : false))
              }
            }}
            checked={checkDefault.find((e) => e == item.pParent) ? true : false}
            style={{ marginRight: '5px' }}
          />
          <span
            onClick={(e) => {
              e.stopPropagation()
              if (show === true) {
                _checkValueRole(show, role_id, item.pParent, item, (checkDefault.find((e) => e == item.pParent) ? true : false))
              }
            }}
          >
            {rolesTranslate(item.pParent)}
          </span>
        </div>
      </div>
      {showChild &&
        item.pChildren?.map((tree, index) => (
          <TreeItem
            key={index}
            _checkValueRole={_checkValueRole}
            show={show}
            item={tree}
            role_id={role_id}
            checkDefault={checkDefault}
          />
        ))}
    </div>
  )
}
const TreeView = ({ _checkValueRole, show, data, roleCheck }) => {
  if (!data.permission_list) return <></>
  return (
    <div>
      {PERMISSIONS_APP.map((item, index) => (
        <TreeItem
          key={index}
          _checkValueRole={_checkValueRole}
          show={show}
          item={item}
          role_id={data.role_id}
          checkDefault={
            Object(roleCheck.find((item) => item.role_id === data.role_id)).permission_list_new
          }
        />
      ))}
    </div>
  )
}

export default TreeView
