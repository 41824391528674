/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from 'react'
import styles from '../sell.module.scss'
import {
  Row,
  Select,
  Tabs,
  Popconfirm,
  Tooltip,
  Spin,
  notification,
  Col,
  Button,
  Modal,
} from 'antd'
import QRCode from 'qrcode.react'
import moment from 'moment'

import {
  SearchOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  CloseOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

//component
import { searchComponent } from 'utils/search'
import Permission from 'components/permission'
import { IMAGE_DEFAULT, PERMISSIONS, ROUTES } from 'consts'
import { formatCash, removeUnicode } from 'utils'
import ScanProduct from '../scan-product'
import HeaderGroupButton from '../header-group-button'
import HeaderBranch from './header-branch'
import HeaderProfile from './header-profile'
import KeyboardShortCuts from '../keyboard-shortcuts'
import DropdownGroupButton from './items/dropdownGroupButton'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isBuffer, sortBy } from 'lodash'
import LockShiftForm from './items/lockShiftForm'
import RollCallForm from './items/rollCallForm'

const HeaderLayout = (props) => {
  const {
    loadingProduct,
    setLoadingProduct,
    products,
    productsFil,
    invoices,
    indexInvoice,
    _editProductInInvoices,
    _addProductToCartInvoice,
    activeKeyTab,
    setActiveKeyTab,
    _createInvoice,
    setIndexInvoice,
    _deleteInvoice,
    _removeMultipleProduct,
    changeProductOfBranch,
    scrollSmoothHandler,
    _deleteAllInvoice
  } = props
  const dataUser = useSelector((state) => state.login.dataUser)
  const { connect_device } = useSelector((state) => state)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [filteredResults, setFilteredResults] = useState([])
  const [checkSearch, setCheckSearch] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const { products, productsFil } = useSelector((state) => state.variant)

  const [windowSize, setWindowSize] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const [visible, setVisible] = useState(false)
  const [visibleLockShift, setVisibleLockShift] = useState(false)
  const [modalConnectDevice, setModalConnectDevice] = useState(false)
  const [visibleRollCall, setVisibleRollCall] = useState(false)
  const toggleRollCall = () => setVisibleRollCall(!visibleRollCall)
  const toggle = () => setVisible(!visible)
  const toggleLockShift = () => setVisibleLockShift(!visibleLockShift)
  const detectSize = () => {
    setWindowSize({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowSize])
  return (
    <div className={styles['sell-header']}>
      <Row align="middle" wrap={false}>
        <div className="select-product-sell">
          <Select
            onFocus={changeProductOfBranch}
            style={{ width: 400 }}
            notFoundContent={loadingProduct ? <Spin size="small" /> : null}
            dropdownClassName="dropdown-select-search-product"
            allowClear
            showSearch
            value={null}
            clearIcon={<CloseOutlined style={{ color: 'black' }} />}
            suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
            className={styles['search-product']}
            placeholder={t('sell.add_product_to_invoice')}
            filterOption={(inputValue, option) =>
              removeUnicode(option.key.toString())
                .toLowerCase()
                .trim()
                .indexOf(removeUnicode(inputValue).toLowerCase().trim()) >= 0
            }
            onChange={(value) => {
              if (value !== null && value !== undefined && value !== '') {
                let index = value.lastIndexOf('/')
                let resultIndex = value.substring(index + 1)
                scrollSmoothHandler(products[resultIndex].variant_id)
                
                let indexProduct = invoices[indexInvoice].order_details.findIndex(
                  (e) => e.variant_id === products[resultIndex].variant_id
                )
                let quantityCurrent = 0
                if (indexProduct !== -1) {
                  quantityCurrent = invoices[indexInvoice].order_details[indexProduct].quantity
                }
                if (indexProduct !== -1) {
                  if (
                    products[resultIndex].type_advanced === 1 ||
                    products[resultIndex].inventory === 'normal'
                  ) {
                    _editProductInInvoices('quantity', quantityCurrent + 1, indexProduct,indexInvoice)
                  }
                  if (products[resultIndex].type_advanced === 2) {
                    let listLocationOld = invoices[indexInvoice].order_details[indexProduct].locations_new
                    let today = moment().startOf('date').format('YYYY-MM-DD')
                    let new_arr = products[resultIndex]?.location_advanced?.location.reduce(
                      ({ sum, arr }, item) => {
                        if (item.exp >= today && item.quantity > 0) {
                          sum = sum + item.quantity
                          arr.push(item)
                        }
                        return { sum, arr }
                      },
                      { sum: 0, arr: [] }
                    )
                    let new_data = sortBy(new_arr.arr, [
                      (o) => {
                        return o.exp
                      },
                    ])

                    if (quantityCurrent >= new_arr.sum) {
                      return notification.warning({
                        key: 'max_quantity',
                        message: `Sản phẩm ${products[resultIndex].title} (Có thể bán: ${new_arr.sum}), vui lòng nhập thêm hàng để tiếp tục thao tác.`,
                      })
                    }

                    if (new_data.length === 0) {
                      return notification.warning({
                        key: 'not_location',
                        message: `Vui lòng nhập thêm lô để tiếp tục!`,
                      })
                    } else {
                      let new_data_location = []
                          if (listLocationOld.length === 0) {
                            new_data_location.push({
                              location_id: new_data[0].location_id,
                              quantity: 1,
                              code: new_data[0].code,
                              exp: new_data[0].exp,
                            })
                          } else {
                            let quantityOld = listLocationOld.reduce((sum, item) => sum + item.quantity, 0)
                            let new_quantity = quantityCurrent + 1
                            new_data_location = new_data.reduce((arr, current) => {
                              if (new_quantity > 0) {
                                let locationOld = listLocationOld.find(
                                  (e) => e.location_id === current.location_id
                                )
                                if (locationOld) {
                                  let obj = {
                                    location_id: current?.location_id,
                                    quantity: current.quantity === locationOld.quantity ? locationOld.quantity : new_quantity === quantityOld ? locationOld.quantity : locationOld.quantity + 1,
                                    code: current?.code,
                                    exp: current?.exp,
                                  }
                                  arr.push(obj)
                                  new_quantity -= current.quantity === locationOld.quantity ? locationOld.quantity : new_quantity === quantityOld ? locationOld.quantity : locationOld.quantity + 1
                                  quantityOld -= locationOld.quantity 
                                }else{
                                  if(new_quantity > quantityOld){
                                    let obj = {
                                      location_id: current?.location_id,
                                      quantity: 1,
                                      code: current?.code,
                                      exp: current?.exp,
                                    }
                                    arr.push(obj)
                                    new_quantity -= 1
                                  }
                                }
                              }
                              return arr
                            }, [])
                            
                          }
                      _editProductInInvoices('locations_new', new_data_location, indexProduct,indexInvoice)
                    }
                  }
                  if (products[resultIndex].type_advanced === 3) {
                    if (quantityCurrent >= products[resultIndex].total_quantity) {
                      return notification.warning({
                        key: 'max_quantity',
                        message: `Sản phẩm ${products[resultIndex].title} (Có thể bán: ${products[resultIndex].total_quantity}), vui lòng nhập thêm hàng để tiếp tục thao tác.`,
                      })
                    }
                    _editProductInInvoices('quantity', quantityCurrent + 1, indexProduct,indexInvoice)
                  }
                } else {
                  _addProductToCartInvoice(products[resultIndex])
                }
                setFilteredResults([])
              }
            }}
            dropdownRender={(menu) => (
              <div>
                <Permission permissions={[PERMISSIONS.ban_hang]}>
                  <Row
                    wrap={false}
                    align="middle"
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(ROUTES.PRODUCT_ADD, '_blank')}
                  >
                    <Row align="middle" style={{ paddingLeft: 15, height: 50 }}>
                      <PlusSquareOutlined style={{ fontSize: 19 }} />
                    </Row>
                    <span style={{ marginLeft: 20, marginBottom: 0, fontSize: 16 }}>
                      {t('sell.add_new_products')}
                    </span>
                  </Row>
                </Permission>
                {loadingProduct ? (
                  <Spin
                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                    size="middle"
                  />
                ) : (
                  menu
                )}
              </div>
            )}
          >
            {products &&
              products.map(
                (data, index) =>
                  data && (
                    <Select.Option key={data.title + data.sku + data.barcode + '/' + index + ''}>
                      <Row align="middle" wrap={false} style={{ padding: '7px 13px' }}>
                        <img
                          src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                          alt=""
                          style={{
                            minWidth: 55,
                            minHeight: 55,
                            maxWidth: 55,
                            maxHeight: 55,
                            objectFit: 'cover',
                          }}
                        />

                        <div style={{ width: '100%', marginLeft: 15 }}>
                          <Row wrap={false} justify="space-between">
                            <span
                              style={{
                                maxWidth: 300,
                                marginBottom: 0,
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                              }}
                            >
                              {data.title.length > 30 ? data.title.substring(0,20)+'...' : data.title}
                            </span>
                            <span style={{ marginBottom: 0, fontWeight: 500 }}>
                              {formatCash(data.price)}
                            </span>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <span style={{ marginBottom: 0, fontWeight: 500 }}>
                              {data.active ? (
                                t('sell.on_sale')
                              ) : (
                                <div style={{ color: '#ff6666' }}>{t('sell.sale_off')}</div>
                              )}
                            </span>
                            <span
                              style={{
                                marginBottom: 0,
                                color: 'gray',
                                display: !data.is_pre_order && 'none',
                              }}
                            >
                              pre-order
                            </span>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <span style={{ marginBottom: 0, color: 'gray' }}>{data.sku}</span>
                            {data.inventory !== 'normal' ? (
                              <span style={{ marginBottom: 0, color: 'gray' }}>
                                {t('sell.can_be_sold')}: {formatCash(data.total_quantity)}
                              </span>
                            ) : data.is_open === true ? (
                              <span style={{ marginBottom: 0, color: 'gray' }}>Còn hàng</span>
                            ) : (
                              <span style={{ marginBottom: 0, color: 'gray' }}>Hết hàng</span>
                            )}
                          </Row>
                        </div>
                      </Row>
                    </Select.Option>
                  )
              )}
          </Select>
        </div>
        <ScanProduct
          productsCurrent={invoices[indexInvoice].order_details}
          addProductToCartInvoice={_addProductToCartInvoice}
        />
      </Row>

      <Row align="middle" style={{ marginLeft: 15 }}>
        <Tabs
          hideAdd={invoices.length > 9 && true}
          moreIcon={<MoreOutlined style={{ color: 'white', fontSize: 16 }} />}
          activeKey={activeKeyTab}
          onEdit={(key, action) => {
            if (action === 'add') _createInvoice()
          }}
          onChange={(activeKey) => {
            const iVoice = invoices.findIndex((e) => e.id === activeKey)
            if (iVoice !== -1) {
              setIndexInvoice(iVoice)
              dispatch({type: 'UPDATE_INDEXINVOICE', data: iVoice})
            }
            setActiveKeyTab(activeKey)
          }}
          tabBarStyle={{ height: 48, color: 'white' }}
          type="editable-card"
          className="tabs-invoices"
          addIcon={
            <Tooltip title="Thêm mới đơn hàng">
              <PlusOutlined style={{ color: 'white', fontSize: 21, marginLeft: 7 }} />
            </Tooltip>
          }
        >
          {invoices.map((invoice, index) => (
            <Tabs.TabPane
              closeIcon={
                <Popconfirm
                  okText={t('common.agree')}
                  cancelText={t('common.refuse')}
                  title={t('sell.do_you_want_to_delete_this_invoice')}
                  onConfirm={() => _deleteInvoice(invoice, index)}
                >
                  <CloseCircleOutlined
                    style={{
                      display: invoices.length === 1 && 'none',
                      fontSize: 15,
                      color: invoice.id === activeKeyTab ? 'black' : 'white',
                    }}
                  />
                </Popconfirm>
              }
              tab={
                <>
                  <Tooltip title={invoice.name} mouseEnterDelay={1} className="tab-sell">
                    <span
                      style={{
                        marginBottom: 0,
                        color: invoice.id === activeKeyTab ? 'black' : 'white',
                      }}
                    >
                      {invoice.name}
                    </span>
                  </Tooltip>
                </>
              }
              key={invoice.id}
              style={{ display: 'none' }}
            />
          ))}
        </Tabs>
      </Row>

      <KeyboardShortCuts visible={visible} setVisible={setVisible} toggle={toggle} />
      <LockShiftForm
        visibleLockShift={visibleLockShift}
        toggleLockShift={toggleLockShift}
      />
      <RollCallForm visibleRollCall={visibleRollCall} toggleRollCall={toggleRollCall}/>

      {windowSize.winWidth >= 1380 && (
        <Button
          onClick={toggle}
          type="primary"
          style={{
            backgroundColor: '#192DA0',
            borderColor: '#192DA0',
            borderRadius: 5,
            color: 'white',
          }}
        >
          {t('sell.shortcut_keys')}
        </Button>
      )}
      {windowSize.winWidth >= 1480 &&
        (permissions.includes(PERMISSIONS.tao_phieu_chot_ca) ? (
          <Button
            onClick={toggleLockShift}
            type="primary"
            style={{
              backgroundColor: '#192DA0',
              borderColor: '#192DA0',
              borderRadius: 5,
              color: 'white',
              marginLeft: '10px',
            }}
          >
            Chốt ca
          </Button>
        ) : (
          <Button
            disabled
            onClick={toggleLockShift}
            type="primary"
            style={{
              backgroundColor: '#cccc',
              borderColor: '#cccc',
              borderRadius: 5,
              color: 'white',
              marginLeft: '10px',
            }}
          >
            Chốt ca
          </Button>
        ))}

      {windowSize.winWidth >= 1580 &&
        (permissions.includes(PERMISSIONS.tao_phieu_tra_hang) ? (
          <Link to={ROUTES.REFUND} target="_blank">
            <Button
              style={{
                borderWidth: 1,
                marginLeft: 8,
                backgroundColor: '#192DA0',
                borderColor: '#192DA0',
                color: 'white',
              }}
              onClick={() => localStorage.setItem('collapsed', JSON.stringify(false))}
            >
              Trả hàng
            </Button>
          </Link>
        ) : (
          <Link to={ROUTES.REFUND} target="_blank">
            <Button
              style={{
                borderWidth: 1,
                marginLeft: 8,
                backgroundColor: '#cccc',
                borderColor: '#cccc  ',
                color: 'white',
              }}
              disabled={true}
            >
              Trả hàng
            </Button>
          </Link>
        ))}
      {windowSize.winWidth >= 1700 &&
        (dataUser.role_id === 1 || dataUser._role.name === "HOST" ? (
          <Button
            style={{
              borderWidth: 1,
              marginLeft: 8,
              backgroundColor: '#192DA0',
              borderColor: '#192DA0',
              color: 'white',
            }}
            onClick={toggleRollCall}
          >
        Điểm danh
      </Button>
        ) : null)} 
      {windowSize.winWidth < 1720 ? (
        <DropdownGroupButton
          widthData={windowSize.winWidth}
          permissions={permissions}
          toggleLockShift={toggleLockShift}
          toggle={toggle}
          setModalConnectDevice={setModalConnectDevice}
          toggleRollCall={toggleRollCall}
        />
      ) : (
        <Row wrap={false} align="middle" justify="end" style={{ marginLeft: 15, marginRight: 15 }}>
          <HeaderGroupButton setModalConnectDevice={setModalConnectDevice} />
        </Row>
      )}

      <Modal
        title="Kết nối màn hình phụ"
        visible={modalConnectDevice}
        closable={false}
        footer={[
          <Row justify="end">
            <Col>
              <Button onClick={() => setModalConnectDevice(false)}>Đóng</Button>
            </Col>
          </Row>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <QRCode
            id="qrCodeEl"
            size={150}
            value={`https://${dataUser?._business.prefix}.${process.env.REACT_APP_HOST}${
              ROUTES.CONNECT_DEVICE + '/' + connect_device.link
            }`}
          />
          <div>
            <div style={{ marginLeft: '30px', fontSize: '16px', fontWeight: 700 }}>
              <span>Quét mã trên thiết bị khác, hoặc đường dẫn bên dưới:</span>
              <Link target="_blank" to={ROUTES.CONNECT_DEVICE + '/' + connect_device.link}>
                Kết nối
              </Link>
            </div>
            <div>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <Row style={{alignItems: 'center', width: '25%'}}>
        <Col span={13}>
          <HeaderBranch
            _removeMultipleProduct={_removeMultipleProduct}
            invoices={invoices}
            product={invoices[indexInvoice].order_details}
            widthData={windowSize.winWidth}
            changeProductOfBranch={changeProductOfBranch}
            _deleteAllInvoice={_deleteAllInvoice}
          />
        </Col>
        <Col span={11}>
          <HeaderProfile widthData={windowSize.winWidth} />
        </Col>
      </Row>
    </div>
  )
}

export default HeaderLayout
