import React, { useState, useEffect } from 'react'
import { PAGE_SIZE, ROUTES } from 'consts'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { compare, formatCash, compareCustom } from 'utils'
import { useHistory } from 'react-router-dom'

//apis
import { getLockShift } from 'apis/lockShift'
import { getStaff } from 'apis/manage-staff'

//antd
import { Row, Select, Table, Col, Button } from 'antd'

//icons
import { CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons'

//components
import FilterDate from 'components/filter-date'
import columns from './columns'
import TitlePage from 'components/title-page'
import { DefaultTimeFilter } from 'utils/DefaultFilterData'

export default function ReceiptsAndPayment() {
  const dataUser = useSelector((state) => state.login.dataUser)
  const history = useHistory()
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })
  const [lockShift, setLockShift] = useState([])
  const [countLockShift, setCountLockShift] = useState()
  const [employees, setEmployees] = useState([])
  const [total, setTotal] = useState(0)
  const [valueTime, setValueTime] = useState()
  const onFilters = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const onClickClear = async () => {
    setParamsFilter({ page: 1, page_size: 20, check: 1 })
  }

  const _getLockShift = async () => {
    try {
      setLoading(true)
      const res = await getLockShift({ ...paramsFilter, branch_id: branchIdApp })
      if (res.status === 200) {
        setTotal(res.data.total)
        setLockShift(res.data.data)
        setCountLockShift(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const _getEmployees = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) {
        setEmployees(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function getEmployeeInfo(array) {
    let emp = []
    let user = [...employees]
    if (user.length > 0) {
      for (const i in array) {
        let tmp = user.find((employee) => employee.user_id === array[i])
        emp.push(<div>{String(tmp?.first_name).charAt() + '.' + tmp?.last_name}</div>)
      }
    }
    return emp
  }
  useEffect(() => {
    _getEmployees()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchIdApp])

  useEffect(() => {
    if (paramsFilter.check) _getLockShift()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, branchIdApp])
  useEffect(() => {
    let result = DefaultTimeFilter(dataUser.role_id, permissions)
    setParamsFilter({
      page:1, page_size: PAGE_SIZE,
      from_date: result.from_date,
      to_date: result.to_date,
      check: 1,
    })
    setValueTime(result.valueDate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser])
  return (
    <div className="card">
      <TitlePage
      isAffix
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.REPORTS)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Danh sách chốt ca
          </Row>
        }
      ></TitlePage>
      <Row align="middle" style={{ margin: '10px 0 10px 0' }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={10}>
          <FilterDate
          size='default'
            style={{ with: '100%'}}
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
            width="100%"
            title="tạo chốt ca"
            valueTime={valueTime}
            setValueTime={setValueTime}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={10}>
          <Select
            clearIcon={<CloseOutlined />}
            allowClear
            bordered={true}
            showSearch
            optionFilterProp="children"
            placeholder="Nhân viên tạo"
            style={{ width: '100%' }}
            value={paramsFilter.creator_by || 'all'}
            defaultValue={''}
            onChange={(value) => onFilters('creator_by', value)}
          >
            <Select.Option value="all">Tất cả nhân viên</Select.Option>
            {employees.map((employee, index) => (
              <Select.Option value={employee.user_id} key={index}>
                {employee.first_name} {employee.last_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={4}>
          <Button
            style={{ display: Object.keys(paramsFilter).length < 3 && 'none', width: '100%' }}
            onClick={onClickClear}
            type="primary"
            danger
          >
            Xóa tất cả lọc
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={lockShift}
        size="small"
        scroll={{ y: 600 }}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: [20, 30, 40, 50, 70, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countLockShift,
        }}
        columns={columns.map((column) => {
          if (column.key === 'stt')
            return {
              ...column,
              width: 50,
              render: (text, record, index) => index + 1,
            }
          if (column.key === 'code')
            return {
              ...column,
              render: (text, record) => <a href>{record.code}</a>,
              sorter: (a, b) => compare(a, b, 'code'),
            }
          if (column.key === 'employee')
            return {
              ...column,
              render: (text, record) => getEmployeeInfo(record.employee_name),
            }

          if (column.key === 'turnover')
            return {
              ...column,
              render: (text, record) => record.turnover && formatCash(record.turnover || 0),
            }

          if (column.key === 'sumReven')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'sumReven'),
              render: (text, record) => record.sumReven && formatCash(record.sumReven || 0),
            }
          if (column.key === 'systemCash')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'systemCash'),
              render: (text, record) => record.systemCash && formatCash(record.systemCash || 0),
            }
          if (column.key === 'realCash')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'realCash'),
              render: (text, record) => record.realCash && formatCash(record.realCash || 0),
            }
          if (column.key === 'status')
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'status'),
              render: (text, record) => record.status,
            }
          if (column.key === 'create_date')
            return {
              ...column,
              sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
              render: (text, record) =>
                record.create_date && moment(record.create_date).format('DD/MM/YYYY HH:mm'),

              width: 200,
            }
          if (column.key === 'creator')
            return {
              ...column,
              sorter: (a, b) =>
                compareCustom(
                  a.createBy ? `${a.createBy.first_name} ${a.createBy.last_name}` : '',
                  b.createBy ? `${b.createBy.first_name} ${b.createBy.last_name}` : ''
                ),
              render: (text, record) =>
                record.createBy ? `${record.createBy.first_name} ${record.createBy.last_name}` : '',
            }

          if (column.key === 'note')
            return {
              ...column,
              render: (text, record) => record.note,
            }

          return column
        })}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <div style={{ fontWeight: 700 }}>Tổng</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>

              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{total && formatCash(total.turnover)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{total && formatCash(total.sumReven)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{total && formatCash(total.systemCash)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ fontWeight: 700 }}>{total && formatCash(total.realCash)}</div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
