import { Space } from 'antd'
import { Button } from 'components/Button/Button'
import styles from './TimeSchedule.module.scss'
import { PERMISSIONS } from 'consts'
import Permission from 'components/permission'

function TimeSchedule({
  dateStart,
  dateEnd,
  classes = '',
  _getArrayStaffRegister,
  _deleteShiftAllday,
}) {
  return (
    <Space className={`${styles.card} ${classes}`}>
      <div className={styles['card__time-box']}>
        <p className={styles.card__time}>
          <span className={styles.time__text}>Từ:</span>
          <span className={styles.time__number}>{dateStart}</span>
        </p>
        <p className={styles.card__time}>
          <span className={styles.time__text}>Đến:</span>
          <span className={styles.time__number}>{dateEnd}</span>
        </p>
      </div>
      <div>
        <Permission permissions={[PERMISSIONS.cap_nhat_lich_lam_viec]}>
          <Button
            classes="btn-primary"
            text="Sắp xếp lịch tự động"
            onClick={_getArrayStaffRegister}
          />
        </Permission>
      </div>
      <div>
        <Permission permissions={[PERMISSIONS.cap_nhat_lich_lam_viec]}>
          <Button
            className={styles.btn_delete}
            text="Xóa lịch đã xếp"
            onClick={_deleteShiftAllday}
          />
        </Permission>
      </div>
    </Space>
  )
}

export default TimeSchedule
