import React, { useEffect, useState } from 'react'

import styles from '../point.module.scss'
import { PERMISSIONS } from 'consts'
import { compare, formatCash } from 'utils'
import moment from 'moment'
import { useSelector } from 'react-redux'

//antd
import { Row, Col, Select, Input, Table } from 'antd'
export default function ListCustomer(props) {
  const { paramsFilter, setParamsFilter, customers } = props
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [valueSearch, setValueSearch] = useState('')
  const [optionSearch, setOptionSearch] = useState('name')
  const [total, setTotal] = useState({})
  const [tableLoading, setTableLoading] = useState(false)
  const [countCustomer, setCountCustomer] = useState(0)
  const [customerShow, setCustomerShow] = useState()
  const columns = [
    {
      title: 'Mã khách hàng',
      dataIndex: 'code',
    },
    {
      title: 'Tên',
      render: (text, record) => record.first_name + ' ' + record.last_name,
      sorter: (a, b) => a.first_name.length - b.first_name.length,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Hạng thẻ',
      render: (text, record) => record,
    },
    {
      title: 'Điểm hiện tại',
      render: (text, record) => Number(record.point).toFixed(),
      sorter: (a, b) => compare(a, b, 'point'),
    },
    {
      title: 'Điểm đã dùng',
      render: (text, record) => Number(record.used_point).toFixed(),
      sorter: (a, b) => compare(a, b, 'used_point'),
    },
    {
      title: 'Tổng chi tiêu',
      render: (text, record) => formatCash(record.orderPoint),
      sorter: (a, b) => compare(a, b, 'orderPoint'),
    },
    {
      title: 'Giá trị lên hạng',
      render: (text, record) => record,
    },
    {
      title: 'Ngày đạt thẻ',
      render: (text, record) => moment(record.create_date).format('DD-MM-YYYY, HH:mm'),
    },
    {
      title: 'Ngày kết thúc',
      render: (text, record) => moment(record.create_date).format('DD-MM-YYYY, HH:mm'),
    },
  ]
  const onSearch = (e) => {
    e = e.target.value
    setValueSearch(e)
    e = e.toLowerCase().trim()
    if (e.includes(' ')) {
      e = new RegExp(e.replace(/ /g, '.*'))
    } else {
      e = new RegExp(e)
    }
    paramsFilter[optionSearch] = e
  }

  useEffect(() => {
    setTableLoading(true)
    let arr = [...customers]
    const filtered = arr.filter((current) => {
      let matchCode = true
      let matchName = true
      let matchPhone = true
      if (paramsFilter.code) matchCode = paramsFilter.code.test(current?.code.toLowerCase())
      if (paramsFilter.name){
        let newObject = {
          first_name: current.first_name,
          last_name: current.last_name
        }
        newObject = Object.values(newObject).join(' ').toLocaleLowerCase()
        matchName = paramsFilter.name.test(newObject)
      }
      if (paramsFilter.phone) matchPhone = paramsFilter.phone.test(current?.phone)
      return matchPhone && matchName && matchCode
    })

    let objTmp = {
      point: 0,
      used_point: 0,
      orderPoint: 0,
    }
    let totalNew = filtered.reduce((obj, e) => {
      objTmp.point += e.point
      objTmp.used_point += e.used_point
      objTmp.orderPoint += e.orderPoint

      obj['point'] = objTmp.point
      obj['used_point'] = objTmp.used_point
      obj['orderPoint'] = objTmp.orderPoint
      return obj
    }, {})
    setTotal(totalNew)
    setCustomerShow(filtered)
    setCountCustomer(filtered.length)
    setTableLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter,valueSearch])

  return (
    <Col>
      <Row justify="space-between" className={styles['point-bottom']}>
        <Col md={6} lg={6} xl={6}>
          <p style={{ fontWeight: 'bold' }}>Thông tin về những khách hàng đã tích điểm.</p>
        </Col>
        <Col md={16} lg={16} xl={16} className={styles['point-bottom-right']}>
          <Row wrap={false} style={{ width: '90%', border: '1px solid #d9d9d9', borderRadius: 5 }}>
            <Input
              style={{ width: '100%', borderRight: '1px solid #d9d9d9' }}
              placeholder="Tìm kiếm theo..."
              value={valueSearch}
              onChange={(e) => onSearch(e)}
              allowClear
              bordered={false}
            />
            <Select
              style={{ width: 180 }}
              value={optionSearch}
              onChange={(value) => {
                delete paramsFilter[optionSearch]
                setOptionSearch(value)
              }}
              bordered={false}
            >
              <Select.Option value="name">Tên khách hàng</Select.Option>
              <Select.Option value="phone">SDT khách hàng</Select.Option>
              <Select.Option value="code">Mã khách hàng</Select.Option>
            </Select>
          </Row>
        </Col>

        <Col md={24} lg={24} xl={24} className={styles['point-bottom-right']}>
          {permissions.includes(PERMISSIONS.xem_danh_sach_khach_hang_tich_diem) ? (
            <Table
              loading={tableLoading}
              size="small"
              columns={columns}
              dataSource={customerShow}
              scroll={{ y: 450 }}
              style={{ width: '100%' }}
              pagination={{
                position: ['bottomLeft'],
                current: paramsFilter.page,
                pageSize: paramsFilter.page_size,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                showQuickJumper: true,
                onChange: (page, pageSize) =>
                  setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
                total: countCustomer,
              }}
              summary={(pageData) => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <div style={{ fontWeight: 700 }}>Tổng</div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>

                    <Table.Summary.Cell>
                      <div style={{ fontWeight: 700 }}>{formatCash(total?.point)}</div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div style={{ fontWeight: 700 }}>{formatCash(total?.used_point)}</div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div style={{ fontWeight: 700 }}>{formatCash(total?.orderPoint)}</div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Col>
  )
}
