import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'

//andtd
import {
  Drawer,
  Row,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
  notification,
  Space,
  Table,
} from 'antd'

//apis
import { createShippingControl, getOrderCarrier } from 'apis/shipping-control'

//components
import TitlePage from 'components/title-page'
import {
  ArrowLeftOutlined,
  CaretDownOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons'
import { ROUTES } from 'consts'
import styles from './shipping-control.module.scss'
import TableData from './items/table'
import ModalImport from './import-excel/modal-import'
import { useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
export default function ShippingControlForm() {
  const location = useLocation()
  const history = useHistory()
  const shippingCompany = useSelector((state) => state.shipping_company)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [code, setCode] = useState('')
  const [note, setNote] = useState('')

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [carrier, setCarrier] = useState(null)
  const [loading, setLoading] = useState(false)
  const [checkSubmit, setCheckSubmit] = useState(false)

  const [dataOrders, setDataOrders] = useState([])
  const [ordersError, setOrdersError] = useState([])
  const [dataOrdersCount, setDataOrdersCount] = useState(0)

  const headers = [
    { label: "Lỗi", key: "error" },
    { label: "Mã đơn hàng", key: "code" },
    { label: "Mã đơn hàng shop", key: "code_shop" },
    { label: "Trạng thái đơn hàng", key: "status" },
    { label: "Ngày tạo", key: "start_date" },
    { label: "Ngày hoàn thành", key: "end_date" },
    { label: "Khối lượng (kg)", key: "weight" },
    { label: "Thông tin khách hàng", key: "customer_info" },
    { label: "Tiền thu hộ", key: "cod" },
    { label: "Phí bảo hiểm", key: "fee_1" },
    { label: "Phí giao hàng", key: "fee_2" },
    { label: "Phí dịch vụ trả trước", key: "fee_3" },
    { label: "Phí dịch vụ cấn trừ", key: "fee_4" },
    { label: "Phí dịch vụ hoàn lại", key: "fee_5" },
    { label: "Phí chuyển khoản", key: "fee_6" },
    { label: "Phí thay đổi địa chỉ", key: "fee_7" },
    { label: "Phí lưu kho", key: "fee_8" },
    { label: "Tiền tip", key: "fee_9" },
    { label: "Phí đơn hoàn đã thanh toán", key: "fee_10" },
    { label: "Shop trả ship khi trả hàng", key: "fee_11" },
    { label: "Khuyến mãi", key: "discount" },
    { label: "Thanh toán", key: "price.result" },
    { label: "Ghi chú", key: "note" },
  ];

  const onChangeDate = (value, dateString, check) => {
    if (check === true) {
      setStartDate(value)
      setEndDate(null)
    } else {
      setEndDate(value)
    }
  }

  const disabledDate = (current) => {
    return current && current <= startDate
  }

  const handleGetData = async () => {
    if (!carrier) {
      return notification.error({
        key: 'validate carrier',
        message: 'Vui lòng chọn đơn vị vận chuyển!',
      })
    }
    if (!startDate) {
      return notification.error({
        key: 'validate startDate',
        message: 'Vui lòng chọn ngày nhận đơn!',
      })
    }
    if (!endDate) {
      return notification.error({
        key: 'validate endDate',
        message: 'Vui lòng chọn ngày hoàn thành!',
      })
    }
    let query = {
      shipping_company_id: carrier,
      start_date: moment(startDate).format(),
      end_date: moment(endDate).format(),
    }
    setLoading(true)
    try {
      let res = await getOrderCarrier({ ...query, branch_id: branchIdApp })
      if (res.status === 200) {
        let new_data = res.data.data.filter(item => item?.shipping_info?.tracking_number?.length > 0).map((item, index) => ({
          stt: index + 1,
          order_id: item.order_id,
          code_delivery: null,
          code_order: item.code,
          cod_system: item.final_cost,
          cod_partner: null,
          cost_ship_system: item.shipping_info.fee_shipping,
          cost_ship_partner: null,
          tracking_number: item.shipping_info.tracking_number
        }))
        setDataOrders(new_data)
        setDataOrdersCount(res.data.count)
      } else {
        notification.error({ key: 'error data orders', message: res.data.message })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return notification.error({ key: 'error data try catch', message: error })
    }
  }

  const _createShippingControl = async (status) => {
    try {
      let body = {
        code: code,
        note: note,
        branch_id: branchIdApp,
        shipping_company_id: carrier,
        start_date: moment(startDate).format(),
        end_date: moment(endDate).format(),
        table_data: dataOrders,
        status: status
      }
      let res = await createShippingControl(body)
      if(res.data.success) {
        notification.success({message: 'Tạo phiếu đối soát thành công!'})
        history.push(ROUTES.SHIPPING_CONTROL)
      } else {
        notification.error({message: 'Tạo phiếu đối soát thất bại!'})
      }
    } catch (error) {
      return notification.error({ key: 'error data try catch', message: error })
    }
  }

  return (
    <div className="card">
      <TitlePage
        isAffix={true}
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.SHIPPING_CONTROL)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            {location.state ? 'Cập nhật' : 'Tạo'} phiếu đối soát vận chuyển
          </Row>
        }
      >
        <Space>
          <Button onClick={async () => {_createShippingControl(0)}} size="large" type="primary">
            Lưu nháp
          </Button>
          <Button onClick={async () => {_createShippingControl(1)}} size="large" type="primary">
            Đối soát file
          </Button>
        </Space>
      </TitlePage>
      <div style={{ marginTop: 25,backgroundColor:'white' }}>
        <div className={styles['form-input']}>
          <div className={styles['form-left']}>
            <div className={styles['item-left-1']}>
              <span className={styles['span']}>
                Mã phiếu đối soát <span style={{ color: 'red' }}>*</span>
              </span>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Nhập mã phiếu đối soát"
              />
            </div>
            <div className={styles['item-left-2']}>
              <span className={styles['span']}>Ghi chú</span>
              <Input.TextArea value={note} onChange={(e) => setNote(e.target.value)} rows={4} />
            </div>
          </div>

          <div className={styles['form-right']}>
            <div className={styles['item-right-1']}>
              <span className={styles['span']}>
                Đơn vị vận chuyển <span style={{ color: 'red' }}>*</span>
              </span>
              <Select
                disabled={loading}
                value={carrier}
                placeholder="Chọn đơn vị vận chuyển"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setCarrier(e)
                }}
              >
                {shippingCompany?.map((item, index) => (
                  <Select.Option key={item.shipping_company_id}>{item.name}</Select.Option>
                ))}
              </Select>
            </div>
            <div className={styles['item-right-2']}>
              <div className={styles['item-right-child-1']}>
                <span className={styles['span']}>
                  Ngày nhận đơn <span style={{ color: 'red' }}>*</span>
                </span>
                <div style={{ width: '100%' }}>
                  <DatePicker
                    disabled={loading}
                    value={startDate}
                    style={{ width: '100%' }}
                    onChange={(value, dateString) => onChangeDate(value, dateString, true)}
                  />
                </div>
              </div>
              <div className={styles['item-right-child-2']}>
                <span className={styles['span']}>
                  Ngày hoàn thành <span style={{ color: 'red' }}>*</span>
                </span>
                <div style={{ width: '100%' }}>
                  <DatePicker
                    disabled={loading}
                    value={endDate}
                    disabledDate={disabledDate}
                    style={{ width: '100%' }}
                    onChange={(value, dateString) => onChangeDate(value, dateString, false)}
                  />
                </div>
              </div>
              <div className={styles['item-right-child-3']}>
                <Button
                  onClick={() => handleGetData()}
                  className={styles['item-button']}
                  type="primary"
                >
                  Kiểm tra
                </Button>
              </div>
            </div>
            <div className={styles['item-right-3']}>
              <span className={styles['span']}>File đối soát</span>
              <ModalImport checkSubmit={checkSubmit} dataOrders={dataOrders} setDataOrders={setDataOrders} setOrdersError={setOrdersError} startDate={startDate} endDate={endDate} />
            </div>
          </div>
        </div>
      </div>
      <div style={{backgroundColor:'white' }}>
        <span>
          <span style={{ color: 'red' }}>*</span> Vui lòng chọn đơn vị vận chuyển và ngày nhận đơn
          để xem thông tin hệ thống, import file đối soát để xem thông tin đối tác
        </span>
      </div>
      <div style={{marginTop: '3px'}}>
            {ordersError.length > 0 && <Button
                type="primary"
            >
                <CSVLink
                    headers={headers}
                    data={ordersError}
                    filename={'Sản phẩm lỗi'}
                    style={{ "textDecoration": "none", "color": "#fff" }}
                >
                    Tải file lỗi
                </CSVLink>
            </Button>}
            </div>
      <div
        style={{
          marginTop: '1%',
          marginBottom: '1%',
          boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
        }}
      >
        <TableData dataOrders={dataOrders} dataOrdersCount={dataOrdersCount} />
      </div>
      <div className={styles['footer']}>
        <div className={styles['final-card']}>
          <div className={styles['header-card']}>
            <div>
              <span className={styles['span-name']}>
                Tổng đối soát hệ thống: <ExclamationCircleTwoTone twoToneColor={'#1677ff'} />
              </span>
              <span className={styles['span-price']}> 2,500,000</span>
            </div>
            <div>
              <span className={styles['span-name']}>
                Tổng đối soát đối tác: <ExclamationCircleTwoTone twoToneColor={'#1677ff'} />
              </span>
              <span className={styles['span-price']}> 2,500,000</span>
            </div>
            <div>
              <span className={styles['span-name']}>
                Tổng chênh lệch: <ExclamationCircleTwoTone twoToneColor={'#1677ff'} />
              </span>
              <span className={styles['span-price']}> 0</span>
            </div>
          </div>
          <div className={styles['body-card']}>
            <span style={{ backgroundColor: '#1677ff' }} className={styles['span-dot']}></span>
            <span className={styles['span-name']}>Tiền mặt: </span>
            <span className={styles['span-price']}>2,500,000</span>
            <span className={styles['span-time']}>
              <CaretDownOutlined /> 26/07/2021 12:00:40
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
