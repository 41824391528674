import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from '../schedule-staff.module.scss'

// antd
import { Row, Col, notification, Popconfirm, InputNumber, Table, Dropdown, Input } from 'antd'
import moment from 'moment'
//apis
import { updateShift } from 'apis/schedule-staff'
import { DeleteOutlined } from '@ant-design/icons'

//components
import { TimePickerRangePicker } from 'components/Form'
import noData from 'assets/icons/no-data.png'
import { Button } from 'components/Button/Button'
import { removeUnicode } from 'utils'

export default function ShiftForm({ record, getShift, close, shifts }) {
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const role = useSelector((state) => state.role)
  const [id, setId] = useState()
  const [roles, setRoles] = useState([])
  const [roleArray, setRoleArray] = useState([])
  const [listRole, setListRole] = useState([])
  const [listRoleTmp, setListRoleTmp] = useState([])
  const [listRoleBan, setListRoleBan] = useState([])
  const [loadingShift, setLoadingShift] = useState(false)
  const [timeS, setTimeS] = useState([])
  const [showT, setShowT] = useState(true)
  const [timeUpdate, setTimeUpdate] = useState([])

  const _updateShift = async (id) => {
    try {
      setLoadingShift(true)
      let arrTmp = [...roleArray]

      const body = {
        branch_id: branchIdApp,
        role: arrTmp,
        time_start: timeUpdate[0],
        time_end: timeUpdate[1],
      }
      let res = await updateShift(id, body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: 'Cập nhật ca thành công',
          })
          setRoleArray([])
          close()
          getShift()
        } else
          notification.error({
            message: res.data.message || 'Cập nhật ca thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật ca thất bại, vui lòng thử lại',
        })

      setLoadingShift(false)
      setShowT(true)
    } catch (error) {
      setLoadingShift(false)
      console.log(error)
    }
  }
  const addRole = (value) => {
    let index = listRoleBan.findIndex(e => e.role_id === value)
     if(index === -1){
      let array = [...roleArray]
      let audi = [...listRole]

      let i = audi.findIndex((e) => e.role_id === value)

      let roleInfo = roles.find((r) => r.role_id === value)
      let obj = {
        name: roleInfo?.name,
        role_id: value,
        total: 1,
      }
      array.push(obj)
      audi.splice(i, 1)

      setRoleArray([...array])
      setListRole(audi)
    }else{
      notification.error({
        message:`Bộ phận đã được thêm ở ca ${listRoleBan[index].shift} (${listRoleBan[index].time_start}-${listRoleBan[index].time_end} )`,
      })
    }
    
  }
  const _roleAmount = (total, value, index) => {
    let arrayTmp = [...roleArray]
    arrayTmp[index][total] = value
    setRoleArray(arrayTmp)
  }
  const columns = [
    {
      title: `STT`,
      render: (text, record, index) => index + 1,
    },
    {
      title: `Bộ phận`,
      dataIndex: 'name',
    },
    {
      title: 'Số lượng nhân viên',
      render: (data, record, index) => (
        <div>
          <InputNumber
            style={{ width: '100%' }}
            onChange={(e) => {
              const index = roleArray.findIndex((e) => e.role_id === record?.role_id)
              _roleAmount('total', +e, index)
            }}
            value={record.total}
            min={1}
            placeholder="Nhập số lượng"
          />
        </div>
      ),
    },
    {
      title: 'Hành động',
      render: (record) => (
        <Popconfirm
          title="Bạn có muốn xóa bộ phận này không?"
          cancelText="Từ chối"
          okText="Đồng ý"
          onConfirm={() => _deleteRole(record.role_id)}
        >
          <Button
            type="button"
            icon={() => <DeleteOutlined style={{ fontSize: 15, color: 'red' }} />}
          />
        </Popconfirm>
      ),
    },
  ]
  function checkOverlap(range1, range2) {
    const [a, b] = range1;
    const [c, d] = range2;
    // Kiểm tra điều kiện chồng chéo
    const isOverlapping = (a > c && a < d) || (b > c && b < d);
    // Kiểm tra điều kiện giao nhau
    const isIntersecting = a < d && c < b;
    // Kiểm tra điều kiện bằng nhau
    const isEqual = a == c && b == d
    return isOverlapping || isIntersecting || isEqual;
  }
  const _onUpdate = (dataShift) => {
    let range1 = [moment(dataShift.time_start).hours(),moment(dataShift.time_end).hours()]
    let shiftOverLap = []
    shiftOverLap = shifts && shifts.filter(e => {
      if(e.shift_id !== dataShift.shift_id){
        let range2 = [moment(e.time_start).hours(), moment(e.time_end).hours()]
        let check = checkOverlap(range1, range2)
        if(check) return e
      }
    });
    let role_unauthorized = []
    if(shiftOverLap && shiftOverLap.length > 0){
      role_unauthorized = shiftOverLap.reduce((arr, element) => {
        element.role.forEach(_role => {
          let index = arr.findIndex(e => e === _role.role_id)
          if(index === -1){
            arr.push({
              role_id: _role.role_id,
              shift: element.title,
              time_start : moment(element.time_start).format('HH:mm'),
              time_end : moment(element.time_end).format('HH:mm') 
            })
          }
        })
        return arr
      },[]);
    }
    setId(dataShift.shift_id)
    let arr = []
    arr[0] = moment(dataShift.time_start).format('HH:mm')
    arr[1] = moment(dataShift.time_end).format('HH:mm')
    setTimeS(arr)
    setRoleArray(dataShift.role)
    let timeArr = []
    timeArr[0] = dataShift.time_start
    timeArr[1] = dataShift.time_end
    let arrRoleNew = [...role]
    dataShift.role.map((e) => {
      let index = arrRoleNew.findIndex((item) => item.role_id == e.role_id)
      arrRoleNew.splice(index, 1)
    })

    setListRoleBan(role_unauthorized)
    setRoles(role)
    setListRole(arrRoleNew)
    setListRoleTmp(arrRoleNew)
    setTimeUpdate(timeArr)
  }

  const _deleteRole = (id) => {
    let arrayTmp = [...roleArray]
    let audi = [...listRole]

    for (let i = 0; i < roleArray.length; i++) {
      if (id == roleArray[i].role_id) {
        arrayTmp.splice(i, 1)
      }
    }
    let item = roles.find((e) => e.role_id === id)
    audi.push(item)

    setListRole(audi)
    setRoleArray(arrayTmp)
  }

  const _searchRole = (e) => {
    let aaaa = []
    if (e == '') {
      aaaa = [...listRoleTmp]
    } else {
      aaaa = listRole.filter(
        (item) =>
          removeUnicode(item.name.toString())
            .toLowerCase()
            .trim()
            .indexOf(removeUnicode(e).toLowerCase().trim()) >= 0
      )
    }
    setListRole([...aaaa])
  }

  function rangeMinutes(start, end) {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  function disabledDateTime() {
    return {
      disabledHours: () => [],
      disabledMinutes: () => rangeMinutes(0, 60),
      disabledSeconds: () => [0, 60],
    }
  }

  const MenuItem = ({ role }) => {
    return (
      <div
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          backgroundColor: '#fff',
          padding: '10px',
        }}
      >
        {role.map((item, index) => (
          <div
            onClick={() => {
              addRole(item.role_id)
            }}
            key={index}
            style={{ cursor: 'pointer', height: '30px' }}
          >
            {item.name}
          </div>
        ))}
      </div>
    )
  }
  useEffect(() => {
    _onUpdate(record)
  }, [branchIdApp])
  useEffect(() => {
    document.getElementById('role').focus()
  }, [])
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Dropdown overlay={<MenuItem role={[...listRole]} />} trigger={['click']}>
            <Input
              id="role"
              placeholder="Chọn Vai trò..."
              style={{ height: '100%', width: '80%' }}
              onClick={(e) => e.preventDefault()}
              onChange={(e) => {
                _searchRole(e.target.value)
              }}
            />
          </Dropdown>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {showT === true ? (
            <h2
              className={styles.btnShift__title}
              onClick={() => setShowT(!showT)}
              style={{ cursor: 'pointer' }}
            >
              {timeS[0]} - {timeS[1]}
            </h2>
          ) : (
            <TimePickerRangePicker
              showTime={{ format: 'HH:mm' }}
              format="HH:00"
              classes={styles.input__time}
              allowClear
              disabledTime={() => disabledDateTime()}
              onChange={(value) => {
                if (value !== null) {
                  let arr = []
                  arr[0] = moment(value[0]).format('HH:mm')
                  arr[1] = moment(value[1]).format('HH:mm')
                  setTimeS(arr)
                  setTimeUpdate(value)
                }
                setShowT(true)
              }}
            />
          )}
        </Col>
      </Row>
      <Table
        pagination={false}
        columns={columns}
        size="small"
        dataSource={roleArray}
        scroll={{ y: 200 }}
        locale={{
          emptyText: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 200,
              }}
            >
              <img src={noData} alt="" style={{ width: 90, height: 90 }} />
              <h4 style={{ fontSize: 15, color: '#555' }}>Chưa có bộ phận</h4>
            </div>
          ),
        }}
      />
      <Row style={{ width: '100%', marginTop: '50px' }}>
        <Button
          onClick={() => _updateShift(id)}
          text="Lưu"
          classes="btn__blue-fill"
          loading={loadingShift}
          style={{
            display: 'inline-block',
            padding: '6px 12px',
            fontSize: '14px',
            fontWeight: 500,
          }}
        />
      </Row>
    </>
  )
}
