import React, { useState } from 'react'
import { Button, Col, Input, InputNumber, Modal, Row } from 'antd'
import { formatCash } from 'utils'
import './item-style.css'
export const ModalPrice = ({ data, setVariants, variants }) => {
  const [visible, setVisible] = useState(false)
  const [valuePrice, setValuePrice] = useState(data.price)
  const [valueWholeSalePrice, setValueWholeSalePrice] = useState(data.whole_sale_price)
  const [valueImportPrice, setValueImportPrice] = useState(data.import_price)

  const editPrice = () => {
    let newDataVariant = [...variants]
    let indexVariant = newDataVariant.findIndex((e) => e.sku === data.sku)
    newDataVariant[indexVariant].price = valuePrice
    newDataVariant[indexVariant].whole_sale_price = valueWholeSalePrice
    newDataVariant[indexVariant].import_price = valueImportPrice
    setVariants([...newDataVariant])
    setVisible(false)
  }

  const closeEditPrice = () => {
    setValuePrice(data.price)
    setValueWholeSalePrice(data.whole_sale_price)
    setValueImportPrice(data.import_price)
    setVisible(false)
  }

  return (
    <>
      <div className="price-cost" onClick={() => setVisible(true)} type="text">
        <strong>Giá bán lẻ: </strong>
        {formatCash(valuePrice)}
        <br></br>
        <strong>Giá bán sỉ: </strong>
        {formatCash(valueWholeSalePrice)}
        <br></br>
        <strong>Giá nhập: </strong>
        {formatCash(valueImportPrice)}
        <a> {`(Sửa)`}</a>
      </div>
      <Modal
        visible={visible}
        closable={false}
        footer={[
          <Row gutter={[16, 0]} justify="end">
            <Col>
              <Button onClick={() => closeEditPrice()}>Đóng</Button>
            </Col>
            <Col>
              <Button onClick={() => editPrice()} type="primary">Xác nhận</Button>
            </Col>
          </Row>,
        ]}
        title="Thông tin giá sản phẩm"
        width={500}
      >
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <span>Giá bán lẻ:</span>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Nhập giá bán"
              size="large"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={valuePrice}
              min={0}
              onChange={(e) => {
                if (e) {
                  setValuePrice(e)
                } else {
                  setValuePrice(0)
                }
              }}
            />
          </Col>
          <Col span={12}>
            <span>Giá nhập hàng:</span>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Nhập giá nhập hàng"
              size="large"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={valueImportPrice}
              min={0}
              onChange={(e) => {
                if (e) {
                  setValueImportPrice(e)
                } else {
                  setValueImportPrice(0)
                }
              }}
            />
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <span>Giá bán sỉ:</span>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Nhập giá bán sỉ"
              size="large"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={valueWholeSalePrice}
              min={0}
              onChange={(e) => {
                if (e) {
                  setValueWholeSalePrice(e)
                } else {
                  setValueWholeSalePrice(0)
                }
              }}
            />
          </Col>

        </Row>
      </Modal>
    </>
  )
}
