const columns = [
  {
    title: 'TT',
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: 'Ảnh',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: 'Mã sản phẩm',
    dataIndex: 'sku',
    key: 'sku',
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Giá bán',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Giá nhập',
    dataIndex: 'import_price',
    key: 'import_price',
  },
  {
    title: 'Đơn vị',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: 'Mô tả',
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'Hành động',
    dataIndex: 'action',
    key: 'action',
  },
]

export default columns
