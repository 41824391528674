import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ROUTES, PERMISSIONS, ACTION, TIME_FORMAT } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import styles from './stock-adjustments.module.scss'
import moment from 'moment'
//components
import TitlePage from 'components/title-page'
import Permission from 'components/permission'
import ContainerLeft from './containerLeft'
import ContainerRight from './containerRight'
//antd
import { Row, Input, Button, Form, Spin, notification, Steps } from 'antd'

//icons
import { ArrowLeftOutlined } from '@ant-design/icons'

//apis
import { getStaff } from 'apis/manage-staff'
import {
  pushLocationInventoryProduct,
  updateInventoryListLocation,
  updateCheckInventoryNoteAdvanced,
  getCheckInventoryNoteAdvanced,
} from 'apis/inventory-node-advanced'
import { getProductWarehouse } from 'redux/actions/productWarehouse'

const { Step } = Steps

export default function StockAdjustmentsForm() {
  const [form] = Form.useForm()
  const { code } = useParams()
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const [loadingProduct, setLoadingProduct] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inventoryNote, setInventoryNote] = useState({})
  const [dateChecked, setDateChecked] = useState()
  const [dateBalanced, setDateBalanced] = useState()
  const [filteredResults, setFilteredResults] = useState([])

  const productSystem = useSelector((state) => state.products.productWarehouse)
  const [productArr, setProductArr] = useState([])
  const [productChange, setProductChange] = useState([])
  const [users, setUsers] = useState([])
  const [userChange, setUserChange] = useState([])

  const [listProduct, setListProduct] = useState([])
  const [countCheck, setCountCheck] = useState(0)
  const [current, setCurrent] = useState(0)
  const [amountSystem, setAmountSystem] = useState(0)

  const [listUserUpdate, setListUserUpdate] = useState([])
  const [listProductUpdate, setlistProductUpdate] = useState([])
  const [paramsFilterSearchHeader, setParamsFilterSearchHeader] = useState({
    page: 1,
    page_size: 50,
  })
  const [checker, setChecker] = useState(false)
  const [sample, setSample] = useState(false)
  const [sampleList, setSampleList] = useState([])
  const [detailLocation, setDetailLocation] = useState(0)
  const [voteDetail, setVoteDetail] = useState()

  const dispatch = useDispatch()
  const next = () => {
    setCurrent(current + 1)
  }
  const _getCheckInventoryNote = async () => {
    try {
      dispatch({type: ACTION.LOADING, data: true})
      setLoading(true)
      const res = await getCheckInventoryNoteAdvanced({
        code: code,
        branch_id: branchIdApp,
        detail: 1,
      })
      if (res.status === 200 && res.data.data[0]) {
        setAmountSystem(res.data.data[0].system_product_quantity)
        setInventoryNote(res.data.data[0])
        setCountCheck(res.data.data[0].countCheck)
        if (moment(res.data.data[0].check_date).format(TIME_FORMAT.DATE_TIME) == 'Invalid date') {
          setDateChecked('')
        } else {
          setDateChecked(moment(res.data.data[0].check_date).format(TIME_FORMAT.DATE_TIME))
        }
        if (moment(res.data.data[0].balance_date).format(TIME_FORMAT.DATE_TIME) == 'Invalid date') {
          setDateBalanced('')
        } else {
          setDateBalanced(moment(res.data.data[0].balance_date).format(TIME_FORMAT.DATE_TIME))
        }
        form.setFieldsValue({
          title: res.data.data[0].title,
          date: moment(res.data.data[0].create_date).format(TIME_FORMAT.DATE_TIME),
          create_by:
            res.data.data[0]?.creator_info?.first_name +
            ' ' +
            res.data.data[0]?.creator_info?.last_name,
          last_update: moment(res.data.data[0].last_update).format(TIME_FORMAT.DATE_TIME),
          inventoryCheck: [],
          productCheck: [],
        })
        if (
          (dataUser.role_id === 1 ||
          res.data.data[0].inventoryCheckers.includes(dataUser.user_id) ||
          dataUser.user_id === res.data.data[0].creator_id) && permissions.includes(PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao)
        ) {
          setChecker(true)
        }
      } else {
        history.push(ROUTES.STOCK_ADJUSTMENTS_ADVANCED)
      }
      dispatch({type: ACTION.LOADING, data: false})
    } catch (err) {
      dispatch({type: ACTION.LOADING, data: false})
      console.log(err)
    }
  }
  const addProduct = async (variant) => {
    if(variant.imported === 0){
      return notification.warning({
        key: 'null_inventory',
        message: 'Sản phẩm chưa từng nhập hàng!'
      })
    }
    const listProductNew = [...listProduct]
    let productTmp = productSystem.find((item) => item.product_id === variant.product_id)
    var variantNew = {
      product_id: variant.product_id,
      variant_id: variant.variant_id,
      sku: variant.sku,
      title: variant.title,
      unit: productTmp.unit,
      image: variant.image,
      price: variant.price,
      real_quantity: variant.type_advanced === 1 ? 1 : 0,
      diff_reason: '',
      system_quantity: 0,
      location: '',
      checked: [],
      type_advanced: productTmp.type_advanced,
      location_check: [],
      imei_check: [],
    }
    for (var i = 0; i < variant.locations.length; i++) {
      variantNew.system_quantity += variant.locations[i].quantity
    }
    if (listProductNew.length === 0) listProductNew.push(variantNew)
    else {
      const indexVariant = listProduct.findIndex((p) => p.variant_id === variant.variant_id)
      if (indexVariant === -1) listProductNew.push(variantNew)
      // else listProductNew[indexVariant].real_quantity += 1
    }
    let sample_list = [...sampleList]

    let index = sample_list.findIndex((e) => e === variant.variant_id)
    if (sample === true) {
      if (index === -1) sample_list.push(variant.variant_id)
    } 

    setSampleList(sample_list)
    setListProduct([...listProductNew])
  }
  // tổng số lượng 1 vote
  const _sumRealAmount = () => {
    let amount = 0
    listProduct.map((item) => {
      amount += item.real_quantity
    })
    return amount
  }
  const _sumSystemAmount = () => {
    let amount = 0
    listProduct.map((item) => {
      amount += item.system_quantity
    })
    return amount
  }
  //tổng số lượng 1 lần kiểm
  const _sumRealActual = () => {
    let amount = 0
    inventoryNote.locationInventoryProduct.map((item) => {
      amount += item.real_quantity
    })
    return amount
  }
  const _sumSystem = () => {
    let amount = 0
    inventoryNote.locationInventoryProduct.map((item) => {
      amount += item.system_quantity
    })
    return amount
  }
  const addVOte = async () => {
    if (checker === false) {
      notification.error({ message: 'Bạn không có quyền cập nhật phiếu này' })
      return
    }
    await form.validateFields()
    const dataForm = form.getFieldsValue()
    let amount = _sumRealAmount()
    let systemAmount = _sumSystemAmount()
    let listTmp = [...listProduct]
    listTmp.map((item) => {
      item.location = dataForm.locations
      item.checked.push({
        check_by: dataUser.user_id,
        countCheck: countCheck,
        quantity: item.real_quantity,
      })
    })

    const body = {
      location: dataForm.locations,
      listProduct: listTmp,
      note: dataForm.note,
      real_quantity: amount,
      system_quantity: systemAmount,
      countCheck: countCheck,
      sample: sample,
      last_update_old: inventoryNote.last_update
    }
    if (body.location.trim() == '') {
      notification.warning({ message: 'Vui lòng nhập vị trí kiểm' })
    } else {
      if (listProduct.length === 0) {
        notification.warning({ message: 'Vui lòng thêm sản phẩm vào phiếu kiểm!' })
      } else {
        // setId(voteNew.vote_id + 1)
        let res = await pushLocationInventoryProduct(body, inventoryNote.inventoryNoteId)
        if (res.status === 200) {
          if(res.data.success){
            setListProduct([])
            setSample(false)
            _getCheckInventoryNote()
            form.setFieldsValue({
              locations: '',
              inventoryCheckers: [],
              note: '',
            })
            notification.success({ message: res.data.message || 'Thêm vị trí kiểm thành công' })
          }else{
            notification.warning({ message: res.data.message || 'Thêm vị trí kiểm thất bại' })
          }
        } else {
          notification.warning({ message: res.data.message || 'Thêm vị trí kiểm thất bại' })
        }
      }
    }
  }

  const _PrevInventoryNote = async () => {
    try {
      dispatch({type: ACTION.LOADING, data: true})
      if (checker === false) {
        dispatch({type: ACTION.LOADING, data: false})
        notification.error({ message: 'Bạn không có quyền cập nhật phiếu này' })
        return
      }
      let countTmp = countCheck + 1
      let arr = [dataUser.user_id]
      const body = {
        status: 1,
        countCheck: countTmp,
        inventoryCheckers: arr,
        last_update_old: inventoryNote.last_update
      }
      let res = await updateCheckInventoryNoteAdvanced(body, inventoryNote.inventoryNoteId)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `Kiểm lại lần ${countCheck + 1}`,
          })
          setCurrent(current - 1)
          setCountCheck(countCheck + 1)
          setDateChecked('')
          _getCheckInventoryNote()
        } else
          notification.error({
            message: res.data.message || 'Cập nhật phiếu kiểm hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật phiếu kiểm hàng thất bại, vui lòng thử lại!',
        })
      dispatch({type: ACTION.LOADING, data: false})

    } catch (err) {
      dispatch({type: ACTION.LOADING, data: false})
      console.log(err)
    }
  }
  const _UpdateInventoryNote = async () => {
    try {
      dispatch({type: ACTION.LOADING, data: true})
      if (checker === false) {
      dispatch({type: ACTION.LOADING, data: false})
      notification.error({ message: 'Bạn không có quyền cập nhật phiếu này' })
        return
      }
      if (inventoryNote.locationInventoryProduct.length === 0) {
        notification.warning({ message: 'Vui lòng thêm vị trí kiểm' })
        dispatch({type: ACTION.LOADING, data: false})
        return
      }
      let amount = _sumRealActual()
      let systemAmount = _sumSystem()
      let arr = [dataUser.user_id]
      const body = {
        status: 2,
        actual_product_quantity: amount,
        system_product_quantity: systemAmount,
        branch_id: branchIdApp,
        inventoryCheckers: arr,
        last_update_old: inventoryNote.last_update
      }
      let res = await updateCheckInventoryNoteAdvanced(body, inventoryNote.inventoryNoteId)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: 'Cập nhật phiếu kiểm hàng thành công',
          })
          next()
          setDateChecked(moment(new Date()).format(TIME_FORMAT.DATE_TIME))
          _getCheckInventoryNote()
        } else
          notification.error({
            message: res.data.message || 'Cập nhật phiếu kiểm hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật phiếu kiểm hàng thất bại, vui lòng thử lại!',
        })
      dispatch({type: ACTION.LOADING, data: false})
    } catch (err) {
      dispatch({type: ACTION.LOADING, data: false})
      console.log(err)
    }
  }
  const _UpdateCheckInventoryNoteBalance = async () => {
    try {
      dispatch({type: ACTION.LOADING, data: true})
      if (checker === false) {
      dispatch({type: ACTION.LOADING, data: false})
      notification.error({ message: 'Bạn không có quyền cập nhật phiếu này' })
        return
      }
      let amount = _sumRealActual()
      let systemAmount = _sumSystem()
      let arr = [dataUser.user_id]
      const body = {
        status: 3,
        actual_product_quantity: amount,
        system_product_quantity: systemAmount,
        branch_id: branchIdApp,
        inventoryCheckers: arr,
        last_update_old: inventoryNote.last_update
      }
      const res = await updateCheckInventoryNoteAdvanced(body, inventoryNote.inventoryNoteId)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: 'Cân bằng phiếu kiểm hàng thành công',
          })
          next()
          setDateBalanced(moment(new Date()).format(TIME_FORMAT.DATE_TIME))
        } else
          notification.error({
            message: res.data.message || 'Cân bằng phiếu kiểm hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cân bằng phiếu kiểm hàng thất bại, vui lòng thử lại!',
        })
      dispatch({type: ACTION.LOADING, data: false})
    } catch (err) {
      dispatch({type: ACTION.LOADING, data: false})
      console.log(err)
    }
  }

  const _updateInventoryListLocation = async () => {
    try {
      if (checker === false) {
        notification.error({ message: 'Bạn không có quyền cập nhật phiếu này' })
        return
      }
      if (dataUser.user_id !== voteDetail.check_by && dataUser.role_id !== 1) {
        notification.error({ message: 'Bạn không có quyền cập nhật vị trí này' })
        return
      }
      let amount = _sumRealAmount()
      let systemAmount = _sumSystemAmount()
      await form.validateFields()
      const dataForm = form.getFieldsValue()

      let listTmp = [...listProduct]
      listTmp.map((item) => {
        item.location = dataForm.locations
        item.checked.push({
          check_by: dataUser.user_id,
          countCheck: countCheck,
          quantity: item.real_quantity,
        })
      })
      const voteNew = {
        ...voteDetail,
        vote_id: voteDetail.vote_id,
        location: dataForm.locations,
        check_by: voteDetail.check_by,
        listProduct: listTmp,
        note: dataForm.note,
        real_quantity: amount,
        system_quantity: systemAmount,
        countCheck: countCheck,
        sample: sample,
        last_update_old: voteDetail.last_update,
        last_update_old_inventoryNote: inventoryNote.last_update
      }
      if (voteNew.location.trim() == '') {
        notification.warning({ message: 'Vui lòng nhập vị trí kiểm' })
      } else {
        if (listProduct.length === 0) {
          notification.warning({ message: 'Vui lòng nhập sản phẩm' })
        } else {
          let res = await updateInventoryListLocation(voteNew, inventoryNote.inventoryNoteId)
          if (res.status === 200) {
            if(res.data.success){
              _getCheckInventoryNote()
              _cancel()
              notification.success({ message: 'Cập nhật vị trí kiểm thành công' })
            }else{
              notification.warning({
                message: res.data.message || 'Cập nhật vị trí kiểm không thành công',
              })
            }
          } else {
            notification.warning({
              message: res.data.message || 'Cập nhật vị trí kiểm không thành công',
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _cancel = () => {
    setListProduct([])
    setDetailLocation(0)
    setVoteDetail()
    setSample(false)
    form.setFieldsValue({
      locations: '',
      inventoryCheckers: [],
      note: '',
    })
    setSampleList (_getSampleList())
  }
  const _getProduct = ()=> {
    try {
      setLoading(true)
      let newArrProduct = [...productSystem]
      newArrProduct = newArrProduct.filter((item) => item?.inventory === 'advanced')
      if (inventoryNote && !inventoryNote.allProduct) {
        let arrProduct = []
        let arrTmp = Array.isArray(inventoryNote.product) ? [...inventoryNote.product] : []
        let arrTmpChange = []
        Array.isArray(inventoryNote.category) && inventoryNote.category.map((id) => {
          let pr = newArrProduct.reduce(function (arr, item) {
            if (item.category_id.indexOf(id) >= 0) arr.push(item?.product_id)
            return arr
          }, [])
          arrTmp = [...arrTmp, ...pr]
          arrTmp = [...new Set(arrTmp)]
        })
        let prId = newArrProduct.map((e) => e.product_id)
        prId = [...new Set(prId)]
        // get product in note
        arrTmp.map((id) => {
          let pr = newArrProduct.filter((item) => item?.product_id === id)
          arrProduct = [...arrProduct, ...pr]
          let index = prId.findIndex((e) => e === id)
          prId.splice(index, 1)
        })
        // get product not in note
        prId.map((id) => {
          let pr = newArrProduct.filter((item) => item?.product_id === id)
          arrTmpChange = [...arrTmpChange, ...pr]
        })
        setProductArr(arrProduct)
        setProductChange(arrTmpChange)
      } else {
        setProductArr(newArrProduct)
      }
      setTimeout(setLoading(false), 500)
    } catch (err) {
      setTimeout(setLoading(false), 500)
      console.log(err)
    }
  }
  const _getSampleList = () => {
    let results =
      inventoryNote?.locationInventoryProduct &&
      inventoryNote.locationInventoryProduct.reduce((arr, current) => {
        if (current.sample === true) {
          current.listProduct.forEach((element) => {
            arr.push(element.variant_id)
          })
        }
        return arr
      }, [])
      return results
  }

  const _getUsers = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })
      if (res.status === 200) {
        setUsers(res.data.data)
        if (inventoryNote)
          setUserChange(res.data.data.filter((item) => (item.status === 1 && item.is_delete === false)).filter((e) => !inventoryNote.inventoryCheckers.includes(e.user_id)))
      }
    } catch (e) {
      console.log(e)
    }
  }
  const _importProduct = (value) => {
    dispatch({ type: ACTION.LOADING, data: true })
    const listProductNew = [...listProduct]
    let sample_list = [...sampleList]

    value.map((item) => {
      let variant = productSystem.find((product) => product.sku === item.variant_sku)
      var variantNew = {
        product_id: variant.product_id,
        variant_id: variant.variant_id,
        sku: variant.sku,
        title: variant.title,
        unit: variant.unit,
        image: variant.image,
        price: variant.price,
        real_quantity: item.real_quantity,
        diff_reason: '',
        system_quantity: 0,
        location: '',
        checked: [],
        type_advanced: item.type_advanced,
        location_check: item.location_check || [],
        imei_check: item.imei_check || [],
      }
      for (var i = 0; i < variant.locations.length; i++) {
        variantNew.system_quantity += variant.locations[i].quantity
      }
      let index = listProductNew.findIndex((item) => item.variant_id == variantNew.variant_id)
      if (index !== -1) {
        listProductNew[index].real_quantity += variantNew.real_quantity
      } else {
        listProductNew.push(variantNew)
      }

      let indexSample = sample_list.findIndex((e) => e === variant.variant_id)
      if (sample === true) {
        if (indexSample === -1) sample_list.push(variant.variant_id)
      } 

    })
    setSampleList(sample_list)

    setListProduct([...listProductNew])
    dispatch({ type: ACTION.LOADING, data: false })
  }

  const _setStatus = (e) => {
    if (e === 1) setCurrent(0)
    if (e === 2) setCurrent(1)
    if (e === 3) setCurrent(2)
    if (e === 4) setCurrent(3)
  }
  useEffect(() => {
    _getCheckInventoryNote()
  }, [code, branchIdApp])
  useEffect(() => {
    _setStatus(inventoryNote?.status)
  }, [inventoryNote?.status])
  useEffect(() => {
    setSampleList(_getSampleList())
  }, [inventoryNote?.locationInventoryProduct])
  const _changeUser = (e) => {
    if (e.length > 0) {
      let userCheck = [...inventoryNote.inventoryCheckers]
      userCheck = [...userCheck, ...e]
      userCheck = [...new Set(userCheck)]
      setListUserUpdate(userCheck)
    }
  }
  const _updateProductChange = (e) => {
    if (e.length > 0) {
      let productUpdate = [...inventoryNote.product]
      productUpdate = [...productUpdate, ...e]
      productUpdate = [...new Set(productUpdate)]
      setlistProductUpdate(productUpdate)
    }
  }
  const _updateInventoryUserAndProduct = async () => {
    try {
      dispatch({type: ACTION.LOADING, data: true})
      if (checker === false) {
      dispatch({type: ACTION.LOADING, data: false})
      notification.error({ message: 'Bạn không có quyền cập nhật phiếu này' })
        return
      }
      setLoading(true)
      let body = {
        inventoryCheckers: listUserUpdate,
        product: listProductUpdate,
        last_update_old: inventoryNote.last_update
      }
      let res = await updateCheckInventoryNoteAdvanced(body, inventoryNote.inventoryNoteId)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: 'Cập nhật phiếu kiểm hàng thành công',
          })
          _getCheckInventoryNote()
        } else
          notification.error({
            message: res.data.message || 'Cập nhật phiếu kiểm hàng thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật phiếu kiểm hàng thất bại, vui lòng thử lại!',
        })
      setLoading(false)
      dispatch({type: ACTION.LOADING, data: false})
    } catch (error) {
      dispatch({type: ACTION.LOADING, data: false})
      console.log(error)
    }
  }
  useEffect(() => {
    _getProduct()
  }, [productSystem, inventoryNote])
  useEffect(() => {
    if (!permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_nang_cao)) {
      history.push(ROUTES.STOCK_ADJUSTMENTS_ADVANCED)
    }
    if (branchIdApp) {
      dispatch(getProductWarehouse({ branchIdApp: branchIdApp }))
    }
    _getUsers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchIdApp, inventoryNote])
  return (
    <Spin spinning={loading} tip="Loading..." size="large">
      <div className="card">
        <TitlePage
          isAffix={true}
          title={
            <Link to={ROUTES.STOCK_ADJUSTMENTS_ADVANCED}>
              <Row
                align="middle"
                style={{
                  fontSize: 18,
                  color: 'black',
                  fontWeight: 600,
                  width: 'max-content',
                }}
              >
                <ArrowLeftOutlined style={{ marginRight: 5 }} />
                Chi tiết phiếu kiểm
              </Row>
            </Link>
          }
        >
          {current === 3 ? (
            <Steps current={current} style={{ width: '55%' }}>
              <Step
                title="Phiếu đã hủy"
                status="error"
                description={moment(inventoryNote?.last_update).format(TIME_FORMAT.DATE_TIME)}
              />
            </Steps>
          ) : (
            <Steps current={current} style={{ width: '55%' }}>
              <Step
                title={`Đang kiểm (Lần ${countCheck})`}
                description={moment(inventoryNote?.untested_date).format(TIME_FORMAT.DATE_TIME)}
              />
              <Step title="Kiểm xong" description={dateChecked} />
              <Step title="Cân bằng" description={dateBalanced} />
            </Steps>
          )}

          <div>
            {current === 0 && (
              <Permission permissions={[PERMISSIONS.cap_nhat_phieu_kiem_hang_nang_cao]}>
                <Button type="primary" size="large" onClick={_UpdateInventoryNote}>
                  Kiểm xong
                </Button>
              </Permission>
            )}
            {current === 1 && (
              <>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                  onClick={_PrevInventoryNote}
                >
                  Kiểm lại
                </Button>
                <Permission permissions={[PERMISSIONS.can_bang_phieu_kiem_hang_nang_cao]}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{
                      background: '#66AE43',
                      borderColor: '#66AE43',
                      visibility:
                        inventoryNote.creator_id == dataUser.user_id || dataUser.role_id === 1
                          ? 'visible'
                          : 'hidden',
                    }}
                    onClick={_UpdateCheckInventoryNoteBalance}
                  >
                    Cân bằng
                  </Button>
                </Permission>
              </>
            )}
            {current === 2 && (
              <Link to={ROUTES.STOCK_ADJUSTMENTS_ADVANCED}>
                <Button type="primary">Done</Button>
              </Link>
            )}
          </div>
        </TitlePage>
        <div className={styles['container']}>
          <div className={styles['containerLeft']}>
            <ContainerLeft
              form={form}
              current={current}
              inventoryNote={inventoryNote}
              productArr={productArr}
              amountSystem={amountSystem}
              _changeUser={_changeUser}
              userChange={userChange}
              _updateProductChange={_updateProductChange}
              productChange={productChange}
              users={users}
              setListProduct={setListProduct}
              setDetailLocation={setDetailLocation}
              setVoteDetail={setVoteDetail}
              _getCheckInventoryNote={_getCheckInventoryNote}
              _updateInventoryUserAndProduct={_updateInventoryUserAndProduct}
              checker={checker}
              setSample={setSample}
              sampleList={sampleList}
            />
          </div>
          <div className={styles['containerRight']}>
            <ContainerRight
              current={current}
              detailLocation={detailLocation}
              _updateInventoryListLocation={_updateInventoryListLocation}
              _cancel={_cancel}
              addVOte={addVOte}
              form={form}
              loadingProduct={loadingProduct}
              addProduct={addProduct}
              productArr={productArr}
              paramsFilterSearchHeader={paramsFilterSearchHeader}
              _importProduct={_importProduct}
              users={users}
              listProduct={listProduct}
              setListProduct={setListProduct}
              filteredResults={filteredResults}
              sample={sample}
              setSample={setSample}
              sampleList={sampleList}
              setSampleList={setSampleList}
              _getSampleList={_getSampleList}
              id={inventoryNote.inventoryNoteId}
            />
          </div>
        </div>
      </div>
    </Spin>
  )
}
