import React from 'react'
import RoleItem from '../items/role-item'
import styles from './layout-items.module.scss'

const ListRole = ({
  _activeRole,
  _updateRole,
  _checkValueRole,
  _refreshValueRole,
  _deleteValueRole,
  roles,
  roleCheck,
}) => {
  return (
    <div>
      {roleCheck.map((data, index) => (
        <div className={styles['role-data']} key={index}>
          <RoleItem
            _activeRole={_activeRole}
            _updateRole={_updateRole}
            _checkValueRole={_checkValueRole}
            _refreshValueRole={_refreshValueRole}
            _deleteValueRole={_deleteValueRole}
            data={data}
            roleCheck={roleCheck}
          />
        </div>
      ))}
    </div>
  )
}

export default ListRole
