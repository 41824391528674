import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES, PERMISSIONS } from 'consts'
const ConnectStatus = ({ record }) => {
  const statusLinks = [
    {
      status: 'import',
      pathname: `${ROUTES.IMPORT_INVENTORY_UPDATE + '/' + record.code_source}`,
    },
    {
      status: 'export',
      pathname: `${ROUTES.ORDER_UPDATE + '/' + record?.order_info?.order_id}`,
    },
    { status: 'balance-import-product', pathname: `${ROUTES.STOCK_ADJUSTMENTS_UPDATE + '/' + record.code_source}` },
    { status: 'balance-export-product', pathname: `${ROUTES.STOCK_ADJUSTMENTS_UPDATE + '/' + record.code_source}` },
    { status: 'balance-import-product-advanced', pathname: `${ROUTES.STOCK_ADJUSTMENTS_ADVANCED_UPDATE + '/' + record.code_source}` },
    { status: 'balance-export-product-advanced', pathname: `${ROUTES.STOCK_ADJUSTMENTS_ADVANCED_UPDATE + '/' + record.code_source}` },
    { status: 'transport-import-product', pathname: `${ROUTES.SHIPPING_PRODUCT_UPDATE + '/' + record.code_source}` },
    { status: 'transport-export-product', pathname: `${ROUTES.SHIPPING_PRODUCT_UPDATE + '/' + record.code_source}` },
    { status: 'import-refund', pathname: `${ROUTES.REFUND_DETAIL + '/' + record.code_source}` },
    { status: 'import-smart', pathname: `${ROUTES.IMPORT_INVENTORY_UPDATE + '/' + record.code_source}` },

  ]

  return (
    <Link
      permissions={[PERMISSIONS.chi_tiet_san_pham]}
      style={{color:'green'}}
      to={{
        pathname: Object(statusLinks.find((item) => item.status === record.type)).pathname,
      }}
      target='_blank'
    >
      {'#' + record.code_source}
    </Link>
  )
}

export default ConnectStatus
