import React, { useEffect, useState } from 'react'
import styles from '../sell.module.scss'
import { formatCash, removeUnicode } from 'utils'
import { IMAGE_DEFAULT } from 'consts'
import noData from 'assets/icons/no-data.png'
import { DetailProductModal } from '../items/detailProductModal'
//antd
import { Row, Spin, Badge, Space, Input, Tag, Pagination, Layout, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import FilterProductsByCategory from '../filter-by-category'
import { SearchOutlined } from '@ant-design/icons'
import { getLocationExpiry } from 'apis/location'
import { useSelector } from 'react-redux'
import { each, isBuffer, sortBy } from 'lodash'
import moment from 'moment'

const ProductsRelated = (props) => {
  const {
    loadingProductRelated,
    productsRelated,
    scrollSmoothHandler,
    invoices,
    indexInvoice,
    _editProductInInvoices,
    _addProductToCartInvoice,
    showProductRelated,
    changeProductOfBranch
  } = props
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const [locationExpiry, getLocationexpiry] = useState([])
  const [products, setProducts] = useState([])
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 15 })
  const [countProducts, setCountProducts] = useState()
  const _getLocationExpiry = async (variant_id) => {
    try {
      const res = await getLocationExpiry({ variant_id: variant_id, branch_id: branchIdApp })
      if (res.status == 200) {
        let new_data = sortBy(res.data.data, [
          (o) => {
            return o.exp
          },
        ])
        getLocationexpiry(new_data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const [currentPage, setCurrentPage] = useState(1)
  const [valueSeach, setValueSeach] = useState('')

  const _getProductsRelated = (data, page, categorys) => {
    try {
      let productTmp = []
      let newsList = []
      if (data === '' && categorys.length === 0) {
        productTmp = [...productsRelated]
      } else {
        if (data !== '' && categorys.length === 0) {
          productTmp = productsRelated.filter(
            (e) =>
              removeUnicode(e.title.toString())
                .toLowerCase()
                .trim()
                .includes(removeUnicode(data).toLowerCase().trim()) ||
              removeUnicode(e.sku.toString())
                .toLowerCase()
                .includes(removeUnicode(data).toLowerCase())
          )
        } else {
          for (let i in categorys) {
            let a = productsRelated.filter((e) => e?.category_id?.includes(categorys[i]))
            for (let i in a) {
              let index = productTmp.findIndex((e) => e.variant_id === a[i].variant_id)
              if (index === -1) productTmp.push(a[i])
            }
          }
        }
      }
      let current = page * 15 - 15
      newsList = productTmp.splice(current, 15)
      setProducts(newsList)
      setCountProducts(productTmp.length)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (productsRelated.length > 0) _getProductsRelated('', 1, [])
  }, [productsRelated, branchIdApp])
  const { t } = useTranslation()
  return (
    <div
      style={{
        height: showProductRelated ? '52%' : '60px',
        transition: 'height 1s',
        overflow: 'visible',
      }}
      className={styles['sell-products-related']}
    >
      <Row justify="space-between" align="middle">
        <Space size="middle">
          <FilterProductsByCategory
            setParamsFilter={setParamsFilter}
            paramsFilter={paramsFilter}
            _getProductsRelated={_getProductsRelated}
            valueSearchData={valueSeach}
          />
          <Input
            style={{ width: 270 }}
            placeholder="Nhập tên sản phẩm/ mã sản phẩm"
            suffix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.45)',
                }}
              />
            }
            onChange={(e) => {
              let data = e.target.value
              data = data.replace(
                /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/g,
                ''
              )
              setTimeout(() => {
                paramsFilter.page = 1
                if (data) {
                  paramsFilter.search = data
                } else {
                  data = ''
                  delete paramsFilter.search
                }
                setValueSeach(data)
                setParamsFilter({ ...paramsFilter })
                setTimeout(_getProductsRelated(data, 0, []), 2000)
              }, 200)
              //khi search hoặc filter thi reset page ve 1
            }}
          />

          <Row align="middle" wrap={false}>
            {paramsFilter &&
              Object.keys(paramsFilter).map((key) => {
                if (key === 'category_id')
                  return (
                    <Tag
                      closable
                      onClose={() => {
                        delete paramsFilter.category_id
                        setParamsFilter({ ...paramsFilter })
                      }}
                    >
                      {t('sell.filtering_by_product_group')}
                    </Tag>
                  )

                if (key === 'attribute')
                  return (
                    <Tag
                      closable
                      onClose={() => {
                        delete paramsFilter.attribute
                        setParamsFilter({ ...paramsFilter })
                      }}
                    >
                      {t('sell.filtering_by_attribute')}
                    </Tag>
                  )
              })}
          </Row>
        </Space>
        <Pagination
          current={currentPage}
          size="small"
          showSizeChanger={false}
          total={countProducts}
          pageSize={15}
          onChange={(page, pageSize) => {
            setCurrentPage(page)
            _getProductsRelated(valueSeach, page, [])
          }}
        />
      </Row>
      <div
        style={{
          height: showProductRelated ? '100%' : 0,
          transition: 'height 1s, visibility 0.5s, opacity 0.5s linear',
          visibility: showProductRelated ? 'visible' : 'hidden',
          opacity: showProductRelated ? 1 : 0,
        }}
        className={styles['list-product-related']}
      >
        {loadingProductRelated ? (
          <Row
            justify="center"
            align="middle"
            style={{
              width: '100%',
              height: showProductRelated ? '320px' : 0,
            }}
          >
            <Spin />
          </Row>
        ) : products.length ? (
          <Row wrap={true}>
            {products.map((product, index) => (
              <div key={index} className={styles['product-item-wrap']}>
                <div
                  style={{
                    borderColor:
                      (product?.total_quantity === 0 &&
                        product?.inventory === 'advanced' &&
                        'red') ||
                      (product?.type_advanced === 2 && '#3704ff') ||
                      (product?.type_advanced === 3 && '#86d71c'),
                    position: 'relative',
                  }}
                  onClick={async (e) => {
                    scrollSmoothHandler(product.variant_id)
                    let indexProduct = invoices[indexInvoice].order_details.findIndex(
                      (e) => e.variant_id === product.variant_id
                    )
                    let quantityCurrent = 0
                    if (indexProduct !== -1) {
                      quantityCurrent = invoices[indexInvoice].order_details[indexProduct].quantity
                    }
                    if (indexProduct !== -1) {
                      if (product.inventory === 'nomal') {
                        _editProductInInvoices(
                          'quantity',
                          quantityCurrent + 1,
                          indexProduct,
                          indexInvoice
                        )
                      }
                      if (product.type_advanced === 1) {
                        if (quantityCurrent >= product.total_quantity) {
                          return notification.warning({
                            key: 'max_quantity',
                            message: `Sản phẩm ${product.title} (Có thể bán: ${product.total_quantity}), vui lòng nhập thêm hàng để tiếp tục thao tác.`,
                          })
                        }
                        _editProductInInvoices(
                          'quantity',
                          quantityCurrent + 1,
                          indexProduct,
                          indexInvoice
                        )
                      }
                      if (product.type_advanced === 2) {
                        let listLocationOld =
                          invoices[indexInvoice].order_details[indexProduct].locations_new
                        let today = moment().startOf('date').format('YYYY-MM-DD')
                        let new_arr = product?.location_advanced?.location.reduce(
                          ({ sum, arr }, item) => {
                            if (item.exp >= today && item.quantity > 0) {
                              sum = sum + item.quantity
                              arr.push(item)
                            }
                            return { sum, arr }
                          },
                          { sum: 0, arr: [] }
                        )
                        let new_data = sortBy(new_arr.arr, [
                          (o) => {
                            return o.exp
                          },
                        ])
                        if (quantityCurrent >= new_arr.sum) {
                          return notification.warning({
                            key: 'max_quantity',
                            message: `Sản phẩm ${product.title} (Có thể bán: ${new_arr.sum}), vui lòng nhập thêm hàng để tiếp tục thao tác.`,
                          })
                        }

                        if (new_data.length === 0) {
                          return notification.warning({
                            key: 'not_location',
                            message: `Vui lòng nhập thêm lô để tiếp tục!`,
                          })
                        } else {
                          let new_data_location = []
                          if (listLocationOld.length === 0) {
                            new_data_location.push({
                              location_id: new_data[0].location_id,
                              quantity: 1,
                              code: new_data[0].code,
                              exp: new_data[0].exp,
                            })
                          } else {
                            let quantityOld = listLocationOld.reduce((sum, item) => sum + item.quantity, 0)
                            let new_quantity = quantityCurrent + 1
                            new_data_location = new_data.reduce((arr, current) => {
                              if (new_quantity > 0) {
                                let locationOld = listLocationOld.find(
                                  (e) => e.location_id === current.location_id
                                )
                                if (locationOld) {
                                  let obj = {
                                    location_id: current?.location_id,
                                    quantity: current.quantity === locationOld.quantity ? locationOld.quantity : new_quantity === quantityOld ? locationOld.quantity : locationOld.quantity + 1,
                                    code: current?.code,
                                    exp: current?.exp,
                                  }
                                  arr.push(obj)
                                  new_quantity -= current.quantity === locationOld.quantity ? locationOld.quantity : new_quantity === quantityOld ? locationOld.quantity : locationOld.quantity + 1
                                  quantityOld -= locationOld.quantity 
                                }else{
                                  if(new_quantity > quantityOld){
                                    let obj = {
                                      location_id: current?.location_id,
                                      quantity: 1,
                                      code: current?.code,
                                      exp: current?.exp,
                                    }
                                    arr.push(obj)
                                    new_quantity -= 1
                                  }
                                }
                              }
                              return arr
                            }, [])
                            
                          }
                          _editProductInInvoices(
                            'locations_new',
                            new_data_location,
                            indexProduct,
                            indexInvoice
                          )
                        }
                      }
                      if (product.type_advanced === 3) {
                        if (quantityCurrent >= product.total_quantity) {
                          return notification.warning({
                            key: 'max_quantity',
                            message: `Sản phẩm ${product.title} (Có thể bán: ${product.total_quantity}), vui lòng nhập thêm hàng để tiếp tục thao tác.`,
                          })
                        }
                        _editProductInInvoices(
                          'quantity',
                          quantityCurrent + 1,
                          indexProduct,
                          indexInvoice
                        )
                      }
                    } else {
                      _addProductToCartInvoice(product)
                    }
                    e.stopPropagation()
                  }}
                  className={styles['product-item']}
                >
                  <img
                    src={product.image[0] ? product.image[0] : IMAGE_DEFAULT}
                    alt=""
                    style={{
                      width: '100%',
                      height: '9.9rem',
                      objectFit: product.image[0] ? 'cover' : 'contain',
                      padding: '1rem',
                    }}
                  />
                  <div
                    style={{
                      background: '#5f73e2',
                      position: 'absolute',
                      width: '100%',
                      height: '33%',
                      bottom: '0px',
                      borderRadius: '0px 0px 10px 10px',
                    }}
                  >
                    <Row
                      justify="space-between"
                      wrap={false}
                      align="middle"
                      style={{ paddingLeft: 5, paddingRight: 5, marginTop: 3 }}
                    >
                      <span className={styles['product-item__name']}>{product.title}</span>
                      <Badge.Ribbon
                        text="pre-order"
                        style={{
                          right: '-14px',
                          top: '-500%',
                          display: !product.is_pre_order && 'none',
                        }}
                      >
                        <DetailProductModal
                          product={product}
                          addProductToCartInvoice={_addProductToCartInvoice}
                          changeProductOfBranch={changeProductOfBranch}
                        />
                      </Badge.Ribbon>
                    </Row>
                    <Row justify="space-between" wrap={false} align="middle">
                      <span className={styles['product-item__price']}>
                        {formatCash(product.price)} VNĐ
                      </span>
                      <span className={styles['product-item__price']}>
                        {product.active ? (
                          t('sell.on_sale')
                        ) : (
                          <div
                            style={{ color: '#FF6666' }}
                            className={styles['product-item__price']}
                          >
                            {t('sell.sale_off')}
                          </div>
                        )}
                      </span>
                    </Row>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        ) : (
          <div
            style={{
              height: 320,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={noData} alt="" style={{ width: 100 }} />
            <h3>{t('sell.no_product_found')}</h3>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductsRelated
