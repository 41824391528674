import React, { useEffect, useState, useCallBack } from 'react'
import styles from 'views/stock-adjustments-advanced-form/stock-adjustments.module.scss'

import { Row, Modal, Button, Typography, Popover, Col, List, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

//apis
import { getLocationImei } from 'apis/location'

export default function QuantityImei({ product, index, variant_id, _edit }) {
  const { Text } = Typography
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [imeiTotal, setImeiTotal] = useState([])
  const [imeiTotalCheck, setImeiTotalCheck] = useState([])

  const _getLocationImei = async () => {
    try {
      const res = await getLocationImei({ variant_id: variant_id, branch_id: branchIdApp })
      if (res.status == 200) {
        let new_arr = res.data.data.imei
        if(product.imei_check.length > 0){
            product.imei_check.map(e => {
                let index = new_arr.findIndex(item => item == e)
                new_arr.splice(index, 1)
          })}
          setImeiTotalCheck(product.imei_check)
          setImeiTotal(new_arr)

      }
    } catch (err) {
      console.log(err)
    }
  }
  const _changeImei = (value, index) => {
    let imei_new = [...imeiTotal]
    let imei_check_new = [...imeiTotalCheck]

    imei_new.splice(index, 1)
    imei_check_new.push(value)
    setImeiTotal(imei_new)
    setImeiTotalCheck(imei_check_new)
  }
  const _removeImei = (value, index) => {
    let imei_new = [...imeiTotal]
    let imei_check_new = [...imeiTotalCheck]

    imei_check_new.splice(index, 1)
    imei_new.push(value)
    setImeiTotal(imei_new)
    setImeiTotalCheck(imei_check_new)
  }
  const handeChangeQuantity = () => {
    let sum = imeiTotalCheck.length
    _edit(
      'real_quantity',
      sum,
      index
    )
    _edit(
      'imei_check',
      imeiTotalCheck,
      index
    )
    toggle()
    setImeiTotal([])
    setImeiTotalCheck([])
  }

  return (
    <>
      <div
        onClick={() => {
          toggle()
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          color: 'gray',
          cursor: 'pointer',
        }}
      >
        <Button
        onClick={async (e) => {
          e.preventDefault()
          await _getLocationImei()
        }}
        size="small"
        style={{
          width: 120,
          borderRadius: '10px',
          boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
          transition: 'all .3s ease-in',
        }}
      >
        NHẬP IMEI
      </Button>
        <EditOutlined style={{ marginLeft: 5 }} />
      </div>
      <Modal
        title={`Tồn kho của sản phẩm ${product.title}`}
        width={800}
        closable={false}
        visible={visible}
        footer={[
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button
                onClick={() => {
                  toggle()
                  setImeiTotal([])
                  setImeiTotalCheck([])
                }}
              >
                Đóng
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  handeChangeQuantity()
                }}
              >
                Xác nhận
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>

            <h3>Danh sách imei</h3>
            <List
              style={{
                overflow: 'auto',
                height: '500px',
                border: '1px solid #d9d9d9',
                borderRadius: '10px',
                padding: '10px',
              }}
              itemLayout="horizontal"
              dataSource={imeiTotal}
              renderItem={(item, index) => (
                <List.Item>
                  <Space className={styles['btnShift']} onClick={() => _changeImei(item, index)}>
                    {item}
                  </Space>
                </List.Item>
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>

            <h3>Imei tồn</h3>
            <List
               style={{
                overflow: 'auto',
                height: '500px',
                border: '1px solid #d9d9d9',
                borderRadius: '10px',
                padding: '10px',
              }}
              itemLayout="horizontal"
              dataSource={imeiTotalCheck}
              renderItem={(item, index) => (
                <List.Item>
                  <Space className={styles['btnShift']} onClick={() => _removeImei(item, index)}>
                    {item}
                  </Space>
                </List.Item>
              )}
            />
           
          </Col>
        </Row>
      </Modal>
    </>
  )
}
