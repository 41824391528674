const columns = [
  { title: 'STT', key: 'stt',align:'center' },
  { title: 'Code', key: 'code' },
  { title: 'Người tạo', key: 'creator'},
  { title: 'Ngày tạo', key: 'create_date' },
  { title: 'Nhân viên trong ca	', key: 'employee' },
  { title: 'Doanh thu', key: 'turnover' },
  { title: 'Tổng thu chi', key: 'sumReven' },
  { title: 'Tiền mặt theo hệ thống', key: 'systemCash' },
  { title: 'Tiền mặt thực tế', key: 'realCash' },
  { title: 'Trạng thái', key: 'status' },
  {title: 'Ghi chú', key: 'note'},
]

export default columns
