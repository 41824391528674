import { Form, Input, TimePicker, InputNumber, Select, DatePicker } from 'antd'
import styled from 'styled-components'
import styles from './Form.module.scss'
import dayjs from 'dayjs';

const FormItemStyled = styled(Form.Item)`
  .ant-form-item-required {
    font-weight: 600;
    font-size: 12px;
    color: #6c757d;
  }
`
const SelectForm = styled(Select)`
  .ant-select-selector {
    height: 100% !important;
    align-items: center;
  }
`

const InputForm = ({ classes = '', ...rest }) => (
  <Input className={`${styles.form__input} ${classes}`} {...rest} />
)

const InputNumberForm = ({ classes = '',...rest }) => (
  <InputNumber className={`${styles.form__input} ${classes}`} {...rest}/>
)

const FormItem = ({ classes = '', ...rest }) => (
  <FormItemStyled className={`${styles.form__item} ${classes}`} {...rest} />
)

const TimePickerRangePicker = ({ classes = '',...rest }) => (
  <TimePicker.RangePicker className={`${styles.form__input} ${classes}`} {...rest} />
)

const DatePickerRangePicker = ({ classes = '', ...rest }) => (
  <DatePicker.RangePicker className={`${styles.form__input} ${classes}`} {...rest} />
)
export {
  FormItem,
  InputForm,
  InputNumberForm,
  TimePickerRangePicker,
  SelectForm,
  DatePickerRangePicker,
}
