const initialState = {
    check: false,
    link: '',
    invoice: []
}
let connect_device = (state = initialState, action) => {
    switch (action.type) {
        case 'VALID_CONNECT': {
            return {...state, check: true, link: action.payload}
        }
        case 'SET_INVOICE_SCREEN': {
            console.log(action.payload)
            return {...state, invoice: action.payload}
        }
        default:
            return state
    }
}
export default connect_device
