import moment from 'moment'
import { PERMISSIONS, TIME_FORMAT } from 'consts'

export function DefaultFilterData(role_id, filterDate, data) {
  let results = []
  let obj = {}
  let defaultTime = {
    from_date: '',
    to_date: '',
  }
  let valueDate = ''
  if (role_id === 1) {
    results = data
    defaultTime.from_date = moment().subtract(29, 'days').unix()
    defaultTime.to_date = moment().endOf('days').unix()
    valueDate = `${moment().subtract(29, 'days').format(TIME_FORMAT.DATE)} -> ${moment()
      .endOf('days')
      .format(TIME_FORMAT.DATE)}`
  } else if (filterDate.includes(PERMISSIONS.bo_loc_thoi_gian)) {
    if (filterDate.includes(PERMISSIONS.nam_truoc)) {
      obj['last_year'] = true
      obj['last_year_start'] = moment().subtract(1, 'years').startOf('years').unix()
      obj['last_year_end'] = moment().subtract(1, 'years').endOf('years').unix()
      defaultTime.from_date = moment().subtract(1, 'years').startOf('years').unix()
      defaultTime.to_date = moment().subtract(1, 'years').endOf('years').unix()
      valueDate = 'last_year'
    }
    if (filterDate.includes(PERMISSIONS.nam_nay)) {
      obj['year'] = true
      obj['year_start'] = moment().startOf('years').unix()
      obj['year_end'] = moment().endOf('years').unix()
      defaultTime.from_date = moment().startOf('years').unix()
      defaultTime.to_date = moment().endOf('years').unix()
      valueDate = 'this_year'
    }
    if (filterDate.includes(PERMISSIONS.thang_truoc)) {
      obj['last_month'] = true
      obj['last_month_start'] = moment().subtract(1, 'months').startOf('months').unix()
      obj['last_month_end'] = moment().subtract(1, 'months').endOf('months').unix()
      defaultTime.from_date = moment().subtract(1, 'months').startOf('months').unix()
      defaultTime.to_date = moment().subtract(1, 'months').endOf('months').unix()
      valueDate = 'last_month'
    }
    if (filterDate.includes(PERMISSIONS.thang_nay)) {
      obj['month'] = true
      obj['month_start'] = moment().startOf('months').unix()
      obj['month_end'] = moment().endOf('months').unix()
      defaultTime.from_date = moment().startOf('months').unix()
      defaultTime.to_date = moment().endOf('months').unix()
      valueDate = 'this_month'
    }
    if (filterDate.includes(PERMISSIONS.tuan_truoc)) {
      obj['last_week'] = true
      obj['last_week_start'] = moment().subtract(1, 'weeks').startOf('weeks').unix()
      obj['last_week_end'] = moment().subtract(1, 'weeks').endOf('weeks').unix()
      defaultTime.from_date = moment().subtract(1, 'weeks').startOf('weeks').unix()
      defaultTime.to_date = moment().subtract(1, 'weeks').endOf('weeks').unix()
      valueDate = 'last_week'
    }
    if (filterDate.includes(PERMISSIONS.tuan_nay)) {
      obj['week'] = true
      obj['week_start'] = moment().startOf('weeks').unix()
      obj['week_end'] = moment().endOf('weeks').unix()
      defaultTime.from_date = moment().startOf('weeks').unix()
      defaultTime.to_date = moment().endOf('weeks').unix()
      valueDate = 'this_week'
    }
    if (filterDate.includes(PERMISSIONS.hom_qua)) {
      obj['yesterday'] = true
      obj['yesterday_start'] = moment().subtract(1, `days`).startOf('days').unix()
      obj['yesterday_end'] = moment().subtract(1, `days`).endOf('days').unix()
      defaultTime.from_date = moment().subtract(1, `days`).startOf('days').unix()
      defaultTime.to_date = moment().subtract(1, `days`).endOf('days').unix()
      valueDate = 'yesterday'
    }
    if (filterDate.includes(PERMISSIONS.hom_nay)) {
      obj['day'] = true
      obj['day_start'] = moment().startOf('days').unix()
      obj['day_end'] = moment().endOf('days').unix()
      defaultTime.from_date = moment().startOf('days').unix()
      defaultTime.to_date = moment().endOf('days').unix()
      valueDate = 'today'
    }

    if (filterDate.includes(PERMISSIONS.tuy_chon)) {
      obj['all'] = true
      defaultTime.from_date = moment().subtract(29, 'days').unix()
      defaultTime.to_date = moment().endOf('days').unix()
      valueDate = `${moment().subtract(29, 'days').format(TIME_FORMAT.DATE)} -> ${moment()
        .endOf('days')
        .format(TIME_FORMAT.DATE)}`
    }
    if (obj.all) {
      results = data
    } else {
      results = data.filter((item) => {
        return (
          (moment(item.create_date).unix() >= obj.day_start &&
            moment(item.create_date).unix() <= obj.day_end) ||
          (moment(item.create_date).unix() >= obj.yesterday_start &&
            moment(item.create_date).unix() <= obj.yesterday_end) ||
          (moment(item.create_date).unix() >= obj.week_start &&
            moment(item.create_date).unix() <= obj.week_end) ||
          (moment(item.create_date).unix() >= obj.last_week_start &&
            moment(item.create_date).unix() <= obj.last_week_end) ||
          (moment(item.create_date).unix() >= obj.month_start &&
            moment(item.create_date).unix() <= obj.month_end) ||
          (moment(item.create_date).unix() >= obj.last_month_start &&
            moment(item.create_date).unix() <= obj.last_month_end) ||
          (moment(item.create_date).unix() >= obj.year_start &&
            moment(item.create_date).unix() <= obj.year_end) ||
          (moment(item.create_date).unix() >= obj.last_year_start &&
            moment(item.create_date).unix() <= obj.last_year_end)
        )
      })
    }
  }
  return { data: results, defaultTime: defaultTime, valueDate: valueDate }
}
export function DefaultTimeFilter(role_id, filterDate) {
  let from_date = '',
    to_date = '',
    valueDate = ''
  if (role_id === 1) {
      from_date = moment().subtract(29, 'days').format(TIME_FORMAT.FILTER)
      to_date = moment().endOf('days').format(TIME_FORMAT.FILTER)
      valueDate = `${moment().subtract(29, 'days').format(TIME_FORMAT.DATE)} -> ${moment()
        .endOf('days')
        .format(TIME_FORMAT.DATE)}`
  } else if (filterDate.includes(PERMISSIONS.bo_loc_thoi_gian)) {
    if (filterDate.includes(PERMISSIONS.nam_truoc)) {
      from_date = moment().subtract(1, 'years').startOf('years').format(TIME_FORMAT.FILTER)
      to_date = moment().subtract(1, 'years').endOf('years').format(TIME_FORMAT.FILTER)
      valueDate = 'last_year'
    }
    if (filterDate.includes(PERMISSIONS.nam_nay)) {
      from_date = moment().startOf('years').format(TIME_FORMAT.FILTER)
      to_date = moment().endOf('years').format(TIME_FORMAT.FILTER)
      valueDate = 'this_year'
    }
    if (filterDate.includes(PERMISSIONS.thang_truoc)) {
      from_date = moment().subtract(1, 'months').startOf('months').format(TIME_FORMAT.FILTER)
      to_date = moment().subtract(1, 'months').endOf('months').format(TIME_FORMAT.FILTER)
      valueDate = 'last_month'
    }
    if (filterDate.includes(PERMISSIONS.thang_nay)) {
      from_date = moment().startOf('months').format(TIME_FORMAT.FILTER)
      to_date = moment().endOf('months').format(TIME_FORMAT.FILTER)
      valueDate = 'this_month'
    }
    if (filterDate.includes(PERMISSIONS.tuan_truoc)) {
      from_date = moment().subtract(1, 'weeks').startOf('weeks').format(TIME_FORMAT.FILTER)
      to_date = moment().subtract(1, 'weeks').endOf('weeks').format(TIME_FORMAT.FILTER)
      valueDate = 'last_week'
    }
    if (filterDate.includes(PERMISSIONS.tuan_nay)) {
      from_date = moment().startOf('weeks').format(TIME_FORMAT.FILTER)
      to_date = moment().endOf('weeks').format(TIME_FORMAT.FILTER)
      valueDate = 'this_week'
    }
    if (filterDate.includes(PERMISSIONS.hom_qua)) {
      from_date = moment().subtract(1, `days`).startOf('days').format(TIME_FORMAT.FILTER)
      to_date = moment().subtract(1, `days`).endOf('days').format(TIME_FORMAT.FILTER)
      valueDate = 'yesterday'
    }
    if (filterDate.includes(PERMISSIONS.hom_nay)) {
      from_date = moment().startOf('days').format(TIME_FORMAT.FILTER)
      to_date = moment().endOf('days').format(TIME_FORMAT.FILTER)
      valueDate = 'today'
    }

    if (filterDate.includes(PERMISSIONS.tuy_chon)) {
      from_date = moment().subtract(29, 'days').format(TIME_FORMAT.FILTER)
      to_date = moment().endOf('days').format(TIME_FORMAT.FILTER)
      valueDate = `${moment().subtract(29, 'days').format(TIME_FORMAT.DATE)} -> ${moment()
        .endOf('days')
        .format(TIME_FORMAT.DATE)}`
    }

  }

  return { from_date, to_date, valueDate }
}
