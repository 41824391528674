let localStorage = {
    buildKey(key) {
        let version = process.env.REACT_APP_VERSION;
        return ['app', version, key].join('_');
    },

    clear() {
        window.localStorage.clear();
    },

    getItem(key) {
        const keyVersion = this.buildKey(key);
        return window.localStorage.getItem(keyVersion);
    },

    removeItem(key) {
        const keyVersion = this.buildKey(key);
        return window.localStorage.removeItem(keyVersion);
    },

    setItem(key, value) {
        const keyVersion = this.buildKey(key);
        return window.localStorage.setItem(keyVersion, value);
    }
};

export let asyncLocalStorage = {
    buildKey(key) {
        let version = process.env.REACT_APP_VERSION;
        return ['app', version, key].join('_');
    },

    async clear() {
        window.localStorage.clear();
    },

    async getItem(key) {
        const keyVersion = this.buildKey(key);
        return window.localStorage.getItem(keyVersion);
    },

    async removeItem(key) {
        const keyVersion = this.buildKey(key);
        return window.localStorage.removeItem(keyVersion);
    },

    async setItem(key, value) {
        const keyVersion = this.buildKey(key);
        return window.localStorage.setItem(keyVersion, value);
    }
}



export default localStorage;