import { getRowsExcel, startImportproduct } from 'apis/import-product'
import React, { useEffect, useState } from 'react'
import styles from '../import-product.module.css'
import { useDispatch, useSelector } from 'react-redux'
//antd
import { Button, Col, Radio, Row, Spin, Table } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ACTION } from 'consts'

export default function Step2({ filePath, submit, gotoStep, current, setDataError }) {
  const [excelRows, setExcelRows] = useState([])
  const [loading, setLoading] = useState(true)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dispatch = useDispatch()

  const sharedOnCell = (_) => {
    if (_.error === '{ERROR}') {
      return {
        colSpan: 0,
      }
    }

    return {}
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 60,
      colSpan: 1,
      align: 'center',
      render: (text, record) => (record.error === '{ERROR}' ? '' : text),
    },
    {
      title: 'Loại tồn kho',
      dataIndex: 'type_advanced',
      onCell: (_) => ({
        colSpan: _.error === '{ERROR}' ? 12 : 1,
      }),
      render: (text, record) =>
        record.error === '{ERROR}' ? (
          <span style={{ color: '#ff9800' }}>{record.error_msg}</span>
        ) : (
          text
        ),
    },
    {
      title: 'Tên sản phẩm (*)',
      dataIndex: 'sku',
      onCell: sharedOnCell,
    },
    {
      title: 'Tên sản phẩm (*)',
      dataIndex: 'name',
      onCell: sharedOnCell,
    },
    {
      title: 'Mã barcode',
      dataIndex: 'barcode',
      onCell: sharedOnCell,
    },
    {
      title: 'Mã phiên bản (*)',
      dataIndex: 'variant_sku',
      onCell: sharedOnCell,
    },
    {
      title: 'Tên phiên bản (*)',
      dataIndex: 'variant_name',
      onCell: sharedOnCell,
    },
    {
      title: 'Thuộc tính 1',
      dataIndex: 'att1',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá trị 1',
      dataIndex: 'value_att1',
      onCell: sharedOnCell,
    },
    {
      title: 'Thuộc tính 2 ',
      dataIndex: 'att2',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá trị 2',
      dataIndex: 'value_att2',
      onCell: sharedOnCell,
    },
    {
      title: 'Thuộc tính 3',
      dataIndex: 'att3',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá trị 3',
      dataIndex: 'value_att3',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá bán (*)',
      dataIndex: 'price',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá nhập hàng',
      dataIndex: 'import_price',
      onCell: sharedOnCell,
    },
    {
      title: 'Giá bán sỉ',
      dataIndex: 'whole_sale_price',
      onCell: sharedOnCell,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      onCell: sharedOnCell,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      onCell: sharedOnCell,
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'category',
      onCell: sharedOnCell,
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'supplier',
      onCell: sharedOnCell,
    },
    {
      title: 'Khối lượng (kg)',
      dataIndex: 'weight',
      onCell: sharedOnCell,
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'unit',
      onCell: sharedOnCell,
    },
    {
      title: 'Thẻ',
      dataIndex: 'tags',
      onCell: sharedOnCell,
    },
  ]

  async function _getRowsExcel(filePath) {
    dispatch({ type: ACTION.LOADING, data: true })
    setTimeout(async () => {
      setLoading(true)
      let res = await getRowsExcel(filePath)
      if (res.status == 200) {
        setExcelRows(res.data.data)
      }
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
    }, 5000)
  }
  async function submitProcessImportProduct() {
    const data = { filePath, branch_id: branchIdApp }
    const res = await startImportproduct(data)
    if (res.status === 200) {
      gotoStep(2)
      setDataError(res.data.data)
    }
  }

  useEffect(() => {
    _getRowsExcel(filePath)
  }, [filePath])

  return (
    <div className={styles['step-2']}>
      <div className={styles['step-2-header']}>
        <div>
          <Row justify="center" className={styles['group-button']}>
            <Col style={{ marginRight: 10 }}>
              <Button type="default" onClick={() => submit({ step: 0 })}>
                Quay lại
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={submitProcessImportProduct}>
                Thực hiện Import
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        {loading === true ? (
          <></>
        ) : (
          <>
            <p style={{ color: 'red' }}>
              Dưới đây là dữ liệu của 100 dòng đầu tiên trong file excel bạn vừa chọn. Vui lòng kiểm
              tra và xác nhận. Những dữ liệu cảnh báo nhưng bị thiếu sẽ được thêm vào. Những dữ liệu
              tồn tại sẽ không được thêm
            </p>
            <Table
              rowKey="phone"
              scroll={{ x: 2000, y: 500 }}
              columns={columns}
              dataSource={excelRows}
              size="large"
              pagination={false}
            />
          </>
        )}
      </div>
    </div>
  )
}
