import React, { useState, useEffect } from 'react'
import styles from './layout.module.scss'
import moment from 'moment'
import { TIME_FORMAT } from 'consts'
import { formatCash } from 'utils'
//antd
import { Modal, Form, Row, Col, Input, Button, Upload, notification, Select } from 'antd'

//icons
import { LoadingOutlined, PlusOutlined, CaretDownOutlined } from '@ant-design/icons'

//apis
import { updateEmployee } from 'apis/employee'
import { uploadFile } from 'apis/upload'

import {getDetailStaff} from 'apis/manage-staff'
import { useSelector } from 'react-redux'
const logo_app = require('../../assets/shops.png')

export default function ModalUpdateUser({ user, children, reload }) {
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState('')
  const [visible, setVisible] = useState(false)
  const [dataDetail, setDataDetail] = useState([])

  const toggle = () => setVisible(!visible)
  const _getDetailStaff = async () =>{
    try {
      let res = await getDetailStaff({branch_id: branchIdApp})
      if(res.status === 200){
        setDataDetail(res.data.data)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const _updateUser = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      const body = {
        ...dataForm,
        avatar: avatar,
      }
      setLoading(true)
      const res = await updateEmployee(body, user && user.user_id)
      if (res.status === 200) {
        if (res.data.success) {
          toggle()
          notification.success({ message: 'Cập nhật thông tin cá nhân thành công' })
          reload({ user_id: res.data.data.user_id })
        } else
          notification.error({
            message: res.data.message || 'Cập nhật thông tin cá nhân thành công',
          })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thông tin cá nhân thành công' })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      notification.warning({ message: 'Bạn chỉ có thể tải lên tệp JPG / PNG / JPEG!' })
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification.warning({ message: 'Hình ảnh phải có kích thước nhỏ hơn 2MB!' })
    }
    return isJpgOrPng && isLt2M
  }

  const _upload = async (file) => {
    try {
      setLoading(true)
      const url = await uploadFile(file)
      setAvatar(url || '')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (visible)
      if (user) {
        if (user.avatar == '')
          user.avatar =
            'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_08_08_1f472d1f-57ee-4d6f-9765-fbb946e4d595_shops.png'

        setAvatar(user.avatar)
        form.setFieldsValue({ ...user })
      }
  }, [visible])
  useEffect(() =>{
    _getDetailStaff()
  },[user])
  return (
    <>
      <div onClick={toggle}>{children}</div>
      <Modal
        width="60%"
        title="Thông tin cá nhân"
        footer={null}
        visible={visible}
        okText="Lưu"
        cancelText="Đóng"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <Row gutter={[16, 16]} align="middle" className={styles['item-info']}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              data={_upload}
              beforeUpload={beforeUpload}
            >
              <img src={avatar} alt="avatar" style={{ width: '100%' }} />
            </Upload>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} >
            <Row className={styles['item-info__row']}>
              <Col span={8}>Họ Tên:</Col>
              <Col span={16}>{user.first_name} {user.last_name}</Col>
            </Row>
            <Row className={styles['item-info__row']}>
              <Col span={8}>Bộ phận:</Col>
              <Col span={16}>{user._role.name}</Col>
            </Row>
            <Row className={styles['item-info__row']}>
              <Col span={6}>Ngày tham gia:</Col>
              <Col span={6}>{moment(user.created_at).format(TIME_FORMAT.DATE)}</Col>
             
              <Col span={7}>Tổng thời gian làm việc:</Col>
              <Col span={5}>{formatCash(dataDetail.total_working_time)}</Col>
              
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} align="middle" className={styles['item-info']}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Row className={styles['item-info__row']}>
              <Col span={14}>Mức lương:</Col>
              <Col span={10}>{formatCash(dataDetail.wage)}</Col>
            </Row>
            <Row className={styles['item-info__row']}>
              <Col span={14}>Nợ đầu kỳ:</Col>
              <Col span={10}>{formatCash(0)}</Col>
            </Row>
            <Row className={styles['item-info__row']}>
              <Col span={14}>Tổng đơn hàng:</Col>
              <Col span={10}>{formatCash(dataDetail?.countOrder)}</Col>
            </Row>
            <Row className={styles['item-info__row']}>
              <Col span={14}>Tổng công làm:</Col>
              <Col span={10}>{formatCash(dataDetail?.countShift)}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Select
              defaultValue={1}
              suffixIcon={<CaretDownOutlined />}
              style={{ marginBottom: 10, width: '50%', fontSize: '15px' }}
            >
              <Select.Option value={1}>Theo giờ</Select.Option>
              <Select.Option value={2}>Theo ngày</Select.Option>
              <Select.Option value={3}>Theo tuần</Select.Option>
              <Select.Option value={4}>Theo tháng</Select.Option>
              <Select.Option value={5}>Theo năm</Select.Option>

            </Select>
            <Row className={styles['item-info__row']}>
              <Col span={14}>Nợ phát sinh:</Col>
              <Col span={10}>{formatCash(0)}</Col>
            </Row>
            <Row className={styles['item-info__row']}>
              <Col span={14}>Tổng doanh số:</Col>
              <Col span={10}>{formatCash(dataDetail?.total)}</Col>
            </Row>
            <Row className={styles['item-info__row']}>
              <Col span={14}>Lương tạm tính:</Col>
              <Col span={10}>{formatCash(0)}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Row className={styles['item-info__row']}>
              <Col span={16}>Thời gian Check point:</Col>
              <Col span={4}>{formatCash(dataDetail.check_point_time)}</Col>
              <Col span={16}>Bonus:</Col>
              <Col span={8}>{formatCash(0)}</Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
