import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { ACTION, IMAGE_DEFAULT, PERMISSIONS, ROUTES, TIME_FORMAT } from 'consts'
import { formatCash, removeUnicode } from 'utils'
import { Link, useHistory, useParams } from 'react-router-dom'
import styles from './order-create-shipping.module.scss'
import { useReactToPrint } from 'react-to-print'

//components
import TitlePage from 'components/title-page'
import { socket } from '../../socket'
import { Select, Button, Input, Form, Row, Col, notification, Space, Spin, Tag } from 'antd'
import {
  ArrowLeftOutlined,
  CloseOutlined,
  SearchOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons'
import ImportTransport from './item/importTransport'
import { CSVLink } from 'react-csv'
import PrintTransport from '../../components/print/printTransport'
import { TableProduct } from './item/table-product'
import { getProductWarehouse } from 'redux/actions/productWarehouse'

//apis
import { getAllBranch } from 'apis/branch'
import {
  getTransportOrders,
  updateTransportOrder,
  updateTransportOrderComplete,
  cancel,
  updateNote,
  updateInfo,
} from 'apis/transport'

export default function ShippingProductAdd() {
  const id = useParams()
  const dispatch = useDispatch()
  const printerRef = useRef()
  const [form] = Form.useForm()
  const history = useHistory()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const [filteredResults, setFilteredResults] = useState([])
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [productQuantity, setProductQuantity] = useState([])
  const products = useSelector((state) => state.products.productWarehouse)
 

  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const [importLocation, setImportLocation] = useState({})
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState([])
  const [productsTransport, setProductsTransport] = useState([])
  const [productsError, setProductsError] = useState([])

  const [tags, setTags] = useState([])
  const [transport, setTransport] = useState()

  const headers = [
    { label: 'Lỗi', key: 'error' },
    { label: 'Mã sản phẩm', key: 'sku' },
    { label: 'Mã phiên bản', key: 'variant_sku' },
    { label: 'Số lượng', key: 'quantity' },
    { label: 'Mã lô', key: 'location_check' },
    { label: 'Serial/Imei', key: 'imei_check' },
  ]

  const handleChangeTags = (item) => {
    let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
    let new_arr = []
    for (let data of item) {
      if (regex.test(data) === false) {
        new_arr.push(data)
      }
    }
    new_arr = new_arr.map((e) => e.trim())
    setTags(new_arr)
  }

  const _editProductInTransport = (attribute, value, index) => {
    const productsTransportNew = [...productsTransport]
    productsTransportNew[index][attribute] = value
    setProductsTransport([...productsTransportNew])
  }
  const _addProductToTransport = (product) => {
    if (product.inventory === 'normal') {
      return notification.warning({
        key: 'warning_sell_normal_product',
        message: 'Sản phẩm tồn kho cơ bản không thể chuyển, vui lòng chọn sản phẩm khác!',
      })
    }
    const productsTransportNew = [...productsTransport]
    let index = productsTransportNew.findIndex((e) => e.variant_id === product.variant_id)
    if (index == -1) {
      if (product.type_advanced === 1) {
        product.quantity = 1
        productsTransportNew.push({
          ...product,
        })
      } else {
        product.quantity = 0
        productsTransportNew.push({
          ...product,
          imei: [],
          locations: [],
        })
      }
    } else {
      if(productsTransportNew[index].quantity < productsTransportNew[index].total_quantity){
        productsTransportNew[index].quantity += 1
      }else {
        return notification.warning({
          key: 'quantity_maximun',
          message: 'Số lượng chuyển của sản phẩm đã tối đa!',
        })
      }
    }
    setProductsTransport([...productsTransportNew])
  }
  const _importProduct = (value) => {
    dispatch({ type: ACTION.LOADING, data: true })
    if (!value) return
    const productsTransportNew = [...productsTransport]
    let newProductError = [...productsError]
    value.map((item) => {
      let product = products.find((product) => product.sku === item.variant_sku)
      let index = productsTransportNew.findIndex((e) => e.variant_id === product.variant_id)
      if (index == -1) {
        if (product?.type_advanced === 1) {
          productsTransportNew.push({
            ...product,
            quantity: item.quantity, //số lượng sản phẩm
          })
        } else {
          productsTransportNew.push({
            ...product,
            imei: item.imei_check || [],
            locations: item.location_check || [],
            quantity: item.quantity, //số lượng sản phẩm
          })
        }
      } else {
        let objectProduct = {
          error: `Phiên bản sản phẩm đã được thêm vào danh sách sản phẩm chuyển`,
          sku: item.sku || '', // mã sản phẩm
          variant_sku: item.variant_sku || '', //mã phiên bản sản phẩm
          quantity: item.quantity || '', //số lượng nhập
          location_check: item.location_check || '',
          imei_check: item.imei_check || '',
        }
        newProductError.push(objectProduct)
      }
    })
    setProductsTransport([...productsTransportNew])
    setProductsError(newProductError)
    dispatch({ type: ACTION.LOADING, data: false })
  }
  const _getTransportOrders = async () => {
    try {
      setLoading(true)
      const res = await getTransportOrders({ order_id: id.code, branch_id: branchIdApp,detail:1 })
      if (res.status === 200 && res.data.data[0]) {
        setTransport(res.data.data[0])
        form.setFieldsValue({
          note: res.data.data[0].note || '',
          tags: res.data.data[0].tags || [],
          delivery_time: res.data.data[0].delivery_time
            ? moment(res.data.data[0].delivery_time)
            : null,
          export_location: res.data.data[0].export_location || '',
          import_location: res.data.data[0].import_location || '',
        })
        setTags(res.data.data[0].tags || [])
        setImportLocation(res.data.data[0].import_location)
        setProductsTransport(
          res.data.data[0].products.map((e) => {
            return {
              ...e,
              quantity: e.quantity,
            }
          })
        )
      } else {
        history.push(ROUTES.SHIPPING_PRODUCT)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    _getTransportOrders()
    dispatch(getProductWarehouse({ branchIdApp: branchIdApp }))
    _getBranches()
  }, [id, branchIdApp])
  const _acceptTransportOrderComplete = async (status, id) => {
    try {
      await form.validateFields()
      setLoading(true)
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        status: status,
      }
      const res = await updateTransportOrderComplete(body, id)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: res.data.message || 'Cập nhật phiếu chuyển hàng thành công!' })
          // _getTransportOrders()
          // socketListener()
          history.push(ROUTES.SHIPPING_PRODUCT)
        } else
          notification.error({
            message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại!!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại!!',
        })
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _cancelTransport = async (status, id) => {
    try {
      await form.validateFields()
      setLoading(true)
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        status: status,
      }
      const res = await cancel(body, id)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật phiếu chuyển hàng thành công!' })
          // _getTransportOrders()
          // socketListener()
          history.push(ROUTES.SHIPPING_PRODUCT)
        } else
          notification.error({
            message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại!!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại!!',
        })
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      setLoading(false)
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _addOrEditTransportOrder = async () => {
    await form.validateFields()
    try {
      const dataForm = form.getFieldsValue()

      if (productsTransport.length === 0) {
        notification.warning({ message: 'Vui lòng chọn sản phẩm cần chuyển hàng!' })
        return
      }

      if (branchIdApp === importLocation) {
        notification.warning({
          message: 'Chi nhánh chuyển và chi nhánh nhận không thể trùng nhau!',
        })
        return
      }

      for (let product of productsTransport) {
        if (product.quantity === 0) {
          return notification.error({
            message: `Vui lòng nhập số lượng chuyển của sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 2 && product?.locations?.length > 0) {
          let quantity = product?.locations?.reduce((total, item) => (total += item.quantity), 0)

          if (product.quantity !== quantity) {
            return notification.error({
              message: `${product.title} không đủ số lượng chuyển`,
            })
          }
        }
        if (product.type_advanced === 2 && !product.locations) {
          return notification.error({
            message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 2 && product?.locations?.length === 0) {
          return notification.error({
            message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          })
        }
      }
      for (let product of productsTransport) {
        if (product.type_advanced === 3 && product?.imei?.length > 0) {
          if (product.quantity > product?.imei?.length) {
            return notification.error({
              message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
            })
          }
          if (product.quantity < product?.imei?.length) {
            return notification.error({
              message: `${product.title} không đủ số lượng chuyển`,
            })
          }
        }
        if (product.type_advanced === 3 && !product.imei) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 3 && product?.imei?.length === 0) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
      }

      dispatch({ type: ACTION.LOADING, data: true })
      let res
      if (transport?.status === 'DRAFT') {
        const body = {
          note: dataForm.note || '',
          tags: tags || [],
          branch_id: branchIdApp,
          export_location: branchIdApp,
          import_location: dataForm.import_location,
          products: productsTransport.map((e) => ({
            product_id: e.product_id,
            variant_id: e.variant_id,
            title: e.title,
            sku: e.sku,
            locations: e.type_advanced === 2 ? e.locations : [],
            imei: e.type_advanced === 3 ? e.imei : [],
            quantity: +e.quantity,
            type_advanced: e.type_advanced,
          })),
        }
        res = await updateNote(body, transport?.order_id)
      } else {
        const body = {
          note: dataForm.note || '',
          tags: tags || [],
        }
        res = await updateInfo(body, transport?.order_id)
      }

      if (res?.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `Cập nhật phiếu chuyển hàng thành công`,
          })
          // socketListener()
          history.push(ROUTES.SHIPPING_PRODUCT)
        } else
          notification.error({
            message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }
  const _acceptTransportOrder = async (status, id) => {
    try {
      setLoading(true)
      if (productsTransport.length === 0) {
        notification.warning({ message: 'Vui lòng chọn sản phẩm cần chuyển hàng!' })
        return
      }

      if (branchIdApp === importLocation) {
        notification.warning({
          message: 'Chi nhánh chuyển và chi nhánh nhận không thể trùng nhau!',
        })
        return
      }

      for (let product of productsTransport) {
        if (product.quantity === 0) {
          return notification.error({
            message: `Vui lòng nhập số lượng chuyển của sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 2 && product?.locations?.length > 0) {
          let quantity = product?.locations?.reduce((total, item) => (total += item.quantity), 0)

          if (product.quantity !== quantity) {
            return notification.error({
              message: `${product.title} không đủ số lượng chuyển`,
            })
          }
        }
        if (product.type_advanced === 2 && !product.locations) {
          return notification.error({
            message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 2 && product?.locations?.length === 0) {
          return notification.error({
            message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          })
        }
      }
      for (let product of productsTransport) {
        if (product.type_advanced === 3 && product?.imei?.length > 0) {
          if (product.quantity > product?.imei?.length) {
            return notification.error({
              message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
            })
          }
          if (product.quantity < product?.imei?.length) {
            return notification.error({
              message: `${product.title} không đủ số lượng chuyển`,
            })
          }
        }
        if (product.type_advanced === 3 && !product.imei) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 3 && product?.imei?.length === 0) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
      }
      await form.validateFields()

      const dataForm = form.getFieldsValue()
      const body = {
        status: status,
        note: dataForm.note || '',
        tags: tags || [],
        branch_id: branchIdApp,
        delivery_time: new Date(dataForm.delivery_time).toString(),
        import_location: dataForm.import_location,
        products: productsTransport.map((e) => ({
          product_id: e.product_id,
          variant_id: e.variant_id,
          title: e.title,
          sku: e.sku,
          locations: e.type_advanced === 2 ? e.locations : [],
          imei: e.type_advanced === 3 ? e.imei : [],
          quantity: +e.quantity,
          type_advanced: e.type_advanced,
        })),
      }
      const res = await updateTransportOrder(body, id)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Cập nhật phiếu chuyển hàng thành công!' })
          _getTransportOrders()
          let response = await getTransportOrders({ order_id: Number(id.code), detail:1 })
          setTransport(response.data.data[0])
          handlePrint()
        } else
          notification.error({
            message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại!!',
          })
      } else
        notification.error({
          message: res.data.message || 'Cập nhật phiếu chuyển hàng thất bại, vui lòng thử lại!!',
        })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getBranches = async () => {
    try {
      const res = await getAllBranch()
      if (res.status === 200) setBranches(res.data.data)
      
    } catch (error) {
      console.log(error)
    }
  }

  const dataPrint = (data) => (
    <div ref={printerRef} style={{ width: '100%', fontSize: 8, fontFamily: 'Arial,sans-serif' }}>
      <div>
        <div style={{ textAlign: 'center', fontSize: 15, fontWeight: 'bold', marginBottom: 5 }}>
          Thông tin phiếu chuyển
        </div>
        <div>
          <span>Mã phiếu: </span>
          <span style={{ marginLeft: 3 }}>{data?.code}</span>
        </div>
        <div>
          <span>Chi nhánh chuyển: </span>
          <span style={{ marginLeft: 3 }}>{data?.export_location_info?.name}</span>
        </div>
        <div>
          <span>Chi nhánh nhận: </span>
          {data?.import_location_info?.name}
        </div>
        <div>
          <span>Người tạo: </span>
          <span style={{ marginLeft: 3 }}>
            {data?.creator_info?.first_name} {data?.creator_info?.last_name}
          </span>
        </div>
        <div>
          <span>Người nhận: </span>
          <span style={{ marginLeft: 3 }}>
            {data?.completer_info?.first_name} {data?.completer_info?.last_name}
          </span>
        </div>
      </div>
      <hr />
      <div style={{ textAlign: 'center', marginBottom: 0, fontWeight: 'bold', fontSize: 10 }}>
        Danh sách sản phẩm chuyển
      </div>
      <table style={{ borderCollapse: 'collapse' }}>
        <tr>
          <th style={{ border: '0.5px dotted #c1c1c1' }}>SKU</th>
          <th style={{ border: '0.5px dotted #c1c1c1' }}>Số lượng</th>
        </tr>
        {data?.products.map((e) => (
          <tr>
            <td style={{ border: '0.5px dotted #c1c1c1' }}>{e?.sku}</td>
            <td style={{ border: '0.5px dotted #c1c1c1' }}>{e?.quantity}</td>
          </tr>
        ))}
      </table>
    </div>
  )
  const handlePrint = useReactToPrint({
    content: () => printerRef.current,
    copyStyles: false,
  })

  const Print = () => (
    <div style={{ display: 'none' }}>
      <PrintTransport ref={printerRef} content={dataPrint(transport)} />
    </div>
  )

  const socketListener = () => {
    socket.on(`sale:complete:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
      try {
        notification.success({ key: 'socket', message: 'Số lượng sản phẩm đã thay đổi' })
        if (res.productList.length > 0) {
          dispatch(getProductWarehouse({ branchIdApp: branchIdApp }))
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  useEffect(() => {
    socketListener()
  }, [])
  useEffect(() => {
    let newTransport = [...productsTransport]
    newTransport.forEach((e) => {
      let indexTransport = products.findIndex((p) => p.variant_id === e.variant_id)
      if (indexTransport !== -1) e.total_quantity = products[indexTransport].total_quantity
    })
    setProductsTransport([...newTransport])
  }, [products])
  useEffect(() => {
    if (!permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_chuyen_hang))
      history.push(ROUTES.SHIPPING_PRODUCT)
  }, [branchIdApp])
  return (
    <div className="card">
      <Print />
      <TitlePage
        isAffix={true}
        title={
          <Link
            style={{
              color: 'black',
            }}
            to={{
              pathname: ROUTES.SHIPPING_PRODUCT,
            }}
          >
            <ArrowLeftOutlined style={{ marginRight: 7 }} />
            Chi tiết phiếu chuyển hàng
          </Link>
        }
      >
        <Space>
          {transport && transport?.export_location !== branchIdApp ? (
            <></>
          ) : (
            <Button
              disabled={permissions.includes(PERMISSIONS.cap_nhat_phieu_chuyen_hang) ? false : true}
              style={{ display: !transport && 'none' }}
              size="large"
              type="primary"
              onClick={() => _addOrEditTransportOrder()}
            >
              Lưu
            </Button>
          )}

          <>
            <Button
              disabled={permissions.includes(PERMISSIONS.cap_nhat_phieu_chuyen_hang) ? false : true}
              style={{
                display:
                  transport?.status === 'DRAFT' && transport?.export_location === branchIdApp
                    ? ''
                    : 'none',
              }}
              size="large"
              type="primary"
              onClick={() => _acceptTransportOrder('DELIVERY', transport?.order_id)}
              loading={loading}
            >
              Xuất kho
            </Button>
          </>

          {transport && transport?.import_location === branchIdApp ? (
            <>
              <Button
                disabled={
                  permissions.includes(PERMISSIONS.cap_nhat_phieu_chuyen_hang) ? false : true
                }
                style={{
                  display:
                    transport?.status === 'COMPLETE' ||
                    transport?.status === 'DRAFT' ||
                    transport?.status === 'CANCEL'
                      ? 'none'
                      : '',
                }}
                size="large"
                type="primary"
                onClick={() => _acceptTransportOrderComplete('COMPLETE', transport?.order_id)}
                loading={loading}
              >
                Nhận hàng
              </Button>
              <Button
                disabled={
                  permissions.includes(PERMISSIONS.cap_nhat_phieu_chuyen_hang) ? false : true
                }
                style={{
                  display:
                    transport?.status === 'COMPLETE' ||
                    transport?.status === 'DRAFT' ||
                    transport?.status === 'CANCEL'
                      ? 'none'
                      : '',
                }}
                size="large"
                type="primary"
                onClick={() => _cancelTransport('CANCEL', transport?.order_id)}
                loading={loading}
              >
                Từ chối
              </Button>
            </>
          ) : (
            <></>
          )}
        </Space>
      </TitlePage>

      <Form form={form} layout="vertical" style={{backgroundColor:'white', padding:10}}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <Row gutter={[15, 16]} style={{ marginTop: 15 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  rules={[{ required: true, message: 'Vui lòng chọn nơi nhận' }]}
                  label="Nơi nhận"
                  name="import_location"
                >
                  <Select
                    onChange={(value) => {
                      setImportLocation(value)
                    }}
                    showSearch
                    allowClear
                    size="large"
                    placeholder="Chọn nơi nhận"
                    filterOption={(input, option) =>
                      removeUnicode(option.children.toString())
                        .toLowerCase()
                        .trim()
                        .includes(removeUnicode(input).toLowerCase().trim())
                    }
                    disabled={
                      transport?.status === 'COMPLETE' ||
                      transport?.status === 'DELIVERY' ||
                      transport?.status === 'CANCEL' ||
                      transport?.export_location !== branchIdApp
                        ? true
                        : false
                    }
                  >
                    {branches.map((e, index) => (
                      <Select.Option value={e.branch_id} key={index} disabled={e.branch_id === branchIdApp ? true : false}>
                        {e.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[15, 16]} style={{ marginTop: 15 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <p style={{ marginBottom: '8px' }}>Nhãn</p>
                <Select
                  style={{ width: '100%' }}
                  value={tags}
                  onChange={(item) => {
                    handleChangeTags(item)
                  }}
                  mode="tags"
                  allowClear
                  size="large"
                  placeholder="Nhập tags"
                  disabled={branchIdApp !== transport?.export_location ? true : false}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Ghi chú"
                  name="note"
                  rules={[
                    {
                      pattern: /^[^<>\/]*$/,
                      message: 'Chỉ cho phép nhập ký tự chữ và số',
                    },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    rows={1}
                    placeholder="Nhập ghi chú"
                    disabled={branchIdApp !== transport?.export_location ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={styles['block']} style={{ marginLeft: 50, width: '45%' }}>
            {' '}
            <Row className={styles['title']}>{'Thông tin phiếu chuyển hàng'}</Row>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item style={{ marginBottom: '0px' }}>
                  Chi nhánh chuyển : <span>{transport?.export_location_info?.name}</span>
                </Form.Item>

                <Form.Item style={{ marginBottom: '0px' }}>
                  Chi nhánh nhận: <span>{transport?.import_location_info?.name}</span>
                </Form.Item>
                {transport?.delivery_date !== '' ? (
                  <Form.Item style={{ marginBottom: '0px' }}>
                    Ngày xuất kho :{' '}
                    <span>
                      {moment(transport?.delivery_date).format(TIME_FORMAT.DATE_TIME) || ''}
                    </span>
                  </Form.Item>
                ) : null}

                {transport?.status === 'COMPLETE' ? (
                  <Form.Item style={{ marginBottom: '0px' }}>
                    Ngày xác nhận :{' '}
                    <span>
                      {moment(transport?.verify_date).format(TIME_FORMAT.DATE_TIME) || ''}
                    </span>
                  </Form.Item>
                ) : transport?.status === 'CANCEL' ? (
                  <Form.Item style={{ marginBottom: '0px' }}>
                    Ngày hủy :{' '}
                    <span>{moment(transport?.cancel_date).format(TIME_FORMAT.DATE_TIME)}</span>
                  </Form.Item>
                ) : null}

                <Form.Item style={{ marginBottom: '0px' }}>
                  {'Trạng thái'}:{' '}
                  <Tag
                    style={{ width: '30%', textAlign: 'center' }}
                    color={
                      (transport?.status === 'DRAFT' && 'gold') ||
                      (transport?.status === 'DELIVERY' && 'blue') ||
                      (transport?.status === 'COMPLETE' && 'green') ||
                      (transport?.status === 'CANCEL' && 'red')
                    }
                  >
                    {transport?.status === 'COMPLETE' && 'Hoàn thành'}
                    {transport?.status === 'DRAFT' && 'Lưu nháp'}
                    {transport?.status === 'DELIVERY' && 'Đang chuyển'}
                    {transport?.status === 'CANCEL' && 'Đã hủy'}
                  </Tag>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item style={{ marginBottom: '0px' }}>
                  {'Mã phiếu chuyển'}: <span>{transport?.code}</span>
                </Form.Item>
                <Form.Item style={{ marginBottom: '0px' }}>
                  Người tạo:{' '}
                  <span>
                    {transport?.creator_info?.first_name +
                      ' ' +
                      transport?.creator_info?.last_name || ''}
                  </span>
                </Form.Item>
                {transport?.deliverer_id !== '' ? (
                  <Form.Item style={{ marginBottom: '0px' }}>
                    Người xuất kho:{' '}
                    <span>
                      {transport?.delivery_info
                        ? transport?.delivery_info?.first_name +
                          ' ' +
                          transport?.delivery_info?.last_name
                        : ''}
                    </span>
                  </Form.Item>
                ) : null}

                {transport?.status === 'COMPLETE' ? (
                  <Form.Item style={{ marginBottom: '0px' }}>
                    Người nhận:{' '}
                    <span>
                      {transport?.verify_info
                        ? transport?.verify_info?.first_name +
                          ' ' +
                          transport?.verify_info?.last_name
                        : ''}
                    </span>
                  </Form.Item>
                ) : transport?.status === 'CANCEL' ? (
                  <Form.Item style={{ marginBottom: '0px' }}>
                    Người hủy:{' '}
                    <span>
                      {transport?.canceler_info
                        ? transport?.canceler_info?.first_name +
                          ' ' +
                          transport?.canceler_info?.last_name
                        : ''}
                    </span>
                  </Form.Item>
                ) : null}

                <Form.Item style={{ marginBottom: '0px' }}>
                  Số lượng chuyển : <span>{transport?.total_quantity || ''}</span>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>

        <Row wrap={false} style={{ marginBottom: 20, marginTop: 25 }}>
          {transport?.status === 'DRAFT' && transport?.export_location === branchIdApp ? (
            <>
              <a href="https://s3.ap-southeast-1.wasabisys.com/ekata-staging/public/2023/02/16/1676518728804-935165970-ChuyenHang.xlsx">
                <Button>Tải về file import mẫu</Button>
              </a>
              <ImportTransport
                importProduct={_importProduct}
                setProductsError={setProductsError}
                branch_id={branchIdApp}
              />
              <Button
                type="primary"
                style={{ marginLeft: '1em' }}
                disabled={productsError.length > 0 ? false : true}
              >
                <CSVLink
                  headers={headers}
                  data={productsError}
                  filename={'Sản phẩm lỗi'}
                  style={{ textDecoration: 'none' }}
                >
                  Tải file lỗi
                </CSVLink>
              </Button>
            </>
          ) : (
            <Button
              // disabled={permissions.includes(PERMISSIONS.export_phieu_chuyen_hang) ? false : true}
              onClick={() => handlePrint()}
              icon={<VerticalAlignTopOutlined />}
              style={{ backgroundColor: 'green', borderColor: 'green' }}
              type="primary"
            >
              In phiếu chuyển
            </Button>
          )}
          <Select
            style={{ width: '100%', marginLeft: '1em' }}
            notFoundContent={loadingProduct ? <Spin size="small" /> : null}
            dropdownClassName="dropdown-select-search-product"
            allowClear
            showSearch
            value={null}
            disabled={
              transport?.status === 'COMPLETE' ||
              transport?.status === 'DELIVERY' ||
              transport?.export_location !== branchIdApp
                ? true
                : false
            }
            clearIcon={<CloseOutlined style={{ color: 'black' }} />}
            suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
            placeholder="Thêm sản phẩm vào phiếu chuyển hàng"
            onChange={(value, option) => {
              if (value !== null && value !== undefined && value !== '') {
                let index = value.lastIndexOf('/')
                let resultIndex = value.substring(index + 1)
                let productResults = []
                if (filteredResults && filteredResults.length > 0) {
                  productResults = filteredResults
                } else {
                  productResults = products
                }
                if (productResults[resultIndex].inventory === 'advanced') {
                  if (productResults[resultIndex].total_quantity === 0) {
                    return notification.error({
                      message: 'Sản phẩm không đủ số lượng chuyển',
                    })
                  }
                }
                _addProductToTransport(productResults[resultIndex])
              }
            }}
            filterOption={(input, option) =>
              removeUnicode(option.key.toString())
                .toLowerCase()
                .trim()
                .includes(removeUnicode(input).toLowerCase().trim())
            }
          >
            {filteredResults &&
              products &&
              (filteredResults.length > 0 ? filteredResults : products).map(
                (data, index) =>
                  data && (
                    <Select.Option key={data.title + data.sku + data.barcode + '/' + index + ''}>
                      <Row align="middle" wrap={false} style={{ padding: '7px 13px' }}>
                        <img
                          src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                          alt=""
                          style={{
                            minWidth: 55,
                            minHeight: 55,
                            maxWidth: 55,
                            maxHeight: 55,
                            objectFit: 'cover',
                          }}
                        />

                        <div style={{ width: '100%', marginLeft: 15 }}>
                          <Row wrap={false} justify="space-between">
                            <span
                              style={{
                                maxWidth: 300,
                                marginBottom: 0,
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                              }}
                            >
                              {data.title}
                            </span>
                            <span style={{ marginBottom: 0, fontWeight: 500 }}>
                              {formatCash(data.price)}
                            </span>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <span style={{ marginBottom: 0, color: 'gray' }}>{data.sku}</span>
                            <span style={{ marginBottom: 0, fontWeight: 500 }}>
                              {data.inventory !== 'normal' ? (
                                <span style={{ marginBottom: 0, color: 'gray' }}>
                                  Tồn kho: {formatCash(data.total_quantity)}
                                </span>
                              ) : data.active === true ? (
                                <span style={{ marginBottom: 0, color: 'gray' }}>Còn hàng</span>
                              ) : (
                                <span style={{ marginBottom: 0, color: 'gray' }}>Hết hàng</span>
                              )}
                            </span>
                          </Row>
                        </div>
                      </Row>
                    </Select.Option>
                  )
              )}
          </Select>
        </Row>

        <Row>
          <div style={{ color: 'black', fontWeight: '600', fontSize: 16 }}>
            Danh sách sản phẩm chuyển hàng
          </div>
        </Row>
        <TableProduct
          products={productsTransport}
          _editProductInTransport={_editProductInTransport}
          setProductQuantity={setProductQuantity}
          setProductsTransport={setProductsTransport}
          status={transport?.status}
          export_location={transport?.export_location}
        />
      </Form>
    </div>
  )
}
