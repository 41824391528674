import { useDispatch } from 'react-redux'
import { getShippings } from '../apis/shipping'

export async function ShippingCompanies() {
  const dispatch = useDispatch()
  try {
    const res = await getShippings()
    if (res.status === 200) {
      dispatch({
        type: 'SET_SHIPPING_COMPANY',
        data: [...res.data.data],
      })
    }
  } catch (error) {
    console.log(error)
  }
}
