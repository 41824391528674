import React, { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import FormImportFile from './index.js'
import { ImportOutlined } from '@ant-design/icons'
const ModalImport = ({ setProductChange, productChange, loading }) => {
  //valueProductMenu 1: sp moi 2:hang` moi
  const [open, setOpen] = useState(false)
  const showModal = () => {
    setOpen(true)
  }

  return (
    <>
      <Button style={{ background: 'green', color: 'white' }} onClick={showModal}>
        <ImportOutlined />
        Nhập file Excel
      </Button>

      <Modal
        width={1500}
        height={400}
        title="Nhập file Excel"
        closable={false}
        visible={open}
        footer={null}
      >
        <FormImportFile
          open={open}
          setOpen={setOpen}
          setProductChange={setProductChange}
          productChange={productChange}
        />
      </Modal>
    </>
  )
}

export default ModalImport
