import { Dropdown, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { getLocationImei } from 'apis/location'
import { removeUnicode } from 'utils'

export const SelectImei = (props) => {
  const {
    variant_id,
    index,
    _editProductInTransport,
    imei,
    product,
    detail,
    exportLocation,
    disabled,
  } = props
  const [imeiChoose, setImeiChoose] = useState([])
  const [imeiTotal, setImeiTotal] = useState([])
  const _getLocationImei = async () => {
    try {
      const res = await getLocationImei({ variant_id: variant_id, branch_id: exportLocation })
      if (res.status == 200) {
        let new_data = [...res.data.data.imei]
        if (imei.length > 0) {
          for (let i in imei) {
            let index = new_data.findIndex((e) => e.trim() === imei[i].trim())
            if (index !== -1) new_data.splice(index, 1)
          }
          setImeiTotal(new_data)
          setImeiChoose(imei)
          setImeiTotalShow(new_data)
        } else {
          setImeiTotal(res.data.data.imei)
          setImeiTotalShow(res.data.data.imei)
          setImeiChoose([])
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const SelectItemImei = (item, index_imei) => {
    let new_imei_choose = [...imeiChoose]
    let new_imei_total = [...imeiTotal]
    new_imei_choose.push(item)
    new_imei_total.splice(index_imei, 1)
    setImeiChoose(new_imei_choose)
    setImeiTotal(new_imei_total)
    _editProductInTransport('quantity', new_imei_choose.length, index)
    _editProductInTransport('imei', new_imei_choose, index)
  }

  const [imeiTotalShow, setImeiTotalShow] = useState([])
  const _searchImei = (e) => {
    let aaaa = []
    if (e == '') {
      aaaa = [...imeiTotal]
    } else {
      aaaa = imeiTotalShow.filter(
        (item) =>
          removeUnicode(item.toString())
            .toLowerCase()
            .trim()
            .indexOf(removeUnicode(e).toLowerCase().trim()) >= 0
      )
    }
    setImeiTotalShow([...aaaa])
  }
  const MenuItem = () => (
    <div
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        backgroundColor: '#fff',
        padding: '10px',
      }}
    >
      {imeiTotalShow?.map((item, index_imei) => (
        <div
          onClick={() => {
            SelectItemImei(item, index_imei)
          }}
          className={styles['imei-item']}
          key={index_imei}
        >
          <span className={styles['imei-item-span']}>{item}</span>
        </div>
      ))}
    </div>
  )

  return (
    <div>
      {disabled ? null : (
        <Dropdown overlay={<MenuItem />} trigger={['click']}>
          <Input
            placeholder="Chọn iMei"
            style={{ height: '32px', width: '160px', marginLeft: '4px' }}
            onClick={async (e) => {
              e.preventDefault()
              await _getLocationImei()
            }}
            onChange={(e) => {
              _searchImei(e.target.value)
            }}
          />
        </Dropdown>
      )}
    </div>
  )
}
