
import { getProductChange} from 'apis/product'

export const getProductsSystem = ({branchIdApp}) => async (dispatch) => {
    try {
        if (branchIdApp) {
          dispatch({type: 'LOADING', data: true})
            const res = await getProductChange({branch_id: branchIdApp || '' })
          
            if (res.status === 200) {
              dispatch({
                type: 'SET_PRODUCTS',
                data: {
                  products: res.data.data,
                  counts: res.data.count,
                },
              })
            }
          dispatch({type: 'LOADING', data: false})
          }
    } catch (error) {
          dispatch({type: 'LOADING', data: false})
          console.log(error)
    }
  };
