import { Button, Col, Input, InputNumber, Row, Table, Tag } from 'antd'
import { getProducts } from 'apis/product'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { compare, formatCash } from 'utils'
import styles from './custom-items.module.scss'
import { deleteDataOfStore, deleteAllDataOfStore } from '../function-items/delete-item'
const RightTable = (props) => {
  const { productChange, setProductChange, productFilter, valueSearch, setValueSearch } = props

  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 10,
  })

  const columnsVariants = [
    {
      title: 'ID',
      key: 'variant_id',
      align: 'center',
    },
    {
      title: 'Mã phiên bản',
      dataIndex: 'sku',
      key:'sku',
      align: 'center',
    },
    {
      title: 'Tên phiên bản',
      key: 'title',
      align: 'center',
    },
    {
      title: 'Giá ban đầu',
      key: 'default_price',
      align: 'center',
    },
    {
      title: 'Giá thay đổi',
      key: 'price',
      align: 'center',
    },
    {
      title: (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            deleteAllDataOfStore(setProductChange)
            setValueSearch('')
          }}
          size="small"
        >
          Tất cả
        </Button>
      ),
      key: 'action',
      align: 'center',
    },
  ]

  return (
    <Table
      rowKey="variant_id"
      scroll={{ y: 500 }}
      columns={columnsVariants.map((column) => {
        if (column.key === 'variant_id')
          return {
            ...column,
            width: 50,
            render: (text, record) => <span>{record.variant_id}</span>,
          }
        if (column.key === 'sku')
          return {
            ...column,
            render: (text, record) => <span>{record.sku || ''}</span>,
          }
        if (column.key === 'title')
          return {
            ...column,
            render: (text, record) => <span>{record.title || ''}</span>,
          }
        if (column.key === 'default_price')
          return {
            ...column,
            render: (text, record) => <span>{formatCash(record.price || 0)}</span>,
          }

        if (column.key === 'price')
          return {
            ...column,
            render: (text, record) => (
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                min={0}
                value={record.new_price}
                defaultValue={record.price || 0}
                onChange={(e) => {
                  let new_data = [...productChange]
                  let index_data = new_data.findIndex(
                    (item) => item.variant_id == record.variant_id
                  )
                  if (index_data == -1) return
                  new_data[index_data][`new_price`] = Number(e)
                  setProductChange(new_data)
                }}
              />
            ),
          }

        if (column.key === 'action')
          return {
            ...column,
            width: 70,
            render: (text, record) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  deleteDataOfStore(record, productChange, setProductChange)
                  setValueSearch('')
                }}
                type="danger"
                size="small"
              >
                Xoá
              </Button>
            ),
          }

        return column
      })}
      loading={loading}
      dataSource={productFilter}
      size="small"
      pagination={{
        position: ['bottomLeft'],
        current: paramsFilter.page,
        pageSize: paramsFilter.page_size,
        pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
        showQuickJumper: true,
        onChange: (page, pageSize) =>{
          setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })},
        total: (productFilter).length,
      }}
      className={styles['table-items-columns']}
    />
  )
}

export default RightTable
