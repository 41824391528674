import React from 'react'
import jwt_decode from 'jwt-decode'
import localStorage from '../../utils/localStorage'
import { useSelector } from 'react-redux'
const Permission = ({ permissions, children, ...props }) => {
  const dataUser = useSelector((state) => state.login.dataUser)
  try {

    if (!dataUser) return <div></div>
    const allPermission = [...dataUser._role.menu_list, ...dataUser._role.permission_list]
    if (
      !permissions ||
      permissions.length === 0 ||
      permissions.filter((p) => allPermission.includes(p)).length
    ) {
      return React.cloneElement(children, props)
    }

    return <div></div>
  } catch (error) {
    return <div></div>
  }
}

export default Permission
