import { get, patch, post, destroy } from './httpClient'

export const getOrdersImportInventory = (params) => get('/inventory/import', params)
export const updateOrderImportInventory = (body, id) =>patch('/inventory/import/update/' + id, body)
export const createOrderImportInventory = (body) => post('/inventory/import/create', body)
export const deleteOrderImportInventory = (body) =>destroy('/inventory/import/delete', body)
export const uploadOrdersImportInventory = (formData) =>post('/inventory/import/create/file', formData)
export const getStatusOrderImportInventory = () => get('/enum/importorder')
export const getInventories = (query) => get('/inventory/inventories', query)



