import { VERSION_APP } from 'consts'
import { message } from 'antd'
import i18n from 'locales/i18n.js'
import localStorage from './localStorage'
import _ from 'lodash'
import CryptoJS from 'crypto-js'

export const changeLanguage = (language) => {
  i18n.changeLanguage(language)
  localStorage.setItem('language', language)
}

export const validatePhone = (phone = '') => {
  const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
  if (vnf_regex.test(phone)) return true
  return false
}

export const compare = (a, b, key, convert) => {
  if (convert)
    return convert(a[key]) > convert(b[key]) ? 1 : convert(a[key]) === convert(b[key]) ? 0 : -1
  return removeAccents(a[key]).toLowerCase() > removeAccents(b[key]).toLowerCase() ? 1 : removeAccents(a[key]).toLowerCase() === removeAccents(b[key]).toLowerCase() ? 0 : -1
}

export const compareCustom = (a, b) => {
  return a > b ? 1 : a === b ? 0 : -1
}

export const tableSum = (arr, key) => {
  const getValue = (obj, key) => {
    try {
      return key.split('.').reduce((a, b) => {
        return a[b] || 0
      }, obj)
    } catch (e) {
      return 0
    }
  }
  try {
    return arr.reduce((a, b) => a + parseInt(getValue(b, key)), 0)
  } catch (err) {
    console.log(err)
    return 0
  }
}

export const tableSumPayment = (arr) => {
  try {
    let result = {}
    for (let item of arr) {
      for (let payment of item.payments) {
        if (typeof result[payment.payment_method_id] == 'undefined') {
          result[payment.payment_method_id] = { name: payment.name, total: 0 }
        }
        result[payment.payment_method_id].total += +payment.value
      }
    }
    return Object.values(result)
  } catch (err) {
    console.log(err)
    return 0
  }
}

export const tableSumRefund = (arr) => {
  try {
    let result = {}
    for (let item of arr) {
      for (let payment of item.payment_infos) {
        if (typeof result[payment.payment_method_id] == 'undefined') {
          result[payment.payment_method_id] = { name: payment.name, total: 0 }
        }
        result[payment.payment_method_id].total += +payment.value
      }
    }
    return Object.values(result)
  } catch (err) {
    console.log(err)
    return 0
  }
}

export function formatCash(str) {
  if (str) return (str + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else return 0
}

export function removeNull(a) {
  return Object.keys(a)
    .filter((key) => a[key] !== '' && a[key] !== undefined)
    .reduce((res, key) => ((res[key] = a[key]), res), {})
}

//xoá dấu
export function removeAccents(text, removeSpace = false, spaceReplace = '') {
  if (removeSpace && typeof removeSpace != 'boolean') {
    throw new Error('Type of removeSpace input must be boolean!')
  }
  text = (text + '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
  if (removeSpace) {
    text = text.replace(/\s/g, spaceReplace)
  }
  return text
}

export const filterOptionProduct = (input, option) => {
  // new RegExp(stringHandle(input).createSlugRegex()).test(stringHandle(option.children).createSlug())
  removeAccents(option.children).toLowerCase().trim().includes(removeAccents(input).toLowerCase().trim())
}

export function removeUnicode(str) {
  var AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ]
  for (var i = 0; i < AccentsMap.length; i++) {
    var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g')
    var char = AccentsMap[i][0]
    str = str.replace(re, char)
  }
  return str
}

export const clearBrowserCache = () => {
  let version = localStorage.getItem('version_app')
  if (version !== VERSION_APP) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name)
        })
      })

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true)
    }

    localStorage.clear()
    localStorage.setItem('version_app', VERSION_APP)
  }
}

export const copyText = (text) => {
  navigator.clipboard.writeText(text)
  message.success('Copied the text')
}

export const encryptText = (text) =>
  CryptoJS.AES.encrypt(text, process.env.REACT_APP_SECRET_KEY_CRYPTO).toString()

export const decryptText = (cipherText) => {
  var bytes = CryptoJS.AES.decrypt(cipherText, process.env.REACT_APP_SECRET_KEY_CRYPTO)
  var originalText = bytes.toString(CryptoJS.enc.Utf8)
  return originalText || ''
}

export const filterOptionSelect = (input, option) =>
  removeAccents(option.children).toLowerCase().includes(removeAccents(input).toLowerCase())

export function stringHandle(inp) {
  let text = (inp ? String(inp) : '').trim()
  function createSlugRegex(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return stringHandle(input)
      .removeUnicode()
      .lowerCase()
      .toString()
      .replace(/[^a-zA-Z0-9]/g, '-')
      .replace(/-{1,}/g, '(.*?)')
  }
  function createSlug(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return stringHandle(input)
      .removeUnicode()
      .lowerCase()
      .toString()
      .replace(/[^a-zA-Z0-9]/g, ' ')
      .replace(/\s{1,}/g, '-')
  }
  function getFirstLetter(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return stringHandle(input)
      .removeUnicode()
      .toString()
      .split(' ')
      .map((e) => {
        if (/[0-9]/g.test(e)) {
          return e
        }
        return e[0]
      })
      .join('')
  }
  function lowerCase(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return text.toLowerCase()
  }
  function removeStringInBrackets(bracketType, input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    if (!Array.isArray(bracketType)) {
      throw new TypeError('bracketType must be array!')
    }
    if (bracketType.includes('round')) {
      input = input.replace(/\((.*?)\)/gi, '')
    }
    if (bracketType.includes('square')) {
      input = input.replace(/\[(.*?)\]/gi, '')
    }
    if (bracketType.includes('curly')) {
      input = input.replace(/\{(.*?)\}/gi, '')
    }
    if (bracketType.includes('angle')) {
      input = input.replace(/\<(.*?)\>/gi, '')
    }
    return input
  }
  function removeSpecialCharacter(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return input.replace(/[^a-zA-Z0-9\s]/g, '')
  }
  function removeSpace(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return input.replace(/\s/g, '')
  }
  function removeUnicode(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return input
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  }
  function replaceSpaceWithHyphen(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return input.trim().replace(/\s{1,}/g, '-')
  }
  function upperCase(input) {
    if (!input) {
      throw new TypeError('Function input is not valid!')
    }
    return input.toUpperCase()
  }
  return {
    createSlugRegex: () => stringHandle(createSlugRegex(text)).toString(),
    createSlug: () => stringHandle(createSlug(text)).toString(),
    getFirstLetter: () => stringHandle(getFirstLetter(text)).toString(),
    lowerCase: () => stringHandle(lowerCase(text)),
    removeStringInBrackets: (bracketType) =>
      stringHandle(removeStringInBrackets(bracketType, text)),
    removeSpecialCharacter: () => stringHandle(removeSpecialCharacter(text)),
    removeSpace: () => stringHandle(removeSpace(text)),
    removeUnicode: () => stringHandle(removeUnicode(text)),
    replaceSpaceWithHyphen: () => stringHandle(replaceSpaceWithHyphen(text)),
    upperCase: () => stringHandle(upperCase(text)),
    toString: () => text,
  }
}
