/* eslint-disable jsx-a11y/iframe-has-title */
import { FileGifOutlined } from '@ant-design/icons'
import { Col, Dropdown, Menu, Row } from 'antd'
import styles from './comment.module.scss'
import React from 'react'
export const imageShow = (src, check) => {
  return (
    <img
      src={src}
      alt="images"
      style={{
        marginLeft: check !== false && '70px',
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        borderRadius: 10,
      }}
    />
  )
}

export const videoShow = (src, check) => {
  return (
    <video
      controls
      src={src}
      alt="images"
      style={{
        marginLeft: check !== false && '70px',
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        borderRadius: 10,
      }}
    />
  )
}

export const MenuIcon = ({ comments, setComments }) => {
  const reactions1 = ['❤️', '😆', '😯', '😢', '😡', '👍']
  const reactions2 = ['👎', '😄', '😂', '😍', '😘', '😗']
  const reactions3 = ['😚', '😳', '😭', '😓', '😤', '🤤']
  const reactions4 = ['👻', '💀', '🤐', '😴', '😷', '😵 ']
  return (
    <Menu>
      <Row>
        <Col>
          {reactions1.map((item) => (
            <Menu.Item key={item} onClick={() => setComments(comments + item)}>
              {item}
            </Menu.Item>
          ))}
        </Col>
        <Col>
          {reactions2.map((item) => (
            <Menu.Item key={item} onClick={() => setComments(comments + item)}>
              {item}
            </Menu.Item>
          ))}
        </Col>
        <Col>
          {reactions3.map((item) => (
            <Menu.Item key={item} onClick={() => setComments(comments + item)}>
              {item}
            </Menu.Item>
          ))}
        </Col>
        <Col>
          {reactions4.map((item) => (
            <Menu.Item key={item} onClick={() => setComments(comments + item)}>
              {item}
            </Menu.Item>
          ))}
        </Col>
      </Row>
    </Menu>
  )
}

export const MenuGif = () => {
  const exampleGif = [
    1, 2, 3, 4, 5, 6, 7, 8, 9,
  ]

  return (
    <div style={{ overflow: 'scroll', height: '400px' }}>
      {exampleGif.map((item) => (
        <span>
          <div style={{ width: '100%', height: 0, paddingBottom: '60%', position: 'relative' }}>
            <iframe
              src="https://giphy.com/embed/d47HzDtYmyQg4uvC"
              width="100%"
              height="100%"
              style={{ position: 'absolute' }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <div style={{ width: '250px' }}></div>
        </span>
      ))}
    </div>
  )
}
