import { get, patch, post, destroy } from './httpClient'

export const getTransportOrders = (query) => get('/transport-order', query)

export const addTransportOrder = (body) => post('/transport-order/create', body)
export const deleteTransportOrder = (id) => destroy('/transport-order/delete', { order_id: [id] })
export const updateTransportOrder = (body, id) => patch('/transport-order/update/' + id, body)
export const updateTransportOrderComplete = (body, id) => patch('/transport-order/update-complete/' + id, body)
export const cancel = (body, id) => patch('/transport-order/cancel/' + id, body)
export const updateNote = (body, id) => patch('/transport-order/update-note/' + id, body)
export const updateInfo = (body, id) => patch('/transport-order/update-info/' + id, body)

// export const getStatusTransportOrder = () => get('/enum/transport-order')
export const addTransportOrderWithFile = (formData) => post('/transport-order/create/file', formData)
