import React, { useState, useEffect } from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { PERMISSIONS } from 'consts'

//antd
import { Select, DatePicker, Tooltip } from 'antd'
import { StepForwardOutlined } from '@ant-design/icons'
import removeVietnameseTones from 'utils/removeUnicode'
import { useSelector } from 'react-redux'

const { Option } = Select
const { RangePicker } = DatePicker

export default function FilterDate({
  placeholder = t('common.filter_by_time'),
  paramsFilter = {},
  setParamsFilter,
  width = '100%',
  style,
  bordered = true,
  size = 'small',
  valueTime = '',
  setValueTime,
  title,
  screen = 0
}) {
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [open, setOpen] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []

  const [valueDateSearch, setValueDateSearch] = useState([
    moment(moment().subtract(29, 'days').format('YYYY-MM-DD')),
    moment(moment().format('YYYY-MM-DD')),
  ]) //dùng để hiện thị date trong filter by date
  const PARAMS = [
    'from_date',
    'to_date',
    'today',
    'yesterday',
    'this_week',
    'last_week',
    'last_month',
    'this_month',
    'this_year',
    'last_year',
  ]
  const resetFilterDate = () => {
    const keysParamsFilter = Object.keys(paramsFilter)
    for (let i = 0; i < keysParamsFilter.length; i++)
      if (PARAMS.includes(keysParamsFilter[i])) return
    if(screen === 1) setValueDateSearch()

  }
  const handalSelectDate = (value) => {
    let to_date = 0
    let from_date = 0
    switch (value) {
      case 'today': {
        from_date = moment().startOf('days').unix()
        to_date = moment().endOf('days').unix()
        break
      }
      case 'yesterday': {
        from_date = moment().subtract(1, `days`).startOf('days').unix()
        to_date = moment().subtract(1, `days`).endOf('days').unix()
        break
      }
      case 'this_week': {
        from_date = moment().startOf('weeks').unix()
        to_date = moment().endOf('weeks').unix()
        break
      }
      case 'last_week': {
        from_date = moment().subtract(1, 'weeks').startOf('weeks').unix()
        to_date = moment().subtract(1, 'weeks').endOf('weeks').unix()
        break
      }
      case 'this_month': {
        from_date = moment().startOf('months').unix()
        to_date = moment().endOf('months').unix()
        break
      }
      case 'last_month': {
        from_date = moment().subtract(1, 'months').startOf('months').unix()
        to_date = moment().subtract(1, 'months').endOf('months').unix()
        break
      }
      case 'this_year': {
        from_date = moment().startOf('years').unix()
        to_date = moment().endOf('years').unix()
        break
      }
      case 'last_year': {
        from_date = moment().subtract(1, 'years').startOf('years').unix()
        to_date = moment().subtract(1, 'years').endOf('years').unix()
        break
      }
      default:
        break
    }

    paramsFilter.to_date = to_date
    paramsFilter.from_date = from_date
    setParamsFilter({ ...paramsFilter, page: 1 })

    setValueTime(value)
    setValueDateSearch(null)
    if (isOpenSelect) toggleOpenSelect()
  }
  const setDatePicker = (dates, dateStrings) => {
    //khi search hoac filter thi reset page ve 1
    setOpen(!open)

    if (isOpenSelect) toggleOpenSelect()

    //Kiểm tra xem date có được chọn ko
    //Nếu ko thì thoát khỏi hàm, tránh cash app
    //và get danh sách order
    if (!dateStrings[0] && !dateStrings[1]) {
      setValueDateSearch(null)
      setValueTime()
    } else {
      const dateFirst = dateStrings[0]
      const dateLast = dateStrings[1]

      let from_date = moment(dateFirst).startOf('days').unix()
      let to_date = moment(dateLast).endOf('days').unix()
      paramsFilter.to_date = to_date
      paramsFilter.from_date = from_date
      setParamsFilter({ ...paramsFilter, page:1 })
      setValueDateSearch(dates)
      setValueTime(`${dateFirst} -> ${dateLast}`)
      dateFirst.replace(/-/g, '/')
      dateLast.replace(/-/g, '/')
    }
  }
  useEffect(() => {
    resetFilterDate()
  }, [paramsFilter])
  return (
    <Tooltip
      title={
        <span>
          Lọc theo thời gian<br></br>
          {title}
        </span>
      }
    >
      {permissions.includes(PERMISSIONS.bo_loc_thoi_gian) ? (
        <Select
          size={size}
          style={{ width: width, ...style, height: '100%' }}
          bordered={bordered}
          open={isOpenSelect}
          onBlur={() => {
            if (isOpenSelect) toggleOpenSelect()
          }}
          onClick={() => {
            if (!isOpenSelect) toggleOpenSelect()
          }}
          allowClear
          onClear={() => {
            document.getElementById('selectTime').focus()
          }}
          id="selectTime"
          showSearch
          placeholder={placeholder}
          filterOption={(inputValue, option) =>
            removeVietnameseTones(option.props.children)
              .toLowerCase()
              .includes(removeVietnameseTones(inputValue).toLowerCase().trim())
          }
          value={valueTime || 'Lọc theo thời gian'}
          onChange={async (value) => handalSelectDate(value)}
          dropdownStyle={{
            maxHeight: 400,
            overflow: 'auto',
          }}
          dropdownRender={(menu) => (
            <>
              {permissions.includes(PERMISSIONS.tuy_chon) ? (
                <RangePicker
                  id="date-picker"
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect()
                  }}
                  disabledDate={(current) => {
                    // Can not select days before today and today
                    return current && current > moment().endOf('day')
                  }}
                  onClick={() => {
                    if (!open) setOpen(true)
                  }}
                  showTime
                  onOk={() => {
                    if(!open) toggleOpenSelect()}}
                  format={'YYYY-MM-DD'}
                  value={valueDateSearch}
                  onChange={(dates, dateStrings) => {
                    setDatePicker(dates, dateStrings)
                  }}
                  style={{ width: '100%' }}
                />
              ) : null}
              {menu}
            </>
          )}
        >
          {permissions.includes(PERMISSIONS.hom_nay) ? (
            <Option value="today">{t('common.today')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.hom_qua) ? (
            <Option value="yesterday">{t('common.yesterday')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_nay) ? (
            <Option value="this_week">{t('common.this_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.tuan_truoc) ? (
            <Option value="last_week">{t('common.last_week')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_nay) ? (
            <Option value="this_month">{t('common.this_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.thang_truoc) ? (
            <Option value="last_month">{t('common.last_month')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_nay) ? (
            <Option value="this_year">{t('common.this_year')}</Option>
          ) : null}
          {permissions.includes(PERMISSIONS.nam_truoc) ? (
            <Option value="last_year">{t('common.last_year')}</Option>
          ) : null}
        </Select>
      ) : null}
    </Tooltip>
  )
}
