import { useEffect, useState } from 'react'
import { Table, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateBranch, deleteBranch } from 'apis/branch'
import { ACTION } from 'consts'
import { getColumns, getPagination } from './helper'
import { updateUser } from 'apis/users'
import { updateDefault } from 'apis/branch'
function TableBranch(props) {
  const { onGetBranches, loading, branches, countBranch, columns, paramsFilter, onSetParamFilter } =
    props

  const dispatch = useDispatch()

  const dataUser = useSelector((state) => state.login.dataUser)

  const { user_id, branch_id } = dataUser

  const [defaultBranch, setDefaultBranch] = useState()
  const branchIdApp = useSelector((state) => state.branch.branchId)

  const _deleteBranch = async (branch_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteBranch({branch_delete: branch_id, branch_id: branchIdApp})
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa chi nhánh thành công!' })
          onGetBranches()
          dispatch({ type: 'TRIGGER_RELOAD_BRANCH' })
        } else
          notification.error({
            message: res.data.message || 'Xóa chi nhánh thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa chi nhánh thất bại, vui lòng thử lại!',
        })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _editBranch = async (body, id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateBranch(body, id)
      if (res.status === 200) {
        if (res.data.success) {
          dispatch({
            type: 'TRIGGER_RELOAD_BRANCH',
          })
          onGetBranches()
          notification.success({ message: 'Cập nhật thành công' })
        } else
          notification.error({
            message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!' })
    } catch (error) {
      console.log(error)
    }
    dispatch({ type: ACTION.LOADING, data: false })
  }

  const updateDefaultBranch = async (branch_id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateDefault(branch_id)
      if (res.status === 200) {
        if (res.data.success) {
          setDefaultBranch(branch_id)
          onGetBranches()
          notification.success({ message: 'Thiết lập mặc định thành công !' })
        } else
          notification.error({
            message: res.data.message || 'Thiết lập mặc định thất bại, vui lòng thử lại!',
          })
      } else
        notification.error({ message: res.data.message || 'Cập nhật thất bại, vui lòng thử lại!' })
    } catch (error) {
      console.log(error)
    }
    dispatch({ type: ACTION.LOADING, data: false })
  }

  useEffect(() => {
    setDefaultBranch(Object(branches.find(e => e.default === true)).branch_id || 1)
  }, [branches])

  return (
    <Table
      style={{ width: '100%', marginTop: 5 }}
      rowKey="branch_id"
      size="small"
      scroll={{ y: 450 }}
      loading={loading}
      dataSource={branches}
      pagination={getPagination(paramsFilter, countBranch, onSetParamFilter)}
      columns={getColumns({
        columns,
        paramsFilter,
        onGetBranches,
        _editBranch,
        _deleteBranch,
        updateDefaultBranch,
        defaultBranch,
      })}
    />
  )
}

export default TableBranch
