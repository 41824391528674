export const convertFields = (data, template, reverse = false) => {
  if (reverse) {
    return Object.keys(template).reduce((a, b) => ((a[template[b]] = data[b] || ''), a), {})
  }
  return Object.keys(template).reduce((a, b) => ((a[b] = data[template[b]] || ''), a), {})
}

export const guarantee = {
  code: 'Mã phiếu (*)',
  name: 'Tên bảo hành (*)',
  type: 'Loại bảo hành (*)',
  time: 'Thời hạn bảo hành (*)',
  description: 'Mô tả',
}

export const rolesTranslate = (key) => {
  const data = {
    //tổng quan
    tong_quan: 'Tổng quan',
    //bán hàng
    ban_hang: 'Bán hàng',

    danh_sach_don_hang: 'Danh sách đơn hàng',
    // danh sách đơn hàng
    don_hang: 'Quản lý đơn hàng',
    xem_danh_sach_don_hang: 'Xem danh sách đơn hàng',
    tao_don_hang: 'Tạo đơn hàng',
    chi_tiet_don_hang: 'Xem chi tiết đơn hàng',
    cap_nhat_don_hang: 'Cập nhật đơn hàng',
    export_don_hang: 'Xuất file excel đơn hàng',
    //trả hàng
    tra_hang: 'Trả hàng',
    xem_danh_sach_tra_hang: 'Xem danh sách trả hàng',
    xem_chi_tiet_tra_hang: 'Xem chi tiết phiếu trả hàng',
    tao_phieu_tra_hang: 'Tạo phiếu trả hàng',
    cap_nhat_phieu_tra_hang: 'Cập nhật phiếu trả hàng',
    export_phieu_tra_hang: 'Xuất file excel phiếu trả hàng',
    //chốt ca
    chot_ca: 'Chốt ca',
    xem_danh_sach_chot_ca: 'Xem danh sách chốt ca',
    tao_phieu_chot_ca: 'Tạo phiếu chốt ca',
    //Vận chuyển
    van_chuyen: 'Vận chuyển',
    //giao hàng
    quan_li_giao_hang: 'Quản lý giao hàng',
    xem_danh_sach_giao_hang: 'Xem danh sách phiếu giao hàng',
    xem_chi_tiet_phieu_giao_hang: 'Xem chi tiết phiếu giao hàng',
    them_phieu_giao_hang: 'Thêm phiếu giao hàng',
    cap_nhat_giao_hang: 'Cập nhật phiếu giao hàng',
    // đối soát vận chuyển',
    doi_soat_van_chuyen: 'Đối soát vận chuyển',
    xem_danh_sach_doi_soat_van_chuyen: 'Xem danh sách đối soát vận chuyển',
    xem_chi_tiet_phieu_doi_soat_van_chuyen: 'Xem chi tiết phiếu đối soát vận chuyển',
    them_phieu_doi_soat_van_chuyen: 'Thêm phiếu đối soát vận chuyển',
    cap_nhat_doi_soat_van_chuyen: 'Cập nhật đối soát vận chuyển',
    xoa_phieu_doi_soat_van_chuyen: 'Xóa phiếu đối soát vận chuyển',
    export_phieu_doi_soat_van_chuyen: 'Xuất file excel phiếu đối soát vận chuyển',
    // đối tác vận chuyển
    doi_tac_van_chuyen: 'Đối tác vận chuyển',
    xem_danh_sach_doi_tac_van_chuyen: 'Xem danh sách đối tác vận chuyển',
    them_doi_tac_van_chuyen: 'Thêm đối tác vận chuyển',
    cap_nhat_doi_tac_van_chuyen: 'Cập nhật đối tác vận chuyển',
    xoa_doi_tac_van_chuyen: 'Xóa đối tác vận chuyển',

    // sản phẩm
    san_pham: 'Sản phẩm',
    quan_li_san_pham: 'Quản lý sản phẩm',
    xem_danh_sach_san_pham: 'Xem danh sách sản phẩm',
    chi_tiet_san_pham: 'Xem chi tiết sản phẩm',
    them_san_pham: 'Thêm sản phẩm',
    cap_nhat_san_pham: 'Cập nhật sản phẩm',
    xoa_san_pham: 'Xoá sản phẩm',
    mo_ban_san_pham: 'Mở bán sản phẩm',
    in_ma_san_pham: 'In mã sản phẩm',
    export_san_pham: 'Xuất file sản phẩm',

    // nhóm sản phẩm
    nhom_san_pham: 'Nhóm sản phẩm',
    tao_nhom_san_pham: 'Tạo nhóm sản phẩm',
    xem_danh_sach_nhom_san_pham: 'Xem danh sách nhóm sản phẩm',
    xem_chi_tiet_nhom_san_pham: 'Xem chi tiết nhóm sản phẩm',
    cap_nhat_nhom_san_pham: 'Cập nhật nhóm sản phẩm',
    xoa_nhom_san_pham: 'Xóa nhóm sản phẩm',

    // lịch sử thay đổi giá
    lich_su_thay_doi_gia: 'Lịch sử điều chỉnh giá',
    // lịch sử trả hàng
    lich_su_tra_hang: 'Lịch sử trả hàng',
    //lịch sử bán hàng
    lich_su_ban_hang: 'Lịch sử bán hàng',
    // quản lý kho
    quan_li_kho: 'Quản lý Kho',

    //điều chỉnh giá
    dieu_chinh_gia: 'Quản lý điều chỉnh giá bán',
    xem_danh_sach_dieu_chinh_gia: 'Xem danh sách điều chỉnh giá',
    xem_chi_tiet_phieu_dieu_chinh_gia: 'Xem chi tiết phiếu điều chỉnh giá',
    tao_phieu_dieu_chinh: 'Tạo phiếu điều chỉnh giá bán',
    cap_nhat_phieu_dieu_chinh: 'Cập nhật phiếu điều chỉnh giá bán',
    hoan_tac_phieu_dieu_chinh: 'Hoàn tác phiếu điều chỉnh giá bán',
    export_phieu_dieu_chinh: 'Xuất file excel phiếu điều chỉnh giá bán',
    // nhập hàng
    nhap_hang: 'Nhập hàng',
    xem_danh_sach_nhap_hang: 'Xem danh sách nhập hàng',
    xem_chi_tiet_phieu_nhap_hang: 'Xem chi tiết phiếu nhập hàng',
    them_phieu_nhap_hang: 'Thêm phiếu nhập hàng',
    cap_nhat_phieu_nhap_hang: 'Cập nhật phiếu nhập hàng',
    xoa_phieu_nhap_hang: 'Xóa phiếu nhập hàng',
    export_nhap_hang: 'Xuất file excel phiếu nhập hàng',
    // kiểm hàng cơ bản
    kiem_hang_co_ban: 'Kiểm hàng cơ bản',
    xem_danh_sach_kiem_hang_co_ban: 'Xem danh sách kiểm hàng cơ bản',
    xem_chi_tiet_phieu_kiem_hang_co_ban: 'Xem chi tiết phiếu kiểm hàng cơ bản',
    them_phieu_kiem_hang_co_ban: 'Thêm phiếu kiểm hàng cơ bản',
    xoa_phieu_kiem_hang_co_ban: 'Xóa phiếu kiểm hàng cơ bản',
    export_phieu_kiem_hang_co_ban: 'Xuất file excel kiểm hàng cơ bản',
    cap_nhat_phieu_kiem_hang_co_ban: 'Cập nhật phiếu kiểm hàng cơ bản',
    can_bang_phieu_kiem_hang_co_ban: 'Cân bằng phiếu kiểm hàng cơ bản',
    // kiểm hàng nâng cao
    kiem_hang_nang_cao: 'Kiểm hàng nâng cao',
    xem_danh_sach_kiem_hang_nang_cao: 'Xem danh sách kiểm hàng nâng cao',
    xem_chi_tiet_phieu_kiem_hang_nang_cao: 'Xem chi tiết phiếu kiểm hàng nâng cao',
    them_phieu_kiem_hang_nang_cao: 'Thêm phiếu kiểm hàng nâng cao',
    xoa_phieu_kiem_hang_nang_cao: 'Xóa phiếu kiểm hàng nâng cao',
    export_phieu_kiem_hang_nang_cao: 'Xuất file excel kiểm hàng nâng cao',
    cap_nhat_phieu_kiem_hang_nang_cao: 'Cập nhật phiếu kiểm hàng nâng cao',
    can_bang_phieu_kiem_hang_nang_cao: 'Cân bằng phiếu kiểm hàng nâng cao',
    // phiếu chuyển hàng
    phieu_chuyen_hang: 'Quản lý phiếu chuyển hàng',
    xem_danh_sach_chuyen_hang: 'Xem danh sách chuyển hàng',
    xem_chi_tiet_phieu_chuyen_hang: 'Xem chi tiết phiếu chuyển hàng',
    tao_phieu_chuyen_hang: 'Tạo phiếu chuyển hàng',
    cap_nhat_phieu_chuyen_hang: 'Cập nhật phiếu chuyển hàng',
    xoa_phieu_chuyen_hang: 'Xoá phiếu chuyển hàng',
    export_phieu_chuyen_hang: 'Xuất file excel chuyển hàng',
    // quản lý nhân vien
    quan_li_nhan_vien: 'Quản lý nhân viên',
    danh_sach_nhan_vien: 'Danh sách nhân viên',
    xem_danh_sach_nhan_vien: 'Xem danh sách nhân viên',
    xem_chi_tiet_nhan_vien: 'Xem chi tiết nhân viên',
    them_nhan_vien: 'Thêm nhân viên',
    cap_nhat_nhan_vien: 'Cập nhật nhân viên',
    xoa_nhan_vien: 'Xóa nhân viên',

    // lịch làm việc
    lich_lam_viec: 'Lịch làm việc',
    xem_danh_sach_lich_lam_viec: 'Xem danh sách lịch làm việc',
    xem_chi_tiet_lich_lam_viec: 'Xem chi tiết lịch làm việc',
    cau_hinh_ca_lam_viec: 'Cấu hình ca làm việc',
    them_lich_lam_viec: 'Thêm lịch làm việc',
    cap_nhat_lich_lam_viec: 'Cập nhật lịch làm việc',
    xoa_lich_lam_viec: 'Xóa lịch làm việc',
    xem_lich_lam_viec_nhan_vien: 'Xem lịch làm việc nhân viên',
    //Nhà cung cấp
    quan_li_nha_cung_cap: 'Quản lý nhà cung cấp',
    xem_danh_sach_nha_cung_cap: 'Xem danh sách nhà cung cấp',
    xem_chi_tiet_nha_cung_cap:'Xem chi tiết nhà cung cấp',
    them_nha_cung_cap: 'Thêm nhà cung cáp',
    xoa_nha_cung_cap: 'Xóa nhà cung cấp',
    cap_nhat_nha_cung_cap: 'Cập nhật nhà cung cấp',
    export_nha_cung_cap: 'Xuất file nhà cung cấp',
    //Quản lý khách  hàng
    quan_li_khach_hang: 'Quản lý khách hàng',
    xem_danh_sach_khach_hang: 'Xem danh sách khách hàng',
    xem_chi_tiet_khach_hang: 'Xem chi tiết khách hàng',
    them_khach_hang: 'Thêm khách hàng',
    cap_nhat_khach_hang: 'Cập nhật khách hàng',
    xoa_khach_hang: 'Xóa khách hàng',
    export_khach_hang: 'Xuất file excel khách hàng',

    //Danh sách báo cáo
    danh_sach_bao_cao: 'Danh sách báo cáo',
    bao_cao_ton_kho: 'Báo cáo tồn kho',
    bao_cao_xuat_nhap_ton: 'Báo cáo xuất nhập tồn',
    bao_cao_ban_hang: 'Báo cáo bán hàng',
    bao_cao_ban_hang_theo_phuong_thuc_thanh_toan: 'Báo cáo bán hàng theo phương thức thanh toán',
    bao_cao_ton_kho_theo_lo: 'Báo cáo tồn kho theo lô',
    // thu chi
    bao_cao_thu_chi: 'Báo cáo thu chi',
    xem_danh_sach_phieu_thu_chi: 'Xem danh sách phiếu thu chi',
    xem_chi_tiet_phieu_thu_chi: 'Xem chi tiết phiếu thu chi',
    them_phieu_thu_chi: 'Thêm phiếu thu chi',
    xoa_phieu_thu_chi: 'Xóa phiếu thu chi',
    cap_nhat_phieu_thu_chi: 'Cập nhật phiếu thu chi',
    export_phieu_thu_chi: 'Xuất file excel phiếu thu chi',
    // loại phiếu thu chi
    loai_phieu_thu_chi: 'Loại phiếu thu chi',
    xem_danh_sach_loai_phieu_thu_chi: 'Xem danh sách loại phiếu thu chi',
    xem_chi_tiet_loai_phieu_thu_chi:'Xem chi tiết loại phiếu thu chi',
    them_loai_phieu_thu_chi: 'Thêm loại phiếu thu chi',
    xoa_loai_phieu_thu_chi: 'Xóa loại phiếu thu chi',
    cap_nhat_loai_phieu_thu_chi: 'Cập nhật loại phiếu thu chi',

    //cấu hình
    cau_hinh: 'Cấu hình',
    //quản lý chi nhánh
    cau_hinh_chi_nhanh: 'Quản lý chi nhánh',
    xem_danh_sach_chi_nhanh:'Xem danh sách chi nhánh',
    xem_chi_tiet_chi_nhanh:'Xem chi tiết chi nhánh',
    xem_chi_nhanh: 'Xem chi nhánh',
    them_chi_nhanh: 'Thêm chi nhánh',
    cap_nhat_chi_nhanh: 'Cập nhật chi nhánh',
    //phân quyền
    cau_hinh_phan_quyen: 'Quan lý phân quyền',
    xem_phan_quyen: 'Xem danh sách phân quyền',
    them_vai_tro_phan_quyen: 'Thêm vai trò phân quyền',
    cap_nhat_vai_tro_phan_quyen: 'Cập nhật vai trò phân quyền',
    xoa_vai_tro_phan_quyen: 'Xóa vai trò phân quyền',
    // cấu hình hóa đơn
    cau_hinh_hoa_don: 'Cấu hình hóa đơn',
    xem_danh_sach_hoa_don: 'Xem danh sách hóa đơn',
    cau_hinh_mau_hoa_don: 'Cấu hình mẫu hóa đơn',
    // quản lý bảo hành
    cau_hinh_bao_hanh: 'Cấu hình bảo hành',
    xem_danh_sach_bao_hanh: 'Xem danh sách bảo hành',
    xem_chi_tiet_bao_hanh:'Xem chi tiết bảo hành',
    them_bao_hanh: 'Thêm bảo hành',
    cap_nhat_bao_hanh: 'Cập nhật bảo hành',
    xoa_bao_hanh: 'Xóa bảo hành',
    export_bao_hanh: 'Xuất file bảo hành',
    // quản lý thuế
    cau_hinh_thue: 'Cấu hình thuế',
    xem_danh_sach_thue: 'Xem danh sách thuế',
    xem_chi_tiet_thue: 'Xem chi tiết thuế',
    them_thue: 'Thêm thuế',
    cap_nhat_thue: 'Cập nhật thuế',
    xoa_thue: 'Xóa thuế',
    // quản lý thanh toán
    cau_hinh_phuong_thuc_thanh_toan: 'Cấu hình phương thức thanh toán',
    xem_danh_sach_phuong_thuc_thanh_toan: 'Xem danh sách phương thức thanh toán',
    xem_chi_tiet_phuong_thuc_thanh_toan:'Xem chi tiết phương thức thanh toán',
    them_phuong_thuc_thanh_toan: 'Thêm phương thức thanh toán',
    cap_nhat_phuong_thuc_thanh_toan: 'Cập nhật phương thức thanh toán',
    xoa_phuong_thuc_thanh_toan: 'Xóa phương thức thanh toán',

    //quản lý khuyến mãi
    xem_danh_sach_khuyen_mai: 'Xem danh sách khuyến mãi',
    cau_hinh_khuyen_mai: 'Cấu hình khuyến mãi',
    xem_chi_tiet_khuyen_mai: 'Xem chi tiết khuyến mãi',
    them_khuyen_mai: 'Thêm khuyến mãi',
    cap_nhat_khuyen_mai: 'Cập nhật khuyến mãi',
    xoa_khuyen_mai: 'Xóa khuyến mãi',

    // quản lý tích điểm
    cau_hinh_tich_diem: 'Cấu hình tích điểm',
    cap_nhat_tich_diem: 'Cập nhật tích điểm',
    xem_danh_sach_khach_hang_tich_diem: 'Xem danh sách khách hàng tích điểm',
    // quản lý nhập xuất file
    cau_hinh_nhap_xuat_file: 'Cấu hình nhập xuất file',
    //nhật ký hoạt động
    cau_hinh_nhat_ki_hoat_dong: 'Xem nhật ký hoạt động',

    // Bo loc
    bo_loc_thoi_gian: 'Bộ lọc thời gian',
    hom_nay: 'Hôm nay',
    hom_qua: 'Hôm qua',
    tuan_nay: 'Tuần này',
    tuan_truoc: 'Tuần trước',
    thang_nay: 'Tháng này',
    thang_truoc: 'Tháng trước',
    nam_nay: 'Năm này',
    nam_truoc: 'Năm trước',
    tuy_chon: 'Tùy chọn',

    //diem danh
    diem_danh: 'Điểm danh',
    xem_danh_sach_diem_danh: 'Xem danh sách điểm danh',
    cap_nhat_diem_danh: 'Cập nhật điểm danh'
  }
  return data[key] || key
}
