// import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
// import { initReactI18next } from 'react-i18next'

// import en from './translations/en.json'
// import vi from './translations/vi.json'

// const lang = localStorage.getItem('language')
// if (lang) localStorage.setItem('language', 'vi')

// // the translations
// const resources = {
//   en: { translation: en },
//   vi: { translation: vi },
// }

// i18n
//   .use(Backend)
//   .use(initReactI18next)
//   .init({
//     resources,
//     fallbackLng: lang || 'vi',
//     debug: true,
//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     },
//   })

// export default i18n
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import localStorage from "../utils/localStorage";
import { initReactI18next } from 'react-i18next'

import en from './translations/en.json'
import vi from './translations/vi.json'

const lang = localStorage.getItem('language')
const vii = localStorage.getItem('VI')
const enn = localStorage.getItem('EN')
const fr = localStorage.getItem('FR')
const cn = localStorage.getItem('CN')

if (!lang) localStorage.setItem('language', 'vi')

let resources = {
  en: { translation: typeof JSON.parse(enn) === 'object' && enn ? JSON.parse(enn) : en },
  vi: { translation: typeof JSON.parse(vii) === 'object' && vii ? JSON.parse(vii) : vi },
}
if (typeof JSON.parse(cn) === 'object' && cn) {
  resources = { ...resources, cn: { translation: JSON.parse(cn) } }
}
if (typeof JSON.parse(fr) === 'object' && fr) {
  resources = { ...resources, cn: { translation: JSON.parse(fr) } }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: lang || 'vi',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
