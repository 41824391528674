import React, { useEffect, useState } from 'react'
import { Row, Modal, Button, InputNumber, Table, Col, notification } from 'antd'
import { useTranslation } from 'react-i18next'
// import styles from '../sell.module.scss'
import { getLocationExpiry } from 'apis/location'
import { useSelector } from 'react-redux'
import { orderBy, sortBy } from 'lodash'
import { socket } from '../../../socket'

export const ModalProductLocation = (props) => {
  const { product, variant_id, index, _editProductInTransport, exportLocation, disabled } = props
  const [visible, setVisible] = useState(false)
  const toggle = () => {
    setVisible(!visible)
    // setTotalTmp(!totalTmp)
  }
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { t } = useTranslation()
  const dataUser = useSelector((state) => state.login.dataUser)
  const [locationExpiry, setLocationExpiry] = useState([])
  const [listLocation, setListLocation] = useState([])
  const _getLocationExpiry = async (quantity) => {
    try {
      const res = await getLocationExpiry({
        variant_id: variant_id,
        branch_id: exportLocation,
        warehouse: true,
      })
      if (res.status == 200) {
        // let new_data = orderBy(res.data.data, ['quantity', 'exp'],['desc','asc']);
        let new_data = orderBy(
          res.data.data.filter((e) => e.quantity > 0),
          'exp',
          'asc'
        )
        let new_data2 = orderBy(
          res.data.data.filter((e) => e.quantity == 0),
          'exp',
          'asc'
        )
        new_data = new_data.concat(new_data2)
        let new_quantity = quantity
        for (let i of new_data) {
          let index = product.locations.findIndex((item) => item.location_id == i.location_id)
          if (index !== -1) {
            i.quantity_choose = product.locations[index].quantity || 0
          } else {
            if (i.quantity === 0 || new_quantity === 0) {
              i.quantity_choose = 0
              continue
            }
            if (new_quantity) {
              if (new_quantity > i.quantity) {
                i.quantity_choose = i.quantity
                new_quantity = new_quantity - i.quantity
              } else {
                i.quantity_choose = new_quantity
                new_quantity = 0
              }
            }
          }
        }
        setLocationExpiry(new_data)
        setListLocation(product.locations)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Mã lô',
      dataIndex: 'location_id',
      render: (text, record) => record.location_id,
    },
    {
      title: 'Ngày sản xuất',
      dataIndex: 'mfg',
      render: (text, record) => record.mfg,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'exp',
      render: (text, record) => record.exp,
    },
    {
      title: 'Tồn kho',
      dataIndex: 'quantity',
      render: (text, record) => record.quantity,
    },
    {
      title: 'Số lượng chuyển',
      dataIndex: 'quantity_transport',
      render: (text, record, index) => (
        <InputNumber
          onBlur={(e) => {
            const listProductNew = [...locationExpiry]
            let indexNew = listProductNew.findIndex((e) => e.location_id == record.location_id)
            if (Number(e.target.value) > record.quantity) {
              listProductNew[indexNew].quantity_choose = record.quantity
            } else {
              listProductNew[indexNew].quantity_choose = Number(e.target.value)
            }
            let arrTmp = [...listLocation]
            let index_location = arrTmp.findIndex(
                (item) => item.location_id == record.location_id
              )
            if(Number(e.target.value)=== null){
              arrTmp.splice(index_location,1)
              setListLocation(arrTmp)
            }else{
              if (index_location !== -1) {
                arrTmp[index_location].quantity_choose = Number(e.target.value)
              } else {
                arrTmp.push(listProductNew[indexNew])
              }
              setListLocation(arrTmp)
            }
            // setLocationExpiry([...listProductNew])
          }}
          className="show-handler-number"
          style={{ width: '100%' }}
          value={record.quantity_choose}
          bordered={false}
          max={record.quantity}
          min={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder={t('sell.quantity')}
          disabled={disabled}
        />
      ),
    },
  ]
  const socketListener = () => {
    socket.on(`sale:complete:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
      try {
        notification.success({ key: 'socket', message: 'Số lượng sản phẩm đã thay đổi' })
        if (res.productList.length > 0) {
          _getLocationExpiry()
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  useEffect(() => {
    socketListener()
  }, [socket])

  return (
    <div>
      <div
        style={{
          // display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          color: 'gray',
          cursor: 'pointer',
          marginLeft: 4,
        }}
      >
        <InputNumber
          style={{ width: 120 }}
          onBlur={async (e) => {
            try {
              setVisible(true)
              let value = e.target.value.replaceAll(',', '')
              value = Number(value)
              if (value > 0) {
                if (value > product.total_quantity) {
                  await _getLocationExpiry(product.total_quantity)
                } else {
                  await _getLocationExpiry(value)
                }
              } else {
                await _getLocationExpiry(0)
              }
            } catch (error) {
              console.log(error)
            }
          }}
          defaultValue={product.quantity}
          min={0}
          max={product.inventory === 'advanced' && product.total_quantity}
          placeholder="Nhập số lượng chuyển"
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          disabled={disabled}
        />
        {disabled ? null : (
          <Button
            onClick={async (e) => {
              toggle()
              await _getLocationExpiry()
            }}
            size="small"
            style={{
              width: 120,
              borderRadius: '10px',
              boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
              transition: 'all .3s ease-in',
            }}
          >
            CHỌN LÔ
          </Button>
        )}
      </div>

      <Modal
        title={`Chọn lô sản phẩm ${product.title}`}
        width={800}
        closable={false}
        visible={visible}
        footer={[
          <Row justify="end" gutter={[12, 0]}>
            <Col>
              <Button
                onClick={() => {
                  toggle()
                }}
              >
                Đóng
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={(e) => {
                  _editProductInTransport(
                    'quantity',
                    listLocation
                      .filter((item) => item.quantity_choose > 0)
                      .reduce((total, item) => (total += item.quantity_choose), 0),
                    index
                  )

                  _editProductInTransport(
                    'locations',
                    listLocation
                      .filter((item) => item.quantity_choose > 0)
                      .map((item) => ({
                        ...item,
                        quantity: item.quantity_choose,
                      })),
                    index
                  )
                  // _editProductInTransport('quantity', 3, index)
                  toggle()
                }}
              >
                Xác nhận
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Table
          size="small"
          rowKey="location_id"
          scroll={{ y: 500 }}
          columns={columns}
          style={{ width: '100%' }}
          dataSource={locationExpiry}
        />
      </Modal>
    </div>
  )
}
