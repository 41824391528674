export const PERMISSIONS_APP = [
  {
    pParent: 'tong_quan',
  },
  {
    pParent: 'ban_hang',
  },
  {
    pParent: 'don_hang',
    pChildren: [
      {
        pParent: 'danh_sach_don_hang',
        brother: ['tra_hang'],
        father: 'don_hang',
        pChildren: [
          {
            pParent: 'xem_danh_sach_don_hang',
            brother: ['tao_don_hang','chi_tiet_don_hang', 'cap_nhat_don_hang', 'export_don_hang'],
            uncle: ['tra_hang'],
            father: 'danh_sach_don_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'tao_don_hang',
            brother: ['xem_danh_sach_don_hang','chi_tiet_don_hang', 'cap_nhat_don_hang', 'export_don_hang'],
            uncle: ['tra_hang'],
            father: 'danh_sach_don_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'chi_tiet_don_hang',
            brother: ['xem_danh_sach_don_hang','tao_don_hang', 'cap_nhat_don_hang', 'export_don_hang'],
            uncle: ['tra_hang'],
            father: 'danh_sach_don_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'cap_nhat_don_hang',
            brother: ['xem_danh_sach_don_hang','chi_tiet_don_hang', 'tao_don_hang', 'export_don_hang'],
            uncle: ['tra_hang'],
            father: 'danh_sach_don_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'export_don_hang',
            brother: ['xem_danh_sach_don_hang','chi_tiet_don_hang', 'cap_nhat_don_hang', 'tao_don_hang'],
            uncle: ['tra_hang'],
            father: 'danh_sach_don_hang',
            grand: 'don_hang',
          },
          
        ],
      },
      {
        pParent: 'tra_hang',
        brother: ['danh_sach_don_hang'],
        father: 'don_hang',
        pChildren: [
          {
            pParent: 'xem_danh_sach_tra_hang',
            brother: ['xem_chi_tiet_tra_hang','tao_phieu_tra_hang', 'cap_nhat_phieu_tra_hang', 'export_phieu_tra_hang'],
            uncle: ['danh_sach_don_hang'],
            father: 'tra_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'xem_chi_tiet_tra_hang',
            brother: ['xem_danh_sach_tra_hang','tao_phieu_tra_hang', 'cap_nhat_phieu_tra_hang', 'export_phieu_tra_hang'],
            uncle: ['danh_sach_don_hang'],
            father: 'tra_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'tao_phieu_tra_hang',
            brother: ['xem_danh_sach_tra_hang','xem_chi_tiet_tra_hang', 'cap_nhat_phieu_tra_hang', 'export_phieu_tra_hang'],
            uncle: ['danh_sach_don_hang'],
            father: 'tra_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'cap_nhat_phieu_tra_hang',
            brother: ['xem_danh_sach_tra_hang','xem_chi_tiet_tra_hang', 'tao_phieu_tra_hang', 'export_phieu_tra_hang'],
            uncle: ['danh_sach_don_hang'],
            father: 'tra_hang',
            grand: 'don_hang',
          },
          {
            pParent: 'export_phieu_tra_hang',
            brother: ['xem_danh_sach_tra_hang','xem_chi_tiet_tra_hang', 'tao_phieu_tra_hang', 'cap_nhat_phieu_tra_hang'],
            uncle: ['danh_sach_don_hang'],
            father: 'tra_hang',
            grand: 'don_hang',
          },
        ],
      },
    ],
  },
  {
    pParent: 'van_chuyen',
    pChildren: [
      {
        pParent: 'quan_li_giao_hang',
        brother: ['doi_tac_van_chuyen', 'doi_soat_van_chuyen'],
        father: 'van_chuyen',
        pChildren: [
          {
            pParent: 'xem_danh_sach_giao_hang',
            brother: ['xem_chi_tiet_phieu_giao_hang','cap_nhat_giao_hang', 'them_phieu_giao_hang'],
            uncle: ['doi_tac_van_chuyen', 'doi_soat_van_chuyen'],
            father: 'quan_li_giao_hang',
            grand: 'van_chuyen',
          },
          {
            pParent: 'xem_chi_tiet_phieu_giao_hang',
            brother: ['xem_danh_sach_giao_hang','cap_nhat_giao_hang', 'them_phieu_giao_hang'],
            uncle: ['doi_tac_van_chuyen', 'doi_soat_van_chuyen'],
            father: 'quan_li_giao_hang',
            grand: 'van_chuyen',
          },
          {
            pParent: 'them_phieu_giao_hang',
            brother: ['xem_danh_sach_giao_hang','cap_nhat_giao_hang', 'xem_chi_tiet_phieu_giao_hang'],
            uncle: ['doi_tac_van_chuyen', 'doi_soat_van_chuyen'],
            father: 'quan_li_giao_hang',
            grand: 'van_chuyen',
          },
          {
            pParent: 'cap_nhat_giao_hang',
            brother: ['xem_danh_sach_giao_hang','xem_chi_tiet_phieu_giao_hang', 'them_phieu_giao_hang'],
            uncle: ['doi_tac_van_chuyen', 'doi_soat_van_chuyen'],
            father: 'quan_li_giao_hang',
            grand: 'van_chuyen',
          },
        ],
      },
      {
        pParent: 'doi_soat_van_chuyen',
        brother: ['quan_li_giao_hang', 'doi_tac_van_chuyen'],
        father: 'van_chuyen',
        pChildren: [
          {
            pParent: 'xem_danh_sach_doi_soat_van_chuyen',
            brother: [
              'xem_chi_tiet_phieu_doi_soat_van_chuyen',
              'them_phieu_doi_soat_van_chuyen',
              'cap_nhat_doi_soat_van_chuyen',
              'xoa_phieu_doi_soat_van_chuyen',
              'export_phieu_doi_soat_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_tac_van_chuyen'],
            father: 'doi_soat_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'xem_chi_tiet_phieu_doi_soat_van_chuyen',
            brother: [
              'xem_danh_sach_doi_soat_van_chuyen',
              'them_phieu_doi_soat_van_chuyen',
              'cap_nhat_doi_soat_van_chuyen',
              'xoa_phieu_doi_soat_van_chuyen',
              'export_phieu_doi_soat_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_tac_van_chuyen'],
            father: 'doi_soat_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'them_phieu_doi_soat_van_chuyen',
            brother: [
              'xem_danh_sach_doi_soat_van_chuyen',
              'xem_chi_tiet_phieu_doi_soat_van_chuyen',
              'cap_nhat_doi_soat_van_chuyen',
              'xoa_phieu_doi_soat_van_chuyen',
              'export_phieu_doi_soat_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_tac_van_chuyen'],
            father: 'doi_soat_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'cap_nhat_doi_soat_van_chuyen',
            brother: [
              'xem_danh_sach_doi_soat_van_chuyen',
              'them_phieu_doi_soat_van_chuyen',
              'xem_chi_tiet_phieu_doi_soat_van_chuyen',
              'xoa_phieu_doi_soat_van_chuyen',
              'export_phieu_doi_soat_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_tac_van_chuyen'],
            father: 'doi_soat_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'xoa_phieu_doi_soat_van_chuyen',
            brother: [
              'xem_danh_sach_doi_soat_van_chuyen',
              'them_phieu_doi_soat_van_chuyen',
              'cap_nhat_doi_soat_van_chuyen',
              'xem_chi_tiet_phieu_doi_soat_van_chuyen',
              'export_phieu_doi_soat_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_tac_van_chuyen'],
            father: 'doi_soat_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'export_phieu_doi_soat_van_chuyen',
            brother: [
              'xem_danh_sach_doi_soat_van_chuyen',
              'them_phieu_doi_soat_van_chuyen',
              'cap_nhat_doi_soat_van_chuyen',
              'xoa_phieu_doi_soat_van_chuyen',
              'xem_chi_tiet_phieu_doi_soat_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_tac_van_chuyen'],
            father: 'doi_soat_van_chuyen',
            grand: 'van_chuyen',
          },
        ],
      },
      {
        pParent: 'doi_tac_van_chuyen',
        brother: ['quan_li_giao_hang', 'doi_soat_van_chuyen'],
        father: 'van_chuyen',
        pChildren: [
          {
            pParent: 'xem_danh_sach_doi_tac_van_chuyen',
            brother: [
              'them_doi_tac_van_chuyen',
              'cap_nhat_doi_tac_van_chuyen',
              'xoa_doi_tac_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_soat_van_chuyen'],
            father: 'doi_tac_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'them_doi_tac_van_chuyen',
            brother: [
              'xem_danh_sach_doi_tac_van_chuyen',
              'cap_nhat_doi_tac_van_chuyen',
              'xoa_doi_tac_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_soat_van_chuyen'],
            father: 'doi_tac_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'cap_nhat_doi_tac_van_chuyen',
            brother: [
              'xem_danh_sach_doi_tac_van_chuyen',
              'them_doi_tac_van_chuyen',
              'xoa_doi_tac_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_soat_van_chuyen'],
            father: 'doi_tac_van_chuyen',
            grand: 'van_chuyen',
          },
          {
            pParent: 'xoa_doi_tac_van_chuyen',
            brother: [
              'xem_danh_sach_doi_tac_van_chuyen',
              'cap_nhat_doi_tac_van_chuyen',
              'them_doi_tac_van_chuyen',
            ],
            uncle: ['quan_li_giao_hang', 'doi_soat_van_chuyen'],
            father: 'doi_tac_van_chuyen',
            grand: 'van_chuyen',
          },
        ],
      },
    ],
  },
  {
    pParent: 'san_pham',
    pChildren: [
      {
        pParent: 'quan_li_san_pham',
        brother: ['nhom_san_pham', 'lich_su_thay_doi_gia', 'lich_su_tra_hang', 'lich_su_ban_hang','in_ma_san_pham'],
        father: 'san_pham',
        pChildren: [
          {
            pParent: 'xem_danh_sach_san_pham',
            brother: [
              'chi_tiet_san_pham',
              'them_san_pham',
              'cap_nhat_san_pham',
              'in_ma_san_pham',
              'export_san_pham',
              'mo_ban_san_pham',
              'xoa_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'chi_tiet_san_pham',
            brother: [
              'xem_danh_sach_san_pham',
              'them_san_pham',
              'cap_nhat_san_pham',
              'in_ma_san_pham',
              'export_san_pham',
              'mo_ban_san_pham',
              'xoa_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'them_san_pham',
            brother: [
              'xem_danh_sach_san_pham',
              'chi_tiet_san_pham',
              'cap_nhat_san_pham',
              'in_ma_san_pham',
              'export_san_pham',
              'mo_ban_san_pham',
              'xoa_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'cap_nhat_san_pham',
            brother: [
              'xem_danh_sach_san_pham',
              'them_san_pham',
              'chi_tiet_san_pham',
              'in_ma_san_pham',
              'export_san_pham',
              'mo_ban_san_pham',
              'xoa_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'in_ma_san_pham',
            brother: [
              'xem_danh_sach_san_pham',
              'them_san_pham',
              'cap_nhat_san_pham',
              'chi_tiet_san_pham',
              'export_san_pham',
              'mo_ban_san_pham',
              'xoa_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'export_san_pham',
            brother: [
              'xem_danh_sach_san_pham',
              'them_san_pham',
              'cap_nhat_san_pham',
              'in_ma_san_pham',
              'chi_tiet_san_pham',
              'mo_ban_san_pham',
              'xoa_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'xoa_san_pham',
            brother: [
              'xem_danh_sach_san_pham',
              'them_san_pham',
              'cap_nhat_san_pham',
              'in_ma_san_pham',
              'chi_tiet_san_pham',
              'export_san_pham',
              'mo_ban_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'mo_ban_san_pham',
            brother: [
              'xem_danh_sach_san_pham',
              'them_san_pham',
              'cap_nhat_san_pham',
              'in_ma_san_pham',
              'chi_tiet_san_pham',
              'export_san_pham',
              'xoa_san_pham',
            ],
            uncle: [
              'nhom_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'quan_li_san_pham',
            grand: 'san_pham',
          },
        ],
      },
      {
        pParent: 'nhom_san_pham',
        brother: [
          'quan_li_san_pham',
          'lich_su_thay_doi_gia',
          'lich_su_tra_hang',
          'lich_su_ban_hang',
          'in_ma_san_pham'
        ],
        father: 'san_pham',
        pChildren: [
          {
            pParent: 'xem_danh_sach_nhom_san_pham',
            brother: ['xem_chi_tiet_nhom_san_pham','tao_nhom_san_pham', 'cap_nhat_nhom_san_pham', 'xoa_nhom_san_pham'],
            uncle: [
              'quan_li_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'nhom_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'xem_chi_tiet_nhom_san_pham',
            brother: ['xem_danh_sach_nhom_san_pham','tao_nhom_san_pham', 'cap_nhat_nhom_san_pham', 'xoa_nhom_san_pham'],
            uncle: [
              'quan_li_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'nhom_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'tao_nhom_san_pham',
            brother: ['xem_danh_sach_nhom_san_pham','xem_chi_tiet_nhom_san_pham', 'cap_nhat_nhom_san_pham', 'xoa_nhom_san_pham'],
            uncle: [
              'quan_li_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'nhom_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'cap_nhat_nhom_san_pham',
            brother: ['xem_danh_sach_nhom_san_pham','tao_nhom_san_pham', 'xem_chi_tiet_nhom_san_pham', 'xoa_nhom_san_pham'],
            uncle: [
              'quan_li_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'nhom_san_pham',
            grand: 'san_pham',
          },
          {
            pParent: 'xoa_nhom_san_pham',
            brother: ['xem_danh_sach_nhom_san_pham','tao_nhom_san_pham', 'cap_nhat_nhom_san_pham', 'xem_chi_tiet_nhom_san_pham'],
            uncle: [
              'quan_li_san_pham',
              'lich_su_thay_doi_gia',
              'lich_su_tra_hang',
              'lich_su_ban_hang',
            ],
            father: 'nhom_san_pham',
            grand: 'san_pham',
          },
        ],
      },
      {
        pParent: 'in_ma_san_pham',
        brother: ['nhom_san_pham', 'quan_li_san_pham', 'lich_su_tra_hang', 'lich_su_ban_hang','lich_su_thay_doi_gia'],
        father: 'san_pham',
        isParent: true,
      },
      {
        pParent: 'lich_su_thay_doi_gia',
        brother: ['nhom_san_pham', 'quan_li_san_pham', 'lich_su_tra_hang', 'lich_su_ban_hang','in_ma_san_pham'],
        father: 'san_pham',
        isParent: true,
      },
      {
        pParent: 'lich_su_tra_hang',
        brother: ['nhom_san_pham', 'lich_su_thay_doi_gia', 'quan_li_san_pham', 'lich_su_ban_hang','in_ma_san_pham'],
        father: 'san_pham',
        isParent: true,
      },
      {
        pParent: 'lich_su_ban_hang',
        brother: ['nhom_san_pham', 'lich_su_thay_doi_gia', 'lich_su_tra_hang', 'quan_li_san_pham','in_ma_san_pham'],
        father: 'san_pham',
        isParent: true,
      },
    ],
  },
  {
    pParent: 'quan_li_kho',
    pChildren: [
      {
        pParent: 'dieu_chinh_gia',
        brother: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
        father: 'quan_li_kho',
        pChildren: [
          {
            pParent: 'xem_danh_sach_dieu_chinh_gia',
            brother: ['xem_chi_tiet_phieu_dieu_chinh_gia','tao_phieu_dieu_chinh', 'cap_nhat_phieu_dieu_chinh'],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'dieu_chinh_gia',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xem_chi_tiet_phieu_dieu_chinh_gia',
            brother: ['xem_danh_sach_dieu_chinh_gia','tao_phieu_dieu_chinh', 'cap_nhat_phieu_dieu_chinh'],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'dieu_chinh_gia',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'tao_phieu_dieu_chinh',
            brother: ['xem_danh_sach_dieu_chinh_gia','xem_chi_tiet_phieu_dieu_chinh_gia', 'cap_nhat_phieu_dieu_chinh'],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'dieu_chinh_gia',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'cap_nhat_phieu_dieu_chinh',
            brother: ['xem_danh_sach_dieu_chinh_gia','tao_phieu_dieu_chinh', 'xem_chi_tiet_phieu_dieu_chinh_gia'],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'dieu_chinh_gia',
            grand: 'quan_li_kho',
          },
        ],
      },
      {
        pParent: 'nhap_hang',
        brother: ['dieu_chinh_gia', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
        father: 'quan_li_kho',
        pChildren: [
          {
            pParent: 'xem_danh_sach_nhap_hang',
            brother: [
              'xem_chi_tiet_phieu_nhap_hang',
              'them_phieu_nhap_hang',
              'cap_nhat_phieu_nhap_hang',
              'xoa_phieu_nhap_hang',
              'export_nhap_hang',
            ],
            uncle: [
              'dieu_chinh_gia',
              'kiem_hang_co_ban',
              'kiem_hang_nang_cao',
              'phieu_chuyen_hang',
            ],
            father: 'nhap_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xem_chi_tiet_phieu_nhap_hang',
            brother: [
              'xem_danh_sach_nhap_hang',
              'them_phieu_nhap_hang',
              'cap_nhat_phieu_nhap_hang',
              'xoa_phieu_nhap_hang',
              'export_nhap_hang',
            ],
            uncle: [
              'dieu_chinh_gia',
              'kiem_hang_co_ban',
              'kiem_hang_nang_cao',
              'phieu_chuyen_hang',
            ],
            father: 'nhap_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'them_phieu_nhap_hang',
            brother: [
              'xem_danh_sach_nhap_hang',
              'xem_chi_tiet_phieu_nhap_hang',
              'cap_nhat_phieu_nhap_hang',
              'xoa_phieu_nhap_hang',
              'export_nhap_hang',
            ],
            uncle: [
              'dieu_chinh_gia',
              'kiem_hang_co_ban',
              'kiem_hang_nang_cao',
              'phieu_chuyen_hang',
            ],
            father: 'nhap_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'cap_nhat_phieu_nhap_hang',
            brother: [
              'xem_danh_sach_nhap_hang',
              'them_phieu_nhap_hang',
              'xem_chi_tiet_phieu_nhap_hang',
              'xoa_phieu_nhap_hang',
              'export_nhap_hang',
            ],
            uncle: [
              'dieu_chinh_gia',
              'kiem_hang_co_ban',
              'kiem_hang_nang_cao',
              'phieu_chuyen_hang',
            ],
            father: 'nhap_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xoa_phieu_nhap_hang',
            brother: [
              'xem_danh_sach_nhap_hang',
              'them_phieu_nhap_hang',
              'cap_nhat_phieu_nhap_hang',
              'xem_chi_tiet_phieu_nhap_hang',
              'export_nhap_hang',
            ],
            uncle: [
              'dieu_chinh_gia',
              'kiem_hang_co_ban',
              'kiem_hang_nang_cao',
              'phieu_chuyen_hang',
            ],
            father: 'nhap_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'export_nhap_hang',
            brother: [
              'xem_danh_sach_nhap_hang',
              'them_phieu_nhap_hang',
              'cap_nhat_phieu_nhap_hang',
              'xoa_phieu_nhap_hang',
              'xem_chi_tiet_phieu_nhap_hang',
            ],
            uncle: [
              'dieu_chinh_gia',
              'kiem_hang_co_ban',
              'kiem_hang_nang_cao',
              'phieu_chuyen_hang',
            ],
            father: 'nhap_hang',
            grand: 'quan_li_kho',
          },
        ],
      },
      {
        pParent: 'kiem_hang_co_ban',
        brother: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
        father: 'quan_li_kho',
        pChildren: [
          {
            pParent: 'xem_danh_sach_kiem_hang_co_ban',
            brother: [
              'xem_chi_tiet_phieu_kiem_hang_co_ban',
              'them_phieu_kiem_hang_co_ban',
              'cap_nhat_phieu_kiem_hang_co_ban',
              'can_bang_phieu_kiem_hang_co_ban',
              'xoa_phieu_kiem_hang_co_ban',
              'export_phieu_kiem_hang_co_ban',
            ],
            uncle: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'kiem_hang_co_ban',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xem_chi_tiet_phieu_kiem_hang_co_ban',
            brother: [
              'xem_danh_sach_kiem_hang_co_ban',
              'them_phieu_kiem_hang_co_ban',
              'cap_nhat_phieu_kiem_hang_co_ban',
              'can_bang_phieu_kiem_hang_co_ban',
              'xoa_phieu_kiem_hang_co_ban',
              'export_phieu_kiem_hang_co_ban',
            ],
            uncle: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'kiem_hang_co_ban',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'them_phieu_kiem_hang_co_ban',
            brother: [
              'xem_danh_sach_kiem_hang_co_ban',
              'xem_chi_tiet_phieu_kiem_hang_co_ban',
              'cap_nhat_phieu_kiem_hang_co_ban',
              'can_bang_phieu_kiem_hang_co_ban',
              'xoa_phieu_kiem_hang_co_ban',
              'export_phieu_kiem_hang_co_ban',
            ],
            uncle: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'kiem_hang_co_ban',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'cap_nhat_phieu_kiem_hang_co_ban',
            brother: [
              'xem_danh_sach_kiem_hang_co_ban',
              'them_phieu_kiem_hang_co_ban',
              'xem_chi_tiet_phieu_kiem_hang_co_ban',
              'can_bang_phieu_kiem_hang_co_ban',
              'xoa_phieu_kiem_hang_co_ban',
              'export_phieu_kiem_hang_co_ban',
            ],
            uncle: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'kiem_hang_co_ban',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'can_bang_phieu_kiem_hang_co_ban',
            brother: [
              'xem_danh_sach_kiem_hang_co_ban',
              'them_phieu_kiem_hang_co_ban',
              'cap_nhat_phieu_kiem_hang_co_ban',
              'xem_chi_tiet_phieu_kiem_hang_co_ban',
              'xoa_phieu_kiem_hang_co_ban',
              'export_phieu_kiem_hang_co_ban',
            ],
            uncle: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'kiem_hang_co_ban',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xoa_phieu_kiem_hang_co_ban',
            brother: [
              'xem_danh_sach_kiem_hang_co_ban',
              'them_phieu_kiem_hang_co_ban',
              'cap_nhat_phieu_kiem_hang_co_ban',
              'xem_chi_tiet_phieu_kiem_hang_co_ban',
              'can_bang_phieu_kiem_hang_co_ban',
              'export_phieu_kiem_hang_co_ban',
            ],
            uncle: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'kiem_hang_co_ban',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'export_phieu_kiem_hang_co_ban',
            brother: [
              'xem_danh_sach_kiem_hang_co_ban',
              'them_phieu_kiem_hang_co_ban',
              'cap_nhat_phieu_kiem_hang_co_ban',
              'xem_chi_tiet_phieu_kiem_hang_co_ban',
              'can_bang_phieu_kiem_hang_co_ban',
              'xoa_phieu_kiem_hang_co_ban',
            ],
            uncle: ['nhap_hang', 'dieu_chinh_gia', 'kiem_hang_nang_cao', 'phieu_chuyen_hang'],
            father: 'kiem_hang_co_ban',
            grand: 'quan_li_kho',
          },
        ],
      },
      {
        pParent: 'kiem_hang_nang_cao',
        brother: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
        father: 'quan_li_kho',
        pChildren: [
          {
            pParent: 'xem_danh_sach_kiem_hang_nang_cao',
            brother: [
              'xem_chi_tiet_phieu_kiem_hang_nang_cao',
              'them_phieu_kiem_hang_nang_cao',
              'cap_nhat_phieu_kiem_hang_nang_cao',
              'can_bang_phieu_kiem_hang_nang_cao',
              'xoa_phieu_kiem_hang_nang_cao',
              'export_phieu_kiem_hang_nang_cao',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
            father: 'kiem_hang_nang_cao',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xem_chi_tiet_phieu_kiem_hang_nang_cao',
            brother: [
              'xem_danh_sach_kiem_hang_nang_cao',
              'them_phieu_kiem_hang_nang_cao',
              'cap_nhat_phieu_kiem_hang_nang_cao',
              'can_bang_phieu_kiem_hang_nang_cao',
              'xoa_phieu_kiem_hang_nang_cao',
              'export_phieu_kiem_hang_nang_cao',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
            father: 'kiem_hang_nang_cao',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'them_phieu_kiem_hang_nang_cao',
            brother: [
              'xem_danh_sach_kiem_hang_nang_cao',
              'xem_chi_tiet_phieu_kiem_hang_nang_cao',
              'cap_nhat_phieu_kiem_hang_nang_cao',
              'can_bang_phieu_kiem_hang_nang_cao',
              'xoa_phieu_kiem_hang_nang_cao',
              'export_phieu_kiem_hang_nang_cao',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
            father: 'kiem_hang_nang_cao',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'cap_nhat_phieu_kiem_hang_nang_cao',
            brother: [
              'xem_danh_sach_kiem_hang_nang_cao',
              'them_phieu_kiem_hang_nang_cao',
              'xem_chi_tiet_phieu_kiem_hang_nang_cao',
              'can_bang_phieu_kiem_hang_nang_cao',
              'xoa_phieu_kiem_hang_nang_cao',
              'export_phieu_kiem_hang_nang_cao',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
            father: 'kiem_hang_nang_cao',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'can_bang_phieu_kiem_hang_nang_cao',
            brother: [
              'xem_danh_sach_kiem_hang_nang_cao',
              'them_phieu_kiem_hang_nang_cao',
              'cap_nhat_phieu_kiem_hang_nang_cao',
              'xem_chi_tiet_phieu_kiem_hang_nang_cao',
              'xoa_phieu_kiem_hang_nang_cao',
              'export_phieu_kiem_hang_nang_cao',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
            father: 'kiem_hang_nang_cao',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xoa_phieu_kiem_hang_nang_cao',
            brother: [
              'xem_danh_sach_kiem_hang_nang_cao',
              'them_phieu_kiem_hang_nang_cao',
              'cap_nhat_phieu_kiem_hang_nang_cao',
              'xem_chi_tiet_phieu_kiem_hang_nang_cao',
              'can_bang_phieu_kiem_hang_nang_cao',
              'export_phieu_kiem_hang_nang_cao',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
            father: 'kiem_hang_nang_cao',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'export_phieu_kiem_hang_nang_cao',
            brother: [
              'xem_danh_sach_kiem_hang_nang_cao',
              'them_phieu_kiem_hang_nang_cao',
              'cap_nhat_phieu_kiem_hang_nang_cao',
              'xem_chi_tiet_phieu_kiem_hang_nang_cao',
              'can_bang_phieu_kiem_hang_nang_cao',
              'xoa_phieu_kiem_hang_nang_cao',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'dieu_chinh_gia', 'phieu_chuyen_hang'],
            father: 'kiem_hang_nang_cao',
            grand: 'quan_li_kho',
          },
        ],
      },
      {
        pParent: 'phieu_chuyen_hang',
        brother: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'dieu_chinh_gia'],
        father: 'quan_li_kho',
        pChildren: [
          {
            pParent: 'xem_danh_sach_chuyen_hang',
            brother: [
              'xem_chi_tiet_phieu_chuyen_hang',
              'tao_phieu_chuyen_hang',
              'cap_nhat_phieu_chuyen_hang',
              'xoa_phieu_chuyen_hang',
              'export_phieu_chuyen_hang',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'dieu_chinh_gia'],
            father: 'phieu_chuyen_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xem_chi_tiet_phieu_chuyen_hang',
            brother: [
              'xem_danh_sach_chuyen_hang',
              'tao_phieu_chuyen_hang',
              'cap_nhat_phieu_chuyen_hang',
              'xoa_phieu_chuyen_hang',
              'export_phieu_chuyen_hang',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'dieu_chinh_gia'],
            father: 'phieu_chuyen_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'tao_phieu_chuyen_hang',
            brother: [
              'xem_danh_sach_chuyen_hang',
              'xem_chi_tiet_phieu_chuyen_hang',
              'cap_nhat_phieu_chuyen_hang',
              'xoa_phieu_chuyen_hang',
              'export_phieu_chuyen_hang',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'dieu_chinh_gia'],
            father: 'phieu_chuyen_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'cap_nhat_phieu_chuyen_hang',
            brother: [
              'xem_danh_sach_chuyen_hang',
              'tao_phieu_chuyen_hang',
              'xem_chi_tiet_phieu_chuyen_hang',
              'xoa_phieu_chuyen_hang',
              'export_phieu_chuyen_hang',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'dieu_chinh_gia'],
            father: 'phieu_chuyen_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'xoa_phieu_chuyen_hang',
            brother: [
              'xem_danh_sach_chuyen_hang',
              'tao_phieu_chuyen_hang',
              'cap_nhat_phieu_chuyen_hang',
              'xem_chi_tiet_phieu_chuyen_hang',
              'export_phieu_chuyen_hang',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'dieu_chinh_gia'],
            father: 'phieu_chuyen_hang',
            grand: 'quan_li_kho',
          },
          {
            pParent: 'export_phieu_chuyen_hang',
            brother: [
              'xem_danh_sach_chuyen_hang',
              'tao_phieu_chuyen_hang',
              'cap_nhat_phieu_chuyen_hang',
              'xoa_phieu_chuyen_hang',
              'xem_chi_tiet_phieu_chuyen_hang',
            ],
            uncle: ['nhap_hang', 'kiem_hang_co_ban', 'kiem_hang_nang_cao', 'dieu_chinh_gia'],
            father: 'phieu_chuyen_hang',
            grand: 'quan_li_kho',
          },
        ],
      },
    ],
  },
  {
    pParent: 'quan_li_nhan_vien',
    pChildren: [
      {
        pParent: 'danh_sach_nhan_vien',
        brother: ['lich_lam_viec','diem_danh'],
        father: 'quan_li_nhan_vien',
        pChildren: [
          {
            pParent: 'xem_danh_sach_nhan_vien',
            brother: ['xem_chi_tiet_nhan_vien','them_nhan_vien', 'cap_nhat_nhan_vien', 'xoa_nhan_vien'],
            uncle: ['lich_lam_viec','diem_danh'],
            father: 'danh_sach_nhan_vien',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'xem_chi_tiet_nhan_vien',
            brother: ['xem_danh_sach_nhan_vien','them_nhan_vien', 'cap_nhat_nhan_vien', 'xoa_nhan_vien'],
            uncle: ['lich_lam_viec','diem_danh'],
            father: 'danh_sach_nhan_vien',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'them_nhan_vien',
            brother: ['xem_danh_sach_nhan_vien','xem_chi_tiet_nhan_vien', 'cap_nhat_nhan_vien', 'xoa_nhan_vien'],
            uncle: ['lich_lam_viec','diem_danh'],
            father: 'danh_sach_nhan_vien',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'cap_nhat_nhan_vien',
            brother: ['xem_danh_sach_nhan_vien','them_nhan_vien', 'xem_chi_tiet_nhan_vien', 'xoa_nhan_vien'],
            uncle: ['lich_lam_viec','diem_danh'],
            father: 'danh_sach_nhan_vien',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'xoa_nhan_vien',
            brother: ['xem_danh_sach_nhan_vien','them_nhan_vien', 'cap_nhat_nhan_vien', 'xem_chi_tiet_nhan_vien'],
            uncle: ['lich_lam_viec','diem_danh'],
            father: 'danh_sach_nhan_vien',
            grand: 'quan_li_nhan_vien',
          },
        ],
      },
      {
        pParent: 'lich_lam_viec',
        brother: ['danh_sach_nhan_vien','diem_danh'],
        father: 'quan_li_nhan_vien',
        pChildren: [
          {
            pParent: 'xem_danh_sach_lich_lam_viec',
            brother: [
              'xem_chi_tiet_lich_lam_viec',
              'cau_hinh_ca_lam_viec',
              'them_lich_lam_viec',
              'cap_nhat_lich_lam_viec',
              'xoa_lich_lam_viec',
              'xem_lich_lam_viec_nhan_vien',
            ],
            uncle: ['danh_sach_nhan_vien','diem_danh'],
            father: 'lich_lam_viec',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'xem_chi_tiet_lich_lam_viec',
            brother: [
              'xem_danh_sach_lich_lam_viec',
              'cau_hinh_ca_lam_viec',
              'them_lich_lam_viec',
              'cap_nhat_lich_lam_viec',
              'xoa_lich_lam_viec',
              'xem_lich_lam_viec_nhan_vien',
            ],
            uncle: ['danh_sach_nhan_vien','diem_danh'],
            father: 'lich_lam_viec',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'cau_hinh_ca_lam_viec',
            brother: [
              'xem_danh_sach_lich_lam_viec',
              'xem_chi_tiet_lich_lam_viec',
              'them_lich_lam_viec',
              'cap_nhat_lich_lam_viec',
              'xoa_lich_lam_viec',
              'xem_lich_lam_viec_nhan_vien',
            ],
            uncle: ['danh_sach_nhan_vien','diem_danh'],
            father: 'lich_lam_viec',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'them_lich_lam_viec',
            brother: [
              'xem_danh_sach_lich_lam_viec',
              'cau_hinh_ca_lam_viec',
              'xem_chi_tiet_lich_lam_viec',
              'cap_nhat_lich_lam_viec',
              'xoa_lich_lam_viec',
              'xem_lich_lam_viec_nhan_vien',
            ],
            uncle: ['danh_sach_nhan_vien','diem_danh'],
            father: 'lich_lam_viec',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'cap_nhat_lich_lam_viec',
            brother: [
              'xem_danh_sach_lich_lam_viec',
              'cau_hinh_ca_lam_viec',
              'them_lich_lam_viec',
              'xem_chi_tiet_lich_lam_viec',
              'xoa_lich_lam_viec',
              'xem_lich_lam_viec_nhan_vien',
            ],
            uncle: ['danh_sach_nhan_vien','diem_danh'],
            father: 'lich_lam_viec',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'xoa_lich_lam_viec',
            brother: [
              'xem_danh_sach_lich_lam_viec',
              'cau_hinh_ca_lam_viec',
              'them_lich_lam_viec',
              'cap_nhat_lich_lam_viec',
              'xem_chi_tiet_lich_lam_viec',
              'xem_lich_lam_viec_nhan_vien',
            ],
            uncle: ['danh_sach_nhan_vien','diem_danh'],
            father: 'lich_lam_viec',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'xem_lich_lam_viec_nhan_vien',
            brother: [
              'xem_danh_sach_lich_lam_viec',
              'cau_hinh_ca_lam_viec',
              'them_lich_lam_viec',
              'cap_nhat_lich_lam_viec',
              'xem_chi_tiet_lich_lam_viec',
              'xoa_lich_lam_viec',
            ],
            uncle: ['danh_sach_nhan_vien','diem_danh'],
            father: 'lich_lam_viec',
            grand: 'quan_li_nhan_vien',
          },
        ],
      },
      {
        pParent: 'diem_danh',
        brother: ['danh_sach_nhan_vien','lich_lam_viec'],
        father: 'quan_li_nhan_vien',
        pChildren: [
          {
            pParent: 'xem_danh_sach_diem_danh',
            brother: [
              'cap_nhat_diem_danh'
            ],
            uncle: ['danh_sach_nhan_vien','lich_lam_viec'],
            father: 'diem_danh',
            grand: 'quan_li_nhan_vien',
          },
          {
            pParent: 'cap_nhat_diem_danh',
            brother: [
              'xem_danh_sach_diem_danh'
            ],
            uncle: ['danh_sach_nhan_vien','lich_lam_viec'],
            father: 'diem_danh',
            grand: 'quan_li_nhan_vien',
          },
        ],
      },
    ],
  },

  {
    pParent: 'quan_li_nha_cung_cap',
    pChildren: [
      {
        pParent: 'xem_danh_sach_nha_cung_cap',
        brother: ['xem_chi_tiet_nha_cung_cap','them_nha_cung_cap','cap_nhat_nha_cung_cap', 'xoa_nha_cung_cap', 'export_nha_cung_cap'],
        father: 'quan_li_nha_cung_cap',
      },
      {
        pParent: 'xem_chi_tiet_nha_cung_cap',
        brother: ['xem_danh_sach_nha_cung_cap','them_nha_cung_cap','cap_nhat_nha_cung_cap', 'xoa_nha_cung_cap', 'export_nha_cung_cap'],
        father: 'quan_li_nha_cung_cap',
      },
      {
        pParent: 'them_nha_cung_cap',
        brother: ['xem_chi_tiet_nha_cung_cap','xem_danh_sach_nha_cung_cap','cap_nhat_nha_cung_cap', 'xoa_nha_cung_cap', 'export_nha_cung_cap'],
        father: 'quan_li_nha_cung_cap',
      },
      {
        pParent: 'cap_nhat_nha_cung_cap',
        brother: ['xem_chi_tiet_nha_cung_cap','xem_danh_sach_nha_cung_cap','them_nha_cung_cap', 'xoa_nha_cung_cap', 'export_nha_cung_cap'],
        father: 'quan_li_nha_cung_cap',
      },
      {
        pParent: 'xoa_nha_cung_cap',
        brother: ['xem_chi_tiet_nha_cung_cap','xem_danh_sach_nha_cung_cap','cap_nhat_nha_cung_cap', 'them_nha_cung_cap', 'export_nha_cung_cap'],
        father: 'quan_li_nha_cung_cap',
      },
      {
        pParent: 'export_nha_cung_cap',
        brother: ['xem_chi_tiet_nha_cung_cap','xem_danh_sach_nha_cung_cap','cap_nhat_nha_cung_cap', 'xoa_nha_cung_cap', 'them_nha_cung_cap'],
        father: 'quan_li_nha_cung_cap',
      },
    ],
  },
  {
    pParent: 'quan_li_khach_hang',
    pChildren: [
      {
        pParent: 'xem_danh_sach_khach_hang',
        brother: ['xem_chi_tiet_khach_hang','them_khach_hang', 'cap_nhat_khach_hang', 'xoa_khach_hang', 'export_khach_hang'],
        father: 'quan_li_khach_hang',
      },
      {
        pParent: 'xem_chi_tiet_khach_hang',
        brother: ['xem_danh_sach_khach_hang','them_khach_hang', 'cap_nhat_khach_hang', 'xoa_khach_hang', 'export_khach_hang'],
        father: 'quan_li_khach_hang',
      },
      {
        pParent: 'them_khach_hang',
        brother: [
          'xem_danh_sach_khach_hang',
          'xem_chi_tiet_khach_hang',
          'cap_nhat_khach_hang',
          'xoa_khach_hang',
          'export_khach_hang',
        ],
        father: 'quan_li_khach_hang',
      },
      {
        pParent: 'cap_nhat_khach_hang',
        brother: [
          'xem_danh_sach_khach_hang',
          'them_khach_hang',
          'xem_chi_tiet_khach_hang',
          'xoa_khach_hang',
          'export_khach_hang',
        ],
        father: 'quan_li_khach_hang',
      },
      {
        pParent: 'xoa_khach_hang',
        brother: [
          'xem_danh_sach_khach_hang',
          'them_khach_hang',
          'cap_nhat_khach_hang',
          'xem_chi_tiet_khach_hang',
          'export_khach_hang',
        ],
        father: 'quan_li_khach_hang',
      },
      {
        pParent: 'export_khach_hang',
        brother: [
          'xem_danh_sach_khach_hang',
          'them_khach_hang',
          'cap_nhat_khach_hang',
          'xoa_khach_hang',
          'xem_chi_tiet_khach_hang',
        ],
        father: 'quan_li_khach_hang',
      },
    ],
  },
  {
    pParent: 'danh_sach_bao_cao',
    pChildren: [
      {
        pParent: 'bao_cao_ton_kho',
        brother: [
          'bao_cao_xuat_nhap_ton',
          'bao_cao_ban_hang',
          'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
          'bao_cao_ton_kho_theo_lo',
          'bao_cao_thu_chi',
          'chot_ca',
          'loai_phieu_thu_chi',
        ],
        isParent: true,
        father: 'danh_sach_bao_cao',
      },
      {
        pParent: 'bao_cao_xuat_nhap_ton',
        brother: [
          'bao_cao_ton_kho',
          'bao_cao_ban_hang',
          'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
          'bao_cao_ton_kho_theo_lo',
          'bao_cao_thu_chi',
          'chot_ca',
          'loai_phieu_thu_chi',
        ],
        isParent: true,
        father: 'danh_sach_bao_cao',
      },
      {
        pParent: 'bao_cao_ban_hang',
        brother: [
          'bao_cao_xuat_nhap_ton',
          'bao_cao_ton_kho',
          'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
          'bao_cao_ton_kho_theo_lo',
          'bao_cao_thu_chi',
          'chot_ca',
          'loai_phieu_thu_chi',
        ],
        father: 'danh_sach_bao_cao',
        isParent: true,
      },
      {
        pParent: 'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
        brother: [
          'bao_cao_xuat_nhap_ton',
          'bao_cao_ban_hang',
          'bao_cao_ton_kho',
          'bao_cao_ton_kho_theo_lo',
          'bao_cao_thu_chi',
          'chot_ca',
          'loai_phieu_thu_chi',
        ],
        father: 'danh_sach_bao_cao',
        isParent: true,
      },
      {
        pParent: 'bao_cao_ton_kho_theo_lo',
        brother: [
          'bao_cao_xuat_nhap_ton',
          'bao_cao_ban_hang',
          'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
          'bao_cao_ton_kho',
          'bao_cao_thu_chi',
          'chot_ca',
          'loai_phieu_thu_chi',
        ],
        father: 'danh_sach_bao_cao',
        isParent: true,
      },

      {
        pParent: 'bao_cao_thu_chi',
        brother: [
          'bao_cao_xuat_nhap_ton',
          'bao_cao_ban_hang',
          'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
          'bao_cao_ton_kho_theo_lo',
          'bao_cao_ton_kho',
          'chot_ca',
          'loai_phieu_thu_chi',
        ],
        father: 'danh_sach_bao_cao',
        pChildren: [
          {
            pParent: 'xem_danh_sach_phieu_thu_chi',
            brother: ['xem_chi_tiet_phieu_thu_chi','them_phieu_thu_chi','cap_nhat_phieu_thu_chi', 'xoa_phieu_thu_chi', 'export_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_ton_kho',
              'chot_ca',
              'loai_phieu_thu_chi',
            ],
            father: 'bao_cao_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'xem_chi_tiet_phieu_thu_chi',
            brother: ['xem_danh_sach_phieu_thu_chi','them_phieu_thu_chi','cap_nhat_phieu_thu_chi', 'xoa_phieu_thu_chi', 'export_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_ton_kho',
              'chot_ca',
              'loai_phieu_thu_chi',
            ],
            father: 'bao_cao_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'them_phieu_thu_chi',
            brother: ['xem_chi_tiet_phieu_thu_chi','xem_danh_sach_phieu_thu_chi','cap_nhat_phieu_thu_chi', 'xoa_phieu_thu_chi', 'export_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_ton_kho',
              'chot_ca',
              'loai_phieu_thu_chi',
            ],
            father: 'bao_cao_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'cap_nhat_phieu_thu_chi',
            brother: ['xem_chi_tiet_phieu_thu_chi','xem_danh_sach_phieu_thu_chi','them_phieu_thu_chi', 'xoa_phieu_thu_chi', 'export_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_ton_kho',
              'chot_ca',
              'loai_phieu_thu_chi',
            ],
            father: 'bao_cao_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'xoa_phieu_thu_chi',
            brother: ['xem_chi_tiet_phieu_thu_chi','xem_danh_sach_phieu_thu_chi','cap_nhat_phieu_thu_chi', 'them_phieu_thu_chi', 'export_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_ton_kho',
              'chot_ca',
              'loai_phieu_thu_chi',
            ],
            father: 'bao_cao_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'export_phieu_thu_chi',
            brother: ['xem_chi_tiet_phieu_thu_chi','xem_danh_sach_phieu_thu_chi','cap_nhat_phieu_thu_chi', 'xoa_phieu_thu_chi', 'them_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_ton_kho',
              'chot_ca',
              'loai_phieu_thu_chi',
            ],
            father: 'bao_cao_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
        ],
      },
      {
        pParent: 'chot_ca',
        brother: [
          'bao_cao_xuat_nhap_ton',
          'bao_cao_ban_hang',
          'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
          'bao_cao_ton_kho_theo_lo',
          'bao_cao_thu_chi',
          'bao_cao_ton_kho',
          'loai_phieu_thu_chi',
        ],
        father: 'danh_sach_bao_cao',
        pChildren: [
          {
            pParent: 'xem_danh_sach_chot_ca',
            brother: ['tao_phieu_chot_ca'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_thu_chi',
              'bao_cao_ton_kho',
              'loai_phieu_thu_chi',
            ],
            father: 'chot_ca',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'tao_phieu_chot_ca',
            brother: ['xem_danh_sach_chot_ca'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_thu_chi',
              'bao_cao_ton_kho',
              'loai_phieu_thu_chi',
            ],
            father: 'chot_ca',
            grand: 'danh_sach_bao_cao',
          },
        ],
      },
      {
        pParent: 'loai_phieu_thu_chi',
        brother: [
          'bao_cao_xuat_nhap_ton',
          'bao_cao_ban_hang',
          'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
          'bao_cao_ton_kho_theo_lo',
          'bao_cao_thu_chi',
          'chot_ca',
          'bao_cao_ton_kho',
        ],
        father: 'danh_sach_bao_cao',
        pChildren: [
          {
            pParent: 'xem_danh_sach_loai_phieu_thu_chi',
            brother: ['xem_chi_tiet_loai_phieu_thu_chi','them_loai_phieu_thu_chi','cap_nhat_loai_phieu_thu_chi', 'xoa_loai_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_thu_chi',
              'chot_ca',
              'bao_cao_ton_kho',
            ],
            father: 'loai_phieu_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'xem_chi_tiet_loai_phieu_thu_chi',
            brother: ['xem_danh_sach_loai_phieu_thu_chi','them_loai_phieu_thu_chi','cap_nhat_loai_phieu_thu_chi', 'xoa_loai_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_thu_chi',
              'chot_ca',
              'bao_cao_ton_kho',
            ],
            father: 'loai_phieu_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'them_loai_phieu_thu_chi',
            brother: ['xem_chi_tiet_loai_phieu_thu_chi','xem_danh_sach_loai_phieu_thu_chi','cap_nhat_loai_phieu_thu_chi', 'xoa_loai_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_thu_chi',
              'chot_ca',
              'bao_cao_ton_kho',
            ],
            father: 'loai_phieu_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'cap_nhat_loai_phieu_thu_chi',
            brother: ['xem_chi_tiet_loai_phieu_thu_chi','xem_danh_sach_loai_phieu_thu_chi','them_loai_phieu_thu_chi', 'xoa_loai_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_thu_chi',
              'chot_ca',
              'bao_cao_ton_kho',
            ],
            father: 'loai_phieu_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
          {
            pParent: 'xoa_loai_phieu_thu_chi',
            brother: ['xem_chi_tiet_loai_phieu_thu_chi','xem_danh_sach_loai_phieu_thu_chi','cap_nhat_loai_phieu_thu_chi', 'them_loai_phieu_thu_chi'],
            uncle: [
              'bao_cao_xuat_nhap_ton',
              'bao_cao_ban_hang',
              'bao_cao_ban_hang_theo_phuong_thuc_thanh_toan',
              'bao_cao_ton_kho_theo_lo',
              'bao_cao_thu_chi',
              'chot_ca',
              'bao_cao_ton_kho',
            ],
            father: 'loai_phieu_thu_chi',
            grand: 'danh_sach_bao_cao',
          },
        ],
      },
    ],
  },
  {
    pParent: 'cau_hinh',
    pChildren: [
      {
        pParent: 'cau_hinh_chi_nhanh',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_bao_hanh',
          'cau_hinh_thue',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_khuyen_mai',
          'cau_hinh_tich_diem',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        pChildren: [
          {
            pParent: 'xem_danh_sach_chi_nhanh',
            brother: ['xem_chi_tiet_chi_nhanh','them_chi_nhanh','cap_nhat_chi_nhanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_chi_nhanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xem_chi_tiet_chi_nhanh',
            brother: ['xem_danh_sach_chi_nhanh','them_chi_nhanh','cap_nhat_chi_nhanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_chi_nhanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'them_chi_nhanh',
            brother: ['xem_chi_tiet_chi_nhanh','xem_danh_sach_chi_nhanh','cap_nhat_chi_nhanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_chi_nhanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'cap_nhat_chi_nhanh',
            brother: ['xem_chi_tiet_chi_nhanh','xem_danh_sach_chi_nhanh','them_chi_nhanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_chi_nhanh',
            grand: 'cau_hinh',
          },
        ],
      },
      {
        pParent: 'cau_hinh_hoa_don',
        brother: [
          'cau_hinh_chi_nhanh',
          'cau_hinh_bao_hanh',
          'cau_hinh_thue',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_khuyen_mai',
          'cau_hinh_tich_diem',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        pChildren: [
          {
            pParent: 'cau_hinh_mau_hoa_don',
            uncle: [
              'cau_hinh_chi_nhanh',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_hoa_don',
            grand: 'cau_hinh',
          },
        ],
      },
      {
        pParent: 'cau_hinh_bao_hanh',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_chi_nhanh',
          'cau_hinh_thue',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_khuyen_mai',
          'cau_hinh_tich_diem',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        pChildren: [
          {
            pParent: 'xem_danh_sach_bao_hanh',
            brother: ['xem_chi_tiet_bao_hanh','them_bao_hanh','cap_nhat_bao_hanh', 'xoa_bao_hanh', 'export_bao_hanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_chi_nhanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_bao_hanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xem_chi_tiet_bao_hanh',
            brother: ['xem_danh_sach_bao_hanh','them_bao_hanh','cap_nhat_bao_hanh', 'xoa_bao_hanh', 'export_bao_hanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_chi_nhanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_bao_hanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'them_bao_hanh',
            brother: ['xem_chi_tiet_bao_hanh','xem_danh_sach_bao_hanh','cap_nhat_bao_hanh', 'xoa_bao_hanh', 'export_bao_hanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_chi_nhanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_bao_hanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'cap_nhat_bao_hanh',
            brother: ['xem_chi_tiet_bao_hanh','xem_danh_sach_bao_hanh','them_bao_hanh', 'xoa_bao_hanh', 'export_bao_hanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_chi_nhanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_bao_hanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xoa_bao_hanh',
            brother: ['xem_chi_tiet_bao_hanh','xem_danh_sach_bao_hanh','cap_nhat_bao_hanh', 'them_bao_hanh', 'export_bao_hanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_chi_nhanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_bao_hanh',
            grand: 'cau_hinh',
          },
          {
            pParent: 'export_bao_hanh',
            brother: ['xem_chi_tiet_bao_hanh','xem_danh_sach_bao_hanh','cap_nhat_bao_hanh', 'xoa_bao_hanh', 'them_bao_hanh'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_chi_nhanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_bao_hanh',
            grand: 'cau_hinh',
          },
        ],
      },
      {
        pParent: 'cau_hinh_thue',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_bao_hanh',
          'cau_hinh_chi_nhanh',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_khuyen_mai',
          'cau_hinh_tich_diem',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        pChildren: [
          {
            pParent: 'xem_danh_sach_thue',
            brother: ['xem_chi_tiet_thue','them_thue','cap_nhat_thue', 'xoa_thue'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_chi_nhanh',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_thue',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xem_chi_tiet_thue',
            brother: ['xem_danh_sach_thue','them_thue','cap_nhat_thue', 'xoa_thue'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_chi_nhanh',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_thue',
            grand: 'cau_hinh',
          },
          {
            pParent: 'them_thue',
            brother: ['xem_chi_tiet_thue','xem_danh_sach_thue','cap_nhat_thue', 'xoa_thue'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_chi_nhanh',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_thue',
            grand: 'cau_hinh',
          },
          {
            pParent: 'cap_nhat_thue',
            brother: ['xem_chi_tiet_thue','xem_danh_sach_thue','them_thue', 'xoa_thue'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_chi_nhanh',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_thue',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xoa_thue',
            brother: ['xem_chi_tiet_thue','xem_danh_sach_thue','cap_nhat_thue', 'them_thue'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_chi_nhanh',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_thue',
            grand: 'cau_hinh',
          },
        ],
      },
      {
        pParent: 'cau_hinh_phuong_thuc_thanh_toan',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_bao_hanh',
          'cau_hinh_thue',
          'cau_hinh_chi_nhanh',
          'cau_hinh_khuyen_mai',
          'cau_hinh_tich_diem',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        pChildren: [
          {
            pParent: 'xem_danh_sach_phuong_thuc_thanh_toan',
            brother: ['xem_chi_tiet_phuong_thuc_thanh_toan','them_phuong_thuc_thanh_toan','cap_nhat_phuong_thuc_thanh_toan', 'xoa_phuong_thuc_thanh_toan'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_chi_nhanh',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_phuong_thuc_thanh_toan',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xem_chi_tiet_phuong_thuc_thanh_toan',
            brother: ['xem_danh_sach_phuong_thuc_thanh_toan','them_phuong_thuc_thanh_toan','cap_nhat_phuong_thuc_thanh_toan', 'xoa_phuong_thuc_thanh_toan'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_chi_nhanh',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_phuong_thuc_thanh_toan',
            grand: 'cau_hinh',
          },
          {
            pParent: 'them_phuong_thuc_thanh_toan',
            brother: ['xem_chi_tiet_phuong_thuc_thanh_toan','xem_danh_sach_phuong_thuc_thanh_toan','cap_nhat_phuong_thuc_thanh_toan', 'xoa_phuong_thuc_thanh_toan'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_chi_nhanh',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_phuong_thuc_thanh_toan',
            grand: 'cau_hinh',
          },
          {
            pParent: 'cap_nhat_phuong_thuc_thanh_toan',
            brother: ['xem_chi_tiet_phuong_thuc_thanh_toan','xem_danh_sach_phuong_thuc_thanh_toan','them_phuong_thuc_thanh_toan', 'xoa_phuong_thuc_thanh_toan'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_chi_nhanh',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_phuong_thuc_thanh_toan',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xoa_phuong_thuc_thanh_toan',
            brother: ['xem_chi_tiet_phuong_thuc_thanh_toan','xem_danh_sach_phuong_thuc_thanh_toan','cap_nhat_phuong_thuc_thanh_toan', 'them_phuong_thuc_thanh_toan'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_chi_nhanh',
              'cau_hinh_khuyen_mai',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_phuong_thuc_thanh_toan',
            grand: 'cau_hinh',
          },
        ],
      },
      {
        pParent: 'cau_hinh_khuyen_mai',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_bao_hanh',
          'cau_hinh_thue',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_chi_nhanh',
          'cau_hinh_tich_diem',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        pChildren: [
          {
            pParent: 'xem_danh_sach_khuyen_mai',
            brother: ['xem_chi_tiet_khuyen_mai','them_khuyen_mai', 'cap_nhat_khuyen_mai', 'xoa_khuyen_mai'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_chi_nhanh',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_khuyen_mai',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xem_chi_tiet_khuyen_mai',
            brother: ['xem_danh_sach_khuyen_mai','them_khuyen_mai', 'cap_nhat_khuyen_mai', 'xoa_khuyen_mai'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_chi_nhanh',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_khuyen_mai',
            grand: 'cau_hinh',
          },
          {
            pParent: 'them_khuyen_mai',
            brother: ['xem_danh_sach_khuyen_mai','xem_chi_tiet_khuyen_mai', 'cap_nhat_khuyen_mai', 'xoa_khuyen_mai'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_chi_nhanh',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_khuyen_mai',
            grand: 'cau_hinh',
          },
          {
            pParent: 'cap_nhat_khuyen_mai',
            brother: ['xem_danh_sach_khuyen_mai','them_khuyen_mai', 'xem_chi_tiet_khuyen_mai', 'xoa_khuyen_mai'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_chi_nhanh',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_khuyen_mai',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xoa_khuyen_mai',
            brother: ['xem_danh_sach_khuyen_mai','them_khuyen_mai', 'cap_nhat_khuyen_mai', 'xem_chi_tiet_khuyen_mai'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_chi_nhanh',
              'cau_hinh_tich_diem',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_khuyen_mai',
            grand: 'cau_hinh',
          },
        ],
      },
      {
        pParent: 'cau_hinh_tich_diem',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_bao_hanh',
          'cau_hinh_thue',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_khuyen_mai',
          'cau_hinh_chi_nhanh',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        pChildren: [
          {
            pParent: 'cap_nhat_tich_diem',
            brother: ['xem_danh_sach_khach_hang_tich_diem'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_chi_nhanh',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_tich_diem',
            grand: 'cau_hinh',
          },
          {
            pParent: 'xem_danh_sach_khach_hang_tich_diem',
            brother: ['cap_nhat_tich_diem'],
            uncle: [
              'cau_hinh_hoa_don',
              'cau_hinh_bao_hanh',
              'cau_hinh_thue',
              'cau_hinh_phuong_thuc_thanh_toan',
              'cau_hinh_khuyen_mai',
              'cau_hinh_chi_nhanh',
              'cau_hinh_nhap_xuat_file',
              'cau_hinh_nhat_ki_hoat_dong',
            ],
            father: 'cau_hinh_tich_diem',
            grand: 'cau_hinh',
          },
        ],
      },
      {
        pParent: 'cau_hinh_nhap_xuat_file',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_bao_hanh',
          'cau_hinh_thue',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_khuyen_mai',
          'cau_hinh_tich_diem',
          'cau_hinh_chi_nhanh',
          'cau_hinh_nhat_ki_hoat_dong',
        ],
        father: 'cau_hinh',
        isParent: true,
      },
      {
        pParent: 'cau_hinh_nhat_ki_hoat_dong',
        brother: [
          'cau_hinh_hoa_don',
          'cau_hinh_bao_hanh',
          'cau_hinh_thue',
          'cau_hinh_phuong_thuc_thanh_toan',
          'cau_hinh_khuyen_mai',
          'cau_hinh_tich_diem',
          'cau_hinh_nhap_xuat_file',
          'cau_hinh_chi_nhanh',
        ],
        father: 'cau_hinh',
        isParent: true,
      },
    ],
  },
  {
    pParent: 'bo_loc_thoi_gian',
    pChildren: [
      {
        pParent: 'hom_nay',
        brother: [
          'hom_qua',
          'tuan_nay',
          'tuan_truoc',
          'thang_nay',
          'thang_truoc',
          'nam_nay',
          'nam_truoc',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'hom_qua',
        brother: [
          'hom_nay',
          'tuan_nay',
          'tuan_truoc',
          'thang_nay',
          'thang_truoc',
          'nam_nay',
          'nam_truoc',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'tuan_nay',
        brother: [
          'hom_qua',
          'hom_nay',
          'tuan_truoc',
          'thang_nay',
          'thang_truoc',
          'nam_nay',
          'nam_truoc',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'tuan_truoc',
        brother: [
          'hom_qua',
          'tuan_nay',
          'hom_nay',
          'thang_nay',
          'thang_truoc',
          'nam_nay',
          'nam_truoc',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'thang_nay',
        brother: [
          'hom_qua',
          'tuan_nay',
          'tuan_truoc',
          'hom_nay',
          'thang_truoc',
          'nam_nay',
          'nam_truoc',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'thang_truoc',
        brother: [
          'hom_qua',
          'tuan_nay',
          'tuan_truoc',
          'thang_nay',
          'hom_nay',
          'nam_nay',
          'nam_truoc',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'nam_nay',
        brother: [
          'hom_qua',
          'tuan_nay',
          'tuan_truoc',
          'thang_nay',
          'thang_truoc',
          'hom_nay',
          'nam_truoc',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'nam_truoc',
        brother: [
          'hom_qua',
          'tuan_nay',
          'tuan_truoc',
          'thang_nay',
          'thang_truoc',
          'nam_nay',
          'hom_nay',
          'tuy_chon',
        ],
        father: 'bo_loc_thoi_gian',
      },
      {
        pParent: 'tuy_chon',
        brother: [
          'hom_qua',
          'tuan_nay',
          'tuan_truoc',
          'thang_nay',
          'thang_truoc',
          'nam_nay',
          'nam_truoc',
          'hom_nay',
        ],
        father: 'bo_loc_thoi_gian',
      },
    ],
  },
]
