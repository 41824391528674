import React, { useEffect, useRef, useState } from 'react'
import localStorage from '../../utils/localStorage'
import styles from './import-inventory.module.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ROUTES, PERMISSIONS, IMAGE_DEFAULT, ACTION } from 'consts'
import { filterOptionProduct, formatCash, removeUnicode } from 'utils'
import moment from 'moment'
import noData from 'assets/icons/no-data.png'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'
//antd
import {
  Row,
  Col,
  Divider,
  Input,
  Button,
  Table,
  InputNumber,
  Form,
  Select,
  Radio,
  Spin,
  Tooltip,
  Space,
  DatePicker,
  Upload,
  Modal,
  notification,
  Drawer,
  Checkbox,
} from 'antd'
import { PaymentMethodModal } from './items/paymentMethodModal'
import { ModalProductiMei } from './items/iMeiProductModal'

//icons
import {
  ArrowLeftOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
  SearchOutlined,
  PlusSquareOutlined,
  CreditCardFilled,
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'

//apis
import { getProducts } from 'apis/product'
import { getAllBranch } from 'apis/branch'
import { uploadFile } from 'apis/upload'
import { getSuppliers } from 'apis/supplier'
import { getStaff } from 'apis/manage-staff'
import { getPayments } from 'apis/payment'
import {
  createOrderImportInventory,
  updateOrderImportInventory,
  getOrdersImportInventory,
} from 'apis/inventory'
import { addTax, getTaxs, updateTax, deleteTax } from 'apis/tax'
import jwt_decode from 'jwt-decode'
//components
import Permission from 'components/permission'
import TitlePage from 'components/title-page'
import delay from 'delay'
import PaymentMethods from './payment-methods'
import {searchComponent} from 'utils/search'
import { socket } from '../../socket'
import { _getProductChange } from '../../components/Product/getProductChange'
import ItemProductImei from './items/itemProductImei'
import { getProductsSystem } from 'redux/actions/products'
const { RangePicker } = DatePicker

const { TextArea } = Input
export default function ImportInventory() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataUser = useSelector((state) => state.login.dataUser)
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { products } = useSelector((state) => state.products)
  const query = new URLSearchParams(location.search)
  const product_ids = query.get('product_ids')
  const order_id = query.get('order_id')
  let { code } = useParams()
  const [taxList, setTaxList] = useState([])
  const [users, setUsers] = useState([])
  const [branches, setBranches] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [file, setFile] = useState('')
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [productsSearch, setProductsSearch] = useState([])
  const [importLocation, setImportLocation] = useState({})
  const [visiblePayments, setVisiblePayments] = useState(false)
  const [availableOrder, setAvailableOrder] = useState(false)
  const [paymentMethodDefault, setPaymentMethodDefault] = useState({})
  const [filteredResults, setFilteredResults] = useState([])
  const [orderCurrent, setOrderCurrent] = useState()

  const [supplierId, setSupplierId] = useState()
  const [productsSupplier, setProductsSupplier] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [visibleProductsToSupplier, setVisibleProductsToSupplier] = useState(false)
  const toggleProductsToSupplier = () => {
    setVisibleProductsToSupplier(!visibleProductsToSupplier)
    setProductsSupplier([])
    setSelectedProducts([])
    setSupplierId()
  }
  const [checkSearch, setCheckSearch] = useState('')

  //BulkMfdAndExp
  const [mfg] = useState(new Date())
  const [exp] = useState(new Date())


  //object order create
  const [orderCreate, setOrderCreate] = useState({
    order_details: [], //danh sách sản phẩm trong hóa đơn
    type_payment: 'PAID', //hình thức thanh toán
    sumCostPaid: 0, // tổng tiền của tất cả sản phẩm
    deliveryCharges: 0, //phí giao hàng
    moneyToBePaidByCustomer: 0, // tổng tiền khách hàng phải trả (Tổng tiền thanh toán)
    tax_list: [],
    sum_tax_list: 0,
    discount: 0,
    payments: [{ ...paymentMethodDefault }],
  })
  const _getPayments = async () => {
    try {
      const res = await getPayments()
      if (res.status === 200) {
        let paymentMethodDefault = ''
        res.data.data.map((e) => {
          if (e.default && e.active) paymentMethodDefault = e
        })
        if (paymentMethodDefault) {
          const pDefault = {
            name: paymentMethodDefault.name,
            value: orderCreate.moneyToBePaidByCustomer,
            payment_method_id: paymentMethodDefault.payment_method_id,
          }
          _editOrder('payments', [pDefault])
          setPaymentMethodDefault(pDefault)
        } else {
          const dataActive = res.data.data
            .filter((e) => e.active)
            .map((e) => {
              return e
            })
          _editOrder('payments', [
            {
              name: dataActive[0].name,
              value: orderCreate.moneyToBePaidByCustomer,
              payment_method_id: dataActive[0].payment_method_id,
            },
          ])
          setPaymentMethodDefault({
            name: dataActive[0].name,
            value: orderCreate.moneyToBePaidByCustomer,
            payment_method_id: dataActive[0].payment_method_id,
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _editOrder = (attribute, value) => {
    const orderCreateNew = { ...orderCreate }
    orderCreateNew[attribute] = value

    //tổng giá trị ds thuế
    orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
      (total, current) =>
        total + (current.value / 100) * (orderCreateNew.sumCostPaid - orderCreateNew.discount),
      0
    )

    //tổng tiền khách hàng phải trả (thành tiền)
    //tổng tiền sp + phí ship + VAT (mặc định 10%)
    orderCreateNew.moneyToBePaidByCustomer = +(
      orderCreateNew.sumCostPaid +
      orderCreateNew.deliveryCharges +
      orderCreateNew.sum_tax_list -
      orderCreateNew.discount
    ).toFixed(0)

    form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
    setOrderCreate({ ...orderCreateNew })
  }

  const _addProductToOrder = (product) => {
    if (product) {
      const orderCreateNew = { ...orderCreate }
      const indexProduct = orderCreateNew.order_details.findIndex(
        (e) => e.variant_id === product.variant_id
      )
      if (indexProduct === -1 || product.type_advanced === 2) {
        orderCreateNew.order_details.push({
          ...product,
          quantity: 1,
          price: product.price,
          import_price:
            (product.import_price === 0 ? product.price : product.import_price) ||
            0,
          sumCost:
            (product.import_price === 0 ? product.price : product.import_price) ||
            0,
          mfg: product.type_advanced === 2 ? moment(mfg).format() : '',
          exp: product.type_advanced === 2 ? moment(exp).format() : '',
        })

        orderCreateNew.order_details = sortBy(orderCreateNew.order_details, [
          function (i) {
            return i.variant_id
          },
        ])

        // tổng tiền của tất cả sản phẩm
        orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
          (total, current) => total + current.sumCost,
          0
        )

        //tổng giá trị ds thuế
        orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
          (total, current) =>
            total + (current.value / 100) * (orderCreateNew.sumCostPaid - orderCreateNew.discount),
          0
        )

        //tổng tiền khách hàng phải trả (thành tiền)
        //tổng tiền sp + phí ship + VAT (mặc định 10%)
        orderCreateNew.moneyToBePaidByCustomer = +(
          orderCreateNew.sumCostPaid +
          orderCreateNew.deliveryCharges +
          orderCreateNew.sum_tax_list -
          orderCreateNew.discount
        ).toFixed(0)

        form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
        setOrderCreate({ ...orderCreateNew })
      } else notification.warning({ message: 'Sản phẩm đã được thêm' })
    }
  }
  const _editProductInOrder = (attribute = '', value, index) => {
    if (index !== -1) {
      const orderCreateNew = { ...orderCreate }
      orderCreateNew.order_details[index][attribute] = value

      //tổng tiền của 1 sản phẩm
      orderCreateNew.order_details[index].sumCost =
        +orderCreateNew.order_details[index].quantity *
        (orderCreateNew.order_details[index].import_price !== 0
          ? orderCreateNew.order_details[index].import_price
          : orderCreateNew.order_details[index].import_price)

      // tổng tiền của tất cả sản phẩm
      orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
        (total, current) => total + current.sumCost,
        0
      )

      //tổng giá trị ds thuế
      orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
        (total, current) =>
          total + (current.value / 100) * (orderCreateNew.sumCostPaid - orderCreateNew.discount),
        0
      )

      //tổng tiền khách hàng phải trả (thành tiền)
      //tổng tiền sp + phí ship + VAT (mặc định 10%)
      orderCreateNew.moneyToBePaidByCustomer = +(
        // orderCreateNew.moneyToBePaidByCustomer +
        (
          orderCreateNew.sumCostPaid +
          orderCreateNew.deliveryCharges +
          orderCreateNew.sum_tax_list -
          orderCreateNew.discount
        )
      )
      form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
      setOrderCreate({ ...orderCreateNew })
    }
  }

  const _removeProductToOrder = (indexProduct) => {
    if (indexProduct !== -1) {
      const orderCreateNew = { ...orderCreate }
      orderCreateNew.order_details.splice(indexProduct, 1)

      // tổng tiền của tất cả sản phẩm
      orderCreateNew.sumCostPaid = orderCreateNew.order_details.reduce(
        (total, current) => total + current.sumCost,
        0
      )

      //tổng giá trị ds thuế
      orderCreateNew.sum_tax_list = orderCreateNew.tax_list.reduce(
        (total, current) =>
          total + (current.value / 100) * (orderCreateNew.sumCostPaid - orderCreateNew.discount),
        0
      )

      //tổng tiền khách hàng phải trả (thành tiền)
      //tổng tiền sp + phí ship + VAT (mặc định 10%)
      orderCreateNew.moneyToBePaidByCustomer = +(
        orderCreateNew.sumCostPaid +
        orderCreateNew.deliveryCharges +
        orderCreateNew.sum_tax_list -
        orderCreateNew.discount
      ).toFixed(0)

      setOrderCreate({ ...orderCreateNew })
      form.setFieldsValue({ moneyToBePaidByCustomer: orderCreateNew.moneyToBePaidByCustomer })
    }
  }
  const onCheckPaymentValid = (payments) => {
    var value_payment = 0
    if (payments.map !== undefined) {
      payments.map((item) => {
        value_payment += item.value
      })
      if (value_payment >= orderCreate.moneyToBePaidByCustomer) {
        setAvailableOrder(true)
      } else {
        setAvailableOrder(false)
      }
    }
  }

  const onSetMfgExp = (index, data) => {
    let order_create = { ...orderCreate }
    if (order_create !== undefined && data) {
      order_create.order_details[index].mfg = data[0]
      order_create.order_details[index].exp = data[1]
    }

    setOrderCreate(order_create)
  }

  const columnsProductsToSupplier = [
    {
      title: 'Tên Sản phẩm',
      dataIndex: 'product_name',
    },
    {
      title: 'Phiên bản',
      dataIndex: 'title',
    },
    {
      title: 'Đơn giá nhập',
      dataIndex: 'import_price',
    },
  ]
  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width:'5%',
      align: 'center',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
    },
    {
      title: 'Phiên bản',
      dataIndex: 'title',
    },
    {
      title: (
        <div>
          {'Tồn kho  '}
          <Tooltip
            title={
              <div style={{ textAlign: 'center' }}>
                SL tồn kho hiện tại của địa điểm nhận hàng được chọn bên phải
              </div>
            }
          >
            <InfoCircleTwoTone style={{ fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
      align: 'center',
      render: (data, record, index) => {
        var inventory_current = 0
        if (record.locations != undefined) {
          var index = record.locations.findIndex(
            (item) => item.branch_id == importLocation.branch_id
          )
          if (index !== -1) inventory_current = record.locations[index].quantity
        }

        return (
          <div>
            {importLocation.branch_id == undefined ? (
              <div>Chọn địa điểm nhận hàng để xem</div>
            ) : (
              <div>{inventory_current}
              <ModalProductiMei variant_id={record.variant_id} product={record}/>
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: 'SL nhập thêm',
      width: 100,

      render: (data, record, index) => (
        <InputNumber
          style={{ width: 90, padding: 3 }}
          onChange={(event) => {
            _editProductInOrder('quantity', +event, index)
          }}
          value={record.quantity || 1}
          defaultValue={record.quantity || 1}
          min={1}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder="Số lượng nhập"
        />
      ),
    },
    {
      width: 130,
      title: () => {
        return (
          <div>
            {'Đơn giá nhập  '}
            <Tooltip
              title={
                <div style={{ textAlign: 'center' }}>
                  Hệ thống lấy đơn giá nhập hàng lần gần nhất
                </div>
              }
            >
              <InfoCircleTwoTone style={{ fontSize: 12 }} />
            </Tooltip>
          </div>
        )
      },
      render: (data, record, index) => (
        <InputNumber
          disabled={record.status == 'COMPLETE' || record.status == 'CANCEL' ? true : false}
          style={{ width: '100%', padding: 3 }}
          onBlur={(e) => {
            const value = e.target.value.replaceAll(',', '')
            _editProductInOrder('import_price', +value, index)
          }}
          defaultValue={
            (record.import_price !== 0 && record.import_price) ||
            record.price ||
            0
          }
          min={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          placeholder="Nhập đơn giá nhập"
        />
      ),
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {'Loại hàng có tồn kho '}
            <Tooltip
              title={
                <div style={{ textAlign: 'center' }}>
                  - Tồn kho theo lô sẽ điền ngày tháng năm <br></br>- Tồn kho theo iMei sẽ nhập số
                  iMei <br></br>- Tồn kho thường sẽ không nhập
                </div>
              }
            >
              <InfoCircleTwoTone style={{ fontSize: 12 }} />
            </Tooltip>
          </div>
        )
      },
      width: 250,
      render: (text, record, index) => {
        let keyIndex = orderCreate.order_details.findIndex(
          (e) => e.variant_id === record.variant_id
        )
        return (
          (record.type_advanced === 3 && (
            <ItemProductImei
              quantity={record.quantity}
              editProductInOrder={_editProductInOrder}
              variant_id={record.variant_id}
              listProduct={orderCreate.order_details}
            />
          )) ||
          (record.type_advanced === 2 && (
            <Row gutter={[1, 0]}>
              <Col span={21}>
                <RangePicker
                  showTime
                  format={'YYYY-MM-DD'}
                  style={{ width: '100%' }}
                  suffixIcon={null}
                  onChange={(val) => {
                    if (val) {
                      val = val.map((item) => {
                        return moment(item).format()
                      })
                    }
                    onSetMfgExp(index, val)
                  }}
                  defaultValue={[moment(mfg), moment(exp)]}
                />
              </Col>
              <Col span={3}>
                {keyIndex === index && (
                  <Button
                    style={{ backgroundColor: '#5F73E2', color: 'white' }}
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      _addProductToOrder(
                        orderCreate.order_details.find((e) => e.variant_id === record.variant_id)
                      )
                    }}
                  />
                )}
              </Col>
            </Row>
          ))
        )
      },
    },
    {
      title: 'Tổng tiền',
      width: 100,
      render: (text, record) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {formatCash((record.sumCost !== 0 && record.sumCost) || record.price || 0)}
        </div>
      ),
    },
    {
      width: 25,
      title: '',
      render: (data, record, index) => (
        <DeleteOutlined
          style={{ color: 'red', fontSize: 16 }}
          onClick={() => _removeProductToOrder(index)}
        />
      ),
    },
  ]

  const _getBranches = async () => {
    try {
      const res = await getAllBranch()
      if (res.status === 200) {
        setBranches(res.data.data)

        // default value
        if (!code) {
          setImportLocation({ branch_id: branchIdApp })
          form.setFieldsValue({
            import_location: branchIdApp,
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getUsers = async () => {
    try {
      const res = await getStaff({branch_id: branchIdApp})
      if (res.status === 200) {
        setUsers(res.data.data)

        if (!code)
          form.setFieldsValue({
            order_creator_id: dataUser.user_id,
            receiver_id: dataUser.full_name,
          })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _uploadFile = async (file) => {
    try {
      setLoadingUpload(true)
      const url = await uploadFile(file)
      if (url) setFile(url)
      setLoadingUpload(false)
    } catch (error) {
      setLoadingUpload(false)
    }
  }

  const _addOrEditImportInventoryOrder = async (status = 'DRAFT') => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      await form.validateFields()
      const dataForm = form.getFieldsValue()

      let payment_info = {}

      if (dataForm.payment_status === 'PAID') {
        payment_info.paid_amount = dataForm.moneyToBePaidByCustomer
        payment_info.debt_amount = 0
      }

      if (dataForm.payment_status === 'PAYING') {
        const excessCash = dataForm.moneyToBePaidByCustomer - dataForm.paid
        payment_info.paid_amount = dataForm.paid
        payment_info.debt_amount = excessCash >= 0 ? excessCash : 0
      }

      if (dataForm.payment_status === 'UNPAID') {
        payment_info.paid_amount = 0
        payment_info.debt_amount = dataForm.moneyToBePaidByCustomer
      }

      // Check valid
      for (var i = 0; i < orderCreate.order_details.length; i++) {
        if (
          orderCreate.order_details[i].type_advanced === 2 &&
          moment(orderCreate.order_details[i].mfg).unix() >
            moment(orderCreate.order_details[i].exp).unix()
        ) {
          notification.warning({
            message: 'Hạn sử dụng phải lớn hơn ngày sản xuất',
          })
          dispatch({ type: ACTION.LOADING, data: false })
          return
        }
        if (
          orderCreate.order_details[i].type_advanced === 3 &&
          orderCreate.order_details[i].imei.length === 0
        ) {
          notification.warning({
            message: 'Vui lòng nhập iMei cho sản phẩm tồn kho theo iMei',
          })
          dispatch({ type: ACTION.LOADING, data: false })
          return
        }
      }
      
      const body = {
        ...dataForm,
        note: dataForm?.note?.trim() || '',
        code: dataForm?.code || '',
        tags: dataForm?.tags || [],
        branch_id: importLocation?.branch_id,
        // import_location: { ...importLocation },
        products: orderCreate.order_details.map((e) => ({
          product_id: e.product_id,
          variant_id: e.variant_id,
          price: +e.price,
          import_price: +e.import_price,
          quantity: e.type_advanced === 3 ? e.imei.length : +e.quantity,
          locations: e.locations || [],
          mfg: e.type_advanced === 2 ? e.mfg : '',
          exp: e.type_advanced === 2 ? e.exp : '',
          imei: e.type_advanced === 3 ? e.imei : [],
          type_advanced: e.type_advanced,
        })),
        files: file ? [file] : [],
        complete_date: dataForm.complete_date ? new Date(dataForm.complete_date).toString() : null,
        total_cost: orderCreate.sumCostPaid || 0,
        total_tax: orderCreate.sum_tax_list || 0,
        list_tax: orderCreate.tax_list,
        total_discount: orderCreate.discount,
        fee_shipping: orderCreate.deliveryCharges || 0,
        final_cost: orderCreate.moneyToBePaidByCustomer || 0,
        payment_info: [payment_info],
        payments: orderCreate.payments,
      }
      delete body.moneyToBePaidByCustomer
      delete body.paid
      let res
      if (code) res = await updateOrderImportInventory(body, orderCurrent.order_id)
      else res = await createOrderImportInventory({ ...body, status: status })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `${code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng thành công`,
          })
          history.push(ROUTES.IMPORT_INVENTORIES)
        } else
          notification.error({
            message:
              res.data.message ||
              `${code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng thất bại, vui lòng thử lại`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng thất bại, vui lòng thử lại`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getSuppliers = async () => {
    try {
      const res = await getSuppliers()
      if (res.status === 200) setSuppliers(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getProductsByIds = async (ids) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getProducts({ merge: true, detach: true, bulk_query: ids })
      if (res.status === 200) {
        const products = res.data.data.map((e) => ({
          ...e.variants,
          product_name: e.name,
          import_price: e.import_price || 0,
          quantity: 1,
          inventory_quantity: e.location && e.location[0] ? e.location[0].quantity : 0,
          sumCost: e.import_price || 0,
        }))
        products.map((item) => {
          _addProductToOrder(item)
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _getProductsToSupplier = async (supplierId) => {
    try {
      setProductsSupplier([])
      setSelectedProducts([])
      setLoadingProduct(true)
      const res = await getProducts({ merge: true, detach: true, supplier_id: supplierId })
      if (res.status === 200) {
        const productsSupplierNew = res.data.data
          .filter((item) => item.inventory === 'advanced')
          .map((e) => ({
            ...e.variants,
            product_name: e.name,
            import_price: e.import_price || 0,
            quantity: 1,
            sumCost: e.import_price || 0,
          }))
        setProductsSupplier([...productsSupplierNew])
        setSelectedProducts(res.data.data.map((e) => e.product_id))
      }
      setLoadingProduct(false)
    } catch (error) {
      console.log(error)
      setLoadingProduct(false)
    }
  }

  const _getTaxList = async () => {
    try {
      const res = await getTaxs({ branch_id: branchIdApp })
      if (res.status === 200) {
        setTaxList(res.data.data)

        if (!code) {
          if (orderCreate.tax_list.length === 0 && !product_ids) {
            const taxDefault = res.data.data.find((e) => e.default)
            _editOrder('tax_list', taxDefault ? [taxDefault] : [])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getOrderDetail = async (code) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getOrdersImportInventory({ code: code })
      if (res.status === 200)
        if (res.data.data && res.data.data.length) {
          initOrder(res.data.data[0])
          await delay(100)
          form.setFieldsValue({ code: '', complete_date: '' })
        }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const initOrder = (orderDetail) => {
    setOrderCreate({
      ...orderCreate,
      order_details: orderDetail.products.map((e) => ({
        ...e.variant_info,
        product_name: e.product_info ? e.product_info.name : '',
        quantity: e.quantity || 0,
        import_price: e.import_price || 0,
        sumCost: +e.import_price * +e.quantity,
        mfg: e.mfg || '',
        exp: e.exp || '',
      })),
      type_payment: orderDetail.payment_status || '',
      sumCostPaid: orderDetail.total_cost || 0,
      deliveryCharges: orderDetail.fee_shipping || 0,
      moneyToBePaidByCustomer: orderDetail.final_cost || 0,
      tax_list: orderDetail.list_tax || [],
      sum_tax_list: orderDetail.total_tax,
      discount: orderDetail.total_discount,
    })
    setImportLocation(orderDetail.import_location ? { ...orderDetail.import_location } : {})
    form.setFieldsValue({
      ...orderDetail,
      import_location: orderDetail.import_location ? orderDetail.import_location.branch_id : '',
      complete_date: orderDetail.complete_date ? moment(orderDetail.complete_date) : null,
      moneyToBePaidByCustomer: orderDetail.payment_amount || 0,
      paid: orderDetail.payment_amount || 0,
      order_creator_id: orderDetail.order_creator_id,
      receiver_id: orderDetail.receiver_id,
    })
  }

  useEffect(() =>{
    if(branchIdApp) {
      dispatch(getProductsSystem({branchIdApp: branchIdApp}))
    }
  },[branchIdApp])
  useEffect(() => {
    _getBranches()
    _getSuppliers()
    _getTaxList()
    _getPayments()
  }, [])

  useEffect(() => {
    _getUsers()
  },[branchIdApp])

  useEffect(() => {
    if (!code) {
      form.setFieldsValue({ payment_status: 'PAID', complete_date: moment(new Date()) })

      if (code) _getOrderDetail(code)
      if (product_ids) _getProductsByIds(product_ids)
    } else initOrder()
  }, [])
  return (
    <div className="card">
      <Form layout="vertical" form={form}>
        <TitlePage
          isAffix={true}
          title={
            <Row
              onClick={() => history.push(ROUTES.IMPORT_INVENTORIES)}
              align="middle"
              style={{ color: 'black', cursor: 'pointer' }}
            >
              <ArrowLeftOutlined style={{ marginRight: 5 }} />
              {code ? 'Cập nhật' : 'Tạo'} đơn nhập hàng
            </Row>
          }
        >
          <Space>
            <Button
              style={{ display: code && 'none' }}
              size="large"
              type="primary"
              onClick={() =>
                history.push(
                  `${ROUTES.PRODUCT_BARCODE}?product_ids=${orderCreate.order_details.length > 0 ? orderCreate.order_details
                    .reduce((arr, index) => {
                      arr.push(index.product_id)
                      return arr
                    }, [])
                    .join('--') : ''}`
                )
              }
            >
              in mã vạch
            </Button>
            <Button
              style={{ display: code && 'none' }}
              size="large"
              type="primary"
              onClick={() => _addOrEditImportInventoryOrder()}
            >
              Lưu nháp
            </Button>
            {location.state ? (
              <Permission permissions={[PERMISSIONS.cap_nhat_phieu_nhap_hang]}>
                <Button
                  style={{ minWidth: 100 }}
                  size="large"
                  type="primary"
                  onClick={() => _addOrEditImportInventoryOrder('COMPLETE')}
                >
                  Lưu
                </Button>
              </Permission>
            ) : (
              <>
                <Permission permissions={[PERMISSIONS.them_phieu_nhap_hang]}>
                  <Button
                    style={{ minWidth: 100 }}
                    size="large"
                    type="primary"
                    onClick={() => _addOrEditImportInventoryOrder('COMPLETE')}
                  >
                    Tạo đơn hàng và hoàn tất
                  </Button>
                </Permission>
              </>
            )}
          </Space>
        </TitlePage>

        <Row gutter={30} style={{ marginTop: 25 }}>
          <Col span={18}>
            <div className={styles['block']}>
              <Row justify="space-between" align="middle" wrap={false}>
                <div className={styles['title']}>Sản phẩm</div>
                <Button type="link" onClick={toggleProductsToSupplier}>
                  Chọn nhanh từ nhà cung cấp
                </Button>
                <Modal
                  style={{ top: 20 }}
                  footer={null}
                  title="Chọn sản phẩm từ nhà cung cấp"
                  width={820}
                  visible={visibleProductsToSupplier}
                  onCancel={toggleProductsToSupplier}
                >
                  <Row justify="space-between" wrap={false} align="middle">
                    <Select
                      value={supplierId}
                      onChange={(value) => {
                        setSupplierId(value)
                        _getProductsToSupplier(value)
                      }}
                      showSearch
                      style={{ width: 250, marginBottom: 10 }}
                      placeholder="Chọn nhà cung cấp"
                    >
                      {suppliers.map((supplier, index) => (
                        <Select.Option key={index} value={supplier.supplier_id}>
                          {supplier.name}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button
                      onClick={() => {
                        const products = productsSupplier.filter((product) =>
                          selectedProducts.includes(product.product_id)
                        )

                        _editOrder('order_details', products)
                        toggleProductsToSupplier()
                      }}
                      type="primary"
                      style={{ display: !productsSupplier.length && 'none' }}
                    >
                      Xác nhận
                    </Button>
                  </Row>
                  <Table
                    rowSelection={{
                      selectedRowKeys: selectedProducts,
                      onChange: setSelectedProducts,
                    }}
                    rowKey="product_id"
                    size="small"
                    loading={loadingProduct}
                    dataSource={productsSupplier}
                    columns={columnsProductsToSupplier}
                    pagination={false}
                    style={{ width: '100%' }}
                    scroll={{ y: '60vh' }}
                  />
                </Modal>
              </Row>
              <div className="select-product-sell">
                <Select
                  notFoundContent={loadingProduct ? <Spin size="small" /> : null}
                  dropdownClassName="dropdown-select-search-product"
                  allowClear
                  showSearch
                  clearIcon={<CloseOutlined style={{ color: 'black' }} />}
                  suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
                  style={{ width: '100%', marginBottom: 15 }}
                  placeholder="Tìm kiếm sản phẩm theo tên"
                  filterOption={(input, option) =>
                    removeUnicode(option.key.toString())
                      .toLowerCase()
                      .trim()
                      .includes(removeUnicode(input).toLowerCase().trim())
                  }
                  value={null}
                  onChange={(value) => {
                    if (value !== null && value !== undefined && value !== '') {
                      let index = value.lastIndexOf('/')
                      let resultIndex = value.substring(index + 1)
                      let productResults = products.filter((item) => item.inventory !== 'normal')
                      

                      if (productResults[resultIndex].inventory === 'normal') {
                        if (productResults[resultIndex].is_open === false) {
                          return notification.error({
                            message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                          })
                        } else {
                          _addProductToOrder(productResults[resultIndex])
                        }
                      } else {
                        _addProductToOrder(productResults[resultIndex])
                      }

                      if (productResults.length === 0) {
                        return notification.error({
                          message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                        })
                      }
                    }
                  }}
                  dropdownRender={(menu) => (
                    <div>
                      <Permission permissions={[PERMISSIONS.them_san_pham]}>
                        <Row
                          wrap={false}
                          align="middle"
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.open(ROUTES.PRODUCT_ADD, '_blank')}
                        >
                          <div
                            style={{
                              paddingLeft: 15,
                              width: 45,
                              height: 50,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <PlusSquareOutlined style={{ fontSize: 19 }} />
                          </div>
                          <p style={{ marginLeft: 20, marginBottom: 0, fontSize: 16 }}>
                            Thêm sản phẩm mới
                          </p>
                        </Row>
                      </Permission>
                      {menu}
                    </div>
                  )}
                >
                  {products &&
                    (products.filter((item) => item.inventory !== 'normal')).map(
                      (data, index) =>
                        data && (
                          <Select.Option
                            key={data.title + data.sku + data.barcode + '/' + index + ''}
                          >
                            <Row align="middle" wrap={false} style={{ padding: '7px 13px' }}>
                              <img
                                src={data?.image[0] ? data?.image[0] : IMAGE_DEFAULT}
                                alt=""
                                style={{
                                  minWidth: 55,
                                  minHeight: 55,
                                  maxWidth: 55,
                                  maxHeight: 55,
                                  objectFit: 'cover',
                                }}
                              />

                              <div style={{ width: '100%', marginLeft: 15 }}>
                                <Row wrap={false} justify="space-between">
                                  <span
                                    style={{
                                      maxWidth: 300,
                                      marginBottom: 0,
                                      fontWeight: 600,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: 'vertical',
                                      display: '-webkit-box',
                                    }}
                                  >
                                    {data.title}
                                  </span>
                                  <span style={{ marginBottom: 0, fontWeight: 500 }}>
                                    {formatCash(data.import_price)}
                                  </span>
                                </Row>
                              </div>
                            </Row>
                          </Select.Option>
                        )
                    )}
                </Select>
              </div>

              <Table
                scroll={{ y: 400 }}
                sticky
                pagination={false}
                columns={columns}
                size="small"
                dataSource={[...orderCreate.order_details]}
                locale={{
                  emptyText: (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200,
                      }}
                    >
                      <img src={noData} alt="" style={{ width: 90, height: 90 }} />
                      <h4 style={{ fontSize: 15, color: '#555' }}>
                        Đơn hàng của bạn chưa có sản phẩm
                      </h4>
                    </div>
                  ),
                }}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row
                      style={{ display: orderCreate.order_details.length === 0 && 'none' }}
                    >
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={3}>
                        <div style={{ fontSize: 14.4 }}>
                          <Row wrap={false} justify="space-between">
                            <div>Tổng tiền ({orderCreate.order_details.length} sản phẩm)</div>
                            <div>{formatCash(+orderCreate?.sumCostPaid)}</div>
                          </Row>

                          <Row
                            style={{ marginTop: 5, marginBottom: 5 }}
                            wrap={false}
                            justify="space-between"
                          >
                            <div>Chiết khấu</div>
                            <div>
                              <InputNumber
                                style={{
                                  width: 220,
                                  height: 30,
                                }}
                                onChange={(e) => {
                                  _editOrder('discount', +e)
                                }}
                                min={0}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                defaultValue={orderCreate.discount}
                                value={orderCreate.discount}
                              />
                              {/* {formatCash(orderCreate.discount ? orderCreate.discount.value : 0)}{' '}
                                {orderCreate.discount
                                  ? orderCreate.discount.type === 'VALUE'
                                    ? ''
                                    : '%'
                                  : ''} */}
                            </div>
                          </Row>

                          <Row
                            style={{
                              height: 60,
                              // display: 'flex',
                              // flexDirection: 'column',
                            }}
                            wrap={false}
                            justify="space-between"
                          >
                            <div style={{ paddingRight: 10 }}>Thuế</div>
                            <div>
                              <Select
                                maxTagCount="responsive"
                                // size="small"
                                showSearch
                                style={{
                                  width: 220,
                                  height: 30,
                                }}
                                placeholder="Chọn thuế"
                                optionFilterProp="children"
                                allowClear
                                mode="tags"
                                value={orderCreate.tax_list.map((tax) => tax.tax_id)}
                                onChange={(value) => {
                                  const taxListNew = taxList.filter((tax) =>
                                    value.includes(tax.tax_id)
                                  )
                                  _editOrder('tax_list', taxListNew)
                                }}
                              >
                                {taxList.map((tax, index) => (
                                  <Select.Option value={tax.tax_id} key={index}>
                                    {tax.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          </Row>
                          <Row wrap={false} align="middle">
                            <div style={{ width: '100%', marginBottom: 10 }}>
                              {orderCreate.tax_list.map((tax) => (
                                <Row justify="space-between" wrap={false} align="middle">
                                  <p style={{ marginBottom: 0 }}>
                                    {tax.name} ({tax.value} %)
                                  </p>
                                  <p style={{ marginBottom: 0 }}>
                                    {formatCash(
                                      (tax.value / 100) *
                                        (orderCreate.sumCostPaid
                                          ? +(orderCreate.sumCostPaid - orderCreate.discount)
                                          : 0)
                                    )}
                                  </p>
                                </Row>
                              ))}
                            </div>
                          </Row>

                          <Row
                            style={{ marginTop: 5, marginBottom: 5 }}
                            wrap={false}
                            justify="space-between"
                            align="middle"
                          >
                            <div>Phí giao hàng</div>
                            <div>
                              <InputNumber
                                style={{
                                  width: 220,
                                  height: 30,
                                  textAlign: 'end',
                                }}
                                onChange={(e) => {
                                  _editOrder('deliveryCharges', +e)
                                }}
                                min={0}
                                value={orderCreate.deliveryCharges}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                defaultValue={orderCreate.deliveryCharges}
                              />
                            </div>
                          </Row>
                          <PaymentMethodModal
                            isBranchClosed={false}
                            onCheckPaymentValid={onCheckPaymentValid}
                            setVisible={setVisiblePayments}
                            visible={visiblePayments}
                            moneyToBePaidByCustomer={orderCreate.moneyToBePaidByCustomer}
                            editInvoice={_editOrder}
                            paymentsOld={orderCreate.payments}
                            paymentRefundOld={orderCreate.paymentRefund}
                            excessCashOld={orderCreate.excessCashOld}
                          />
                          {!availableOrder && (
                            <div style={{ color: 'red' }}>
                              {'Vui lòng điền số tiền thanh toán phù hợp'}
                            </div>
                          )}
                          <Row wrap={false} justify="space-between" style={{ fontWeight: 600 }}>
                            <div>Tổng tiền thanh toán</div>
                            <div>{formatCash(+orderCreate.moneyToBePaidByCustomer || 0)}</div>
                          </Row>
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </div>
            <div className={styles['block']} style={{ marginTop: 30 }}>
              <Row wrap={false} align="middle" style={{ fontWeight: 600 }}>
                <CreditCardFilled style={{ fontSize: 17, marginRight: 5 }} />
                <div>PHƯƠNG THỨC THANH TOÁN</div>
              </Row>
              <div style={{ marginTop: 10 }}>
                <Form.Item name="payment_status">
                  <Radio.Group onChange={(e) => _editOrder('type_payment', e.target.value)}>
                    <Space size="small" direction="vertical">
                      <Radio value="PAID">Đã thanh toán toàn bộ</Radio>
                      <Radio value="PAYING">Thanh toán một phần</Radio>
                      <Radio value="UNPAID">Thanh toán sau</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Divider />

              {orderCreate.type_payment === 'UNPAID' && (
                <div>
                  <div>Thành tiền cần thanh toán (bao gồm VAT)</div>
                  <Form.Item
                    name="moneyToBePaidByCustomer"
                    rules={[{ required: true, message: 'Vui lòng nhập thành tiền cần thanh toán' }]}
                  >
                    <InputNumber
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '45%' }}
                      placeholder="Nhập thành tiền cần thanh toán"
                    />
                  </Form.Item>
                </div>
              )}
              {orderCreate.type_payment === 'PAYING' && (
                <Row justify="space-between">
                  <div style={{ width: '45%' }}>
                    <div>Tổng tiền cần thanh toán (bao gồm VAT)</div>
                    <Form.Item
                      name="moneyToBePaidByCustomer"
                      rules={[
                        { required: true, message: 'Vui lòng nhập tổng tiền cần thanh toán' },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        placeholder="Nhập tổng tiền cần thanh toán"
                      />
                    </Form.Item>
                  </div>

                  <div style={{ width: '45%' }}>
                    <div>Số tiền đã trả</div>
                    <Form.Item
                      name="paid"
                      rules={[{ required: true, message: 'Vui lòng nhập số tiền đã trả' }]}
                    >
                      <InputNumber
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        placeholder="Nhập số tiền đã trả"
                      />
                    </Form.Item>
                  </div>
                </Row>
              )}

              {orderCreate.type_payment === 'PAID' && (
                <div>
                  <div>Tổng tiền cần thanh toán (bao gồm VAT)</div>
                  <Form.Item
                    name="moneyToBePaidByCustomer"
                    rules={[{ required: true, message: 'Vui lòng nhập tổng tiền cần thanh toán' }]}
                  >
                    <InputNumber
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '45%' }}
                      placeholder="Nhập tổng tiền cần thanh toán"
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          </Col>

          <Col span={6}>
            <div className={styles['block']} style={{ marginBottom: 30 }}>
              <div className={styles['title']}>Thông tin đơn nhập hàng</div>
              <Form.Item label="Mã đơn nhập hàng" name="code">
                <Input placeholder="Nhập mã đơn nhập hàng" />
              </Form.Item>
              <Form.Item
                name="import_location"
                label="Địa điểm nhận hàng"
                rules={[{ required: true, message: 'Vui lòng chọn địa điểm nhận hàng!' }]}
              >
                <Select
                  value=""
                  showSearch
                  optionFilterProp="children"
                  placeholder="Chọn địa điểm nhận hàng"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    removeUnicode(option.children.toString()).toLowerCase().trim().indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                  }
                  onChange={(value, option) => {
                    let p = {}
                    if (value) {
                      const branchFind = branches.find((e) => e.branch_id === value)
                      if (branchFind) p.branch_id = branchFind.branch_id
                    }
                    setImportLocation({ ...p })
                  }}
                >
                  {branches.map((e, index) => (
                    <Select.Option value={e.branch_id} key={index}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Ngày nhập hàng"
                name="complete_date"
                rules={[{ required: true, message: 'Vui lòng chọn ngày nhập hàng!' }]}
              >
                <DatePicker
                  placeholder="Chọn ngày giao"
                  style={{ width: '100%' }}
                  size="large"
                  className="br-15__date-picker"
                  showTime={{ format: 'HH:mm' }}
                />
              </Form.Item>

              <Form.Item label="Người tạo đơn" name="creator_id">
                <Input
                  showSearch
                  optionFilterProp="children"
                  disabled
                  defaultValue={dataUser?.first_name + '' + dataUser.last_name}
                />
              </Form.Item>

              <Form.Item label="Người xác nhận đơn" name="verifier_id">
                <Select
                  allowClear
                  defaultValue={dataUser?.user_id}
                  showSearch
                  optionFilterProp="children"
                  placeholder="Chọn Người xác nhận đơn"
                  filterOption={(input, option) =>
                    removeUnicode(option.children.toString()).toLowerCase().trim().indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                  }
                >
                  {users.map((user, index) => (
                    <Select.Option value={user.user_id} key={index}>
                      {user.first_name + ' ' + user.last_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={styles['block']}>
              <div className={styles['title']}>Hóa đơn </div>
              <div>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  data={_uploadFile}
                >
                  {file ? (
                    <img src={file} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    <div>
                      {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Tải hóa đơn</div>
                    </div>
                  )}
                </Upload>
              </div>

              <div className={styles['title']}>
                Ghi chú{' '}
                <Tooltip
                  title={
                    <div style={{ textAlign: 'center' }}>
                      Thêm thông tin ghi chú phục vụ cho việc xem thông tin và xử lý đơn hàng. (VD:
                      đơn giao trong ngày, giao trong giờ hành chính...)
                    </div>
                  }
                >
                  <InfoCircleTwoTone style={{ fontSize: 12 }} />
                </Tooltip>
              </div>
              <Form.Item name="note">
                <Input.TextArea rows={2} placeholder="Nhập ghi chú" />
              </Form.Item>

              <div className={styles['title']}>
                Tag{' '}
                <Tooltip
                  title={
                    <div style={{ textAlign: 'center' }}>
                      Chọn hoặc thêm các thẻ cho đơn hàng, thẻ này phục vụ cho việc lọc các đơn (VD:
                      Đơn giao gấp, đơn nội thành...)
                    </div>
                  }
                >
                  <InfoCircleTwoTone style={{ fontSize: 12 }} />
                </Tooltip>
              </div>
              <Form.Item name="tags">
                <Select mode="tags" placeholder="Nhập tags"></Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
