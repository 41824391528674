import { ArrowLeftOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import TitlePage from 'components/title-page'
import { ROUTES } from 'consts'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ATM from 'assets/icons/ATM.png'
import Banking from 'assets/icons/Banking.png'
import Momo from 'assets/icons/Momo.png'
import VNPay from 'assets/icons/VNPay.png'
import CardItemPayment from './items/cardItemPayment'
import styles from './styles/setting-payment.module.scss'

const SettingPayment = () => {
    const history = useHistory()

    let payments = [
        {
            name: 'Chuyển khoản ngân hàng',
            icon: Banking,
            check: true,
        },
        {
            name: 'Thẻ ATM (có Internet Banking)',
            icon: ATM,
            check: false,
        },
        {
            name: 'VNPAY-QR',
            icon: VNPay,
            check: false,
        },
        {
            name: 'Ví Momo',
            icon: Momo,
            check: true,
        },
    ]
  return (
    <div className="card">
    <TitlePage
      title={
        <Row
          wrap={false}
          align="middle"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
        >
          <ArrowLeftOutlined style={{ marginRight: 8 }} />
          Cấu hình thanh toán
        </Row>
      }
    >
    </TitlePage>
    
    <div className={styles['display-item']}>
      {payments.map((item, index) => (
        <CardItemPayment item={item} key={index}/>
      ))}
    </div>
  </div>
  )
}

export default SettingPayment