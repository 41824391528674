import React, { useEffect, useState } from 'react'
import styles from './point.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from 'consts'
import { useHistory } from 'react-router-dom'
import { PERMISSIONS } from 'consts'

//components
import TitlePage from 'components/title-page'
import _ from 'lodash'
import { socket } from '../../socket'
import TableCustomer from './item/ListCustomer'
import AdvancedSetting from './item/AdvancedSetting'
import BasicSetting from './item/BasicSetting'
//antd
import { Row, Col, Button, notification, Tabs } from 'antd'

//icons
import { ArrowLeftOutlined, UnorderedListOutlined, RadarChartOutlined } from '@ant-design/icons'

//apis
import { getPoint, updatePoint } from 'apis/point'
import { getCustomers } from 'apis/customer'

export default function Point() {
  const dispatch = useDispatch()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [point, setPoint] = useState({})

  const [paramsFilter, setParamsFilter] = useState({page: 1, page_size:20})

  const [customers, setCustomers] = useState([])
  const { TabPane } = Tabs
  const [mode, setMode] = useState('top')

  const [paramsFilterSearchHeader, setParamsFilterSearchHeader] = useState({
    page: 1,
    page_size: 50,
  })

  const _getCustomers = async () => {
    try {
      const res = await getCustomers({ point:1 })
      if (res.status === 200) {
        // let arrtmp = res.data.data.filter((e) => e.point != 0 || e.used_point != 0)
        // let gfg = _.orderBy(arrtmp, 'code', 'desc')
        setCustomers(res.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [config, setConfig] = useState({
    active: false,
    accumulate_for_promotion_product: false,
    accumulate_for_refund_order: false,
    accumulate_for_staff: false,
    accumulate_for_payment_point: false,
    accumulate_for_fee_shipping: false,
    stack_point: false,
    exchange_point_rate: 1,
    exchange_money_rate: 1,
    order_require: 0,
    order_cost_require: 0,
    all_branch: true,
    branch_id: [],
    all_customer_type: true,
    customer_type_id: [],
    all_category: true,
    category_id: [],
    all_product: true,
    product_id: [],
    conversion_rate: true,
    percent: 0,
    debt_orders: false
  })
  const [orderPoint, setOrderPoint] = useState({
    order_require: config.order_require === false ? 0 : config.order_require,
    order_require_check:
      config.order_require === false ? false : config.order_require !== false && true,
  })

  const setValueRate = (e) => {
    if (!e) return 0
    return e
  }

  const onSaveSetting = async () => {
    try {
      if(config.conversion_rate){
        if(!config.exchange_point_rate) config.exchange_point_rate = 1
        else if(config.exchange_point_rate === 0){
          notification.warning({message: "Tỷ lệ quy đổi điểm phải lớn hơn 0"})
          return 
        }
      }else if(!config.percent) config.percent = 1
       else if(config.percent === 0){
        notification.warning({message: "Tỷ lệ quy đổi điểm phải lớn hơn 0"})
        return 
      }
      dispatch({ type: 'LOADING', data: true })
      console.log(config);
      const data = {
        active: config.active,
        accumulate_for_promotion_product: config.accumulate_for_promotion_product,
        accumulate_for_refund_order: config.accumulate_for_refund_order,
        accumulate_for_staff: config.accumulate_for_staff,
        accumulate_for_payment_point: config.accumulate_for_payment_point,
        accumulate_for_fee_shipping: config.accumulate_for_fee_shipping,
        stack_point: config.stack_point,
        exchange_point_rate: setValueRate(config.exchange_point_rate),
        exchange_money_rate: setValueRate(config.exchange_money_rate || 1),
        order_require: orderPoint.order_require_check === true ? orderPoint.order_require : false,
        order_cost_require: config.order_cost_require,
        all_branch: config.all_branch,
        branch_id: config.all_branch === false ? config.branch_id : [],
        all_customer_type: config.all_customer_type,
        customer_type_id: config.all_customer_type === false ? config.customer_type_id : [],
        all_category: config.all_category,
        category_id: config.all_category === false ? config.category_id : [],
        all_product: config.all_product,
        product_id: config.all_product === false ? config.product_id : [],
        order_require_check: orderPoint.order_require_check,
        conversion_rate: config.conversion_rate,
        percent: setValueRate(config.percent),
        branch_id_update: branchIdApp,
        debt_orders: config.debt_orders
      }
      if (data.all_branch === false && data.branch_id.length === 0) {
        notification.error({
          message: 'Cấu hình tích điểm thất bại, vui lòng chọn chi nhánh',
        })
      } else {
        if (data.all_customer_type === false && data.customer_type_id.length === 0) {
          notification.error({
            message: 'Cấu hình tích điểm thất bại, vui lòng chọn nhóm khách hàng',
          })
        } else {
          if (data.all_category === false && data.category_id.length === 0) {
            notification.error({
              message: 'Cấu hình tích điểm thất bại, vui lòng chọn loại sản phẩm',
            })
          } else {
            if (data.all_product === false && data.product_id.length === 0) {
              notification.error({
                message: 'Cấu hình tích điểm thất bại, vui lòng chọn sản phẩm',
              })
            } else {
              const res = await updatePoint(data)
              if (res.status === 200) {
                if (res.data.success) {
                  notification.success({ message: 'Cấu hình tích điểm thành công' })
                  _getPoint()
                } else {
                  notification.error({
                    message: res.data.message || 'Cấu hình tích điểm thất bại, vui lòng thử lại',
                  })
                }
              } else {
                notification.error({
                  message: res.data.message || 'Cấu hình tích điểm thất bại, vui lòng thử lại',
                })
              }
            }
          }
        }
      }

      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'LOADING', data: true })
      // notification.error({ message: 'Cập nhật thất bại' })
    }
  }

  const _getPoint = async () => {
    try {
      setLoading(true)
      const res = await getPoint()
      if (res.data.success) {
        if (res.data.data && res.data.data.length) setPoint(res.data.data[0])
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const socketListenerPoint = () => {
    socket.on(`sale:complete_point:${dataUser._business.business_id}:${branchIdApp}`, _getCustomers())
  }

  useEffect(() => {
    _getPoint()
    _getCustomers()
  }, [])
  useEffect(() => {
    socketListenerPoint()
  }, [socket])
  useEffect(() => {
    _getCustomers()
  }, [paramsFilter])
  useEffect(() => {
    setConfig({
      active: point.active,
      accumulate_for_promotion_product: point.accumulate_for_promotion_product,
      accumulate_for_refund_order: point.accumulate_for_refund_order,
      accumulate_for_staff: point.accumulate_for_staff,
      accumulate_for_payment_point: point.accumulate_for_payment_point,
      accumulate_for_fee_shipping: point.accumulate_for_fee_shipping,
      stack_point: point.stack_point,
      exchange_point_rate: point.exchange_point_rate,
      exchange_money_rate: point.exchange_money_rate,
      order_cost_require: point.order_cost_require,
      all_branch: point.all_branch,
      branch_id: point.branch_id,
      all_customer_type: point.all_customer_type,
      customer_type_id: point.customer_type_id,
      all_category: point.all_category,
      category_id: point.category_id,
      all_product: point.all_product,
      product_id: point.product_id,
      conversion_rate: point.conversion_rate,
      percent: point.percent,
      debt_orders: point.debt_orders || false
    })
  }, [point])
  useEffect(() => {
    setOrderPoint({
      order_require_check: point.order_require_check,
      order_require: point.order_require > 0 ? point.order_require : 0,
    })
  }, [point])

  return (
    <div className={styles['point-container']}>
      <TitlePage
        title={
          <Row
            wrap={false}
            align="middle"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(ROUTES.CONFIGURATION_STORE)}
          >
            <ArrowLeftOutlined style={{ marginRight: 8 }} />
            Tích điểm
          </Row>
        }
      ></TitlePage>
      <div>
        <Tabs id="tab_detail" defaultActiveKey="1" style={{ width: '100%' }} tabPosition={mode}>
          <TabPane
            tab={
              <span style={{ fontSize: 17, marginLeft: 166, marginRight: 57 }}>
                <RadarChartOutlined style={{ fontSize: 20 }} />
                Cấu hình tích điểm
              </span>
            }
            key="1"
          >
            <Col>
              <TitlePage>
                {permissions.includes(PERMISSIONS.cap_nhat_tich_diem) ? (
                  <Button
                    type="primary"
                    size="large"
                    style={{ width: 100 }}
                    onClick={onSaveSetting}
                  >
                    Lưu
                  </Button>
                ) : (
                  <Button type="primary" size="large" style={{ width: 100 }} disabled>
                    Lưu
                  </Button>
                )}
              </TitlePage>
              <BasicSetting setConfig={setConfig} config={config} />
              <AdvancedSetting
                setConfig={setConfig}
                config={config}
                setLoading={setLoading}
                orderPoint={orderPoint}
                setOrderPoint={setOrderPoint}
                paramsFilterSearchHeader={paramsFilterSearchHeader}
                setParamsFilterSearchHeader={setParamsFilterSearchHeader}
              />
            </Col>
          </TabPane>

          <TabPane
            tab={
              <span style={{ fontSize: 17, marginLeft: 166, marginRight: 57 }}>
                <UnorderedListOutlined style={{ fontSize: 20 }} />
                Danh sách khách hàng tích điểm
              </span>
            }
            key="2"
          >
            <TableCustomer
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
              customers={customers}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
