const initialState = {
    product: [],
    customer: []
}
var idAll = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_ALL_ID': {
            return state.product = action.data
        }
        case 'SET_CUSTOMER_ALL_ID': {
            return state.customer = action.data
        }
        default:
            return state
    }
}
export default idAll
