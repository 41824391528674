import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION, IMAGE_DEFAULT, PERMISSIONS, ROUTES } from 'consts'
import { formatCash, removeUnicode } from 'utils'
import { useHistory } from 'react-router-dom'
import { CSVLink } from 'react-csv'
//components
import TitlePage from 'components/title-page'
import { socket } from '../../socket'
import { Select, Button, Input, Form, Row, Col, notification, Space, Spin, Tooltip } from 'antd'
import { ArrowLeftOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import ImportTransport from './item/importTransport'
import Permission from 'components/permission'
import { TableProduct } from './item/table-product'
//apis
import { getAllBranch } from 'apis/branch'
import { addTransportOrder } from 'apis/transport'
import { getProductWarehouse } from 'redux/actions/productWarehouse'

export default function ShippingProductAdd() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [productQuantity, setProductQuantity] = useState([])
  const [tags, setTags] = useState([])
  const products = useSelector((state) => state.products.productWarehouse)
  const [importLocation, setImportLocation] = useState(0)
  const [branches, setBranches] = useState([])
  const [productsTransport, setProductsTransport] = useState([])
  const [productsError, setProductsError] = useState([])

  const headers = [
    { label: 'Lỗi', key: 'error' },
    { label: 'Mã sản phẩm', key: 'sku' },
    { label: 'Mã phiên bản', key: 'variant_sku' },
    { label: 'Số lượng', key: 'quantity' },
    { label: 'Mã lô', key: 'location_check' },
    { label: 'Serial/Imei', key: 'imei_check' },
  ]

  const [timeoutBlinkVariant, setTimeoutBlink] = useState({})
  const scrollSmoothHandler = (variant_id) => {
    const variantEl = document.getElementById(`variant_${variant_id}`)
    const timeOut = variantEl ? 0 : 300
    setTimeout(() => {
      const variantEl = document.getElementById(`variant_${variant_id}`)
      if (variantEl) {
        if (typeof timeoutBlinkVariant[variant_id] != 'undefined') {
          clearTimeout(timeoutBlinkVariant[variant_id])
        }

        variantEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

        variantEl.style.backgroundColor = '#dddddd'

        let intv = setTimeout(() => {
          variantEl.style.backgroundColor = ''
        }, 1000)
        timeoutBlinkVariant[variant_id] = intv
        setTimeoutBlink(timeoutBlinkVariant)
      }
    }, timeOut)
  }

  const _editProductInTransport = (attribute, value, index) => {
    const productsTransportNew = [...productsTransport]
    productsTransportNew[index][attribute] = value
    setProductsTransport([...productsTransportNew])
  }
  const _addProductToTransport = (product) => {
    if (product.inventory === 'normal') {
      return notification.warning({
        key: 'warning_sell_normal_product',
        message: 'Sản phẩm tồn kho cơ bản không thể chuyển, vui lòng chọn sản phẩm khác!',
      })
    }
    scrollSmoothHandler(product.variant_id)
    const productsTransportNew = [...productsTransport]
    let index = productsTransportNew.findIndex((e) => e.variant_id === product.variant_id)
    if (index == -1) {
      if (product.type_advanced === 1) {
        product.quantity = 1
        productsTransportNew.push({
          ...product,
        })
      } else {
        product.quantity = 0
        productsTransportNew.push({
          ...product,
          imei: [],
          locations: [],
        })
      }
    } else {
      if(productsTransportNew[index].quantity < productsTransportNew[index].total_quantity){
        productsTransportNew[index].quantity += 1
      }else {
        return notification.warning({
          key: 'quantity_maximun',
          message: 'Số lượng chuyển của sản phẩm đã tối đa!',
        })
      }
    }
    setProductsTransport([...productsTransportNew])
  }
  const _importProduct = (value) => {
    dispatch({ type: ACTION.LOADING, data: true })
    if (!value) return
    const productsTransportNew = [...productsTransport]
    let newProductError = [...productsError]
    value.map((item) => {
      let product = products.find((product) => product.sku === item.variant_sku)
      let index = productsTransportNew.findIndex((e) => e.variant_id === product.variant_id)
      if (index == -1) {
        if (product?.type_advanced === 1) {
          productsTransportNew.push({
            ...product,
            quantity: item.quantity, //số lượng sản phẩm
          })
        } else {
          productsTransportNew.push({
            ...product,
            imei: item.imei_check || [],
            locations: item.location_check || [],
            quantity: item.quantity, //số lượng sản phẩm
          })
        }
      } else {
        let objectProduct = {
          error: `Phiên bản sản phẩm đã được thêm vào danh sách sản phẩm chuyển`,
          sku: item.sku || '', // mã sản phẩm
          variant_sku: item.variant_sku || '', //mã phiên bản sản phẩm
          quantity: item.quantity || '', //số lượng nhập
          location_check: item.location_check || '',
          imei_check: item.imei_check || '',
        }
        newProductError.push(objectProduct)
      }
    })
    setProductsTransport([...productsTransportNew])
    setProductsError(newProductError)
    dispatch({ type: ACTION.LOADING, data: false })
  }

  const _addOrEditTransportOrder = async (status = 'DRAFT') => {
    await form.validateFields()

    try {
      const dataForm = form.getFieldsValue()

      if (productsTransport.length === 0) {
        notification.warning({ message: 'Vui lòng chọn sản phẩm cần chuyển hàng!' })
        return
      }

      if (branchIdApp === importLocation) {
        notification.warning({
          message: 'Chi nhánh chuyển và chi nhánh nhận không thể trùng nhau!',
        })
        return
      }

      for (let product of productsTransport) {
        if (product.quantity === 0) {
          return notification.error({
            message: `Vui lòng nhập số lượng chuyển của sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 2 && product?.locations?.length > 0) {
          let quantity = product?.locations?.reduce((total, item) => (total += item.quantity), 0)
          if (product.quantity < quantity) {
            return notification.error({
              message: `Số lượng bán đang không bằng số lượng lô được nhập. Vui lòng sửa số lượng của sản phẩm ${product.title}`,
            })
          }
          if (quantity <= 0) {
            return notification.error({
              message: `Vui lòng kiểm tra lại số lượng của sản phẩm ${product.title}`,
            })
          }
        }
        if (product.type_advanced === 2 && !product.locations) {
          return notification.error({
            message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 2 && product?.locations?.length === 0) {
          return notification.error({
            message: `Vui lòng chọn lô cho sản phẩm ${product.title}`,
          })
        }
      }

      for (let product of productsTransport) {
        if (product.type_advanced === 3 && product?.imei?.length > 0) {
          if (product.quantity > product?.imei?.length) {
            return notification.error({
              message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
            })
          }
          if (product.quantity < product?.imei?.length) {
            return notification.error({
              message: `Vui lòng sửa số lượng của sản phẩm ${product.title}`,
            })
          }
        }
        if (product.type_advanced === 3 && !product.imei) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
        if (product.type_advanced === 3 && product?.imei?.length === 0) {
          return notification.error({
            message: `Vui lòng chọn iMei cho sản phẩm ${product.title}`,
          })
        }
      }
      const body = {
        note: dataForm.note || '',
        tags: tags || [],
        branch_id: branchIdApp,
        delivery_time: new Date(dataForm.delivery_time).toString(),
        export_location: branchIdApp,
        import_location: importLocation,
        products: productsTransport.map((e) => ({
          product_id: e.product_id,
          variant_id: e.variant_id,
          title: e.title,
          sku: e.sku,
          locations: e.type_advanced === 2 ? e.locations : [],
          imei: e.type_advanced === 3 ? e.imei : [],
          quantity: +e.quantity,
          type_advanced: e.type_advanced,
        })),
      }
      dispatch({ type: ACTION.LOADING, data: true })
      let res = await addTransportOrder({ ...body, status: status })
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `Tạo phiếu chuyển hàng thành công`,
          })
          history.push({ pathname: `shipping-product-update/${res.data.data.code}` })
          // socketListener()
        } else
          notification.error({
            message:
              res.data.message ||
              `${
                history.location.state ? 'Cập nhật' : 'Tạo'
              } phiếu chuyển hàng thất bại, vui lòng thử lại`,
          })
      } else
        notification.error({
          message:
            res.data.message ||
            `${
              history.location.state ? 'Cập nhật' : 'Tạo'
            } phiếu chuyển hàng thất bại, vui lòng thử lại`,
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getBranches = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getAllBranch()
      if (res.status === 200) setBranches(res.data.data.filter((e) => e.branch_id !== branchIdApp))
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(getProductWarehouse({ branchIdApp: branchIdApp }))
    _getBranches()
  }, [branchIdApp])
  const handleChangeTags = (item) => {
    let regex = /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|{|}|\||\\/
    let new_arr = []
    for (let data of item) {
      if (regex.test(data) === false) {
        new_arr.push(data)
      }
    }
    new_arr = new_arr.map((e) => e.trim())
    setTags(new_arr)
  }

  const socketListener = () => {
    socket.on(`sale:complete:${dataUser._business.business_id}:${branchIdApp}`, async (res) => {
      try {
        notification.success({ key: 'socket', message: 'Số lượng sản phẩm đã thay đổi' })
        if (res.productList.length > 0) {
          dispatch(getProductWarehouse({ branchIdApp: branchIdApp }))
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  useEffect(() => {
    let newTransport = [...productsTransport]
    newTransport.forEach((e) => {
      let indexTransport = products.findIndex((p) => p.variant_id === e.variant_id)
      if (indexTransport !== -1) e.total_quantity = products[indexTransport].total_quantity
    })
    setProductsTransport([...newTransport])
  }, [products])
  useEffect(() => {
    socketListener()
  }, [])
  return (
    <div className="card">
      <TitlePage
        isAffix={true}
        title={
          <Row
            style={{ cursor: 'pointer', width: 'max-content' }}
            wrap={false}
            align="middle"
            onClick={() => history.push(ROUTES.SHIPPING_PRODUCT)}
          >
            <ArrowLeftOutlined style={{ marginRight: 7 }} />
            Thêm phiếu chuyển hàng
          </Row>
        }
      >
        <Space>
          <Permission permissions={[PERMISSIONS.tao_phieu_chuyen_hang]}>
            <Button size="large" type="primary" onClick={() => _addOrEditTransportOrder()}>
              Lưu nháp
            </Button>
          </Permission>
        </Space>
      </TitlePage>

      <Form form={form} layout="vertical" style={{backgroundColor:'white', padding:10}}>
        <Row gutter={[15, 0]} style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng chọn nơi nhận' }]}
              label="Nơi nhận"
              name="import_location"
            >
              <Select
                onChange={(value) => {
                  setImportLocation(value)
                }}
                showSearch
                allowClear
                size="large"
                placeholder="Chọn nơi nhận"
                filterOption={(input, option) =>
                  removeUnicode(option.children.toString())
                    .toLowerCase()
                    .trim()
                    .includes(removeUnicode(input).toLowerCase().trim())
                }
              >
                {branches.map((e, index) => (
                  <Select.Option value={e.branch_id} key={index}>
                    {e.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <p style={{ marginBottom: '8px' }}>Nhãn</p>
            <Select
              style={{ width: '100%' }}
              value={tags}
              onChange={(item) => {
                handleChangeTags(item)
              }}
              mode="tags"
              allowClear
              size="large"
              placeholder="Nhập tags"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              label="Ghi chú"
              name="note"
              rules={[
                {
                  pattern: /^[^<>\/]*$/,
                  message: 'Chỉ cho phép nhập ký tự chữ và số',
                },
              ]}
            >
              <Input.TextArea
                size="large"
                rows={1}
                placeholder="Nhập ghi chú"
                formatter={(value) => `${value}`.replace(/[/<>{}]/g, ' ')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row wrap={false} style={{ marginBottom: 10 }}>
          <a href="https://s3.ap-southeast-1.wasabisys.com/ekata-staging/public/2023/02/16/1676518728804-935165970-ChuyenHang.xlsx">
            <Button>Tải về file import mẫu</Button>
          </a>
          <ImportTransport
            importProduct={_importProduct}
            setProductsError={setProductsError}
            branch_id={branchIdApp}
          />
          <Button
            type="primary"
            style={{ marginLeft: '1em' }}
            disabled={productsError.length > 0 ? false : true}
          >
            <CSVLink
              headers={headers}
              data={productsError}
              filename={'Sản phẩm lỗi'}
              style={{ textDecoration: 'none' }}
            >
              Tải file lỗi
            </CSVLink>
          </Button>
          <Tooltip
            placement="topLeft"
            title={loadingProduct == true ? 'Đang kiểm tra số lượng tồn kho! Vui lòng đợi' : ''}
          >
            <Select
              style={{ width: '100%', marginLeft: '1em' }}
              notFoundContent={loadingProduct ? <Spin size="small" /> : null}
              dropdownClassName="dropdown-select-search-product"
              allowClear
              showSearch
              value={null}
              clearIcon={<CloseOutlined style={{ color: 'black' }} />}
              suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
              placeholder="Thêm sản phẩm vào phiếu chuyển hàng"
              filterOption={(input, option) =>
                removeUnicode(option.key.toString())
                  .toLowerCase()
                  .trim()
                  .includes(removeUnicode(input).toLowerCase().trim())
              }
              onChange={(value, option) => {
                if (value !== null && value !== undefined && value !== '') {
                  let index = value.lastIndexOf('/')
                  let resultIndex = value.substring(index + 1)

                  if (products[resultIndex].inventory === 'advanced') {
                    if (products[resultIndex].total_quantity === 0) {
                      return notification.error({
                        message: 'Sản phẩm không đủ số lượng chuyển',
                      })
                    }
                  }
                  _addProductToTransport(products[resultIndex])
                }
              }}
            >
              {products.map(
                (data, index) =>
                  data && (
                    <Select.Option key={data.title + data.sku + data.barcode + '/' + index + ''}>
                      <Row align="middle" wrap={false} style={{ padding: '7px 13px' }}>
                        <img
                          src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                          alt=""
                          style={{
                            minWidth: 55,
                            minHeight: 55,
                            maxWidth: 55,
                            maxHeight: 55,
                            objectFit: 'cover',
                          }}
                        />

                        <div style={{ width: '100%', marginLeft: 15 }}>
                          <Row wrap={false} justify="space-between">
                            <span
                              style={{
                                maxWidth: 300,
                                marginBottom: 0,
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                              }}
                            >
                              {data.title}
                            </span>
                            <span style={{ marginBottom: 0, fontWeight: 500 }}>
                              {formatCash(data.price)}
                            </span>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <span style={{ marginBottom: 0, color: 'gray' }}>{data.sku}</span>
                            <span style={{ marginBottom: 0, fontWeight: 500 }}>
                              {data.inventory !== 'normal' ? (
                                <span style={{ marginBottom: 0, color: 'gray' }}>
                                  Tồn kho: {formatCash(data.total_quantity)}
                                </span>
                              ) : data.active === true ? (
                                <span style={{ marginBottom: 0, color: 'gray' }}>Còn hàng</span>
                              ) : (
                                <span style={{ marginBottom: 0, color: 'gray' }}>Hết hàng</span>
                              )}
                            </span>
                          </Row>
                        </div>
                      </Row>
                    </Select.Option>
                  )
              )}
            </Select>
          </Tooltip>
        </Row>
        <Row>
          <div style={{ color: 'black', fontWeight: '600', fontSize: 16 }}>
            Danh sách sản phẩm chuyển hàng
          </div>
        </Row>
        <TableProduct
          products={productsTransport}
          _editProductInTransport={_editProductInTransport}
          setProductQuantity={setProductQuantity}
          setProductsTransport={setProductsTransport}
          status={'DRAFT'}
          export_location={branchIdApp}
        />
      </Form>
    </div>
  )
}
