import { get, patch, post, destroy } from './httpClient'

export const getAllUserSystem = (query) => get('/manage-staff/getAllUser', query)
export const createStaff = (body) => post('/manage-staff/createStaff', body)
export const updateStaff = (body, id) => patch(`/manage-staff/updateStaff/${id}`, body)
export const deleteStaff = (body) => patch('/manage-staff/deleteStaff', body )
export const getStaff = (query) => get('/manage-staff/getStaff', query)
export const getListStaff = (query) => get('/manage-staff/getListStaff', query)
export const getDetailStaff = (query) => get('/manage-staff/getDetailStaff', query)
export const getPayRoll = (query) => get('/manage-staff/getPayRoll', query)


