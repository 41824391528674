import { get, patch, post, destroy } from './httpClient'

export const getLastProduct = () => get('/last-product')
export const getProducts = (query) => get('/product', query)
export const getListProducts = (query) => get('/product/list-product', query)

export const getOneProduct = (query) => get('/product/get-one-product', query)
export const getProductChange = (query) => get('/product/get-product-change', query)


export const getVariantsSell = (query) => get('/product/variant-sell', query)

export const getVariants = (query) => get('/product/get-variants', query)
export const getLocationImei = (query) => get('/locations/locationimei', query)
export const getLocationExpiry = (query) => get('/locations/locationexpiry', query)
export const updateProduct = (body, id) => patch(`/product/update/${id}`, body)
export const updateProductOnline = (body, id) => patch(`/product/update-product-online/${id}`, body)
export const deleteProduct = (id) => patch(`/product/delete/${id}`)
export const getAttributes = (query) => get('/product/attribute', query)
export const addProduct = (body) => post('/product/create', body)
export const deleteProducts = (body) => destroy('/product/delete', body)
export const importProducts = (formData) => post('/product/file/import', formData)
export const pricesProduct = () => get('/product/unit')
export const getUnitProductEnum = () => get('/product/enum/unit-product')
export const updateSellNormalProduct = (body, id) => patch(`/product/updateSellNormalProduct/${id}`, body)
export const getChangePriceHistory = (query) => get('/product/change-price-history', query)
export const getSaleHistory = (query) => get('/product/sale-history', query)
export const getRefundHistory = (query) => get('/product/refund-history', query)
