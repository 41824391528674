import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Select, Row, Space, message, notification } from 'antd'
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { getAllBranch } from 'apis/branch'
import {changeBranchRoleUser} from '../../../redux/actions/changeBranch'
import localStorage from '../../../utils/localStorage'

import { getVariantsSystem } from 'redux/actions/variantSell'

const HeaderBranch = ({ _removeMultipleProduct, product, invoices, widthData,changeProductOfBranch,_deleteAllInvoice }) => {
  const user = useSelector((state) => state.login.dataUser)
  const branch = useSelector((state) => state.branch)
  const dispatch = useDispatch()
  const [isMobile, setIsMobile] = useState(false)
  const [branchesNew, setBranchesNew] = useState(false)
  const { branchId, branches } = branch
  const [branchChanges, setBranchesChanges] = useState(branchId)
  const branchIdApp = useSelector((state) => state.branch.branchId)

  useEffect(() => {
    const _getBranches = async () => {
      try {
        const res = await getAllBranch()
        if (res.status === 200) {
          if (user.role_id === 1) return setBranchesNew(res.data.data)
          let userBranchIdList = Array.isArray(user.branch_id)?[...user.branch_id]:[user.branch_id]
          const list = userBranchIdList.map((branchIdUser) => {
            return res.data.data.find((branch) => branchIdUser === branch.branch_id)
          })
          const listFiltered = list.filter((item) => item !== undefined)
          setBranchesNew(listFiltered)
        }
      } catch (error) {
        console.log(error)
      }
    }
    _getBranches()
  }, [])
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else setIsMobile(false)
  }, [])

  return (
    <Row align="middle">
      <Select
        key={branchId}
        defaultValue={branchId}
        style={{ maxWidth: isMobile ? '90%' : 250, marginTop: 3 }}
        placeholder="Chi nhánh"
        value={branchChanges}
        onChange={(value, { isActive }) => {
          let arr = JSON.parse(localStorage.getItem('arrPhone')) || []
          if(arr.length > 0) {
            notification.warning({key:'branch_otp',message: 'Chi nhánh đang có đơn hàng cần xác thực!'})
            return
          }
          _deleteAllInvoice()
          if (!isActive) {
            if (product.length > 0 || invoices.length > 1) {
              _removeMultipleProduct(product.length, invoices.length)
            }
            message.error('Chi nhánh đã ngừng bán!')
          }
          setBranchesChanges(value)
          dispatch(changeBranchRoleUser({data: value, user}))
          dispatch({ type: 'SET_BRANCH_ID', data: value })
          localStorage.setItem('branch_id', value)
          dispatch(getVariantsSystem({branchIdApp: value}))
        }}
      >
        {branchesNew &&
          branchesNew.map(({ branch_id, name, active }) => (
            <Select.Option key={branch_id} isActive={active} value={branch_id}>
              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                {name}{' '}
                {active ? (
                  <CheckCircleOutlined style={{ color: 'rgb(91, 107, 232)' }} />
                ) : (
                  <MinusCircleOutlined style={{ color: '#ADB5BD' }} />
                )}
              </Space>
            </Select.Option>
          ))}
      </Select>
    </Row>
  )
}
export default HeaderBranch
