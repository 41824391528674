import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION, IMAGE_DEFAULT, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import { compare, compareCustom, removeUnicode } from 'utils'
import moment from 'moment'
import { filterOptionProduct, formatCash } from 'utils'

//components
import TitlePage from 'components/title-page'
import Permission from 'components/permission'

//antd
import {
  Row,
  Form,
  Upload,
  Input,
  Button,
  notification,
  Table,
  Col,
  Select,
  Spin,
  Modal,
} from 'antd'

//icons
import {
  DeleteOutlined,
  PlusOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons'

//apis
import { uploadFile } from 'apis/upload'
import { addCategory } from 'apis/category'
import { getProducts } from 'apis/product'
import {searchComponentProduct} from '../../utils/search'
import { useTranslation } from 'react-i18next'
import { getProductsSystem } from '../../redux/actions/products'

export default function CategoryCreate({
  title,
  toggle,
  reload,
  visible,
  setVisible,
  getCategories,
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const typingTimeoutRef = useRef(null)
  const [loadingProduct, setLoadingProduct] = useState(false)
  let regexCategoryName = /[a-zA-Z0-9\+]/
  const [imageView, setImageView] = useState('')
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 5 })
  const [loading, setLoading] = useState(false)
  const [countProduct, setCountProduct] = useState(0)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const productsData = useSelector((state) => state.products.products)
  const [filteredResults, setFilteredResults] = useState([])

  const [match, setMatch] = useState('all')
  const [conditions, setConditions] = useState([
    { name: 'name', operator: 'is_equal_to', value: '' },
  ])

  const columnsProduct = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'create_date',
      key: 'create_date',
    },
    {
      title: 'Hành động',
      width: 90,
      render: (text, data) => (
        <Button
          icon={<DeleteOutlined />}
          type="primary"
          danger
          onClick={() => _removeProductToCategory(data)}
        />
      ),
    },
  ]

  const _addOrUpdateCategory = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      if (!regexCategoryName.test(dataForm.name)) {
        notification.error({ message: 'Tên nhóm sản phẩm ko hợp lệ' })
        return
      }
      dispatch({ type: ACTION.LOADING, data: true })
      let productNew = listProduct.map(e => e.product_id)
      const body = {
        ...dataForm,
        parent_id: -1,
        image: imageView || '',
        default: dataForm.default || false,
        description: dataForm.description || '',
        branch_id: branchIdApp,
        condition: {
          must_match: match,
          function: conditions,
        },
        name: dataForm.name.trim(),
        products: productNew,

      }

      let res = await addCategory(body)
      if (res.status === 200) {
        if (res.data.success) {
          getCategories()
          setVisible(false)
          setListProduct([])
          setImageView('')
          form.setFieldsValue({
            name: '',
          })
          notification.success({
            message: `Tạo nhóm sản phẩm thành công`,
          })
          if (title === 'product-form') {
            toggle()
            reload()
          } else {
            history.push(ROUTES.CATEGORIES)
          }
        } else
          notification.error({
            message: res.data.mess || res.data.message || `Tạo nhóm sản phẩm thất bại!`,
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || `Tạo nhóm sản phẩm thất bại!`,
        })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const [listProduct, setListProduct] = useState([])

  const addProduct = async (data) => {
    const listProductNew = [...listProduct]
    var variantNew = {
      product_id: data.product_id,
      creator: data._creator,
      sku: data.sku,
      title: data.title,
      unit: data.unit,
      images: data.images,
      total_quantity: data.total_quantity,
      total_inventory: 0,
      real_quantity: 1,
      diff_reason: '',
      system_quantity: data.total_quantity,
    }

    if (listProductNew.length === 0) listProductNew.push(variantNew)
    else {
      const indexVariant = listProduct.findIndex((p) => p.product_id === data.product_id)
      if (indexVariant === -1) listProductNew.push(variantNew)
      else listProductNew[indexVariant].real_quantity += 1
    }
    setListProduct([...listProductNew])
  }
  const _removeProductToCategory = (product) => {
    const productsNew = [...listProduct]

    const indexProduct = productsNew.findIndex((p) => p.product_id === product.product_id)

    if (indexProduct !== -1) {
      productsNew.splice(indexProduct, 1)
    }
    setListProduct([...productsNew])
  }

  useEffect(() =>{
    if(branchIdApp) {
      dispatch(getProductsSystem({branchIdApp: branchIdApp}))
    }
  },[branchIdApp])


  return (
    <Modal
      width={800}
      visible={visible}
      closable={false}
      footer={[
        <Row justify="end" gutter={[12, 0]}>
          <Col>
            {' '}
            <Button onClick={() => setVisible(false)}>Đóng</Button>
          </Col>
          <Col>
            <Permission permissions={[PERMISSIONS.tao_nhom_san_pham]}>
              <Button type="primary" onClick={_addOrUpdateCategory}>
                Tạo
              </Button>
            </Permission>
          </Col>
        </Row>,
      ]}
      title={<div style={{ marginLeft: 8 }}>{'Tạo nhóm sản phẩm'}</div>}
    >
      <Form layout="vertical" form={form}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <span>Hình ảnh</span>
          <Upload
            name="avatar"
            fileList={[]}
            listType="picture-card"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            data={async (file) => {
              const isJpgOrPng =
                file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'

              const isLt2M = file.size / 1024 / 1024 < 2

              if (!isJpgOrPng) {
                notification.error({ message: 'Bạn chỉ có thể tải lên file JPG/PNG/JPEG!' })
              }

              if (!isLt2M) {
                notification.error({ message: 'Hình ảnh không được lớn hơn 2MB!' })
              }

              if (isJpgOrPng && isLt2M && file) {
                setLoading(true)
                const url = await uploadFile(file)
                if (url) setImageView(url || '')
                setLoading(false)
              }
            }}
          >
            
              <div>
              {imageView ? <img src={imageView} alt="" style={{ width: '100%' }} /> : (<><PlusOutlined /> <div style={{ marginTop: 8 }}>Upload</div></>)}
                
              </div>
           
          </Upload>
        </div>
        <div style={{ width: '100%' }}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên nhóm sản phẩm',
                // pattern: regexCategoryName,
              },
            ]}
            name="name"
            label="Tên nhóm sản phẩm"
          >
            <Input placeholder="Nhập tên nhóm sản phẩm" style={{ width: '100%' }} />
          </Form.Item>
        </div>
      </Form>

      <div>
        <Row>
          <h3>Danh sách sản phẩm</h3>
          <Select
            style={{ width: '100%', marginBottom: 10 }}
            notFoundContent={loadingProduct ? <Spin size="small" /> : null}
            dropdownClassName="dropdown-select-search-product"
            allowClear
            showSearch
            clearIcon={<CloseOutlined style={{ color: 'black' }} />}
            suffixIcon={<SearchOutlined style={{ color: 'black', fontSize: 15 }} />}
            placeholder={'Thêm sản phẩm vào nhóm'}
            value={null}
            // onSearch={(searchValue) => searchComponentProduct(searchValue, setFilteredResults, products)}
            filterOption={(input, option) =>
                removeUnicode(option.value.toString())
                  .toLowerCase()
                  .trim()
                  .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
              }
            onChange={(value, option) => {
              if (value !== null && value !== undefined && value !== '') {
                let index = value.lastIndexOf('/')
                let resultIndex = value.substring(index + 1)

                let productResults = []
                if (filteredResults && filteredResults.length > 0) {
                  productResults = filteredResults
                } else {
                  productResults = productsData
                }
                if (productResults[resultIndex].inventory === 'normal') {
                  if (productResults[resultIndex].is_open === false) {
                    return notification.error({
                      message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                    })
                  } else {
                    addProduct(productResults[resultIndex])
                  }
                } else {
                  addProduct(productResults[resultIndex])
                }

                if (productResults.length === 0) {
                  return notification.error({
                    message: 'Sản phẩm cơ bản này hiện tại không mở bán',
                  })
                }
                setFilteredResults([])
              }
            }}
            // dropdownRender={(menu) => <div>{menu}</div>}
          >
            {productsData && productsData.map(
                  (data, index) =>
                    data && (
                      <Select.Option key={data.title + data.sku + data.barcode + '/' + index + ''}>
                      <Row
                        align="middle"
                        wrap={false}
                        style={{
                          padding: '7px 13px',
                          backgroundColor: data.is_choose == true ? 'rgb(231, 233, 251)' : 'unset',
                        }}
                        onClick={(e) => {
                          addProduct(data)
                          e.stopPropagation()
                        }}
                      >
                        <img
                          src={data.image[0] ? data.image[0] : IMAGE_DEFAULT}
                          alt=""
                          style={{
                            minWidth: 40,
                            minHeight: 40,
                            maxWidth: 40,
                            maxHeight: 40,
                            objectFit: 'cover',
                          }}
                        />

                        <div style={{ width: '100%', marginLeft: 15 }}>
                          <Row wrap={false} justify="space-between">
                            <span
                              style={{
                                maxWidth: 450,
                                marginBottom: 0,
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                              }}
                            >
                              {data.title}
                            </span>
                            <p style={{ marginBottom: 0, fontWeight: 500 }}>
                              {formatCash(data.price)}
                            </p>
                          </Row>
                          <Row wrap={false} justify="space-between">
                            <p style={{ marginBottom: 0, color: 'gray' }}>{data.sku}</p>
                          </Row>
                        </div>
                      </Row>
                    </Select.Option>
                    )
                )}
          </Select>
          <Table
            size="small"
            columns={columnsProduct.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              if (column.key === 'title')
                return {
                  ...column,
                  render: (text, record) => record.title,
                  sorter: (a, b) => compare(a, b, 'title'),
                }

              if (column.key === 'sku')
                return {
                  ...column,
                  sorter: (a, b) => compare(a, b, 'sku'),
                }

              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) => moment(record.create_date).format('DD/MM/YYYY'),
                }
              return column
            })}
            scroll={{ y: 300 }}
            style={{ width: '100%' }}
            pagination={{
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showQuickJumper: true,
              onChange: (page, pageSize) =>
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
            }}
            dataSource={listProduct}
          />
        </Row>
      </div>
    </Modal>
  )
}
