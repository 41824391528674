import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { ACTION, PAGE_SIZE, PERMISSIONS, ROUTES, TIME_FORMAT } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

//components
import columnsStockAdvanced from './columns'
import TitlePage from 'components/title-page'
import FilterDate from 'components/filter-date/filter'

//antd
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  Table,
  Select,
  Affix,
  notification,
  Modal,
  Form,
  Radio,
  TreeSelect,
  Popconfirm,
} from 'antd'

//icons
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'

//apis
import {
  getCheckInventoryNoteAdvanced,
  createCheckInventoryNoteAdvanced,
  deleteCheckInventoryNoteAdvanced,
} from 'apis/inventory-node-advanced'
import { getStaff } from 'apis/manage-staff'

import { getProducts } from 'apis/product'
import Permission from 'components/permission'
import FormItem from 'antd/lib/form/FormItem'

import { getCategories } from 'apis/category'
import { removeUnicode } from 'utils'
import { getProductWarehouse } from 'redux/actions/productWarehouse'
import removeVietnameseTones from 'utils/removeUnicode'
import { DefaultFilterData } from 'utils/DefaultFilterData'

const { Search } = Input
const { Option } = Select
export default function StockAdjustmentsAdvanced() {
  const dispatch = useDispatch()
  const typingTimeoutRef = useRef(null)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const { productWarehouse } = useSelector((state) => state.products)
  const [form] = Form.useForm()
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    page_size: 20,
  })
  const [valueTime, setValueTime] = useState()
  const [valueSearch, setValueSearch] = useState('')
  const [inventoryNote, setInventoryNote] = useState([])
  const [inventoryNoteShow, setInventoryNoteShow] = useState([])
  const [countInventoryNote, setCountInventoryNote] = useState(0)
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)
  const [productId, setProductId] = useState([])
  const [valueRadio, setValueRadio] = useState(1)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const dataUser = useSelector((state) => state.login.dataUser)
  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handle = () => {
    setIsModalVisible(false)
  }
  const _createInventoryNote = async () => {
    try {
      await form.validateFields()
      const dataForm = form.getFieldsValue()
      const body = {
        ...dataForm,
        inventoryCheckers: [],
        branch_id: branchIdApp || 0,
        title: dataForm.title,
        status: 1,
        allProduct: valueRadio === 1 ? true : false,
        category: valueRadio === 2 ? dataForm.category_id : [],
        product: valueRadio === 3 ? dataForm.product_id : [],
      }
      if (dataForm.title == undefined) {
        notification.warning({ message: 'Nhập tên phiếu kiểm' })
      } else {
        let res = await createCheckInventoryNoteAdvanced(body)
        if (res.status === 200) {
          if (res.data.success == true) {
            notification.success({ message: 'Thêm phiếu kiểm thành công' })
            _getCheckInventoryNote()
            handle()
            form.resetFields()
            setValueRadio(1)
          } else if (res.data.success == false) {
            notification.error({ message: res.data.message || 'Thêm phiếu không thành công' })
          }
        } else {
          notification.error({ message: res.data.message || 'Thêm phiếu không thành công' })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getCheckInventoryNote = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getCheckInventoryNoteAdvanced({ branch_id: branchIdApp })
      if (res.status === 200) {
        let result = DefaultFilterData(dataUser?.role_id, permissions, res.data.data)
        setInventoryNote(result.data)
        setValueSearch('')
        if (dataUser.role_id === 1 || permissions.includes(PERMISSIONS.tuy_chon)) {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
          })
          setValueTime()
        } else {
          setParamsFilter({
            page: 1,
            page_size: PAGE_SIZE,
            from_date: result.defaultTime.from_date,
            to_date: result.defaultTime.to_date,
          })
          setValueTime(result.valueDate)
        }
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }
  const _deleteInventoryNotes = async (id) => {
    try {
      const res = await deleteCheckInventoryNoteAdvanced({
        inventoryNoteId: id,
        branch_id: branchIdApp,
      })
      if (res.status === 200) {
        notification.success({ message: 'Xóa phiếu kiểm thành công' })
        _getCheckInventoryNote()
      } else
        notification.error({
          message: res.data.message || 'Xóa phiếu kiểm thất bại, vui lòng thử lại',
        })
    } catch (err) {
      console.log(err)
    }
  }

  const _getUserList = async () => {
    try {
      const res = await getStaff({ branch_id: branchIdApp })

      if (res.status === 200) {
        if (res.data.success)
          setUserList(res.data.data.filter((item) => item.status === 1 || item.role_id === 1))
      }
    } catch (err) {
      console.log(err)
    }
  }
  const _getProduct = async () => {
    try {
      const res = await getProducts({ branch_id: branchIdApp })

      if (res.status === 200) {
        if (res.data.success) setProductId(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _onSearch = (e) => {
    setValueSearch(e)
    e = removeVietnameseTones(e)
    if (e.includes(' ')) {
      e = new RegExp(e.replace(/ /g, '.*'))
    } else {
      e = new RegExp(e)
    }
    paramsFilter['search'] = e
  }

  const _onClearFilters = () => {
    setValueSearch('')
    setValueTime()
    setParamsFilter({ page: 1, page_size: 20 })
  }
  const _onFilter = (attribute = '', value = '') => {
    const paramsFilterNew = { ...paramsFilter }
    if (value) paramsFilterNew[attribute] = value
    else delete paramsFilterNew[attribute]
    setParamsFilter({ ...paramsFilterNew })
  }

  const _changeProduct = (inventory) => {
    let arrTmp = []
    if (inventory.allProduct === false) {
      if (inventory?.product === null || inventory?.product?.length === 0) {
        inventory?.category.map((id) => {
          let pr = productWarehouse.reduce(function (arr, item) {
            let check
            if (Array.isArray(item?.category_id)) check = item?.category_id.indexOf(id)
            if (check !== -1) arr.push(item)
            return arr
          }, [])
          arrTmp = [...arrTmp, ...pr]
        })
        let amount = 0
        arrTmp.map((item) => {
          amount += item?.total_quantity || 0
        })
        return amount
      } else {
        inventory.product.map((id) => {
          let pr = productWarehouse.filter((item) => item?.product_id === id)
          arrTmp = [...arrTmp, ...pr]
        })
        let amount = 0
        arrTmp.map((item) => {
          amount += item?.total_quantity || 0
        })
        return amount
      }
    } else {
      let amount = 0
      productWarehouse.map((item) => {
        amount += item?.total_quantity || 0
      })
      return amount
    }
  }

  const _setBackgroundStatus = (e) => {
    if (e === 2) return '#2baaff'
    if (e === 3) return '#39cb7f'
    if (e === 1) return '#fdc90f'
  }
  useEffect(() => {
    let arr = [...inventoryNote]
    const filtered = arr.filter((current) => {
      let matchStatus = true
      let matchTime = true
      let matchSearch = true
      let matchUser = true

      if (paramsFilter.creator_id && paramsFilter.creator_id !== 'all')
        matchUser = current?.creator_id === paramsFilter.creator_id
      if (paramsFilter.status && paramsFilter.status !== 'all')
        matchStatus = current?.status === paramsFilter.status
      if (paramsFilter.search) {
        let newObject = {
          name: current.title,
          code: current.code,
          real_quantity: current.actual_product_quantity,
          system_quantity: current.system_product_quantity,
          create_date: current.create_date,
          creator: current?.creator_info?.first_name + ' ' + current?.creator_info?.last_name,
          note: current.note,
          status:
            current.status === 1 ? 'Đang kiểm' : current.status === 2 ? 'Kiểm xong' : 'Đã cân bằng',
        }
        newObject = removeVietnameseTones(Object.values(newObject).join(' ').toLocaleLowerCase())
        matchSearch = paramsFilter.search.test(newObject)
      }

      if (paramsFilter.to_date)
        matchTime =
          moment(current?.create_date).unix() >= paramsFilter.from_date &&
          moment(current?.create_date).unix() <= paramsFilter.to_date
      return matchStatus && matchSearch && matchTime && matchUser
    })
    setInventoryNoteShow(filtered)
    setCountInventoryNote(filtered.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter, valueSearch])

  useEffect(() => {
    if (branchIdApp) {
      dispatch(getProductWarehouse({ branchIdApp: branchIdApp }))
    }
    _getCategories()
    _getProduct()
  }, [branchIdApp])

  useEffect(() => {
    _getCheckInventoryNote()
    _getUserList()
  }, [branchIdApp, dataUser])

  const onChangeRadio = (e) => {
    setValueRadio(e.target.value)
  }

  const [categories, setCategories] = useState([])
  const _getCategories = async () => {
    try {
      const res = await getCategories()
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) {
          setCategories(res.data.data.filter((e) => e.active))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="card">
        <TitlePage title="Phiếu kiểm hàng nâng cao" isAffix>
          <Space>
            <Button
              size="large"
              onClick={_onClearFilters}
              type="primary"
              danger
              style={{ display: Object.keys(paramsFilter).length <= 2 && 'none' }}
            >
              Xóa bộ lọc
            </Button>
            <Permission permissions={[PERMISSIONS.them_phieu_kiem_hang_nang_cao]}>
              <Button type="primary" size="large" onClick={showModal}>
                Tạo phiếu kiểm
              </Button>
            </Permission>
          </Space>
        </TitlePage>
      {permissions.includes(PERMISSIONS.xem_danh_sach_kiem_hang_nang_cao) ? (
        <>
          <Row
            gutter={[0, 16]}
            style={{
              marginLeft: 0,
              marginRight: 0,
              marginTop: 15,
              border: '1px solid #d9d9d9',
              borderRadius: 5,
              backgroundColor: 'white'
            }}
          >
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Input
                value={valueSearch}
                prefix={<SearchOutlined />}
                allowClear
                onChange={(e) => _onSearch(e.target.value)}
                placeholder="Tìm kiếm phiếu kiểm hàng"
                bordered={false}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              style={{ borderRight: '1px solid #d9d9d9', borderLeft: '1px solid #d9d9d9' }}
            >
              <FilterDate
                style={{ marginTop: 3 }}
                bordered={false}
                paramsFilter={paramsFilter}
                setParamsFilter={setParamsFilter}
                title="tạo phiếu kiểm hàng nâng cao"
                valueTime={valueTime}
                setValueTime={setValueTime}
                screen={1}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{ borderRight: '1px solid #d9d9d9' }}>
              <Select
                placeholder="Lọc theo trạng thái"
                allowClear
                showSearch
                defaultValue={'all'}
                bordered={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={paramsFilter.status || 'all'}
                onChange={(value) => _onFilter('status', value)}
                style={{ width: '100%' }}
              >
                <Select.Option value="all">Tất cả trạng thái</Select.Option>
                <Select.Option value={1}>Đang kiểm</Select.Option>
                <Select.Option value={2}>Kiểm xong</Select.Option>
                <Select.Option value={3}>Đã cân bằng</Select.Option>
                {/* <Select.Option value={4}>Đã hủy</Select.Option> */}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Select
                onChange={(value) => _onFilter('creator_id', value)}
                value={paramsFilter.creator_id || 'all'}
                style={{ width: '100%' }}
                placeholder="Lọc theo nhân viên tạo"
                allowClear
                showSearch
                optionFilterProp="children"
                bordered={false}
              >
                <Option value="all">Tất cả nhân viên</Option>
                {userList.map((item, index) => {
                  return (
                    <Option value={item.user_id} key={index}>
                      {item.first_name} {item.last_name}
                    </Option>
                  )
                })}
              </Select>
            </Col>
          </Row>

          <Table
            size="small"
            scroll={{ y: 650 }}
            dataSource={inventoryNoteShow}
            columns={columnsStockAdvanced.map((column) => {
              if (column.key === 'stt')
                return {
                  ...column,
                  width: 50,
                  render: (text, record, index) =>
                    (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                }
              if (column.key === 'code')
                return {
                  ...column,
                  render: (text, record) =>
                    permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_kiem_hang_nang_cao) ? (
                      <Link
                        to={{
                          pathname: ROUTES.STOCK_ADJUSTMENTS_ADVANCED_UPDATE + '/' + record.code,
                          state: record,
                        }}
                      >
                        {record.code}
                      </Link>
                    ) : (
                      <div
                        style={{ color: '#5F73E2', cursor: 'pointer' }}
                        onClick={() => notification.warning({ message: 'Permission denied!' })}
                      >
                        #{record.code}
                      </div>
                    ),
                }
              if (column.key === 'status')
                return {
                  ...column,
                  render: (text, record) => (
                    <div
                      style={{
                        textAlign: 'center',
                        background: _setBackgroundStatus(record.status),
                      }}
                    >
                      {/* {record.status === 4
                    ? 'Đã hủy' */}
                      {record.status === 3
                        ? 'Đã cân bằng'
                        : record.status === 2
                        ? 'Kiểm xong'
                        : 'Đang kiểm'}
                    </div>
                  ),
                }
              if (column.key === 'title')
                return {
                  ...column,
                  render: (text, record) => record.title,
                }
              if (column.key === 'create_date')
                return {
                  ...column,
                  render: (text, record) => moment(record.create_date).format(TIME_FORMAT.DATE),
                }
              if (column.key === 'real_quantity')
                return {
                  ...column,
                  render: (text, record) => record.actual_product_quantity,
                }
              if (column.key === 'system_quantity')
                return {
                  ...column,
                  render: (text, record) =>
                    record.status !== 3 ? _changeProduct(record) : record.system_product_quantity,
                }
              if (column.key === 'creator_info')
                return {
                  ...column,
                  render: (text, record) =>
                    record.creator_info &&
                    record.creator_info.first_name + ' ' + record.creator_info.last_name,
                }
              if (column.key === 'action')
                return {
                  ...column,

                  render: (text, record) =>
                    record.status < 3 ? (
                      <Popconfirm
                        title="Bạn có muốn xóa phiếu kiểm này không?"
                        okText="Đồng ý"
                        cancelText="Từ chối"
                        onConfirm={() => _deleteInventoryNotes(record.inventoryNoteId)}
                        disabled={
                          permissions.includes(PERMISSIONS.xoa_phieu_kiem_hang_nang_cao)
                            ? false
                            : true
                        }
                      >
                        <Button
                          disabled={
                            permissions.includes(PERMISSIONS.xoa_phieu_kiem_hang_nang_cao)
                              ? false
                              : true
                          }
                          icon={<DeleteOutlined />}
                          type="primary"
                          danger
                        />
                      </Popconfirm>
                    ) : (
                      <></>
                    ),
                }

              return column
            })}
            loading={loading}
            style={{ width: '100%', marginTop: 10 }}
            pagination={{
              position: ['bottomLeft'],
              current: paramsFilter.page,
              pageSize: paramsFilter.page_size,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
              },
              total: countInventoryNote,
            }}
          />
        </>
      ) : null}

      <Modal
        title="Tạo phiếu kiểm mới"
        visible={isModalVisible}
        onOk={_createInventoryNote}
        onCancel={handle}
        okText="Lưu"
        cancelText="Hủy bỏ"
      >
        <Form form={form}>
          <FormItem
            label="Tiêu đề"
            name="title"
            rules={[{ required: true, message: 'Vui lòng nhập tên phiếu!', whitespace: true }]}
          >
            <Input allowClear />
          </FormItem>

          <h3>Sản phẩm kiểm</h3>
          <Radio.Group onChange={onChangeRadio} value={valueRadio}>
            <Radio value={1}>Tất cả sản phẩm</Radio>
            <Radio value={2}>Nhóm sản phẩm</Radio>
            <Radio value={3}>Sản phẩm được chọn</Radio>
          </Radio.Group>

          {valueRadio === 2 ? (
            <Col xs={24} sm={24} md={8} lg={8} xl={24} style={{ marginTop: 10 }}>
              <Row wrap={false} align="middle">
                <Form.Item
                  rules={[{ required: true, message: 'Vui lòng chọn nhóm sản phẩm' }]}
                  label="Nhóm sản phẩm"
                  name="category_id"
                  style={{ marginRight: 10, width: '100%' }}
                >
                  <TreeSelect
                    showCheckedStrategy={TreeSelect.SHOW_ALL}
                    size="large"
                    style={{ width: '100%' }}
                    treeNodeFilterProp="title"
                    maxTagCount="responsive"
                    placeholder="Chọn nhóm sản phẩm"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    filterTreeNode={(input, TreeNode) =>
                      removeUnicode(TreeNode.title)
                        .toLowerCase()
                        .trim()
                        .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                    }
                  >
                    {categories.map((category) => (
                      <TreeSelect.TreeNode value={category.category_id} title={category.name}>
                        {category.children_category.map((child) => (
                          <TreeSelect.TreeNode value={child.category_id} title={child.name}>
                            {child.children_category.map((e) => (
                              <TreeSelect.TreeNode value={e.category_id} title={e.name}>
                                {e.name}
                              </TreeSelect.TreeNode>
                            ))}
                          </TreeSelect.TreeNode>
                        ))}
                      </TreeSelect.TreeNode>
                    ))}
                  </TreeSelect>
                </Form.Item>
              </Row>
            </Col>
          ) : null}
          {valueRadio === 3 ? (
            <Col xs={24} sm={24} md={8} lg={8} xl={24} style={{ marginTop: 10 }}>
              <Row wrap={false} align="middle">
                <Form.Item
                  rules={[{ required: true, message: 'Vui lòng chọn sản phẩm' }]}
                  label="Sản phẩm"
                  name="product_id"
                  style={{ marginRight: 10, width: '100%' }}
                >
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Chọn sản phẩm"
                    allowClear
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      removeUnicode(option.key.toString())
                        .toLowerCase()
                        .trim()
                        .indexOf(removeUnicode(input).toLowerCase().trim()) >= 0
                    }
                  >
                    {productId.map((product) => (
                      <Select.Option
                        key={product.name + product.sku + ''}
                        value={product.product_id}
                      >
                        {product.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
            </Col>
          ) : null}
        </Form>
      </Modal>
    </div>
  )
}
