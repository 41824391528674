import { ArrowLeftOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Button, Input, notification, Row, Space } from 'antd'
import { deleteShippingControl, getOneShippingControl, updateShippingControl } from 'apis/shipping-control'
import TitlePage from 'components/title-page'
import { PERMISSIONS, ROUTES } from 'consts'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { formatCash } from 'utils'
import TableData from './items/table'
import styles from './shipping-control.module.scss'

const ShippingControlUpdate = () => {
  const history = useHistory()
  const {code} = useParams()
  const [dataDetail, setDataDetail] = useState()
  const [note, setNote] = useState('')
  const branch = useSelector((state) => state.branch.branches)
  const branchIdApp = useSelector((state) => state.branch.branchId)
  const dataUser = useSelector((state) => state.login.dataUser)

  const permissions = dataUser ? [...dataUser._role.permission_list] : []
  const _updateShippingControl = async () => {
    try {
      let body = {
        shipping_control_id: dataDetail?.shipping_control_id,
        branch_id: branch?.branch_id,
        note: note,
      }
      let res = await updateShippingControl(body)
      if(res.data.success) {
        notification.success({message: 'Cập nhật phiếu đối soát thành công!'})
        history.push(ROUTES.SHIPPING_CONTROL)
      } else {
        notification.error({message: 'Cập nhật phiếu đối soát thất bại!'})
      }
    } catch (error) {
      return notification.error({ key: 'error data try catch', message: error })
    }
  }

  const _deleteShippingControl = async () => {
    try {
      let body = {
        shipping_control_id: dataDetail?.shipping_control_id,
        branch_id: branch?.branch_id,
      }
      let res = await deleteShippingControl(body)
      if(res.data.success) {
        notification.success({message: 'Xoá phiếu đối soát thành công!'})
        history.push(ROUTES.SHIPPING_CONTROL)
      } else {
        notification.error({message: 'Xoá phiếu đối soát thất bại!'})
      }
    } catch (error) {
      return notification.error({ key: 'error data try catch', message: error })
    }
  }

  

  useEffect(() => {
    const _getOneShippingControl = async () => {
      try {
        let res = await getOneShippingControl({code: code})
        if(res.status === 200) {
          setDataDetail(res.data.data)
          setNote(res.data.data.note)
        } else {
          notification.error({message: 'Mã phiếu đối soát không tồn tại'})
        }
      } catch (error) {
        
      }
    }
    _getOneShippingControl()
  },[code])
  useEffect(() =>{
    if(!permissions.includes(PERMISSIONS.xem_chi_tiet_phieu_doi_soat_van_chuyen)) history.push(ROUTES.SHIPPING_CONTROL)
  },[branchIdApp])
  if(!dataDetail) return <div className="card">
  <TitlePage
    isAffix={true}
    title={
      <Row
        wrap={false}
        align="middle"
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(ROUTES.SHIPPING_CONTROL)}
      >
        <ArrowLeftOutlined style={{ marginRight: 8 }} />
        Chi tiết phiếu đối soát vận chuyển
      </Row>
    }
  >
  </TitlePage>
  </div>

  return (
    <div className="card">
    <TitlePage
      isAffix={true}
      title={
        <Row
          wrap={false}
          align="middle"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(ROUTES.SHIPPING_CONTROL)}
        >
          <ArrowLeftOutlined style={{ marginRight: 8 }} />
          Chi tiết phiếu đối soát vận chuyển
        </Row>
      }
    >
      <Space>
        <Button onClick={() => {_deleteShippingControl()}} size='large' type="danger" ghost>
          Xoá phiếu
        </Button>
        <Button onClick={() => {_updateShippingControl()}} size='large' type="primary" ghost>
          Chỉnh sửa
        </Button>
      </Space>
    </TitlePage>
    <div style={{ marginTop: 7 }}>
      <div className={styles['detail-header']}>
        <span className={styles['span-1']}>
          {code}
        </span>
        {/* <span className={styles['span-2']}><VerticalAlignBottomOutlined style={{marginRight: '3px'}} />Xuất file</span> */}
      </div>
      <div className={styles['detail-information']}>
        <div className={styles['info-1']}>
          <h1>
            Thông tin phiếu 
          </h1>
          <div className={styles['info-data']}>
            <div className={styles['left-side']}>
              <div className={styles['title']}>
                <p>Đối tác vận chuyển:</p>
                <p>Nhân viên tạo phiếu:</p>
                <p>Thời gian đối soát:</p>
              </div>
              <div className={styles['data']}>
                <p>{dataDetail?.shipping_company_info?.name}</p>
                <p>{dataDetail?.creator_info?.first_name + ' ' + dataDetail?.creator_info?.last_name}</p>
                <p>{moment(dataDetail?.create_date).format('DD/MM/YYYY') + ' --- ' + moment(dataDetail?.last_update).format('DD/MM/YYYY')}</p>
              </div>
            </div>
            <div className={styles['right-side']}>
              <div className={styles['title']}>
                  <p>Chi nhánh đối soát:</p>
                  <p>Ngày đối soát:</p>
              </div>
              <div className={styles['data']}>
                  <p>{Object(branch?.find(item => item.branch_id === dataDetail.branch_id)).name}</p>
                  <p>{moment(dataDetail?.create_date).format('DD/MM/YYYY hh:mm:ss')}</p>
              </div>
            </div>
          </div>
          <div className={styles['total-data']}>
            <h1>
              Tổng đối soát hệ thống: {formatCash(dataDetail.table_data.reduce((total, item) => total += item.cod_system ,0))} VNĐ
            </h1>
            <h1>
              Tổng đối soát đối tác: {formatCash(dataDetail.table_data.reduce((total, item) => total += item.cod_partner ,0))} VNĐ
            </h1>
          </div>

        </div>
        <div className={styles['info-2']}>
          <h1>
            Thông tin bổ sung 
          </h1>
          <h1>
            Ghi chú:
          </h1>
          <Input.TextArea value={note} onChange={(e) => setNote(e.target.value)} placeholder='Không có ghi chú'/>
        </div>
      </div>
      <div style={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)'}}>
        <TableData dataOrders={dataDetail?.table_data} dataOrdersCount={dataDetail?.table_data?.length}/>
      </div>
    </div>
  </div>
  )
}

export default ShippingControlUpdate